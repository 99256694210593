import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Constants from "../../constants/constants";


const EmployeeAceReport = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [row, setRow] = useState({});
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split('-')[0]);
    $('#s_month').val(Fn.getCurrentDate().split('-')[1]);

    // 현재 주차
    const cdate = new Date();
    const firstWeekday = new Date(cdate.getFullYear(), cdate.getMonth(), 1).getDay();
    const offsetDate = cdate.getDate() + firstWeekday - 1;
    $('#s_week').val(Math.floor(offsetDate / 7) + 1);
  }, []);

  useEffect(() => {
    load();
  }, [renew, srchRenew]);

  const load = () => {
    axios2
      .post(`/employee/ace/report/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&s_week=${$('#s_week option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRow(result?.row);
          console.log(result);

          let sms_date = [];
          let period1 = [];
          let period2 = [];
          let member_num = [];
          let no_member = [];
          let sms_send = [];
          let tel_send = [];
          let per1 = [];
          let per2 = [];
          let per3 = [];

          for (let i = 0; i < result?.data?.length; i++) {
            let d_week = result?.data[i].week;
            sms_date[d_week] = result?.data[i].sms_date;
            period1[d_week] = result?.data[i].period1;
            period2[d_week] = result?.data[i].period2;
            member_num[d_week] = result?.data[i].member_num;
            no_member[d_week] = result?.data[i].no_member;
            sms_send[d_week] = result?.data[i].sms_send;
            tel_send[d_week] = result?.data[i].tel_send;
          }

          $('#max_week').val(result?.maxWeek);

          if (result?.row?.img1_name?.length > 0)
            $('#img1_img').attr('src', Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=ace_img&filename=" + encodeURIComponent(result?.row?.img1_name)));
          if (result?.row?.img2_name?.length > 0)
            $('#img2_img').attr('src', Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=ace_img&filename=" + encodeURIComponent(result?.row?.img2_name)));
          if (result?.row?.img3_name?.length > 0)
            $('#img3_img').attr('src', Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=ace_img&filename=" + encodeURIComponent(result?.row?.img3_name)));

          $('#good_point').val(result?.row?.good_point);
          $('#bad_point').val(result?.row?.bad_point);
          $('#coaching').val(result?.row?.coaching);

          let tableHtml = `<table width="100%" border="0" cellspacing="0" cellpadding="0" class="table table-bordered">
            <tr>
              <th width="35" rowspan="4">구<br>분</th>`;

          for (let i = 1; i <= result?.maxWeek; i++) {
            tableHtml += `<th colspan="4">${i}주차</th>`;
          }

          tableHtml += `</tr><tr>`;

          for (let i = 1; i <= result?.maxWeek; i++) {
            tableHtml += `
              <th>문자발송일</th>
              <th colspan="3">회원 미출석기간</th>`;
          }

          tableHtml += `</tr><tr>`;

          for (let i = 1; i <= result?.maxWeek; i++) {
            if (i == $('#s_week').val()) {
              tableHtml += `
                <td>
                  <input type="text" id="sms_date_${i}" class="form-control wid70 input_date" value="${sms_date[i] || ''}">
                </td>
                <td colspan="3">
                  <div class="input-group">
                    <input type="text" id="period1_${i}" class="form-control wid70 input_date" value="${period1[i] || ''}"> ~ <input type="text" id="period2_${i}" class="form-control wid70 input_date" value="${period2[i] || ''}">
                  </div>
                </td>`;
            } else {
              tableHtml += `
                <td>${sms_date[i] || ''}</td>
                <td colspan="3">${period1[i] || ''} ~ ${period1[i] || ''}</td>`;
            }
          }

          tableHtml += `</tr><tr>`;

          for (let i = 1; i <= result?.maxWeek; i++) {
            tableHtml += `
              <th width="90">유효회원수</th>
              <th width="70">미출석회원</th>
              <th width="70">문자발송</th>
              <th width="70">전화통화</th>`;
          }

          tableHtml += `</tr>
                        <tr>
                          <th rowspan="2">현<br>황</th>`;

          for (let i = 1; i <= result?.maxWeek; i++) {
            if (i == $('#s_week').val()) {
              tableHtml += `
                <td rowspan="2">
                  <input type="text" class="form-control" id="member_num_${i}" style="ime-mode:disabled" value="${Fn.numberWithCommas(member_num[i])}" onfocus="this.select()">
                </td>
                <td>
                  <input type="text" class="form-control" id="no_member_${i}" style="ime-mode:disabled" value="${Fn.numberWithCommas(no_member[i])}" onfocus="this.select()">
                </td>
                <td>
                  <input type="text" class="form-control" id="sms_send_${i}" style="ime-mode:disabled" value="${Fn.numberWithCommas(sms_send[i])}" onfocus="this.select()">
                </td>
                <td>
                  <input type="text" class="form-control" id="tel_send_${i}" style="ime-mode:disabled" value="${Fn.numberWithCommas(tel_send[i])}" onfocus="this.select()">
                </td>`;
            } else {
              tableHtml += `
                <td rowspan="2">${Fn.numberWithCommas(member_num[i])}</td>
                <td>${Fn.numberWithCommas(no_member[i])}</td>
                <td>${Fn.numberWithCommas(sms_send[i])}</td>
                <td>${Fn.numberWithCommas(tel_send[i])}</td>
              `;
            }
          }

          tableHtml += `</tr><tr>`;

          for (let i = 1; i <= result?.maxWeek; i++) {
            if (member_num[i]) {
              per1[i] = `${(no_member[i] / member_num[i]) * 100}%`;
            } else {
              per1[i] = '';
            }

            if (no_member[i]) {
              per2[i] = `${(sms_send[i] / no_member[i]) * 100}%`;
            } else {
              per2[i] = '';
            }

            if (no_member[i]) {
              per3[i] = `${(tel_send[i] / no_member[i]) * 100}%`;
            } else {
              per3[i] = '';
            }

            tableHtml += `
              <td>&nbsp;${per1[i] || ''}</td>
              <td>&nbsp;${per2[i] || ''}</td>
              <td>&nbsp;${per3[i] || ''}</td>
            `;
          }

          tableHtml += `</tr></table>`;

          $('#div_tbl').html(tableHtml);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-1.
  function go_submit() {
    const formData = new FormData();

    formData.append('img1', $('#userfile1')[0].files[0]);
    formData.append('img2', $('#userfile2')[0].files[0]);
    formData.append('img3', $('#userfile3')[0].files[0]);

    formData.append('s_year', $('#s_year option:selected').val() || '');
    formData.append('s_month', $('#s_month option:selected').val() || '');
    formData.append('s_week', $('#s_week option:selected').val() || '');
    formData.append('good_point', $('#good_point').val() || '');
    formData.append('bad_point', $('#bad_point').val() || '');
    formData.append('coaching', $('#coaching').val() || '');
    formData.append('max_week', $('#max_week').val() || '');

    for (let i = 1; i <= Fn.parseInt($('#max_week').val()); i++) {
      formData.append('sms_date_' + i, $('#sms_date_' + i).val() || '');
      formData.append('period1_' + i, $('#period1_' + i).val() || '');
      formData.append('period2_' + i, $('#period2_' + i).val() || '');
      formData.append('member_num_' + i, $('#member_num_' + i).val() || '');
      formData.append('no_member_' + i, $('#no_member_' + i).val() || '');
      formData.append('sms_send_' + i, $('#sms_send_' + i).val() || '');
      formData.append('tel_send_' + i, $('#tel_send_' + i).val() || '');
    }

    axios2.postFormEx('/employee/ace/report', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (code === 200) {
          if (data === "err") {
            alert('오류가 발생하였습니다');
          } else if (data === "not_img_auth") {
            alert("확장자가 gif,jpg,png 파일만 가능합니다");
          } else {
            alert("저장되었습니다");
            setRenew(Fn.getRenewTime());
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function del_pic(cnt) {
    axios2
      .postEx('/employee/ace/report', {
        type: 'del',
        s_year: $('#s_year option:selected').val(),
        s_month: $('#s_month option:selected').val(),
        s_week: $('#s_week option:selected').val(),
        cnt: cnt
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/employee/ace/work")}>고객유치</label>
          <input id="bb" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/employee/ace/report")}>고객유지</label>
        </div>
      </div>

      <div className="d-flex mt-3 mb-3 ml-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}년</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}월</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_week">
            {Array.from({length: 6}, (_, index) => index + 1).map((week) => (
              <option key={week} value={week}>{week}주</option>
            ))}
          </select>
        </div>

        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3" id="div_tbl">
        <table className="view-rounded sub_table_rowspan mt-3 mb-3 table">
          <colgrpup>
            <col width="80px"/>
            <col width="*"/>
          </colgrpup>
          <tr>
            <th rowSpan="4">구분</th>
            <th colSpan="4">1주차</th>
            <th colSpan="4">2주차</th>
            <th colSpan="4">3주차</th>
            <th colSpan="4">4주차</th>
            <th colSpan="4">5주차</th>
          </tr>
          <tr>
            <th>문자발송일</th>
            <th colSpan="3">회원 미출석기간</th>
            <th>문자발송일</th>
            <th colSpan="3">회원 미출석기간</th>
            <th>문자발송일</th>
            <th colSpan="3">회원 미출석기간</th>
            <th>문자발송일</th>
            <th colSpan="3">회원 미출석기간</th>
            <th>문자발송일</th>
            <th colSpan="3">회원 미출석기간</th>
          </tr>
          <tr>
            <td></td>
            <td colSpan="3">~</td>
            <td><input type="text" className="form-control"/></td>
            <td colSpan="3">
              <div className="input-group">
                <input type="text" className="form-control"/>
                <p className="mx-2">~</p>
                <input type="text" className="form-control"/>
              </div>
            </td>
            <td></td>
            <td colSpan="3">~</td>
            <td></td>
            <td colSpan="3">~</td>
            <td></td>
            <td colSpan="3">~</td>
          </tr>
          <tr>
            <th>유효회원수</th>
            <th>미출석회원</th>
            <th>문자발송</th>
            <th>전화통화</th>
            <th>유효회원수</th>
            <th>미출석회원</th>
            <th>문자발송</th>
            <th>전화통화</th>
            <th>유효회원수</th>
            <th>미출석회원</th>
            <th>문자발송</th>
            <th>전화통화</th>
            <th>유효회원수</th>
            <th>미출석회원</th>
            <th>문자발송</th>
            <th>전화통화</th>
            <th>유효회원수</th>
            <th>미출석회원</th>
            <th>문자발송</th>
            <th>전화통화</th>
          </tr>
          <tr>
            <th rowSpan="2" style={{borderBottom: "0px"}}>현황</th>
            <td rowSpan="2" style={{borderBottom: "0px"}}>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td rowSpan="2" style={{borderBottom: "0px"}}><input type="text" className="form-control"/></td>
            <td><input type="text" className="form-control"/></td>
            <td><input type="text" className="form-control"/></td>
            <td><input type="text" className="form-control"/></td>
            <td rowSpan="2" style={{borderBottom: "0px"}}>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td rowSpan="2" style={{borderBottom: "0px"}}>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td rowSpan="2" style={{borderBottom: "0px"}}>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
          </tr>
          <tr>
            <td>-</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <div className="subprice_item">
          <div className="border-bottom text-left">
            <p className="px-3 py-2"><span className="h7 bold700">활동 POINT</span></p>
          </div>

          <div className="m-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border">
              <tr>
                <th>Good Point</th>
                <th>Bad Point</th>
                <th>지부장 코칭내용</th>
              </tr>
              <tr>
                <td className="pb-0"><textarea className="txtarea" style={{minHeight: '160px'}} id="good_point"></textarea></td>
                <td className="pb-0"><textarea className="txtarea" style={{minHeight: '160px'}} id="bad_point"></textarea></td>
                <td className="pb-0"><textarea className="txtarea" style={{minHeight: '160px'}} id="coaching"></textarea></td>
              </tr>
            </table>
          </div>
        </div>
        <div className="subprice_item mt-3">
          <div className="border-bottom text-left d-flex justify-content-between">
            <p className="px-3 py-2"><span className="h7 bold700">활동이미지</span> *홍보활동 계획서, 실제 홍보활동 사진 등</p>
            <p className="px-3 py-2"></p>
          </div>

          <div className="m-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border">
              <colgroup>
                <col width="110px"/>
                <col width="450px"/>
                <col width="*"/>
              </colgroup>

              <tr>
                <th>첨부1</th>
                <td>
                  <input type="file" className="form-control" id="userfile1"/>
                  <div className="inline d-flex justify-content-center">
                    {/*<button type="button" className="btn btn-ssm btn-outline-point">업로드</button>*/}
                  </div>
                </td>
                <td className="text-left">
                  {row?.img1_name?.length > 0 ?
                    <>
                      <img id="img1_img" style={{width: "160px", objectFit: "contain"}}/>
                      <button type="button" className="ml-2 btn btn-ssm btn-point" onClick={() => del_pic('1')}>삭제</button>
                    </>
                    :
                    <>
                      <div className="IMG_thum mt-1" style={{width: '240px'}}></div>
                    </>
                  }
                </td>
              </tr>
              <tr>
                <th>첨부2</th>
                <td>
                  <input type="file" className="form-control" id="userfile2"/>
                  <div className="inline d-flex justify-content-center">
                    {/*<button type="button" className="btn btn-ssm btn-outline-point">업로드</button>*/}
                  </div>
                </td>
                <td className="text-left">
                  {row?.img2_name?.length > 0 ?
                    <>
                      <img id="img2_img" style={{width: "160px", objectFit: "contain"}}/>
                      <button type="button" className="ml-2 btn btn-ssm btn-point" onClick={() => del_pic('2')}>삭제</button>
                    </>
                    :
                    <>
                      <div className="IMG_thum mt-1" style={{width: '240px'}}></div>
                    </>
                  }
                </td>
              </tr>
              <tr>
                <th>첨부3</th>
                <td>
                  <input type="file" className="form-control" id="userfile3"/>
                  <div className="inline d-flex justify-content-center">
                    {/*<button type="button" className="btn btn-ssm btn-outline-point">업로드</button>*/}
                  </div>
                </td>
                <td className="text-left">
                  {row?.img3_name?.length > 0 ?
                    <>
                      <img id="img3_img" style={{width: "160px", objectFit: "contain"}}/>
                      <button type="button" className="ml-2 btn btn-ssm btn-point" onClick={() => del_pic('3')}>삭제</button>
                    </>
                    :
                    <>
                      <div className="IMG_thum mt-1" style={{width: '240px'}}></div>
                    </>
                  }
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <input type="hidden" id="max_week"/>

      <div className="m-4 text-center">
        <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
      </div>
    </CmnCard>
  );
};

export default EmployeeAceReport;
