import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import ChkAuthNum from "../common/ChkAuthNum";

const MemberOcareCoupon = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/member/ocare/coupon/ready?s_pub_date1=${$('#s_pub_date1').val()}&s_pub_date2=${$('#s_pub_date2').val()}&s_use_date1=${$('#s_use_date1').val()}&s_use_date2=${$('#s_use_date2').val()}&s_use_YN=${$('#s_use_YN option:selected').val() || ''}&s_refund_YN=${$('#s_refund_YN option:selected').val() || ''}&s_mb_name=${$('#s_mb_name').val()}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.memberHp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  3-1.
  const make_monitor = () => {
    const as = window.open('/inc/black.html', 'enter_monitor', 'width=0,height=0,scrollbars=yes,top=10000,left=10000');
    as.window.close();
    const as2 = window.open('/enter/enter_monitor.html', 'enter_monitor', 'width=500,height=500,scrollbars=yes,top=600,left=1000');

    return () => {
      as2.close();
    };
  };

  //  4-1.
  const [searchAuthCode, setSearchAuthCode] = useState("");
  const [authCodeName, setAuthCodeName] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [regStep, setRegStep] = useState("");
  const [type, setType] = useState("");

  const handleSearchAuthCodeChange = (event) => {
    setSearchAuthCode(event.target.value);
  };

  //  4-2.
  // TODO : 변환 필요
  // <input type="text" id="search_auth_code" value={searchAuthCode} onChange={handleSearchAuthCodeChange} />
  // <button onClick={chk_auth_code}>
  //     Check Auth Code
  // </button>
  // <div id="auth_code_name">{authCodeName}</div>
  // <input type="text" id="auth_code" value={authCode} readOnly />

  return (
    <CmnCard onLogout={props.onLogout}>
      <div>
        <div className="d-flex m-3 table_wide_sh fn_search_box">
          <div className="pr-1 d-flex">
            <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>
              발행일 :
            </label>
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_pub_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_pub_date2"/>
            </div>
          </div>
          <div className="pr-1 d-flex">
            <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>
              사용일 :
            </label>
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_use_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_use_date2"/>
            </div>
          </div>

          <div className="pr-1" id="s_use_YN">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}}>
              <option value="">사용여부</option>
              <option value="Y">사용</option>
              <option value="N">미사용</option>
            </select>
          </div>
          <div className="pr-1" id="s_refund_YN">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}}>
              <option value="">환불여부</option>
              <option value="Y">환불</option>
              <option value="N">정상</option>
            </select>
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control width_shorts"
              aria-describedby="button-addon2"
              placeholder="이름 검색"
              id="s_mb_name"
            />
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>

        <div className="m-3 pb-5 table_list">
          <table className="view-rounded sub_table_border table_wide_sh">
            <colgroup>
              <col width="60px"/>
              <col width="*"/>
              <col width="210px"/>
              <col width="*"/>
              <col width="20%"/>
              <col width="*"/>
              <col width=""/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>발행일</th>
              <th>쿠폰번호</th>
              <th>상품코드</th>
              <th>상품명</th>
              <th>상품금액</th>
              <th>회원번호</th>
              <th>회원명</th>
              <th>회원연락처</th>
              <th>사용여부</th>
              <th>사용지점</th>
              <th>사용일</th>
            </tr>
            {rets && rets.map((ret, i) => {
              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>{`${ret?.pub_date.substring(0, 4)}-${ret?.pub_date.substring(4, 6)}-${ret?.pub_date.substring(6, 8)}`}</td>
                  <td>
                    <div dangerouslySetInnerHTML={{__html: (ret?.refund_YN === "Y" ? `<span style='color: red'>(환불)</span>` : '') + " " + ret?.coupon_no}}/>
                  </td>
                  <td>{ret?.goods_code}</td>
                  <td className="text-left">{ret?.goods_name}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.goods_price)}</td>
                  <td>{ret?.memberId}</td>
                  <td>{ret?.memberName}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{ret?.use_YN}</td>
                  <td>{ret?.useBranch}</td>
                  <td>{ret?.use_date}</td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={12}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
          {pageInfo?.totalArticles > 0 && (
            (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('carecounpon')}>연락처 확인</button>
            )
          )}
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};
export default MemberOcareCoupon;
