import React, {useEffect, useState} from "react";
import axios from 'axios';
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";

import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";

const MemberOcareReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const date_type = "A";

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/member/ocare/reg/ready?search_YN=Y&o_date1=${$('#o_date1').val()}&o_date2=${$('#o_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_hp=${$('#s_mb_hp').val()}&s_sex=${$('#s_sex option:selected').val() || ''}&s_member_id=${$('#s_member_id').val()}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/member/gift/use/ocare/ready?mb_no=${$('#modal_mb_no').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function gift_chk() {
    const g1 = $('#g1').val();
    const g2 = $('#g2').val();
    const g3 = $('#g3').val();
    //const g4 = $('#g4').val();

    if (g1.length !== 4) {
      alert("이용권번호를 입력해주세요");
      return;
    }
    if (g2.length !== 4) {
      alert("이용권번호를 입력해주세요");
      return;
    }
    if (g3.length !== 4) {
      alert("이용권번호를 입력해주세요");
      return;
    }
    /*
    if(g4.length !==4)
    {
        alert("이용권번호를 입력해주세요");
        return;
    }
    */

    axios2
      .post(`/member/gift/use/chk/ocare?mb_no=${$('#modal_mb_no').val()}&g1=` + g1 + "&g2=" + g2 + "&g3=" + g3)
      .then((response) => {
        const {code, message: data, result, pageInfo} = response?.data;
        if (code === 200) {
          const split = data.split("||");
          const v1 = split?.[0];
          const v2 = split?.[1];
          const v3 = split?.[2];	//추가개월수
          const v4 = split?.[3];	//상품코드
          const v5 = split?.[4];	//B2B여부


          if (v1 == "no_gift") {
            alert("일치하는 쿠폰번호가 없습니다.");
            return;
          }
          if (v1 == "not_pub") {
            alert("발행되지 않은 쿠폰번호입니다");
            return;
          }
          if (v1 == "used") {
            alert("이미 사용된 쿠폰번호입니다");
            return;
          }
          if (v1 == "no_match") {
            alert("구매회원과 등록회원이 일치하지 않습니다");
            return;
          }
          if (v1 == "returned") {
            alert("환불된 회원권입니다");
            return;
          }
          if (v1 == "not_brand") {
            alert("상위브랜드는 이용하실수 없습니다.");
            return;
          }

          if (v1 === "ok") {
            if (v5 === "Y") {
              //b2b 인경우 해당 지점의 계좌를 표시한다
              $("#b2b_YN").val("Y");

              //계좌리스트를 펼친다
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              //form1.g4.readOnly = true;
              $('#g_cont').html(v2);
              $('#start_set').css('display', "table-row");
              $('#auth_set').css('display', "table-row");
              $('#btn_set').css('display', "block");
              $('#add_month').val(v3);
              $('#select_item_id').val(v4);

              $("#pay_method").show();
            } else {
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              //form1.g4.readOnly = true;
              $('#g_cont').html(v2);
              $('#start_set').css('display', "table-row");
              $('#auth_set').css('display', "table-row");
              $('#btn_set').css('display', "block");
              $('#add_month').val(v3);
              $('#select_item_id').val(v4);
              $("#b2b_YN").val("N");

              $("#pay_method").hide();
            }
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function goSubmit() {

    //b2b 일경우 지불수단 필수 입력
    if ($("#b2b_YN").val() == "Y") {
      if (!$("#payCard").val()) {
        alert('지불수단을 선택해주세요');
        return;
      }
    }

    if ($('#radio1').prop('checked') == false && $('#radio2').prop('checked') == false && $('#radio3').prop('checked') == false) {
      alert('시작일을 설정해주세요');
      return;
    }

    if ($('#or_auth_code').val() == "") {
      alert('인증번호를 입력해주세요');
      return;
    }

    if (window.confirm('이용권을 등록하시겠습니까?')) {

      console.log({
        select_item_id: $('#select_item_id').val(),
        auth_code: $('#auth_code').val(),
        mb_no: $('#modal_mb_no').val(),
        it_start_date: $('#it_start_date').val(),
        it_end_date: $('#it_end_date').val() || '',
        ap_step: $('#ap_step').val(),
        g1: $('#g1').val(),
        g2: $('#g2').val(),
        g3: $('#g3').val(),
        b2b_YN: $('#b2b_YN').val(),
        add_month: $('#add_month').val(),
        period_day: $('#period_day').val(),
      })

      axios2.postEx("/member/gift/use/ocare", {
        select_item_id: $('#select_item_id').val(),
        auth_code: $('#or_auth_code').val(),
        mb_no: $('#modal_mb_no').val(),
        it_start_date: $('#it_start_date').val(),
        it_end_date: $('#it_end_date').val() || '',
        ap_step: $('#ap_step').val(),
        g1: $('#g1').val(),
        g2: $('#g2').val(),
        g3: $('#g3').val(),
        b2b_YN: $('#b2b_YN').val(),
        add_month: $('#add_month').val(),
        period_day: $('#period_day').val(),
      })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          console.log(data, result);

          if (data == "no_gift") {
            alert("일치하는 쿠폰번호가 없습니다.");
          } else if (data == "not_pub") {
            alert("발행되지 않은 쿠폰번호입니다");
          } else if (data == "used") {
            alert("이미 사용된 쿠폰번호입니다");
          } else if (data == "no_match") {
            alert("구매회원과 등록회원이 일치하지 않습니다");
          } else if (data == "returned") {
            alert("환불된 회원권입니다");
          } else if (data == "not_brand") {
            alert("상위브랜드는 이용하실수 없습니다.");
          } else if (data == "ok") {
            alert('등록되었습니다');
            setRenew(Fn.getRenewTime());
            setModalOpen(false);
          } else {
            alert("오케어 통신오류 입니다" + data + " 다시 시도해주세요");
          }
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        })
        .finally(() => {
          // $(".loading_bg").fadeOut("fast");
        });
    }
  }

  function it_period_chk(chk) //chk 값은 설정 선택할때 값
  {
    const period_kind = $(':radio[name="period_kind"]:checked').val();

    if (period_kind == 1) {
      $('#start_date_show').css('display', "none");
    } else {
      $('#start_date_show').css('display', "flex");
    }

    var period_month = $("#add_month").val();
    var period_day = 0;
    var it_start_date = $("#it_start_date").val();

    var mb_no = $('#modal_mb_no').val();


    var select_item_id = $('#select_item_id').val();
    if (select_item_id) {
      select_item_id = select_item_id;
    } else {
      select_item_id = "";
    }

    console.log("/member/it/period/chk?period_kind=" + period_kind + "&period_month=" + period_month + "&period_day=" + period_day + "&mb_no=" + mb_no + "&chk=" + chk + "&it_start_date=" + it_start_date + "&select_item_id=" + select_item_id);
    axios2
      .post("/member/it/period/chk?period_kind=" + period_kind + "&period_month=" + period_month + "&period_day=" + period_day + "&mb_no=" + mb_no + "&chk=" + chk + "&it_start_date=" + it_start_date + "&select_item_id=" + select_item_id)
      .then((response) => {
        const {code, message: data, result, pageInfo} = response?.data;
        if (code === 200) {
          if (data == "err") {
            alert('회원정보를 확인할수 없습니다.');
            return;
          }

          var data2 = data.split("||");
          console.log(data2);

          if (data2[0] > 0 && chk == 3) {
            if (window.confirm("해당회원은 회원권기간이 남아있습니다.\n재등록으로 기간설정을 하시겠습니까?")) {
              $("#it_start_date").val(data2[1])
              $("#it_end_date").val(data2[2])
              $("#it_end_date_show").html(data2[2])
            }
          } else {
            $("#it_start_date").val(data2[1])
            $("#it_end_date").val(data2[2])
            $("#it_end_date_show").html(data2[2])
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX" style={{maxWidth: "1700px"}}>
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="pr-1 d-flex">
            <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>
              가입일 :
            </label>
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date2"/>
            </div>
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control width_shorts"
              aria-describedby="button-addon2"
              placeholder="이름 검색"
              id="s_mb_name"
            />
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="연락처 검색"
              id="s_mb_hp"
            />
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" id="s_sex">
              <option value="">성별</option>
              <option value="M">남성</option>
              <option value="F">여성</option>
            </select>
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="스포애니 아이디 검색"
              id="s_member_id"
            />
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>


        <div className="m-3 pb-5 table_list">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border table_wide_sh2">
            <colgroup>
              <col width="80px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="220px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>No</th>
              <th>카드번호</th>
              <th>지점명</th>
              <th>이름</th>
              <th>회원아이디</th>
              <th>연락처</th>
              <th>성별</th>
              <th>연령</th>
              <th>가입일</th>
              <th>시작일</th>
              <th>종료일</th>
              <th>쿠폰등록</th>
            </tr>
            {rets && rets.map((ret, i) => {
              const {
                mb_no,
                mb_id,
                mb_name,
                mb_hp,
                mb_sex,
                mb_birth,
                mb_open_date,
                start_date,
                end_date,
                locker,
                locker_name,
                suit,
                suit_start_date,
                suit_end_date,
                locker_start_date,
                locker_end_date,
                opt1,
                opt2,
                opt3,
                opt4,
                opt5,
                opt6,
                opt7,
                opt8,
                opt9,
                opt10,
                g_locker,
                ot_cnt,
                buy_month,
                member_grade_name,
                member_id,
                branch_code,
              } = ret;

              const m_birth_str = mb_birth.slice(0, 2);
              let birth_show = '';

              if (m_birth_str === '19') {
                birth_show = 100;
              } else {
                const m_real_b = m_birth_str < 30 ? `20${m_birth_str}` : `19${m_birth_str}`;
                birth_show = new Date().getFullYear() - parseInt(m_real_b) + 1;
              }

              let locker_period = '';
              let locker_show = '';

              if (locker === '1') {
                locker_period = `${locker_start_date} ~ ${locker_end_date}`;
                locker_show = (
                  <a style={{cursor: "pointer"}} onClick={() => alert(locker_period)}>
                    <font color="blue">{locker_name}</font>
                  </a>
                );
              } else if (locker === '0') {
                locker_show = '미사용';
              } else if (locker === '2') {
                locker_show = '예약';
              } else if (locker === '3') {
                locker_show = '미지급';
              } else {
                locker_show = '미사용';
              }

              let suit_period = '';
              let suit_show = '';

              if (suit === '1') {
                suit_period = `${suit_start_date} ~ ${suit_end_date}`;
                suit_show = (
                  <a style={{cursor: "pointer"}} onClick={() => alert(suit_period)}>
                    <font color="blue">사용</font>
                  </a>
                );
              } else {
                suit_show = '미사용';
              }

              // const base_mb_no = btoa(mb_no);
              // const mb_hp_show = hp_show(mb_hp, total_record);
              // const base_hp = btoa(mb_hp_show);
              // const tot_base_hp = `${count},${base_hp}|`;

              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>{mb_id}</td>
                  <td>{ret?.branchName}</td>
                  <td>
                    <strong>{mb_name}</strong>
                  </td>
                  <td>{member_id}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{mb_sex === 'M' ? '남' : mb_sex === 'F' ? '여' : ''}</td>
                  <td>{birth_show > 0 && birth_show}</td>
                  <td>{mb_open_date && mb_open_date.slice(0, 10)}</td>
                  <td>{start_date}</td>
                  <td>{end_date}</td>
                  <td>
                    {branch_code && branch_code != localStorage.getItem("USER.systemBranchCode") ? (
                      <button type="button" className="btn btn-ssm btn-outline-dark px-2" onClick={() => alert('주센터 변경후 등록가능합니다')}>
                        쿠폰등록
                      </button>
                    ) : (
                      <button type="button" className="btn btn-ssm btn-outline-dark px-2"
                              onClick={() => {
                                $('#modal_mb_no').val(mb_no);
                                setModalOpen(true);
                              }}
                      >
                        쿠폰등록
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={12}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="m-3 p-3 mb-4 d-flex justify-content-between border-top" style={{maxWidth: "1700px"}}>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>
            {pageInfo?.totalArticles > 0 && (
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('carereg')}>연락처 확인</button>
              )
            )}
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '760px'}}>
            <div className="POP_title">
              <h5>
                회원정보 > <strong>오케어 쿠폰 등록</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div>
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <td colSpan="5" className="text-left">
                      <span className="h7 bold700 px-2">회원정보</span>
                    </td>
                  </tr>
                  <tr>
                    <th>회원번호</th>
                    <th>회원명</th>
                    <th>연락처</th>
                    <th>이용기간</th>
                    <th>운동복</th>
                  </tr>
                  <tr>
                    <td className="text-center">{modalResult?.mbInfo?.mb_id}</td>
                    <td className="text-center">{modalResult?.mbInfo?.mb_name}</td>
                    <td className="text-center">{modalResult?.mbInfo?.mb_hp}</td>
                    <td className="text-center">
                      {modalResult?.mbInfo?.start_date?.replaceAll('-', '.').substring(2)} ~ <br/>
                      {modalResult?.mbInfo?.end_date?.replaceAll('-', '.').substring(2)}
                    </td>
                    <td className="text-center">
                      {modalResult?.mbInfo?.suit_start_date?.replaceAll('-', '.').substring(2)} ~ <br/>
                      {modalResult?.mbInfo?.suit_end_date?.replaceAll('-', '.').substring(2)}
                    </td>
                  </tr>
                </table>
              </div>

              <div className="mt-3">
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="130px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <td colSpan="2" className="text-left">
                      <span className="h7 bold700 px-2">쿠폰 정보</span>
                    </td>
                  </tr>
                  <tr>
                    <th>쿠폰 등록</th>
                    <td>
                      <div className="input-group mt-1" style={{width: 'auto'}}>
                        <input type="text" className="form-control" aria-describedby="button-addon2" id="g1" maxLength={4}/>
                        <p className="mx-2">-</p>
                        <input type="text" className="form-control" aria-describedby="button-addon2" id="g2" maxLength={4}/>
                        <p className="mx-2">-</p>
                        <input type="text" className="form-control" aria-describedby="button-addon2" id="g3" maxLength={4}/>
                        <button type="button" className="btn btn-sm btn-outline-dark px-3 mx-1" onClick={() => gift_chk()}>
                          확인
                        </button>
                        <button type="button" className="btn btn-sm btn-point px-3" id="btn_set" style={{display: 'none'}} onClick={() => goSubmit()}>
                          사용
                        </button>
                      </div>
                      <p>위 입력란에 쿠폰의 번호를 입력하세요.</p>
                    </td>
                  </tr>


                  <tr id="pay_method" style={{display: "none"}}>
                    <th>지불수단</th>
                    <td className="txt_left">
                      <select id="payCard" className="form-control wid80" style={{width: '120px', display: 'inline-block'}}>
                        <option value=''>지불수단 선택</option>
                        <option value='1111'>현금</option>
                        <option value='2222'>카드</option>
                        {/*TODO*/}
                      </select>
                    </td>
                  </tr>

                  <tr id="start_set" style={{display: "none"}}>
                    {/*TODO : it_period_chk 연동*/}
                    <th>시작일 설정</th>
                    <td className="txt_left">
                      {/*<div className="form-check form-check-inline">*/}
                      {/*  <input className="form-check-input" type="radio" name="period_kind" id="radio1" value="1" onClick={(e) => it_period_chk(e.target.value)}/>*/}
                      {/*  <label className="form-check-label" htmlFor="radio1">*/}
                      {/*    미정*/}
                      {/*  </label>*/}
                      {/*</div>*/}
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="period_kind" id="radio2" value="2" onClick={(e) => it_period_chk(e.target.value)}/>
                        <label className="form-check-label" htmlFor="radio2">
                          재등록
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="period_kind" id="radio2" value="3" onClick={(e) => it_period_chk(e.target.value)}/>
                        <label className="form-check-label" htmlFor="radio3">
                          신규
                        </label>
                      </div>
                      <div style={{clear: "both"}}/>
                      <div id="start_date_show" style={{display: "none", marginTop: "10px"}}>
                        <JDatePicker className="form-control" aria-describedby="button-addon2" id="it_start_date" placeholder={"시작일"} onChange={(e) => it_period_chk()}/>
                        <JDatePicker className="form-control" aria-describedby="button-addon2" id="it_end_date" placeholder={"종료일"} style={{marginLeft: '10px'}} readOnly={true}/>
                        {/*시작일 : <input type="text" className="form-control wid100 input_date" placeholder="시작일" id="it_start_date"/>*/}
                        {/*&nbsp;&nbsp;*/}
                        {/*종료일 : <strong><span id="it_end_date_show" style={{paddingRight: '10px'}}></span></strong><input type="text" id="it_end_date" style={{display: 'none'}}/>*/}
                      </div>
                    </td>
                  </tr>

                  <tr id="auth_set" style={{display: "none"}}>
                    <th>인증번호</th>
                    <td className="txt_left">
                      <div className="d-flex">
                        <div className="d-flex mx-1">
                          <ChkAuthNum type={"locker_info_change"} prefix="or_"/>
                        </div>
                      </div>
                    </td>
                  </tr>

                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="modal_mb_no"/>
      <input type="hidden" id="mb_no"/>
      <input type="hidden" id="add_month"/>
      <input type="hidden" id="select_item_id"/>
      <input type="hidden" id="b2b_YN"/>

    </CmnCard>
  );
};
export default MemberOcareReg;
