import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import JDatePicker from "../common/JDatePicker";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Modal from "react-modal";
import Fn from "../../libraries/Fn";

const ConfigItemReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.baseSeq || '';
  const copySeq = location?.state?.copySeq || '';

  const [modal1Open, setModal1Open] = useState(false);
  const [result, setResult] = useState({});
  const [result1, setResult1] = useState({});

  useEffect(() => {
    show_basic_display();
    load();
  }, []);

  const load = () => {
    $('#base_seq').val(baseSeq || '');
    $('#copy_seq').val(copySeq || '');

    if (!baseSeq && !copySeq) {
      $('#able_start_hour').val('00');
      $('#able_start_minute').val('00');
      $('#able_end_hour').val('23');
      $('#able_end_minute').val('55');
      $('#it_use').prop("checked", true);
      $('#day1').prop("checked", true);
      $('#day2').prop("checked", true);
      $('#day3').prop("checked", true);
      $('#day4').prop("checked", true);
      $('#day5').prop("checked", true);
      $('#day6').prop("checked", true);
      $('#day7').prop("checked", true);
      $('#it_order').val(100);
    }

    axios2
      .post(`/branchop/item/reg/ready?base_seq=${baseSeq}&copy_seq=${copySeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          if ($('#brand').html() === '') {
            let html = '<option value="">::브랜드::</option>';
            result?.bl?.forEach((list_row) => {
              html += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#brand').html(html);
          }
          if ($('#it_acc').html() === '') {
            let html = '<option value="">::계정_적요선택</option>';
            result?.list?.forEach((list_row) => {
              html += `<option value="${list_row.j_no}">${list_row.j_jukname}</option>`;
            });
            $('#it_acc').html(html);
          }

          if (baseSeq || copySeq) {
            $('#brand').val(result?.brand || '');
            if (baseSeq !== '') {
              $('#it_id').val(result?.it_id || '');
            }
            $('#it_name').val(result?.it_name || '');
            $('#ca_id').val(result?.ca_id || '');
            $('#basic_period').val(result?.basic_period || '');
            $('#it_acc').val(result?.it_acc || '');
            $('#it_amount').val(Fn.numberWithCommas(result?.it_amount) || '');
            $('#it_period').val(result?.it_period || '');
            $('#it_period_type').val(result?.it_period_type || '');
            $('#day1').prop("checked", result?.day[0] === 'Y');
            $('#day2').prop("checked", result?.day[1] === 'Y');
            $('#day3').prop("checked", result?.day[2] === 'Y');
            $('#day4').prop("checked", result?.day[3] === 'Y');
            $('#day5').prop("checked", result?.day[4] === 'Y');
            $('#day6').prop("checked", result?.day[5] === 'Y');
            $('#day7').prop("checked", result?.day[6] === 'Y');
            $('#able_start_hour').val(result?.able_start_hour || '');
            $('#able_start_minute').val(result?.able_start_minute || '');
            $('#able_end_hour').val(result?.able_end_hour || '');
            $('#able_end_minute').val(result?.able_end_minute || '');
            $('#it_suit').prop("checked", result?.it_suit == '1');
            $('#it_locker').prop("checked", result?.it_locker == '1');
            $('#it_day').prop("checked", result?.it_day == '1');
            $('#menu_YN').prop("checked", result?.menu_YN == 'Y');
            //$('#insen').html(result?.insen_price); // TODO
            $('#it_use').prop("checked", result?.it_use == '1');
            $('#it_use_h').prop("checked", result?.it_use_h == '1');
            $('#it_order').val(result?.it_order || '');
            $('#menu_start_day').val(result?.menu_start_day || '');
            $('#menu_end_day').val(result?.menu_end_day || '');
            $('#all_show').prop("checked", result?.all_show_chk == 'checked');
            $('#step_permit').val(result?.step_permit || '');
            $('#free_only').prop("checked", result?.free_only == 'Y');
            $('#no_delay').prop("checked", result?.no_delay == 'Y');
            $('#branch_list').val(result?.branch_list || '');

            show_basic_display();

            $('#incen_price').val(result?.incen_price || '');
            if (result?.night_YN == "Y") $('#night_YN').prop("checked", true);
            else $('#night_YN').prop("checked", false);

            setResult(result);
            console.log(result);
            console.log('result?.branch_list', result?.bl);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit() {
    const brand = $('input[id="brand"]').val();
    const itId = $('input[id="it_id"]').val();
    const itName = $('input[id="it_name"]').val();
    const caId = $('input[id="ca_id"]').val();
    const basicPeriod = $('input[id="basic_period"]').val();
    const itAcc = $('input[id="it_acc"]').val();
    const menuStartDay = $('input[id="menu_start_day"]').val();
    const menuEndDay = $('input[id="menu_end_day"]').val();

    if (brand === '') {
      alert('브랜드를 선택해주세요');
      $('input[id="brand"]').focus();
      return;
    }

    if (itId === '') {
      alert('상품코드를 입력해주세요');
      $('input[id="it_id"]').focus();
      return;
    }

    if (itName === '') {
      alert('상품명을 입력해주세요');
      $('input[id="it_name"]').focus();
      return;
    }

    if (caId === '') {
      alert('카테고리를 선택해주세요');
      $('input[id="ca_id"]').focus();
      return;
    }

    if (caId === '10' && basicPeriod === '') {
      alert('기준기간을 선택해주세요');
      $('input[id="basic_period"]').focus();
      return;
    }

    if (itAcc === '') {
      alert('계정/적요를 선택해주세요');
      $('input[id="it_acc"]').focus();
      return;
    }

    if (menuStartDay === '') {
      alert('메뉴노출기간 시작일을 입력해주세요');
      $('input[id="menu_start_day"]').focus();
      return;
    }

    if (menuEndDay === '') {
      alert('메뉴노출기간 종료일을 입력해주세요');
      $('input[id="menu_end_day"]').focus();
      return;
    }

    if (menuStartDay > menuEndDay) {
      alert('메뉴노출기간 설정이 올바르지 않습니다');
      return;
    }

    if (caId === '10') {
      if (basicPeriod === '') {
        alert('카테고리가 기본일 경우 기준기간은 필수입력입니다.');
        $('input[id="basic_period"]').focus();
        return;
      }

      let itAmount = 0;
      if (itAmount === '' || itAmount === '0') {
        //alert('카테고리가 기본일 경우 금액은 필수입력입니다.');
        //$('input[id="it_amount"]').focus();
        //return;
      }

      let itPeriodType = '';
      if (itPeriodType !== 'month') {
        alert('카테고리가 기본일 경우 이용기간타입은 Month 입니다.');
        $('input[id="it_period"]').focus();
        return;
      }
    }

    const formData = new FormData();

    formData.append("base_seq", $('#base_seq').val() || '');
    formData.append("brand", $('#brand').val() || '');
    formData.append("it_id", $('#it_id').val() || '');
    formData.append("it_name", $('#it_name').val() || '');
    formData.append("ca_id", $('#ca_id').val() || '');
    formData.append("basic_period", $('#basic_period').val() || '');
    formData.append("it_acc", $('#it_acc').val() || '');
    formData.append("it_amount", Fn.cfNumeric($('#it_amount').val()) || '');
    formData.append("it_period", $('#it_period').val() || '');
    formData.append("it_period_type", $('#it_period_type option:selected').val() || '');
    formData.append("day1", $('#day1:checked').val() || '');
    formData.append("day2", $('#day1:checked').val() || '');
    formData.append("day3", $('#day1:checked').val() || '');
    formData.append("day4", $('#day1:checked').val() || '');
    formData.append("day5", $('#day1:checked').val() || '');
    formData.append("day6", $('#day1:checked').val() || '');
    formData.append("day7", $('#day1:checked').val() || '');
    formData.append("able_start_hour", $('#able_start_hour').val() || '');
    formData.append("able_start_minute", $('#able_start_minute').val() || '');
    formData.append("able_end_hour", $('#able_end_hour').val() || '');
    formData.append("able_end_minute", $('#able_end_minute').val() || '');
    formData.append("it_suit", $('#it_suit:checked').val() || '');
    formData.append("it_locker", $('#it_locker:checked').val() || '');
    formData.append("it_day", $('#it_day:checked').val() || '');
    formData.append("menu_YN", $('#menu_YN:checked').val() || '');
    formData.append("insen", $('#insen').html() || ''); // TODO
    formData.append("it_use", $('#it_use:checked').val() || '');
    formData.append("it_use_h", $('#it_use_h:checked').val() || '');
    formData.append("it_order", $('#it_order').val() || '');
    formData.append("menu_start_day", $('#menu_start_day').val() || '');
    formData.append("menu_end_day", $('#menu_end_day').val() || '');
    formData.append("all_show", $('#all_show:checked').val() || '');
    formData.append("step_permit", $('#step_permit').val() || '');
    formData.append("free_only", $('#free_only:checked').val() || '');
    formData.append("no_delay", $('#no_delay:checked').val() || '');
    formData.append("branch_list", $('#branch_list').val() || '');
    formData.append("incen_price", $('#incen_price').val() || '');
    formData.append("night_YN", $('#night_YN:checked').val() || '');

    axios2.postFormEx(`/branchop/item/reg`, formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();

        if (data === 'err') {
          alert('오류가 발생하였습니다');
        } else if (data === 'dup_id') {
          alert('동일한 상품코드가 존재합니다');
        } else if (data === 'insert_err') {
          alert('등록오류! 관리자에게 문의하세요.');
        } else if (data === 'up_err') {
          alert('수정오류! 관리자에게 문의하세요.');
          history.push("/config/item/list");
        } else if (data === 'insert_ok') {
          alert('등록되었습니다');
          history.push("/config/item/list");
        } else if (data === 'update_ok') {
          alert('수정되었습니다');
          history.push({pathname: '/config/item/list', state: {usePrevUrl: true}});
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  3-1.
  function go_submit2() {
    var form = $('form')[0];
    var formData = new FormData(form);
    $.ajax({
      url: 'item_reg.php',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data.trim();
        document.write(data);
        return;
      }
    });
  }

  //  4-1.
  const form1 = useRef(null); // TODO : FORM 참조
  const chk_it_id = () => {
    var itId = $('#it_id').val();

    if (itId) {
      //해당지점 리스트만 표시
      $.ajax({
        type: "post",
        url: "chk_it_id.php",
        data: "it_id=" + itId,
        success: function (data) {
          //쿼리된 지점 표시
          data = data.trim();
          if (data === "dup") {
            alert('동일한 상품코드가 존재합니다');
            form1.it_id.value = "";
            return;
          }
        }
      });
    }
  };

  const loadModal1 = () => {
    axios2
      .post(`/branchop/branch/select/ready?branch_list=${encodeURIComponent($('#branch_list').val() || '')}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult1(result);

          setTimeout(() => {
            result?.list?.forEach((list_row) => {
              const branchList = $('#branch_list').val().split("|");
              if (branchList?.filter(r => r === list_row?.branch_code)?.length) {
                $('#m1chk_' + list_row?.branch_code).prop("checked", true);
              }
            });
          }, 200);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const submitModal1 = () => {
    let con = $('[id^=m1chk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    $('#branch_list').val(con);

    setModal1Open(false);
  }

  //  5-1.
  const show_basic_display = () => {
    const form1 = document.getElementById('form1');

    if (form1.ca_id.value === "10") {
      $('#insen').html("<input type='text' class='form-control wid150' placeholder='' name='incen_price' id='incen_price' value='0' style='text-align:right;padding-right:5px;ime-mode:disabled' onfocus='this.select()' onKeyPress='return numkeyCheck(event)' onkeyup='vComma(this);' maxlength=10/>  심야만 <input type='checkbox' name='night_YN' id='night_YN' value='Y'  class='checkbox' id='night_YN'><label for='night_YN'><span></span></label>");
      form1.basic_period.disabled = false;
    } else {
      $('#insen').html("인센티브 없음");
      form1.basic_period.value = "";
      form1.basic_period.disabled = true;
    }

    if (form1.ca_id.value === "30") {
      $("#addition").css("display", "");
    } else {
      $("#addition").css("display", "none");
    }
  };

  //  6-1.
  $(document).ready(() => {
    $("input[name=it_id]").keyup((event) => {
      if (!(event.keyCode >= 37 && event.keyCode <= 40)) {
        const inputVal = $(event.target).val();
        $(event.target).val(inputVal.replace(/[^a-z0-9]/gi, ''));
      }
    });
  });

  //  7-1.
  const popUp = (file, names, width, height, sc) => {
    const w = width;
    const h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h},scrollbars=${sc},top=${wint},left=${winl}`);
  };

  const select_branch = () => {
    const branchList = form1.branch_list.value;
    popUp(`branch_select.html?branch_list=${branchList}`, 'select_branch', '500', '600', 'yes');
  };

  //  8-1.
  const select_branch_new = () => {
    // post 팝업
    popUp(`branch_select.html`, 'product_chk_C2', '500', '600', 'yes');
    document.form1.action = 'branch_select.html'; // 팝업 URL을 지정해두고
    document.form1.method = 'post'; // 메서드방식 지정
    document.form1.target = 'product_chk_C2'; // 옵션
    document.form1.submit(); // 폼에 있는 데이터를 POST방식을 통해 지정한 URL로 보낸다.
  };

  //  9-1.
  const all_show_selected = (val) => {
    if (val) {
      $('#menu_start_day').val('2008-01-01');
      $('#menu_end_day').val('2100-01-01');
    } else {
      if ($('#menu_start_day').val() === '2008-01-01' && $('#menu_end_day').val() === '2100-01-01') {
        $('#menu_start_day').val(result?.menu_start_day || '');
        $('#menu_end_day').val('2020-12-31');
      } else {
        $('#menu_start_day').val(result?.menu_start_day || '');
        $('#menu_end_day').val(result?.menu_end_day || '2020-12-31');
      }
    }
  };

  //  10-1.
  const item_brand_price = () => {
    popUp(`item_brand_price.html?it_id=&it_name=`, 'item_brand_price', '400', '400', 'yes');
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <form name="form1" method="post" id="form1">
        <div className="m-3 mb-4 search_box">
          <div className="border-bottom text-left">
            <p className="px-3 py-2"><span className="h6 bold700">지점 기본정보</span></p>
          </div>
          <div className="p-3">
            <table className="view-rounded sub_view_sm">
              <colgroup>
                <col width="135px"/>
                <col width="*"/>
                <col width="135px"/>
                <col width="*"/>
                <col width="135px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>브랜드</th>
                <td className="text-left">
                  <select className="form-select" aria-label="Default select example" style={{maxWidth: '32%'}} id="brand">
                  </select>
                </td>
                <th>상품코드</th>
                <td>
                  {/*
                <? if ($base_seq) { ?>
                    <strong><?= $it_id ?></strong>
                    <input type="hidden" name="it_id" value="<?= $it_id ?>">
                <? } else { ?>
                    <input type="it_id" class="form-control wid200" placeholder="상품코드 입력" name="it_id" id="it_id" maxlength=10 style="ime-mode:disabled;" onblur="chk_it_id()"/>
                <? } ?>
              */}
                  <input type="text" className="form-control table-inline cls-type-nokr" placeholder="" id="it_id" maxLength={10}/>
                </td>
                <th>상품명</th>
                <td><input type="text" className="form-control table-inline" placeholder="" id="it_name"/></td>
              </tr>
              <tr>
                <th>카테고리</th>
                <td>
                  <div className="d-flex">
                    <select className="form-select-sm table-inline mr-1" style={{maxWidth: "32%"}} id="ca_id" onChange={() => show_basic_display()}>
                      <option value="">카테고리</option>
                      <option value="10">기본</option>
                      <option value="20">부가</option>
                      <option value="30">무상</option>
                    </select>
                    <select className="form-select-sm table-inline" style={{maxWidth: "32%"}} id="basic_period">
                      <option value="">기준기간</option>
                      <option value="opt0">헬스</option>
                      <option value="opt1">골프</option>
                      <option value="opt2">골프라커</option>
                      <option value="opt3">스쿼시</option>
                      <option value="opt4">스피닝</option>
                      <option value="opt5">태닝</option>
                      <option value="opt6">토탈</option>
                      <option value="opt7">필라테스</option>
                      <option value="opt8">Opt5</option>
                      <option value="opt9">Opt6</option>
                      <option value="opt10">Opt7</option>
                    </select>
                  </div>
                </td>
                <th>계정적요</th>
                <td className="text-left">
                  <select className="form-select" style={{maxWidth: "32%"}} id="it_acc">
                  </select>
                </td>
                <th>상품가격</th>
                <td>
                  {/*
                <? if ($it_id == "1341129358" || $it_id == "1341129345" || $it_id == "10") { ?>
                    <input type="text" class="form-control wid150" placeholder="" name="it_amount" style="text-align:right;padding-right:5px;ime-mode:disabled" onfocus="this.select()"
                           onKeyPress="return numkeyCheck(event)" onkeyup="vComma(this);" maxlength=12 readonly/>
                    <input class="btn btn-sm btn-primary" type="button" value="브랜드별 가격설정" onclick="item_brand_price()">
                <? } else { ?>
                    <input type="text" class="form-control wid200" placeholder="" name="it_amount" <? if ($it_amount) { ?>value="<?= number_format($it_amount) ?>" <? } ?>
                           style="text-align:right;padding-right:5px;ime-mode:disabled" onfocus="this.select()" onKeyPress="return numkeyCheck(event)" onkeyup="vComma(this);"
                           maxlength=12/>
                <? } ?>
              */}
                  <input type="text" className="form-control cls-type-fmtnum-ex" aria-describedby="button-addon2" id="it_amount"/>
                </td>
              </tr>
              <tr>
                <th>이용기간</th>
                <td>
                  <div className="d-flex">
                    <input type="text" className="form-control table-inline mr-1" id="it_period"/>
                    <select className="form-select-sm table-inline" style={{maxWidth: "32%"}} id="it_period_type">
                      <option value="">--</option>
                      <option value="month">Month</option>
                      <option value="day">Day</option>
                    </select>
                  </div>
                </td>
                <th>이용가능일</th>
                <td className="text-left">
                  <div className="form-check form-check-inline mx-1">
                    <input className="form-check-input2" type="checkbox" value="Y" id="day1"/>
                    <label className="form-check-label mx-1" htmlFor="day1">월</label>
                    <input className="form-check-input2" type="checkbox" value="Y" id="day2"/>
                    <label className="form-check-label mx-1" htmlFor="day2">화</label>
                    <input className="form-check-input2" type="checkbox" value="Y" id="day3"/>
                    <label className="form-check-label mx-1" htmlFor="day3">수</label>
                    <input className="form-check-input2" type="checkbox" value="Y" id="day4"/>
                    <label className="form-check-label mx-1" htmlFor="day4">목</label>
                    <input className="form-check-input2" type="checkbox" value="Y" id="day5"/>
                    <label className="form-check-label mx-1" htmlFor="day5">금</label>
                    <input className="form-check-input2" type="checkbox" value="Y" id="day6"/>
                    <label className="form-check-label mx-1" htmlFor="day6">토</label>
                    <input className="form-check-input2" type="checkbox" value="Y" id="day7"/>
                    <label className="form-check-label mx-1" htmlFor="day7">일</label>
                  </div>
                </td>
                <th>이용가능시간</th>
                <td>
                  <div className="d-flex flex-wrap">
                    <div className="d-flex">
                      <select className="form-select-sm table-inline" style={{maxWidth: "32%"}} id="able_start_hour">
                        {Array.from({length: 24}).map((_, i) => {
                          const j = i < 10 ? `0${i}` : i;
                          return (
                            <option value={j}>{j}시</option>
                          );
                        })}
                      </select>
                      <p className="p-1">:</p>
                      <select className="form-select-sm table-inline" style={{maxWidth: "32%"}} id="able_start_minute">
                        {Array.from({length: 12}).map((_, i) => {
                          const j = i * 5 < 10 ? `0${i * 5}` : i * 5;
                          return (
                            <option value={j}>{j}분</option>
                          );
                        })}
                      </select>
                    </div>
                    <p className="px-1">~</p>
                    <div className="d-flex">
                      <select className="form-select-sm table-inline" style={{maxWidth: "32%"}} id="able_end_hour">
                        {Array.from({length: 24}).map((_, i) => {
                          const j = i < 10 ? `0${i}` : i;
                          return (
                            <option value={j}>{j}시</option>
                          );
                        })}
                      </select>
                      <p className="p-1">:</p>
                      <select className="form-select-sm table-inline" style={{maxWidth: "32%"}} id="able_end_minute">
                        {Array.from({length: 12}).map((_, i) => {
                          const j = i * 5 < 10 ? `0${i * 5}` : i * 5;
                          return (
                            <option value={j}>{j}분</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>운동복</th>
                <td><input type="checkbox" className="form-control" id="it_suit" value="1"/></td>
                <th>락커</th>
                <td><input type="checkbox" className="form-control" id="it_locker" value="1"/></td>
                <th>1일2회</th>
                <td><input type="checkbox" className="form-control" id="it_day" value="1"/></td>
              </tr>
              <tr>
                <th>사용지점</th>
                <td className="text-left">
                  <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => setModal1Open(true)}>지점설정</button>
                  <input type="hidden" id="branch_list"/>
                </td>
                <th>메뉴판</th>
                <td><input type="checkbox" className="form-control" id="menu_YN" value="Y"/></td>
                <th>장기등록인센티브</th>
                <td className="text-left"><span id="insen"></span></td>
              </tr>
              <tr>
                <th>사용여부</th>
                <td className="text-left">
                  <div className="form-check form-check-inline mx-0">
                    <label className="form-check-label mx-1" htmlFor="it_use">
                      SMMS :
                    </label>
                    <input className="form-check-input2" type="checkbox" id="it_use" value="1"/>
                  </div>
                  <div className="form-check form-check-inline mx-0">
                    <label className="form-check-label mx-1" htmlFor="it_use_h">
                      Homepage :
                    </label>
                    <input className="form-check-input2" type="checkbox" id="it_use_h" value="1"/>
                  </div>
                </td>
                <th>전시순서</th>
                <td><input type="text" className="form-control" aria-describedby="button-addon2" id="it_order" style={{maxWidth: "32%"}}/></td>
                <th>연기신청불가</th>
                <td>
                  <input type="checkbox" className="form-control" id="free_only" value="Y" style={{display: "none"}}/>
                  <input type="checkbox" className="form-control" id="no_delay" value="Y"/>
                </td>
              </tr>
              <tr>
                <th>메뉴노출기간</th>
                <td colSpan="5">
                  <div className="d-flex">
                    <JDatePicker className="form-control table-inline" style={{maxWidth: '120px'}} id="menu_start_day"/>
                    <p className="m-1 bold700">-</p>
                    <JDatePicker className="form-control table-inline" style={{maxWidth: '120px'}} id="menu_end_day"/>
                    <div className="form-check form-check-inline mx-2">
                      <input className="form-check-input2" type="checkbox" value="Y" id="all_show" onClick={(e) => all_show_selected(e.target.checked)}/>
                      <label className="form-check-label mx-1" htmlFor="all_show">
                        상시노출
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
              <tr id='addition' style={{display: "none"}}>
                <th>부가승인</th>
                <td colSpan="5">
                  <select className="form-select" aria-label="Default select example" style={{maxWidth: '32%'}} id="step_permit">
                    <option value="N">필요없음</option>
                    <option value="7">지점장 승인</option>
                    <option value="8">본사 승인</option>
                  </select>
                </td>
              </tr>
            </table>
            <div className="mt-2">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
              <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => {
                history.push({pathname: '/config/item/list', state: {usePrevUrl: true}});
              }}>목록
              </button>
            </div>
          </div>
        </div>
      </form>

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal1();
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 520}}>
            <div className="POP_title">
              <h5><strong>지점설정</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              {/* 회원목록 */}
              <table className="view-rounded sub_table_border text-center">
                <colgroup>
                  <col width="60px"/>
                  <col width="70px"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>
                    <input type="checkbox" onClick={(e) => {
                      if ($(e.target).prop("checked")) $('[id^=m1chk]').prop("checked", true);
                      else $('[id^=m1chk]').prop("checked", false);
                    }}/>
                  </th>
                  <th>브랜드</th>
                  <th>지점명</th>
                </tr>
                {result1?.list?.length > 0 && result1?.list?.map((ret, index) =>
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <input
                        type="checkbox"
                        name={`m1chk[${index + 1}]`}
                        value={ret?.branch_code}
                        className="checkbox"
                        id={`m1chk_${ret?.branch_code}`}
                      />
                    </td>
                    <td>{ret?.brand_name}</td>
                    <td>{ret?.branch_name}</td>
                  </tr>
                )}
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => submitModal1()}>등록</button>
              <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => setModal1Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="base_seq"/>
      <input type="hidden" id="copy_seq"/>

    </CmnCard>
  );
};

export default ConfigItemReg;
