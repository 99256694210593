import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import {useHistory} from "react-router-dom";


const SalesOrderList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/sales/order/list/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_company_seq=${$('#s_company_seq option:selected').val() || ''}&s_use_use=${$('#s_use_use option:selected').val() || ''}&s_p_name=${$('#s_p_name').val()}&s_bigo=${$('#s_bigo').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);

          // EXAMPLE
          if ($('#s_company_seq').html() === '') {
            let optionsHTML = '<option value="">공급업체</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.shop_name}</option>`;
            });
            $('#s_company_seq').html(optionsHTML);
          }

          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const delItem = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 발주내역을 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/sales/order/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="input-group pr-1" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_company_seq">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_use_use">
              <option value="">발주상태</option>
              <option value="Y">업체확인</option>
              <option value="N">미확인</option>
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="상품명" id="s_p_name"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="비고" id="s_bigo"/>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.push("/sales/order/reg")}>발주등록</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="60px"/>
              <col width="70px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="23%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>발주일</th>
              <th>입고희망일</th>
              <th>공급업체</th>
              <th>상품명</th>
              <th>품목수</th>
              <th>발주수량</th>
              <th>발주금액</th>
              <th>발주상태</th>
              <th>택배사</th>
              <th>송장번호</th>
            </tr>
            {rets && rets.map((ret, i) =>
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  <input
                    type="checkbox"
                    // name={`chk[${i + 1}]`}
                    name="chk"
                    value={ret?.order_code}
                    className="checkbox"
                    id={`achk${i + 1}`}
                  />
                </td>
                <td
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    history.push({
                      pathname: '/sales/order/reg', state: {
                        orderCode: ret?.order_code,
                      }
                    });
                  }}
                >
                  {ret?.order_date}
                </td>
                <td>{ret?.hope_date}</td>
                <td>{ret?.company_name}</td>
                <td className="text-left">{ret?.order_cnt == 1 ? ret.order_name : `${ret.order_name} 외 ${ret.order_cnt - 1}건`}</td>
                <td>{ret?.order_cnt}</td>
                <td>{ret?.order_qty}</td>
                <td className="text-right">{Fn.numberWithCommas(ret?.order_price || 0)}</td>
                <td>{ret?.order_state === 'Y' ? '업체확인' : <span style={{color: 'red'}}>미확인</span>}</td>
                <td>{ret?.delevery_company}</td>
                <td>{ret?.delivery_number}</td>
              </tr>
            )}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={12}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>

        <nav className="p-3 mb-4 d-flex justify-content-between border-top">
          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          <div>
            <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => delItem()}>삭제</button>
          </div>
        </nav>
      </div>
    </CmnCard>
  );
};

export default SalesOrderList;
