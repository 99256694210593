import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import Constants from "../../constants/constants";

const EmployeeBookMeetList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/employee/book/meet/list/ready?s_book_seq=${$('#s_book_seq option:selected').val() || ''}&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&key=${$('#key').val()}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_book_seq').html() === '') {
            let optionsHTML = '<option value="">::도서명::</option>';
            result?.bList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.book_name}</option>`;
            });
            $('#s_book_seq').html(optionsHTML);
          }
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const del_data = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 모임완료도서를 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/employee/book/meet/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === "err") {
            alert('오류가 발생하였습니다');
          } else {
            alert("삭제되었습니다");
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  function Excel_download() {
    const url = `/xls/employee/book/meet/list?s_book_seq=${$('#s_book_seq option:selected').val() || ''}&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&key=${$('#key').val()}`;
    Fn.download(Constants.API_URL + url);
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item" onClick={() => history.push("/employee/book/list")}/>
          <label className="tab_item" htmlFor="aa">도서관리</label>

          <input id="bb" type="radio" name="tab_item" onClick={() => history.push("/employee/book/end/list")}/>
          <label className="tab_item" htmlFor="bb">필독완료</label>

          <input id="cc" type="radio" name="tab_item" checked onClick={() => history.push("/employee/book/meet/list")}/>
          <label className="tab_item" htmlFor="cc">모임완료</label>
        </div>
      </div>

      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" id="s_book_seq">
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" id="s_branch_code">
          </select>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" aria-describedby="button-addon2" id="key"/>
        </div>

        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime)}>검색</button>
        </div>
      </div>

      {/* 테이블 */}

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="70px"/>
            <col width="20%"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            <th>도서명</th>
            <th>저자</th>
            <th>출판사</th>
            <th>지점명</th>
            <th>스텝명</th>
            <th>직책</th>
            <th>완료일자</th>
          </tr>

          {rets && rets.map((ret, i) => {
            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  <input
                    type="checkbox"
                    // name={`chk[${i + 1}]`}
                    name="chk"
                    value={btoa(ret?.seq)}
                    className="checkbox"
                    id={`achk${i + 1}`}
                  />
                </td>
                <td className="text-left">{ret?.bData?.book_name}</td>
                <td>{ret?.bData?.author}</td>
                <td>{ret?.bData?.publishing}</td>
                <td>{ret?.bnRow?.branch_name}</td>
                <td>{ret?.user_name}</td>
                <td>{ret?.pnRow?.sub_code_name}</td>
                <td>{ret?.end_date}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={9}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            {/*TODO : PTODO*/}
            {/*<button type="button" className="btn btn-sm btn-outline-point px-3 mx-1 cls-hide" onClick={() => Excel_download()}>엑셀 다운로드</button>*/}
            <button type="button" className="btn btn-sm btn-outline-dark px-3" onClick={() => del_data()}>삭제</button>
          </div>
        </div>
      </nav>
    </CmnCard>
  );
};

export default EmployeeBookMeetList;
