import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import JDatePicker from "../common/JDatePicker";
import Fn from "../../libraries/Fn";

const MvAppInsert = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const load = () => {
    axios2
      .post(`/member/app/insert/manualy/ready?mb_no=${props.mbNo}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);

          $('#ai_pdt_date').val(Fn.getCurrentDate());

          if ($('#ai_pdt_no').html() === '') {
            let optionsHTML = '<option value="">::서비스 선택</option>';
            result?.forEach((list_row) => {
              if ( !(Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 8 && (list_row.it_id == 'mileage' || list_row?.it_name?.includes("마일리지")) ) ) {
                optionsHTML += `<option value="${list_row.it_id}">${list_row.it_name}</option>`;
              }
            });
            $('#ai_pdt_no').html(optionsHTML);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {
    if ($('#ai_pdt_date').val() == "") {
      alert('매출일자를 선택해주세요');
      $('#ai_pdt_date').focus();
      return;
    }
    if ($('#ai_pdt_no option:selected').val() == "") {
      alert('서비스를 선택해주세요');
      $('#ai_pdt_no').focus();
      return;
    }
    if ($('#ai_pdt_qty').val() == "") {
      alert('수량을 입력해주세요');
      $('#ai_pdt_qty').focus();
      return;
    }

    //입력가격과 상품가격이 틀릴경우 비고 입력되게
    if ($('#ai_pdt_amt_original').val() != $('#ai_pdt_amt').val()) {
      if ($('#ai_pdt_etc').val() == "") {
        alert('입력금액과 상품금액이 틀린경우 서비스비고를 입력해주세요');
        $('#ai_pdt_etc').focus();
        return;
      }
    }

    axios2
      .postEx(`/member/app/insert/manualy`, {
        auth_code: $('#auth_code').val(),
        mb_no: props.mbNo,
        reg_step: $('#_reg_step option:selected').val() || '',
        pdt_date: $('#ai_pdt_date').val(),
        pdt_no: $('#ai_pdt_no option:selected').val(),
        pdt_amt: Fn.cfNumeric($('#ai_pdt_amt').val()),
        pdt_amt_original: Fn.cfNumeric($('#ai_pdt_amt_original').val()),
        pdt_qty: Fn.cfNumeric($('#ai_pdt_qty').val()),
        pdt_etc: $('#ai_pdt_etc').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        } else {
          alert('등록되었습니다');
          props?.onCallback();
          onToggleModal();
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function select_item(value) {
    axios2
      .post(`/member/item/select?it_id=${value}`)
      .then((response) => {
        const {code, message: data, result, pageInfo} = response?.data;
        if (code === 200) {
          if (data == "no1") {
            alert('운동복/라커/PT 신청은 회원권판매 기능을 이용해주세요');
            $('#ai_pdt_no').val('');
          } else if (data == "no2") {
            alert('결제수단변경/취소/환불(이체)/환불(카드취소)/환불(현금)/환불(오픈,리모델링) 의 경우 금액입력시 자동 - 처리됩니다.')
          } else {
            $('#ai_pdt_amt').val(Fn.numberWithCommas(result?.it_amount));
            $('#ai_pdt_amt_original').val(result?.it_amount);
            $('#ai_pdt_qty').val("1");

            if (data == "0") {
              $('#ai_pdt_amt')[0].select();
            } else {
              $('#ai_pdt_etc')[0].select();
            }
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();

        $(document).on("keyup", ".cls-type-fmtnum-ex", function () {
          $(this).val($(this).val().replace(/[^-?0-9,]/g, ""));
          Fn.vComma(this);
        });
        $(document).on("blur", ".cls-type-fmtnum-ex", function () {
          $(this).val($(this).val().replace(/[^-?0-9,]/g, ""));
          Fn.vComma(this);
        });
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '900px'}}>
          <div className="POP_title">
            <h5><strong>서비스 신청</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <table className="view-rounded sub_view" style={{borderRadius: 0}}>
              <colgroup>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>매출날짜</th>
                <td>
                  <JDatePicker className="form-control" id="ai_pdt_date" selectOnly={true}/>
                </td>
                <th>서비스명</th>
                <td>
                  <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="ai_pdt_no" onChange={(e) => select_item(e.target.value)}>
                  </select>
                </td>
                <th>가격</th>
                <td>
                  <input type="text" className="form-control cls-type-fmtnum-ex" aria-describedby="button-addon2" placeholder="" id="ai_pdt_amt"
                  />
                  <input type="hidden" id="ai_pdt_amt_original" name="ai_pdt_amt_original"/>
                </td>
              </tr>
              <tr>
                <th>수량</th>
                <td><input type="text" className="form-control cls-type-fmtnum-ex" aria-describedby="button-addon2" placeholder="" id="ai_pdt_qty"/></td>
                <th>서비스비고</th>
                <td colSpan="3"><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" id="ai_pdt_etc"/></td>
              </tr>
            </table>
          </div>
          <div className="text-center mb-3">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit()}>등록</button>
            <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setIsOpen(false)}>닫기</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MvAppInsert;
