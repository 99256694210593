import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import JDatePicker from "../common/JDatePicker";
import ChkAuthNum from "../common/ChkAuthNum";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";

const MvGiftUse = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const loadModal = (mb_no = props.mbNo) => {
    axios2
      .post(`/member/gift/use/ready?mb_no=${mb_no}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
          $('#mb_no').val(mb_no);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function gift_chk() {
    const g1 = $('#g1').val();
    const g2 = $('#g2').val();
    const g3 = $('#g3').val();
    //const g4 = $('#g4').val();

    if (g1.length !== 4) {
      alert("이용권번호를 입력해주세요");
      return;
    }
    if (g2.length !== 4) {
      alert("이용권번호를 입력해주세요");
      return;
    }
    if (g3.length !== 4) {
      alert("이용권번호를 입력해주세요");
      return;
    }
    /*
    if(g4.length !==4)
    {
        alert("이용권번호를 입력해주세요");
        return;
    }
    */

    axios2
      .post(`/member/gift/use/chk?mb_no=${$('#mb_no').val()}&g1=` + g1 + "&g2=" + g2 + "&g3=" + g3)
      .then((response) => {
        const {code, message: data, result, pageInfo} = response?.data;
        if (code === 200) {
          const split = data.split("||");
          const v1 = split?.[0];
          const v2 = split?.[1];
          const v3 = split?.[2];	//추가개월수
          const v4 = split?.[3];	//추가일수
          const v5 = split?.[4];	//상품코드
          const v6 = split?.[5];	//B2B여부


          if (v1 == "no_gift") {
            alert("일치하는 이용권번호가 없습니다.");
            return;
          }
          if (v1 == "not_pub") {
            alert("발행되지 않은 이용권번호입니다");
            return;
          }
          if (v1 == "used") {
            alert("이미 사용된 이용권번호입니다");
            return;
          }
          if (v1 == "old") {
            alert("사용 유효기간이 만료된 이용권번호입니다");
            return;
          }
          if (v1 == "not_b2b_use") {
            alert("B2B이용권은 발행 지점에서만 사용가능합니다");
            return;
          }

          if (v1 === "ok") {
            if (v6 === "Y") {
              //b2b 인경우 해당 지점의 계좌를 표시한다
              $("#b2b_YN").val("Y");

              //계좌리스트를 펼친다
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              //form1.g4.readOnly = true;
              $('#g_cont').html(v2);
              $('#start_set').css('display', "table-row");
              $('#auth_set').css('display', "table-row");
              $('#btn_set').css('display', "block");
              $('#add_month').val(v3);
              $('#add_day').val(v4);
              $('#select_item_id').val(v5);

              $("#pay_method").show();
            } else {
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              //form1.g4.readOnly = true;
              $('#g_cont').html(v2);
              $('#start_set').css('display', "table-row");
              $('#auth_set').css('display', "table-row");
              $('#btn_set').css('display', "block");
              $('#add_month').val(v3);
              $('#add_day').val(v4);
              $('#select_item_id').val(v5);
              $("#b2b_YN").val("N");

              $("#pay_method").hide();
            }
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  let processing = false;

  function goSubmit() {
    if (processing) return;

    //b2b 일경우 지불수단 필수 입력
    if ($("#b2b_YN").val() == "Y") {
      if (!$("#payCard").val()) {
        alert('지불수단을 선택해주세요');
        return;
      }
    }

    if ($('#radio1').prop('checked') == false && $('#radio2').prop('checked') == false && $('#radio3').prop('checked') == false) {
      alert('시작일을 설정해주세요');
      return;
    }

    if (!$('#gift_auth_code').val()) {
      alert('인증번호를 입력해주세요');
      return;
    }

    if (window.confirm('이용권을 등록하시겠습니까?')) {
      processing = true;

      axios2.postEx("/member/gift/use", {
        select_item_id: $('#select_item_id').val(),
        auth_code: $('#gift_auth_code').val(),
        mb_no: $('#mb_no').val(),
        it_start_date: $('#it_start_date').val(),
        it_end_date: $('#it_end_date').val() || '',
        ap_step: $('#ap_step').val(),
        g1: $('#g1').val(),
        g2: $('#g2').val(),
        g3: $('#g3').val(),
        b2b_YN: $('#b2b_YN').val(),
        add_month: $('#add_month').val(),
        add_day: $('#add_day').val(),
        period_day: $('#add_day').val(),
        pay_card: $('#payCard option:selected').val(),
      })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data == "ok") {
            alert('등록되었습니다');
            props?.onCallback();
            setIsOpen(false);
          } else {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
            setIsOpen(false);
          }
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          setIsOpen(false);
        })
        .finally(() => {
          // $(".loading_bg").fadeOut("fast");
        });
    }
  }

  function it_period_chk(chk) //chk 값은 설정 선택할때 값
  {
    const period_kind = $(':radio[name="period_kind"]:checked').val();

    if (period_kind == 1) {
      $('#start_date_show').css('display', "none");
    } else {
      $('#start_date_show').css('display', "flex");
    }

    var period_month = $("#add_month").val();
    var period_day = $("#add_day").val();
    var it_start_date = $("#it_start_date").val();

    var mb_no = $('#mb_no').val();


    var select_item_id = $('#select_item_id').val();
    if (select_item_id) {
      select_item_id = select_item_id;
    } else {
      select_item_id = "";
    }

    axios2
      .post("/member/it/period/chk?period_kind=" + period_kind + "&period_month=" + period_month + "&period_day=" + period_day + "&mb_no=" + mb_no + "&chk=" + chk + "&it_start_date=" + it_start_date + "&select_item_id=" + select_item_id)
      .then((response) => {
        const {code, message: data, result, pageInfo} = response?.data;
        if (code === 200) {
          if (data == "err") {
            alert('회원정보를 확인할수 없습니다.');
            return;
          }

          var data2 = data.split("||");

          if (data2[0] > 0 && chk == 3) {
            if (window.confirm("해당회원은 회원권기간이 남아있습니다.\n재등록으로 기간설정을 하시겠습니까?")) {
              $("#it_start_date").val(data2[1])
              $("#it_end_date").val(data2[2])
              $("#it_end_date_show").html(data2[2])
            }
          } else {
            $("#it_start_date").val(data2[1])
            $("#it_end_date").val(data2[2])
            $("#it_end_date_show").html(data2[2])
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        processing = false;
        loadModal();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '820px'}}>
          <div className="POP_title">
            <h5><strong>이용권 등록</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <table className="view-rounded sub_table_border" style={{borderRadius: 0}}>
              <tr>
                <th>회원번호</th>
                <th>회원명</th>
                <th>연락처</th>
                <th>이용기간</th>
                <th>운동복</th>
              </tr>
              <tr>
                <td className="text-center">{modalResult?.mbInfo?.mb_id}</td>
                <td className="text-center">{modalResult?.mbInfo?.mb_name}</td>
                <td className="text-center">{modalResult?.mbInfo?.mb_hp}</td>
                <td className="text-center">
                  {modalResult?.mbInfo?.start_date?.replaceAll("-", ".")?.substring(2)} ~ <br/>
                  {modalResult?.mbInfo?.end_date?.replaceAll("-", ".")?.substring(2)}
                </td>
                <td className="text-center">
                  {modalResult?.mbInfo?.suit_start_date?.replaceAll("-", ".")?.substring(2)} ~ <br/>
                  {modalResult?.mbInfo?.suit_end_date?.replaceAll("-", ".")?.substring(2)}
                </td>
              </tr>
            </table>

            <p className="bold800 my-2">이용권 정보</p>
            <div className="mb-2">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="130px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <td colSpan="2" className="text-left">
                    <span className="h7 bold700 px-2">이용권 정보</span>
                  </td>
                </tr>
                <tr>
                  <th>이용권 등록</th>
                  <td>
                    <div className="input-group mt-1" style={{width: 'auto'}}>
                      <input type="text" className="form-control" aria-describedby="button-addon2" id="g1" maxLength={4}/>
                      <p className="mx-2">-</p>
                      <input type="text" className="form-control" aria-describedby="button-addon2" id="g2" maxLength={4}/>
                      <p className="mx-2">-</p>
                      <input type="text" className="form-control" aria-describedby="button-addon2" id="g3" maxLength={4}/>
                      <button type="button" className="btn btn-sm btn-outline-dark px-3 mx-1" onClick={() => gift_chk()}>
                        확인
                      </button>
                      <button type="button" className="btn btn-sm btn-point px-3" id="btn_set" style={{display: 'none'}} onClick={() => goSubmit()}>
                        사용
                      </button>
                    </div>
                    <div className="msg_box">
                      <span id="g_cont">위 입력란에 이용권의 번호를 입력하세요.</span>
                    </div>
                  </td>
                </tr>

                <tr id="pay_method" style={{display: "none"}}>
                  <th>지불수단</th>
                  <td className="txt_left">
                    <select id="payCard" className="form-control wid80" style={{width: '120px', display: 'inline-block'}}>
                      <option value=''>지불수단 선택</option>
                      <option value='1111'>현금</option>
                      <option value='2222'>카드</option>
                      {modalResult?.pc1s?.map((item, index) => <option value={`${item?.j_no},${item?.j_jukname},${item?.j_gyecode}`}>(계좌) {item.j_jukname}</option>)}
                      {modalResult?.pc2s?.filter(i => i?.j_no != "244")?.map((item, index) =>
                        (item?.j_no == "174" || item?.j_no == "176" || item?.j_no == "180" || item?.j_no == "242" || item?.j_no == "244") ?
                          <option value={`${item?.j_no},${item?.j_jukname}`}>{item.j_jukname}</option>
                          :
                          <option value={`${item?.j_no},${item?.j_jukname}`}>{item.j_jukname}Card</option>
                      )}
                    </select>
                  </td>
                </tr>

                <tr id="start_set" style={{display: "none"}}>
                  {/*TODO : it_period_chk 연동*/}
                  <th>시작일 설정</th>
                  <td className="txt_left">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="period_kind" id="radio1" value="1" onClick={(e) => it_period_chk(e.target.value)}/>
                      <label className="form-check-label" htmlFor="radio1">
                        미정
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="period_kind" id="radio2" value="2" onClick={(e) => it_period_chk(e.target.value)}/>
                      <label className="form-check-label" htmlFor="radio2">
                        재등록
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="period_kind" id="radio2" value="3" onClick={(e) => it_period_chk(e.target.value)}/>
                      <label className="form-check-label" htmlFor="radio3">
                        신규
                      </label>
                    </div>
                    <div style={{clear: "both"}}/>
                    <div id="start_date_show" style={{display: "none", marginTop: "10px"}}>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="it_start_date" placeholder={"시작일"} onChange={(e) => it_period_chk()}/>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="it_end_date" placeholder={"종료일"} style={{marginLeft: '10px'}} readOnly={true}/>
                      {/*시작일 : <input type="text" className="form-control wid100 input_date" placeholder="시작일" id="it_start_date"/>*/}
                      {/*&nbsp;&nbsp;*/}
                      {/*종료일 : <strong><span id="it_end_date_show" style={{paddingRight: '10px'}}></span></strong><input type="text" id="it_end_date" style={{display: 'none'}}/>*/}
                    </div>
                  </td>
                </tr>

                <tr id="auth_set" style={{display: "none"}}>
                  <th>인증번호</th>
                  <td className="txt_left">
                    <div className="d-flex">
                      <div className="d-flex mx-1">
                        <ChkAuthNum type={"locker_info_change"} prefix="gift_"/>
                      </div>
                    </div>
                  </td>
                </tr>

              </table>

              <input type="hidden" id="mb_no"/>
              <input type="hidden" id="add_month"/>
              <input type="hidden" id="add_day"/>
              <input type="hidden" id="select_item_id"/>
              <input type="hidden" id="b2b_YN"/>

            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MvGiftUse;
