import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";

import dumbell from "../../images/Dumbbell_02.png";
import pt from "../../images/pt.png";
import dotp from "../../images/dot_p.png";
import dotb from "../../images/dot_b.png";
import dotg from "../../images/dot_g.png";
import Arrow_r from "../../images/Arrow_r.png";
import Arrow_l from "../../images/Arrow_l.png";
import {Bar} from "react-chartjs-2";

const Summary = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();
  const [result, setResult] = useState();
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/main/summary/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function permit_take(base_seq) {
    $.ajax({
      type: "post",
      url: "/branchop/permit/period",
      data: "base_seq=" + base_seq,
      success: function (data) {
        data = data?.message?.trim();
        if (data == "ok") {
          alert('승인처리되었습니다');
          setRenew(Fn.getRenewTime());
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      }
    });
  }

  function take_permit(type, base_seq) {
    $.ajax({
      type: "post",
      url: "/branchop/permit/service",
      data: "type=" + type + "&base_seq=" + base_seq,
      success: function (data) {
        data = data?.message?.trim();
        if (data == "ok") {
          alert('승인처리되었습니다');
          setRenew(Fn.getRenewTime());
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      }
    });
  }

  function take_return_period(type, base_seq) {
    $.ajax({
      type: "post",
      url: "/branchop/return/period",
      data: "type=" + type + "&base_seq=" + base_seq,
      success: function (data) {
        data = data?.message?.trim();
        if (data == "ok") {
          alert('반려처리되었습니다');
          setRenew(Fn.getRenewTime());
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      }
    });
  }

  function take_return_service(type, base_seq) {
    $.ajax({
      type: "post",
      url: "/branchop/return/service",
      data: "type=" + type + "&base_seq=" + base_seq,
      success: function (data) {
        data = data?.message?.trim();
        if (data == "ok") {
          alert('반려처리되었습니다');
          setRenew(Fn.getRenewTime());
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      }
    });
  }

  return (
    <CmnCard onLogout={props.onLogout}>

      <div className="row m-3" style={{maxWidth: "1300px"}}>
        <div className="col-12 chart_box">

          <p className="text-left p-1 px-3"><span className="h7 bold700 ">{Fn.parseInt(Fn.getCurrentDate().split("-")[1])}월 주차별 매출</span><span className="h9 px-2">단위: 백 만원</span></p>

          <div className="row px-3" style={{marginTop: "30px"}}>
            <div className="col-6 px-2">
              <div className="chart_box_inline">
                <p className="border-bottom text-left p-2 px-3">
                  <img src={dumbell} style={{width: "18px", verticalAlign: "-1px"}}/>
                  <span className="h7 bold700 p-1">퍼블릭</span>
                </p>
                <p style={{maxHeight: "215px", minHeight: "215px", padding: "15px"}}>
                  {result?.publicPrices?.filter(i => i.price > 0)?.length > 0 ?
                    <Bar options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        title: {
                          display: false
                        },
                      }
                    }}
                         data={{
                           labels: result?.publicPrices.map((row, i) => {
                             return (row?.week || 0) + "주";
                           }),
                           datasets: [
                             {
                               label: '주별 매출',
                               data: result?.publicPrices.map((row, i) => {
                                 return row?.price || 0;
                               }),
                               backgroundColor: 'rgba(53, 162, 235, 0.5)',
                               barPercentage: 0.4,
                             },
                           ]
                         }}
                    />
                    :
                    <div style={{width: '100%', height: "180px", lineHeight: "180px", display: "flex", flexDirection: "row"}} className="justify-content-center align-content-center align-items-center">매출 데이터가 없습니다.</div>
                  }
                </p>
              </div>
            </div>
            <div className="col-6 px-2">
              <div className="chart_box_inline">
                <p className="border-bottom text-left p-2 px-3">
                  <img src={pt} style={{width: "18px", verticalAlign: "-1px"}}/>
                  <span className="h7 bold700 p-1">PT</span>
                </p>
                <p style={{maxHeight: "215px", minHeight: "215px", padding: "15px"}}>
                  {result?.ptPrices?.filter(i => i.price > 0)?.length > 0 ?
                    <Bar options={{
                      maintainAspectRatio: false,
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        title: {
                          display: false
                        },
                      }
                    }}
                         data={{
                           labels: result?.ptPrices.map((row, i) => {
                             return (row?.week || 0) + "주";
                           }),
                           datasets: [
                             {
                               label: '주별 매출',
                               data: result?.ptPrices.map((row, i) => {
                                 return row?.price || 0;
                               }),
                               backgroundColor: 'rgba(53, 162, 235, 0.5)',
                               barPercentage: 0.4,
                             },
                           ]
                         }}
                    />
                    :
                    <div style={{width: '100%', height: "180px", lineHeight: "180px", display: "flex", flexDirection: "row"}} className="justify-content-center align-content-center align-items-center">매출 데이터가 없습니다.</div>
                  }
                </p>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="col-3 p-0 pl-3">*/}
        {/*  <table className="view-rounded calender_main" style={{height: "300px"}}>*/}
        {/*    <tr>*/}
        {/*      <td colSpan="7" className="border_btm">*/}
        {/*        <div className="d-flex justify-content-between mx-3 mt-2">*/}
        {/*          <div className="text-left ">*/}
        {/*            <span className="h7 bold700 vertical-middle px-2">{Fn.getCurrentDate().split("-")[0]}년 {Fn.parseInt(Fn.getCurrentDate().split("-")[1])}월</span><br/>*/}
        {/*            <div className="d-flex mb-2 flex-wrap">*/}
        {/*              <div className="h9"><img src={dotp} style={{width: "6px"}}/><span className="h9 px-2">공휴일</span></div>*/}
        {/*              <div className="h9 mx-1"><img src={dotb} style={{width: "6px"}}/><span className="h9 px-2">전체행사</span></div>*/}
        {/*              <div className="h9"><img src={dotg} style={{width: "6px"}}/><span className="h9 px-2">지점행사</span></div>*/}
        {/*            </div>*/}
        {/*          </div>*/}

        {/*          <div>*/}
        {/*            <button type="button" className="btn btn-sssm px-0"><img src={Arrow_r}/></button>*/}
        {/*            <button type="button" className="btn btn-sssm px-0"><img src={Arrow_l}/></button>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </td>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <th>일</th>*/}
        {/*      <th>월</th>*/}
        {/*      <th>화</th>*/}
        {/*      <th>수</th>*/}
        {/*      <th>목</th>*/}
        {/*      <th>금</th>*/}
        {/*      <th>토</th>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <td></td>*/}
        {/*      <td></td>*/}
        {/*      <td></td>*/}
        {/*      <td></td>*/}
        {/*      <td><a href="#" className="cal_date2">1</a>*/}
        {/*      </td>*/}
        {/*      <td><a href="#" className="cal_date2">2</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">3</a></td>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <td><a href="#" className="cal_date2">4</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">5</a><br/>*/}
        {/*        <img src={dotp} style={{width: "4px"}}/>*/}
        {/*        <img src={dotg} style={{width: "4px"}}/>*/}
        {/*      </td>*/}
        {/*      <td><a href="#" className="cal_date2">6</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">7</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">8</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">9</a></td>*/}
        {/*      <td>*/}
        {/*        <a href="#" className="cal_date2">10</a><br/>*/}
        {/*        <img src={dotb} style={{width: "4px"}}/>*/}
        {/*      </td>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <td><a href="#" className="cal_date2">11</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">12</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">13</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">14</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">15</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">16</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">17</a></td>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <td><a href="#" className="cal_date2">18</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">19</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">20</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">21</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">22</a><br/>*/}
        {/*        <img src={dotb} style={{width: "4px"}}/>*/}
        {/*        <img src={dotg} style={{width: "4px"}}/>*/}
        {/*      </td>*/}
        {/*      <td><a href="#" className="cal_date2">23</a></td>*/}
        {/*      <td><a href="#" className="cal_main_today">24</a></td>*/}
        {/*    </tr>*/}
        {/*    <tr>*/}
        {/*      <td><a href="#" className="cal_date2">25</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">26</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">27</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">28</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">29</a></td>*/}
        {/*      <td><a href="#" className="cal_date2">30</a></td>*/}
        {/*      <td></td>*/}
        {/*    </tr>*/}
        {/*  </table>*/}
        {/*</div>*/}
      </div>

      <div className="m-3" style={{borderWidth: "1px", borderStyle: "solid", borderColor: "#eee", borderRadius: "12px", maxWidth: "1300px"}}>
        <div className="border-bottom d-flex text-left px-3 py-2">
          <p className="h7 bold700">공지사항</p>
          <span className="h9 bold500 text-pink p-1 px-2" onClick={() => history.push("/branchop/notice/list")} style={{cursor: "pointer"}}>전체보기</span>
        </div>
        <div className="p-3">
          <table className="rounded-corners sub_table auto">
            <tr>
              <th>No.</th>
              <th>대상</th>
              <th>제목</th>
              <th>등록자</th>
              <th>등록일</th>
            </tr>
            {result?.notices?.map((item, index) =>
              <tr style={{cursor: "pointer"}} onClick={() => {
                history.push({
                  pathname: '/branchop/notice/view', state: {
                    base_seq: btoa(item?.seq)
                  }
                });
              }}>
                <td>{item?.num}</td>
                <td>{item?.target}</td>
                <td className="text-left">{item?.title}</td>
                <td>
                  <div className="d-flex justify-content-center">
                    <div className="USER_thum_ssm"></div>
                    <p className="px-2 mt-1">{item?.reg_name}</p>
                  </div>
                </td>
                <td>{item?.reg_date?.substring(0, 10)}</td>
              </tr>
            )}
          </table>
        </div>
      </div>

      <div className="m-3" style={{borderWidth: "1px", borderStyle: "solid", borderColor: "#eee", borderRadius: "12px", maxWidth: "1300px"}}>
        <div className="border-bottom text-left px-3 py-2"><p className="h7 bold700">회원현황</p></div>
        <div className="p-3">
          <table className="rounded-corners sub_table auto">
            <tr>
              <th>당일 이용</th>
              <th>신규 등록</th>
              <th>재등록</th>
              <th>만료 예정(7일이내)</th>
              <th>금일 만료</th>
              <th>유효 회원</th>
              <th>누적 회원</th>
            </tr>
            <tr>
              <td style={{cursor: "pointer"}} onClick={() => history.push({
                pathname: '/member/input/list', state: {
                  s_date1: Fn.getCurrentDate(),
                  s_date2: Fn.getCurrentDate(),
                }
              })}>
                {result?.member?.attend_today}
              </td>
              <td>{result?.member?.new_registration_today}</td>
              <td>{result?.member?.re_registration_today}</td>
              <td style={{cursor: "pointer"}} onClick={() => history.push({
                pathname: '/member/list', state: {
                  e_date1: Fn.getCurrentDate(),
                  e_date2: Fn.getDate(new Date(new Date().getTime() + (7 * 24 * 60 * 60 * 1000))),
                }
              })}>
                {result?.member?.expiry_within_seven_days}
              </td>
              <td style={{cursor: "pointer"}} onClick={() => history.push({
                pathname: '/member/list', state: {
                  e_date1: Fn.getCurrentDate(),
                  e_date2: Fn.getCurrentDate(),
                }
              })}>
                {result?.member?.expiry_today}
              </td>
              <td style={{cursor: "pointer"}} onClick={() => history.push({
                pathname: '/member/list', state: {
                  e_date1: Fn.getCurrentDate()
                }
              })}>
                {result?.member?.valid_members}
              </td>
              <td style={{cursor: "pointer"}} onClick={() => history.push({
                pathname: '/member/list', state: {
                }
              })}>
                {result?.member?.total_members}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="m-3" style={{borderWidth: "1px", borderStyle: "solid", borderColor: "#eee", borderRadius: "12px", maxWidth: "1300px"}}>
        <div className="border-bottom text-left px-3 py-2"><p className="h7 bold700">승인처리</p></div>
        <div className="p-3">
          <table className="rounded-corners sub_table auto">
            <tr>
              <th>요청일</th>
              <th>카드번호</th>
              <th>성명</th>
              <th>연락처</th>
              <th>구분</th>
              <th className="w-30">요청내용</th>
              <th>사유</th>
              <th>요청자</th>
              <th>승인</th>
              <th>반려</th>
            </tr>
            {result?.approves?.map((item, index) => {
              function ApprovalButton({row}) {
                const handleApproval = (baseSeq) => {
                  if (window.confirm('해당 승인요청건을 승인처리 하시겠습니까?')) {
                    if (row.gubun === 'period') {
                      permit_take(baseSeq);
                    } else if (row.gubun === 'service') {
                      take_permit('1', baseSeq);
                    }
                  }
                }

                const base_seq = btoa(row?.seq);

                if (row?.gubun === 'period' && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7) {
                  return (
                    <button className='btn btn-sssm btn-outline-point px-2' onClick={() => handleApproval(base_seq)}>
                      승인
                    </button>
                  );
                } else if (row?.gubun === 'service') {
                  if (row?.permit_depth === '2' && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7) {
                    return (
                      <button className='btn btn-sssm btn-outline-point px-2' onClick={() => handleApproval(base_seq)}>
                        승인
                      </button>
                    );
                  } else if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7) {
                    return (
                      <button className='btn btn-sssm btn-outline-point px-2' onClick={() => handleApproval(base_seq)}>
                        승인
                      </button>
                    );
                  }
                }

                return null;
              }

              function RejectButton({row}) {
                const handleRejectionPeriod = (baseSeq) => {
                  if (window.confirm('해당 승인요청건을 반려처리 하시겠습니까?')) {
                    take_return_period('1', baseSeq);
                  }
                }

                const handleRejectionService = (baseSeq) => {
                  if (window.confirm('해당 승인요청건을 반려처리 하시겠습니까?')) {
                    take_return_service('1', baseSeq);
                  }
                }

                const base_seq = btoa(row?.seq);

                if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7) {
                  if (row.gubun === 'period') {
                    return (
                      <button className='btn btn-sssm btn-outline-point px-2' onClick={() => handleRejectionPeriod(base_seq)}>
                        반려
                      </button>
                    );
                  } else {
                    return (
                      <button className='btn btn-sssm btn-outline-point px-2' onClick={() => handleRejectionService(base_seq)}>
                        반려
                      </button>
                    );
                  }
                }

                return null;
              }

              return (
                <tr>
                  <td>{item?.request_date}</td>
                  <td onClick={() => history.push({
                    pathname: '/member/view/detail', state: {
                      mbNo: item?.memInfoRow?.mb_no,
                    }
                  })}>{item?.memInfoRow?.mb_id}</td>
                  <td>{item?.memInfoRow?.mb_name}</td>
                  <td>{item?.mb_hp_show}</td>
                  <td>{item?.show_gubun}</td>
                  <td className="text-left">{item?.descr}</td>
                  <td className="text-left">{item?.change_reason}</td>
                  <td>{item?.request_name}</td>
                  <td><ApprovalButton row={item}/></td>
                  <td><RejectButton row={item}/></td>
                </tr>
              )
            })}
            {result?.approves?.length === 0 &&
              <tr>
                <td colSpan="10">등록된 승인요청이 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

    </CmnCard>
  );
};

export default Summary;

