import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import Constants from "../../constants/constants";

import {Document, Page, setOptions} from 'react-pdf/dist/esm/entry.webpack';

const CleanManual = (props) => {
  const [result, setResult] = useState();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({numPages}) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () =>
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

  const goToNextPage = () => setPageNumber(
    pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
  );

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, []);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    axios2
      .post(`/guide/manual/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          // console.log(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=spoany_pdf&filename=" + encodeURIComponent(result?.result?.clean_manual)));
          // $('#pdf').attr('src', "https://smms.spoany.co.kr/ActiveFile/spoany.smms/spoany_pdf/" + encodeURIComponent(result?.result?.clean_manual));
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      {result?.result?.clean_manual &&
        <div style={{padding: "20px"}}>
          <div className="input-group" style={{marginLeft: '7px', width: '880px', flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: '20px'}}>
            <div className="input-group" style={{width: 'auto'}}>
              <button type="button" className="btn btn-sm btn-dark px-3 mr-2" onClick={goToPrevPage}>이전</button>
              <button type="button" className="btn btn-sm btn-dark px-3" onClick={goToNextPage}>다음</button>
            </div>
            <span>
              페이지 {pageNumber} / {numPages}
            </span>
          </div>

          <Document
            file={Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=spoany_pdf&filename=" + encodeURIComponent(result?.result?.clean_manual))}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} width={900}/>
          </Document>
        </div>
      }
    </CmnCard>
  );
};
export default CleanManual;
