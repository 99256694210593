import React, {useEffect} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";


const EmployeeBookReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.baseSeq || '';

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    $('#base_seq').val(baseSeq || '');

    axios2
      .post(`/employee/book/reg/ready?base_seq=${baseSeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $('#book_name').val(result?.book_name || '');
          $('#author').val(result?.author || '');
          $('#publishing').val(result?.publishing || '');
          $('#bigo').val(result?.bigo || '');

          if (result?.central_meet == 'Y') $('#central_meet').prop("checked", true);
          if (result?.branch_meet == 'Y') $('#branch_meet').prop("checked", true);

          if ($('#area_ps').html() === '') {
            let html = '';
            result?.list?.forEach((list_row, i) => {
              html += `
                  <div class="form-check form-check-inline width_shorts mb-1">
                    <input class="form-check-input" type="checkbox" name="${`position_${i + 1}`}" id="${`position_${i + 1}`}" value="${list_row?.sub_code}"/>
                    <label class="form-check-label" htmlFor="${`position_${i + 1}`}">${list_row?.sub_code_name}</label>
                  </div>
                `;
            });
            $('#area_ps').html(html);
            if (baseSeq) {
              result?.list?.forEach((list_row, i) => {
                if (result?.position.indexOf(list_row?.sub_code + "|") !== -1) $(`#position_${i + 1}`).prop("checked", true);
              });
            }
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit() {
    if ($('#book_name').val() === "") {
      alert("도서명을 입력해주세요");
      $('#book_name').focus();
      return;
    }

    const formData = new FormData();

    let values = $('[id^=position]:checked').map(function () {
      return $(this).val();
    }).get().join(",");
    formData.append("position[]", values);

    formData.append("base_seq", $('#base_seq').val());
    formData.append("book_name", $('#book_name').val());
    formData.append("author", $('#author').val());
    formData.append("publishing", $('#publishing').val());
    formData.append("central_meet", $('#central_meet').is(":checked") ? "Y" : "N");
    formData.append("branch_meet", $('#branch_meet').is(":checked") ? "Y" : "N");
    formData.append("bigo", $('#bigo').val());

    axios2.postFormEx(`/employee/book/reg`, formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();

        if (data === "err") {
          alert('오류가 발생하였습니다');
        } else if (data === "insert_ok") {
          alert("등록되었습니다");
          history.goBack();
        } else if (data === "up_ok") {
          alert("수정되었습니다");
          history.goBack();
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 text-left">
        <p className="h6 bold700 px-3">도서등록</p>
      </div>

      {/* 테이블 */}
      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="120px"/>
            <col width="*"/>
            <col width="120px"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>도서명</th>
            <td>
              <input type="text" className="form-control" aria-describedby="button-addon2" id="book_name"/>
            </td>
            <th>저자</th>
            <td>
              <input type="text" className="form-control" aria-describedby="button-addon2" id="author"/>
            </td>
          </tr>

          <tr>
            <th>출판사</th>
            <td>
              <input type="text" className="form-control" aria-describedby="button-addon2" id="publishing"/>
            </td>
            <th>독서모임대상</th>
            <td className="text-left">
              <div className="form-check form-check-inline">
                <input
                  name="central_meet"
                  type="checkbox"
                  value="Y"
                  className="checkbox_sm"
                  id="central_meet"
                />
                <label className="form-check-label" htmlFor="central_meet">
                  본사
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  name="branch_meet"
                  type="checkbox"
                  value="Y"
                  className="checkbox_sm"
                  id="branch_meet"
                />
                <label className="form-check-label" htmlFor="branch_meet">
                  지점
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>비고</th>
            <td colSpan="3">
              <input type="text" className="form-control" aria-describedby="button-addon2" id="bigo"/>
            </td>
          </tr>
          <tr>
            <th>필독서 대상직책</th>
            <td colSpan="3" className="text-left" id="area_ps">
            </td>
          </tr>
        </table>
        <div className="m-4 text-center">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>등록</button>
          <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.goBack()}>목록</button>
        </div>
      </div>

      <input type="hidden" id="base_seq" name="base_seq"/>

    </CmnCard>
  );
};

export default EmployeeBookReg;
