import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import {useHistory} from "react-router-dom";
import Modal from "react-modal";
import SelectProduct from "../common/SelectProduct";


const SalesControl = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [baseSeq, setBaseSeq] = useState();
  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [productModalOpen, setProductModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 3);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/sales/sales/inout/control/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_control_code=${$('#s_control_code option:selected').val() || ''}&s_p_name=${$('#s_p_name').val()}&s_bigo=${$('#s_bigo').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_control_code').html() === '') {
            let optionsHTML = '<option value="">조정구분</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_control_code').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const delIn = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 공급업체를 삭제하시겠습니까? 상품은 삭제되지 않습니다.')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      // TODO : API 구현 필요
      axios2.postFormEx('/sales/sales/control/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  const loadModal = (_base_seq) => {
    setBaseSeq(_base_seq)
    axios2
      .post(`/sales/sales/control/edit/ready?base_seq=${_base_seq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalOpen(true);

          const new_cnt = "_1";

          if ($("#control_code" + new_cnt).html() === '') {
            let optionsHTML = '<option value="">조정구분</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $("#control_code" + new_cnt).html(optionsHTML);
          }


          $("#inout_date").val(result?.inoutDate);
          $("#p_code_show" + new_cnt).val(result?.pCode);
          $("#p_code" + new_cnt).val(result?.pCode);
          $("#p_name" + new_cnt).text(result?.pName);
          $("#unit_price_show" + new_cnt).text(result?.unitPrice);
          $("#unit_price" + new_cnt).val(result?.unitPrice);
          $("#qty" + new_cnt).val(1);
          $("#price" + new_cnt).text(result?.price);
          $("#qty" + new_cnt).focus();
          $("#detail_bigo" + new_cnt).val(result?.detailBigo);
          $("#control_code" + new_cnt).val(result?.controlCode);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit() {
    /*
        String baseSeq = (String) requestParams.get("base_seq");
        String[] pCodes = (String[]) requestParams.get("p_codes[]"); // TODO : 체크 필요
        String[] qtys = (String[]) requestParams.get("qtys[]"); // TODO : 체크 필요
        String[] detailBigos = (String[]) requestParams.get("detail_bigos[]"); // TODO : 체크 필요
        String inoutDate = (String) requestParams.get("inout_date");
        int detailCnt = Fn.parseInt(requestParams.get("detail_cnt"));
     */

    if (!$('#p_code_show_1').val()) {
      alert('상품 코드를 입력해 주세요.');
      return;
    }

    const formData = new FormData();

    let p_codes = $('[id^=p_code_]').map(function () {
      return $(this).val();
    }).get().join(",");
    formData.append("p_codes[]", p_codes);

    let qtys = $('[id^=qty_]').map(function () {
      return $(this).val();
    }).get().join(",");
    formData.append("qtys[]", qtys);

    let control_codes = $('[id^=control_code_]').map(function () {
      return $(this).find('option:selected').val();
    }).get().join(",");
    formData.append("control_codes[]", control_codes);

    let detail_bigos = $('[id^=detail_bigo_]').map(function () {
      return $(this).val();
    }).get().join(",");
    formData.append("detail_bigos[]", detail_bigos);

    formData.append("base_seq", baseSeq);
    formData.append("inout_date", $('#inout_date').val());
    formData.append("detail_cnt", $('#detail_cnt option:selected').val() || '1');

    if (baseSeq && !$('#control_code_1').val()) {
      alert('조정구분을 선택해주세요');
      return;
    }

    axios2.postFormEx('/sales/sales/control/reg', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();
        if (data === 'insert_ok') {
          alert('저장되었습니다.');
          setRenew(Fn.getRenewTime());
          setModalOpen(false);
        } else if (data === 'update_ok') {
          alert('수정되었습니다.');
          setRenew(Fn.getRenewTime());
          setModalOpen(false);
        } else {
          alert("저장오류! 잠시후 다시 시도해주세요");
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function changeQty() {
    for (let i = 1; i <= $('[id^=p_code_]').length; i++) {
      $('#price_' + i).html(Fn.numberWithCommas(Fn.parseInt($('#unit_price_' + i).val()) * Fn.parseInt($('#qty_' + i).val())));
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <input type="hidden" id="cnt" value="1"/>

      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="input-group pr-1" style={{width: 'auto'}}>
            <p className="p-1">대상일 :</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_control_code">
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="상품명 검색" id="s_p_name"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="비고 검색" id="s_bigo"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="60px"/>
              <col width="70px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>

              <col width=""/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="20%"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>대상일</th>
              <th>조정구분</th>
              <th>상품코드</th>
              <th>상품명</th>
              <th>단가</th>
              <th>수량</th>
              <th>금액</th>
              <th>비고</th>
            </tr>
            {rets && rets.map((ret, i) => {
              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input
                      type="checkbox"
                      // name={`chk[${i + 1}]`}
                      name="chk"
                      value={btoa(ret?.seq)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  </td>
                  <td>{ret?.inout_date || ''}</td>
                  <td>{ret?.control_code_name || ''}</td>
                  <td
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      loadModal(btoa(ret?.seq));
                    }}
                  >{ret?.p_code || ''}</td>
                  <td className="text-left">{ret?.p_name || ''}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.unit_price)}</td>
                  <td>{Fn.numberWithCommas(ret?.qty)}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.price)}</td>
                  <td className="text-left">{ret?.detail_bigo || ''}</td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={10}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top">
        <ul className="pagination"></ul>

        <div>
          <button type="button" className="btn btn-sm btn-outline-point px-2 mx-1" onClick={() => history.push("/sales/control/reg")}>재고조정등록</button>
          <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => delIn()}>삭제</button>
        </div>
      </nav>

      {/*팝업*/}

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: '830px'}}>
            <div className="POP_title">
              <h5><strong>조정내역 수정</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="100px"/>
                  <col width="*"/>
                  <col width="100px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>대상일</th>
                  <td><JDatePicker className="form-control" id="inout_date"/></td>
                  <th>조정수량</th>
                  <td>
                    <input type="text" className="form-control" id="qty_1" onChange={() => changeQty()}/>
                  </td>
                </tr>
                <tr>
                  <th>상품코드</th>
                  <td>
                    <div className="d-flex">
                      <input type="text" className="form-control" style={{width: 'calc(100% - 35px)'}} id="p_code_show_1"/>
                      <button type="button" className="btn btn-sm btn-point space_nowrap px-3" onClick={() => setProductModalOpen(true)}>검색</button>
                    </div>
                  </td>
                  <th>상품명</th>
                  <td>
                    <strong><span id="p_name_1"></span></strong>
                  </td>
                </tr>
                <tr>
                  <th>단가</th>
                  <td>
                    <strong><span id="unit_price_show_1"></span></strong>
                    <input type="hidden" id="unit_price_1"/>
                  </td>
                  <th>조정금액</th>
                  <td>
                    <strong><span id="price_1"></span></strong>
                  </td>
                </tr>
                <tr>
                  <th>조정구분</th>
                  <td>
                    <select className="form-select" id="control_code_1"/>
                  </td>
                  <th>비고</th>
                  <td><input type="text" className="form-control" id="detail_bigo_1"/></td>
                </tr>
              </table>
            </div>

            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
              <button type="button" className="btn btn-sm btn-ligray px-3 mx-1" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <SelectProduct
        isOpen={productModalOpen}
        onModalClose={() => setProductModalOpen(false)}
        searchType={"input_reg"}
        callback={() => {
          setProductModalOpen(false);
        }}
      />

    </CmnCard>
  );
};

export default SalesControl;
