import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";

const BranchopNoticeView = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.base_seq || '';

  const [result, setResult] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/branchop/notice/view/ready?base_seq=${baseSeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        console.log(baseSeq, result);
        if (code === 200) {
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 pb-2">
        <table className="view-rounded sub_view text-left">
          <colgroup>
            <col width="120px"/>
            <col width="*"/>
            <col width="120px"/>
            <col width="*"/>
            <col width="120px"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>지점명</th>
            <td>{result?.target}</td>
            <th>등록자</th>
            <td>{result?.row?.reg_name}</td>
            <th>등록일</th>
            <td>{result?.row?.reg_date?.split(" ")?.[0]}</td>
          </tr>
          <tr>
            <th>제목</th>
            <td colSpan="5">{result?.row?.title}</td>
          </tr>
          <tr>
            <th>내용</th>
            <td colSpan="5" className="p-4">
              <div style={{minHeight: '200px'}} dangerouslySetInnerHTML={{__html: result?.row?.content}}/>
            </td>
          </tr>
        </table>
      </div>
      <div className="text-center my-4">
        {localStorage.getItem("USER.systemID") == result?.row?.reg_id &&
          <button type="button" className="btn btn-sm btn-point px-3 mr-2" onClick={() => history.push({pathname: '/branchop/notice/write', state: {base_seq: baseSeq}})}>수정
          </button>
        }
        <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.replace({pathname: '/branchop/notice/list', state: {fromBack: 'true'}})}>목록
        </button>
      </div>
    </CmnCard>
  );
};

export default BranchopNoticeView;
