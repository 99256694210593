import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";

import axios from "axios";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import Constants from "../../constants/constants";

const BranchopStepAppInstallList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/branchop/step/app/install/list/ready?s_position=${$('#s_position option:selected').val() || ''}&s_pay_part=${$('#s_pay_part option:selected').val() || ''}&s_part=${$('#s_part option:selected').val() || ''}&s_new_position=${$('#s_new_position option:selected').val() || ''}&s_inout_state=${$('#s_inout_state option:selected').val() || ''}&key=${$('#key').val()}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_position').html() === '') {
            let optionsHTML = '<option value="">::계약명칭::</option>';
            result?.list1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.sub_code}">${list_row.sub_code_name}</option>`;
            });
            $('#s_position').html(optionsHTML);
          }
          if ($('#s_part').html() === '') {
            let optionsHTML = '<option value="">::부서구분::</option>';
            result?.list2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.sub_code}">${list_row.sub_code_name}</option>`;
            });
            $('#s_part').html(optionsHTML);
          }
          if ($('#s_new_position').html() === '') {
            let optionsHTML = '<option value="">::계약형태::</option>';
            result?.list3?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.sub_code}">${list_row.sub_code_name}</option>`;
            });
            $('#s_new_position').html(optionsHTML);
          }

          Fn.processPhone(result?.result, pageInfo, "ret?.hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-1.
  const form1 = useRef(null); // TODO : FORM 참조

  const delIn = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 설치내역을 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/branchop/step/install/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  //  4-1.
  const popUp = (file, names, width, height, sc) => {
    const w = width;
    let h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h},scrollbars=${sc},top=${wint},left=${winl}`);
  };

  function chkSend() {
    let con = 0;
    let editMsgid = "";
    for (let i = 0; i < form1.elements.length; i++) {
      if (form1.elements[i].type === 'checkbox') {
        if (form1.elements[i].checked === true) {
          const nn = form1.elements[i].name;
          const m_chk = nn.substr(0, 3);
          if (m_chk === "chk") {
            con++;
            editMsgid = editMsgid + form1.elements[i].value + "|";
          }
        }
      }
    }
    if (con === 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    popUp('/message/sms.html?b_type=manager&s_type=sel&editMsgid=' + editMsgid, 'sms.html', '800', '450', 'no');
  }

  //  5-1.
  function searchSend() {
    if (window.confirm("검색된 전체스텝에게 SMS를 발송하시겠습니까?")) {
      popUp('/message/sms.html?b_type=manager&s_type=all&my_sort=seq desc&s_branch_code=central&s_position=&s_pay_part=&key=&s_part=&s_new_position=&s_inout_state=', 'sms.html', '800', '450', 'no');
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 fn_search_box">
          <div className="pr-1">
            <select className="form-select" id="s_position">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_pay_part">
              <option value="">::수수료부서선택</option>
              <option value="A">본사</option>
              <option value="B">퍼블릭</option>
              <option value="C">PT</option>
              <option value="D">퍼블릭+PT</option>
              <option value="E">GX</option>
              <option value="F">용역</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_part">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_new_position">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_inout_state">
              <option value="">::상태::</option>
              <option value="Y">근무</option>
              <option value="N">퇴사</option>
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="이름/연락처 검색" id="key"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_border auto">
            <tr>
              <th className="w_70px">No</th>
              <th className="w_70px"><input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/></th>
              <th>ID</th>
              <th>성명</th>
              <th>계약명칭</th>
              <th>연락처</th>
              <th>계약체결일</th>
              <th>앱설치일</th>
              <th>상태</th>
            </tr>
            {rets && rets.map((ret, i) => {

              let inout_state_txt;
              if (ret?.inout_state === "Y") {
                inout_state_txt = "근무";
              } else {
                inout_state_txt = <span style={{color: 'red'}}>퇴사</span>;
              }

              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input
                      type="checkbox"
                      // name={`chk[${i + 1}]`}
                      name="chk"
                      value={btoa(ret?.seq)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  </td>
                  <td>{ret?.user_id}</td>
                  <td>{ret?.user_name}</td>
                  <td>{ret?.position_name}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{(ret?.in_date || '').replace(/-/g, '.').substr(2, 8)}</td>
                  <td>{(ret?.reg_date || '').replace(/-/g, '.').substr(2, 8)}</td>
                  <td>{inout_state_txt}</td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={9}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>

        <div className="d-flex">
          <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => delIn()}>삭제</button>
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
          {pageInfo?.totalArticles > 0 && (
            (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('emp_list')}>연락처 확인</button>
            )
          )}
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default BranchopStepAppInstallList;
