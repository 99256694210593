import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import {useHistory, useLocation} from "react-router-dom";
import Constants from "../../constants/constants";

const AnalysisPointHistory = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const location = useLocation();
  const tblGubun = location?.state?.tblGubun || '';
  const sDate1 = location?.state?.sDate1 || '';
  const sDate2 = location?.state?.sDate2 || '';

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    const daysAgo = new Date(currentDate);
    daysAgo.setDate(daysAgo.getDate() - 1);

    if (!sDate2) $('#s_date2').val(Fn.getDate(daysAgo));
    else $('#s_date2').val(sDate2);

    if (!sDate1) $('#s_date1').val(Fn.getDate(monthsAgo));
    else $('#s_date1').val(sDate1);

    if (!tblGubun) $('#tbl_gubun').val('now');
    else $('#tbl_gubun').val(tblGubun);

    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 8)
      $('#s_branch_code').attr("disabled", true);
  }, []);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/analysis/point/history/ready?tbl_gubun=${$('#tbl_gubun option:selected').val() || ''}&s_branch_code=${$('#s_branch_code option:selected').val() || localStorage.getItem("USER.systemBranchCode") || ''}&s_part=${$('#s_part option:selected').val() || ''}&s_gubun=${$('#s_gubun option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function Excel_download() {
    // var auth_code = $("#auth_code").val();
    // if (!auth_code) {
    //   alert("인증코드를 입력해주세요");
    //   return;
    // }

    // var down_reason = $("#down_reason").val();
    // if (!down_reason) {
    //   alert("사유를 입력해주세요");
    //   return;
    // }

    const url = `/xls/analysis/point/history?tbl_gubun=${$('#tbl_gubun option:selected').val() || ''}&s_branch_code=${$('#s_branch_code option:selected').val() || localStorage.getItem("USER.systemBranchCode") || ''}&s_part=${$('#s_part option:selected').val() || ''}&s_gubun=${$('#s_gubun option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&key=${$('#key').val()}&verify=${localStorage.getItem("USER.token")}`;
    Fn.download(Constants.API_URL + url);
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex justify-content-between m-3 flex-wrap">
        <p className="h6 bold700 p-1">지점별 리스트</p>
        <div className="d-flex flex-wrap fn_search_box">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: "32%"}} id="tbl_gubun">
              <option value="now">현재</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: "32%"}} id="s_branch_code">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: "32%"}} id="s_part">
              <option value="">분류선택</option>
              <option value="적립">적립</option>
              <option value="사용">사용</option>
              <option value="소멸">소멸</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: "32%"}} id="s_gubun">
              <option value="">내용선택</option>
              <option value="앱설치">앱설치</option>
              <option value="회원권구매">회원권구매</option>
              <option value="만족도조사">만족도조사</option>
              <option value="마일리지사용">마일리지사용</option>
              <option value="입장체크">입장체크</option>
              <option value="포인트조정">포인트조정</option>
              <option value="1년경과포인트소멸">1년경과포인트소멸</option>
            </select>
          </div>
          <div className="input-group pr-1" style={{width: "auto"}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="카드/성명/휴대폰 검색" id="key"/>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => Excel_download()}>엑셀저장</button>

            <button type="button" className="btn btn-sm btn-dark"
                    onClick={() => {
                      history.push({
                        pathname: '/analysis/point/history/branch', state: {
                          tblGubun: $('#tbl_gubun option:selected').val(),
                          sDate1: $('#s_date1').val(),
                          sDate2: $('#s_date2').val()
                        }
                      });
                    }}
            >지점별 합계
            </button>
          </div>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="75px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="17%"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>대상일</th>
            <th>지점명</th>
            <th>회원명</th>
            <th>회원번호</th>
            <th>포인트</th>
            <th>분류</th>
            <th>내용</th>
          </tr>
          {rets && rets.map((ret, i) => {
            let part_show;
            if (ret?.gubun === '포인트조정') {
              part_show = '수기조정';
            } else if (ret?.point > 0) {
              part_show = '적립';
            } else if (ret?.gubun === '1년경과포인트소멸') {
              part_show = '소멸';
            } else {
              part_show = '사용';
            }

            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>{ret?.basic_date}</td>
                <td>{ret?.branch_name}</td>
                <td>{ret?.mb_name}</td>
                <td>{ret?.mb_id}</td>
                <td>{Fn.numberWithCommas(ret?.point)}</td>
                <td>{part_show}</td>
                <td>{ret?.gubun}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={8}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
        </div>
      </nav>

    </CmnCard>
  );
};

export default AnalysisPointHistory;
