import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import $ from 'jquery';
import CmnCard from "../CmnCard";
import axios from "axios";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import SmsNew from "../common/SmsNew";
import Modal from "react-modal";
import Constants from "../../constants/constants";

const BranchPtPTList = (props) => {

  window._load_pt_list = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [smsModalOpen, setSmsModalOpen] = useState(false);

  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [modal4Open, setModal4Open] = useState(false);
  const [result1, setResult1] = useState();
  const [result2, setResult2] = useState();
  const [result3, setResult3] = useState();
  const [result4, setResult4] = useState();

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 3);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  function chk_remain(cnt) {
    var c1 = eval(cnt);
    var c2 = eval(result1?.dataRow?.pt_cnt - result1?.dataRow?.take_cnt);

    if (c1 > c2) {
      alert('진행하려는 PT횟수가 잔여횟수보다 많습니다');
      $('#m1_pt_cnt').val(1);
    }
  }

  const load = (_page) => {
    axios2
      .post(`/branchop/pt/list/ready?s_date1=${$('#s_date1').val() || ''}&s_date2=${$('#s_date2').val() || ''}&s_mb_name=${$('#s_mb_name').val() || ''}&s_mb_hp=${$('#s_mb_hp').val() || ''}&s_receipt_train=${$('#s_receipt_train option:selected').val() || ''}&s_take_train=${$('#s_take_train option:selected').val() || ''}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_receipt_train').html() === '') {
            let optionsHTML = '<option value="">접수 T : 전체</option>';
            result?.tnList1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#s_receipt_train').html(optionsHTML);
          }
          if ($('#s_take_train').html() === '') {
            let optionsHTML = '<option value="">진행 T : 전체</option>';
            result?.tnList2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#s_take_train').html(optionsHTML);
          }

          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  let s_form;
  let form1;
  let common_i;
  let re_data;

  useEffect(() => {
    s_form = $("[name='s_form']");
    form1 = $("[name='form1']");
    common_i = $("[name='common_i']");
  });

  const popUp = (file, names, width, height, sc) => {
    const w = width;
    const h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h}, scrollbars=${sc}, top=${wint}, left=${winl}`);
  };

  const chkSend = () => {
    let con = 0;
    let editMsgid = '';

    for (let i = 0; i < form1.elements.length; i++) {
      if (form1.elements[i].type === 'checkbox') {
        if (form1.elements[i].checked === true) {
          const nn = form1.elements[i].name;
          const mChk = nn.substr(0, 3);
          if (mChk === 'chk') {
            con++;
            editMsgid += form1.elements[i].value + '|';
          }
        }
      }
    }

    if (con === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    popUp(
      `/message/sms?b_type=pt&s_type=sel&editMsgid=${editMsgid}`,
      'sms.html',
      '800',
      '600',
      'no'
    );
  };

  const searchSend = () => {
    if (window.confirm('검색된 전체회원에게 SMS를 발송하시겠습니까?')) {
      popUp(
        `/message/sms?b_type=pt&s_type=all&<?=$link_param?>`, // TODO
        'sms.html',
        '800',
        '600',
        'no'
      );
    }
  };

  const chkAuth = value => {
    $.ajax({
      type: 'post',
      url: '/branchop/search/user',
      data: `type=search&search_auth_code=${value}`,
      success: function (data) {
        data = data.trim();
        if (data === 'no_auth') {
          alert('인증번호가 일치하지 않습니다');
          s_form.auth_code.value = '';
          s_form.search_auth_code.value = '';
          s_form.search_YN.value = 'N';
          s_form.u_name.value = '';
          // auth_code_name.innerHTML = ''; // TODO
          s_form.base_tranier_seq.value = '';
        } else if (data === '') {
          alert('설정오류! 시스템 관리자에게 문의하세요.');
          s_form.auth_code.value = '';
          s_form.search_auth_code.value = '';
          s_form.search_YN.value = 'N';
          s_form.u_name.value = '';
          // auth_code_name.innerHTML = ''; // TODO
          s_form.base_tranier_seq.value = '';
          s_form.search_auth_code.focus();
        } else {
          re_data = data.split('||');

          s_form.search_auth_code.value = '';
          s_form.auth_code.value = '';
          // auth_code_name.innerHTML = re_data[0]; // TODO
          s_form.u_name.value = re_data[0];
          s_form.search_YN.value = 'Y';
          s_form.base_tranier_seq.value = re_data[1];
          s_form.submit();
        }
      }
    });
  };

  const force_end = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크된 PT 대상자를 강제종료 하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");
      formData.append("chk[]", values);

      axios2.postFormEx('/branchop/pt/force/end/new', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('처리되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function Excel_pt_list() {
    var auth_code = $("#auth_code").val();
    if (!auth_code) {
      alert("인증코드를 입력해주세요");
      return;
    }

    var down_reason = $("#down_reason").val();
    if (!down_reason) {
      alert("사유를 입력해주세요");
      return;
    }

    const url = `/xls/branchop/pt/list?s_date1=${$('#s_date1').val() || ''}&s_date2=${$('#s_date2').val() || ''}&s_mb_name=${$('#s_mb_name').val() || ''}&s_mb_hp=${$('#s_mb_hp').val() || ''}&s_receipt_train=${$('#s_receipt_train option:selected').val() || ''}&s_take_train=${$('#s_take_train option:selected').val() || ''}&auth_code=${auth_code}&down_reason=${down_reason}`;
    Fn.download(Constants.API_URL + url);
  }

  const ptRefundCha = base_ap_no => {
    const auth_code = $("#auth_code").val();
    if (!auth_code) {
      alert('인증코드를 입력해주세요');
      return;
    }

    popUp(`/branchop/pt/refund/cha?base_ap_no=${base_ap_no}&auth_code=${auth_code}`, 'pt_refund_cha', '800', '500', 'no');
  };

  const ptRefundAll = base_ap_no => {
    const auth_code = $("#auth_code").val();
    if (!auth_code) {
      alert('인증코드를 입력해주세요');
      return;
    }

    popUp(`/branchop/pt/refund/all?base_ap_no=${base_ap_no}&auth_code=${auth_code}`, 'pt_refund_cha', '800', '500', 'no');
  };

  const loadModal1 = () => {
    axios2
      .post(`/branchop/take/pt/ready?base_ap_no=${$('#base_ap_no').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#m1_trainer_seq').html() === '') {
            let optionsHTML = '<option value="">트레이너 선택</option>';
            result?.tnList1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#m1_trainer_seq').html(optionsHTML);
          }

          setResult1(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal2 = () => {
    $('#form1')[0].base_ap_no.value = $('#base_ap_no').val();
    $('#form1')[0].auth_code.value = $('#auth_code').val();

    axios2
      .post(`/branchop/pt/refund/cha/ready?base_ap_no=${$('#base_ap_no').val()}&auth_code=${$('#auth_code').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $('#cha_danga').val(Fn.numberWithCommas(result?.dataRow?.pt_danga));
          $('#wi_price').val(Fn.numberWithCommas((result?.dataRow?.pt_danga * result?.dataRow?.pt_cnt * 1.1) * 0.1));
          setResult2(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal3 = () => {
    $('#form2')[0].base_ap_no.value = $('#base_ap_no').val();
    $('#form2')[0].auth_code.value = $('#auth_code').val();

    axios2
      .post(`/branchop/pt/refund/all/ready?base_ap_no=${$('#base_ap_no').val()}&auth_code=${$('#auth_code').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $('#cha_danga').val(Fn.numberWithCommas(result?.dataRow?.pt_danga));
          $('#wi_price').val(Fn.numberWithCommas((result?.dataRow?.pt_danga * result?.dataRow?.pt_cnt * 1.1) * 0.1));
          setResult3(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal4 = () => {
    axios2
      .post(`/branchop/pt/trainer/change/ready?base_ap_no=${$('#base_ap_no').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        console.log(result);
        if (code === 200) {
          if ($('#r_pt').html() === '') {
            let optionsHTML = '<option value="">트레이너 선택</option>';
            result?.tnList1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#r_pt').html(optionsHTML);
          }
          if ($('#p_pt').html() === '') {
            let optionsHTML = '<option value="">트레이너 선택</option>';
            result?.tnList2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#p_pt').html(optionsHTML);
          }

          $('#r_pt').val(result?.dataRow?.receipt_train);
          $('#p_pt').val(result?.dataRow?.take_train);

          setResult4(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function cal_price() {
    //진행금액,진행부가세, 총 진행금액 변경
    var cha_cnt = $("#cha_cnt").text();
    cha_cnt = Fn.cfNumeric(cha_cnt);
    cha_cnt = eval(cha_cnt);

    var cha_danga = $("#cha_danga").val();
    cha_danga = Fn.cfNumeric(cha_danga);
    cha_danga = eval(cha_danga);

    var ing_pt_price = eval(cha_cnt * cha_danga);
    var ing_add_price = eval(ing_pt_price) * 0.1;
    var ing_tot_price = eval(ing_pt_price) + eval(ing_add_price);

    var won_tot_price = ing_tot_price;

    ing_pt_price = Fn.commaNum(ing_pt_price);
    $("#ing_pt_price").text(ing_pt_price);

    ing_add_price = Fn.commaNum(ing_add_price);
    $("#ing_add_price").text(ing_add_price);

    ing_tot_price = Fn.commaNum(ing_tot_price);
    $("#ing_tot_price").text(ing_tot_price);


    //환불금액 = 총결제금액 - 위약금 - 진행금액;
    var before_tot_price = $("#before_tot_price1").val();
    before_tot_price = Fn.cfNumeric(before_tot_price);
    before_tot_price = eval(before_tot_price);

    var wi_price = $("#wi_price").val();
    wi_price = Fn.cfNumeric(wi_price);
    wi_price = eval(wi_price);


    var refund_price = eval(before_tot_price) - eval(wi_price) - eval(won_tot_price);
    refund_price = Fn.commaNum(refund_price);
    $("#refund_price").text(refund_price);
  }

  function goSubmit1() {
    if ($('#m1_take_date').val() == "") {
      alert("진행일을 입력해주세요");
      $('#m1_take_date').focus();
      return;
    }

    if ($('#m1_take_hour').val() == "") {
      alert("시작시간을 입력해주세요");
      $('#m1_take_hour').focus();
      return;
    }
    if ($('#m1_take_minute').val() == "") {
      alert("시작시간을 입력해주세요");
      $('#m1_take_minute').focus();
      return;
    }
    if (!$('#m1_trainer_seq option:selected').val()) {
      alert("진행트레이너를 선택해주세요");
      $('#m1_trainer_seq').focus();
      return;
    }

    var c1 = eval($('#m1_pt_cnt').val());
    var c2 = eval(result1?.dataRow?.pt_cnt - result1?.dataRow?.take_cnt);

    if (c1 > c2) {
      alert('진행하려는 PT횟수가 잔여횟수보다 많습니다');
      $('#m1_pt_cnt').val(1);
    }

    if (window.confirm('PT진행내역을 등록하시겠습니까?')) {
      axios2
        .postEx('/branchop/take/pt', {
          base_ap_no: $('#base_ap_no').val(),
          take_date: $('#m1_take_date').val(),
          take_hour: $('#m1_take_hour option:selected').val(),
          take_minute: $('#m1_take_minute option:selected').val(),
          pt_cnt: $('#m1_pt_cnt').val(),
          bigo: $('#m1_bigo').val(),
          gubun: $('#m1_gubun option:selected').val(),
          trainer_seq: $('#m1_trainer_seq option:selected').val(),
        })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          alert("등록되었습니다");
          setRenew(Fn.getRenewTime());
          setModal1Open(false);
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function goSubmit2() {
    //환불일자
    if (!$("#refund_date").val()) {
      alert("환불일자를 입력해주세요");
      return;
    }

    //진행단가
    if (!$("#cha_danga").val()) {
      alert("진행단가를 입력해주세요");
      return;
    }

    //위약금
    if (!$("#wi_price").val()) {
      alert("위약금을 입력해주세요");
      return;
    }

    //환불계좌
    if (!$("#payCard").val()) {
      alert("환불계좌를 선택해주세요");
      return;
    }

    var params = $("#form1").serialize();
    $.ajax({
      type: 'POST',
      url: '/branchop/pt/refund',
      data: params,
      success: function (data) {
        data = data?.message?.trim();

        if (data == "ok") {
          alert("처리되었습니다");
          setRenew(Fn.getRenewTime());
          setModal2Open(false);
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      }
    });
  }

  function goSubmit3() {
    //환불일자
    if (!$("#refund_date").val()) {
      alert("환불일자를 입력해주세요");
      return;
    }

    //환불계좌
    if (!$("#payCard").val()) {
      alert("환불계좌를 선택해주세요");
      return;
    }

    var params = $("#form2").serialize();
    $.ajax({
      type: 'POST',
      url: '/branchop/pt/refund/all',
      data: params,
      success: function (data) {
        data = data?.message?.trim();

        if (data == "ok") {
          alert("처리되었습니다");
          setRenew(Fn.getRenewTime());
          setModal3Open(false);
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      }
    })
  }

  function goSubmit4(insert_type) {
    const form4 = $('#form4')[0];

    if (form4.r_pt.value == "") {
      alert("접수 트레이너를 선택해주세요");
      form4.r_pt.focus();
      return;
    }

    if (form4.p_pt.value == "") {
      alert("진행 트레이너를 선택해주세요");
      form4.p_pt.focus();
      return;
    }

    if (!window.confirm("트레이너를 변경하시겠습니까?")) return;

    var params = $("#form4").serialize();
    $.ajax({
      type: 'POST',
      url: '/branchop/pt/trainer/change',
      data: params,
      success: function (data) {
        data = data?.message?.trim();

        if (data == "ok") {
          alert("처리되었습니다");
          setRenew(Fn.getRenewTime());
          setModal4Open(false);
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      }
    })
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2">
          <div className="pr-1 d-flex">
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
            </div>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="카드/성명 검색" id="s_mb_name"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="연락처 검색" id="s_mb_hp"/>
          </div>
          <div className="pr-1">
            <select className="form-select sel_lg" aria-label="Default select example" id="s_receipt_train">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select sel_lg" aria-label="Default select example" id="s_take_train">
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list table_wide_sh2">
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="60px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>성명</th>
              <th>카드번호</th>
              <th>연락처</th>
              <th>접수T</th>
              <th>진행T</th>
              <th>등록일</th>
              <th>횟수</th>
              <th>단가</th>
              <th>진행</th>
              <th>강제종료</th>
              <th>잔여</th>
              <th>최종진행</th>
              <th>진행등록</th>
              <th>부분환불</th>
              <th>전체환불</th>
            </tr>
            {rets && rets.map((ret, i) =>
              <tr style={{backgroundColor: Fn.parseInt(ret?.pt_cnt) < 0 ? "#eed3d7" : "#ffffff"}}>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  <input
                    type="checkbox"
                    name="chk"
                    value={btoa(ret?.ap_no)}
                    className="checkbox"
                    id={`achk${i + 1}`}
                  />
                </td>
                {/*<? if ($_SESSION[SystemBranchCode] == "ALL") { ?>*/}
                {/*<td><?= $row[branch_name] ?></td>*/}
                {/*<? } ?>*/}
                <td
                  style={{cursor: Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7 ? "pointer" : "default"}}
                  onClick={() => {
                    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7) {
                      if (!$('#auth_code').val()) {
                        alert("인증 코드를 입력해 주세요.");
                        return;
                      }
                      $('#base_ap_no').val(btoa(ret?.ap_no));
                      setModal4Open(true);
                    }
                  }}
                >{ret?.mb_name || '탈퇴회원'}</td>
                <td>{ret?.mb_id || ''}</td>
                <td>
                  <div id={`default_show${i + 1}`}>***-****-****</div>
                </td>
                <td>{ret?.receipt_name || ''}</td>
                <td>{ret?.take_train || ''}</td>
                <td>{ret?.ap_date || ''}</td>
                <td>{ret?.pt_cnt || ''}</td>
                <td>{Fn.numberWithCommas(ret?.pt_danga)}</td>
                <td>{ret?.takeCnt || '0'}</td>
                <td>{ret?.cancelCnt || ''}</td>
                <td>{(ret?.pt_cnt - ret?.takeCnt - ret?.cancelCnt) || '0'}</td>
                <td>{ret?.lastPtDate || ''}</td>
                <td>
                  {Fn.parseInt(ret?.pt_cnt - ret?.takeCnt - ret?.cancelCnt) > 0 && <button type="button" className="btn btn-ssm btn-point" onClick={() => {
                    $('#base_ap_no').val(btoa(ret?.ap_no));
                    setModal1Open(true);
                  }}>진행등록</button>}
                </td>
                <td>
                  {((localStorage.getItem("USER.systemAccountYN") === 'Y' || localStorage.getItem("USER.systemID") === 'futureinfo') && (ret?.pt_cnt - ret?.takeCnt - ret?.cancelCnt) > 0) &&
                    <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => {
                      if (!$('#auth_code').val()) {
                        alert('인증코드를 입력해주세요.');
                        return;
                      }
                      $('#base_ap_no').val(btoa(ret?.ap_no));
                      setModal2Open(true);
                    }}>부분환불</button>}
                </td>
                <td>
                  {((localStorage.getItem("USER.systemAccountYN") === 'Y' || localStorage.getItem("USER.systemID") === 'futureinfo') && (ret?.pt_cnt - ret?.takeCnt - ret?.cancelCnt) > 0) &&
                    <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => {
                      if (!$('#auth_code').val()) {
                        alert('인증코드를 입력해주세요.');
                        return;
                      }
                      $('#base_ap_no').val(btoa(ret?.ap_no));
                      setModal3Open(true);
                    }}>전체환불</button>}
                </td>
              </tr>)}
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div>
            <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => force_end()}>강제종료</button>
            <button type="button" className="btn btn-sm btn-outline-success mx-1" onClick={() => {
              if (eval("pageInfo?.totalArticles") > 0) {
                if (!window.confirm(`검색된 ${pageInfo?.totalArticles}명 회원에게 SMS를 발송하시겠습니까?`)) return;
              } else {
                if (!window.confirm(`검색된 회원에게 SMS를 발송하시겠습니까?`)) return;
              }

              $('#SMS_editMsgid').val('');
              $('#SMS_b_type').val("pt");
              $('#SMS_s_type').val("all");

              setSmsModalOpen(true);
            }}>검색 SMS 발송
            </button>
            <button type="button" className="btn btn-sm btn-outline-success"
                    onClick={() => {
                      if ($('[id^=achk]:checked').length === 0) {
                        window.alert('체크된 리스트가 없습니다.');
                        return;
                      }

                      let con = $('[id^=achk]:checked').map(function () {
                        return $(this).val();
                      }).get().join("|");

                      $('#SMS_editMsgid').val(con);
                      $('#SMS_b_type').val("pt");
                      $('#SMS_s_type').val("sel");

                      setSmsModalOpen(true);
                    }}
            >선택 SMS 발송
            </button>
          </div>
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
          {localStorage.getItem("USER.systemBranchCode") == "ALL" &&
            <div className="d-flex mx-1">
              <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="다운사유" id="down_reason"/>
              <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => Excel_pt_list()}>엑셀저장</button>
            </div>
          }
          {pageInfo?.totalArticles > 0 && (
            (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('pt_list')}>연락처 확인</button>
            )
          )}
        </div>
      </nav>

      {/*팝업 1*/}

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal1();
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: "900px"}}>
            <div className="POP_title">
              <h5><strong>{result1?.mInfoRow?.mb_name || ''} PT 진행 등록</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="mb-2">
                <table className="view-rounded sub_view">
                  <colgroup>
                    <col width="110px"/>
                    <col width="*"/>
                    <col width="110px"/>
                    <col width="*"/>
                    <col width="110px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>PT등록일</th>
                    <td id="m1_PT등록일">{result1?.dataRow?.ap_date}</td>
                    <th>PT횟수</th>
                    <td id="m1_PT횟수">{result1?.dataRow?.pt_cnt}</td>
                    <th>단가</th>
                    <td id="m1_단가">{Fn.numberWithCommas(result1?.dataRow?.pt_danga)}</td>
                  </tr>
                  <tr>
                    <th>접수트레이너</th>
                    <td id="m1_접수트레이너">{result1?.dataRow?.receipt_name}</td>
                    <th>진행트레이너</th>
                    <td id="m1_진행트레이너">{result1?.dataRow?.take_train}</td>
                    <th>잔여횟수</th>
                    <td id="m1_잔여횟수">{result1?.dataRow?.pt_cnt - result1?.dataRow?.take_cnt}</td>
                  </tr>
                </table>
              </div>
              <p className="bold800 my-2">PT 진행 상세등록</p>
              <table className="view-rounded sub_view">
                <colgroup>
                  <col width="110px"/>
                  <col width="*"/>
                  <col width="110px"/>
                  <col width="*"/>
                  <col width="110px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>진행일</th>
                  <td><JDatePicker type="date" className="form-control" aria-describedby="button-addon2" placeholder="" id="m1_take_date"/></td>
                  <th>시작시간</th>
                  <td>
                    <select className="form-select mx-1" style={{maxWidth: '65px'}} id="m1_take_hour">
                      {[...Array(24)].map((_, index) => {
                        const monthNumber = index;
                        const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                        return (
                          <option key={monthString} value={monthString}>{monthString}</option>
                        );
                      })}
                    </select>
                    <select className="form-select" style={{maxWidth: '65px'}} id="m1_take_minute">
                      {[...Array(60)].map((_, index) => {
                        const monthNumber = index;
                        const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                        return (
                          <option key={monthString} value={monthString}>{monthString}</option>
                        );
                      })}
                    </select>
                  </td>
                  <th>진행트레이너</th>
                  <td>
                    <select className="form-select" aria-label="Default select example" id="m1_trainer_seq">
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>진행횟수</th>
                  <td><input type="text" id="m1_pt_cnt" onBlur={(e) => chk_remain(e.target.value)}/></td>
                  <th>구분</th>
                  <td>
                    <select id="m1_gubun" className="form-select">
                      <option value="K">정상</option>
                      <option value="C">무단취소</option>
                    </select>
                  </td>
                  <th>비고</th>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" id="m1_bigo"/></td>
                </tr>
              </table>
            </div>

            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit1()}>진행등록</button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModal1Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      {/*팝업 2*/}

      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal2();
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: "900px"}}>
            <div className="POP_title">
              <h5><strong>{result2?.mInfoRow?.mb_name || ''} PT 환불 등록</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <form name="form1" id="form1" method="post">
                <input type="hidden" name="base_ap_no"/>
                <input type="hidden" name="auth_code"/>
                <div className="mb-2">
                  <table className="view-rounded sub_view">
                    <colgroup>
                      <col width="110px"/>
                      <col width="*"/>
                      <col width="110px"/>
                      <col width="*"/>
                      <col width="110px"/>
                      <col width="*"/>
                    </colgroup>
                    <tr>
                      <th>PT등록일</th>
                      <td id="m2_PT등록일" className="text-right">{result2?.dataRow?.ap_date}</td>
                      <th>계약 PT횟수</th>
                      <td id="m2_PT횟수" className="text-right">{result2?.dataRow?.pt_cnt}</td>
                      <th>계약 단가</th>
                      <td id="m2_단가" className="text-right">{Fn.numberWithCommas(result2?.dataRow?.pt_danga)}</td>
                    </tr>
                    <tr>
                      <th>PT금액</th>
                      <td id="m2_PT금액" className="text-right">{Fn.numberWithCommas(result2?.dataRow?.pt_danga * result2?.dataRow?.pt_cnt)}</td>
                      <th>부가세</th>
                      <td id="m2_부가세" className="text-right">{Fn.numberWithCommas(result2?.dataRow?.pt_danga * result2?.dataRow?.pt_cnt * 0.1)}</td>
                      <th>총 결제금액</th>
                      <td id="m2_총결제금액" className="text-right">{Fn.numberWithCommas(result2?.dataRow?.pt_danga * result2?.dataRow?.pt_cnt * 1.1)}</td>
                    </tr>
                    <tr>
                      <th>접수트레이너</th>
                      <td id="m2_접수트레이너" className="text-right">{result2?.dataRow?.receipt_name}</td>
                      <th>진행트레이너</th>
                      <td id="m2_진행트레이너" className="text-right">{result2?.dataRow?.take_train}</td>
                      <th></th>
                      <td></td>
                    </tr>
                  </table>
                </div>
                <p className="bold800 my-2">PT 환불등록</p>
                <table className="view-rounded sub_view">
                  <colgroup>
                    <col width="110px"/>
                    <col width="*"/>
                    <col width="110px"/>
                    <col width="*"/>
                    <col width="110px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>환불일자</th>
                    <td><JDatePicker type="date" className="form-control" aria-describedby="button-addon2" placeholder="" name="refund_date" id="refund_date" value={Fn.getCurrentDate()}/></td>
                    <th>진행횟수</th>
                    <td id="m2_환불_진행횟수" className="text-right"><span id="cha_cnt">{Fn.numberWithCommas(result2?.dataRow?.take_cnt)}</span><input type="hidden" name="cha_cnt" value={result2?.dataRow?.take_cnt}/></td>
                    <th>진행단가</th>
                    <td>
                      <input type="text" id="cha_danga" name="cha_danga" className="form-control cls-type-fmtnum-ex text-right" onKeyUp={(e) => {
                        cal_price();
                      }}/>
                    </td>
                  </tr>
                  <tr>
                    <th>진행금액</th>
                    <td id="m2_환불_진행금액" className="text-right"><span id="ing_pt_price">{Fn.numberWithCommas(result2?.dataRow?.take_cnt * result2?.dataRow?.pt_danga)}</span></td>
                    <th>진행금액</th>
                    <td id="m2_환불_부가세" className="text-right"><span id="ing_add_price">{Fn.numberWithCommas(result2?.dataRow?.take_cnt * result2?.dataRow?.pt_danga * 0.1)}</span></td>
                    <th>총 진행금액</th>
                    <td id="m2_환불_총진행금액" className="text-right"><span id="ing_tot_price">{Fn.numberWithCommas(result2?.dataRow?.take_cnt * result2?.dataRow?.pt_danga * 1.1)}</span></td>
                  </tr>
                  <tr>
                    <th>위약금</th>
                    <td>
                      <input type="text" id="wi_price" name="wi_price" className="form-control cls-type-fmtnum-ex text-right" onKeyUp={(e) => {
                        cal_price();
                      }}/>
                    </td>
                    <th>기 결제금액</th>
                    <td id="m2_환불_기결제금액" className="text-right">
                      {Fn.numberWithCommas(result2?.dataRow?.pt_cnt * result2?.dataRow?.pt_danga * 1.1)}
                      <input type="hidden" id="before_tot_price1" name="before_tot_price1" value={Fn.parseInt(result2?.dataRow?.pt_cnt * result2?.dataRow?.pt_danga * 1.1)}/>
                    </td>
                    <th>총 환불금액</th>
                    <td id="m2_환불_총환불금액" className="text-right">
                    <span id="refund_price">
                      {Fn.numberWithCommas(result2?.dataRow?.pt_cnt * result2?.dataRow?.pt_danga * 1.1 - (result2?.dataRow?.pt_cnt * result2?.dataRow?.pt_danga * 1.1) * 0.1 - result2?.dataRow?.take_cnt * result2?.dataRow?.pt_danga * 0.1)}
                    </span>
                    </td>
                  </tr>
                  <tr>
                    <th>결제수단</th>
                    <td>
                      <select className="form-select" name="payCard" id="payCard">
                        <option value=''>선택</option>
                        <option value='1111'>현금</option>
                        {result2?.pc1?.map((item, index) => <option value={`${item?.j_no},${item?.j_jukname},${item?.j_gyecode}`}>(계좌) {item.j_jukname}</option>)}
                        {result2?.pc2?.map((item, index) => <option value={`${item?.j_no},${item?.j_jukname}`}>{item.j_jukname}Card</option>)}
                      </select>
                    </td>
                    <th>금액비고</th>
                    <td colSpan={3}><input type="text" name="ip_etc" id="ip_etc" className="form-control"/></td>
                  </tr>
                </table>
              </form>
            </div>

            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit2()}>진행등록</button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModal2Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      {/*팝업 3*/}

      <Modal
        isOpen={modal3Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal3();
        }}
        onRequestClose={() => setModal3Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: "900px"}}>
            <div className="POP_title">
              <h5><strong>{result3?.mInfoRow?.mb_name || ''} PT 환불 등록</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal3Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <form name="form2" id="form2" method="post">
                <input type="hidden" name="base_ap_no"/>
                <input type="hidden" name="auth_code"/>
                <div className="mb-2">
                  <table className="view-rounded sub_view">
                    <colgroup>
                      <col width="110px"/>
                      <col width="*"/>
                      <col width="110px"/>
                      <col width="*"/>
                      <col width="110px"/>
                      <col width="*"/>
                    </colgroup>
                    <tr>
                      <th>PT등록일</th>
                      <td id="m3_PT등록일" className="text-right">{result3?.dataRow?.ap_date}</td>
                      <th>계약 PT횟수</th>
                      <td id="m3_PT횟수" className="text-right">{result3?.dataRow?.pt_cnt}</td>
                      <th>계약 단가</th>
                      <td id="m3_단가" className="text-right">{Fn.numberWithCommas(result3?.dataRow?.pt_danga)}</td>
                    </tr>
                    <tr>
                      <th>PT금액</th>
                      <td id="m3_PT금액" className="text-right">{Fn.numberWithCommas(result3?.dataRow?.pt_danga * result3?.dataRow?.pt_cnt)}</td>
                      <th>부가세</th>
                      <td id="m3_부가세" className="text-right">{Fn.numberWithCommas(result3?.dataRow?.pt_danga * result3?.dataRow?.pt_cnt * 0.1)}</td>
                      <th>총 결제금액</th>
                      <td id="m3_총결제금액" className="text-right">{Fn.numberWithCommas(result3?.dataRow?.pt_danga * result3?.dataRow?.pt_cnt * 1.1)}</td>
                    </tr>
                    <tr>
                      <th>접수트레이너</th>
                      <td id="m3_접수트레이너" className="text-right">{result3?.dataRow?.receipt_name}</td>
                      <th>진행트레이너</th>
                      <td id="m3_진행트레이너" className="text-right">{result3?.dataRow?.take_train}</td>
                      <th></th>
                      <td></td>
                    </tr>
                  </table>
                </div>
                <p className="bold800 my-2">PT 환불등록</p>
                <table className="view-rounded sub_view">
                  <colgroup>
                    <col width="110px"/>
                    <col width="*"/>
                    <col width="110px"/>
                    <col width="*"/>
                    <col width="110px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>환불일자</th>
                    <td><JDatePicker type="date" className="form-control" aria-describedby="button-addon2" placeholder="" name="refund_date" id="refund_date" value={Fn.getCurrentDate()}/></td>
                    <th>총 환불금액</th>
                    <td id="m3_환불_총환불금액" className="text-right">{Fn.numberWithCommas(result3?.dataRow?.pt_danga * result3?.dataRow?.pt_cnt)}</td>
                    <th>결제수단</th>
                    <td>
                      <select className="form-select" name="payCard" id="payCard">
                        <option value=''>선택</option>
                        <option value='1111'>현금</option>
                        {result3?.pc1?.map((item, index) => <option value={`${item?.j_no},${item?.j_jukname},${item?.j_gyecode}`}>(계좌) {item.j_jukname}</option>)}
                        {result3?.pc2?.map((item, index) => <option value={`${item?.j_no},${item?.j_jukname}`}>{item.j_jukname}Card</option>)}
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>금액비고</th>
                    <td colSpan={5}><input type="text" name="ip_etc" id="ip_etc" className="form-control"/></td>
                  </tr>
                </table>
                <input type="hidden" id="before_tot_price2" name="before_tot_price2" value={Fn.parseInt(result3?.dataRow?.pt_danga * result3?.dataRow?.pt_cnt * 1.1)}/>
              </form>
            </div>

            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit3()}>진행등록</button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModal3Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      {/*팝업 3*/}

      <Modal
        isOpen={modal4Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          $('#form4')[0].base_ap_no.value = $('#base_ap_no').val();
          $('#form4')[0].auth_code.value = $('#auth_code').val();
          loadModal4();
        }}
        onRequestClose={() => setModal4Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: "600px"}}>
            <div className="POP_title">
              <h5><strong>{result3?.mInfoRow?.mb_name || ''} PT 트레이너 변경</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal4Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <form name="form4" id="form4" method="post">
                <input type="hidden" name="base_ap_no"/>
                <input type="hidden" name="auth_code"/>
                <div className="mb-2">
                  <table className="view-rounded sub_view">
                    <colgroup>
                      <col width="110px"/>
                      <col width="*"/>
                      <col width="110px"/>
                      <col width="*"/>
                    </colgroup>
                    <tr>
                      <th>접수트레이너</th>
                      <td id="m4_접수트레이너" className="text-right">
                        <select className="form-select" name="r_pt" id="r_pt">
                        </select>
                      </td>
                      <th>진행트레이너</th>
                      <td id="m4_진행트레이너" className="text-right">
                        <select className="form-select" name="p_pt" id="p_pt">
                        </select>
                      </td>
                    </tr>
                  </table>
                </div>
              </form>
            </div>

            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit4()}>진행등록</button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModal4Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <SmsNew
        isOpen={smsModalOpen}
        onModalClose={() => setSmsModalOpen(false)}
        searchType={"sms_list"}
        callback={() => {
          setSmsModalOpen(false);
        }}
      />
      <input type="hidden" id="SMS_b_type"/>
      <input type="hidden" id="SMS_s_type"/>
      <input type="hidden" id="SMS_editMsgid"/>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

      <input type="hidden" id="base_ap_no" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default BranchPtPTList;
