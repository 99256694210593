import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import JDatePicker from "../common/JDatePicker";
import {useHistory, useLocation} from "react-router-dom";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";


const ConfigResearchCopy = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const location = useLocation();
  const history = useHistory();
  const baseSeq = location?.state?.baseSeq || '';
  const maxCnt = 10;

  const [form1, setForm1] = useState();
  const [result, setResult] = useState({});

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    setForm1($("form[name='form1']")[0]);
    let form1 = $("form[name='form1']")[0];

    axios2
      .post(`/config/research/write/new/ready?base_seq=${baseSeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          console.log(result);

          $('#start_date').val(result?.row?.start_date);
          $('#end_date').val(result?.row?.end_date);
          form1.add_point.value = result?.row?.add_point;
          form1.title.value = result?.row?.title;

          for (let cnt = 1; cnt <= result?.row?.qna_count; cnt++) {
            $(`input[name='question${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.question);

            $(`input[name='answer1_${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.answer1);
            $(`input[name='answer2_${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.answer2);
            $(`input[name='answer3_${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.answer3);
            $(`input[name='answer4_${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.answer4);
            $(`input[name='answer5_${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.answer5);
            $(`input[name='answer6_${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.answer6);
            $(`input[name='answer7_${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.answer7);
            $(`input[name='answer8_${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.answer8);
            $(`input[name='answer9_${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.answer9);
            $(`input[name='answer10_${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.answer10);
            $(`input[name='answer11_${cnt}']`).val(result?.qnaRs?.[cnt - 1]?.answer11);

            if (result?.row?.display_flag === 'Y') $('#radio_show_y').prop("checked", true);
            else $('#radio_show_n').prop("checked", true);

            if (result?.qnaRs?.[cnt - 1]?.recomm_YN === 'Y')
              $(`input[name='question${cnt}']`).siblings('div').find('[name=recomm_YN]').prop('checked', true);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const go_submit = () => {
    for (let i = 0; i < form1.max_cnt.value; i++) {
      if (form1.recomm_YN[i].checked === true) {
        var recomm_cnt = i;
        break;
      }
    }

    recomm_cnt = eval(recomm_cnt) + eval("1");

    if (form1.title.value === "") {
      alert("제목을 입력해주세요");
      form1.title.focus();
      return;
    }
    if (form1.start_date.value === "") {
      alert("시작일을 입력해주세요");
      form1.start_date.focus();
      return;
    }
    if (form1.end_date.value === "") {
      alert("종료일을 입력해주세요");
      form1.end_date.focus();
      return;
    }

    let cnt = 1;

    if (eval("form1.question" + cnt).value === "") {
      alert("첫번째 질문을 입력해주세요");
      return;
    }

    form1.recomm_cnt.value = recomm_cnt;

    var formData = new FormData(form1);
    $.ajax({
      url: '/config/research/write/new',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data?.message?.trim();
        if (data == "err") {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        } else {
          alert('복사되었습니다');
          history.push("/config/research/list");
        }
      }
    });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <form name="form1" id="form1">
        <div className="m-3" style={{border: "1px solid #eee", borderRadius: "12px"}}>

          <div className="border-bottom text-left">
            <p className="px-3 py-2"><span className="h6 bold700">설문조사 등록/수정</span></p>
          </div>

          <div className="p-3">
            <table className="view-rounded sub_table_border">
              <colgroup>
                <col width="120px"/>
                <col width="*"/>
                <col width="120px"/>
                <col width="*"/>
                <col width="120px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>제목</th>
                <td colSpan="5" className="text-left">
                  <input type="text" name="title" className="form-control" aria-describedby="button-addon2" placeholder="제목입력"/>
                </td>
              </tr>
              <tr>
                <th>설문조사기간</th>
                <td className="text-left">
                  <div className="input-group" style={{width: 'auto'}}>
                    <JDatePicker name="start_date" id="start_date" className="form-control" aria-describedby="button-addon2"/>
                    <p className="mx-2">~</p>
                    <JDatePicker name="end_date" id="end_date" className="form-control" aria-describedby="button-addon2"/>
                  </div>
                </td>
                <th>지급포인트</th>
                <td className="text-left"><input name="add_point" type="number" className="form-control"/></td>
                <th>전시여부</th>
                <td className="text-left">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" name="display_flag" type="radio" value="Y" id="radio_show_y"/>
                    <label className="form-check-label" htmlFor="radio_show_y">전시</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" name="display_flag" type="radio" value="N" id="radio_show_n"/>
                    <label className="form-check-label" htmlFor="radio_show_n">미전시</label>
                  </div>
                </td>
              </tr>
            </table>

            {Array.from({length: maxCnt}, (_, index) => index + 1).map((i) => (
              <table className="view-rounded sub_table_border my-3">
                <colgroup>
                  <col width="150px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th className="sub">
                    <p className="p-1 mx-3">질문{i}</p>
                  </th>
                  <th className="sub">
                    <div className="d-flex">
                      <input
                        type="text"
                        className="form-control form-white"
                        style={{width: 'calc(100% - 250px)'}}
                        aria-describedby="button-addon2"
                        placeholder="질문 입력"
                        name={`question${i}`}
                      />
                      <div className="form-check form-check-inline mx-2">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="recomm_YN"
                          value={`${i}`}
                        />
                        <label className="form-check-label" htmlFor="inlineRadio2">
                          추천지수적용
                        </label>
                      </div>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>선택항목1</th>
                  <td className="text-left">
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="button-addon2"
                      placeholder="객관식선택항목"
                      name={`answer1_${i}`}
                    />
                  </td>
                </tr>
                <tr>
                  <th>선택항목2</th>
                  <td className="text-left">
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="button-addon2"
                      placeholder="객관식선택항목"
                      name={`answer2_${i}`}
                    />
                  </td>
                </tr>
                <tr>
                  <th>선택항목3</th>
                  <td className="text-left">
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="button-addon2"
                      placeholder="객관식선택항목"
                      name={`answer3_${i}`}
                    />
                  </td>
                </tr>
                <tr>
                  <th>선택항목4</th>
                  <td className="text-left">
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="button-addon2"
                      placeholder="객관식선택항목"
                      name={`answer4_${i}`}
                    />
                  </td>
                </tr>
                <tr>
                  <th>선택항목5</th>
                  <td className="text-left">
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="button-addon2"
                      placeholder="객관식선택항목"
                      name={`answer5_${i}`}
                    />
                  </td>
                </tr>
                {i === 1 &&
                  <>
                    <tr>
                      <th>선택항목6</th>
                      <td className="text-left">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="button-addon2"
                          placeholder="객관식선택항목"
                          name={`answer6_${i}`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>선택항목7</th>
                      <td className="text-left">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="button-addon2"
                          placeholder="객관식선택항목"
                          name={`answer7_${i}`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>선택항목8</th>
                      <td className="text-left">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="button-addon2"
                          placeholder="객관식선택항목"
                          name={`answer8_${i}`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>선택항목9</th>
                      <td className="text-left">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="button-addon2"
                          placeholder="객관식선택항목"
                          name={`answer9_${i}`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>선택항목10</th>
                      <td className="text-left">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="button-addon2"
                          placeholder="객관식선택항목"
                          name={`answer10_${i}`}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>선택항목11</th>
                      <td className="text-left">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="button-addon2"
                          placeholder="객관식선택항목"
                          name={`answer11_${i}`}
                        />
                      </td>
                    </tr>
                  </>
                }
              </table>
            ))}
          </div>
        </div>

        <div className="my-3 mb-5">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>
            복사
          </button>
          <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.push("/config/research/list")}>
            목록
          </button>
        </div>

        <input type="hidden" name="recomm_cnt"/>
        <input type="hidden" name="max_cnt" value={maxCnt}/>

      </form>
    </CmnCard>
  );
};

export default ConfigResearchCopy;
