import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import ChkAuthNum from "../common/ChkAuthNum";
import Modal from "react-modal";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import {useHistory} from "react-router-dom";


const MemberToreciveReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);

  const [result1, setResult1] = useState({});
  const [result, setResult] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [searchYN, setSearchYN] = useState('N');
  const [form1, setForm1] = useState();
  const [payform, setPayform] = useState();
  const [searchType, setSearchType] = useState('yangdo');
  const [modalCurrPage, setModalCurrPage] = useState(1);
  const [modalPageInfo, setModalPageInfo] = useState();

  useEffect(() => {
    // if (!srchRenew) return;
    load();
    if (modalOpen) {
      loadModal(1);
    }
  }, [srchRenew]);

  useEffect(() => {
    if (modalOpen) {
      loadModal();
    }
  }, [modalCurrPage, renew]);

  const load = (_page) => {
    setForm1($("form[name='form1']")[0]);
    const form1 = $("form[name='form1']")[0];

    setPayform($("form[name='payform']")[0]);
    const payform = $("form[name='payform']")[0];

    axios2
      .post(`/member/toreceive/reg/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult1(result);
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = (_page) => {
    axios2
      .post(`/member/transfer/search/member/ready?search_YN=${searchYN}&search_type=${$('#search_type').val()}&do_id=${$('#do_id').val() || ''}&key=${$('#key').val() || ''}&page=${_page || modalCurrPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          console.log(result);
          setModalPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function search_member1() {
    if ($('#auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
      return;
    }

    setSearchYN('N');

    $('#search_type').val('yangdo');
    setSearchType('yangdo');
    $('#do_id').val('');
    setModalOpen(true);
  }

  function search_member2() {
    if ($('#auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
      return;
    }

    if (!form1.mb_no.value) {
      alert('양도인을 먼저 선택해주세요');
      $('#auth_code').focus();
      return;
    }

    setSearchYN('N');

    $('#search_type').val('yangsu');
    setSearchType('yangsu');
    $('#do_id').val(form1.mb_no.value);
    setModalOpen(true);
  }

  function able_show() {
    //양도가능한 항목 보여주기 및 체크
    $('#do_list').html('');

    var do_mb_no = form1.mb_no.value;
    var su_mb_no = form1.mb_no_se.value;

    if (do_mb_no && su_mb_no) {
      $.ajax({
        type: "post",
        url: "/member/do/list",
        data: "mb_no=" + do_mb_no + "&su_mb_no=" + su_mb_no,
        success: function (data) {
          // TODO
          console.log(data);

          $('#h_add_date_html').html((data?.result?.hAddDate || '0') + "일");
          $('#s_add_date_html').html((data?.result?.sAddDate || '0') + "일");
          $('#l_add_date_html').html((data?.result?.lAddDate || '0') + "일");
          $('#p_add_date_html').html((data?.result?.remainPT || '0회'));

          let d = "";

          d = (data?.result?.hAddDate || '0');
          if (d == 0 || d == "0일") $('#check_all').prop('disabled', true);
          else $('#check_all').prop('disabled', false);

          d = (data?.result?.sAddDate || '0');
          if (d == 0 || d == "0일") $('#check_1').prop('disabled', true);
          else $('#check_1').prop('disabled', false);

          d = (data?.result?.lAddDate || '0');
          if (d == 0 || d == "0일") $('#check_2').prop('disabled', true);
          else $('#check_2').prop('disabled', false);

          d = (data?.result?.remainPT || '0');
          if (d == 0 || d == "0회" || d == "0 회") {
            $('#check_3').prop('disabled', true);
            $('#p_pt').hide();
          } else {
            $('#check_3').prop('disabled', false);

            let optionsHTML = '<option value="">진행트레이너 선택</option>';
            data?.result?.trList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#p_pt').html(optionsHTML).show();
          }
        }
      });
    }
  }

  function copy_data1(mb_no, mb_id, mb_name, mb_hp, health_show, suit_show, locker_show, remain_pt) {
    console.log(mb_no, mb_id, mb_name, mb_hp, health_show, suit_show, locker_show, remain_pt);
    form1.mb_no.value = mb_no
    $('#b_mb_name')[0].innerHTML = mb_name
    $('#b_mb_hp')[0].innerHTML = mb_hp
    $('#b_mb_id')[0].innerHTML = mb_id
    $('#b_health_date')[0].innerHTML = health_show;
    $('#b_suit_date')[0].innerHTML = suit_show;
    $('#b_locker_date')[0].innerHTML = locker_show;
    $('#b_remain_PT')[0].innerHTML = remain_pt + "회";

    //양수자 리셋
    form1.mb_no_se.value = "";
    $('#a_mb_name')[0].innerHTML = "";
    $('#a_mb_hp')[0].innerHTML = "";
    $('#a_mb_id')[0].innerHTML = "";
    $('#a_health_date')[0].innerHTML = "";
    $('#a_suit_date')[0].innerHTML = "";
    $('#a_locker_date')[0].innerHTML = "";
    form1.input_btn.style.display = "none";

    able_show();

    setModalOpen(false);
  }

  function copy_data2(mb_no, mb_id, mb_name, mb_hp, health_show, suit_show, locker_show) {
    $.ajax({
      type: "post",
      url: "/member/do/list/chk",
      data: `do_id=${form1.mb_no.value}&su_id=${mb_no}`,
      success: function (data) {
        console.log(data);
        //alert(data);return;
        data = data?.message?.trim();
        if (data == "ok") {
          form1.mb_no_se.value = mb_no
          $('#a_mb_name').html(mb_name)
          $('#a_mb_hp').html(mb_hp)
          $('#a_mb_id').html(mb_id)
          $('#a_health_date').html(health_show)
          $('#a_suit_date').html(suit_show);
          $('#a_locker_date').html(locker_show);
          form1.input_btn.style.display = "inline";
          able_show();
          setModalOpen(false);
        } else if (data == "no_brand") {
          alert('하위 브랜드에서 상위 브랜드로 양도하실수 없습니다');
        } else if (data == "ing") {
          alert('기존작업이 진행중입니다. 양도양수 메뉴를 다시 클릭후 확인해주세요');
        } else if (data == "ing2") {
          alert('기존작업이 진행중입니다. 양도양수 메뉴를 다시 클릭후 확인해주세요');
        }
      }
    });
  }

  function cal_price(price) {
    var SAL_PRICE = 0;
    var SAL_VAT = 0;

    price = Fn.cfNumeric(price);

    let SAL_AMT = price; // 판매금액

    SAL_VAT = ((SAL_AMT / 1.1) * 0.1);    // 부가세(VAT)
    SAL_PRICE = Math.round(SAL_AMT - SAL_VAT); // 단가(반올림)
    SAL_VAT = Math.round(SAL_VAT);    // 부가세(반올림)

    payform.edtAmount.value = SAL_PRICE;
    payform.edtTax.value = SAL_VAT;
  }

  function pre_go_submit() {
    //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
    if ($('#auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      $('#search_auth_code').focus();
      return;
    }
    if (form1.mb_no.value == "") {
      alert('양도자를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      form1.mb_no.focus();
      return;
    }
    if (form1.mb_no_se.value == "") {
      alert('양수자를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      form1.mb_no_se.focus();
      return;
    }
    if (form1.ipkumok[0].checked == false && form1.ipkumok[1].checked == false) {
      alert('입금여부를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      return;
    }
    /*
    if (form1.ip_method.value == "1143,카드매출금" && form1.ip_cardName.value == "") {
      alert('카드사를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      return;
    }
    if (form1.ip_method.value == "1112,보통예금" && form1.ip_bank.value == "") {
      alert('계좌를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      return;
    }
    */

    // 보완 로직 (2023.11.07)
    {
      if (form1.able_end.checked) $('#_able_end').val("Y");
      else $('#_able_end').val("N");

      if (form1.able_suit_end.checked) $('#_able_suit_end').val("Y");
      else $('#_able_suit_end').val("N");

      if (form1.able_locker_end.checked) $('#_able_locker_end').val("Y");
      else $('#_able_locker_end').val("N");

      if (form1.able_pt.checked) $('#_able_pt').val("Y");
      else $('#_able_pt').val("N");

      if ($('#_able_end').val() != "Y" && $('#_able_suit_end').val() != "Y" && $('#_able_locker_end').val() != "Y" && $('#_able_pt').val() != "Y") {
        alert('양도항목을 선택해주세요');
        return;
      }
    }

    if (form1.able_end.checked == false && form1.able_suit_end.checked == false && form1.able_locker_end.checked == false && form1.able_pt.checked == false) {
      alert('양도항목을 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      return;
    }

    if (form1.able_pt.checked == true && form1.p_pt.value == "") {
      alert('진행트레이너를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      return;
    }

    let dd = form1.payCard.value.split(",");
    let dd_size = dd.length;

    if (form1.payCard.value == "2222" && form1.ipkumok[1].checked == true) {
      //카드결제일경우 금액 필수입력

      //결제금액이 5만원 이하이면서 할부불가처리
      let input_price = form1.ip_amt.value;
      input_price = Fn.cfNumeric(input_price);

      //할부개월
      let month_card = form1.month_card.value;
      month_card = Fn.parseInt(month_card);

      if (input_price < 50000 && month_card > 1) {
        alert("할부결제는 5만원 이상부터 가능합니다.");
        //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
        return;
      }
      //카드결제 항목 초기화
      //edtPayID.value = "";		//전문관리번호
      payform.edtAmount.value = 0;		//전문관리번호
      payform.edtTax.value = 0;			//부가세
      payform.edtInstallment.value = "00";//할부개월

      //1.전문관리번호 입력하기
      //new_time = nds();
      //new_time  = new_time.trim();

      //edtPayID.value = new_time;

      //2.결제금액 입력
      var pay_price = form1.ip_amt.value;
      //3.단가/부가세 입력
      cal_price(pay_price);

      //4. 할부개월 입력하기
      payform.edtInstallment.value = form1.month_card.value;

      //$("#radioAuthType1").prop('checked', true);   //라디오버튼 체크

      $("#authType").val("1010");		//신용카드

      writeTo();
    } else if (form1.payCard.value == "1111" && form1.ipkumok[1].checked == true) {
      if (localStorage.getItem("USER.systemID") == "testjbj") {
        if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 8 || localStorage.getItem("USER.systemAccountYN") == "Y") {
        } else {
          if (form1.cash_p.checked == false) {
            alert("현금영수증은 필수사항입니다.");
            return;
          }
        }
      }

      if (form1.cash_p.checked == true && result1?.bOptRow?.TID) {
        //카드결제 항목 초기화
        //edtPayID.value = "";		//전문관리번호
        payform.edtAmount.value = 0;		//전문관리번호
        payform.edtTax.value = 0;			//부가세
        payform.edtInstallment.value = "00";//할부개월

        //1.전문관리번호 입력하기
        //new_time = nds();
        //new_time  = new_time.trim();
        //edtPayID.value = new_time;

        //2.결제금액 입력
        var pay_price = form1.ip_amt.value;

        //3.단가/부가세 입력
        cal_price(pay_price);

        $("#authType").val("5010");		//현금영수증
        writeTo();
      } else {
        go_submit();
      }
    } else {
      if (dd_size == "3" && form1.ipkumok[1].checked == true) {
        if (localStorage.getItem("USER.systemID") == "testjbj") {
          if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 8 || localStorage.getItem("USER.systemAccountYN") == "Y") {
          } else {
            if (form1.cash_p.checked == false) {
              alert("현금영수증은 필수사항입니다.");
              return;
            }
          }
        }
      }

      go_submit();
    }
  }

  function go_submit() {
    //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
    if ($('#auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      $('#search_auth_code').focus();
      return;
    }
    if (form1.mb_no.value == "") {
      alert('양도자를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      form1.mb_no.focus();
      return;
    }
    if (form1.mb_no_se.value == "") {
      alert('양수자를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      form1.mb_no_se.focus();
      return;
    }
    if (form1.ipkumok[0].checked == false && form1.ipkumok[1].checked == false) {
      alert('입금여부를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      return;
    }
    /*
    if (form1.ip_method.value == "1143,카드매출금" && form1.ip_cardName.value == "") {
      alert('카드사를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      return;
    }
    if (form1.ip_method.value == "1112,보통예금" && form1.ip_bank.value == "") {
      alert('계좌를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      return;
    }
    */

    if (form1.able_end.checked == false && form1.able_suit_end.checked == false && form1.able_locker_end.checked == false && form1.able_pt.checked == false) {
      alert('양도항목을 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      return;
    }

    if (form1.able_pt.checked == true && form1.p_pt.value == "") {
      alert('진행트레이너를 선택해주세요');
      //input_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='pre_go_submit()'><span class='glyphicon glyphicon-pencil'></span>양도양수 저장</a>";
      return;
    }

    form1.auth_code.value = $('#auth_code').val();
    // form1.test.value = test;

    var formData = new FormData(form1);
    $.ajax({
      url: '/member/toreceive/reg',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        console.log(data);
        data = data?.message?.trim();
        if (data == "err") {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        } else if (data == "no_brand") {
          alert('하위 브랜드에서 상위 브랜드로 양도하실수 없습니다');
        } else if (data == "ing") {
          alert('기존작업이 진행중입니다. 양도양수 메뉴를 다시 클릭후 확인해주세요');
        } else if (data == "ing2") {
          alert('기존작업이 진행중입니다. 양도양수 메뉴를 다시 클릭후 확인해주세요');
        } else {
          let split = data.split("||");

          if (split?.[1] == "Y") {
            alert('등록되었습니다');
            history.push("/member/toreceive");
          } else {
            alert('등록되었습니다');
            history.push("/member/toreceive");
          }
        }
      }
    });
  }


  //<!-- POS 관련 -->

  function getTime_YYMMDDHHMMSS() {
    var today = new Date();

    var year = today.getFullYear()
    var month = ('0' + (today.getMonth() + 1)).slice(-2);
    var day = ('0' + today.getDate()).slice(-2);

    var hours = ('0' + today.getHours()).slice(-2);
    var minutes = ('0' + (today.getMinutes() + 1)).slice(-2);
    var seconds = ('0' + today.getSeconds()).slice(-2);

    var time = year + month + day + hours + minutes + seconds;

    return time.slice(-12);
  }

  function jsonInsert(json, field, value) {
    if (json.length == 0) {
      json = '{"';
      json += field;
      json += '":"';
      json += value;
      json += '"}';
    } else {
      json = json.substr(0, json.length - 1) + ',"';
      json += field;
      json += '":"';
      json += value;
      json += '"}';
    }
    return json;
  }

  function ShowLoadingImage() {
    //결제시 이미지 표시 - 미사용
    //document.getElementById('btn_send').disabled = true;
    //document.all.LoadingPageLayer.style.visibility = "visible";
    //document.all.LoadingImageLayer.style.visibility = "visible";
  }

  function HideLoadingImage() {
    //document.getElementById('btn_send').disabled = false;
    //document.all.LoadingPageLayer.style.visibility = "hidden";
    //document.all.LoadingImageLayer.style.visibility = "hidden";
  }


  var authType;
  var payType;

  function ParseHttpResponse(rtn)	//결제결과 파싱
  {
    // 스트링 utf-8 -> multibyte 변환 필요
    //console.log('응답전문 길이: ' + (String(rtn).indexOf(String.fromCharCode(13)) + 1));

    // json 전문
    if (String(rtn).substr(0, 1) == '{') {
      let rtn_ele_cr = rtn.replace(String.fromCharCode(0x0d), '');
      console.log(rtn_ele_cr);
      const obj = JSON.parse(rtn_ele_cr);


      if (obj.ERROR_CHECK_RESULT == 'S' && obj.PKTYPE == 'S' && obj.REPLY == '0000') {
        // 신용
        if (obj.MSGTYPE == '1010' || obj.MSGTYPE == '1050') {
          // 카카오페이 신용
          if (obj.ADDINFO2.slice(0, 3) == 'KKO') {
            payform.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            payform.edtAuthNo.value = String(obj.MESSAGE).substr(0, 10);
            payform.edtAuthUniqueNo.value = '';
          }
          // SSG페이 신용
          else if (obj.ADDINFO2.slice(0, 3) == 'SSG') {
            payform.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            payform.edtAuthNo.value = String(obj.MESSAGE).substr(0, 12) + fillSpace(3);
            payform.edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
          }
          // 일반 신용
          else {
            payform.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            payform.edtAuthNo.value = String(obj.MESSAGE).substr(0, 12);
            payform.edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
          }
        }

        // 현금영수증
        else if (obj.MSGTYPE == '5010' || obj.MSGTYPE == '5050') {
          payform.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
          payform.edtAuthNo.value = String(obj.MESSAGE).substr(0, 12);
          payform.edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
        }

        var a17 = obj.ACQUIERCD;
        var a4 = obj.TID;
        var a14 = obj.MERCHANTNO;
        var a21 = obj.TRACK2DATA;

        var d1 = String(obj.MESSAGE).substr(0, 12);
        var d2 = String(obj.MESSAGE).substr(12, 12);

        var a19 = obj.CARDKBN;

        var d3 = String(obj.MESSAGE).substr(24, 12);

        var a16 = obj.ISSUER;
        var a18 = obj.ACQUIER;


        var won_rtn = rtn;


        var purchase_code = a17;		//매입사코드
        var machine_no = a4;			//단말기번호
        var company_no = a14;			//가맹점번호
        var card_no = a21;				//카드번호
        var permit_no = d1;				//승인번호
        var permit_time = d2;			//승인일시
        var card_gubun = a19;	//카드구분
        var unique_no = d3;		//거래고유번호
        //발급사코드

        form1.purchase_code.value = purchase_code;				//발급사코드
        form1.machine_no.value = machine_no;					//단말기번호
        form1.company_no.value = company_no;					//가맹점번호
        form1.card_no.value = card_no;							//카드번호
        form1.permit_no.value = permit_no;						//승인번호
        form1.permit_time.value = permit_time;					//승인일시
        form1.card_gubun.value = card_gubun;					//카드구분
        form1.unique_no.value = unique_no;						//거래고유번호


        //영수증 출력
        if (form1.payCard.value == "2222")	//카드결제
        {
          var month_card = form1.month_card.value;
        } else {
          var month_card = "00";
        }

        var price = form1.ip_amt.value;

        // 2023.10.22 수정
        var c1 = result1?.bOptRow?.branch_name;
        var c2 = result1?.bInfoRow?.company_no;
        var c3 = "이규정";
        var c4 = result1?.bInfoRow?.address;
        var c5 = result1?.bInfoRow?.tel;

        var c6 = "";
        c6 = a21.substr(0, 4) + "-" + a21.substr(4, 4) + "-" + a21.substr(8, 4) + "-" + a21.substr(12, 4);

        if (form1.payCard.value == "2222")	//카드결제
        {
          var c7 = a16.trim();
          var c8 = a18.trim();
        } else {
          var c7 = a16;
          var c8 = a18;
        }

        var c9 = "";
        c9 = d2.substr(0, 2) + "/" + d2.substr(2, 2) + "/" + d2.substr(4, 2) + " " + d2.substr(6, 2) + ":" + d2.substr(8, 2) + ":" + d2.substr(10, 2);

        var c10 = "";
        if (a19 == "CK") {
          c10 = "신용승인("
          if (month_card == "00") {
            c10 += "일시불)";
          } else {
            c10 += "할부" + month_card + "개월)";
          }
        } else {
          c10 = "체크승인)"
          if (month_card == "00") {
            c10 += "일시불)";
          } else {
            c10 += "할부" + month_card + "개월)";
          }
        }

        if (form1.payCard.value == "2222")	//카드결제
        {
          var c11 = d1.trim();
          var c12 = a4.trim();
          var c13 = a14.trim();
        } else {
          var c11 = d1;
          var c12 = a4;
          var c13 = a14;
        }


        price = Fn.cfNumeric(price);

        if (price >= 50000) {
          var c17 = "Y";
        } else {
          var c17 = "N";
        }

        var SAL_AMT = price; // 판매금액

        var SAL_VAT = ((SAL_AMT / 1.1) * 0.1);    // 부가세(VAT)
        var SAL_PRICE = Math.round(SAL_AMT - SAL_VAT); // 단가(반올림)
        SAL_VAT = Math.round(SAL_VAT);    // 부가세(반올림)

        var c14 = Fn.commaNum(SAL_PRICE);
        var c15 = Fn.commaNum(SAL_VAT);
        var c16 = Fn.commaNum(price);

        var receipt_info = c1 + "|" + c2 + "|" + c3 + "|" + c4 + "|" + c5 + "|" + c6 + "|" + c7 + "|" + c8 + "|" + c9 + "|" + c10 + "|" + c11 + "|" + c12 + "|" + c13 + "|" + c14 + "|" + c15 + "|" + c16 + "|" + c17;
        //영수증 출력 끝

        form1.rtn.value = won_rtn;								//전문데이터
        form1.receipt_info.value = receipt_info;				//영수증데이터


        //input_form.print_YN.value = "Y";

        if (form1.payCard.value == "2222") {
          form1.print_YN.value = "Y";
        } else {
          var new_hp = obj.TRACK2DATA;

          form1.cash_hp.value = new_hp;				//핸드폰번호
          form1.print_YN.value = "N";


        }


        go_submit();


      } else {
        //거래실패시 에러메시지 출력
        var dda = obj.ERROR_CHECK_MESSAGE;
        //alert(dda);
        alert(dda + "\r정상적으로 승인되지 않았습니다. 잠시후 다시 시도해주세요");
      }
    }

    payform.taResponse.value += "\n";
    payform.taResponse.value += "===================================================\n";
    payform.taResponse.value += "응답 전문: " + String(rtn);
    payform.taResponse.value += "\n";
    payform.taResponse.value += "===================================================\n";
  }

  function httpRequest(api, data) {

    ShowLoadingImage();

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 0) {
        alert("단말기 연결이 실패하였습니다.");
      }
      if (this.readyState == XMLHttpRequest.DONE) {
        HideLoadingImage();
        if (this.status == 200) {
          var rtn = xmlHttp.responseText;
          console.log('recieve');

          ParseHttpResponse(rtn);
        }
      } else {
        console.log('waiting...');
      }
    };
    xmlHttp.ontimeout = function (e) {
      //console.log('Timeout!');
      alert("시간초과! 다시 시도해주세요");
      // location.href = "member_view.html"
    };
    //xmlHttp.timeout = 35 * 1000;

    var url = 'http';
    if (document.querySelector('input[name="HttpType"]:checked').value == 0)
      url += 's';
    url += '://127.0.0.1:';
    url += payform.portNo.value;
    url += api;
    xmlHttp.open("POST", url, true);
    //xmlHttp.open("POST", url.value, true);
    //xmlHttp.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
    xmlHttp.setRequestHeader("Content-Type", "text/plain;charset=utf-8");
    //xmlHttp.setRequestHeader("Connection", "Close");	// 브라우저에 의존
    //xmlHttp.setRequestHeader("Content-Type", "text/plain");
    //xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    //xmlHttp.setRequestHeader("Content-Type", "application/json");

    xmlHttp.send(data);

    //console.log('send');		//요청전문
  }


  // 단말기 정보 요청
  function SendInfo() {
    var taskType = document.querySelector('input[name="radioTaskType"]:checked').value;
    var subCmd = document.querySelector('input[name="radioFunctinType"]:checked').value;
    var inputData = "";

    // 전문 요청
    payform.taResponse.value += "전문 요청" + "[업무구분:" + taskType + " 세부명령:" + subCmd + "]" + " [명령인자:" + String(inputData) + "]\n";
    payform.taResponse.value += "전문 전송 응답\n";
    // TODO : 결제 모듈
    {
      //var rtn = objEncPCPOS.TPayPosAuthSerial(parseInt(taskType), parseInt(subCmd), String(inputData));

      // 전문 응답 처리
      //payform.taResponse.value += "[ " + rtn + " ]\n";
    }
    payform.taResponse.value += "단말기 시리얼번호 : " + payform.rtn.substring(5, 15) + '\n';
  }

  function writeTo() {
    var taskType = document.querySelector('input[name="radioTaskType"]:checked').value;

    switch (taskType) {
      // 거래요청
      case '0':
        SendPayment();
        break;

      // 기능요청
      case '1':
        SendInfo();
        break;
    }
  }

  var cardNum100;

  var amount12;
  var tax12;
  var tip12;
  var taxFree12;

  var amount9;
  var tax9;
  var tip9;
  var taxFree9;

  var recycleUse;
  var recycleRcdAmt9;

  function SendPayment() {
    // 가맹점 단말기 tid
    if (payform.txtTID.value.length != 10) {
      alert("단말기 TID 자리수 확인(10자리)");
      payform.txtTID.focus();
      return;
    }

    if (payform.edtCardNum.value.length > 0 && payform.edtCardNum.value.length < 10 || payform.edtCardNum.value.length > 98) {
      alert("카드번호 자리수 확인(10자리 이상)");
      payform.edtCardNum.focus();
      return;
    }

    var cardPaddingLen;
    cardPaddingLen = 100 - payform.edtCardNum.value.length - 2;
    if (payform.WCC.value == 'K') {
      cardNum100 = payform.edtCardNum.value.length + payform.edtCardNum.value + fillSpace(cardPaddingLen);
    } else if (payform.WCC.value == 'A') {
      cardNum100 = payform.edtCardNum.value.length + payform.edtCardNum.value + fillSpace(cardPaddingLen);
    } else {
      cardNum100 = fillSpace(100);
    }

    // 거래금액 입력.
    if (payform.edtAmount.value.length > 9) {
      alert("거래금액 자리수 확인(9자리 이하)");
      payform.edtAmount.focus();
      return;
    }
    var amountLen = 9 - payform.edtAmount.value.length;
    amount9 = fillZero(amountLen) + payform.edtAmount.value;
    amount12 = '000' + amount9;

    // 세금 입력.
    if (payform.edtTax.value.length > 9) {
      alert("세금 자리수 확인(9자리 이하)");
      payform.edtTax.focus();
      return;
    }
    var taxLen = 9 - payform.edtTax.value.length;
    tax9 = fillZero(taxLen) + payform.edtTax.value;
    tax12 = '000' + tax9;

    // 봉사료 입력.
    if (payform.edtTip.value.length > 9) {
      alert("봉사료 자리수 확인(9자리 이하)");
      payform.edtTip.focus();
      return;
    }
    var tipLen = 9 - payform.edtTip.value.length;
    tip9 = fillZero(tipLen) + payform.edtTip.value;
    tip12 = '000' + tip9;

    // 비과세 입력.
    if (payform.edtTaxFree.value.length > 9) {
      alert("비과세 자리수 확인(9자리 이하)");
      payform.edtTaxFree.focus();
      return;
    }
    var taxFreeLen = 9 - payform.edtTaxFree.value.length;
    taxFree9 = fillZero(taxFreeLen) + payform.edtTaxFree.value;
    taxFree12 = '000' + taxFree9;

    recycleUse = document.querySelector('input[name="recycleType"]:checked').value;
    if (recycleUse == 1) {
      if (payform.recycleAmt.value.length > 9) {
        alert("비과세 자리수 확인(9자리 이하)");
        payform.recycleAmt.focus();
        return;
      }
      var recycleAmtLen = 9 - payform.recycleAmt.value.length;
      recycleRcdAmt9 = 'RCD' + fillZero(recycleAmtLen) + payform.recycleAmt.value;
    }

    var installment = "00"
    if (payform.edtInstallment.value.length != 2) {
      // 카드 결제 관련 입력값 검사.
      alert("할부 개월수 확인(00:일시불, 01~12:개월수");
      payform.edtInstallment.focus();
      return;
    }
    installment = payform.edtInstallment.value;

    // 거래요청 전문 생성
    authType = document.querySelector('#authType option:checked').value;
    payType = document.querySelector('#payType').value;


    // 취소인 경우 원 거래일시 / 원 거래번호 / 원거래고유번호 필요.
    if (authType == '1050' || authType == '1052' || authType == '1060' || authType == '5050') {
      // 원 거래일시 입력.
      if (payform.edtAuthDate.value.length != 6) {
        alert("원 거래일시 확인(6자리)");
        payform.edtAuthDate.focus();
        return;
      }
      //authDate = edtAuthDate.value; // 6자리 'YYMMDD'

      // 원 거래번호 입력.
      if (payform.edtAuthNo.value.length != 12) {
        alert("원 거래번호 확인(12자리 이하)");
        payform.edtAuthNo.focus();
        return;
      }
      //var authNoLen = 12 - edtAuthNo.value.length; // 12자리
      //authNo = edtAuthNo.value + fillSpace(authNoLen);

      // 원거래 고유번호 입력.
      if (payform.edtAuthUniqueNo.value.length != 12) {
        alert("원거래 고유번호 확인(12자리 이하)");
        payform.edtAuthUniqueNo.focus();
        return;
      }
      //var authUniqueNoLen = 12 - edtAuthUniqueNo.value.length;
      //authUniqueNo = edtAuthUniqueNo.value + fillSpace(authUniqueNoLen); // 12자리
    }

    let inputData;

    if (authType == '5010' || authType == '5050') // 현금 영수증 승인,취소
    {
      inputData = '';
      inputData += makeCash(authType);
    }
    if (authType == '1010' || authType == '1050' || authType == '1012' || authType == '1052') {
      inputData = '';
      inputData += makeCredit(authType);
    }

    /*
    ##########  추후 적용 ################
    // 카카오페이 승인조회/취소조회
    else if (authType == '8040' || authType == '8041') {
        inputData = '';
        inputData += makeKakaoPay(authType);
    }
    // 통합간편결제 승인조회/취소조회
    else if (authType == '80990' || authType == '80991') {
        inputData = '';
        inputData += makeEasyPay(authType);
    }
    // 통합간편결제 승인조회/취소조회
    else if (authType == '8020' || authType == '8021') {
        inputData = '';
        inputData += makeLPay(authType);
    }
    // 현금IC 승인/취소
    else if (authType == '8080' || authType == '8081')
    {
        inputData = '';
        inputData += makeCashIC(authType);
    }
    // 현금IC 잔액조회
    else if (authType == '8084')
    {
        inputData = '';
        inputData += makeCashICQuery(authType);
    }
    // 제로페이 승인/취소
    else if (authType == '8050' || authType == '8051')
    {
        inputData = '';
        inputData += makeZeroPay(authType);
    }
    // 유니온/BC QR - 네이버페이 및 하나앱카드
    else if (authType == '1014A' || authType == '1014C') {
        inputData = '';
        inputData += makeQRCode(authType);
    }
    // SSG페이 승인조회/취소조회
    else if (authType == '8030' || authType == '8031') {
        inputData = '';
        inputData += makeSSGPay(authType);
    }
    // SSG페이 확정
    else if (authType == '8036')
    {
        inputData = '';
        inputData += makeSSGPayConfirm(authType);
    }
    // 알리페이
    else if (authType == '8046' || authType == '8047')
    {
        inputData = '';
        inputData += makeAliPay(authType);
    }
    // 수표조회
    else if (authType == '6080')
    {
        inputData = '';
        inputData += makeCheck(authType);
    }
    // 포인트
    else if (authType.slice(0,2) == '30')
    {
        inputData = '';
        inputData += makePoint(authType);
    }
    // 멤버쉽
    else if (authType.slice(0,2) == '40')
    {
        inputData = '';
        inputData += makeMembership(authType);
    }
    #############################################
    */

    // 승인 요청 전문 출력
    payform.taResponse.value += '요청 전문: ' + String(inputData);

    // http 전송
    var api = '/tPayDaemon/Auth'
    httpRequest(api, inputData);
  }

  function nds() {
    var d = new Date();
    var t_year = d.getFullYear();

    t_year = new String(t_year)

    var year = t_year.substr(2, 2);
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var second = d.getSeconds();

    if (eval(month) < 10) {
      month = "0" + month;
    } else {
      month = month;
    }

    if (eval(day) < 10) {
      day = "0" + day;
    } else {
      day = day;
    }

    if (eval(hour) < 10) {
      hour = "0" + hour;
    } else {
      hour = hour;
    }

    if (eval(minute) < 10) {
      minute = "0" + minute;
    } else {
      minute = minute;
    }

    if (eval(second) < 10) {
      second = "0" + second;
    } else {
      second = second;
    }

    let new_pay_id = year + month + day + hour + minute + second;

    return new_pay_id;
  }

  function makeCredit(authType) {

    let new_time = nds();
    new_time = new_time.trim();

    var packet;
    packet = '';
    packet = jsonInsert(packet, 'TIMEOUT', '02');
    packet = jsonInsert(packet, 'MSGTYPE', String(authType));			//전문종류
    packet = jsonInsert(packet, 'TID', payform.txtTID.value);					//단말기번호
    packet = jsonInsert(packet, 'MSGNO', new_time);						//전문번호
    packet = jsonInsert(packet, 'TRANSTIME', getTime_YYMMDDHHMMSS());	//전문생성일자

    if (payform.WCC.value != ' ') {
      packet = jsonInsert(packet, 'WCC', payform.WCC.value);
      packet = jsonInsert(packet, 'TRACK2DATA', cardNum100);
    }

    packet = jsonInsert(packet, 'INSTALLMENT', payform.edtInstallment.value);
    packet = jsonInsert(packet, 'AMOUNT', amount9);
    packet = jsonInsert(packet, 'TAX', tax9);
    packet = jsonInsert(packet, 'SERVICE', tip9);
    packet = jsonInsert(packet, 'CURRENCY', 'KRW');

    if (authType == '1010' || authType == '1012') {
      //packet = jsonInsert(packet, 'TRANSDATE', fillSpace(6));
      //packet = jsonInsert(packet, 'AUTHORITY', fillSpace(12));
      //packet = jsonInsert(packet, 'ORGAUTHNO', fillSpace(12));
    } else {
      packet = jsonInsert(packet, 'TRANSDATE', payform.edtAuthDate.value);
      packet = jsonInsert(packet, 'AUTHORITY', payform.edtAuthNo.value);
      //packet = jsonInsert(packet, 'ORGAUTHNO', edtAuthUniqueNo.value);	// 직전취소 설정
    }

    packet = jsonInsert(packet, 'NOTAX', taxFree9);			//비과세금액
    /*
    packet = jsonInsert(packet, 'PINNO', fillSpace(18));		//비밀번호
    packet = jsonInsert(packet, 'OILINFO', fillSpace(24));	//주유정보
    packet = jsonInsert(packet, 'SITEBIZNO', fillSpace(10));	//하위 사업자 번호
    */
    packet = jsonInsert(packet, 'POSSERIALNO', 'JTPOSDM16011E278');	//pos 시리얼 번호

    //packet = jsonInsert(packet, 'ADDINFO1', fillSpace(32));			//부가정보
    //packet = jsonInsert(packet, 'ADDINFO2', fillSpace(128));			//부가정보2
    if (recycleUse == 1) {
      packet = jsonInsert(packet, 'ADDINFO2', fillSpace(64) + recycleRcdAmt9 + fillSpace(52));			//부가정보2
    }
    //packet = jsonInsert(packet, 'RESERVED', fillSpace(64));			//reserved

    packet = jsonInsert(packet, 'SIGNKBN', ' ');				//서명 여부
    packet = jsonInsert(packet, 'CR', String.fromCharCode(0x0d));		// CR

    return packet;
  }

  function makeCash(authType) {
    var packet;

    let new_time = nds();
    new_time = new_time.trim();

    packet = '';
    packet = jsonInsert(packet, 'TIMEOUT', '02');
    packet = jsonInsert(packet, 'MSGTYPE', String(authType));
    packet = jsonInsert(packet, 'TID', payform.txtTID.value); //TID
    packet = jsonInsert(packet, 'MSGNO', new_time);					//전문관리번호 (POS가 거래를 확인 하기 위한 번호)
    packet = jsonInsert(packet, 'TRANSTIME', getTime_YYMMDDHHMMSS());		//전문 생성 일자

    if (payform.WCC.value != ' ') {
      packet = jsonInsert(packet, 'WCC', payform.WCC.value);
      packet = jsonInsert(packet, 'TRACK2DATA', cardNum100);
    }
    packet = jsonInsert(packet, 'AMOUNT', amount9);
    packet = jsonInsert(packet, 'TAX', tax9);
    packet = jsonInsert(packet, 'SERVICE', tip9);

    if (form1.cash_p_gubun.value == "0") {
      //inputData += "0";    //거래구분자 -개인
      packet = jsonInsert(packet, 'AUTH_TYPE', '0'); //개인
    } else {
      //inputData += "1";    //거래구분자 -사업자
      packet = jsonInsert(packet, 'AUTH_TYPE', '1'); //사업자
    }


    if (authType == '5010') {

    } else //취소 인 경우
    {
      packet = jsonInsert(packet, 'TRANSDATE', payform.edtAuthDate.value); //원거래일자
      packet = jsonInsert(packet, 'AUTHORITY', payform.edtAuthNo.value); //원승인번호
      packet = jsonInsert(packet, 'CANCELCODE', '1');
    }
    packet = jsonInsert(packet, 'NOTAX', taxFree9);

    if (recycleUse == 1) {
      packet = jsonInsert(packet, 'ADDINFO2', recycleRcdAmt9 + fillSpace(20));
    }

    packet = jsonInsert(packet, 'CR', String.fromCharCode(0x0d));

    return packet;
  }

  function fillZero(n) {
    var zeros = "";

    for (let i = 0; i < n; i++) {
      zeros += '0';
    }

    return zeros;
  }

  function fillSpace(n) {
    var zeros = "";

    for (let i = 0; i < n; i++) {
      zeros += ' ';
    }

    return zeros;
  }

  function leadingZeros(n, digits) {
    var zero = '';
    n = n.toString();

    if (n.length < digits) {
      for (var i = 0; i < digits - n.length; i++)
        zero += '0';
    }

    return zero + n;
  }

  function auto_insert() {
    if (form1.payCard.value) {
      let dd = form1.payCard.value.split(",");
      let dd_size = dd.length;

      if (form1.payCard.value == "2222")	//카드결제
      {
        $('#cash_p_show').hide();
      } else if (form1.payCard.value == "1111" || dd_size == "3")	//현금 혹은 계좌이체
      {
        $('#cash_p_show').show();
      } else {
        $('#cash_p_show').hide();
        form1.cash_p.checked = false;
      }
      //카드결제일때 금액에 따라 일시불. 할부 표시
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3">
        <ChkAuthNum type={"locker_info_change"}/>
      </div>

      <form name="form1" id="form1" method="post">
        <input type="hidden" name="mb_no"/>
        <input type="hidden" name="mb_no_se"/>
        <input type="hidden" name="auth_code"/>
        <input type="hidden" name="test"/>
        <input type="hidden" name="print_YN"/>
        <input type="hidden" name="_able_end" id="_able_end"/>
        <input type="hidden" name="_able_suit_end" id="_able_suit_end"/>
        <input type="hidden" name="_able_locker_end" id="_able_locker_end"/>
        <input type="hidden" name="_able_pt" id="_able_pt"/>

        {/* 카드결제 관련정보 */}
        <input type="text" name="purchase_code" style={{display: 'none'}}/>
        <input type="text" name="machine_no" style={{display: 'none'}}/>
        <input type="text" name="company_no" style={{display: 'none'}}/>
        <input type="text" name="card_no" style={{display: 'none'}}/>
        <input type="text" name="permit_no" style={{display: 'none'}}/>
        <input type="text" name="permit_time" style={{display: 'none'}}/>
        <input type="text" name="card_gubun" style={{display: 'none'}}/>
        <input type="text" name="unique_no" style={{display: 'none'}}/>
        <input type="text" name="rtn" style={{display: 'none'}}/>
        <input type="text" name="receipt_info" style={{display: 'none'}}/>
        <input type="text" name="cash_hp" style={{display: 'none'}}/>
        {localStorage.getItem("USER.systemBrand") === 'L' ? (
          <input type="hidden" name="ip_amt" value="33000"/>
        ) : (
          <input type="hidden" name="ip_amt" value="11000"/>
        )}
        <input type="hidden" name="month_card" value="00"/>

        <div className="m-3 mb-4" style={{border: "1px solid #eee", borderRadius: "12px"}}>
          <div className="p-2 px-3 d-flex border-bottom">
            <p>
              <span className="h6 bold700 vertical-middle">양도인 선택</span>
            </p>
            <p className="mx-2">
              <button type="button" className="btn btn-ssm btn-dark" onClick={() => search_member1()}>
                양도회원 검색
              </button>
            </p>
          </div>
          <div className="p-3">
            <table className="view-rounded sub_view">
              <colgroup>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>이름</th>
                <td id="b_mb_name"></td>
                <th>연락처</th>
                <td id="b_mb_hp"></td>
                <th>회원번호</th>
                <td id="b_mb_id"></td>
                <th>이용기간</th>
                <td id="b_health_date"></td>
              </tr>
              <tr>
                <th>운동복</th>
                <td id="b_suit_date"></td>
                <th>라커</th>
                <td id="b_locker_date"></td>
                <th>잔여PT</th>
                <td id="b_remain_PT">0회</td>
                <th></th>
                <td></td>
              </tr>
            </table>
          </div>
        </div>

        <div className="m-3 mb-4" style={{border: "1px solid #eee", borderRadius: "12px"}}>
          <div className="p-2 px-3 d-flex border-bottom">
            <p>
              <span className="h6 bold700 vertical-middle">양수인 선택</span>
            </p>
            <p className="mx-2">
              <button type="button" className="btn btn-ssm btn-dark" onClick={() => search_member2()}>
                양수회원 검색
              </button>
            </p>
          </div>
          <div className="p-3">
            <table className="view-rounded sub_view">
              <colgroup>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>이름</th>
                <td id="a_mb_name"></td>
                <th>연락처</th>
                <td id="a_mb_hp"></td>
                <th>회원번호</th>
                <td id="a_mb_id"></td>
                <th>이용기간</th>
                <td id="a_health_date"></td>
              </tr>
              <tr>
                <th>운동복</th>
                <td id="a_suit_date"></td>
                <th>라커</th>
                <td id="a_locker_date"></td>
                <th>양도수수료</th>
                <td>
                  <div className="d-flex justify-content-center">
                    <div className="pr-1">
                      <select className="form-select" aria-label="Default select example" style={{minWidth: "80px"}} id="payCard" name="payCard"
                              onChange={() => auto_insert()}
                      >
                        <option value='1111'>현금</option>
                        <option value='2222'>카드</option>
                        {result1?.pc1?.map((item, index) => <option value={`${item?.j_no},${item?.j_jukname},${item?.j_gyecode}`}>(계좌) {item.j_jukname}</option>)}
                        {result1?.pc2?.map((item, index) => <option value={`${item?.j_no},${item?.j_jukname}`}>{item.j_jukname}Card</option>)}
                      </select>
                      <div style={{float: "left", paddingLeft: "4px"}}>
                        <span id="card_month"></span>
                      </div>
                    </div>
                  </div>
                  <div id="cash_p_show" className="mt-2 ">
                    <div className="input-group">
                      <div className="form-check form-check-inline d-flex justify-content-center" style={{minWidth: "100%"}}>
                        <label className="form-check-label" htmlFor="cash_p">
                          현금영수증 :
                        </label>
                        <input className="form-check-input" type="checkbox" value="Y" id="cash_p" name="cash_p"/>
                        <select className="form-select" aria-label="Default select example" style={{maxWidth: "80px"}} id="cash_p_gubun" name="cash_p_gubun">
                          <option value='0'>개인</option>
                          <option value='1'>사업자</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </td>
                <th>입금여부</th>
                <td>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="ipkumok" id="as1" value="0"/>
                    <label className="form-check-label" htmlFor="as1">
                      미입금
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="ipkumok" id="as2" value="1"/>
                    <label className="form-check-label" htmlFor="as2">
                      입금
                    </label>
                  </div>
                  <p className="form-check-inline bold700 h7 vertical-middle">
                    {localStorage.getItem("USER.systemBrand") === 'L' ? <>[양도금액 : 33,000]</> : <>[양도금액 : 11,000]</>}
                  </p>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="m-3 mb-4" style={{border: "1px solid #eee", borderRadius: "12px"}}>
          <div className="p-2 px-3 d-flex border-bottom">
            <p>
              <span className="h6 bold700 vertical-middle">양도항목 선택</span>
            </p>
            <p className="mx-2"></p>
          </div>
          <div className="p-3">
            <table className="view-rounded sub_view">
              <colgroup>
                <col width="130px"/>
                <col width="*"/>
                <col width="130px"/>
                <col width="*"/>
                <col width="130px"/>
                <col width="*"/>
                <col width="130px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="Y" name='able_end' id='check_all'/>
                    <label className="form-check-label" htmlFor="check_all">
                      이용기간
                    </label>
                  </div>
                </th>
                <td>
                  <span id="h_add_date_html"></span>
                  <input type="hidden" id="h_add_date" value=""/>
                </td>
                <th>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="Y" name='able_suit_end' id='check_1'/>
                    <label className="form-check-label" htmlFor="check_1">
                      운동복
                    </label>
                  </div>
                </th>
                <td>
                  <span id="s_add_date_html"></span>
                  <input type="hidden" id="s_add_date" value=""/>
                </td>
                <th>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="Y" name='able_locker_end' id='check_2'/>
                    <label className="form-check-label" htmlFor="check_2">
                      라커
                    </label>
                  </div>
                </th>
                <td>
                  <span id="l_add_date_html"></span>
                  <input type="hidden" id="l_add_date" value=""/>
                </td>
                <th>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="Y" name='able_pt' id='check_3'/>
                    <label className="form-check-label" htmlFor="check_3">
                      PT
                    </label>
                  </div>
                </th>
                <td>
                  <div style={{width: 'auto'}}>
                    <span id="p_add_date_html"></span>
                    <input type="hidden" id="p_add_date" value=""/>
                    <select className='form-select ml-2' name='p_pt' id='p_pt' style={{maxWidth: "160px", display: "none"}}>
                    </select>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="m-3 mb-4">
          <button type="button" className="btn btn-point" id="input_btn"
                  onClick={() => pre_go_submit()}
          >
            양도양수 저장
          </button>
        </div>
      </form>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();

          $('.fn_search_box_pop').each(function () {
            $(this).find("input[type=text]").each(function () {
              $(this).onEnter(() => {
                setSearchYN('Y');
                setSrchRenew(Fn.getRenewTime)
              });
            });
          });

          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: '1550px'}}>
            <div className="POP_title">
              <h5><strong>양도/양수 검색</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="mb-3 d-flex flex-nowrap fn_search_box_pop">
                <div className="pr-1">
                  <input type="text" className="form-control" aria-describedby="button-addon2" id="key" placeholder="성명/연락처 검색"/>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => {
                    setSearchYN('Y');
                    setSrchRenew(Fn.getRenewTime());
                  }}>검색
                  </button>
                </div>
              </div>
              <table className="view-rounded sub_table_border" style={{borderRadius: 0}}>
                <colgroup>
                  <col width="80px"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th className="w_70px">No</th>
                  <th>카드번호</th>
                  <th>성명</th>
                  <th>연락처</th>
                  <th>시작일</th>
                  <th>종료일</th>
                  <th>라커</th>
                  <th>운동복</th>
                  <th>잔여PT</th>
                  <th>미납금</th>
                </tr>
                {searchYN === 'Y' && result?.result && result?.result.map((ret, i) => {
                  const {
                    remainPrice,
                    won_branch_code: wonBranchCode,
                    notUseNum,
                    b2bNo,
                    mb_id: mbId,
                    mb_name: mbName,
                    mb_no: mbNo,
                  } = ret;

                  let mbHpShow = Fn.hpShow(ret?.mb_hp?.replace(/-/g, "", ret?.mb_hp), modalPageInfo?.totalArticles);

                  let remain_PT = ret?.remainPT;

                  let locker_period = "";
                  let locker_period2 = "";
                  let suit_period = "";
                  let suit_period2 = "";
                  let health_period = "";

                  if (ret?.locker == 1 || ret?.locker == 3) {
                    locker_period = `${(ret?.locker_start_date || '')?.substring(2, 10)?.replace(/-/g, ".")} ~ ${(ret?.locker_end_date || '')?.substring(2, 10)?.replace(/-/g, ".")}`;
                    locker_period2 = `${ret?.locker_start_date || ''} ~ ${ret?.locker_end_date || ''}`;
                  }

                  if (ret?.suit == 1) {
                    suit_period = `${(ret?.suit_start_date || '')?.substring(2, 10)?.replace(/-/g, ".")} ~ ${(ret?.suit_end_date || '')?.substring(2, 10)?.replace(/-/g, ".")}`;
                    suit_period2 = `${ret?.suit_start_date || ''} ~ ${ret?.suit_end_date || ''}`;
                  }

                  if (ret?.end_date) {
                    health_period = `${ret?.start_date || ''} ~ ${ret?.end_date || ''}`;
                  }

                  return (
                    <tr>
                      <td className="text-center">{modalPageInfo?.totalArticles - modalPageInfo?.articlesPerPage * (modalPageInfo?.currentPage - 1) - i}</td>
                      {searchType === 'yangdo' ? (
                        remainPrice > 0 ? (
                          <>
                            <td><strong><a href="#1" onClick={() => alert('미납금이 있는 회원은 양도하실수 없습니다')}>{mbId}</a></strong></td>
                            <td><strong><a href="#1" onClick={() => alert('미납금이 있는 회원은 양도하실수 없습니다')}>{mbName}</a></strong></td>
                          </>
                        ) : wonBranchCode !== localStorage.getItem("USER.systemBranchCode") ? (
                          <>
                            <td><strong><a href="#1" onClick={() => alert('먼저 주센터변경이 진행되어야 합니다.')}>{mbId}</a></strong></td>
                            <td><strong><a href="#1" onClick={() => alert('먼저 주센터변경이 진행되어야 합니다.')}>{mbName}</a></strong></td>
                          </>
                        ) : notUseNum > 0 ? (
                          <>
                            <td><strong><a href="#1" onClick={() => alert('연기중인 회원은 양도가 불가능합니다.')}>{mbId}</a></strong></td>
                            <td><strong><a href="#1" onClick={() => alert('연기중인 회원은 양도가 불가능합니다.')}>{mbName}</a></strong></td>
                          </>
                        ) : b2bNo === 'Y' ? (
                          <>
                            <td><strong><a href="#1" onClick={() => alert('양도가 불가능한 B2B 이용권 사용자입니다')}>{mbId}</a></strong></td>
                            <td><strong><a href="#1" onClick={() => alert('양도가 불가능한 B2B 이용권 사용자입니다')}>{mbName}</a></strong></td>
                          </>
                        ) : (
                          <>
                            <td><strong><a href="#1" onClick={() => copy_data1(mbNo, mbId, mbName, mbHpShow, health_period, suit_period, locker_period, remain_PT)}>{mbId}</a></strong></td>
                            <td><strong><a href="#1" onClick={() => copy_data1(mbNo, mbId, mbName, mbHpShow, health_period, suit_period, locker_period, remain_PT)}>{mbName}</a></strong></td>
                          </>
                        )
                      ) : wonBranchCode !== localStorage.getItem("USER.systemBranchCode") ? (
                        <>
                          <td><strong><a href="#1" onClick={() => alert('먼저 주센터변경이 진행되어야 합니다.')}>{mbId}</a></strong></td>
                          <td><strong><a href="#1" onClick={() => alert('먼저 주센터변경이 진행되어야 합니다.')}>{mbName}</a></strong></td>
                        </>
                      ) : (
                        <>
                          <td><strong><a href="#1" onClick={() => copy_data2(mbNo, mbId, mbName, mbHpShow, health_period, suit_period, locker_period)}>{mbId}</a></strong></td>
                          <td><strong><a href="#1" onClick={() => copy_data2(mbNo, mbId, mbName, mbHpShow, health_period, suit_period, locker_period)}>{mbName}</a></strong></td>
                        </>
                      )}
                      <td className="text-center">{Fn.hpShow(ret?.mb_hp, modalPageInfo?.totalArticles)}</td>
                      <td className="text-center">{ret?.start_date?.substring(2, 10)?.replace(/-/g, ".")}</td>
                      <td className="text-center">{ret?.end_date?.substring(2, 10)?.replace(/-/g, ".")}</td>
                      <td className="text-center">{locker_period}</td>
                      <td className="text-center">{suit_period}</td>
                      <td className="text-right">{Fn.numberWithCommas(ret?.remainPT)}</td>
                      <td className="text-right">{Fn.numberWithCommas(ret?.misuPrice)}</td>
                    </tr>
                  );
                })}
                {searchYN === 'Y' && (!result?.result || result?.result?.length === 0) &&
                  <tr>
                    <td colSpan={10} className="text-center">※ 등록된 data가 없습니다.</td>
                  </tr>
                }
                {searchYN !== 'Y' &&
                  <tr>
                    <td colSpan={10} className="text-center">※ 검색버튼을 클릭해주세요.</td>
                  </tr>
                }
              </table>
            </div>
            <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
              <Paging pageInfo={modalPageInfo} setCurrPage={page => setModalCurrPage(page)}/>
            </nav>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="search_type"/>
      <input type="hidden" id="do_id"/>

      <span style={{display: "none"}}>
        <form name="payform" id="payform">

          {/*연결방식-ok : */}
          <input type="radio" name="HttpType" value="0" checked="checked"/>HTTPS(보안) 연결 / <input type="radio" name="HttpType" value="1"/>HTTP 연결

          {/*Port 번호-ok : */}
          <input id="portNo" name="portNo" style={{width: '60px;'}} type="text" value="6443"/>

          {/*단말기번호-ok : */}
          <input id="txtTID" name="txtTID" style={{width: '200px;'}} type="text" value={result1?.bOptRow?.TID}/>

          {/*사업자번호-ok : */}
          <input id="txtBiz" name="txtBiz" style={{width: '200px;'}} type="text" value=""/>

            <input type="radio" name="radioTaskType" value="0" checked="checked"/>거래 요청-ok
            <select id="authType" style={{width: '200px;'}}>
              <option value="1010">01.신용 승인</option>
              <option value="1050">02.신용 취소</option>
              <option value="1012">03.은련 승인</option>
              <option value="1052">04.은련 취소</option>
              <option value="5010">05.현금영수증 승인</option>
              <option value="5050">06.현금영수증 취소</option>
              <option value="8040">07.카카오페이 승인조회</option>
              <option value="8041">08.카카오페이 취소조회</option>
              <option value="80990">09.통합간편결제 승인</option>
              <option value="80991">10.통합간편결제 취소</option>
              <option value="8020">11.L.PAY 승인</option>
              <option value="8021">12.L.PAY 취소</option>
              <option value="8080">13.현금IC 승인</option>
              <option value="8081">14.현금IC 취소</option>
              <option value="8084">15.현금IC 조회</option>
              <option value="8050">16.제로페이 승인</option>
              <option value="8051">17.제로페이 취소</option>
              <option value="1014A">18.ICQR 승인</option>
              <option value="1014C">19.ICQR 취소</option>
              <option value="8030">20.SSG페이 승인</option>
              <option value="8031">21.SSG페이 취소</option>
              <option value="8036">22.SSG페이 확정</option>
              <option value="8046">23.알리페이 승인</option>
              <option value="8047">24.알리페이 취소</option>
              <option value="6080">25.수표조회</option>
              <option value="3010">26.포인트적립 승인</option>
              <option value="4010">27.멤버쉽 승인</option>
            </select>

            <input type="radio" name="radioTaskType" value="1"/>기능 요청-ok
            <select id="abilityType" style={{width: '200px;'}}>
              <option value="PC">01.식별번호 요청</option>
              <option value="KC">02.키 교환 요청</option>
              <option value="DC">03.데몬 상태 체크</option>
              <option value="RC">04.키오스크 리더기 체크</option>
              <option value="UP">05.데몬 업데이트 요청</option>
              <option value="LG">06.로그 수집 요청</option>
            </select>

          {/*현금영수증 승인 구분-ok :*/}
          <select id="authCashType" name="authCashType" style={{width: '200px;'}}>
              <option value="0">01.소비자 소득공제</option>
              <option value="1">02.사업자 지출증빙</option>
            </select>

          {/*WCC-ok :*/}
          <select id="WCC" name="WCC" style={{width: '200px;'}}>
              <option value=" ">01. 리더기 처리</option>
              <option value="K">02. Key-In</option>
              <option value="Q">03. 앱카드</option>
              <option value="A">04. 앱카드KeyIn</option>
              <option value="B">05. 바코드</option>
            </select>

          {/*지불수단-ok*/}
          <select id="payType" name="payType" style={{width: '200px;'}}>
              <option value="C">01. 신용</option>
              <option value="M">02. 머니</option>
              <option value="B">03. 계좌</option>
            </select>

          {/*카드번호-ok*/}
          <input type="text" id="edtCardNum" name="edtCardNum" value="" style={{width: '200px;'}}/>

          {/*거래금액-ok*/}
          <input type="text" id="edtAmount" name="edtAmount" value="0" style={{width: '200px;'}}/>

          {/*세금-ok*/}
          <input type="text" id="edtTax" name="edtTax" value="0" style={{width: '200px;'}}/>

          {/*봉사료-ok*/}
          <input type="text" id="edtTip" name="edtTip" value="0" style={{width: '200px;'}}/>

          {/*할부개월-ok*/}
          <input type="text" id="edtInstallment" name="edtInstallment" value="00" placeholder="00" style={{width: '200px;'}}/>

          {/*비과세 금액-ok*/}
          <input type="text" id="edtTaxFree" name="edtTaxFree" value="0" style={{width: '200px;'}}/>

          {/*자연순환보증금-ok*/}
          <input type="radio" name="recycleType" value="0" checked="checked"/>없음 <input type="radio" name="recycleType" value="1"/>있음 <input id="recycleAmt" style={{width: "80px"}} type="text" value="0"/>

          {/*원 거래일자-ok*/}
          <input type="text" id="edtAuthDate" name="edtAuthDate" placeholder="YYMMDD" style={{width: '200px;'}}/>

          {/*원 승인번호-ok*/}
          <input type="text" id="edtAuthNo" name="edtAuthNo" style={{width: '200px;'}}/>

          {/*원거래 고유번호-ok*/}
          <input type="text" id="edtAuthUniqueNo" name="edtAuthUniqueNo" style={{width: '200px;'}}/>

            <textarea id="taResponse" name="taResponse" style={{height: "260px", width: "100%;"}} rows="1" cols="129"></textarea>

        </form>
      </span>

    </CmnCard>
  );
};

export default MemberToreciveReg;
