import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import $ from 'jquery';
import CmnCard from "../CmnCard";
import axios from "axios";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";

const BranchPtOTList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 3);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/branchop/ot/list/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_hp=${$('#s_mb_hp').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  let s_form;
  let form1;
  let common_i;
  let re_data;

  useEffect(() => {
    s_form = $("[name='s_form']");
    form1 = $("[name='form1']");
    common_i = $("[name='common_i']");
  });

  function chkSms() {
    let con = 0;
    let editMsgid = "";
    for (let i = 0; i < form1.elements.length; i++) {
      if (form1.elements[i].type === "checkbox") {
        if (form1.elements[i].checked === true) {
          const nn = form1.elements[i].name;
          const m_chk = nn.substr(0, 3);
          if (m_chk === "chk" && form1.elements[i].value !== "o") {
            con++;
            editMsgid += form1.elements[i].value + "|";
          }
        }
      }
    }
    if (con === 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    window.location.href = "/sms/sms.html?b_type=pt&s_type=sel&editMsgid=" + editMsgid;
  }

  function forckEnd() {
    let con = 0;
    let editMsgid = "";
    for (let i = 0; i < form1.elements.length; i++) {
      if (form1.elements[i].type === "checkbox") {
        if (form1.elements[i].checked === true) {
          const nn = form1.elements[i].name;
          const m_chk = nn.substr(0, 3);
          if (m_chk === "chk" && form1.elements[i].value !== "o") {
            con++;
            editMsgid += form1.elements[i].value + "|";
          }
        }
      }
    }
    if (con === 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    if (window.confirm("체크된 PT 대상자를 강제종료 하시겠습니까?")) {
      const form = document.querySelectorAll("form")[1];
      const formData = new FormData(form);
      axios
        .post("/branchop/pt/force/end", formData, {
          processData: false,
          contentType: false,
        })
        .then((response) => {
          const data = response.data.trim();
          if (data === "err") {
            alert("오류가 발생하였습니다");
          } else if (data === "del_ok") {
            alert("처리되었습니다");
            window.parent.location.reload();
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert("데이터오류! 시스템 관리자에게 문의하세요");
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="pr-1 d-flex">
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
            </div>
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="성명 검색"
              id="s_mb_name"
            />
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="연락처 검색"
              id="s_mb_hp"
            />
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list table_wide_sh2">
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="60px"/>
              <col width="80px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>
                <input type="checkbox"/>
              </th>
              <th>성명</th>
              <th>카드번호</th>
              <th>연락처</th>
              <th>등록일</th>
              <th>시작일</th>
              <th>종료일</th>
              <th>OT횟수</th>
              <th>진행</th>
              <th>잔여</th>
              <th>최종진행</th>
            </tr>

            {rets && rets.map((ret, i) => {
              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input type="checkbox"/>
                  </td>
                  <td>{ret?.mb_name || ''}</td>
                  <td>{ret?.mb_id || ''}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{ret?.mb_open_date && ret?.mb_open_date?.replace(/-/g, '.')?.slice(2, 10)}</td>
                  <td>{ret?.start_date && ret?.start_date?.replace(/-/g, '.')?.slice(2, 10)}</td>
                  <td>{ret?.end_date && ret?.end_date?.replace(/-/g, '.')?.slice(2, 10)}</td>
                  <td>{ret?.ot_able || '0'}</td>
                  <td>{ret?.ot_cnt || '0'}</td>
                  <td>{(ret?.ot_able - ret?.ot_cnt) || ''}</td>
                  <td>{ret?.lastOtDate && ret?.lastOtDate?.replace(/-/g, '.')?.slice(2, 10)}</td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={12}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
          <div>
            <button type="button" className="btn btn-sm btn-outline-dark">
              엑셀저장
            </button>
            {pageInfo?.totalArticles > 0 && (
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('ot_list')}>연락처 확인</button>
              )
            )}
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default BranchPtOTList;
