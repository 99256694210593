import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import $ from "jquery";
import CmnCard from "../CmnCard";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";

const AnalysisFinanceMonthlyReport = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  useEffect(() => {
    $(document).on('scroll', function () {
      let scrollTop = $(window).scrollTop();
      if (scrollTop > 190)
        $("#detail_area").offset({top: scrollTop + 80});
      else
        $("#detail_area").offset({top: 190});
    });
  }, []);

  const [renew, setRenew] = useState(0);
  const [result, setResult] = useState({});
  const [resultDtl, setResultDtl] = useState({});
  const [type, setType] = useState('all');
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [sDate1, setSDate1] = useState('');
  const [sDate2, setSDate2] = useState('');
  const [eDate1, setEDate1] = useState('');
  const [eDate2, setEDate2] = useState('');

  const [frChkAble, setFrChkAble] = useState('');
  const [frResult, setFrResult] = useState({});
  const [frMode, setFrMode] = useState(false);

  let C_id = 0;
  let D_id = 0;
  let tMC = 0;

  useEffect(() => {
    $('#type').val('all');
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#s_year').val(Fn.getDate(currentDate).split('-')[0]);
    $('#s_month').val(Fn.getDate(currentDate).split('-')[1]);
    $('#e_year').val(Fn.getDate(monthsAgo).split('-')[0]);
    $('#e_month').val(Fn.getDate(monthsAgo).split('-')[1]);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  useEffect(() => {
    if ($('#type option:selected').val() === 'all') {
      $('#div_in, #div_out').show();
    } else if ($('#type option:selected').val() === 'in') {
      $('#div_in').show();
      $('#div_out').hide();
    } else if ($('#type option:selected').val() === 'out') {
      $('#div_in').hide();
      $('#div_out').show();
    }
  }, [type]);

  const load = () => {
    C_id = 0;
    D_id = 0;
    tMC = 0;

    if ($('#type option:selected').val() === 'all') {
      $('#div_in, #div_out').show();
    } else if ($('#type option:selected').val() === 'in') {
      $('#div_in').show();
      $('#div_out').hide();
    } else if ($('#type option:selected').val() === 'out') {
      $('#div_in').hide();
      $('#div_out').show();
    }

    let s_year = $('#s_year option:selected').val();
    let s_month = $('#s_month option:selected').val();
    let e_year = $('#e_year option:selected').val();
    let e_month = $('#e_month option:selected').val();

    let s_date1 = `${s_year}-${s_month}-01`;
    let s_date2 = Fn.getDate(new Date(s_year, s_month, 0));

    let e_date1, e_date2;
    if (e_year && e_month) {
      e_date1 = `${e_year}-${e_month}-01`;
      e_date2 = Fn.getDate(new Date(e_year, e_month, 0));
    } else {
      const lastMonth = new Date(s_year, s_month - 1, 0);
      e_date1 = Fn.getDate(new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 1));
      e_date2 = Fn.getDate(lastMonth);
    }
    setSDate1(s_date1);
    setSDate2(s_date2);
    setEDate1(e_date1);
    setEDate2(e_date2);

    console.log(`/analysis/finance/monthly/report/ready?s_year=${s_year}&s_month=${s_month}&e_year=${e_year}&e_month=${e_month}&type=${$('#type option:selected').val() || ''}`);
    axios2
      .post(`/analysis/finance/monthly/report/ready?s_year=${s_year}&s_month=${s_month}&e_year=${e_year}&e_month=${e_month}&type=${$('#type option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadDtl = (type, k, v, date1 = '', date2 = '') => {
    setType(type);
    setFrMode(false);

    let s_date1 = "", s_date2 = "";
    if (type == 'in') {
      s_date1 = sDate1;
      s_date2 = sDate2;
    } else {
      s_date1 = eDate1;
      s_date2 = eDate2;
    }
    console.log(sDate1, sDate2, eDate1, eDate2, type);
    if (date1) s_date1 = date1;
    if (date2) s_date2 = date2;

    console.log(`/analysis/finance/monthly/report?type=${type}&k=${k}&v=${v}&s_date1=${date1}&s_date2=${date2}`);
    axios2
      // .post(`/analysis/finance/monthly/report?type=${type}&k=${k}&v=${v}&s_date1=${type === 'in' ? sDate1 : eDate1}&s_date2=${type === 'in' ? sDate2 : eDate2}`)
      .post(`/analysis/finance/monthly/report?type=${type}&k=${k}&v=${v}&s_date1=${s_date1}&s_date2=${s_date2}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResultDtl(result);
          console.log(result)
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const [showSuip, setShowSuip] = useState(true);
  const [showJichul, setShowJichul] = useState(true);

  const change_data_new = (e) => {
    const {value} = e.target;

    if (value === 'suip') {
      setShowSuip(true);
      setShowJichul(false);
    } else if (value === 'jichul') {
      setShowSuip(false);
      setShowJichul(true);
    } else {
      setShowSuip(true);
      setShowJichul(true);
    }
  };

  const n_form1 = document.getElementById('n_form1');
  const color_change = (C_id) => {
    const t = n_form1.C_id_count.value;

    for (let i = 1; i <= t; i++) {
      const detailElement = document.getElementById(`detail_${i}`);
      if (detailElement) {
        detailElement.style.background = "#FFFFFF";
      }
    }
    const targetElement = document.getElementById(`detail_${C_id}`);
    if (targetElement) {
      targetElement.style.background = "#CCEEDD";
    }
  };


  const n_form2 = document.getElementById('n_form1');
  const color_change_D = (D_id) => {
    const t = n_form2.C_id_count.value;

    for (let i = 1; i <= t; i++) {
      const detailElement = document.getElementById(`detail_${i}`);
      if (detailElement) {
        detailElement.style.background = "#FFFFFF";
      }
    }
    const targetElement = document.getElementById(`detail_${D_id}`);
    if (targetElement) {
      targetElement.style.background = "#CCEEDD";
    }
  };

  const n_form3 = document.getElementById('n_form1');
  const j_color_change = (J_id) => {
    const t = n_form3.C_id_count.value;

    for (let i = 1; i <= t; i++) {
      const detailElement = document.getElementById(`detail_${i}`);
      if (detailElement) {
        detailElement.style.background = "#FFFFFF";
      }
    }
    const targetElement = document.getElementById(`detail_${J_id}`);
    if (targetElement) {
      targetElement.style.background = "#CCEEDD";
    }
  };

  const n_form4 = document.getElementById('n_form1');
  const K_color_change = (K_id) => {
    const t = n_form3.C_id_count.value;

    for (let i = 1; i <= t; i++) {
      const detailElement = document.getElementById(`detail_${i}`);
      if (detailElement) {
        detailElement.style.background = "#FFFFFF";
      }
    }
    const targetElement = document.getElementById(`detail_${K_id}`);
    if (targetElement) {
      targetElement.style.background = "#CCEEDD";
    }
  };

  const onFinanceReport = (year, month) => {
    const s_date = year + '-' + month + '-01';
    const e_date = year + '-' + month + '-31';

    console.log(`/finance/finance/report/ready?ddate=&s_date1=${s_date}&s_date2=${e_date}`);

    axios2
      .post(`/finance/finance/report/ready?ddate=&s_date1=${s_date}&s_date2=${e_date}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          let chk_able = "N";
          if (result?.idChkRow?.account_YN == "Y" || localStorage.getItem("USER.systemID") == "mamark" || localStorage.getItem("USER.systemID") == "futureinfo") {
            chk_able = "Y";
          }
          setFrChkAble(chk_able);
          setFrResult(result);
          setFrMode(true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const load2 = () => {
  }

  function showCkkEtc1(row) {
    try {
      if (row?.ckk_etc) return row?.ckk_etc;
      else return resultDtl?.aryMC[row?.ck_summary_no];
    } catch (err) {
      return "";
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex" style={{maxWidth: "1450px"}}>
        <div className="wid_fix" style={{maxWidth: "700px"}}>
          <div className="d-flex m-3">
            <div className="pr-1">
              <select className="form-select-sm" aria-label="Default select example" style={{width: '100px'}} id="s_year">
                {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
              년
            </div>
            <div className="pr-1">
              <select className="form-select-sm" aria-label="Default select example" style={{width: '80px'}} id="s_month">
                {[...Array(12)].map((_, index) => {
                  const monthNumber = index + 1;
                  const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                  return (
                    <option key={monthString} value={monthString}>{monthString}</option>
                  );
                })}
              </select>
              월
            </div>
            <p className="p-1">/</p>
            <div className="pr-2">
              <select className="form-select-sm" aria-label="Default select example" style={{width: '100px'}} id="e_year">
                {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
              년
            </div>
            <div className="pr-1">
              <select className="form-select-sm" aria-label="Default select example" style={{width: '80px'}} id="e_month">
                {[...Array(12)].map((_, index) => {
                  const monthNumber = index + 1;
                  const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                  return (
                    <option key={monthString} value={monthString}>{monthString}</option>
                  );
                })}
              </select>
              월
            </div>
            <div className="pr-1">
              <select className="form-select-sm" id="type" style={{width: "90px"}} onChange={(e) => {
                $('#type').val(e.target.value);
                setType(e.target.value);
              }}>
                <option value="in">수입</option>
                <option value="out">지출</option>
                <option value="all">전체</option>
              </select>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
          </div>

          <div className="m-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border">
              <colgroup>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
              </colgroup>

              <tr>
                <th>{sDate1?.split('-')?.[0]}년 {sDate1?.split('-')?.[1]}월 순이익</th>
                <td className="text-right" style={{cursor: "pointer"}} onClick={() => onFinanceReport(sDate1?.split('-')?.[0], sDate1?.split('-')?.[1])}>{Fn.numberWithCommas(result?.tMC - result?.tBY)}</td>
                <th>{eDate1?.split('-')?.[0]}년 {eDate1?.split('-')?.[1]}월 순이익</th>
                <td className="text-right" style={{cursor: "pointer"}} onClick={() => onFinanceReport(eDate1?.split('-')?.[0], eDate1?.split('-')?.[1])}>{Fn.numberWithCommas(result?.b_tMC - result?.b_tBY)}</td>
              </tr>
            </table>
          </div>

          <div className="m-3" id="div_in" style={{display: "none"}}>
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border text-left">
              <colgroup>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
              </colgroup>

              <tr>
                <th>{sDate1?.split('-')?.[0]}년 {sDate1?.split('-')?.[1]}월 수입합계</th>
                <td className="text-right">{Fn.numberWithCommas(result?.tMC)}</td>
                <th>{eDate1?.split('-')?.[0]}년 {eDate1?.split('-')?.[1]}월 수입합계</th>
                <td className="text-right">{Fn.numberWithCommas(result?.b_tMC)}</td>
              </tr>
              <tr>
                <th colSpan="2"
                    onClick={() => {
                      loadDtl('in', 'all', '수입전체', sDate1, sDate2);
                    }}
                    style={{cursor: 'pointer'}}
                >{sDate1?.split('-')?.[0]}년 {sDate1?.split('-')?.[1]}월 수입
                </th>
                <th colSpan="2"
                    onClick={() => {
                      loadDtl('in', 'all', '수입전체', eDate1, eDate2);
                    }}
                    style={{cursor: 'pointer'}}
                >{eDate1?.split('-')?.[0]}년 {eDate1?.split('-')?.[1]}월 수입
                </th>
              </tr>
              <tr>
                <th>계정</th>
                <th>금액</th>
                <th>계정</th>
                <th>금액</th>
              </tr>
              {result?.aryMC && Object.keys(result?.aryMC).map((k, index) => {
                const v = result?.aryMC?.[k];
                k = k.replace("_", "");
                C_id++;
                tMC += result?.mc['4113']?.[k] || 0;

                return (
                  <tr
                    id={`detail_${C_id}`}
                    style={{cursor: 'pointer'}}
                  >
                    <td className="txt_left"
                        onClick={(e) => {
                          //color_change(e.target);
                          loadDtl('in', k, v, sDate1, sDate2);
                        }}
                    >{v}</td>
                    <td className="text-right"
                        onClick={(e) => {
                          //color_change(e.target);
                          loadDtl('in', k, v, sDate1, sDate2);
                        }}
                    >
                      {result?.mc['4113-' + k] ? Fn.numberWithCommas(result?.mc['4113-' + k]) : ''}
                    </td>
                    <td className="txt_left"
                        onClick={(e) => {
                          //color_change(e.target);
                          loadDtl('in', k, v, eDate1, eDate2);
                        }}
                    >{v}</td>
                    <td className="text-right"
                        onClick={(e) => {
                          //color_change(e.target);
                          loadDtl('in', k, v, eDate1, eDate2);
                        }}
                    >
                      {result?.b_mc['4113-' + k] ? Fn.numberWithCommas(result?.b_mc['4113-' + k]) : ''}
                    </td>
                  </tr>
                );
              })}

              <tr style={{cursor: "pointer"}}>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '2511', '단기대여금', sDate1, sDate2);
                    }}
                >단기대여금
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '2511', '단기대여금', sDate1, sDate2);
                    }}
                >
                  {result?.jgain3 ? Fn.numberWithCommas(result?.jgain3) : ''}
                </td>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '2511', '단기대여금', eDate1, eDate2);
                    }}
                >단기대여금
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '2511', '단기대여금', eDate1, eDate2);
                    }}
                >
                  {result?.b_jgain3 ? Fn.numberWithCommas(result?.b_jgain3) : ''}
                </td>
              </tr>

              <tr style={{cursor: "pointer"}}>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '2221', '단기차입금', sDate1, sDate2);
                    }}
                >단기차입금
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '2221', '단기차입금', sDate1, sDate2);
                    }}
                >
                  {result?.jgain4 ? Fn.numberWithCommas(result?.jgain4) : ''}
                </td>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '2221', '단기차입금', eDate1, eDate2);
                    }}
                >단기차입금
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '2221', '단기차입금', eDate1, eDate2);
                    }}
                >
                  {result?.b_jgain4 ? Fn.numberWithCommas(result?.b_jgain4) : ''}
                </td>
              </tr>

              <tr style={{cursor: "pointer"}}>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '7211', '이자수익', sDate1, sDate2);
                    }}
                >이자수익
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '7211', '이자수익', sDate1, sDate2);
                    }}
                >
                  {result?.jgain ? Fn.numberWithCommas(result?.jgain) : ''}
                </td>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '7211', '이자수익', eDate1, eDate2);
                    }}
                >이자수익
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '7211', '이자수익', eDate1, eDate2);
                    }}
                >
                  {result?.b_jgain ? Fn.numberWithCommas(result?.b_jgain) : ''}
                </td>
              </tr>

              <tr style={{cursor: "pointer"}}>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '7218', '타지점정산', sDate1, sDate2);
                    }}
                >타지점정산
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '7218', '타지점정산', sDate1, sDate2);
                    }}
                >
                  {result?.jgain8 ? Fn.numberWithCommas(result?.jgain8) : ''}
                </td>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '7218', '타지점정산', eDate1, eDate2);
                    }}
                >타지점정산
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '7218', '타지점정산', eDate1, eDate2);
                    }}
                >
                  {result?.b_jgain8 ? Fn.numberWithCommas(result?.b_jgain8) : ''}
                </td>
              </tr>

              <tr style={{cursor: "pointer"}}>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '7214', '잡이익', sDate1, sDate2);
                    }}
                >잡이익
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '7214', '잡이익', sDate1, sDate2);
                    }}
                >
                  {result?.jgain2 ? Fn.numberWithCommas(result?.jgain2) : ''}
                </td>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '7214', '잡이익', eDate1, eDate2);
                    }}
                >잡이익
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '7214', '잡이익', eDate1, eDate2);
                    }}
                >
                  {result?.b_jgain2 ? Fn.numberWithCommas(result?.b_jgain2) : ''}
                </td>
              </tr>

              <tr style={{cursor: "pointer"}}>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('in', '', '미등록', sDate1, sDate2);
                    }}
                >미등록
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('in', '', '미등록', sDate1, sDate2);
                    }}
                >
                  {result?.etc ? Fn.numberWithCommas(result?.etc) : ''}
                </td>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('in', '', '미등록', eDate1, eDate2);
                    }}
                >미등록
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('in', '', '미등록', eDate1, eDate2);
                    }}
                >
                  {result?.b_etc ? Fn.numberWithCommas(result?.b_etc) : ''}
                </td>
              </tr>

              <tr style={{cursor: "pointer"}}>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '3114', '출자금', sDate1, sDate2);
                    }}
                >출자금
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '3114', '출자금', sDate1, sDate2);
                    }}
                >
                  {result?.jgain5 ? Fn.numberWithCommas(result?.jgain5) : ''}
                </td>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '3114', '출자금', eDate1, eDate2);
                    }}
                >출자금
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '3114', '출자금', eDate1, eDate2);
                    }}
                >
                  {result?.b_jgain5 ? Fn.numberWithCommas(result?.b_jgain5) : ''}
                </td>
              </tr>

              <tr style={{cursor: "pointer"}}>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '7215', '임시수입', sDate1, sDate2);
                    }}
                >임시보유자금
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '7215', '임시수입', sDate1, sDate2);
                    }}
                >
                  {result?.jgain6 ? Fn.numberWithCommas(result?.jgain6) : ''}
                </td>
                <td className="txt_left"
                    onClick={(e) => {
                      loadDtl('out', '7215', '임시수입', eDate1, eDate2);
                    }}
                >임시보유자금
                </td>
                <td className="text-right"
                    onClick={(e) => {
                      loadDtl('out', '7215', '임시수입', eDate1, eDate2);
                    }}
                >
                  {result?.b_jgain6 ? Fn.numberWithCommas(result?.b_jgain6) : ''}
                </td>
              </tr>

              <tr className="bgcolor bold700">
                <td className="text-center">미납(-)<br/>전월완납(+)</td>
                <td className="text-right">{Fn.numberWithCommas(-result?.dd?.jan)}</td>
                <td className="text-center">미납(-)<br/>전월완납(+)</td>
                <td className="text-right">{Fn.numberWithCommas(-result?.b_dd?.jan)}</td>
              </tr>
            </table>
          </div>

          <div className="m-3" id="div_out" style={{display: "none"}}>
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border text-left">
              <colgroup>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
              </colgroup>

              <tr>
                <th>{sDate1?.split('-')?.[0]}년 {sDate1?.split('-')?.[1]}월 지출합계</th>
                <td className="text-right"
                >{Fn.numberWithCommas(result?.tBY)}</td>
                <th>{eDate1?.split('-')?.[0]}년 {eDate1?.split('-')?.[1]}월 지출합계</th>
                <td className="text-right"
                >{Fn.numberWithCommas(result?.b_tBY)}</td>
              </tr>
              <tr>
                <th colSpan="2"
                    onClick={() => {
                      loadDtl('out', 'all', '지출전체', sDate1, sDate2);
                    }}
                    style={{cursor: 'pointer'}}
                >{sDate1?.split('-')?.[0]}년 {sDate1?.split('-')?.[1]}월 지출
                </th>
                <th colSpan="2"
                    onClick={() => {
                      loadDtl('out', 'all', '지출전체', eDate1, eDate2);
                    }}
                    style={{cursor: 'pointer'}}
                >{eDate1?.split('-')?.[0]}년 {eDate1?.split('-')?.[1]}월 지출
                </th>
              </tr>
              <tr>
                <th>계정</th>
                <th>금액</th>
                <th>계정</th>
                <th>금액</th>
              </tr>
              {result?.biyong && Object.keys(result?.biyong).map((k, index) => {
                const v = result?.biyong?.[k];
                k = k.replace("_", "");
                return (
                  <tr
                    id={`J_detail_${C_id}`}
                  >
                    <td className="txt_left"
                        onClick={() => {
                          loadDtl('out', k, v, sDate1, sDate2);
                        }}
                        style={{cursor: 'pointer'}}
                    >{v}</td>
                    <td className="text-right"
                        onClick={() => {
                          //color_change(C_id);
                          loadDtl('out', k, v, sDate1, sDate2);
                        }}
                        style={{cursor: 'pointer'}}
                    >
                      {result?.jichul?.[k + "-amt"] ? Fn.numberWithCommas(result?.jichul?.[k + "-amt"]) : ''}
                    </td>
                    <td className="txt_left"
                        onClick={() => {
                          //color_change(C_id);
                          loadDtl('out', k, v, eDate1, eDate2);
                        }}
                        style={{cursor: 'pointer'}}
                    >{v}</td>
                    <td className="text-right"
                        onClick={() => {
                          //color_change(C_id);
                          loadDtl('out', k, v, eDate1, eDate2);
                        }}
                        style={{cursor: 'pointer'}}
                    >
                      {result?.b_jichul?.[k + "-amt"] ? Fn.numberWithCommas(result?.b_jichul?.[k + "-amt"]) : ''}
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>

        {!frMode ?
          <div style={{width: "calc(100% - 700px)"}}>
            <div className="tip_box tip_box2 w-100 my-3"><span className="p-3">Tip! 좌측 계정명을 click하시면 해당 상세 내역을 보실 수 있습니다</span></div>
            <div id="detail_area" className="fixed_box" style={{top: "190px", position: "absolute", maxWidth: "750px"}}>
              <p className="border-bottom p-2 px-3 bold700 text-left h7">계정상세내역 (총 {resultDtl?.result?.length || 0}건)</p>
              <div className="p-3">
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="70px"/>
                    <col width="85px"/>
                    <col width="*"/>
                    <col width="35%"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>No</th>
                    <th>일자</th>
                    <th>거래처</th>
                    <th>적요</th>
                    <th>금액</th>
                  </tr>
                  {type === 'in' ? resultDtl?.result?.map((row, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{row?.ck_date}</td>
                          <td>{row?.ck_sangho}</td>
                          <td className="text-left">{showCkkEtc1(row)}
                          </td>
                          <td className="text-right">{Fn.numberWithCommas(row?.ck_amtdae)}</td>
                        </tr>
                      )
                    }) :
                    resultDtl?.result?.map((row, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{row?.ck_date}</td>
                          <td>{row?.ck_sangho}</td>
                          <td className="text-left">{row?.ckk_etc ? row?.ckk_etc : row?.ck_acname}</td>
                          <td className="text-right">{row?.ck_amtcha ? Fn.numberWithCommas(row?.ck_amtcha) : Fn.numberWithCommas(row?.ck_amtdae)}</td>
                        </tr>
                      )
                    })
                  }
                  <tr className="bgcolor bold700">
                    <td colSpan="4" className="text-center">합계</td>
                    <td className="text-right">{resultDtl.T_price}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          :
          <div style={{width: "calc(100% - 700px)"}}>
            <div className="d-flex" style={{flexDirection: "column"}}>
              <div className="tip_box tip_box2 w-100 my-3"><span className="p-3">Tip! 좌측 계정명을 click하시면 해당 상세 내역을 보실 수 있습니다</span></div>
              <div id="detail_area" className="fixed_box" style={{top: "190px", position: "absolute", maxWidth: "750px"}}>
                <p className="border-bottom p-2 px-3 bold700 text-left h7">계정상세내역</p>
                <div className="p-3">
                  <div className="d-flex" style={{flexDirection: "column"}}>
                    <div>
                      <table className="view-rounded sub_table_border w-100">
                        <tr onClick={() => load2(`/finance/finance/report?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&gyecode=1111&jukcode=현금&top_name=현금`, '현금')}>
                          <th width="30%">현금</th>
                          <td className="text-right">
                            {Fn.numberWithCommas(frResult?.cash?.["jan"])}
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div className="my-3">
                      <table className="view-rounded sub_table_border auto">
                        <tbody>
                        <tr>
                          <th colSpan={2}>통장</th>
                        </tr>
                        </tbody>
                        {frResult?.bank && Object.entries(frResult?.bank).map(([k, v]) =>
                          frResult?.bk?.[k] &&
                          <tr onClick={() => load2(`/finance/finance/report?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&gyecode=1112&jukcode=${k}&top_name=${frResult?.bk[k]}`, frResult?.bk[k])}>
                            <td width="30%" className="danger">
                              {frResult?.bk?.[k]}
                            </td>
                            <td className="text-right">
                              {Fn.numberWithCommas(frResult?.bank?.[k])}
                            </td>
                          </tr>
                        )}
                      </table>
                    </div>

                    <div className="my-3">
                      <table className="view-rounded sub_table_border auto">
                        <tr onClick={() => load2(`/finance/finance/report?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&gyecode=1143&jukcode=&top_name=전체카드`, '전체카드')}>
                          <th colSpan="2">카드매출금</th>
                        </tr>
                        {frResult?.card && Object.entries(frResult?.cardA).map(([k, v]) =>
                          <tr onClick={() => load2(`/finance/finance/report?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&gyecode=1143&jukcode=${k}&top_name=${frResult?.bk[k]}`, frResult?.bk[k])}>
                            <td width="30%" className="danger">
                              {frResult?.bk?.[k]}
                            </td>
                            <td className="text-right">
                              {Fn.numberWithCommas(frResult?.card?.[k])}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td width="30%">합계</td>
                          <td className="text-right">{Fn.numberWithCommas(frResult?.sumCard)}</td>
                        </tr>
                      </table>
                    </div>

                    <div className="my-3">
                      <table className="view-rounded sub_table_border auto">
                        <tr onClick={() => load2(`/finance/finance/report?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&code=all&top_name=전체`, '전체')}>
                          <th width="30%">합계</th>
                          <td className="text-right bold700 text-danger">{Fn.numberWithCommas(frResult?.sumCard + frResult?.sumBank + (frResult?.cash?.["jan"] || 0))}</td>
                        </tr>
                        <tr>
                          <th>전잔액</th>
                          <td className="text-right bold700 text-primary">{Fn.numberWithCommas(frResult?.b_row?.["jan"])}</td>
                        </tr>
                        <tr>
                          <th>증감</th>
                          <td className="text-right bold700 text-success">{Fn.numberWithCommas((frResult?.sumCard + frResult?.sumBank + (frResult?.cash?.["jan"] || 0)) - (frResult?.b_row?.["jan"] || 0))}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </CmnCard>
  );
};

export default AnalysisFinanceMonthlyReport;
