import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";

const AnalysisStatePeriod = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [result, setResult] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  const [t1, setT1] = useState(0);
  const [t2, setT2] = useState(0);
  const [t3, setT3] = useState(0);
  const [t5, setT5] = useState(0);
  const [t6, setT6] = useState(0);
  const [t12, setT12] = useState(0);
  const [t18, setT18] = useState(0);
  const [tt, setTt] = useState(0);

  useEffect(() => {
    const currentDate = Fn.getCurrentDate();
    $('#s_year').val(currentDate.split("-")[0]);
    $('#s_month').val(currentDate.split("-")[1]);

    // 현재 주차
    const cdate = new Date();
    const firstWeekday = new Date(cdate.getFullYear(), cdate.getMonth(), 1).getDay();
    const offsetDate = cdate.getDate() + firstWeekday - 1;
    $('#e_week').val(Math.floor(offsetDate / 7) + 1);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/analysis/state/period/ready?s_brand=${$('#s_brand option:selected').val() || ''}&s_jibu=${$('#s_jibu option:selected').val() || ''}&s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&s_week=${$('#s_week option:selected').val() || ''}&e_week=${$('#e_week option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7 && localStorage.getItem("USER.systemFranYN") === "Y") {
            result.data = result?.data?.filter((row) => row.branch_code == localStorage.getItem("USER.systemBranchCode"));
            result.list = result?.list?.filter((row) => row.branch_code == localStorage.getItem("USER.systemBranchCode"));
          }
          console.log(result);

          setResult(result);

          if ($('#s_brand').html() === '') {
            let optionsHTML = '<option value="">브랜드</option>';
            result?.sBrand?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_brand').html(optionsHTML);
          }
          if ($('#s_jibu').html() === '') {
            let optionsHTML = '<option value="">지부선택</option>';
            result?.sJibu?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.jibu}">${list_row.jibu}</option>`;
            });
            $('#s_jibu').html(optionsHTML);
          }

          let t1 = 0, t2 = 0, t3 = 0, t5 = 0, t6 = 0, t12 = 0, t18 = 0, tt = 0;

          result?.list?.map((item) => {
            const d = result?.data.filter(i => i.branch_code === item.branch_code)?.[0];

            t1 += d?.month1 || 0;
            t2 += d?.month2 || 0;
            t3 += d?.month3 || 0;
            t5 += d?.month5 || 0;
            t6 += d?.month6 || 0;
            t12 += d?.month12 || 0;
            t18 += d?.month18 || 0;

            tt += (d?.month1 || 0) + (d?.month2 || 0) + (d?.month3 || 0) + (d?.month5 || 0) + (d?.month6 || 0) + (d?.month12 || 0) + (d?.month18 || 0);
          });

          setT1(t1);
          setT2(t2);
          setT3(t3);
          setT5(t5);
          setT6(t6);
          setT12(t12);
          setT18(t18);
          setTt(tt);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/analysis/make/data/monthly/ready?s_year=${$('#modal_s_year option:selected').val()}&s_month=${$('#modal_s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function make_data(branchCode, branchName) {
    const sYear = $('#modal_s_year option:selected').val();
    const sMonth = $('#modal_s_month option:selected').val();

    const txt = `${branchName}지점의 ${sYear}년 ${sMonth}월 데이터를 생성하시겠습니까?`;

    if (window.confirm(txt)) {
      if (!$("#overlay").is(":visible"))
        Fn.showSpinner();

      axios2.postEx("/analysis/make/data/monthly", {
        s_year: sYear,
        s_month: sMonth,
        branch_code: branchCode
      })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          console.log(data, result);
          alert('생성되었습니다');
          setRenew(Fn.getRenewTime());
          loadModal();
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        })
        .finally(() => {
          // $(".loading_bg").fadeOut("fast");
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/new/old")}/>
          <label className="tab_item" htmlFor="aa">구분별</label>

          <input id="bb" type="radio" name="tab_item" checked onClick={() => history.push("/analysis/state/period")}/>
          <label className="tab_item" htmlFor="bb">기간별</label>

          <input id="cc" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/visit")}/>
          <label className="tab_item" htmlFor="cc">경로별</label>

          <input id="dd" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/week")}/>
          <label className="tab_item" htmlFor="dd">주별회원현황</label>

          <input id="ee" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/month")}/>
          <label className="tab_item" htmlFor="ee">월별회원현황</label>
        </div>
      </div>

      <div className="overflowX ">
        <div className="d-flex m-3 table_wide_sh2">
          <div className="pr-1">
            <select className="form-select" id="s_brand">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_jibu">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_year">
              {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_month">
              {[...Array(12)].map((_, index) => {
                const monthNumber = index + 1;
                const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
          </div>
          <div className="d-flex pr-1">
            <select className="form-select" id="s_week">
              {Array.from({length: 6}, (_, index) => index + 1).map((week) => (
                <option key={week} value={week}>{week}주</option>
              ))}
            </select>
            <p className="p-1">~</p>
            <select className="form-select" id="e_week">
              {Array.from({length: 6}, (_, index) => index + 1).map((week) => (
                <option key={week} value={week}>{week}주</option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => setModalOpen(true)}>데이타생성</button>
          </div>
        </div>

        <div className="m-3 mb-5 table_list table_wide_sh2">
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="20%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>지점명</th>
              <th>1개월</th>
              <th>2개월</th>
              <th>3개월</th>
              <th>5개월</th>
              <th>6개월</th>
              <th>12개월</th>
              <th>18개월</th>
              <th>합계</th>
            </tr>
            {result?.list?.map((item) => {
              const d = result?.data.filter(i => i.branch_code === item.branch_code)?.[0];
              return (
                <tr key={item.branch_code}>
                  <td>{item.branch_name}</td>
                  <td>{Fn.numberWithCommas(d?.month1)}</td>
                  <td>{Fn.numberWithCommas(d?.month2)}</td>
                  <td>{Fn.numberWithCommas(d?.month3)}</td>
                  <td>{Fn.numberWithCommas(d?.month5)}</td>
                  <td>{Fn.numberWithCommas(d?.month6)}</td>
                  <td>{Fn.numberWithCommas(d?.month12)}</td>
                  <td>{Fn.numberWithCommas(d?.month18)}</td>
                  <td>
                    {(d?.month1 || 0) + (d?.month2 || 0) + (d?.month3 || 0) + (d?.month5 || 0) + (d?.month6 || 0) + (d?.month12 || 0) + (d?.month18 || 0)}
                  </td>
                </tr>
              );
            })}
            {!(Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7 && localStorage.getItem("USER.systemFranYN") === "Y") &&
              <>
                <tr>
                  <td style={{background: '#ffffdd'}}>
                    <strong>합계</strong>
                  </td>
                  <td style={{background: '#ffffdd'}}>
                    <strong id="t1">{Fn.numberWithCommas(t1)}</strong>
                  </td>
                  <td style={{background: '#ffffdd'}}>
                    <strong id="t2">{Fn.numberWithCommas(t2)}</strong>
                  </td>
                  <td style={{background: '#ffffdd'}}>
                    <strong id="t3">{Fn.numberWithCommas(t3)}</strong>
                  </td>
                  <td style={{background: '#ffffdd'}}>
                    <strong id="t5">{Fn.numberWithCommas(t5)}</strong>
                  </td>
                  <td style={{background: '#ffffdd'}}>
                    <strong id="t6">{Fn.numberWithCommas(t6)}</strong>
                  </td>
                  <td style={{background: '#ffffdd'}}>
                    <strong id="t12">{Fn.numberWithCommas(t12)}</strong>
                  </td>
                  <td style={{background: '#ffffdd'}}>
                    <strong id="t18">{Fn.numberWithCommas(t18)}</strong>
                  </td>
                  <td style={{background: '#ffffdd'}}>
                    <strong id="tt">{Fn.numberWithCommas(tt)}</strong>
                  </td>
                </tr>
              </>
            }
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          $('#modal_s_year').val(Fn.getCurrentDate().split('-')[0]);
          $('#modal_s_month').val(Fn.getCurrentDate().split('-')[1]);
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '800px'}}>
            <div className="POP_title">
              <h5><strong>데이터 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box">
              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select className="form-select" id="modal_s_year">
                    {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <div className="pr-1">
                  <select className="form-select" id="modal_s_month">
                    {[...Array(12)].map((_, index) => {
                      const monthNumber = index + 1;
                      const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                      return (
                        <option key={monthString} value={monthString}>{monthString}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal()}>검색</button>
                </div>
              </div>
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <thead>
                  <tr>
                    <th className="w_70px">No</th>
                    <th>지점명</th>
                    <th>최종 주</th>
                    <th>생성일시</th>
                    <th>생성</th>
                  </tr>
                  </thead>
                  <tbody>
                  {modalResult?.result && modalResult?.result?.map((ret, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ret?.branch_name}</td>
                        <td>{ret?.last_week || ''}</td>
                        <td>{ret?.last_date?.replace(/-/g, '.')?.substring(2, 16) || ''}</td>
                        <td>
                          <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => make_data(ret?.branch_code, ret?.branch_name)}>생성</button>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              <div className="text-center my-3">
                <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModalOpen(false)}>닫기</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </CmnCard>
  );
};

export default AnalysisStatePeriod;
