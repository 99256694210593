import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import _ from "lodash";
import {value} from "lodash/seq";

const BranchPtPTReserveMonth = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  let s_form;
  let form1;

  const total_days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  const [year, setYear] = useState(Fn.parseInt(Fn.getCurrentDate().split("-")[0]));
  const [month, setMonth] = useState(Fn.parseInt(Fn.getCurrentDate().split("-")[1]));
  const [day, setDay] = useState(25);
  const [search_YN, setSearchYN] = useState('Y');
  const [team_YN, setTeamYN] = useState('Y');
  const [result, setResult] = useState();
  const [calendarArray, setCalendarArray] = useState([]);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    load();
  }, [renew, srchRenew]);

  const load = () => {
    axios2
      .post(`/branchop/pt/reserve/month/ready?year=${year}&month=${Fn.lpad(month.toString(), 2, "0")}&day=${Fn.lpad(day.toString(), 2, "0")}&search_YN=Y&u_name=&base_tranier_seq=&s_tranier_seq=${$('#s_tranier_seq option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $('.class-cal').remove();

          if ($('#s_tranier_seq').html() === '') {
            let optionsHTML = '<option value="">트레이너 선택</option>';
            result?.tnList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#s_tranier_seq').html(optionsHTML);
          }

          // 윤년
          if (new Date(year, 1, 29).getDate() === 29) {
            total_days[1] = 29;
          }

          const first_yoil = new Date(year, month - 1, 1).toLocaleString('en-US', {weekday: 'long'});
          const last_yoil = new Date(year, month - 1, total_days[month - 1]).toLocaleString('en-US', {weekday: 'long'});

          let td_su, td_su2;

          switch (first_yoil) {
            case 'Sunday':
              td_su = 0;
              break;
            case 'Monday':
              td_su = 1;
              break;
            case 'Tuesday':
              td_su = 2;
              break;
            case 'Wednesday':
              td_su = 3;
              break;
            case 'Thursday':
              td_su = 4;
              break;
            case 'Friday':
              td_su = 5;
              break;
            case 'Saturday':
              td_su = 6;
              break;
          }

          switch (last_yoil) {
            case 'Sunday':
              td_su2 = 6;
              break;
            case 'Monday':
              td_su2 = 5;
              break;
            case 'Tuesday':
              td_su2 = 4;
              break;
            case 'Wednesday':
              td_su2 = 3;
              break;
            case 'Thursday':
              td_su2 = 2;
              break;
            case 'Friday':
              td_su2 = 1;
              break;
            case 'Saturday':
              td_su2 = 0;
              break;
          }

          const start_num = 1 - td_su;
          const end_num = total_days[month - 1] + td_su2;

          const _calendar_array = [];

          for (let i = start_num; i <= end_num; i++) {
            if (i < 1 || i > total_days[month - 1]) {
              _calendar_array[i] = [' ', ' '];
            } else {
              _calendar_array[i] = [i, new Date(year, month - 1, i).toLocaleString('en-US', {weekday: 'long'})];
            }
          }


          let output = '';

          console.log(start_num, end_num, _calendar_array);
          let cnt = 1;
          for (let i = start_num; i <= end_num; i++) {
            const value = _calendar_array[i];
            let holy_class = '';

            if (value[1] === 'Sunday' || cnt === 1) {
              if (year === new Date().getFullYear() && month === new Date().getMonth() + 1 && day === new Date().getDate() && value[0] === day) {
                holy_class = "class='sunday today'";
              } else {
                holy_class = "class='sunday'";
              }
            } else {
              if (year === new Date().getFullYear() && month === new Date().getMonth() + 1 && day === new Date().getDate() && value[0] === day) {
                holy_class = "class='today'";
              } else {
                holy_class = "";
              }
            }

            if (cnt % 7 === 1) {
              output += '<tr class="class-cal">';
            }

            let db_month = month < 10 ? '0' + month : month.toString();
            let db_day = value[0] < 10 ? '0' + value[0] : value[0].toString();
            let today_chk = `${year}-${db_month}-${db_day}`;

            holy_class = "scope";

            if (value?.[0] > 0)
              output += `<td class="${holy_class}"><a href="#" class="cal_date">${value[0]}</a>`;
            else
              output += `<td><a href="#" class="cal_date">${value[0]}</a>`;

            if (result?.result?.[today_chk]?.length) output += `<ul class="px-5 text-left pt-2" style="vertical-align: top;">`;
            result?.result?.[today_chk]?.forEach(row => {
              let base_seq = btoa(row?.seq);
              let basic_time = row?.basic_time === ":" ? "" : row?.basic_time;
              let cls = row?.take_YN === "Y" ? "cell2_memo" : "cell_memo";
              let mb_split = row?.mb_info?.split("/");

              output += `<li class="border-bottom"><strong>[${row?.state}]${basic_time}:00</strong> ${mb_split?.[1] || ''} (${row?.trainer_name || ''}T)</li>`;
            });
            if (result?.result?.[today_chk]?.length) output += `</ul>`;

            output += '</td>';

            if (cnt % 7 === 0) {
              output += '</tr>';
            }

            cnt++;
          }

          $('#this_area').after(output);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }


  useEffect(() => {
    s_form = $("[name='s_form']");
    form1 = $("[name='form1']");
  });

  const chkAuth = (value) => {
    axios
      .post('/branchop/search/user/pt', `type=search&search_auth_code=${value}`)
      .then(response => {
        let data = response.data.trim();

        if (data === 'no_auth') {
          alert('인증번호가 일치하지 않습니다');
          s_form.auth_code.value = '';
          s_form.search_auth_code.value = '';
          s_form.search_YN.value = 'N';
          s_form.u_name.value = '';
          // auth_code_name.innerHTML = ''; // TODO
          s_form.base_tranier_seq.value = '';
        } else if (data === '') {
          alert('설정오류! 시스템 관리자에게 문의하세요.');
          s_form.auth_code.value = '';
          s_form.search_auth_code.value = '';
          s_form.search_YN.value = 'N';
          s_form.u_name.value = '';
          // auth_code_name.innerHTML = ''; // TODO
          s_form.base_tranier_seq.value = '';
          s_form.search_auth_code.focus();
        } else {
          const re_data = data.split('||');
          s_form.search_auth_code.value = '';
          s_form.auth_code.value = '';
          // auth_code_name.innerHTML = re_data[0]; // TODO
          s_form.u_name.value = re_data[0];
          s_form.search_YN.value = 'Y';
          s_form.base_tranier_seq.value = re_data[1];
          s_form.submit();
        }
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  function onDate(_year, _month) {
    setYear(_year);
    setMonth(_month);
    setRenew(Fn.getRenewTime());
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex justify-content-between m-3 flex-wrap">
        <div className="mt-2">
          <span className="h4 bold700">{year}년</span>
        </div>
        <div className="mt-2">
          <ul className="d-flex my-1">
            <li className="cal-years">
              <a className="cal-link" style={{cursor: "pointer"}} onClick={() => onDate(year - 1, 12)}>{year - 1}년</a>
            </li>
            {Array.from({length: 12}, (_, i) =>
              <li className="cal-item"><a className="cal-link" href="#" style={{cursor: "pointer"}} onClick={() => onDate(year, i + 1)}>{i + 1}월</a></li>
            )}
            <li className="cal-years">
              <a className="cal-link" onClick={() => onDate(year + 1, 1)}>{year + 1}년</a>
            </li>
          </ul>
        </div>

        <div className="d-flex mt-2">
          <div className="pr-1">
            <select className="form-select" id="s_tranier_seq">
            </select>
          </div>
          <button type="button" className="btn btn-sm btn-point" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded calender">
          <tr id="this_area">
            <th><span style={{color: "red"}}>일</span></th>
            <th>월</th>
            <th>화</th>
            <th>수</th>
            <th>목</th>
            <th>금</th>
            <th>토</th>
          </tr>


          {/*<tr>*/}
          {/*  <td></td>*/}
          {/*  <td></td>*/}
          {/*  <td></td>*/}
          {/*  <td></td>*/}
          {/*  <td className="scope">*/}
          {/*    <a href="#" className="cal_date">1</a>*/}
          {/*    <ul className="px-3 text-left">*/}
          {/*      <li className="border-bottom">01.트레이너</li>*/}
          {/*      <li className="border-bottom">02.트레이너</li>*/}
          {/*    </ul>*/}
          {/*  </td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">2</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_today">3</a></td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">4</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">5</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">6</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">7</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">8</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">9</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">10</a></td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">11</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">12</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">13</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">14</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">15</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">16</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">17</a></td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">18</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">19</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">20</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">21</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">22</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">23</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">24</a></td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">25</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">26</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">27</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">28</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">29</a></td>*/}
          {/*  <td className="scope"><a href="#" className="cal_date">30</a></td>*/}
          {/*  <td></td>*/}
          {/*</tr>*/}
        </table>
      </div>
    </CmnCard>
  );
};

export default BranchPtPTReserveMonth;
