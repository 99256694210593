import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import Constants from "../../constants/constants";
import Fn from "../../libraries/Fn";


const MemberTakeOverMember = (props) => {

  const [result, setResult] = useState([]);

  const go_file = () => {
    if (!$('#userfile').val()) {
      alert('파일을 등록해주세요');
      return;
    }

    const formData = new FormData();

    formData.append('userfile', $('#userfile')[0].files[0]);

    axios2.postFormEx('/member/insu/member/ready', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (code === 200) {
          setResult(result);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const go_file_real = () => {
    if (!$('#userfile').val()) {
      alert('파일을 등록해주세요');
      return;
    }

    const formData = new FormData();

    formData.append('userfile', $('#userfile')[0].files[0]);

    axios2.postFormEx('/member/insu/member/real', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (code === 200) {
          alert('등록되었습니다.');
          setResult([]);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3">
          <div className="pr-1 d-flex">
            <div className="input-group">
              <input type="file" className="form-control" aria-describedby="inputGroupFileAddon04" aria-label="Upload" name="userfile" id="userfile"/>
              <button className="btn btn-sm btn-point" type="button" id="inputGroupFileAddon04" onClick={() => go_file()}>파일등록</button>
            </div>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => Fn.download(`${Constants.API_URL}/common/download?cd=attachment&type=samples&filename=${encodeURIComponent("insu_sample.xls")}`)}>샘플 다운로드</button>
          </div>
        </div>
      </div>

      <div className="m-3 pb-5 table_list overflowX">
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border table">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="140px"/>
            <col width="*"/>
            <col width="60px"/>
            <col width="20%"/>
            <col width="110px"/>
            <col width="110px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="110px"/>
            <col width="110px"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>회원번호</th>
            <th>회원명</th>
            <th>연락처</th>
            <th>생년월일</th>
            <th>성별</th>
            <th>주소</th>
            <th>시작일</th>
            <th>종료일</th>
            <th>라커</th>
            <th>라커시작</th>
            <th>라커종료</th>
            <th>운동복</th>
            <th>운동복시작</th>
            <th>운동복종료</th>
            <th>1일2회</th>
          </tr>
          {result && result.map((ret, i) => {
            console.log(ret);
            return (
              <tr>
                <td>{i + 1}</td>
                <td>{ret?.mbId}</td>
                <td>{ret?.mbName}</td>
                <td>{ret?.mbHp}</td>
                <td>{ret?.mbBirth}</td>
                <td>{ret?.mbSex === 'M' ? '남' : '여'}</td>
                <td className="text-left">{ret?.addrCode}</td>
                <td>{ret?.startDate}</td>
                <td>{ret?.endDate}</td>
                <td>{ret?.locker == 1 ? "사용" : "미사용"}</td>
                <td>{ret?.lockerStartDate}</td>
                <td>{ret?.lockerEndDate}</td>
                <td>{ret?.suit == 1 ? "사용" : "미사용"}</td>
                <td>{ret?.suitStartDate}</td>
                <td>{ret?.suitEndDate}</td>
                <td>{ret?.dubAble}</td>
              </tr>
            );
          })}
          {(!result || result?.length === 0) &&
            <tr>
              <td colSpan={16}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top">
        <div>
          <div className="d-flex">
            <button type="button" className="btn btn-sm btn-outline-point px-2" onClick={() => go_file_real()}>
              회원등록
            </button>
          </div>
        </div>
      </nav>

    </CmnCard>
  );
};
export default MemberTakeOverMember;
