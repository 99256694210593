import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios from "axios";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";

const BranchopGxReserve = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/branchop/gx/reserve/ready?s_gx_key=${$('#s_gx_key option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_gx_key').html() === '') {
            let optionsHTML = '<option value="">::GX 이름::</option>';
            result?.g?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.gx_name}</option>`;
            });
            $('#s_gx_key').html(optionsHTML);
          }

          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 fn_search_box">
          <div className="pr-1">
            <select className="form-select" id="s_gx_key">
            </select>
          </div>
          <div className="input-group pr-1" style={{width: "auto"}}>
            <JDatePicker className="form-control" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="s_date2"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="성명 검색" id="s_mb_name"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_border auto">
            <tr>
              <th className="w_70px">No</th>
              <th>대상일</th>
              <th>GX구분</th>
              <th>GX타입</th>
              <th className="w-20">GX이름</th>
              <th>시작시간</th>
              <th>성명</th>
              <th>회원번호</th>
              <th>연락처</th>
              <th>예약일시</th>
            </tr>
            {rets && rets.map((ret, i) => {

              const basic_date = ret.basic_date;
              const mb_name = ret.mb_name;
              const mb_id = ret.mb_id;
              const mb_no = ret.mb_no;
              const mb_hp = ret.mb_hp;
              const reg_date = ret.reg_date;
              const gx_info = ret.gx_info;

              const gx_info_split = gx_info.split("||");

              const gx_type = gx_info_split[0];
              const gx_gubun = gx_info_split[1];
              const gx_name = gx_info_split[2];
              const start_time = gx_info_split[3];

              let gx_type_show = "";
              if (gx_type === "A") {
                gx_type_show = "무료회원";
              } else if (gx_type === "B") {
                gx_type_show = "유료회원";
              }

              let gx_gubun_show = "";
              if (gx_gubun === "opt4") {
                gx_gubun_show = "스피닝";
              } else if (gx_gubun === "opt7") {
                gx_gubun_show = "필라테스";
              }

              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>{basic_date.replace(/-/g, '.').substr(2, 8)}</td>
                  <td>{gx_type_show}</td>
                  <td>{gx_gubun_show}</td>
                  <td className="text-left">{gx_name}</td>
                  <td>{start_time}</td>
                  <td>{mb_name}</td>
                  <td>{mb_id}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{reg_date.replace(/-/g, '.').substr(2, 8)}</td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={10}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
            {pageInfo?.totalArticles > 0 && (
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('gx_reserve')}>연락처 확인</button>
              )
            )}
          </div>
          {/*<? if ($total_record) { ?>*/}
          {/* <? if ($_SESSION[SystemUserAuth] == "9" || ($_SESSION[SystemUserAuth] == "8" && $_SESSION[SystemManageYN] != "Y" && $total_record <= 300) || ($_SESSION[SystemUserAuth] == "7" && $total_record <= 300) || ($_SESSION[SystemUserAuth] == "6" && $total_record <= 10)) { ?>*/}
          {/*   <button type="button" class="btn btn-primary btn-sm" onclick="show_phone()">연락처확인</button>*/}
          {/* <? } ?>*/}
          {/*<? } ?>*/}
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default BranchopGxReserve;
