import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import JDatePicker from "../common/JDatePicker";
import Fn from "../../libraries/Fn";

const MvChangeBrand = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();
  const [txt, setTxt] = useState('');
  const [dateRange, setDateRange] = useState('');

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const load = () => {
    const type = props.periodType;
    let txt;

    switch (type) {
      case "health":
        txt = "이용기간";
        break;
      case "suit":
        txt = "운동복 이용기간";
        break;
      case "locker":
        txt = "라커 이용기간";
        break;
      case "golf":
        txt = "골프 이용기간";
        break;
      case "g_locker":
        txt = "골프라커 이용기간";
        break;
      case "sque":
        txt = "스쿼시 이용기간";
        break;
      case "spin":
        txt = "스피닝 이용기간";
        break;
      case "tae":
        txt = "태닝 이용기간";
        break;
      case "pila":
        txt = "필라테스 이용기간";
        break;
      default:
        txt = "이용기간";
        break;
    }
    setTxt(txt);

    axios2
      .post(`/member/change/period/ready?mb_no=${props.mbNo}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);

          let dateRange = '';
          if (type === "health") {
            dateRange = `${result?.start_date || ''} ~ ${result?.end_date || ''}`;
          } else if (type === "suit") {
            dateRange = `${result?.suit_start_date || ''} ~ ${result?.suit_end_date || ''}`;
          } else if (type === "locker") {
            dateRange = `${result?.locker_start_date || ''} ~ ${result?.locker_end_date || ''}`;
          } else if (type === "g_locker") {
            dateRange = `${result?.g_locker_start_date || ''} ~ ${result?.g_locker_end_date || ''}`;
          } else if (type === "golf") {
            dateRange = `${result?.etc3_start_date || ''} ~ ${result?.etc3_end_date || ''}`;
          } else if (type === "sque") {
            dateRange = `${result?.etc1_start_date || ''} ~ ${result?.etc1_end_date || ''}`;
          } else if (type === "spin") {
            dateRange = `${result?.etc2_start_date || ''} ~ ${result?.etc2_end_date || ''}`;
          } else if (type === "tae") {
            dateRange = `${result?.etc4_start_date || ''} ~ ${result?.etc4_end_date || ''}`;
          } else if (type === "pila") {
            dateRange = `${result?.etc5_start_date || ''} ~ ${result?.etc5_end_date || ''}`;
          }
          setDateRange(dateRange);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = (insert_type) => {
    if ($('#auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
      return;
    }

    if ($('#p_start_date').val() == "") {
      alert("변경 시작일을 입력해주세요");
      return;
    }

    if ($('#p_end_date').val() == "") {
      alert("변경 종료일을 입력해주세요");
      return;
    }

    if ($('#change_reason').val() == "") {
      alert("변경사유를 입력해주세요");
      $('#change_reason').focus();
      return;
    }

    let url = "";
    if (insert_type == "in") {
      url = "/member/change/period";
    } else {
      url = "/member/change/period/request";
    }

    axios2
      .postEx(url, {
        auth_code: $('#auth_code').val(),
        mb_no: props.mbNo,
        type: props.periodType,
        reg_step: $('#_reg_step option:selected').val() || '',
        start_date: $('#p_start_date').val(),
        end_date: $('#p_end_date').val(),
        change_reason: $('#change_reason').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        } else {
          alert('변경되었습니다');
          props?.onCallback();
          onToggleModal();
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '850px'}}>
          <div className="POP_title">
            <h5><strong>이용기간 변경</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <table className="view-rounded sub_view" style={{borderRadius: 0}}>
              <colgroup>
                <col width="220px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>변경전 {txt}</th>
                <td>{dateRange === 'null ~ null' ? '' : dateRange}</td>
              </tr>
              <tr>
                <th>변경후 {txt}</th>
                <td>
                  <div className="d-flex">
                    <JDatePicker className="form-control" id="p_start_date"/>
                    <p className="mx-2">~</p>
                    <JDatePicker className="form-control" id="p_end_date"/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>변경사유</th>
                <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" id="change_reason"/></td>
              </tr>
            </table>
          </div>
          <div className="text-center mb-3">
            {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7 ?
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit('in')}>기간변경</button> :
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit('request')}>승인요청</button>
            }
            <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>닫기</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MvChangeBrand;
