import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const MvServicePeriod = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const load = () => {
    axios2
      .post(`/member/app/period/setting/ready?ap_date=${$('#edit_ap_date').val()}&mb_no=${props.mbNo}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);

          $('#ii_input_form')[0].mb_no.value = props.mbNo;
          $('#ii_input_form')[0].ap_date.value = $('#edit_ap_date').val();
          $('#ii_input_form')[0].auth_code.value = $('#auth_code').val();
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {
    if ($('#s_date1').val() == "") {
      alert("서비스 시작일을 입력해주세요");
      $('#s_date1').focus();
      return;
    }

    var form = $('#ii_input_form')[0];
    var formData = new FormData(form);
    $.ajax({
      url: '/member/app/period/setting',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        alert('설정되었습니다. 라커와 같이 등록되어있는경우 라커정보를 입력해주셔야 합니다.');
        props?.onCallback();
        onToggleModal();
      }
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '900px'}}>
          <div className="POP_title">
            <h5><strong>이용기간 설정</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100" style={{minHeight: '370px'}}>
            <form name="ii_input_form" id="ii_input_form" method="post">
              <input type="hidden" name="mb_no"/>
              <input type="hidden" name="ap_date"/>
              <input type="hidden" name="auth_code"/>
              <table className="view-rounded sub_table_border" style={{borderRadius: 0}}>
                <colgroup>
                  <col width="110px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>이용시작일</th>
                  <td>
                    <div className="input-group" style={{width: 'auto'}}>
                      <JDatePicker name="s_date1" id="s_date1" type="text" className="form-control wid80" placeholder="" selectOnly={true}/>
                      <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit()}>등록</button>
                    </div>
                  </td>
                </tr>
              </table>

              <table className="view-rounded sub_table_border mt-4" style={{borderRadius: 0, borderTopWidth: 2, borderTopColor: "#aaa"}}>
                <colgroup>
                  <col width="180px"/>
                  <col width="130px"/>
                  <col width="110px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>서비스명</th>
                  <th>가격</th>
                  <th>기간</th>
                  <th>서비스비고</th>
                </tr>
                {result?.main?.length > 0 && result?.main?.map((row, index) =>
                  <tr>
                    <td className="text-left">{row?.ap_pdtName}</td>
                    <td className="text-right">{Fn.numberWithCommas(row?.ap_amt)}</td>
                    <td className="text-right">{row?.period} {row?.period_type}</td>
                    <td className="text-left">
                      {row?.ap_etc}
                      <input type="hidden" name={`ap_no${index + 1}`} id={`ap_no${index + 1}`} value={row?.ap_no}/>
                      <input type="hidden" name={`ap_pdt${index + 1}`} id={`ap_pdt${index + 1}`} value={row?.pdt_type}/>
                    </td>
                  </tr>
                )}
              </table>

              <input type="hidden" name="count" id="count" value={result?.main?.length}/>
              {result?.chkGLocker > 0 && <input type="hidden" name="locker_g" id="locker_g" value="Y"/>}
              {result?.chkLocker > 0 && <input type="hidden" name="locker_n" id="locker_n" value="Y"/>}
              {result?.chkSuit > 0 && <input type="hidden" name="suit" id="suit" value="Y"/>}

            </form>
          </div>

          <div className="text-center mb-3">
            <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>닫기</button>
          </div>

        </div>
      </div>
    </Modal>
  );
};

export default MvServicePeriod;
