import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";
import ChkAuthNum from "../common/ChkAuthNum";

const ConfigCouponList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);

  const [modal1SrchRenew, setModal1SrchRenew] = useState(0);
  const [modal1CurrPage, setModal1CurrPage] = useState(1);
  const [modal1Result, setModal1Result] = useState({});
  const [modal1PageInfo, setModal1PageInfo] = useState();

  const [modal2SrchRenew, setModal2SrchRenew] = useState(0);
  const [modal2CurrPage, setModal2CurrPage] = useState(1);
  const [modal2Result, setModal2Result] = useState({});
  const [modal2PageInfo, setModal2PageInfo] = useState();

  const [modal3Result, setModal3Result] = useState({});
  const [modal3CouponType, setModal3CouponType] = useState("A");

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  useEffect(() => {
    if (!modal1SrchRenew) return;
    $('[id^=m1chk]').prop("checked", false);
    loadModal1(1);
  }, [modal1SrchRenew]);

  useEffect(() => {
    if (!modal1CurrPage) return;
    $('[id^=m1chk]').prop("checked", false);
    loadModal1();
  }, [modal1CurrPage]);

  useEffect(() => {
    if (!modal2SrchRenew) return;
    loadModal2(1);
  }, [modal2SrchRenew]);

  useEffect(() => {
    if (!modal2CurrPage) return;
    loadModal2();
  }, [modal2CurrPage]);

  const load = (_page) => {
    axios2
      .post(`/branchop/coupon/list/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_coupon_name=${$('#s_coupon_name').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const del_data = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 쿠폰내역을 삭제 하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/branchop/coupon/list/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('삭제되었습니다.');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  const loadModal1 = (_page) => {
    if (modal1Open) {
      let params = `o_date1=${$('#m1_o_date1').val() || ''}&o_date2=${$('#m1_o_date2').val() || ''}&`;
      params += `s_date1=${$('#m1_s_date1').val() || ''}&s_date2=${$('#m1_s_date2').val() || ''}&`;
      params += `s_mb_name=${$('#m1_s_mb_name').val() || ''}&s_mb_hp=${$('#m1_s_mb_hp').val() || ''}&`;
      params += `s_branch_code=${$('#m1_s_branch_code option:selected').val() || ''}&`;
      params += `s_sex=${$('#m1_s_sex option:selected').val() || ''}&`;
      params += `e_date1=${$('#m1_e_date1').val() || ''}&e_date2=${$('#m1_e_date2').val() || ''}&`;
      params += `s_visit_course=${$('#m1_s_visit_course option:selected').val() || ''}&`;
      params += `s_mb_birth=${$('#m1_s_mb_birth').val() || ''}&`;
      params += `s_member_grade=${$('#m1_s_member_grade option:selected').val() || ''}&`;
      params += `num_per_page=${$('#m1_num_per_page option:selected').val() || ''}`;

      console.log(`/branchop/coupon/publish/ready?base_seq=${$('#base_seq').val() || ''}&page=${_page || modal1CurrPage}&search_YN=${$('#m1_search_YN').val() || ''}&${params}`);
      axios2
        .post(`/branchop/coupon/publish/ready?base_seq=${$('#base_seq').val() || ''}&page=${_page || modal1CurrPage}&search_YN=${$('#m1_search_YN').val() || ''}&${params}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            console.log(result);

            /*
            params += `s_branch_code=${$('#m1_s_branch_code option:selected').val()}&`;
            params += `s_visit_course=${$('#m1_s_visit_course option:selected').val()}&`;
            params += `s_member_grade=${$('#m1_s_member_grade option:selected').val()}&`;
           */
            if ($('#m1_s_branch_code').html() === '') {
              let optionsHTML = '<option value="">::지점::</option>';
              result?.topList?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
              });
              $('#m1_s_branch_code').html(optionsHTML);
            }
            if ($('#m1_s_visit_course').html() === '') {
              let optionsHTML = '<option value="">가입경로</option>';
              result?.topList?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.sub_code_name}">${list_row.sub_code_name}</option>`;
              });
              $('#m1_s_visit_course').html(optionsHTML);
            }
            if ($('#m1_s_member_grade').html() === '') {
              let optionsHTML = '<option value="">::브랜드::</option>';
              result?.bbList?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
              });
              $('#m1_s_member_grade').html(optionsHTML);
            }

            setModal1Result(result);
            setModal1PageInfo(pageInfo);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const loadModal2 = (_page) => {
    if (modal2Open) {
      axios2
        .post(`/branchop/publish/check/ready?page=${_page || modal2CurrPage}&base_seq=${$('#base_seq').val() || ''}&s_jibu=${$('#m2_s_jibu option:selected').val() || ''}&s_branch_code=${$('#m2_s_branch_code option:selected').val() || ''}&s_date1=${$('#m2_s_date1').val() || ''}&s_date2=${$('#m2_s_date2').val() || ''}&s_mb_name=${$('#m2_s_mb_name').val() || ''}&s_use_YN=${$('#m2_s_use_YN option:selected').val() || ''}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            if ($('#m2_s_jibu').html() === '') {
              let optionsHTML = '<option value="">::지부</option>';
              result?.jibuRs?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.jibu}">${list_row.jibu}</option>`;
              });
              $('#m2_s_jibu').html(optionsHTML);
            }
            if ($('#m2_s_branch_code').html() === '') {
              let optionsHTML = '<option value="">::지점</option>';
              result?.topList?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
              });
              $('#m2_s_branch_code').html(optionsHTML);
            }
            console.log(result);

            setModal2Result(result);
            setModal2PageInfo(pageInfo);

            Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const loadModal3 = () => {
    $('#modal3_submit').show();

    axios2
      .post(`/branchop/coupon/make/ready?base_seq=${$('#base_seq').val() || ''}&copy_seq=${$('#copy_seq').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModal3Result(result);

          if (result?.row?.seq) {
            setModal3CouponType(result?.row?.coupon_type || 'A');
            setTimeout(() => {
              // $('#base_seq').val(btoa(result?.row?.seq));
              $('#coupon_price').val(result?.row?.coupon_price);
              $('#coupon_type').val(result?.row?.coupon_type);
              $('#make_date').val(result?.row?.make_date);
              $('#coupon_name').val(result?.row?.coupon_name);
              $('#health_YN').prop("checked", result?.row?.health_YN == "Y");
              $('#suit_YN').prop("checked", result?.row?.suit_YN == "Y");
              $('#locker_YN').prop("checked", result?.row?.locker_YN == "Y");
              $('#period_type').val(result?.row?.period_type);
              $('#period').val(result?.row?.period);
              $('#permit_date').val(result?.row?.permit_date);
              $('#gcm_title').val(result?.row?.gcm_title);
              $('#gcm_msg').val(result?.row?.gcm_msg);
              $('#it_id').val(result?.row?.it_id);
              $('#push_YN').val(result?.row?.push_YN);

              if ($('#base_seq').val())
                $('#modal3_submit').hide();

              $('#gcm_title').val(result?.row?.gcm_title);
              $('#gcm_msg').val(result?.row?.gcm_msg);
            }, 100);
          } else {
            $('#make_date').val(Fn.getCurrentDate());

            if (!$('#gcm_title').val()) $('#gcm_title').val("스포애니 쿠폰발급");
            if (!$('#gcm_msg').val()) $('#gcm_msg').val("쿠폰이 발급되었으니 마이페이지에서 확인하세요");
          }

          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit3() {
    if ($('#coupon_name').val() == "") {
      alert("쿠폰명을 입력해주세요");
      $('#coupon_name').focus();
      return;
    }

    if (modal3CouponType === "A") {
      if ($('#health_YN').is(":checked") == false && $('#suit_YN').is(":checked") == false && $('#locker_YN').is("checked") == false) {
        alert("적용구분 1개 이상을 선택해주세요");
        return;
      }

      if ($('#period') == "0" || $('#period').val() == "") {
        alert("적용기간을 입력해주세요");
        $('#period').focus();
        return;
      }
      if ($('#period_type option:selected').val() == "") {
        alert("적용기간을 구분을 선택해주세요");
        $('#period_type').focus();
        return;
      }
    } else {
      if ($('#coupon_price').val() == "" || $('#coupon_price').val() == "0") {
        alert("쿠폰금액을 입력해주세요");
        $('#coupon_price').focus();
        return;
      }
    }

    if ($('#permit_date').val() == "") {
      alert("유효기간을 입력해주세요");
      $('#permit_date').focus();
      return;
    }

    if (window.confirm('쿠폰을 생성하시겠습니까?')) {
      axios2
        .postEx('/branchop/coupon/make', {
          base_seq: $('#base_seq').val(),
          coupon_type: $('#coupon_type option:selected').val(),
          make_date: $('#make_date').val(),
          coupon_name: $('#coupon_name').val(),
          health_YN: $('#health_YN:checked').val() || '',
          suit_YN: $('#suit_YN:checked').val() || '',
          locker_YN: $('#locker_YN:checked').val() || '',
          period_type: $('#period_type option:selected').val() || '',
          period: $('#period').val() || '',
          permit_date: $('#permit_date').val() || '',
          gcm_title: $('#gcm_title').val() || '',
          gcm_msg: $('#gcm_msg').val() || '',
          it_id: $('#it_id').val() || '',
          push_YN: $('#push_YN option:selected').val() || '',
          coupon_price: $('#coupon_price').val() || '',
        })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data === "err") {
            alert('오류가 발생하였습니다');
          } else {
            if (data == "err") {
              alert('오류가 발생하였습니다');
            } else if (data == "ok") {
              alert("생성되었습니다.");
              setRenew(Fn.getRenewTime());
              setModal3Open(false);
            } else if (data == "edit") {
              alert("수정되었습니다.");
              setRenew(Fn.getRenewTime());
              setModal3Open(false);
            }
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function chk_it_id() {
    const it_id = $("#it_id").val();

    if (it_id) {
      axios2
        .post(`/branchop/chk/coupon/it/id?it_id=${it_id}`)
        .then((response) => {
          const {code, message: data, result, pageInfo} = response?.data;
          if (data == "no") {
            alert("판매중인 상품이 아닙니다");
            $("#it_id").val('');
            $("#it_id_name").text('');
          } else {
            $("#it_id_name").text(data);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function search_pub() {

    /*
        String baseSeq = (String) requestParams.get("base_seq");
        String sDate1 = (String) requestParams.get("s_date1");
        String sDate2 = (String) requestParams.get("s_date2");
        String eDate1 = (String) requestParams.get("e_date1");
        String eDate2 = (String) requestParams.get("e_date2");
        String oDate1 = (String) requestParams.get("o_date1");
        String oDate2 = (String) requestParams.get("o_date2");
        String key = (String) requestParams.get("key");
        String sBranchCode = (String) requestParams.get("s_branch_code");
    */

    let params = `o_date1=${$('#m1_o_date1').val() || ''}&o_date2=${$('#m1_o_date2').val() || ''}&`;
    params += `s_date1=${$('#m1_s_date1').val() || ''}&s_date2=${$('#m1_s_date2').val() || ''}&`;
    params += `s_mb_name=${$('#m1_s_mb_name').val() || ''}&s_mb_hp=${$('#m1_s_mb_hp').val() || ''}&`;
    params += `s_branch_code=${$('#m1_s_branch_code option:selected').val() || ''}&`;
    params += `s_sex=${$('#m1_s_sex option:selected').val() || ''}&`;
    params += `e_date1=${$('#m1_e_date1').val() || ''}&e_date2=${$('#m1_e_date2').val() || ''}&`;
    params += `s_visit_course=${$('#m1_s_visit_course option:selected').val() || ''}&`;
    params += `s_mb_birth=${$('#m1_s_mb_birth').val() || ''}&`;
    params += `s_member_grade=${$('#m1_s_member_grade option:selected').val() || ''}&`;
    params += `num_per_page=${$('#m1_num_per_page option:selected').val() || ''}`;

    if (window.confirm('검색된 회원에게 쿠폰을 발급 하시겠습니까?')) {
      axios2.post(`/branchop/coupon/pub/search?base_seq=${$('#base_seq').val() || ''}&search_YN=${$('#m1_search_YN').val() || ''}&${params}`)
        .then(function (response) {
          const {code, message: data, result, pageInfo} = response?.data;
          if (data == "err") {
            alert('오류가 발생하였습니다');
          } else if (data == "ok") {
            alert('발급되었습니다.');
            setModal1Open(false);
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function chk_pub() {
    let con = $('[id^=m1chk]:checked').map(function () {
      return $(this).val();
    }).get().join(",");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    const formData = new FormData();

    formData.append("chk[]", con);
    formData.append("base_seq", $('#base_seq').val());

    // TODO
    // String itId = (String) requestParams.get("it_id");
    // String push_YN = (String) requestParams.get("push_YN");

    if (window.confirm('체크한 회원에게 쿠폰을 발급 하시겠습니까?')) {
      axios2.postFormEx('/branchop/coupon/pub/chk', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else {
            alert("처리되었습니다");
            setModal1Open(false);
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 fn_search_box">
          <div className="input-group pr-1" style={{width: "auto"}}>
            <p className="p-1">생성일 : </p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="p-1">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="쿠폰명" id="s_coupon_name"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => {
              $('#copy_seq').val('');
              $('#base_seq').val('');
              setModal3CouponType('A');
              setModal3Open(true)
            }}>쿠폰생성
            </button>
          </div>
        </div>

        {/* 테이블 */}
        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_rowspan">
            <colgroup>
              <col width="75"/>
              <col width="55"/>
              <col width="85"/>
              <col width="*"/>
              <col width="70"/>
              <col width="70"/>
              <col width="70"/>
              <col width="90"/>
              <col width="105"/>
              <col width="85"/>
              <col width="90"/>
              <col width="90"/>
              <col width="90"/>
              <col width="90"/>
              <col width="90"/>
            </colgroup>

            <tr>
              <th rowSpan="2">No</th>
              <th rowSpan="2">
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th rowSpan="2">생성일</th>
              <th rowSpan="2">쿠폰명</th>
              <th colSpan="3">적용구분</th>
              <th rowSpan="2">적용기간</th>
              <th rowSpan="2">쿠폰금액</th>
              <th rowSpan="2">유효기간</th>
              <th rowSpan="2">발급수</th>
              <th rowSpan="2">사용수</th>
              <th rowSpan="2">쿠폰발급</th>
              <th rowSpan="2">발급내역</th>
              <th rowSpan="2">복사등록</th>
            </tr>
            <tr>
              <th>헬스</th>
              <th>운동복</th>
              <th>라커</th>
            </tr>

            {rets.map((row, i) => {
              const count = i + 1;
              const seq = row.seq;
              const make_date = row.make_date;
              const coupon_name = row.coupon_name;
              const health_YN = row.health_YN;
              const suit_YN = row.suit_YN;
              const locker_YN = row.locker_YN;
              const period_type = row.period_type;
              const period = row.period;
              const coupon_price = row.coupon_price;
              const permit_date = row.permit_date;

              // 발급수
              const pp_num = row?.ppNum || 0;

              // 사용수
              const uu_num = row?.uuNum || 0;

              return (
                // <tr style={{background: `${bgclr}`}} key={i}>
                <tr key={i}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    {!pp_num ? (
                      <input
                        type="checkbox"
                        name={`chk[${count}]`}
                        value={btoa(seq)}
                        id={`achk${count}`}
                        className="checkbox"
                      />
                    ) : null}
                    <label htmlFor={`achk${count}`}><span></span></label>
                  </td>
                  <td>{make_date.substring(2, 10).replace(/-/g, ".")}</td>
                  <td>
                    <a style={{cursor: "pointer"}}
                       onClick={() => {
                         $('#base_seq').val(btoa(seq));
                         $('#copy_seq').val('');
                         setModal3Open(true);
                       }}
                    >
                      <strong>
                        <div align="left" style={{paddingLeft: '5px'}}>{coupon_name}</div>
                      </strong>
                    </a>
                  </td>
                  <td>{health_YN}</td>
                  <td>{suit_YN}</td>
                  <td>{locker_YN}</td>
                  <td>{period} {period_type}</td>
                  <td>
                    <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(coupon_price)}</div>
                  </td>
                  <td>{permit_date.substring(2, 10).replace(/-/g, ".")}</td>
                  <td>
                    <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(pp_num)}</div>
                  </td>
                  <td>
                    <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(uu_num)}</div>
                  </td>
                  <td>
                    <input
                      type="button"
                      className="btn btn-ssm btn-outline-point"
                      value="쿠폰발급"
                      // onClick={() => pop_up(`coupon_publish.html?base_seq=${base64_encode(seq)}`, 'gift_make_detail', '950', '700', 'yes')}
                      onClick={() => {
                        $('#base_seq').val(btoa(seq));
                        setModal1Open(true);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="button"
                      className="btn btn-ssm btn-outline-dark"
                      value="발급내역"
                      // onClick={() => pop_up(`publish_check.html?base_seq=${base64_encode(seq)}`, 'gift_make_detail', '900', '600', 'yes')}
                      onClick={() => {
                        $('#base_seq').val(btoa(seq));
                        setModal2Open(true);
                      }}
                    />
                  </td>
                  <td>
                    <input
                      type="button"
                      className="btn btn-ssm btn-outline-dark"
                      value="복사등록"
                      onClick={() => {
                        $('#base_seq').val('');
                        $('#copy_seq').val(btoa(seq));
                        setModal3Open(true);
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          <div>
            <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => del_data()}>삭제</button>
          </div>
        </nav>
      </div>

      {/*팝업 1*/
      }

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal1();
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01_wide p_absolute_mid_pop">
            <div className="POP_title">
              <h5><strong>쿠폰 발급</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="search_box">
                {/* 회원검색 */}
                <div className="p-3">
                  <table className="view-rounded sub_view_sm">
                    <colgroup>
                      <col width="90px"/>
                      <col width="*"/>
                      <col width="90px"/>
                      <col width="*"/>
                      <col width="90px"/>
                      <col width="*"/>
                    </colgroup>
                    <tr>
                      <th>가입일</th>
                      <td>
                        <div className="input-group" style={{width: 'auto'}}>
                          <JDatePicker className="form-control table-inline" id="m1_o_date1"/>
                          <p className="mx-1">~</p>
                          <JDatePicker className="form-control table-inline" id="m1_o_date2"/>
                        </div>
                      </td>
                      <th>이름</th>
                      <td>
                        <input type="text" className="form-control table-inline" placeholder="이름" id="m1_s_mb_name"/>
                      </td>
                      <th>연락처</th>
                      <td>
                        <input type="text" className="form-control table-inline" placeholder="연락처" id="m1_s_mb_hp"/>
                      </td>
                    </tr>
                    <tr>
                      <th>운동시작일</th>
                      <td>
                        <div className="input-group" style={{width: 'auto'}}>
                          <JDatePicker className="form-control table-inline" id="m1_s_date1"/>
                          <p className="mx-1">~</p>
                          <JDatePicker className="form-control table-inline" id="m1_s_date2"/>
                        </div>
                      </td>
                      <th>지점선택</th>
                      <td>
                        <select className="form-select-sm table-inline" id="m1_s_branch_code">
                        </select>
                      </td>
                      <th>성별</th>
                      <td className="text-left">
                        <div className="input-group">
                          <select className="form-select-sm table-inline" id="m1_s_sex">
                            <option value="">성별</option>
                            <option value="M">남성</option>
                            <option value="F">여성</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>운동종료일</th>
                      <td>
                        <div className="input-group" style={{width: 'auto'}}>
                          <JDatePicker className="form-control table-inline" id="m1_e_date1"/>
                          <p className="mx-1">~</p>
                          <JDatePicker className="form-control table-inline" id="m1_e_date2"/>
                        </div>
                      </td>
                      <th>가입경로</th>
                      <td className="text-left">
                        <select className="form-select-sm table-inline" id="m1_s_visit_course">
                        </select>
                      </td>
                      <th>생년월일</th>
                      <td className="text-left">
                        <input type="text" className="form-control table-inline" placeholder="생년월일" id="m1_s_mb_birth"/>
                      </td>
                    </tr>
                    <tr>
                      <th>브랜드</th>
                      <td>
                        <select className="form-select-sm table-inline" id="m1_s_member_grade">
                        </select>
                      </td>
                      <th>쿠폰개수</th>
                      <td>
                        <select className="form-select-sm table-inline" id="m1_num_per_page">
                          <option value="20">20개</option>
                          <option value="50">50개</option>
                          <option value="100">100개</option>
                          <option value="200">200개</option>
                          <option value="300">300개</option>
                        </select>
                      </td>
                      <th></th>
                      <td></td>
                    </tr>
                  </table>
                  <div className="text-right mt-2">
                    <button type="button" className="btn btn-sm btn-point px-3" onClick={() => {
                      $("#m1_search_YN").val("Y");
                      setModal1SrchRenew(Fn.getRenewTime());
                    }}>검색
                    </button>
                  </div>
                </div>
              </div>

              <div className="text-center mt-3">
                {/* 회원목록 */}
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="65px"/>
                    <col width="70px"/>
                    <col width="*"/>
                    <col width="130px"/>
                    <col width="*"/>
                    <col width="130px"/>
                    <col width="60px"/>
                    <col width="80px"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>

                  <tr>
                    <th>No</th>
                    <th>
                      <input type="checkbox" onClick={(e) => {
                        if ($(e.target).prop("checked")) $('[id^=m1chk]').prop("checked", true);
                        else $('[id^=m1chk]').prop("checked", false);
                      }}/>
                    </th>
                    <th>지점명</th>
                    <th>카드번호</th>
                    <th>성명</th>
                    <th>연락처</th>
                    <th>성별</th>
                    <th>연령</th>
                    <th>가입일</th>
                    <th>시작일</th>
                    <th>종료일</th>
                    <th>미수금</th>
                  </tr>
                  {modal1Result?.result?.map((ret, i) => {
                    let mb_sex_show = '';
                    if (ret?.mb_sex === 'M') {
                      mb_sex_show = '남';
                    } else if (ret?.mb_sex === 'F') {
                      mb_sex_show = '여';
                    }

                    const m_birth_str = ret?.mb_birth.substring(0, 2);

                    let birth_show = '';
                    if (m_birth_str === '19') {
                      birth_show = 100;
                    } else {
                      if (m_birth_str < '30') {
                        const m_real_b = '20' + m_birth_str;
                        birth_show = new Date().getFullYear() - parseInt(m_real_b, 10) + 1;
                      } else {
                        const m_real_b = '19' + m_birth_str;
                        birth_show = new Date().getFullYear() - parseInt(m_real_b, 10) + 1;
                      }
                    }

                    return (
                      <tr>
                        <td>{modal1PageInfo?.totalArticles - modal1PageInfo?.articlesPerPage * (modal1PageInfo?.currentPage - 1) - i}</td>
                        <td>
                          <input
                            type="checkbox"
                            name="m1chk"
                            value={btoa(ret?.mb_no)}
                            className="checkbox"
                            id={`m1chk${i + 1}`}
                          />
                        </td>
                        <td>{ret?.branch_name}</td>
                        <td>{ret?.mb_id}</td>
                        <td>{ret?.mb_name}</td>
                        <td>{Fn.hpShow(ret?.mb_hp, modal1PageInfo?.totalArticles)}</td>
                        <td>{mb_sex_show}</td>
                        <td>{birth_show}</td>
                        <td>{ret?.open_date?.substring(0, 10)}</td>
                        <td>{ret?.start_date}</td>
                        <td>{ret?.end_date}</td>
                        <td>
                          <div style={{textAlign: 'right', paddingRight: '5px'}}>
                            {ret?.remain_price ? new Intl.NumberFormat('en-US').format(ret?.remain_price) : ''}
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </table>
              </div>

              <nav className="p-3 mb-4 d-flex justify-content-center border-top flex-wrap">
                <Paging pageInfo={modal1PageInfo} setCurrPage={page => setModal1CurrPage(page)}/>
              </nav>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => search_pub()}>검색발급</button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => chk_pub()}>체크발급</button>
            </div>
          </div>
        </div>
      </Modal>

      {/*팝업 2*/}
      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal2();
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01_wide p_absolute_mid_pop">
            <div className="POP_title">
              <h5><strong>쿠폰 발급내역</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="d-flex">
                <div className="pr-1">
                  <select className="form-select" aria-label="Default select example" id="m2_s_jibu">
                  </select>
                </div>
                <div className="pr-1">
                  <select className="form-select" aria-label="Default select example" id="m2_s_branch_code">
                  </select>
                </div>
                <div className="input-group pr-1" style={{width: 'auto'}}>
                  <p className="p-1">사용기간 : </p>
                  <JDatePicker className="form-control" aria-describedby="button-addon2" id="m2_s_date1"/>
                  <p className="p-1">~</p>
                  <JDatePicker className="form-control" aria-describedby="button-addon2" id="m2_s_date2"/>
                </div>
                <div className="pr-1">
                  <input type="text" className="form-control width_shorts" placeholder="회원명" id="m2_s_mb_name"/>
                </div>
                <div className="pr-1">
                  <select className="form-select" aria-label="Default select example" id="m2_s_use_YN">
                    <option value="">사용여부</option>
                    <option value="Y">사용완료</option>
                    <option value="N">미사용</option>
                  </select>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setModal2SrchRenew(Fn.getRenewTime())}>검색</button>
                </div>
              </div>

              <div className="text-center mt-3" style={{minHeight: "500px"}}>
                {/* 회원목록 */}
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="60px"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="24%"/>
                  </colgroup>
                  <tr>
                    <th>No</th>
                    <th>지점명</th>
                    <th>회원번호</th>
                    <th>회원명</th>
                    <th>연락처</th>
                    <th>사용기간</th>
                  </tr>
                  {modal2Result?.result?.map((ret, i) => {
                    return (
                      <tr>
                        <td>{modal2PageInfo?.totalArticles - modal2PageInfo?.articlesPerPage * (modal2PageInfo?.currentPage - 1) - i}</td>
                        <td>{ret?.branch_name}</td>
                        <td>{ret?.mb_id}</td>
                        <td>{ret?.mb_name}</td>
                        <td>
                          <div id={`default_show${i + 1}`}>***-****-****</div>
                        </td>
                        <td>{ret?.use_date}</td>
                      </tr>
                    )
                  })}
                </table>
              </div>
            </div>
            <nav className="d-flex justify-content-between flex-wrap pt-2">
              <Paging pageInfo={modal2PageInfo} setCurrPage={page => setModal2CurrPage(page)}/>
              <div className="d-flex justify-content-end">
                <div className="d-flex mx-1">
                  <ChkAuthNum type={"locker_info_change"} prefix="m2_"/>
                </div>
                <div className="d-flex mx-1">
                  <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="다운로드 사유 입력" style={{width: '150px'}}/>
                  <button type="button" className="btn btn-sm btn-outline-point px-2">엑셀저장</button>
                </div>
                {pageInfo?.totalArticles > 0 && (
                  (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                    <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('publish_check', "m2_")}>연락처 확인</button>
                  )
                )}
              </div>
            </nav>
          </div>
        </div>
      </Modal>

      {/*팝업 3*/}

      <Modal
        isOpen={modal3Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal3();
        }}
        onRequestClose={() => setModal3Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01_wide p_absolute_mid_pop">
            <div className="POP_title">
              <h5><strong>쿠폰 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal3Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="text-center">
                {/* 회원목록 */}
                <table className="view-rounded sub_table_rowspan">
                  <colgroup>
                    {modal3CouponType === "A" ? (
                      <>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="24%"/>
                        <col width="70px"/>
                        <col width="70px"/>
                        <col width="70px"/>
                        <col width="*"/>
                        <col width="*"/>
                      </>
                    ) : (
                      <>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                        <col width="*"/>
                      </>
                    )}
                  </colgroup>

                  {modal3CouponType === "A" ? (
                    <>
                      <tr>
                        <th rowSpan="2">생성일</th>
                        <th rowSpan="2">쿠폰타입</th>
                        <th rowSpan="2">쿠폰명</th>
                        <th colSpan="3">적용구분</th>
                        <th rowSpan="2">적용기간</th>
                        <th rowSpan="2">유효기간</th>
                      </tr>
                      <tr>
                        <th>헬스</th>
                        <th>운동복</th>
                        <th>라커</th>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <th>생성일</th>
                      <th>쿠폰타입</th>
                      <th>쿠폰명</th>
                      <th>쿠폰금액</th>
                      <th>유효기간</th>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <JDatePicker className="form-control" id="make_date"/>
                    </td>
                    <td>
                      <select className="form-select" aria-label="Default select example" id="coupon_type" onChange={(e) => setModal3CouponType(e.target.value)}>
                        <option value="A">기간쿠폰</option>
                        <option value="B">금액쿠폰</option>
                      </select>
                    </td>
                    <td>
                      <input type="text" className="form-control" id="coupon_name"/>
                    </td>
                    {modal3CouponType === "A" ? (
                      <>
                        <td>
                          <input
                            type="checkbox"
                            id="health_YN"
                            value="Y"
                            // checked={health_YN === "Y"}
                            className="checkbox"
                          />
                          <label htmlFor="health_YN"><span></span></label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="suit_YN"
                            value="Y"
                            // checked={suit_YN === "Y"}
                            className="checkbox"
                          />
                          <label htmlFor="suit_YN"><span></span></label>
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            id="locker_YN"
                            value="Y"
                            // checked={locker_YN === "Y"}
                            className="checkbox"
                          />
                          <label htmlFor="locker_YN"><span></span></label>
                        </td>
                        <td>
                          <div className="d-flex">
                            <input
                              type="text"
                              className="form-control wid180 mr-1"
                              placeholder=""
                              id="period"
                              style={{imeMode: 'disabled', width: '55px'}}
                              onFocus={(event) => event.target.select()}
                              // onKeyPress={(event) => numkeyCheck(event)}
                              maxLength={3}
                            />
                            <select
                              className="form-control wid200"
                              id="period_type"
                            >
                              <option value="">--</option>
                              <option value="month">개월</option>
                              <option value="day">일</option>
                            </select>
                          </div>
                        </td>
                      </>
                    ) : (
                      <td>
                        <input
                          type="text"
                          className="form-control wid100"
                          placeholder=""
                          id="coupon_price"
                          style={{textAlign: 'right', paddingRight: '5px', imeMode: 'disabled'}}
                          onFocus={(event) => event.target.select()}
                          // onKeyPress={(event) => numkeyCheck(event)}
                          // onKeyUp={(event) => vComma(event.target)}
                          maxLength={12}
                        />
                      </td>
                    )}
                    <td>
                      <JDatePicker className="form-control" id="permit_date"/>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="text-center mt-3">
                {/* 회원목록 */}
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="50%"/>
                    <col width="*"/>
                  </colgroup>

                  <tr>
                    <th>적용회원권</th>
                    <th>Push 제목</th>
                    <th>Push 내용</th>
                    <th>Push 발송</th>
                  </tr>
                  <tr>
                    <td>
                      <input type="text" className="form-control" id="it_id" onBlur={() => chk_it_id()}/>
                      <span id="it_id_name"></span>
                    </td>
                    <td>
                      <input type="text" className="form-control" id="gcm_title"/>
                    </td>
                    <td>
                      <input type="text" className="form-control" id="gcm_msg"/>
                    </td>
                    <td>
                      <select className="form-select" aria-label="Default select example" id="push_YN">
                        <option value="Y">발송</option>
                        <option value="N">미발송</option>
                      </select>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="text-center">
              <button type="button" className="btn btn-sm btn-point px-3" id="modal3_submit" onClick={() => go_submit3()}>생성</button>
              <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => setModal3Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="base_seq"/>
      <input type="hidden" id="copy_seq"/>
      <input type="hidden" id="m1_search_YN"/>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default ConfigCouponList;
