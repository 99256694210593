import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";

const ConfigUserReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const [baseSeq, setBaseSeq] = useState(location?.state?.baseSeq || '');

  const [form1, setForm1] = useState($('#form1')[0]);

  const [modal1Open, setModal1Open] = useState(false);

  const [result, setResult] = useState({});
  const [result1, setResult1] = useState({});

  useEffect(() => {
    $('#base_seq').val(location?.state?.baseSeq || '');
    load();
  }, []);

  const load = () => {
    axios2
      .post(`/config/user/reg/ready?base_seq=${baseSeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setForm1($('#form1')[0]);

          if ($('#menu_auth').html() === '') {
            let optionsHTML = '<option value="">::메뉴권한선택</option>';
            result?.list1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.auth_name}</option>`;
            });
            $('#menu_auth').html(optionsHTML);
          }
          if ($('#branch_code').html() === '') {
            let optionsHTML = '<option value="">::소속(지점)선택</option>';
            result?.list2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#branch_code').html(optionsHTML);
          }

          setResult(result);
          console.log(result);

          if (baseSeq) {
            $('#base_seq').val(baseSeq);
            $('#user_id').val(result?.row?.user_id);
            $('#user_name').val(result?.row?.user_name);
            $('#user_auth').val(result?.row?.user_auth);
            $('#menu_auth').val(result?.row?.menu_auth);
            $('#branch_code').val(result?.row?.branch_code);
            $('#etc_auth').val(result?.row?.manage_YN === 'Y' ? "A" : (result?.row?.account_YN === 'Y' ? "B" : ""));
            $('#manage_branch').val(result?.row?.manage_branch || '');
            $('#fran_YN').prop("checked", result?.row?.fran_YN === "Y");
            if (result?.row?.use_YN === 'Y') $('#use_YN').prop("checked", true);

            try {
              $('#manage_hp1').val(result?.row?.manage_hp?.split("-")?.[0]);
            } catch (err) {
            }
            try {
              $('#manage_hp2').val(result?.row?.manage_hp?.split("-")?.[1]);
            } catch (err) {
            }
            try {
              $('#manage_hp3').val(result?.row?.manage_hp?.split("-")?.[2]);
            } catch (err) {
            }
          } else {
            $('#use_YN').prop("checked", true);
          }

          if (baseSeq) {
            manage_view();
            select_user_auth_edit();
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const go_submit = () => {
    const form1 = $('#form1')[0];

    if (!baseSeq) {
      if (form1.user_id.value == "") {
        alert('ID를 입력해주세요');
        form1.user_id.focus();
        return;
      }
      if (form1.user_pwd.value == "") {
        alert('비밀번호를 입력해주세요');
        form1.user_pwd.focus();
        return;
      }
      var password = form1.user_pwd.value
      if (!/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/.test(password)) {
        alert("비밀번호를 영문,숫자,특수문자를 포함한 8~15자까지 입력해주세요");
        return false;
      }
    }

    if (form1.user_name.value == "") {
      alert('성명을 입력해주세요');
      form1.user_name.focus();
      return;
    }
    if (form1.user_auth.value == "") {
      alert('사용메뉴를 입력해주세요');
      form1.user_auth.focus();
      return;
    }
    if (form1.user_auth.value != "9" && form1.user_auth.value != "7" && form1.user_auth.value != "5" && form1.menu_auth.value == "") {
      alert('메뉴권한을 선택해주세요');
      form1.menu_auth.focus();
      return;
    }
    if (form1.branch_code.value == "") {
      alert('소속(지점)을 선택해주세요');
      form1.branch_code.focus();
      return;
    }
    if ((form1.etc_auth.value == "A" || form1.etc_auth.value == "B") && form1.manage_branch.value == "") {
      alert('관리지점을 설정해주세요');
      return;
    }

    var formData = new FormData(form1);
    $.ajax({
      url: '/config/user/reg',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data?.message?.trim();
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (data == "dup_id") {
          alert('동일한 ID가 존재합니다')
        } else if (data == "insert_err") {
          alert("등록오류! 관리자에게 문의하세요.");
        } else if (data == "up_err") {
          alert("수정오류! 관리자에게 문의하세요.");
        } else if (data == "insert_ok") {
          alert("등록되었습니다");
          history.push("/config/user/list");
        } else if (data == "up_ok") {
          alert("수정되었습니다");
          history.push("/config/user/list");
        }
      }
    });
  };

  const loadModal1 = () => {
    axios2
      .post(`/branchop/branch/manager/ready?branch_list=${encodeURIComponent($('#branch_list').val() || '')}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult1(result);

          setTimeout(() => {
            result?.list?.forEach((list_row) => {
              const branchList = $('#manage_branch').val().split("|");
              if (branchList?.filter(r => r === list_row?.branch_code)?.length) {
                $('#m1chk_' + list_row?.branch_code).prop("checked", true);
              }
            });
          }, 200);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const submitModal1 = () => {
    let con = $('[id^=m1chk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    $('#manage_branch').val(con);

    setModal1Open(false);
  }

  const manage_view = () => {
    if ($('#form1')[0].etc_auth.value === "") {
      $('#manage_show').css('display', "none");
    } else {
      $('#manage_show').css('display', "inline");
    }

    if ($('#form1')[0].etc_auth.value === "A") {
      $(".manage_show2").css("display", "");
    } else {
      $(".manage_show2").css("display", "none");
    }
  };

  //  3-1.
  const select_user_auth = () => {
    const form1 = $('#form1')[0];

    if (
      form1.user_auth.value === "9" ||
      form1.user_auth.value === "7" ||
      form1.user_auth.value === "5"
    ) {
      form1.menu_auth.value = "";
      form1.menu_auth.style.background = "#fee9ec";
      form1.menu_auth.disabled = true;

      // 지부장 선택 불가
      // form1.manage_YN.checked = false;
      // form1.manage_YN.disabled = true;
      form1.etc_auth.value = "";
      form1.etc_auth.disabled = true;

      $('#manage_show').css('display', "none");
    } else if (form1.user_auth.value === "8") {
      form1.menu_auth.value = "";
      form1.menu_auth.style.background = "#fff";
      form1.menu_auth.disabled = false;

      // 지부장 선택 가능
      form1.etc_auth.disabled = false;
    } else {
      form1.menu_auth.value = "";
      form1.menu_auth.style.background = "#fff";
      form1.menu_auth.disabled = false;

      // 지부장 선택 불가
      // form1.manage_YN.checked = false;
      // form1.manage_YN.disabled = true;
      form1.etc_auth.value = "";
      form1.etc_auth.disabled = true;

      $('#manage_show').css('display', "none");
    }

    if (form1.user_auth.value !== "7") {
      $('#show_fran_YN').hide();
      $('#fran_YN').val("N");
    } else {
      $('#show_fran_YN').show();
    }
  };

  //  4-1.
  const select_user_auth_edit = () => {
    const form1 = $('#form1')[0];

    if (
      form1.user_auth.value === "9" ||
      form1.user_auth.value === "7" ||
      form1.user_auth.value === "5"
    ) {
      form1.menu_auth.style.background = "#fee9ec";
      form1.menu_auth.disabled = true;

      // 지부장 선택 불가
      // form1.manage_YN.disabled = true;
      form1.etc_auth.value = "";
      form1.etc_auth.disabled = true;

      $('#manage_show').css('display', "none");
    } else if (form1.user_auth.value === "8") {
      form1.menu_auth.style.background = "#fff";
      form1.menu_auth.disabled = false;

      // 지부장 선택 가능
      // form1.manage_YN.disabled = false;
      form1.etc_auth.disabled = false;
    } else {
      form1.menu_auth.style.background = "#fff";
      form1.menu_auth.disabled = false;

      // 지부장 선택 불가
      // form1.manage_YN.disabled = true;
      form1.etc_auth.value = "";
      form1.etc_auth.disabled = true;

      $('#manage_show').css('display', "none");
    }

    if (form1.user_auth.value !== "7") {
      $('#show_fran_YN').hide();
      $('#fran_YN').val("N");
    } else {
      $('#show_fran_YN').show();
    }
  };

  //  6-1.
  const check_id = (user_id) => {
    if (user_id) {
      fetch('chk_id.php', {
        method: 'POST',
        body: JSON.stringify({user_id: user_id}),
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => response.text())
        .then(data => {
          data = data.trim();

          if (data === 'dup') {
            alert('동일한 ID가 존재합니다');
            form1.user_id.value = '';
          }
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <form name="form1" method="post" id="form1">
        <input type="hidden" name="base_seq" id="base_seq"/>

        <div className="m-3" style={{border: '1px solid #eee', borderRadius: '12px'}}>
          <div className="border-bottom text-left">
            <p className="px-3 py-2">
              <span className="h6 bold700">계정 등록</span>
            </p>
          </div>
          {/* 컨텐츠 내용 시작----- */}
          <div className="p-3">
            <table className="view-rounded sub_view">
              <colgroup>
                <col width="120px"/>
                <col width="*"/>
                <col width="120px"/>
                <col width="*"/>
                <col width="120px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>ID <font color='red'>*</font></th>
                <td className="text-left">
                  {baseSeq !== '' ?
                    result?.row?.user_id
                    :
                    <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="15자이내 영문/숫자" id="user_id" name="user_id"/>
                  }
                </td>
                <th>비밀번호 <font color='red'>*</font></th>
                <td>
                  <input type="password" className="form-control" aria-describedby="button-addon2" placeholder="영문/숫자/특수문자조합" id="user_pwd" name="user_pwd"/>
                </td>
                <th>성명 <font color='red'>*</font></th>
                <td>
                  <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="이름입력" id="user_name" name="user_name"/>
                </td>
              </tr>
              <tr>
                <th>사용메뉴 <font color='red'>*</font></th>
                <td className="text-left">
                  <select className="form-select" aria-label="Default select example" id="user_auth" name="user_auth" style={{maxWidth: "50%"}} onChange={() => select_user_auth()}>
                    <option value="">::사용메뉴선택</option>
                    {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 9 &&
                      <option value="9">본사관리</option>
                    }
                    <option value="8">본사스텝</option>
                    <option value="7">지점관리</option>
                    <option value="6">지점스텝</option>
                  </select>
                  <span id="show_fran_YN" style={{display: "none"}}><b style={{marginLeft: 25}}>가맹점주</b> <input type="checkbox" id="fran_YN" name="fran_YN" value="Y" style={{marginLeft: 5}}/></span>
                </td>
                <th>메뉴권한</th>
                <td className="text-left">
                  <select className="form-select" aria-label="Default select example" id="menu_auth" name="menu_auth" style={{maxWidth: "50%"}}>
                  </select>
                </td>
                <th>소속(지점) <font color='red'>*</font></th>
                <td className="text-left">
                  <select className="form-select" aria-label="Default select example" id="branch_code" name="branch_code" style={{maxWidth: "50%"}}>
                  </select>
                </td>
              </tr>
              <tr>
                <th>지부장/경리팀</th>
                <td>
                  <div className="input-group text-left">
                    <select className="form-select mr-2" aria-label="Default select example" id="etc_auth" name="etc_auth" style={{maxWidth: "50%"}} onChange={() => manage_view()}>
                      <option value="">해당없음</option>
                      <option value="A">지부장</option>
                      <option value="B">경리팀</option>
                    </select>
                    <span id="manage_show" style={{display: "none"}}><button type="button" className="btn btn-ssm btn-outline-point" onClick={() => setModal1Open(true)}>지점설정</button></span>
                    <input type="hidden" name="manage_branch" id="manage_branch"/>
                  </div>
                </td>
                <th>로그인 허용</th>
                <td className="text-left">
                  <input type="checkbox" id="use_YN" name="use_YN" value="Y"/>
                </td>
                <th><span className='manage_show2' style={{display: "none"}}>지부장연락처</span></th>
                <td>
                <span className='manage_show2' style={{display: "none"}}>
                  <div className="input-group text-left">
                    <input type="text" className="form-control wid50" name="manage_hp1" id="manage_hp1" style={{imeMode: "active", maxWidth: "60px"}} maxLength={4}/>
                    <input type="text" className="form-control wid50 mx-1" name="manage_hp2" id="manage_hp2" style={{imeMode: "active", maxWidth: "60px"}} maxLength={4}/>
                    <input type="text" className="form-control wid50" name="manage_hp3" id="manage_hp3" style={{imeMode: "active", maxWidth: "60px"}} maxLength={4}/>
                  </div>
                </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </form>

      <div className="m-4 text-center">
        <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
        <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.push("/config/user/list")}>목록</button>
      </div>

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal1();
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 520}}>
            <div className="POP_title">
              <h5><strong>지점설정</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              {/* 회원목록 */}
              <table className="view-rounded sub_table_border text-center">
                <colgroup>
                  <col width="60px"/>
                  <col width="70px"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>
                    <input type="checkbox" onClick={(e) => {
                      if ($(e.target).prop("checked")) $('[id^=m1chk]').prop("checked", true);
                      else $('[id^=m1chk]').prop("checked", false);
                    }}/>
                  </th>
                  <th>지점코드</th>
                  <th>지점명</th>
                </tr>
                {result1?.list?.length > 0 && result1?.list?.map((ret, index) =>
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <input
                        type="checkbox"
                        name={`m1chk[${index + 1}]`}
                        value={ret?.branch_code}
                        className="checkbox"
                        id={`m1chk_${ret?.branch_code}`}
                      />
                    </td>
                    <td>{ret?.branch_code}</td>
                    <td>{ret?.branch_name}</td>
                  </tr>
                )}
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => submitModal1()}>설정</button>
              <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => setModal1Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>
    </CmnCard>
  );
};

export default ConfigUserReg;
