import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import JDatePicker from "../common/JDatePicker";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";

const MvBlackListReg = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();
  const [type, setType] = useState();

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const load = () => {
    // axios2
    //   .post(`/member/black/list/reg?mb_no=${props.mbNo}`)
    //   .then((response) => {
    //     const {code, message, result, pageInfo} = response?.data;
    //     if (code === 200) {
    //       setResult(result);
    //     }
    //   })
    //   .catch((error) => {
    //     if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
    //   });
  }

  const goSubmit = () => {

    if ($('#bl_reg_type').val() === 'register') {
      if (!$('#register_date').val()) {
        alert("관심회원 등록일을 선택해 주세요.");
        return;
      }
      if (!$('#register_reason').val()) {
        alert("관심회원 등록사유를 입력해 주세요.");
        return;
      }
    } else {
      if (!$('#release_date').val()) {
        alert("관심회원 해제일을 선택해 주세요.");
        return;
      }
      if (!$('#release_reason').val()) {
        alert("관심회원 해제사유를 입력해 주세요.");
        return;
      }
    }

    axios2
      .postEx(props.mbNo ? `/member/black/list/reg` : `/employee/black/list/reg`, {
        type: $('#bl_reg_type').val() || 'register',
        mb_no: props.mbNo,
        base_manager_seq: props.baseSeq,
        auth_code: $('#auth_code').val(),
        register_date: $('#register_date').val(),
        register_reason: $('#register_reason').val(),
        release_date: $('#release_date').val(),
        release_reason: $('#release_reason').val()
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert("처리실패! 관리자에게 문의하세요");
        } else {
          alert("처리되었습니다");
          props?.onCallback();
          onToggleModal();
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '720px'}}>
          <div className="POP_title">
            {$('#bl_reg_type').val() === 'register' ?
              <h5><strong>관심회원 등록</strong></h5>
              :
              <h5><strong>관심회원 해제</strong></h5>
            }
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <table className="view-rounded sub_table" style={{borderRadius: 0}}>
              <colgroup>
                <col width="160px"/>
                <col width="*"/>
              </colgroup>
              {$('#bl_reg_type').val() === 'register' ?
                <>
                  <tr>
                    <th className="border-right">등록일</th>
                    <th>등록사유</th>
                  </tr>
                  <tr style={{backgroundColor: "#ffffff"}}>
                    <td className="border-right text-center"><JDatePicker className="form-control" aria-describedby="button-addon2" id="register_date" selectOnly={true}/></td>
                    <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" id="register_reason"/></td>
                  </tr>
                </>
                :
                <>
                  <tr>
                    <th className="border-right">해제일</th>
                    <th>해제사유</th>
                  </tr>
                  <tr style={{backgroundColor: "#ffffff"}}>
                    <td className="border-right text-center"><JDatePicker className="form-control" aria-describedby="button-addon2" id="release_date" selectOnly={true}/></td>
                    <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" id="release_reason"/></td>
                  </tr>
                </>
              }
            </table>
          </div>
          <div className="text-center mb-3">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit()}>저장</button>
            <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>닫기</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MvBlackListReg;
