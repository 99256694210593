import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import {useHistory} from "react-router-dom";

const ConfigAuthList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/config/auth/list/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.list);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const delIn = (baseSeq) => {
    axios2
      .post(`/config/auth/reg?type=del&base_seq=${baseSeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex justify-content-end m-3" style={{maxWidth: "635px"}}>
        <button type="button" className="btn btn-sm btn-point" onClick={() => history.push("/config/auth/reg")}>추가</button>
      </div>

      <div className="m-3 pb-2" style={{maxWidth: "635px"}}>
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="120px"/>
            <col width="200px"/>
            <col width="*"/>
            <col width="130px"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>권한그룹명</th>
            <th>권한그룹설명</th>
            <th>수정/삭제</th>
          </tr>

          {rets && rets.map((ret, i) => {
            return (
              <tr>
                <td>{i + 1}</td>
                <td className="text-left">{ret?.auth_name}</td>
                <td className="text-left">{ret?.auth_describe}</td>
                <td>
                  <button type="button" className="btn btn-ssm btn-outline-point"
                          onClick={() => {
                            history.push({
                              pathname: '/config/auth/reg', state: {
                                baseSeq: btoa(ret?.seq),
                              }
                            });
                          }}
                  >수정
                  </button>
                  <button type="button" className="btn btn-ssm btn-outline-dark mx-1"
                          onClick={() => {
                            if (!window.confirm('삭제하시겠습니까?')) return;
                            delIn(btoa(ret?.seq));
                          }}
                  >삭제
                  </button>
                </td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={4}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>
    </CmnCard>
  );
};

export default ConfigAuthList;
