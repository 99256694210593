import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory, useLocation} from "react-router-dom";
import Modal from "react-modal";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";


const EmployeeInvReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.baseSeq || '';

  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    $('#base_seq').val(baseSeq || '');
    if (baseSeq) {
      axios2
        .post(`/inv/inv/reg/ready?base_seq=${baseSeq}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            $('#user_id').val(result?.row?.user_id);
            // $('#user_pwd').val(result?.row?.user_pwd);
            $('#user_name').val(result?.row?.user_name);
            $('#user_hp').val(result?.row?.user_hp);
            $('#user_email').val(result?.row?.user_email || '');
            $('#manage_branch').val(result?.row?.manage_branch || '');
            $('#inv_branch').val(result?.row?.inv_branch || '');
            if (result?.row?.use_YN === 'Y')
              $('#use_YN').prop("checked", true);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function go_submit() {
    if (!$('#base_seq').val()) {
      if ($('#user_id').val() === "") {
        alert('ID를 입력해주세요');
        $('#user_id').focus();
        return;
      }
      if ($('#user_pwd').val() === "") {
        alert('비밀번호를 입력해주세요');
        $('#user_pwd').focus();
        return;
      }
      const password = $('#user_pwd').val();
      if (!/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/.test(password)) {
        alert("비밀번호를 영문,숫자,특수문자를 포함한 8~15자까지 입력해주세요");
        return;
      }
    }

    if ($('#user_name') === "") {
      alert('성명을 입력해주세요');
      $('#user_name').focus();
      return;
    }

    if ($('#manage_branch').val() === "") {
      alert('관리지점을 설정해주세요');
      return;
    }

    axios2
      .postEx('/inv/inv/reg', {
        base_seq: $('#base_seq').val() || '',
        user_id: $('#user_id').val() || '',
        user_pwd: $('#user_pwd').val() || '',
        user_name: $('#user_name').val() || '',
        user_hp: $('#user_hp').val() || '',
        user_email: $('#user_email').val() || '',
        manage_branch: $('#manage_branch').val() || '',
        inv_branch: $('#inv_branch').val() || '',
        use_YN: $('#use_YN:checked').val() || 'N',
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === "err") {
          alert('오류가 발생하였습니다');
        } else if (data === "dup_id") {
          alert('동일한 ID가 존재합니다');
        } else if (data === "insert_err") {
          alert("등록오류! 관리자에게 문의하세요.");
        } else if (data === "up_err") {
          alert("수정오류! 관리자에게 문의하세요.");
          // history.push("/employee/inv/list");
        } else if (data === "insert_ok") {
          alert("등록되었습니다");
          history.push("/employee/inv/list");
        } else if (data === "up_ok") {
          alert("수정되었습니다");
          history.push("/employee/inv/list");
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  3-1.
  function check_id(user_id) {
    if (user_id) {
      $.ajax({
        type: "post",
        url: "../config/chk_id.php",
        data: "user_id=" + user_id,
        success: function (data) {
          data = data.trim();

          if (data === "dup") {
            alert('동일한 ID 존재합니다');
            return;
          }
        }
      });
    }
  }

  const loadModal = () => {
    axios2
      .post(`/config/branch/manager/inv/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);

          result?.list?.map((row, i) => {
            const inv_rate = $('#inv_branch').val().split("|")[$('#manage_branch').val().split("|").indexOf(row?.branch_code)];
            if ($('#manage_branch').val().split("|").indexOf(row?.branch_code) !== -1)
              $('#achk_' + row?.branch_code).prop("checked", true);
            $('#rate_' + row?.branch_code).val(inv_rate);
          });
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const submitModal = () => {
    let manage_branch = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");
    manage_branch += "|";

    let inv_branch = $('[id^=achk]:checked').map(function () {
      return $('#rate_' + $(this).val()).val();
    }).get().join("|");
    inv_branch += "|";

    $('#manage_branch').val(manage_branch);
    $('#inv_branch').val(inv_branch);

    setModalOpen(false);
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{border: '1px solid #eee', borderRadius: '12px'}}>
        <div className="border-bottom text-left">
          <p className="px-3 py-2">
            <span className="h6 bold700">투자자 등록/수정</span>
          </p>
        </div>
        <div className="p-3">
          <table className="view-rounded sub_view">
            <colgroup>
              <col width="120px"/>
              <col width="*"/>
              <col width="120px"/>
              <col width="*"/>
              <col width="120px"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>ID</th>
              <td>
                <input type="text" className="form-control cls-type-nokr" aria-describedby="button-addon2" placeholder="15자이내 영문/숫자" id="user_id"/>
              </td>
              <th>비밀번호</th>
              <td>
                <input type="password" className="form-control" aria-describedby="button-addon2" placeholder="영문/숫자/특수문자조합" id="user_pwd"/>
              </td>
              <th>성명</th>
              <td>
                <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="이름입력" id="user_name"/>
              </td>
            </tr>
            <tr>
              <th>연락처</th>
              <td>
                <input type="text" className="form-control" aria-describedby="button-addon2" id="user_hp"/>
              </td>
              <th>이메일</th>
              <td>
                <input type="text" className="form-control" aria-describedby="button-addon2" id="user_email"/>
              </td>
              <th>투자지점</th>
              <td className="text-left">
                <button type="button" className="btn btn-ssm btn-outline-point px-2" onClick={() => setModalOpen(true)}>
                  투자지점설정
                </button>
                <input type="hidden" id="manage_branch"/>
                <input type="hidden" id="inv_branch"/>
              </td>
            </tr>
            <tr>
              <th>로그인 허용</th>
              <td className="text-left">
                <input type="checkbox" id="use_YN" value="Y"/>
              </td>
              <th></th>
              <td></td>
              <th></th>
              <td></td>
            </tr>
          </table>
        </div>
        <div className="m-4 text-center">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>
            저장
          </button>
          <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.goBack()}>
            목록
          </button>
        </div>

        <input type="hidden" id="base_seq"/>

      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '750px'}}>
            <div className="POP_title">
              <h5>
                <strong>투자지점 설정</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="80px"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="120px"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>
                    <input type="checkbox"/>
                  </th>
                  <th>지점코드</th>
                  <th>지점명</th>
                  <th>지분율</th>
                </tr>
                {modalResult?.list?.map((row, i) =>
                  <tr>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">
                      <input type="checkbox" className="input_shorts" id={`achk_${row?.branch_code}`} value={row?.branch_code}/>
                    </td>
                    <td className="text-center">{row?.branch_code}</td>
                    <td className="text-center">{row?.branch_name}</td>
                    <td className="text-center">
                      <input type="text" className="input_shorts" id={`rate_${row?.branch_code}`}/>
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => submitModal()}>
                등록
              </button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>
                취소
              </button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default EmployeeInvReg;
