import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";


const BranchopStepAttend = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();

    $('#s_date1').val(Fn.getDate(currentDate));
    $('#s_date2').val(Fn.getDate(currentDate));
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/branchop/step/attend/ready?page=${_page || currPage}&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_ba_mb_name=${$('#s_ba_mb_name').val()}&s_gubun=${$('#s_gubun option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">지점</option>';
            result?.topList?.forEach((list_row) => {
              if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 7) {
                if (localStorage.getItem("USER.systemBranchCode") == list_row.branch_code)
                  optionsHTML += `<option value="${list_row.branch_code}" selected>${list_row.branch_name}</option>`;
              } else
                optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          Fn.processPhone(result?.result, pageInfo, "ret?.user_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 fn_search_box">
          <div className="pr-1">
            <select className="form-select" id="s_branch_code">
            </select>
          </div>

          <div className="input-group pr-1" style={{width: 'auto'}}>
            <JDatePicker className="form-control" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="s_date2"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="이름/연락처 검색" id="s_ba_mb_name"/>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_gubun">
              <option value="">::입장구분::</option>
              <option value="1">정상입장</option>
              <option value="2">유효시간초과</option>
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_border auto">
            <tr>
              <th style={{width: "75px"}}>No</th>
              <th>일시</th>
              <th>스텝명</th>
              <th>연락처</th>
              <th>소속지점</th>
              <th>방문지점</th>
              <th>입장구분</th>
            </tr>
            {rets && rets.map((ret, i) => {

              const err_type_show = ret?.err_type == "1" ? "정상입장" : ret?.err_type == "2" ? "유효시간초과" : "";

              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>{ret?.ba_date} {ret?.ba_time}</td>
                  <td>{ret?.step_name}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{ret?.won_gr_name}</td>
                  <td>{ret?.ba_gr_name}</td>
                  <td>
                    {ret?.err_type === 1 ? err_type_show : <strong className="text-danger">{err_type_show}</strong>}
                  </td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={7}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>

        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
          {pageInfo?.totalArticles > 0 && (
            (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('step_attend')}>연락처 확인</button>
            )
          )}
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default BranchopStepAttend;
