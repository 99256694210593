import React from "react";
import {confirmAlert} from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import $ from "jquery";
import axios2 from "../helpers/APIHelper";

export default class Fn {
  static jalert = message => {
    $(':focus').blur();

    return new Promise(async (resolve, reject) => {
      confirmAlert({
        title: 'SMMS',
        message,
        keyCodeForClose: [8, 13, 32],
        willUnmount: () => {
          window.event.preventDefault();
          window.event.stopPropagation();
        },
        afterClose: () => {
          return resolve(true);
        },
        buttons: [
          {
            label: '확인',
            onClick: () => {
              return resolve(true);
            },
          }
        ]
      });
    });
  }

  static jconfirm = message => {
    return new Promise(async (resolve, reject) => {
      confirmAlert({
        title: 'SMMS',
        message,
        afterClose: () => {
          return resolve(false);
        },
        buttons: [
          {
            label: '확인',
            onClick: () => {
              return resolve(true);
            },
          },
          {
            label: '취소',
            onClick: () => {
              return resolve(false);
            },
          }
        ]
      });
    });
  }

  static generateUUID = () => {
    let d = new Date().getTime();
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      let r = Math.random() * 16;
      if (d > 0) {
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  static parseInt = val => {
    let ret = 0;
    try {
      ret = parseInt(val);
    } catch (err) {
    }
    if (isNaN(ret)) ret = 0;
    if (!ret) ret = 0;
    return ret;
  };

  static parseFloat = val => {
    let ret = 0;
    try {
      ret = parseFloat(val);
    } catch (err) {
    }
    if (isNaN(ret)) ret = 0;
    if (!ret) ret = 0;
    return ret;
  };

  static numberWithCommas = (x) => {
    x = Fn.parseInt(x);
    let ret;
    try {
      ret = x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (err) {
      ret = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    if (ret === "NaN") ret = "";
    if (!ret) ret = "0";
    return ret;
  };

  static floatWithCommas = (x) => {
    x = Fn.parseFloat(x);
    if (isNaN(x) || x === "NaN") x = 0;
    let ret;
    try {
      const formatter = new Intl.NumberFormat('en-US', {
        maximumSignificantDigits: 15,
        style: 'currency',
        currency: 'USD',
      });
      ret = formatter.format(x.toFixed(4));
    } catch (err) {
    }
    if (ret === "NaN") ret = "0";
    if (!ret) ret = "0";
    return ret.replace("$", "");
  };

  static strWithCommas = (x) => {
    let ret = "";
    try {
      for (let i = x.length - 1; i >= 0; i--) {
        if ((i + 1) % 3 === 0) ret += ",";
        ret += x[i];
      }
    } catch (err) {
    }
    return ret;
  };

  static zeroPad(nr, base) {
    const len = (String(base).length - String(nr).length) + 1;
    return len > 0 ? new Array(len).join("0") + nr : nr;
  };

  static getRenewTime() {
    return new Date().getTime();
  }

  static escapedNewLineToLineBreakTag = (string = '') => {
    return string.split('\n').map((item, index) => {
      return (index === 0) ? item : [<br key={index}/>, item]
    })
  }

  static escapedSpaceToLineBreakTag = (string = '') => {
    return string.split(' ').map((item, index) => {
      return (index === 0) ? item : [<br key={index}/>, item]
    })
  }

  static cfNumeric = (sOrg) => {
    let nm;
    sOrg = sOrg.replace(/,/g, "");
    nm = parseFloat(sOrg).toString();
    return isNaN(nm) ? 0 : nm;
  }

  static getCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  static hpShow = (mb_hp, total_record, service_end_date) => {
    try {
      let mb_hp_show;

      mb_hp = mb_hp.replace(/-/g, "");

      if (mb_hp.length === 11) {
        mb_hp_show = `${mb_hp.substr(0, 3)}-${mb_hp.substr(3, 4)}-${mb_hp.substr(7, 4)}`;
      } else if (mb_hp.length === 10) {
        mb_hp_show = `${mb_hp.substr(0, 3)}-${mb_hp.substr(3, 3)}-${mb_hp.substr(6, 4)}`;
      } else {
        mb_hp_show = mb_hp;
      }

      if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 9) {
        return mb_hp_show;
      } else if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 8) {
        if (localStorage.getItem("USER.systemManageYN") === "Y") {
          if (service_end_date >= Fn.getDate(new Date())) {
            return mb_hp_show;
          } else {
            return mb_hp ? `${mb_hp.substr(0, 3)}-****-****` : "";
          }
        } else {
          return total_record <= 300 ? mb_hp_show : mb_hp ? `${mb_hp.substr(0, 3)}-****-****` : "";
        }
      } else if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7) {
        return total_record <= 300 ? mb_hp_show : mb_hp ? `${mb_hp.substr(0, 3)}-****-****` : "";
      } else {
        return total_record <= 10 ? mb_hp_show : mb_hp ? `${mb_hp.substr(0, 3)}-****-****` : "";
      }
    } catch (err) {
      console.log(err);
      return "!!!-!!!!-!!!!";
    }
  }

  static getDateDiff = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    const diffInDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));

    return diffInDays;
  };

  static period = (prd) => {
    let now = new Date()

    let firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    let lastMonth = new Date(firstDayOfMonth.setDate(firstDayOfMonth.getDate() - 1));
    let bemonth = lastMonth.getMonth() + 1

    let beyears = lastMonth.getFullYear()

    if (bemonth < 10) bemonth = "0" + bemonth

    let tomonth = now.getMonth() + 1
    if (tomonth < 10) tomonth = "0" + tomonth
    let beforemonth = now.getMonth()
    if (beforemonth < 10) beforemonth = "0" + beforemonth
    let toyear = now.getFullYear();
    let beyear = now.getFullYear() - 1;

    let tohour = now.getHours()

    let todate = now.getDate();
    if (todate < 10) todate = "0" + todate
    let bedate = now.getDate();
    if (bedate < 10) bedate = "0" + bedate

    let t0 = new Date(toyear, beforemonth, 0);
    let ed = t0.getDate();

    let today = toyear + "-" + tomonth + "-" + todate
    let tomonth_start = toyear + "-" + tomonth + "-" + "01"

    let bemonth_start = beyears + "-" + bemonth + "-" + "01";
    let bemonth_end = beyears + "-" + bemonth + "-" + ed;

    if (prd == 1) {
      $('#s_date1').val(today);
      $('#s_date2').val(today);
    } else if (prd == 2) {
      $('#s_date1').val(tomonth_start);
      $('#s_date2').val(today);
    } else if (prd == 3) {
      $('#s_date1').val(bemonth_start);
      $('#s_date2').val(bemonth_end);
    } else if (prd == 4) {
      $('#s_date1').val(toyear + '-01-01');
      $('#s_date2').val(toyear + '-03-31');
    } else if (prd == 5) {
      $('#s_date1').val(toyear + '-04-01');
      $('#s_date2').val(toyear + '-06-30');
    } else if (prd == 6) {
      $('#s_date1').val(toyear + '-01-01');
      $('#s_date2').val(toyear + '-06-30');
    } else if (prd == 7) {
      $('#s_date1').val(toyear + '-07-01');
      $('#s_date2').val(toyear + '-09-30');
    } else if (prd == 8) {
      $('#s_date1').val(toyear + '-10-01');
      $('#s_date2').val(toyear + '-12-31');
    } else if (prd == 9) {
      $('#s_date1').val(toyear + '-07-01');
      $('#s_date2').val(toyear + '-12-31');
    } else if (prd == 10) {
      $('#s_date1').val(toyear + '-01-01');
      $('#s_date2').val(toyear + '-12-31');
    } else if (prd == 11) {
      $('#s_date1').val(beyear + '-01-01');
      $('#s_date2').val(beyear + '-12-31');
    } else if (prd == 12) {
      // TODO
      // document.s_form.s_date1.value = g4_beforeWeekS;
      // document.s_form.s_date2.value = g4_beforeWeekE;
    }
  }

  static period2 = (prd) => {
    var now = new Date()
    var bemonth = now.getMonth()
    if (bemonth < 10) bemonth = "0" + bemonth
    var tomonth = now.getMonth() + 1
    if (tomonth < 10) tomonth = "0" + tomonth
    var beforemonth = now.getMonth()
    if (beforemonth < 10) beforemonth = "0" + beforemonth
    var toyear = now.getFullYear();
    var beyear = now.getFullYear() - 1;

    var tohour = now.getHours()

    var todate = now.getDate();
    if (todate < 10) todate = "0" + todate
    var bedate = now.getDate();
    if (bedate < 10) bedate = "0" + bedate

    let t0 = new Date(toyear, beforemonth, 0);
    let ed = t0.getDate();

    var today = toyear + "-" + tomonth + "-" + todate
    var tomonth_start = toyear + "-" + tomonth + "-" + "01"
    var bemonth_start = toyear + "-" + bemonth + "-" + "01";
    var bemonth_end = toyear + "-" + bemonth + "-" + ed;

    if (prd == 1) {
      $('#s_date1').val(today);
      $('#s_date2').val(today);
    } else if (prd == 2) {
      $('#s_date1').val(tomonth_start);
      $('#s_date2').val(today);
    } else if (prd == 3) {

      if (bemonth == "00") {
        bemonth = '12';
        toyear = toyear - 1;
      }

      var bemonth_start = toyear + "-" + bemonth + "-" + "01";
      var bemonth_end = toyear + "-" + bemonth + "-" + ed;

      $('#s_date1').val(bemonth_start);
      $('#s_date2').val(bemonth_end);
    } else if (prd == 4) {
      $('#s_date1').val(toyear + '-01-01');
      $('#s_date2').val(toyear + '-03-31');
    } else if (prd == 5) {
      $('#s_date1').val(toyear + '-04-01');
      $('#s_date2').val(toyear + '-06-30');
    } else if (prd == 6) {
      $('#s_date1').val(toyear + '-01-01');
      $('#s_date2').val(toyear + '-06-30');
    } else if (prd == 7) {
      $('#s_date1').val(toyear + '-07-01');
      $('#s_date2').val(toyear + '-09-30');
    } else if (prd == 8) {
      $('#s_date1').val(toyear + '-10-01');
      $('#s_date2').val(toyear + '-12-31');
    } else if (prd == 9) {
      $('#s_date1').val(toyear + '-07-01');
      $('#s_date2').val(toyear + '-12-31');
    } else if (prd == 10) {
      $('#s_date1').val(toyear + '-01-01');
      $('#s_date2').val(toyear + '-12-31');
    } else if (prd == 11) {
      $('#s_date1').val(beyear + '-01-01');
      $('#s_date2').val(beyear + '-12-31');

    } else if (prd == 12) {
      // $('#s_date1').val(g4_beforeWeekS);
      // $('#s_date2').val(g4_beforeWeekE);
    } else if (prd == 13) {
      var selectDate = today.split("-");
      var changeDate = new Date();
      changeDate.setFullYear(selectDate[0], selectDate[1] - 1, selectDate[2] - 1);

      var y = changeDate.getFullYear();
      var m = changeDate.getMonth() + 1;
      var d = changeDate.getDate();
      if (m < 10) {
        m = "0" + m;
      }
      if (d < 10) {
        d = "0" + d;
      }

      var resultDate = y + "-" + m + "-" + d;

      $('#s_date1').val(resultDate);
      $('#s_date2').val(resultDate);
    }
  }

  static chkAuthCode = async (type, search_auth_code, successCallback = null, prefix = '') => {
    try {
      if (type === 'pt') {
        const response = await axios2.post(`/branchop/search/user/pt?type=search&search_auth_code=${encodeURIComponent(search_auth_code)}`);
        const data = response.data.result.trim();

        if (data === "err") {
          alert("인증번호가 올바르지 않습니다.");
          $("#" + prefix + "auth_code").val('');
          $("#" + prefix + "search_auth_code").val('');
          $("#" + prefix + "auth_code_name").text('');
          $("#" + prefix + "auth_code_name").hide();
          $("#" + prefix + "base_tranier_seq").val('');
          $("#" + prefix + "search_auth_code").focus();
        } else if (data === "dup") {
          alert("인증번호 설정오류! 관리자에게 문의하세요.");
          $("#" + prefix + "auth_code").val('');
          $("#" + prefix + "search_auth_code").val('');
          $("#" + prefix + "auth_code_name").text('');
          $("#" + prefix + "auth_code_name").hide();
          $("#" + prefix + "base_tranier_seq").val('');
          $("#" + prefix + "search_auth_code").focus();
        } else {
          const split = data.split("||");

          $("#" + prefix + "auth_code_name").text(split?.[0] || '');
          $("#" + prefix + "auth_code_name").show();
          $("#" + prefix + "base_tranier_seq").val(split?.[1] || '');
          $("#" + prefix + "auth_code").val(search_auth_code);
          if (successCallback) {
            successCallback(data);
          }
        }
      } else {
        const response = await axios2.post(`/inc/auth/num/chk?type=${type}&search_auth_code=${encodeURIComponent(search_auth_code)}`);
        const data = response.data.message.trim();

        if (data === "err") {
          alert("인증번호가 올바르지 않습니다.");
          $("#" + prefix + "auth_code").val('');
          $("#" + prefix + "search_auth_code").val('');
          $("#" + prefix + "auth_code_name").text('');
          $("#" + prefix + "auth_code_name").hide();
          $("#" + prefix + "reg_step").val('');
          $("#" + prefix + "search_auth_code").focus();
        } else if (data === "dup") {
          alert("인증번호 설정오류! 관리자에게 문의하세요.");
          $("#" + prefix + "auth_code").val('');
          $("#" + prefix + "search_auth_code").val('');
          $("#" + prefix + "auth_code_name").text('');
          $("#" + prefix + "auth_code_name").hide();
          $("#" + prefix + "reg_step").val('');
          $("#" + prefix + "search_auth_code").focus();
        } else {
          $("#" + prefix + "auth_code_name").show();
          if (!successCallback) {
            const split = data.split("||");
            const re_cont1 = split[1];
            const re_cont2 = split[2];

            $("#" + prefix + "auth_code_name").text(re_cont1);
            $("#" + prefix + "auth_code").val(re_cont2);
          } else {
            successCallback(data);
          }
        }
      }
    } catch (error) {
      console.error(error);
      if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
    }
  };

  static processPhone = (rets, pageInfo, field) => {
    if (!rets) return;

    $('[id^=default_show]').text('***-****-****');

    let tot_base_hp = "";
    let count = 0;

    let account_YN;
    if ((localStorage.getItem("USER.systemID") === "futureinfo" || localStorage.getItem("USER.systemID") === "spoany" || localStorage.getItem("USER.systemID") === "tpwls8905") && localStorage.getItem("USER.systemBranchCode") == "ALL") {
      account_YN = "Y";
    } else {
      account_YN = "N";
    }

    rets.forEach(ret => {
      count++;

      let mb_hp = eval(field);
      let mb_hp_show;

      if (account_YN === "Y") {
        mb_hp = mb_hp?.replace("-", "");

        if (mb_hp?.length === 11) {
          mb_hp_show = `${mb_hp?.substr(0, 3)}-${mb_hp?.substr(3, 4)}-${mb_hp?.substr(7, 4)}`;
        } else if (mb_hp?.length === 10) {
          mb_hp_show = `${mb_hp?.substr(0, 3)}-${mb_hp?.substr(3, 3)}-${mb_hp?.substr(6, 4)}`;
        } else {
          mb_hp_show = mb_hp;
        }

        try {
          mb_hp_show = `${mb_hp_show.substr(0, 3)}-${mb_hp_show.substr(4, 4)}-****`;
        } catch (err) {
          mb_hp_show = '';
        }
      } else {
        mb_hp_show = Fn.hpShow(mb_hp, pageInfo?.totalArticles || 0);
      }

      let base_mb_hp_show = '';
      try {
        base_mb_hp_show = btoa(mb_hp_show);
      } catch (e) {
      }

      tot_base_hp += count + "," + base_mb_hp_show + "|";
    });
    $('#tot_record').val(rets?.length || 0);
    $('#tot_base_hp').val(tot_base_hp);
  }

  static showPhone = (type, prefix = '') => {
    if (!$("#" + prefix + "auth_code").val()) {
      alert('인증코드를 입력해주세요');
      return;
    }

    const tot_record = $("#tot_record").val();
    const tot_base_hp = $("#tot_base_hp").val();
    const tot_sql = $("#tot_sql").val();

    // TODO : tot_sql 처리 필요
    axios2
      .postEx('/member/member/list/hp/show', {
        member_type: type,
        auth_code: $("#" + prefix + "auth_code").val(),
        tot_record: tot_record,
        tot_base_hp: tot_base_hp,
        tot_sql: tot_sql,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        const new_data = data.split('|');
        const dd = new_data.length;

        for (let i = 0; i < dd; i++) {
          const won_data = new_data[i];
          if (won_data) {
            const won_data_split = won_data.split(',');

            const cnt = won_data_split[0];
            const hp = won_data_split[1];

            $(`#default_show${cnt}`).text(hp === '!!!-!!!!-!!!!' ? "" : hp);
          }
        }
      })
      .catch((error) => {
        alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  static lpad = (str, padLen, padStr) => {
    if (padStr.length > padLen) {
      console.log("오류 : 채우고자 하는 문자열이 요청 길이보다 큽니다");
      return str;
    }
    str += ""; // 문자로
    padStr += ""; // 문자로
    while (str.length < padLen)
      str = padStr + str;
    str = str.length >= padLen ? str.substring(0, padLen) : str;
    return str;
  }

  static detectRatio = () => {
    const orientation = (window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation;
    if ("userAgentData" in window.navigator && window.navigator.userAgentData.mobile && orientation === "landscape-primary" || orientation === "landscape-secondary") {
      return window.devicePixelRatio;
    } else if ("userAgentData" in window.navigator && !window.navigator.userAgentData.mobile && orientation === "landscape-primary" || orientation === "landscape-secondary") {
      return window.outerWidth / window.innerWidth;
    } else if (orientation === "portrait-secondary" || orientation === "portrait-primary") {
      return window.devicePixelRatio;
    } else {
      return Math.max(window.devicePixelRatio, window.outerWidth / window.innerWidth);
    }
  }

  static resizeScreen = (r) => {
    const ratio1 = Fn.detectRatio();
    const ratio2 = window.devicePixelRatio / ratio1;

    if (false) {
      if (true) {
        $('body').css('zoom', (1 / (ratio1 * ratio2)) * (window.screen.availWidth * window.devicePixelRatio / 1521) / r);
        $('body').css('transform', "perspective(1px) translateZ(0)");
      } else {
        const scale = (1 / (ratio1 * ratio2)) * (window.screen.availWidth * window.devicePixelRatio / 1521) / r;
        alert(scale);
        $('body').css('transform', "scale(" + scale + ", " + scale + ")");
        $('body').css('transform-origin', "0 0");
        // $('body').css('-webkit-transform', "scale(" + scale + ", " + scale + ")");
        // $('body').css('-webkit-transform-origin', "0 0");
      }
    }

    $('body').css('overflow-y', 'auto');
    $('body').css('minWidth', '1600px');

    $('body').css('backface-visibility', 'hidden');
    // $('*').css('-webkit-font-smoothing', 'subpixel-antialiased');
  }

  static resizeScreenReal = (r, tag = 'body') => {
    const ratio1 = Fn.detectRatio();
    const ratio2 = window.devicePixelRatio / ratio1;

    if (true) {
      if (true) {
        $(tag).css('zoom', (1 / (ratio1 * ratio2)) * (window.screen.availWidth * window.devicePixelRatio / 1521) / r);
        $(tag).css('transform', "perspective(1px) translateZ(0)");
      } else {
        const scale = (1 / (ratio1 * ratio2)) * (window.screen.availWidth * window.devicePixelRatio / 1521) / r;
        $(tag).css('transform', "scale(" + scale + ", " + scale + ")");
        $(tag).css('transform-origin', "0 0");
        // $('body').css('-webkit-transform', "scale(" + scale + ", " + scale + ")");
        // $('body').css('-webkit-transform-origin', "0 0");
      }
    }

    // $('body').css('overflow-y', 'auto');
    // $('body').css('minWidth', '1600px');
    //
    // $('body').css('backface-visibility', 'hidden');
    // $('*').css('-webkit-font-smoothing', 'subpixel-antialiased');
  }

  static getNonNull = (str) => {
    if (!str) return "";
    if (str === "null" || str === "undefined") return "";
    return str;
  }

  static numkeyCheck(e) {
    $(e.target).val($(e.target).val().replace(/[^-?0-9,]/g, ""));
    const keyValue = e.keyCode;
    if (((keyValue >= 43) && (keyValue <= 57))) return true;
    else return false;
  }

  static vComma(obj) {
    let str = "" + obj.value.replace(/,/gi, ''); // 콤마 제거
    let regx = new RegExp(/(-?\d+)(\d{3})/);
    let bExists = str.indexOf(".", 0);
    let strArr = str.split('.');
    while (regx.test(strArr[0])) {
      strArr[0] = strArr[0].replace(regx, "$1,$2");
    }
    if (bExists > -1)
      obj.value = strArr[0] + "." + strArr[1];
    else
      obj.value = strArr[0];
  }

  static commaNum(num) {
    if (num < 0) {
      num *= -1;
      var minus = true
    } else var minus = false

    var dotPos = (num + "").split(".")
    var dotU = dotPos[0]
    var dotD = dotPos[1]
    var commaFlag = dotU.length % 3

    if (commaFlag) {
      var out = dotU.substring(0, commaFlag)
      if (dotU.length > 3) out += ","
    } else var out = ""

    for (var i = commaFlag; i < dotU.length; i += 3) {
      out += dotU.substring(i, i + 3)
      if (i < dotU.length - 3) out += ","
    }

    if (minus) out = "-" + out
    if (dotD) return out + "." + dotD
    else return out
  }

  static centerModal() {
    try {
      window.extendLogout();
    } catch (err) {
    }

    // document.body.style.overflow = 'hidden';
    $('.ReactModalPortal:visible').height($('body')[0].scrollHeight);
    $('.ReactModal__Overlay:visible').height($('body')[0].scrollHeight);
    $('.ReactModal__Content:visible').height($('body')[0].scrollHeight);
    $('.pop_background:visible').height($('body')[0].scrollHeight);
    $('.pop_box01:visible, .pop_box01_wide:visible').removeClass('p_absolute_mid_pop').addClass('p_absolute_mid_pop2');

    $("input[type=text]").each(function () {
      $(this).prop("autocomplete", "one-time-code");
    });
    $("input[type=password]").each(function () {
      $(this).prop("autocomplete", "one-time-code");
    });

    if (false) {
      const ratio1 = Fn.detectRatio();
      const ratio2 = window.devicePixelRatio / ratio1;

      const r = 1.5;
      const a = (1 / (ratio1 * ratio2)) * (window.screen.availWidth * window.devicePixelRatio / 1521) / r;

      $('.pop_box01:visible, .pop_box01_wide:visible').css('marginTop', $(document).scrollTop() / a);
    } else {
      $('.pop_box01:visible, .pop_box01_wide:visible').css('marginTop', $(document).scrollTop());
    }
  }

  static showPopSpinner() {
    $('#pop-overlay').show();
    // document.body.style.overflow = 'hidden';
    $('#pop-overlay:visible').height($('body')[0].scrollHeight);
    $('.pop-cv-spinner:visible').removeClass('p_absolute_mid_pop').addClass('p_absolute_mid_pop2');
    $('.pop-cv-spinner:visible').css('marginTop', $(document).scrollTop());
  }

  static showSpinner() {
    $('#overlay').show();
    // document.body.style.overflow = 'hidden';
    $('#overlay:visible').height($('body')[0].scrollHeight);
    $('.cv-spinner:visible').removeClass('p_absolute_mid_pop').addClass('p_absolute_mid_pop2');
    $('.cv-spinner:visible').css('marginTop', $(document).scrollTop());
  }

  static download(url, filename) {
    if (url.includes("?")) url += "&token=" + localStorage.getItem("USER.token");
    else url += "?token=" + localStorage.getItem("USER.token");
    window.location.href = url;
    /*
    $.ajax({
      type: "POST",
      url: url,
      xhrFields: {
        responseType: 'blob' // to avoid binary data being mangled on charset conversion
      },
      success: function(blob, status, xhr) {
        // check for a filename
        var disposition = xhr.getResponseHeader('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
        }

        if (typeof window.navigator.msSaveBlob !== 'undefined') {
          // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
          window.navigator.msSaveBlob(blob, filename);
        } else {
          var URL = window.URL || window.webkitURL;
          var downloadUrl = URL.createObjectURL(blob);

          if (filename) {
            // use HTML5 a[download] attribute to specify filename
            var a = document.createElement("a");
            // safari doesn't support this yet
            if (typeof a.download === 'undefined') {
              window.location.href = downloadUrl;
            } else {
              a.href = downloadUrl;
              a.download = filename;
              document.body.appendChild(a);
              a.click();
            }
          } else {
            window.location.href = downloadUrl;
          }

          setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
        }
      }
    });
    */
  }

  static makeUrl(url) {
    if (url.includes("?")) url += "&token=" + localStorage.getItem("USER.token");
    else url += "?token=" + localStorage.getItem("USER.token");
    return url;
  }

  static getDate(date) {
    try {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    } catch (err) {
      return "";
    }
  }

  static parseAjaxError(jqXHR, textStatus, errorThrown, history) {
    if (window.disableErrorMsg) return;

    if (jqXHR?.status === 0) {
      alert("일시적인 네트워크 상태의 문제 또는 서버 문제로 서버에 접속이 불가능합니다. 잠시 후 다시 시도해 주세요.\n\nERROR CODE : NETWORK ERROR");
    } else if (jqXHR?.status === 404) {
      alert("일시적인 네트워크 상태의 문제 또는 서버 문제로 서버에 접속이 불가능합니다. 잠시 후 다시 시도해 주세요.\n\nERROR CODE : NETWORK ERROR [404]");
    } else if (jqXHR?.status === 401) {
      alert("일시적인 네트워크 상태의 문제 또는 서버 문제로 서버에 접속이 불가능합니다. 잠시 후 다시 시도해 주세요.\n\nERROR CODE : NETWORK ERROR [401]");
    } else if (textStatus === 'parsererror') {
      alert('데이터오류! 시스템 관리자에게 문의하세요\nERROR CODE : JSON 처리 오류');
    } else if (textStatus === 'timeout') {
      alert('데이터오류! 시스템 관리자에게 문의하세요\nERROR CODE : 네트워크 요청 시간 만료');
    } else if (textStatus === 'abort') {
      alert('데이터오류! 시스템 관리자에게 문의하세요\nERROR CODE : 네트워크 요청 취소');
    } else if (jqXHR?.status == 500) {
      alert('데이터오류! 시스템 관리자에게 문의하세요\nERROR CODE : 서버 오류 [500]');
    } else {
      alert("데이터오류! 시스템 관리자에게 문의하세요\nERROR CODE : UNKNOWN : " + (jqXHR?.responseText || ''));
    }
    Fn.exit(0);
  }

  static exit(status) {
    if (true) return;

    // http://kevin.vanzonneveld.net
    // +   original by: Brett Zamir (http://brettz9.blogspot.com)
    // +      input by: Paul
    // +   bugfixed by: Hyam Singer (http://www.impact-computing.com/)
    // +   improved by: Philip Peterson
    // +   bugfixed by: Brett Zamir (http://brettz9.blogspot.com)
    // %        note 1: Should be considered expirimental. Please comment on this function.
    // *     example 1: exit();
    // *     returns 1: null

    var i;

    if (typeof status === 'string') {
      alert(status);
    }

    window.addEventListener('error', function (e) {
      e.preventDefault();
      e.stopPropagation();
    }, false);

    var handlers = [
      'copy', 'cut', 'paste',
      'beforeunload', 'blur', 'change', 'click', 'contextmenu', 'dblclick', 'focus', 'keydown', 'keypress', 'keyup', 'mousedown', 'mousemove', 'mouseout', 'mouseover', 'mouseup', 'resize', 'scroll',
      'DOMNodeInserted', 'DOMNodeRemoved', 'DOMNodeRemovedFromDocument', 'DOMNodeInsertedIntoDocument', 'DOMAttrModified', 'DOMCharacterDataModified', 'DOMElementNameChanged', 'DOMAttributeNameChanged', 'DOMActivate', 'DOMFocusIn', 'DOMFocusOut', 'online', 'offline', 'textInput',
      'abort', 'close', 'dragdrop', 'load', 'paint', 'reset', 'select', 'submit', 'unload'
    ];

    function stopPropagation(e) {
      e.stopPropagation();
      // e.preventDefault(); // Stop for the form controls, etc., too?
    }

    for (i = 0; i < handlers.length; i++) {
      window.addEventListener(handlers[i], function (e) {
        stopPropagation(e);
      }, true);
    }

    if (window.stop) {
      window.stop();
    }

    throw '';
  }
};
