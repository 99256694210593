import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import {useHistory, useLocation} from "react-router-dom";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import Constants from "../../constants/constants";

const MemberList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();
  const location = useLocation();

  const [result, setResult] = useState({});
  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [accountYN, setAccountYN] = useState('');

  const [BranchOpt, setBranchOpt] = useState(["", "골프", "골프라커", "스쿼시", "스피닝", "태닝", "토탈", "필라테스", "Opt1", "Opt2", "Opt3"])

  useEffect(() => {
    $('#s_date1').val(location?.state?.s_date1 || '');
    $('#s_date2').val(location?.state?.s_date2 || '');
    $('#e_date1').val(location?.state?.e_date1 || '');
    $('#e_date2').val(location?.state?.e_date2 || '');

    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage]);

  const getParam = () => {
    let params = `&o_date1=${$('#o_date1').val()}&o_date2=${$('#o_date2').val()}&date_type=${$('#date_type option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&e_date1=${$('#e_date1').val()}&e_date2=${$('#e_date2').val()}&l_date1=${$('#l_date1').val()}&l_date2=${$('#l_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_hp=${$('#s_mb_hp').val()}&s_member_id=${$('#s_member_id').val()}`;
    params += `&s_locker=${$('#s_locker option:selected').val() || ''}&s_locker_name=${$('#s_locker_name').val()}&s_suit=${$('#s_suit option:selected').val() || ''}&s_now_use=${$('#s_now_use option:selected').val() || ''}&s_ot_cnt=${$('#s_ot_cnt option:selected').val() || ''}&s_visit_course=${$('#s_visit_course option:selected').val() || ''}&s_sex=${$('#s_sex option:selected').val() || ''}&s_mb_birth=${$('#s_mb_birth').val()}&s_item_month=${$('#s_item_month option:selected').val() || ''}`;
    params += `&s_item_grade=${$('#s_item_grade option:selected').val() || ''}&atd_date1=${$('#atd_date1').val()}&atd_date2=${$('#atd_date2').val()}&s_start_hour=${$('#s_start_hour option:selected').val() || ''}&s_start_minute=${$('#s_start_minute option:selected').val() || ''}&s_end_hour=${$('#s_end_hour option:selected').val() || ''}&s_end_minute=${$('#s_end_minute option:selected').val() || ''}&s_jibu=${$('#s_jibu option:selected').val() || ''}&s_marketing_YN=${$('#s_marketing_YN option:selected').val() || ''}&num_per_page=${$('#num_per_page option:selected').val() || ''}`;
    params += `&s_opt1=${$('#s_opt1:checked').val() || ''}`;
    params += `&s_opt2=${$('#s_opt2:checked').val() || ''}`;
    params += `&s_opt3=${$('#s_opt3:checked').val() || ''}`;
    params += `&s_opt4=${$('#s_opt4:checked').val() || ''}`;
    params += `&s_opt5=${$('#s_opt5:checked').val() || ''}`;
    params += `&s_opt6=${$('#s_opt6:checked').val() || ''}`;
    params += `&s_opt7=${$('#s_opt7:checked').val() || ''}`;
    params += `&s_opt8=${$('#s_opt8:checked').val() || ''}`;
    params += `&s_opt9=${$('#s_opt9:checked').val() || ''}`;
    params += `&s_opt10=${$('#s_opt10:checked').val() || ''}`;
    return params;
  }

  const load = (_page) => {
    let params = getParam();

    let account_YN;
    if (
      (localStorage.getItem("USER.systemID") === "futureinfo" ||
        localStorage.getItem("USER.systemID") === "spoany" ||
        localStorage.getItem("USER.systemID") === "tpwls8905") &&
      localStorage.getItem("USER.systemBranchCode") === "ALL"
    ) {
      account_YN = "Y";
    } else {
      account_YN = "N";
    }
    setAccountYN(account_YN);
    params += `&account_YN=${account_YN}`;

    axios2
      .post(`/member/member/list/ready?page=${_page || currPage}${params}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_visit_course').html() === '') {
            let optionsHTML = '<option value="">가입경로</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.sub_code_name}">${list_row.sub_code_name}</option>`;
            });
            $('#s_visit_course').html(optionsHTML);
          }
          if ($('#s_item_grade').html() === '') {
            let optionsHTML = '<option value="">상품등급</option>';
            result?.bl?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_item_grade').html(optionsHTML);
          }
          if ($('#s_jibu').html() === '') {
            let optionsHTML = '<option value="">::지부</option>';
            result?.jibuRs?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.jibu}">${list_row.jibu}</option>`;
            });
            $('#s_jibu').html(optionsHTML);
          }

          console.log(result);

          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setResult(result);
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        console.error(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const clear_form = () => {
    $('#s_mb_name').val('');
    $('#s_member_id').val('');
    $('#s_mb_hp').val('');
    $('#o_date1').val('');
    $('#o_date2').val('');
    $('#s_date1').val('');
    $('#s_date2').val('');
    $('#e_date1').val('');
    $('#e_date2').val('');
    $('#s_locker').val('');
    $('#s_suit').val('');
    $('#s_locker_name').val('');
    $('#l_date1').val('');
    $('#l_date2').val('');
    $('#s_visit_course').val('');
    $('#s_sex').val('');
    $('#s_ot_cnt').val('');
    $('#s_now_use').val('');
    $('#s_item_month').val('');
    $('#s_item_grade').val('');

    $('#s_start_hour').val('00');
    $('#s_start_minute').val('00');
    $('#s_end_hour').val('23');
    $('#s_end_minute').val('59');

    setSrchRenew(Fn.getRenewTime());
  };

  //  6-1.
  const doSomething = () => {
    const atdDate1 = document.getElementById('atd_date1').value;
    const atdDate2 = document.getElementById('atd_date2').value;

    if (atdDate1 !== '' && atdDate2 === '') {
      alert('출석시작일을 입력해주세요');
      return false;
    }

    if (atdDate1 === '' && atdDate2 !== '') {
      alert('출석종료일을 입력해주세요');
      return false;
    }
  };

  //  6-2.
  <button onClick={doSomething}></button>

  //  7-1.
  const [dateType, setDateType] = useState('');

  const handleDateTypeChange = (event) => {
    setDateType(event.target.value);
  };

  const show_txt = () => {
    switch (dateType) {
      case 'A':
        return '운동종료일';
      case 'B':
        return '골프종료일';
      case 'C':
        return '스쿼시종료일';
      case 'D':
        return '스피닝종료일';
      case 'E':
        return '태닝종료일';
      case 'F':
        return '필라테스종료일';
      default:
        return '';
    }
  };

  //  7-2.
  // TODO : 변환 필요
  // <select value={dateType} onChange={handleDateTypeChange}>
  //   <option value="">Select Date Type</option>
  //   <option value="A">운동</option>
  //   <option value="B">골프</option>
  //   <option value="C">스쿼시</option>
  //   <option value="D">스피닝</option>
  //   <option value="E">태닝</option>
  //   <option value="F">필라테스</option>
  // </select>
  // <p>{show_txt()}</p>

  const Excel_member = () => {
    if (!$("#auth_code").val()) {
      alert('인증코드를 입력해주세요');
      return;
    }

    if (!$("#down_reason").val()) {
      alert('사유를 입력해주세요');
      return;
    }

    const url = `/xls/member?_renew=1${getParam()}&&auth_code=${$("#auth_code").val()}&down_reason=${$("#down_reason").val()}`;
    Fn.download(Constants.API_URL + url);
  };

  const Excel_member_all = () => {
    if (!$("#auth_code").val()) {
      alert('인증코드를 입력해주세요');
      return;
    }

    if (!$("#down_reason").val()) {
      alert('사유를 입력해주세요');
      return;
    }

    const url = `/xls/member/all?_renew=1${getParam()}&auth_code=${$("#auth_code").val()}&down_reason=${$("#down_reason").val()}`;
    Fn.download(Constants.API_URL + url);
  };

  const Excel_member_jumjang = () => {
    if (!$("#auth_code").val()) {
      alert('인증코드를 입력해주세요');
      return;
    }

    if (!$("#down_reason").val()) {
      alert('사유를 입력해주세요');
      return;
    }

    const url = `/xls/member/jumjang?_renew=1${getParam()}&&auth_code=${$("#auth_code").val()}&down_reason=${$("#down_reason").val()}`;
    Fn.download(Constants.API_URL + url);
  };

  const Excel_member_jibujang = () => {
    if (!$("#auth_code").val()) {
      alert('인증코드를 입력해주세요');
      return;
    }

    if (!$("#down_reason").val()) {
      alert('사유를 입력해주세요');
      return;
    }

    const url = `/xls/member/jibujang?_renew=1${getParam()}&&auth_code=${$("#auth_code").val()}&down_reason=${$("#down_reason").val()}`;
    Fn.download(Constants.API_URL + url);
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      {/*!---- 회원검색 -----*/}
      <div className="p-3">
        <table className="view-rounded sub_view fn_search_box">
          <colgroup>
            <col width="160px"/>
            <col width="*"/>
            <col width="160px"/>
            <col width="*"/>
            <col width="160px"/>
            <col width="*"/>
          </colgroup>
          <tbody>
          <tr>
            <th>가입일</th>
            <td>
              <div className="input-group" style={{width: "auto"}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date1"/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date2"/>
              </div>
            </td>
            <th>이름검색</th>
            <td><input type="text" className="form-control" aria-describedby="button-addon2" id="s_mb_name"/></td>
            <th>아이디검색</th>
            <td><input type="text" className="form-control" aria-describedby="button-addon2" id="s_member_id"/></td>
          </tr>
          <tr>
            <th>생년월일검색</th>
            <td><input type="text" className="form-control" aria-describedby="button-addon2" id="s_mb_birth"/></td>
            <th>연락처검색</th>
            <td><input type="text" className="form-control" aria-describedby="button-addon2" id="s_mb_hp"/></td>
            <th>성별검색</th>
            <td className="text-left">
              <select className="form-select" aria-label="Default select example" style={{minWidth: "110px", maxWidth: "110px"}} id="s_sex">
                <option value="">성별</option>
                <option value="M">남성</option>
                <option value="F">여성</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>
              <select className="form-select" aria-label="Default select example" style={{width: "32%"}} id="date_type">
                <option value="A" selected={dateType === "A"}>운동시작일</option>
                {result?.bOptRow?.opt1 === "Y" && <option value="B">골프시작일</option>}
                {result?.bOptRow?.opt3 === "Y" && <option value="C">스쿼시시작일</option>}
                {result?.bOptRow?.opt4 === "Y" && <option value="D">스피닝시작일</option>}
                {result?.bOptRow?.opt5 === "Y" && <option value="E">태닝시작일</option>}
                {result?.bOptRow?.opt7 === "Y" && <option value="F">필라테스시작일</option>}
              </select>
            </th>
            <td>
              <div className="input-group" style={{width: "auto"}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
              </div>
            </td>
            <th>운동종료일</th>
            <td>
              <div className="input-group" style={{width: "auto"}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="e_date1"/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="e_date2"/>
              </div>
            </td>
            <th>라커종료일</th>
            <td>
              <div className="input-group" style={{width: "auto"}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="l_date1"/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="l_date2"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>라커사용여부</th>
            <td>
              <div className="input-group">
                <select className="form-select form-select-not100" aria-label="Default select example" style={{width: "32%"}} id="s_locker">
                  <option value="">라커</option>
                  <option value="1">사용</option>
                  <option value="x">미사용</option>
                  <option value="2">예약</option>
                  <option value="3">미지급</option>
                </select>
                <input type="text" className="form-control ml-1" aria-describedby="button-addon2" placeholder="라커번호" style={{width: "100px !important"}} id="s_locker_name"/>
              </div>
            </td>
            <th>지부검색</th>
            <td className="text-left">
              <select className="form-select" aria-label="Default select example" style={{minWidth: "120px", maxWidth: "120px"}} id="s_jibu">
              </select>
            </td>
            <th>마케팅 동의</th>
            <td className="text-left">
              <select className="form-select" aria-label="Default select example" id="s_marketing_YN" style={{minWidth: "110px", maxWidth: "110px"}}>
                <option value="">전체</option>
                <option value="Y">동의</option>
                <option value="N">비동의</option>
              </select>
            </td>
          </tr>
          <tr>
            {/*<th>입장시간</th>*/}
            {/*<td className="text-left">*/}
            {/*  <div className="input-group">*/}
            {/*    <select className="form-select" style={{maxWidth: "75px"}} id="s_start_hour">*/}
            {/*      {Array.from({length: 24}).map((_, i) => {*/}
            {/*        const j = i < 10 ? `0${i}` : i;*/}
            {/*        return (*/}
            {/*          <option value={j}>{j}시</option>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    </select>*/}
            {/*    <p className="m-1">:</p>*/}
            {/*    <select className="form-select" style={{maxWidth: "75px"}} id="s_start_minute">*/}
            {/*      {Array.from({length: 60}).map((_, i) => {*/}
            {/*        const j = i < 10 ? `0${i}` : i;*/}
            {/*        return (*/}
            {/*          <option value={j}>{j}분</option>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    </select>*/}
            {/*    <p className="mx-2">~</p>*/}
            {/*    <select className="form-select" style={{maxWidth: "75px"}} id="s_end_hour">*/}
            {/*      {Array.from({length: 24}).map((_, i) => {*/}
            {/*        const j = i < 10 ? `0${i}` : i;*/}
            {/*        return (*/}
            {/*          <option value={j}>{j}시</option>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    </select>*/}
            {/*    <p className="m-1">:</p>*/}
            {/*    <select className="form-select" style={{maxWidth: "75px"}} id="s_end_minute">*/}
            {/*      {Array.from({length: 60}).map((_, i) => {*/}
            {/*        const j = i < 10 ? `0${i}` : i;*/}
            {/*        return (*/}
            {/*          <option value={j}>{j}분</option>*/}
            {/*        );*/}
            {/*      })}*/}
            {/*    </select>*/}
            {/*  </div>*/}
            {/*</td>*/}
            <th>기타검색</th>
            <td colSpan="5" className="text-left">
              <div className="input-group justify-content-between align-content-center align-items-center" style={{width: "auto"}}>
                <div>
                  <select className="form-select" aria-label="Default select example" style={{minWidth: "110px", maxWidth: "110px"}} id="s_vacc_YN">
                    <option value="">타지점이용</option>
                    <option value="Y">이용</option>
                  </select>
                  <select className="form-select mx-1" aria-label="Default select example" style={{minWidth: "90px", maxWidth: "90px"}} id="s_suit">
                    <option value="">운동복</option>
                    <option value="1">사용</option>
                    <option value="x">미사용</option>
                  </select>
                  <select className="form-select" aria-label="Default select example" style={{minWidth: "105px", maxWidth: "105px"}} id="s_now_use">
                    <option value="">이용구분</option>
                    <option value="Y">이용회원</option>
                    <option value="N">중단회원</option>
                  </select>
                  <select className="form-select mx-1" aria-label="Default select example" style={{minWidth: "90px", maxWidth: "90px"}} id="s_ot_cnt">
                    <option value="">OT횟수</option>
                    <option value="x">미실시</option>
                    <option value="1">1회</option>
                    <option value="2">2회</option>
                  </select>
                  <select className="form-select" aria-label="Default select example" id="s_visit_course" style={{minWidth: "120px", maxWidth: "120px"}}>
                  </select>
                  <select className="form-select mx-1" aria-label="Default select example" id="s_item_month" style={{minWidth: "110px", maxWidth: "110px"}}>
                    <option value="">상품개월수</option>
                    <option value="1">1개월</option>
                    <option value="3">3개월</option>
                    <option value="6">6개월</option>
                    <option value="12">12개월</option>
                    <option value="18">18개월</option>
                  </select>
                  <select className="form-select" aria-label="Default select example" id="s_item_grade" style={{minWidth: "110px", maxWidth: "110px"}}>
                  </select>

                  {(result?.bOptRow?.opt1 === "Y" || result?.bOptRow?.opt2 === "Y" || result?.bOptRow?.opt3 === "Y" || result?.bOptRow?.opt4 === "Y" || result?.bOptRow?.opt5 === "Y" || result?.bOptRow?.opt6 === "Y" || result?.bOptRow?.opt7 === "Y" || result?.bOptRow?.opt8 === "Y" || result?.bOptRow?.opt9 === "Y" || result?.bOptRow?.opt10 === "Y") &&
                    <div style={{marginTop: "5px"}}>
                      {result?.bOptRow?.opt1 === "Y" && (
                        <span className="pl-2">
                          {Constants.BranchOpt1} : {` `}
                          <input
                            name="s_opt1"
                            id="s_opt1"
                            type="checkbox"
                            value="Y"
                            className="checkbox"
                          />
                        </span>
                      )}

                      {result?.bOptRow?.opt2 === "Y" && (
                        <span className="pl-2">
                          {Constants.BranchOpt2} : {` `}
                          <input
                            name="s_opt2"
                            id="s_opt2"
                            type="checkbox"
                            value="Y"
                            className="checkbox"
                          />
                        </span>
                      )}

                      {result?.bOptRow?.opt3 === "Y" && (
                        <span className="pl-2">
                          {Constants.BranchOpt3} : {` `}
                          <input
                            name="s_opt3"
                            id="s_opt3"
                            type="checkbox"
                            value="Y"
                            className="checkbox"
                          />
                        </span>
                      )}

                      {result?.bOptRow?.opt4 === "Y" && (
                        <span className="pl-2">
                          {Constants.BranchOpt4} : {` `}
                          <input
                            name="s_opt4"
                            id="s_opt4"
                            type="checkbox"
                            value="Y"
                            className="checkbox"
                          />
                        </span>
                      )}

                      {result?.bOptRow?.opt5 === "Y" && (
                        <span className="pl-2">
                          {Constants.BranchOpt5} : {` `}
                          <input
                            name="s_opt5"
                            id="s_opt5"
                            type="checkbox"
                            value="Y"
                            className="checkbox"
                          />
                        </span>
                      )}

                      {result?.bOptRow?.opt6 === "Y" && (
                        <span className="pl-2">
                          {Constants.BranchOpt6} : {` `}
                          <input
                            name="s_opt6"
                            id="s_opt6"
                            type="checkbox"
                            value="Y"
                            className="checkbox"
                          />
                        </span>
                      )}

                      {result?.bOptRow?.opt7 === "Y" && (
                        <span className="pl-2">
                          {Constants.BranchOpt7} : {` `}
                          <input
                            name="s_opt7"
                            id="s_opt7"
                            type="checkbox"
                            value="Y"
                            className="checkbox"
                          />
                        </span>
                      )}

                      {result?.bOptRow?.opt8 === "Y" && (
                        <span className="pl-2">
                          {Constants.BranchOpt8} : {` `}
                          <input
                            name="s_opt8"
                            id="s_opt8"
                            type="checkbox"
                            value="Y"
                            className="checkbox"
                          />
                        </span>
                      )}

                      {result?.bOptRow?.opt9 === "Y" && (
                        <span className="pl-2">
                          {Constants.BranchOpt9} : {` `}
                          <input
                            name="s_opt9"
                            id="s_opt9"
                            type="checkbox"
                            value="Y"
                            className="checkbox"
                          />
                        </span>
                      )}

                      {result?.bOptRow?.opt10 === "Y" && (
                        <span className="pl-2">
                          {Constants.BranchOpt10} : {` `}
                          <input
                            name="s_opt10"
                            id="s_opt10"
                            type="checkbox"
                            value="Y"
                            className="checkbox"
                          />
                        </span>
                      )}
                    </div>
                  }
                </div>

                <div className="text-right">
                  <select className="form-select SEL_white" aria-label="Default select example" id="num_per_page" style={{maxWidth: "70px"}}>
                    <option value="20">20개</option>
                    <option value="50">50개</option>
                    <option value="100">100개</option>
                    <option value="200">200개</option>
                    <option value="300">300개</option>
                  </select>
                  <button type="button" className="btn btn-sm btn-point px-2 mx-1" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
                  <button type="button" className="btn btn-sm btn-dark px-2" onClick={() => clear_form()}>검색 초기화</button>
                </div>
              </div>
            </td>
          </tr>
          {false &&
            <tr>
              <th></th>
              <td colSpan={5} className="text-left">
                출석율0% 회원 :
                <input type="checkbox" value='Y' className="checkbox" id="nchk"/>
                <label htmlFor="nchk"><span></span></label> &nbsp;

                {Array.from({length: 10}, (_, i) => i + 1).map(optNumber => (
                  result?.bOptRow?.[`opt${optNumber}`] === "Y" && (
                    <React.Fragment key={optNumber}>
                      {BranchOpt[`BranchOpt${optNumber}`]} :
                      <input
                        name={`s_opt${optNumber}`}
                        type="checkbox"
                        value="Y"
                        className="checkbox"
                        id={`s_opt${optNumber}`}
                      />
                      <label htmlFor={`s_opt${optNumber}`}><span></span></label> &nbsp;
                    </React.Fragment>
                  )
                ))}
              </td>
            </tr>
          }
          </tbody>
        </table>
      </div>

      <div className="mt-0 ml-3 mr-3 mb-3 table_list">
        {/*!---- 회원목록 -----*/}
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="40px"/>
            {accountYN === "Y" && (
              <col width="115px"/>
            )}
            <col width="115px"/>
            <col width="70px"/>
            <col width="110px"/>
            <col width="45px"/>
            <col width="55px"/>
            <col width="85px"/>
            <col width="60px"/>
            <col width="80px"/>
            <col width="90px"/>
            <col width="90px"/>
            <col width="130px"/>
            <col width="65px"/>
            {accountYN !== "Y" &&
              <col width="100px"/>
            }
            <col width="50px"/>
            <col width="*"/>
            <col width="100px"/>
          </colgroup>

          <tbody>
          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            {accountYN === "Y" && (
              <th>지점명</th>
            )}
            <th>카드번호</th>
            <th>성명</th>
            <th>연락처</th>
            <th>성별</th>
            <th>연령</th>
            <th>가입일</th>
            <th>개월수</th>
            <th>상품등급</th>
            <th>시작일</th>
            <th>종료일</th>
            <th>라커</th>
            <th>운동복</th>
            {accountYN !== "Y" &&
              <th>사용서비스</th>
            }
            <th>OT</th>
            <th>ID</th>
            <th>미수금</th>
          </tr>
          {rets && rets.map((ret, i) => {
            let {
              mb_no,
              mb_id,
              mb_name,
              mb_hp: _mb_hp,
              mb_sex,
              mb_birth,
              mb_open_date,
              start_date,
              end_date,
              locker,
              locker_name,
              suit,
              remain_price,
              suit_start_date,
              suit_end_date,
              locker_start_date,
              locker_end_date,
              opt1,
              opt2,
              opt3,
              opt4,
              opt5,
              opt6,
              opt7,
              opt8,
              opt9,
              opt10,
              g_locker,
              ot_cnt,
              buy_month,
              member_grade_name,
              branch_name,
              etc1_start_date,
              etc2_start_date,
              etc3_start_date,
              etc4_start_date,
              etc5_start_date,
              etc1_end_date,
              etc2_end_date,
              etc3_end_date,
              etc4_end_date,
              etc5_end_date,
              member_id,
              date_type
            } = ret;

            let adjustedStartDate = start_date;
            let adjustedEndDate = end_date;

            if (date_type === "B") {
              adjustedStartDate = etc3_start_date;
              adjustedEndDate = etc3_end_date;
            } else if (date_type === "C") {
              adjustedStartDate = etc1_start_date;
              adjustedEndDate = etc1_end_date;
            } else if (date_type === "D") {
              adjustedStartDate = etc2_start_date;
              adjustedEndDate = etc2_end_date;
            } else if (date_type === "E") {
              adjustedStartDate = etc4_start_date;
              adjustedEndDate = etc4_end_date;
            } else if (date_type === "F") {
              adjustedStartDate = etc5_start_date;
              adjustedEndDate = etc5_end_date;
            }

            let opt_use = "";
            if (opt1 == 1 || opt1 == "Y") opt_use += `${Constants.BranchOpt1},`;
            if (g_locker == 1) opt_use += `${Constants.BranchOpt2},`;
            if (opt3 == 1 || opt3 == "Y") opt_use += `${Constants.BranchOpt3},`;
            if (opt4 == 1 || opt4 == "Y") opt_use += `${Constants.BranchOpt4},`;
            if (opt5 == 1 || opt5 == "Y") opt_use += `${Constants.BranchOpt5},`;
            if (opt6 == 1 || opt6 == "Y") opt_use += `${Constants.BranchOpt6},`;
            if (opt7 == 1 || opt7 == "Y") opt_use += `${Constants.BranchOpt7},`;
            if (opt8 == 1 || opt8 == "Y") opt_use += `${Constants.BranchOpt8},`;
            if (opt9 == 1 || opt9 == "Y") opt_use += `${Constants.BranchOpt9},`;
            if (opt10 == 1 || opt10 == "Y") opt_use += `${Constants.BranchOpt10},`;
            if(opt_use.endsWith(",")) opt_use = opt_use.substring(0, opt_use.length - 1);


            let mb_hp_show;

            let account_YN;
            if (
              (localStorage.getItem("USER.systemID") === "futureinfo" ||
                localStorage.getItem("USER.systemID") === "spoany" ||
                localStorage.getItem("USER.systemID") === "tpwls8905") &&
              localStorage.getItem("USER.systemBranchCode") === "ALL"
            ) {
              account_YN = "Y";
            } else {
              account_YN = "N";
            }

            if (account_YN === "Y") {
              const mb_hp = _mb_hp?.replace(/-/g, "");

              if (mb_hp.length === 11) {
                mb_hp_show = `${mb_hp?.substring(0, 3)}-${mb_hp?.substring(3, 7)}-${mb_hp?.substring(7, 11)}`;
              } else if (mb_hp?.length === 10) {
                mb_hp_show = `${mb_hp?.substring(0, 3)}-${mb_hp?.substring(3, 6)}-${mb_hp?.substring(6, 10)}`;
              } else {
                mb_hp_show = mb_hp;
              }

              mb_hp_show = `${mb_hp_show?.substring(0, 3)}-${mb_hp_show?.substring(4, 8)}-****`;
            } else {
              mb_hp_show = Fn.hpShow(_mb_hp, pageInfo?.totalArticles);
            }

            let mb_sex_show;
            if (mb_sex === "M") {
              mb_sex_show = "남";
            } else if (mb_sex === "F") {
              mb_sex_show = "여";
            } else {
              mb_sex_show = "";
            }

            const m_birth_str = mb_birth?.substring(0, 2);

            let birth_show;
            if (m_birth_str === "19") {
              birth_show = 100;
            } else {
              const m_real_b = m_birth_str < "30" ? `20${m_birth_str}` : `19${m_birth_str}`;
              birth_show = new Date().getFullYear() - Number(m_real_b) + 1;
            }

            let locker_period;
            let locker_show;
            if (locker === "1") {
              locker_period = `${locker_start_date} ~ ${locker_end_date}`;
              locker_show = (
                <a style={{cursor: "pointer"}} onClick={() => alert(locker_period)}>
                  <font color="blue">{locker_name}</font>
                </a>
              );
            } else if (locker === "0") {
              locker_period = "";
              locker_show = "미사용";
            } else if (locker === "2") {
              locker_period = "";
              locker_show = "예약";
            } else if (locker === "3") {
              locker_period = "";
              locker_show = "미지급";
            } else {
              locker_period = "";
              locker_show = "미사용";
            }

            let suit_period;
            let suit_show;
            if (suit === "1") {
              suit_period = `${suit_start_date} ~ ${suit_end_date}`;
              suit_show = (
                <a style={{cursor: "pointer"}} onClick={() => alert(suit_period)}>
                  <font color="blue">사용</font>
                </a>
              );
            } else {
              suit_period = "";
              suit_show = "미사용";
            }

            const atd = false; // TODO

            let abl_day;
            let att_day;
            if (atd) {
              abl_day = ret?.abl_day;
              att_day = ret?.att_day;
            }

            return (
              <tr>
                <td>{pageInfo?.totalArticles - pageInfo?.articlesPerPage * (pageInfo?.currentPage - 1) - i}</td>
                <td>
                  {/*<input type="checkbox" name={`chk[${count}]`} value={base_mb_no} id={`achk${count}`} className="checkbox"/>*/}
                  {/*<label htmlFor={`achk${count}`}><span></span></label>*/}
                  <input
                    type="checkbox"
                    name={`chk[${i + 1}]`}
                    value={btoa(mb_no)}
                    id={`achk${i + 1}`}
                    className="checkbox"
                  />
                </td>
                {account_YN === "Y" && (
                  <td>{branch_name}</td>
                )}
                <td>
                  {account_YN === "Y" ? (
                    <strong>
                      <a style={{cursor: "pointer"}} onClick={() => alert("마스터아이디로 회원정보는 확인할수 없습니다. 회원관리 메뉴를 이용해주세요")}>
                        {mb_id}
                      </a>
                    </strong>
                  ) : (
                    <strong>
                      <a onClick={() => history.push({pathname: '/member/view/detail', state: {mbNo: mb_no}})} style={{cursor: "pointer"}}>{mb_id}</a>
                    </strong>
                  )}
                </td>
                <td>
                  <strong>{mb_name}</strong>
                </td>
                <td>
                  <div id={`default_show${i + 1}`}>***-****-****</div>
                </td>
                <td>{mb_sex_show}</td>
                <td>{birth_show > 0 && birth_show}</td>
                <td>{mb_open_date?.substring(0, 10)}</td>
                <td>
                  <div align="right" style={{paddingRight: "3px"}}>{buy_month}개월</div>
                </td>
                <td>{member_grade_name}</td>
                <td>{start_date}</td>
                {ret?.delayChkNum && date_type === "A" ? (
                  <>
                    {ret?.delayChkRows && (
                      <td>
                        <a style={{cursor: "pointer"}} onClick={() => alert(`휴회기간 : ${ret?.delayChkRows[0].de_sDate}~${ret?.delayChkRows[0].de_eDate}`)}>
                          <font color="red">{end_date}</font>
                        </a>
                      </td>
                    )}
                  </>
                ) : (
                  <td>{end_date}</td>
                )}
                <td>{locker_show}</td>
                <td>{suit_show}</td>
                {account_YN === "N" && (
                  <td>
                    <div align="left" style={{paddingLeft: "3px"}}>{opt_use}</div>
                  </td>
                )}
                <td>
                  <div align="center">{ot_cnt}</div>
                </td>
                <td>
                  <div align="left">{member_id}</div>
                </td>
                <td>
                  <div align="right" style={{paddingRight: "5px"}}>
                    {remain_price && Fn.numberWithCommas(remain_price)}
                  </div>
                </td>
                {atd && (
                  <>
                    <td>
                      <div align="center">{att_day}</div>
                    </td>
                    <td>
                      <div align="center">{abl_day - att_day}</div>
                    </td>
                    <td>
                      <div align="center">
                        {abl_day && `${Math.round((att_day / abl_day) * 100)}%`}
                      </div>
                    </td>
                  </>
                )}
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={18}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
          </tbody>
        </table>
      </div>

      <nav className="p-3 my-4 d-flex justify-content-between border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>
            <div className="d-flex mx-1">
              <input
                type="text"
                className="form-control"
                aria-describedby="button-addon2"
                placeholder="먼저 다운로드 사유를 입력해 주세요."
                id="down_reason" name="down_reason"
                style={{width: '280px'}}
              />
              {(localStorage.getItem("USER.systemID") === "spoany" || localStorage.getItem("USER.systemID") === "ceo" ||
                localStorage.getItem("USER.systemID") === "futureinfo" || localStorage.getItem("USER.systemID") === "geniuspark") && (
                <>
                  <button type="button" className="btn btn-danger btn-sm mx-1" onClick={Excel_member}>엑셀저장(휴대폰)</button>
                  <button type="button" className="btn btn-danger btn-sm" onClick={Excel_member_all}>엑셀저장(전체)</button>
                </>
              )}

              {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 400 && (
                <button type="button" className="btn btn-danger btn-sm" onClick={Excel_member_jumjang}>엑셀저장</button>
              )}

              {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && pageInfo?.totalArticles <= 1000 && (
                <button type="button" className="btn btn-danger btn-sm" onClick={Excel_member_jibujang}>엑셀저장</button>
              )}
            </div>
            {pageInfo?.totalArticles > 0 && (
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('member_list')}>연락처 확인</button>
              )
            )}
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default MemberList;
