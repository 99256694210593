import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios2 from "../../helpers/APIHelper";
import {useHistory, useParams} from "react-router-dom";
import Modal from "react-modal";
import $ from "jquery";
import Fn from "../../libraries/Fn";

const FinanceFeeList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();
  const {renew: _renew} = useParams()

  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [result, setResult] = useState({});
  const [modalResult, setModalResult] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (_renew === true || _renew === "true") {
      localStorage.removeItem('fee_year');
      localStorage.removeItem('fee_month');
    }
    $('#s_year').val(localStorage.getItem('fee_year') || Fn.getCurrentDate().split('-')[0])
    $('#s_month').val(localStorage.getItem('fee_month') || Fn.getCurrentDate().split('-')[1])
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const loadModal = () => {
    axios2
      .post(`/finance/make/data/fee/ready?s_year=${$('#s_year option:selected').val()}&s_month=${$('#s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function make_data(branchCode, branchName) {
    const sYear = $('#s_year option:selected').val();
    const sMonth = $('#s_month option:selected').val();

    const txt = `${branchName}지점의 ${sYear}년 ${sMonth}월 데이터를 생성하시겠습니까?`;

    if (window.confirm(txt)) {
      if (!$("#overlay").is(":visible"))
        Fn.showSpinner();

      axios2.postEx("/finance/make/data/fee", {
        s_year: sYear,
        s_month: sMonth,
        branch_code: branchCode
      })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          console.log(data, result);
          alert('생성되었습니다');
          loadModal();
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        })
        .finally(() => {
          // $(".loading_bg").fadeOut("fast");
        });
    }
  }

  const load = () => {
    axios2
      .post(`/finance/fee/list/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          $('#bigo').val(result?.bb_row?.bigo);

          localStorage.setItem('fee_year', $('#s_year option:selected').val());
          localStorage.setItem('fee_month', $('#s_month option:selected').val());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //1-1
  const go_submit = async (event) => {
    axios2
      .postEx('/finance/fee/list', {
        s_year: $('#s_year option:selected').val() || '',
        s_month: $('#s_month option:selected').val() || '',
        bigo: $('#bigo').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        alert("등록되었습니다");
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };


  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/finance/fee/list/false")}>수수료 내역보고</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/finance/fee/public")}>퍼블릭 부서</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/finance/fee/pt")}>PT 부서</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/finance/fee/add")}>추가/기타</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/finance/fee/incen")}>인센티브</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/finance/fee/incen/pt")}>PT 수당</label>

          <input id="gg" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/finance/fee/gx")}>GX 수당</label>

          <input id="hh" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/finance/fee/consult")}>영업컨설팅비</label>

          <input id="ii" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ii" onClick={() => history.push("/finance/fee/step")}>직원별 수수료</label>

          <input id="jj" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="jj" onClick={() => history.push("/finance/fee/overtime/list")}>추가근무관리</label>

          <input id="kk" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="kk" onClick={() => history.push("/finance/fee/etc")}>기타수당</label>

          <input id="ll" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ll" onClick={() => history.push("/finance/fee/branch/overprice")}>초과수당설정</label>
        </div>
      </div>
      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}년</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: "80px"}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => setModalOpen(true)}>수수료 데이타 생성</button>
        </div>
      </div>

      <div className="overflowX m-3">
        <div className="table_wide_sh3 no-min-width" style={{minWidth: "935px !important", maxWidth: "935px"}}>
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <tr>
              <th className="w-25">전월 일반 수수료 내역 총액</th>
              <td className="text-right">{Fn.numberWithCommas(result?.b_row?.normal_price)}</td>
              <th className="w-25s">금월 일반 수수료 내역 총액</th>
              <td className="text-right">{Fn.numberWithCommas(result?.n_row?.normal_price)}</td>
            </tr>
            <tr>
              <th>전월 인센티브 총액</th>
              <td className="text-right">{Fn.numberWithCommas(result?.b_row?.incen_price)}</td>
              <th>금월 인센티브 총액</th>
              <td className="text-right">{Fn.numberWithCommas(result?.n_row?.incen_price)}</td>
            </tr>
            <tr>
              <th>전월 P.T 수수료 내역 총액</th>
              <td className="text-right">{Fn.numberWithCommas(result?.b_row?.pt_price)}</td>
              <th>금월 PT 수수료내역 총액</th>
              <td className="text-right">{Fn.numberWithCommas(result?.n_row?.pt_price)}</td>
            </tr>
            <tr>
              <th>전월 G.X 수수료 내역 총액</th>
              <td className="text-right">{Fn.numberWithCommas(result?.b_row?.gx_price)}</td>
              <th>금월 G.X 수수료내역 총액</th>
              <td className="text-right">{Fn.numberWithCommas(result?.n_row?.gx_price)}</td>
            </tr>
            <tr>
              <th>전월 수수료 내역 총액</th>
              <td className="text-right"><strong>{Fn.numberWithCommas(result?.b_row?.normal_price + result?.b_row?.incen_price + result?.b_row?.pt_price + result?.b_row?.gx_price)}</strong></td>
              <th>금월 수수료 내역 총액</th>
              <td className="text-right"><strong>{Fn.numberWithCommas(result?.n_row?.normal_price + result?.n_row?.incen_price + result?.n_row?.pt_price + result?.n_row?.gx_price)}</strong></td>
            </tr>
            <tr className="warning">
              <th>전월 영업 컨설팅비</th>
              <td className="text-right">{Fn.numberWithCommas(result?.b_row?.consult_price)}</td>
              <th>금월 영업 컨설팅비</th>
              <td className="text-right">{Fn.numberWithCommas(result?.n_row?.consult_price)}</td>
            </tr>
            <tr className="warning">
              <th>전월 영업 컨설팅비 포함 총액</th>
              <td className="text-right">{Fn.numberWithCommas(result?.b_row?.normal_price + result?.b_row?.incen_price + result?.b_row?.pt_price + result?.b_row?.gx_price + result?.b_row?.consult_price)}</td>
              <th>금월 영업 컨설팅비 포함 총액</th>
              <td className="text-right">{Fn.numberWithCommas(result?.n_row?.normal_price + result?.n_row?.incen_price + result?.n_row?.pt_price + result?.n_row?.gx_price + result?.n_row?.consult_price)}</td>
            </tr>
            <tr>
              <th>수수료 내역 보고</th>
              <td colSpan="3">
                <textarea id="bigo" rows="7" className="form-control w-100" placeholder=""></textarea>
              </td>
            </tr>
          </table>
        </div>
        <div className="my-4">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
        </div>
      </div>


      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal()
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>

          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: '650px'}}>
            <div className="POP_title">
              <h5><strong>수수료 데이터 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="*"/>
                  <col width="90px"/>
                  <col width="145px"/>
                  <col width="60px"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>지점명</th>
                  <th>최종월</th>
                  <th>생성일시</th>
                  <th>생성</th>
                </tr>
                {modalResult?.result && modalResult?.result?.map((ret, i) => {
                  return (
                    <tr>
                      <td className="text-center">{i + 1}</td>
                      <td>{ret?.branch_name}</td>
                      <td className="text-center">{ret?.llRow?.make_month || ''}</td>
                      <td className="text-center">{ret?.llRow?.reg_date?.replace(/-/g, '.')?.substring(2, 16) || ''}</td>
                      <td className="text-center">
                        <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => make_data(ret?.branch_code, ret?.branch_name)}>생성</button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default FinanceFeeList;
