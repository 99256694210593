import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import Modal from "react-modal";
import {useHistory} from "react-router-dom";

const EmployeeInvList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [invModalResult, setInvModalResult] = useState({});
  const history = useHistory();

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/inv/inv/list/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);

          result?.result?.map((row, i) => {
            if (row?.use_YN === 'Y') $(`#bchk${i + 1}`).prop("checked", true);
            else $(`#bchk${i + 1}`).prop("checked", false);
          });
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-1.
  const form1 = document.getElementById('form1');

  function del_user() {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 계정을 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/config/user/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function change_login(base_seq, checked) {
    axios2
      .post(`/config/change/user/login?base_seq=${base_seq}&value=${checked ? "Y" : "N"}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function chk_send_mail() {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).attr("value2");
    }).get().join(",");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 투자자에게 메일을 발송하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).attr("value2");
      }).get().join(",");

      formData.append("send_type", $('#send_type').val());
      formData.append("mail_type", $('#mail_type').val());
      formData.append("title", $('#title').val());
      formData.append("cont1", $('#cont1').val());
      formData.append("cont2", $('#cont2').val());
      formData.append("emails[]", values);

      axios2.postFormEx('/inv/inv/mail/send', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          alert('메일 발송이 완료되었습니다.');
          setRenew(Fn.getRenewTime());
          setModal2Open(false);
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const loadInvModal = () => {
    axios2
      .post(`/config/branch/manager/inv/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setInvModalResult(result);

          result?.list?.map((row, i) => {
            const inv_rate = $('#inv_branch').val().split("|")[$('#manage_branch').val().split("|").indexOf(row?.branch_code)];
            if ($('#manage_branch').val().split("|").indexOf(row?.branch_code) !== -1)
              $('#bchk_' + row?.branch_code).prop("checked", true);
            $('#rate_' + row?.branch_code).val(inv_rate || '');
          });
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div>
        <div className="d-flex m-3 fn_search_box">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_branch_code">
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" aria-describedby="button-addon2" id="key"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        {/* 테이블 */}
        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="60px"/>
              <col width="70px"/>
              <col width=""/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>아이디</th>
              <th>성명</th>
              <th>연락처</th>
              <th>이메일</th>
              <th>지점수</th>
              <th>투자지점</th>
              <th>로그인</th>
            </tr>

            {rets.map((row, i) => {
              const {
                seq,
                user_id,
                user_name,
                user_hp,
                user_email,
                manage_branch,
                inv_branch,
                use_YN,
              } = row;

              const manage_branch_split = manage_branch.split('|').filter((item) => item.trim() !== '');
              const manage_branch_split_num = manage_branch_split.length;

              const u_chk = use_YN === 'Y' ? 'checked' : '';

              const base_seq = btoa(seq);

              return (
                <tr key={i}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input
                      type="checkbox"
                      value={btoa(row?.seq)}
                      value2={user_email + "|" + user_name}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                    <label htmlFor={`achk${i + 1}`}>
                      <span></span>
                    </label>
                  </td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px', cursor: "pointer"}}
                         onClick={() => {
                           history.push({
                             pathname: '/employee/inv/reg', state: {
                               baseSeq: btoa(row?.seq),
                             }
                           });
                         }}
                    >
                      {/*<a href={`inv_reg.html?base_seq=${base_seq}&page=${page}&${link_param}`}>*/}
                      <strong>{user_id}</strong>
                      {/*</a>*/}
                    </div>
                  </td>
                  <td>
                    <div align="center">
                      {user_name}
                    </div>
                  </td>
                  <td>{user_hp}</td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px'}}>
                      {user_email}
                    </div>
                  </td>
                  <td>{manage_branch_split_num}</td>
                  <td>
                    <input
                      className="btn btn-ssm btn-outline-point px-2"
                      type="button"
                      value="확인"
                      onClick={() => {
                        $('#manage_branch').val(manage_branch);
                        $('#inv_branch').val(inv_branch);
                        setModal1Open(true);
                      }}
                      // onClick={() =>
                      //   pop_up(
                      //     `../config/branch_manager_inv.html?type=show&selected_branch=${manage_branch}&selected_inv=${inv_branch}`,
                      //     'branch_manager',
                      //     '550',
                      //     '500',
                      //     'yes'
                      //   )
                      // }
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="use_YN"
                      value="Y"
                      className="checkbox"
                      id={`bchk${i + 1}`}
                      onClick={(e) => change_login(base_seq, e.target.checked)}
                    />
                    <label htmlFor={`bachk${i + 1}`}>
                      <span></span>
                    </label>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          <div>
            <button type="button" className="btn btn-sm btn-outline-point px-2" onClick={() => {
              let con = $('[id^=achk]:checked').map(function () {
                return $(this).attr("value2");
              }).get().join(",");

              if (con === '' || con.split(",").length === 0) {
                window.alert('체크된 리스트가 없습니다.');
                return;
              }

              setModal2Open(true);
            }}>메일발송
            </button>
            <button type="button" className="btn btn-sm btn-outline-point px-2 mx-1" onClick={() => history.push("/employee/inv/reg")}>추가</button>
            <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => del_user()}>삭제</button>
          </div>
        </nav>
      </div>

      {/*팝업 1*/}

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadInvModal();
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '750px'}}>
            <div className="POP_title">
              <h5>
                <strong>투자지점 설정</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="80px"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="120px"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>
                    <input type="checkbox"/>
                  </th>
                  <th>지점코드</th>
                  <th>지점명</th>
                  <th>지분율</th>
                </tr>
                {invModalResult?.list?.map((row, i) =>
                  <tr>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">
                      <input type="checkbox" className="input_shorts" id={`bchk_${row?.branch_code}`} value={row?.branch_code}/>
                    </td>
                    <td className="text-center">{row?.branch_code}</td>
                    <td className="text-center">{row?.branch_name}</td>
                    <td className="text-center">
                      <input type="text" className="input_shorts" id={`rate_${row?.branch_code}`}/>
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModal1Open(false)}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/*팝업 2*/}

      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '1050px'}}>
            <div className="POP_title">
              <h5>
                <strong>이메일 작성</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_view">
                <colgroup>
                  <col width="120px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>제목</th>
                  <td>
                    <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="제목입력" id="title"/>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <textarea className="txtarea mt-1" style={{minHeight: '200px'}} placeholder="내용입력" id="cont1"></textarea>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <textarea className="txtarea mt-1" style={{minHeight: '200px'}} placeholder="내용입력" id="cont2"></textarea>
                    투자자페이지 : http://inv.spoany.co.kr
                  </td>
                </tr>
              </table>
            </div>

            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => chk_send_mail()}>
                이메일전송
              </button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModal2Open(false)}>
                취소
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="send_type" value="pop"/>
      <input type="hidden" id="mail_type" value="B"/>
      <input type="hidden" id="manage_branch"/>
      <input type="hidden" id="inv_branch"/>

    </CmnCard>
  );
};

export default EmployeeInvList;
