import React, {useContext, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../sass/main.scss";
import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import _ from "lodash";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "./Paging";
import JDatePicker from "./JDatePicker";

const SmsNew = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [modalOpen, setModalOpen] = useState(props.isOpen);
  const [modal2Open, setModal2Open] = useState(false);

  useEffect(() => {
    setModalOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    if (!modalOpen) {
      props?.onModalClose();
    }
  }, [modalOpen]);

  const [result, setResult] = useState({});
  const [modal2Result, setModal2Result] = useState();
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [bType, setBType] = useState('');

  useEffect(() => {
    if (srchRenew <= 0) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const appendContent = (cont) => {
    $('#content').focus();
    $('#content').val($('#content').val() + cont);
    cut_txt($('#content')[0]);
  }

  function getTextLength(str) {
    let len = 0;
    for (let i = 0; i < str.length; i++) {
      if (escape(str.charAt(i)).length == 6) {
        len++;
      }
      len++;
    }
    return len;
  }

  function cut_txt(obj) {
    let text = $(obj).val();
    let leng = text.length;
    let mlen = $("#max_msg_length").val();

    if (getTextLength(text) > mlen) {
      if (window.confirm("90바이트를 초과하였습니다. LMS 발송으로 변경하시겠습니까?")) {

        $("#max_msg_length").val("2000")
        $("#mlen_txt").html("2000");
        //LMS제목 보이게 처리
        $("#sms_title").show();
      } else {
        while (getTextLength(text) > 90) {
          leng--;
          text = text.substring(0, leng);
        }
        $(obj).val(text);
      }
    }

    $('#msg_byte').text(getTextLength(text));
  }

  const load = (_page) => {
    $('#editMsgid').val($('#SMS_editMsgid').val());
    $('#b_type').val($('#SMS_b_type').val());
    setBType($('#SMS_b_type').val());
    $('#s_type').val($('#SMS_s_type').val());

    const params = getParams();

    if (!$('#b_type').val()) return;
    if (localStorage.getItem("USER.systemBranchCode") == "ALL" && $('#b_type').val() != "member") {
      alert("전체지점 선택 시, 메시지관리 > SMS 발송만 가능합니다.");
      setModalOpen(false);
      return;
    }

    axios2
      .post(`/message/sms/new/ready?edit_msg_id=${encodeURIComponent($('#editMsgid').val())}&b_type=${$('#b_type').val()}&s_type=${$('#s_type').val()}&${params}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $('#r_hp').val(result?.sendTelNo);
          $('#add_tel_no2').val(result?.manageHp1);
          $('#add_tel_no3').val(result?.manageHp2);
          $('#add_tel_no4').val(result?.manageHp3);
          setResult(result);
          console.log('sms', result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const loadModal2 = () => {
    const params = getParams();

    if (params == "") {
      alert("파라메터에 오류가 있어, 미리보기가 불가능합니다.");
      return;
    }

    var form = $('#sms_form')[0];
    var formData = new FormData(form);
    $.ajax({
      url: `/message/sms/preview/ready?${params}`,
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        console.log(data?.result);
        setModal2Result(data?.result);
        data = data?.result?.status?.trim();
        if (data == "many") {
          alert("15,000건 이상의 메세지는 발송할 수 없습니다.");
          return;
        }
      }
    });
  };

  const getParams = () => {
    let params = "";
    if ($('#b_type').val() == "member") {
      params += `search_YN=Y&`;
      params += `o_date1=${$('#o_date1').val()}&`;
      params += `o_date2=${$('#o_date2').val()}&`;
      params += `date_type=A&`;
      params += `s_date1=${$('#s_date1').val()}&`;
      params += `s_date2=${$('#s_date2').val()}&`;
      params += `e_date1=${$('#e_date1').val()}&`;
      params += `e_date2=${$('#e_date2').val()}&`;
      params += `l_date1=${$('#l_date1').val()}&`;
      params += `l_date2=${$('#l_date2').val()}&`;
      params += `s_mb_name=${$('#s_mb_name').val()}&`;
      params += `s_mb_hp=${$('#s_mb_hp').val()}&`;
      params += `s_member_id=${$('#s_member_id').val()}&`;
      params += `s_locker=${$('#s_locker option:selected').val() || ''}&`;
      params += `s_locker_name=${$('#s_locker_name').val()}&`;
      params += `s_suit=${$('#s_suit option:selected').val() || ''}&`;
      params += `s_now_use=${$('#s_now_use option:selected').val() || ''}&`;
      params += `s_ot_cnt=${$('#s_ot_cnt option:selected').val() || ''}&`;
      params += `s_visit_course=${$('#s_visit_course option:selected').val() || ''}&`;
      params += `s_sex=${$('#s_sex option:selected').val() || ''}&`;
      params += `s_mb_birth=${$('#s_mb_birth').val() || ''}&`;
      params += `s_item_month=${$('#s_item_month option:selected').val() || ''}&`;
      params += `s_item_grade=${$('#s_item_grade option:selected').val() || ''}&`;
      params += `atd_date1=&`;
      params += `atd_date2=&`;
      params += `s_start_hour=00&`;
      params += `s_start_minute=00&`;
      params += `s_end_hour=00&`;
      params += `s_end_minute=00&`;
      params += `s_marketing_YN=${$('#s_marketing_YN option:selected').val() || ''}&`;
      params += `num_per_page=${$('#num_per_page option:selected').val() || ''}`;
    } else if ($('#b_type').val() == "locker") {
      params = `s_lo_posi=${$('#s_lo_posi').val()}&s_mb_name=${$('#s_mb_name').val()}&date_type=${$('#date_type option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_empty=${$('#s_empty:checked').val() || ''}`;
    } else if ($('#b_type').val() == "e_locker") {
      params = `s_lo_posi=${$('#s_lo_posi').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_id=${$('#s_mb_id').val()}&date_type=${$('#date_type option:selected').val() || ''}&off_type=${$('#off_type option:selected').val() || ''}`;
    } else if ($('#b_type').val() == "pt") {
      params = `s_date1=${$('#s_date1').val() || ''}&s_date2=${$('#s_date2').val() || ''}&s_mb_name=${$('#s_mb_name').val() || ''}&s_mb_hp=${$('#s_mb_hp').val() || ''}&s_receipt_train=${$('#s_receipt_train option:selected').val() || ''}&s_take_train=${$('#s_take_train option:selected').val() || ''}&base_tranier_seq=${$('#base_tranier_seq').val() || ''}&s_end_YN=${$('#s_end_YN').val() || ''}`;
    } else if ($('#b_type').val() == "finance") {
      params = `s_mb_name=${$('#s_mb_name').val()}&o_date1=${$('#o_date1').val()}&o_date2=${$('#o_date2').val()}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&sf_type=${$('#sf_type option:selected').val() || ''}`;
    } else if ($('#b_type').val() == "manager") {
      params = `s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_position=${$('#s_position option:selected').val() || ''}&s_pay_part=${$('#s_pay_part option:selected').val() || ''}&s_part=${$('#s_part option:selected').val() || ''}&s_new_position=${$('#s_new_position option:selected').val() || ''}&s_inout_state=${$('#s_inout_state option:selected').val() || ''}&key=${$('#key').val()}`;
    }

    return params;
  }

  const go_submit = (send_tot) => {
    const params = getParams();

    if (!$('#r_hp').val()?.trim()) {
      alert("발신번호를 입력해주세요.");
      return;
    }

    if (!$('#content').val()?.trim()) {
      alert("내용을 입력해주세요.");
      return;
    }

    if (params == "") {
      alert("파라메터에 오류가 있어, SMS를 발송할 수 없습니다.");
      return;
    }

    if (!window.confirm(send_tot + "명의 회원에게 SMS(LMS)를 정말 발송하시겠습니까?")) return;

    var form = $('#sms_form')[0];
    var formData = new FormData(form);
    $.ajax({
      url: `/message/sms/send/new?${params}`,
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        console.log(data);
        data = data?.result?.status?.trim();
        if (data == "many") {
          alert("15,000건 이상의 메세지는 발송할 수 없습니다.");
          return;
        }
        alert("발송되었습니다.");
        setModalOpen(false);
      }
    });
  }

  return (
    <Modal
      isOpen={modalOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();

        $('#max_msg_length').val('90');
        $('#add_tel_no1').val('01084059618');

        load();

        $('.spe')
          .css('cursor', 'pointer')
          .on("click", function (e) {
            e.preventDefault();
            e.stopPropagation();

            let text = $(this).text();
            if (text === '&#38;') text = '&'

            appendContent(text);
          });
      }}
      onRequestClose={() => setModalOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>

        <div className="pop_box01 p_absolute_mid_pop" style={{width: '750px'}}>
          <div className="POP_title">
            <h5><strong>SMS 전송</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <form name="sms_form" id="sms_form" method="post">
              <input type="hidden" name="b_type" id="b_type"/>
              <input type="hidden" name="s_type" id="s_type"/>
              <input type="hidden" name="editMsgid" id="editMsgid"/>

              <div className="row g-1">
                <div className="col-5">

                  <input type="hidden" id="max_msg_length" name="max_msg_length"/>

                  <table className="view-rounded sub_view">
                    <colgroup>
                      <col width="30%"/>
                      <col width="*"/>
                    </colgroup>
                    <tr>
                      <th colSpan="2"><span id="msg_byte">0</span>/<span id="mlen_txt">90</span> Byte</th>
                    </tr>
                    <tr>
                      <td className="text-left" colSpan="2"><input type="text" className="form-control my-1" placeholder="제목없음" name="sms_title" id="sms_title" style={{display: "none"}}/></td>
                    </tr>
                    <tr>
                      <td colSpan="2"><textarea className="form-control w-100" id="content" name="content" onKeyUp={(e) => cut_txt(e.target)} style={{height: "112px"}}></textarea></td>
                    </tr>
                    <tr>
                      <td className="border-right">발신번호</td>
                      <td className="text-left"><input type="text" className="form-control my-1" placeholder="" name="r_hp" id="r_hp" readOnly={true}/></td>
                    </tr>
                    <tr>
                      <td className="border-right">수신번호</td>
                      <td className="text-left">
                        <input type="text" className="form-control my-1" placeholder="1." name="add_tel_no1" id="add_tel_no1"/>
                        <input type="text" className="form-control my-1" placeholder="2." name="add_tel_no2" id="add_tel_no2"/>
                        <input type="text" className="form-control my-1" placeholder="3." name="add_tel_no3" id="add_tel_no3"/>
                        <input type="text" className="form-control my-1" placeholder="4." name="add_tel_no4" id="add_tel_no4"/>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="col-7">
                  <table className="view-rounded sub_view">
                    <tr>
                      <th>특수문자</th>
                    </tr>
                    <tr>
                      <td>
                        <table className="view-rounded sub_table_border text-center">
                          <tr className="bold700">
                            <td className="spe">&#37;</td>
                            <td className="spe">&#38;</td>
                            <td className="spe">&#42;</td>
                            <td className="spe">&#64;</td>
                            <td className="spe">&#8251;</td>
                            <td className="spe">☆</td>
                            <td className="spe">★</td>
                            <td className="spe">◎</td>
                            <td className="spe">●</td>
                            <td className="spe">◆</td>
                            <td className="spe">◇</td>
                          </tr>
                          <tr>
                            <td className="spe">△</td>
                            <td className="spe">▲</td>
                            <td className="spe">▽</td>
                            <td className="spe">▼</td>
                            <td className="spe">→</td>
                            <td className="spe">←</td>
                            <td className="spe">↑</td>
                            <td className="spe">↓</td>
                            <td className="spe">◁</td>
                            <td className="spe">▷</td>
                            <td className="spe">♤</td>
                          </tr>
                          <tr>
                            <td className="spe">♧</td>
                            <td className="spe">♡</td>

                            <td className="spe"></td>
                            <td className="spe"></td>
                            <td className="spe"></td>
                            <td className="spe"></td>
                            <td className="spe"></td>
                            <td className="spe"></td>
                            <td className="spe"></td>
                            <td className="spe"></td>
                            <td className="spe"></td>

                            {/*<td className="spe">◀︎</td>*/}
                            {/*<td className="spe">▶︎</td>*/}
                            {/*<td className="spe">♠︎</td>*/}
                            {/*<td className="spe">♣︎</td>*/}
                            {/*<td className="spe">♥︎</td>*/}
                            {/*<td className="spe">◉</td>*/}
                            {/*<td className="spe">⦿</td>*/}
                            {/*<td className="spe">◼︎</td>*/}
                            {/*<td className="spe">︎◻</td>*/}
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th>문구대체</th>
                    </tr>
                    {bType == 'locker' &&
                      <tr>
                        <td>
                          <div className="d-flex flex-wrap">
                            <p className="w-50 p-1">1.[[회원명]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[회원명]]`)}>선택</button></p>
                            <p className="w-50 p-1">2.[[종료일]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[종료일]]`)}>선택</button></p>
                            <p className="w-50 p-1">3.[[수신거부]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`무료수신거부 0808809618`)}>선택</button></p>
                          </div>
                        </td>
                      </tr>
                    }
                    {bType == 'member' &&
                      <tr>
                        <td>
                          <div className="d-flex flex-wrap">
                            <p className="w-50 p-1">1.[[회원명]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[회원명]]`)}>선택</button></p>
                            <p className="w-50 p-1">2.[[종료일]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[종료일]]`)}>선택</button></p>
                            <p className="w-50 p-1">3.[[수신거부]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`무료수신거부 0808809618`)}>선택</button></p>
                          </div>
                        </td>
                      </tr>
                    }
                    {bType == 'e_locker' &&
                      <tr>
                        <td>
                          <div className="d-flex flex-wrap">
                            <p className="w-50 p-1">1.[[회원명]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[회원명]]`)}>선택</button></p>
                            <p className="w-50 p-1">2.[[종료일]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[종료일]]`)}>선택</button></p>
                            <p className="w-50 p-1">3.[[수신거부]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`무료수신거부 0808809618`)}>선택</button></p>
                          </div>
                        </td>
                      </tr>
                    }
                    {bType == 'pt' &&
                      <tr>
                        <td>
                          <div className="d-flex flex-wrap">
                            <p className="w-50 p-1">1.[[회원명]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[회원명]]`)}>선택</button></p>
                            <p className="w-50 p-1">2.[[진행횟수]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[진행횟수]]`)}>선택</button></p>
                            <p className="w-50 p-1">3.[[잔여횟수]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[잔여횟수]]`)}>선택</button></p>
                            <p className="w-50 p-1">4.[[수신거부]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`무료수신거부 0808809618`)}>선택</button></p>
                          </div>
                        </td>
                      </tr>
                    }
                    {bType == 'free_ot' &&
                      <tr>
                        <td>
                          <div className="d-flex flex-wrap">
                            <p className="w-50 p-1">1.[[회원명]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[회원명]]`)}>선택</button></p>
                          </div>
                        </td>
                      </tr>
                    }
                    {bType == 'finance' &&
                      <tr>
                        <td>
                          <div className="d-flex flex-wrap">
                            <p className="w-50 p-1">1.[[회원명]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[회원명]]`)}>선택</button></p>
                            <p className="w-50 p-1">2.[[미납금]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`[[미납금]]`)}>선택</button></p>
                            <p className="w-50 p-1">3.[[수신거부]] <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => appendContent(`무료수신거부 0808809618`)}>선택</button></p>
                          </div>
                        </td>
                      </tr>
                    }
                    <tr>
                      <th>발송시간</th>
                    </tr>
                    <tr>
                      <td className="text-left">
                        <select className="form-select" aria-label="Default select example" style={{maxWidth: "32%"}} name="reserve_YN" id="reserve_YN"
                                onChange={(e) => {
                                  if (e.target.value == "Y") {
                                    $('#reserve_data').show();
                                  } else {
                                    $('#reserve_data').hide();
                                  }
                                }}
                        >
                          <option value="N">즉시</option>
                          <option value="Y">예약</option>
                        </select>
                      </td>
                    </tr>
                    <tr id="reserve_data" style={{display: "none"}}>
                      <td className="text-left">
                        <div className="d-flex text-left">
                          <JDatePicker className="form-control" aria-describedby="button-addon2" name="reserve_date" id="reserve_date"/>
                          <select className="form-select-sm table-inline mx-1" name="reserve_hour" id="reserve_hour" style={{maxWidth: "60px"}}>
                            {Array.from({length: 24}).map((_, i) => {
                              const j = i < 10 ? `0${i}` : i;
                              return (
                                <option value={j}>{j}시</option>
                              );
                            })}
                          </select>
                          <p className="p-1">:</p>
                          <select className="form-select-sm table-inline" name="reserve_minute" id="reserve_minute" style={{maxWidth: "60px"}}>
                            {Array.from({length: 6}).map((_, i) => {
                              const j = i * 10 < 10 ? `0${i * 10}` : i * 10;
                              return (
                                <option value={j}>{j}분</option>
                              );
                            })}
                          </select>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </form>
          </div>
          <div className="text-center my-3">
            {/*TODO : PTODO -> 반드시 구현*/}
            <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setModal2Open(true)}>미리보기</button>
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => go_submit(result?.num)}>SMS 전송</button>
            <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>닫기</button>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal2();
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1100000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '600px'}}>
            <div className="POP_title">
              <h5><strong>SMS 미리보기</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box">
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <colgroup>
                    <col style={{width: '50px'}}/>
                    <col style={{width: '110px'}}/>
                    <col style={{width: '*'}}/>
                  </colgroup>
                  <thead>
                  <tr>
                    <th className="w_70px">No</th>
                    <th>휴대폰번호</th>
                    <th>미리보기</th>
                  </tr>
                  </thead>
                  <tbody>
                  {modal2Result?.list && modal2Result?.list?.map((ret, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ret?.mbHp || ''}</td>
                        <td className="text-left"
                            dangerouslySetInnerHTML={{__html: (($("#sms_title").val() && $("#sms_title").val() != "제목없음") ? "[" + $("#sms_title").val() + "] " : "") + (ret?.content ? ret?.content?.replace(/\n/gi, '<br/>')?.replace(/ /gi, '&nbsp;') : '')}}></td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              <div className="text-center my-3">
                <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal2Open(false)}>닫기</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Modal>
  );
};

export default SmsNew;
