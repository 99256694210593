import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";

// 스크립트 없음
const AnalysisKpi = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [result, setResult] = useState({});
  const [modalResult, setModalResult] = useState({});
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modalSrchRenew, setModalSrchRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
    $('#s_month').val(Fn.getCurrentDate().split("-")[1]);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  useEffect(() => {
    loadModal();
  }, [modalSrchRenew]);

  const load = () => {
    axios2
      .post(`/analysis/kpi/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/analysis/make/data/kpi/ready?s_year=${$('#modal_s_year option:selected').val() || ''}&s_month=${$('#modal_s_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function make_data(type, type_txt) {
    if (type == 21) {
      alert("PT순익률은 해당월의 수수료보고서가 생성되어있어야 정상적인 데이터가 표시됩니다");
    }

    var txt = type_txt + `의 ${$('#modal_s_year option:selected').val()}년 ${$('#modal_s_month option:selected').val()}월 데이터를 생성하시겠습니까?`
    if (window.confirm(txt)) {
      if (!$("#overlay").is(":visible"))
        Fn.showSpinner();

      axios2
        .post(`/analysis/make/data/kpi?s_year=${$('#modal_s_year option:selected').val() || ''}&s_month=${$('#modal_s_month option:selected').val() || ''}&type=${type}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            alert('생성되었습니다');
            setRenew(Fn.getRenewTime());
            setModalOpen(false);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: '80px'}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => setModalOpen(true)}>KPI 데이타 생성</button>
        </div>
      </div>

      <div id="GRID" className="m-3">
        <dl>
          <dt>재등록률</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "1")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a1_value} %</span> / <span className="kpt_txt3">{result?.data?.a1_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>복귀율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "2")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a2_value} %</span> / <span className="kpt_txt3">{result?.data?.a2_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>미출석율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "3")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a3_value} %</span> / <span className="kpt_txt3">{result?.data?.a3_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>출석율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "4")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a4_value} %</span> / <span className="kpt_txt3">{result?.data?.a4_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>추천지수</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "6")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a6_value} %</span> / <span className="kpt_txt3">{result?.data?.a6_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>문자쿠폰 회수율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "7")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a7_value} %</span> / <span className="kpt_txt3">{result?.data?.a7_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>소개등록율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "8")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a8_value} %</span> / <span className="kpt_txt3">{result?.data?.a8_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>환불율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "9")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a9_value} %</span> / <span className="kpt_txt3">{result?.data?.a9_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>신규등록율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "12")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a12_value} %</span> / <span className="kpt_txt3">{result?.data?.a12_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>장기등록율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "13")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a13_value} %</span> / <span className="kpt_txt3">{result?.data?.a13_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>PT가입율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "14")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a14_value} %</span> / <span className="kpt_txt3">{result?.data?.a14_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>OT진행율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "15")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a15_value} %</span> / <span className="kpt_txt3">{result?.data?.a15_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>OT진행자중 PT가입율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "16")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a16_value} %</span> / <span className="kpt_txt3">{result?.data?.a16_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>총매출</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "17")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{Fn.numberWithCommas(result?.data?.a17_value)} 원</span> / <span className="kpt_txt3">{result?.data?.a17_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>퍼블릭 매출</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "18")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{Fn.numberWithCommas(result?.data?.a18_value)} 원</span> / <span className="kpt_txt3">{result?.data?.a18_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>PT매출</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "19")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{Fn.numberWithCommas(result?.data?.a19_value)} 원</span> / <span className="kpt_txt3">{result?.data?.a19_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>총 순익</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "20")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{Fn.numberWithCommas(result?.data?.a20_value)} 원</span> / <span className="kpt_txt3">{result?.data?.a20_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>PT순익률</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "21")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a21_value} %</span> / <span className="kpt_txt3">{result?.data?.a21_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>순익타겟 달성율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "22")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a22_value} %</span> / <span className="kpt_txt3">{result?.data?.a22_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>목표매출 달성율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "23")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a23_value} %</span> / <span className="kpt_txt3">{result?.data?.a23_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>퇴직율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "24")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a24_value} %</span> / <span className="kpt_txt3">{result?.data?.a24_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>서비스개월 발급율</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "25")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a25_value} %</span> / <span className="kpt_txt3">{result?.data?.a25_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
        <dl>
          <dt>컴플레인관리</dt>
          <dd>
            <>
              {result?.ff?.filter(r => r?.type === "26")?.length > 0 ? (
                <a href="#1">
                  <div className="kpt_txt_wrap">
                    <span className="kpt_txt2">{result?.data?.a26_value} %</span> / <span className="kpt_txt3">{result?.data?.a26_rank} 위</span>
                  </div>
                </a>
              ) : (
                <div className="kpt_txt_wrap">
                  <span className="kpt_txt4">
                    <i className="fas fa-exclamation-triangle"></i> 데이터 미생성
                  </span>
                </div>
              )}
            </>
          </dd>
        </dl>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          $('#modal_s_year').val($('#s_year').val());
          $('#modal_s_month').val($('#s_month').val());
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '650px'}}>
            <div className="POP_title">
              <h5><strong>KPI 데이타 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box ">
              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select className="form-select" id="modal_s_year">
                    {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <div className="pr-1">
                  <select className="form-select" id="modal_s_month">
                    {[...Array(12)].map((_, index) => {
                      const monthNumber = index + 1;
                      const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                      return (
                        <option key={monthString} value={monthString}>{monthString}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setModalSrchRenew(Fn.getRenewTime())}>검색</button>
                </div>
              </div>
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <colgroup>
                    <col width="60px"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>No</th>
                    <th>항목명</th>
                    <th>생성일시</th>
                    <th>생성</th>
                  </tr>
                  {modalResult?.result?.map((list_row, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{list_row?.type_txt}</td>
                      <td>{list_row?.reg_date?.replace(/-/g, '.').substring(2, 16)}</td>
                      <td>
                        <button className="btn btn-ssm btn-outline-dark" onClick={() => make_data(list_row?.type, list_row?.type_txt)}>
                          생성
                        </button>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default AnalysisKpi;
