import React, {useState, useEffect} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";


const FinanceAccountManage = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('#base_seq').val('');
    $('#j_jukname').val('');
    $('#j_jukname2').val('');
    $('#j_useok').val('Y');

    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/finance/account/manage/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.list);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //1-1
  const go_submit = () => {
    // const regBtn = document.getElementById("reg_btn");
    // regBtn.innerHTML = "<a class='btn btn-danger btn-sm' ><span class='glyphicon glyphicon-saved'></span> 저장</a>";

    if ($('#j_jukname').val() === '') {
      alert('통장명을 입력해주세요');
      $('#j_jukname').focus();
      // regBtn.html("<a class='btn btn-danger btn-sm' onclick='go_submit()'><span class='glyphicon glyphicon-saved'></span> 저장</a>");
      return;
    }

    if ($('#j_jukname2').val() === '') {
      alert('계좌번호를 입력해주세요');
      $('#j_jukname2').focus();
      // regBtn.html("<a class='btn btn-danger btn-sm' onclick='go_submit()'><span class='glyphicon glyphicon-saved'></span> 저장</a>");
      return;
    }

    axios2
      .postEx('/finance/account/manage', {
        base_seq: $('#base_seq').val(),
        j_jukname: $('#j_jukname').val(),
        j_jukname2: $('#j_jukname2').val(),
        j_useok: $('#j_useok option:selected').val(),
        j_gyecode: $('#j_gyecode').val()
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === "err") {
          alert('오류가 발생하였습니다');
        } else if (data === "in_ok") {
          alert("등록되었습니다");
          setRenew(Fn.getRenewTime());
        } else if (data === "in_err") {
          alert("등록오류! 관리자에게 문의하세요");
          // window.location.reload();
        } else if (data === "up_ok") {
          alert("수정되었습니다");
          setRenew(Fn.getRenewTime());
        } else if (data === "up_err") {
          alert("수정오류! 관리자에게 문의하세요");
          // window.location.reload();
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const copy_data = (baseSeq, jJukname, jJukname2, jUseok) => {
    $('#base_seq').val(baseSeq);
    $('#j_jukname').val(jJukname);
    $('#j_jukname2').val(jJukname2);
    $('#j_useok').val(jUseok);
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 mb-4" style={{border: "1px solid #eee", borderRadius: "12px", maxWidth: "935px"}}>
        <div className="border-bottom px-3 p-2">
          <div className="mt-1 text-left"><span className="h6 bold700">계좌 입력</span></div>
        </div>
        {/* 컨텐츠 내용 시작 */}
        <div className="p-3">
          <input type="hidden" id="base_seq"/>
          <input type="hidden" id="j_gyecode" value="1112"/>
          <table className="view-rounded sub_table_border text-center auto">
            <tr>
              <th>통장명</th>
              <td>
                <input type="text" className="form-control" id="j_jukname"/>
              </td>
              <th>계좌번호</th>
              <td>
                <input type="text" className="form-control" id="j_jukname2"/>
              </td>
              <th>사용여부</th>
              <td>
                <select className="form-select" id="j_useok">
                  <option value="Y">사용</option>
                  <option value="N">미사용</option>
                </select>
              </td>
            </tr>
          </table>
        </div>
        <div className="mb-3">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
        </div>
      </div>

      <div className="m-3 pb-2" style={{maxWidth: "935px"}}>
        <table className="view-rounded sub_table_border auto">
          <tr>
            <th className="w_70px">No</th>
            <th>통장명</th>
            <th>계좌번호</th>
            <th>사용여부</th>
            <th>수정</th>
          </tr>
          {rets.map((list_row, index) => {
            const {j_no, j_jukname, j_jukname2, j_useok} = list_row;
            const show_YN_txt = j_useok === 'Y' ? <span className="text-success">사용</span> : <span className="text-danger">미사용</span>;

            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <strong>{j_jukname}</strong>
                </td>
                <td>
                  <div style={{paddingLeft: '10px'}}>
                    <strong>{j_jukname2}</strong>
                  </div>
                </td>
                <td>{show_YN_txt}</td>
                <td>
                  <input
                    type="button"
                    className="btn btn-ssm btn-outline-dark"
                    value="수정"
                    onClick={() => copy_data(btoa(j_no), j_jukname, j_jukname2, j_useok)}
                  />
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </CmnCard>
  );
};

export default FinanceAccountManage;
