import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";

const MessageSmsSendState = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [result, setResult] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('#sms_year').val(Fn.getCurrentDate().split("-")[0]);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/message/sms/send/state/ready?s_result=${$('#s_result option:selected').val() || ''}&s_opt_cmp=${$('#s_opt_cmp option:selected').val() || ''}&sms_year=${$('#sms_year option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-1.
  const memo_form = document.getElementById('memo_form');

  function del_ok() {
    let con = 0;
    let editMsgid = "";
    for (let i = 0; i < memo_form.elements.length; i++) {
      if (memo_form.elements[i].type === 'checkbox') {
        if (memo_form.elements[i].checked === true) {
          const nn = memo_form.elements[i].name;
          const m_chk = nn.substr(0, 3);
          if (m_chk === "chk") {
            con++;
            editMsgid = editMsgid + memo_form.elements[i].value + "|";
          }
        }
      }
    }
    if (con === 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    if (window.confirm('체크한 리스트를 삭제하시겠습니까?')) {
      memo_form.action = 'notice_del.php';
      memo_form.target = "common_i";
      memo_form.submit();
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3">
          <div className="pr-1">
            <select className="form-select" id="s_result">
              <option value="">::전송결과</option>
              <option value="100">발송성공</option>
              <option value="xxx">실패</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_opt_cmp">
              <option value="">::문자타입</option>
              <option value="SMS">SMS</option>
              <option value="LMS">LMS</option>
              <option value="kakao">kakao</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="sms_year">
              {Array.from({length: new Date().getFullYear() - 2013 + 1}, (_, index) => new Date().getFullYear() - index).map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_border auto text-right">
            <colgroup>
              <col width="70px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>지점명</th>
              <th>1월</th>
              <th>2월</th>
              <th>3월</th>
              <th>4월</th>
              <th>5월</th>
              <th>6월</th>
              <th>7월</th>
              <th>8월</th>
              <th>9월</th>
              <th>10월</th>
              <th>11월</th>
              <th>12월</th>
              <th>합계</th>
            </tr>
            {result?.result?.map((row, index) => {
              const count = index + 1;
              const branch_code = row.branch_code;

              return (
                <tr key={branch_code}>
                  <td>{count}</td>
                  <td style={{textAlign: 'left'}}>
                    {branch_code === 'test' ? '기타' : row.branch_name}
                  </td>
                  {[...Array(12)].map((_, i) => {
                    const monthIndex = i + 1;
                    const j = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`;
                    // const branch_month = row.send_cnt[j][branch_code];
                    // row.branch_all_cnt[branch_code] += branch_month;
                    // row.T_branch[j] += branch_month;

                    return (
                      <td key={j}>
                        <div align="right" style={{paddingRight: '5px'}}>
                          {Fn.numberWithCommas(result?.dataMap?.[branch_code]?.[j])}
                        </div>
                      </td>
                    );
                  })}
                  <td>
                    <div align="right" style={{paddingRight: '5px'}}>
                      {Fn.numberWithCommas(result?.dataMap?.[branch_code + "_all_cnt"])}
                    </div>
                  </td>
                </tr>
              );
            })}
            {/*// TODO : PTODO : 합계 처리*/}
            <tr className="bgcolor bold700">
              <td colSpan="2" className="text-center">합계</td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['01'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['02'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['03'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['04'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['05'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['06'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['07'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['08'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['09'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['10'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['11'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(result?.monthMap?.['12'] || 0)}</div>
              </td>
              <td>
                <div align="right" style={{paddingRight: '5px'}}>{Fn.numberWithCommas(
                  result?.monthMap?.['01'] +
                  result?.monthMap?.['02'] +
                  result?.monthMap?.['03'] +
                  result?.monthMap?.['04'] +
                  result?.monthMap?.['05'] +
                  result?.monthMap?.['06'] +
                  result?.monthMap?.['07'] +
                  result?.monthMap?.['08'] +
                  result?.monthMap?.['09'] +
                  result?.monthMap?.['10'] +
                  result?.monthMap?.['11'] +
                  result?.monthMap?.['12']
                )}</div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default MessageSmsSendState;
