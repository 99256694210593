import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";

const MessagePushList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/message/push/list/ready?s_mb_name=${$('#s_mb_name').val()}&o_date1=${$('#o_date1').val()}&o_date2=${$('#o_date2').val()}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&e_date1=${$('#e_date1').val()}&e_date2=${$('#e_date2').val()}&l_date1=${$('#l_date1').val()}&l_date2=${$('#l_date2').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit() {
    if ($('#gcm_title').val() == "") {
      alert('제목을 입력해주세요');
      return;
    }
    if ($('#gcm_msg').val() == "") {
      alert('내용을 입력해주세요');
      return;
    }

    if (!$("#overlay").is(":visible"))
      Fn.showSpinner();

    if ($('#type').val() == "search") {
      var form = $('#form1')[0];
      var formData = new FormData(form);
      $.ajax({
        url: '/message/push/search/send',
        processData: false,
        contentType: false,
        data: formData,
        type: 'POST',
        success: function (data) {
          $("#overlay").hide();
          data = data?.message?.trim();

          if (data === 'many') {
            alert('한 번에 15,000건 이상의 푸시 메세지를 발송할 수 없습니다.');
            return;
          }

          alert("발송되었습니다.");
          setModalOpen(false);
        }
      });
    } else {
      let chks = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join("|");
      $('#chks').val(chks);

      var form = $('#form1')[0];
      var formData = new FormData(form);
      $.ajax({
        url: '/message/push/chk/send',
        processData: false,
        contentType: false,
        data: formData,
        type: 'POST',
        success: function (data) {
          $("#overlay").hide();
          data = data?.message?.trim();

          if (data === 'many') {
            alert('한 번에 15,000건 이상의 푸시 메세지를 발송할 수 없습니다.');
            return;
          }

          alert("발송되었습니다.");
          setModalOpen(false);
        }
      });
    }
  }

  //  3-1.
  function chk_send_real() {
    $(".loading_bg").fadeIn("fast");

    const form = $('form')[1];
    const formData = new FormData(form);

    $.ajax({
      url: 'push_chk_send.php',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data.trim();

        if (data === 'many') {
          alert('한 번에 15,000건 이상의 푸시 메세지를 발송할 수 없습니다.');
          return;
        }

        alert("발송되었습니다.");
        window.location.reload();
      }
    });
  }

  //  5-1.
  function search_send_real() {
    $(".loading_bg").fadeIn("fast");

    const form = $('form')[0];
    const formData = new FormData(form);

    $.ajax({
      url: 'push_search_send.php',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data.trim();

        // document.write(data);
        // return;

        alert("발송되었습니다.");
        window.location.reload();
      }
    });
  }

  function select_reserve() {
    const reserve_YN_value = $("#reserve_YN option:selected").val();

    if (reserve_YN_value == "Y") {
      $('#reserve_data').show();
    } else {
      $('#reserve_data').hide();
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX ">
        <div className="d-flex m-3 flex-wrap fn_search_box">
          <div className="pr-1">
            <input type="text" className="form-control width_shorts" placeholder="회원명 검색" id="s_mb_name"/>
          </div>
          <div className="input-group pr-2 justify-content-center align-content-center align-items-center" style={{width: 'auto'}}>
            <p className="p-1">가입일 :</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date1"/>
            <p className="p-1">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date2"/>
          </div>
          <div className="input-group pr-2 justify-content-center align-content-center align-items-center" style={{width: 'auto'}}>
            <p className="p-1">시작일 :</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="p-1">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
          <div className="input-group pr-2 justify-content-center align-content-center align-items-center" style={{width: 'auto'}}>
            <p className="p-1">종료일 :</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="e_date1"/>
            <p className="p-1">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="e_date2"/>
          </div>
          <div className="input-group pr-1 justify-content-center align-content-center align-items-center" style={{width: 'auto'}}>
            <p className="p-1">라커기간 :</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="l_date1"/>
            <p className="p-1">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="l_date2"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 table_list">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="70px"/>
              <col width="50px"/>
              <col width="130px"/>
              <col width="90px"/>
              <col width="115px"/>
              <col width="60px"/>
              <col width="60px"/>
              <col width="90px"/>
              <col width="90px"/>
              <col width="90px"/>
              <col width="200px"/>
              <col width="90px"/>
              <col width="90px"/>
            </colgroup>
            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>지점명</th>
              <th>성명</th>
              <th>연락처</th>
              <th>성별</th>
              <th>연령</th>
              <th>가입일</th>
              <th>시작일</th>
              <th>종료일</th>
              <th>라커이용기간</th>
              <th>앱설치일</th>
              <th>기기구분</th>
            </tr>
            {rets.map((row, i) => {
              const {
                seq,
                mb_no,
                mb_id,
                mb_hp,
                mb_sex,
                mb_birth,
                mb_open_date,
                start_date,
                end_date,
                locker_start_date,
                locker_end_date,
                reg_date,
                ios_YN,
                event_push,
              } = row;

              const mb_hp_show = Fn.hpShow(mb_hp, pageInfo.totalArticles);
              const base_hp = btoa(mb_hp_show);
              // tot_base_hp += `${index+1},${base_hp}|`;

              const mb_sex_show = mb_sex === "M" ? "남" : (mb_sex === "F" ? "여" : "");

              let birth_show = "";
              if (mb_birth.length > 2) {
                const m_birth_str = mb_birth.slice(0, 2);
                if (m_birth_str === "19") {
                  birth_show = 100;
                } else {
                  const m_real_b =
                    m_birth_str < 30 ? `20${m_birth_str}` : `19${m_birth_str}`;
                  birth_show = new Date().getFullYear() - m_real_b + 1;
                }
              }

              const locker_date_show = locker_start_date && locker_end_date ? `${locker_start_date} ~ ${locker_end_date}` : "";

              const m_gubun = ios_YN === "Y" ? "iphone" : "Android";

              return (
                <tr key={i}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    {event_push === "Y" && (
                      <>
                        <input
                          type="checkbox"
                          value={btoa(mb_no) + "," + seq}
                          className="checkbox"
                          id={`achk${i + 1}`}
                        />
                      </>
                    )}
                  </td>
                  <td>
                    <div style={{paddingLeft: "5px"}}>{row?.branch_name}</div>
                  </td>
                  <td>{row?.mb_name}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{mb_sex_show}</td>
                  <td>{birth_show > 0 && birth_show}</td>
                  <td>{mb_open_date?.substr(0, 10)}</td>
                  <td>{start_date}</td>
                  <td>{end_date}</td>
                  <td>{locker_date_show}</td>
                  <td>{reg_date?.substr(0, 10)}</td>
                  <td>{m_gubun}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            <div>
              <button type="button" className="btn btn-sm btn-outline-success px-2" onClick={() => {
                if (eval("pageInfo?.totalArticles") > 0) {
                  if (!window.confirm(`검색된 ${pageInfo?.totalArticles}명 회원에게 Push를 발송하시겠습니까?`)) return;
                } else {
                  if (!window.confirm(`검색된 회원에게 Push를 발송하시겠습니까?`)) return;
                }
                $('#type').val('search');
                setModalOpen(true);
              }}>검색 Push 발송
              </button>
              <button type="button" className="btn btn-sm btn-outline-success px-2 mx-1"
                      onClick={() => {
                        if ($('[id^=achk]:checked').length === 0) {
                          window.alert('체크된 리스트가 없습니다.');
                          return;
                        }
                        $('#type').val('chk');
                        setModalOpen(true);
                      }}
              >선택 Push 발송
              </button>
            </div>
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>
            {/*TODO : PTODO*/}
            {/*<div className="d-flex mx-1">*/}
            {/*  <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="다운로드 사유 입력" style={{width: "180px"}}/>*/}
            {/*  <button type="button" className="btn btn-sm btn-outline-point px-2 cls-hide">엑셀저장</button>*/}
            {/*</div>*/}
            {pageInfo?.totalArticles > 0 && (
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('push_list')}>연락처 확인</button>
              )
            )}
          </div>
        </div>
      </nav>

      {/*팝업*/}

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          $('#chks').val('');
          $('#reserve_date').val(Fn.getCurrentDate());
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '750px'}}>
            <div className="POP_title">
              <h5><strong>PUSH 발송</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <form name="form1" id="form1">
                <div className="mb-3 tip_box">
                  ", ' , ` 따옴표 등 특수문자 사용 금지
                </div>

                <div className="pb-2">
                  <table className="view-rounded sub_view_sm text-center auto">
                    <tr>
                      <th className="w-20">제목</th>
                      <td><input type="text" className="form-control" aria-describedby="button-addon2" name="gcm_title" id="gcm_title"/></td>
                    </tr>
                    <tr>
                      <th>내용</th>
                      <td><textarea className="form-control w-100" rows="4" name="gcm_msg" id="gcm_msg"></textarea></td>
                    </tr>
                    <tr>
                      <th>링크</th>
                      <td>
                        <select className="form-select" name="gcm_url" id="gcm_url">
                          <option value="">메인</option>
                          <option value="/cscenter/notice_list.html">공지사항</option>
                          <option value="/main/main.html">마이홈</option>
                          <option value="/set/set_main.html">마이페이지</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>발송형태</th>
                      <td>
                        <select className="form-select" name="send_type" id="send_type">
                          <option value="E">이벤트</option>
                          <option value="G">공지사항</option>
                        </select>
                      </td>
                    </tr>
                    <tr>
                      <th>발송시간</th>
                      <td>
                        <select className="form-select" name="reserve_YN" id="reserve_YN" onChange={() => select_reserve()}>
                          <option value="N">즉시</option>
                          <option value="Y">예약</option>
                        </select>
                      </td>
                    </tr>
                    <tr id="reserve_data" style={{display: "none"}}>
                      <th></th>
                      <td className="text-left">
                        <div className="d-flex text-left">
                          <JDatePicker className="form-control" aria-describedby="button-addon2" name="reserve_date" id="reserve_date"/>
                          <select className="form-select-sm table-inline mx-1" name="reserve_hour" id="reserve_hour" style={{maxWidth: "60px"}}>
                            {Array.from({length: 24}).map((_, i) => {
                              const j = i < 10 ? `0${i}` : i;
                              return (
                                <option value={j}>{j}시</option>
                              );
                            })}
                          </select>
                          <p className="p-1">:</p>
                          <select className="form-select-sm table-inline" name="reserve_minute" id="reserve_minute" style={{maxWidth: "60px"}}>
                            {Array.from({length: 6}).map((_, i) => {
                              const j = i * 10 < 10 ? `0${i * 10}` : i * 10;
                              return (
                                <option value={j}>{j}분</option>
                              );
                            })}
                          </select>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <input type="hidden" id="chks" name="chks"/>
              </form>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>발송</button>
              <button type="button" className="btn btn-sm btn-ligray px-3 mx-1" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="type" style={{display: "none"}}/>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default MessagePushList;
