import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import JDatePicker from "../common/JDatePicker";
import $ from "jquery";
import Modal from "react-modal";


const FinanceFeeOverTimeList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();
  const [result, setResult] = useState({});
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/finance/overtime/list/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_manager_name=${$('#s_manager_name').val()}&currPage=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/finance/overtime/reg/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#trainer_seq').html() === '') {
            let optionsHTML = '<option value="">선택</option>';
            result?.regStepList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#trainer_seq').html(optionsHTML);
          }

          console.log(result);
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit() {
    if ($('#o_date').val() == "") {
      alert("근무일을 입력해주세요");
      $('#o_date').focus();
      return;
    }
    if ($('#over_time').val() == "") {
      alert("추가근무시간을 입력해주세요");
      $('#over_time').focus();
      return;
    }
    if ($('#trainer_seq option:selected').val() == "") {
      alert("스텝을 선택해주세요");
      $('#trainer_seq').focus();
      return;
    }
    if ($('#bigo').val() == "") {
      alert("사유를 입력해주세요");
      $('#bigo').focus();
      return;
    }

    if (window.confirm('추가근무내역을 등록하시겠습니까?')) {
      axios2
        .postEx('/finance/overtime/reg', {
          o_date: $('#o_date').val(),
          over_time: $('#over_time').val(),
          trainer_seq: $('#trainer_seq option:selected').val(),
          bigo: $('#bigo').val(),
        })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          alert("등록되었습니다");
          setRenew(Fn.getRenewTime());
          setModalOpen(false);
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function over_time_del(base_seq) {
    axios2
      .postEx('/finance/overtime/del', {
        base_seq: base_seq
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        alert("삭제되었습니다");
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX m-3 ">
        <div className="table_wide_sh3 mb-3">
          <div className="tabs">
            <input id="aa" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="aa" onClick={() => history.push("/finance/fee/list/false")}>수수료 내역보고</label>

            <input id="bb" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="bb" onClick={() => history.push("/finance/fee/public")}>퍼블릭 부서</label>

            <input id="cc" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="cc" onClick={() => history.push("/finance/fee/pt")}>PT 부서</label>

            <input id="dd" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="dd" onClick={() => history.push("/finance/fee/add")}>추가/기타</label>

            <input id="ee" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="ee" onClick={() => history.push("/finance/fee/incen")}>인센티브</label>

            <input id="ff" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="ff" onClick={() => history.push("/finance/fee/incen/pt")}>PT 수당</label>

            <input id="gg" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="gg" onClick={() => history.push("/finance/fee/gx")}>GX 수당</label>

            <input id="hh" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="hh" onClick={() => history.push("/finance/fee/consult")}>영업컨설팅비</label>

            <input id="ii" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="ii" onClick={() => history.push("/finance/fee/step")}>직원별 수수료</label>

            <input id="jj" type="radio" name="tab_item" checked/>
            <label className="tab_item" htmlFor="jj" onClick={() => history.push("/finance/fee/overtime/list")}>추가근무관리</label>

            <input id="kk" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="kk" onClick={() => history.push("/finance/fee/etc")}>기타수당</label>

            <input id="ll" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="ll" onClick={() => history.push("/finance/fee/branch/overprice")}>초과수당설정</label>
          </div>
        </div>

        <div className="d-flex my-3 fn_search_box">
          <div className="d-flex pr-1">
            <JDatePicker className="form-control" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="s_date2"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="성명검색" id="s_manager_name"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7 ?
              <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => setModalOpen(true)}>등록</button>
              :
              <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => alert('지점장 이상만 등록가능합니다')}>등록</button>
            }
          </div>
        </div>

        <div className="table_list table_wide_sh3">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <tr>
              <th className="w_70px">No</th>
              <th>근무일</th>
              <th>근무시간</th>
              <th>시급</th>
              <th>추가수당</th>
              <th>스텝명</th>
              <th className="w-25">사유</th>
              <th>삭제</th>
            </tr>
            {result?.result?.map((row, index) => {
              const base_seq = btoa(row.seq); // Using btoa() to encode the sequence value to base64

              return (
                <tr key={index}>
                  <td>{pageInfo?.totalArticles - pageInfo?.articlesPerPage * (pageInfo?.currentPage - 1) - index}</td>
                  <td>{row?.o_date}</td>
                  <td>{row?.over_time}시간</td>
                  <td className="text-right">{Fn.numberWithCommas(row?.hour_price)}</td>
                  <td className="text-right">{Fn.numberWithCommas(row?.over_price)}</td>
                  <td>{row?.user_name}</td>
                  <td className="text-left">{row?.bigo}</td>
                  <td>
                    {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7 && Fn.getCurrentDate() === row?.o_date ?
                      <button className="btn btn-ssm btn-outline-dark" type="button" onClick={() => {
                        if (window.confirm('해당 추가근무내역을 삭제하시겠습니까?')) {
                          over_time_del(btoa(row?.seq))
                        }
                      }}>삭제</button>
                      :
                      <button className="btn btn-ssm btn-outline-dark" type="button" onClick={() => alert('지점장 이상 당일건만 삭제가능합니다')}>삭제</button>
                    }
                  </td>
                </tr>
              );
            })}
            {(!result?.result || result?.result?.length === 0) &&
              <tr>
                <td colSpan={8}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-center border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

      {/*팝업*/}

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '900px'}}>
            <div className="POP_title">
              <h5><strong>추가근무 등록</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box">
              <table className="view-rounded sub_table_border">
                <tr>
                  <th>근무일</th>
                  <td>
                    <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date"/>
                  </td>
                  <th>추가근무시간</th>
                  <td>
                    <div className="d-flex">
                      <input type="text" className="form-control table-inline input_shorts" id="over_time"/>
                      <p className="p-1">시간</p>
                    </div>
                  </td>
                  <th>스텝</th>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: "32%"}} id="trainer_seq">
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>사유</th>
                  <td colSpan="5"><input type="text" className="form-control" id="bigo"/></td>
                </tr>
              </table>
            </div>

            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => go_submit()}>등록</button>
              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default FinanceFeeOverTimeList;
