import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios from "axios";
import ChkAuthNum from "../common/ChkAuthNum";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Modal from "react-modal";
import Fn from "../../libraries/Fn";
import Constants from "../../constants/constants";

const BranchopStrangeWrite = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();
  const location = useLocation();
  const [baseSeq, setBaseSeq] = useState(location?.state?.baseSeq || '');

  const [content, setContent] = useState('');
  const [result, setResult] = useState();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});
  const [modalPageInfo, setModalPageInfo] = useState({});
  const [modalCurrPage, setModalCurrPage] = useState(1);

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    axios2
      .post(`/branchop/strange/write/ready?base_seq=${baseSeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult(result);
          if (baseSeq) {
            $('#mb_no').val(result?.row?.mb_no);
            $('#branch_code').val(result?.row?.branch_code);
            $('#take_YN').val(result?.row?.take_YN);
            setContent(result?.row?.content || '');

            if (!result?.row?.mb_no) {
              $('#not_mem_show').css('display', 'flex');
              $('#not_mem_name').val(result?.row?.mb_name);
              $('#not_mem_hp').val(result?.row?.mb_hp);
              $('#not_mem').prop('checked', true);
            } else {
              $('#mem_info').html(result?.row?.mb_name + " / " + result?.row?.mb_hp);
            }
          }

          /*
          mb_no: $('#mb_no').val(),
            auth_code: $('#auth_code').val(),
            branch_code: $('#branch_code').val(),
            content: content,
            not_mem_name: $('#not_mem_name').val(),
            not_mem_hp: $('#not_mem_hp').val(),
            take_YN: "N" // TODO
          */
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/branchop/strange/search/ready?key=${encodeURIComponent($('#modal_key').val())}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
          setModalPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function goSubmit() {
    if ($('#not_mem').prop('checked')) {
      if (!$('#not_mem_name').val()) {
        alert('이상사용자 성명을 입력해주세요');
        return;
      }
      if (!$('#not_mem_hp').val()) {
        alert('이상사용자 연락처를 입력해주세요');
        return;
      }
    } else {
      if (!$('#mb_no').val()) {
        alert('이상사용자를 검색해주세요');
        return;
      }
    }

    if ($('#auth_code').val() === '') {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
      return;
    }

    if (content === '' || content === '<p>&nbsp;</p>') {
      alert('내용을 입력해주세요');
      return;
    }


    const formData = new FormData();

    /*
        base_seq: baseSeq,
        mb_no: $('#mb_no').val(),
        auth_code: $('#auth_code').val(),
        branch_code: $('#branch_code').val(),
        content: content,
        not_mem_name: $('#not_mem_name').val(),
        not_mem_hp: $('#not_mem_hp').val(),
        take_YN: $('#take_YN option:selected').val() || '',
    */

    formData.append('userfile1', $('#userfile1')[0].files[0]);
    formData.append('userfile2', $('#userfile2')[0].files[0]);
    formData.append('userfile3', $('#userfile3')[0].files[0]);
    formData.append('userfile4', $('#userfile4')[0].files[0]);
    formData.append('base_seq', baseSeq);
    formData.append('mb_no', $('#mb_no').val());
    formData.append('auth_code', $('#auth_code').val());
    formData.append('branch_code', $('#branch_code').val());
    formData.append('content', content);
    formData.append('not_mem_name', $('#not_mem_name').val());
    formData.append('not_mem_hp', $('#not_mem_hp').val());
    formData.append('take_YN', $('#take_YN option:selected').val());

    axios2.postFormEx('/branchop/strange/write', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (code === 200) {
          if (data === 'auth_fail') {
            alert('등록한 본인만 수정이 가능합니다');
          } else if (data === 'update_ok') {
            alert('수정하였습니다');
            history.push("/branchop/strange/list");
          } else if (data === 'insert_ok') {
            alert('추가하였습니다');
            history.push("/branchop/strange/list");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }


  function search_member() {
    const mem_search = $("#mem_search").val();
    if (mem_search.length < 2) {
      alert("2글자 이상으로 검색해주세요");
      return;
    }

    setModalOpen(true);
  }

  function change_mem() {
    if ($('#not_mem').prop("checked")) {
      $('#mb_no').val('');
      $('#mem_search').val('');
      $('#mem_info').text('');
      $('#not_mem_show').css('display', 'flex');
    } else {
      $('#not_mem_show').hide();
    }
  }

  function copy_data1(mb_no, mb_id, mb_name, mb_hp) {
    $('#mb_no').val(mb_no);
    const mb_data = mb_name + " / " + mb_hp;
    $('#mem_info').html(mb_data);

    //비회원 정보 삭제
    $("#not_mem_name").val('');
    $("#not_mem_hp").val('');
    $('#not_mem').attr('checked', false);
    $('#not_mem_show').hide();

    setModalOpen(false);
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);

            const token = (localStorage.getItem("USER.token") || null);
            let headers = token ? {Authorization: "Bearer " + token, CurrentUri: window.location.pathname} : {CurrentUri: window.location.pathname};

            fetch(Constants.API_URL.replace("/api/", "") + `/common/editor/upload`, {
              method: "post",
              body: body,
              headers
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: Constants.API_URL.replace("/api/", "") + `${res.url}`
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 pb-2">
        <table className="view-rounded sub_view text-left">
          <colgroup>
            <col width="120px"/>
            <col width="*"/>
            <col width="120px"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>이상이용자</th>
            <td>
              <div className="d-flex">
                <div className="d-flex">
                  <input type="text" className="form-control" placeholder="성명/휴대폰" id="mem_search"/>
                  <button type="button" className="btn btn-sm btn-outline-point" onClick={() => search_member()}>검색</button>
                </div>
                <div className="form-check form-check-inline mx-2">
                  <span id="mem_info"/>
                  <input className="form-check-input" type="checkbox" name="inlineRadioOptions" id="not_mem" value="Y" onClick={() => change_mem()}/>
                  <label className="form-check-label" htmlFor="not_mem">비회원</label>
                </div>
              </div>
              <div id="not_mem_show" style={{display: "none", paddingTop: "5px"}}>
                <input type="text" name='not_mem_name' id="not_mem_name" className="form-control wid100 mr-1" placeholder="성명"/>
                <input type="text" name='not_mem_hp' id="not_mem_hp" className="form-control wid100" placeholder="연락처"/>
              </div>
            </td>
            <th>인증코드</th>
            <td>
              <div className="d-flex">
                <ChkAuthNum type={"locker_info_change"}/>
              </div>
            </td>
          </tr>
          <tr>
            <th>첨부파일1</th>
            <td>
              {result?.row?.userfile1_name &&
                <span style={{cursor: "pointer", paddingTop: "5px"}} onClick={() => Fn.download(Constants.API_URL + "/common/download?cd=attachment&type=ace_img&filename=" + encodeURIComponent(result?.row?.userfile1_name))}>{result?.row?.userfile1_name}</span>
              }
              <input type="file" name="userfile1" id="userfile1" className="form-control w-100"/>
            </td>
            <th>첨부파일2</th>
            <td>
              {result?.row?.userfile2_name &&
                <span style={{cursor: "pointer", paddingTop: "5px"}} onClick={() => Fn.download(Constants.API_URL + "/common/download?cd=attachment&type=ace_img&filename=" + encodeURIComponent(result?.row?.userfile2_name))}>{result?.row?.userfile2_name}</span>
              }
              <input type="file" name="userfile2" id="userfile2" className="form-control w-100"/>
            </td>
          </tr>
          <tr>
            <th>첨부파일3</th>
            <td>
              {result?.row?.userfile3_name &&
                <span style={{cursor: "pointer", paddingTop: "5px"}} onClick={() => Fn.download(Constants.API_URL + "/common/download?cd=attachment&type=ace_img&filename=" + encodeURIComponent(result?.row?.userfile3_name))}>{result?.row?.userfile3_name}</span>
              }
              <input type="file" name="userfile3" id="userfile3" className="form-control w-100"/>
            </td>
            <th>첨부파일4</th>
            <td>
              {result?.row?.userfile4_name &&
                <span style={{cursor: "pointer", paddingTop: "5px"}} onClick={() => Fn.download(Constants.API_URL + "/common/download?cd=attachment&type=ace_img&filename=" + encodeURIComponent(result?.row?.userfile4_name))}>{result?.row?.userfile4_name}</span>
              }
              <input type="file" name="userfile4" id="userfile4" className="form-control w-100"/>
            </td>
          </tr>
          {baseSeq &&
            <tr>
              <th>확인상태</th>
              <td colSpan={3}>
                <select className="form-select" id="take_YN" name="take_YN" style={{maxWidth: "120px"}}>
                  <option value="N">확인중</option>
                  <option value="Y">확인완료</option>
                </select>
              </td>
            </tr>
          }
          <tr>
            <td colSpan="4" className="p-2">
              <CKEditor editor={DecoupledEditor} data={content}
                        config={{
                          extraPlugins: [uploadPlugin]
                        }}
                        onReady={(editor) => {
                          editor.ui.getEditableElement().parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement()
                          );
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "350px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(data);
                        }}
              />
            </td>
          </tr>
        </table>
      </div>
      <div className="text-center my-4">
        <button type="button" className="btn btn-sm btn-point px-3" onClick={() => goSubmit()}>저장</button>
        <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.goBack()}>목록</button>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          $('#modal_key').val($("#mem_search").val());
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01_wide p_absolute_mid_pop">
            <div className="POP_title">
              <h5>
                <strong>이상이용자 검색</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="d-flex">
                <div className="input-group pr-1" style={{width: 'auto'}}>
                  <p className="p-1">검색어 : </p>
                  <input type="input" className="form-control" aria-describedby="button-addon2" id="modal_key"/>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal()}>
                    검색
                  </button>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-dark px-3">
                    엑셀저장
                  </button>
                </div>
              </div>

              <div className="text-center mt-3">
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="60px"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>No</th>
                    <th>카드번호</th>
                    <th>성명</th>
                    <th>연락처</th>
                    <th>시작일</th>
                    <th>종료일</th>
                  </tr>
                  {modalResult?.result?.map((row, i) =>
                    <tr>
                      <td>{modalPageInfo.totalArticles - modalPageInfo.articlesPerPage * (modalPageInfo.currentPage - 1) - i}</td>
                      <td style={{cursor: "pointer"}} onClick={() => copy_data1(row?.mb_no, row?.mb_id, row?.mb_name, row?.mb_hp)}>{row?.mb_id}</td>
                      <td style={{cursor: "pointer"}} onClick={() => copy_data1(row?.mb_no, row?.mb_id, row?.mb_name, row?.mb_hp)}>{row?.mb_name}</td>
                      <td>{Fn.hpShow(row?.mb_hp, modalPageInfo.totalArticles)}</td>
                      <td>{row?.start_date?.replace(/-/g, '.')?.substring(2, 10)}</td>
                      <td>{row?.end_date?.replace(/-/g, '.')?.substring(2, 10)}</td>
                    </tr>
                  )}
                </table>
              </div>
            </div>

            <nav className="p-3 mb-4 d-flex justify-content-center border-top">
              <Paging pageInfo={modalPageInfo} setCurrPage={page => setModalCurrPage(page)}/>
            </nav>

          </div>
        </div>
      </Modal>

      <input type='hidden' name='mb_no' id="mb_no"/>

    </CmnCard>
  );
};

export default BranchopStrangeWrite;
