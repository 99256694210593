import React, {useState, useEffect} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";


const FinanceCash = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [result, setResult] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('#sangho').val('');
    $('#biyong').val('');
    $('#sub_price').val('');
    $('#amt').val('');
    $('#etc').val('');

    load();
  }, [srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/finance/finance/cash/ready?ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_biyong=${$('#s_biyong option:selected').val() || ''}&s_ck_sangho=${$('#s_ck_sangho').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#biyong').html() === '') {
            let optionsHTML = '<option value="">계정 선택</option>';
            result?.qry?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.acc_no},${list_row.acc_name}">${list_row.acc_name}</option>`;
            });
            $('#biyong').html(optionsHTML);
          }
          if ($('#s_biyong').html() === '') {
            let optionsHTML = '<option value="">계정 선택</option>';
            result?.qry2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.acc_no}">${list_row.acc_name}</option>`;
            });
            $('#s_biyong').html(optionsHTML);
          }

          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const go_submit = () => {
    // reg_btn.innerHTML = "<a class=' btn btn-danger btn-sm'><span class='glyphicon glyphicon-pencil'></span>  지출 저장</a>";

    if (!$('#sangho').val()) {
      alert('상호 또는 거래처명을 입력해주세요');
      // reg_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='go_submit()'><span class='glyphicon glyphicon-pencil'></span>  지출 저장</a>";
      $('#sangho').focus();
      return;
    }

    if (!$('#biyong').val()) {
      alert('계정을 입력해주세요');
      // reg_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='go_submit()'><span class='glyphicon glyphicon-pencil'></span>  지출 저장</a>";
      $('#biyong').focus();
      return;
    }

    if (!$('#amt').val()) {
      alert('금액을 입력해주세요');
      // reg_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='go_submit()'><span class='glyphicon glyphicon-pencil'></span>  지출 저장</a>";
      $('#amt').focus();
      return;
    }
    if (!$('#auth_code').val()) {
      alert('인증 해주세요');
      // reg_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='go_submit()'><span class='glyphicon glyphicon-pencil'></span>  지출 저장</a>";
      $('#search_auth_code').focus();
      return;
    }

    axios2
      .postEx('/finance/finance/cash', {
        sangho: $('#sangho').val(),
        biyong: $('#biyong').val(),
        sub_price: $('#sub_price').val(),
        amt: $('#amt').val(),
        etc: $('#etc').val(),
        auth_code: $('#auth_code').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else {
          alert("등록되었습니다");
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  //3-1
  const del_data = (ckk_no) => {
    const authCode = $('#auth_code').val();

    if (!authCode) {
      alert('인증 해주세요');
      $('#search_auth_code').focus();
      return;
    }

    if (!window.confirm('해당 지출내역을 삭제하시겠습니까?')) return;

    axios2
      .postEx('/finance/finance/cash', {
        type: 'del',
        ckk_no: ckk_no,
        auth_code: authCode,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        alert("삭제되었습니다");
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  //4-1
  const [selectedValue, setSelectedValue] = useState('');
  const [subPriceOptions, setSubPriceOptions] = useState([]);

  const show_sub = (event) => {
    const value = event.target.value;
    const splitData = value.split(",");
    const acc = splitData[0];

    setSelectedValue(value);

    fetch('finance_cash_sub.php', {
      method: 'POST',
      body: `acc=${acc}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then((response) => response.text())
      .then((data) => {
        data = data.trim();
        setSubPriceOptions(data);
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };


  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 mb-4" style={{border: '1px solid #eee', borderRadius: '12px'}}>
        <div className="d-flex justify-content-between border-bottom px-3 p-2">
          <div className="mt-1"><span className="h6 bold700">비용지출 입력</span></div>
          <div>
            <button type="button" className="btn btn-sm btn-point mx-1" onClick={() => go_submit()}>지출저장</button>
          </div>
        </div>

        <div className="p-3">
          <table className="view-rounded sub_table_border text-center auto">
            <tr>
              <th>거래처</th>
              <td>
                <input type="text" className="form-control" id="sangho"/>
              </td>
              <th>계정과목</th>
              <td>
                <div className="d-flex">
                  <select className="form-select" id="biyong">
                  </select>
                  <select className="form-select ml-1" id="sub_price">
                    <option value="">지출세부</option>
                  </select>
                </div>
              </td>
              <th>지출금액</th>
              <td>
                <input type="text" className="form-control" id="amt"/>
              </td>
            </tr>
            <tr>
              <th>메모</th>
              <td colSpan="3">
                <input type="text" className="form-control" id="etc"/>
              </td>
              <th>인증</th>
              <td>
                <div className="d-flex mx-1">
                  <ChkAuthNum type={"locker_info_change"}/>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="ddate" onChange={(e) => Fn.period(e.target.value)}>
            <option value="0">검색기간</option>
            <option value="1">오늘</option>
            <option value="2">이번달</option>
            <option value="3">전월</option>
            <option value="4">1분기</option>
            <option value="5">2분기</option>
            <option value="6">1,2분기</option>
            <option value="7">3분기</option>
            <option value="8">4분기</option>
            <option value="9">3,4분기</option>
            <option value="10">올해전체</option>
            <option value="11">전1년</option>
          </select>
        </div>
        <div className="d-flex pr-1">
          <JDatePicker className="form-control" id="s_date1"/>
          <p className="mx-2">~</p>
          <JDatePicker className="form-control" id="s_date2"/>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_biyong">
          </select>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" placeholder="거래처 입력" id="s_ck_sangho"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3 pb-2">
        <table className="view-rounded sub_table_border auto">
          <tr>
            <th className="w_70px">No</th>
            <th>일자</th>
            <th>거래처</th>
            <th>계정과목</th>
            <th>지출세부</th>
            <th>지출금액</th>
            <th>메모</th>
            <th>등록자</th>
            <th>삭제</th>
          </tr>

          {result?.result?.map((row, count) => {
            count++;

            return (
              <tr key={count}>
                <td>{count}</td>
                <td>{row.ckk_date}</td>
                <td className="text-left">{row.ckk_sangho}</td>
                <td className="text-left">{row.ckk_acname}</td>
                <td className="text-left">{row.sub_price}</td>
                <td className="text-right">{new Intl.NumberFormat().format(row.ckk_amt)}</td>
                <td className="text-left">{row.ckk_etc}</td>
                <td>{row.ckk_step}</td>
                <td>
                  {row.ckk_date === Fn.getCurrentDate() && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7 ? (
                    <button type="button" className="btn btn-ssm btn-outline-dark"
                            onClick={() => del_data(row?.ckk_no)}
                    >
                      삭제
                    </button>
                  ) : (
                    <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => alert('삭제는 당일건에 한해 지점장 이상 권한 사용자만 가능합니다')}>
                      삭제
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
          <tr className="bold700 bgcolor">
            <td colSpan={5}><strong>소 계</strong></td>
            <td className="text-right"><strong>{Fn.numberWithCommas(result?.total_price)}</strong></td>
            <td colSpan={3}></td>
          </tr>
        </table>
      </div>
    </CmnCard>
  );
};

export default FinanceCash;
