import React, {useEffect, useState} from 'react'
import NewWindow from 'react-new-window'
import axios2 from "../helpers/APIHelper";
import $ from 'jquery';
import Fn from "../libraries/Fn";
import {useHistory} from "react-router-dom";

export const EntranceWin = (props) => {
  const [result, setResult] = useState({});
  const [renew, setRenew] = useState(0);

  const history = useHistory();

  useEffect(() => {
    if (renew <= 0) return;
    load();
  }, [renew])

  function showData(mb_no) {
    history.push({
      pathname: '/member/view/detail', state: {
        mbNo: mb_no,
      }
    });
    clearInterval(window.entwin_intval);
  }

  const load = () => {
    axios2
      .post(`/enter/enter/monitor/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          console.log(result);
        }
      })
      .catch((error) => {
        alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  if (props.isOpen) {
    return (
      <NewWindow
        name="ent_win"
        title={"입장내역"}
        features={{popup: "yes", width: "700px", height: "760px", left: ($(window).width() / 2 - 710 / 2), top: ($(window).height() / 2 - 785 / 2) + 'px', scrollbars: "no"}}
        onOpen={() => {
          load();
          window.entwin_intval = setInterval(() => load(), 30000);
        }}
        onUnload={() => {
          clearInterval(window.entwin_intval);
          props?.onClose();
        }}
      >
        <div className="pop_box01 p_absolute_mid_top" style={{width: "700px", marginTop: 0, paddingTop: "25px"}}>
          <div className="POP_title">
            <h5><strong>입장내역</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => {
                clearInterval(window.entwin_intval);
                props?.onClose();
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <div className="bg_black">
              <div className="dash_area">
                <div className="dash_title">
                  <i className="fa fa-users" aria-hidden="true"></i> <strong>{result?.branchData?.branch_name}</strong> 이용회원 수
                </div>
                <div className="dash_chart_wrap">
                  <div className="dash_chart_no">
                    <div className="dash_chart_no_a" style={{left: "33%"}}></div>
                    <div className="dash_chart_no_b" style={{left: "66%"}}></div>
                  </div>
                  <div className="dash_chart_area dash_chart_area_height">
                    <div className="dash_chart_bar dash_chart_bar_color"><span className="dash_chart_point" style={{left: (result?.hr || 0) + "%"}}>{result?.enter_num}명</span></div>
                    <span className="dash_chart_label1">쾌적</span>
                    <span className="dash_chart_label2">혼잡</span>
                  </div>
                </div>
              </div>
            </div>

            <p className="text-right mb-3">
              {/*<button type="button" className="btn btn-sm btn-point px-2" onClick={() => setRenew(Fn.getRenewTime())}> 새로고침</button>*/}
            </p>
            <table className="view-rounded sub_table_border">

              <tr>
                <th>일시</th>
                <th>회원명</th>
                <th>구분</th>
                <th>운동복</th>
                <th>주센터</th>
              </tr>

              {result?.list?.map((listRow, i) => {

                  let cc = "#ffffff", err_type = listRow?.err_type;

                  if (err_type == 0) {
                    cc = "#ffffff";
                  } else if (err_type == 1) {
                    cc = "#fee9f0";
                  } else if (err_type == 2) {
                    cc = "#fee9f0";
                  } else if (err_type == 3) {
                    cc = "#fee9f0";
                  } else if (err_type == 4) {
                    cc = "#fee9f0";
                  } else if (err_type == 5) {
                    cc = "#fee9f0";
                  } else if (err_type == 6) {
                    cc = "#ffffff";
                  } else if (err_type == 8) {
                    cc = "#fee9f0";
                  } else if (err_type == 9) {
                    cc = "#fee9f0";
                  } else if (err_type == 10) {
                    cc = "#fee9f0";
                  } else if (err_type == 11) {
                    cc = "#fee9f0";
                  } else {
                    cc = "#ffffff";
                  }

                  return (
                    <tr style={{backgroundColor: cc, pointerEvents: localStorage.getItem("USER.systemBranchCode") !== listRow.won_gr_id ? "none" : "unset"}}>
                      <td className="text-center">{`${new Date().getMonth() + 1}.${new Date().getDate()}`} {listRow?.ba_time}</td>
                      <td className="text-center">
                        <a style={{cursor: "pointer"}} onClick={() => showData(listRow?.ba_mb_no)}>
                          {listRow?.ba_mb_name?.substring(0, 6)}
                        </a>
                      </td>
                      <td className="text-center">{listRow?.errTypeShow}</td>
                      <td className="text-center">{listRow?.suit}</td>
                      <td className="text-center">{listRow.won_gr_name.substring(0, 8)}</td>
                    </tr>
                  );
                }
              )}
            </table>
          </div>
          <div id="pop-overlay">
            <div className="pop-cv-spinner">
              <span className="pop-spinner"></span>
            </div>
          </div>
        </div>
      </NewWindow>);
  } else {
    return null;
  }
}
