import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const BranchopPermitList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [tabType, setTabType] = useState('period');
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 3);

    $('#a1_s_date2, #a2_s_date2').val(Fn.getDate(currentDate));
    $('#a1_s_date1, #a2_s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  const onTab = (type) => {
    $('[id^=achk]').prop("checked", false);

    setTabType(type);
    setSrchRenew(Fn.getRenewTime());

    setTimeout(() => {
      if (type === 'period') {
        $('#aa').prop('checked', true);
        $('#bb').prop('checked', false);
      } else {
        $('#aa').prop('checked', false);
        $('#bb').prop('checked', true);
      }

      $('.fn_search_box').each(function () {
        $(this).find("input[type=text]").each(function () {
          $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
        });
      });
    }, 150);
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(tabType, 1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load(tabType);
  }, [currPage, renew]);

  const load = (type, _page) => {
    let url = `/branchop/permit/period/list?s_mb_name=${$('#a1_s_mb_name').val()}&s_mb_hp=${$('#a1_s_mb_hp').val()}&s_date1=${$('#a1_s_date1').val()}&s_date2=${$('#a1_s_date2').val()}&e_date1=${$('#a1_e_date1').val()}&e_date2=${$('#a1_e_date2').val()}&s_permit_YN=${$('#a1_s_permit_YN option:selected').val() || ''}&page=${_page || currPage}`;
    if (type === 'service') url = `/branchop/permit/service/list?s_mb_name=${$('#a2_s_mb_name').val()}&s_mb_hp=${$('#a2_s_mb_hp').val()}&s_date1=${$('#a2_s_date1').val()}&s_date2=${$('#a2_s_date2').val()}&e_date1=${$('#a2_e_date1').val()}&e_date2=${$('#a2_e_date2').val()}&s_permit_YN=${$('#a2_s_permit_YN option:selected').val() || ''}&page=${_page || currPage}`;

    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        setPageInfo(pageInfo);
        if (code === 200) {
          setRets(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const delItem = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 데이터를 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx(tabType === 'period' ? '/branchop/permit/period/del' : '/branchop/permit/service/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  function permit_take(base_seq) {
    if (!window.confirm('해당 승인요청건을 승인처리 하시겠습니까?')) return;

    $.ajax({
      type: "post",
      url: "/branchop/permit/period",
      data: "base_seq=" + base_seq,
      success: function (data) {
        data = data?.message?.trim();
        if (data == "ok") {
          alert('승인처리되었습니다');
          setRenew(Fn.getRenewTime());
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      }
    });
  }


  function take_return(type, base_seq) {
    if (!window.confirm('해당 승인요청건을 반려처리 하시겠습니까?')) return;

    $.ajax({
      type: "post",
      url: "/branchop/return/period",
      data: "type=" + type + "&base_seq=" + base_seq,
      success: function (data) {
        data = data?.message?.trim();
        if (data == "ok") {
          alert('반려처리되었습니다');
          setRenew(Fn.getRenewTime());
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      }
    });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item" checked={tabType === 'period'}/>
          <label className="tab_item" htmlFor="aa" onClick={() => onTab('period')}>기간변경</label>
          <input id="bb" type="radio" name="tab_item" checked={tabType === 'service'}/>
          <label className="tab_item" htmlFor="bb" onClick={() => onTab('service')}>무상서비스</label>
        </div>
      </div>

      {tabType === 'period' ?
        <div className="overflowX">
          <div className="d-flex m-3 table_wide_sh2 fn_search_box">
            <div className="pr-1">
              <input type="text" className="form-control" placeholder="이름 검색" id="a1_s_mb_name"/>
            </div>
            <div className="pr-1">
              <input type="text" className="form-control" placeholder="연락처 검색" id="a1_s_mb_hp"/>
            </div>
            <div className="d-flex pr-1 justify-content-center align-content-center align-items-center">
              <span className="p-1">요청일 :</span>
              <JDatePicker className="form-control" id="a1_s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" id="a1_s_date2"/>
            </div>
            <div className="d-flex pr-1 justify-content-center align-content-center align-items-center">
              <span className="p-1">승인일 :</span>
              <JDatePicker className="form-control" id="a1_e_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" id="a1_e_date2"/>
            </div>
            <div className="pr-1 justify-content-center align-content-center align-items-center">
              <span className="p-1">승인여부 :</span>
              <select className="form-select" id="a1_s_permit_YN">
                <option value="N" selected>미승인</option>
                <option value="Y">승인</option>
                <option value="R">반려</option>
              </select>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
          </div>

          <div className="m-3 pb-2 table_wide_sh2 table_list">
            <table className="view-rounded sub_table_border auto">
              <tr>
                <th className="w_70px">No</th>
                <th className="w_70px">
                  <input type="checkbox" onClick={(e) => {
                    if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                    else $('[id^=achk]').prop("checked", false);
                  }}/>
                </th>
                <th>요청일</th>
                <th>카드번호</th>
                <th>성명</th>
                <th>연락처</th>
                <th>구분</th>
                <th className="w-20">승인요청사항</th>
                <th>변경사유</th>
                <th>요청자</th>
                <th>승인일</th>
                <th>승인</th>
                <th>반려</th>
              </tr>
              {rets && rets.map((ret, i) => {

                let type_txt;

                let mb_hp_show = Fn.hpShow(ret?.mb_hp, pageInfo?.totalArticles);

                switch (ret?.type) {
                  case "health":
                    type_txt = "이용기간";
                    break;
                  case "suit":
                    type_txt = "운동복";
                    break;
                  case "locker":
                    type_txt = "라커";
                    break;
                  case "g_locker":
                    type_txt = "골프라커";
                    break;
                  case "golf":
                    type_txt = "골프";
                    break;
                  case "sque":
                    type_txt = "스쿼시";
                    break;
                  case "spin":
                    type_txt = "스피닝";
                    break;
                  case "tae":
                    type_txt = "태닝";
                    break;
                  case "pila":
                    type_txt = "필라테스";
                    break;
                  default:
                    type_txt = "";
                    break;
                }

                return (
                  <tr>
                    <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                    <td>
                      <input
                        type="checkbox"
                        // name={`chk[${i + 1}]`}
                        name="chk"
                        value={btoa(ret?.seq)}
                        className="checkbox"
                        id={`achk${i + 1}`}
                      />
                    </td>
                    <td>{ret?.request_date}</td>
                    <td>{ret?.mb_id}</td>
                    <td>{ret?.mb_name}</td>
                    <td>{mb_hp_show}</td>
                    <td>{type_txt}</td>
                    <td>
                      {`${ret?.descr}`}
                    </td>
                    <td>{ret?.change_reason}</td>
                    <td>{ret?.request_name}</td>
                    <td>{ret?.permit_date?.slice(2, 10)?.replace(/-/g, ".")}</td>
                    <td>
                      {ret?.permit_YN === "N" && (
                        Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7 ? <button type="button" className="btn btn-ssm btn-outline-dark px-2" onClick={() => permit_take(btoa(ret?.seq))}>승인</button> : null
                      )}
                    </td>
                    <td>
                      {ret?.permit_YN === "N" && (
                        Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7 ? <button type="button" className="btn btn-ssm btn-outline-dark px-2" onClick={() => take_return('1', btoa(ret?.seq))}>반려</button> : null
                      )}
                    </td>
                  </tr>
                );
              })}
              {(!rets || rets?.length === 0) &&
                <tr>
                  <td colSpan={13}>※ 등록된 data가 없습니다.</td>
                </tr>
              }
            </table>
          </div>
        </div>
        :
        <div className="overflowX">
          <div className="d-flex m-3 table_wide_sh2 fn_search_box">
            <div className="pr-1">
              <input type="text" className="form-control" placeholder="이름 검색" id="a2_s_mb_name"/>
            </div>
            <div className="pr-1">
              <input type="text" className="form-control" placeholder="연락처 검색" id="a2_s_mb_hp"/>
            </div>
            <div className="d-flex pr-1 justify-content-center align-content-center align-items-center">
              <span className="p-1">요청일 :</span>
              <JDatePicker className="form-control" id="a2_s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" id="a2_s_date2"/>
            </div>
            <div className="d-flex pr-1 justify-content-center align-content-center align-items-center">
              <span className="p-1">승인일 :</span>
              <JDatePicker className="form-control" id="a2_e_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" id="a2_e_date2"/>
            </div>
            <div className="pr-1 justify-content-center align-content-center align-items-center">
              <span className="p-1">승인여부 :</span>
              <select className="form-select" id="a2_s_permit_YN">
                <option value="N" selected>미승인</option>
                <option value="Y">승인</option>
                <option value="R">반려</option>
              </select>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
          </div>

          <div className="m-3 pb-2 table_wide_sh2 table_list">
            <table className="view-rounded sub_table_border auto">
              <tr>
                <th className="w_70px">No</th>
                <th className="w_70px">
                  <input type="checkbox" onClick={(e) => {
                    if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                    else $('[id^=achk]').prop("checked", false);
                  }}/>
                </th>
                <th>요청일</th>
                <th>카드번호</th>
                <th>성명</th>
                <th>연락처</th>
                <th className="w-20">승인요청사항</th>
                <th>사유</th>
                <th>요청자</th>
                <th>승인일</th>
                <th>승인</th>
                <th>반려</th>
              </tr>
              {rets && rets.map((ret, i) => {

                let type_txt;

                let mb_hp_show = Fn.hpShow(ret?.mb_hp, pageInfo?.totalArticles);

                switch (ret?.type) {
                  case "health":
                    type_txt = "이용기간";
                    break;
                  case "suit":
                    type_txt = "운동복";
                    break;
                  case "locker":
                    type_txt = "라커";
                    break;
                  case "g_locker":
                    type_txt = "골프라커";
                    break;
                  default:
                    type_txt = "";
                    break;
                }

                return (
                  <tr>
                    <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                    <td>
                      <input
                        type="checkbox"
                        // name={`chk[${i + 1}]`}
                        name="chk"
                        value={btoa(ret?.seq)}
                        className="checkbox"
                        id={`achk${i + 1}`}
                      />
                    </td>
                    <td>{ret?.request_date}</td>
                    <td>{ret?.mb_id}</td>
                    <td>{ret?.mb_name}</td>
                    <td>{mb_hp_show}</td>
                    <td>{ret?.descr}</td>
                    <td>{ret?.change_reason}</td>
                    <td>{ret?.request_name}</td>
                    <td>{ret?.permit_date?.slice(2, 10)?.replace(/-/g, ".")}</td>
                    <td>
                      {ret?.permit1_YN === "N" && (
                        ret?.permit_depth === "2" ? (
                          (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7) && (
                            <a
                              className="btn btn-danger btn-xs"
                              href="#1"
                              onClick={() => {
                                if (window.confirm('해당 승인요청건을 승인처리 하시겠습니까?')) {
                                  // take_permit('1', base_seq);
                                }
                              }}
                            >
                              <button type="button" className="btn btn-ssm btn-outline-dark px-2">승인</button>
                            </a>
                          )
                        ) : (
                          (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7) && (
                            <a
                              className="btn btn-danger btn-xs"
                              href="#1"
                              onClick={() => {
                                if (window.confirm('해당 승인요청건을 승인처리 하시겠습니까?')) {
                                  // take_permit('1', base_seq);
                                }
                              }}
                            >
                              <button type="button" className="btn btn-ssm btn-outline-dark px-2">승인</button>
                            </a>
                          )
                        )
                      )}
                    </td>
                    <td>
                      {ret?.permit_YN === "N" && (
                        Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7 ? <button type="button" className="btn btn-ssm btn-outline-dark px-2">반려</button> : null
                      )}
                    </td>
                  </tr>
                );
              })}
              {(!rets || rets?.length === 0) &&
                <tr>
                  <td colSpan={13}>※ 등록된 data가 없습니다.</td>
                </tr>
              }
            </table>
          </div>
        </div>
      }

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => delItem()}>삭제</button>
        </div>
      </nav>
    </CmnCard>
  );
};

export default BranchopPermitList;
