import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";

import axios from "axios";
import Fn from "../../libraries/Fn";
import {useHistory} from "react-router-dom";

const BranchopStrangeList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=chk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/branchop/strange/list/ready?part=&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const allDel = () => {
    let con = $('[id^=chk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 게시물을 삭제하시겠습니까?')) {
      let delKey = $('[id^=chk]:checked').map(function () {
        return $(this).val();
      }).get().join("|");

      axios2
        .post(`/branchop/strange/del?del_key=${encodeURIComponent(delKey)}`)
        .then((response) => {
          setRenew(Fn.getRenewTime());
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_branch_code">
          </select>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" placeholder="제목 검색" id="key"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.push("/branchop/strange/write")}>등록</button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border auto">
          <tr>
            <th className="w_70px"><input type="checkbox" onClick={(e) => {
              if ($(e.target).prop("checked")) $('[id^=chk]').prop("checked", true);
              else $('[id^=chk]').prop("checked", false);
            }}/></th>
            <th className="w_70px">No</th>
            <th>이상이용자</th>
            <th>사용자 주센터</th>
            <th>신고자</th>
            <th>신고센터</th>
            <th>신고일</th>
            <th>확인상태</th>
          </tr>
          {rets && rets.map((ret, i) => {

            let take_YN_show;
            if (ret?.take_YN === "N") {
              take_YN_show = <span style={{color: 'red'}}>확인중</span>;
            } else if (ret?.take_YN === "Y") {
              take_YN_show = <span style={{color: 'blue'}}>확인완료</span>;
            } else {
              take_YN_show = "";
            }

            return (
              <tr>
                <td>
                  {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 9 &&
                    <input
                      type="checkbox"
                      name="chk"
                      value={btoa(ret?.seq)}
                      className="checkbox"
                      id={`chk${i + 1}`}
                    />
                  }
                </td>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    history.push({
                      pathname: '/branchop/strange/write', state: {
                        baseSeq: btoa(ret?.seq)
                      }
                    });
                  }}
                >{ret?.mb_name}</td>
                <td>{ret?.mb_branch_name}</td>
                <td>{ret?.writer_name}</td>
                <td>{ret?.real_branch_name}</td>
                <td>{(ret?.reg_date || '').replace(/-/g, '.').substr(2, 8)}</td>
                <td>{take_YN_show}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={8}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => allDel()}>삭제</button>
        </div>
      </nav>

    </CmnCard>
  );
};

export default BranchopStrangeList;
