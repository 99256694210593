import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import JDatePicker from "../common/JDatePicker";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";
import Constants from "../../constants/constants";

const AnalysisMonthlyPt = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [row, setRow] = useState({});

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split('-')[0])
    $('#s_month').val(Fn.getCurrentDate().split('-')[1])
  }, []);

  useEffect(() => {
    load();
  }, [renew, srchRenew]);

  const load = () => {
    axios2
      .post(`/analysis/monthly/pt/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRow(result?.row);
          console.log(result?.row);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const go_submit = () => {
    const formData = new FormData();

    formData.append('userfile', $('#userfile')[0].files[0]);
    formData.append('type', 'pt');
    formData.append('s_year', $('#s_year option:selected').val() || '');
    formData.append('s_month', $('#s_month option:selected').val() || '');

    axios2.postFormEx('/analysis/monthly/public', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (code === 200) {
          if (data == "err") {
            alert('오류가 발생하였습니다');
          } else if (data == "not_img_auth") {
            alert("확장자가 gif,jpg,png 파일만 가능합니다");
          } else {
            alert("등록되었습니다");
            // TOOD : 차후 리로드로 수정할 것
            history.go(0);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/analysis/monthly/member")}>월간등록현황</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/analysis/monthly/finance/new")}>월계표 비교분석</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/analysis/monthly/coupon")}>쿠폰발송내역</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/analysis/monthly/tm")}>TM 현황보고</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/analysis/monthly/region")}>지역분류</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/analysis/monthly/public")}>경쟁업체(퍼블릭)</label>

          <input id="gg" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/analysis/monthly/pt")}>경쟁업체(PT)</label>

          <input id="hh" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/analysis/monthly/analysis/pt")}>PT월간등록현황</label>
        </div>
      </div>

      <div className="">
        <div className="d-flex justify-content-between m-3">
          <div className="d-flex pr-1" style={{width: 'auto'}}>
            <div className="pr-1">
              <select className="form-select" id="s_year">
                {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                  <option key={year} value={year}>{year}년</option>
                ))}
              </select>
            </div>
            <div className="pr-1">
              <select className="form-select" style={{minWidth: "80px"}} id="s_month">
                {[...Array(12)].map((_, index) => {
                  const monthNumber = index + 1;
                  const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                  return (
                    <option key={monthString} value={monthString}>{monthString}</option>
                  );
                })}
              </select>
            </div>
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>

          <div className="text-left d-flex">
            <p className="p-1">(Width : 1160Px 미만, jpg,gif,png 파일)</p>
            <div className="d-flex pr-1">
              <input type="file" className="form-control" id="userfile"/>
            </div>
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>등록</button>
          </div>
        </div>
        <p className={`m-3 my-4 ${row?.img_name ? "text-left" : "text-center"} table_box p-4`}>
          {!row?.img_name ?
            <span className="h7 bold500">등록된 데이터가 없습니다.</span>
            :
            <img src={Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=monthly_img&filename=" + encodeURIComponent(row?.img_name))}/>
          }
        </p>
      </div>

    </CmnCard>
  );
};

export default AnalysisMonthlyPt;
