import React, {useState, useRef, useEffect} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";
import Modal from "react-modal";

const AnalysisMonthlyMember = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [result, setResult] = useState({});
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [basicDate, setBasicDate] = useState("");
  const [last6Months, setLast6Months] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split('-')[0]);
    $('#s_month').val(Fn.getCurrentDate().split('-')[1]);
  }, []);

  useEffect(() => {
    load();
  }, [renew, srchRenew]);

  const load = () => {
    axios2
      .post(`/analysis/monthly/analysis/member/ready?s_year=${$('#s_year option:selected').val()}&s_month=${$('#s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          const basicDate = new Date(Fn.parseInt($('#s_year option:selected').val()), Fn.parseInt($('#s_month option:selected').val()) - 1, 1);
          const _basicDate = $('#s_year option:selected').val() + "-" + $('#s_month option:selected').val() + "-01";
          setBasicDate(_basicDate);

          const last6Months = [];
          last6Months.push(_basicDate.substring(0, 7).replace(/-/g, "."));
          for (let k = 1; k <= 6; k++) {
            const newDate = new Date(basicDate.getFullYear(), basicDate.getMonth() - k, 1);
            const formattedDate = `${newDate.getFullYear()}.${String(newDate.getMonth() + 1).padStart(2, '0')}`;
            last6Months.push(formattedDate);
          }
          setLast6Months(last6Months);

          console.log(result);
          setResult(result);
        }
      })
      .catch((error) => {
        console.error(error)
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/analysis/make/data/monthly/ready?s_year=${$('#modal_s_year option:selected').val()}&s_month=${$('#modal_s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function make_data(branchCode, branchName) {
    const sYear = $('#modal_s_year option:selected').val();
    const sMonth = $('#modal_s_month option:selected').val();

    const txt = `${branchName}지점의 ${sYear}년 ${sMonth}월 데이터를 생성하시겠습니까?`;

    if (window.confirm(txt)) {
      if (!$("#overlay").is(":visible"))
        Fn.showSpinner();

      axios2.postEx("/analysis/make/data/monthly", {
        s_year: sYear,
        s_month: sMonth,
        branch_code: branchCode
      })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          console.log(data, result);
          alert('생성되었습니다');
          setRenew(Fn.getRenewTime());
          loadModal();
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        })
        .finally(() => {
          // $(".loading_bg").fadeOut("fast");
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/analysis/monthly/member")}>월간등록현황</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/analysis/monthly/finance/new")}>월계표 비교분석</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/analysis/monthly/coupon")}>쿠폰발송내역</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/analysis/monthly/tm")}>TM 현황보고</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/analysis/monthly/region")}>지역분류</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/analysis/monthly/public")}>경쟁업체(퍼블릭)</label>

          <input id="gg" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/analysis/monthly/pt")}>경쟁업체(PT)</label>

          <input id="hh" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/analysis/monthly/analysis/pt")}>PT월간등록현황</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 3}, (_, index) => new Date().getFullYear() - 2 + index).map((year) => (
              <option key={year} value={year}>{year}년</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: '80px'}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}월</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => setModalOpen(true)}>데이타 생성</button>
        </div>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>

            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th rowSpan="2">금월목표</th>
            <th>인원</th>
            <th>신규</th>
            <th>재등록</th>
            <th>PT목표매출</th>

            <th>PT달성률</th>
            <th>목표매출</th>
            <th>달성매출</th>
            <th>달성률</th>
          </tr>
          <tr>
            <td height="30">
              <span id="target_man">{Fn.numberWithCommas(result?.targetNewMan + result?.targetOldMan)}</span>
            </td>
            <td>
              <span id="target_man_new">{Fn.numberWithCommas(result?.targetNewMan)}</span>
            </td>
            <td>
              <span id="target_man_old">{Fn.numberWithCommas(result?.targetOldMan)}</span>
            </td>
            <td>
              <span id="target_pt_price">{Fn.numberWithCommas(result?.targetPtPrice)}</span>
            </td>
            <td className="bgcolor2">{Number(Number(result?.ptRate).toFixed(2))}%</td>
            <td>
              <span id="target_public_price">{Fn.numberWithCommas(result?.targetPublicPrice + result?.targetPtPrice)}</span>
            </td>
            <td className="bgcolor2">
              <span id="target_suip_price">{Fn.numberWithCommas(result?.suipPrice)}</span>
            </td>
            <td className="bgcolor2">
              <span id="public_rate">{isNaN(result?.publicRate) ? "" : Number(Number(result?.publicRate).toFixed(2))}%</span>
            </td>
          </tr>
          <tr>
            <th height="30" className="bgcolor bold700">금월 미납현황</th>
            <th className="bgcolor bold700">미납자수</th>
            <td><span id="misu_cnt">{Fn.numberWithCommas(result?.misuCnt)}</span></td>
            <th className="bgcolor bold700">미납금액</th>
            <td><span id="misu_price">{Fn.numberWithCommas(result?.misuPrice)}</span></td>
            <td colSpan="4">&nbsp;</td>
          </tr>
          <tr>
            <th height="30" className="bgcolor2 bold700">1인당 매출단가</th>
            <td colSpan="4"><strong style={{fontSize: "16px"}}><span id="man_danga">{Fn.numberWithCommas(Math.round(result?.manDanga))}</span></strong></td>
            <td colSpan="4">&nbsp;</td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <div className="d-flex p_rative">
          <div className="table_overlap1 overlap240_1" style={{zIndex: 1000}}>
            <table className="rounded-corner-l sub_table_border">
              <colgroup>
                <col width="*"/>
                <col width="*"/>
              </colgroup>
              <tr style={{minHeight: "88px", height: "88px", maxHeight: "88px"}}>
                <th>주차</th>
                <th>날짜</th>
              </tr>

              {result?.result?.map((row, index) => (
                <tr className="bgcolor bold700" style={{minHeight: "44px", height: "44px", maxHeight: "44px"}}>
                  <td colSpan="2">{row.start_date.substring(5, 10)?.replace(/-/g, ".")} ~ {row.end_date.substring(5, 10)?.replace(/-/g, ".")}</td>
                </tr>
              ))}
              {last6Months?.map((month, index) => (
                <tr className={`${index === 0 ? 'bgcolor' : 'bgcolor2'} bold700`} style={{minHeight: "44px", height: "44px", maxHeight: "44px"}}>
                  <td colSpan="2">{month}</td>
                </tr>
              ))}
            </table>
          </div>

          <div className="table_overlap2 overlap240_2" style={{minWidth: "min-content", paddingRight: "20px"}}>
            <table className="view-rounded sub_table_rowspan text-right auto" style={{width: '3500px'}}>
              <tr style={{minHeight: "44px", height: "44px", maxHeight: "44px"}}>
                <th rowSpan="2">토탈</th>
                <th rowSpan="2">신규</th>
                <th rowSpan="2">재등록</th>
                <th rowSpan="2">P.T</th>
                <th rowSpan="2">PT매출금</th>
                <th colSpan="2">매출</th>
                <th colSpan="2">물품판매</th>
                <th rowSpan="2">매출합계</th>
                <th colSpan="7">기간</th>
                <th colSpan="2">성별</th>
                <th colSpan="13">가입경로</th>
                <th colSpan="5">타임</th>
                <th colSpan="6">연령대</th>
              </tr>

              <tr style={{minHeight: "44px", height: "44px", maxHeight: "44px"}}>
                <th>현금+이체</th>
                <th>카드</th>
                <th>건수</th>
                <th>금액</th>
                <th>1개월</th>
                <th>2개월</th>
                <th>3개월</th>
                <th>5개월</th>
                <th>6개월</th>
                <th>1년</th>
                <th>프레</th>
                <th>남자</th>
                <th>여자</th>
                <th>TV</th>
                <th>간판</th>
                <th>현수막</th>
                <th>전봇대</th>
                <th>광고지</th>
                <th>소개</th>
                <th>인터넷</th>
                <th>신문</th>
                <th>문구</th>
                <th>판촉물</th>
                <th>인수C</th>
                <th>네이버</th>
                <th>기타</th>
                <th>오전</th>
                <th>오후</th>
                <th>심야</th>
                <th>주말</th>
                <th>공휴일</th>
                <th>10대</th>
                <th>20대</th>
                <th>30대</th>
                <th>40대</th>
                <th>50대</th>
                <th>60대 이상</th>
              </tr>

              {result?.result?.map((row, index) => (
                <tr key={index} style={{minHeight: "44px", height: "44px", maxHeight: "44px"}}>
                  <td className="bg_yellow1">{Fn.numberWithCommas(row?.new_man + row?.old_man)}</td>
                  <td className="bg_yellow1">{Fn.numberWithCommas(row?.new_man)}</td>
                  <td className="bg_yellow1 brd_right_1">{Fn.numberWithCommas(row?.old_man)}</td>
                  <td>{Fn.numberWithCommas(row?.pt_cnt)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(row?.pt_price)}</td>
                  <td className="bg_yellow1">{Fn.numberWithCommas(row?.cash_price)}</td>
                  <td className="bg_yellow1">{Fn.numberWithCommas(row?.card_price)}</td>
                  <td className="bg_yellow1" style={{background: '#f3f2ff'}}>{Fn.numberWithCommas(row?.sales_cnt)}</td>
                  <td className="bg_yellow1 brd_right_1" style={{background: '#f3f2ff'}}>{Fn.numberWithCommas(row?.sales_price)}</td>
                  <td className="brd_right_2">{Fn.numberWithCommas(row?.cash_price + row?.card_price + row?.sales_price)}</td>
                  <td>{Fn.numberWithCommas(row?.month1)}</td>
                  <td>{Fn.numberWithCommas(row?.month2)}</td>
                  <td>{Fn.numberWithCommas(row?.month3)}</td>
                  <td>{Fn.numberWithCommas(row?.month5)}</td>
                  <td>{Fn.numberWithCommas(row?.month6)}</td>
                  <td>{Fn.numberWithCommas(row?.month12)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(row?.month18)}</td>
                  <td>{Fn.numberWithCommas(row?.m_num)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(row?.f_num)}</td>
                  <td>{Fn.numberWithCommas(row?.join1)}</td>
                  <td>{Fn.numberWithCommas(row?.join2)}</td>
                  <td>{Fn.numberWithCommas(row?.join3)}</td>
                  <td>{Fn.numberWithCommas(row?.join4)}</td>
                  <td>{Fn.numberWithCommas(row?.join5)}</td>
                  <td>{Fn.numberWithCommas(row?.join6)}</td>
                  <td>{Fn.numberWithCommas(row?.join7)}</td>
                  <td>{Fn.numberWithCommas(row?.join8)}</td>
                  <td>{Fn.numberWithCommas(row?.join9)}</td>
                  <td>{Fn.numberWithCommas(row?.join10)}</td>
                  <td>{Fn.numberWithCommas(row?.join12)}</td>
                  <td>{Fn.numberWithCommas(row?.join13)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(row?.join11)}</td>
                  <td>{Fn.numberWithCommas(row?.time1)}</td>
                  <td>{Fn.numberWithCommas(row?.time2)}</td>
                  <td>{Fn.numberWithCommas(row?.time3)}</td>
                  <td>{Fn.numberWithCommas(row?.time4)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(row?.time5)}</td>
                  <td>{Fn.numberWithCommas(row?.age1)}</td>
                  <td>{Fn.numberWithCommas(row?.age2)}</td>
                  <td>{Fn.numberWithCommas(row?.age3)}</td>
                  <td>{Fn.numberWithCommas(row?.age4)}</td>
                  <td>{Fn.numberWithCommas(row?.age5)}</td>
                  <td>{Fn.numberWithCommas(row?.age6)}</td>
                </tr>
              ))}

              {last6Months?.map((month, index) => (
                <tr className={`${index === 0 ? 'bgcolor' : 'bgcolor2'} bold700`} style={{minHeight: "44px", height: "44px", maxHeight: "44px"}}>
                  <td className="bg_yellow1">{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.newMan + result?.result2Map?.[month.replace(".", "")]?.oldMan)}</td>
                  <td className="bg_yellow1">{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.newMan)}</td>
                  <td className="bg_yellow1 brd_right_1">{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.oldMan)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.ptCnt)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.ptPrice)}</td>
                  <td className="bg_yellow1">{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.cashPrice)}</td>
                  <td className="bg_yellow1">{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.cardPrice)}</td>
                  <td className="bg_yellow1" style={{background: '#f3f2ff'}}>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.salesCnt)}</td>
                  <td className="bg_yellow1 brd_right_1" style={{background: '#f3f2ff'}}>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.salesPrice)}</td>
                  <td className="brd_right_2">{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.cashPrice + result?.result2Map?.[month.replace(".", "")]?.cardPrice + result?.result2Map?.[month.replace(".", "")]?.salesPrice)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.month1)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.month2)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.month3)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.month5)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.month6)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.month12)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.month18)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.m_num)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.f_num)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join1)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join2)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join3)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join4)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join5)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join6)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join7)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join8)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join9)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join10)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join12)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join13)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.join11)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.time1)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.time2)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.time3)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.time4)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.time5)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.age1)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.age2)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.age3)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.age4)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.age5)}</td>
                  <td>{Fn.numberWithCommas(result?.result2Map?.[month.replace(".", "")]?.age6)}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          $('#modal_s_year').val(Fn.getCurrentDate().split('-')[0]);
          $('#modal_s_month').val(Fn.getCurrentDate().split('-')[1]);
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '800px'}}>
            <div className="POP_title">
              <h5><strong>데이터 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box">
              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select className="form-select" id="modal_s_year">
                    {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <div className="pr-1">
                  <select className="form-select" id="modal_s_month">
                    {[...Array(12)].map((_, index) => {
                      const monthNumber = index + 1;
                      const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                      return (
                        <option key={monthString} value={monthString}>{monthString}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal()}>검색</button>
                </div>
              </div>
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <thead>
                  <tr>
                    <th className="w_70px">No</th>
                    <th>지점명</th>
                    <th>최종 주</th>
                    <th>생성일시</th>
                    <th>생성</th>
                  </tr>
                  </thead>
                  <tbody>
                  {modalResult?.result && modalResult?.result?.map((ret, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ret?.branch_name}</td>
                        <td>{ret?.last_week || ''}</td>
                        <td>{ret?.last_date?.replace(/-/g, '.')?.substring(2, 16) || ''}</td>
                        <td>
                          <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => make_data(ret?.branch_code, ret?.branch_name)}>생성</button>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              <div className="text-center my-3">
                <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModalOpen(false)}>닫기</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </CmnCard>
  );
};

export default AnalysisMonthlyMember;
