import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import _ from "lodash";
import Fn from "../../libraries/Fn";

// 스크립트 없음

const SalesBranchPriceState = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [positionNames, setPositionNames] = useState([]);
  const [positionSeq, setPositionSeq] = useState([]);
  const [salesData, setSalesData] = useState([]);
  const [positionPrice, setPositionPrice] = useState({});
  const [myOrderPrice, setMyOrderPrice] = useState({});
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");

    $('#s_year').val(currentYear);
    $('#s_month').val('01');
    $('#e_year').val(currentYear);
    $('#e_month').val(currentMonth);
    }, []);

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/sales/branch/price/state/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&e_year=${$('#e_year option:selected').val() || ''}&e_month=${$('#e_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);

          setPositionNames(result?.list.map((item) => item.sub_code_name));
          setPositionSeq(result?.list.map((item) => item.seq));

          setSalesData(result?.result);
          setPositionPrice(result?.positionPriceMap);
          setMyOrderPrice(result?.myOrderPriceMap);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2">
          <div className="pr-1">
            <div className="d-flex" style={{width: 'auto'}}>
              <select className="form-select mr-1" aria-label="Default select example" id="s_year">
                {Array.from({ length: 4 }, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
              <select className="form-select" aria-label="Default select example" id="s_month">
                {[...Array(12)].map((_, index) => {
                  const monthNumber = index + 1;
                  const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                  return (
                    <option key={monthString} value={monthString}>{monthString}</option>
                  );
                })}
              </select>
              <p className="mx-2">~</p>
              <select className="form-select mr-1" aria-label="Default select example" id="e_year">
                {Array.from({ length: 4 }, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
              <select className="form-select" aria-label="Default select example" id="e_month">
                {[...Array(12)].map((_, index) => {
                  const monthNumber = index + 1;
                  const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                  return (
                    <option key={monthString} value={monthString}>{monthString}</option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 table_list_sh2">
          {/* ---- 회원목록 ---- */}
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="60px"/>
              <col width="*"/>
              {positionNames.map((name, index) => (
                <col width="*"/>
              ))}
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>랭킹</th>
              <th>지점명</th>
              {positionNames.map((name, index) => (
                <th key={positionSeq[index]}>{name}</th>
              ))}
              <th>매출합계</th>
              <th>발주금액</th>
              <th>순익</th>
            </tr>
            {salesData.map((row, index) => {
              const branchCode = row.branch_code;
              const salesPrice = row.sales_price || 0;
              const realPrice = (myOrderPrice[branchCode] || 0) - salesPrice;

              return (
                <tr key={branchCode}>
                  <td>{index + 1}</td>
                  <td>
                    <div style={{paddingLeft: "5px"}}>{row.branch_name}</div>
                  </td>
                  {positionSeq.map((seq) => (
                    <td key={seq}>
                      <div style={{paddingRight: "5px"}}>
                        {positionPrice[branchCode]?.[seq]?.toLocaleString()}
                      </div>
                    </td>
                  ))}
                  <td>
                    <div style={{paddingRight: "5px"}}>{salesPrice.toLocaleString()}</div>
                  </td>
                  <td style={{background: "#fedee7"}}>
                    <div style={{paddingRight: "5px"}}>{myOrderPrice[branchCode]?.toLocaleString()}</div>
                  </td>
                  <td style={{background: "#e9f1fe"}}>
                    <div style={{paddingRight: "5px"}}>{realPrice?.toLocaleString()}</div>
                  </td>
                </tr>
              );
            })}
          </table>
          );
        </div>
      </div>
    </CmnCard>
  );
};

export default SalesBranchPriceState;
