import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";

const MemberHistory = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/member/member/history/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_hp=${$('#s_mb_hp').val()}&s_hi_type=${$('#s_hi_type option:selected').val() || ''}&s_hi_step=${$('#s_hi_step').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);

          if ($('#s_hi_type').html() === '') {
            let optionsHTML = '<option value="">::구분::</option>';
            result?.c?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.hi_type}">${list_row.hi_type}</option>`;
            });
            $('#s_hi_type').html(optionsHTML);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = (hi_no) => {
    axios2
      .post(`/member/hi/bigo/ready?hi_no=${hi_no}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $('#hi_no').val(hi_no);
          $('#hi_bigo').val(message || '');
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const modal_submit = () => {
    axios2
      .postEx('/member/hi/bigo', {
        hi_no: $('#hi_no').val(),
        hi_bigo: $('#hi_bigo').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (data == "ok") {
          alert("등록되었습니다");
          setModalOpen(false);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1 d-flex">
          <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: "keep-all"}}>대상일 : </label>
          <div className="input-group" style={{width: "auto"}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control width_shorts" aria-describedby="button-addon2" placeholder="이름 검색" id="s_mb_name"/>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="연락처 검색" id="s_mb_hp"/>
        </div>
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{minWidth: "32%"}} id="s_hi_type">
          </select>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control width_shorts" aria-describedby="button-addon2" placeholder="스텝명 검색" id="s_hi_step"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3 mb-5 table_list">
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="80px"/>
            <col width="115px"/>
            <col width="90px"/>
            <col width="150px"/>
            <col width="90px"/>
            <col width="140px"/>
            <col width="*"/>
            <col width="90px"/>
            <col width="90px"/>
            <col width="130px"/>
            <col width="80px"/>
          </colgroup>
          <tr>
            <th>No</th>
            <th>대상일</th>
            <th>시간</th>
            <th>구분</th>
            <th>회원명</th>
            <th>연락처</th>
            <th>변경내용</th>
            <th>등록자</th>
            <th>접수자</th>
            <th>접속지</th>
            <th>비고</th>
          </tr>
          {rets && rets.map((ret, i) =>
            <tr>
              <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
              <td>{ret?.hi_date}</td>
              <td>{ret?.hi_time}</td>
              <td className="text-left">{ret?.hi_type}</td>
              <td>{ret?.mb_name || ret?.hi_mb_name}</td>
              <td>
                <div id={`default_show${i + 1}`}>***-****-****</div>
              </td>
              <td className="text-left"><div dangerouslySetInnerHTML={{__html: Fn.getNonNull(ret?.hi_etc)}}/></td>
              <td>{ret?.hi_step}</td>
              <td>{Fn.getNonNull(ret?.receive_name?.split('/')?.[1])}</td>
              <td>{ret?.reg_ip}</td>
              <td>
                <button type="button" className="btn btn-ssm btn-outline-dark px-2" onClick={() => {
                  setModalOpen(true);
                  loadModal(ret?.hi_no);
                }}>비고
                </button>
              </td>
            </tr>
          )}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={10}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>
      <nav className="p-3 mb-4 d-flex justify-content-between border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>
            {pageInfo?.totalArticles > 0 && (
              Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)
            ) && (
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('member_history')}>연락처 확인</button>
            )}
          </div>
        </div>
      </nav>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: "1000000000"}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: "720px"}}>
            <div className="POP_title">
              <h5><strong>히스토리 비고</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_view">
                <tr>
                  <th style={{width: "80px"}}>비고</th>
                  <td>
                    <input type="hidden" id="hi_no"/>
                    <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="비고 내용" id="hi_bigo"/>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => modal_submit()}>등록</button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>취소</button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default MemberHistory;
