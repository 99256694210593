import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import ChkAuthNum from "../common/ChkAuthNum";
import {useHistory} from "react-router-dom";
import Constants from "../../constants/constants";

const EmployeePTtraninerList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const history = useHistory();

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/employee/pt/trainer/list/ready?s_inout_state=${$('#s_inout_state option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.hp");

          setRets(result?.result);

          result?.result?.map((row, i) => $(`#show_out_${i}`).val(row?.show_out))

          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function change_state(base_seq, value) {
    if (value === true) {
      value = "Y";
    } else {
      value = "N";
    }

    axios2
      .post(`/employee/change/web/show?base_seq=${base_seq}&value=${value}`)
      .then((response) => {
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function change_state_pt(base_seq, value) {
    if (value === true) {
      value = "Y";
    } else {
      value = "N";
    }

    axios2
      .post(`/employee/change/pt/show?base_seq=${base_seq}&value=${value}`)
      .then((response) => {
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function show_change(show_out, base_seq) {
    axios2
      .post(`/employee/manager/show/change?base_seq=${base_seq}&show_out=${show_out}`)
      .then((response) => {
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflow-hidden">
        <div className="d-flex m-3 table_wide_sh fn_search_box">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_inout_state">
              <option value="">::상태::</option>
              <option value="Y">근무</option>
              <option value="N">퇴사</option>
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="이름/핸드폰 검색" id="key"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list overflowX">
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="60px"/>
              <col width="130px"/>
              <col width="100px"/>
              <col width="120px"/>
              <col width="100px"/>
              <col width="70px"/>
              <col width="150px"/>
              <col width="100px"/>
              <col width="140px"/>
              <col width="100px"/>
              <col width="60px"/>
              <col width="85px"/>
              <col width="100px"/>
              <col width="85px"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>사진</th>
              <th>성명</th>
              <th>직무구분</th>
              <th>근무형태</th>
              <th>PT여부</th>
              <th>부서</th>
              <th>직급</th>
              <th>연락처</th>
              <th>입사일</th>
              <th>상태</th>
              <th>지점정보</th>
              <th>트레이너매칭</th>
              <th>전시순서</th>
            </tr>

            {rets.map((ret, i) => {
              const {
                seq,
                user_name,
                intro_pic,
                position_name,
                work_type,
                pt_YN,
                part_name,
                new_position_name,
                hp,
                in_date,
                inout_state,
                web_show,
                pt_show,
                show_out,
              } = ret;

              const base_seq = btoa(seq);

              return (
                <tr key={i}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    {intro_pic ? (
                      <img src={Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=step_pr_img&filename=" + encodeURIComponent(intro_pic))} style={{objectFit: "cover", width: "90px", height: "90px", borderRadius: 8}}/>
                    ) : (
                      <p className="USER_thum_sm"></p>
                    )}
                  </td>
                  <td>
                    <a onClick={() => history.push({pathname: '/employee/pt/trainer/reg', state: {baseSeq: base_seq}})} style={{cursor: "pointer"}}>
                      <strong>{user_name}</strong>
                    </a>
                  </td>
                  <td>{position_name}</td>
                  <td>{work_type}</td>
                  <td>{pt_YN === 'Y' ? <strong>Y</strong> : 'N'}</td>
                  <td>{part_name}</td>
                  <td>{new_position_name}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{in_date.replace(/-/g, '.').slice(2, 10)}</td>
                  <td>
                    {inout_state === 'Y' ? '근무' : <font color="red">퇴사</font>}
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="web_show"
                      value="Y"
                      onClick={(e) => change_state(base_seq, e.target.checked)}
                      checked={ret?.web_show === 'Y'}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      name="pt_show"
                      value="Y"
                      onClick={(e) => change_state_pt(base_seq, e.target.checked)}
                      checked={ret?.pt_show === 'Y'}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      id={`show_out_${i}`}
                      className="form-control wid50"
                      onBlur={(e) => show_change(e.target.value, base_seq)}
                    />
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          <div className="d-flex">
            <div className="d-flex align-items-center pr-3">
              <ChkAuthNum type={"locker_info_change"}/>
              {pageInfo?.totalArticles > 0 && (
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                  (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                  (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                  (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                  <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('pt_trainer_list')}>연락처 확인</button>
                )
              )}
            </div>
          </div>
        </nav>

        <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
        <input type="hidden" id="tot_record" style={{display: "none"}}/>
        <input type="hidden" id="tot_sql" style={{display: "none"}}/>

      </div>
    </CmnCard>
  );
};

export default EmployeePTtraninerList;
