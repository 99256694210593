import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import Constants from "../../constants/constants";

// 스크립트 없음

const SalesStockState = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  window.count = 0;
  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  let tot_before_stock = 0;
  let tot_in_stock = 0;
  let tot_change_stock = 0;
  let tot_sales_stock = 0;
  let tot_now_stock = 0;

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
    $('#s_month').val(Fn.getCurrentDate().split("-")[1]);
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  // TODO : 합계 처리가 필요
  const load = (_page) => {
    axios2
      .post(`/sales/stock/state/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&s_pum_position=${$('#s_pum_position option:selected').val() || ''}&s_pum_code=${$('#s_pum_code option:selected').val() || ''}&s_stock_type=${$('#s_stock_type option:selected').val() || ''}&key=${$('#key').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_pum_position').html() === '') {
            let optionsHTML = '<option value="">::품목분류::</option>';
            result?.list1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_pum_position').html(optionsHTML);
          }
          if ($('#s_pum_code').html() === '') {
            let optionsHTML = '<option value="">::품목구분::</option>';
            result?.list2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_pum_code').html(optionsHTML);
          }

          setRets(result?.topRow);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const Excel_download = () => {
    const url = `/xls/sales/stock/state?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&s_pum_position=${$('#s_pum_position option:selected').val() || ''}&s_pum_code=${$('#s_pum_code option:selected').val() || ''}&s_stock_type=${$('#s_stock_type option:selected').val() || ''}&key=${$('#key').val()}`;
    Fn.download(Constants.API_URL + url);
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 fn_search_box">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_year">
              {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_month">
              {[...Array(12)].map((_, index) => {
                const monthNumber = index + 1;
                const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_pum_position">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_pum_code">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_stock_type">
              <option value=''>전체상품</option>
              <option value="Y">재고있는상품만</option>
              <option value="M">마이너스재고만</option>
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="상품명 입력" id="key"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => Excel_download()}>엑셀저장</button>
          </div>
        </div>

        <div className="m-3 table_list">
          {/* ---- 회원목록 ---- */}
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="60px"/>
              <col width="17%"/>
              <col width="10%"/>
              <col width="24%"/>
              <col width="*"/>

              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>No</th>
              <th>품목분류</th>
              <th>품목구분</th>
              <th>품명</th>
              <th>이전재고</th>

              <th>입고수량</th>
              <th>재고조정</th>
              <th>판매수량</th>
              <th>현재재고</th>
            </tr>
            {rets && rets.map((top_row, i) => {
              const {before_stock, in_stock, change_stock, sales_stock} = top_row;

              const now_stock = before_stock + in_stock + change_stock - Math.abs(sales_stock);

              let tr_style = '';
              let s_stock_type = $('#s_stock_type option:selected').val() || '';

              if (s_stock_type === 'Y') {
                if (now_stock !== 0) {
                  window.count++;
                  tr_style = '';

                  tot_before_stock += before_stock;
                  tot_in_stock += in_stock;
                  tot_change_stock += change_stock;
                  tot_sales_stock += sales_stock;
                  tot_now_stock += now_stock;
                } else {
                  tr_style = "none";
                }
              } else if (s_stock_type === 'M') {
                if (now_stock < 0) {
                  window.count++;
                  tr_style = '';

                  tot_before_stock += before_stock;
                  tot_in_stock += in_stock;
                  tot_change_stock += change_stock;
                  tot_sales_stock += sales_stock;
                  tot_now_stock += now_stock;
                } else {
                  tr_style = "none";
                }
              } else {
                window.count++;
                tr_style = '';

                tot_before_stock += before_stock;
                tot_in_stock += in_stock;
                tot_change_stock += change_stock;
                tot_sales_stock += sales_stock;
                tot_now_stock += now_stock;
              }

              return (
                <tr style={{display: tr_style}}>
                  <td>{window.count}</td>
                  <td>{top_row?.position_name}</td>
                  <td>{top_row?.pum_name}</td>
                  <td className="text-left">{top_row?.p_name}</td>
                  <td className="text-right">{Fn.numberWithCommas(before_stock)}</td>
                  <td className="text-right">{Fn.numberWithCommas(in_stock)}</td>
                  <td className="text-right">{Fn.numberWithCommas(change_stock)}</td>
                  <td className="text-right">{Fn.numberWithCommas(sales_stock)}</td>
                  <td className="text-right">{Fn.numberWithCommas(now_stock)}</td>
                </tr>);
            })}
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default SalesStockState;
