import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";
import Modal from "react-modal";
import Constants from "../../constants/constants";

const AnalysisMonthlyFinanceNew = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [result, setResult] = useState({});
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [type, setType] = useState('all');

  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState();

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#type').val('all');

    $('#s_year').val(Fn.getDate(monthsAgo).split('-')[0]);
    $('#s_month').val(Fn.getDate(monthsAgo).split('-')[1]);

    $('#e_year').val(Fn.getCurrentDate().split('-')[0]);
    $('#e_month').val(Fn.getCurrentDate().split('-')[1]);
  }, []);

  useEffect(() => {
    load();
  }, [renew, srchRenew]);

  let wi = 0;

  function changeType(type) {
    setType(type);
    $('#type').val(type);
    $('.cls-in, .cls-out').hide();
    if (type === 'all' || type === 'in') $('.cls-in').show();
    if (type === 'all' || type === 'out') $('.cls-out').show();
  }

  const load = () => {
    const strSYear = Fn.parseInt($('#s_year option:selected').val());
    const strSMonth = Fn.parseInt($('#s_month option:selected').val());
    const strEYear = Fn.parseInt($('#e_year option:selected').val());
    const strEMonth = Fn.parseInt($('#e_month option:selected').val());

    // const strSYear = '2022';
    // const strSMonth = '08';
    // const strEYear = '2022';
    // const strEMonth = '10';

    const sYear = Fn.parseInt(strSYear);
    const sMonth = Fn.parseInt(strSMonth);
    const eYear = Fn.parseInt(strEYear);
    const eMonth = Fn.parseInt(strEMonth);

    let gab = (eYear * 12 + eMonth) - (sYear * 12 + sMonth);

    if (gab > 12) {
      alert('최대 12개월만 가능합니다');
      gab = 12;
    }
    wi = (gab + 1) * 150;

    function show_data(data) {
      let new_data = "";
      if (data)
        new_data = Fn.numberWithCommas(data);
      else
        new_data = "&nbsp;"

      return new_data;
    }

    axios2
      .post(`/analysis/monthly/analysis/finance/new/ready?s_year=${$('#s_year option:selected').val()}&s_month=${$('#s_month option:selected').val()}&e_year=${$('#e_year option:selected').val()}&e_month=${$('#e_month option:selected').val()}&type=${type}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);

          console.log(result);
          $('#type').val(type);

          // $('#out_td').next('tr').remove();
          // $('#html_area').html('');

          {
            $('.outtd').remove();
            let tdResult = '';
            for (const fieldName of result?._tDesc2) {
              tdResult += '<tr class="outtd cls-out">';
              tdResult += '<td>' + fieldName + '</td>';
              tdResult += '</tr>';
            }
            $('#out_td').after(tdResult);
          }

          let htmlResult = '';
          {
            // basic months
            htmlResult += '<tr class="cls-in">';
            for (let i = 0; i <= gab; i++) {
              const currentDate = new Date(eYear, eMonth - 1);
              currentDate.setMonth(currentDate.getMonth() - i);

              const year = currentDate.getFullYear();
              const month = currentDate.getMonth() + 1;
              const formattedDate = year + '.' + (month < 10 ? '0' : '') + month;

              htmlResult += '<th colspan="2">' + formattedDate + '</th>';
            }
            htmlResult += '</tr>';

            // "순이익", "순이익율"
            let sunPriceRow = '<tr class="warning cls-in">';
            let sunRateRow = '<tr class="warning cls-in">';
            for (let i = 0; i <= gab; i++) {
              const currentDate = new Date(eYear, eMonth - 1);
              currentDate.setMonth(currentDate.getMonth() - i);

              const year = currentDate.getFullYear();
              const month = currentDate.getMonth() + 1;
              const basicMonth = year + (month < 10 ? '0' : '') + month;

              const sunPriceValue = result?.inData?.[basicMonth]?.sun_price;  // Calculate 순이익
              let sunRateValue = "&nbsp;"; // Calculate 순이익율
              if (result?.inData?.[basicMonth]?.in_price) {
                try {
                  let _sunRateValue = (result?.inData?.[basicMonth]?.sun_price / result?.inData?.[basicMonth]?.in_price) * 100;
                  sunRateValue = Number(Number(_sunRateValue).toFixed(2)) + "%";
                } catch (err) {
                }
              }

              sunPriceRow += '<td colspan="2">' + show_data(sunPriceValue) + '</td>';
              sunRateRow += '<td colspan="2">' + sunRateValue + '</td>';
            }
            sunPriceRow += '</tr>';
            sunRateRow += '</tr>';

            // "전월대비증감"
            let sunRateDiffRow = '<tr class="warning cls-in">';
            for (let i = 0; i <= gab; i++) {
              let basicMonth = "";
              {
                const currentDate = new Date(eYear, eMonth - 1);
                currentDate.setMonth(currentDate.getMonth() - i);

                const year = currentDate.getFullYear();
                const month = currentDate.getMonth() + 1;
                basicMonth = year + (month < 10 ? '0' : '') + month;
              }
              let basicMonthOld = "";
              {
                const currentDate = new Date(eYear, eMonth - 1);
                currentDate.setMonth(currentDate.getMonth() - i - 1);

                const year = currentDate.getFullYear();
                const month = currentDate.getMonth() + 1;
                basicMonthOld = year + (month < 10 ? '0' : '') + month;
              }

              let bSunRate = "&nbsp;";
              let basicMonthData = result?.inData?.[basicMonth];
              let basicMonthOldData = result?.inData?.[basicMonthOld];

              if (basicMonthOldData && basicMonthOldData?.sun_price) {
                let sunRateDifference = basicMonthData?.sun_price - basicMonthOldData?.sun_price;
                let bSunRateValue = (sunRateDifference / basicMonthOldData?.sun_price) * 100;
                bSunRate = Number(Number(isNaN(bSunRateValue)?0:bSunRateValue).toFixed(2)) + "%";
              }

              // Calculate 전월대비증감
              const sunRateDiffValue = bSunRate; // Calculate 전월대비증감

              sunRateDiffRow += '<td colspan="2">' + sunRateDiffValue + '</td>';
            }
            sunRateDiffRow += '</tr>';

            let inPriceRow = '<tr class="warning cls-in">';
            for (let i = 0; i <= gab; i++) {
              const currentDate = new Date(eYear, eMonth - 1);
              currentDate.setMonth(currentDate.getMonth() - i);

              const year = currentDate.getFullYear();
              const month = currentDate.getMonth() + 1;
              const basicMonth = year + (month < 10 ? '0' : '') + month;

              // 수입합계
              const inPriceValue = result?.inData?.[basicMonth]?.in_price; // Calculate 수입합계
              inPriceRow += '<td>' + show_data(inPriceValue) + '</td><td>100%</td>';
            }
            inPriceRow += '</tr>';

            htmlResult += sunPriceRow + sunRateRow + sunRateDiffRow + inPriceRow;
          }

          {
            for (const fieldName of result?.tDesc1) {
              if (fieldName.charAt(0) === 'f' && fieldName !== 'f53') {
                // Calculate and set other variables here as needed

                // Start a new row for the field
                htmlResult += '<tr class="cls-in">';

                // Iterate for each month
                for (let i = 0; i <= gab; i++) {
                  const currentDate = new Date(eYear, eMonth - 1);
                  currentDate.setMonth(currentDate.getMonth() - i);

                  const year = currentDate.getFullYear();
                  const month = currentDate.getMonth() + 1;
                  const basicMonth = year + (month < 10 ? '0' : '') + month;

                  // console.log(basicMonth, fieldName, result?.inData?.[basicMonth])
                  // Calculate the value for this cell based on your PHP code
                  const cellValue = result?.inData?.[basicMonth]?.[fieldName]; // Calculate the cell value here;

                  let basicMonthData = result?.inData?.[basicMonth];
                  let newFieldData = basicMonthData?.[fieldName];

                  let per = "0%";
                  if (basicMonthData && basicMonthData?.['in_price']) {
                    let perValue = (newFieldData / basicMonthData?.['in_price']) * 100;
                    per = Number(Number(perValue).toFixed(1)) + "%";
                  }

                  // Add the cell to the current row
                  htmlResult += '<td>' + show_data(cellValue) + '</td>';
                  htmlResult += '<td>' + per + '</td>';
                }

                // Close the row for the field
                htmlResult += '</tr>';
              }
            }
          }

          {
            htmlResult += '<tr class="cls-out">';
            for (let i = 0; i <= gab; i++) {
              const currentDate = new Date(eYear, eMonth - 1);
              currentDate.setMonth(currentDate.getMonth() - i);

              const year = currentDate.getFullYear();
              const month = currentDate.getMonth() + 1;
              const formattedDate = year + '.' + (month < 10 ? '0' : '') + month;

              htmlResult += '<td colspan="2" class="text-center bgcolor bold700">' + formattedDate + '</td>';
            }
            htmlResult += '</tr>';

          }
          {
            htmlResult += '<tr class="cls-out">';
            for (let i = 0; i <= gab; i++) {
              const currentDate = new Date(eYear, eMonth - 1);
              currentDate.setMonth(currentDate.getMonth() - i);

              const year = currentDate.getFullYear();
              const month = currentDate.getMonth() + 1;
              const basicMonth = year + (month < 10 ? '0' : '') + month;
              const formattedDate = year + '.' + (month < 10 ? '0' : '') + month;

              // 지출합계
              const outPriceValue = result?.outData?.[basicMonth]?.out_price; // Calculate 지출합계

              let per_all = "0%";
              try {
                if (result?.inData?.[basicMonth]?.in_price) {
                  let _val = result?.outData?.[basicMonth]?.out_price / result?.inData?.[basicMonth]?.in_price * 100;
                  per_all = Number(Number(_val).toFixed(1)) + "%";
                }
              } catch (err) {
              }
              htmlResult += '<td>' + show_data(outPriceValue) + '</td><td>' + per_all + '</td>';
            }
            htmlResult += '</tr>';
          }

          {
            // Initialize an empty string to store the HTML result
            // let htmlResult = '';

            // Iterate through the field names
            for (const fieldName of result?.tDesc2) {
              if (fieldName.charAt(0) === 'f') {
                // Calculate and set other variables here as needed

                // Start a new row for the field
                htmlResult += '<tr class="cls-out">';

                // Iterate for each month
                for (let i = 0; i <= gab; i++) {
                  const currentDate = new Date(eYear, eMonth - 1);
                  currentDate.setMonth(currentDate.getMonth() - i);

                  const year = currentDate.getFullYear();
                  const month = currentDate.getMonth() + 1;
                  const basicMonth = year + (month < 10 ? '0' : '') + month;

                  // console.log(basicMonth, fieldName)
                  // Calculate the value for this cell based on your PHP code
                  const cellValue = result?.outData?.[basicMonth]?.[fieldName]; // Calculate the cell value here;

                  // Calculate the percentage for this cell based on your PHP code
                  const cellPercentage = 0; // Calculate the cell percentage here; // TODO

                  let inDataForBasicMonth = result?.inData?.[basicMonth];
                  let outDataForBasicMonth = result?.outData?.[basicMonth];
                  let newFieldData = outDataForBasicMonth?.[fieldName];

                  let per = "0%";
                  if (inDataForBasicMonth && inDataForBasicMonth['in_price']) {
                    let perValue = (newFieldData / inDataForBasicMonth['in_price']) * 100;
                    per = Number(Number(perValue).toFixed(1)) + "%";
                  } else {
                    per = "0%";
                  }

                  // Add the cell to the current row
                  htmlResult += '<td>' + show_data(cellValue) + '</td>';
                  htmlResult += '<td>' + per + '</td>';
                }

                // Close the row for the field
                htmlResult += '</tr>';
              }
            }
          }

          $('#html_area').html(htmlResult);
          changeType(type);
        }
      })
      .catch((error) => {
        console.error(error)
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    console.log(`/analysis/make/data/finance/ready?s_year=${$('#modal_s_year option:selected').val()}&s_month=${$('#modal_s_month option:selected').val()}`);
    axios2
      .post(`/analysis/make/data/finance/ready?s_year=${$('#modal_s_year option:selected').val()}&s_month=${$('#modal_s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function make_data(branchCode, branchName) {
    const sYear = $('#modal_s_year option:selected').val();
    const sMonth = $('#modal_s_month option:selected').val();

    const txt = `${branchName}지점의 ${sYear}년 ${sMonth}월 데이터를 생성하시겠습니까?`;

    if (window.confirm(txt)) {
      if (!$("#overlay").is(":visible"))
        $('#overlay').show();

      setTimeout(() => {
        axios2.postEx("/analysis/make/data/finance", {
          s_year: sYear,
          s_month: sMonth,
          branch_code: branchCode
        })
          .then((response) => response.json())
          .then(({code, message: data, result, pageInfo}) => {
            console.log(data, result);
            alert('생성되었습니다');
            loadModal();
          })
          .catch(error => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          })
          .finally(() => {
            $("#overlay").hide();
          });
      }, 100);
    }
  }

  function Excel_download() {
    // var auth_code = $("#auth_code").val();
    // if (!auth_code) {
    //   alert("인증코드를 입력해주세요");
    //   return;
    // }

    // var down_reason = $("#down_reason").val();
    // if (!down_reason) {
    //   alert("사유를 입력해주세요");
    //   return;
    // }

    const url = `/xls/finance/monthly/report2?s_year=${$('#s_year option:selected').val()}&s_month=${$('#s_month option:selected').val()}&e_year=${$('#e_year option:selected').val()}&e_month=${$('#e_month option:selected').val()}&type=${type}&verify=${localStorage.getItem("USER.token")}`;
    Fn.download(Constants.API_URL + url);
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/analysis/monthly/member")}>월간등록현황</label>

          <input id="bb" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/analysis/monthly/finance/new")}>월계표 비교분석</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/analysis/monthly/coupon")}>쿠폰발송내역</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/analysis/monthly/tm")}>TM 현황보고</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/analysis/monthly/region")}>지역분류</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/analysis/monthly/public")}>경쟁업체(퍼블릭)</label>

          <input id="gg" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/analysis/monthly/pt")}>경쟁업체(PT)</label>

          <input id="hh" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/analysis/monthly/analysis/pt")}>PT월간등록현황</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="">
          <select className="form-select" style={{minWidth: '80px'}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <p className="p-1">~</p>
        <div className="pr-1">
          <select className="form-select" id="e_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: '80px'}} id="e_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" id="type" onChange={(e) => {
            $('#type').val(e.target.value);
            changeType(e.target.value);
          }}>
            <option value="in">수입</option>
            <option value="out">지출</option>
            <option value="all">전체</option>
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => Excel_download()}>엑셀</button>
          <button type="button" className="btn btn-sm btn-dark" onClick={() => setModalOpen(true)}>월계표 데이터 생성</button>
        </div>
      </div>

      <div className="m-3 table_list">
        <div className="d-flex p_rative">
          <div className="table_overlap1 overlap240_1" style={{zIndex: 1000}}>
            <table className="rounded-corner-l sub_table_border">
              <tr className="cls-in">
                <th>구분월</th>
              </tr>
              <tr className="cls-in bgcolor bold700">
                <td>순이익</td>
              </tr>
              <tr className="cls-in bgcolor bold700">
                <td>순이익률</td>
              </tr>
              <tr className="cls-in bgcolor bold700">
                <td>전월대비증감율</td>
              </tr>
              <tr className="cls-in bgcolor bold700">
                <td>수입합계</td>
              </tr>
              <tr className="cls-in">
                <td>헬스신규</td>
              </tr>
              <tr className="cls-in">
                <td>헬스재등록</td>
              </tr>
              <tr className="cls-in">
                <td>PT</td>
              </tr>
              <tr className="cls-in">
                <td>GX</td>
              </tr>
              <tr className="cls-in">
                <td>골프</td>
              </tr>
              <tr className="cls-in">
                <td>스쿼시</td>
              </tr>
              <tr className="cls-in">
                <td>크로스핏</td>
              </tr>
              <tr className="cls-in">
                <td>운동복</td>
              </tr>
              <tr className="cls-in">
                <td>라커</td>
              </tr>
              <tr className="cls-in">
                <td>카드재발급</td>
              </tr>
              <tr className="cls-in">
                <td>양도</td>
              </tr>
              <tr className="cls-in">
                <td>가입비</td>
              </tr>
              <tr className="cls-in">
                <td>1일2회이용</td>
              </tr>
              <tr className="cls-in">
                <td>일일이용</td>
              </tr>
              <tr className="cls-in">
                <td>부가세</td>
              </tr>
              <tr className="cls-in">
                <td>서비스</td>
              </tr>
              <tr className="cls-in">
                <td>기타</td>
              </tr>
              <tr className="cls-in">
                <td>계약금</td>
              </tr>
              <tr className="cls-in">
                <td>프레스티지</td>
              </tr>
              <tr className="cls-in">
                <td>출자금</td>
              </tr>

              <tr className="cls-in">
                <td>단기차입</td>
              </tr>
              <tr className="cls-in">
                <td>단기차입</td>
              </tr>
              <tr className="cls-in">
                <td>각출금</td>
              </tr>
              <tr className="cls-in">
                <td>단기 차입</td>
              </tr>
              <tr className="cls-in">
                <td>단기 차입</td>
              </tr>
              <tr className="cls-in">
                <td>위약금</td>
              </tr>
              <tr className="cls-in">
                <td>절사</td>
              </tr>
              <tr className="cls-in">
                <td>스피닝</td>
              </tr>
              <tr className="cls-in">
                <td>필라테스</td>
              </tr>
              <tr className="cls-in">
                <td>모바일쿠폰</td>
              </tr>
              <tr className="cls-in">
                <td>물품판매</td>
              </tr>
              <tr className="cls-in">
                <td>쿠폰사용</td>
              </tr>
              <tr className="cls-in">
                <td>마일리지사용</td>
              </tr>

              <tr className="cls-in">
                <td>주센터변경</td>
              </tr>

              <tr className="cls-in">
                <td>환불(이체)</td>
              </tr>
              <tr className="cls-in">
                <td>환불(현금)</td>
              </tr>
              <tr className="cls-in">
                <td>환불(리모델링)</td>
              </tr>
              <tr className="cls-in">
                <td>타지점정산</td>
              </tr>
              <tr className="cls-in">
                <td>물품판매</td>
              </tr>

              <tr className="cls-in">
                <td>잡이익</td>
              </tr>
              <tr className="cls-in">
                <td>미등록</td>
              </tr>

              <tr className="cls-in">
                <td>단기대여금</td>
              </tr>
              <tr className="cls-in">
                <td>단기차입금</td>
              </tr>
              <tr className="cls-in">
                <td>이자수익</td>
              </tr>

              <tr className="cls-in">
                <td>출자금</td>
              </tr>
              <tr className="cls-in">
                <td>임시보유자금</td>
              </tr>
              <tr className="cls-in">
                <td>
                  미납(-)&nbsp;전월미납(+)
                </td>
              </tr>

              <tr className="cls-out bgcolor bold700">
                <td>구분 월</td>
              </tr>
              <tr id="out_td" className="cls-out">
                <td>지출합계</td>
              </tr>
            </table>
          </div>

          <div className="table_overlap2 overlap240_2" style={{minWidth: "min-content", paddingRight: "20px"}}>
            <table className="view-rounded sub_table_border text-right tbl-mcomp" id="html_area">
              {/*<colgroup>*/}
              {/*  <col width="30px"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*  <col width="60px"/>*/}
              {/*  <col width="*"/>*/}
              {/*</colgroup>*/}

              <tr>
                <th></th>
                <th colSpan="2">2022.12</th>
                <th colSpan="2">2022.11</th>
                <th colSpan="2">2022.10</th>
                <th colSpan="2">2022.09</th>
                <th colSpan="2">2022.08</th>
                <th colSpan="2">2022.07</th>
                <th colSpan="2">2022.06</th>
                <th colSpan="2">2022.05</th>
                <th colSpan="2">2022.04</th>
                <th colSpan="2">2022.03</th>
                <th colSpan="2">2022.02</th>
                <th colSpan="2">2022.01</th>
              </tr>
              <tr className="bgcolor bold700">
                <td>0</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr className="bgcolor bold700">
                <td>0</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr className="bgcolor bold700">
                <td>0</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr className="bgcolor bold700">
                <td>0</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
              </tr>
              <tr>
                <th></th>
                <th colSpan="2">2022.12</th>
                <th colSpan="2">2022.11</th>
                <th colSpan="2">2022.10</th>
                <th colSpan="2">2022.09</th>
                <th colSpan="2">2022.08</th>
                <th colSpan="2">2022.07</th>
                <th colSpan="2">2022.06</th>
                <th colSpan="2">2022.05</th>
                <th colSpan="2">2022.04</th>
                <th colSpan="2">2022.03</th>
                <th colSpan="2">2022.02</th>
                <th colSpan="2">2022.01</th>
              </tr>
              <tr className="bgcolor bold700">
                <td></td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
                <td></td>
                <td>0%</td>
              </tr>
            </table>
          </div>
        </div>
      </div>


      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          $('#modal_s_year').val(Fn.getCurrentDate().split('-')[0]);
          $('#modal_s_month').val(Fn.getCurrentDate().split('-')[1]);
          Fn.centerModal();
          loadModal()
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 100000}}>

          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: '650px'}}>
            <div className="POP_title">
              <h5><strong>월계표 데이터 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select className="form-select" id="modal_s_year">
                    {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <div className="pr-1">
                  <select className="form-select" id="modal_s_month">
                    {[...Array(12)].map((_, index) => {
                      const monthNumber = index + 1;
                      const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                      return (
                        <option key={monthString} value={monthString}>{monthString}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal()}>검색</button>
                </div>
              </div>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="*"/>
                  <col width="90px"/>
                  <col width="145px"/>
                  <col width="60px"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>지점명</th>
                  <th>최종월</th>
                  <th>생성일시</th>
                  <th>생성</th>
                </tr>
                {modalResult?.result && modalResult?.result?.map((ret, i) => {
                  return (
                    <tr>
                      <td className="text-center">{i + 1}</td>
                      <td>{ret?.branch_name}</td>
                      <td className="text-center">{ret?.llRow?.YM ? (ret?.llRow?.YM?.substring(0, 4) + "." + ret?.llRow?.YM?.substring(4, 6)) : ''}</td>
                      <td className="text-center">{ret?.llRow?.reg_date?.replace(/-/g, '.')?.substring(2, 16) || ''}</td>
                      <td className="text-center">
                        <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => make_data(ret?.branch_code, ret?.branch_name)}>생성</button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>
    </CmnCard>
  );
};

export default AnalysisMonthlyFinanceNew;
