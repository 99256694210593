import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import $ from 'jquery';
import CmnCard from "../CmnCard";
import axios from "axios";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";

const BranchPtOTTakeList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 3);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/branchop/ot/take/list/ready?s_date1=${$('#s_date1').val() || ''}&s_date2=${$('#s_date2').val() || ''}&s_mb_name=${$('#s_mb_name').val() || ''}&s_mb_hp=${$('#s_mb_hp').val() || ''}&s_take_train=${$('#s_take_train option:selected').val() || ''}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_take_train').html() === '') {
            let optionsHTML = '<option value="">진행 T : 전체</option>';
            result?.tnList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#s_take_train').html(optionsHTML);
          }

          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  let s_form;
  let form1;
  let common_i;
  let re_data;

  useEffect(() => {
    s_form = $("[name='s_form']");
    form1 = $("[name='form1']");
    common_i = $("[name='common_i']");
  });

  const popUp = (file, names, width, height, sc) => {
    const w = width;
    let h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h},scrollbars=${sc},top=${wint},left=${winl}`);
  };

  const inputOt = () => {
    popUp('ot_reg.html', 'ot_take_reg', '750', '270', 'no');
  };

  const delTake = (baseSeq, takeDate) => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7 && takeDate == Fn.getCurrentDate()) {
      axios2
        .post(`/branchop/ot/take/del?base_seq=${baseSeq}`)
        .then((response) => {
          alert('삭제되었습니다');
          setRenew(Fn.getRenewTime());
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    } else {
      alert('지점장이상 당일건만 삭제가능합니다');
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1 d-flex">
          <div className="input-group" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="성명 검색" id="s_mb_name"/>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="연락처 검색" id="s_mb_hp"/>
        </div>
        <div className="pr-1">
          <select className="form-select sel_lg" aria-label="Default select example" id="s_take_train">
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border auto">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>진행일</th>
            <th>시작시간</th>
            <th>성명</th>
            <th>카드번호</th>
            <th>연락처</th>
            <th>진행T</th>
            <th>진행시간</th>
            <th>비고</th>
            <th>삭제</th>
          </tr>

          {rets && rets.map((ret, i) => {
            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>{ret?.take_date || ''}</td>
                <td>{ret?.take_time || ''}</td>
                <td>{ret?.mb_name || ''}</td>
                <td>{ret?.mb_no || ''}</td>
                <td>
                  <div id={`default_show${i + 1}`}>***-****-****</div>
                </td>
                <td>{ret?.take_train_name || ''}</td>
                <td>{ret?.act_time || '0'}회</td>
                <td className="text-left">{ret?.bigo || ''}</td>
                <td>
                  <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => delTake(btoa(ret?.seq), (ret?.take_date || ''))}>
                    삭제
                  </button>
                </td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={10}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
          {pageInfo?.totalArticles > 0 && (
            (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('ot_take_list')}>연락처 확인</button>
            )
          )}
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default BranchPtOTTakeList;
