import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const MvChangeStartDate = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const load = () => {
    axios2
      .post(`/member/change/start/date/ready?mb_no=${props.mbNo}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          console.log(result);

          if ($('#csd_before_start_date').html() === '') {
            let optionsHTML = '<option value="">:선택</option>';
            result?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row}">${list_row}</option>`;
            });
            $('#csd_before_start_date').html(optionsHTML);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {
    if (!$('#csd_before_start_date option:selected').val()) {
      alert('기존 시작일을 선택해주세요');
      return;
    }
    if (!$('#csd_new_start_date').val()) {
      alert('변경 시작일을 선택해주세요');
      return;
    }

    axios2
      .postEx(`/member/start/date/change`, {
      // .postEx(`/member/change/start/date`, {
        auth_code: $('#auth_code').val(),
        mb_no: props.mbNo,
        insert_YN: "Y",
        receive_name: $('#_reg_step option:selected').val() || '',
        before_start_date: $('#csd_before_start_date option:selected').val() || '',
        new_start_date: $('#csd_new_start_date').val()
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data?.trim();
        if (data.startsWith("ok")) {
          alert('변경되었습니다');
          props?.onCallback();
          onToggleModal();
        } else if (data.startsWith("exist")) {
          alert('아직 미승인된 요청건이 있습니다');
        } else if (data.startsWith("pre")) {
          alert('신청일 이후에 휴회기간이 있는경우 시작일변경이 불가합니다');
        } else if (data.startsWith("delay")) {
          alert('연기기간중으로 시작일을 설정할수 없습니다.');
        } else {
          console.log(data);
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '720px'}}>
          <div className="POP_title">
            <h5><strong>시작일 변경</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <table className="view-rounded sub_view" style={{borderRadius: 0}}>
              <colgroup>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>기존시작일</th>
                <td>
                  <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="csd_before_start_date">
                  </select>
                </td>
                <th>변경시작일</th>
                <td>
                  <JDatePicker type="date" className="form-control" aria-describedby="button-addon2" id="csd_new_start_date" selectOnly={true}/>
                </td>
              </tr>
            </table>
          </div>
          <div className="text-center mb-3">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit()}>변경</button>
            <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>닫기</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MvChangeStartDate;
