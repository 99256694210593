import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Modal from "react-modal";
import JDatePicker from "../common/JDatePicker";
import SelectProduct from "../common/SelectProduct";
import {useLocation} from "react-router-dom";

const SalesProductDiscount = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [spModalOpen, setSpModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [renew, currPage]);

  const load = (_page) => {
    axios2
      .post(`/sales/sales/product/discount/ready?s_discount_YN=${$('#s_discount_YN option:selected').val() || ''}&s_category=${$('#s_category option:selected').val() || ''}&s_pum_code=${$('#s_pum_code option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_category').html() === '') {
            let optionsHTML = '<option value="">브랜드</option>';
            result?.list1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_category').html(optionsHTML);
          }
          if ($('#s_pum_code').html() === '') {
            let optionsHTML = '<option value="">품목구분</option>';
            result?.list2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_pum_code').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const form1 = useRef(null); // TODO : FORM 참조


  const delItem = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 할인판매상품을 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/sales/discount/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  function input_insert() {
    if ($('#start_date').val() == "") {
      alert("할인시작일을 선택해주세요");
      $('#start_date').focus();
      return;
    }
    if ($('#end_date').val() == "") {
      alert("할인종료일을 선택해주세요");
      $('#end_date').focus();
      return;
    }

    if ($('#start_date').val() > $('#end_date').val()) {
      alert("할인시작일이 할인종료일 이후입니다.");
      return;
    }


    if ($('#p_code').val() == "") {
      alert("할인상품을 선택해주세요");
      $('#p_code').focus();
      return;
    }
    if ($('#sale_price').val() == "" || $('#sale_price').val() == "0") {
      alert('할인판매가를 입력해주세요');
      $('#sale_price').focus();
      return;
    }

    //매입가는 판매가보다 작아야 함
    var won_sale_price = $('#won_sale_price').html();
    won_sale_price = Fn.cfNumeric(won_sale_price);

    var sale_price = $('#sale_price').val();
    sale_price = Fn.cfNumeric(sale_price);

    if (eval(won_sale_price) < eval(sale_price)) {
      alert("할인판매가가 판매가보다 높습니다.");
      return;
    }

    axios2
      .postEx('/sales/discount/product/reg', {
        p_code: $('#p_code').val(),
        sale_price: $('#sale_price').val(),
        start_date: $('#start_date').val(),
        end_date: $('#end_date').val(),
        base_seq: $('#base_seq').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (data == "dup") {
          alert('동일한 상품이 할인기간내에 등록되어 있습니다.')
        } else if (data == "ok") {
          alert("저장되었습니다.");
          setRenew(Fn.getRenewTime());
          setModalOpen(false);
        } else {
          alert("저장오류! 관리자에게 문의하세요.");
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    if ($('#base_seq').val()) {
      axios2
        .post(`/sales/discount/product/reg/ready?base_seq=${$('#base_seq').val()}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            const ret = result?.[0];

            $('#p_code').val(ret?.p_code);
            $('#sale_price').val(ret?.sale_price);
            $('#start_date').val(ret?.start_date);
            $('#end_date').val(ret?.end_date);
            $('#base_seq').val(btoa(ret?.seq));

            $('#p_code_show').val(ret?.p_code);
            $('#p_name').html(ret?.p_name);
            $('#buy_price').html(ret?.buy_price);
            $('#won_sale_price').html(ret?.won_sale_price);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="pr-1">
            <select className="form-select" id="s_discount_YN">
              <option value="">할인적용여부</option>
              <option value="Y">할인적용/적용예정</option>
              <option value="N">할인종료</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_category">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_pum_code">
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="상품명/바코드 검색" id="key"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="60px"/>
              <col width="70px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>

              <col width=""/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>

              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>시작일</th>
              <th>종료일</th>
              <th>상품코드</th>

              <th>상품명</th>
              <th>브랜드</th>
              <th>품목구분</th>
              <th>매입가</th>
              <th>정상판매가격</th>

              <th>할인판매가격</th>
              <th>할인금액</th>
            </tr>
            {rets && rets.map((ret, i) => {
              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input
                      type="checkbox"
                      // name={`chk[${i + 1}]`}
                      name="chk"
                      value={btoa(ret?.seq)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  </td>
                  <td>{ret?.start_date || ''}</td>
                  <td>{ret?.end_date || ''}</td>
                  <td
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      $('#base_seq').val(btoa(ret?.seq))
                      setModalOpen(true);
                    }}
                  >{ret?.p_code || ''}</td>
                  <td className="text-left">{ret?.p_name || ''}</td>
                  <td>{ret?.category_name || ''}</td>
                  <td>{ret?.pum_name || ''}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.buy_price)}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.won_sale_price)}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.sale_price)}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.cha_price)}</td>
                </tr>);
            })}
          </table>
        </div>

        <nav className="p-3 mb-4 d-flex justify-content-between border-top">
          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          <div>
            <div>
              <button type="button" className="btn btn-sm btn-outline-point mx-1" onClick={() => {
                $('#base_seq').val('');
                setModalOpen(true);
              }}>추가
              </button>
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => delItem()}>삭제</button>
            </div>
          </div>
        </nav>
      </div>

      {/*팝업*/}

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: '830px'}}>
            <div className="POP_title">
              <h5><strong>할인상품 등록</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="100px"/>
                  <col width="*"/>
                  <col width="100px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>시작일</th>
                  <td><JDatePicker className="form-control" id="start_date"/></td>
                  <th>종료일</th>
                  <td><JDatePicker className="form-control" id="end_date"/></td>
                </tr>
                <tr>
                  <th>상품코드</th>
                  <td>
                    <div className="d-flex">
                      <input type="text" className="form-control" style={{width: 'calc(100% - 35px)'}} id="p_code_show" readOnly={true}/>
                      <input type="hidden" id="p_code"/>
                      <button type="button" className="btn btn-sm btn-point space_nowrap px-3" onClick={() => setSpModalOpen(true)}>검색</button>
                    </div>
                  </td>
                  <th>상품명</th>
                  <td id="p_name"></td>
                </tr>
                <tr>
                  <th>매입가</th>
                  <td id="buy_price"></td>
                  <th>정상판매가</th>
                  <td id="won_sale_price"></td>
                </tr>
                <tr>
                  <th>할인판매가</th>
                  <td>
                    <input type="text" className="form-control" id="sale_price"/>
                  </td>
                  <th></th>
                  <td></td>
                </tr>
              </table>
            </div>

            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => input_insert()}>저장</button>
              <button type="button" className="btn btn-sm btn-ligray px-3 mx-1" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>

        <SelectProduct
          isOpen={spModalOpen}
          onModalClose={() => setSpModalOpen(false)}
          searchType={"discount_reg"}
          callback={() => {
            setSpModalOpen(false);
          }}
        />
      </Modal>

      <input type="hidden" id="base_seq"/>

    </CmnCard>
  );
};

export default SalesProductDiscount;
