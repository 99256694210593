import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";
import Constants from "../../constants/constants";
import moment from "moment/moment";

const MemberCuUseList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/member/cu/use/list/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_status=${$('#s_status option:selected').val() || ''}&s_brand=${$('#s_brand option:selected').val() || ''}&s_cal_yyyymm=${$('#s_cal_yyyymm')?.val()?.substring(0, 7)}&s_refund_yyyymm=${$('#s_refund_yyyymm')?.val()?.substring(0, 7)}&s_pub_date1=${$('#s_pub_date1').val()}&s_pub_date2=${$('#s_pub_date2').val()}&s_use_date1=${$('#s_use_date1').val()}&s_use_date2=${$('#s_use_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        console.log(response);
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.bbList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          // const total_goods_price = result?.totalAmount;
          // const add_price = Math.floor(total_goods_price * 0.1);
          // const goods_price = total_goods_price + add_price;
          // const cu_fee = Math.floor(goods_price * 0.05);
          // const jung_price = goods_price - cu_fee;
          // const center_fee = Math.floor(jung_price * 0.1);

          // <th className="bgcolor bold700">판매금액합계</th>
          // <td className="text-right"><span id="summary1">{Fn.numberWithCommas(0)}</span></td>
          // <th className="bgcolor bold700">CU수수료합계</th>
          // <td className="text-right"><span id="summary2">{Fn.numberWithCommas(0)}</span></td>
          // <th className="bgcolor bold700">정산총액합계</th>
          // <td className="text-right"><span id="summary3">{Fn.numberWithCommas(0)}</span></td>
          // <th className="bgcolor bold700">본사합계</th>
          // <td className="text-right"><span id="summary4">{Fn.numberWithCommas(0)}</span></td>
          // <th className="bgcolor bold700">지점합계</th>
          // <td className="text-right"><span id="summary5">{Fn.numberWithCommas(0)}</span></td>

          $('#summary1').text(Fn.numberWithCommas(result?.totalAmount || 0));
          $('#summary2').text(Fn.numberWithCommas(result?.cuFee || 0));
          $('#summary3').text(Fn.numberWithCommas(result?.jungPrice || 0));
          $('#summary4').text(Fn.numberWithCommas(result?.centerFee || 0));
          $('#summary5').text(Fn.numberWithCommas(result?.branchFee || 0));

          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  4-1.
  const in_bigo = async (baseSeq, value) => {
    try {
      const response = await axios.post('care_bigo.php', {
        base_seq: baseSeq,
        value: value,
      });

      console.log(response.data);
    } catch (error) {
      if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
    }
  };

  //  4-2.
  // in_bigo(baseSeq, value);

  const call_refund = () => {
    let con = $('[id^=achk]:checked').length;

    if (con == 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }
    if (con > 1) {
      window.alert("한건씩만 가능합니다.");
      return;
    }

    $('#base_seq').val($('[id^=achk]:checked').val());
    loadModal();
  };

  const loadModal = () => {
    axios2
      .post(`/member/refund/cal/care/ready?base_seq=${$('#base_seq').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalOpen(true);

          $('#delay_day').val(result?.delayDay || 0);
          $('#refund_start_date').val(result?.ticketData?.start_date || '');
          $('#pdt_date').val(Fn.getCurrentDate());
          $('#pay_amount').val(result?.payAmount || 0);
          $('#ot_left').html((result?.memberData?.ot_able || 0) - (result?.memberData?.ot_cnt || 0));

          useday_calc($('#refund_start_date').val(), "refund_use_date");
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const go_refund = (base_seq) => {
    if (window.confirm('등록된 서비스를 삭제하시겠습니까?')) {
      // TODO : API 구현 필요
      axios2
        .post(`/member/cu/use/del?base_seq=${base_seq}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            const dd = message.split("||");
            if (dd[0] === "care_err") {
              alert("CU 통신오류");
            } else {
              alert("환불처리되었습니다");
              setRenew(Fn.getRenewTime());
            }
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  function useday_calc(start_date, input_name) { //사용일자 계산. 시작일과 객체명.
    let t = new Date();
    let sdate_arr = start_date.split("-");
    let s_day = new Date(sdate_arr[0], sdate_arr[1] - 1, sdate_arr[2]);
    let start_day = s_day.getTime();

    //변경
    let pdt_date = $("#pdt_date").val();
    let Tdate_arr = pdt_date.split("-");
    let Ts_day = new Date(Tdate_arr[0], Tdate_arr[1] - 1, Tdate_arr[2]);
    let to_day = Ts_day.getTime();

    let useday = Math.round((to_day - start_day) / (1000 * 60 * 60 * 24));
    useday = eval(useday) - eval(Fn.parseInt($('#delay_day').val()));

    if (to_day > start_day) {
      $('#' + input_name).val(useday);
    } else {
      $('#' + input_name).val(0);
    }
  }

  function result_calc() {
    //사용일 재정의
    let dts = $('#refund_start_date').val();
    let use_date = $('#refund_use_date').val()

    if (use_date == "" || use_date == "0") {
      if (window.confirm('사용일수가 0일 입니다. 계속 진행하시겠습니까?')) {
      } else {
        return false;
      }
    }

    if (!$('#pay_amount').val() || $('#pay_amount').val() == 0) {
      alert("결제금액을 입력하세요.");
      $('#pay_amount').focus();
      return false;
    }

    let ndx = $('#refund_kind')[0].selectedIndex;
    let selected = Fn.parseInt($('#refund_kind.options[ndx]').val());
    let ndx1 = $('#refund_part')[0].selectedIndex;
    let selected1 = Fn.parseInt($('#refund_part.options[ndx1]').val());

    let refund_use_date = Fn.parseInt($('#refund_use_date').val());
    let pay_amount = Fn.parseInt($('#pay_amount').val().replace(/,/g, ""));

    let penalty_day_amount = refund_use_date * selected1;
    let penalty_per_amount = pay_amount * (selected / 100);

    let penalty_amount = penalty_day_amount + penalty_per_amount;
    let return_amount = pay_amount - penalty_amount

    penalty_amount = Math.round(penalty_amount);
    return_amount = Math.round(return_amount);

    $('#real_penalty_amount').val(penalty_amount);

    $("#penalty_amount").html("<font color='red'><b>" + commaNum(penalty_amount) + "원</b></font>");

    let add_wi = Fn.parseInt($('#add_wi').val().replace(/,/g, ""));

    if (add_wi) {
      return_amount = return_amount - add_wi;
    }

    if (eval(return_amount) > 0) {
      if (penalty_amount > pay_amount) {//위약금이 결제금액보다 크면
        $('#refund_amount').val(0);
      } else {
        $('#refund_amount').val(commaNum(return_amount));
      }

      //환불금액에 대해서 본사/지점 나눈다
      let bb = Math.round(eval(return_amount) * eval(0.8));
      let cc = eval(eval(return_amount) - eval(bb));

      $('#penalty_amount_branch').html("<font color='red'><b>" + commaNum(bb) + "원</b></font>");
      $('#penalty_amount_central').html("<font color='red'><b>" + commaNum(cc) + "원</b></font>");
    } else {
      $('#refund_amount').val(0);
      $('#penalty_amount_branch').html('');
      $('#penalty_amount_central').html('');
    }
  }

  function commaNum(num) {
    if (num < 0) {
      num *= -1;
      var minus = true
    } else var minus = false

    var dotPos = (num + "").split(".")
    var dotU = dotPos[0]
    var dotD = dotPos[1]
    var commaFlag = dotU.length % 3

    if (commaFlag) {
      var out = dotU.substring(0, commaFlag)
      if (dotU.length > 3) out += ","
    } else var out = ""

    for (var i = commaFlag; i < dotU.length; i += 3) {
      out += dotU.substring(i, i + 3)
      if (i < dotU.length - 3) out += ","
    }

    if (minus) out = "-" + out
    if (dotD) return out + "." + dotD
    else return out
  }

  function Set_Comma(n) {
    return Number(String(n).replace(/\..*|[^\d]/g, "")).toLocaleString().slice(0, -3);
  }

  function Excel_download() {
    var auth_code = $("#auth_code").val();
    if (!auth_code) {
      alert("인증코드를 입력해주세요");
      return;
    }

    var down_reason = $("#down_reason").val();
    if (!down_reason) {
      alert("사유를 입력해주세요");
      return;
    }

    const url = `/xls/member/cu/coupon/use?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_status=${$('#s_status option:selected').val() || ''}&s_brand=${$('#s_brand option:selected').val() || ''}&s_cal_yyyymm=${$('#s_cal_yyyymm')?.val()?.substring(0, 7)}&s_refund_yyyymm=${$('#s_refund_yyyymm')?.val()?.substring(0, 7)}&s_pub_date1=${$('#s_pub_date1').val()}&s_pub_date2=${$('#s_pub_date2').val()}&s_use_date1=${$('#s_use_date1').val()}&s_use_date2=${$('#s_use_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&auth_code=${auth_code}&down_reason=${down_reason}`
    Fn.download(Constants.API_URL + url);
  }

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  return (
    <CmnCard onLogout={props.onLogout}>
      <div>
        <div className="d-flex m-3 justify-content-between">
          <div className="d-flex fn_search_box" style={{minWidth: '1900px'}}>
            <div className="pr-1">
              <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_branch_code">
              </select>
            </div>
            <div className="pr-1 d-flex">
              <label htmlFor="발행일" className="p-1 px-2 mt-1" style={{wordBreak: 'keep-all'}}>발행일 : </label>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_pub_date1"/>
              <p className="mx-2 mt-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_pub_date2"/>
            </div>

            <div className="pr-1 d-flex">
              <label htmlFor="대상일" className="p-1 px-2 mt-1" style={{wordBreak: 'keep-all'}}>구매적용일 : </label>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_use_date1"/>
              <p className="mx-2 mt-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_use_date2"/>
            </div>

            <div className="pr-1">
              <select className="form-select table-inline" id="s_status" style={{marginTop: '2px'}}>
                <option value="" selected>상태</option>
                <option value="발행">발행</option>
                <option value="등록">등록</option>
                <option value="발행취소">발행취소</option>
                <option value="환불">환불</option>
                <option value="기간만료">기간만료</option>
              </select>
            </div>

            <div className="pr-1">
              <select className="form-select table-inline" id="s_brand" style={{marginTop: '2px'}}>
                <option value="" selected>이용권타입</option>
                <option value="F">피트니스</option>
                <option value="P">프로</option>
                <option value="X">엑스</option>
              </select>
            </div>

            <div className="pr-1 d-flex">
              <label htmlFor="정산월" className="p-1 px-2 mt-1" style={{wordBreak: 'keep-all'}}>정산월 : </label>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_cal_yyyymm" dateFormat="yyyy-MM" placeholder={'정산월'}/>
              <label htmlFor="정산월" className="p-1 px-2 mt-1" style={{wordBreak: 'keep-all'}}>환불월 : </label>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_refund_yyyymm" dateFormat="yyyy-MM" placeholder={'환불월'}/>
            </div>

            <div className="pr-1">
              <input type="text" className="form-control width_shorts" aria-describedby="button-addon2" placeholder="사용자/회원번호" id="s_mb_name"/>
            </div>

            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
          </div>
        </div>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th className="bgcolor bold700">판매금액합계</th>
            <td className="text-right"><span id="summary1">{Fn.numberWithCommas(0)}</span></td>
            <th className="bgcolor bold700">CU수수료합계</th>
            <td className="text-right"><span id="summary2">{Fn.numberWithCommas(0)}</span></td>
            <th className="bgcolor bold700">정산총액합계</th>
            <td className="text-right"><span id="summary3">{Fn.numberWithCommas(0)}</span></td>
            <th className="bgcolor bold700">본사합계</th>
            <td className="text-right"><span id="summary4">{Fn.numberWithCommas(0)}</span></td>
            <th className="bgcolor bold700">지점합계</th>
            <td className="text-right"><span id="summary5">{Fn.numberWithCommas(0)}</span></td>
          </tr>
        </table>
      </div>

      <div className="m-3 pb-5 table_list">
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border table_wide">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="140px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>발행일</th>
            <th>발행번호</th>
            <th>상태</th>
            <th>회원명</th>
            <th>회원번호</th>
            <th>연락처</th>
            <th>구매회원권</th>
            <th>타입</th>
            <th>구매적용일</th>
            <th>구매금액</th>
            <th>CU수수료</th>
            <th>정산총액</th>
            <th>정산(본사)</th>
            <th>정산(지점)</th>
            <th>사용지점</th>
            <th>정산월</th>
            <th>환불월</th>
          </tr>
          {rets && rets.map((ret, i) => {
            const {
              seq,
              coupon_no,
              pub_date,
              branch_name,
              mb_id,
              mb_name,
              member_id,
              goods_name,
              use_date,
              goods_price: temp_goods_price,
              start_date,
              end_date,
              use_branch_name,
              bigo,
              cancel_YN,
              refund_YN,
              mb_hp,
            } = ret;

            // function hp_show(mb_hp, total_record) {
            //   const mb_hp_show = `${mb_hp.substring(0, 3)}-${mb_hp.substring(3, 7)}-${mb_hp.substring(7, 11)}`;
            //   return mb_hp_show;
            // }

            let status = '발행';
            if (mb_id) status = '등록';
            if (refund_YN === 'Y' || cancel_YN === 'Y') {
              if (moment(pub_date).add(1, 'months').format('YYYY-MM') > moment(ret?.refund_date).format('YYYY-MM')) status = '발행취소';
              else status = '환불';
            }
            const valid_dt = moment(pub_date).add(5, 'years').add(-1, 'days').format('YYYY-MM-DD');
            if (cancel_YN === 'N' && Fn.getCurrentDate() > valid_dt) status = '기간만료';

            const add_price = Math.floor(temp_goods_price * 0.1);
            const goods_price = temp_goods_price + add_price;
            const cu_fee = Math.floor(goods_name?.toLowerCase()?.includes("1day") ? goods_price * 0.10 : goods_price * 0.05);
            const jung_price = goods_price - cu_fee;
            const center_fee = Math.floor(jung_price * 0.1);
            const base_seq = btoa(seq);
            // const mb_hp_show = hp_show(mb_hp, total_record);
            // const base_hp = btoa(mb_hp_show);
            // let tot_base_hp = `${count},${base_hp}|`;

            const tableRowStyle = refund_YN === 'Y' ? {background: '#ffe1ea'} : {};

            return (
              <tr style={{color: status === '환불' ? 'red' : undefined}}>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>{pub_date?.substring(0, 10)}</td>
                <td>{coupon_no?.substring(0, 4)}-{coupon_no?.substring(4, 8)}-{coupon_no?.substring(8, 12)}</td>
                <td>{status}</td>
                <td>{(status !== '환불' && status !== '발행취소') && mb_name}</td>
                <td>{(status !== '환불' && status !== '발행취소') && mb_id}</td>
                <td>
                  {(status !== '환불' && status !== '발행취소') && <div id={`default_show${i + 1}`}>***-****-****</div>}
                </td>
                <td>{goods_name}</td>
                <td>{ret?.brand === 'F' ? '피트니스' : ret?.brand === 'P' ? '프로' : ret?.brand === 'G' ? '짐' : ret?.brand === 'S' ? '패스트' : ret?.brand === 'X' ? '엑스' : ''}</td>
                <td>{use_date}</td>
                <td className="text-right">{status === '발행취소' ? 0 : Fn.numberWithCommas(goods_price)}</td>
                <td className="text-right">{status === '발행취소' ? 0 : Fn.numberWithCommas(cu_fee)}</td>
                <td className="text-right">{status === '발행취소' ? 0 : Fn.numberWithCommas(jung_price)}</td>
                <td className="text-right">{status === '발행취소' ? 0 : Fn.numberWithCommas(center_fee)}</td>
                <td className="text-right">{status === '발행취소' ? 0 : Fn.numberWithCommas((jung_price || 0) - (center_fee || 0))}</td>
                <td>{(status !== '환불' && status !== '발행취소') && branch_name}</td>
                <td>{moment(pub_date?.substring(0, 10)).add(1, 'months').format('YYYY-MM')}</td>
                <td>{ret?.refund_date?.substring(0, 7) || ''}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={14}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-end border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex mx-1">
          <ChkAuthNum type={"locker_info_change"}/>
        </div>
        <div className="d-flex mx-1">
          <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="다운로드 사유 입력" style={{width: '200px'}} id="down_reason"/>
          {pageInfo?.totalArticles &&
            <>
              {(
                  Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                  (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 300) ||
                  (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                  (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)
                ) &&
                <button type="button" className="btn btn-sm btn-outline-dark px-2 mx-1" onClick={() => Fn.showPhone('carecounpon_use')}>연락처 확인</button>
              }
              <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => Excel_download()}>엑셀저장</button>
            </>
          }
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

      <input type="hidden" id="base_seq"/>
      <input type="hidden" id="delay_day"/>

    </CmnCard>
  );
};
export default MemberCuUseList;
