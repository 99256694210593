import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import $ from "jquery";
import JDatePicker from "../common/JDatePicker";


const FinanceCashNew = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [result, setResult] = useState({});
  const [resultDtl, setResultDtl] = useState({});
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('#s_date1').val(Fn.getCurrentDate());
    $('#s_date2').val(Fn.getCurrentDate());
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/finance/finance/cash/new/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadDetail = (type = 'A', cardName = '') => {
    axios2
      .post(`/finance/finance/cash/new?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&type=${type}&card_name=${cardName}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResultDtl(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="row m-3">
        <div className="col-5">
          <div className="d-flex my-3">
            <div className="d-flex">
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
            </div>
            <div className="mx-1">
              <button type="button" className="btn btn-sm btn-point px-3 space_nowrap" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
          </div>
          <div className="my-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border auto">
              <tr>
                <th>구분</th>
                <th>정상건수</th>
                <th>금액</th>
                <th>취소건수</th>
                <th>취소금액</th>
              </tr>
              <tr>
                <td onClick={() => loadDetail('A', '')} style={{cursor: "pointer"}}>현금</td>
                <td className="text-right">{Fn.numberWithCommas(result?.cash_row?.cash_cnt)} 건</td>
                <td className="text-right">{Fn.numberWithCommas(result?.cash_row?.jan)} 원</td>
                <td className="text-right">{Fn.numberWithCommas(result?.cash2_row?.cash_cnt)} 건</td>
                <td className="text-right">{Fn.numberWithCommas(result?.cash2_row?.jan)} 원</td>
              </tr>
              {result?.bank?.map((bank_row, index) => {

                return (
                  <tr key={index}>
                    <td>
                      <strong>
                        <a href="#1" onClick={() => loadDetail('B', bank_row?.j_jukname || '')}>{bank_row?.j_jukname}</a>
                      </strong>
                    </td>
                    <td className="text-right">{Fn.numberWithCommas(bank_row?.bank_sub1_row?.bank_cnt)} 건</td>
                    <td className="text-right text-primary">{Fn.numberWithCommas(bank_row?.bank_sub1_row?.jan)} 원</td>
                    <td className="text-right">{Fn.numberWithCommas(bank_row?.bank_sub2_row?.bank_cnt)} 건</td>
                    <td className="text-right text-danger">{Fn.numberWithCommas(bank_row?.bank_sub2_row?.jan)} 원</td>
                  </tr>
                );
              })}
              <tr className="bgcolor bold700">
                <td>소계</td>
                <td className="text-right">
                  <strong>{Fn.numberWithCommas(result?.t_cnt)} 건</strong>
                </td>
                <td className="text-right text-primary">
                  <strong>{Fn.numberWithCommas(result?.t_price)} 원</strong>
                </td>
                <td className="text-right">
                  <strong>{Fn.numberWithCommas(result?.r_cnt)} 건</strong>
                </td>
                <td className="text-right text-danger">
                  <strong>{Fn.numberWithCommas(result?.r_price)} 원</strong>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="col-7">
          <div className="my-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border auto">
              <tr>
                <th className="w_70px">No</th>
                <th>일시</th>
                <th>회원명</th>
                <th>금액</th>
                <th>등록자</th>
              </tr>
              {resultDtl?.result && resultDtl?.result?.map((row, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{`${row?.ck_date} ${row?.ckk_time}`}</td>
                  <td>{row?.ck_sangho}</td>
                  <td className={`text-right ${row?.ck_amtcha < 0 ? 'red-text' : ''}`}>
                    {Fn.numberWithCommas(row?.ck_amtcha)}
                  </td>
                  <td>{row?.ip_step}</td>
                </tr>
              ))}
              {(!resultDtl?.result || resultDtl?.result?.length === 0) &&
                <tr>
                  <td colSpan={5}>※ 등록된 data가 없습니다.</td>
                </tr>
              }
            </table>
          </div>
        </div>
      </div>
    </CmnCard>
  );
};

export default FinanceCashNew;
