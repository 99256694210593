import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import Modal from 'react-modal';
import ChkAuthNum from "../common/ChkAuthNum";

const MemberGiftRegi = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    if (srchRenew > 0)
      $('#search_YN').val('Y');
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/member/gift/reg/ready?search_YN=${$('#search_YN').val()}&o_date1=${$('#o_date1').val()}&o_date2=${$('#o_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_hp=${$('#s_mb_hp').val()}&s_visit_course=${$('#s_visit_course option:selected').val() || ''}&s_sex=${$('#s_sex option:selected').val() || ''}&s_mb_birth=${$('#s_mb_birth').val()}&s_item_month=${$('#s_item_month option:selected').val() || ''}&s_item_grade=${$('#s_item_grade option:selected').val() || ''}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_visit_course').html() === '') {
            let optionsHTML = '<option value="">::가입경로::</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.sub_code_name}">${list_row.sub_code_name}</option>`;
            });
            $('#s_visit_course').html(optionsHTML);
          }
          if ($('#s_item_grade').html() === '') {
            let optionsHTML = '<option value="">::상품등급::</option>';
            result?.bl?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_item_grade').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = (mb_no) => {
    axios2
      .post(`/member/gift/use/ready?mb_no=${mb_no}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
          setModalOpen(true);
          processing = false;
          $('#mb_no').val(mb_no);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function gift_chk() {
    const g1 = $('#g1').val();
    const g2 = $('#g2').val();
    const g3 = $('#g3').val();
    //const g4 = $('#g4').val();

    if (g1.length !== 4) {
      alert("이용권번호를 입력해주세요");
      return;
    }
    if (g2.length !== 4) {
      alert("이용권번호를 입력해주세요");
      return;
    }
    if (g3.length !== 4) {
      alert("이용권번호를 입력해주세요");
      return;
    }
    /*
    if(g4.length !==4)
    {
        alert("이용권번호를 입력해주세요");
        return;
    }
    */

    axios2
      .post(`/member/gift/use/chk?mb_no=${$('#mb_no').val()}&g1=` + g1 + "&g2=" + g2 + "&g3=" + g3)
      .then((response) => {
        const {code, message: data, result, pageInfo} = response?.data;
        if (code === 200) {
          const split = data.split("||");
          const v1 = split?.[0];
          const v2 = split?.[1];
          const v3 = split?.[2];	//추가개월수
          const v4 = split?.[3];	//추가일수
          const v5 = split?.[4];	//상품코드
          const v6 = split?.[5];	//B2B여부


          if (v1 == "no_gift") {
            alert("일치하는 이용권번호가 없습니다.");
            return;
          }
          if (v1 == "not_pub") {
            alert("발행되지 않은 이용권번호입니다");
            return;
          }
          if (v1 == "used") {
            alert("이미 사용된 이용권번호입니다");
            return;
          }
          if (v1 == "old") {
            alert("사용 유효기간이 만료된 이용권번호입니다");
            return;
          }
          if (v1 == "not_b2b_use") {
            alert("B2B이용권은 발행 지점에서만 사용가능합니다");
            return;
          }

          if (v1 === "ok") {
            if (v6 === "Y") {
              //b2b 인경우 해당 지점의 계좌를 표시한다
              $("#b2b_YN").val("Y");

              //계좌리스트를 펼친다
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              //form1.g4.readOnly = true;
              $('#g_cont').html(v2);
              $('#start_set').css('display', "table-row");
              $('#auth_set').css('display', "table-row");
              $('#btn_set').css('display', "block");
              $('#add_month').val(v3);
              $('#add_day').val(v4);
              $('#select_item_id').val(v5);

              $("#pay_method").show();
            } else {
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              $('#g1').prop('readOnly', true);
              //form1.g4.readOnly = true;
              $('#g_cont').html(v2);
              $('#start_set').css('display', "table-row");
              $('#auth_set').css('display', "table-row");
              $('#btn_set').css('display', "block");
              $('#add_month').val(v3);
              $('#add_day').val(v4);
              $('#select_item_id').val(v5);
              $("#b2b_YN").val("N");

              $("#pay_method").hide();
            }
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  let processing = false;

  function goSubmit() {
    if (processing) return;

    //b2b 일경우 지불수단 필수 입력
    if ($("#b2b_YN").val() == "Y") {
      if (!$("#payCard").val()) {
        alert('지불수단을 선택해주세요');
        return;
      }
    }

    if ($('#radio1').prop('checked') == false && $('#radio2').prop('checked') == false && $('#radio3').prop('checked') == false) {
      alert('시작일을 설정해주세요');
      return;
    }

    if ($('#auth_code').value == "") {
      alert('인증번호를 입력해주세요');
      return;
    }

    if (window.confirm('이용권을 등록하시겠습니까?')) {
      processing = true;

      axios2.postEx("/member/gift/use", {
        select_item_id: $('#select_item_id').val(),
        auth_code: $('#auth_code').val(),
        mb_no: $('#mb_no').val(),
        it_start_date: $('#it_start_date').val(),
        it_end_date: $('#it_end_date').val() || '',
        ap_step: $('#ap_step').val(),
        g1: $('#g1').val(),
        g2: $('#g2').val(),
        g3: $('#g3').val(),
        b2b_YN: $('#b2b_YN').val(),
        add_month: $('#add_month').val(),
        add_day: $('#add_day').val(),
        period_day: $('#add_day').val(),
        pay_card: $('#payCard').val(),
      })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          console.log(data, result);
          if (data == "ok") {
            alert('등록되었습니다');
            setRenew(Fn.getRenewTime());
            setModalOpen(false);
          } else {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
            setModalOpen(false);
          }
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          setModalOpen(false);
        })
        .finally(() => {
          // $(".loading_bg").fadeOut("fast");
        });
    }
  }

  function it_period_chk(chk) //chk 값은 설정 선택할때 값
  {
    const period_kind = $(':radio[name="period_kind"]:checked').val();

    if (period_kind == 1) {
      $('#start_date_show').css('display', "none");
    } else {
      $('#start_date_show').css('display', "flex");
    }

    var period_month = $("#add_month").val();
    var period_day = $("#add_day").val();
    var it_start_date = $("#it_start_date").val();

    var mb_no = $('#mb_no').val();


    var select_item_id = $('#select_item_id').val();
    if (select_item_id) {
      select_item_id = select_item_id;
    } else {
      select_item_id = "";
    }

    axios2
      .post("/member/it/period/chk?period_kind=" + period_kind + "&period_month=" + period_month + "&period_day=" + period_day + "&mb_no=" + mb_no + "&chk=" + chk + "&it_start_date=" + it_start_date + "&select_item_id=" + select_item_id)
      .then((response) => {
        const {code, message: data, result, pageInfo} = response?.data;
        if (code === 200) {
          if (data == "err") {
            alert('회원정보를 확인할수 없습니다.');
            return;
          }

          var data2 = data.split("||");

          if (data2[0] > 0 && chk == 3) {
            if (window.confirm("해당회원은 회원권기간이 남아있습니다.\n재등록으로 기간설정을 하시겠습니까?")) {
              $("#it_start_date").val(data2[1])
              $("#it_end_date").val(data2[2])
              $("#it_end_date_show").html(data2[2])
            }
          } else {
            $("#it_start_date").val(data2[1])
            $("#it_end_date").val(data2[2])
            $("#it_end_date_show").html(data2[2])
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>

      <input type="hidden" id="search_YN" value=""/>

      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="pr-1 d-flex">
            <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>
              가입일 :
            </label>
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date2"/>
            </div>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control width_shorts" aria-describedby="button-addon2" placeholder="이름 검색" id="s_mb_name"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control width_shorts" aria-describedby="button-addon2" placeholder="연락처 검색" id="s_mb_hp"/>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_visit_course">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_sex">
              <option value=''>성별</option>
              <option value="M">남성</option>
              <option value="F">여성</option>
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control width_shorts" aria-describedby="button-addon2" placeholder="생년월일 검색" id="s_mb_birth"/>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_item_month">
              <option value=''>상품개월수</option>
              <option value="1">1개월</option>
              <option value="3">3개월</option>
              <option value="6">6개월</option>
              <option value="12">12개월</option>
              <option value="18">18개월</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_item_grade">
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>


        <div className="m-3 pb-1 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="60px"/>
              <col width="140px"/>
              <col width="70px"/>
              <col width="140px"/>
              <col width="60px"/>
              <col width="60px"/>
              <col width="85px"/>
              <col width="70px"/>
              <col width="*"/>
              <col width="85px"/>
              <col width="85px"/>
              <col width="*"/>
              <col width="65px"/>
              <col width="*"/>
              <col width="110px"/>
              <col width="120px"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>카드번호</th>
              <th>성명</th>
              <th>연락처</th>
              <th>성별</th>
              <th>연령</th>
              <th>가입일</th>
              <th>개월수</th>
              <th>상품등급</th>
              <th>시작일</th>
              <th>종료일</th>
              <th>라커</th>
              <th>운동복</th>
              <th>사용서비스</th>
              <th>미수금</th>
              <th>이용권등록</th>
            </tr>
            {rets && rets.map((ret, i) => {

              //성별
              let mbSexShow;
              if (ret?.mb_sex === "M") {
                mbSexShow = "남";
              } else if (ret?.mb_sex === "F") {
                mbSexShow = "여";
              } else {
                mbSexShow = "";
              }

              //연령
              let birthShow;
              const mBirthStr = ret?.mb_birth.substring(0, 2);
              if (mBirthStr === "19") {
                birthShow = 100;
              } else {
                let mRealB;
                if (parseInt(mBirthStr) < 30) {
                  mRealB = "20" + mBirthStr;
                } else {
                  mRealB = "19" + mBirthStr;
                }
                birthShow = new Date().getFullYear() - parseInt(mRealB);
              }

              //라커 사용
              let lockerPeriod;
              let lockerShow;

              if (ret?.locker === "1") {
                lockerPeriod = `${ret?.locker_start_date} ~ ${ret?.locker_end_date}`;
                lockerShow = (
                  <a style={{cursor: "pointer"}} onClick={() => alert(lockerPeriod)}>
                    <font color="blue">{ret?.locker_name}</font>
                  </a>
                );
              } else if (ret?.locker === "0") {
                lockerPeriod = "";
                lockerShow = "미사용";
              } else if (ret?.locker === "2") {
                lockerPeriod = "";
                lockerShow = "예약";
              } else if (ret?.locker === "3") {
                lockerPeriod = "";
                lockerShow = "미지급";
              } else {
                lockerPeriod = "";
                lockerShow = "미사용";
              }

              //운동복
              let suitPeriod;
              let suitShow;
              if (ret?.suit === "1") {
                suitPeriod = `${ret?.suit_start_date} ~ ${ret?.suit_end_date}`;
                suitShow = (
                  <a style={{cursor: "pointer"}} onClick={() => alert(suitPeriod)}>
                    <font color="blue">사용</font>
                  </a>
                );
              } else {
                suitPeriod = "";
                suitShow = "미사용";
              }

              return (
                <tr>
                  <td>{pageInfo?.totalArticles - pageInfo?.articlesPerPage * (pageInfo?.currentPage - 1) - i}</td>
                  <td>{ret?.mb_id}</td>
                  <td>{ret?.mb_name}</td>
                  <td>***-****-****</td>
                  <td>{mbSexShow}</td>
                  <td>{birthShow}</td>
                  <td>{(ret?.mb_open_date || '').replace(/-/g, '-').substr(0, 10)}</td>
                  <td>{ret?.buy_month}개월</td>
                  <td>{ret?.member_grade_name}</td>
                  <td>{ret?.start_date}</td>
                  {/* TODO 휴회기간 추가 필요  */}
                  <td>{ret?.end_date}</td>
                  <td>{lockerShow}</td>
                  <td>{suitShow}</td>
                  <td></td>
                  <td className="text-right">{ret?.remain_price > 0 && (ret?.remain_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}</td>
                  <td>
                    <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => loadModal(ret?.mb_no)}>
                      이용권등록
                    </button>
                  </td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                {$('#search_YN').val() === 'Y' ?
                  <td colSpan={16}>※ 등록된 data가 없습니다.</td>
                  :
                  <td colSpan={16}>※ 검색조건 입력후 검색버튼을 클릭해주세요.</td>
                }
              </tr>
            }
          </table>
        </div>
      </div>
      <nav className="p-3 mb-4 d-flex justify-content-center border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

      <input type="hidden" id="mb_no"/>
      <input type="hidden" id="add_month"/>
      <input type="hidden" id="add_day"/>
      <input type="hidden" id="select_item_id"/>
      <input type="hidden" id="b2b_YN"/>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '760px'}}>
            <div className="POP_title">
              <h5>
                회원정보 > <strong>이용권 등록</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div>
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <td colSpan="5" className="text-left">
                      <span className="h7 bold700 px-2">회원정보</span>
                    </td>
                  </tr>
                  <tr>
                    <th>회원번호</th>
                    <th>회원명</th>
                    <th>연락처</th>
                    <th>이용기간</th>
                    <th>운동복</th>
                  </tr>
                  <tr>
                    <td className="text-center">{modalResult?.mbInfo?.mb_id}</td>
                    <td className="text-center">{modalResult?.mbInfo?.mb_name}</td>
                    <td className="text-center">{modalResult?.mbInfo?.mb_hp}</td>
                    <td className="text-center">
                      {modalResult?.mbInfo?.start_date?.replaceAll("-", ".")?.substring(2)} ~ <br/>
                      {modalResult?.mbInfo?.end_date?.replaceAll("-", ".")?.substring(2)}
                    </td>
                    <td className="text-center">
                      {modalResult?.mbInfo?.suit_start_date?.replaceAll("-", ".")?.substring(2)} ~ <br/>
                      {modalResult?.mbInfo?.suit_end_date?.replaceAll("-", ".")?.substring(2)}
                    </td>
                  </tr>
                </table>
              </div>

              <div className="mt-3">
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="130px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <td colSpan="2" className="text-left">
                      <span className="h7 bold700 px-2">이용권 정보</span>
                    </td>
                  </tr>
                  <tr>
                    <th>이용권 등록</th>
                    <td>
                      <div className="input-group mt-1" style={{width: 'auto'}}>
                        <input type="text" className="form-control" aria-describedby="button-addon2" id="g1" maxLength={4}/>
                        <p className="mx-2">-</p>
                        <input type="text" className="form-control" aria-describedby="button-addon2" id="g2" maxLength={4}/>
                        <p className="mx-2">-</p>
                        <input type="text" className="form-control" aria-describedby="button-addon2" id="g3" maxLength={4}/>
                        <button type="button" className="btn btn-sm btn-outline-dark px-3 mx-1" onClick={() => gift_chk()}>
                          확인
                        </button>
                        <button type="button" className="btn btn-sm btn-point px-3" id="btn_set" style={{display: 'none'}} onClick={() => goSubmit()}>
                          사용
                        </button>
                      </div>
                      <div className="msg_box">
                        <span id="g_cont">위 입력란에 이용권의 번호를 입력하세요.</span>
                      </div>
                    </td>
                  </tr>

                  <tr id="pay_method" style={{display: "none"}}>
                    <th>지불수단</th>
                    <td className="txt_left">
                      <select id="payCard" className="form-control wid80" style={{width: '120px', display: 'inline-block'}}>
                        <option value=''>지불수단 선택</option>
                        <option value='1111'>현금</option>
                        <option value='2222'>카드</option>
                        {/*TODO*/}
                      </select>
                    </td>
                  </tr>

                  <tr id="start_set" style={{display: "none"}}>
                    {/*TODO : it_period_chk 연동*/}
                    <th>시작일 설정</th>
                    <td className="txt_left">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="period_kind" id="radio1" value="1" onClick={(e) => it_period_chk(e.target.value)}/>
                        <label className="form-check-label" htmlFor="radio1">
                          미정
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="period_kind" id="radio2" value="2" onClick={(e) => it_period_chk(e.target.value)}/>
                        <label className="form-check-label" htmlFor="radio2">
                          재등록
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="period_kind" id="radio2" value="3" onClick={(e) => it_period_chk(e.target.value)}/>
                        <label className="form-check-label" htmlFor="radio3">
                          신규
                        </label>
                      </div>
                      <div style={{clear: "both"}}/>
                      <div id="start_date_show" style={{display: "none", marginTop: "10px"}}>
                        <JDatePicker className="form-control" aria-describedby="button-addon2" id="it_start_date" placeholder={"시작일"} onChange={(e) => it_period_chk()}/>
                        <JDatePicker className="form-control" aria-describedby="button-addon2" id="it_end_date" placeholder={"종료일"} style={{marginLeft: '10px'}} readOnly={true}/>
                        {/*시작일 : <input type="text" className="form-control wid100 input_date" placeholder="시작일" id="it_start_date"/>*/}
                        {/*&nbsp;&nbsp;*/}
                        {/*종료일 : <strong><span id="it_end_date_show" style={{paddingRight: '10px'}}></span></strong><input type="text" id="it_end_date" style={{display: 'none'}}/>*/}
                      </div>
                    </td>
                  </tr>
                  <tr id="auth_set" style={{display: "none"}}>
                    <th>인증번호</th>
                    <td className="txt_left">
                      <div className="d-flex">
                        <div className="d-flex mx-1">
                          <ChkAuthNum type={"locker_info_change"}/>
                        </div>
                      </div>
                    </td>
                  </tr>

                </table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </CmnCard>
  );
};
export default MemberGiftRegi;
