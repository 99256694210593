import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";


const ConfigWeeklySet = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/config/weekly/set/ready?year=${$('#s_year option:selected').val() || ''}`)
      .then((response) => {
        try {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            const start_dates = [];
            const end_dates = [];

            result?.data?.map((dataRow, i) => {
              const d_year = dataRow?.year;
              const d_month = dataRow?.month;
              const d_week = dataRow?.week;

              $(`#start_date_${d_month}_${d_week}`).val(dataRow?.start_date);
              $(`#end_date_${d_month}_${d_week}`).val(dataRow?.end_date);
            });
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {
    const params = {year: $('#s_year option:selected').val()};
    for (let month = 1; month <= 12; month++) {
      for (let weeks = 1; weeks <= 6; weeks++) {
        params[`start_date_${month}_${weeks}`] = $(`#start_date_${month}_${weeks}`).val();
        params[`end_date_${month}_${weeks}`] = $(`#end_date_${month}_${weeks}`).val();
      }
    }

    axios2
      .postEx(`/config/weekly/set`, params)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        alert('저장하였습니다.');
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const autoSet = () => {
    axios2
      .post(`/config/weekly/set/auto?year=${$('#s_year option:selected').val() || ''}`)
      .then((response) => {
        try {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            Object.keys(result.data).map(key => {
              const val = result?.data?.[key];
              $('#' + key).val(val);
              console.log(key, val);
            });
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3">
        <div className="pr-1">
          <span className="px-2">대상년도 :</span>
          <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          <button type="button" className="btn btn-sm btn-outline-point px-3 mx-1" onClick={() => autoSet()}>자동설정</button>
          <button type="button" className="btn btn-sm btn-outline-point px-3" onClick={() => goSubmit()}>저장</button>
        </div>
      </div>

      <div className="row row-cols-3 m-2">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
          <div className="col my-2">
            <table className="view-rounded sub_view">
              <colgroup>
                <col width="15%"/>
                <col width="*"/>
              </colgroup>
              <tr key={month}>
                <td colSpan="2" className="text-left py-2">
                  <span className="h6 bold700">{month}월</span>
                  <input type="hidden" name={`month[${month}]`} value={month}/>
                </td>
              </tr>
              {[1, 2, 3, 4, 5, 6].map((week) => {
                return (
                  <tr key={week}>
                    <th>{week}주차</th>
                    <td>
                      <div className="input-group" style={{width: "auto"}}>
                        <JDatePicker className="form-control table-inline"
                                     id={`start_date_${month}_${week}`}
                                     maxLength={10}
                        />
                        <p className="mx-2">~</p>
                        <JDatePicker className="form-control table-inline"
                                     id={`end_date_${month}_${week}`}
                                     maxLength={10}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </table>
          </div>
        ))}
      </div>

      <div className="mt-2 mb-4">
        <button type="button" className="btn btn-sm btn-point px-3" onClick={() => goSubmit()}>저장</button>
      </div>
    </CmnCard>
  );
};

export default ConfigWeeklySet;
