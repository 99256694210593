import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";

const EmployeeConfig2 = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    clear_data();
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/employee/em/config2/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.list);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit() {
    if ($('#sub_code').val() === '') {
      alert('직급구분코드를 입력해주세요');
      $('#sub_code').focus();
      return;
    }

    if ($('#sub_code_name').val() === '') {
      alert('직급구분명을 입력해주세요');
      $('#sub_code_name').focus();
      return;
    }

    axios2
      .postEx('/employee/em/config', {
        base_seq: $('#base_seq').val(),
        sub_code: $('#sub_code').val(),
        sub_code_name: $('#sub_code_name').val(),
        show_YN: $('#show_YN option:selected').val(),
        show_out: $('#show_out').val(),
        main_code_fk: "3"
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (data == "dup_id") {
          alert('동일한 직급구분코드가 존재합니다');
        } else if (data == "dup_name") {
          alert('동일한 직급구분명이 존재합니다');
        } else if (data == "in_ok") {
          alert("등록되었습니다");
          setRenew(Fn.getRenewTime());
        } else if (data == "up_ok") {
          alert("수정되었습니다");
          setRenew(Fn.getRenewTime());
        } else if (data == "del_ok") {
          alert("삭제되었습니다");
          setRenew(Fn.getRenewTime());
        } else if (data == "in_err") {
          alert("등록오류! 관리자에게 문의하세요");
        } else if (data == "up_err") {
          alert("수정오류! 관리자에게 문의하세요");
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function clear_data() {
    $('#base_seq').val('');
    $('#sub_code').val('');
    $('#sub_code_name').val('');
    $('#show_YN').val('Y');
    $('#show_out').val('');

    $('#sub_code').removeAttr('style');
    $('#sub_code').prop('readOnly', false);

    $('#sub_code_name').focus();
  }

  function copy_data(base_seq, sub_code, sub_code_name, show_YN, show_out) {
    $('#base_seq').val(base_seq);
    $('#sub_code').val(sub_code);
    $('#sub_code_name').val(sub_code_name);
    $('#show_YN').val(show_YN);
    $('#show_out').val(show_out);

    $('#sub_code').css('backgroundColor', "#fee9ec");
    $('#sub_code').prop('readOnly', true);

    $('#sub_code_name').focus();
  }

  function del_data(base_seq) {
    if (!window.confirm('해당 직급을 삭제하시겠습니까?')) return;

    axios2
      .post(`/employee/em/config?type=del&base_seq=${base_seq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          alert("삭제되었습니다.");
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: "both"}}>
        <div className="tabs">
          <input id="all" type="radio" name="tab_item" onClick={() => history.push("/employee/config")}/>
          <label className="tab_item" htmlFor="all">계약명칭관리</label>
          <input id="programming" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="programming" onClick={() => history.push("/employee/config2")}>계약형태관리</label>
          <input id="design" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="design" onClick={() => history.push("/employee/config3")}>부서관리</label>
        </div>
      </div>

      <div className="m-3 search_box" style={{maxWidth: "1035px"}}>
        <div className="p-3">
          <input type="hidden" id="base_seq"/>
          <table className="view-rounded sub_view">
            <colgroup>
              <col width="108px"/>
              <col width="*"/>
              <col width="108px"/>
              <col width="*"/>
              <col width="108px"/>
              <col width="*"/>
              <col width="108px"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>계약형태코드</th>
              <td><input type="text" className="form-control" aria-describedby="button-addon2" id="sub_code"/></td>
              <th>계약형태명</th>
              <td><input type="text" className="form-control" aria-describedby="button-addon2" id="sub_code_name"/></td>
              <th>전시순서</th>
              <td><input type="text" className="form-control" aria-describedby="button-addon2" id="show_out"/></td>
              <th>전시여부</th>
              <td>
                <select className="form-select" aria-label="Default select example" id="show_YN">
                  <option value="Y">전시</option>
                  <option value="N">미전시</option>
                </select>
              </td>
            </tr>
          </table>
          <div className="text-center mt-2">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
          </div>
        </div>
      </div>

      <div className="m-3 pb-2 table_list" style={{maxWidth: "1035px"}}>
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="35%"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>No</th>
            <th>코드</th>
            <th>계약형태명</th>
            <th>전시여부</th>
            <th>전시순서</th>
            <th>비고</th>
          </tr>

          {rets.map((list_row, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <strong>{list_row.sub_code}</strong>
              </td>
              <td className="text-left">
                <div style={{paddingLeft: '10px'}}>
                  <strong>{list_row.sub_code_name}</strong>
                </div>
              </td>
              <td>
                {list_row.show_YN === 'Y' ? (
                  <span className='text-success'>전시</span>
                ) : (
                  <span className='text-danger'>미전시</span>
                )}
              </td>
              <td>{list_row.show_out}</td>
              <td>
                <button
                  className="btn btn-ssm btn-outline-point"
                  onClick={() =>
                    copy_data(
                      btoa(list_row.seq),
                      list_row.sub_code,
                      list_row.sub_code_name,
                      list_row.show_YN,
                      list_row.show_out
                    )
                  }
                >
                  수정
                </button>
                <button
                  className="btn btn-ssm btn-outline-dark mx-1"
                  onClick={() => del_data(btoa(list_row.seq))}
                >
                  삭제
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </CmnCard>
  );
};

export default EmployeeConfig2;
