import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory, useLocation} from "react-router-dom";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";


const ConfigResearchView = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.baseSeq || '';

  const [result, setResult] = useState({});
  const [statusText, setStatusText] = useState();

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    axios2
      .post(`/config/research/view/ready?base_seq=${baseSeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);

          const nowDate = Fn.getCurrentDate();
          const startDate = result?.row?.start_date;
          const endDate = result?.row?.end_date;

          if (nowDate >= startDate && nowDate <= endDate) {
            setStatusText(<strong className="text-primary">진행중</strong>);
          } else if (nowDate < startDate) {
            setStatusText(<strong>예정</strong>);
          } else {
            setStatusText(<strong className="text-danger">종료</strong>);
          }
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{border: '1px solid #eee', borderRadius: '12px'}}>
        <div className="border-bottom text-left">
          <p className="px-3 py-2"><span className="h6 bold700">설문조사 상세보기</span></p>
        </div>
        {/* 컨텐츠 내용 시작 */}
        <div className="p-3">
          <table className="view-rounded sub_view">
            <colgroup>
              <col width="120px"/>
              <col width="*"/>
              <col width="120px"/>
              <col width="*"/>
              <col width="120px"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>제목</th>
              <td colSpan="5" className="text-left">{result?.row?.title}</td>
            </tr>
            <tr>
              <th>기간</th>
              <td className="text-left">{result?.row?.start_date} ~ {result?.row?.end_date}</td>
              <th>상태</th>
              <td className="text-left">{statusText}</td>
              <th>전시여부</th>
              <td className="text-left">
                {result?.row?.display_flag === 'Y' ? <span className="label label-primary">전시</span> : <span className="label label-danger">미전시</span>}
              </td>
            </tr>
            <tr>
              <th>내용</th>
              <td colSpan="5" className="text-left">
                {result?.result2?.map((row, index) => {
                  let all_cnt = row?.a1_cnt + row?.a2_cnt + row?.a3_cnt + row?.a4_cnt + row?.a5_cnt + row?.a6_cnt + row?.a7_cnt + row?.a8_cnt + row?.a9_cnt + row?.a10_cnt + row?.a11_cnt;

                  let a1_per = 0;
                  let a2_per = 0;
                  let a3_per = 0;
                  let a4_per = 0;
                  let a5_per = 0;
                  let a6_per = 0;
                  let a7_per = 0;
                  let a8_per = 0;
                  let a9_per = 0;
                  let a10_per = 0;
                  let a11_per = 0;

                  if (all_cnt) {
                    a1_per = ((row?.a1_cnt / all_cnt) * 100).toFixed(2);
                    a2_per = ((row?.a2_cnt / all_cnt) * 100).toFixed(2);
                    a3_per = ((row?.a3_cnt / all_cnt) * 100).toFixed(2);
                    a4_per = ((row?.a4_cnt / all_cnt) * 100).toFixed(2);
                    a5_per = ((row?.a5_cnt / all_cnt) * 100).toFixed(2);
                    a6_per = ((row?.a6_cnt / all_cnt) * 100).toFixed(2);
                    a7_per = ((row?.a7_cnt / all_cnt) * 100).toFixed(2);
                    a8_per = ((row?.a8_cnt / all_cnt) * 100).toFixed(2);
                    a9_per = ((row?.a9_cnt / all_cnt) * 100).toFixed(2);
                    a10_per = ((row?.a10_cnt / all_cnt) * 100).toFixed(2);
                    a11_per = ((row?.a11_cnt / all_cnt) * 100).toFixed(2);
                  }

                  return (
                    <dl id="POLL" className="m-2">
                      <dt className="text-left border-bottom py-3 h6 bold700">{row?.question}</dt>
                      <dd className="text-left">
                        {row?.answer_cnt >= 1 &&
                          <>
                            <p>{row?.answer1} ({a1_per}%)</p>
                            <li className="poll_graph my-2">
                              <p style={{width: a1_per + "%"}}></p>
                            </li>
                          </>
                        }
                        {row?.answer_cnt >= 2 &&
                          <>
                            <p>{row?.answer2} ({a2_per}%)</p>
                            <li className="poll_graph my-2">
                              <p style={{width: a2_per + "%"}}></p>
                            </li>
                          </>
                        }
                        {row?.answer_cnt >= 3 &&
                          <>
                            <p>{row?.answer3} ({a3_per}%)</p>
                            <li className="poll_graph my-2">
                              <p style={{width: a3_per + "%"}}></p>
                            </li>
                          </>
                        }
                        {row?.answer_cnt >= 4 &&
                          <>
                            <p>{row?.answer4} ({a4_per}%)</p>
                            <li className="poll_graph my-2">
                              <p style={{width: a4_per + "%"}}></p>
                            </li>
                          </>
                        }
                        {row?.answer_cnt >= 5 &&
                          <>
                            <p>{row?.answer5} ({a5_per}%)</p>
                            <li className="poll_graph my-2">
                              <p style={{width: a5_per + "%"}}></p>
                            </li>
                          </>
                        }
                        {(index === 0 && row?.answer_cnt >= 6) &&
                          <>
                            <p>{row?.answer6} ({a6_per}%)</p>
                            <li className="poll_graph my-2">
                              <p style={{width: a6_per + "%"}}></p>
                            </li>
                          </>
                        }
                        {(index === 0 && row?.answer_cnt >= 7) &&
                          <>
                            <p>{row?.answer7} ({a7_per}%)</p>
                            <li className="poll_graph my-2">
                              <p style={{width: a7_per + "%"}}></p>
                            </li>
                          </>
                        }
                        {(index === 0 && row?.answer_cnt >= 8) &&
                          <>
                            <p>{row?.answer8} ({a8_per}%)</p>
                            <li className="poll_graph my-2">
                              <p style={{width: a8_per + "%"}}></p>
                            </li>
                          </>
                        }
                        {(index === 0 && row?.answer_cnt >= 9) &&
                          <>
                            <p>{row?.answer9} ({a9_per}%)</p>
                            <li className="poll_graph my-2">
                              <p style={{width: a9_per + "%"}}></p>
                            </li>
                          </>
                        }
                        {(index === 0 && row?.answer_cnt >= 10) &&
                          <>
                            <p>{row?.answer10} ({a10_per}%)</p>
                            <li className="poll_graph my-2">
                              <p style={{width: a10_per + "%"}}></p>
                            </li>
                          </>
                        }
                        {(index === 0 && row?.answer_cnt >= 11) &&
                          <>
                            <p>{row?.answer11} ({a11_per}%)</p>
                            <li className="poll_graph my-2">
                              <p style={{width: a11_per + "%"}}></p>
                            </li>
                          </>
                        }
                      </dd>
                    </dl>
                  );
                })}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="m-4 text-center">
        <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.goBack()}>목록</button>
      </div>
    </CmnCard>
  );
};

export default ConfigResearchView;
