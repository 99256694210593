import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const EmployeeBlackList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/employee/emp/blacklist/ready?date_type=${$('#date_type option:selected').val() || ''}&s_start_date=${$('#s_start_date').val()}&s_end_date=${$('#s_end_date').val()}&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_status=${$('#s_status option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function status_change(base_seq, status) {
    axios2
      .postForm('/employee/emp/blacklist/state/change', {
        base_seq: base_seq,
        status: status,
      })
      .then((response) => {
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="date_type">
              <option value="A.register_date">등록일</option>
              <option value="A.release_date">해제일</option>
            </select>
          </div>
          <div className="pr-1 d-flex">
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id='s_start_date'/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id='s_end_date'/>
            </div>
          </div>
          <div className="pr-1">
            <select className="form-select sel_lg" aria-label="Default select example" id='s_branch_code'>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" id="s_status">
              <option value="">::상태::</option>
              <option value="Y">등록</option>
              <option value="N">해제</option>
            </select>
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="이름/핸드폰 검색"
              id="key"
            />
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>

        {/* 테이블 */}
        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_border table_wide_sh2">
            <colgroup>
              <col width="60px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="20%"/>
              <col width="*"/>
              <col width="20%"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>지점명</th>
              <th>성명</th>
              <th>직무구분</th>
              <th>부서</th>
              <th>직급</th>
              <th>등록일</th>
              <th>등록사유</th>
              <th>해제일</th>
              <th>해제사유</th>
              <th>상태</th>
            </tr>

            {rets.map((ret, i) => {
              const count = i + 1;
              const seq = ret?.seq;
              const user_name = ret?.user_name;
              const position_name = ret?.position_name;
              const part_name = ret?.part_name;
              const new_position_name = ret?.new_position_name;
              const register_date = ret?.register_date;
              const register_reason = ret?.register_reason;
              const release_date = ret?.release_date;
              const release_reason = ret?.release_reason;
              const status = ret?.status;
              const branch_name = ret?.branch_name;

              let status_txt;
              if (status === "Y") {
                status_txt = <font color="red">등록</font>;
              } else {
                status_txt = <font color="blue">해제</font>;
              }

              const base_seq = btoa(seq);

              return (
                <tr key={i}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px'}}>{branch_name}</div>
                  </td>
                  <td>{user_name}</td>
                  <td>{position_name}</td>
                  <td>{part_name}</td>
                  <td>{new_position_name}</td>
                  <td>{register_date?.substring(2, 10)?.replaceAll("-", ".")}</td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px'}}>{register_reason}</div>
                  </td>
                  <td>{release_date?.substring(2, 10)?.replaceAll("-", ".")}</td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px'}}>{release_reason}</div>
                  </td>
                  <td>
                    {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 || localStorage.getItem("USER.systemManageYN") === "Y" ? (
                      <a onClick={() => status_change(base_seq, status)} style={{cursor: 'pointer'}}>
                        {status_txt}
                      </a>
                    ) : (
                      status_txt
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        <nav className="p-3 mb-4 d-flex justify-content-center border-top flex-wrap">
          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        </nav>
      </div>
    </CmnCard>
  );
};

export default EmployeeBlackList;
