import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const MvChangePoint = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const goSubmit = () => {
    if ($('#cp_basic_date').val() == "") {
      alert('대상일자를 선택해주세요');
      $('#cp_basic_date').focus();
      return;
    }
    if ($('#cp_point').val() == "" || $('#cp_point').val() == "0") {
      alert('조정포인트를 입력해주세요');
      $('#cp_point').focus();
      return;
    }

    if (window.confirm('포인트조정을 진행하시겠습니까?')) {
      axios2
        .postEx(`/member/change/point`, {
          auth_code: $('#auth_code').val(),
          mb_no: props.mbNo,
          reg_step: $('#_reg_step option:selected').val() || '',
          basic_date: $('#cp_basic_date').val(),
          point: $('#cp_point').val(),
          bigo: $('#cp_bigo').val(),
        })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data == "err") {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          } else {
            alert('등록되었습니다');
            props?.onCallback();
            onToggleModal();
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        $('#cp_basic_date').val(Fn.getCurrentDate());
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '900px'}}>
          <div className="POP_title">
            <h5><strong>포인트 조정</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <table className="view-rounded sub_view" style={{borderRadius: 0}}>
              <colgroup>
                <col width="90px"/>
                <col width="*"/>
                <col width="90px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>대상일</th>
                <td><JDatePicker type="date" className="form-control" aria-describedby="button-addon2" placeholder="" id="cp_basic_date" selectOnly={true}/></td>
                <th>조정포인트</th>
                <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" id="cp_point"/></td>
              </tr>
              <tr>
                <th>비고</th>
                <td colSpan="3"><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" id="cp_bigo"/></td>
              </tr>
            </table>
          </div>

          <div className="text-center mb-3">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit()}>저장</button>
            <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>닫기</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MvChangePoint;
