import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";


const FinanceFeeAdd = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [result, setResult] = useState({});

  useEffect(() => {
    $('#s_year').val(localStorage.getItem('fee_year') || Fn.getCurrentDate().split('-')[0])
    $('#s_month').val(localStorage.getItem('fee_month') || Fn.getCurrentDate().split('-')[1])
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/finance/fee/add/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          console.log(result);

          localStorage.setItem('fee_year', $('#s_year option:selected').val());
          localStorage.setItem('fee_month', $('#s_month option:selected').val());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/finance/fee/list/false")}>수수료 내역보고</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/finance/fee/public")}>퍼블릭 부서</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/finance/fee/pt")}>PT 부서</label>

          <input id="dd" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/finance/fee/add")}>추가/기타</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/finance/fee/incen")}>인센티브</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/finance/fee/incen/pt")}>PT 수당</label>

          <input id="gg" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/finance/fee/gx")}>GX 수당</label>

          <input id="hh" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/finance/fee/consult")}>영업컨설팅비</label>

          <input id="ii" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ii" onClick={() => history.push("/finance/fee/step")}>직원별 수수료</label>

          <input id="jj" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="jj" onClick={() => history.push("/finance/fee/overtime/list")}>추가근무관리</label>

          <input id="kk" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="kk" onClick={() => history.push("/finance/fee/etc")}>기타수당</label>

          <input id="ll" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ll" onClick={() => history.push("/finance/fee/branch/overprice")}>초과수당설정</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}년</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: "80px"}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="overflowX m-3 ">
        <div className="table_list table_wide_sh3 no-min-width" style={{minWidth: "1044px !important", maxWidth: "1044px"}}>
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <tr>
              <th>스텝명</th>
              <th>직무구분</th>
              <th>수수료부서</th>
              <th>추가수당</th>
              <th>기타수당</th>
              <th>합계</th>
            </tr>
            {result?.row?.map((item, index) => (
              <tr key={index}>
                <td>{item?.manager_name}</td>
                <td>{item?.position_name}</td>
                <td>{item?.pay_part_show}</td>
                <td className="text-right">{Fn.numberWithCommas(item?.over_prie)}</td>
                <td className="text-right">{Fn.numberWithCommas(item?.etc_price)}</td>
                <td className="text-right">{Fn.numberWithCommas(item?.over_prie + item?.etc_price)}</td>
              </tr>
            ))}
            {(!result?.row || result?.row?.length == 0) &&
              <tr>
                <td colSpan={6}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
            {result?.row?.length > 0 &&
              <tr className="bgcolor bold700">
                <td colSpan="3">합계</td>
                <td className="text-right">{Fn.numberWithCommas(result?.over_prie)}</td>
                <td className="text-right">{Fn.numberWithCommas(result?.etc_price)}</td>
                <td className="text-right">{Fn.numberWithCommas(result?.over_prie + result?.etc_price)}</td>
              </tr>
            }
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default FinanceFeeAdd;
