import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import {useHistory} from "react-router-dom";

const AnalysisLongPeriod = (props) => {
  const history = useHistory();

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [tab, setTab] = useState('default');
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    const currentDate = Fn.getCurrentDate();

    $('#s_year').val(currentDate.split("-")[0]);
    $('#s_month').val("01");
    $('#e_year').val(currentDate.split("-")[0]);
    $('#e_month').val(currentDate.split("-")[1]);
  }, []);

  const onTab = (type) => {
    $('#s_brand, #s_jibu').val('');

    const currentDate = Fn.getCurrentDate();

    $('#s_year').val(currentDate.split("-")[0]);
    $('#s_month').val("01");
    $('#e_year').val(currentDate.split("-")[0]);
    $('#e_month').val(currentDate.split("-")[1]);

    setCurrPage(1);
    setTab(type);
    setTimeout(() => {
      if (type === 'default') {
        $('#default').prop("checked", true);
      } else {
        $('#pt').prop("checked", true);
      }
    }, 150);
  }

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [tab, currPage]);

  const load = (_page) => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7 && localStorage.getItem("USER.systemFranYN") === "Y") {
      alert("접근 권한이 없습니다.");
      history.back();
      return;
    }

    const page = _page || currPage || 1;

    let params = `s_brand=${$('#s_brand option:selected').val() || ''}&s_jibu=${$('#s_jibu option:selected').val() || ''}&s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&e_year=${$('#e_year option:selected').val() || ''}&e_month=${$('#e_month option:selected').val() || ''}&gubun=${$('#gubun option:selected').val() || ''}&over=${$('#over:checked').val() || ''}&page=${page}`;

    let url = '';
    if (tab === 'default') url = `/analysis/long/period/ready?search_YN=Y&${params}`;
    else if (tab === 'pt') url = `/analysis/long/period/pt/ready?search_YN=Y&${params}`;

    if (!$("#overlay").is(":visible"))
      Fn.showSpinner();

    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_brand').html() === '') {
            let optionsHTML = '<option value="">전체 브랜드</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_brand').html(optionsHTML);
          }
          if ($('#s_jibu').html() === '') {
            let optionsHTML = '<option value="">지부선택</option>';
            result?.jibuRs?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.jibu}">${list_row.jibu}</option>`;
            });
            $('#s_jibu').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const s_form = document.getElementById('s_form');

  const go_submit = () => {
    $(".loading_bg").fadeIn("fast");
    document.getElementById('s_form').submit();
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3">
        <div className="tabs">
          <input id="default" type="radio" name="tab_item" defaultChecked onClick={() => onTab('default')}/>
          <label className="tab_item" htmlFor="ee" onClick={() => onTab('default')}>장기등록 랭킹</label>
          <input id="pt" type="radio" name="tab_item" onClick={() => onTab('pt')}/>
          <label className="tab_item" htmlFor="aa" onClick={() => onTab('pt')}>PT 랭킹</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_brand">
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_jibu">
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: '80px'}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <p className="p-1">~</p>
        <div className="pr-1">
          <select className="form-select" id="e_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: '80px'}} id="e_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" id="gubun">
            <option value="1">1개월</option>
            <option value="2">2개월</option>
            <option value="3">3개월</option>
            <option value="6">6개월</option>
            <option value="12">12개월</option>
            <option value="x">12개월이상</option>
          </select>
        </div>
        <div className="p-1 vertical-middle">
          <span className="p-1">이상</span>
          <input type="checkbox" className="form-controls" id="over" value="Y"/>
        </div>
        <div className="px-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3 table_list" style={{width: "935px"}}>
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border">
          {tab === 'default' &&
            <>
              <colgroup>
                <col width="90px"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>랭킹</th>
                <th>지점명</th>
                <th>스텝명</th>
                <th>매출건수</th>
                <th>매출액</th>
              </tr>
            </>
          }
          {tab === 'pt' &&
            <>
              <colgroup>
                <col width="90px"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>랭킹</th>
                <th>지점명</th>
                <th>트레이너명</th>
                <th>PT등록금액</th>
                <th>PT등록건수</th>
              </tr>
            </>
          }
          {tab === 'default' && rets && rets.map((row, index) => {
            return (
              <tr key={index}>
                <td height="39">
                  <div align="center">{index + 1}</div>
                </td>
                <td>
                  <div align="left" style={{paddingLeft: '10px'}}>
                    {row?.branch_name}
                  </div>
                </td>
                <td>
                  <div align="center">{row?.reg_step}</div>
                </td>
                <td>
                  <div align="right" style={{paddingRight: '10px'}}>
                    {Fn.numberWithCommas(row?.machul_cnt)}
                  </div>
                </td>
                <td>
                  <div align="right" style={{paddingRight: '10px'}}>
                    {Fn.numberWithCommas(row?.machul_price)}
                  </div>
                </td>
              </tr>);
          })}
          {tab === 'pt' && rets && rets.map((row, index) => {
            return (
              <tr key={index}>
                <td height="39">{index + 1}</td>
                <td>
                  <div align="left" style={{paddingLeft: '10px'}}>
                    {row?.ggRow?.branch_name}
                  </div>
                </td>
                <td>{row?.trainer_name}</td>
                <td>
                  <div align="right" style={{paddingRight: '10px'}}>
                    {Fn.numberWithCommas(row.machul_price)}
                  </div>
                </td>
                <td>
                  <div align="right" style={{paddingRight: '10px'}}>
                    {Fn.numberWithCommas(row.machul_cnt)}
                  </div>
                </td>
              </tr>);
          })}
        </table>
      </div>

    </CmnCard>
  );
};

export default AnalysisLongPeriod;
