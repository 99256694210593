import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import JDatePicker from "../common/JDatePicker";
import {useHistory, useLocation} from "react-router-dom";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";

const AnalysisPointHistoryBranch = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const location = useLocation();
  const tblGubun = location?.state?.tblGubun || '';
  const sDate1 = location?.state?.sDate1 || '';
  const sDate2 = location?.state?.sDate2 || '';

  const [result, setResult] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  const history = useHistory();

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    const daysAgo = new Date(currentDate);
    daysAgo.setDate(daysAgo.getDate() - 1);

    if (!sDate2) $('#s_date2').val(Fn.getDate(daysAgo));
    else $('#s_date2').val(sDate2);

    if (!sDate1) $('#s_date1').val(Fn.getDate(monthsAgo));
    else $('#s_date1').val(sDate1);

    if (!tblGubun) $('#tbl_gubun').val('now');
    else $('#tbl_gubun').val(tblGubun);
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/analysis/point/history/branch/ready?tbl_gubun=${$('#tbl_gubun option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex justify-content-between m-3 flex-wrap">
        <p className="h6 bold700 p-1">지점별 합계</p>
        <div className="d-flex flex-wrap">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: "32%"}} id="tbl_gubun">
              <option value="now">현재</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
            </select>
          </div>
          <div className="input-group pr-1" style={{width: "auto"}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="카드/성명/휴대폰 검색" id="key"/>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            <button type="button" className="btn btn-sm btn-dark mx-1">엑셀저장</button>
            <button type="button" className="btn btn-sm btn-dark"
                    onClick={() => {
                      history.push({
                        pathname: '/analysis/point/history', state: {
                          tblGubun: $('#tbl_gubun option:selected').val(),
                          sDate1: $('#s_date1').val(),
                          sDate2: $('#s_date2').val()
                        }
                      });
                    }}>
              지점별 리스트
            </button>
          </div>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border auto">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>지점명</th>
            <th>적립</th>
            <th>사용</th>
            <th>소멸</th>
          </tr>

          {result?.topList?.map((row, i) =>
            <tr>
              <td>{i + 1}</td>
              <td>{row?.branch_name}</td>
              <td>{Fn.numberWithCommas(result?.plusPoint?.[row?.branch_code])}</td>
              <td>{Fn.numberWithCommas(result?.usePoint?.[row?.branch_code])}</td>
              <td>{Fn.numberWithCommas(result?.delPoint?.[row?.branch_code])}</td>
            </tr>
          )}
        </table>
      </div>

    </CmnCard>
  );
};

export default AnalysisPointHistoryBranch;
