import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import $ from "jquery";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";


const FinancePrepaid = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [result, setResult] = useState({});
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  useEffect(() => {
    $('#s_date1').val(Fn.getCurrentDate());
  }, []);

  useEffect(() => {
    load();
  }, [renew, srchRenew]);

  const load = () => {
    console.log(`/finance/finance/prepaid/ready?s_date1=${$('#s_date1').val()}`);
    axios2
      .post(`/finance/finance/prepaid/ready?s_date1=${$('#s_date1').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //1-1
  const vv = document.getElementById('vv');
  const ck = document.getElementById('ck');
  const f = document.getElementById('f');
  const insuSpan = document.getElementById('insuSpan');
  const handleInsertDate = () => {
    document.f.amt.value = vv;
    document.f.ck_no.value = ck;

    const form = document.getElementsByTagName('form')[1];
    const formData = new FormData(form);

    fetch('finance_prepaid.php', {
      method: 'POST',
      body: formData
    })
      .then(response => response.text())
      .then(data => {
        data = data?.message?.trim();
        if (data === "refresh") {
          setRenew(Fn.getRenewTime());
        } else if (data === "err1") {
          alert('인증번호가 일치하지 않습니다');
          f.insu.value = '';
          f.insu.focus();
        } else if (data === "err2") {
          alert('설정오류! 시스템 관리자에게 문의하세요.');
          f.insu.value = '';
          f.insu.focus();
        } else {
          alert("처리되었습니다");
          setRenew(Fn.getRenewTime());
        }
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const loadModal = () => {
    if ($('#modal_s_branch_code').html() !== '') {
      const branchCode = $('#modal_s_branch_code option:selected').val() || '';
      if (!branchCode) {
        alert('지점을 선택해 주세요.');
        return;
      }
    }

    axios2
      .post(`/finance/pop/finance/prepaid/ready?search_YN=Y&s_branch_code=${$('#modal_s_branch_code option:selected').val() || ''}&s_date1=${$('#modal_s_date1').val()}&s_date2=${$('#modal_s_date2').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#modal_s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#modal_s_branch_code').html(optionsHTML);
          }

          setModalResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function insert_date(vv, ck, i) {
    const f = $('#f')[0];

    f.amt.value = vv;
    f.ck_no.value = ck;

    /*
    document.f.target='common_i';
    document.f.action='finance_prepaid.php';
    document.f.submit();
    */
    var form = f;
    var formData = new FormData(form);
    $.ajax({
      url: '/finance/finance/prepaid?insu=' + $('#insu_' + i).val(),
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data?.message?.trim();
//				document.write(data); return;
        if (data == "refresh") {
          setRenew(Fn.getRenewTime());
        } else if (data == "err1") {
          alert('인증번호가 일치하지 않습니다');
          f.insu.value = '';
          f.insu.focus();
        } else if (data == "err2") {
          alert('설정오류! 시스템 관리자에게 문의하세요.');
          f.insu.value = '';
          f.insu.focus();
        } else {
          alert("처리되었습니다");
          setRenew(Fn.getRenewTime());
        }
      },
      error: function (e) {
        alert('데이터오류! 시스템 관리자에게 문의하세요');
      }
    });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <form name="f" id="f" method="post">
        <input type="hidden" name="amt"/>
        <input type="hidden" name="ck_no"/>
        <div className="m-3 d-flex flex-nowrap">
          <JDatePicker className="form-control" id="s_date1"/>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 8 ?
              <button type="button" className="btn btn-sm btn-dark" onClick={() => setModalOpen(true)}>기간별 조회</button>
              :
              <button type="button" className="btn btn-sm btn-dark" onClick={() => alert('본사사용자만 사용가능합니다')}>기간별 조회</button>
            }
          </div>
        </div>

        <div className="m-3 pb-2">
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="150px"/>
              <col width="150px"/>
              <col width="*"/>
              <col width="150px"/>
              <col width="150px"/>
              <col width="150px"/>
              <col width="150px"/>
              <col width="150px"/>
            </colgroup>
            <tr>
              <th>시간</th>
              <th>거래처(회원)</th>
              <th>내용</th>
              <th>입금</th>
              <th>출금</th>
              <th>잔액</th>
              <th>등록자</th>
              <th>인수자</th>
            </tr>
            <tr className="bgcolor bold700">
              <td colSpan="5" className="text-right">전일잔액</td>
              <td className="text-right">{Fn.numberWithCommas(result?.jan)}</td>
              <td colSpan="2"></td>
            </tr>
            {result?.result?.map((row, i) =>
              <tr>
                <td>{row?.ckk_time}</td>
                <td>{row?.ck_sangho}</td>
                <td className="text-left">{row?.etc}</td>
                <td className="text-right">{Fn.numberWithCommas(row?.ck_amtcha)}</td>
                <td className="text-right">{Fn.numberWithCommas(row?.ck_amtdae)}</td>
                <td className="text-right">{row?.jan}</td>
                <td>{row?.ckk_step}</td>
                <td>
                  {
                    i == result?.result?.length - 1 && !row?.in_step
                      ?
                      <input type="password" name={`insu_${i}`} id={`insu_${i}`} onKeyDown={e => {
                        if (e.keyCode == 13) {
                          insert_date(row?.jan, row?.ck_no, i);
                        }
                      }}/>
                      :
                      row?.in_step
                  }
                </td>
              </tr>
            )}
          </table>
        </div>
      </form>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          $('#modal_s_date1').val(Fn.getCurrentDate());
          $('#modal_s_date2').val(Fn.getCurrentDate());
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01_wide p_absolute_mid_pop">
            <div className="POP_title">
              <h5><strong>전도금 기간별 조회</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box overflowX w-100">

              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select className="form-select" id="modal_s_branch_code">
                  </select>
                </div>
                <div className="d-flex pr-1">
                  <JDatePicker className="form-control" id="modal_s_date1"/>
                  <p className="mx-2">~</p>
                  <JDatePicker className="form-control" id="modal_s_date2"/>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal()}>검색</button>
                </div>
              </div>

              {/* 테이블 */}
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <tr>
                    <th>일자</th>
                    <th>시간</th>
                    <th>거래처(회원)</th>
                    <th>내용</th>
                    <th>입금</th>
                    <th>출금</th>
                    <th>잔액</th>
                    <th>등록자</th>
                  </tr>
                  <tr className="bgcolor bold700">
                    <td colSpan="5" className="text-right">전일잔액</td>
                    <td className="text-right">{Fn.numberWithCommas(modalResult?.jan || 0)}</td>
                    <td colSpan="2"></td>
                  </tr>
                  {modalResult?.result?.map((row, i) =>
                    <tr>
                      <td>{row?.ck_date}</td>
                      <td>{row?.ckk_time}</td>
                      <td>{row?.ck_sangho}</td>
                      <td className="text-left">{row?.ip_etc || '' + " " + row?.ckk_etc || ''}</td>
                      <td className="text-right">{Fn.numberWithCommas(row?.ck_amtcha || 0)}</td>
                      <td className="text-right">{Fn.numberWithCommas(row?.ck_amtdae || 0)}</td>
                      <td className="text-right">{Fn.numberWithCommas(row?.jan || 0)}</td>
                      <td>{row?.ckk_step}</td>
                    </tr>
                  )}
                </table>
              </div>

              <div className="text-center my-3">
                <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModalOpen(false)}>닫기</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </CmnCard>
  );
};

export default FinancePrepaid;
