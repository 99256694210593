import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";

// 스크립트 없음
const AnalysisBranchPricePt = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 6);

    $('#e_year').val(Fn.getDate(currentDate).split('-')[0]);
    $('#e_month').val(Fn.getDate(currentDate).split('-')[1]);
    $('#s_year').val(Fn.getDate(monthsAgo).split('-')[0]);
    $('#s_month').val(Fn.getDate(monthsAgo).split('-')[1]);
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/analysis/branch/price/pt/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&e_year=${$('#e_year option:selected').val() || ''}&e_month=${$('#e_month option:selected').val() || ''}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 10}, (_, index) => new Date().getFullYear() - 9 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: "80px"}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <p className="mx-2">~</p>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: "80px"}} id="e_year">
            {Array.from({length: 10}, (_, index) => new Date().getFullYear() - 9 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: "80px"}} id="e_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          <button type="button" className="btn btn-sm btn-dark mx-1">엑셀저장</button>
        </div>
      </div>

      <div className="m-3 table_list">
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>회원번호</th>
            <th>회원명</th>
            <th>PT횟수</th>
            <th>결제금액</th>
            <th>회당단가</th>
            <th>진행횟수</th>
            <th>실매출액</th>
            <th>남은금액</th>
          </tr>
          {rets && rets.map((ret, i) => {
            const real_price = (ret?.pt_danga || 0) * (ret?.take_cnt || 0);
            const remain_price = (ret?.pt_danga || 0) * ((ret?.pt_cnt || 0) - (ret?.take_cnt || 0));

            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>{ret?.mb_id}</td>
                <td>{ret?.mb_name}</td>
                <td className="text-right">{Fn.numberWithCommas(ret?.pt_cnt)}</td>
                <td className="text-right">{Fn.numberWithCommas(ret?.ap_amt)}</td>
                <td className="text-right">{Fn.numberWithCommas(ret?.pt_danga)}</td>
                <td className="text-right">{Fn.numberWithCommas(ret?.take_cnt)}</td>
                <td className="text-right">{Fn.numberWithCommas(real_price)}</td>
                <td className="text-right">{Fn.numberWithCommas(remain_price)}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={9}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-center border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

    </CmnCard>
  );
};

export default AnalysisBranchPricePt;
