import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const MessagePushSendList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [tab, setTab] = useState('manual');
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#s_date1').val(`${year}-${month}-01`);
    $('#s_date2').val(today);
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  const onTab = (type) => {
    {
      const today = Fn.getCurrentDate();
      const [year, month] = today.split("-");

      $('#s_date1').val(`${year}-${month}-01`);
      $('#s_date2').val(today);

      $('#tbl_gubun').val('now');
      $('#s_branch_code').val('');
      $('#key').val('');
      $('#s_gcm_url').val('');
    }

    setCurrPage(1);
    setTab(type);
    setTimeout(() => {
      if (type === 'auto') {
        $('#auto').prop("checked", true);
      } else {
        $('#manual').prop("checked", true);
      }
    }, 150);
  }

  function getGcmUrlShow(gcm_url) {
    switch (gcm_url) {
      case '/cscenter/notice_list.html':
        return '공지사항';
      case '/main/main.html':
        return '마이홈';
      case '/set/set_main.html':
        return '마이페이지';
      default:
        return '메인';
    }
  }

  function getGubunShow(gubun) {
    return gubun === 'A' ? '회원' : '스텝';
  }

  useEffect(() => {
    load();
  }, [tab, currPage]);

  const load = (_page) => {
    const page = _page || currPage || 1;

    let url = '';
    const params = `tbl_gubun=${$('#tbl_gubun option:selected').val() || ''}&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&key=${$('#key').val()}&s_gcm_url=${$('#s_gcm_url option:selected').val() || ''}&page=${page}`;
    if (tab === 'manual') url = `/message/push/send/list/ready?${params}`;
    else if (tab === 'auto') url = `/message/push/send/list/auto/ready?${params}`;

    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="manual" type="radio" name="tab_item" defaultChecked onClick={() => onTab('manual')}/>
          <label className="tab_item" htmlFor="manual">수동 PUSH 발송</label>
          <input id="auto" type="radio" name="tab_item" onClick={() => onTab('auto')}/>
          <label className="tab_item" htmlFor="auto">자동 PUSH 발송</label>
        </div>
      </div>

      <div className="overflowX ">
        <div className="d-flex m-3 flex-wrap fn_search_box">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" id="tbl_gubun">
              <option value="now">2023.04 ~ 현재</option>
              <option value="2022">2022.01 ~ 2023.04</option>
              <option value="2021">2020.11 ~ 2022.01</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" id="s_branch_code">
            </select>
          </div>
          <div className="input-group pr-1" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="p-1 h7">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control width_shorts" placeholder="성명/회원번호 검색" id="key"/>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" id="s_gcm_url">
              <option value="">링크</option>
              <option value="all">메인</option>
              <option value="/cscenter/notice_list.html">공지사항</option>
              <option value="/main/main.html">마이홈</option>
              <option value="/set/set_main.html">마이페이지</option>
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 table_list">
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="65px"/>
              <col width="120px"/>
              <col width="120px"/>
              <col width="90px"/>
              <col width="130px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="80px"/>
            </colgroup>

            {tab === 'manual' &&
              <tr>
                <th>No</th>
                <th>지점</th>
                <th>회원번호</th>
                <th>회원명</th>
                <th>발송일시</th>
                <th>제목</th>
                <th>내용</th>
                <th>링크</th>
              </tr>
            }
            {tab === 'auto' &&
              <tr>
                <th>No</th>
                <th>지점</th>
                <th>대상구분</th>
                <th>수신자</th>
                <th>발송일시</th>
                <th>제목</th>
                <th>내용</th>
                <th>링크</th>
              </tr>
            }
            {tab === 'manual' && rets && rets.map((row, index) => {
              return (
                <tr key={index}>
                  <td className="w_70px">{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - index}</td>
                  <td style={{textAlign: 'center'}}>{row?.branch_name}</td>
                  <td>{row?.mb_id}</td>
                  <td>{row?.mb_name}</td>
                  <td>{row?.push_datetime?.replace(/-/g, '.')?.substr(2, 14)}</td>
                  <td style={{textAlign: 'left'}}>{row?.gcm_title}</td>
                  <td style={{textAlign: 'left'}}>{row?.gcm_msg}</td>
                  <td>{getGcmUrlShow(row?.gcm_url)}</td>
                </tr>
              );
            })}
            {tab === 'auto' && rets && rets.map((row, index) => {
              return (
                <tr key={index}>
                  <td className="w_70px">{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - index}</td>
                  <td style={{textAlign: 'left'}}>{row?.branch_name}</td>
                  <td>{getGubunShow(row?.gubun)}</td>
                  <td>{row?.mb_name}</td>
                  <td>{row?.reg_date?.replace(/-/g, '.')?.substr(2, 14)}</td>
                  <td style={{textAlign: 'left'}}>{row?.gcm_title}</td>
                  <td style={{textAlign: 'left'}}>{row?.gcm_msg}</td>
                  <td>{row?.link_type}</td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={8}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-center border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

    </CmnCard>
  );
};

export default MessagePushSendList;
