import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const BranchPtOTTakeMonth = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [totCnt, setTotCnt] = useState(0);
  const [totPrice, setTotPrice] = useState(0);
  const [totCntSub, setTotCntSub] = useState(0);
  const [totPriceSub, setTotPriceSub] = useState(0);

  const [sDate1, setSDate1] = useState();
  const [sDate2, setSDate2] = useState();

  const [rets, setRets] = useState([]);
  const [rets2, setRets2] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#s_date1').val(`${year}-${month}-01`);
    $('#s_date2').val(today);
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load();
    setTotCntSub(0);
    setTotPriceSub(0);
    setRets2([]);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = () => {
    axios2
      .post(`/branchop/ot/take/month/ready?ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setSDate1($('#s_date1').val());
          setSDate2($('#s_date2').val());
          let tot_cnt = 0, tot_price = 0;
          result?.result?.map((ret, i) => {
            tot_cnt += (ret?.ot_cnt || 0);
            tot_price += (ret?.ot_time || 0);
          });
          setTotCnt(tot_cnt);
          setTotPrice(tot_price);
          setRets(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const load2 = (tranier_seq) => {
    axios2
      .post(`/branchop/ot/take/month?tranier_seq=${tranier_seq}&s_date1=${sDate1}&s_date2=${sDate2}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          let tot_cnt = 0, tot_price = 0;
          result?.result?.map((ret, i) => {
            tot_cnt += (ret?.ot_cnt || 0);
            tot_price += (ret?.act_time || 0);
          });
          setTotCntSub(tot_cnt);
          setTotPriceSub(tot_price);
          setRets2(result?.result);
          console.log(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  // 별도 스크립트 없음

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex">
        <div className="wid_fix">
          <div className="d-flex m-3">
            <div className="pr-1">
              <select className="form-select" id="ddate" onChange={(e) => Fn.period(e.target.value)}>
                <option value="0">검색기간</option>
                <option value="1">오늘</option>
                <option value="2">이번달</option>
                <option value="3">전월</option>
                <option value="4">1분기</option>
                <option value="5">2분기</option>
                <option value="6">1,2분기</option>
                <option value="7">3분기</option>
                <option value="8">4분기</option>
                <option value="9">3,4분기</option>
                <option value="10">올해전체</option>
                <option value="11">전1년</option>
              </select>
            </div>
            <div className="input-group pr-1" style={{width: "auto"}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
          </div>

          <div className="m-3" style={{maxWidth: "600px"}}>
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border text-left">
              <colgroup>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
              </colgroup>

              <tr>
                <th>트레이너명</th>
                <th>진행횟수</th>
                <th>진행시간</th>
              </tr>
              {rets && rets.map((ret, i) => {
                return (
                  <tr>
                    <td className="text-center" style={{cursor: "pointer"}} onClick={() => load2(ret.trainer_seq)}>{ret.trainer_name}</td>
                    <td className="text-right">{Fn.numberWithCommas(ret?.ot_cnt)}</td>
                    <td className="text-right">{Fn.numberWithCommas(ret?.ot_time)}</td>
                  </tr>
                );
              })}
              <tr className="bgcolor bold700">
                <td className="text-center">소계</td>
                <td className="text-right">{Fn.numberWithCommas(totCnt)}</td>
                <td className="text-right">{Fn.numberWithCommas(totPrice)}</td>
              </tr>
            </table>
          </div>
        </div>

        <div style={{width: "calc(100% - 650px)"}}>
          <div className="tip_box tip_box2 w-100 my-3"><span className="p-3">Tip! 좌측 트레이너명을 click하시면 해당 상세 내역을 보실 수 있습니다</span></div>
          <div className="fixed_box" style={{top: "190px"}}>
            <p className="border-bottom p-2 px-3 bold700 text-left h7">OT진행 상세내역</p>
            <div className="p-3">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="70px"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="120px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>진행일</th>
                  <th>시작시간</th>
                  <th>성명</th>
                  <th>연락처</th>
                  <th>진행시간</th>
                </tr>
                {rets2 && rets2.map((ret, i) => {
                  let take_date = ret?.take_date?.replace(/-/g, '')?.substring(2, 10)?.replace(/(\d{2})(\d{2})(\d{2})/, '$1.$2.$3');

                  let mb_hp = ret?.mb_hp?.replace(/-/g, '');

                  let mb_hp_show;
                  if (mb_hp?.length === 11) {
                    mb_hp_show = `${mb_hp.substring(0, 3)}-${mb_hp.substring(3, 7)}-${mb_hp.substring(7, 11)}`;
                  } else if (mb_hp?.length === 10) {
                    mb_hp_show = `${mb_hp.substring(0, 3)}-${mb_hp.substring(3, 6)}-${mb_hp.substring(6, 10)}`;
                  } else {
                    mb_hp_show = mb_hp;
                  }

                  let price = `${ret.act_time}회`;

                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{take_date}</td>
                      <td>{ret?.take_time || ''}</td>
                      <td>{ret?.mb_name || ''}</td>
                      <td>{mb_hp_show}</td>
                      <td>{price}</td>
                    </tr>
                  );
                })}
                <tr className="bgcolor bold700">
                  <td className="text-center">소계</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{totPriceSub || 0}회</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </CmnCard>
  );
};

export default BranchPtOTTakeMonth;
