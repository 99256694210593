import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import {useHistory} from "react-router-dom";


const SalesSupplyList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew, srchRenew]);

  const load = () => {
    axios2
      .post(`/sales/supply/list/ready?keyfield=${$('#keyfield option:selected').val() || ''}&key=${encodeURIComponent($('#key').val())}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  // EXAMPLE
  const delIn = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 공급업체를 삭제하시겠습니까? 상품은 삭제되지 않습니다.')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/sales/supply/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <form name="form1" method="post">
        <div className="overflowX">
          <div className="d-flex m-3 table_wide_sh2 fn_search_box">
            <div className="pr-1">
              <select className="form-select" aria-label="Default select example" style={{width: "32%"}} id="keyfield">
                <option value="A.shop_name">공급업체명</option>
                <option value="A.shop_ceo">대표자</option>
                <option value="A.shop_manager">담당자</option>
              </select>
            </div>

            <div className="pr-1">
              <input type="text" className="form-control" placeholder="상품명/바코드 검색" id="key"/>
            </div>

            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
              <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => history.push("/sales/supply/reg")}>공급업체추가</button>
            </div>
          </div>

          <div className="m-3 pb-2 table_list table_wide_sh2">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border auto">
              <colgroup>
                <col width="60px"/>
                <col width="70px"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width=""/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
              </colgroup>

              <tr>
                <th>No</th>
                <th>
                  <input type="checkbox" onClick={(e) => {
                    if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                    else $('[id^=achk]').prop("checked", false);
                  }}/>
                </th>
                <th>업체명</th>
                <th>대표자</th>
                <th>아이디</th>
                <th>대표번호</th>
                <th>팩스</th>
                <th>담당자</th>
                <th>연락처</th>
                <th>거래시작일</th>
                <th>품목수</th>
                <th>상태</th>
              </tr>
              {rets && rets.map((ret, i) =>
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    {/*TODO : SystemAuth >=9 ?*/}
                    <input
                      type="checkbox"
                      name="chk"
                      value={btoa(ret?.seq)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  </td>
                  <td className="text-left"
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        history.push({
                          pathname: '/sales/supply/reg', state: {
                            baseSeq: btoa(ret?.seq)
                          }
                        });
                      }}
                  >{ret?.shop_name}</td>
                  {/*TODO : sales_reg 으로 이동 처리*/}
                  <td>{ret?.shop_ceo}</td>
                  <td>{ret?.shop_id}</td>
                  <td>{ret?.shop_tel}</td>
                  <td>{ret?.shop_fax}</td>
                  <td>{ret?.shop_manager}</td>
                  <td>{ret?.shop_manager_tel}</td>
                  <td>{ret?.shop_in_date}</td>
                  <td>{ret?.prd_num}</td>
                  <td>{ret?.shop_state === 'Y' ? <span>정상</span> : <span style={{color: 'red'}}>종료</span>}</td>
                </tr>)}
            </table>
          </div>
        </div>
      </form>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div>
            <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => delIn()}>삭제</button>
          </div>
        </div>
      </nav>
    </CmnCard>
  );
};

export default SalesSupplyList;
