import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const MvIpkumDateChange = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const load = () => {
    axios2
      .post(`/member/ipkum/date/change/ready?ip_no=${$('#cr_ip_no').val() || ''}&mb_no=${props.mbNo}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const goSubmit = () => {
    const form1 = $('#form1')[0];

    if (form1.ip_date.value == "") {
      alert('변경 입금일자를 입력해주세요');
      return;
    }

    var formData = new FormData(form1);
    $.ajax({
      url: '/member/ipkum/date/change',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data?.message?.trim();
        if (data == "err") {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        } else {
          alert('변경되었습니다');
          props?.onCallback();
          onToggleModal();
        }
      }
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        $('#ip_date').val(Fn.getCurrentDate());
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '600px'}}>
          <div className="POP_title">
            <h5><strong>입금일자 변경</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <form name="form1" id="form1" method="POST">
              <input type="hidden" name="mb_no" value={props.mbNo}/>
              <input type="hidden" name="ip_no" value={$('#cr_ip_no').val() || ''}/>
              <input type="hidden" name="auth_code" value={$('#auth_code').val() || ''}/>
              <input type="hidden" name="before_date" value={result?.result?.ip_date}/>
              <input type="hidden" name="ip_amt" value={result?.result?.ip_amt}/>
              <input type="hidden" name="category" value={result?.result?.category || ''}/>
              <input type="hidden" name="receive_name" value={$('#_reg_step option:selected').val() || ''}/>
              <table className="view-rounded sub_view" style={{borderRadius: 0}}>
                <colgroup>
                  <col width="90px"/>
                  <col width="*"/>
                  <col width="90px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>기존입금일</th>
                  <td>{result?.result?.ip_date}</td>
                  <th>변경입금일</th>
                  <td><JDatePicker type="date" className="form-control" aria-describedby="button-addon2" placeholder="" name="ip_date" id="ip_date" selectOnly={true}/></td>
                </tr>
              </table>
            </form>
          </div>

          <div className="text-center mb-3">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit()}>저장</button>
            <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>닫기</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MvIpkumDateChange;
