import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";

import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";


const MessageTmReg = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [row, setRow] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
    $('#s_month').val(Fn.getCurrentDate().split("-")[1]);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/message/tm/reg/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRow(result?.row);

          $('#target1').val(result?.row?.target1);
          $('#period1').val(result?.row?.period1);
          $('#connect1').val(result?.row?.connect1);
          $('#no_connect1').val(result?.row?.no_connect1);
          $('#no_number1').val(result?.row?.no_number1);
          $('#yes_man1').val(result?.row?.yes_man1);
          $('#think_man1').val(result?.row?.think_man1);
          $('#no_man1').val(result?.row?.no_man1);
          $('#return_num1').val(result?.row?.return_num1);
          $('#return_rate1').val(result?.row?.return_rate1);

          $('#target2').val(result?.row?.target2);
          $('#period2').val(result?.row?.period2);
          $('#connect2').val(result?.row?.connect2);
          $('#no_connect2').val(result?.row?.no_connect2);
          $('#no_number2').val(result?.row?.no_number2);
          $('#yes_man2').val(result?.row?.yes_man2);
          $('#think_man2').val(result?.row?.think_man2);
          $('#no_man2').val(result?.row?.no_man2);
          $('#return_num2').val(result?.row?.return_num2);
          $('#return_rate2').val(result?.row?.return_rate2);

          $('#target3').val(result?.row?.target3);
          $('#period3').val(result?.row?.period3);
          $('#connect3').val(result?.row?.connect3);
          $('#no_connect3').val(result?.row?.no_connect3);
          $('#no_number3').val(result?.row?.no_number3);
          $('#yes_man3').val(result?.row?.yes_man3);
          $('#think_man3').val(result?.row?.think_man3);
          $('#no_man3').val(result?.row?.no_man3);
          $('#return_num3').val(result?.row?.return_num3);
          $('#return_rate3').val(result?.row?.return_rate3);

          $('#target4').val(result?.row?.target4);
          $('#period4').val(result?.row?.period4);
          $('#connect4').val(result?.row?.connect4);
          $('#no_connect4').val(result?.row?.no_connect4);
          $('#no_number4').val(result?.row?.no_number4);
          $('#yes_man4').val(result?.row?.yes_man4);
          $('#think_man4').val(result?.row?.think_man4);
          $('#no_man4').val(result?.row?.no_man4);
          $('#return_num4').val(result?.row?.return_num4);
          $('#return_rate4').val(result?.row?.return_rate4);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit() {
    axios2.postEx("/message/tm/reg", {
      s_year: $('#s_year option:selected').val(),
      s_month: $('#s_month option:selected').val(),

      target1: $('#target1').val(),
      period1: $('#period1').val(),
      connect1: $('#connect1').val(),
      no_connect1: $('#no_connect1').val(),
      no_number1: $('#no_number1').val(),
      yes_man1: $('#yes_man1').val(),
      think_man1: $('#think_man1').val(),
      no_man1: $('#no_man1').val(),
      return_num1: $('#return_num1').val(),
      return_rate1: $('#return_rate1').val(),

      target2: $('#target2').val(),
      period2: $('#period2').val(),
      connect2: $('#connect2').val(),
      no_connect2: $('#no_connect2').val(),
      no_number2: $('#no_number2').val(),
      yes_man2: $('#yes_man2').val(),
      think_man2: $('#think_man2').val(),
      no_man2: $('#no_man2').val(),
      return_num2: $('#return_num2').val(),
      return_rate2: $('#return_rate2').val(),

      target3: $('#target3').val(),
      period3: $('#period3').val(),
      connect3: $('#connect3').val(),
      no_connect3: $('#no_connect3').val(),
      no_number3: $('#no_number3').val(),
      yes_man3: $('#yes_man3').val(),
      think_man3: $('#think_man3').val(),
      no_man3: $('#no_man3').val(),
      return_num3: $('#return_num3').val(),
      return_rate3: $('#return_rate3').val(),

      target4: $('#target4').val(),
      period4: $('#period4').val(),
      connect4: $('#connect4').val(),
      no_connect4: $('#no_connect4').val(),
      no_number4: $('#no_number4').val(),
      yes_man4: $('#yes_man4').val(),
      think_man4: $('#think_man4').val(),
      no_man4: $('#no_man4').val(),
      return_num4: $('#return_num4').val(),
      return_rate4: $('#return_rate4').val()
    })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();

        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else {
          alert("등록되었습니다.");
          setRenew(Fn.getRenewTime());
        }
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      })
      .finally(() => {
        // $(".loading_bg").fadeOut("fast");
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3">
        <div className="d-flex">
          <div className="pr-1">
            <select className="form-select" id="s_year">
              {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_month">
              {[...Array(12)].map((_, index) => {
                const monthNumber = index + 1;
                const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-6">
            <table className="view-rounded sub_view_sm">
              <tr>
                <th className="w-30">TM대상자</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="target1"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">진행기간</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="period1"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">연결된회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="connect1"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">부재중회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="no_connect1"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">없는번호</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="no_number1"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 의사가 있는 회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="yes_man1"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 고민중인 회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="think_man1"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 의사가 없는 회원</th>
                <td>
                  <textarea
                    className="form-control w-100"
                    id="no_man1"
                    rows={5}
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">회수량</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="return_num1"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">회수율</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="return_rate1"
                  />
                </td>
              </tr>
            </table>
          </div>

          <div className="col-6">
            <table className="view-rounded sub_view_sm">
              <tr>
                <th className="w-30">TM대상자</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="target2"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">진행기간</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="period2"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">연결된회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="connect2"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">부재중회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="no_connect2"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">없는번호</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="no_number2"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 의사가 있는 회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="yes_man2"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 고민중인 회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="think_man2"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 의사가 없는 회원</th>
                <td>
                  <textarea
                    className="form-control w-100"
                    id="no_man2"
                    rows={5}
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">회수량</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="return_num2"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">회수율</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="return_rate2"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="row my-3">
          <div className="col-6">
            <table className="view-rounded sub_view_sm">
              <tr>
                <th className="w-30">TM대상자</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="target3"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">진행기간</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="period3"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">연결된회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="connect3"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">부재중회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="no_connect3"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">없는번호</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="no_number3"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 의사가 있는 회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="yes_man3"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 고민중인 회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="think_man3"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 의사가 없는 회원</th>
                <td>
                  <textarea
                    className="form-control w-100"
                    id="no_man3"
                    rows={5}
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">회수량</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="return_num3"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">회수율</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="return_rate3"
                  />
                </td>
              </tr>
            </table>
          </div>

          <div className="col-6">
            <table className="view-rounded sub_view_sm">
              <tr>
                <th className="w-30">TM대상자</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="target4"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">진행기간</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="period4"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">연결된회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="connect4"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">부재중회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="no_connect4"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">없는번호</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="no_number4"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 의사가 있는 회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="yes_man4"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 고민중인 회원</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="think_man4"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">재등록 의사가 없는 회원</th>
                <td>
                  <textarea
                    className="form-control w-100"
                    id="no_man4"
                    rows={5}
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">회수량</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="return_num4"
                  />
                </td>
              </tr>
              <tr>
                <th className="w-30">회수율</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    id="return_rate4"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="my-4">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
        </div>
      </div>
    </CmnCard>
  );
};

export default MessageTmReg;
