import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import _ from "lodash";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";

const MemberService = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [g, setG] = useState([]);
  const [sRs, setSRs] = useState([]);
  const [resultDtl, setResultDtl] = useState([]);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#s_date1').val(`${year}-${month}-01`);
    $('#s_date2').val(today);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/member/member/service/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_ap_step=${$('#s_ap_step option:selected').val() || ''}&s_mb_name=${$('#s_mb_name').val()}&search_auth_code=&auth_code=&reg_step=`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $('.cls-result').remove();

          setG(result?.g);
          setSRs(result?.sRs);
          setResultDtl([]);

          if ($('#s_ap_step').html() === '') {
            let optionsHTML = '<option value="">::스텝명 선택::</option>';
            result?.regStepList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row}">${list_row}</option>`;
            });
            $('#s_ap_step').html(optionsHTML);
          }

          // sRs
          {
            let output = '';

            if (result?.sRs > 0) {
              result?.sRs.forEach((s_row, index) => {
                const count = index + 1;
                const td_class = count % 2 === 1 ? "class='cls-result active'" : " class='cls-result'";
                const td_class2 = count % 2 === 1 ? "class='cls-result active border-right'" : "class='cls-result border-right'";
                const sevice_name = s_row.sevice_name.replace("+", "||");

                output += `
                <td ${td_class}>${count}</td>
                <td class="cls-result" style="text-align:left;padding-left:5px;" ${td_class}>
                  <strong>
                    <a href="javascript:window.loadDtl('${s_row?.sevice_name}')">
                      ${s_row.sevice_name}
                    </a>
                  </strong>
                </td>
                <td ${td_class}>
                  <strong>
                    <a href="javascript:window.loadDtl('${s_row?.sevice_name}')">
                      ${s_row.count}
                    </a>
                  </strong>
                </td>
              `;

                if (count % 4 === 0) {
                  output += '</tr><tr class="cls-result">';
                }
              });
              for (let i = 0; i < 4 - (result?.sRs?.length % 4); i++) {
                output += `
                <td class="cls-result"></td>
                <td class="cls-result border-right"></td>
                <td class="cls-result"></td>
              `;
              }
              output = `<tr class="cls-result">${output}</tr>`;
              $('#rows1').after(output);
            } else {
              output = `<tr class="cls-result"><td colspan='12'>※ 등록된 data가 없습니다.</td></tr>`;
              $('#rows1').after(output);
            }
          }

          // g
          {
            let output = '';

            if (result?.g > 0) {
              result?.g.forEach((g_row, index) => {
                const count = index + 1;
                const td_class = count % 2 === 1 ? "class='cls-result active'" : " class='cls-result'";
                const td_class2 = count % 2 === 1 ? "class='cls-result active border-right'" : "class='cls-result border-right'";

                // TODO : PHP와 비교하여 링크 정상적으로 처리할 것
                output += `
                <td ${td_class}>${count}</td>
                <td class="cls-result" style="text-align:left;padding-left:5px;" ${td_class}>
                  <strong>
                    <a style="cursor: pointer;" onclick="window.loadDtl2('${g_row?.ap_step}')">
                      ${g_row?.ap_step}
                    </a>
                  </strong>
                </td>
                <td ${td_class}>
                  <strong>
                    <a style="cursor: pointer;" onclick="window.loadDtl2('${g_row?.ap_step}')">
                      ${g_row.cnt}
                    </a>
                  </strong>
                </td>
              `;

                if (count % 4 === 0) {
                  output += '</tr><tr class="cls-result">';
                }
              });
              for (let i = 0; i < 4 - (result?.g?.length % 4); i++) {
                output += `
                <td class="cls-result"></td>
                <td class="cls-result border-right"></td>
                <td class="cls-result"></td>
              `;
              }
              output = `<tr class="cls-result">${output}</tr>`;
              $('#rows2').after(output);
            } else {
              output = `<tr class="cls-result"><td colspan='12'>※ 등록된 data가 없습니다.</td></tr>`;
              $('#rows2').after(output);
            }
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  window.loadDtl = (sevice_name) => {
    if (!$('#auth_code').val()) {
      alert('인증코드를 입력해 주세요.');
      $('#auth_code').focus();
      return;
    }

    axios2.post(`/member/member/service/view2?sevice_name=${encodeURIComponent(sevice_name)}&s_date1=${$('#s_date1').val()}&s_ap_step=${$('#s_ap_step option:selected').val()}&s_date2=${$('#s_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&auth_code=${$('#auth_code').val()}`)
      .then(function (response) {
        const {code, message, result, pageInfo} = response?.data;
        setResultDtl(result?.result);
      })
      .catch(function (error) {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  window.loadDtl2 = (sApStep) => {
    if (!$('#auth_code').val()) {
      alert('인증코드를 입력해 주세요.');
      $('#auth_code').focus();
      return;
    }

    axios2.post(`/member/member/service/view?s_date1=${$('#s_date1').val()}&s_ap_step=${encodeURIComponent(sApStep)}&s_date2=${$('#s_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&auth_code=${$('#auth_code').val()}`)
      .then(function (response) {
        const {code, message, result, pageInfo} = response?.data;
        setResultDtl(result?.result);
      })
      .catch(function (error) {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const rows1 = (rets1) => {
    $('.d_rows1').remove()

    let html = '<tr class="d_rows1">';

    $.each(rets1, function (index, s_row) {
      var count = index + 1;

      html += `<td class="cls-result">${count}</td><td class="cls-result">${s_row.sevice_name || ''}</td><td class="cls-result">${s_row.count || ''}</td>`;

      if (count % 4 === 0) {
        html += "</tr><tr class='cls-result d_rows1'>";
      }
    });

    $('#rows1').after(html + "</tr>");
  }

  const rows2 = (rets2) => {
    $('.d_rows2').remove()

    let html = '<tr class="cls-result d_rows2">';

    $.each(rets2, function (index, s_row) {
      var count = index + 1;

      html += `<td class="cls-result">${count}</td><td class="cls-result">${s_row.ap_step || ''}</td><td class="cls-result">${s_row.cnt || ''}</td>`;

      if (count % 4 === 0) {
        html += "</tr><tr class='cls-result d_rows1'>";
      }
    });

    $('#rows2').after(html + "</tr>");
  }

  //  3-1.
  const make_monitor = () => {
    const as = window.open('/inc/black.html', 'enter_monitor', 'width=0,height=0,scrollbars=yes,top=10000,left=10000');
    as.window.close();
    const as2 = window.open('/enter/enter_monitor.html', 'enter_monitor', 'width=500,height=500,scrollbars=yes,top=600,left=1000');

    return () => {
      as2.close();
    };
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex justify-content-between m-3 fn_search_box">
        <div className="d-flex">
          <div className="pr-1 d-flex">
            <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: "keep-all"}}>기간선택 : </label>
            <div className="input-group" style={{width: "auto"}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
            </div>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: "32%"}} id="s_ap_step">
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="회원명 검색" id="s_mb_name"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>
        <div>
          <div className="d-flex">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
        </div>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="12" className="text-left px-3 p-2"><span className="h7 bold700">서비스</span></td>
          </tr>
          <tr id="rows1">
            <th>No</th>
            <th>서비스명</th>
            <th>수량</th>
            <th>No</th>
            <th>서비스명</th>
            <th>수량</th>
            <th>No</th>
            <th>서비스명</th>
            <th>수량</th>
            <th>No</th>
            <th>서비스명</th>
            <th>수량</th>
          </tr>
          {/*<tr>*/}
          {/*  <td id="rows1"/>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td>5</td>*/}
          {/*  <td className="text-left">서비스+1개월(운동복)</td>*/}
          {/*  <td>11</td>*/}
          {/*  <td>6</td>*/}
          {/*  <td className="text-left">서비스+1개월(라커)</td>*/}
          {/*  <td>11</td>*/}
          {/*  <td>7</td>*/}
          {/*  <td className="text-left">서비스+7일(헬스)</td>*/}
          {/*  <td>11</td>*/}
          {/*  <td>8</td>*/}
          {/*  <td className="text-left">서비스+10일(헬스)</td>*/}
          {/*  <td>11</td>*/}
          {/*</tr>*/}
        </table>
      </div>
      <div className="m-3">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="12" className="text-left px-3 p-2"><span className="h7 bold700">스텝</span></td>
          </tr>
          <tr id="rows2">
            <th>No</th>
            <th>스텝명</th>
            <th>수량</th>
            <th>No</th>
            <th>스텝명</th>
            <th>수량</th>
            <th>No</th>
            <th>스텝명</th>
            <th>수량</th>
            <th>No</th>
            <th>스텝명</th>
            <th>수량</th>
          </tr>
          {/*<tr>*/}
          {/*  <td>1</td>*/}
          {/*  <td className="text-left"></td>*/}
          {/*  <td>11</td>*/}
          {/*  <td>2</td>*/}
          {/*  <td className="text-left">김보경</td>*/}
          {/*  <td>11</td>*/}
          {/*  <td>3</td>*/}
          {/*  <td className="text-left">류경환</td>*/}
          {/*  <td>11</td>*/}
          {/*  <td>4</td>*/}
          {/*  <td className="text-left">서준</td>*/}
          {/*  <td>11</td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td>5</td>*/}
          {/*  <td className="text-left">신유정</td>*/}
          {/*  <td>11</td>*/}
          {/*  <td>6</td>*/}
          {/*  <td className="text-left">안혜민</td>*/}
          {/*  <td>11</td>*/}
          {/*  <td>7</td>*/}
          {/*  <td className="text-left">양병조</td>*/}
          {/*  <td>11</td>*/}
          {/*  <td>8</td>*/}
          {/*  <td className="text-left">양희주</td>*/}
          {/*  <td>11</td>*/}
          {/*</tr>*/}
          {/*<tr>*/}
          {/*  <td colSpan="12" className="text-right px-3 p-2">*/}
          {/*    <p className="h6 bold800">계 : 848</p>*/}
          {/*  </td>*/}
          {/*</tr>*/}
        </table>
      </div>

      {/*TODO : 처리할 것*/}
      <div className="m-3 mt-4 table_list">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="180px"/>
            <col width="30%"/>
          </colgroup>
          <tr>
            <th>No</th>
            <th>회원명</th>
            <th>회원카드</th>
            <th>연락처</th>
            <th>서비스명</th>
            <th>스텝명</th>
            <th>등록일시</th>
            <th>사유</th>
          </tr>
          {resultDtl && resultDtl.map((ret, i) => {

            return (
              <tr>
                <td>{i + 1}</td>
                <td>{ret?.mb_name}</td>
                <td>{ret?.mb_id}</td>
                <td>{ret?.mbHp}</td>
                <td className="text-left">{ret?.ap_pdtName || ret?.it_name}</td>
                <td>{ret?.ap_step}</td>
                <td>{ret?.ap_date} {ret?.ap_time}</td>
                <td className="text-left">{ret?.ap_etc}</td>
              </tr>
            );
          })}
          {(!resultDtl || resultDtl?.length === 0) &&
            <tr>
              <td colSpan={8}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>


    </CmnCard>
  );
};

export default MemberService;
