import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios from "axios";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import Constants from "../../constants/constants";
import Modal from "react-modal";
import Paging from "../common/Paging";

const BranchopInoutCalNew = (props) => {

  window._load_cal = (_page = 1) => {
    setSystemBranchCode(localStorage.getItem("USER.systemBranchCode"));
    if (localStorage.getItem("USER.systemBranchCode") !== "central") {
      setTimeout(() => {
        $('#aa').trigger("click");
        onTab('1');
        load();
      }, 500);
    } else {
      $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
      $('#s_month').val(Fn.getCurrentDate().split("-")[1]);
      load();
    }
  }

  const [tabType, setTabType] = useState('1');
  const [srchRenew, setSrchRenew] = useState(0);
  const [systemBranchCode, setSystemBranchCode] = useState(localStorage.getItem("USER.systemBranchCode"));

  const [modal1Open, setModal1Open] = useState(false);
  const [modal1Result, setModal1Result] = useState();
  const [modal1CurrPage, setModal1CurrPage] = useState(1);
  const [modal1PageInfo, setModal1PageInfo] = useState(1);
  const [modal1SrchRenew, setModal1SrchRenew] = useState(0);
  const [modal1BranchCode, setModal1BranchCode] = useState('');

  const onTab = (type) => {
    setTabType(type);
    load(type);

    setTimeout(() => {
      if (type === '1') {
        $('#aa').prop('checked', true);
        $('#bb').prop('checked', false);
      } else {
        $('#aa').prop('checked', false);
        $('#bb').prop('checked', true);
      }
    }, 150);
  }

  useEffect(() => {
    if (localStorage.getItem("USER.systemBranchCode") === "central") {
      $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
      $('#s_month').val(Fn.getCurrentDate().split("-")[1]);
    }
  }, []);

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#s_date1').val(`${year}-${month}-01`);
    $('#s_date2').val(today);
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(tabType);
  }, [srchRenew]);

  useEffect(() => {
    load('1');
  }, []);

  const downloadExcel = () => {
    let url;
    if (localStorage.getItem("USER.systemBranchCode") == "central") {
      url = `/xls/branchop/inout/cal/central/new?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`;
    } else {
      url = `/xls/branchop/inout/cal/new?s_gubun=${$('#s_gubun option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`;
      if (tabType != "1") url = `/xls/branchop/not/inout/cal2/new?s_gubun=${$('#s_gubun option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`;
    }
    if (url) {
      Fn.download(Constants.API_URL + url);
    }
  }

  const load = (type) => {
    let url = '';

    if (localStorage.getItem("USER.systemBranchCode") === "central") {
      url = `/branchop/inout/cal/central/new?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`;
    } else {
      url = `/branchop/inout/cal/new?s_gubun=${$('#s_gubun option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`;
      if (type != "1") url = `/branchop/not/inout/cal/new?s_gubun=${$('#s_gubun option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`;
    }

    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if (localStorage.getItem("USER.systemBranchCode") === "central") {
            let tot_rr_price = 0;
            let tot_ss_price = 0;
            let tot_jj_price = 0;
            let cont = '';

            $('.cls-data').remove();

            result?.data.forEach((data_row, index) => {
              const count = index + 1;
              const branch_name = data_row.branch_name;

              tot_rr_price += Fn.parseInt(data_row?.receive_m);
              tot_ss_price += Fn.parseInt(data_row?.send_m);
              tot_jj_price += Fn.parseInt(data_row?.cha_m);

              cont += `
                <tr class="cls-data">
                  <td>${count}</td>
                  <td>${branch_name}</td>
                  <td class='text-right'>${data_row?.rr_price}</td>
                  <td class='text-right'>${data_row?.ss_price}</td>
                  <td class='text-right'>${data_row?.jj_price}</td>
                </tr>
              `;
            });

            cont += `
              <tr class="cls-data">
                <td colspan='2' style='background:#fcfff2'><strong>합 계</strong></td>
                <td style='background:#fcfff2' class='text-right'><strong>${Fn.numberWithCommas(tot_rr_price)}</strong></td>
                <td style='background:#fcfff2' class='text-right'><strong>${Fn.numberWithCommas(tot_ss_price)}</strong></td>
                <td style='background:#fcfff2' class='text-right'><strong>${Fn.numberWithCommas(tot_jj_price)}</strong></td>
              </tr>
            `;

            $('#header_tr').after(cont);
          } else {
            let tot_cnts = 0;
            let tot_price = 0;
            let cont = '';

            $('.cls-data').remove();

            if (url.includes("/not/")) {
              result?.data.forEach((data_row, index) => {
                const count = index + 1;
                const branch_name = data_row.branch_name;

                const cnts = Number(data_row.cnts).toLocaleString();
                const price = data_row?.prices || 0;
                const new_price = price.toLocaleString();

                tot_cnts += Number(cnts);
                tot_price += price;

                cont += `
                  <tr class="cls-data">
                    <td>${count}</td>
                    <td><a onclick="window.loadModal1(1, '${data_row?.ba_gr_id}')" style="cursor: pointer;">${branch_name}</a></td>
                    <td class='text-right'>${cnts}</td>
                    <td class='text-right'>${new_price}</td>
                  </tr>
                `;
              });
            } else {
              result?.data.forEach((data_row, index) => {
                const count = index + 1;
                const branch_name = data_row.branch_name;

                const cnts = Number(data_row.cnts).toLocaleString();
                const price = data_row?.prices || 0;
                const new_price = price.toLocaleString();

                tot_cnts += Number(cnts);
                tot_price += price;

                cont += `
                  <tr class="cls-data">
                    <td>${count}</td>
                    <td><a onclick="window.loadModal1(1, '${data_row?.won_gr_id}')" style="cursor: pointer;">${branch_name}</a></td>
                    <td class='text-right'>${cnts}</td>
                    <td class='text-right'>${new_price}</td>
                  </tr>
                `;
              });
            }

            cont += `
              <tr class="cls-data">
                <td colspan='2' style='background:#fcfff2'><strong>합 계</strong></td>
                <td style='background:#fcfff2' class='text-right'><strong>${tot_cnts.toLocaleString()}</strong></td>
                <td style='background:#fcfff2' class='text-right'><strong>${tot_price.toLocaleString()}</strong></td>
              </tr>
            `;

            $('#header_tr').after(cont);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  window.loadModal1 = (page = modal1CurrPage, branch_code) => {
    setModal1BranchCode(branch_code);

    if (localStorage.getItem("USER.systemBranchCode") === "central") return;

    let url = `/branchop/pop/inout/receive/ready?s_gubun=${$('#s_gubun option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_branch_code=${branch_code}&page=${page}`;
    if (tabType != "1") {
      url = `/branchop/pop/inout/send/ready?s_gubun=${$('#s_gubun option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_branch_code=${branch_code}&page=${page}`;
    }

    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setModal1Result(result);
          setModal1PageInfo(pageInfo);
          setModal1Open(true);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-1.
  const showData = () => {
    $(".loading_bg").fadeIn("fast");

    const form = $('form')[0];
    const formData = new FormData(form);
    axios({
      url: '/branchop/inout/cal/central/new',
      method: 'POST',
      data: formData,
      processData: false,
      contentType: false
    })
      .then(response => {
        let data = response.data.trim();

        //cal_info.innerHTML = data;
        //alert(data);

        $("#cal_info").html(data);
        $(".loading_bg").fadeOut();
        //alert(data);
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  //  3-1.
  const notShowData = () => {
    const pub = document.getElementById('pub');
    pub.style.display = 'inline';
    $(".loading_bg").fadeIn("fast");

    const form = $('form')[0];
    const formData = new FormData(form);
    axios({
      url: '/branchop/not/inout/cal/central/new',
      method: 'POST',
      data: formData,
      processData: false,
      contentType: false
    })
      .then(response => {
        let data = response.data.trim();
        //cal_info.innerHTML = data;
        $("#not_cal_info").html(data);
        $(".loading_bg").fadeOut();
        //alert(data);
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  //  4-1.
  const sForm = document.getElementById('s_form');

  const goSearch = () => {
    if (sForm.target.elements.s_date1.value === "") {
      alert("입장 시작일을 선택해주세요");
      return;
    }
    if (sForm.target.elements.s_date2.value === "") {
      alert("입장 종료일을 선택해주세요");
      return;
    }
    sForm.target.submit();
  };

  //  5-1.
  const e1 = document.getElementById('e1');
  const e2 = document.getElementById('e2');
  const common_i = document.getElementById('common_i');

  const showChange = () => {
    e1.innerHTML = "<font color='white'>데이터변환중.</font>";
    common_i.location.href = 'Excel_inout_cal_central_new.php?s_date1=2023-06-01&s_date2=2023-06-29&s_gubun=x&s_year=2023&s_month=06';
  };

  //  6-1.
  const showChange2 = () => {
    e1.innerHTML = "<font color='white'>데이터변환중.</font>";
    common_i.location.href = 'Excel_not_inout_cal2_central_new.php?s_date1=2023-06-01&s_date2=2023-06-29&s_gubun=x&s_year=2023&s_month=06';
  };

  //  7-1.
  const showDataMake = () => {
    if (window.confirm("해당월 데이터를 생성하시겠습니까?")) {
      axios2
        .post(`/branchop/inout/make/central/new?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            load();
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  //  8-1.
  const pubData = () => {
    const pub_date = $("#pub_date").val();

    if (!pub_date) {
      alert("발행일을 입력해주세요");
      return;
    }

    window.location.href = `Excel_inout_publish_new.php?s_date1=2023-06-01&s_date2=2023-06-29&s_gubun=x&s_year=2023&s_month=06&pub_date=${pub_date}`;
  };

  //  9-1.
  const popUp = (file, names, width, height, sc) => {
    const w = width;
    const h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h}, scrollbars=${sc}, top=${wint}, left=${winl}`);
  };

  const showInCal = (branch_code) => {
    const s_gubun = $("#s_gubun").val();
    const s_date1 = $("#s_date1").val();
    const s_date2 = $("#s_date2").val();

    const url = `/branchop/pop_inout_receive.html?s_gubun=${s_gubun}&s_date1=${s_date1}&s_date2=${s_date2}&s_branch_code=${branch_code}`;
    popUp(url, '/branchop/pop_senddetail.html', '700', '600', 'no');
  };

  //  10-1.
  const showOutCal = (branch_code) => {
    const s_gubun = $("#s_gubun").val();
    const s_date1 = $("#s_date1").val();
    const s_date2 = $("#s_date2").val();

    const url = `/branchop/pop_inout_send.html?s_gubun=${s_gubun}&s_date1=${s_date1}&s_date2=${s_date2}&s_branch_code=${branch_code}`;
    popUp(url, '/branchop/pop_senddetail.html', '700', '600', 'no');
  };

  const inoutPub = () => {
    if (!$('#pub_date').val()) {
      alert('발행일을 입력해 주세요.');
      return;
    }
    const url = Constants.API_URL + `/branchop/excel/inout/publish/new?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_gubun=${$('#s_gubun option:selected').val()}&pub_date=${$('#pub_date').val()}`;
    Fn.download(url);
  }

  if (systemBranchCode !== "central") {
    return (
      <CmnCard onLogout={props.onLogout}>
        <div className="wid_MAX" style={{maxWidth: "700px"}}>
          <div className="d-flex m-3">
            <div className="pr-1">
              <select className="form-select" id="s_gubun">
                <option value="x">정상입장</option>
                <option value="1">비회원</option>
                <option value="2">이용기간만료</option>
                <option value="3">휴회기간</option>
                <option value="4">미납회원</option>
                <option value="5">중복입장</option>
                <option value="6">1일2회입장</option>
                <option value="xx">정상입장제외</option>
              </select>
            </div>
            <div className="pr-1">
              <select className="form-select" id="ddate" onChange={(e) => Fn.period(e.target.value)}>
                <option value="0">검색기간</option>
                <option value="1">오늘</option>
                <option value="2">이번달</option>
                <option value="3">전월</option>
              </select>
            </div>
            <div className="d-flex pr-1">
              <JDatePicker className="form-control" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" id="s_date2"/>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
            <p className="p-1">(최대90일)</p>
          </div>

          <div className="m-3 p_rative" style={{clear: 'both'}}>
            {tabType === '2' &&
              <div className="d-flex" style={{position: 'absolute', right: 0}}>
                <div className="pr-1">
                  <JDatePicker className="form-control" id="pub_date" name="pub_date"/>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-dark" onClick={() => inoutPub()}>계산서 발행</button>
                </div>
              </div>
            }
            <div className="tabs" style={{minHeight: "40px"}}>
              <input id="aa" type="radio" name="tab_item" defaultChecked/>
              <label className="tab_item" htmlFor="aa" onClick={() => onTab('1')}>정산내역(받을돈)</label>
              <input id="bb" type="radio" name="tab_item"/>
              <label className="tab_item" htmlFor="bb" onClick={() => onTab('2')}>타지점 정산내역(보낼돈)</label>
            </div>
          </div>

          <div className="m-3 pb-2">
            <table className="view-rounded sub_table_border auto">
              <colgroup>
                <col width="65px"/>
                <col width="*"/>
                <col width="100px"/>
                <col width="140px"/>
              </colgroup>
              <tr id="header_tr">
                <th className="w_70px">No</th>
                <th>지점명</th>
                <th>횟수</th>
                <th>금액</th>
              </tr>
            </table>
          </div>

          <div className="mt-2 mb-4">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => downloadExcel()}>정산내역 엑셀저장</button>
          </div>
        </div>

        <Modal
          isOpen={modal1Open}
          style={{
            modal: {
              backgroundColor: 'transparent',
              margin: 0,
              padding: 0,
              alignItems: undefined,
              justifyContent: undefined,
            },
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent'
            }
          }}
          onAfterOpen={() => {
            Fn.centerModal();
            setModal1CurrPage(1);
            // loadModal1();
          }}
          onRequestClose={() => setModal1Open(false)}
          ariaHideApp={false}
        >
          <div className="pop_background" style={{zIndex: 1000000000}}>
            <div className="pop_box01 p_absolute_mid_pop" style={{width: '800px'}}>
              <div className="POP_title">
                <h5><strong>정산내역 &gt; 상세내역</strong></h5>
                <p className="closed">
                  <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </p>
              </div>
              <div className="POP_cnt_box">
                <div className="pb-2">
                  <table className="view-rounded sub_table_border text-center auto">
                    <thead>
                    <tr>
                      <th className="w_70px">No</th>
                      <th>일자</th>
                      <th>이름</th>
                      <th>소속지점</th>
                      <th>입장기준</th>
                      <th>정산금액</th>
                    </tr>
                    </thead>
                    <tbody>
                    {modal1Result?.result && modal1Result?.result?.map((ret, i) => {

                      let type = '', price = '';
                      if (ret?.ba_price == 1) {
                        type = "비정상운영";
                        price = modal1Result?.notOperPrice;
                      } else if (ret?.ba_price == 2) {
                        type = "공사/프리세일";
                        price = modal1Result?.freeSalePrice;
                      } else {
                        type = "정상(타지점)";
                        price = modal1Result?.calPrice;
                      }

                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{ret?.ba_date}</td>
                          <td>{ret?.ba_mb_name || ''}</td>
                          <td>{ret?.ba_gr_name || ''}</td>
                          <td>{type}</td>
                          <td>{Fn.numberWithCommas(price)}</td>
                        </tr>
                      );
                    })}
                    </tbody>
                  </table>
                </div>
                {/*{modal1Result?.pageInfo?.lastPage > 1 ?*/}
                {/*  <nav className="p-3 mb-0 d-flex justify-content-between flex-wrap">*/}
                {/*    <Paging pageInfo={modal1Result?.pageInfo} setCurrPage={page => {*/}
                {/*      setModal1CurrPage(page);*/}
                {/*      window.loadModal1(page, modal1BranchCode);*/}
                {/*    }}/>*/}
                {/*    <div>*/}
                {/*      <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal1Open(false)}>닫기</button>*/}
                {/*    </div>*/}
                {/*  </nav>*/}
                {/*  :*/}
                {/*  <div className="text-center my-3">*/}
                {/*    <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal1Open(false)}>닫기</button>*/}
                {/*  </div>*/}
                {/*}*/}
              </div>
            </div>
          </div>
        </Modal>
      </CmnCard>
    );
  } else {
    return (
      <CmnCard onLogout={props.onLogout}>
        <div className="wid_MAX" style={{maxWidth: "700px"}}>
          <div className="d-flex m-3">
            <div className="pr-1">
              <select className="form-select" id="s_year">
                {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
            <div className="pr-1">
              <select className="form-select" style={{minWidth: '80px'}} id="s_month">
                {[...Array(12)].map((_, index) => {
                  const monthNumber = index + 1;
                  const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                  return (
                    <option key={monthString} value={monthString}>{monthString}</option>
                  );
                })}
              </select>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-dark" onClick={() => load()}>검색</button>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point" onClick={() => showDataMake()}>데이터 생성</button>
            </div>
          </div>

          <div className="m-3 pb-2">
            <table className="view-rounded sub_table_border auto">
              <colgroup>
                <col width="65px"/>
                <col width="*"/>
                <col width="100px"/>
                <col width="100px"/>
                <col width="100px"/>
              </colgroup>
              <tr id="header_tr">
                <th className="w_70px">No</th>
                <th>지점명</th>
                <th>받을돈</th>
                <th>보낼돈</th>
                <th>차액</th>
              </tr>
            </table>
          </div>

          <div className="mt-2 mb-4">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => downloadExcel()}>정산내역 엑셀저장</button>
          </div>

        </div>

      </CmnCard>
    );
  }
};

export default BranchopInoutCalNew;
