import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import axios from "axios";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";

const BranchopNotOperList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#s_date1').val(`${year}-${month}-01`);
    $('#s_date2').val(today);
    $('#s_end_hour').val('23');
    $('#s_end_minute').val('59');
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/branchop/not/oper/list/ready?s_jibu=${$('#s_jibu option:selected').val() || ''}&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_gubun=${$('#s_gubun option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_start_hour=${$('#s_start_hour option:selected').val() || ''}&s_start_minute=${$('#s_start_minute option:selected').val() || ''}&s_date2=${$('#s_date2').val()}&s_end_hour=${$('#s_end_hour option:selected').val() || ''}&s_end_minute=${$('#s_end_minute option:selected').val() || ''}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_jibu').html() === '') {
            let optionsHTML = '<option value="">지부선택</option>';
            result?.jibuRs?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row}">${list_row}</option>`;
            });
            $('#s_jibu').html(optionsHTML);
          }
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          setRets(result?.list);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const delData = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 리스트를 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/branchop/not/oper/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          alert('삭제되었습니다');
          setRenew(Fn.getRenewTime());
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  function popFee(base_seq) {
    $('#base_seq').val(base_seq);

    axios2
      .post(`/branchop/pop/timeset/ready?base_seq=${$('#base_seq').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
          setModalOpen(true);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function data_save() {
    axios2
      .post(`/branchop/take/not/order/data?base_seq=${$('#base_seq').val()}&not_oper_type=${$("input[name='not_oper_type']:checked").val()}`)
      .then((response) => {
        const {code, message: data, result, pageInfo} = response?.data;
        if (code === 200) {
          if (data === "err") {
            alert("잘못된 데이터 입니다");
            setModalOpen(false);
          } else if (data === "take") {
            alert("이미 처리된 데이터 입니다");
            setModalOpen(false);
          } else {
            setRenew(Fn.getRenewTime());
            setModalOpen(false);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 fn_search_box" style={{maxWidth: "1122px"}}>
          <div className="pr-1">
            <select className="form-select" id="s_jibu">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_branch_code">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_gubun">
              <option value="A">입장전</option>
              <option value="B">입장후</option>
            </select>
          </div>
          <div className="pr-1 d-flex">
            <JDatePicker className="form-control" id="s_date1"/>
            <select className="form-select mx-1" style={{minWidth: '80px'}} id="s_start_hour">
              {[...Array(24)].map((_, index) => {
                const monthNumber = index;
                const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
            <select className="form-select" style={{minWidth: '80px'}} id="s_start_minute">
              {[...Array(60)].map((_, index) => {
                const monthNumber = index;
                const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
          </div>
          <div className="d-flex justify-content-center align-content-center align-items-center">
            <p className="mx-2">~</p>
          </div>
          <div className="pr-1 d-flex">
            <JDatePicker className="form-control" id="s_date2"/>
            <select className="form-select mx-1" style={{minWidth: '80px'}} id="s_end_hour">
              {[...Array(24)].map((_, index) => {
                const monthNumber = index;
                const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
            <select className="form-select" style={{minWidth: '80px'}} id="s_end_minute">
              {[...Array(60)].map((_, index) => {
                const monthNumber = index;
                const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-1 table_list" style={{maxWidth: "1122px"}}>
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="70px"/>
              <col width="55px"/>
              <col width="170px"/>
              <col width="170px"/>
              <col width="*"/>
              <col width="110px"/>
              <col width="110px"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th><input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/></th>
              <th>입장미체크 시작시간</th>
              <th>이후 입장체크 시간</th>
              <th>지점명</th>
              <th>적용구분</th>
              <th>적용설정</th>
            </tr>
            {rets && rets.map((ret, i) => {

              const not_oper_type_show = ret?.not_oper_type === "B" ? "비정상운영" : ret?.not_oper_type === "G" ? "공사/프리세일" : "";

              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    {ret?.take_YN === 'N' &&
                      <input
                        type="checkbox"
                        // name={`chk[${i + 1}]`}
                        name="chk"
                        value={btoa(ret?.seq)}
                        className="checkbox"
                        id={`achk${i + 1}`}
                      />
                    }
                  </td>
                  <td>{ret?.start_time}</td>
                  <td>{ret?.end_time}</td>
                  <td>{ret?.branch_name}</td>
                  <td>{not_oper_type_show}</td>
                  {ret?.take_YN === "N" ? (
                    <td>
                      <button type="button" className="btn btn-danger btn-sm" onClick={() => popFee(btoa(ret?.seq))}>적용
                      </button>
                    </td>
                  ) : ret?.take_YN === "Y" ? (
                    <td><strong><font color="blue">적용완료</font></strong></td>
                  ) : (
                    <td>에러</td>
                  )}
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={7}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="m-3 p-3 mb-4 d-flex justify-content-between border-top flex-wrap" style={{maxWidth: "1122px"}}>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => delData()}>삭제</button>
        </div>
      </nav>

      <input type="hidden" id="base_seq"/>
      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          if (modalResult?.row?.not_oper_type === "B")
            $('#flag_1').prop("checked", true);
          else
            $('#flag_2').prop("checked", true);
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '680px'}}>
            <div className="POP_title">
              <h5><strong>비정상운영 일자 및 시간 적용</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="158px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>입장 미체크 시작시간</th>
                  <td>{modalResult?.row?.start_time}</td>
                </tr>
                <tr>
                  <th>이후 입장체크 시간</th>
                  <td>{modalResult?.row?.end_time}</td>
                </tr>
                <tr>
                  <th>적용선택</th>
                  <td>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="not_oper_type" value="B" id="flag_1"/>
                      <label className="form-check-label" htmlFor="inlineRadio1">비정상운영(미오픈)</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="not_oper_type" value="G" id="flag_2"/>
                      <label className="form-check-label" htmlFor="inlineRadio2">공사 / 프리세일</label>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => data_save()}>저장</button>
              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModalOpen(false)}>취소</button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default BranchopNotOperList;
