import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import {useHistory} from "react-router-dom";

const MemberInputStat = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [errTypeShow, setErrTypeShow] = useState('');
  const [renew, setRenew] = useState(0);
  const history = useHistory();

  useEffect(() => {
    $('#s_date1').val(Fn.getCurrentDate());
    $('#s_date2').val(Fn.getCurrentDate());
  }, []);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/member/inout/statistics/new/ready?page=${currPage}&tbl_gubun=now&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_gubun=${$('#s_gubun').val()}&df_branch=${$('#df_branch:checked').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          function compare(a, b) {
            if (a.fcRow.show_out < b.fcRow.show_out) {
              return -1;
            }
            if (a.fcRow.show_out > b.fcRow.show_out) {
              return 1;
            }
            return 0;
          }

          result?.result.sort(compare);
          setRets(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //입장구문
  const handleErrType = (errType) => {
    if (errType == "0") {
      return "정상입장";
    } else if (errType == "1") {
      return "없는회원";
    } else if (errType == "2") {
      return "이용기간만료";
    } else if (errType == "3") {
      return "휴회기간";
    } else if (errType == "4") {
      return "미납회원";
    } else if (errType == "5") {
      return "중복입장";
    } else if (errType == "6") {
      return "1일2회입장";
    } else if (errType == "7") {
      return "타지점10회이상";
    } else if (errType == "8") {
      return "유효시간초과";
    } else if (errType == "9") {
      return "이용불가지점";
    } else if (errType == "10") {
      return "이용불가요일";
    } else if (errType == "11") {
      return "이용불가시간";
    } else if (errType == "12") {
      return "타지점이용불가";
    } else {
      return "";
    }
  };

  //  3-1.
  const make_monitor = () => {
    const as = window.open('/inc/black.html', 'enter_monitor', 'width=0,height=0,scrollbars=yes,top=10000,left=10000');
    as.window.close();
    const as2 = window.open('/enter/enter_monitor.html', 'enter_monitor', 'width=500,height=500,scrollbars=yes,top=600,left=1000');

    return () => {
      as2.close();
    };
  };

  //  4-1.
  const [sDate1, setSDate1] = useState('');
  const [sDate2, setSDate2] = useState('');

  const date_reset = (tbl) => {
    if (tbl !== "b_attend") {
      setSDate1('');
      setSDate2('');
    }
  };

  //  4-2.
  date_reset("b_attend");

  //  5-1.
  const [searchAuthCode, setSearchAuthCode] = useState("");
  const [authCodeName, setAuthCodeName] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [regStep, setRegStep] = useState("");
  const [type, setType] = useState("");

  const handleSearchAuthCodeChange = (event) => {
    setSearchAuthCode(event.target.value);
  };

  return (
    <CmnCard onLogout={props.onLogout}>

      <div className="d-flex justify-content-between flex-wrap mt-4 m-3" style={{maxWidth: "1080px"}}>
        <div className="d-flex">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%', display: "none"}} id="tbl_gubun">
              <option value="now">2022</option>
              <option value="2021">2021</option>
              <option value="2020">2020</option>
              <option value="2019">2019</option>
              <option value="2018">2018</option>
            </select>
          </div>
          <div className="pr-1 d-flex">
            <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>
              입장일 :
            </label>
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
            </div>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_gubun">
              <option value="">:전체</option>
              <option value="x">정상입장</option>
              <option value="1">비회원</option>
              <option value="2">이용기간만료</option>
              <option value="3">휴회기간</option>
              <option value="4">미납회원</option>
              <option value="5">중복입장</option>
              <option value="6">1일2회입장</option>
              <option value="8">유효시간초과</option>
              <option value="9">이용불가지점</option>
              <option value="10">이용불가요일</option>
              <option value="11">이용불가시간</option>
              <option value="12">타지점이용불가</option>
              <option value="xx">정상입장제외</option>
            </select>
          </div>
          <div className="pt-1">
            <div className="form-check form-check-inline">
              <label className="form-check-label" htmlFor="inlineCheckbox1">
                타지점 :
              </label>
              <input className="form-check-input" type="checkbox" id="df_branch" value="Y"/>
            </div>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
          {/*TODO : 처리 필요*/}
          {/*<? if ($_SESSION["SystemUserAuth"] == "9") { ?>*/}
          {/*<a href='./inout_statistics_new.html' class="btn btn-primary btn-sm">입장내역통계</a>*/}
          {/*<? } ?>*/}
        </div>

        <div>
          <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.push("/member/input/list")}>
            입장내역
          </button>
        </div>
      </div>

      <div className="m-3 table_list" style={{maxWidth: "1080px"}}>
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="200px"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>지점명</th>
            <th>합계</th>
          </tr>
          {rets && rets.map((ret, i) =>
            <tr>
              <td>{ret?.fcRow?.branch_name}</td>
              <td className="text-right">{Fn.numberWithCommas(ret?.ea)}</td>
            </tr>
          )}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={2}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
          {(rets && rets?.length > 0) &&
            <tr className="bgcolor bold700">
              <td>합계</td>
              <td className="text-right">{Fn.numberWithCommas(rets?.reduce((n, {ea}) => n + ea, 0))}</td>
            </tr>
          }
        </table>
      </div>

    </CmnCard>
  );
};

export default MemberInputStat;
