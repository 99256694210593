import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";
import Modal from "react-modal";

const AnalysisMonthlyAnalysisPt = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [result, setResult] = useState({});
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [basicDate, setBasicDate] = useState("");
  const [last6Months, setLast6Months] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState();

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split('-')[0]);
    $('#s_month').val(Fn.getCurrentDate().split('-')[1]);
  }, []);

  useEffect(() => {
    load();
  }, [renew, srchRenew]);

  const load = () => {
    axios2
      .post(`/analysis/monthly/analysis/pt/ready?s_year=${$('#s_year option:selected').val()}&s_month=${$('#s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          const basicDate = new Date(Fn.parseInt($('#s_year option:selected').val()), Fn.parseInt($('#s_month option:selected').val()) - 1, 1);
          const _basicDate = $('#s_year option:selected').val() + "-" + $('#s_month option:selected').val() + "-01";
          setBasicDate(_basicDate);

          const last6Months = [];
          last6Months.push(_basicDate.substring(0, 7).replace(/-/g, "."));
          for (let k = 1; k <= 6; k++) {
            const newDate = new Date(basicDate.getFullYear(), basicDate.getMonth() - k, 1);
            const formattedDate = `${newDate.getFullYear()}.${String(newDate.getMonth() + 1).padStart(2, '0')}`;
            last6Months.push(formattedDate);
          }
          setLast6Months(last6Months);

          console.log(result);
          setResult(result);
        }
      })
      .catch((error) => {
        console.error(error)
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/analysis/make/data/monthly/pt/ready?s_year=${$('#modal_s_year option:selected').val()}&s_month=${$('#modal_s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function make_data(branchCode, branchName) {
    const sYear = $('#modal_s_year option:selected').val();
    const sMonth = $('#modal_s_month option:selected').val();

    const txt = `${branchName}지점의 ${sYear}년 ${sMonth}월 데이터를 생성하시겠습니까?`;

    if (window.confirm(txt)) {
      if (!$("#overlay").is(":visible"))
        $('#overlay').show();

      setTimeout(() => {
        axios2.postEx("/analysis/make/data/monthly/pt", {
          s_year: sYear,
          s_month: sMonth,
          branch_code: branchCode
        })
          .then((response) => response.json())
          .then(({code, message: data, result, pageInfo}) => {
            console.log(data, result);
            alert('생성되었습니다');
            setRenew(Fn.getRenewTime());
            loadModal();
          })
          .catch(error => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          })
          .finally(() => {
            $("#overlay").hide();
          });
      }, 100);

    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/analysis/monthly/member")}>월간등록현황</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/analysis/monthly/finance/new")}>월계표 비교분석</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/analysis/monthly/coupon")}>쿠폰발송내역</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/analysis/monthly/tm")}>TM 현황보고</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/analysis/monthly/region")}>지역분류</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/analysis/monthly/public")}>경쟁업체(퍼블릭)</label>

          <input id="gg" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/analysis/monthly/pt")}>경쟁업체(PT)</label>

          <input id="hh" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/analysis/monthly/analysis/pt")}>PT월간등록현황</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}년</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: '80px'}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => setModalOpen(true)}>PT 데이터 생성</button>
        </div>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>전월 목표</th>
            <td className="text-right"><span className="text-primary bold700">{Fn.numberWithCommas(result?.bResult?.targetPtPrice)}</span></td>
            <th>전월 달성</th>
            <td className="text-right"><span className="text-primary bold700">{Fn.numberWithCommas(result?.bResult?.ptPrice)}</span></td>
            <th>전월 달성률</th>
            <td className="text-right"><span className="text-primary bold700">{Number(Number(result?.bPtRate).toFixed(2))}%</span></td>
          </tr>
          <tr>
            <th>당월 목표</th>
            <td className="text-right"><span className="text-danger bold700">{Fn.numberWithCommas(result?.targetPtPrice)}</span></td>
            <th>당월 달성</th>
            <td className="text-right"><span className="text-danger bold700">{Fn.numberWithCommas(result?.tPtPrice)}</span></td>
            <th>당월 달성률</th>
            <td className="text-right"><span className="text-danger bold700">{Number(Number(result?.ptRate).toFixed(2))}%</span></td>
          </tr>
          <tr>
            <td colSpan="3" className="bgcolor2 bold700">1인당 매출단가</td>
            <td colSpan="3" className="bold700">{Fn.numberWithCommas(result?.manDanga)}</td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <div className="d-flex p_rative">
          <div className="table_overlap1 overlap240_1" style={{zIndex: 1000}}>
            <table className="rounded-corner-l sub_table_border">
              <colgroup>
                <col width="*"/>
                <col width="150px"/>
              </colgroup>
              <tr style={{height: "85.88px"}}>
                <th>주차</th>
                <th>날짜</th>
              </tr>
              {result?.result?.map((item, index) => (
                <tr className="bgcolor bold700" style={{maxHeight: "42.99px", minHeight: "42.99px", height: "42.99px"}}>
                  <td>{index + 1}</td>
                  <td>
                    {/*{item?.start_date?.substring(5, 10)?.replace(/-/g, ".")} ~ {item?.end_date?.substring(5, 10)?.replace(/-/g, ".")}*/}
                    {item?.start_date?.substring(2, 10)?.replace(/-/g, "/")} ~ {item?.end_date?.substring(2, 10)?.replace(/-/g, "/")}
                  </td>
                </tr>
              ))}
              {result?.result2?.map((item, index) => (
                <tr style={{maxHeight: "42.99px", minHeight: "42.99px", height: "42.99px"}}>
                  <td colSpan={2}>
                    {item?.YM?.substring(0, 4)}.{item?.YM?.substring(4, 6)}
                  </td>
                </tr>
              ))}
            </table>
          </div>

          <div className="table_overlap2 overlap240_2" style={{minWidth: "min-content", paddingRight: "20px"}}>
            <table className="view-rounded sub_table_rowspan text-right auto" style={{width: "3000px", borderRadius: 0}}>
              <colgroup>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>

                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>

                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>

                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>

                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
              </colgroup>
              <tr style={{maxHeight: "42.99px", minHeight: "42.99px", height: "42.99px"}}>
                <th rowSpan="2">합계</th>
                <th rowSpan="2">신규</th>
                <th rowSpan="2">재등록</th>

                <th rowSpan="2">매출</th>
                <th rowSpan="2">환불</th>
                <th rowSpan="2">매출합계</th>
                <th colSpan="2">수업관련사항</th>

                <th colSpan="6">가입횟수</th>
                <th colSpan="2">성별</th>
                <th colSpan="5">가입경로</th>
                <th colSpan="5">타임</th>
                <th colSpan="6">연령대</th>
              </tr>
              <tr style={{maxHeight: "42.99px", minHeight: "42.99px", height: "42.99px"}}>
                <th>유료수업횟수</th>
                <th>무료수업인원</th>
                <th>10회</th>
                <th>20회</th>
                <th>30회</th>
                <th>40회</th>
                <th>50회</th>
                <th>100회</th>

                <th>남자</th>
                <th>여자</th>

                <th>워크인</th>
                <th>OT</th>
                <th>헌팅</th>
                <th>소개</th>
                <th>재등록</th>

                <th>오전</th>
                <th>오후</th>
                <th>심야</th>
                <th>주말</th>
                <th>공휴일</th>

                <th>10대</th>
                <th>20대</th>
                <th>30대</th>
                <th>40대</th>
                <th>50대</th>
                <th>60대 이상</th>
              </tr>
              {result?.result?.map((item, index) => (
                <tr className="bgcolor bold700" style={{maxHeight: "42.99px", minHeight: "42.99px", height: "42.99px"}}>
                  <td className="bg_yellow1">{Fn.numberWithCommas(item?.new_man + item?.old_man)}</td>
                  <td className="bg_yellow1">{Fn.numberWithCommas(item?.new_man)}</td>
                  <td className="bg_yellow1 brd_right_1">{Fn.numberWithCommas(item?.old_man)}</td>
                  <td>{Fn.numberWithCommas(item?.pt_price - item?.r_pt_price)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.r_pt_price)}</td>
                  <td className="bg_yellow1">{Fn.numberWithCommas(item?.pt_price)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.take_pt_cnt)}</td>
                  <td className="brd_right_2">{Fn.numberWithCommas(item?.ot_man_num)}</td>
                  <td>{Fn.numberWithCommas(item?.session10)}</td>
                  <td>{Fn.numberWithCommas(item?.session20)}</td>
                  <td>{Fn.numberWithCommas(item?.session30)}</td>
                  <td>{Fn.numberWithCommas(item?.session40)}</td>
                  <td>{Fn.numberWithCommas(item?.session50)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.session100)}</td>
                  <td>{Fn.numberWithCommas(item?.m_num)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.f_num)}</td>
                  <td>{Fn.numberWithCommas(item?.join1)}</td>
                  <td>{Fn.numberWithCommas(item?.join2)}</td>
                  <td>{Fn.numberWithCommas(item?.join3)}</td>
                  <td>{Fn.numberWithCommas(item?.join4)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.join5)}</td>
                  <td>{Fn.numberWithCommas(item?.time1)}</td>
                  <td>{Fn.numberWithCommas(item?.time2)}</td>
                  <td>{Fn.numberWithCommas(item?.time3)}</td>
                  <td>{Fn.numberWithCommas(item?.time4)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.time5)}</td>
                  <td>{Fn.numberWithCommas(item?.age1)}</td>
                  <td>{Fn.numberWithCommas(item?.age2)}</td>
                  <td>{Fn.numberWithCommas(item?.age3)}</td>
                  <td>{Fn.numberWithCommas(item?.age4)}</td>
                  <td>{Fn.numberWithCommas(item?.age5)}</td>
                  <td>{Fn.numberWithCommas(item?.age6)}</td>
                </tr>
              ))}
              {result?.result2?.map((item, index) => (
                <tr style={{maxHeight: "42.99px", minHeight: "42.99px", height: "42.99px"}}>
                  <td className="bg_yellow1">{Fn.numberWithCommas(item?.new_man + item?.old_man)}</td>
                  <td className="bg_yellow1">{Fn.numberWithCommas(item?.new_man)}</td>
                  <td className="bg_yellow1 brd_right_1">{Fn.numberWithCommas(item?.old_man)}</td>
                  <td>{Fn.numberWithCommas(item?.pt_price - item?.r_pt_price)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.r_pt_price)}</td>
                  <td className="bg_yellow1">{Fn.numberWithCommas(item?.pt_price)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.take_pt_cnt)}</td>
                  <td className="brd_right_2">{Fn.numberWithCommas(item?.ot_man_num)}</td>
                  <td>{Fn.numberWithCommas(item?.session10)}</td>
                  <td>{Fn.numberWithCommas(item?.session20)}</td>
                  <td>{Fn.numberWithCommas(item?.session30)}</td>
                  <td>{Fn.numberWithCommas(item?.session40)}</td>
                  <td>{Fn.numberWithCommas(item?.session50)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.session100)}</td>
                  <td>{Fn.numberWithCommas(item?.m_num)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.f_num)}</td>
                  <td>{Fn.numberWithCommas(item?.join1)}</td>
                  <td>{Fn.numberWithCommas(item?.join2)}</td>
                  <td>{Fn.numberWithCommas(item?.join3)}</td>
                  <td>{Fn.numberWithCommas(item?.join4)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.join5)}</td>
                  <td>{Fn.numberWithCommas(item?.time1)}</td>
                  <td>{Fn.numberWithCommas(item?.time2)}</td>
                  <td>{Fn.numberWithCommas(item?.time3)}</td>
                  <td>{Fn.numberWithCommas(item?.time4)}</td>
                  <td className="brd_right_1">{Fn.numberWithCommas(item?.time5)}</td>
                  <td>{Fn.numberWithCommas(item?.age1)}</td>
                  <td>{Fn.numberWithCommas(item?.age2)}</td>
                  <td>{Fn.numberWithCommas(item?.age3)}</td>
                  <td>{Fn.numberWithCommas(item?.age4)}</td>
                  <td>{Fn.numberWithCommas(item?.age5)}</td>
                  <td>{Fn.numberWithCommas(item?.age6)}</td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      </div>


      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          $('#modal_s_year').val($('#s_year option:selected').val());
          $('#modal_s_month').val($('#s_month option:selected').val());
          Fn.centerModal();
          loadModal()
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 100000}}>

          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: '650px'}}>
            <div className="POP_title">
              <h5><strong>PT 데이터 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select className="form-select" id="modal_s_year">
                    {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <div className="pr-1">
                  <select className="form-select" id="modal_s_month">
                    {[...Array(12)].map((_, index) => {
                      const monthNumber = index + 1;
                      const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                      return (
                        <option key={monthString} value={monthString}>{monthString}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal()}>검색</button>
                </div>
              </div>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="*"/>
                  <col width="90px"/>
                  <col width="145px"/>
                  <col width="60px"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>지점명</th>
                  <th>최종주</th>
                  <th>생성일시</th>
                  <th>생성</th>
                </tr>
                {modalResult?.result && modalResult?.result?.map((ret, i) => {
                  return (
                    <tr>
                      <td className="text-center">{i + 1}</td>
                      <td>{ret?.branch_name}</td>
                      <td className="text-center">{ret?.llRow?.week ? ret?.llRow?.week : ''}</td>
                      <td className="text-center">{ret?.llRow?.reg_date?.replace(/-/g, '.')?.substring(2, 16) || ''}</td>
                      <td className="text-center">
                        <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => make_data(ret?.branch_code, ret?.branch_name)}>생성</button>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>
    </CmnCard>
  );
};

export default AnalysisMonthlyAnalysisPt;
