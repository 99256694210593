import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";

const FinanceSpending = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/finance/finance/spending/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_biyong=${$('#s_biyong option:selected').val() || ''}&s_sub_price=${$('#s_sub_price option:selected').val() || ''}&s_ck_sangho=${$('#s_ck_sangho').val()}&search_auth_code=&auth_code=&reg_step=&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.Top_list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }
          if ($('#s_biyong').html() === '') {
            let optionsHTML = '<option value="">계정 선택</option>';
            result?.qry?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.acc_no}">${list_row.acc_name}</option>`;
            });
            $('#s_biyong').html(optionsHTML);
          }

          setRets(result?.list);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const del_data = (ckkNo) => {
    if (!$('#auth_code').val()) {
      alert('인증 해주세요');
      $('#auth_code').focus();
      return;
    }

    if (window.confirm('해당 지출내역을 삭제하시겠습니까?')) {
      axios2
        .post(`/finance/finance/cash?type=del&ckk_no=${ckkNo}&auth_code=${$('#auth_code').val()}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  //3-1
  const show_sub = async () => {
    let value;
    try {
      const response = await fetch('finance_cash_sub.php', {
        method: 'POST',
        body: `acc=${value}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (!response.ok) {
        throw new Error('데이터오류! 시스템 관리자에게 문의하세요');
      }

      const data = await response.text();
      const trimmedData = data.trim();
      const subPriceElement = document.getElementById('sub_price');

      if (subPriceElement) {
        subPriceElement.innerHTML = `<option value=''>지출세부</option>${trimmedData}`;
      }
    } catch (error) {
      alert(error.message);
    }
  };

  //4-1
  const show_sub1 = async () => {
    let value;
    let sSubPrice;

    try {
      const response = await fetch('finance_cash_sub.php', {
        method: 'POST',
        body: `acc=${value}&s_sub_price=${sSubPrice}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (!response.ok) {
        throw new Error('데이터오류! 시스템 관리자에게 문의하세요');
      }

      const data = await response.text();
      const trimmedData = data.trim();
      const subPriceElement = document.getElementById('sub_price');

      if (subPriceElement) {
        subPriceElement.innerHTML = `<option value=''>지출세부</option>${trimmedData}`;
      }
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 justify-content-between flex-wrap">
        <div className="d-flex my-1 fn_search_box">
          <div className="pr-1">
            <select className="form-select" id="s_branch_code">
            </select>
          </div>
          <div className="d-flex pr-1">
            <p className="p-1 bold700">검색기간 :</p>
            <JDatePicker className="form-control" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="s_date2"/>
          </div>
          <div className="d-flex pr-1">
            <select className="form-select" id="s_biyong">
            </select>
          </div>
          <div className="d-flex pr-1">
            <select className="form-select" id="s_sub_price">
              <option value="">지출세부</option>
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="거래처 입력" id="s_ck_sangho"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(() => Fn.getRenewTime())}>검색</button>
          </div>
        </div>
        <div className="d-flex my-1">
          <ChkAuthNum type={"locker_info_change"}/>
        </div>
      </div>

      <div className="m-3 pb-2">
        <table className="view-rounded sub_table_border auto">
          <tr>
            <th style={{width: "80px"}}>No</th>
            <th>지점명</th>
            <th>일자</th>
            <th>거래처</th>
            <th>계정과목</th>
            <th>지출세부</th>
            <th>지출금액</th>
            <th>메모</th>
            <th>등록자</th>
            <th style={{width: '90px'}}>삭제</th>
          </tr>
          {
            rets.map((row, i) => {
              const count = i + 1;
              // TODO
              // total_price += row.ckk_amt;

              return (
                <tr key={i}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>{row.branch_name}</td>
                  <td>{row.ckk_date}</td>
                  <td className="text-left">{row.ckk_sangho}</td>
                  <td className="text-left">{row.ckk_acname}</td>
                  <td className="text-left">{row.sub_price}</td>
                  <td className="text-right">{Fn.numberWithCommas(row.ckk_amt)}</td>
                  <td className="text-left">{row.ckk_etc}</td>
                  <td>{row.ckk_step}</td>
                  <td>
                    {row.ckk_date === Fn.getCurrentDate() && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7 ? (
                      <button
                        className="btn btn-ssm btn-outline-dark"
                        onClick={() => del_data(row.ckk_no)}
                      >
                        삭제
                      </button>
                    ) : (
                      <button
                        className="btn btn-ssm btn-outline-dark"
                        onClick={() =>
                          alert("삭제는 당일건에 한해 지점장 이상 권한 사용자만 가능합니다")
                        }
                      >
                        삭제
                      </button>
                    )}
                  </td>
                </tr>
              );
            })
          }
        </table>
      </div>
      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>
    </CmnCard>
  );
};

export default FinanceSpending;
