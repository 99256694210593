import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import JDatePicker from "../common/JDatePicker";
import {useHistory, useLocation} from "react-router-dom";
import PostCode from "../common/PostCode";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";
import MvBlackListReg from "../member/MvBlackListReg";
import Modal from "react-modal";
import Constants from "../../constants/constants";

const EmployeeReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

    const [isPostCodeOpen, setPostCodeOpen] = useState(false);
    const [isMvBlackListReg, setIsMvBlackListReg] = useState(false);
    const [blType, setBlType] = useState('register');

    const history = useHistory();

    const location = useLocation();
    const baseSeq = location?.state?.baseSeq || '';

    const [renew, setRenew] = useState(0);
    const [result, setResult] = useState({});

    const [modal1Open, setModal1Open] = useState(false);
    const [modal1Result, setModal1Result] = useState({});

    const [modal2Open, setModal2Open] = useState(false);
    const [modal2Result, setModal2Result] = useState({});

    const [modal3Open, setModal3Open] = useState(false);
    const [modal3Result, setModal3Result] = useState({});

    useEffect(() => {
      $('#base_seq').val(baseSeq);

      $("#mail_sel").change(function () {
        if ($("#mail_sel option:selected").val() == "1") {
          $("#member_id2").attr("readonly", false);
          $("#member_id2").val("");
        } else {
          $("#member_id2").val($("#mail_sel option:selected").val());
          $("#member_id2").attr("readonly", true);
        }
      });
      $("#member_id1").keyup(function () {
        $(this).val($(this).val().replace(/[^a-z0-9:\-_.]/gi, ""));
      });
      $("#member_id2").keyup(function () {
        $(this).val($(this).val().replace(/[^a-z0-9:\-_.]/gi, ""));
      });
    }, []);

    useEffect(() => {
      load();
    }, [renew]);

    const load = () => {
      axios2
        .post(`/employee/emp/reg/ready?base_seq=${baseSeq || ''}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            if ($('#position').html() === '') {
              let optionsHTML = '<option value="">::계약명칭선택</option>';
              result?.position_list?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.sub_code}">${list_row.sub_code_name}</option>`;
              });
              $('#position').html(optionsHTML);
            }
            if ($('#jibu_id').html() === '') {
              let optionsHTML = '<option value="">::지부장 선택::</option>';
              result?.jibu_id_list?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.user_id}">${list_row.user_name}</option>`;
              });
              $('#jibu_id').html(optionsHTML);
            }
            if ($('#bank_code').html() === '') {
              let optionsHTML = '<option value="">은행선택</option>';
              result?.bank_list?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.sub_code}">${list_row.sub_code_name}</option>`;
              });
              $('#bank_code').html(optionsHTML);
            }
            if ($('#part').html() === '') {
              let optionsHTML = '<option value="">::부서선택::</option>';
              result?.part_list?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.sub_code}">${list_row.sub_code_name}</option>`;
              });
              $('#part').html(optionsHTML);
            }
            if ($('#new_position').html() === '') {
              let optionsHTML = '<option value="">::계약형태선택::</option>';
              result?.new_position_list?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.sub_code}">${list_row.sub_code_name}</option>`;
              });
              $('#new_position').html(optionsHTML);
            }

            if (baseSeq) {
              setResult(result);

              console.log(result);

              $('#user_name').val(result?.user_name || '');
              $('#account_no').val(result?.account_no || '');
              $('#address1').val(result?.addr1 || '');
              $('#address2').val(result?.addr2 || '');
              $('#chagam_gubun').val(result?.chagam_gubun || '');
              $('#pt_YN').attr("checked", result?.pt_YN == "Y");
              $('#etc_use').val(result?.etc_use || ''); // TODO : checkbox??

              $('#tel1').val(result?.tel?.split("-")?.[0] || '');
              $('#tel2').val(result?.tel?.split("-")?.[1] || '');
              $('#tel3').val(result?.tel?.split("-")?.[2] || '');

              $('#hp1').val(result?.hp?.split("-")?.[0] || '');
              $('#hp2').val(result?.hp?.split("-")?.[1] || '');
              $('#hp3').val(result?.hp?.split("-")?.[2] || '');

              $('#jumin_no').val(result?.jumin_no || '');

              $('#post1').val(result?.post?.split("-")?.[0] || '');
              $('#post2').val(result?.post?.split("-")?.[1] || '');

              $('#member_id1').val(result?.mall_id?.split("@")?.[0] || '');
              $('#member_id2').val(result?.mall_id?.split("@")?.[1] || '');

              $('#jibun_rate').val(result?.jibun_rate || '');
              $('#chagam_rate').val(result?.chagam_rate || '');
              $('#out_date').val(result?.out_date || '');
              $('#position').val(result?.position || '');
              $('#jibu_id').val(result?.jibu_id || '');
              $('#base_seq').val(result?.base_seq || '');
              $('#auth_code').val(result?.auth_code || '');
              $('#groupware_id').val(result?.groupware_id || '');
              $('#pay_part').val(result?.pay_part || '');
              $('#zonecode').val(result?.zonecode || '');
              $('#in_date').val(result?.in_date || '');
              $('#step_pic').val(result?.step_pic || '');
              $('#new_position').val(result?.new_position || '');
              if (result?.gender === "M")
                $('#mb_sexM').prop("checked", true);
              else if (result?.gender === "F")
                $('#mb_sexF').prop("checked", true);
              $('#part').val(result?.part || '');
              $('#bank_code').val(result?.bank_code || '');

              $('#career').val(result?.career || '');
              $('#declarem').val(result?.declarem || '');
              $('#intro').val(result?.intro || '');

              if (result?.b_list?.[0] === 'Y') $('#bl_reg_type').val('release');
              else $('#bl_reg_type').val('register');

              // TODO
              // $('#member_pwd').val(result?.bank_code || '');

              {
                result?.lic_info?.forEach((list_row, index) => {
                  $(`#lic_name_${index + 1}`).val(list_row?.lic_name || '');
                  $(`#lic_grade_${index + 1}`).val(list_row?.lic_grade || '');
                  $(`#lic_date_${index + 1}`).val(list_row?.lic_date || '');
                  $(`#lic_org_${index + 1}`).val(list_row?.lic_org || '');
                  $(`#lic_memo_${index + 1}`).val(list_row?.lic_memo || '');
                });
                $('#lic_cnt').val(result?.lic_info?.length || "1");

                for (let i = 1; i <= Fn.parseInt($('#lic_cnt').val()); i++) {
                  $("#lic_show" + i).show();
                }
              }
              {
                result?.lag_info?.forEach((list_row, index) => {
                  $(`#lag_name_${index + 1}`).val(list_row?.lag_name || '');
                  $(`#lag_exam_${index + 1}`).val(list_row?.lag_exam || '');
                  $(`#lag_score_${index + 1}`).val(list_row?.lag_score || '');
                  $(`#lag_org_${index + 1}`).val(list_row?.lag_org || '');
                  $(`#lag_memo_${index + 1}`).val(list_row?.lag_memo || '');
                });
                $('#lag_cnt').val(result?.lag_info?.length || "1");

                for (let i = 1; i <= Fn.parseInt($('#lag_cnt').val()); i++) {
                  $("#lag_show" + i).show();
                }
              }
              {
                result?.speak_info?.forEach((list_row, index) => {
                  $(`#speak_country_${index + 1}`).val(list_row?.speak_country || '');
                  $(`#speak_language_${index + 1}`).val(list_row?.speak_language || '');
                  $(`#speak_period_${index + 1}`).val(list_row?.speak_period || '');
                  $(`#speak_org_${index + 1}`).val(list_row?.speak_org || '');
                  $(`#speak_memo_${index + 1}`).val(list_row?.speak_memo || '');
                });
                $('#speak_cnt').val(result?.speak_info?.length || "1");

                for (let i = 1; i <= Fn.parseInt($('#speak_cnt').val()); i++) {
                  $("#speak_show" + i).show();
                }
              }
              {
                result?.job_info?.forEach((list_row, index) => {
                  $(`#job_name_${index + 1}`).val(list_row?.job_name || '');
                  $(`#job_item_${index + 1}`).val(list_row?.job_item || '');
                  $(`#job_s_date_${index + 1}`).val(list_row?.job_s_date || '');
                  $(`#job_e_date_${index + 1}`).val(list_row?.job_e_date || '');
                  $(`#job_year_${index + 1}`).val(list_row?.job_year || '');
                  $(`#job_position_${index + 1}`).val(list_row?.job_position || '');
                  $(`#job_memo_${index + 1}`).val(list_row?.job_memo || '');
                  $(`#job_part_${index + 1}`).val(list_row?.job_part || '');
                });
                $('#job_cnt').val(result?.job_info?.length || "1");

                for (let i = 1; i <= Fn.parseInt($('#job_cnt').val()); i++) {
                  $("#job_show" + i).show();
                }
              }
              {
                result?.edu_info?.forEach((list_row, index) => {
                  $(`#edu_course_${index + 1}`).val(list_row?.edu_course || '');
                  $(`#edu_number_${index + 1}`).val(list_row?.edu_number || '');
                  $(`#edu_date_${index + 1}`).val(list_row?.edu_date || '');
                  $(`#edu_state_${index + 1}`).val(list_row?.edu_state || '');
                  $(`#edu_memo_${index + 1}`).val(list_row?.edu_memo || '');
                });
                $('#edu_cnt').val(result?.edu_info?.length || "1");

                for (let i = 1; i <= Fn.parseInt($('#edu_cnt').val()); i++) {
                  $("#edu_show" + i).show();
                }
              }
              {
                result?.rnf_info?.forEach((list_row, index) => {
                  $(`#rnf_gubun_${index + 1}`).val(list_row?.rnf_gubun || '');
                  $(`#rnf_cont_${index + 1}`).val(list_row?.rnf_cont || '');
                  $(`#rnf_date_${index + 1}`).val(list_row?.rnf_date || '');
                  $(`#rnf_memo_${index + 1}`).val(list_row?.rnf_memo || '');
                });
                $('#rnf_cnt').val(result?.rnf_info?.length || "1");

                for (let i = 1; i <= Fn.parseInt($('#rnf_cnt').val()); i++) {
                  $("#rnf_show" + i).show();
                }
              }
            }
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }

    function lic_add() {
      if (Fn.parseInt($('#lic_cnt').val()) >= 5) {
        alert('최대 가능갯수는 5개 입니다');
      } else {
        let now_cnt = Fn.parseInt($('#lic_cnt').val());
        let new_cnt = now_cnt + 1;

        for (let i = 1; i <= new_cnt; i++) {
          $("#lic_show" + i).show();
        }

        $('#lic_cnt').val(new_cnt);
      }
    }

    function lag_add() {
      if (Fn.parseInt($('#lag_cnt').val()) >= 5) {
        alert('최대 가능갯수는 5개 입니다');
      } else {
        let now_cnt = Fn.parseInt($('#lag_cnt').val());
        let new_cnt = now_cnt + 1;

        for (let i = 1; i <= new_cnt; i++) {
          $("#lag_show" + i).show();
        }

        $('#lag_cnt').val(new_cnt);
      }
    }

    function speak_add() {
      if (Fn.parseInt($('#speak_cnt').val()) >= 5) {
        alert('최대 가능갯수는 5개 입니다');
      } else {
        let now_cnt = Fn.parseInt($('#speak_cnt').val());
        let new_cnt = now_cnt + 1;

        for (let i = 1; i <= new_cnt; i++) {
          $("#speak_show" + i).show();
        }

        $('#speak_cnt').val(new_cnt);
      }
    }

    function job_add() {
      if (Fn.parseInt($('#job_cnt').val()) >= 5) {
        alert('최대 가능갯수는 5개 입니다');
      } else {
        let now_cnt = Fn.parseInt($('#job_cnt').val());
        let new_cnt = now_cnt + 1;

        for (let i = 1; i <= new_cnt; i++) {
          $("#job_show" + i).show();
        }

        $('#job_cnt').val(new_cnt);
      }
    }

    function edu_add() {
      if (Fn.parseInt($('#edu_cnt').val()) >= 5) {
        alert('최대 가능갯수는 5개 입니다');
      } else {
        let now_cnt = Fn.parseInt($('#edu_cnt').val());
        let new_cnt = now_cnt + 1;

        for (let i = 1; i <= new_cnt; i++) {
          $("#edu_show" + i).show();
        }

        $('#edu_cnt').val(new_cnt);
      }
    }

    function rnf_add() {
      if (Fn.parseInt($('#rnf_cnt').val()) >= 5) {
        alert('최대 가능갯수는 5개 입니다');
      } else {
        let now_cnt = Fn.parseInt($('#rnf_cnt').val());
        let new_cnt = now_cnt + 1;

        for (let i = 1; i <= new_cnt; i++) {
          $("#rnf_show" + i).show();
        }

        $('#rnf_cnt').val(new_cnt);
      }
    }

    //  2-1.
    function ajaxFileUpload() {
      // 업로드 버튼이 클릭되면 파일 찾기 창을 띄운다.
      $("#userfile").click();
    }

    //  3-1.
    function ajaxFileChange() {
      // 파일이 선택되면 업로드를 진행한다.
      ajaxFileTransmit();
    }

    //  4-1.
    function ajaxFileTransmit() {
      var form = $('form')[0];
      var formData = new FormData(form);
      $.ajax({
        url: 'img_tempsave.php',
        processData: false,
        contentType: false,
        data: formData,
        type: 'POST',
        success: function (data) {
          data = data.trim();
          //alert(data);
          var split = data.split("||");

          info_form.step_pic.value = split[1];
          var pic_data = split[0];
          $('#pic_btn').hide();
          $('#b_pic').append(pic_data);
        }
      });
    }

    //  5-1.
    const info_form = document.getElementById('info_form');

    function temp_img_del(d_id) {
      $('#' + d_id).remove();
      $('#pic_btn').show();
      info_form.step_pic.value = "";
      //템프DB 삭제
    }

    //  6-1.
    function manage_view() {
      let manage_show;

      if (info_form.position.value === "0002") {
        manage_show.style.display = "inline";
      } else {
        manage_show.style.display = "none";
      }
    }

    //  7-1.
    function chk_auth_code(value) {
      /*
      if (value) {
        //해당지점 리스트만 표시
        $.ajax({
          type: "post",
          url: "chk_auth_code.php",
          data: "auth_code=" + value,
          success: function (data) {
            //쿼리된 지점 표시
            data = data.trim();
            if (data === "dup") {
              alert('동일한 인증코드가 존재합니다');
              info_form.auth_code.value = "";
              return;
            }
            if (data === "short") {
              alert('4자리 이상으로 등록해주세요');
              info_form.auth_code.value = "";
              return;
            }
          },
          error: function (e) {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          }
        });
      }
      */
    }

    //  8-1.
    function chk_group_id(value) {
      if (value) {
        //해당지점 리스트만 표시
        $.ajax({
          type: "post",
          url: "chk_group_id.php",
          data: {groupware_id: value, base_seq: ""},
          success: function (data) {
            //쿼리된 지점 표시
            data = data.trim();
            if (data === "dup") {
              alert('동일한 그룹웨어아이디가 존재합니다');
              info_form.groupware_id.value = "";
              return;
            }
          },
          error: function (e) {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          }
        });
      }
    }

    function go_submit() {
      if ($('#user_name').val() === "") {
        alert('성명을 입력해주세요');
        $('#user_name').focus();
        return;
      }

      if ($('#position option:selected').val() === "") {
        alert('계약명칭을 선택해주세요');
        $('#position').focus();
        return;
      }

      if ($('#position option:selected').val() === "0002" && $('#jibu_id option:selected').val() === "") {
        alert('지부장 아이디를 설정해주세요');
        return;
      }

      if ($('#pay_part option:selected').val() === "") {
        alert('수수료부서를 선택해주세요');
        $('#pay_part').focus();
        return;
      }
      if ($('#auth_code').val() === "") {
        alert('인증코드를 입력해주세요');
        $('#auth_code').focus();
        return;
      }

      if ($('#auth_code').val().length < 4) {
        alert('인증코드는 최소 4자리로 입력해주세요');
        $('#auth_code').focus();
        return;
      }

      if ($("[name='gender']:checked").val() === "") {
        alert('성별을 선택해주세요');
        return;
      }

      if ($('#member_id1').val() !== "" && $('#member_id2').val() === "") {
        alert('쇼핑몰 아이디를 정확히 입력해주세요');
        return;
      }
      if ($('#member_id1').val() === "" && $('#member_id2').val() !== "") {
        alert('쇼핑몰 아이디를 정확히 입력해주세요');
        return;
      }

      if (
        $('#member_id1').val() !== "" &&
        $('#member_id2').val() !== "" &&
        $('#member_pwd').val() === ""
      ) {
        alert('쇼핑몰 비밀번호를 입력해주세요');
        return;
      }

      const formData = new FormData();

      formData.append("user_name", $('#user_name').val() || '');
      formData.append("account_no", $('#account_no').val() || '');
      formData.append("address1", $('#address1').val() || '');
      formData.append("address2", $('#address2').val() || '');
      formData.append("chagam_gubun", $('#chagam_gubun option:selected').val() || '');
      formData.append("pt_YN", $('#pt_YN:checked').val() || '');
      formData.append("etc_use", $('#etc_use').val() || '');

      formData.append("tel1", $('#tel1').val() || '');
      formData.append("tel2", $('#tel2').val() || '');
      formData.append("tel3", $('#tel3').val() || '');

      formData.append("hp1", $('#hp1').val() || '');
      formData.append("hp2", $('#hp2').val() || '');
      formData.append("hp3", $('#hp3').val() || '');

      formData.append("jumin_no", $('#jumin_no').val() || ''); // base64

      formData.append("post1", $('#post1').val() || '');
      formData.append("post2", $('#post2').val() || '');

      formData.append("member_id1", $('#member_id1').val() || '');
      formData.append("member_id2", $('#member_id2').val() || '');

      formData.append("jibun_rate", $('#jibun_rate').val() || '');
      formData.append("chagam_rate", $('#chagam_rate').val() || '');
      formData.append("out_date", $('#out_date').val() || '');
      formData.append("position", $('#position option:selected').val() || '');
      formData.append("jibu_id", $('#jibu_id option:selected').val() || '');
      formData.append("base_seq", $('#base_seq').val() || '');
      formData.append("auth_code", $('#auth_code').val() || '');
      formData.append("groupware_id", $('#groupware_id').val() || '');
      formData.append("pay_part", $('#pay_part option:selected').val() || '');
      formData.append("zonecode", $('#zonecode').val() || '');
      formData.append("in_date", $('#in_date').val() || '');
      formData.append("step_pic", $('#step_pic').val() || '');
      formData.append("new_position", $('#new_position option:selected').val() || '');
      formData.append("gender", $("[name='gender']:checked").val() || '');
      formData.append("part", $('#part option:selected').val() || '');
      formData.append("bank_code", $('#bank_code option:selected').val() || '');

      formData.append("member_pwd", $('#member_pwd').val() || '');

      axios2.postFormEx('/employee/emp/reg', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          const res_data = data.split("||");
          const return_value = res_data[0];
          const base_seq = res_data[1];

          if (return_value === "err") {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          } else if (return_value === "dup_auth_code") {
            alert('동일한 인증번호가 존재합니다')
          } else if (return_value === "dup_group_id") {
            alert('동일한 그룹웨어 아이디가 존재합니다')
          } else if (return_value === "insert_err") {
            alert("등록오류! 관리자에게 문의하세요.");
          } else if (return_value === "up_err") {
            alert("수정오류! 관리자에게 문의하세요.");
            history.push("/employee/list");
          } else if (return_value === "insert_ok") {
            alert("등록되었습니다");
            $('#base_seq').val(base_seq);
            setRenew(Fn.getRenewTime());
            history.push("/employee/list");
          } else if (return_value === "update_ok") {
            alert("수정되었습니다");
            setRenew(Fn.getRenewTime());
            history.push("/employee/list");
          } else if (return_value === "dup_mall_id") {
            alert("동일한 쇼핑몰 아이디가 존재합니다.");
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }

    function go_detail() {
      const formData = new FormData();

      formData.append("declarem", $('#declarem').val() || '');
      formData.append("intro", $('#intro').val() || '');
      formData.append("career", $('#career').val() || '');
      formData.append("base_seq", $('#base_seq').val() || '');

      formData.append(`lic_cnt`, $(`#lic_cnt`).val() || '');
      for (let i = 1; i <= 5; i++) {
        formData.append(`lic_name_${i}`, $(`#lic_name_${i}`).val() || '');
        formData.append(`lic_grade_${i}`, $(`#lic_grade_${i}`).val() || '');
        formData.append(`lic_date_${i}`, $(`#lic_date_${i}`).val() || '');
        formData.append(`lic_org_${i}`, $(`#lic_org_${i}`).val() || '');
        formData.append(`lic_memo_${i}`, $(`#lic_memo_${i}`).val() || '');
      }

      formData.append(`lag_cnt`, $(`#lag_cnt`).val() || '');
      for (let i = 1; i <= 5; i++) {
        formData.append(`lag_name_${i}`, $(`#lag_name_${i}`).val() || '');
        formData.append(`lag_exam_${i}`, $(`#lag_exam_${i}`).val() || '');
        formData.append(`lag_score_${i}`, $(`#lag_score_${i}`).val() || '');
        formData.append(`lag_org_${i}`, $(`#lag_org_${i}`).val() || '');
        formData.append(`lag_memo_${i}`, $(`#lag_memo_${i}`).val() || '');
      }

      formData.append(`speak_cnt`, $(`#speak_cnt`).val() || '');
      for (let i = 1; i <= 5; i++) {
        formData.append(`speak_country_${i}`, $(`#speak_country_${i}`).val() || '');
        formData.append(`speak_language_${i}`, $(`#speak_language_${i}`).val() || '');
        formData.append(`speak_period_${i}`, $(`#speak_period_${i}`).val() || '');
        formData.append(`speak_org_${i}`, $(`#speak_org_${i}`).val() || '');
        formData.append(`speak_memo_${i}`, $(`#speak_memo_${i}`).val() || '');
      }

      formData.append(`job_cnt`, $(`#job_cnt`).val() || '');
      for (let i = 1; i <= 5; i++) {
        formData.append(`job_name_${i}`, $(`#job_name_${i}`).val() || '');
        formData.append(`job_item_${i}`, $(`#job_item_${i}`).val() || '');
        formData.append(`job_s_date_${i}`, $(`#job_s_date_${i}`).val() || '');
        formData.append(`job_e_date_${i}`, $(`#job_e_date_${i}`).val() || '');
        formData.append(`job_year_${i}`, $(`#job_year_${i}`).val() || '');
        formData.append(`job_position_${i}`, $(`#job_position_${i}`).val() || '');
        formData.append(`job_memo_${i}`, $(`#job_memo_${i}`).val() || '');
        formData.append(`job_part_${i}`, $(`#job_part_${i}`).val() || '');
      }

      formData.append(`edu_cnt`, $(`#edu_cnt`).val() || '');
      for (let i = 1; i <= 5; i++) {
        formData.append(`edu_course_${i}`, $(`#edu_course_${i}`).val() || '');
        formData.append(`edu_number_${i}`, $(`#edu_number_${i}`).val() || '');
        formData.append(`edu_date_${i}`, $(`#edu_date_${i}`).val() || '');
        formData.append(`edu_state_${i}`, $(`#edu_state_${i}`).val() || '');
        formData.append(`edu_memo_${i}`, $(`#edu_memo_${i}`).val() || '');
      }

      formData.append(`rnf_cnt`, $(`#rnf_cnt`).val() || '');
      for (let i = 1; i <= 5; i++) {
        formData.append(`rnf_gubun_${i}`, $(`#rnf_gubun_${i}`).val() || '');
        formData.append(`rnf_cont_${i}`, $(`#rnf_cont_${i}`).val() || '');
        formData.append(`rnf_date_${i}`, $(`#rnf_date_${i}`).val() || '');
        formData.append(`rnf_memo_${i}`, $(`#rnf_memo_${i}`).val() || '');
      }

      if ($('#contfile').val()) formData.append("contfile", $('#contfile')[0].files[0]);
      if ($('#infofile').val()) formData.append("infofile", $('#infofile')[0].files[0]);
      if ($('#accountfile').val()) formData.append("accountfile", $('#accountfile')[0].files[0]);
      if ($('#juminfile').val()) formData.append("juminfile", $('#juminfile')[0].files[0]);

      formData.append("contfile_del", $('#contfile_del').val() || '');
      formData.append("infofile_del", $('#infofile_del').val() || '');
      formData.append("accountfile_del", $('#accountfile_del').val() || '');
      formData.append("juminfile_del", $('#juminfile_del').val() || '');

      formData.append("base_seq", $('#base_seq').val() || '');

      axios2.postFormEx('/employee/emp/detail/reg', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          const res_data = data.split("||");
          const return_value = res_data[0];
          const base_seq = res_data[1];

          if (return_value === "err") {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          } else if (return_value === "insert_err") {
            alert("등록오류! 관리자에게 문의하세요.");
          } else if (return_value === "insert_ok") {
            alert("등록되었습니다");
            $('#form1')[0].reset();
            $('#contfile').val('');
            $('#infofile').val('');
            $('#accountfile').val('');
            $('#juminfile').val('');
            setRenew(Fn.getRenewTime());
            history.push("/employee/list");
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }

//  11-1.
    let conffile_show;
    const detail_form = document.getElementById('detail_form');

//  15-1.
    $(function () {
      $("#mail_sel").change(function () {
        if ($("#mail_sel option:selected").val() == "1") {
          $("#member_id2").attr("readonly", false);
          $("#member_id2").val("");
        } else {
          $("#member_id2").val($("#mail_sel option:selected").val());
          $("#member_id2").attr("readonly", true);
        }
      });

      $("#member_id1").keyup(function () {
        $(this).val($(this).val().replace(/[^a-z0-9:\-_.]/gi, ""));
      });

      $("#member_id2").keyup(function () {
        $(this).val($(this).val().replace(/[^a-z0-9:\-_.]/gi, ""));
      });
    });

    function chk_id() {
      if (!$("#member_id1").val()) {
        alert("아이디를 입력해주세요");
        return;
      }
      if (!$("#member_id2").val()) {
        alert("아이디를 입력해주세요");
        return;
      }

      var member_id1 = $("#member_id1").val();
      var member_id2 = $("#member_id2").val();

      axios2
        .post(`/employee/chk/mall/id?member_id1=${member_id1}&member_id2=${member_id2}`)
        .then((response) => {
          const {code, message: data, result, pageInfo} = response?.data;
          if (code === 200) {
            if (data == "dup") {
              alert("이미 사용중인 아이디입니다");
              $("#member_id1").val('');
              $("#member_id2").val('');
              $("#id_chk").val('');
            } else if (data == "ok") {
              alert("사용가능한 아이디입니다");
              $("#id_chk").val('Y');
            }
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }

    function loadModal1() {
      axios2
        .post(`/employee/change/branch/ready?base_seq=${$('#base_seq').val()}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            setModal1Result(result);

            if ($('#m1_after_branch_code').html() === '') {
              let optionsHTML = '<option value="">::이동지점 선택::</option>';
              result?.topList?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
              });
              $('#m1_after_branch_code').html(optionsHTML);
            }
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }

    function go_submit1() {
      if ($('#m1_change_date').val() == "") {
        alert("이동일을 선택해주세요");
        $('#m1_change_date').focus();
        return;
      }
      if ($('#m1_after_branch_code').val() == "") {
        alert("이동지점을 선택해주세요");
        $('#m1_after_branch_code').focus();
        return;
      }

      axios2
        .postEx('/employee/change/branch', {
          change_date: $('#m1_change_date').val(),
          before_branch_code: localStorage.getItem("USER.systemBranchCode"),
          after_branch_code: $('#m1_after_branch_code option:selected').val(),
          bigo: $('#m1_bigo').val(),
          base_seq: $('#base_seq').val(),
        })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data == "err") {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          } else if (data == "up_err") {
            alert("수정오류! 관리자에게 문의하세요.");
          } else if (data == "update_ok") {
            alert("정상 처리되었습니다");
            setRenew(Fn.getRenewTime());
            setModal1Open(false);
            history.push("/employee/list");
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }

    function loadModal2() {
      axios2
        .post(`/employee/change/new/position/ready?base_seq=${$('#base_seq').val()}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            setModal2Result(result);

            $('#m2_before_new_position').val(result?.sInfoRow?.new_position);

            if ($('#m2_after_new_position').html() === '') {
              let optionsHTML = '<option value="">::직급 선택::</option>';
              result?.topList?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.sub_code}">${list_row.sub_code_name}</option>`;
              });
              $('#m2_after_new_position').html(optionsHTML);
            }
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }

    function go_submit2() {
      if ($('#m2_change_date').val() == "") {
        alert("변경일을 선택해주세요");
        $('#m2_change_date').focus();
        return;
      }
      if ($('#m2_after_new_position').val() == "") {
        alert("변경직급을 선택해주세요");
        $('#m2_after_new_position').focus();
        return;
      }

      axios2
        .postEx('/employee/change/new/position', {
          change_date: $('#m2_change_date').val(),
          before_new_position: $('#m2_before_new_position').val(),
          after_new_position: $('#m2_after_new_position option:selected').val(),
          bigo: $('#m2_bigo').val(),
          base_seq: $('#base_seq').val(),
        })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data == "err") {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          } else if (data == "up_err") {
            alert("수정오류! 관리자에게 문의하세요.");
          } else if (data == "update_ok") {
            alert("정상 처리되었습니다");
            setRenew(Fn.getRenewTime());
            setModal2Open(false);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }

    function loadModal3() {
      axios2
        .post(`/employee/change/position/ready?base_seq=${$('#base_seq').val()}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            setModal3Result(result);

            console.log(result);
            $('#m3_before_position').val(result?.sInfoRow?.position);

            if ($('#m3_after_position').html() === '') {
              let optionsHTML = '<option value="">::직무구분 선택::</option>';
              result?.topList?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.sub_code}">${list_row.sub_code_name}</option>`;
              });
              $('#m3_after_position').html(optionsHTML);
            }
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }

    function go_submit3() {
      if ($('#m3_change_date').val() == "") {
        alert("변경일을 선택해주세요");
        $('#m3_change_date').focus();
        return;
      }
      if ($('#m3_after_position').val() == "") {
        alert("변경직무를 선택해주세요");
        $('#m3_after_position').focus();
        return;
      }

      axios2
        .postEx('/employee/change/position', {
          change_date: $('#m3_change_date').val(),
          before_position: $('#m3_before_position').val(),
          after_position: $('#m3_after_position option:selected').val(),
          bigo: $('#m3_bigo').val(),
          base_seq: $('#base_seq').val(),
        })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data == "err") {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          } else if (data == "up_err") {
            alert("수정오류! 관리자에게 문의하세요.");
          } else if (data == "update_ok") {
            alert("정상 처리되었습니다");
            setRenew(Fn.getRenewTime());
            setModal3Open(false);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }

    function contfile_del() {
      $('#contfile_show').hide();
      $('#contfile_del').val("Y");
    }

    function infofile_del() {
      $('#infofile_show').hide();
      $('#infofile_del').val("Y");
    }

    function accountfile_del() {
      $('#accountfile_show').hide();
      $('#accountfile_del').val("Y");
    }

    function juminfile_del() {
      $('#juminfile_show').hide();
      $('#juminfile_del').val("Y");
    }

    function onDownload(url, filename) {
      Fn.download(url, filename);
    }

    return (
      <CmnCard onLogout={props.onLogout}>
        <div className="m-3" style={{clear: 'both'}}>
          <div className="tabs">
            <input id="all" type="radio" name="tab_item" checked/>
            <label className="tab_item" htmlFor="all">기본정보</label>
            {baseSeq &&
              <>
                <input id="programming" type="radio" name="tab_item"/>
                <label className="tab_item" htmlFor="programming"
                       onClick={() => {
                         history.push({
                           pathname: '/employee/reg/pay', state: {
                             baseSeq: baseSeq
                           }
                         });
                       }}
                >기본급/요율표</label>
              </>
            }
          </div>
        </div>

        <div className="m-3 mb-4 search_box">
          <div className="border-bottom text-left">
            <p className="px-3 py-2">
              <span className="h6 bold700">스텝 기본정보</span>
            </p>
          </div>

          <div className="p-3">
            <div className="d-flex justify-content-between">
              <div>
                <div className="USER_thum_regi">
                  <p className="absmiddle">
                    <button type="button" className="btn btn-ssm btn-dark space_nowrap">+ 사진등록</button>
                  </p>
                </div>
                <p className="mt-1">500*500 이상, 1Mbyte 이내</p>
              </div>
              <div style={{width: 'calc(100% - 210px)'}}>
                <table className="view-rounded sub_view_sm">
                  <colgroup>
                    <col width="110px"/>
                    <col width="*"/>
                    <col width="110px"/>
                    <col width="*"/>
                    <col width="110px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>성명 <font color='red'>*</font></th>
                    <td><input type="text" className="form-control table-inline" id="user_name"/></td>
                    <th>계약명칭 <font color='red'>*</font></th>
                    <td>
                      <div className="d-flex">
                        <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="position">
                        </select>
                        <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="jibu_id">
                        </select>
                      </div>
                    </td>
                    <th>수수료부서 <font color='red'>*</font></th>
                    <td>
                      <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="pay_part">
                        <option value="">::수수료부서선택</option>
                        <option value="A">본사</option>
                        <option value="B">퍼블릭</option>
                        <option value="C">PT</option>
                        <option value="D">퍼블릭+PT</option>
                        <option value="E">GX</option>
                        <option value="F">용역</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>PT여부</th>
                    <td><input type="checkbox" className="form-control" id="pt_YN" value="Y"/></td>
                    <th>휴대전화</th>
                    <td>
                      <div className="input-group">
                        <input type="text" className="form-control table-inline" id="hp1"/>
                        <p className="m-1">-</p>
                        <input type="text" className="form-control table-inline" id="hp2"/>
                        <p className="m-1">-</p>
                        <input type="text" className="form-control table-inline" id="hp3"/>
                      </div>
                    </td>
                    <th>일반전화</th>
                    <td>
                      <div className="input-group">
                        <input type="text" className="form-control table-inline" placeholder="010" id="tel1"/>
                        <p className="m-1">-</p>
                        <input type="text" className="form-control table-inline" id="tel2"/>
                        <p className="m-1">-</p>
                        <input type="text" className="form-control table-inline" id="tel3"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>그룹웨어ID</th>
                    <td><input type="text" className="form-control table-inline" id="groupware_id"/></td>
                    <th>은행계좌</th>
                    <td colSpan="3">
                      <div className="d-flex">
                        <select className="form-select w-50" id="bank_code">
                        </select>
                        <input type="text" className="form-control table-inline w-100" id="account_no"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>주소</th>
                    <td colSpan="5">
                      <div className="input-group">
                        <button type="button" className="btn btn-ssm btn-outline-point mx-1" onClick={() => setPostCodeOpen(true)}>주소검색</button>
                        <input type="text" className="form-control table-inline" style={{maxWidth: '300px'}} placeholder="기본주소" id="address1"/>
                        <input type="text" className="form-control table-inline mx-1" style={{maxWidth: '350px'}} placeholder="상세주소" id="address2"/>
                        <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} placeholder="우편번호" id="zonecode"/>
                        <input type="text" className="form-control table-inline mx-1" style={{maxWidth: '60px'}} placeholder="구번호" id="post1"/>
                        &nbsp;-&nbsp;
                        <input type="text" className="form-control table-inline" style={{maxWidth: '60px'}} placeholder="구번호" id="post2"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>인증번호 <font color='red'>*</font></th>
                    <td>
                      <div className="input-group">
                        <input type="text" className="form-control table-inline" placeholder="" id="auth_code"/>
                        <p className="m-1">(4~10자리)</p>
                      </div>
                    </td>
                    <th>부서</th>
                    <td>
                      <select className="form-select" id="part">
                      </select>
                    </td>
                    <th>계약형태</th>
                    <td>
                      <select className="form-select" id="new_position">
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <th>계약체결일</th>
                    <td className="text-left"><JDatePicker className="form-control table-inline w-100" id="in_date"/></td>
                    <th>퇴사일</th>
                    <td className="text-left"><JDatePicker className="form-control table-inline w-100" id="out_date"/></td>
                    <th>승급일자</th>
                    <td className="text-left"><JDatePicker className="form-control table-inline w-100"/></td>
                  </tr>
                  <tr>
                    <th>성별</th>
                    <td className="text-left">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="gender" value="M" id="mb_sexM"/>
                        <label className="form-check-label" htmlFor="mb_sexM">남</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="gender" value="F" id="mb_sexF"/>
                        <label className="form-check-label" htmlFor="mb_sexF">여</label>
                      </div>
                    </td>
                    <th>영업컨설팅비</th>
                    <td colSpan="3">
                      <div className="d-flex">
                        <p className="m-1 bold700 space_nowrap">지분이체%</p>
                        <input type="text" className="form-control table-inline input_shorts" id="jibun_rate"/>
                        <p className="m-1">%</p>
                        <p className="m-1">/</p>
                        <p className="m-1 bold700 space_nowrap">차감%</p>
                        <input type="text" className="form-control table-inline input_shorts" id="chagam_rate"/>
                        <p className="m-1">%</p>
                        <p className="m-1">/</p>
                        <p className="m-1 bold700 space_nowrap">차감비고</p>
                        <select className="form-select w-25" id="chagam_gubun">
                          <option value="">::선택::</option>
                          <option value="법인세비축">법인세비축</option>
                          <option value="사업소득">사업소득</option>
                          <option value="사업소득">사업소득</option>
                          <option value="익명조합원">익명조합원</option>
                          <option value="위탁사업자">위탁사업자</option>
                          <option value="영업자">영업자</option>
                        </select>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>쇼핑몰ID</th>
                    <td colSpan="3">
                      <div className="d-flex">
                        {!result?.mall_id ?
                          <>
                            <input type="text" className="form-control table-inline" id="member_id1"/>
                            <p className="m-1">@</p>
                            <input type="text" className="form-control table-inline" id="member_id2" readOnly={true}/>
                            <select className="form-select mx-1" id="mail_sel" style={{maxWidth: "200px"}}>
                              <option value="">이메일선택</option>
                              <option value="naver.com">naver.com</option>
                              <option value="hanmail.net">hanmail.net</option>
                              <option value="gmail.com">gmail.com</option>
                              <option value="nate.com">nate.com</option>
                              <option value="1">직접입력</option>
                            </select>
                            <button type="button" className="btn btn-ssm btn-outline-point space_nowrap" onClick={() => chk_id()}>중복체크</button>
                            <input type="hidden" id="id_chk"/>
                          </>
                          :
                          <span>{result?.mall_id}</span>
                        }
                      </div>
                    </td>
                    <th>쇼핑몰비밀번호</th>
                    <td>
                      {result?.mall_id ?
                        <strong>쇼핑몰 로그인후 변경</strong>
                        :
                        <input type="password" className="form-control" aria-describedby="button-addon2" id="member_pwd"/>
                      }
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="mt-2">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => go_submit()}>저장</button>
              <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.push("/employee/list")}>목록</button>
            </div>
          </div>
        </div>

        {baseSeq &&
          <>
            <div className="m-3">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="50%"/>
                </colgroup>
                <tr>
                  <td colSpan="4" className="text-left">
                    <div className="d-flex justify-content-between">
                      <p><span className="h7 bold700 p-1">이동기록</span></p>
                      <p>
                        <button type="button" className="btn btn-ssm btn-dark" onClick={() => setModal1Open(true)}>이동등록</button>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>일자</th>
                  <th>이전지점</th>
                  <th>이동지점</th>
                  <th>메모</th>
                </tr>
                {result?.branch_history?.map((item, index) => (
                  <tr>
                    <td>{item?.change_date}</td>
                    <td>{item?.before_name}</td>
                    <td>{item?.after_name}</td>
                    <td className="text-left">{item?.bigo}</td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="m-3">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="50%"/>
                </colgroup>
                <tr>
                  <td colSpan="4" className="text-left">
                    <div className="d-flex justify-content-between">
                      <p><span className="h7 bold700 px-2">계약형태 기록</span></p>
                      <p>
                        <button type="button" className="btn btn-ssm btn-dark" onClick={() => setModal2Open(true)}>계약형태변경</button>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>일자</th>
                  <th>이전계약형태</th>
                  <th>변경계약형태</th>
                  <th>메모</th>
                </tr>
                {result?.new_position_history?.map((item, index) => (
                  <tr>
                    <td>{item?.change_date}</td>
                    <td>{item?.before_name}</td>
                    <td>{item?.after_name}</td>
                    <td className="text-left">{item?.bigo}</td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="m-3">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="50%"/>
                </colgroup>
                <tr>
                  <td colSpan="4" className="text-left">
                    <div className="d-flex justify-content-between">
                      <p><span className="h7 bold700 px-2">직무기록</span></p>
                      <p>
                        <button type="button" className="btn btn-ssm btn-dark" onClick={() => setModal3Open(true)}>계약명칭변경</button>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>일자</th>
                  <th>이전직무</th>
                  <th>변경직무</th>
                  <th>메모</th>
                </tr>
                {result?.position_history?.map((item, index) => (
                  <tr>
                    <td>{item?.change_date}</td>
                    <td>{item?.before_name}</td>
                    <td>{item?.after_name}</td>
                    <td className="text-left">{item?.bigo}</td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="m-3">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="120px"/>
                  <col width="*"/>
                  <col width="120px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>이력/학력</th>
                  <td className="pb-0"><textarea className="txtarea" id="career" style={{minHeight: '150px'}}></textarea></td>
                  <th>사명선언문</th>
                  <td className="pb-0"><textarea className="txtarea" id="declarem" style={{minHeight: '150px'}}></textarea></td>
                </tr>
                <tr>
                  <th>인사말</th>
                  <td colSpan="3" className="pb-0"><textarea className="txtarea" id="intro" style={{minHeight: '150px'}}></textarea></td>
                </tr>
              </table>
            </div>

            <div className="m-3">
              <input type="hidden" id="lic_cnt"/>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="50%"/>
                </colgroup>
                <tr>
                  <td colSpan="5" className="text-left">
                    <div className="d-flex justify-content-between">
                      <p><span className="h7 bold700 px-2">자격사항</span></p>
                      <p>
                        <button type="button" className="btn btn-ssm btn-dark" onClick={() => lic_add()}>추가</button>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>자격/면허종류</th>
                  <th>등급</th>
                  <th>취득일자</th>
                  <th>발행처</th>
                  <th>메모</th>
                </tr>
                {Array.from({length: 5}).map((_, a) => (
                  <tr style={{display: 'none'}} id={`lic_show${a + 1}`} key={a}>
                    <td><input type="textbox" id={`lic_name_${a + 1}`} className="form-control wid150" style={{imeMode: 'active', width: "100%"}} maxLength={20}/></td>
                    <td><input type="textbox" id={`lic_grade_${a + 1}`} className="form-control wid100" style={{imeMode: 'active', width: "100%"}} maxLength={10}/></td>
                    <td><JDatePicker type="textbox" id={`lic_date_${a + 1}`} className="form-control wid100 input_date" style={{imeMode: 'active', width: "100%"}} maxLength={10}/></td>
                    <td><input type="textbox" id={`lic_org_${a + 1}`} className="form-control wid150" style={{imeMode: 'active', width: "100%"}} maxLength={20}/></td>
                    <td><input type="textbox" id={`lic_memo_${a + 1}`} className="form-control wid400" style={{imeMode: 'active', width: "100%"}} maxLength={50}/></td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="m-3">
              <input type="hidden" id="lag_cnt"/>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="50%"/>
                </colgroup>
                <tr>
                  <td colSpan="5" className="text-left">
                    <div className="d-flex justify-content-between">
                      <p><span className="h7 bold700 px-2">외국어능력</span></p>
                      <p>
                        <button type="button" className="btn btn-ssm btn-dark" onClick={() => lag_add()}>추가</button>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>언어</th>
                  <th>시험명</th>
                  <th>시험점수</th>
                  <th>기관명</th>
                  <th>메모</th>
                </tr>
                {Array.from({length: 5}).map((_, a) => (
                  <tr style={{display: 'none'}} id={`lag_show${a + 1}`} key={a}>
                    <td><input type="textbox" id={`lag_name_${a + 1}`} className="form-control wid150" style={{imeMode: 'active', width: "100%"}} maxLength={20}/></td>
                    <td><input type="textbox" id={`lag_exam_${a + 1}`} className="form-control wid100" style={{imeMode: 'active', width: "100%"}} maxLength={10}/></td>
                    <td><input type="textbox" id={`lag_score_${a + 1}`} className="form-control wid100 input_date" style={{imeMode: 'active', width: "100%"}} maxLength={10}/></td>
                    <td><input type="textbox" id={`lag_org_${a + 1}`} className="form-control wid150" style={{imeMode: 'active', width: "100%"}} maxLength={20}/></td>
                    <td><input type="textbox" id={`lag_memo_${a + 1}`} className="form-control wid400" style={{imeMode: 'active', width: "100%"}} maxLength={50}/></td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="m-3">
              <input type="hidden" id="speak_cnt"/>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="50%"/>
                </colgroup>
                <tr>
                  <td colSpan="5" className="text-left">
                    <div className="d-flex justify-content-between">
                      <p><span className="h7 bold700 px-2">어학연수</span></p>
                      <p>
                        <button type="button" className="btn btn-ssm btn-dark" onClick={() => speak_add()}>추가</button>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>국가</th>
                  <th>언어</th>
                  <th>연수기간</th>
                  <th>연수기관</th>
                  <th>메모</th>
                </tr>
                {Array.from({length: 5}).map((_, a) => (
                  <tr style={{display: 'none'}} id={`speak_show${a + 1}`} key={a}>
                    <td><input type="textbox" id={`speak_country_${a + 1}`} className="form-control wid150" style={{imeMode: 'active', width: "100%"}} maxLength={20}/></td>
                    <td><input type="textbox" id={`speak_language_${a + 1}`} className="form-control wid100" style={{imeMode: 'active', width: "100%"}} maxLength={10}/></td>
                    <td><input type="textbox" id={`speak_period_${a + 1}`} className="form-control wid100 input_date" style={{imeMode: 'active', width: "100%"}} maxLength={10}/></td>
                    <td><input type="textbox" id={`speak_org_${a + 1}`} className="form-control wid150" style={{imeMode: 'active', width: "100%"}} maxLength={20}/></td>
                    <td><input type="textbox" id={`speak_memo_${a + 1}`} className="form-control wid400" style={{imeMode: 'active', width: "100%"}} maxLength={50}/></td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="m-3">
              <input type="hidden" id="job_cnt"/>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="17%"/>
                  <col width="*"/>
                  <col width="290px"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="23%"/>
                </colgroup>
                <tr>
                  <td colSpan="7" className="text-left">
                    <div className="d-flex justify-content-between">
                      <p><span className="h7 bold700 px-2">스텝경력</span></p>
                      <p>
                        <button type="button" className="btn btn-ssm btn-dark" onClick={() => job_add()}>추가</button>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>직장</th>
                  <th>업종</th>
                  <th>근무기간</th>
                  <th>근무년수</th>
                  <th>소속부서</th>
                  <th>최종계약형태</th>
                  <th>메모</th>
                </tr>
                {Array.from({length: 5}).map((_, a) => (
                  <tr style={{display: 'none'}} id={`job_show${a + 1}`} key={a}>
                    <td><input type="textbox" id={`job_name_${a + 1}`} className="form-control wid150" style={{imeMode: 'active', width: "100%"}} maxLength={20}/></td>
                    <td><input type="textbox" id={`job_item_${a + 1}`} className="form-control wid100" style={{imeMode: 'active', width: "100%"}} maxLength={10}/></td>
                    <td>
                      <div className="input-group" style={{width: "auto"}}>
                        <JDatePicker type="textbox" id={`job_s_date_${a + 1}`} className="form-control wid80 input_date" style={{imeMode: 'active'}} maxLength={10}/>
                        ~
                        <JDatePicker type="textbox" id={`job_e_date_${a + 1}`} className="form-control wid80 input_date" style={{imeMode: 'active'}} maxLength={10}/>
                      </div>
                    </td>
                    <td><input type="textbox" id={`job_year_${a + 1}`} className="form-control wid80" style={{imeMode: 'active', width: "100%"}} maxLength={10}/></td>
                    <td><input type="textbox" id={`job_part_${a + 1}`} className="form-control wid100" style={{imeMode: 'active', width: "100%"}} maxLength={15}/></td>
                    <td><input type="textbox" id={`job_position_${a + 1}`} className="form-control wid100" style={{imeMode: 'active', width: "100%"}} maxLength={15}/></td>
                    <td><input type="textbox" id={`job_memo_${a + 1}`} className="form-control wid200" style={{imeMode: 'active', width: "100%"}} maxLength={100}/></td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="m-3">
              <input type="hidden" id="edu_cnt"/>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="50%"/>
                </colgroup>
                <tr>
                  <td colSpan="5" className="text-left">
                    <div className="d-flex justify-content-between">
                      <p><span className="h7 bold700 px-2">교육</span></p>
                      <p>
                        <button type="button" className="btn btn-ssm btn-dark" onClick={() => edu_add()}>추가</button>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>과정명</th>
                  <th>차수</th>
                  <th>교육일시</th>
                  <th>상태</th>
                  <th>메모</th>
                </tr>
                {Array.from({length: 5}).map((_, a) => (
                  <tr style={{display: 'none'}} id={`edu_show${a + 1}`} key={a}>
                    <td><input type="textbox" id={`edu_course_${a + 1}`} className="form-control wid150" style={{imeMode: 'active', width: "100%"}} maxLength={20}/></td>
                    <td><input type="textbox" id={`edu_number_${a + 1}`} className="form-control wid100" style={{imeMode: 'active', width: "100%"}} maxLength={10}/></td>
                    <td><JDatePicker type="textbox" id={`edu_date_${a + 1}`} className="form-control wid100 input_date" style={{imeMode: 'active', width: "100%"}} maxLength={10}/></td>
                    <td><input type="textbox" id={`edu_state_${a + 1}`} className="form-control wid150" style={{imeMode: 'active', width: "100%"}} maxLength={20}/></td>
                    <td><input type="textbox" id={`edu_memo_${a + 1}`} className="form-control wid400" style={{imeMode: 'active', width: "100%"}} maxLength={50}/></td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="m-3">
              <input type="hidden" id="rnf_cnt"/>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="*"/>
                  <col width="30%"/>
                  <col width="*"/>
                  <col width="40%"/>
                </colgroup>
                <tr>
                  <td colSpan="4" className="text-left">
                    <div className="d-flex justify-content-between">
                      <p><span className="h7 bold700 px-2">상벌사항</span></p>
                      <p>
                        <button type="button" className="btn btn-ssm btn-dark" onClick={() => rnf_add()}>추가</button>
                      </p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>상벌구분</th>
                  <th>내용</th>
                  <th>일자</th>
                  <th>메모</th>
                </tr>
                {Array.from({length: 5}).map((_, a) => (
                  <tr style={{display: 'none', width: "100%"}} id={`rnf_show${a + 1}`} key={a}>
                    <td>
                      <select id={`rnf_gubun_${a + 1}`} className="form-control wid100">
                        <option value="">선택</option>
                        <option value="R">상</option>
                        <option value="F">벌</option>
                      </select>
                    </td>
                    <td><input type="textbox" id={`rnf_cont_${a + 1}`} className="form-control wid400" style={{imeMode: 'active', width: "100%"}} maxLength={50}/></td>
                    <td><JDatePicker type="textbox" id={`rnf_date_${a + 1}`} className="form-control wid100 input_date" style={{imeMode: 'active', width: "100%"}} maxLength={15}/></td>
                    <td><input type="textbox" id={`rnf_memo_${a + 1}`} className="form-control wid400" style={{imeMode: 'active', width: "100%"}} maxLength={50}/></td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="m-3">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="25%"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="30%"/>
                </colgroup>
                <tr>
                  <td colSpan="4" className="text-left">
                    <span className="h7 bold700 px-2">필독도서</span>
                  </td>
                </tr>
                <tr>
                  <th>No</th>
                  <th>도서명</th>
                  <th>저자</th>
                  <th>출판사</th>
                  <th>완료일</th>
                  <th>비고</th>
                </tr>
                {result?.book_end?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.book_name}</td>
                    <td>{item?.author}</td>
                    <td>{item?.publishing}</td>
                    <td>{item?.end_date}</td>
                    <td className="text-left">{item?.book_name}{item?.bigo}</td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="m-3">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="25%"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="30%"/>
                </colgroup>
                <tr>
                  <td colSpan="4" className="text-left">
                    <span className="h7 bold700 px-2">독서모임</span>
                  </td>
                </tr>
                <tr>
                  <th>No</th>
                  <th>도서명</th>
                  <th>저자</th>
                  <th>출판사</th>
                  <th>완료일</th>
                  <th>비고</th>
                </tr>
                {result?.book_end_meet?.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.book_name}</td>
                    <td>{item?.author}</td>
                    <td>{item?.publishing}</td>
                    <td>{item?.end_date}</td>
                    <td className="text-left">{item?.book_name}{item?.bigo}</td>
                  </tr>
                ))}
              </table>
            </div>

            <div className="m-3">
              <form id="form1">
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <td colSpan="4" className="text-left">
                      <span className="h7 bold700 px-2">파일관리</span>
                    </td>
                  </tr>
                  <tr>
                    <th>계약서</th>
                    <th>정보보호계약서</th>
                    <th>통장사본</th>
                    <th>주민등록등본</th>
                  </tr>
                  <tr>
                    <td>
                      <div className="inline">
                        {result?.contfile_name &&
                          <span id="contfile_show">
                            파일 등록됨
                            <input
                              className="btn btn-ssm btn-outline-dark mx-1"
                              type="button"
                              onClick={() => contfile_del()}
                              value="삭제"
                            />
                            <input
                              className="btn btn-ssm btn-outline-point"
                              type="button"
                              onClick={() => onDownload(`${Constants.API_URL}/common/download?cd=attachment&type=manager_file&filename=${encodeURIComponent(result?.contfile_name)}`, result?.contfile_name)}
                              value="다운로드"
                            />
                          </span>
                        }
                        <input type="file" className="form-control w-100" id="contfile" style={{marginTop: result?.contfile_name ? 5 : 0}}/>
                      </div>
                    </td>
                    <td>
                      <div className="inline">
                        {result?.infofile_name &&
                          <span id="infofile_show">
                            파일 등록됨
                            <input
                              className="btn btn-ssm btn-outline-dark mx-1"
                              type="button"
                              onClick={() => infofile_del()}
                              value="삭제"
                            />
                            <input
                              className="btn btn-ssm btn-outline-point"
                              type="button"
                              onClick={() => onDownload(`${Constants.API_URL}/common/download?cd=attachment&type=manager_file&filename=${encodeURIComponent(result?.infofile_name)}`, result?.infofile_name)}
                              value="다운로드"
                            />
                          </span>
                        }
                        <input type="file" className="form-control w-100" id="infofile" style={{marginTop: result?.infofile_name ? 5 : 0}}/>
                      </div>
                    </td>
                    <td>
                      <div className="inline">
                        {result?.accountfile_name &&
                          <span id="accountfile_show">
                            파일 등록됨
                            <input
                              className="btn btn-ssm btn-outline-dark mx-1"
                              type="button"
                              onClick={() => accountfile_del()}
                              value="삭제"
                            />
                            <input
                              className="btn btn-ssm btn-outline-point"
                              type="button"
                              onClick={() => onDownload(`${Constants.API_URL}/common/download?cd=attachment&type=manager_file&filename=${encodeURIComponent(result?.accountfile_name)}`, result?.accountfile_name)}
                              value="다운로드"
                            />
                          </span>
                        }
                        <input type="file" className="form-control w-100" id="accountfile" style={{marginTop: result?.accountfile_name ? 5 : 0}}/>
                      </div>
                    </td>
                    <td>
                      <div className="inline">
                        {result?.juminfile_name &&
                          <span id="juminfile_show">
                            파일 등록됨
                            <input
                              className="btn btn-ssm btn-outline-dark mx-1"
                              type="button"
                              onClick={() => juminfile_del()}
                              value="삭제"
                            />
                            <input
                              className="btn btn-ssm btn-outline-point"
                              type="button"
                              onClick={() => onDownload(`${Constants.API_URL}/common/download?cd=attachment&type=manager_file&filename=${encodeURIComponent(result?.juminfile_name)}`, result?.juminfile_name)}
                              value="다운로드"
                            />
                          </span>
                        }
                        <input type="file" className="form-control w-100" id="juminfile" style={{marginTop: result?.juminfile_name ? 5 : 0}}/>
                      </div>
                    </td>
                  </tr>
                </table>
              </form>
            </div>
          </>
        }

        <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
          <div>
            <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => onDownload(`${Constants.API_URL}/employee/document/down?type=A&base_manager_seq=${$('#base_seq').val()}`, '재직증명서.docx')}>재직증명서</button>
            <button type="button" className="btn btn-sm btn-outline-dark mx-1" onClick={() => onDownload(`${Constants.API_URL}/employee/document/down?type=B&base_manager_seq=${$('#base_seq').val()}`, '경력증명서.docx')}>경력증명서</button>
            <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => onDownload(`${Constants.API_URL}/employee/document/down?type=C&base_manager_seq=${$('#base_seq').val()}`, '퇴직증명서.docx')}>퇴직증명서</button>
            <button type="button" className="btn btn-sm btn-outline-dark mx-1" onClick={() => onDownload(`${Constants.API_URL}/employee/document/down?type=D&base_manager_seq=${$('#base_seq').val()}`, '해촉증명서.docx')}>해촉증명서</button>
            <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => onDownload(`${Constants.API_URL}/employee/document/down?type=E&base_manager_seq=${$('#base_seq').val()}`, '계약종료확인서.docx')}>계약종료확인서</button>
            {baseSeq && result?.b_list?.[0] === 'Y' &&
              <button type="button" className="btn btn-sm btn-outline-dark mx-1" onClick={() => setIsMvBlackListReg(true)}>관심스포애너 해제</button>
            }
            {baseSeq && result?.b_list?.[0] !== 'Y' &&
              <button type="button" className="btn btn-sm btn-outline-dark mx-1" onClick={() => setIsMvBlackListReg(true)}>관심스포애너 등록</button>
            }
          </div>
          <div>
            {baseSeq &&
              <>
                <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => go_detail()}>저장</button>
                <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.push("/employee/list")}>목록</button>
              </>
            }
          </div>
        </nav>

        <PostCode isOpen={isPostCodeOpen} onCallback={(addr, zonecode, postcode1, postcode2) => {
          $('#address1').val(addr);
          $('#zonecode').val(zonecode);
          $('#post1').val(postcode1);
          $('#post2').val(postcode2);
        }} onClose={() => setPostCodeOpen(false)}/>

        <input type="hidden" id="base_seq"/>
        <input type="hidden" id="bl_reg_type"/>

        <MvBlackListReg isOpen={isMvBlackListReg} onClose={() => setIsMvBlackListReg(false)} onCallback={() => setRenew(Fn.getRenewTime())} baseSeq={baseSeq}/>

        <Modal
          isOpen={modal1Open}
          style={{
            modal: {
              backgroundColor: 'transparent',
              margin: 0,
              padding: 0,
              alignItems: undefined,
              justifyContent: undefined,
            },
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent'
            }
          }}
          onRequestClose={() => setModal1Open(false)}
          onAfterOpen={() => {
            Fn.centerModal();
            loadModal1();
          }}
          ariaHideApp={false}
        >
          <div className="pop_background" style={{zIndex: 1000000000}}>
            <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 820}}>
              <div className="POP_title">
                <div className="d-flex flex_gap">
                  <h5 className="pt-1"><strong>이동등록</strong></h5>
                </div>
                <p className="closed">
                  <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </p>
              </div>

              <div className="POP_cnt_box w-100">
                <table className="view-rounded sub_table_border text-center">
                  <colgroup>
                    <col width="140px"/>
                    <col width="200px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>이동일</th>
                    <th>이동지점</th>
                    <th>메모</th>
                  </tr>
                  <tr>
                    <td>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="m1_change_date"/>
                    </td>
                    <td>
                      <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="m1_after_branch_code">
                      </select>
                    </td>
                    <td>
                      <input type="text" className="form-control table-inline" id="m1_bigo"/>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="text-center mt-2">
                <button type="button" className="btn btn-sm btn-point mx-1" onClick={() => go_submit1()}>등록</button>
                <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setModal1Open(false)}>닫기</button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={modal2Open}
          style={{
            modal: {
              backgroundColor: 'transparent',
              margin: 0,
              padding: 0,
              alignItems: undefined,
              justifyContent: undefined,
            },
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent'
            }
          }}
          onRequestClose={() => setModal2Open(false)}
          onAfterOpen={() => {
            Fn.centerModal();
            loadModal2();
          }}
          ariaHideApp={false}
        >
          <div className="pop_background" style={{zIndex: 1000000000}}>
            <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 820}}>
              <div className="POP_title">
                <div className="d-flex flex_gap">
                  <h5 className="pt-1"><strong>직급변경</strong></h5>
                </div>
                <p className="closed">
                  <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </p>
              </div>

              <div className="POP_cnt_box w-100">
                <table className="view-rounded sub_table_border text-center">
                  <colgroup>
                    <col width="140px"/>
                    <col width="200px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>변경일</th>
                    <th>변경직급</th>
                    <th>메모</th>
                  </tr>
                  <tr>
                    <td>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="m2_change_date"/>
                    </td>
                    <td>
                      <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="m2_after_new_position">
                      </select>
                    </td>
                    <td>
                      <input type="text" className="form-control table-inline" id="m2_bigo"/>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="text-center mt-2">
                <button type="button" className="btn btn-sm btn-point mx-1" onClick={() => go_submit2()}>등록</button>
                <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setModal2Open(false)}>닫기</button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          isOpen={modal3Open}
          style={{
            modal: {
              backgroundColor: 'transparent',
              margin: 0,
              padding: 0,
              alignItems: undefined,
              justifyContent: undefined,
            },
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent'
            }
          }}
          onRequestClose={() => setModal3Open(false)}
          onAfterOpen={() => {
            Fn.centerModal();
            loadModal3();
          }}
          ariaHideApp={false}
        >
          <div className="pop_background" style={{zIndex: 1000000000}}>
            <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 820}}>
              <div className="POP_title">
                <div className="d-flex flex_gap">
                  <h5 className="pt-1"><strong>직무구분 변경</strong></h5>
                </div>
                <p className="closed">
                  <button type="button" className="btn" onClick={() => setModal3Open(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </p>
              </div>

              <div className="POP_cnt_box w-100">
                <table className="view-rounded sub_table_border text-center">
                  <colgroup>
                    <col width="140px"/>
                    <col width="200px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>변경일</th>
                    <th>변경직무</th>
                    <th>메모</th>
                  </tr>
                  <tr>
                    <td>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="m3_change_date"/>
                    </td>
                    <td>
                      <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="m3_after_position">
                      </select>
                    </td>
                    <td>
                      <input type="text" className="form-control table-inline" id="m3_bigo"/>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="text-center mt-2">
                <button type="button" className="btn btn-sm btn-point mx-1" onClick={() => go_submit3()}>등록</button>
                <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setModal3Open(false)}>닫기</button>
              </div>
            </div>
          </div>
        </Modal>

        <input type="hidden" id="m2_before_new_position"/>
        <input type="hidden" id="m3_before_position"/>

        <input type="hidden" id="contfile_del"/>
        <input type="hidden" id="infofile_del"/>
        <input type="hidden" id="accountfile_del"/>
        <input type="hidden" id="juminfile_del"/>

      </CmnCard>
    );
  }
;

export default EmployeeReg;
