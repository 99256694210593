import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import SelectProduct from "../common/SelectProduct";
import SmsNew from "../common/SmsNew";
import {useHistory} from "react-router-dom";

const MessageSmsList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [atd, setAtd] = useState(0); // TODO
  const [accountYN, setAccountYN] = useState('N');
  const [smsModalOpen, setSmsModalOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    $('#s_marketing_YN').val("Y");
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    //로그인한 사람이 경리팀인지 체크
    if (localStorage.getItem("USER.systemAccountYN") === 'Y' || localStorage.getItem("USER.systemID") === 'mamark' || localStorage.getItem("USER.systemID") === 'futureinfo') {
      setAccountYN("Y");
    } else {
      setAccountYN("N");
    }
    setAccountYN("N");

    let params = "";
    params += `search_YN=Y&`;
    params += `o_date1=${$('#o_date1').val()}&`;
    params += `o_date2=${$('#o_date2').val()}&`;
    params += `date_type=A&`;
    params += `s_date1=${$('#s_date1').val()}&`;
    params += `s_date2=${$('#s_date2').val()}&`;
    params += `e_date1=${$('#e_date1').val()}&`;
    params += `e_date2=${$('#e_date2').val()}&`;
    params += `l_date1=${$('#l_date1').val()}&`;
    params += `l_date2=${$('#l_date2').val()}&`;
    params += `s_mb_name=${$('#s_mb_name').val()}&`;
    params += `s_mb_hp=${$('#s_mb_hp').val()}&`;
    params += `s_member_id=${$('#s_member_id').val()}&`;
    params += `s_locker=${$('#s_locker option:selected').val() || ''}&`;
    params += `s_locker_name=${$('#s_locker_name').val()}&`;
    params += `s_suit=${$('#s_suit option:selected').val() || ''}&`;
    params += `s_now_use=${$('#s_now_use option:selected').val() || ''}&`;
    params += `s_ot_cnt=${$('#s_ot_cnt option:selected').val() || ''}&`;
    params += `s_visit_course=${$('#s_visit_course option:selected').val() || ''}&`;
    params += `s_sex=${$('#s_sex option:selected').val() || ''}&`;
    params += `s_mb_birth=${$('#s_mb_birth').val() || ''}&`;
    params += `s_item_month=${$('#s_item_month option:selected').val() || ''}&`;
    params += `s_item_grade=${$('#s_item_grade option:selected').val() || ''}&`;
    params += `atd_date1=&`;
    params += `atd_date2=&`;
    params += `s_start_hour=00&`;
    params += `s_start_minute=00&`;
    params += `s_end_hour=00&`;
    params += `s_end_minute=00&`;
    params += `s_marketing_YN=${$('#s_marketing_YN option:selected').val() || ''}&`;
    params += `num_per_page=${$('#num_per_page option:selected').val() || ''}`;

    axios2
      .post(`/message/sms/list/ready?page=${_page || currPage}&` + params)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          if ($('#s_visit_course').html() === '') {
            let optionsHTML = '<option value="">가입경로</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.sub_code}">${list_row.sub_code_name}</option>`;
            });
            $('#s_visit_course').html(optionsHTML);
          }
          if ($('#s_item_grade').html() === '') {
            let optionsHTML = '<option value="">상품등급</option>';
            result?.bl?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_item_grade').html(optionsHTML);
          }

          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-1.
  const popUp = (file, names, width, height, sc) => {
    const w = width;
    let h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h},scrollbars=${sc},top=${wint},left=${winl}`);
  };

  const form1 = document.getElementById('form1');

  function chk_sms() {
    var con = 0;
    var editMsgid = "";
    for (let i = 0; i < form1.elements.length; i++) {
      if (form1.elements[i].type === 'checkbox') {
        if (form1.elements[i].checked === true) {
          var nn = form1.elements[i].name;
          var m_chk = nn.substr(0, 3);
          if (m_chk === "chk" && form1.elements[i].value !== "o") {
            con++;
            editMsgid = editMsgid + form1.elements[i].value + "|";
          }
        }
      }
    }
    if (con === 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    popUp('../message/sms.html?type=chks', 'sms.html', '800', '400', 'no');
  }

  //  3-1.
  const s_form = document.getElementById('s_form');

  function clear_form() {
    s_form.s_mb_name.value = "";
    s_form.s_member_id.value = "";
    s_form.s_mb_hp.value = "";
    s_form.o_date1.value = "";
    s_form.o_date2.value = "";
    s_form.s_date1.value = "";
    s_form.s_date2.value = "";
    s_form.e_date1.value = "";
    s_form.e_date2.value = "";
    s_form.s_locker.value = "";
    s_form.s_suit.value = "";
    s_form.s_locker_name.value = "";
    s_form.l_date1.value = "";
    s_form.l_date2.value = "";
    s_form.s_visit_course.value = "";
    s_form.s_sex.value = "";
    s_form.s_ot_cnt.value = "";
    s_form.s_now_use.value = "";
    s_form.s_item_month.value = "";
    s_form.s_item_grade.value = "";

    s_form.s_start_hour.value = "00";
    s_form.s_start_minute.value = "00";
    s_form.s_end_hour.value = "23";
    s_form.s_end_minute.value = "59";
  }

  //  4-1.
  function doSomething() {
    if ($("#atd_date1").val() !== "" && $("#atd_date2").val() === "") {
      alert("출석시작일을 입력해주세요");
      return false;
    }

    if ($("#atd_date1").val() === "" && $("#atd_date2").val() !== "") {
      alert("출석종료일을 입력해주세요");
      return false;
    }
  }

  //  5-1.
  function show_txt() {
    const dateType = s_form.date_type.value;
    const dateShow = document.getElementById("date_show");

    if (dateType === "A") {
      dateShow.innerHTML = "운동종료일";
    } else if (dateType === "B") {
      dateShow.innerHTML = "골프종료일";
    } else if (dateType === "C") {
      dateShow.innerHTML = "스쿼시종료일";
    } else if (dateType === "D") {
      dateShow.innerHTML = "스피닝종료일";
    } else if (dateType === "E") {
      dateShow.innerHTML = "태닝종료일";
    } else if (dateType === "F") {
      dateShow.innerHTML = "필라테스종료일";
    }
  }

  //  6-1.
  function chk_send() {
    var con = 0;
    var editMsgid = "";
    for (var i = 0; i < form1.elements.length; i++) {
      if (form1.elements[i].type === 'checkbox') {
        if (form1.elements[i].checked === true) {
          var nn = form1.elements[i].name;
          var m_chk = nn.substr(0, 3);
          if (m_chk === "chk") {
            con++;
            editMsgid = editMsgid + form1.elements[i].value + "|";
          }
        }
      }
    }
    if (con === 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    popUp('sms.html?b_type=member&s_type=sel&editMsgid=' + editMsgid, 'sms.html', '800', '710', 'no');
  }

  //  7-1.
  function chk_send_new() {
    var con = 0;
    var editMsgid = "";
    for (var i = 0; i < form1.elements.length; i++) {
      if (form1.elements[i].type === 'checkbox') {
        if (form1.elements[i].checked === true) {
          var nn = form1.elements[i].name;
          var m_chk = nn.substr(0, 3);
          if (m_chk === "chk") {
            con++;
            editMsgid = editMsgid + form1.elements[i].value + "|";
          }
        }
      }
    }
    if (con === 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    popUp('sms_new.html?b_type=member&s_type=sel&editMsgid=' + editMsgid, 'sms_new.html', 800, 710, 'no');
  }

  //  8-1.
  function search_send(nums) {
    if (window.confirm("검색된 " + nums + "명 회원에게 SMS를 발송하시겠습니까?")) {
      popUp('sms.html?b_type=member&s_type=all&s_mb_name=&s_mb_hp=&s_date1=&s_date2=&e_date1=&e_date2=&s_locker=&s_suit=&s_locker_name=&l_date1=&l_date2=&s_visit_course=&s_sex=&s_opt1=&s_opt2=&s_opt3=&s_opt4=&s_opt5=&s_opt6=&s_opt7=&s_opt8=&s_opt9=&s_opt10=&s_ot_cnt=&o_date1=&o_date2=&s_now_use=&atd_date1=&atd_date2=&nchk=&num_per_page=20&s_mb_birth=&s_item_month=&s_item_grade=&date_type=A&s_start_hour=00&s_start_minute=00&s_end_hour=00&s_end_minute=00&s_member_id=&s_marketing_YN=Y&search_YN=', 'sms.html', 800, 710, 'no');
    }
  }

  //  9-1.
  function search_send_new(nums) {
    if (window.confirm("검색된 " + nums + "명 회원에게 SMS를 발송하시겠습니까?")) {
      popUp('sms_new.html?b_type=member&s_type=all&s_mb_name=&s_mb_hp=&s_date1=&s_date2=&e_date1=&e_date2=&s_locker=&s_suit=&s_locker_name=&l_date1=&l_date2=&s_visit_course=&s_sex=&s_opt1=&s_opt2=&s_opt3=&s_opt4=&s_opt5=&s_opt6=&s_opt7=&s_opt8=&s_opt9=&s_opt10=&s_ot_cnt=&o_date1=&o_date2=&s_now_use=&atd_date1=&atd_date2=&nchk=&num_per_page=20&s_mb_birth=&s_item_month=&s_item_grade=&date_type=A&s_start_hour=00&s_start_minute=00&s_end_hour=00&s_end_minute=00&s_member_id=&s_marketing_YN=Y&search_YN=', 'sms_new.html', 800, 710, 'no');
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX ">
        <div className="m-3 search_box table_wide_sh2 fn_search_box">
          <div className="p-3">
            <form id="form1">
              <table className="view-rounded sub_view">
                <colgroup>
                  <col width="108px"/>
                  <col width="*"/>
                  <col width="108px"/>
                  <col width="*"/>
                  <col width="108px"/>
                  <col width="*"/>
                  <col width="108px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>가입일</th>
                  <td>
                    <div className="input-group" style={{width: "auto"}}>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date1"/>
                      <p className="mx-2">~</p>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date2"/>
                    </div>
                  </td>
                  <th>이름검색</th>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" id="s_mb_name"/></td>
                  <th>아이디검색</th>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" id="s_member_id"/></td>
                  <th>생년월일검색</th>
                  <td className="text-left"><JDatePicker type="text" className="form-control" aria-describedby="button-addon2" id="s_mb_birth"/></td>
                </tr>
                <tr>
                  <th>연락처검색</th>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" id="s_mb_hp"/></td>
                  <th>성별검색</th>
                  <td className="text-left">
                    <select className="form-select" aria-label="Default select example" style={{width: "32%"}} id="s_sex">
                      <option value="">성별</option>
                      <option value="M">남성</option>
                      <option value="F">여성</option>
                    </select>
                  </td>
                  <th>운동시작일</th>
                  <td>
                    <div className="input-group" style={{width: "auto"}}>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
                      <p className="mx-2">~</p>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
                    </div>
                  </td>
                  <th>운동종료일</th>
                  <td>
                    <div className="input-group" style={{width: "auto"}}>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="e_date1"/>
                      <p className="mx-2">~</p>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="e_date2"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>라커종료일</th>
                  <td>
                    <div className="input-group" style={{width: "auto"}}>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="l_date1"/>
                      <p className="mx-2">~</p>
                      <JDatePicker className="form-control" aria-describedby="button-addon2" id="l_date2"/>
                    </div>
                  </td>
                  <th>라커사용여부</th>
                  <td>
                    <div className="d-flex">
                      <select className="form-select w-50 mr-1" id="s_locker">
                        <option value="">라커</option>
                        <option value="1">사용</option>
                        <option value="x">미사용</option>
                        <option value="2">예약</option>
                        <option value="3">미지급</option>
                      </select>
                      <input type="text" className="form-control table-inline" placeholder="라커번호" id="s_locker_name"/>
                    </div>
                  </td>
                  <th>마케팅 동의</th>
                  <td colSpan={3} className="text-left">
                    <select name="s_marketing_YN" id="s_marketing_YN" className="form-select" style={{maxWidth: "32%"}}>
                      <option value="">전체</option>
                      <option value="Y">동의</option>
                      <option value="N">미동의</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>기타검색</th>
                  <td colSpan="7" className="text-left">
                    <div className="d-flex" style={{width: "auto"}}>
                      <select className="form-select" id="s_suit">
                        <option value="">운동복 사용여부</option>
                        <option value="1">사용</option>
                        <option value="x">미사용</option>
                      </select>
                      <select className="form-select mx-1" id="s_now_use">
                        <option value="">이용구분</option>
                        <option value="Y">이용회원</option>
                        <option value="N">중단회원</option>
                      </select>
                      <select className="form-select mx-1" id="s_ot_cnt">
                        <option value="">OT횟수</option>
                        <option value="x">미실시</option>
                        <option value="1">1회</option>
                        <option value="2">2회</option>
                      </select>
                      <select className="form-select mx-1" id="s_visit_course">
                      </select>
                      <select className="form-select mx-1" id="s_item_month">
                        <option value="">상품개월수</option>
                        <option value="1">1개월</option>
                        <option value="3">3개월</option>
                        <option value="6">6개월</option>
                        <option value="12">12개월</option>
                        <option value="18">18개월</option>
                      </select>
                      <select className="form-select" id="s_item_grade">
                      </select>
                    </div>
                  </td>
                </tr>
              </table>
              <div className="text-right mt-2">
                <select className="form-select SEL_white" aria-label="Default select example" id="num_per_page">
                  <option value="20">20개</option>
                  <option value="50">50개</option>
                  <option value="100">100개</option>
                  <option value="200">200개</option>
                  <option value="300">300개</option>
                </select>
                <button type="button" className="btn btn-sm btn-point px-2 mx-1" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
                <button type="button" className="btn btn-sm btn-dark px-2" onClick={() => {
                  $('#o_date1, #o_date2, #s_date1, #s_date2, #e_date1, #e_date2, #l_date1, #l_date2, #s_mb_birth').val('');
                  $('#form1')[0].reset();
                  $('#s_marketing_YN').val("Y");
                }}>검색 초기화
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="m-3 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="60px"/>
              <col width="50px"/>
              {accountYN === "Y" && <col width="80px"/>}
              <col width="120px"/>
              <col width="90px"/>
              <col width="115px"/>
              <col width="60px"/>
              <col width="60px"/>
              <col width="90px"/>
              <col width="70px"/>
              <col width="80px"/>
              <col width="90px"/>
              <col width="90px"/>
              <col width="130px"/>
              <col width="65px"/>
              {accountYN === "N" && <col width="100px"/>}
              <col width="60px"/>
              <col width="*"/>
              <col width="100px"/>
              {atd > 0 && <>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
              </>}
            </colgroup>
            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
                <label htmlFor="ve"><span></span></label>
              </th>
              {accountYN === "Y" && <th width="120">지점명</th>}
              <th>카드번호</th>
              <th>성명</th>
              <th>연락처</th>
              <th>성별</th>
              <th>연령</th>
              <th>가입일</th>
              <th>개월수</th>
              <th>상품등급</th>
              <th>시작일</th>
              <th>종료일</th>
              <th>라커</th>
              <th>운동복</th>
              {accountYN === "N" && <th>사용서비스</th>}
              <th>OT</th>
              <th>ID</th>
              <th>미수금</th>
              {atd > 0 && <>
                <th>출석</th>
                <th>미출석</th>
                <th>출석률</th>
              </>}
            </tr>
            {rets.map((row, i) => {
              const {
                mb_no,
                mb_id,
                mb_name,
                mb_hp,
                mb_sex,
                mb_birth,
                mb_open_date,
                start_date,
                end_date,
                locker,
                locker_name,
                suit,
                remain_price,
                suit_start_date,
                suit_end_date,
                locker_start_date,
                locker_end_date,
                opt1,
                opt2,
                opt3,
                opt4,
                opt5,
                opt6,
                opt7,
                opt8,
                opt9,
                opt10,
                g_locker,
                ot_cnt,
                buy_month,
                member_grade_name,
                branch_name,
                etc1_start_date,
                etc2_start_date,
                etc3_start_date,
                etc4_start_date,
                etc5_start_date,
                etc1_end_date,
                etc2_end_date,
                etc3_end_date,
                etc4_end_date,
                etc5_end_date,
                member_id,
              } = row;

              const modified_mb_id = mb_id.replace(/"/g, '');
              const modified_mb_name = mb_name.replace(/"/g, '');

              const m_birth_str = mb_birth?.substring(0, 2);

              let birth_show;
              if (m_birth_str === "19") {
                birth_show = 100;
              } else {
                const m_real_b = m_birth_str < "30" ? `20${m_birth_str}` : `19${m_birth_str}`;
                birth_show = new Date().getFullYear() - Number(m_real_b) + 1;
              }

              // TODO : PTODO -> BranchOp1 과 같은 문자열 표시가 아니라 DEFINE된 값으로 치환해야 함
              let opt_use = '';
              if (opt1 == 1 && opt1 == 'Y') opt_use += 'BranchOpt1,';
              if (g_locker == 1) opt_use += 'BranchOpt2,';
              if (opt3 == 1 && opt3 == 'Y') opt_use += 'BranchOpt3,';
              if (opt4 == 1 && opt4 == 'Y') opt_use += 'BranchOpt4,';
              if (opt5 == 1 && opt5 == 'Y') opt_use += 'BranchOpt5,';
              if (opt6 == 1 && opt6 == 'Y') opt_use += 'BranchOpt6,';
              if (opt7 == 1 && opt7 == 'Y') opt_use += 'BranchOpt7,';
              if (opt8 == 1 && opt8 == 'Y') opt_use += 'BranchOpt8,';
              if (opt9 == 1 && opt9 == 'Y') opt_use += 'BranchOpt9,';
              if (opt10 == 1 && opt10 == 'Y') opt_use += 'BranchOpt10,';

              const mb_hp_show = Fn.hpShow(mb_hp, pageInfo.totalArticles);

              const mb_sex_show = mb_sex === 'M' ? '남' : mb_sex === 'F' ? '여' : '';

              let locker_show = '';
              switch (locker) {
                case '1':
                  // TODO
                  const locker_period = `${locker_start_date} ~ ${locker_end_date}`;
                  // locker_show = (<a href="javascript:alert(locker_period)"><font color='blue'>{locker_name}</font></a>);
                  locker_show = (<font color='blue'>{locker_name}</font>);
                  break;
                case '0':
                  locker_show = '미사용';
                  break;
                case '2':
                  locker_show = '예약';
                  break;
                case '3':
                  locker_show = '미지급';
                  break;
                default:
                  locker_show = '미사용';
              }

              let suit_show = '';
              if (suit === '1') {
                // TODO
                const suit_period = `${suit_start_date} ~ ${suit_end_date}`;
                // suit_show = <a href='#1' onclick="alert('{suit_period}')"><font color='blue'>사용</font></a>;
                suit_show = (<font color='blue'>사용</font>);
              } else {
                suit_show = '미사용';
              }

              let abl_day = "", att_day = "";

              if (atd > 0) {
                abl_day = row?.abl_day;
                att_day = row?.att_day;
              }

              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input
                      type="checkbox"
                      name={`chk[${i + 1}]`}
                      value={btoa(row?.mb_no)}
                      id={`achk${i + 1}`}
                      className="checkbox"
                    />
                    <label htmlFor={`achk${i + 1}`}>
                      <span></span>
                    </label>
                  </td>
                  {accountYN === "Y" && (
                    <td>{branch_name}</td>
                  )}
                  <td>
                    <strong>
                      <a onClick={() => history.push({pathname: '/member/view/detail', state: {mbNo: mb_no}})} style={{cursor: "pointer"}}>{mb_id}</a>
                    </strong>
                  </td>
                  <td>
                    <strong>{mb_name}</strong>
                  </td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{mb_sex_show}</td>
                  <td>
                    {birth_show > 0 && birth_show}
                  </td>
                  <td>{mb_open_date?.substr(0, 10)}</td>
                  <td>
                    <div align="right" style={{paddingRight: "3px"}}>
                      {buy_month}개월
                    </div>
                  </td>
                  <td>{member_grade_name}</td>
                  <td>{start_date}</td>
                  {row?.delay_chk_num && row?.date_type === "A" ? (
                    <>
                      {row?.delayChkRow && (
                        <td>
                          <a style={{cursor: "pointer"}} onClick={() => alert(`휴회기간 : ${row?.delay_chk_row?.de_sDate} ~ ${row?.delay_chk_row?.de_eDate}`)}>
                            <font color="red">{end_date}</font>
                          </a>
                        </td>
                      )}
                    </>
                  ) : (
                    <td>{end_date}</td>
                  )}
                  <td>{locker_show}</td>
                  <td>{suit_show}</td>
                  {accountYN === "N" && (
                    <td>
                      <div align="left" style={{paddingLeft: "3px"}}>
                        {opt_use}
                      </div>
                    </td>
                  )}
                  <td>
                    <div align="center">{ot_cnt}</div>
                  </td>
                  <td>
                    <div align="left">{member_id}</div>
                  </td>
                  <td>
                    <div align="right" style={{paddingRight: "5px"}}>
                      {remain_price && Fn.numberWithCommas(remain_price)}
                    </div>
                  </td>
                  {atd > 0 && (
                    <>
                      <td>
                        <div align="center">{row?.att_day}</div>
                      </td>
                      <td>
                        <div align="center">{(row?.abl_day || 0) - (row?.att_day || 0)}</div>
                      </td>
                      <td>
                        <div align="center">
                          {row?.abl_day && `${Math.round(((row?.att_day || 0) / (row?.abl_day || 0)) * 100)}%`}
                        </div>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            <div>
              <button type="button" className="btn btn-sm btn-outline-success px-2" onClick={() => {
                if (pageInfo?.totalArticles > 0) {
                  if (!window.confirm(`검색된 ${pageInfo?.totalArticles}명 회원에게 SMS를 발송하시겠습니까?`)) return;
                } else {
                  if (!window.confirm(`검색된 회원에게 SMS를 발송하시겠습니까?`)) return;
                }

                $('#SMS_editMsgid').val('');
                $('#SMS_b_type').val("member");
                $('#SMS_s_type').val("all");

                setSmsModalOpen(true);
              }}>검색 SMS 발송
              </button>
              <button type="button" className="btn btn-sm btn-outline-success px-2 mx-1"
                      onClick={() => {
                        if ($('[id^=achk]:checked').length === 0) {
                          window.alert('체크된 리스트가 없습니다.');
                          return;
                        }

                        let con = $('[id^=achk]:checked').map(function () {
                          return $(this).val();
                        }).get().join("|");

                        $('#SMS_editMsgid').val(con);
                        $('#SMS_b_type').val("member");
                        $('#SMS_s_type').val("sel");

                        setSmsModalOpen(true);
                      }}
              >선택 SMS 발송
              </button>
            </div>
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>
            {pageInfo?.totalArticles > 0 && (
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('sms_list')}>연락처 확인</button>
              )
            )}
          </div>
        </div>
      </nav>

      {/*팝업*/}

      {/*<div className="pop_background" style={{ zIndex: 1000000000 }}>*/}
      {/*    <div className="pop_box01 p_absolute_mid_pop" style={{ width: '750px' }}>*/}
      {/*        <div className="POP_title">*/}
      {/*            <h5><strong>SMS 전송</strong></h5>*/}
      {/*            <p className="closed">*/}
      {/*                <button type="button" className="btn">*/}
      {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">*/}
      {/*                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />*/}
      {/*                    </svg>*/}
      {/*                </button>*/}
      {/*            </p>*/}
      {/*        </div>*/}
      {/*        <div className="POP_cnt_box w-100">*/}
      {/*            <div className="row g-1">*/}
      {/*                <div className="col-5">*/}
      {/*                    <table className="view-rounded sub_view">*/}
      {/*                        <colgroup>*/}
      {/*                            <col width="30%" />*/}
      {/*                            <col width="*" />*/}
      {/*                        </colgroup>*/}
      {/*                        <tr>*/}
      {/*                            <th colSpan="2">0/90 Byte</th>*/}
      {/*                        </tr>*/}
      {/*                        <tr>*/}
      {/*                            <td colSpan="2">*/}
      {/*                                <textarea className="form-control w-100" rows="8"></textarea>*/}
      {/*                            </td>*/}
      {/*                        </tr>*/}
      {/*                        <tr>*/}
      {/*                            <td className="border-right">발신번호</td>*/}
      {/*                            <td className="text-left">010-****-****</td>*/}
      {/*                        </tr>*/}
      {/*                        <tr>*/}
      {/*                            <td className="border-right">수신번호</td>*/}
      {/*                            <td className="text-left">*/}
      {/*                                <input type="text" className="form-control my-1" placeholder="1." />*/}
      {/*                                <input type="text" className="form-control my-1" placeholder="2." />*/}
      {/*                                <input type="text" className="form-control my-1" placeholder="3." />*/}
      {/*                                <input type="text" className="form-control my-1" placeholder="4." />*/}
      {/*                                <input type="text" className="form-control my-1" placeholder="5." />*/}
      {/*                            </td>*/}
      {/*                        </tr>*/}
      {/*                    </table>*/}
      {/*                </div>*/}
      {/*                <div className="col-7">*/}
      {/*                    <table className="view-rounded sub_view">*/}
      {/*                        <tr>*/}
      {/*                            <th>특수문자</th>*/}
      {/*                        </tr>*/}
      {/*                        <tr>*/}
      {/*                            <td>*/}
      {/*                                <table className="view-rounded sub_table_border text-center">*/}
      {/*                                    <tr className="bold700">*/}
      {/*                                        <td><a href="#">&#37;</a></td>*/}
      {/*                                        <td><a href="#">&#38;</a></td>*/}
      {/*                                        <td><a href="#">&#42;</a></td>*/}
      {/*                                        <td><a href="#">&#64;</a></td>*/}
      {/*                                        <td><a href="#">&#8251;</a></td>*/}
      {/*                                        <td><a href="#">☆</a></td>*/}
      {/*                                        <td><a href="#">★</a></td>*/}
      {/*                                        <td><a href="#">◎</a></td>*/}
      {/*                                        <td><a href="#">⦿</a></td>*/}
      {/*                                        <td><a href="#">●</a></td>*/}
      {/*                                        <td><a href="#">◆</a></td>*/}
      {/*                                    </tr>*/}
      {/*                                    <tr>*/}
      {/*                                        <td><a href="#">◇</a></td>*/}
      {/*                                        <td><a href="#">◼︎</a></td>*/}
      {/*                                        <td><a href="#">◻︎</a></td>*/}
      {/*                                        <td><a href="#">△</a></td>*/}
      {/*                                        <td><a href="#">▲</a></td>*/}
      {/*                                        <td><a href="#">▽</a></td>*/}
      {/*                                        <td><a href="#">▼</a></td>*/}
      {/*                                        <td><a href="#">→</a></td>*/}
      {/*                                        <td><a href="#">←</a></td>*/}
      {/*                                        <td><a href="#">↑</a></td>*/}
      {/*                                        <td><a href="#">↓</a></td>*/}
      {/*                                    </tr>*/}
      {/*                                    <tr>*/}
      {/*                                        <td><a href="#">◁</a></td>*/}
      {/*                                        <td><a href="#">◀︎</a></td>*/}
      {/*                                        <td><a href="#">▶︎</a></td>*/}
      {/*                                        <td><a href="#">▷</a></td>*/}
      {/*                                        <td><a href="#">♤</a></td>*/}
      {/*                                        <td><a href="#">♠︎</a></td>*/}
      {/*                                        <td><a href="#">♧</a></td>*/}
      {/*                                        <td><a href="#">♣︎</a></td>*/}
      {/*                                        <td><a href="#">♡</a></td>*/}
      {/*                                        <td><a href="#">♥︎</a></td>*/}
      {/*                                        <td><a href="#">◉</a></td>*/}
      {/*                                    </tr>*/}
      {/*                                </table>*/}
      {/*                            </td>*/}
      {/*                        </tr>*/}
      {/*                        <tr>*/}
      {/*                            <th>문구대체</th>*/}
      {/*                        </tr>*/}
      {/*                        <tr>*/}
      {/*                            <td>*/}
      {/*                                <div className="d-flex flex-wrap">*/}
      {/*                                    <p className="w-50 p-1">1.[[회원명]] <button type="button" className="btn btn-ssm btn-outline-dark">선택</button></p>*/}
      {/*                                    <p className="w-50 p-1">2.[[종료일]] <button type="button" className="btn btn-ssm btn-outline-dark">선택</button></p>*/}
      {/*                                    <p className="w-50 p-1">3.[[수신거부]] <button type="button" className="btn btn-ssm btn-outline-dark">선택</button></p>*/}
      {/*                                </div>*/}
      {/*                            </td>*/}
      {/*                        </tr>*/}
      {/*                        <tr>*/}
      {/*                            <th>발송시간</th>*/}
      {/*                        </tr>*/}
      {/*                        <tr>*/}
      {/*                            <td className="text-left">*/}
      {/*                                <select className="form-select" aria-label="Default select example" style={{ width: '32%' }}>*/}
      {/*                                    <option selected>즉시 </option>*/}
      {/*                                    <option value="1">예약</option>*/}
      {/*                                </select>*/}
      {/*                            </td>*/}
      {/*                        </tr>*/}
      {/*                    </table>*/}
      {/*                </div>*/}
      {/*            </div>*/}
      {/*        </div>*/}
      {/*        <div className="text-center my-3">*/}
      {/*            <button type="button" className="btn btn-sm btn-dark px-3">미리보기</button>*/}
      {/*            <button type="button" className="btn btn-sm btn-point px-3">SMS 전송</button>*/}
      {/*            <button type="button" className="btn btn-sm btn-ligray px-2">닫기</button>*/}
      {/*        </div>*/}
      {/*    </div>*/}
      {/*</div>*/}

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

      <SmsNew
        isOpen={smsModalOpen}
        onModalClose={() => setSmsModalOpen(false)}
        searchType={"sms_list"}
        callback={() => {
          setSmsModalOpen(false);
        }}
      />
      <input type="hidden" id="SMS_b_type"/>
      <input type="hidden" id="SMS_s_type"/>
      <input type="hidden" id="SMS_editMsgid"/>

    </CmnCard>
  );
};

export default MessageSmsList;
