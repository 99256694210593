import React, {useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../sass/main.scss";
import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import _ from "lodash";

const Paging = (props) => {

  const pageInfo = props.pageInfo;
  return (
    pageInfo?.lastPage > 1 ?
      <ul className="pagination">
        <li className="page-item">
          <a className="page-link" onClick={() => props.setCurrPage(pageInfo.prevBlock)} aria-label="Previous">
            <img src={caretLeftFill} alt="Previous"/>
          </a>
        </li>
        {_.range(pageInfo.startPage, pageInfo.endPage + 1).map(page =>
          <li onClick={() => props.setCurrPage(page)} className={pageInfo.currentPage === page ? 'page-item active' : 'page-item'}><a className="page-link" href="javascript:;">{page}</a></li>
        )}
        <li className="page-item">
          <a className="page-link" onClick={() => props.setCurrPage(pageInfo.nextBlock)} aria-label="Next">
            <img src={caretRightFill} alt="Next"/>
          </a>
        </li>
      </ul>
      :
      <ul className="pagination"></ul>
  );
};

export default Paging;
