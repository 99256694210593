import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import $ from "jquery";
import Modal from "react-modal";
import JDatePicker from "../common/JDatePicker";


const FinanceFeeStep = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [result, setResult] = useState({});
  const [rets1, setRets1] = useState([]);
  const [rets2, setRets2] = useState([]);

  useEffect(() => {
    $('#s_year').val(localStorage.getItem('fee_year') || Fn.getCurrentDate().split('-')[0])
    $('#s_month').val(localStorage.getItem('fee_month') || Fn.getCurrentDate().split('-')[1])
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/finance/fee/step/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          console.log(result);

          localStorage.setItem('fee_year', $('#s_year option:selected').val());
          localStorage.setItem('fee_month', $('#s_month option:selected').val());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal1 = () => {
    axios2
      .post(`/finance/pop/long/detail/ready?manager_seq=${$('#manager_seq').val()}&s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        console.log(result);
        if (code === 200) {
          setRets1(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal2 = () => {
    axios2
      .post(`/finance/pop/refund/detail/ready?manager_seq=${$('#manager_seq').val()}&s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setRets2(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/finance/fee/list/false")}>수수료 내역보고</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/finance/fee/public")}>퍼블릭 부서</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/finance/fee/pt")}>PT 부서</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/finance/fee/add")}>추가/기타</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/finance/fee/incen")}>인센티브</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/finance/fee/incen/pt")}>PT 수당</label>

          <input id="gg" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/finance/fee/gx")}>GX 수당</label>

          <input id="hh" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/finance/fee/consult")}>영업컨설팅비</label>

          <input id="ii" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="ii" onClick={() => history.push("/finance/fee/step")}>직원별 수수료</label>

          <input id="jj" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="jj" onClick={() => history.push("/finance/fee/overtime/list")}>추가근무관리</label>

          <input id="kk" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="kk" onClick={() => history.push("/finance/fee/etc")}>기타수당</label>

          <input id="ll" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ll" onClick={() => history.push("/finance/fee/branch/overprice")}>초과수당설정</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}년</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: "80px"}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3">
        <div className="table_list table_wide">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="70"></col>
              <col width="*"></col>
              <col width="120"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="*"></col>
              <col width="170"></col>
            </colgroup>
            <tr>
              <th className="w_70px">No</th>
              <th>스텝명</th>
              <th>직무구분</th>
              <th>수수료부서</th>
              <th>Pu기본</th>
              <th>Pt기본</th>
              <th>추가수당</th>
              <th>장기인센</th>
              <th>환불차감</th>
              <th>PT커미션</th>
              <th>PT수업료</th>
              <th>PT팀장</th>
              <th>GX수당</th>
              <th>영업컨설팅</th>
              <th>기타수당</th>
              <th>점장인센</th>
              <th>점장초과</th>
              <th>지부장인센</th>
              <th>지부장초과</th>
              <th>총 수수료</th>
              <th>사업소득세</th>
              <th>실제이체액</th>
              <th>주민번호</th>
              <th>은행명</th>
              <th>계좌번호</th>
            </tr>
            {result?.list?.map((row, i) => {
              const base_seq = btoa(row?.seq);

              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{row?.manager_name}</td>
                  <td>{row?.position_name}</td>
                  <td>{row?.pay_part_show}</td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.puplic_basic_price)}</div>
                  </td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.pt_basic_price)}</div>
                  </td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.over_prie)}</div>
                  </td>
                  <td>
                    <div align="right" onClick={() => {
                      $('#manager_seq').val(row?.manager_seq);
                      setModal1Open(true);
                    }}
                         style={{cursor: "pointer"}}
                    >
                      {Fn.numberWithCommas(row?.long_price)}
                    </div>
                  </td>
                  <td>
                    <div align="right" onClick={() => {
                      $('#manager_seq').val(row?.manager_seq);
                      setModal2Open(true);
                    }}
                         style={{cursor: "pointer"}}
                    >
                      {Fn.numberWithCommas(row?.refund_price)}
                    </div>
                  </td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.pt_c_price)}</div>
                  </td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.pt_s_price)}</div>
                  </td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.pt_team_price)}</div>
                  </td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.gx_price)}</div>
                  </td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.consult_price)}</div>
                  </td>
                  <td>
                    <div align="right">
                      <a
                        href="#1"
                        // onClick={() =>
                        //   pop_up(
                        //     `pop_etc_detail.html?manager_seq=${row?.manager_seq}&s_year=${s_year}&s_month=${s_month}`,
                        //     "pop_long_detail",
                        //     "600",
                        //     "550",
                        //     "yes"
                        //   )
                        // }
                      >
                        {Fn.numberWithCommas(row?.etc_price)}
                      </a>
                    </div>
                  </td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.jum_incen)}</div>
                  </td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.jum_over)}</div>
                  </td>

                  {row?.branch_code === "central" ? (
                    <>
                      <td>
                        <div align="right">
                          <a
                            href="#1"
                            // onClick={() =>
                            //   pop_up(
                            //     `pop_jibu_incen.html?manager_seq=${row?.manager_seq}&s_year=${s_year}&s_month=${s_month}`,
                            //     "pop_long_detail",
                            //     "400",
                            //     "550",
                            //     "yes"
                            //   )
                            // }
                          >
                            {Fn.numberWithCommas(row?.jibu_incen)}
                          </a>
                        </div>
                      </td>
                      <td>
                        <div align="right">
                          <a
                            href="#1"
                            // onClick={() =>
                            //   pop_up(
                            //     `pop_jibu_over.html?manager_seq=${row?.manager_seq}&s_year=${s_year}&s_month=${s_month}`,
                            //     "pop_long_detail",
                            //     "400",
                            //     "550",
                            //     "yes"
                            //   )
                            // }
                          >
                            {Fn.numberWithCommas(row?.jibu_over)}
                          </a>
                        </div>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>
                        <div align="right">{Fn.numberWithCommas(row?.jibu_incen)}</div>
                      </td>
                      <td>
                        <div align="right">{Fn.numberWithCommas(row?.jibu_over)}</div>
                      </td>
                    </>
                  )}

                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.fee)}</div>
                  </td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.saup_price)}</div>
                  </td>
                  <td>
                    <div align="right">{Fn.numberWithCommas(row?.real_price)}</div>
                  </td>
                  <td>{row?.jumin_no}</td>
                  <td>{row?.bank_name}</td>
                  <td>{row?.account_no}</td>
                </tr>
              );
            })}
            {result?.list?.length > 0 &&
              <tr className="bgcolor2 bold700">
                <td colSpan={4} className="text-center">합 계</td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_puplic_basic_price)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_pt_basic_price)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_over_prie)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_long_price)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_refund_price)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_pt_c_price)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_pt_s_price)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_pt_team_price)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_gx_price)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_consult_price)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_etc_price)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_jum_incen)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_jum_over)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_jibu_incen)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_jibu_over)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_fee)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_saup_price)}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(result?.tot_real_price)}</div>
                </td>
                <td>{result?.tot_jumin_no}</td>
                <td>{result?.tot_bank_name}</td>
                <td>{result?.tot_account_no}</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <ul className="pagination"></ul>
        <div className="">
          <button type="button" className="btn btn-sm btn-outline-point px-2">엑셀저장</button>
        </div>
      </nav>

      {/*팝업 1*/}

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal1();
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: '1100px'}}>
            <div className="POP_title">
              <h5>
                장기등록 인센티브 상세내역
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="90px"/>
                  <col width="120px"/>
                  <col width="80px"/>
                  <col width="*"/>
                  <col width="80px"/>
                  <col width="100px"/>
                  <col width="100px"/>
                  <col width="100px"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>매출일자</th>
                  <th>회원번호</th>
                  <th>회원명</th>
                  <th>서비스명</th>
                  <th>기간</th>
                  <th>서비스금액</th>
                  <th>인센티브</th>
                  <th>현미수금</th>
                </tr>
                {rets1 && rets1.map((row, index) =>
                  <tr>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{row?.ap_date}</td>
                    <td className="text-center">{row?.mmRow?.mb_id}</td>
                    <td className="text-center">{row?.mmRow?.mb_name}</td>
                    <td className="text-left">{row?.item_name}</td>
                    <td className="text-center">{row?.period}개월</td>
                    <td className="text-right">{Fn.numberWithCommas(row?.ap_amt)}</td>
                    <td className="text-right">{Fn.numberWithCommas(row?.long_incen)}</td>
                    <td className="text-right">{Fn.numberWithCommas((row?.m1Row?.m1_sum || 0) + (row?.m2Row?.m2_sum || 0))}</td>
                  </tr>
                )}
              </table>
            </div>
            <div className="d-flex justify-content-center my-3 pt-2">
              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal1Open(false)}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/*팝업 2*/}

      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal2();
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: '600px'}}>
            <div className="POP_title">
              <h5>
                환불차감 상세내역
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="90px"/>
                  <col width="120px"/>
                  <col width="80px"/>
                  <col width="*"/>
                  <col width="90px"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>환불일</th>
                  <th>회원번호</th>
                  <th>회원명</th>
                  <th>차감금액</th>
                  <th>등록자</th>
                </tr>
                {rets2 && rets2.map((row, index) =>
                  <tr>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{row?.refund_date}</td>
                    <td className="text-center">{row?.incentive_mb_id}</td>
                    <td className="text-center">{row?.incentive_mb_name}</td>
                    <td className="text-right">{Fn.numberWithCommas(row?.incentive_amount)}</td>
                    <td className="text-center">{row?.reg_step_name}</td>
                  </tr>
                )}
              </table>
            </div>
            <div className="d-flex justify-content-center my-3 pt-2">
              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal2Open(false)}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="manager_seq"/>

    </CmnCard>
  );
};

export default FinanceFeeStep;
