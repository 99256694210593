import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import logoL from "../../images/Logo_L.png";
import logoP from "../../images/Logo_P.png";
import logoM from "../../images/Logo_M.png";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios from "axios";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import {useHistory} from "react-router-dom";
import Constants from "../../constants/constants";

const ConfigBrandList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/branchop/brand/list/ready?page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.list);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const delIn = (seq) => {
    if (window.confirm('브랜드를 삭제하시겠습니까?')) {
      const formData = new FormData();
      formData.append("seq", seq);

      axios2.postFormEx('/branchop/brand/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          } else if (data === 'err') {
            alert('삭제오류! 시스템 관리자에게 문의하세요.');
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        {/* 컨텐츠 내용 시작 */}
        <div className="d-flex m-3">
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => history.push("/config/brand/reg")}>추가</button>
          </div>
        </div>

        {/* 테이블 */}
        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_border table_wide_sh2">
            <colgroup>
              <col width="60px"/>
              <col width="*"/>
              <col width="13%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>No</th>
              <th>브랜드</th>
              <th>이미지</th>
              <th>약어</th>
              <th>1일2회</th>
              <th>이용우선순위</th>
              <th>타지정산금액</th>
              <th>비정상운영</th>
              <th>공사/프리세일</th>
              <th>전시여부</th>
              <th>전시순서</th>
              <th>구분</th>
            </tr>
            {rets.map((row, index) => {
              const count = index + 1;
              let showYNTxt;
              if (row.show_YN === 'Y') {
                showYNTxt = '전시';
              } else {
                showYNTxt = <font color="red">미전시</font>;
              }

              return (
                <tr key={row?.seq}>
                  <td align="center">{count}</td>
                  <td>
                    <strong>{row?.brand_name}</strong>
                  </td>
                  <td>
                    <div className="logo_thumb">
                      {row?.brand_img &&
                        <img src={Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=member_pic&renew=" + Fn.getRenewTime() + "&filename=" + encodeURIComponent(row?.brand_img))} width="200" height="70" alt="로고"
                             style={{objectFit: "contain"}}/>
                      }
                    </div>
                  </td>
                  <td>{row?.brand}</td>
                  <td>{row?.in_twice}%</td>
                  <td>{row?.apply_cnt}</td>
                  <td>{Fn.numberWithCommas(row?.cal_price)}</td>
                  <td>{Fn.numberWithCommas(row?.not_oper_price)}</td>
                  <td>{Fn.numberWithCommas(row?.free_sale_price)}</td>
                  <td>
                    <strong>{showYNTxt}</strong>
                  </td>
                  <td>
                    <strong>{row?.show_out}</strong>
                  </td>
                  <td>
                    {/*<a className="btn btn-default btn-xs" onClick={() => parent.location.href = `brand_reg.html?base_seq=${base64_encode(row.seq)}`}>*/}
                    <button type="button" className="btn btn-ssm btn-outline-point"
                            onClick={() => {
                              history.push({
                                pathname: '/config/brand/reg', state: {
                                  baseSeq: btoa(row?.seq)
                                }
                              });
                            }}>
                      수정
                    </button>
                    {/*</a>*/}
                    {/*<a className="btn btn-danger btn-xs" onClick={() => {*/}
                    {/*  if (window.confirm('브랜드를 삭제하시겠습니까?')) {*/}
                    {/*    del_brand(row.seq);*/}
                    {/*  }*/}
                    {/*}}>*/}
                    <button type="button" className="btn btn-ssm btn-outline-dark mx-1" onClick={() => delIn(row?.seq)}>삭제</button>
                    {/*</a>*/}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default ConfigBrandList;
