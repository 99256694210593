import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import JDatePicker from "../common/JDatePicker";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import PostCode from "../common/PostCode";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";

const SalesSupplyReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory()

  const location = useLocation();
  const baseSeq = location?.state?.baseSeq || '';

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    $('#base_seq').val(baseSeq);

    if (baseSeq) {
      axios2
        .post(`/sales/supply/reg/ready?base_seq=${baseSeq}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            console.log(result);

            $('#shop_name').val(result?.row?.shop_name);
            $('#shop_id').val(result?.row?.shop_id);
            $('#shop_in_date').val(result?.row?.shop_in_date);
            $('#shop_state').val(result?.row?.shop_state);
            $('#shop_ceo').val(result?.row?.shop_ceo);
            $('#shop_company_no1').val(result?.row?.shop_company_no?.split("-")?.[0]);
            $('#shop_company_no2').val(result?.row?.shop_company_no?.split("-")?.[1]);
            $('#shop_company_no3').val(result?.row?.shop_company_no?.split("-")?.[2]);
            $('#shop_zonecode').val(result?.row?.shop_zonecode);
            $('#shop_addr1').val(result?.row?.shop_addr1);
            $('#shop_addr2').val(result?.row?.shop_addr2);
            $('#shop_tel1').val(result?.row?.shop_tel?.split("-")?.[0]);
            $('#shop_tel2').val(result?.row?.shop_tel?.split("-")?.[1]);
            $('#shop_tel3').val(result?.row?.shop_tel?.split("-")?.[2]);
            $('#shop_fax1').val(result?.row?.shop_fax?.split("-")?.[0]);
            $('#shop_fax2').val(result?.row?.shop_fax?.split("-")?.[1]);
            $('#shop_fax3').val(result?.row?.shop_fax?.split("-")?.[2]);
            $('#shop_email').val(result?.row?.shop_email);
            $('#shop_manager').val(result?.row?.shop_manager);
            $('#shop_manager_tel1').val(result?.row?.shop_manager_tel?.split("-")?.[0]);
            $('#shop_manager_tel2').val(result?.row?.shop_manager_tel?.split("-")?.[1]);
            $('#shop_manager_tel3').val(result?.row?.shop_manager_tel?.split("-")?.[2]);
            $('#shop_manager2').val(result?.row?.shop_manager2);
            $('#shop_manager2_tel1').val(result?.row?.shop_manager2_tel?.split("-")?.[0]);
            $('#shop_manager2_tel2').val(result?.row?.shop_manager2_tel?.split("-")?.[1]);
            $('#shop_manager2_tel3').val(result?.row?.shop_manager2_tel?.split("-")?.[2]);
            $('#shop_manager3').val(result?.row?.shop_manager3);
            $('#shop_manager3_tel1').val(result?.row?.shop_manager3_tel?.split("-")?.[0]);
            $('#shop_manager3_tel2').val(result?.row?.shop_manager3_tel?.split("-")?.[1]);
            $('#shop_manager3_tel3').val(result?.row?.shop_manager3_tel?.split("-")?.[2]);

            setShopId(result?.row?.shop_id);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const [isPostCodeOpen, setPostCodeOpen] = useState(false);

  //  3-1.
  const [shopId, setShopId] = useState('');
  const [idCheckStatus, setIdCheckStatus] = useState('');

  const idDupChk = async () => {
    if (!$('#shop_id').val()) {
      alert('공급업체 ID를 입력해주세요');
      return;
    }

    try {
      const response = await axios2.post(`/sales/supply/id/chk?shop_id=${$('#shop_id').val()}`);
      const data = response.data.message.trim();

      if (data === 'dup') {
        alert('이미 사용중인 아이디 입니다');
        $('#id_chk').val();
      } else if (data === 'ok') {
        alert('사용 가능한 아이디 입니다');
        // 중복없을시
        $('#id_chk').val('Y');
      }
    } catch (error) {
      alert('데이터 오류! 시스템 관리자에게 문의하세요');
    }
  };

  const go_submit = async () => {
    if ($('#shop_name').val() === '') {
      alert('공급업체명을 입력해주세요');
      return;
    }

    if (!$('#base_seq').val()) {
      if ($('#shop_id').val() === '') {
        alert('공급업체 ID를 입력해주세요');
        return;
      }

      if ($('#id_chk').val() === '') {
        alert('공급업체 ID 중복확인 버튼을 클릭해주세요');
        return;
      }

      if ($('#shop_pwd').val() === '') {
        alert('공급업체 비밀번호를 입력해주세요');
        return;
      }

      if ($('#shop_pwd_re').val() === '') {
        alert('공급업체 비밀번호 확인을 입력해주세요');
        return;
      }

      if ($('#shop_pwd').val() !== $('#shop_pwd_re').val()) {
        alert('비밀번호 확인이 일치하지 않습니다');
        $('#shop_pwd_re').val('');
        return;
      }
    }

    const formData = new FormData();

    formData.append("base_seq", $('#base_seq').val());
    formData.append("shop_name", $('#shop_name').val());
    formData.append("shop_id", $('#shop_id').val());
    formData.append("shop_pwd", $('#shop_pwd').val());
    formData.append("shop_pwd_re", $('#shop_pwd_re').val());
    formData.append("shop_in_date", $('#shop_in_date').val());
    formData.append("shop_state", $('#shop_state option:selected').val());
    formData.append("shop_ceo", $('#shop_ceo').val());
    formData.append("shop_company_no1", $('#shop_company_no1').val());
    formData.append("shop_company_no2", $('#shop_company_no2').val());
    formData.append("shop_company_no3", $('#shop_company_no3').val());
    formData.append("shop_zonecode", $('#shop_zonecode').val());
    formData.append("shop_addr1", $('#shop_addr1').val());
    formData.append("shop_addr2", $('#shop_addr2').val());
    formData.append("shop_tel1", $('#shop_tel1').val());
    formData.append("shop_tel2", $('#shop_tel2').val());
    formData.append("shop_tel3", $('#shop_tel3').val());
    formData.append("shop_fax1", $('#shop_fax1').val());
    formData.append("shop_fax2", $('#shop_fax2').val());
    formData.append("shop_fax3", $('#shop_fax3').val());
    formData.append("shop_email", $('#shop_email').val());
    formData.append("shop_manager", $('#shop_manager').val());
    formData.append("shop_manager_tel1", $('#shop_manager_tel1').val());
    formData.append("shop_manager_tel2", $('#shop_manager_tel2').val());
    formData.append("shop_manager_tel3", $('#shop_manager_tel3').val());
    formData.append("shop_manager2", $('#shop_manager2').val());
    formData.append("shop_manager2_tel1", $('#shop_manager2_tel1').val());
    formData.append("shop_manager2_tel2", $('#shop_manager2_tel2').val());
    formData.append("shop_manager2_tel3", $('#shop_manager2_tel3').val());
    formData.append("shop_manager3", $('#shop_manager3').val());
    formData.append("shop_manager3_tel1", $('#shop_manager3_tel1').val());
    formData.append("shop_manager3_tel2", $('#shop_manager3_tel2').val());
    formData.append("shop_manager3_tel3", $('#shop_manager3_tel3').val());

    try {
      axios2.postFormEx('/sales/supply/reg', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data === 'insert_ok') {
            alert('등록되었습니다');
            history.push('/sales/supply/list');
          } else if (data === 'dup_id') {
            alert('동일한 공급업체 ID가 존재합니다');
          } else if (data === 'insert_err') {
            alert('등록오류! 관리자에게 문의하세요');
          } else if (data === 'update_ok') {
            alert('수정되었습니다');
            history.push('/sales/supply/list');
          } else if (data === 'update_err') {
            alert('수정오류! 관리자에게 문의하세요');
          } else {
            alert('데이터오류! 관리자에게 문의하세요');
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    } catch (error) {
      if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="130px"/>
            <col width="*"/>
            <col width="130px"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>공급업체명 <span style={{color: "deeppink"}}>*</span></th>
            <td><input type="text" className="form-control table-inline" style={{maxWidth: "32%"}} id="shop_name"/></td>
            <th>공급업체 ID <span style={{color: "deeppink"}}>*</span></th>
            <td className="text-left">
              {shopId ?
                <>
                  <strong>{shopId}</strong>
                  <input type="hidden" id="shop_id"/>
                </>
                :
                <div className="input-group" style={{width: "auto"}}>
                  <input type="text" className="form-control table-inline cls-type-nokr2" style={{maxWidth: "32%"}} id="shop_id"/>
                  <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => idDupChk()}>중복확인</button>
                </div>
              }
            </td>
          </tr>
          <tr>
            <th>비밀번호 <span style={{color: "deeppink"}}>*</span></th>
            <td><input type="password" className="form-control table-inline" style={{maxWidth: "32%"}} id="shop_pwd"/></td>
            <th>비밀번호 확인 <span style={{color: "deeppink"}}>*</span></th>
            <td><input type="password" className="form-control table-inline" style={{maxWidth: "32%"}} id="shop_pwd_re"/></td>
          </tr>
          <tr>
            <th>거래시작일</th>
            <td className="text-left"><JDatePicker className="form-control" aria-describedby="button-addon2" id="shop_in_date"/></td>
            <th>상태</th>
            <td className="text-left">
              <select className="form-select" style={{maxWidth: "32%"}} id="shop_state">
                <option value="Y">정상</option>
                <option value="N">종료</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>대표자</th>
            <td><input type="text" className="form-control table-inline" style={{maxWidth: "32%"}} id="shop_ceo"/></td>
            <th>사업자등록번호</th>
            <td className="text-left">
              <div className="input-group" style={{width: "auto"}}>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} maxLength={3} id="shop_company_no1"/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} maxLength={2} id="shop_company_no2"/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} maxLength={5} id="shop_company_no3"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>사업장주소</th>
            <td colSpan="3">
              <div className="input-group" style={{width: "auto"}}>
                <input type="text" className="form-control table-inline" placeholder="우편번호" style={{maxWidth: "90px"}} id="shop_zonecode"/>
                <button type="button" className="btn btn-ssm btn-outline-point px-3" onClick={() => setPostCodeOpen(true)}>주소검색</button>
                <input type="text" className="form-control table-inline mx-1" placeholder="기본주소" style={{maxWidth: "35%"}} id="shop_addr1" maxLength={100}/>
                <input type="text" className="form-control table-inline" style={{maxWidth: '35%'}} placeholder="상세주소" id="shop_addr2" maxLength={100}/>
              </div>
            </td>
          </tr>
          <tr>
            <th>대표전화</th>
            <td>
              <div className="input-group" style={{width: "auto"}}>
                <input type="text" className="form-control table-inline cls-type-number" style={{maxWidth: "80px"}} maxLength={4} id="shop_tel1"/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} maxLength={4} id="shop_tel2"/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} maxLength={4} id="shop_tel3"/>
              </div>
            </td>
            <th>팩스</th>
            <td>
              <div className="input-group" style={{width: "auto"}}>
                <input type="text" className="form-control table-inline cls-type-number" style={{maxWidth: "80px"}} maxLength={4} id="shop_fax1"/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} maxLength={4} id="shop_fax2"/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} maxLength={4} id="shop_fax3"/>
              </div>
            </td>
          </tr>
          <tr>
            <th>이메일</th>
            <td className="text-left" colSpan={3}>
              <input type="text" className="form-control table-inline cls-type-email" aria-describedby="button-addon2" style={{maxWidth: "300px"}} maxLength={60} id="shop_email"/>
            </td>
          </tr>
          <tr>
            <th>담당자</th>
            <td><input type="text" className="form-control table-inline" style={{maxWidth: "32%"}} id="shop_manager"/></td>
            <th>담당자연락처</th>
            <td>
              <div className="input-group" style={{width: "auto"}}>
                <input type="text" className="form-control table-inline cls-type-number" style={{maxWidth: "80px"}} id='shop_manager_tel1'/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} id='shop_manager_tel2'/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} id='shop_manager_tel3'/>
                <p className="p-1">(발주등록시 SMS발송)</p>
              </div>
            </td>
          </tr>
          <tr>
            <th>담당자</th>
            <td><input type="text" className="form-control table-inline" style={{maxWidth: "32%"}} id="shop_manager2"/></td>
            <th>담당자연락처</th>
            <td>
              <div className="input-group" style={{width: "auto"}}>
                <input type="text" className="form-control table-inline cls-type-number" style={{maxWidth: "80px"}} id='shop_manager2_tel1'/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} id='shop_manager2_tel2'/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} id='shop_manager2_tel3'/>
                <p className="p-1">(발주등록시 SMS발송)</p>
              </div>
            </td>
          </tr>
          <tr>
            <th>담당자</th>
            <td><input type="text" className="form-control table-inline" style={{maxWidth: "32%"}} id="shop_manager3"/></td>
            <th>담당자연락처</th>
            <td>
              <div className="input-group" style={{width: "auto"}}>
                <input type="text" className="form-control table-inline cls-type-number" style={{maxWidth: "80px"}} id='shop_manager3_tel1'/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} id='shop_manager3_tel2'/>
                <p className="mx-2">-</p>
                <input type="text" className="form-control table-inline cls-type-number" aria-describedby="button-addon2" style={{maxWidth: "80px"}} id='shop_manager3_tel3'/>
                <p className="p-1">(발주등록시 SMS발송)</p>
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div className="my-3 text-center">
        <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => go_submit()}>저장</button>
        <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.goBack()}>목록</button>
      </div>

      <PostCode isOpen={isPostCodeOpen} onCallback={(addr, zonecode) => {
        $('#shop_zonecode').val(zonecode);
        $('#shop_addr1').val(addr);
      }} onClose={() => setPostCodeOpen(false)}/>

      <input type="hidden" id="base_seq"/>

    </CmnCard>
  );
};

export default SalesSupplyReg;
