import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import {useHistory} from "react-router-dom";

const ConfigUserList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/config/user/list/ready?my_sort=${$('#my_sort option:selected').val() || ''}&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_user_auth=${$('#s_user_auth option:selected').val() || ''}&s_menu_auth=${$('#s_menu_auth option:selected').val() || ''}&s_use_yn=${$('#s_use_yn option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">지점선택</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          if ($('#s_menu_auth').html() === '') {
            let optionsHTML = '<option value="">메뉴권한선택</option>';
            result?.list2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.auth_name}</option>`;
            });
            $('#s_menu_auth').html(optionsHTML);
          }

          setRets(result?.userList);
          setPageInfo(pageInfo);

          result?.userList?.map((row, i) => {
            if (row?.use_YN === 'Y') $(`#bchk${i + 1}`).prop("checked", true);
            else $(`#bchk${i + 1}`).prop("checked", false);

            if (row?.sec_use_YN === 'Y') $(`#cchk${i + 1}`).prop("checked", true);
            else $(`#cchk${i + 1}`).prop("checked", false);
          });
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const del_user = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 계정을 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/config/user/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  function change_login(base_seq, checked) {
    axios2
      .post(`/config/change/user/login?base_seq=${base_seq}&value=${checked ? "Y" : "N"}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function change_login_sec(base_seq, checked) {
    axios2
      .post(`/config/change/user/login/sec?base_seq=${base_seq}&value=${checked ? "Y" : "N"}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" id="my_sort">
            <option value="seq desc">등록역순</option>
            <option value="branch_name asc">지점명순</option>
            <option value="user_name asc">성명순</option>
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" id="s_branch_code">
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" id="s_user_auth">
            <option value="">::사용메뉴선택</option>
            <option value="9">전체관리</option>
            <option value="8">본사스텝</option>
            <option value="7">지점관리</option>
            <option value="6">지점스텝</option>
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" id="s_menu_auth">
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" id="s_use_yn">
            <option value="">::로그인</option>
            <option value="Y">가능</option>
            <option value="N">불가</option>
          </select>
        </div>
        <div className="pr-1">
          <input
            type="text"
            className="form-control"
            aria-describedby="button-addon2"
            placeholder="검색어 입력"
            id="key"
          />
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>
            검색
          </button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="70px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width=""/>
            <col width="*"/>
            <col width="100px"/>
            <col width="100px"/>
          </colgroup>

          <tbody>
          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            <th>아이디</th>
            <th>성명</th>
            <th>사용메뉴</th>
            <th>메뉴권한</th>
            <th>소속(지점)</th>
            <th>지부장</th>
            <th>로그인</th>
            <th>sec로그인</th>
          </tr>
          </tbody>

          {rets.map((item, i) => {
            const seq = item.seq;
            const user_id = item.user_id;
            const user_name = item.user_name;
            const user_auth = item.user_auth;
            const menu_auth_name = item.menu_auth_name;
            const branch_code_name = item.branch_code_name;
            const manage_YN = item.manage_YN;
            const use_YN = item.use_YN;
            const sec_use_YN = item.sec_use_YN;
            const count = i + 1;

            let user_auth_txt = '';
            if (user_auth === 9) {
              user_auth_txt = '본사관리';
            } else if (user_auth === 8) {
              user_auth_txt = '본사스텝';
            } else if (user_auth === 7) {
              user_auth_txt = '지점관리';
            } else if (user_auth === 6) {
              user_auth_txt = '지점스텝';
            } else if (user_auth === 5) {
              user_auth_txt = '지점투자';
            }

            const manage_YN_txt = manage_YN === 'Y' ? 'Y' : '';

            const u_chk = use_YN === 'Y' ? 'checked' : '';
            const sec_u_chk = sec_use_YN === 'Y' ? 'checked' : '';

            const base_seq = btoa(seq);

            return (
              <tr key={i}>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  <input
                    type="checkbox"
                    value={btoa(item?.seq)}
                    className="checkbox"
                    id={`achk${i + 1}`}
                  />
                </td>
                <td>
                  <div align="left" style={{paddingLeft: '5px'}}>
                    <a style={{cursor: "pointer"}}
                       onClick={() => {
                         history.push({
                           pathname: '/config/user/reg', state: {
                             baseSeq: btoa(item?.seq)
                           }
                         });
                       }}>
                      <strong>{user_id}</strong>
                    </a>
                  </div>
                </td>
                <td>
                  <div align="left" style={{paddingLeft: '5px'}}>
                    <a style={{cursor: "pointer"}}
                       onClick={() => {
                         history.push({
                           pathname: '/config/user/reg', state: {
                             baseSeq: btoa(item?.seq)
                           }
                         });
                       }}>
                      <strong>{user_name}</strong>
                    </a>
                  </div>
                </td>
                <td>{user_auth_txt}</td>
                <td>
                  <div align="left" style={{paddingLeft: '5px'}}>
                    {menu_auth_name}
                  </div>
                </td>
                <td>
                  <div align="left" style={{paddingLeft: '5px'}}>
                    {branch_code_name}
                  </div>
                </td>
                <td>{manage_YN_txt}</td>
                <td>
                  <input
                    type="checkbox"
                    name="use_YN"
                    value="Y"
                    className="checkbox"
                    id={`bchk${i + 1}`}
                    onClick={(e) => change_login(base_seq, e.target.checked)}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    name="sec_use_YN"
                    value="Y"
                    className="checkbox"
                    id={`cchk${i + 1}`}
                    onClick={(e) => change_login_sec(base_seq, e.target.checked)}
                  />
                </td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={10}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div>
            <button type="button" className="btn btn-sm btn-outline-point px-2" onClick={() => history.push("/config/user/reg")}>
              추가
            </button>
            <button type="button" className="btn btn-sm btn-outline-dark px-2 mx-1" onClick={() => del_user()}>
              삭제
            </button>
          </div>
        </div>
      </nav>
    </CmnCard>
  );
};

export default ConfigUserList;
