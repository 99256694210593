import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";

const AnalysisMonthlyTm = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [srchRenew, setSrchRenew] = useState(0);
  const [row, setRow] = useState({});

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split('-')[0])
    $('#s_month').val(Fn.getCurrentDate().split('-')[1])
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/analysis/monthly/analysis/tm/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRow(result?.row);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/analysis/monthly/member")}>월간등록현황</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/analysis/monthly/finance/new")}>월계표 비교분석</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/analysis/monthly/coupon")}>쿠폰발송내역</label>

          <input id="dd" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/analysis/monthly/tm")}>TM 현황보고</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/analysis/monthly/region")}>지역분류</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/analysis/monthly/public")}>경쟁업체(퍼블릭)</label>

          <input id="gg" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/analysis/monthly/pt")}>경쟁업체(PT)</label>

          <input id="hh" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/analysis/monthly/analysis/pt")}>PT월간등록현황</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}년</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: "80px"}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-1 row row-cols-2 g-2">
        <div className="col">
          <table className="view-rounded sub_view text-left">
            <colgroup>
              <col width="200px"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>TM대상자</th>
              <td>{row?.target1}</td>
            </tr>
            <tr>
              <th>진행기간</th>
              <td>{row?.period1}</td>
            </tr>
            <tr>
              <th>연결된회원</th>
              <td>{row?.connect1}</td>
            </tr>
            <tr>
              <th>부재중회원</th>
              <td>{row?.no_connect1}</td>
            </tr>
            <tr>
              <th>없는번호</th>
              <td>{row?.no_number1}</td>
            </tr>
            <tr>
              <th>재등록 의사가 있는 회원</th>
              <td>{row?.yes_man1}</td>
            </tr>
            <tr>
              <th>재등록 고민중인 회원</th>
              <td>{row?.think_man1}</td>
            </tr>
            <tr style={{height: '160px'}}>
              <th>재등록 의사가 없는 회원</th>
              <td>{row?.no_man1}</td>
            </tr>
            <tr>
              <th>회수량</th>
              <td>{row?.return_num1}</td>
            </tr>
            <tr>
              <th>회수율</th>
              <td>{row?.return_rate1}</td>
            </tr>
          </table>
        </div>

        <div className="col">
          <table className="view-rounded sub_view text-left">
            <colgroup>
              <col width="200px"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>TM대상자</th>
              <td>{row?.target2}</td>
            </tr>
            <tr>
              <th>진행기간</th>
              <td>{row?.period2}</td>
            </tr>
            <tr>
              <th>연결된회원</th>
              <td>{row?.connect2}</td>
            </tr>
            <tr>
              <th>부재중회원</th>
              <td>{row?.no_connect2}</td>
            </tr>
            <tr>
              <th>없는번호</th>
              <td>{row?.no_number2}</td>
            </tr>
            <tr>
              <th>재등록 의사가 있는 회원</th>
              <td>{row?.yes_man2}</td>
            </tr>
            <tr>
              <th>재등록 고민중인 회원</th>
              <td>{row?.think_man2}</td>
            </tr>
            <tr style={{height: '160px'}}>
              <th>재등록 의사가 없는 회원</th>
              <td>{row?.no_man2}</td>
            </tr>
            <tr>
              <th>회수량</th>
              <td>{row?.return_num2}</td>
            </tr>
            <tr>
              <th>회수율</th>
              <td>{row?.return_rate2}</td>
            </tr>
          </table>
        </div>
      </div>

      <div className="m-1 my-4 row row-cols-2 g-2">
        <div className="col">
          <table className="view-rounded sub_view text-left">
            <colgroup>
              <col width="200px"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>TM대상자</th>
              <td>{row?.target3}</td>
            </tr>
            <tr>
              <th>진행기간</th>
              <td>{row?.period3}</td>
            </tr>
            <tr>
              <th>연결된회원</th>
              <td>{row?.connect3}</td>
            </tr>
            <tr>
              <th>부재중회원</th>
              <td>{row?.no_connect3}</td>
            </tr>
            <tr>
              <th>없는번호</th>
              <td>{row?.no_number3}</td>
            </tr>
            <tr>
              <th>재등록 의사가 있는 회원</th>
              <td>{row?.yes_man3}</td>
            </tr>
            <tr>
              <th>재등록 고민중인 회원</th>
              <td>{row?.think_man3}</td>
            </tr>
            <tr style={{height: '160px'}}>
              <th>재등록 의사가 없는 회원</th>
              <td>{row?.no_man3}</td>
            </tr>
            <tr>
              <th>회수량</th>
              <td>{row?.return_num3}</td>
            </tr>
            <tr>
              <th>회수율</th>
              <td>{row?.return_rate3}</td>
            </tr>
          </table>
        </div>

        <div className="col">
          <table className="view-rounded sub_view text-left">
            <colgroup>
              <col width="200px"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>TM대상자</th>
              <td>{row?.target4}</td>
            </tr>
            <tr>
              <th>진행기간</th>
              <td>{row?.period4}</td>
            </tr>
            <tr>
              <th>연결된회원</th>
              <td>{row?.connect4}</td>
            </tr>
            <tr>
              <th>부재중회원</th>
              <td>{row?.no_connect4}</td>
            </tr>
            <tr>
              <th>없는번호</th>
              <td>{row?.no_number4}</td>
            </tr>
            <tr>
              <th>재등록 의사가 있는 회원</th>
              <td>{row?.yes_man4}</td>
            </tr>
            <tr>
              <th>재등록 고민중인 회원</th>
              <td>{row?.think_man4}</td>
            </tr>
            <tr style={{height: '160px'}}>
              <th>재등록 의사가 없는 회원</th>
              <td>{row?.no_man4}</td>
            </tr>
            <tr>
              <th>회수량</th>
              <td>{row?.return_num4}</td>
            </tr>
            <tr>
              <th>회수율</th>
              <td>{row?.return_rate4}</td>
            </tr>
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default AnalysisMonthlyTm;
