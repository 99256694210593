import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";


const LockerReserve = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/locker/locker/reserve/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_hp=${$('#s_mb_hp').val()}&s_state=${$('#s_state option:selected').val() || ''}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.list, pageInfo, "ret?.mb_hp");

          setRets(result?.list);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  3-1.
  const take_end = () => {
    if (!$('#auth_code').val()) {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
      return;
    }

    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 예약내역을 발급완료처리 하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);
      formData.append("auth_code", $('#auth_code').val());

      axios2.postFormEx('/locker/reserve/take', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('발급처리되었습니다.');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  //  4-1.
  const del_data = () => {
    if (!$('#auth_code').val()) {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
      return;
    }

    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 예약내역을 삭제 하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);
      formData.append("auth_code", $('#auth_code').val());

      axios2.postFormEx('/locker/reserve/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('삭제되었습니다.');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 d-flex fn_search_box" style={{maxWidth: "935px"}}>
        <div className="input-group pr-1" style={{width: "auto"}}>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
          <p className="mx-2">~</p>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
        </div>

        <div className="pr-1">
          <input type="text" className="form-control width_shorts" placeholder="회원명" id="s_mb_name"/>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" placeholder="연락처" id="s_mb_hp"/>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_state">
            <option value="">처리상태</option>
            <option value="N">예약</option>
            <option value="Y">발급완료</option>
          </select>
        </div>

        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list" style={{maxWidth: "935px"}}>
        <table className="view-rounded sub_table_border auto">
          <colgroup>
            <col width="60px"/>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            <th>예약일</th>
            <th>회원명</th>
            <th>카드번호</th>
            <th>핸드폰</th>
            <th>처리상태</th>
          </tr>
          {rets && rets.map((ret, i) => {

            const statusTxt = ret?.state === 'N' ? <span style={{color: 'blue'}}>예약</span> : <span style={{color: 'red'}}>발급완료</span>;

            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  {ret?.state === "N" && (
                    <input
                      type="checkbox"
                      name="chk"
                      value={btoa(ret?.seq)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  )}
                </td>
                <td>{ret?.basic_date}</td>
                <td>{ret?.mb_name}</td>
                <td>{ret?.mb_id}</td>
                <td>
                  <div id={`default_show${i + 1}`}>***-****-****</div>
                </td>
                <td>{statusTxt}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={7}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap" style={{maxWidth: "935px"}}>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>

          <div>
            <button type="button" className="btn btn-sm btn-outline-success" onClick={() => take_end()}>
              발급완료처리
            </button>
            <button type="button" className="btn btn-sm btn-outline-success mx-1" onClick={() => del_data()}>
              삭제
            </button>
            {pageInfo?.totalArticles > 0 && (
              Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)
            ) && (
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('locker_reserve')}>연락처 확인</button>
            )}
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default LockerReserve;
