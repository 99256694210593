import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import _ from "lodash";
import {useHistory} from "react-router-dom";


const LockerConfig = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();
  const [adm, setAdm] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/locker/locker/config/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setAdm(result?.adm);
          {
            Array.from({length: 20}, (_, i) => {
              $(`#ad_loc${i + 1}`).val(result?.adm?.[`ad_loc${i + 1}`]);
              $(`#ad_loc${i + 1}_y`).val(result?.adm?.[`ad_loc${i + 1}_y`]);
              $(`#ad_loc${i + 1}_x`).val(result?.adm?.[`ad_loc${i + 1}_x`]);
              $(`#ad_loc${i + 1}_s`).val(result?.adm?.[`ad_loc${i + 1}_s`]);
              $(`#ad_loc${i + 1}_e`).val(result?.adm?.[`ad_loc${i + 1}_e`]);
            })
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {

    const obj = {};

    Array.from({length: 20}, (_, i) => {
      obj[`ad_loc${i + 1}`] = $(`#ad_loc${i + 1}`).val();
      obj[`ad_loc${i + 1}_y`] = $(`#ad_loc${i + 1}_y`).val();
      obj[`ad_loc${i + 1}_x`] = $(`#ad_loc${i + 1}_x`).val();
      obj[`ad_loc${i + 1}_s`] = $(`#ad_loc${i + 1}_s`).val();
      obj[`ad_loc${i + 1}_e`] = $(`#ad_loc${i + 1}_e`).val();
    });

    axios2
      .postEx(`/locker/locker/config`, obj)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else {
          alert("저장하였습니다.");
          history.push({pathname: '/locker/number', state: {init: "Y"}});
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 pb-2" style={{maxWidth: "935px"}}>
        <table className="view-rounded sub_table_border auto">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>번호</th>
            <th>그룹명</th>
            <th>층수</th>
            <th>갯수</th>
            <th>시작번호</th>
            <th>끝번호</th>
          </tr>

          {Array.from({length: 20}, (_, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td><input type="text" className="form-control table-inline" id={`ad_loc${i + 1}`} onFocus={e => e.target.select()}/></td>
              <td><input type="text" className="form-control table-inline" id={`ad_loc${i + 1}_y`} onFocus={e => e.target.select()}/></td>
              <td><input type="text" className="form-control table-inline" id={`ad_loc${i + 1}_x`} onFocus={e => e.target.select()}/></td>
              <td><input type="text" className="form-control table-inline" id={`ad_loc${i + 1}_s`} onFocus={e => e.target.select()}/></td>
              <td><input type="text" className="form-control table-inline" id={`ad_loc${i + 1}_e`} onFocus={e => e.target.select()}/></td>
            </tr>
          ))}
        </table>
        <div className="m-4 text-center">
          <button type="button" className="btn btn-sm btn-point px-4" onClick={() => goSubmit()}>
            저장
          </button>
        </div>
      </div>
    </CmnCard>
  );
};

export default LockerConfig;
