import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios2 from "../../helpers/APIHelper";
import $ from 'jquery';
import Paging from "../common/Paging";

import axios from "axios";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";
import MvInputInsert from "../member/MvInputInsert";
import SlCardReturn from "./SlCardReturn";
import SlCashReturn from "./SlCashReturn";
import SlCashCancel from "./SlCashCancel";
import SlCardCancel from "./SlCardCancel";
import Constants from "../../constants/constants";


const SalesList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [result, setResult] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});
  const [slCashCancel, setSlCashCancel] = useState(false);
  const [slCardCancel, setSlCardCancel] = useState(false);
  const [slCashReturn, setSlCashReturn] = useState(false);
  const [slCardReturn, setSlCardReturn] = useState(false);

  const [rt, setRt] = useState({});

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#s_date1').val(`${year}-${month}-01`);
    $('#s_date2').val(today);
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/sales/sales/list/ready?page=${_page || currPage}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_p_name=${encodeURIComponent($('#s_p_name').val())}&s_pay_method=${$('#s_pay_method option:selected').val() || ''}&s_permit_no=${$('#s_permit_no').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          console.log(result?.result);

          if ($('#s_pay_method').html() === '') {
            let optionsHTML = '<option value="">결제방식</option><option value="xx">현금</option>';
            result?.pc?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.j_no}">${list_row.j_jukname}</option>`;
            });
            $('#s_pay_method').html(optionsHTML);
          }

          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        alert('데이터오류! 시스템 관리자에게 문의하세요.');
      });
  }

  const popUp = (file, names, width, height, sc) => {
    const w = width;
    const h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h}, scrollbars=${sc}, top=${wint}, left=${winl}`);
  };

  window.pop_up = (file, names, width, height, sc) => {
    const w = width;
    const h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;

    if (file.startsWith("print_pay_sales_re.html")) {
      window.open("http://cdc-smms.spoany.co.kr/sales/" + file, names, `width=600, height=700, scrollbars=no, top=${($(window).height() / 2 - 350)}, left=${($(window).width() / 2 - 300)}`);
    } else
      window.open(file, names, `width=${w}, height=${h}, scrollbars=${sc}, top=${wint}, left=${winl}`);
  }

  const dailyMagam = () => {
    popUp('daily_magam.html', 'daily_magam', '900', '500', 'yes');
  };

  const loadModal = () => {
    axios2
      .post(`/sales/daily/magam/ready`)
      .then((response) => {
        const {code, message, result: data, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(data);

          let cash_price = 0;
          let card_price = 0;
          let online_price = 0;

          data && data?.map((ret, i) => {
            if (ret?.pay_method === "1111") {
              cash_price += ret?.price;
            } else if (ret?.pay_method === "1112") {
              if (ret?.ck_summary_no === "180") {
                card_price += ret?.price;
              } else if (ret?.ck_summary_no === "242") {
                card_price += ret?.price;
              } else {
                online_price += ret?.price;
              }
            } else {
              card_price += ret?.price;
            }
          });

          $('#cash_price').html(Fn.numberWithCommas(cash_price));
          $('#card_price').html(Fn.numberWithCommas(card_price));
          $('#online_price').html(Fn.numberWithCommas(online_price));
          $('#total_price').html(Fn.numberWithCommas(cash_price + card_price + online_price));
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function input_insert() {
    if (window.confirm("일일정산을 진행하시겠습니까?")) {
      axios2
        .postEx('/sales/daily/magam/end', {
          magam_YN: "Y",
          ap_step: "",
        })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data == "err") {
            alert("오류가 발생하였습니다");
          } else {
            alert("일일정산 처리되었습니다");
            setRenew(Fn.getRenewTime());
            setModalOpen(false);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  window.cashCancel = (sales_code, sales_date) => {
    if (window.confirm('해당 판매내역을 취소하시겠습니까?')) {
      setRt({
        wonSalesCode: sales_code,
        wonSalesDate: sales_date
      });
      setSlCashCancel(true);
    }
  };

  window.cashReturn = (sales_code, sales_date) => {
    if (window.confirm('해당 판매내역을 반품하시겠습니까?')) {
      setRt({
        wonSalesCode: sales_code,
        wonSalesDate: sales_date
      });
      setSlCashReturn(true);
    }
  };

  window.cardCancel = (sales_code, sales_date) => {
    if (window.confirm('해당 판매내역을 취소하시겠습니까?')) {
      setRt({
        wonSalesCode: sales_code,
        wonSalesDate: sales_date
      });
      setSlCardCancel(true);
    }
  };

  window.cardReturn = (sales_code, sales_date) => {
    if (window.confirm('해당 판매내역을 반품하시겠습니까?')) {
      setRt({
        wonSalesCode: sales_code,
        wonSalesDate: sales_date
      });
      setSlCardReturn(true);
    }
  };

  const Excel_download = () => {
    const url = `/xls/sales/list?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_p_name=${encodeURIComponent($('#s_p_name').val())}&s_pay_method=${$('#s_pay_method option:selected').val() || ''}&s_permit_no=${$('#s_permit_no').val()}`;
    Fn.download(Constants.API_URL + url);
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="input-group pr-1" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="상품명 검색" id="s_p_name"/>
          </div>

          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_pay_method">
            </select>
          </div>

          <div className="pr-1">
            <input type="text" className="form-control" placeholder="카드승인번호 검색" id="s_permit_no"/>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            <button type="button" className="btn btn-sm btn-dark" onClick={() => setModalOpen(true)}>일일정산</button>
            <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => Excel_download()}>엑셀저장</button>
          </div>
        </div>

        <div className="m-3">
          {currPage === 1 &&
            <div id="GRID">
              <dl>
                <dt>판매총액</dt>
                <dd>{Fn.numberWithCommas(result?.s1Price || 0)}</dd>
              </dl>

              <dl>
                <dt>취소</dt>
                <dd>{Fn.numberWithCommas(result?.s2Price || 0)}</dd>
              </dl>

              <dl>
                <dt>반품금액</dt>
                <dd>{Fn.numberWithCommas(result?.s3Price || 0)}</dd>
              </dl>

              <dl>
                <dt>할인</dt>
                <dd>{Fn.numberWithCommas(result?.s4Price || 0)}</dd>
              </dl>

              <dl>
                <dt>손실</dt>
                <dd>{Fn.numberWithCommas(result?.s6Price || 0)}</dd>
              </dl>

              <dl>
                <dt>실판매</dt>
                <dd>{Fn.numberWithCommas(result?.s5Price || 0)}</dd>
              </dl>
            </div>
          }
        </div>

        <div className="m-3 pb-2 table_list table_wide_sh2">
          {/* 판매목록 */}
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="60px"/>
              <col width="90px"/>
              <col width="75px"/>
              <col width="*"/>
              <col width="75px"/>
              <col width="75px"/>
              <col width="75px"/>
              <col width="60px"/>
              <col width="75px"/>
              <col width="75px"/>
              <col width="90px"/>
              <col width="*"/>
              <col width="85px"/>
              <col width="85px"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>판매일</th>
              <th>상품코드</th>
              <th>상품명</th>
              <th>상품단가</th>
              <th>할인금액</th>
              <th>판매단가</th>
              <th>수량</th>
              <th>금액</th>
              <th>결제금액</th>
              <th>결제방식</th>
              <th>결제비고</th>
              <th>비고</th>
              <th>영수증</th>
            </tr>
            {
              // TODO : rowSpan 처리 필수
              rets.map((ret, i) => (
                ret?.sub && ret?.sub.map((subRow, i2) => {

                  const str = ret?.payInfo?.trim().replace(/<a.*>.<\/a>/g, "");
                  const arr = str.split(/<br\s*\/?>/);
                  const res = arr[0] + arr.slice(1, -1).join('<br>') + arr.slice(-1);

                  return (
                    <tr>
                      {i2 === 0 ? <td rowSpan={ret?.sub?.length}>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td> : <></>}
                      {i2 === 0 ? <td rowSpan={ret?.sub?.length}>{ret.sales_date}</td> : <></>}
                      <td>{subRow?.p_code}</td>
                      <td className="text-left">{subRow?.p_name2 || subRow?.p_name}</td>
                      <td className="text-right">{Fn.numberWithCommas(subRow?.won_sales_price || 0)}</td>
                      <td className="text-right">{subRow?.discount_price && <span style={{color: 'red'}}>{Fn.numberWithCommas(subRow.discount_price)}</span>}</td>
                      <td className="text-right">{Fn.numberWithCommas(subRow?.unit_price || 0)}</td>
                      <td>{Fn.numberWithCommas(subRow?.qty || 0)}</td>
                      <td className="text-right">{Fn.numberWithCommas(subRow?.price || 0)}</td>
                      {i2 === 0 ?
                        <td className="text-right" rowSpan={ret?.sub?.length}>{Fn.numberWithCommas(ret?.sum_price || 0)}</td>
                        :
                        <></>}
                      {i2 === 0 ?
                        <td className="text-center" rowSpan={ret?.sub?.length}>{ret.payMethodShow}</td>
                        :
                        <></>}
                      {i2 === 0 ?
                        <td className="text-left" rowSpan={ret?.sub?.length} dangerouslySetInnerHTML={{__html: res?.replace(/btn\-success/g, "btn-success btn-sssm")}}></td>
                        :
                        <></>}
                      {i2 === 0 ?
                        <td className="text-left" rowSpan={ret?.sub?.length}>
                          <MyComponent
                            salesState={ret?.sales_state}
                            sumPrice={ret?.sum_price}
                            actYN={ret?.act_YN}
                            salesCode={ret?.sales_code}
                            salesDate={ret?.sales_date}
                            cardInfo={ret?.card_info}
                            sessionUserAuth={Fn.parseInt(localStorage.getItem("USER.systemUserAuth"))}
                          />
                        </td>
                        :
                        <></>}
                      {i2 === 0 ?
                        <td rowSpan={ret?.sub?.length}>
                          {ret?.card_info && <button type="button" className="btn btn-ssm btn-outline-dark px-2"
                                                     onClick={() => popUp(`https://spoany.co.kr/receipt/sales_print.html?sales_code=${ret.sales_code}&sales_state=${ret.sales_state}`, 'receipt_sales_print', '600', '700', 'no')}>영수증</button>}
                        </td>
                        :
                        <></>}
                    </tr>
                  )
                })
              ))
            }
          </table>
        </div>
      </div>
      <nav className="p-3 mb-4 d-flex justify-content-between border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '880px'}}>
            <div className="POP_title">
              <h5><strong>일일정산</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <thead>
                <tr>
                  <th style={{width: '60px'}}>No</th>
                  <th>판매시간</th>
                  <th className="w-30">상품명</th>
                  <th>결제</th>
                  <th>단가</th>
                  <th>수량</th>
                  <th>금액</th>
                </tr>
                </thead>
                <tbody>
                {modalResult && modalResult?.length > 0 && modalResult?.map((ret, i) => {
                  let pay_show = "";

                  if (ret?.pay_method === "1111") {
                    pay_show = "현금";
                  } else if (ret?.pay_method === "1112") {
                    if (ret?.ck_summary_no === "180") {
                      pay_show = "제로페이";
                    } else if (ret?.ck_summary_no === "242") {
                      pay_show = "서울페이플러스";
                    } else {
                      pay_show = "계좌";
                    }
                  } else {
                    pay_show = "카드";
                  }

                  return (
                    <tr>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-center">{ret?.reg_date?.substring(11, 16)}</td>
                      <td className="text-left" style={{paddingLeft: '10px'}}>{ret?.p_name}</td>
                      <td className="text-center">{pay_show}</td>
                      <td className="text-right" style={{paddingRight: '10px'}}>{Fn.numberWithCommas(ret?.unit_price)}</td>
                      <td className="text-center">{ret?.qty}</td>
                      <td className="text-right" style={{paddingRight: '10px'}}>{Fn.numberWithCommas(ret?.price)}</td>
                    </tr>
                  );
                })}
                {(!modalResult || modalResult?.length === 0) &&
                  <tr>
                    <td colSpan="7" className="text-center">정산대상이 없습니다.</td>
                  </tr>
                }
                </tbody>
              </table>
              {((modalResult && modalResult?.length > 0)) &&
                <table width="100%" className="view-rounded sub_table_border mt-3">
                  <tr>
                    <td width="20%" align="right">대상건수 : <strong>{modalResult?.length}</strong></td>
                    <td width="20%" align="right">카드매출 : <strong><span id="card_price"></span></strong></td>
                    <td width="20%" align="right">현금매출 : <strong><span id="cash_price"></span></strong></td>
                    <td width="20%" align="right">계좌매출 : <strong><span id="online_price"></span></strong></td>
                    <td width="20%" align="right">총매출 : <strong><span id="total_price"></span></strong></td>
                  </tr>
                </table>}
            </div>
            <div className="text-center mb-3">
              {((modalResult && modalResult?.length > 0)) &&
                <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => input_insert()}>마감</button>
              }
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <SlCashCancel isOpen={slCashCancel} onClose={() => setSlCashCancel(false)} onCallback={() => setRenew(Fn.getRenewTime())} wonSalesCode={rt?.wonSalesCode} wonSalesDate={rt?.wonSalesDate}/>
      <SlCardCancel isOpen={slCardCancel} onClose={() => setSlCardCancel(false)} onCallback={() => setRenew(Fn.getRenewTime())} wonSalesCode={rt?.wonSalesCode} wonSalesDate={rt?.wonSalesDate}/>
      <SlCashReturn isOpen={slCashReturn} onClose={() => setSlCashReturn(false)} onCallback={() => setRenew(Fn.getRenewTime())} wonSalesCode={rt?.wonSalesCode} wonSalesDate={rt?.wonSalesDate}/>
      <SlCardReturn isOpen={slCardReturn} onClose={() => setSlCardReturn(false)} onCallback={() => setRenew(Fn.getRenewTime())} wonSalesCode={rt?.wonSalesCode} wonSalesDate={rt?.wonSalesDate}/>

    </CmnCard>
  );


  const delSales = (sales_code, sales_date) => {
    if (window.confirm('해당 손실내역을 삭제하시겠습니까?')) {
      axios2
        .postEx('/sales/del/sales', {
          sales_code,
          sales_date
        })
        .then((response) => {
          const data = response.data.trim();
          if (data === 'ok') {
            alert('삭제 되었습니다');
            setRenew(Fn.getRenewTime());
          } else {
            alert('오류! 관리자에게 문의하세요');
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  function MyComponent({salesState, sumPrice, actYN, salesCode, salesDate, cardInfo, sessionUserAuth}) {
    let statusComponent;

    if (salesState === 'R') {
      statusComponent = sumPrice > 0 ? (
        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}><strong><font color='red'>반품완료</font></strong></div>
      ) : (
        <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}><strong><font color='green'>반품등록</font></strong></div>
      );
    } else if (salesState === 'C') {
      statusComponent = <div style={{display: 'flex', justifyContent: 'center', alignContent: 'center'}}><strong><font color='blue'>취소완료</font></strong></div>;
    } else {
      if (sumPrice < 0) {
        statusComponent = actYN === 'N' ? (
          <div align="center">
            {sessionUserAuth >= 7 ? (
              <button type="button" className="btn btn-info btn-sm" onClick={() => delSales(salesCode, salesDate)}>
                삭제
              </button>
            ) : (
              <button type="button" className="btn btn-info btn-sm" onClick={() => alert('점장권한 이상의 계정에서만 삭제 가능합니다')}>
                삭제
              </button>
            )}
          </div>
        ) : (
          <div align="center">
            <button type="button" className="btn btn-info btn-sm" onClick={() => alert('확정된 손실은 삭제할 수 없습니다')}>
              손실
            </button>
          </div>
        );
      } else {
        statusComponent = (actYN === 'N') ? (
          cardInfo ? (
            <div align="center">
              <button type="button" className="btn btn-dark btn-ssm" onClick={() => window.cardCancel(salesCode, salesDate)}>
                취소
              </button>
            </div>
          ) : (
            <div align="center">
              <button type="button" className="btn btn-dark btn-ssm" onClick={() => window.cashCancel(salesCode, salesDate)}>
                취소
              </button>
            </div>
          )
        ) : (
          cardInfo ? (
            <div align="center">
              <button type="button" className="btn btn-point btn-ssm" onClick={() => window.cardReturn(salesCode, salesDate)}>
                반품
              </button>
            </div>
          ) : (
            <div align="center">
              <button type="button" className="btn btn-point btn-ssm" onClick={() => window.cashReturn(salesCode, salesDate)}>
                반품
              </button>
            </div>
          )
        );
      }
    }

    return statusComponent;
  }
};

export default SalesList;
