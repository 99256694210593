import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const BranchPtOtTrainer = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [data, setData] = useState([]);
  const [man, setMan] = useState([]);
  const [cellClass, setCellClass] = useState({});
  const [info, setInfo] = useState({});
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('#s_date').val(Fn.getCurrentDate());
  }, []);

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/branchop/ptot/trainer/ready?s_date=${$('#s_date').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          const _cellClass = {};
          const _info = {};

          result?.data?.forEach(dataRow => {
            const times = dataRow.basic_time;
            const trainerSeq = dataRow.traniner_seq;

            if (dataRow.state === "P") {
              _cellClass[times] = _cellClass[times] || {};
              _cellClass[times][trainerSeq] = "cell_bg_pt";

              _info[times] = _info[times] || {};
              _info[times][trainerSeq] = dataRow.mb_info;
            } else if (dataRow.state === "O") {
              _cellClass[times] = _cellClass[times] || {};
              _cellClass[times][trainerSeq] = "cell_bg_ot";

              _info[times] = _info[times] || {};
              _info[times][trainerSeq] = dataRow.mb_info;
            } else {
              _cellClass[times] = _cellClass[times] || {};
              _cellClass[times][trainerSeq] = "";

              _info[times] = _info[times] || {};
              _info[times][trainerSeq] = "";
            }
          });
          setCellClass(_cellClass);
          setInfo(_info);

          setData(result?.data);
          setMan(result?.man);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  // 캘린더 스크립트 외 별도 스크립트 없음

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex justify-content-between m-3">
        <div className="d-flex">
          <div className="pr-1">
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>
        <div className="text-left d-flex">
          <p className="px-3 bold700">
            <span className="h4 bold700 vertical-middle" style={{color: '#F7D5AE'}}>◼︎</span> PT
          </p>
          <p className="px-3 bold700">
            <span className="h4 bold700 vertical-middle" style={{color: '#BEE4EB'}}>◼︎</span> OT
          </p>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="*"></col>
            {man.map((manRow) => (
              <col width="*"></col>
            ))}
          </colgroup>
          <tr>
            <th></th>
            {man.map((manRow) => (
              <th key={manRow.seq}>{manRow.user_name}</th>
            ))}
          </tr>
          {Array.from({length: 24}, (_, i) => {
            const j = i < 10 ? "0" + i : i;
            return (
              <tr key={i}>
                <th>{j}시</th>
                {man.map((manRow) => {
                  const tSeq = manRow.seq;
                  const split = info?.[j] && info[j]?.[tSeq] ? info?.[j]?.[tSeq]?.split("/") : ['', ''];

                  return (
                    <td key={manRow.seq} className={cellClass[j] && cellClass[j][tSeq]}>
                      {split[0]}<br/>{split[1]}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </table>
      </div>
    </CmnCard>
  );
};

export default BranchPtOtTrainer;
