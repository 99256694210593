import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const MvPointList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };
  const [result, setResult] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    if (isOpen) {
      load(1);
    }
  }, [srchRenew]);

  useEffect(() => {
    if (isOpen) {
      load();
    }
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/member/point/list/ready?mb_no=${props.mbNo}&tbl_gubun=${$('#pl_tbl_gubun option:selected').val() || ''}&start_date=${$('#pl_s_date1').val() || ''}&end_date=${$('#pl_s_date2').val() || ''}&gubun=${$('#pl_s_gubun option:selected').val() || ''}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function del_point(seq) {
    console.log("/member/point/list/del?mb_no=" + props.mbNo + "&tbl_gubun=" + $('#pl_tbl_gubun option:selected').val() + "&seq=" + seq + "&auth_code=" + $('#auth_code').val());
    if (window.confirm('해당 포인트를 삭제하시겠습니까?')) {
      axios2
        .post("/member/point/list/del?mb_no=" + props.mbNo + "&tbl_gubun=" + $('#pl_tbl_gubun option:selected').val() + "&seq=" + seq + "&auth_code=" + $('#auth_code').val())
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            alert('삭제되었습니다');
            props?.onCallback();
            onToggleModal();
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '820px'}}>
          <div className="POP_title">
            <h5><strong>포인트 내역</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <div className="mb-3 d-flex flex-nowrap">
              <div className="pr-1">
                <select className="form-select" id='pl_tbl_gubun'>
                  <option value="now">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                </select>
              </div>
              <div className="d-flex pr-1">
                <JDatePicker type="date" className="form-control" aria-describedby="button-addon2" id="pl_s_date1"/>
                <p className="mx-2">~</p>
                <JDatePicker type="date" className="form-control" aria-describedby="button-addon2" id="pl_s_date2"/>
              </div>
              <div className="pr-1">
                <select className="form-select" id="pl_s_gubun">
                  <option value="">:전체</option>
                  <option value="입장체크">입장체크</option>
                  <option value="입장체크제외">입장체크제외</option>
                </select>
              </div>

              <div className="pr-1">
                <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
              </div>
            </div>
            <table className="view-rounded sub_table_border" style={{borderRadius: 0}}>
              <tr>
                <th className="w_70px">No</th>
                <th>일자</th>
                <th>구분</th>
                <th>마일리지</th>
                <th>삭제</th>
              </tr>
              {result?.result && result?.result.map((ret, i) => {
                return (
                  <tr>
                    <td className="text-center">{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                    <td className="text-center">{ret?.basic_date}</td>
                    <td className="text-center">{ret?.gubun}</td>
                    <td className="text-right">{Fn.numberWithCommas(ret?.point)}</td>
                    <td className="text-center">
                      {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 8 ?
                        <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => del_point(ret?.seq)}>삭제</button>
                        :
                        <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => alert('본사스텝이상 사용가능합니다')}>삭제</button>
                      }
                    </td>
                  </tr>
                );
              })}
              {(!result?.result || result?.result?.length === 0) &&
                <tr>
                  <td colSpan={5} className="text-center">※ 등록된 data가 없습니다.</td>
                </tr>
              }
            </table>
          </div>
          <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </nav>
        </div>
      </div>
    </Modal>
  );
};

export default MvPointList;
