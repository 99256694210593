import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";

const MvRepuCard = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const load = () => {
    axios2
      .post(`/member/repu/card/ready?mb_no=${props.mbNo}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#ip_bank').html() === '') {
            let optionsHTML = '<option value="">은행</option>';
            result?.qry1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.j_no},${list_row.j_jukname}">${list_row.j_jukname}</option>`;
            });
            $('#ip_bank').html(optionsHTML);
          }
          if ($('#ip_cardName').html() === '') {
            let optionsHTML = '<option value="">카드사</option>';
            result?.qry2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.j_no},${list_row.j_jukname}">${list_row.j_jukname}</option>`;
            });
            $('#ip_cardName').html(optionsHTML);
          }

          setResult(result);
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {

    if ($('#new_card_no').val() == "") {
      alert("변경 카드번호를 입력해주세요");
      $('#new_card_no').focus();
      return;
    }

    if ($('#re_type_1').is(":checked")) {
      if ($('#ip_method').val() == "1143,카드매출금" && $('#ip_cardName').val() == "") {
        alert("카드사를 선택해주세요");
        $('#ip_cardName').focus();
        return;
      }
      if ($('#ip_method').val() == "-1143,카드취소" && $('#ip_cardName').val() == "") {
        alert("카드사를 선택해주세요");
        $('#ip_cardName').focus();
        return;
      }
      if ($('#ip_method').val() == "1112,보통예금" && $('#ip_bank').val() == "") {
        alert("통장을 선택해주세요");
        $('#ip_bank').focus();
        return;
      }
    }

    axios2
      .postEx(`/member/repu/card`, {
        ip_method: $('#ip_method option:selected').val() || '',
        ip_bank: $('#ip_bank option:selected').val() || '',
        ip_cardName: $('#ip_cardName option:selected').val() || '',
        ip_etc: $('#ip_etc').val() || '',
        reg_step: $('#_reg_step option:selected').val() || '',
        re_type: $(`[name='re_type']:checked`).val() || '',
        auth_code: $('#auth_code').val(),
        pdt_etc: $('#pdt_etc').val() || '', // N/A
        new_card_no: $('#new_card_no').val() || '',
        ip_amt: $('#ip_amt').val().replaceAll(",", "") || '',
        mb_no: props.mbNo,
        ckk_acc_jukyoo: "", // N/A
        ckk_acc_jukyoo_no: "", // N/A
        is_new: "", // N/A
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (data == "dup") {
          alert('동일한 카드번호가 사용중입니다. 다시 클릭해주세요');
        } else {
          alert('변경되었습니다');
          props?.onCallback();
          onToggleModal();
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function ch(type) {
    if (type == "1") {
      $('#ip_method').val("1111,현금");
      $('#ip_method').prop("disabled", false);
      $('#ip_amt').val("5,000");
      $('#ip_amt').prop("disabled", false);
      $('#ip_amt').attr("readOnly", true);
    } else {
      $('#ip_method').val("1111,현금");
      $('#ip_method').prop("disabled", true);
      $('#ip_amt').val("0");
      $('#ip_amt').prop("disabled", true);
    }
    ch2('1111,현금');
  }

  function ch2(value) {
    if (value == '1143,카드매출금') {
      $('#ip_cardName').show();
      $('#ip_cardNameLabel').show();
      $('#ip_bank').val('');
      $('#ip_bank').hide();
    } else if (value == '1111,현금') {
      $('#ip_cardName').val('');
      $('#ip_cardName').hide();
      $('#ip_bank').val('');
      $('#ip_bank').hide();
      $('#ip_cardNameLabel').hide();
    } else if (value == '1112,보통예금') {
      $('#ip_cardName').val('');
      $('#ip_cardName').hide();
      $('#ip_cardNameLabel').show();
      $('#ip_bank').show();
    } else if (value == '-1111,현금') {
      $('#ip_cardName').val('');
      $('#ip_cardName').hide();
      $('#ip_bank').val('');
      $('#ip_bank').hide();
      $('#ip_cardNameLabel').hide();
    } else if (value == '-1143,카드취소') {
      $('#ip_cardName').show();
      $('#ip_cardNameLabel').show();
      $('#ip_bank').val('');
      $('#ip_bank').hide();
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '900px'}}>
          <div className="POP_title">
            <h5><strong>카드재발급</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <div className="d-flex pb-3">
              <p className="pr-3">재발급형태 :</p>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="re_type" id="re_type_1" value="유" onClick={() => ch('1')}/>
                <label className="form-check-label" htmlFor="re_type_1">유상</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="re_type" id="re_type_0" value="무" onClick={() => ch('0')}/>
                <label className="form-check-label" htmlFor="re_type_0">무상</label>
              </div>
            </div>
            <table className="view-rounded sub_view" style={{borderRadius: 0}}>
              <colgroup>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>신규카드번호</th>
                <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" id="new_card_no"/></td>
                <th>형태</th>
                <td>
                  <select className="form-select" id="ip_method" onChange={(e) => {
                    ch2(e.target.value);
                  }}>
                    <option value='1111,현금'>현금</option>
                    <option value='1143,카드매출금'>카드</option>
                    <option value='1112,보통예금'>이체</option>
                    <option value='-1111,현금'>현금환불</option>
                    {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7 && <option value='5159,잡손실'>대체손실</option>}
                  </select>
                  <select className="form-select" style={{marginTop: 5, display: "none"}} id="ip_bank">
                  </select>
                  <select className="form-select" style={{marginTop: 5, display: "none"}} id="ip_cardName">
                  </select>
                </td>
                <th>금액</th>
                <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" id="ip_amt"/></td>
              </tr>
            </table>
          </div>
          <div className="text-center mb-3">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit()}>등록</button>
            <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>취소</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MvRepuCard;
