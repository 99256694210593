import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import _ from "lodash";
import ChkAuthNum from "../common/ChkAuthNum";
import Modal from "react-modal";

const BranchPtOpen = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  // const trainerSeq = '3189'; // TODO : ??? / <input type="hidden" name="base_tranier_seq" value="<?= $_SESSION[SystemStepSeq] ?>">
  const [renew, setRenew] = useState(0);
  const [modalRenew, setModalRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});
  const [modalCurrPage, setModalCurrPage] = useState(1);
  const [modalPageInfo, setModalPageInfo] = useState({});
  const [type, setType] = useState('P');

  const week = ["일", "월", "화", "수", "목", "금", "토"];

  const [gabDate, setGabDate] = useState(0);
  const [tableCells, setTableCells] = useState([]);

  useEffect(() => {
    $('#aa').prop('checked', true);

    $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
    $('#s_month').val(Fn.parseInt(Fn.getCurrentDate().split("-")[1]));

    // 현재 주차
    const cdate = new Date();
    const firstWeekday = new Date(cdate.getFullYear(), cdate.getMonth(), 1).getDay();
    const offsetDate = cdate.getDate() + firstWeekday - 1;
    $('#s_week').val(Math.floor(offsetDate / 7) + 1);
    defineType('P');
  }, []);

  useEffect(() => {
    load();
  }, [renew]);

  useEffect(() => {
    if (!modalRenew) return;
    loadModal();
  }, [modalRenew, modalCurrPage]);

  const load = () => {
    // TODO : API에서 start_date, end_date 값을 계산하는 로직이 다소 이상한 듯.
    axios2
      .post(`/branchop/ptot/open/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&s_week=${$('#s_week option:selected').val() || ''}&search_auth_code=&auth_code=&u_name=&search_YN=Y&base_tranier_seq=${$('#base_tranier_seq').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);

          function dateGab(start_date, end_date) {
            const startDate = new Date(start_date);
            const endDate = new Date(end_date);
            const diffInTime = Math.abs(endDate.getTime() - startDate.getTime());
            const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
            return diffInDays;
          }

          const gab_date = dateGab(result?.start_date, result?.end_date);

          setGabDate(gab_date);

          const _cellClass = {};
          const _info = {};
          const _tIn = {};

          setStartDate(result?.start_date);
          setEndDate(result?.end_date);

          result?.data?.forEach(dataRow => {
            const {basic_date, basic_time, state, mb_info, mb_no, traniner_seq, t_insert_YN} = dataRow;

            if (state === 'P') {
              _cellClass[basic_time] = _cellClass[basic_time] || {};
              _cellClass[basic_time][basic_date] = 'cell_bg_pt';

              _info[basic_time] = _info[basic_time] || {};
              _info[basic_time][basic_date] = mb_info + "/" + mb_no + "/" + traniner_seq;

              _tIn[basic_time] = _tIn[basic_time] || {};
              _tIn[basic_time][basic_date] = t_insert_YN;
            } else if (state === 'O') {
              _cellClass[basic_time] = _cellClass[basic_time] || {};
              _cellClass[basic_time][basic_date] = 'cell_bg_ot';

              _info[basic_time] = _info[basic_time] || {};
              _info[basic_time][basic_date] = mb_info + "/" + mb_no + "/" + traniner_seq;

              _tIn[basic_time] = _tIn[basic_time] || {};
              _tIn[basic_time][basic_date] = t_insert_YN;
            } else {
              _cellClass[basic_time] = _cellClass[basic_time] || {};
              _cellClass[basic_time][basic_date] = '';

              _info[basic_time] = _info[basic_time] || {};
              _info[basic_time][basic_date] = '';

              _tIn[basic_time] = _tIn[basic_time] || {};
              _tIn[basic_time][basic_date] = '';
            }
          });

          console.log(_cellClass);

          setTableCells(generateTableCells(gab_date, result?.start_date, _cellClass, _info, _tIn, $('#base_tranier_seq').val() || '', delData));
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    // TODO : API에서 start_date, end_date 값을 계산하는 로직이 다소 이상한 듯.
    console.log(`/branchop/ptot/member/search/ready?search_YN=${$('#mv_search_YN').val()}&basic_date=${$('#mv_basic_date').val()}&basic_time=${$('#mv_basic_time').val()}&traniner_seq=${$('#mv_traniner_seq').val()}&search_type=${$('#mp_search_type option:selected').val() || ''}&key=${$('#mp_key').val() || ''}`);
    axios2
      .post(`/branchop/ptot/member/search/ready?search_YN=${$('#mv_search_YN').val() || ''}&basic_date=${$('#mv_basic_date').val() || ''}&basic_time=${$('#mv_basic_time').val() || ''}&traniner_seq=${$('#mv_traniner_seq').val() || ''}&search_type=${$('#mp_search_type option:selected').val() || ''}&key=${$('#mp_key').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result, pageInfo);
          setModalResult(result);
          setModalPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function popUp(search_type, basic_date, basic_time, traniner_seq) {
    $('#mv_search_type').val(search_type);
    $('#mv_basic_date').val(basic_date);
    $('#mv_basic_time').val(basic_time);
    $('#mv_traniner_seq').val(atob(traniner_seq));

    axios2
      .postEx('/branchop/ptot/open', {
        state: $('#type').val(),
        select_date: basic_date,
        select_time: basic_time,
        base_tranier_seq: $('#base_tranier_seq').val() || '',
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "reserved") {
          alert("예약된 일정은 삭제할수 없습니다");
        } else {
          setRenew(Fn.getRenewTime());
          setModalOpen(false);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function modal_reg(mb_no, mb_name, basic_date, basic_time, traniner_seq) {
    const search_type = $('#mp_search_type option:selected').val() || "cell_bg_pt";
    console.log(search_type, mb_no, mb_name, basic_date, basic_time, traniner_seq);

    if (search_type !== "cell_bg_pt") {
      const txt = mb_name + "회원님의 OT예약을 등록하시겠습니까?";
      if (window.confirm(txt)) {
        const insert_type = "ot";

        axios2
          .postEx('/branchop/ptot/reserve', {
            insert_type: insert_type,
            mb_no: mb_no,
            basic_date: basic_date,
            basic_time: basic_time,
            traniner_seq: traniner_seq,
          })
          .then((response) => response.json())
          .then(({code, message: data, result, pageInfo}) => {
            if (data == "ok") {
              alert("예약등록 되었습니다");
              setRenew(Fn.getRenewTime());
              setModalOpen(false);
            } else {
              alert("이미 예약된 시간대입니다");
              setRenew(Fn.getRenewTime());
              setModalOpen(false);
            }
          })
          .catch((error) => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          });
      }
    } else {
      const txt = mb_name + "회원님의 PT예약을 등록하시겠습니까?";
      if (window.confirm(txt)) {
        const insert_type = "pt";

        axios2
          .postEx('/branchop/ptot/reserve', {
            insert_type: insert_type,
            mb_no: mb_no,
            basic_date: basic_date,
            basic_time: basic_time,
            traniner_seq: traniner_seq,
          })
          .then((response) => response.json())
          .then(({code, message: data, result, pageInfo}) => {
            if (data == "ok") {
              alert("예약등록 되었습니다");
              setRenew(Fn.getRenewTime());
              setModalOpen(false);
            } else {
              alert("이미 예약된 시간대입니다");
              setRenew(Fn.getRenewTime());
              setModalOpen(false);
            }
          })
          .catch((error) => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          });
      }
    }
  }

  const defineType = (type) => {
    if (type === 'O') {
      $('#type').val('O');
      $('#bb').prop('checked', true);
      $('#lb_aa, #lb_bb').removeClass('btn-info btn-outline-info btn-first');
      $('#lb_bb').addClass('btn-info btn-first');
      $('#lb_aa').addClass('btn-outline-info');
      setType('O');
    } else {
      $('#type').val('P');
      $('#aa').prop('checked', true);
      $('#lb_aa, #lb_bb').removeClass('btn-info btn-outline-info btn-first');
      $('#lb_aa').addClass('btn-info btn-first');
      $('#lb_bb').addClass('btn-outline-info');
      setType('P');
    }
  }

  const selectTime = (select_date, select_time, dd) => {
    /*
        if (s_form.base_tranier_seq.value === '') {
          alert('인증번호를 입력해주세요');
          s_form.search_auth_code.focus();
          return;
        }

        let state = '';
        if (form1.gubun[0].checked === true) {
          state = 'P';
        } else if (form1.gubun[1].checked === true) {
          state = 'O';
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
          return;
        }

        const base_tranier_seq = s_form.base_tranier_seq.value;

        axios
          .post('/branchop/ptot/open', `base_tranier_seq=${base_tranier_seq}&select_date=${select_date}&select_time=${select_time}&state=${state}`)
          .then(response => {
            let data = response.data.trim();

            if (data === 'reserved') {
              alert('예약된 일정은 삭제할수 없습니다');
              return;
            } else {
              const split = data.split('||');

              if (split[0] === 'P') {
                const data1 = split[1];
                const data2 = 'cell_pt';

                $(`#${data1}`).addClass(data2);
              } else if (split[0] === 'O') {
                const data1 = split[1];
                const data2 = 'cell_ot';

                $(`#${data1}`).addClass(data2);
              } else {
                const data1 = split[1];

                $(`#${data1}`).removeClass('cell_ot');
                $(`#${data1}`).removeClass('cell_pt');
              }
            }
          })
          .catch(error => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          });
    */
  };

  const delData = (basic_date, times, mb_no, traniner_seq) => {
    axios2
      .post(`/branchop/ptot/reserve/del?basic_date=${basic_date}&basic_time=${times}&mb_no=${mb_no}&traniner_seq=${atob(traniner_seq)}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        console.error(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  function generateTableCells(gab_date, start_date, cell_class, info, t_in, traniner_seq, delData) {
    const tableCells = [];

    for (let i = 0; i <= 23; i++) {
      const j = i < 10 ? `0${i}` : i;
      const tableRow = (
        <tr>
          <th>{j}시</th>

          {Array.from({length: gab_date + 1}, (_, d) => {
            const basic_date = new Date(start_date);
            basic_date.setDate(basic_date.getDate() + d);
            const formatted_date = Fn.getDate(basic_date);

            const cell_id = `date_${j}${formatted_date}`;
            const split = info?.[j]?.[formatted_date]?.split('/') ?? [];

            const dd = cell_class?.[j]?.[formatted_date] || '';
            const t_insert_YN = t_in?.[j]?.[formatted_date];

            if (!traniner_seq) traniner_seq = split?.[2];

            console.log(cell_class?.[j]?.[formatted_date]);

            const handleClick = traniner_seq
              ? split[0]
                ? split[0] != 'null'
                  ? () => alert('예약된 일정은 삭제할수 없습니다')
                  : () => delData(formatted_date, j, split[1], traniner_seq)
                : () =>
                  popUp(dd, formatted_date, j, traniner_seq)
              : () => alert('인증번호를 입력해주세요');

            return (
              <td
                className={cell_class?.[j]?.[formatted_date]}
                onClick={() => {
                  if (!$('#auth_code').val()) {
                    alert('인증번호를 입력해주세요.');
                    return;
                  }
                  handleClick();
                }}
                key={`${j}-${formatted_date}`}
              >
                {split?.[0] == 'null' ? '' : split?.[0]}<br/>
                {split?.[0] == 'null' ? '' : split?.[1]}
              </td>
            );
          })}
        </tr>
      );

      tableCells.push(tableRow);
    }

    return tableCells;
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex justify-content-between flex-wrap m-3">
        <div className="d-flex">
          <div className="pr-1">
            <select className="form-select" id="s_year">
              {Array.from({length: 3}, (_, index) => new Date().getFullYear() - 1 + index).map((year) => (
                <option key={year} value={year}>
                  {year} 년
                </option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_month">
              {Array.from({length: 12}, (_, index) => index + 1).map((month) => (
                <option key={month} value={month}>
                  {month} 월
                </option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_week">
              {Array.from({length: 6}, (_, index) => index + 1).map((month) => (
                <option key={month} value={month}>
                  {month} 주
                </option>
              ))}
            </select>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>
        <div className="d-flex">
          <ChkAuthNum type={"pt"} successCallback={(data) => {
            setRenew(Fn.getRenewTime());
          }}/>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border">
          <tr>
            <td colSpan={gabDate + 1} className="text-left p-3">
              <div className="d-flex justify-content-between space_nowrap">
                <div>
                  <span className="h5 bold700">{startDate?.replaceAll('-', '.')} ~ {endDate?.replaceAll('-', '.')}</span>
                </div>

                <div>
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="aa"
                      autoComplete="off"
                      onClick={() => {
                        defineType('P');
                      }}
                    />
                    <label
                      id="lb_aa"
                      className="btn btn-ssm btn-first btn-info mb-0"
                      htmlFor="aa"
                    >
                      P/T 등록
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="btnradio"
                      id="bb"
                      autoComplete="off"
                      onClick={() => defineType('O')}
                    />
                    <label
                      id="lb_bb"
                      className="btn btn-ssm btn-outline-info mb-0"
                      htmlFor="bb"
                    >
                      O/T 등록
                    </label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>시간/요일</th>
            {gabDate && _.range(0, gabDate + 1).map(d => {
              const date = new Date(startDate);
              date.setDate(date.getDate() + d);
              const weekday = week[date.getDay()];
              return (
                <th>{weekday}</th>
              );
            })}
          </tr>
          {tableCells}
        </table>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '1150px'}}>
            <div className="POP_title">
              <h5><strong>PT/OT 회원 검색</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box ">
              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select name="mp_search_type" id="mp_search_type" className="form-select"
                    // onchange="s_form.submit()"
                  >
                    <option value="">PT/OT 선택</option>
                    <option value="cell_bg_pt">PT</option>
                    <option value="cell_bg_ot">OT</option>
                  </select>
                </div>
                <div className="pr-1">
                  <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="성명/연락처 검색" id="mp_key"/>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3"
                          onClick={() => {
                            $('#mv_search_YN').val('Y');
                            setModalRenew(Fn.getRenewTime());
                          }}>검색
                  </button>
                </div>
              </div>
              <div className="zpb-2 table_list">
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="70px"/>
                    <col width="110px"/>
                    <col width="80px"/>
                    <col width="120px"/>
                    <col width="90px"/>
                    <col width="90px"/>
                    <col width="60px"/>
                    <col width="60px"/>
                    <col width="70px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>No</th>
                    <th>카드번호</th>
                    <th>성명</th>
                    <th>연락처</th>
                    <th>시작일</th>
                    <th>종료일</th>
                    <th>라커</th>
                    <th>운동복</th>
                    <th>잔여PT</th>
                    <th>미납금</th>
                  </tr>
                  {modalResult?.result?.map((ret, i) =>
                    <tr>
                      <td className="text-center">{modalPageInfo?.totalArticles - modalPageInfo?.articlesPerPage * (modalPageInfo?.currentPage - 1) - i}</td>
                      <td className="text-center">{ret?.mb_id}</td>
                      <td className="text-center" style={{cursor: "pointer"}} onClick={() => modal_reg(ret?.mb_no, ret?.mb_name, $('#mv_basic_date').val(), $('#mv_basic_time').val(), $('#mv_traniner_seq').val())}><strong>{ret?.mb_name}</strong></td>
                      <td className="text-center">{ret?.mb_hp}</td>
                      <td className="text-center">{ret?.start_date}</td>
                      <td className="text-center">{ret?.end_date}</td>
                      <td className="text-center">{ret?.locker == '1' ? "O" : "X"}</td>
                      <td className="text-center">{ret?.suit == '1' ? "O" : "X"}</td>
                      <td className="text-center">{ret?.remainPT}</td>
                      <td className="text-right">{ret?.remainPrice}</td>
                    </tr>
                  )}
                </table>
              </div>
            </div>

            <nav className="p-3 mb-3 d-flex justify-content-between border-top flex-wrap">
              <Paging pageInfo={modalPageInfo} setCurrPage={page => setModalCurrPage(page)}/>
              <div>
                <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>닫기</button>
              </div>
            </nav>

          </div>
        </div>
      </Modal>

      <input type="hidden" id="type"/>
      <input type="hidden" id="mv_search_YN"/>
      <input type="hidden" id="mv_search_type"/>
      <input type="hidden" id="mv_basic_date"/>
      <input type="hidden" id="mv_basic_time"/>
      <input type="hidden" id="mv_traniner_seq"/>

      <input type="hidden" id="base_tranier_seq"/>

    </CmnCard>
  );
};

export default BranchPtOpen;
