import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import JDatePicker from "../common/JDatePicker";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import Modal from "react-modal";
import {useHistory} from "react-router-dom";

const EmployeeInvMailSendListNew = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [result, setResult] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});
  const history = useHistory();

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split('-')[0]);
    $('#s_month').val(Fn.getCurrentDate().split('-')[1]);
  }, []);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/inv/mail/send/list/new/ready?s_year=${$('#s_year option:selected').val()}&s_month=${$('#s_month option:selected').val()}&s_branch_name=${$('#s_branch_name').val()}&s_receiver_name=${$('#s_receiver_name').val()}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/inv/mail/show/new/ready?user_id=${$('#modal_user_id').val()}&s_year=${$('#s_year option:selected').val()}&s_month=${$('#s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function sendMail(userId, cnt) {
    const formData = new FormData();

    formData.append("user_id", userId);

    if (window.confirm('이메일을 발송하시겠습니까?')) {
      if ($('#ing_YN').val() === "Y") {
        alert("아직 다른 메일이 발송중입니다");
        return;
      }

      $('#ing_YN').val("Y");

      formData.append("s_year", $('#s_year option:selected').val());
      formData.append("s_month", $('#s_month option:selected').val());
      formData.append("s_branch_name", $('#s_branch_name').val());

      axios2.postFormEx('/inv/send/mail/new', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          alert("발송되었습니다");
          setRenew(Fn.getRenewTime());
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        })
        .finally(() => {
          $('#ing_YN').val('N');
        });
    }
  }

  function sendMailAll() {
    const formData = new FormData();

    let values = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join(",");

    formData.append("chk[]", values);

    if (values === "") {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    // send_btn.innerHTML = "<a type='button' class='btn btn-primary btn-sm'>체크발송</a>";

    if (window.confirm('체크된 이메일을 발송하시겠습니까?')) {
      if ($('#ing_YN').val() === "Y") {
        alert("아직 다른 메일이 발송중입니다");
        return;
      }

      $('#ing_YN').val("Y");

      formData.append("s_year", $('#s_year option:selected').val());
      formData.append("s_month", $('#s_month option:selected').val());

      axios2.postFormEx('/inv/send/mail/all/new', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          alert("발송되었습니다");
          setRenew(Fn.getRenewTime());
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        })
        .finally(() => {
          $('#ing_YN').val('N');
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <input type="hidden" id="modal_user_id"/>
      <input type="hidden" id="ing_YN"/>

      <div className="d-flex m-3 fn_search_box" style={{maxWidth: "935px"}}>
        <div className="pr-1 d-flex">
          <div className="input-group pr-1" style={{width: 'auto'}}>
            <p className="p-1">대상월 :</p>
            <select className="form-select" aria-label="Default select example" id="s_year">
              {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" style={{minWidth: '80px'}} id="s_month">
              {[...Array(12)].map((_, index) => {
                const monthNumber = index + 1;
                const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="지점명 검색" id="s_branch_name"/>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="수신자 검색" id="s_receiver_name"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      {/* ---- 테이블 ---- */}

      <div className="m-3 pb-2 table_list" style={{maxWidth: "935px"}}>
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="70px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="35%"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            <th>아이디</th>
            <th>이름</th>
            <th>이메일</th>
            <th>발송지점</th>
            <th>내용확인</th>
            <th>발송여부</th>
          </tr>
          {result?.result && result?.result?.map((ret, i) => {
            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td><input type="checkbox" id={`achk[${i + 1}]`} value={ret?.userId}/></td>
                <td>{ret?.userId || ''}</td>
                <td>{ret?.receiverName || ''}</td>
                <td>{ret?.receiveEmail || ''}</td>
                <td>{ret?.manageBranch || ''}</td>
                <td>
                  <a
                    className="btn btn-ssm btn-dark"
                    onClick={() => {
                      $('#modal_user_id').val(ret?.userId);
                      setModalOpen(true);
                    }}
                  >
                    메일내용
                  </a>
                </td>
                <td>
                  {ret?.sendYN === "Y" ? (
                    <a className="btn btn-ssm btn-outline-dark send_btn">발송완료</a>
                  ) : (
                    <a
                      className="btn btn-ssm btn-point send_btn"
                      style={{cursor: "pointer"}}
                      onClick={() => sendMail(ret?.userId, i + 1)}
                    >
                      <span id={`show_data${i + 1}`}>발송대기</span>
                    </a>
                  )}
                </td>
              </tr>
            );
          })}
        </table>
      </div>

      <div className="text-right mb-3" style={{maxWidth: "935px"}}>
        <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => sendMailAll()}>선택발송</button>
      </div>

      <nav className="m-3 p-3 mb-4 d-flex justify-content-center border-top flex-wrap" style={{maxWidth: "935px"}}>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: "1000000000"}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: "720px"}}>
            <div className="POP_title">
              <h5><strong>메일내용 확인</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_view">
                <colgroup>
                  <col width="100px"/>
                  <col width="*"/>
                </colgroup>
                {modalResult && modalResult?.result && modalResult?.result?.map((item) =>
                  <>
                    <tr>
                      <th colSpan={2} className="text-left">
                        ({item?.branch_name}) {item?.dataRow?.mail_title}
                      </th>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        {item?.dataRow?.mail_content1}
                      </td>
                    </tr>
                    <tr>
                      <th>년월</th>
                      <td>
                        {item?.dataRow?.basic_month}
                      </td>
                    </tr>
                    <tr>
                      <th>지점명</th>
                      <td>
                        {item?.dataRow?.branch_name}
                      </td>
                    </tr>
                    <tr>
                      <th>배분가능액</th>
                      <td>
                        {Fn.numberWithCommas(item?.dataRow?.price)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        {item?.dataRow?.mail_content2}
                      </td>
                    </tr>
                  </>
                )}
              </table>
            </div>
            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default EmployeeInvMailSendListNew;
