import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";


const EmployeeRegPay = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.baseSeq || '';

  const [renew, setRenew] = useState(0);
  const [result, setResult] = useState(0);

  useEffect(() => {
    $('#base_seq').val(baseSeq);
  }, []);

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/employee/emp/pay/reg/ready?base_seq=${$('#base_seq').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult(result);

          // pu_basic_price, pu_hour_price, pt_basic_price, pt_hour_price, saup
          $('#pu_basic_price').val(result?.mInfoRow?.pu_basic_price);
          $('#pu_hour_price').val(result?.mInfoRow?.pu_hour_price);
          $('#pt_basic_price').val(result?.mInfoRow?.pt_basic_price);
          $('#pt_hour_price').val(result?.mInfoRow?.pt_hour_price);
          $('#saup').val(result?.mInfoRow?.saup);

          // pu_basic_price: $('#pu_basic_price').val(),
          //   pu_hour_price: $('#pu_hour_price').val(),
          //   pt_basic_price: $('#pt_basic_price').val(),
          //   pt_hour_price: $('#pt_hour_price').val(),
          //   saup: $('#saup option:selected').val() || '',

        }
      });
  }

  function go_submit() {
    axios2
      .postEx('/employee/basic/pay/reg', {
        base_seq: $('#base_seq').val(),
        pu_basic_price: $('#pu_basic_price').val(),
        pu_hour_price: $('#pu_hour_price').val(),
        pt_basic_price: $('#pt_basic_price').val(),
        pt_hour_price: $('#pt_hour_price').val(),
        saup: $('#saup option:selected').val() || '',
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (data == "insert_ok") {
          alert("등록되었습니다");
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit2() {
    if ($('#s_price').val() === "") {
      alert("매출액 시작값을 입력해주세요");
      $('#s_price').focus();
      return;
    }
    if ($('#e_price').val() === "") {
      alert("매출액 종료값을 입력해주세요");
      $('#e_price').focus();
      return;
    }
    if ($('#c_rate').val() === "") {
      alert("커미션 요율을 입력해주세요");
      $('#c_rate').focus();
      return;
    }
    if ($('#s_rate').val() === "") {
      alert("수업 요율을 입력해주세요");
      $('#s_rate').focus();
      return;
    }

    console.log({
      base_seq: $('#base_seq').val(),
      won_seq: $('#won_seq').val(),
      s_price: $('#s_price').val(),
      e_price: $('#e_price').val(),
      c_rate: $('#c_rate').val(),
      s_rate: $('#s_rate').val(),
      type: $('#type').val(),
      del_seq: $('#del_seq').val(),
    });

    axios2
      .postEx('/employee/pt/commission/reg', {
        base_seq: $('#base_seq').val(),
        won_seq: $('#won_seq').val(),
        s_price: $('#s_price').val(),
        e_price: $('#e_price').val(),
        c_rate: $('#c_rate').val(),
        s_rate: $('#s_rate').val(),
        type: $('#type').val(),
        del_seq: $('#del_seq').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === "err") {
          alert('오류가 발생하였습니다');
        } else if (data === "insert_ok") {
          alert("등록되었습니다");
          setRenew(Fn.getRenewTime());
          $('#won_seq').val('');
          $('#s_price').val('');
          $('#e_price').val('');
          $('#c_rate').val('');
          $('#s_rate').val('');
        } else if (data === "insert_err") {
          alert("등록오류! 관리자에게 문의하세요");
          setRenew(Fn.getRenewTime());
        } else if (data === "update_ok") {
          alert("수정되었습니다");
          setRenew(Fn.getRenewTime());
          $('#won_seq').val('');
          $('#s_price').val('');
          $('#e_price').val('');
          $('#c_rate').val('');
          $('#s_rate').val('');
        } else if (data === "update_err") {
          alert("수정오류! 관리자에게 문의하세요");
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function del_data(base_seq) {
    axios2
      .post(`/employee/pt/commission/reg?type=del&del_seq=${base_seq || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          alert("삭제되었습니다.");
          setRenew(Fn.getRenewTime());
          $('won_seq').val('');
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function copy_data_new(won_seq, s_price, e_price, c_rate, s_rate) {
    $('#won_seq').val(won_seq);
    $('#s_price').val(s_price);
    $('#e_price').val(e_price);
    $('#c_rate').val(c_rate);
    $('#s_rate').val(s_rate);
  }

  function go_submit3() {
    if (window.confirm('기본설정으로 저장하시겠습니까?')) {
      console.log('/employee/pt/commission/basic?base_seq=' + $('#base_seq').val());
      axios2
        .post('/employee/pt/commission/basic?base_seq=' + $('#base_seq').val())
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            alert("설정되었습니다");
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="all" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="all"
                 onClick={() => {
                   history.push({
                     pathname: '/employee/reg', state: {
                       baseSeq: baseSeq
                     }
                   });
                 }}
          >기본정보</label>
          <input id="programming" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="programming">기본급/요율표</label>
        </div>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="130px"/>
            <col width="*"/>
            <col width="130px"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="4" className="text-left">
              <div className="d-flex justify-content-between">
                <p><span className="h7 bold700 p-1">기본급</span></p>
                <p>
                  <button type="button" className="btn btn-ssm btn-point" onClick={() => go_submit()}>저장</button>
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <th>퍼블릭기본급</th>
            <td><input type="number" className="form-control table-inline" id="pu_basic_price"/></td>
            <th>영업지원금(PT)</th>
            <td><input type="number" className="form-control table-inline" id="pt_basic_price"/></td>
          </tr>
          <tr>
            <th>시급</th>
            <td><input type="number" className="form-control table-inline" id="pu_hour_price"/></td>
            <th>사업소득세</th>
            <td className="text-left">
              <select className="form-select" aria-label="Default select example" style={{maxWidth: '32%'}} id="saup">
                <option value="">없음</option>
                <option value="A">0.33%</option>
              </select>
            </td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="130px"/>
            <col width="*"/>
            <col width="130px"/>
            <col width="*"/>
            <col width="130px"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="6" className="text-left">
              <div className="d-flex justify-content-between">
                <p><span className="h7 bold700 px-2">요율표</span></p>
                <p>
                  <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => go_submit3()}>기본설정</button>
                  <button type="button" className="btn btn-ssm btn-point" onClick={() => go_submit2()}>저장</button>
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <th>매출액</th>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" aria-describedby="button-addon2" id="s_price"/>
                <p className="mx-2">~</p>
                <input type="text" className="form-control table-inline" aria-describedby="button-addon2" id="e_price"/>
              </div>
            </td>
            <th>커미션 요율</th>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" aria-describedby="button-addon2" id="c_rate"/>
                <p className="mx-2">%</p>
              </div>
            </td>
            <th>수업료 요율</th>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" aria-describedby="button-addon2" id="s_rate"/>
                <p className="mx-2">%</p>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="*"/>
            <col width="25%"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>구간</th>
            <th>매출액</th>
            <th>커미션</th>
            <th>수업료율</th>
            <th>직원% 합계</th>
            <th>비고</th>
          </tr>
          {result?.list?.map((item, index) =>
            <tr>
              <td>{index + 1}</td>
              <td>{Fn.numberWithCommas(item?.s_price)} ~ {Fn.numberWithCommas(item?.e_price)}</td>
              <td>{Fn.numberWithCommas(item?.c_rate)}</td>
              <td>{Fn.numberWithCommas(item?.s_rate)}</td>
              <td>{Fn.numberWithCommas((item?.c_rate || 0) + (item?.s_rate || 0))}</td>
              <td>
                <button
                  className="btn btn-dark btn-ssm"
                  onClick={() =>
                    copy_data_new(
                      item.seq,
                      item.s_price,
                      item.e_price,
                      item.c_rate,
                      item.s_rate
                    )
                  }
                >
                  수정
                </button>
                &nbsp;
                <button
                  className="btn btn-point btn-ssm"
                  onClick={() => {
                    if (window.confirm("해당 요율을 삭제하시겠습니까?"))
                      del_data(item.seq);
                  }}
                >
                  삭제
                </button>
              </td>
            </tr>
          )}
          {(!result?.list || result?.list?.length === 0) &&
            <tr>
              <td colSpan={6}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <input type="hidden" id="base_seq"/>
      <input type="hidden" id="won_seq"/>

      <div className="m-3 text-right">
        <button type="button" className="btn btn-sm btn-dark" onClick={() => history.push("/employee/list")}>목록</button>
      </div>
    </CmnCard>
  );
};

export default EmployeeRegPay;
