import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import {useHistory} from "react-router-dom";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";
import JDatePicker from "../common/JDatePicker";

const EmployeeBookList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  const [srchRenew1, setSrchRenew1] = useState(0);
  const [srchRenew2, setSrchRenew2] = useState(0);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [rets1, setRets1] = useState([]);
  const [rets2, setRets2] = useState([]);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/employee/book/list/ready?keyfield=${$('#keyfield option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const del_data = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 도서를 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/employee/book/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === "err") {
            alert('오류가 발생하였습니다');
          } else {
            alert("삭제되었습니다");
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  useEffect(() => {
    if (modal1Open) {
      $('[id^=m1chk]').prop("checked", false);
      loadModal1();
    }
  }, [srchRenew1]);

  useEffect(() => {
    if (modal2Open) {
      $('[id^=m2chk]').prop("checked", false);
      loadModal2();
    }
  }, [srchRenew2]);

  const loadModal1 = () => {
    axios2
      .post(`/employee/book/end/ready?base_seq=${$('#base_seq').val() || ''}&s_branch_code=${$('#m1_s_branch_code option:selected').val() || ''}&key=${$('#key').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#m1_s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#m1_s_branch_code').html(optionsHTML);
          }

          if (!$('#m1_end_date').val()) $('#m1_end_date').val(Fn.getCurrentDate());

          setRets1(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal2 = () => {
    if (!$('#m2_s_branch_code option:selected').val())
      $('#m2_guide').show();
    else
      $('#m2_guide').hide();

    axios2
      .post(`/employee/book/end/meet/ready?base_seq=${$('#base_seq').val() || ''}&s_branch_code=${$('#m2_s_branch_code option:selected').val() || ''}&key=${$('#key').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#m2_s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#m2_s_branch_code').html(optionsHTML);
          }

          if (!$('#m2_end_date').val()) $('#m2_end_date').val(Fn.getCurrentDate());

          setRets2(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const go_submit1 = () => {
    let con = $('[id^=m1chk]:checked').map(function () {
      return $(this).val();
    }).get().join(",");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if ($('#m1_end_date').val() === '') {
      window.alert('완료일을 입력해 주세요.');
      return;
    }

    const formData = new FormData();

    formData.append("chk[]", con);
    formData.append("end_date", $('#m1_end_date').val());
    formData.append("base_seq", $('#base_seq').val());

    axios2.postFormEx('/employee/book/end', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();
        if (data === 'err') {
          alert('오류가 발생하였습니다');
        } else {
          alert("처리되었습니다");
          setModal1Open(false);
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const go_submit2 = () => {
    let con = $('[id^=m2chk]:checked').map(function () {
      return $(this).val();
    }).get().join(",");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if ($('#m2_end_date').val() === '') {
      window.alert('완료일을 입력해 주세요.');
      return;
    }

    const formData = new FormData();

    formData.append("chk[]", con);
    formData.append("end_date", $('#m2_end_date').val());
    formData.append("base_seq", $('#base_seq').val());

    axios2.postFormEx('/employee/book/end/meet', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();
        if (data === 'err') {
          alert('오류가 발생하였습니다');
        } else {
          alert("처리되었습니다");
          setModal2Open(false);
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item" checked onClick={() => history.push("/employee/book/list")}/>
          <label className="tab_item" htmlFor="aa">도서관리</label>

          <input id="bb" type="radio" name="tab_item" onClick={() => history.push("/employee/book/end/list")}/>
          <label className="tab_item" htmlFor="bb">필독완료</label>

          <input id="cc" type="radio" name="tab_item" onClick={() => history.push("/employee/book/meet/list")}/>
          <label className="tab_item" htmlFor="cc">모임완료</label>
        </div>
      </div>

      <div>
        <div className="d-flex m-3 table_wide_sh fn_search_box">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="keyfield">
              <option value="book_name">도서명</option>
              <option value="author">저자</option>
              <option value="publishing">출판사</option>
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" aria-describedby="button-addon2" id="key"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list overflowX">
          <table className="view-rounded sub_table_rowspan table_wide_sh">
            <colgroup>
              <col width="60px"/>
              <col width="70px"/>
              <col width="10%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="20%"/>
              <col width=""/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="10%"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th rowSpan="2">No</th>
              <th rowSpan="2">
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th rowSpan="2">도서명</th>
              <th rowSpan="2">저자</th>
              <th rowSpan="2">출판사</th>
              <th rowSpan="2">필독서대상직책</th>
              <th colSpan="2">독서모임대상</th>
              <th rowSpan="2">필독대상</th>
              <th rowSpan="2">필독완료</th>
              <th rowSpan="2">독서모임대상</th>
              <th rowSpan="2">독서모임완료</th>
              <th rowSpan="2">비고</th>
              <th rowSpan="2">필독완료</th>
              <th rowSpan="2">모임완료</th>
            </tr>
            <tr>
              <th>본사</th>
              <th>지점</th>
            </tr>

            {rets && rets.map((ret, i) => {
              const position_split = ret?.position?.split("|");
              let p_cnt = 0;
              position_split?.forEach((position, j) => {
                if (position?.length) p_cnt++;
              });

              let prt1 = false;
              if (p_cnt) {
                if (ret?.target > ret?.end_count) prt1 = true;
              }

              let prt2 = false;
              if (ret?.central_meet == "Y" || ret?.branch_meet == "Y") {
                if (ret?.meetCnt > ret?.meet_end_count) prt2 = true;
              }

              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input
                      type="checkbox"
                      // name={`chk[${i + 1}]`}
                      name="chk"
                      value={btoa(ret?.seq)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  </td>
                  <td
                    className="text-left"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      history.push({
                        pathname: '/employee/book/reg', state: {
                          baseSeq: btoa(ret?.seq),
                        }
                      });
                    }}
                  ><strong>{ret?.book_name}</strong></td>
                  <td>{ret?.author}</td>
                  <td>{ret?.publishing}</td>
                  <td className="text-left">{ret?.pName}</td>
                  <td>{ret?.central_meet}</td>
                  <td>{ret?.branch_meet}</td>
                  <td>{ret?.target}</td>
                  <td>{ret?.end_count}</td>
                  <td>{ret?.meetCnt}</td>
                  <td>{ret?.meet_end_count}</td>
                  <td className="text-left">{ret?.bigo}</td>
                  <td>
                    {prt1 && <button type="button" className="btn btn-ssm btn-outline-point px-2" onClick={() => {
                      $('#base_seq').val(btoa(ret?.seq));
                      setModal1Open(true)
                    }}>완료처리</button>}
                  </td>
                  <td>
                    {prt2 && <button type="button" className="btn btn-ssm btn-outline-point px-2" onClick={() => {
                      $('#base_seq').val(btoa(ret?.seq));
                      setModal2Open(true);
                    }}>완료처리</button>}
                  </td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={15}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>

        <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          <div>
            <div className="d-flex">
              <button type="button" className="btn btn-sm btn-outline-point px-3 mx-1" onClick={() => history.push("/employee/book/reg")}>추가</button>
              <button type="button" className="btn btn-sm btn-outline-dark px-3" onClick={() => del_data()}>삭제</button>
            </div>
          </div>
        </nav>
      </div>

      {/*팝업 1*/}

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal1();
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '850px'}}>
            <div className="POP_title">
              <h5>
                도서관리 &gt; <strong>완료처리</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="d-flex mb-3">
                <div className="pr-1">
                  <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="m1_s_branch_code">
                  </select>
                </div>
                <div className="pr-1">
                  <input type="text" className="form-control" aria-describedby="button-addon2" id="m1_key"/>
                </div>

                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew1(Fn.getRenewTime())}>
                    검색
                  </button>
                </div>
              </div>

              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="60px"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>
                    <input type="checkbox" onClick={(e) => {
                      if ($(e.target).prop("checked")) $('[id^=m1chk]').prop("checked", true);
                      else $('[id^=m1chk]').prop("checked", false);
                    }}/>
                  </th>
                  <th>지점명</th>
                  <th>성명</th>
                  <th>부서</th>
                  <th>직책</th>
                </tr>
                {rets1 && rets1.map((item, index) =>
                  <tr>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        name="m1chk"
                        value={item?.seq}
                        className="checkbox"
                        id={`m1chk${index + 1}`}
                      />
                    </td>
                    <td className="text-center">{item?.branch_name}</td>
                    <td className="text-center">{item?.user_name}</td>
                    <td className="text-center">{item?.part_name || ''}</td>
                    <td className="text-center">{item?.new_position_name || ''}</td>
                  </tr>
                )}
              </table>
            </div>
            <div className="d-flex justify-content-center my-3 pt-2">
              <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>
                완료일 :
              </label>
              <div className="input-group" style={{width: 'auto'}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="m1_end_date"/>
                <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => go_submit1()}>
                  완료처리
                </button>
              </div>

              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal1Open(false)}>
                취소
              </button>
            </div>
          </div>
        </div>
      </Modal>

      {/*팝업 2*/}

      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal2();
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '850px'}}>
            <div className="POP_title">
              <h5>도서관리 > <strong>모임 완료처리</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="d-flex mb-3">
                <div className="pr-1">
                  <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="m2_s_branch_code">
                  </select>
                </div>
                <div className="pr-1">
                  <input type="text" className="form-control" aria-describedby="button-addon2" id="m2_key"/>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew2(Fn.getRenewTime())}>검색</button>
                </div>
              </div>

              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="60px"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>
                    <input type="checkbox" onClick={(e) => {
                      if ($(e.target).prop("checked")) $('[id^=m2chk]').prop("checked", true);
                      else $('[id^=m2chk]').prop("checked", false);
                    }}/>
                  </th>
                  <th>지점명</th>
                  <th>성명</th>
                  <th>부서</th>
                  <th>직책</th>
                </tr>
                {rets2 && rets2.map((item, index) =>
                  <tr>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        name="m2chk"
                        value={item?.seq}
                        className="checkbox"
                        id={`m2chk${index + 1}`}
                      />
                    </td>
                    <td className="text-center">{item?.branch_name}</td>
                    <td className="text-center">{item?.user_name}</td>
                    <td className="text-center">{item?.part_name || ''}</td>
                    <td className="text-center">{item?.new_position_name || ''}</td>
                  </tr>
                )}
                {(!rets2 || rets2?.length === 0) &&
                  <tr id="m2_guide">
                    <td colSpan="6" className="text-center">※ 지점을 검색해 주세요.</td>
                  </tr>
                }
              </table>
            </div>

            <div className="d-flex justify-content-center my-3 pt-2">
              <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>완료일 : </label>
              <div className="input-group" style={{width: 'auto'}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="m2_end_date"/>
                <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => go_submit2()}>완료처리</button>
              </div>
              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal2Open(false)}>취소</button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="base_seq"/>

    </CmnCard>
  );
};

export default EmployeeBookList;
