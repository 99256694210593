import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";
import ChkAuthNum from "../common/ChkAuthNum";
import Constants from "../../constants/constants";

const ConfigApplicant = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal2SrchRenew, setModal2SrchRenew] = useState(0);
  const [modal2Result, setModal2Result] = useState({});
  const [modal2CurrPage, setModal2CurrPage] = useState(1);
  const [modal2PageInfo, setModal2PageInfo] = useState({});

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  useEffect(() => {
    if (!modal2SrchRenew) return;
    loadModal2(1);
  }, [modal2SrchRenew]);

  useEffect(() => {
    if (!modal2Open) return;
    loadModal2();
  }, [modal2CurrPage]);

  const load = (_page) => {
    axios2
      .post(`/config/applicant/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_applicant_name=${$('#s_applicant_name').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal2 = (_page) => {
    axios2
      .post(`/config/applicant/detail/ready?base_seq=${$('#base_seq').val()}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_applicant_no=${$('#s_applicant_no').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_hp=${$('#s_mb_hp').val()}&s_applicant_state=${$('#s_applicant_state option:selected').val()}&page=${_page || modal2CurrPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setModal2Result(result);
          setModal2PageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const del_data = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 응모권 생성내역을 삭제 하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/config/applocant/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  //  4-1.
  const down_excel = (make_key, make_month) => {
    const authCode = document.getElementById('auth_code').value;
    if (!authCode) {
      alert('인증코드를 입력해주세요');
      return;
    }

    const downReason = document.getElementById('down_reason').value;
    if (!downReason) {
      alert('사유를 입력해주세요');
      return;
    }

    const downloadUrl = `Excel_gift_detail.php?make_key=${make_key}&make_month=${make_month}&auth_code=${authCode}&down_reason=${downReason}`;
    window.location.href = downloadUrl;
  };

  const go_submit = () => {
    if (!$('#start_date').val()) {
      alert('시작일을 입력해주세요.');
      return;
    }
    if (!$('#end_date').val()) {
      alert('종료일을 입력해주세요.');
      return;
    }
    if (!$('#applicant_name').val()) {
      alert('응모권명을 입력해주세요.');
      return;
    }
    if ($('#applicant_price').val() === '' || $('#applicant_price').val() === '0') {
      alert('발급기준금액을 입력해주세요.');
      return;
    }

    let msg = "응모권을 생성하시겠습니까?";
    if ($('#base_seq').val()) msg = "응모권을 수정하시겠습니까?";

    axios2.postEx("/config/applicant/make", {
      base_seq: $('#base_seq').val(),
      start_date: $('#start_date').val(),
      end_date: $('#end_date').val(),
      applicant_name: $('#applicant_name').val(),
      applicant_price: $('#applicant_price').val(),
      use_YN: $('#use_YN option:selected').val() || '',
    })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (data == "wrong_date") {
          alert("시작일/종료일 설정 오류입니다");
        } else if (data == "dup_period") {
          alert("시작일/종료일 설정 오류입니다");
        } else if (data == "in_ok") {
          alert('생성되었습니다');
          setRenew(Fn.getRenewTime());
          setModal1Open(false);
        } else if (data == "in_err") {
          alert('등록오류! 관리자에게 문의하세요');
        } else if (data == "up_ok") {
          alert("수정되었습니다.");
          setRenew(Fn.getRenewTime());
          setModal1Open(false);
        } else if (data == "up_err") {
          alert('수정오류! 관리자에게 문의하세요');
        }
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      })
      .finally(() => {
      });
  }

  const loadModal1 = () => {
    axios2
      .post(`/config/applicant/make/ready?base_seq=${$('#base_seq').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $('#start_date').val(result?.result?.start_date);
          $('#end_date').val(result?.result?.end_date);
          $('#applicant_name').val(result?.result?.applicant_name);
          $('#applicant_price').val(result?.result?.applicant_price);
          $('#use_YN').val(result?.result?.use_YN);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function Excel_download(base_seq) {
    var auth_code = $(".modal2 #auth_code").val();
    if(!auth_code)
    {
      alert("인증코드를 입력해주세요");
      return;
    }

    var down_reason = $(".modal2 #down_reason").val();
    if(!down_reason)
    {
      alert("사유를 입력해주세요");
      return;
    }

    const url = `/xls/config/applicant/member?base_seq=${$('#base_seq').val()}&auth_code=${auth_code}&down_reason=${encodeURIComponent(down_reason)}`;
    Fn.download(Constants.API_URL + url);
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <input type="hidden" id="base_seq"/>
      <div className="d-flex m-3 fn_search_box" style={{maxWidth: "935px"}}>
        <div className="input-group pr-1" style={{width: 'auto'}}>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
          <p className="p-1">~</p>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" placeholder="응모권명" id="s_applicant_name"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => {
            $('#base_seq').val('');
            setModal1Open(true)
          }}>응모권생성
          </button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list" style={{maxWidth: "935px"}}>
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="60px"/>
            <col width="85px"/>
            <col width="17%"/>
            <col width="*"/>
            <col width="100px"/>
            <col width="90px"/>
            <col width="130px"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            <th>생성일</th>
            <th>응모발급기간</th>
            <th>응모권명</th>
            <th>기준금액</th>
            <th>상태</th>
            <th>엑셀저장</th>
          </tr>

          {rets.map((row, i) => {
            const count = i + 1;
            const make_date = row.make_date;
            const start_date = row.start_date;
            const end_date = row.end_date;
            const applicant_name = row.applicant_name;
            const applicant_price = row.applicant_price;
            const use_YN = row.use_YN;
            const seq = row.seq;

            let use_YN_txt;
            if (use_YN === "Y") {
              use_YN_txt = <strong><font color="blue">진행중</font></strong>;
            } else {
              use_YN_txt = <strong><font color="red">완료</font></strong>;
            }

            const base_seq = btoa(seq);

            return (
              // <tr style={{background: `${bgclr}`}} key={i}>
              <tr key={i}>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  <input
                    type="checkbox"
                    name={`chk[${count}]`}
                    value={base_seq}
                    id={`achk${count}`}
                    className="checkbox"
                  />
                  <label htmlFor={`achk${count}`}><span></span></label>
                </td>
                <td>
                  <a
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      $('#base_seq').val(base_seq);
                      setModal1Open(true);
                    }}
                  >
                    <strong>{make_date?.substring(2, 10)?.replace(/-/g, ".")}</strong>
                  </a>
                </td>
                <td>
                  {`${start_date.substring(2, 10).replace(/-/g, ".")} ~ ${end_date.substring(2, 10).replace(/-/g, ".")}`}
                </td>
                <td className="text-left">{applicant_name}</td>
                <td>
                  <div align="right" style={{paddingRight: '5px'}}>
                    {Fn.numberWithCommas(applicant_price)}
                  </div>
                </td>
                <td>{use_YN_txt}</td>
                <td>
                  <input
                    type="button"
                    className="btn btn-ssm btn-outline-point"
                    value="발행/상세확인"
                    // onClick={() => pop_up(`applicant_detail.html?base_seq=${base_seq}`, 'applicant_detail', '1020', '600', 'yes')}
                    onClick={() => {
                      $('#base_seq').val(base_seq);
                      setModal2Open(true);
                    }}
                  />
                </td>
              </tr>
            );
          })}
        </table>
      </div>

      <nav className="m-3 p-3 mb-4 d-flex justify-content-between border-top flex-wrap" style={{maxWidth: "935px"}}>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => del_data()}>삭제</button>
        </div>
      </nav>

      {/*팝업 1*/}

      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal2();
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background modal2" style={{zIndex: 1000000000}}>
          <div className="pop_box01_wide p_absolute_mid_pop">
            <div className="POP_title">
              <h5><strong>응모권 상세내역</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box w-100">
              <div className="d-flex">
                <div className="input-group pr-1" style={{width: 'auto'}}>
                  <p className="p-1">발급일 : </p>
                  <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
                  <p className="p-1">~</p>
                  <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
                </div>
                <div className="pr-1">
                  <input type="text" className="form-control width_shorts" placeholder="응모번호" id="s_applicant_no" style={{maxWidth: "140px"}}/>
                </div>
                <div className="pr-1">
                  <input type="text" className="form-control width_shorts" placeholder="회원명" id="s_mb_name" style={{maxWidth: "140px"}}/>
                </div>
                <div className="pr-1">
                  <input type="text" className="form-control width_shorts" placeholder="연락처" id="s_mb_hp" style={{maxWidth: "140px"}}/>
                </div>
                <div className="pr-1">
                  <select className="form-select" aria-label="Default select example" id="s_applicant_state">
                    <option value="">상태</option>
                    <option value="Y">정상</option>
                    <option value="C">취소</option>
                    <option value="1y">1차당첨</option>
                    <option value="2y">2차당첨</option>
                    <option value="3y">3차당첨</option>
                  </select>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setModal2SrchRenew(Fn.getRenewTime())}>검색</button>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => Excel_download($('#base_seq').val())}>엑셀저장</button>
                </div>
              </div>
              <div className="text-center mt-3">
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="60px"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>No</th>
                    <th>발급일</th>
                    <th>응모권번호</th>
                    <th>지점명</th>
                    <th>회원번호</th>
                    <th>회원명</th>
                    <th>연락처</th>
                    <th>상태</th>
                  </tr>
                  {modal2Result?.result?.map((row, index) => {
                    let applicantStateTxt = row?.ok_yes === "1" ?
                      <font color='blue'>1차당첨</font> :
                      (row.applicant_state === "Y" ? "정상" : <font color='red'>취소</font>);

                    return (
                      <tr key={index}>
                        <td>{modal2PageInfo.totalArticles - modal2PageInfo.articlesPerPage * (modal2PageInfo.currentPage - 1) - index}</td>
                        <td>{row.applicant_date}</td>
                        <td>{row.applicant_no}</td>
                        <td>{row.branch_name}</td>
                        <td>{row.mb_id}</td>
                        <td>{row.mb_name}</td>
                        <td>
                          <div id={`default_show${index + 1}`}>***-****-****</div>
                        </td>
                        <td>{applicantStateTxt}</td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>


            <nav className="m-3 p-3 mb-4 d-flex justify-content-between border-top flex-wrap w-100">
              <Paging pageInfo={modal2PageInfo} setCurrPage={page => setModal2CurrPage(page)}/>
              <div className="d-flex justify-content-end">
                <div className="d-flex mx-1">
                  <ChkAuthNum type={"locker_info_change"}/>
                </div>
                <div className="d-flex mx-1">
                  <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="다운로드 사유 입력" style={{width: '150px'}} id="down_reason"/>
                </div>
                {pageInfo?.totalArticles > 0 && (
                  (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                    <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('applicant')}>연락처 확인</button>
                  )
                )}
              </div>
            </nav>
          </div>
        </div>
      </Modal>

      {/*팝업 2*/}

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          if ($('#base_seq').val())
            loadModal1();
          else {
            $('#base_seq').val('');
            $('#start_date').val('');
            $('#end_date').val('');
            $('#applicant_name').val('');
            $('#applicant_price').val('');
            $('#use_YN').val('Y');
          }
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop">
            <div className="POP_title">
              <h5><strong>응모권 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>

                <tr>
                  <th>시작일</th>
                  <th>종료일</th>
                  <th>응모권명</th>
                  <th>발급기준금액</th>
                  <th>상태</th>
                </tr>
                <tr>
                  <td><JDatePicker className="form-control" aria-describedby="button-addon2" style={{margin: 'auto'}} id="start_date"/></td>
                  <td><JDatePicker className="form-control" aria-describedby="button-addon2" style={{margin: 'auto'}} id="end_date"/></td>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" id="applicant_name"/></td>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" id="applicant_price"/></td>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="use_YN">
                      <option value="Y">진행중</option>
                      <option value="N">완료</option>
                    </select>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>생성</button>
              <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => setModal1Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default ConfigApplicant;
