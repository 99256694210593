import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import DaumPostcode from "react-daum-postcode";
import APIHelper from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";

const {kakao, daum} = window;

const PostCode = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    var geocoder = new daum.maps.services.Geocoder();

// 주소로 좌표를 검색합니다
    geocoder.addressSearch(data.roadAddress, function (result, status) {
      try {
        document.getElementById("coord_x").value = result[0].x;
        document.getElementById("coord_y").value = result[0].y;
      } catch (err) {
      }
    });

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    if (props?.onCallback)
      props.onCallback(fullAddress, (data?.zonecode || ''), (data.postcode1 || ''), (data.postcode2 || '')); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    if (props?.onCallback2)
      props.onCallback2(data.address, extraAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    onToggleModal(); // 주소창은 자동으로 사라지므로 모달만 꺼주면
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '600px'}}>
          <div className="POP_title">
            <h5><strong>주소 검색</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>
          <div className="POP_cnt_box">
            <div className="mb-3 d-flex flex-nowrap">
              <DaumPostcode onComplete={handleComplete}/>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PostCode;
