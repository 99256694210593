import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import _ from "lodash";
import Fn from "../../libraries/Fn";
import Constants from "../../constants/constants";

// 스크립트 없음

const SalesStockStateDetail = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [topRow, setTopRow] = useState([]);
  const [saleInfoRow, setSaleInfoRow] = useState({});
  const [salesQty, setSalesQty] = useState();
  const [totS, setTotS] = useState([]);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
    $('#s_month').val(Fn.getCurrentDate().split("-")[1]);
  }, []);

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/sales/stock/state/detail/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setTopRow(result?.topRow);
          setSaleInfoRow(result?.saleInfoRow);

          const salesQty = {};
          result?.saleInfoRow.forEach((_saleInfoRow) => {
            const pCodes = _saleInfoRow.p_code;
            const inoutDate = _saleInfoRow.inout_date;

            if (!salesQty[pCodes]) salesQty[pCodes] = {};

            salesQty[pCodes][inoutDate] = Math.abs(_saleInfoRow.qty);
          });
          const _salesQty = _.clone(salesQty);
          setSalesQty(salesQty);

          {
            let tot_s = [];
            Array.from({length: topRow.length}, (_, j) => {
              const my_p_code = topRow?.[j]?.p_code;
              Array.from({length: 31}, (_, i) => {
                const k = (i + 1) < 10 ? "0" + (i + 1) : (i + 1).toString();
                if (!tot_s[i + 1]) tot_s[i + 1] = 0;
                tot_s[i + 1] += (_salesQty?.[my_p_code]?.[k] || 0);
              });
            });
            setTotS(tot_s);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const Excel_download = () => {
    const url = `/xls/sales/stock/state/detail?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`;
    Fn.download(Constants.API_URL + url);
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_year">
              {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_month">
              {[...Array(12)].map((_, index) => {
                const monthNumber = index + 1;
                const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>검색</button>
            <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => Excel_download()}>엑셀저장</button>
          </div>
        </div>

        <div className="m-3 table_list">
          {/* 회원목록 */}
          <div className="d-flex p_rative">
            <div className="table_overlap1" style={{zIndex: 1000,}}>
              <table className="rounded-corner-l sub_table_border space_nowrap" style={{width: "auto"}}>
                <colgroup>
                  <col width="60px"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>

                <tr style={{height: "39.67px"}}>
                  <th>No</th>
                  <th>품목분류</th>
                  <th>품목구분</th>
                  <th>품명</th>
                  <th>이전</th>
                  <th>입고</th>
                  <th>재고조정</th>
                  <th>판매</th>
                  <th>현재</th>
                </tr>
                {topRow && topRow.map((ret, i) => {
                  return (
                    <tr style={{height: "39.67px"}}>
                      <td>{i + 1}</td>
                      <td>{ret?.position_name}</td>
                      <td>{ret?.pum_name}</td>
                      <td className="text-left">{ret?.p_name}</td>
                      <td className="text-right">{Fn.numberWithCommas(ret?.before_stock)}</td>
                      <td className="text-right">{Fn.numberWithCommas(ret?.in_stock)}</td>
                      <td className="text-right">{Fn.numberWithCommas(ret?.change_stock)}</td>
                      <td className="text-right">{Fn.numberWithCommas(ret?.sales_stock)}</td>
                      <td className="text-right">{Fn.numberWithCommas(ret?.now_stock)}</td>
                    </tr>
                  );
                })}
                <tr className="bold700 bgcolor" style={{height: "39.67px"}}>
                  <td colSpan="4">합계</td>
                  <td className="text-right">{Fn.numberWithCommas(topRow?.reduce((n, {before_stock}) => n + before_stock, 0))}</td>
                  <td className="text-right">{Fn.numberWithCommas(topRow?.reduce((n, {in_stock}) => n + in_stock, 0))}</td>
                  <td className="text-right">{Fn.numberWithCommas(topRow?.reduce((n, {change_stock}) => n + change_stock, 0))}</td>
                  <td className="text-right">{Fn.numberWithCommas(topRow?.reduce((n, {sales_stock}) => n + sales_stock, 0))}</td>
                  <td className="text-right">{Fn.numberWithCommas(topRow?.reduce((n, {now_stock}) => n + now_stock, 0))}</td>
                </tr>
              </table>
            </div>

            <div className="table_overlap2 ml-2">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  {Array.from({length: 31}, (_, i) => (
                    <col width="*"/>
                  ))}
                </colgroup>
                <thead>
                <tr style={{height: "39.67px"}}>
                  {Array.from({length: 31}, (_, i) => (
                    <th width="30" key={i + 1}>
                      {i + 1}
                    </th>
                  ))}
                </tr>
                </thead>
                <tbody>
                {Array.from({length: topRow.length}, (_, j) => {
                  const my_p_code = topRow?.[j]?.p_code;
                  return (
                    <tr key={j + 1} style={{height: "39.67px"}}>
                      {Array.from({length: 31}, (_, i) => {
                        const k = i + 1 < 10 ? "0" + (i + 1) : String(i + 1);

                        return (
                          <td key={i + 1} style={{height: "30px"}}>
                            <div align="center">
                              <strong>{salesQty?.[my_p_code]?.[k]}</strong>
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                <tr style={{height: "39.67px"}}>
                  {Array.from({length: 31}, (_, i) => (
                    <td style={{background: "#feffdb", height: "30px"}} key={i + 1}>
                      <div align="center">
                        <strong>{totS?.[i + 1]}</strong>
                      </div>
                    </td>
                  ))}
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </CmnCard>
  );
};

export default SalesStockStateDetail;
