import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory, useLocation} from "react-router-dom";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import userImage from "../../images/thum_user.png";
import ChkAuthNum from "../common/ChkAuthNum";
import Fn from "../../libraries/Fn";

const BranchopBoardView = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.base_seq || '';

  const [result, setResult] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/branchop/board/read/ready?base_seq=${baseSeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const escapedNewLineToLineBreakTag = (string) => {
    return string.split('\n').map((item, index) => {
      return (index === 0) ? item : [<br key={index}/>, item]
    })
  }

  window.go_reg_comment = () => {
    var auth_code = $("#comment_auth_code").val();

    if (!auth_code) {
      $("#comment_search_auth_code").focus();
      alert("인증코드를 입력해주세요");
      return;
    }

    if (!$("#rep_comment").val()) {
      alert("내용을 입력해주세요");
      return;
    }

    var content = $("#rep_comment").val()

    $.ajax({
      type: "post",
      url: "/branchop/board/rep/input",
      data: `base_seq=${baseSeq}&auth_code=` + auth_code + "&content=" + content,
      success: function (data) {
        data = data?.message?.trim();

        if (data == "auth_code_err") {
          alert('잘못된 인증번호 입니다');
        } else if (data == "ok") {
          alert("등록되었습니다");
          $("#rep_comment").val('');
          setRenew(Fn.getRenewTime());
        }
      }
    });
  }

  window.go_edit_comment = (rep_seq) => {
    var auth_code = $("#comment_auth_code").val();

    if (!auth_code) {
      $("#comment_search_auth_code").focus();
      alert("인증코드를 입력해주세요");
      return;
    }

    if (!$("#rep_comment").val()) {
      alert("내용을 입력해주세요");
      return;
    }

    var content = $("#rep_comment").val()

    console.log(`base_seq=${baseSeq}&auth_code=` + auth_code + "&rep_seq=" + rep_seq + "&content=" + content);

    $.ajax({
      type: "post",
      url: "/branchop/board/rep/edit",
      data: `base_seq=${baseSeq}&auth_code=` + auth_code + "&rep_seq=" + rep_seq + "&content=" + content,
      success: function (data) {
        data = data?.message?.trim();

        if (data == "auth_code_err") {
          alert('잘못된 인증번호 입니다');
        } else if (data == "wrong_auth") {
          alert('등록한 본인만 수정가능합니다');
        } else if (data == "ok") {
          alert("수정되었습니다");
          $("#rep_comment").val('');
          $("#rep_input").html(`<button type="button" class="btn btn-point pt-3 pb-3" onClick="window.go_reg_comment()">댓글입력</button>`);
          setRenew(Fn.getRenewTime());
        }
      }
    });
  }

  function edit_rep(rep_seq) {
    var auth_code = $("#comment_auth_code").val();

    if (!auth_code) {
      $("#comment_search_auth_code").focus();
      alert("인증코드를 입력해주세요");
      return;
    }

    $.ajax({
      type: "post",
      url: "/branchop/chk/board/rep",
      data: "auth_code=" + auth_code + "&rep_seq=" + rep_seq,
      success: function (data) {
        console.log(data);
        data = data?.message?.trim();

        if (data == "auth_code_err") {
          alert('잘못된 인증번호 입니다');
        } else if (data == "manager_err") {
          alert('등록한 본인만 수정가능합니다');
        } else if (data == "ok") {
          var won_data = $("#rep_content" + rep_seq).text();
          won_data = won_data.trim();
          $("#rep_comment").val(won_data);
          $("#rep_input").html(`<button type="button" class="btn btn-point pt-3 pb-3" onclick="window.go_edit_comment(` + rep_seq + `)">댓글수정</button>`);
        }
      }
    });
  }

  function rep_del(rep_seq) {
    var auth_code = $("#comment_auth_code").val();

    if (!auth_code) {
      $("#comment_search_auth_code").focus();
      alert("인증코드를 입력해주세요");
      return;
    }

    if (window.confirm("삭제하시겠습니까?")) {
      $.ajax({
        type: "post",
        url: "/branchop/board/rep/del",
        data: `base_seq=${baseSeq}&auth_code=` + auth_code + "&seq=" + rep_seq,
        success: function (data) {
          data = data?.message?.trim();

          if (data == "auth_code_err") {
            alert('잘못된 인증번호 입니다');
          } else if (data == "wrong_auth") {
            alert('등록한 본인만 삭제가능합니다');
          } else if (data == "ok") {
            alert("삭제되었습니다");
            setRenew(Fn.getRenewTime());
          }
        }
      });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 pb-2">
        <table className="view-rounded sub_view text-left">
          <colgroup>
            <col width="120px"/>
            <col width="*"/>
            <col width="120px"/>
            <col width="*"/>
            <col width="120px"/>
            <col width="*"/>
            <col width="120px"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>지점명</th>
            <td>{result?.row?.real_branch_name}</td>
            <th>등록자</th>
            <td>{result?.row?.writer_name}</td>
            <th>등록일</th>
            <td>{result?.row?.reg_date?.split(" ")?.[0]}</td>
            <th>조회수</th>
            <td>{result?.row?.readnum}</td>
          </tr>
          <tr>
            <th>제목</th>
            <td colSpan="7">{result?.row?.title}</td>
          </tr>
          <tr>
            <th>내용</th>
            <td colSpan="7" className="p-4">
              <div style={{minHeight: '200px'}} dangerouslySetInnerHTML={{__html: result?.row?.content}}/>
            </td>
          </tr>
        </table>
      </div>
      <div className="text-center my-4">
        <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.goBack()}>목록</button>
      </div>

      <div className="m-3 pb-2">
        <table className="view-rounded sub_view">
          <tbody>
          <tr>
            <th style={{width: '120px'}}>인증코드</th>
            <td className="p-3 text-left">
              <div className="d-flex">
                <ChkAuthNum type={"locker_info_change"} prefix="comment_"/>
              </div>
            </td>
          </tr>
          <tr>
            <th style={{width: '120px'}}>댓글입력</th>
            <td className="p-3 text-left">
              <div className="d-flex">
                <textarea className="form-control" style={{width: 'calc(100% - 100px)'}} id="rep_comment"/>
                <span id="rep_input"><button type="button" className="btn btn-point pt-3 pb-3" onClick={() => window.go_reg_comment()}>댓글입력</button></span>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      {result?.repList?.length > 0 &&
        <div className="m-3 pb-2">
          <div className="table_box">
            {result?.repList?.map((item, index) =>
              <div key={index} className="reply">
                <p className="USER_thum_sm2">
                </p>
                <div className="text-left px-3" style={{flex: 2}}>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex" style={{justifyContent: "flex-end", alignItems: "flex-end", alignContent: "flex-end"}}>
                      <p className="bold700 h7 pr-2">{item?.manager_name}</p>
                      <p className="text-left text-gray h9 bold700">{item?.reg_date?.substring(2, 16)?.replace(/-/g, ".")}</p>
                    </div>
                    <p className="float-right">
                      <button type="button" className="btn btn-ssm btn-outline-point mx-1" onClick={() => edit_rep(item?.seq)}>수정</button>
                      <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => rep_del(item?.seq)}>삭제</button>
                    </p>
                    <div className="re_comment_txt" style={{display: "none"}}>
                      <span id={`rep_content${item?.seq}`}>{item?.content}</span>
                    </div>
                  </div>

                  <p className="py-2">{escapedNewLineToLineBreakTag(item?.content)}</p>
                </div>
              </div>
            )};
          </div>
        </div>
      }

    </CmnCard>
  );
};

export default BranchopBoardView;
