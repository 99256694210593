import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";


const ConfigOption = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/config/option/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.row);

          $('#ot_cnt').val(result?.row?.ot_cnt || '');
          $('#free_add1').val(result?.row?.free_add1 || '');
          $('#free_add2').val(result?.row?.free_add2 || '');
          $('#free_add3').val(result?.row?.free_add3 || '');
          $('#free_add4').val(result?.row?.free_add4 || '');
          $('#free_add5').val(result?.row?.free_add5 || '');
          $('#free_add6').val(result?.row?.free_add6 || '');
          $('#locker_push').val(result?.row?.locker_push || '');
          $('#gx_reserve').val(result?.row?.gx_reserve || '');
          $('#pt_cancel').val(result?.row?.pt_cancel || '');
          $('#etc_branch_in').val(result?.row?.etc_branch_in || '');
          $('#not_oper_time').val(result?.row?.not_oper_time || '');
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const go_submit = () => {
    axios2
      .postEx('/config/option', {
        ot_cnt: $('#ot_cnt').val(),
        free_add1: $('#free_add1').val(),
        free_add2: $('#free_add2').val(),
        free_add3: $('#free_add3').val(),
        free_add4: $('#free_add4').val(),
        free_add5: $('#free_add5').val(),
        free_add6: $('#free_add6').val(),
        locker_push: $('#locker_push').val(),
        gx_reserve: $('#gx_reserve').val(),
        pt_cancel: $('#pt_cancel').val(),
        etc_branch_in: $('#etc_branch_in').val(),
        not_oper_time: $('#not_oper_time').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === 'err') {
          alert('오류가 발생하였습니다');
        } else if (data === 'insert_ok') {
          alert('저장되었습니다.');
          setRenew(Fn.getRenewTime());
        } else if (data === 'insert_err') {
          alert('등록에러! 관리자에게 문의하세요.');
          // window.location.href = 'option.html';
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 pb-2" style={{maxWidth: "935px"}}>
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="25%"/>
            <col width="*"/>
            <col width="25%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>OT 횟수</th>
            <td colSpan="3" className="text-left">
              <div className="input-group">
                <input type="text" className="input_shorts table-inline" id="ot_cnt"/>
                <p className="p-1">회</p>
              </div>
            </td>
          </tr>
          <tr>
            <th>추천소개 무료일수</th>
            <td colSpan="3" className="text-left">
              <div className="inline_tbl">
                <dl>
                  <dt className="wide">1개월이상 3개월 미만</dt>
                  <dd>
                    <div className="input-group">
                      <input type="text" className="input_shorts table-inline" id="free_add1"/>
                      <p className="p-1">일</p>
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt className="wide">3개월이상 5개월 미만</dt>
                  <dd>
                    <div className="input-group">
                      <input type="text" className="input_shorts table-inline" id="free_add2"/>
                      <p className="p-1">일</p>
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt className="wide">5개월이상 6개월 미만</dt>
                  <dd>
                    <div className="input-group">
                      <input type="text" className="input_shorts table-inline" id="free_add3"/>
                      <p className="p-1">일</p>
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt className="wide">6개월이상 12개월 미만</dt>
                  <dd>
                    <div className="input-group">
                      <input type="text" className="input_shorts table-inline" id="free_add4"/>
                      <p className="p-1">일</p>
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt className="wide">12개월이상 18개월 미만</dt>
                  <dd>
                    <div className="input-group">
                      <input type="text" className="input_shorts table-inline" id="free_add5"/>
                      <p className="p-1">일</p>
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt className="wide">18개월 이상</dt>
                  <dd>
                    <div className="input-group">
                      <input type="text" className="input_shorts table-inline" id="free_add6"/>
                      <p className="p-1">일</p>
                    </div>
                  </dd>
                </dl>
              </div>
            </td>
          </tr>
          <tr>
            <th>락커 만료 전 PUSH</th>
            <td className="text-left">
              <div className="input-group">
                <input type="text" className="input_shorts table-inline" id="locker_push"/>
                <p className="p-1">일</p>
              </div>
            </td>
            <th>GX 예약 가능 시간</th>
            <td>
              <div className="input-group">
                <p className="p-1">수업시작</p>
                <input type="text" className="input_shorts table-inline" id="gx_reserve"/>
                <p className="p-1">분 전부터</p>
              </div>
            </td>
          </tr>
          <tr>
            <th>PT · OT 사용자<br/>취소 가능 기간 설정</th>
            <td className="text-left">
              <div className="input-group">
                <input type="text" className="input_shorts table-inline" id="pt_cancel"/>
                <p className="p-1">시간 이내</p>
              </div>
            </td>
            <th>타지점 이용횟수</th>
            <td>
              <div className="input-group">
                <input type="text" className="input_shorts table-inline" id="etc_branch_in"/>
                <p className="p-1">회</p>
              </div>
            </td>
          </tr>
          <tr>
            <th>비정상운영 리스트업 입장<br/>미체크 시간</th>
            <td className="text-left" colSpan={3}>
              <div className="input-group">
                <input type="text" className="input_shorts table-inline" id="not_oper_time"/>
                <p className="p-1">시간</p>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div className="my-4 text-center" style={{maxWidth: "935px"}}>
        <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
      </div>
    </CmnCard>
  );
};

export default ConfigOption;
