import axios from "axios";
import axiosRetry from "axios-retry";

import Constants from "../constants/constants";
import FormData from "form-data";
import * as qs from "qs";
import Fn from "../libraries/Fn";
import $ from 'jquery';
import {useHistory} from "react-router-dom";
import _ from "lodash";

window.disableErrorMsg = false;

const parseErrorCode = async (error) => {
  try {
    if (error?.toString()?.toLowerCase()?.includes("network error")) {
      alert("일시적인 네트워크 상태의 문제 또는 서버 문제로 서버에 접속이 불가능합니다. 잠시 후 다시 시도해 주세요.\n\nERROR CODE : NETWORK ERROR");
      return Promise.resolve(error.response);
    }

    if (error?.response?.status === 401) {
      alert("접근 권한이 없습니다.\nERROR CODE : 401");

      localStorage.removeItem("USER.systemID");
      localStorage.removeItem("USER.systemName");
      localStorage.removeItem("USER.systemBranchName");
      localStorage.removeItem("USER.systemManageYN");
      localStorage.removeItem("USER.systemUserAuth");
      localStorage.removeItem("USER.systemAccountYN");
      localStorage.removeItem("USER.systemBranchCode");
      localStorage.removeItem("USER.systemBrand");
      localStorage.removeItem("USER.token");
      localStorage.removeItem("isUserLoggedIn");

      sessionStorage.removeItem("USER.systemID");
      sessionStorage.removeItem("USER.systemName");
      sessionStorage.removeItem("USER.systemBranchName");
      sessionStorage.removeItem("USER.systemManageYN");
      sessionStorage.removeItem("USER.systemUserAuth");
      sessionStorage.removeItem("USER.systemAccountYN");
      sessionStorage.removeItem("USER.systemBranchCode");
      sessionStorage.removeItem("USER.systemBrand");
      sessionStorage.removeItem("USER.token");
      sessionStorage.removeItem("isUserLoggedIn");

      window.location.href = '/login';
      // const response = _.clone(error.response);
      // response.status = 200;
      // return Promise.reject(response);
    } else if (error?.response?.status === 403) {
      alert("접근 권한이 없습니다.");
      throw new axios.Cancel('Operation canceled by the user.');
    } else if (error?.response?.status === 404) {
      alert("일시적인 네트워크 상태의 문제 또는 서버 문제로 서버에 접속이 불가능합니다. 잠시 후 다시 시도해 주세요.\n\nERROR CODE : NETWORK ERROR");
      throw new axios.Cancel('Operation canceled by the user.');
    } else if (error?.response?.status === 500) {
      alert('데이터오류! 시스템 관리자에게 문의하세요\nERROR CODE : 서버 오류 [500]');
      throw new axios.Cancel('Operation canceled by the user.');
    } else {
      try {
        const {message} = error?.response?.data;
        alert("데이터오류! 시스템 관리자에게 문의하세요\nERROR CODE : UNKNOWN : " + (message || ''));
        throw new axios.Cancel('System error.');
      } catch (err) {
        alert("데이터오류! 시스템 관리자에게 문의하세요\nERROR CODE : UNKNOWN");
        throw new axios.Cancel('System error.');
      }
    }
  } finally {
    if (window.disableErrorMsg) {
      clearTimeout(window.aixosTO);
      window.aixosTO = setTimeout(() => window.disableErrorMsg = false, 2000);
    }
  }

  return Promise.resolve(error.response);
};

const axios2 = axios.create();

axiosRetry(axios2, {
  retries: 3,
  shouldResetTimeout: true,
  retryCondition: (_error) => false,
});

axios2.interceptors.request.use(
  async (config) => {

    const controller = new AbortController();

    if (window.cancelAxios) {
      controller.abort();
    } else {
      window.currentJobStatus = "init";
      if (!config.url.startsWith("/finance/change/state")) {
        if (config.url === "/enter/enter/monitor/ready")
          Fn.showPopSpinner();
        else
          Fn.showSpinner();
      }

      config.baseURL = Constants.API_URL;
      if (config.url.startsWith("/")) config.baseURL = Constants.API_HOST;

      const token = (localStorage.getItem("USER.token") || null);
      const headers = token ? {Authorization: "Bearer " + token, CurrentUri: window.location.pathname} : {CurrentUri: window.location.pathname};
      if (headers) {
        config.headers = {...config.headers, ...headers};
      }
    }

    return {
      ...config,
      signal: controller.signal
    };
  },
  (error) => {
    console.log(error);
  }
);

// Response parsing interceptor
axios2.interceptors.response.use(
  function (response) {
    if (response.config.url === "/enter/enter/monitor/ready") {
    } else {
      $("#overlay").fadeOut(300);
    }
    window.currentJobStatus = "done";
    return response;
  },
  function (error) {
    if (window.disableErrorMsg) {
      clearTimeout(window.aixosTO);
      window.aixosTO = setTimeout(() => window.disableErrorMsg = false, 2000);
      return Promise.resolve(error.response);
    }

    window.disableErrorMsg = true;

    $("#overlay").fadeOut(300);
    window.currentJobStatus = "done";
    return parseErrorCode(error);
  }
);

axios2.postForm = (uri, objs = {}, headers = {}) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(objs)) {
    formData.append(key, value);
  }
  return axios2.post(uri, formData, headers);
};

function parseFetchStatus(err) {
  console.log(err);
}

axios2.postFormEx = (uri, formData, _headers = {}) => {
  if (!$("#overlay").is(":visible")) {
    Fn.showSpinner();
  }

  const token = (localStorage.getItem("USER.token") || null);
  let headers = token ? {Authorization: "Bearer " + token, CurrentUri: window.location.pathname} : {CurrentUri: window.location.pathname};
  if (_headers) {
    headers = {...headers, ..._headers};
  }

  return fetch(Constants.API_URL.replace("/api/", "") + uri, {
    method: 'POST',
    body: formData,
    headers
  }).catch((error) => {
    if (window.disableErrorMsg) return Promise.resolve(error.response);
    window.disableErrorMsg = true;

    if (error?.toString()?.toLowerCase()?.includes("failed to fetch")) {
      alert("일시적인 네트워크 상태의 문제 또는 서버 문제로 서버에 접속이 불가능합니다. 잠시 후 다시 시도해 주세요.\n\nERROR CODE : NETWORK ERROR");
    } else {
      alert("데이터오류! 시스템 관리자에게 문의하세요\nERROR CODE : " + error?.toString());
    }
  }).finally(() => {
    $("#overlay").fadeOut(300);
    window.currentJobStatus = "done";

    if (window.disableErrorMsg) {
      setTimeout(() => window.disableErrorMsg = false, 2000);
    }
  });
};

axios2.postEx = (uri, objs = {}, _headers = {}) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(objs)) {
    formData.append(key, value);
  }

  const token = (localStorage.getItem("USER.token") || null);
  let headers = token ? {Authorization: "Bearer " + token, CurrentUri: window.location.pathname} : {CurrentUri: window.location.pathname};
  if (_headers) {
    headers = {...headers, ..._headers};
  }

  return fetch(Constants.API_URL.replace("/api/", "") + uri, {
    method: 'POST',
    body: formData,
    headers
  });
};

axios2.postRawForm = (uri, formData, headers = {}) => {
  return axios2.post(uri, formData, headers);
};

axios2.getForm = (uri, objs = {}, headers = {}) => {
  const queryString = qs.stringify(objs);
  if (queryString) {
    uri = uri + "?" + queryString;
  }
  return axios2.get(uri, {}, headers);
};

axios2.putRawForm = (uri, formData, headers = {}) => {
  return axios2.put(uri, formData, headers);
};

axios2.putForm = (uri, objs = {}, headers = {}) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(objs)) {
    formData.append(key, value);
  }
  return axios2.put(uri, formData, headers);
};

axios2.deleteForm = (uri, objs = {}, headers = {}) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(objs)) {
    formData.append(key, value);
  }
  return axios2.delete(uri, formData, headers);
};

axios2.patchForm = (uri, objs = {}, headers = {}) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(objs)) {
    formData.append(key, value);
  }
  return axios2.patch(uri, formData, headers);
};

export default axios2;
