import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";

const FinanceAppWebPayNice = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [result, setResult] = useState({});
  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/finance/app/web/pay/nice/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_buy_type=${$('#s_buy_type option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_hp=${$('#s_mb_hp').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.list, pageInfo, "ret?.mb_hp");

          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.Top_list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);

            if (result?.s_branch_code) {
              $('#s_branch_code').val(result?.s_branch_code);
            }
            if (result?.disable === "disabled") {
              $('#s_branch_code').prop("disabled", true);
            }
          }

          setResult(result);
          setRets(result?.list);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //2-1
  const maxNum = 30000;
  const totRecord = 3000;

  const chk_total_record = () => {
    const authCode = $("#auth_code").val();
    if (!authCode) {
      alert("인증코드를 입력해주세요");
      return;
    }

    const downReason = $("#down_reason").val();
    if (!downReason) {
      alert("사유를 입력해주세요");
      return;
    }

    const url = `Excel_paylist_nice.php?<?=$link_param?>&f_limit=0&l_limit=${totRecord}&max_num=${maxNum}&excel_num=1&auth_code=${authCode}&down_reason=${downReason}`;
    window.location.href = url;
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex justify-content-between flex-wrap m-3">
        <div className="d-flex my-1 fn_search_box">
          <div className="pr-1">
            <select className="form-select" id="s_branch_code">
            </select>
          </div>

          <div className="d-flex pr-1">
            <select className="form-select" id="s_buy_type">
              <option value="">::결제구분::</option>
              <option value="N">신규회원</option>
              <option value="Y">재등록회원</option>
            </select>
          </div>

          <div className="d-flex pr-1">
            <JDatePicker className="form-control" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="s_date2"/>
          </div>

          <div className="pr-1">
            <input type="text" className="form-control" placeholder="회원명" id="s_mb_name"/>
          </div>

          <div className="pr-1">
            <input type="text" className="form-control" placeholder="전화번호" id="s_mb_hp"/>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="my-1">
          <div id="GRID_col" className="line-height-180p">
            <dl className="d-flex">
              <dt>신규</dt>
              <dd>{Fn.numberWithCommas(result?.row1_tot_amt || 0)}</dd>
            </dl>

            <dl>
              <dt>재등록</dt>
              <dd>{Fn.numberWithCommas(result?.row2_tot_amt || 0)}</dd>
            </dl>

            <dl>
              <dt>합계</dt>
              <dd>{Fn.numberWithCommas((result?.row1_tot_amt || 0) + (result?.row2_tot_amt || 0))}</dd>
            </dl>
          </div>
        </div>
      </div>

      <div className="m-3 pb-2">
        <table className="view-rounded sub_table_border auto">
          <tr>
            <th className="w_70px">No</th>
            <th>일자</th>
            <th>결제구분</th>
            <th>지점명</th>
            <th>회원번호</th>
            <th>회원명</th>
            <th>연락처</th>
            <th>구매내역</th>
            <th>구매금액</th>
          </tr>
          {rets && rets.map((ret, i) => {
            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>{ret?.pay_date}</td>
                <td>{ret?.buy_num_show}</td>
                <td className="text-left">{ret?.branch_name}</td>
                <td>{ret?.mb_id}</td>
                <td>{ret?.mb_name}</td>
                <td>
                  <div id={`default_show${i + 1}`}>***-****-****</div>
                </td>
                <td className="text-left">{ret?.goods_name}</td>
                <td className="text-right">{Fn.numberWithCommas(ret?.pay_price)}</td>
              </tr>
            )
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={9}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
          <div className="d-flex mx-1">
            <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="다운로드 사유를 입력해 주세요." style={{width: "280px"}}/>
            <button type="button" className="btn btn-sm btn-outline-dark px-2 mx-1">엑셀저장</button>
            {pageInfo?.totalArticles > 0 && (
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('app_web_pay')}>연락처 확인</button>
              )
            )}
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default FinanceAppWebPayNice;
