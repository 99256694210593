import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";

const MemberOcareUseListBranch = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/member/ocare/use/list/branch/ready?s_pub_date1=${$('#s_pub_date1').val()}&s_pub_date2=${$('#s_pub_date2').val()}&s_use_date1=${$('#s_use_date1').val()}&s_use_date2=${$('#s_use_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  4-1.
  const in_bigo = async (baseSeq, value) => {
    try {
      const response = await axios.post('care_bigo.php', {
        base_seq: baseSeq,
        value: value,
      });

      // Handle the response data if necessary
      console.log(response.data);
    } catch (error) {
      if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
    }
  };

  const call_refund = () => {
    let con = $('[id^=achk]:checked').length;

    if (con == 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }
    if (con > 1) {
      window.alert("한건씩만 가능합니다.");
      return;
    }

    $('#base_seq').val($('[id^=achk]:checked').val());
    loadModal();
  };

  const loadModal = () => {
    axios2
      .post(`/member/refund/cal/care/ready?base_seq=${$('#base_seq').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalOpen(true);

          $('#delay_day').val(result?.delayDay || 0);
          $('#refund_start_date').val(result?.ticketData?.start_date || '');
          $('#pdt_date').val(Fn.getCurrentDate());
          $('#pay_amount').val(result?.payAmount || 0);
          $('#ot_left').html((result?.memberData?.ot_able || 0) - (result?.memberData?.ot_cnt || 0));

          useday_calc($('#refund_start_date').val(), "refund_use_date");
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  6-1.
  const go_refund = (base_seq) => {
    if (window.confirm('등록된 서비스를 삭제하시겠습니까?')) {
      // TODO : API 구현 필요
      axios2
        .post(`/member/ocare/use/del?base_seq=${base_seq}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            const dd = message.split("||");
            if (dd[0] === "care_err") {
              alert("오케어 통신오류");
            } else {
              alert("환불처리되었습니다");
              setRenew(Fn.getRenewTime());
            }
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  function useday_calc(start_date, input_name) { //사용일자 계산. 시작일과 객체명.
    let t = new Date();
    let sdate_arr = start_date.split("-");
    let s_day = new Date(sdate_arr[0], sdate_arr[1] - 1, sdate_arr[2]);
    let start_day = s_day.getTime();

    //변경
    let pdt_date = $("#pdt_date").val();
    let Tdate_arr = pdt_date.split("-");
    let Ts_day = new Date(Tdate_arr[0], Tdate_arr[1] - 1, Tdate_arr[2]);
    let to_day = Ts_day.getTime();

    let useday = Math.round((to_day - start_day) / (1000 * 60 * 60 * 24));
    useday = eval(useday) - eval(Fn.parseInt($('#delay_day').val()));

    if (to_day > start_day) {
      $('#' + input_name).val(useday);
    } else {
      $('#' + input_name).val(0);
    }
  }

  function result_calc() {
    //사용일 재정의
    let dts = $('#refund_start_date').val();
    let use_date = $('#refund_use_date').val()

    if (use_date == "" || use_date == "0") {
      if (window.confirm('사용일수가 0일 입니다. 계속 진행하시겠습니까?')) {
      } else {
        return false;
      }
    }

    if (!$('#pay_amount').val() || $('#pay_amount').val() == 0) {
      alert("결제금액을 입력하세요.");
      $('#pay_amount').focus();
      return false;
    }

    let ndx = $('#refund_kind')[0].selectedIndex;
    let selected = Fn.parseInt($('#refund_kind.options[ndx]').val());
    let ndx1 = $('#refund_part')[0].selectedIndex;
    let selected1 = Fn.parseInt($('#refund_part.options[ndx1]').val());

    let refund_use_date = Fn.parseInt($('#refund_use_date').val());
    let pay_amount = Fn.parseInt($('#pay_amount').val().replace(/,/g, ""));

    let penalty_day_amount = refund_use_date * selected1;
    let penalty_per_amount = pay_amount * (selected / 100);

    let penalty_amount = penalty_day_amount + penalty_per_amount;
    let return_amount = pay_amount - penalty_amount

    penalty_amount = Math.round(penalty_amount);
    return_amount = Math.round(return_amount);

    $('#real_penalty_amount').val(penalty_amount);

    $("#penalty_amount").html("<font color='red'><b>" + commaNum(penalty_amount) + "원</b></font>");

    let add_wi = Fn.parseInt($('#add_wi').val().replace(/,/g, ""));

    if (add_wi) {
      return_amount = return_amount - add_wi;
    }

    if (eval(return_amount) > 0) {
      if (penalty_amount > pay_amount) {//위약금이 결제금액보다 크면
        $('#refund_amount').val(0);
      } else {
        $('#refund_amount').val(commaNum(return_amount));
      }

      //환불금액에 대해서 본사/지점 나눈다
      let bb = Math.round(eval(return_amount) * eval(0.8));
      let cc = eval(eval(return_amount) - eval(bb));

      $('#penalty_amount_branch').html("<font color='red'><b>" + commaNum(bb) + "원</b></font>");
      $('#penalty_amount_central').html("<font color='red'><b>" + commaNum(cc) + "원</b></font>");
    } else {
      $('#refund_amount').val(0);
      $('#penalty_amount_branch').html('');
      $('#penalty_amount_central').html('');
    }
  }

  function commaNum(num) {
    if (num < 0) {
      num *= -1;
      var minus = true
    } else var minus = false

    var dotPos = (num + "").split(".")
    var dotU = dotPos[0]
    var dotD = dotPos[1]
    var commaFlag = dotU.length % 3

    if (commaFlag) {
      var out = dotU.substring(0, commaFlag)
      if (dotU.length > 3) out += ","
    } else var out = ""

    for (var i = commaFlag; i < dotU.length; i += 3) {
      out += dotU.substring(i, i + 3)
      if (i < dotU.length - 3) out += ","
    }

    if (minus) out = "-" + out
    if (dotD) return out + "." + dotD
    else return out
  }

  function Set_Comma(n) {
    return Number(String(n).replace(/\..*|[^\d]/g, "")).toLocaleString().slice(0, -3);
  }

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 justify-content-between table_wide_sh2">
          <div className="d-flex fn_search_box">
            <div className="pr-1 d-flex">
              <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>
                구매적용일 :
              </label>
              <div className="input-group" style={{width: 'auto'}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_pub_date1"/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_pub_date2"/>
              </div>
            </div>
            <div className="pr-1 d-flex">
              <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>
                사용일 :
              </label>
              <div className="input-group" style={{width: 'auto'}}>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_use_date1"/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_use_date2"/>
              </div>
            </div>

            <div className="pr-1">
              <input
                type="text"
                className="form-control width_shorts"
                aria-describedby="button-addon2"
                placeholder="이름 검색"
                id="s_mb_name"
              />
            </div>

            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>
                검색
              </button>
            </div>
          </div>

          <div className="mx-1">
            <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => call_refund()}>환불계산</button>
          </div>
        </div>


        <div className="m-3 table_list">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border table_wide_sh2">
            <colgroup>
              <col width="60px"/>
              <col width="80px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="20%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>등록지점</th>
              <th>회원번호</th>
              <th>회원명</th>
              <th>아이디</th>
              <th>구매회원권</th>
              <th>구매적용일</th>
              <th>구매금액</th>
              <th>시작일</th>
              <th>종료일</th>
              <th>환불</th>
            </tr>
            {rets && rets.map((ret, i) => {
              const {
                seq,
                branch_name,
                mb_id,
                mb_name,
                member_id,
                goods_name,
                use_date,
                goods_price,
                start_date,
                end_date,
                refund_YN,
              } = ret;

              const kb_fee = parseInt(goods_price * 0.05);
              const center_fee = parseInt(goods_price * 0.15);
              const base_seq = btoa(seq);
              const add_price = Math.round(goods_price * 0.1);

              const handleRefund = () => {
              };

              return (
                <tr style={refund_YN === 'Y' ? {background: '#ffe1ea'} : null}>
                  <td>{i + 1}</td>
                  <td>
                    <input type="checkbox" name={`chk[${i + 1}]`} value={base_seq} id={`achk${i + 1}`} className="checkbox"/>
                  </td>
                  <td>
                    {branch_name}
                  </td>
                  <td>
                    {mb_id}
                  </td>
                  <td>
                    {mb_name}
                  </td>
                  <td className="text-left">
                    {member_id}
                  </td>
                  <td>
                    {goods_name}
                  </td>
                  <td>
                    {use_date}
                  </td>
                  <td className="text-right">
                    {Fn.numberWithCommas(goods_price)}
                  </td>
                  <td>
                    <div align="center">{start_date}</div>
                  </td>
                  <td>
                    <div align="center">{end_date}</div>
                  </td>
                  <td>
                    {refund_YN === 'N' && start_date > Fn.getDate(new Date()) && (
                      <button type="button" className="btn btn-ssm btn-dark" onClick={() => go_refund(base_seq)}>환불</button>
                    )}
                    {refund_YN === 'Y' && (
                      <strong>
                        <font color="red">환불완료</font>
                      </strong>
                    )}
                  </td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={12}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-end border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

      <input type="hidden" id="base_seq"/>
      <input type="hidden" id="delay_day"/>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        // onAfterOpen={() => {
        //   $('#modal_s_year').val(Fn.getCurrentDate().split('-')[0]);
        //   $('#modal_s_month').val(Fn.getCurrentDate().split('-')[1]);
        //   loadModal();
        // }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '850px'}}>
            <div className="POP_title">
              <h5><strong>환불계산</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <p className="text-right h7"><span className="h7 text-pink">사용일</span>은 <span className="h7 text-blue">연기일</span>을 제외하고 계산됨</p>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="90px"/>
                  <col width="*"/>
                  <col width="90px"/>
                  <col width="*"/>
                  <col width="90px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>운동시작일</th>
                  <td>
                    <JDatePicker className="form-control" aria-describedby="button-addon2" id="refund_start_date"/>
                  </td>
                  <th>환불일자</th>
                  <td>
                    <JDatePicker className="form-control" aria-describedby="button-addon2" id="pdt_date"/>
                  </td>
                  <th>사용일</th>
                  <td>
                    <div className="input-group">
                      <input type="text" className="form-control" id="refund_use_date"/><p className="px-2 p-1">일</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>결제금액</th>
                  <td><input type="text" className="form-control" id="pay_amount"/></td>
                  <th>위약구분</th>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="refund_kind">
                      <option value='10'>10%</option>
                      <option value='20'>20%</option>
                    </select>
                  </td>
                  <th>구분</th>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="refund_part">
                      <option value="2000">패스트(2000)</option>
                      <option value="3000">피트니스/짐(3000)</option>
                      <option value="3700">프리미엄(3700)</option>
                      <option value="8800">골프/유료GX(8800)</option>
                      <option value="25000">필라테스(25000)</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>추가위약금</th>
                  <td><input type="text" className="form-control" id="add_wi"/></td>
                  <th>위약금</th>
                  <td>
                    <span id="penalty_amount"></span>
                    <input type="hidden" id="real_penalty_amount"/>
                  </td>
                  <th>환불금액</th>
                  <td><input type="text" className="form-control" id="refund_amount"/></td>
                </tr>
                <tr>
                  <th>OT잔여</th>
                  <td>
                    <span id="ot_left"></span>
                  </td>
                  <th>환불지점(80%)</th>
                  <td>
                    <span id="penalty_amount_branch"></span>
                  </td>
                  <th>환불본사(20%)</th>
                  <td>
                    <span id="penalty_amount_central"></span>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => result_calc()}>계산</button>
              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModalOpen(false)}>취소</button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};
export default MemberOcareUseListBranch;
