import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import ChkAuthNum from "../common/ChkAuthNum";

const FinanceAccountEdit = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();

  const [form1, setForm1] = useState();
  const [hide, setHide] = useState();

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const load = () => {
    setForm1($(".cls-main form[name='form1']")[0]);
    setHide($(".cls-main form[name='hide']")[0]);

    axios2
      .post(`/finance/finance/account/edit/ready?base_ckk_no=${$('#edit_base_ckk_no').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);

          window.__cls_tree('1', result?.d1_row?.ckk_kind, '1');

          if (!(result?.d1_row?.ckk_accode == 1112 || result?.d1_row?.ckk_accode == 1113 || result?.d1_row?.ckk_accode == 1114 || result?.d1_row?.ckk_accode == 1116 || result?.d1_row?.ckk_accode == 2114 || result?.d1_row?.ckk_accode == 1143)) {
            $('.cls-edit #m1_ckk_accjukyoo1').val(result?.d1_row?.ckk_accjukyoo);
          }
          $('.cls-edit #m1_ckk_amt1').val(Fn.numberWithCommas(result?.d1_row?.ckk_amt));
          $('.cls-edit #m1_ckk_etc1').val(result?.d1_row?.ckk_etc);

          $('#m1_ckk_acname1 option').filter(function () {
            return this.value.startsWith(result?.d1_row?.ckk_accode + '/')
          }).prop('selected', true);
          window.__cls_tree('5', $('#m1_ckk_acname1 option:selected').val(), '1')

          $('#m1_ckk_bank1 option').filter(function () {
            return this.value.startsWith(result?.d1_row?.ckk_bankcode + '/')
          }).prop('selected', true);
          window.__cls_tree('2', "", '1');

          if (!(result?.d1_row?.ckk_bankcode == 1112 || result?.d1_row?.ckk_bankcode == 1113 || result?.d1_row?.ckk_bankcode == 1114 || result?.d1_row?.ckk_bankcode == 1116 || result?.d1_row?.ckk_bankcode == 2114 || result?.d1_row?.ckk_bankcode == 1143))
            $('.cls-edit #m1_ckk_summary1').val(result?.d1_row?.ckk_summary);
          else {
            $('#m1_ckk_summary1 option').filter(function () {
              return this.value.startsWith(result?.d1_row?.ckk_summary_no + '/')
            }).prop('selected', true);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  window.__cls_tree = (gubun, cod, i) => {
    let form1 = $(".cls-edit #m1_form1")[0];
    let hide = $(".cls-edit #m1_hide")[0];

    const gaKind = $(`.cls-edit #m1_ckk_kind${i}`).val();
    const branchCode = $('.cls-edit').find(`#branch_code${i} option:selected`).val();

    if (!branchCode) return;

    if (gaKind === "") {
      var msg = "<input type='text' name='ckk_accjukyoo" + i + "' class='wid100' style='width:130px' placeholder='' onkeydown=\"cusor_move('ckk_accjukyoo','" + i + "',event.keyCode)\"/>";
      $(".cls-edit #saccB_" + i).parent().html(msg);
    }

    if (gubun == "2") {
      cod = eval("form1.ckk_bank" + i).value;
    }

    hide.gubun.value = gubun;
    hide.cc.value = cod;
    hide.ii.value = i;

    if (gubun === "2") {
      hide.ga_kind.value = gaKind;
    } else {
      hide.ga_kind.value = gaKind;
    }

    var gaecode = eval("form1.ckk_acname" + i).value;
    hide.gaecode.value = gaecode;
    hide.branch_code.value = branchCode;


    var formData = new FormData(hide);
    $.ajax({
      url: '/finance/account/select/data/edit?prefix=m1_',
      processData: false,
      async: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        if (gubun == 1) {
          if (data?.result?.result2?.length > 0) {
            if (data?.result?.select2) {
              // $(`.cls-edit #m1_ckk_acname${i}`).parent().html(data?.result?.select2);
              $(`.cls-edit #sacc5_${i}`).html(data?.result?.select2);
            }
          } else {
            $(`.cls-edit #sacc_${i}`).html(msg);
            $(`.cls-edit #m1_ckk_acname${i}`)[0].length = 1;
          }

          if (data?.result?.result3?.length > 0) {
            if (data?.result?.select3) {
              // $(`.cls-edit #m1_ckk_bank${i}`).parent().html(data?.result?.select3);
              $(`.cls-edit #sacc6_${i}`).html(data?.result?.select3);
            }
          } else {
            $(`.cls-edit #m1_ckk_bank${i}`)[0].length = 1;
          }
        }

        if (gubun == 5) {
          if (data?.result?.select1) {
            $(`.cls-edit #saccB_${i}`).parent().html(data?.result?.select1);
            $(`.cls-edit #sub_price_${i}`).html('');
          } else {
            $(`.cls-edit #sub_price_${i}`).html(data?.result?.sub_price);
          }
        }

        if (gubun == 2) {
          if (data?.result?.select1) {
            $(`.cls-edit #sacc7_${i}`).html(data?.result?.select1);
            if (data?.result?.display === 'inline')
              $(`.cls-edit #card_fee_${i}`).show();
            else
              $(`.cls-edit #card_fee_${i}`).hide();
          } else {
            $(`.cls-edit #sacc7_${i}`).html(data?.result?.input);
          }
        }

        // TODO : PTODO
        if (gubun == 5 && cod == 1) {
          let msg = `
            <input name="ckk_accode${i}" type="text" size="1" class="form-control wid100 ac" maxLength="4" onclick="this.select();" style="background:#FFCCCC;width:35px;" ondblclick="__ondouble_PopGJ(${i});" onchange="__search_code_gj(${i});">
            <input name="ckk_acname${i}" type="text" size="5" maxLength="20" style="width:90px" readonly class="form-control wid100">
          `;
          $(`#sacc5_${i}`).html(msg);
        }
      }
    });
  };

  function __ondouble_PopGJ(i) {
    // TODO : PTODO
    // pop_up('etc_account.html?i=' + i, 'etc_account', '400', '600', 'yes')
  }

  function __search_code_gj(i) {
    let common_i = $(".cls-edit #common_i")[0];

    var a = i;
    var par = eval("document.form1.ckk_accode" + i + ".value");
    if (par) {
      //pop_up('etc_account.html?i='+i+'&par='+par,'etc_account','400','600','yes')
      common_i.location.href = "chk_etc_account.php?i=" + i + "&par=" + par;
    }
  }

  function go_submit() {
    const form1 = $(".cls-edit #m1_form1")[0];

    if (!$('#fae_auth_code').val()) {
      alert('인증코드를 입력해주세요');
      $('#fae_search_auth_code').focus();
      return;
    }

    var j = 1;
    const bobj0_1 = form1[`branch_code${j}`];
    if (!bobj0_1.value) {
      alert('지점을 선택해주세요');
      bobj0_1.focus();
      return;
    }

    const obj0_1 = form1[`ckk_kind${j}`];
    if (!obj0_1.value) {
      alert('분류를 선택해주세요');
      obj0_1.focus();
      return;
    }

    var numbering = form1.numbering.value;
    for (let i = 1; i <= numbering; i++) {
      const obj0 = $(`#m1_ckk_kind${i} option:selected`).val();
      const obj3 = $(`#m1_ckk_acname${i} option:selected`).val();
      const obj1 = $(`#m1_ckk_bank${i} option:selected`).val();
      const obj2 = $(`#m1_ckk_amt${i}`).val();
      const obj4 = $(`#m1_ckk_date${i}`).val();

      //날짜와 분류가 선택되었을 경우 계정~ 기타 입력하도록 처리
      var val_crdr = obj0;
      var val_amt = obj2;
      if (val_amt == "0") {
        val_amt = "";
      }
      if ((val_crdr == "0" || val_crdr == "") && (obj1 == "") && (val_amt == "")) {

      } else {
        if (val_crdr != "0") {
          if (obj4 == "") {
            alert("일자를 입력해주세요");
            (`#m1_ckk_date${i}`).focus();
            return false;
          }

          if (obj3 == "") {
            alert("계정코드를 선택해주세요");
            $(`#m1_ckk_amt${i}`).focus();
            return false;
          }

          if (obj1 == "") {
            alert("계좌코드를 선택해주세요");
            $(`#m1_ckk_bank${i}`).focus();
            return false;
          }

          if (val_amt == "") {
            alert("금액을 입력해주세요");
            $(`#m1_ckk_amt${i}`).focus();
            return false;
          }
        }
      }
    }

    if (window.confirm('간편장부를 수정하시겠습니까?')) {
      const form = $('#m1_form1')[0];
      const formData = new FormData(form);

      $.ajax({
        url: '/finance/finance/account/edit',
        processData: false,
        contentType: false,
        data: formData,
        type: 'POST',
        success: function (data) {
          alert("저장되었습니다.");
          props?.onCallback();
          onToggleModal();
        }
      });
    }
  }

  function re_branch(c) {
    eval("document.form1.ckk_kind" + c).value = "";
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        $('#m1_base_ckk_no').val($('#edit_base_ckk_no').val());
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: '1400px'}}>
          <div className="POP_title">
            <h5><strong>간편장부 수정</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box cls-edit w-100">
            <form name="form1" id="m1_form1" method="post">
              <input type="hidden" name="numbering" value="1"/>
              <input type="hidden" name="base_ckk_no" id="m1_base_ckk_no"/>
              <div className="d-flex flex-end mb-3">
                <ChkAuthNum type={"locker_info_change"} prefix="fae_"/>
              </div>

              <div id="ac_show">
                <table className="view-rounded sub_table_border" style={{borderRadius: 0}}>
                  <tr>
                    <th>일자</th>
                    <th>지점</th>
                    <th>분류</th>
                    <th>계정</th>
                    <th>계정적요</th>
                    <th>계좌</th>
                    <th>계좌적요</th>
                    <th>금액</th>
                    <th>메모</th>
                  </tr>
                  <tr id="detail1">
                    <td className="text-center"><JDatePicker id="m1_ckk_date1" name="ckk_date1" value={result?.d1_row?.ckk_date}/></td>
                    <td className="text-center">
                      <select className="form-select" name="branch_code1" id="branch_code1" onChange={() => re_branch(1)}>
                        {result?.Top_list && result?.Top_list?.map(item => <option value={item.branch_code} selected={result?.d1_row?.ckk_gr_id == item.branch_code ? true : false}>{item.branch_name}</option>)}
                      </select>
                    </td>
                    <td className="text-center">
                        <span className="form-inline" id="sacc3_1">
                        <select name="ckk_kind1" id="m1_ckk_kind1" className="form-select" style={{width: '100%'}} onChange={(e) => window.__cls_tree('1', e.target.value, '1')}>
                          <option value="">선택</option>
                          <option className="text-success" value="G" selected={result?.d1_row?.ckk_kind == "G" ? true : false}>대체</option>
                          <option className="text-primary" value="A" selected={result?.d1_row?.ckk_kind == "A" ? true : false}>비용</option>
                          <option className="text-primary" value="B" selected={result?.d1_row?.ckk_kind == "B" ? true : false}>급여</option>
                          <option className="text-danger" value="C" selected={result?.d1_row?.ckk_kind == "C" ? true : false}>입금</option>
                        </select>
                      </span>
                    </td>
                    <td className="text-center">
                      <span id="sacc5_1">
                        <select name="ckk_acname1" id="m1_ckk_acname1" className="form-select" style={{width: '100%'}} onChange={(e) => window.__cls_tree('5', e.target.value, '1')}>
                          <option value="">계정 선택</option>
                          {result?.queryAcc && result?.queryAcc?.map(item => <option value={item.acc_no + "/" + item.acc_name} selected={result?.d1_row?.ckk_accode == item.acc_no ? true : false}>{item.acc_name}</option>)}
                          <option value='1' style={{color: 'red'}}>기타계정</option>
                        </select>
                      </span>
                      <span id="sub_price_1">
                        {result?.d1_row?.ckk_kind === "A" &&
                          <select name="sub_price1" id="sub_price1" className="form-select" style={{width: '100%'}}>
                            <option value="">지출세부</option>
                            {result?.sData && result?.sData?.map(item => <option value={item.seq} selected={result?.d1_row?.sub_price == item.seq ? true : false}>{item.sub_name}</option>)}
                          </select>
                        }
                     </span>
                    </td>
                    <td className="text-center">
                      <span id="saccB_1">
                        {(result?.d1_row?.ckk_accode == 1112 || result?.d1_row?.ckk_accode == 1113 || result?.d1_row?.ckk_accode == 1114 || result?.d1_row?.ckk_accode == 1116 || result?.d1_row?.ckk_accode == 2114 || result?.d1_row?.ckk_accode == 1143) ?
                          <select name="ckk_accjukyoo1" id="m1_ckk_accjukyoo1" style={{width: '100%'}} className="form-select">
                            {result?.result1 && result?.result1?.map(item => <option value={item.j_no + '/' + item.j_jukname} selected={result?.d1_row?.ckk_accjukyoo_no == item.j_no ? true : false}>{item.j_jukname}</option>)}
                          </select>
                          :
                          <input type="text" name="ckk_accjukyoo1" id="m1_ckk_accjukyoo1" className="form-control" style={{width: '100%'}} placeholder=""/>
                        }
                      </span>
                    </td>
                    <td className="text-center">
                      <span id="sacc6_1" onChange={(e) => window.__cls_tree('2', e.target.value, '1')}>
                        <select name="ckk_bank1" id="m1_ckk_bank1" className="form-select" style={{width: '100%'}}>
                          <option value="">계좌 선택</option>
                          {result?.result3 && result?.result3?.map(item => <option value={item.acc_no + '/' + item.acc_name} selected={result?.d1_row?.ckk_bankcode == item.acc_no ? true : false}>{item.acc_name}</option>)}
                        </select>
                      </span>
                    </td>
                    <td className="text-center">
                      <span id="sacc7_1">
                        {(result?.d1_row?.ckk_bankcode == 1112 || result?.d1_row?.ckk_bankcode == 1113 || result?.d1_row?.ckk_bankcode == 1114 || result?.d1_row?.ckk_bankcode == 1116 || result?.d1_row?.ckk_bankcode == 2114 || result?.d1_row?.ckk_bankcode == 1143) ?
                          <select name="ckk_summary1" id="m1_ckk_summary1" className="form-select " style={{width: '100%'}}>
                            {result?.result4 && result?.result4?.map(item => <option value={item.j_no + '/' + item.j_jukname} selected={result?.d1_row?.ckk_summary_no == item.j_no ? true : false}>{item.j_jukname}</option>)}
                          </select>
                          :
                          <input type="text" name="ckk_summary1" id="m1_ckk_summary1" className="form-control " placeholder="" style={{width: '100%'}}/>
                        }
                      </span>
                    </td>
                    <td className="text-center">
                      <input type="text" name="ckk_amt1" id="m1_ckk_amt1" className="form-control price_input" placeholder="" style={{width: '100%', imeMode: 'disabled'}}
                             onKeyPress={(e) => Fn.numkeyCheck(e)}
                             onKeyUp={(e) => Fn.vComma(e.target)}/>
                    </td>
                    <td className="text-center">
                      <input type="text" name="ckk_etc1" id="m1_ckk_etc1" className="form-control" placeholder="" style={{width: '100%', imeMode: 'disabled'}}/>
                    </td>
                  </tr>
                </table>
              </div>
            </form>
            <form name='hide' id='m1_hide' target='iframe' action='account_select_data_edit.php'>
              <input type='hidden' name='gubun'/>
              <input type='hidden' name='cc'/>
              <input type='hidden' name='ii'/>
              <input type='hidden' name='ga_kind'/>
              <input type='hidden' name='gaecode'/>
              <input type='hidden' name='branch_code'/>
            </form>
            <iframe src='#' name='iframe' id='iframe' width="500" height="300" style={{display: 'none'}}/>
            <iframe name="common_i_pop" id="common_i_pop" width="0" height="0" src="#" style={{display: 'none'}}/>
            <iframe name="common_i" id="common_i" width="0" height="0" src="#" style={{display: 'none'}}/>
          </div>
          <div className="text-center mb-3">
            <button type="button" id="input_btn" name="input_btn" className="btn btn-sm btn-point px-3 mx-1" onClick={() => go_submit()}>간편장부 수정</button>
            <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>닫기</button>
          </div>

        </div>
      </div>
    </Modal>
  );
};

export default FinanceAccountEdit;
