import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import SelectProduct from "../common/SelectProduct";
import {useHistory, useLocation} from "react-router-dom";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import _ from "lodash";


const SalesOrderReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const orderCode = location?.state?.orderCode || '';

  const [modalOpen, setModalOpen] = useState(false);
  const [maxCnt, setMaxCnt] = useState(10);

  useEffect(() => {
    load();
    if (!$('#inout_date').val()) $('#inout_date').val(Fn.getCurrentDate());
    change_list(10);
  }, [])

  const load = (_page) => {
    axios2
      .post(`/sales/order/reg/ready?order_code=${orderCode}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          $('.control_code').each(function (index, item) {
            if ($(item).html() === '') {
              let optionsHTML = '<option value="">조정구분</option>';
              result?.list?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
              });
              $(item).html(optionsHTML);
            }
          });

          if (result) {
            $('#order_date').val(result?.orderDate || result?.order_date);
            $('#hope_date').val(result?.hopeDate || result?.hope_date);
            if (result?.detailCnt > 0)
              change_list(result?.detailCnt);

            result?.detail_data?.map((ret, i) => {
              $("#p_cd_show_" + (i + 1)).val(ret?.myPCode);
              $("#p_code_" + (i + 1)).val(ret?.myPCode);
              $("#qty_" + (i + 1)).val(ret?.myQty);

              $("#unit_price_" + (i + 1)).val(ret?.myPrice);
              $("#detail_bigo_" + (i + 1)).val(ret?.myDetailBigo);
              $("#control_code_" + (i + 1)).val("");

              $("#p_name_" + (i + 1)).html(ret?.myPName);
              $("#unit_price_show_" + (i + 1)).html(ret?.myPrice);
              $("#price_" + (i + 1)).html(ret?.myPrice);
            });

            show_tot();
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function data_reset(cnt) {
    $("#p_cd_show_" + cnt).val("");
    $("#p_code_" + cnt).val("");
    $("#qty_" + cnt).val("");
    $("#unit_price_" + cnt).val("");
    $("#detail_bigo_" + cnt).val("");
    $("#control_code_" + cnt).val("");

    $("#p_name_" + cnt).html("");
    $("#unit_price_show_" + cnt).html("");
    $("#price_" + cnt).html("");

    show_tot();
  }

  function cfNumeric(sOrg) {
    let nm;
    sOrg = sOrg.replace(/,/g, "");
    nm = parseFloat(sOrg).toString();
    return (isNaN(nm) ? 0 : nm);
  }

  function show_tot() {
    let tot_cnt = 0;
    let tot_qty = 0;
    let tot_price = 0;

    for (let cnt = 1; cnt <= Fn.parseInt($('#max_cnt').val()); cnt++) {
      let p_code = $("#p_code_" + cnt).val();

      if (p_code) {
        tot_cnt++;

        let unit_price = $("#unit_price_" + cnt).val(); //단가
        unit_price = cfNumeric(unit_price);

        let qty = $("#qty_" + cnt).val(); //수량
        qty = cfNumeric(qty);

        let o_price = eval(unit_price) * eval(qty);

        tot_qty = eval(tot_qty) + eval(qty);
        tot_price = eval(tot_price) + o_price;
      }
    }

    $('#tot_qty_show').html("<strong>" + tot_qty + "</strong>");
    $('#tot_price_show').html("<strong>" + Fn.numberWithCommas(tot_price) + "</strong>");
  }

  function change_list(val) {
    $('#max_cnt').val(val);

    let cnts = Fn.parseInt($('#max_cnt').val());

    for (let i = 1; i <= cnts; i++) {
      $('#detail_' + i).show();
    }
    for (let i = 30; i > cnts; i--) {
      $('#detail_' + i).hide();
      //데이터 초기화
      data_reset(i)
    }

    show_tot();
  }

  function go_submit() {
    /*
        String baseSeq = (String) requestParams.get("base_seq");
        String[] pCodes = (String[]) requestParams.get("p_codes[]"); // TODO : 체크 필요
        String[] qtys = (String[]) requestParams.get("qtys[]"); // TODO : 체크 필요
        String[] detailBigos = (String[]) requestParams.get("detail_bigos[]"); // TODO : 체크 필요
        String inoutDate = (String) requestParams.get("inout_date");
        int detailCnt = Fn.parseInt(requestParams.get("detail_cnt"));
     */

    const formData = new FormData();

    let p_codes = $('[id^=p_code_]').map(function () {
      return $(this).val();
    }).get().join(",");
    formData.append("p_codes[]", p_codes);

    let qtys = $('[id^=qty_]').map(function () {
      return $(this).val();
    }).get().join(",");
    formData.append("qtys[]", qtys);

    let purchase_cnts = $('[id^=purchase_cnt_]').map(function () {
      return $(this).val();
    }).get().join(",");
    formData.append("purchase_cnts[]", purchase_cnts);

    let detail_bigos = $('[id^=detail_bigo_]').map(function () {
      return $(this).val();
    }).get().join(",");
    formData.append("detail_bigos[]", detail_bigos);

    formData.append("order_date", $('#order_date').val());
    formData.append("hope_date", $('#hope_date').val());
    formData.append("order_code", $('#order_code').val());
    formData.append("detail_cnt", $('#detail_cnt option:selected').val() || '10');

    axios2.postFormEx('/sales/order/reg', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();
        if (data === 'insert_ok') {
          alert('저장되었습니다.');
          history.goBack();
        } else if (data === 'update_ok') {
          alert('수정되었습니다.');
          history.goBack();
        } else {
          alert("저장오류! 잠시후 다시 시도해주세요");
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  // TODO : select_product 구현 -> 동일한 상품 등록 방지

  /*
    const form1 = useRef(null); // TODO : FORM 참조
    const maxCnt = useState(0); // TODO : PHP 값

    const documentReady = (event) => {
      const {value, name} = event.target;
      // TODO : 입력값 처리 로직 추가
      //숫자만 체크
      // $(".numberOnly").keyup(function () {
      //   /!* 48~57:일반 숫자키 코드, 96~105:숫자키패드 숫자키 코드 , 8 : backspace, 9 : tab ,46 : delete, 37 : 왼쪽방향키 , 39 : 오른쪽방향키 *!/
      //   var keyID = event.which;
      //
      //   if ((keyID >= 48 && keyID <= 57) || (keyID >= 96 && keyID <= 105) || keyID == 8 || keyID == 9 || keyID == 46 || keyID == 37 || keyID == 39) {
      //   } else {
      //     this.value = this.value.replace(/[^0-9\.]/g, ''); //숫자를 제외한 문자를 지워준다.
      //   }
      // });
      // $(".nodda").keyup(function () {
      //   /!* 48~57:일반 숫자키 코드, 96~105:숫자키패드 숫자키 코드 , 8 : backspace, 9 : tab ,46 : delete, 37 : 왼쪽방향키 , 39 : 오른쪽방향키 *!/
      //   var keyID = event.which;
      //
      //   if (keyID == 222) {
      //     this.value = this.value.replace(/['"]/g, ''); //숫자를 제외한 문자를 지워준다.
      //   }
      // });
    };

    const changeListInit = () => {
      const cnts = form1.detailCnt.value;

      for (let i = 1; i <= cnts; i++) {
        const as = eval(`detail${i}.style`);
        as.display = 'table-row';
      }
      for (let i = maxCnt; i > cnts; i--) {
        const as = eval(`detail${i}.style`);
        as.display = 'none';
      }
    };

    const changeList = () => {
      const cnts = form1.detailCnt.value;

      for (let i = 1; i <= cnts; i++) {
        const as = eval(`detail${i}.style`);
        as.display = 'table-row';
      }
      for (let i = maxCnt; i > cnts; i--) {
        const as = eval(`detail${i}.style`);
        as.display = 'none';

        //데이터 초기화
        dataReset(i);
      }

      showTotal();
    };

    const changeEa = (cnt) => {
      if (!form1[`p_code[${cnt}]`].value) {
        alert('상품이 선택되지 않았습니다');
        form1[`qty[${cnt}]`].value = '';
        dataReset(cnt);
        return;
      }

      let unitPrice = form1[`unit_price[${cnt}]`].value; //단가
      unitPrice = Fn.cfNumeric(unitPrice);

      let qty = form1[`qty[${cnt}]`].value; //수량
      qty = Fn.cfNumeric(qty);

      let oPrice = eval(unitPrice) * eval(qty);
      oPrice = Fn.numberWithCommas(oPrice);

      eval(`price${cnt}`).innerHTML = oPrice;

      showTotal();
    };

    const chkPurchase = (cnt) => {
      //발주단위를 구한다
      let purchaseCnt = form1[`purchase_cnt[${cnt}]`].value; //발주수량
      let qty = Fn.cfNumeric(purchaseCnt);

      //발수수량을 구한다
      qty = form1[`qty[${cnt}]`].value; //수량
      qty = Fn.cfNumeric(qty);

      const dd = qty / purchaseCnt;

      if (qty === 0) {
        alert('수량을 입력해주세요');
        form1[`qty[${cnt}]`].value = form1[`purchase_cnt[${cnt}]`].value;
        changeEa(cnt);
      }

      if (dd === parseInt(dd, 10)) {
        // Do something
      } else {
        alert('발주수량 단위를 확인해주세요');
        form1[`qty[${cnt}]`].value = form1[`purchase_cnt[${cnt}]`].value;
        changeEa(cnt);
      }
    };

    const dataReset = (cnt) => {
      form1[`p_code_show[${cnt}]`].value = '';
      form1[`p_code[${cnt}]`].value = '';
      form1[`qty[${cnt}]`].value = '';
      form1[`unit_price[${cnt}]`].value = '';
      form1[`detail_bigo[${cnt}]`].value = '';

      eval(`p_name${cnt}`).innerHTML = '';
      eval(`unit_price_show${cnt}`).innerHTML = '';
      eval(`price${cnt}`).innerHTML = '';

      showTotal();
    };

    const showTotal = () => {
      let totCnt = 0;
      let totQty = 0;
      let totPrice = 0;

      for (let cnt = 1; cnt <= form1.detailCnt.value; cnt++) {
        const pCode = form1[`p_code[${cnt}]`].value;

        if (pCode) {
          totCnt++;

          let unitPrice = form1[`unit_price[${cnt}]`].value; //단가
          unitPrice = Fn.cfNumeric(unitPrice);

          let qty = form1[`qty[${cnt}]`].value; //수량
          qty = Fn.cfNumeric(qty);

          let oPrice = eval(unitPrice) * eval(qty);

          totQty = eval(totQty) + eval(qty);
          totPrice = eval(totPrice) + oPrice;
        }
      }

      //totCntShow.innerHTML = totCnt;
      form1.totQtyShow.innerHTML = `<strong>${totQty}</strong>`;
      form1.totPriceShow.innerHTML = `<strong>${Fn.numberWithCommas(totPrice)}</strong>`;
    };

    const goSubmit = () => {
      // TODO
      // regBtn.innerHTML = "<a class='btn btn-primary'><i class='fas fa-edit' aria-hidden='true'></i> 저장</a>";

      if (form1.orderDate.value === '') {
        alert('입고일을 입력해주세요');
        // TODO
        // regBtn.innerHTML = "<a class='btn btn-primary' onclick='goSubmit()'><i class='fas fa-edit' aria-hidden='true'></i> 저장</a>";
        form1.orderDate.focus();
        return;
      }

      let cnt = 1;
      const firstGoods = form1[`p_code[${cnt}]`].value;

      if (firstGoods === '') {
        alert('첫번째 상품은 필수입력 항목입니다');
        // TODO
        // regBtn.innerHTML = "<a class='btn btn-primary' onclick='goSubmit()'><i class='fas fa-edit' aria-hidden='true'></i> 저장</a>";
        return;
      }

      //수량여부 체크
      let errQty = 0;
      for (cnt = 1; cnt <= form1.detailCnt.value; cnt++) {
        const pCode = form1[`p_code[${cnt}]`].value;
        const qty = eval(form1[`qty[${cnt}]`].value);

        if (pCode && !qty) {
          errQty++;
        }
      }
      if (errQty) {
        alert('수량이 입력되지 않은 상품이 있습니다.');
        // TODO
        // regBtn.innerHTML = "<a class='btn btn-primary' onclick='goSubmit()'><i class='fas fa-edit' aria-hidden='true'></i> 저장</a>";
        return;
      }

      const params = $('#form1').serialize();

      axios.post('/sales/order/reg', params)
        .then(response => {
          const data = response.data.trim();

          if (data === 'insert_ok') {
            alert('저장되었습니다');
            location.href = 'order_list.html';
            // TODO
            // return;
          } else if (data === 'update_ok') {
            alert('수정되었습니다');
            location.href = `order_list.html?<?=$link_param?>&page=<?=$page?>`;
          } else {
            alert('저장오류! 잠시후 다시 시도해주세요');
            // TODO
            // regBtn.innerHTML = "<a class='btn btn-primary' onclick='goSubmit()'><i class='fas fa-edit' aria-hidden='true'></i> 저장</a>";
          }
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          // TODO
          // regBtn.innerHTML = "<a class='btn btn-primary' onclick='goSubmit()'><i class='fas fa-edit' aria-hidden='true'></i> 저장</a>";
        });
    };

    const selectProduct = (cnt) => {
      const pCode = $(`#p_code_show${cnt}`).val();

      for (let i = 1; i <= 'maxCnt'; i++) {
        if (i === cnt) {
        } else {
          if (pCode === form1[`p_code[${i}]`].value) {
            alert('동일한 상품이 선택되어 있습니다.');
            form1[`p_code_show[${cnt}]`].value = '';
            form1[`p_code[${cnt}]`].value = '';
            return;
          }
        }
      }

      const companySeq = '<?=$company_seq?>'; // TODO : PHP 값

      axios.post('/sales/select/product/order', `search_type=array&p_code=${pCode}&cnt=${cnt}&company_seq=${companySeq}`)
        .then(response => {
          const data = response.data.trim();

          const value = data.split('||');

          if (value[0] === 'err') {
            alert('검색오류! 관리자에게 문의하세요');
            const newCnt = value[1];

            alert('상품코드 혹은 바코드가 일치하는 상품이 없습니다');
            $(`#p_code_show${newCnt}`).val('');
            $(`#p_code${newCnt}`).val('');
            $(`#p_name${newCnt}`).text('');
            $(`#unit_price_show${newCnt}`).text('');
            $(`#unit_price${newCnt}`).val('');
            $(`#qty${newCnt}`).val('');
            $(`#price${newCnt}`).text('');
          } else if (value[0] === 'same_company') {
            alert('수정시에는 동일한 공급업체 상품만 선택가능합니다');
            const newCnt = value[1];

            $(`#p_code_show${newCnt}`).val('');
            $(`#p_code${newCnt}`).val('');
            $(`#p_name${newCnt}`).text('');
            $(`#unit_price_show${newCnt}`).text('');
            $(`#unit_price${newCnt}`).val('');
            $(`#qty${newCnt}`).val('');
            $(`#price${newCnt}`).text('');
          } else if (value[0] === 'over') {
            alert('2개 이상 상품이 검색되었습니다. 정확한 상품코드 혹은 바코드를 입력하세요');
            const newCnt = value[1];

            alert('상품코드 혹은 바코드가 일치하는 상품이 없습니다');
            $(`#p_code_show${newCnt}`).val('');
            $(`#p_code${newCnt}`).val('');
            $(`#p_name${newCnt}`).text('');
            $(`#unit_price_show${newCnt}`).text('');
            $(`#unit_price${newCnt}`).val('');
            $(`#qty${newCnt}`).val('');
            $(`#price${newCnt}`).text('');
          } else if (value[0] === 'ok') {
            const newCnt = value[1];
            const pCode = value[2];        //상품코드
            const salePrice = value[3];    //판매가
            const pName = value[4];        //상품명
            const buyPrice = value[5];     //매입가
            const buyPriceShow = value[6]; //매입가

            $(`#p_code_show${newCnt}`).val(pCode);
            $(`#p_code${newCnt}`).val(pCode);
            $(`#p_name${newCnt}`).text(pName);
            $(`#unit_price_show${newCnt}`).text(buyPriceShow);
            $(`#unit_price${newCnt}`).val(buyPrice);
            $(`#qty${newCnt}`).val(1);
            $(`#price${newCnt}`).text(buyPriceShow);
            $(`#qty${newCnt}`).focus();
            showTotal();
          } else {
            const newCnt = value[1];

            alert('상품코드 혹은 바코드가 일치하는 상품이 없습니다');
            $(`#p_code_show${newCnt}`).val('');
            $(`#p_code${newCnt}`).val('');
            $(`#p_name${newCnt}`).text('');
            $(`#unit_price_show${newCnt}`).text('');
            $(`#unit_price${newCnt}`).val('');
            $(`#qty${newCnt}`).val('');
            $(`#price${newCnt}`).text('');
          }
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    };
  */

  return (
    <CmnCard onLogout={props.onLogout}>
      <div>
        <div className="m-3 search_box wid_MAX">
          {/* 회원검색 */}
          <div className="p-3">
            <table className="view-rounded sub_view">
              <colgroup>
                <col width="120px"/>
                <col width="*"/>
                <col width="120px"/>
                <col width="*"/>
                <col width="120px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>발주일</th>
                <td className="text-left"><JDatePicker className="form-control" id="order_date"/></td>
                <th>입고희망일</th>
                <td className="text-left"><JDatePicker className="form-control" id="hope_date"/></td>
                <th>품목수</th>
                <td className="text-left">
                  <select className="form-select" style={{maxWidth: '250px'}} id="detail_cnt" onChange={(e) => change_list(e.target.value)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </select>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="m-3 pb-2 table_list">

          <input type="hidden" id="order_code" value={orderCode}/>
          <input type="hidden" id="cnt"/>
          <input type="hidden" id="max_cnt" value={maxCnt}/>

          {/* 회원목록 */}
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="60px"/>
              <col width="15%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="100px"/>
              <col width="100px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>상품코드</th>
              <th>상품명</th>
              <th>단가</th>
              <th>수량단위</th>
              <th>수량</th>
              <th>금액</th>
              <th>비고</th>
              <th>삭제</th>
            </tr>
            {_.range(1, 30 + 1).map(i =>
              <tr id={`detail_${i}`}>
                <td>{i}</td>
                <td>
                  <div className="input-group" style={{width: 'auto'}}>
                    <input type="text" className="form-control" aria-describedby="button-addon2" id={`p_cd_show_${i}`}/>
                    <input type="hidden" id={`p_code_${i}`}/>
                    <button type="button" className="btn btn-ssm btn-outline-point"
                            onClick={() => {
                              $('#cnt').val(i);
                              $('#max_cnt').val(maxCnt);
                              setModalOpen(true);
                            }}
                    >검색
                    </button>
                  </div>
                </td>
                <td className="text-left"><span id={`p_name_${i}`}></span></td>
                <td>
                  <span id={`unit_price_show_${i}`}></span>
                  <input type="hidden" id={`unit_price_${i}`}/>
                </td>
                <td>
                  <input type="text" id={`purchase_cnt_${i}`} className="form-control" aria-describedby="button-addon2" readOnly={true}/>
                </td>
                <td><input type="text" id={`qty_${i}`} className="form-control" aria-describedby="button-addon2" onChange={() => show_tot()}/></td>
                <td className="text-right"><span id={`price_${i}`}></span></td>
                <td><input type="text" className="form-control" aria-describedby="button-addon2" id={`detail_bigo_${i}`}/></td>
                <td>
                  <button type="button" className="btn btn-ssm btn-outline-dark"
                          onClick={() => {
                            data_reset(i);
                          }}
                  >삭제
                  </button>
                </td>
              </tr>
            )}
            <tr>
              <th colSpan="5">합계</th>
              <th><span id="tot_qty_show"></span></th>
              <th><span id="tot_price_show"></span></th>
              <th colSpan="2"></th>
            </tr>
          </table>
        </div>
      </div>
      <div className="my-3 text-center">
        <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
        <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.goBack()}>목록</button>
      </div>

      <SelectProduct
        isOpen={modalOpen}
        onModalClose={() => setModalOpen(false)}
        searchType={"input_reg"}
        callback={() => {
          setModalOpen(false);
          show_tot();
        }}
      />
    </CmnCard>
  );
};

export default SalesOrderReg;
