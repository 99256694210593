import React, {useState} from 'react'

export const GlobalContext = React.createContext({
  refershAlarm: new Date().getTime(),
  setRefreshAlarm: () => {
  }
})

export const GlobalContextProvider = (props) => {

  const setRefreshAlarm = (renew) => {
    setState({...state, refreshAlarm: renew})
  }

  const initState = {
    refreshAlarm: new Date().getTime(),
    setRefreshAlarm: setRefreshAlarm
  }

  const [state, setState] = useState(initState)

  return (
    <GlobalContext.Provider value={state}>
      {props.children}
    </GlobalContext.Provider>
  )
}
