import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import CmnCard from "../CmnCard";
import axios from "axios";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import {useHistory, useLocation} from "react-router-dom";

const BranchopNoticeList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const location = useLocation();

  // NAVIGATION : 뒤로가기 시 상태유지 테스트 코드
  // const [fromBack, setFromBack] = useState(location?.state?.fromBack || '');

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(location?.state?.fromBack === 'true' ? Fn.parseInt(sessionStorage.getItem("page")) : 1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  const history = useHistory();

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=chk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=chk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    let url = `/branchop/notice/list/ready?key=${$('#key').val()}&page=${_page || currPage}`;
    setCurrPage(_page || currPage); // NAVIGATION : 검색 후 currPage 조정

    // NAVIGATION : 뒤로가기 시 상태유지 테스트 코드
    // if (fromBack === 'true') {
    //   url = sessionStorage.getItem("url") || url;
    //   sessionStorage.setItem("url", undefined);
    //   sessionStorage.setItem("page", undefined);
    //   setFromBack('');
    // } else {
    //   sessionStorage.setItem("url", url);
    //   sessionStorage.setItem("page", _page || currPage);
    // }
    // console.log(url);
    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  let s_form;
  let form1;
  let common_i;

  useEffect(() => {
    s_form = $("[name='s_form']");
    form1 = $("[name='form1']");
    common_i = $("[name='common_i']");
  });

  const allDel = () => {
    let con = $('[id^=chk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 게시물을 삭제하시겠습니까?')) {
      let delKey = $('[id^=chk]:checked').map(function () {
        return $(this).val();
      }).get().join("|");

      axios2
        .post(`/branchop/notice/del?del_key=${encodeURIComponent(delKey)}`)
        .then((response) => {
          setRenew(Fn.getRenewTime());
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1">
          <input type="text" className="form-control" placeholder="제목 검색" id="key"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => {
            history.push({
              pathname: '/branchop/notice/write', state: {}
            });
          }} style={{cursor: "pointer"}}>등록
          </button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border auto">
          <tr>
            <th className="w_70px"><input type="checkbox" onClick={(e) => {
              if ($(e.target).prop("checked")) $('[id^=chk]').prop("checked", true);
              else $('[id^=chk]').prop("checked", false);
            }}/></th>
            <th className="w_70px">No</th>
            <th>대상</th>
            <th className="w-50">제목</th>
            <th>등록자</th>
            <th>등록일</th>
          </tr>
          {rets && rets.map((ret, i) => {

            const target = ret?.branch_code === '' ? '전체지점' : '선택지점';

            return (
              <tr>
                <td>
                  {(true /*systemID === $reg_id*/) && (
                    <input
                      type="checkbox"
                      id={`chk[${i + 1}]`}
                      name={`chk[[${i + 1}]]`}
                      value={btoa(ret?.seq)}
                    />
                  )}
                </td>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>{target}</td>
                <td className="text-left bold700"
                    onClick={() => {
                      history.push({
                        pathname: '/branchop/notice/view', state: {
                          base_seq: btoa(ret?.seq)
                        }
                      });
                    }} style={{cursor: "pointer"}}>
                  {ret?.gong_YN === "Y" && <span style={{color: 'red'}}>[공지]</span>}
                  {ret?.title}
                </td>
                <td>{ret?.reg_name}</td>
                <td>{(ret?.reg_date || '').replace(/-/g, '.').substr(2, 8)}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={11}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>

        <div>
          <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => allDel()}>삭제</button>
        </div>
      </nav>
    </CmnCard>
  );
};

export default BranchopNoticeList;
