import React, {createRef, useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import ReactAudioPlayer from "react-audio-player";


const ConfigSetSound = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [renew, setRenew] = useState(0);
  const [ret, setRet] = useState({});
  const [audioFile, setAudioFile] = useState();

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/config/set/sound/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRet(result?.dataRow?.[0]);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const go_submit = () => {
    const formData = new FormData();

    formData.append('userfile1', $('#userfile1')[0].files[0]);
    formData.append('chk1', $('#chk1').is(':checked') ? "Y" : "");

    formData.append('userfile2', $('#userfile2')[0].files[0]);
    formData.append('chk2', $('#chk2').is(':checked') ? "Y" : "");

    formData.append('userfile3', $('#userfile3')[0].files[0]);
    formData.append('chk3', $('#chk3').is(':checked') ? "Y" : "");

    formData.append('userfile4', $('#userfile4')[0].files[0]);
    formData.append('chk4', $('#chk4').is(':checked') ? "Y" : "");

    formData.append('userfile5', $('#userfile5')[0].files[0]);
    formData.append('chk5', $('#chk5').is(':checked') ? "Y" : "");

    formData.append('userfile6', $('#userfile6')[0].files[0]);
    formData.append('chk6', $('#chk6').is(':checked') ? "Y" : "");

    axios2.postFormEx('/config/set/sound', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (code === 200) {
          alert('저장하였습니다.');
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-2.
  const presound = (filename) => {
    const sourceUrl = Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=sound_files&filename=" + encodeURIComponent(filename));
    console.log(sourceUrl);
    setAudioFile(sourceUrl);
    //player.current.audioEl.play(sourceUrl);
    // var audio = $("#player");
    // $('#audio_src').attr('src', sourceUrl);
    // /****************/
    // audio[0].pause();
    // audio[0].load();//suspends and restores all audio element
    //
    // //audio[0].play(); changed based on Sprachprofi's comment below
    // audio[0].oncanplaythrough = audio[0].play();
    // /****************/
  };


  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3">
        <table className="view-rounded sub_view_sm">
          <colgroup>
            <col width="220px"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>1.정상출입</th>
            <td>
              <div className="d-flex">
                <input type="file" id="userfile1" className="form-control" style={{maxWidth: '500px'}}/>
                {ret?.sound_1?.length > 0 &&
                  <>
                    {/*TODO : 임시로 숨겨놓은 영역 / 반드시 처리할 것*/}
                    {/*<button type="button" className="btn btn-ssm btn-outline-point mx-1" onClick={() => presound(ret?.sound_1)}>미리듣기</button>*/}
                    <div className="form-check form-check-inline mx-1">
                      <input className="form-check-input2" type="checkbox" name="chk1" value="Y" id="chk1"/>
                      <label className="form-check-label mx-1" htmlFor="chk1">삭제</label>
                    </div>
                  </>
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>2. 기간만료</th>
            <td>
              <div className="d-flex">
                <input type="file" id="userfile2" className="form-control" style={{maxWidth: '500px'}}/>
                {ret?.sound_2?.length > 0 &&
                  <>
                    {/*TODO : 임시로 숨겨놓은 영역 / 반드시 처리할 것*/}
                    {/*<button type="button" className="btn btn-ssm btn-outline-point mx-1" onClick={() => presound(ret?.sound_2)}>미리듣기</button>*/}
                    <div className="form-check form-check-inline mx-1">
                      <input className="form-check-input2" type="checkbox" name="chk2" value="Y" id="chk2"/>
                      <label className="form-check-label mx-1" htmlFor="chk2">삭제</label>
                    </div>
                  </>
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>3. 중복입장</th>
            <td>
              <div className="d-flex">
                <input type="file" id="userfile3" className="form-control" style={{maxWidth: '500px'}}/>
                {ret?.sound_3?.length > 0 &&
                  <>
                    {/*TODO : 임시로 숨겨놓은 영역 / 반드시 처리할 것*/}
                    {/*<button type="button" className="btn btn-ssm btn-outline-point mx-1" onClick={() => presound(ret?.sound_3)}>미리듣기</button>*/}
                    <div className="form-check form-check-inline mx-1">
                      <input className="form-check-input2" type="checkbox" name="chk3" value="Y" id="chk3"/>
                      <label className="form-check-label mx-1" htmlFor="chk3">삭제</label>
                    </div>
                  </>
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>4. 연기기간</th>
            <td>
              <div className="d-flex">
                <input type="file" id="userfile4" className="form-control" style={{maxWidth: '500px'}}/>
                {ret?.sound_4?.length > 0 &&
                  <>
                    {/*TODO : 임시로 숨겨놓은 영역 / 반드시 처리할 것*/}
                    {/*<button type="button" className="btn btn-ssm btn-outline-point mx-1" onClick={() => presound(ret?.sound_4)}>미리듣기</button>*/}
                    <div className="form-check form-check-inline mx-1">
                      <input className="form-check-input2" type="checkbox" name="chk4" value="Y" id="chk4"/>
                      <label className="form-check-label mx-1" htmlFor="chk4">삭제</label>
                    </div>
                  </>
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>5. 미납회원</th>
            <td>
              <div className="d-flex">
                <input type="file" id="userfile5" className="form-control" style={{maxWidth: '500px'}}/>
                {ret?.sound_5?.length > 0 &&
                  <>
                    {/*TODO : 임시로 숨겨놓은 영역 / 반드시 처리할 것*/}
                    {/*<button type="button" className="btn btn-ssm btn-outline-point mx-1" onClick={() => presound(ret?.sound_5)}>미리듣기</button>*/}
                    <div className="form-check form-check-inline mx-1">
                      <input className="form-check-input2" type="checkbox" name="chk5" value="Y" id="chk5"/>
                      <label className="form-check-label mx-1" htmlFor="chk5">삭제</label>
                    </div>
                  </>
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>6. 이용불가지점</th>
            <td>
              <div className="d-flex">
                <input type="file" id="userfile6" className="form-control" style={{maxWidth: '600px'}}/>
                {ret?.sound_6?.length > 0 &&
                  <>
                    {/*TODO : 임시로 숨겨놓은 영역 / 반드시 처리할 것*/}
                    {/*<button type="button" className="btn btn-ssm btn-outline-point mx-1" onClick={() => presound(ret?.sound_6)}>미리듣기</button>*/}
                    <div className="form-check form-check-inline mx-1">
                      <input className="form-check-input2" type="checkbox" name="chk6" value="Y" id="chk6"/>
                      <label className="form-check-label mx-1" htmlFor="chk6">삭제</label>
                    </div>
                  </>
                }
              </div>
            </td>
          </tr>
        </table>
        <div className="mt-2">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
        </div>
      </div>

      {/*<ReactAudioPlayer src={audioFile} autoplay controls />*/}
      <ReactAudioPlayer src={audioFile} autoplay />

    </CmnCard>
  );
};

export default ConfigSetSound;
