import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Constants from "../../constants/constants";


const EmployeeAceWork = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [row, setRow] = useState({});
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split('-')[0]);
    $('#s_month').val(Fn.getCurrentDate().split('-')[1]);

    // 현재 주차
    const cdate = new Date();
    const firstWeekday = new Date(cdate.getFullYear(), cdate.getMonth(), 1).getDay();
    const offsetDate = cdate.getDate() + firstWeekday - 1;
    $('#s_week').val(Math.floor(offsetDate / 7) + 1);
  }, []);

  useEffect(() => {
    load();
  }, [renew, srchRenew]);

  const load = () => {
    axios2
      .post(`/employee/ace/work/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&s_week=${$('#s_week option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRow(result?.row);

          if (result?.row?.img1_name?.length > 0)
            $('#img1_img').attr('src', Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=ace_img&filename=" + encodeURIComponent(result?.row?.img1_name)));
          if (result?.row?.img2_name?.length > 0)
            $('#img2_img').attr('src', Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=ace_img&filename=" + encodeURIComponent(result?.row?.img2_name)));
          if (result?.row?.img3_name?.length > 0)
            $('#img3_img').attr('src', Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=ace_img&filename=" + encodeURIComponent(result?.row?.img3_name)));

          $('#good_point').val(result?.row?.good_point);
          $('#bad_point').val(result?.row?.bad_point);
          $('#coaching').val(result?.row?.coaching);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-1.
  function go_submit() {
    const formData = new FormData();

    formData.append('img1', $('#userfile1')[0].files[0]);
    formData.append('img2', $('#userfile2')[0].files[0]);
    formData.append('img3', $('#userfile3')[0].files[0]);

    formData.append('s_year', $('#s_year option:selected').val() || '');
    formData.append('s_month', $('#s_month option:selected').val() || '');
    formData.append('s_week', $('#s_week option:selected').val() || '');
    formData.append('good_point', $('#good_point').val() || '');
    formData.append('bad_point', $('#bad_point').val() || '');
    formData.append('coaching', $('#coaching').val() || '');

    axios2.postFormEx('/employee/ace/work', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (code === 200) {
          if (data === "err") {
            alert('오류가 발생하였습니다');
          } else if (data === "not_img_auth") {
            alert("확장자가 gif,jpg,png 파일만 가능합니다");
          } else {
            alert("저장되었습니다");
            $('#form1')[0].reset();
            setRenew(Fn.getRenewTime());
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function del_pic(cnt) {
    const formData = new FormData();

    formData.append('type', 'del');
    formData.append('s_year', $('#s_year option:selected').val());
    formData.append('s_month', $('#s_month option:selected').val());
    formData.append('s_week', $('#s_week option:selected').val());
    formData.append('cnt', cnt);

    axios2.postFormEx('/employee/ace/work', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        $('#form1')[0].reset();
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
    //
    // axios2
    //   .postEx('/employee/ace/work', {
    //   })
    //   .then((response) => response.json())
    //   .then(({code, message: data, result, pageInfo}) => {
    //     setRenew(Fn.getRenewTime());
    //   })
    //   .catch((error) => {
    //     if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
    //   });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/employee/ace/work")}>고객유치</label>
          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/employee/ace/report")}>고객유지</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}년</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}월</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_week">
            {Array.from({length: 6}, (_, index) => index + 1).map((week) => (
              <option key={week} value={week}>{week}주</option>
            ))}
          </select>
        </div>

        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3">
        <div className="subprice_item">
          <div className="border-bottom text-left">
            <p className="px-3 py-2"><span className="h7 bold700">활동 POINT</span></p>
          </div>

          <div className="m-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border">
              <tr>
                <th>Good Point</th>
                <th>Bad Point</th>
                <th>지부장 코칭내용</th>
              </tr>
              <tr>
                <td className="pb-0"><textarea className="txtarea" style={{minHeight: '160px'}} id="good_point"></textarea></td>
                <td className="pb-0"><textarea className="txtarea" style={{minHeight: '160px'}} id="bad_point"></textarea></td>
                <td className="pb-0"><textarea className="txtarea" style={{minHeight: '160px'}} id="coaching"></textarea></td>
              </tr>
            </table>
          </div>
        </div>
        <div className="subprice_item mt-3">
          <div className="border-bottom text-left d-flex justify-content-between">
            <p className="px-3 py-2"><span className="h7 bold700">활동이미지</span> *홍보활동 계획서, 실제 홍보활동 사진 등</p>
            <p className="px-3 py-2"></p>
          </div>

          <form name="form1" id="form1" style={{margin: 0}}>
            <div className="m-3">
              {/* 회원목록 */}
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="110px"/>
                  <col width="450px"/>
                  <col width="*"/>
                </colgroup>

                <tr>
                  <th>첨부1</th>
                  <td>
                    <input type="file" className="form-control" id="userfile1"/>
                    <div className="inline d-flex justify-content-center">
                      {/*<button type="button" className="btn btn-ssm btn-outline-point">업로드</button>*/}
                    </div>
                  </td>
                  <td className="text-left">
                    {row?.img1_name?.length > 0 ?
                      <>
                        <img id="img1_img" style={{width: "160px", objectFit: "contain"}}/>
                        <button type="button" className="ml-2 btn btn-ssm btn-point" onClick={() => del_pic('1')}>삭제</button>
                      </>
                      :
                      <>
                        <div className="IMG_thum mt-1" style={{width: '240px'}}></div>
                      </>
                    }
                  </td>
                </tr>
                <tr>
                  <th>첨부2</th>
                  <td>
                    <input type="file" className="form-control" id="userfile2"/>
                    <div className="inline d-flex justify-content-center">
                      {/*<button type="button" className="btn btn-ssm btn-outline-point">업로드</button>*/}
                    </div>
                  </td>
                  <td className="text-left">
                    {row?.img2_name?.length > 0 ?
                      <>
                        <img id="img2_img" style={{width: "160px", objectFit: "contain"}}/>
                        <button type="button" className="ml-2 btn btn-ssm btn-point" onClick={() => del_pic('2')}>삭제</button>
                      </>
                      :
                      <>
                        <div className="IMG_thum mt-1" style={{width: '240px'}}></div>
                      </>
                    }
                  </td>
                </tr>
                <tr>
                  <th>첨부3</th>
                  <td>
                    <input type="file" className="form-control" id="userfile3"/>
                    <div className="inline d-flex justify-content-center">
                      {/*<button type="button" className="btn btn-ssm btn-outline-point">업로드</button>*/}
                    </div>
                  </td>
                  <td className="text-left">
                    {row?.img3_name?.length > 0 ?
                      <>
                        <img id="img3_img" style={{width: "160px", objectFit: "contain"}}/>
                        <button type="button" className="ml-2 btn btn-ssm btn-point" onClick={() => del_pic('3')}>삭제</button>
                      </>
                      :
                      <>
                        <div className="IMG_thum mt-1" style={{width: '240px'}}></div>
                      </>
                    }
                  </td>
                </tr>
              </table>
            </div>
          </form>
        </div>
      </div>

      <div className="m-4 text-center">
        <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
      </div>
    </CmnCard>
  );
};

export default EmployeeAceWork;
