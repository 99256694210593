import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import {useHistory} from "react-router-dom";

const SecureMenuAuthHistory = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 9) {
      alert('메뉴 접근 권한이 없습니다.');
      history.goBack();
      return;
    }

    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/secure/menu/auth/history/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box" style={{maxWidth: "935px"}}>
        <div className="input-group pr-1" style={{width: "auto"}}>
          <p className="p-1">수정일 :</p>
          <JDatePicker id="s_date1"/>
          <p className="mx-2">~</p>
          <JDatePicker id="s_date2"/>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" placeholder="권한그룹 검색" id="key"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3 pb-2" style={{maxWidth: "935px"}}>
        <table className="view-rounded sub_table_border auto">
          <colgroup>
            <col width="65px"/>
            <col width="160px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="100px"/>
            <col width="100px"/>
          </colgroup>

          <tr>
            <th>seq</th>
            <th>수정일시</th>
            <th>기존권한그룹명</th>
            <th>현재권한그룹명</th>
            <th>수정자</th>
            <th>기존권한</th>
            <th>현재권한</th>
          </tr>
          {
            rets.map((row, i) => {
              const seq = row.seq;
              const auth_name = row.auth_name;
              const last_reg_date = row.last_reg_date;
              const before_name = row.before_name;
              const edit_user = row.edit_user;

              return (
                <tr key={seq}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>{last_reg_date.replaceAll("-", ".")}</td>
                  <td>{auth_name}</td>
                  <td>{before_name}</td>
                  <td>{edit_user}</td>
                  <td>
                    <input
                      className="btn btn-ssm btn-outline-dark"
                      type="button"
                      value="기존정보"
                      // onClick={() =>
                      //   location.href = `menu_auth_detail.html?base_seq=${btoa(seq)}&page=${page}&${link_param}&type=before`
                      // }
                      onClick={() => {
                        history.push({
                          pathname: '/secure/menu/auth/detail', state: {
                            baseSeq: btoa(seq),
                            type: "before"
                          }
                        });
                      }}
                    />
                  </td>
                  <td>
                    <input
                      className="btn btn-ssm btn-outline-point"
                      type="button"
                      value="현재정보"
                      // onClick={() =>
                      //   location.href = `menu_auth_detail.html?base_seq=${btoa(seq)}&page=${page}&${link_param}&type=after`
                      // }
                      onClick={() => {
                        history.push({
                          pathname: '/secure/menu/auth/detail', state: {
                            baseSeq: btoa(seq),
                            type: "current"
                          }
                        });
                      }}
                    />
                  </td>
                </tr>
              );
            })
          }
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={7}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="m-3 p-3 mb-4 d-flex justify-content-center border-top flex-wrap" style={{maxWidth: "935px"}}>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>
    </CmnCard>
  );
};

export default SecureMenuAuthHistory;
