import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import $ from "jquery";
import JDatePicker from "../common/JDatePicker";
import Modal from "react-modal";
import Paging from "../common/Paging";


const FinanceFranInfo = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [result, setResult] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);

  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [modal4Open, setModal4Open] = useState(false);
  const [modal5Open, setModal5Open] = useState(false);

  const [modal1Result, setModal1Result] = useState();
  const [modal2Result, setModal2Result] = useState();
  const [modal3Result, setModal3Result] = useState();
  const [modal4Result, setModal4Result] = useState();
  const [modal5Result, setModal5Result] = useState();

  const [modal1CurrPage, setModal1CurrPage] = useState(1);
  const [modal2CurrPage, setModal2CurrPage] = useState(1);
  const [modal3CurrPage, setModal3CurrPage] = useState(1);
  const [modal4CurrPage, setModal4CurrPage] = useState(1);
  const [modal5CurrPage, setModal5CurrPage] = useState(1);

  const [modal1PageInfo, setModal1PageInfo] = useState(1);
  const [modal2PageInfo, setModal2PageInfo] = useState(1);
  const [modal3PageInfo, setModal3PageInfo] = useState(1);
  const [modal4PageInfo, setModal4PageInfo] = useState(1);
  const [modal5PageInfo, setModal5PageInfo] = useState(1);

  const [modal1SrchRenew, setModal1SrchRenew] = useState(0);
  const [modal2SrchRenew, setModal2SrchRenew] = useState(0);
  const [modal3SrchRenew, setModal3SrchRenew] = useState(0);
  const [modal4SrchRenew, setModal4SrchRenew] = useState(0);
  const [modal5SrchRenew, setModal5SrchRenew] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/finance/fran/info/ready?tbl_gubun=now&s_brand=0000&s_jibu=0000&s_date1=2023-10-30&s_date2=2023-10-30`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_brand').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_brand').html(optionsHTML);
          }
          if ($('#s_jibu').html() === '') {
            let optionsHTML = '<option value="">지부선택</option>';
            result?.jibu_rs?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.jibu}">${list_row.jibu}</option>`;
            });
            $('#s_jibu').html(optionsHTML);
          }

          // $('#s_date1').val('2023-02-01');
          // $('#s_date2').val('2023-02-28');

          axios2
            .post(`/finance/fran/info/ready?tbl_gubun=${$('#tbl_gubun option:selected').val()}&s_brand=${$('#s_brand option:selected').val()}&s_jibu=${$('#s_jibu option:selected').val()}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`)
            .then((response) => {
              const {code, message, result, pageInfo} = response?.data;
              if (code === 200) {
                setResult(result);
              }
            })
            .catch((error) => {
              if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
            });
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }


  const loadModal1 = (page = modal1CurrPage) => {
    axios2
      .post(`/finance/fran/fee/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_branch_code=${$('#branch_code').val()}&page=${page}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setModal1Result(result);
          setModal1PageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal2 = (page = modal2CurrPage) => {
    axios2
      .post(`/finance/fran/minus/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_branch_code=${$('#branch_code').val()}&page=${page}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModal2Result(result);
          setModal2PageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal3 = (page = modal3CurrPage) => {
    axios2
      .post(`/finance/fran/plus/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_branch_code=${$('#branch_code').val()}&page=${page}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModal3Result(result);
          setModal3PageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal4 = (page = modal4CurrPage) => {
    axios2
      .post(`/finance/fran/rprice/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_branch_code=${$('#branch_code').val()}&page=${page}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModal4Result(result);
          setModal4PageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal5 = (page = modal5CurrPage) => {
    axios2
      .post(`/finance/fran/sprice/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_branch_code=${$('#branch_code').val()}&page=${page}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModal5Result(result);
          setModal5PageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }


  //1-1
  const [branchCode, setBranchCode] = useState("");
  const pop_fee = () => {
    const url = `fran_fee.html?s_date1=<?=$s_date1?>&s_date2=<?=$s_date2?>&s_branch_code=${branchCode}`;
    const windowFeatures = 'width=450,height=700,scrollbars=yes';
    window.open(url, 'fran_fee', windowFeatures);
  };

  //2-1
  const pop_minus_point = () => {
    const url = `fran_minus.html?s_date1=<?=$s_date1?>&s_date2=<?=$s_date2?>&s_branch_code=${branchCode}`;
    const windowFeatures = 'width=600,height=770,scrollbars=yes';
    window.open(url, 'fran_minus', windowFeatures);
  };

  //3-1
  const pop_plus_point = () => {
    const url = `fran_plus.html?s_date1=<?=$s_date1?>&s_date2=<?=$s_date2?>&s_branch_code=${branchCode}`;
    const windowFeatures = 'width=600,height=770,scrollbars=yes';
    window.open(url, 'fran_plus', windowFeatures);
  };

  //4-1
  const pop_r_price = () => {
    const url = `fran_rprice.html?s_date1=<?=$s_date1?>&s_date2=<?=$s_date2?>&s_branch_code=${branchCode}`;
    const windowFeatures = 'width=490,height=700,scrollbars=yes';
    window.open(url, 'fran_rprice', windowFeatures);
  };

  //5-1
  const pop_s_price = () => {
    const url = `fran_sprice.html?s_date1=<?=$s_date1?>&s_date2=<?=$s_date2?>&s_branch_code=${branchCode}`;
    const windowFeatures = 'width=490,height=700,scrollbars=yes';
    window.open(url, 'fran_sprice', windowFeatures);
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="tbl_gubun">
            <option value="now">현재</option>
            <option value="2021">2021</option>
            <option value="2020">2020</option>
            <option value="2019">2019</option>
          </select>
        </div>

        <div className="d-flex pr-1">
          <select className="form-select" id="s_brand">
          </select>
        </div>

        <div className="d-flex pr-1">
          <select className="form-select" id="s_jibu">
          </select>
        </div>

        <div className="d-flex pr-1">
          <JDatePicker className="form-control" id="s_date1"/>
          <p className="mx-2">~</p>
          <JDatePicker className="form-control" id="s_date2"/>
        </div>

        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <input type="hidden" id="branch_code"/>

      <div className="m-3 pb-2">
        <table className="view-rounded sub_table_border auto">
          <tr>
            <th className="w_70px">No</th>
            <th>가맹점명</th>
            <th>본사수수료(-)</th>
            <th>포인트정산(+)</th>
            <th>포인트적립½(-)</th>
            <th>타지점정산(+)</th>
            <th>타지점이용(-)</th>
            <th>합계</th>
          </tr>

          {result?.my_branch_name?.map((row, i) => {
            return (
              <tr>
                <td>{row?.count}</td>
                <td>{row?.branch_name}</td>
                <td className="text-right">
                  <a style={{cursor: "pointer"}} onClick={() => {
                    $('#branch_code').val(row?.branch_code);
                    setModal1Open(true);
                  }}>
                    {Fn.numberWithCommas(row?.fee_price)}
                  </a>
                </td>
                <td className="text-right">
                  <a style={{cursor: "pointer"}} onClick={() => {
                    $('#branch_code').val(row?.branch_code);
                    setModal2Open(true);
                  }}>
                    {Fn.numberWithCommas(row?.minus_point)}
                  </a>
                </td>
                <td className="text-right">
                  <a style={{cursor: "pointer"}} onClick={() => {
                    $('#branch_code').val(row?.branch_code);
                    setModal3Open(true);
                  }}>
                    {Fn.numberWithCommas(row?.plus_point)}
                  </a>
                </td>
                <td className="text-right">
                  <a style={{cursor: "pointer"}} onClick={() => {
                    $('#branch_code').val(row?.branch_code);
                    setModal4Open(true);
                  }}>
                    {Fn.numberWithCommas(row?.r_price)}
                  </a>
                </td>
                <td className="text-right">
                  <a style={{cursor: "pointer"}} onClick={() => {
                    $('#branch_code').val(row?.branch_code);
                    setModal5Open(true);
                  }}>
                    {Fn.numberWithCommas(row?.s_price)}
                  </a>
                </td>
                <td className="text-right">{Fn.numberWithCommas(row?.t_price)}</td>
              </tr>
            );
          })}
          {(!result?.my_branch_name || result?.my_branch_name?.length === 0) &&
            <tr>
              <td colSpan={8}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>


      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          setModal1CurrPage(1);
          loadModal1();
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '700px'}}>
            <div className="POP_title">
              <h5><strong>본사수수료</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box">
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <colgroup>
                    <col width="50%"/>
                    <col width="50%"/>
                  </colgroup>
                  <thead>
                  <tr>
                    <th>계정</th>
                    <th>금액</th>
                  </tr>
                  </thead>
                  <tbody>
                  {modal1Result?.aryMC && Object.keys(modal1Result?.aryMC).map((k, index) => {
                    k = k.replace("_", "");
                    const v = modal1Result?.aryMC?.[k];
                    // tMC += modal1Result?.mc['4113-'+k] || 0;

                    return (
                      <tr>
                        <td className="txt_left">{v}</td>
                        <td className="text-right">
                          {modal1Result?.mc['4113-' + k] ? Fn.numberWithCommas(modal1Result?.mc['4113-' + k]) : ''}
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td>타지점정산</td>
                    <td className="text-right">{modal1Result?.jgain8 ? Fn.numberWithCommas(modal1Result?.jgain8) : ''}</td>
                  </tr>
                  <tr>
                    <td>물품판매</td>
                    <td className="text-right">{modal1Result?.jgain7 ? Fn.numberWithCommas(modal1Result?.jgain7) : ''}</td>
                  </tr>
                  <tr>
                    <td>잡이익</td>
                    <td className="text-right">{modal1Result?.jgain2 ? Fn.numberWithCommas(modal1Result?.jgain2) : ''}</td>
                  </tr>
                  <tr>
                    <td>미등록</td>
                    <td className="text-right">{modal1Result?.etc ? Fn.numberWithCommas(modal1Result?.etc) : ''}</td>
                  </tr>
                  <tr className="bg_yellow1">
                    <td>현장매출 합계</td>
                    <td
                      className="text-right">{Fn.numberWithCommas(modal1Result?.tMC - modal1Result?.jgain - modal1Result?.jgain3 - modal1Result?.jgain4 - modal1Result?.jgain5 - modal1Result?.jgain6 - modal1Result?.jgain7)}</td>
                  </tr>
                  <tr className="bg_yellow1">
                    <td>수수료율</td>
                    <td className="text-right">{modal1Result?.fran_rate != 0 ? Fn.numberWithCommas(modal1Result?.fran_rate) + "%" : ''}</td>
                  </tr>
                  <tr className="bg_yellow1">
                    <td>본사수수료</td>
                    <td className="text-right">{modal1Result?.fee != 0 ? Fn.numberWithCommas(modal1Result?.fee) : ''}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div className="text-center my-3">
                <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal1Open(false)}>닫기</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>


      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          setModal2CurrPage(1);
          loadModal2();
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '800px'}}>
            <div className="POP_title">
              <h5><strong>포인트 정산</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box">
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <thead>
                  <tr>
                    <th className="w_70px">No</th>
                    <th>대상일</th>
                    <th>회원명</th>
                    <th>회원번호</th>
                    <th>포인트</th>
                    <th>내용</th>
                  </tr>
                  </thead>
                  <tbody>
                  {modal2Result?.list && modal2Result?.list?.map((ret, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ret?.basic_date}</td>
                        <td>{ret?.mb_name || ''}</td>
                        <td>{ret?.mb_id || ''}</td>
                        <td>{Fn.numberWithCommas(ret?.point)}</td>
                        <td>{ret?.gubun}</td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              {modal2Result?.pageInfo?.lastPage > 1 ?
                <nav className="p-3 mb-0 d-flex justify-content-between flex-wrap">
                  <Paging pageInfo={modal2Result?.pageInfo} setCurrPage={page => {
                    setModal2CurrPage(page);
                    loadModal2(page);
                  }}/>
                  <div>
                    <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal2Open(false)}>닫기</button>
                  </div>
                </nav>
                :
                <div className="text-center my-3">
                  <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal2Open(false)}>닫기</button>
                </div>
              }
            </div>
          </div>
        </div>
      </Modal>


      <Modal
        isOpen={modal3Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          setModal3CurrPage(1);
          loadModal3();
        }}
        onRequestClose={() => setModal3Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '800px'}}>
            <div className="POP_title">
              <h5><strong>포인트 적립</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal3Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box">
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <thead>
                  <tr>
                    <th className="w_70px">No</th>
                    <th>대상일</th>
                    <th>회원명</th>
                    <th>회원번호</th>
                    <th>포인트</th>
                    <th>내용</th>
                  </tr>
                  </thead>
                  <tbody>
                  {modal3Result?.list && modal3Result?.list?.map((ret, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ret?.basic_date}</td>
                        <td>{ret?.mb_name || ''}</td>
                        <td>{ret?.mb_id || ''}</td>
                        <td>{Fn.numberWithCommas(ret?.point)}</td>
                        <td>{ret?.gubun}</td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              {modal3Result?.pageInfo?.lastPage > 1 ?
                <nav className="p-3 mb-0 d-flex justify-content-between flex-wrap">
                  <Paging pageInfo={modal3Result?.pageInfo} setCurrPage={page => {
                    setModal3CurrPage(page);
                    loadModal3(page);
                  }}/>
                  <div>
                    <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal3Open(false)}>닫기</button>
                  </div>
                </nav>
                :
                <div className="text-center my-3">
                  <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal3Open(false)}>닫기</button>
                </div>
              }
            </div>
          </div>
        </div>
      </Modal>


      <Modal
        isOpen={modal4Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          setModal4CurrPage(1);
          loadModal4();
        }}
        onRequestClose={() => setModal4Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '700px'}}>
            <div className="POP_title">
              <h5><strong>타지점정산(+)</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal4Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box">
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <colgroup>
                    <col width="70"/>
                    <col width="250"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>
                  <thead>
                  <tr>
                    <th className="w_70px">No</th>
                    <th>지점명</th>
                    <th>횟수</th>
                    <th>금액</th>
                  </tr>
                  </thead>
                  <tbody>
                  {modal4Result?.result && modal4Result?.result?.map((ret, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ret?.branch_name}</td>
                        <td className="text-right">{ret?.cnts || ''}</td>
                        <td className="text-right">{ret?.new_price || ''}</td>
                      </tr>
                    );
                  })}
                  <tr style={{background: "rgb(255, 255, 221)"}}>
                    <td colSpan={2}>합계</td>
                    <td className="text-right">{Fn.numberWithCommas(modal4Result?.tot_cnts)}</td>
                    <td className="text-right">{Fn.numberWithCommas(modal4Result?.tot_price)}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div className="text-center my-3">
                <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal4Open(false)}>닫기</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>


      <Modal
        isOpen={modal5Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          setModal5CurrPage(1);
          loadModal5();
        }}
        onRequestClose={() => setModal5Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '700px'}}>
            <div className="POP_title">
              <h5><strong>타지점이용(-)</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal5Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box">
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <colgroup>
                    <col width="70"/>
                    <col width="250"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>
                  <thead>
                  <tr>
                    <th className="w_70px">No</th>
                    <th>지점명</th>
                    <th>횟수</th>
                    <th>금액</th>
                  </tr>
                  </thead>
                  <tbody>
                  {modal5Result?.result && modal5Result?.result?.map((ret, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ret?.branch_name}</td>
                        <td className="text-right">{ret?.cnts || ''}</td>
                        <td className="text-right">{ret?.new_price || ''}</td>
                      </tr>
                    );
                  })}
                  <tr style={{background: "rgb(255, 255, 221)"}}>
                    <td colSpan={2}>합계</td>
                    <td className="text-right">{Fn.numberWithCommas(modal5Result?.tot_cnts)}</td>
                    <td className="text-right">{Fn.numberWithCommas(modal5Result?.tot_price)}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div className="text-center my-3">
                <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal5Open(false)}>닫기</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>


    </CmnCard>
  );
};

export default FinanceFranInfo;
