import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import search from "../../images/search.svg";
import axios from "axios";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import Modal from 'react-modal';


const MemberCenterChange = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [searchTxt, setSearchTxt] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => {
          $(this).blur(); // 2023.10.26 중요
          setSrchRenew(Fn.getRenewTime)
        });
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    if (srchRenew > 0)
      $('#search_YN').val('Y');
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    if ($('#search_txt')?.val()?.length < 2) {
      $('#search_YN').val('N');
      return;
    } else {
      $('#search_YN').val('Y');
    }

    setSearchTxt($('#search_txt').val());

    axios2
      .post(`/member/center/change/ready?page=${_page || currPage}&search_YN=${$('#search_YN').val()}&search_type=&reg_step=&search_txt=${$('#search_txt').val()}&search_auth_code=&auth_code=`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          if (result?.result?.length > 100) {
            alert('검색결과가 100건 이상입니다. 핸드폰 혹은 카드번호로 조회해주세요');
          } else {
            console.log(result?.result);
            setRets(result?.result);
            setPageInfo(pageInfo);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/member/change/center/ready?before_center=${$('#modal_branch_name').val()}&mb_no=${$('#modal_mb_no').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {

          if (result?.reman_pt > 0 && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 8) {
            alert('잔여PT가 있는경우 주센터 변경이 불가능합니다.');
            setModalOpen(false);
            return;
          }
          if ((result?.mm_row?.locker == "1" || result?.mm_row?.locker == "2") && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 8) {
            alert('라커비움처리후 주센터 변경 가능합니다.');
            setModalOpen(false);
            return;
          }
          if ((result?.mm_row?.g_locker_name && result?.mm_row?.locker == "1") && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 8) {
            alert('골프라커비움처리후 주센터 변경 가능합니다.');
            setModalOpen(false);
            return;
          }

          const currentDate = new Date();
          const monthsAgo = new Date(currentDate);
          if ((localStorage.getItem("USER.systemID") === "futureinfo" ||
              localStorage.getItem("USER.systemID") === "spoany" ||
              localStorage.getItem("USER.systemID") === "testjjj" ||
              localStorage.getItem("USER.systemID") === "testst" ||
              localStorage.getItem("USER.systemID") === "tpwls8905") ||
            Fn.getCurrentDate() >= '2024-01-01'
          ) {
            monthsAgo.setMonth(monthsAgo.getMonth() - 3);
          } else {
            monthsAgo.setMonth(monthsAgo.getMonth() - 1);
          }
          const before_date = Fn.getDate(monthsAgo)

          if (result?.end_date >= Fn.getCurrentDate()) {
            if (result?.LL_row?.ap_date > before_date && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 7) {
              if ((localStorage.getItem("USER.systemID") === "futureinfo" ||
                  localStorage.getItem("USER.systemID") === "spoany" ||
                  localStorage.getItem("USER.systemID") === "testjjj" ||
                  localStorage.getItem("USER.systemID") === "testst" ||
                  localStorage.getItem("USER.systemID") === "tpwls8905") ||
                Fn.getCurrentDate() >= '2024-01-01'
              ) {
                alert('주센터 가입후 3달 이내는 주센터 변경이 불가능합니다');
              } else {
                alert('주센터 가입후 1달 이내는 주센터 변경이 불가능합니다');
              }
              setModalOpen(false);
              return;
            }
            if (result?.LL_row2?.hi_date) {
              const tMonth = new Date(result?.LL_row2?.hi_date);
              tMonth.setMonth(tMonth.getMonth() + 3);
              const afterDate = Fn.getDate(tMonth);

              if (Fn.getCurrentDate() < afterDate && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 7) {
                alert('주센터 변경후 3달 이내는 주센터 변경이 불가능합니다.');
                setModalOpen(false);
                return;
              }
            }
          }

          if ($('#modal_t_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.topList1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}/${list_row.branch_name}">${list_row.ho}.${list_row.branch_name}</option>`;
            });
            $('#modal_t_branch_code').html(optionsHTML);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const modal_go_submit = () => {
    if (!$('#modal_t_branch_code option:selected').val()) {
      alert('변경하려는 지점을 선택해주세요')
      $('#modal_t_branch_code').focus();
      return;
    }

    if (!window.confirm('지점을 변경하시겠습니까?')) return;

    axios2.postEx('/member/change/center', {
      auth_code: $('#auth_code').val(),
      receive_name: $('#reg_step option:selected').val(),
      mb_no: $('#modal_mb_no').val(),
      t_branch_code: $('#modal_t_branch_code option:selected').val() || '',
    })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === 'ok') {
          alert('변경되었습니다.');
          setRenew(Fn.getRenewTime());
          setModalOpen(false);
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
          setModalOpen(false);
        }
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>

      <input type="hidden" id="search_YN" value=""/>

      <div className="d-flex m-3 justify-content-between">
        <div className="d-flex fn_search_box">
          <div>
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="카드/이름/연락처 2글자 이상 검색"
              id="search_txt"
              style={{width: "250px"}}
            />
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
        </div>
      </div>

      <div className="m-3 table_list">
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>지점명</th>
            <th>성명</th>
            <th>카드번호</th>
            <th>연락처</th>
            <th>가입일</th>
            <th>시작일</th>
            <th>종료일</th>
            <th>운동복</th>
            <th>라커</th>
            <th>변경</th>
          </tr>
          {rets && rets.map((row, i) => {

            const currentDate = new Date();
            const monthsAgo = new Date(currentDate);
            if ((localStorage.getItem("USER.systemID") === "futureinfo" ||
                localStorage.getItem("USER.systemID") === "spoany" ||
                localStorage.getItem("USER.systemID") === "testjjj" ||
                localStorage.getItem("USER.systemID") === "testst" ||
                localStorage.getItem("USER.systemID") === "tpwls8905") ||
              Fn.getCurrentDate() >= '2024-01-01'
            ) {
              monthsAgo.setMonth(monthsAgo.getMonth() - 3);
            } else {
              monthsAgo.setMonth(monthsAgo.getMonth() - 1);
            }
            const before_date = Fn.getDate(monthsAgo)


            let mb_hp = row?.mb_hp;  // Assuming 'row' is an object in JS with the 'mb_hp' property

            mb_hp = mb_hp?.replace(/-/g, "");  // Remove all hyphens

            let mb_hp_show = "***-****-****";

            if (mb_hp.length === 11) {
              mb_hp_show = `${mb_hp.substring(0, 3)}-${mb_hp.substring(3, 7)}-${mb_hp.substring(7)}`;
            } else if (mb_hp.length === 10) {
              mb_hp_show = `${mb_hp.substring(0, 3)}-${mb_hp.substring(3, 6)}-${mb_hp.substring(6)}`;
            } else {
              mb_hp_show = mb_hp;
            }

            let reman_pt = (row?.rePt || 0) - (row?.taPt || 0)

            let locker_date = "";
            if (row?.locker_start_date?.length > 0 && row?.locker_end_date?.length > 0)
              locker_date = (row?.locker_start_date || '').replace(/-/g, '.').substr(2, 8) + " ~ " + (row?.locker_end_date || '').replace(/-/g, '.').substr(2, 8);

            let suit_date = "";
            if (row?.suit_start_date?.length > 0 && row?.suit_end_date?.length > 0)
              suit_date = (row?.suit_start_date || '').replace(/-/g, '.').substr(2, 8) + " ~ " + (row?.suit_end_date || '').replace(/-/g, '.').substr(2, 8);

            return (
              <tr>
                <td>{i + 1}</td>
                <td className="text-left">{row?.branch_name}</td>
                <td>{row?.mb_name}</td>
                <td>{row?.mb_id}</td>
                <td>{mb_hp_show}</td>
                <td>{(row?.mb_open_date || '').replace(/-/g, '.').substr(2, 8)}</td>
                <td>{row?.start_date}</td>
                <td>{row?.end_date}</td>
                <td>{suit_date}</td>
                <td><a style={{cursor: row?.locker_name?.length > 0 ? "pointer" : undefined}} onClick={() => row?.locker_name?.length > 0 ? alert(row?.locker_name) : ""}>{locker_date}</a></td>
                <td>
                  <button type="button" className="btn btn-ssm btn-outline-point px-2" onClick={() => {
                    if (reman_pt > 0) {
                      alert('잔여PT가 있는경우 주센터 변경이 불가능합니다.');
                      return;
                    } else if (row?.locker == '1' || row?.locker == '2') {
                      alert('라커비움처리후 주센터 변경 가능합니다.');
                      return;
                    } else if (row?.g_locker_name && row?.g_locker == '1') {
                      alert('골프라커비움처리후 주센터 변경 가능합니다.');
                      return;
                    } else if (row?.LL?.ap_date > before_date) {
                      if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7) {
                      } else {
                        if (row?.end_date < Fn.getCurrentDate()) {
                        } else {
                          if ((localStorage.getItem("USER.systemID") === "futureinfo" ||
                              localStorage.getItem("USER.systemID") === "spoany" ||
                              localStorage.getItem("USER.systemID") === "testjjj" ||
                              localStorage.getItem("USER.systemID") === "testst" ||
                              localStorage.getItem("USER.systemID") === "tpwls8905") ||
                            Fn.getCurrentDate() >= '2024-01-01'
                          ) {
                            alert('주센터 가입후 3달 이내는 주센터 변경이 불가능합니다');
                          } else {
                            alert('주센터 가입후 1달 이내는 주센터 변경이 불가능합니다');
                          }
                          return;
                        }
                      }
                    }
                    console.log(row?.end_date, Fn.getCurrentDate());

                    if ($('#auth_code').val() == "") {
                      alert('인증코드를 입력해주세요');
                      $('#search_auth_code').focus();
                      return;
                    }

                    $('#modal_mb_no').val(row?.mb_no);
                    $('#modal_branch_name').val(row?.branch_name);
                    setModalOpen(true);
                  }}>변경
                  </button>
                </td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              {searchTxt ?
                <td colSpan={11}>※ 등록된 data가 없습니다.</td>
                :
                <td colSpan={11}>※ 검색 버튼을 클릭해 주세요.</td>
              }
            </tr>
          }
        </table>
      </div>

      <input type="hidden" id="modal_mb_no"/>
      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '420px'}}>
            <div className="POP_title">
              <h5><strong>주센터변경</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_view">
                <colgroup>
                  <col width="108px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>지점명</th>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="modal_t_branch_code">
                    </select>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => {
                modal_go_submit();
              }}>변경
              </button>
              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModalOpen(false)}>취소</button>
            </div>
          </div>
        </div>
      </Modal>
    </CmnCard>
  );
};

export default MemberCenterChange;
