import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";
import JDatePicker from "../common/JDatePicker";

const MvRefundCal = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();

  const [rc_form, setRcForm] = useState();

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const load = () => {
    setRcForm($("form[name='rc_form']")[0]);
    const rcForm = $("form[name='rc_form']")[0];

    axios2
      .post(`/member/refund/cal/ready?mb_no=${props.mbNo}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          console.log(result);

          if ($('#incentive_reg_step').html() === '') {
            let optionsHTML = '<option value="">스텝 선택</option>';
            result?.regStepList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}/${list_row.user_name}">${list_row.user_name}</option>`;
            });
            $('#incentive_reg_step').html(optionsHTML);
          }

          rcForm.refund_start_date.value = result?.refundStartDate;
          useday_calc(result?.refundStartDate, "refund_use_date");
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  function useday_calc(start_date, input_name) { //사용일자 계산. 시작일과 객체명.
    var t = new Date();
    var sdate_arr = start_date.split("-");
    var s_day = new Date(sdate_arr[0], sdate_arr[1] - 1, sdate_arr[2]);
    var start_day = s_day.getTime();


    //변경
    var pdt_date = $("#pdt_date").val();
    var Tdate_arr = pdt_date.split("-");
    var Ts_day = new Date(Tdate_arr[0], Tdate_arr[1] - 1, Tdate_arr[2]);
    var to_day = Ts_day.getTime();

    //기존
    var useday = Math.round((to_day - start_day) / (1000 * 60 * 60 * 24));

    useday = useday - (result?.delayDay || 0);
    if (to_day > start_day) {
      document.getElementById(input_name).value = useday;
    } else {
      document.getElementById(input_name).value = 0;
    }
  }

  function result_calc() {
    //사용일 재정의
    var dts = rc_form.refund_start_date.value;
    useday_calc(dts, "refund_use_date")

    var use_date = rc_form.refund_use_date.value;

    if (use_date == "" || use_date == "0") {
      if (!window.confirm('사용일수가 0일 입니다. 계속 진행하시겠습니까?')) return false;
    }

    if (!rc_form.pay_amount.value || rc_form.pay_amount.value == 0) {
      alert("결제금액을 입력하세요.");
      rc_form.pay_amount.focus();
      return false;
    }

    var ndx = rc_form.refund_kind.selectedIndex;
    var selected = parseInt(rc_form.refund_kind.options[ndx].value);
    var ndx1 = rc_form.refund_part.selectedIndex;
    var selected1 = parseInt(rc_form.refund_part.options[ndx1].value);

    var refund_use_date = parseInt(rc_form.refund_use_date.value);
    var pay_amount = parseInt(rc_form.pay_amount.value.replace(/,/g, ""));

    var penalty_day_amount = refund_use_date * selected1;
    var penalty_per_amount = pay_amount * (selected / 100);

    var penalty_amount = penalty_day_amount + penalty_per_amount;
    var return_amount = pay_amount - penalty_amount

    penalty_amount = Math.round(penalty_amount);
    return_amount = Math.round(return_amount);

    rc_form.real_penalty_amount.value = penalty_amount;

    document.getElementById("penalty_amount").innerHTML = "<font color='red'><b>" + Fn.commaNum(penalty_amount) + "원</b></font>";


    var add_wi = parseInt(rc_form.add_wi.value.replace(/,/g, ""));

    if (add_wi) {
      return_amount = return_amount - add_wi;
    }

    if (penalty_amount > pay_amount) {//위약금이 결제금액보다 크면
      //document.getElementById("refund_amount").innerHTML = "<b>0원</b>";
      rc_form.refund_amount.value = 0;
    } else {
      //document.getElementById("refund_amount").innerHTML = "<b>"+Fn.commaNum(return_amount)+"원</b>";
      rc_form.refund_amount.value = Fn.commaNum(return_amount);
    }
  }

  function Set_Comma(n) {
    return Number(String(n).replace(/\..*|[^\d]/g, "")).toLocaleString().slice(0, -3);
  }

  function go_submit() {
    rc_form.auth_code.value = $('#auth_code').val();
    rc_form.reg_step.value = $('#_reg_step option:selected').val() || '';

    if (rc_form.pdt_date.value == "") {
      alert('환불일자를 선택해주세요');
      rc_form.pdt_date.focus();
      return;
    }
    if (rc_form.refund_amount.value == "" || rc_form.refund_amount.value == "0") {
      alert('환불금액을 선택해주세요');
      rc_form.refund_amount.focus();
      return;
    }

    if (rc_form.incentive_reg_step.value != "") {
      if (rc_form.incentive_amount.value == "" || rc_form.incentive_amount.value == "0") {
        alert('차감대상이 선택된경우 차감금액을 입력해주세요');
        rc_form.incentive_amount.focus();
        return;
      }
    }

    if (rc_form.incentive_reg_step.value != "") {
      if (window.confirm('환불등록을 진행하시겠습니까?')) {
        var form = $('#rc_form')[0];
        var formData = new FormData(form);
        $.ajax({
          url: '/member/refund/reg',
          processData: false,
          contentType: false,
          data: formData,
          type: 'POST',
          success: function (data) {
            data = data?.message?.trim();
            if (data == "err") {
              if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
            } else {
              alert('등록되었습니다');
              props?.onCallback();
              setIsOpen(false);
            }
          },
          error: function (e) {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          }
        });
      }
    } else {
      if (window.confirm('환불수당 차감없이 환불등록을 진행하시겠습니까?')) {
        var form = $('#rc_form')[0];
        var formData = new FormData(form);
        $.ajax({
          url: '/member/refund/reg',
          processData: false,
          contentType: false,
          data: formData,
          type: 'POST',
          success: function (data) {
            data = data?.message?.trim();
            if (data == "err") {
              if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
              window.close();
            } else {
              alert('등록되었습니다');
              props?.onCallback();
              setIsOpen(false);
            }
          },
          error: function (e) {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          }
        });
      }
    }
  }


  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        $('#pdt_date').val(Fn.getCurrentDate());
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <form name="rc_form" id="rc_form">
          <input type="hidden" name="incentive_mb_no" value={props.mbNo}/>
          <input type="hidden" name="auth_code"/>
          <input type="hidden" name="reg_step"/>

          <div className="pop_box01 p_absolute_mid_pop" style={{width: '900px'}}>
            <div className="POP_title">
              <h5><strong>환불등록</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <p className="text-right"><span className="text-pink">사용일</span>은 <span className="text-blue">연기일</span>을 제외하고 계산됨</p>
              <table className="view-rounded sub_view" style={{borderRadius: 0}}>
                <colgroup>
                  <col width="90px"/>
                  <col width="*"/>
                  <col width="90px"/>
                  <col width="*"/>
                  <col width="90px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>운동시작일</th>
                  <td><JDatePicker type="date" className="form-control" aria-describedby="button-addon2" placeholder="" name="refund_start_date" id="ip_date"/></td>
                  <th>사용일</th>
                  <td>
                    <div className="d-flex">
                      <input type="text" className="form-control mx-1" style={{width: '60px'}} name="refund_use_date" id="refund_use_date"/> <span className="p-1">일</span>
                    </div>
                  </td>
                  <th>구분</th>
                  <td>
                    <select className="form-select" name="refund_part">
                      <option value="2000">패스트(2000)</option>
                      <option value="3000">피트니스/짐(3000)</option>
                      <option value="3700">프리미엄(3700)</option>
                      <option value="8800">골프/유료GX(8800)</option>
                      <option value="25000">필라테스(25000)</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>위약구분</th>
                  <td>
                    <select className="form-select" name="refund_kind">
                      <option value="10">10%</option>
                      <option value="20">20%</option>
                    </select>
                  </td>
                  <th>결제금액</th>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" style={{maxWidth: "100%"}} name="pay_amount" id="pay_amount"
                             onKeyPress={(e) => {
                               return Fn.numkeyCheck(e);
                             }}
                             onKeyUp={(e) => Fn.vComma(e.target)}
                             maxLength={10}
                  />
                  </td>
                  <th>위약금</th>
                  <td>
                    <div align="center">
                      <span id="penalty_amount"></span>
                      <input type="hidden" name="real_penalty_amount"/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>환불일자</th>
                  <td><JDatePicker type="date" className="form-control" aria-describedby="button-addon2" placeholder="" name="pdt_date" id="pdt_date"/></td>
                  <th>서비스명</th>
                  <td>
                    <select className="form-select" name="pdt_no">
                      <option value="refundC">환불(카드취소)</option>
                      <option value="refundT">환불(이체)</option>
                    </select>
                  </td>
                  <th>추가위약금</th>
                  <td>
                    <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" name="add_wi" id="add_wi"
                           onKeyPress={(e) => {
                             return Fn.numkeyCheck(e);
                           }}
                           onKeyUp={(e) => Fn.vComma(e.target)}
                           maxLength={10}
                           style={{maxWidth: '100%'}}
                    />
                  </td>
                </tr>
                <tr>
                  <th>OT잔여</th>
                  <td>{result?.mInfoRow?.ot_able - result?.mInfoRow?.ot_cnt}회</td>
                  <th>비고</th>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" style={{maxWidth: "100%"}} name="bigo" maxLength={50}/>
                  </td>
                  <th>환불금액</th>
                  <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" name="refund_amount" id="refund_amount" maxLength={10} readOnly={true} style={{maxWidth: '100%'}}/>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => result_calc()}>계산</button>
            </div>
            <p className="bold800 my-2">환불수당차감</p>
            <div className="mb-2">
              <table className="view-rounded sub_view">
                <colgroup>
                  <col width="108px"/>
                  <col width="*"/>
                  <col width="108px"/>
                  <col width="*"/>
                </colgroup>
                {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7 ?
                  <tr>
                    <th>차감대상</th>
                    <td>
                      <select className="form-select" name="incentive_reg_step" id="incentive_reg_step">
                      </select>
                    </td>
                    <th>차감금액</th>
                    <td>
                      <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" name="incentive_amount" id="incentive_amount"
                             onKeyPress={(e) => {
                               return Fn.numkeyCheck(e);
                             }}
                             onKeyUp={(e) => Fn.vComma(e.target)}
                             maxLength={10}
                             style={{maxWidth: '100%'}}
                      />
                    </td>
                  </tr>
                  :
                  <tr>
                    <th>차감대상</th>
                    <td colSpan={3}><strong><font color='red'>환불수당 차감은 경리팀 이상만 등록가능합니다</font></strong></td>
                  </tr>
                }
              </table>
            </div>
            <p className="mb-3 text-pink">환불금액 및 차감금액은 - 입력하지 않고 숫자만 기입</p>
            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => go_submit()}>환불등록</button>
              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>닫기</button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MvRefundCal;
