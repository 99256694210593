import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";
import {useHistory} from "react-router-dom";

const EmployeeInvGroupNew = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [modalResult, setModalResult] = useState({});
  const [modalResult2, setModalResult2] = useState({});
  const [modalResult3, setModalResult3] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#s_year').val(Fn.getDate(monthsAgo).split('-')[0]);
    $('#s_month').val(Fn.getDate(monthsAgo).split('-')[1]);
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/inv/inv/group/new/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = (branchCode) => {
    $('#modal_branch_code').val(branchCode);

    axios2
      .post(`/inv/set/mail/form/ready?branch_code=${branchCode}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalOpen(true);
          setModalResult(result);

          $('#mail_title').val(result?.row?.mail_title);
          $('#mail_cont1').val(result?.row?.mail_cont1);
          $('#mail_cont2').val(result?.row?.mail_cont2);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const make = () => {
    if ($('[id^=achk]:checked').length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }
  }

  const make_data = () => {
    if (!window.confirm(`${$('#s_year option:selected').val()}년 ${$('#s_month option:selected').val()}월 데이터를 생성하시겠습니까?`)) return;

    axios2.post(`/inv/make/data/inv?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
      .then(function (response) {
        const {code, message: data, result, pageInfo} = response?.data;
        alert('생성되었습니다');
        setModalOpen(false);
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {
    axios2
      .postEx('/inv/set/mail/form', {
        branch_code: $('#modal_branch_code').val(),
        mail_title: $('#mail_title').val(),
        mail_cont1: $('#mail_cont1').val(),
        mail_cont2: $('#mail_cont2').val()
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        alert("저장되었습니다");
        setRenew(Fn.getRenewTime());
        setModalOpen(false);
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal2 = (branchCode) => {
    $('#modal_branch_code').val(branchCode);

    console.log(`/inv/mail/send/pop/new/ready?branch_code=${branchCode}&s_year=${$('#s_year option:selected').val()}&s_month=${$('#s_month option:selected').val()}`);
    axios2
      .post(`/inv/mail/send/pop/new/ready?branch_code=${branchCode}&s_year=${$('#s_year option:selected').val()}&s_month=${$('#s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);

          setModal2Open(true);
          setModalResult2(result);
          console.log('result', result);

          setTimeout(() => {
            $('#m2_mail_cont1').val(result?.mail_content1 || result?.mail_cont1);
            $('#m2_mail_cont2').val(result?.mail_content2 || result?.mail_cont2);
            $('#m2_sun_price').val(Fn.numberWithCommas(result?.sunPrice));
            $('#m2_branch_name').val(result?.branch_name);
            $('#m2_s_year').val($('#s_year option:selected').val());
            $('#m2_s_month').val($('#s_month option:selected').val());

            let hiddenStr = "";
            result?.userList?.forEach((user, index) => {
              hiddenStr += `<input type='hidden' name='email_${index + 1}' value='${user.user_email}'>`;
              hiddenStr += `<input type='hidden' name='user_name_${index + 1}' value='${user.user_name}'>`;
              hiddenStr += `<input type='hidden' name='user_id_${index + 1}' value='${user.user_id}'>`;
            });
            hiddenStr += `<input type='hidden' name='max' value='${result?.userList?.length}'>`;
            $('#m2_hidden_area').html(hiddenStr);
          }, 400);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal3 = (branchCode) => {
    $('#modal_branch_code').val(branchCode);

    axios2
      .post(`/config/branch/manager/group/ready?selected_branch=${branchCode}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModal3Open(true);
          setModalResult3(result);
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit2 = () => {
    if (($('#m2_s_year option:selected').val() || '') == "") {
      alert("년도를 입력해주세요");
      $('#m2_s_year')[0].focus();
      return;
    }
    if (($('#m2_s_month option:selected').val() || '') == "") {
      alert("월을 입력해주세요");
      $('#m2_s_month')[0].focus();
      return;
    }
    if ($('#m2_branch_name').val() == "") {
      alert("지점을 입력해주세요");
      $('#m2_branch_name')[0].focus();
      return;
    }
    if ($('#m2_sun_price').val() == "") {
      alert("배분가능액을 입력해주세요");
      $('#m2_sun_price')[0].focus();
      return;
    }

    const formData = new FormData();

    formData.append('cont1', $('#m2_mail_cont1').val());
    formData.append('year', $('#m2_s_year option:selected').val());
    formData.append('month', $('#m2_s_month option:selected').val());
    formData.append('branch_name', $('#m2_branch_name').val());
    formData.append('branch_code', $('#modal_branch_code').val());
    formData.append('sun_price', Fn.cfNumeric($('#m2_sun_price').val()));
    formData.append('cont2', $('#m2_mail_cont2').val());
    formData.append('send_month', $('#m2_s_year option:selected').val() + "-" + $('#m2_s_month option:selected').val());

    modalResult2?.userList?.forEach((user, index) => {
      formData.append('email_' + (index + 1), user.user_email);
      formData.append('user_name_' + (index + 1), user.user_name);
      formData.append('user_id_' + (index + 1), user.user_id);
    });
    formData.append('max', modalResult2?.userList?.length);

    axios2.postFormEx('/inv/mail/send/new', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();
        if (data === "err") {
          alert('오류가 발생하였습니다');
        } else {
          setRenew(Fn.getRenewTime());
          alert("생성되었습니다");
          setModal2Open(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>

      <div style={{maxWidth: "935px"}}>
        <div className="d-flex m-3">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_year">
              {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
            년
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_month">
              {[...Array(12)].map((_, index) => {
                const monthNumber = index + 1;
                const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
            월
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => make_data()}>데이터 생성</button>
          </div>
        </div>

        <div className="m-3">
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="60px"/>
              <col width="70px"/>
              <col width=""/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>지점명</th>
              <th>구성원수</th>
              <th>영업이익</th>
              <th>발송대상 생성</th>
              <th>이메일고정템플릿</th>
            </tr>

            {rets.map((row, index) => {
              const {
                branch_code,
                branch_name,
                mail_title,
                mail_cont1,
                mail_cont2,
                userNum: user_num,
                sunPrice: sun_price,
                makeYn: make_YN
              } = row;

              return (
                <tr key={index}
                  // onClick={() => handleCheckboxChange(branch_code)} bgcolor="#FFFFFF"
                >
                  <td>{rets.length - index}</td>
                  <td>
                    {(user_num && mail_title && mail_cont1 && mail_cont2) ?
                      <>
                        <input type="checkbox" className="checkbox_sm" id={`achk${index + 1}`} name={`chk[${index + 1}]`} value={branch_code}/>
                        <label htmlFor={`achk${index + 1}`}>
                          <span></span>
                        </label>
                      </>
                      :
                      <></>
                    }
                  </td>
                  <td>
                    <div align="left" style={{paddingLeft: '10px'}}>
                      {branch_name}
                    </div>
                  </td>
                  <td>
                    <div align="center">
                      {user_num ? (
                        <span onClick={() => loadModal3(branch_code)} style={{cursor: "pointer"}}><strong>{user_num} 명</strong></span>
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div align="right" style={{paddingRight: '10px'}}>
                      {Fn.numberWithCommas(sun_price)}
                    </div>
                  </td>
                  <td>
                    <div align="center">
                      {user_num > 0 && (make_YN ? (
                        <input
                          className="btn btn-ssm btn-dark px-2"
                          type="button"
                          value="발송대상 생성"
                          onClick={() => loadModal2(branch_code)}
                        />
                      ) : (
                        <input
                          className="btn btn-ssm btn-outline-point px-2"
                          type="button"
                          value="발송대상 생성"
                          onClick={() => loadModal2(branch_code)}
                        />
                      ))}
                    </div>
                  </td>
                  <td>
                    <div align="center">
                      <input
                        className="btn btn-ssm btn-outline-dark px-2"
                        type="button"
                        value="수정"
                        onClick={() => loadModal(branch_code)}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>

        <div className="m-4 text-center">
          <button type="button" className="btn btn-sm btn-point px-4" onClick={() => make()}>일괄생성</button>
        </div>
      </div>

      {/* 팝업 */}
      <input type="hidden" id="modal_branch_code"/>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
        onAfterOpen={() => {
          Fn.centerModal();
        }}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '1050px'}}>
            <div className="POP_title">
              <h5>
                <strong>메일폼 저장</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_view">
                <colgroup>
                  <col width="120px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>제목</th>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      aria-describedby="button-addon2"
                      placeholder="메일 제목"
                      id="mail_title"
                    />
                  </td>
                </tr>

                <tr>
                  <th>내용</th>
                  <td>
                  <textarea
                    className="txtarea mt-1"
                    style={{minHeight: '150px'}}
                    placeholder="내용입력"
                    id="mail_cont1"
                  />
                    <div className="inline_tbl mb-1">
                      <dl>
                        <dt>년월</dt>
                        <dd>자동설정</dd>
                      </dl>
                      <dl>
                        <dt>지점명</dt>
                        <dd>자동설정</dd>
                      </dl>
                      <dl>
                        <dt>배분가능액</dt>
                        <dd>자동설정</dd>
                      </dl>
                    </div>
                    <textarea
                      className="txtarea mt-1"
                      style={{minHeight: '180px'}}
                      placeholder="내용입력"
                      id="mail_cont2"
                    />
                  </td>
                </tr>
              </table>
            </div>

            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => goSubmit(false)}>
                저장
              </button>
              <button type="button" className="btn btn-sm btn-ligray px-3 mx-1" onClick={() => setModalOpen(false)}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
        onAfterOpen={() => {
          Fn.centerModal();
        }}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '1050px'}}>
            <div className="POP_title">
              <h5>
                <strong>이메일 작성</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_view">
                <tr>
                  <td>
                    <textarea
                      className="txtarea mt-1"
                      style={{minHeight: '150px'}}
                      placeholder="내용입력"
                      id="m2_mail_cont1"
                    />
                    <div className="inline_tbl mb-1">
                      <dl>
                        <dt>년월</dt>
                        <dd>
                          <div className="d-flex">
                            <div className="pr-1">
                              <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="m2_s_year">
                                {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + 1 + index).map((year) => (
                                  <option key={year} value={year}>{year}</option>
                                ))}
                              </select>
                            </div>
                            <div className="pr-1">
                              <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="m2_s_month">
                                {[...Array(12)].map((_, index) => {
                                  const monthNumber = index + 1;
                                  const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                                  return (
                                    <option key={monthString} value={monthString}>{monthString}</option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </dd>
                      </dl>
                      <dl>
                        <dt>지점명</dt>
                        <dd>
                          <input
                            type="text"
                            className="form-control"
                            aria-describedby="button-addon2"
                            placeholder="지점명"
                            id="m2_branch_name"
                            style={{width: '200px'}}
                          />
                        </dd>
                      </dl>
                      <dl>
                        <dt>배분가능액</dt>
                        <dd>
                          <input
                            type="text"
                            className="form-control cls-type-fmtnum-ex"
                            aria-describedby="button-addon2"
                            placeholder="배분가능액"
                            id="m2_sun_price"
                            style={{width: '200px'}}
                          />
                        </dd>
                      </dl>
                    </div>
                    <textarea
                      className="txtarea mt-1"
                      style={{minHeight: '180px'}}
                      placeholder="내용입력"
                      id="m2_mail_cont2"
                    />
                    <span>투자자페이지 : <a href="http://inv.spoany.co.kr" target="_blank">http://inv.spoany.co.kr</a></span>
                  </td>
                </tr>
              </table>

              <div id="m2_hidden_area"/>
            </div>

            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => goSubmit2(false)}>
                발송대상 생성
              </button>
              <button type="button" className="btn btn-sm btn-ligray px-3 mx-1" onClick={() => setModal2Open(false)}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal3Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
        }}
        onRequestClose={() => {
          clearInterval(window.modal3_intval);
          setModal3Open(false);
        }}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: "600px"}}>
            <div className="POP_title">
              <h5><strong>구성원수 확인</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => {
                  setModal3Open(false);
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">

                <colgroup>
                  <col width="50px"/>
                  <col width="150px"/>
                  <col width="100px"/>
                  <col width="*"/>
                </colgroup>

                <tr>
                  <th>No</th>
                  <th>아이디</th>
                  <th>성명</th>
                  <th>이메일</th>
                </tr>

                {modalResult3?.list?.map((listRow, i) => {
                    return (
                      <tr>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">{listRow?.user_id}</td>
                        <td className="text-center">{listRow?.user_name}</td>
                        <td className="text-center">{listRow?.user_email}</td>
                      </tr>
                    );
                  }
                )}
              </table>
            </div>

            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-ligray px-3 mx-1" onClick={() => setModal3Open(false)}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default EmployeeInvGroupNew;
