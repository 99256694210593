import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import $ from "jquery";
import JDatePicker from "../common/JDatePicker";

const MvDelayInsert = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState({});
  const [ableCnt, setAbleCnt] = useState(0);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const isDv = () => {
    return result?.chkDelayRow?.de_sDate <= Fn.getCurrentDate() && Fn.getCurrentDate() <= result?.chkDelayRow?.de_eDate;
    // return Fn.getCurrentDate() <= result?.chkDelayRow?.de_eDate;
  }

  const load = () => {
    axios2
      .post(`/member/delay/insert/manualy/ready?mb_no=${props.mbNo}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        console.log(result);

        if (code === 200) {
          const lastStartDate = result?.lbRow?.startDate;
          const lastMonth = result?.lbRow?.period;
          const mbNo = props.mbNo;
          const today = Fn.getCurrentDate();

          const chkNum = result?.chkNum || 0;
          let ableCnt = result?.ableCnt || 0;

          setAbleCnt(ableCnt);

          $('#_mb_no').val(props.mbNo);
          $('#de_no').val(result?.chkDelayRow?.de_no || '');
          $('#last_month').val(lastMonth);
          $('#able_cnt').val(ableCnt);
          $('#receive_name').val($('#_reg_step option:selected').val() || '');
          $('#de_sdate').val(Fn.getCurrentDate());

          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function DelayButton({chkDelayRow, today, b2bNo, delayInput}) {
    if(isDv()) {
    //if (chkDelayRow?.de_sDate <= today && today <= chkDelayRow.de_eDate) {
      return (
        <input
          type="button"
          className="btn btn-sm btn-point px-3 mx-1"
          value="해제"
          onClick={delayInput}
        />
      );
    } else {
      if (b2bNo === 'Y') {
        return (
          <input
            type="button"
            className="btn btn-sm btn-point px-3 mx-1"
            value="신청"
            onClick={() => alert('연기가 불가능한 B2B 이용권 사용자입니다')}
          />
        );
      } else {
        return (
          <input
            type="button"
            className="btn btn-sm btn-point px-3 mx-1"
            value="신청"
            onClick={delayInput}
          />
        );
      }
    }
  }

  function delay_input() {
    if ($('#delayStatus').val() != "1") {
      if (($('#de_period').val() == "" || $('#de_period').val() == "0") && $('#de_periodM option:selected').val() == "0") {
        alert('기간을 입력해주세요');
        $('#de_period').focus();
        return;
      }
    }

    if ($('#delayStatus').val() == "1") {
      if (window.confirm('연기해제를 진행하시겠습니까?')) {
        $('#_mb_no').val(props.mbNo);

        axios2.postEx("/member/delay/save", {
          de_etc: $('#de_etc').val(),
          auth_code: $('#auth_code').val(),
          receive_name: $('#receive_name').val(),
          //type: "del",
          type: "",
          mb_no: $('#_mb_no').val(),
          de_no: $('#de_no').val(),
          token: "", // TODO : ???
          delayStatus: $('#delayStatus').val(),
          de_sdate: $('#de_sdate').val(),
          de_periodM: $('#de_periodM option:selected').val() || '',
          de_period: $('#de_period').val() || '',
        })
          .then((response) => response.json())
          .then(({code, message: data, result, pageInfo}) => {
            data = data.trim();
            if (data == "err") {
              alert('오류가 발생하였습니다');
            } else if (data == "over") {
              alert('이미 연기종료일이 지난건입니다.');
            } else {
              alert('연기 해제되었습니다.');
              props?.onCallback();
              setIsOpen(false);
            }
          })
          .catch(error => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          })
          .finally(() => {
          });
      }
    } else {
      if ($('#de_sdate').val() < Fn.getCurrentDate()) {
        if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 7) {
          alert('연기신청일은 오늘부터 가능합니다');
          return;
        }
      }

      if (window.confirm('연기신청을 진행하시겠습니까?')) {
        $('#_mb_no').val(props.mbNo);

        axios2.postEx("/member/delay/save", {
          de_etc: $('#de_etc').val(),
          auth_code: $('#auth_code').val(),
          receive_name: $('#receive_name').val(),
          type: "",
          mb_no: $('#_mb_no').val(),
          de_no: "", // TODO : ???
          token: "", // TODO : ???
          delayStatus: $('#delayStatus').val(),
          de_sdate: $('#de_sdate').val(),
          de_periodM: $('#de_periodM option:selected').val() || '',
          de_period: $('#de_period').val() || '',
        })
          .then((response) => response.json())
          .then(({code, message: data, result, pageInfo}) => {
            data = data.trim();
            if (data == "err") {
              alert('오류가 발생하였습니다');
            } else if (data == "pre") {
              alert('신청일 이후에 휴회기간이 있는경우 연기신청이 불가합니다');
            } else if (data == "care_err") {
              alert('오케어 통신오류');
            } else {
              alert('연기 신청되었습니다.');
              props?.onCallback();
              setIsOpen(false);
            }
          })
          .catch(error => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          })
          .finally(() => {
          });
      }
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '900px'}}>

          {isDv() ?
            <input type="hidden" id="delayStatus" value="1"/>
            :
            <input type="hidden" id="delayStatus" value="0"/>
          }
          <input type="hidden" id="_mb_no"/>
          <input type="hidden" id="de_no"/>
          <input type="hidden" id="last_month"/>
          <input type="hidden" id="able_cnt"/>
          <input type="hidden" id="receive_name"/>

          <div className="POP_title">
            {isDv() ?
              <h5><strong>연기해제</strong></h5>
              :
              <h5><strong>연기신청</strong></h5>
            }
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <p className="text-right mb-1">연기신청 가능건수는 <span className="bold700 text-pink">{ableCnt}회</span> 입니다.</p>
            <table className="view-rounded sub_view" style={{borderRadius: 0}}>
              <colgroup>
                <col width="90px"/>
                <col width="*"/>
                <col width="90px"/>
                <col width="25%"/>
                <col width="90px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>시작일</th>
                <td><JDatePicker id="de_sdate" disabled={isDv()}/></td>
                <th>기간</th>
                <td>
                  <div className="d-flex">
                    <select className="form-select" style={{width: '100px !important'}} id="de_periodM" disabled={isDv()}>
                      <option value="0">0개월</option>
                      <option value="1">1개월</option>
                      <option value="2">2개월</option>
                      <option value="3">3개월</option>
                    </select>
                    <input type="number" id="de_period" name="de_period" className="form-control mx-1" style={{width: '60px'}} disabled={isDv()}/><span className="p-1">일</span>
                  </div>
                </td>
                <th>비고</th>
                <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" id="de_etc" disabled={isDv()}/></td>
              </tr>
            </table>
          </div>
          <div className="text-center mb-3">
            <DelayButton chkDelayRow={result?.chkDelayRow} today={Fn.getCurrentDate()} b2bNo={result?.b2bNo} delayInput={() => delay_input()}/>
            <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>취소</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MvDelayInsert;
