import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import SmsNew from "../common/SmsNew";
import Constants from "../../constants/constants";

const LockerList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [smsModalOpen, setSmsModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/locker/locker/list/ready?s_lo_posi=${$('#s_lo_posi').val()}&s_mb_name=${$('#s_mb_name').val()}&date_type=${$('#date_type option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_empty=${$('#s_empty:checked').val() || ''}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.list, pageInfo, "ret?.mb_hp");

          setRets(result?.list);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function clear_locker() {
    let auth_code = $("#auth_code").val();

    if (!auth_code) {
      $("#search_auth_code").focus();
      alert("인증코드를 입력해주세요");
      return;
    }

    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 라커를 비움처리 하시겠습니까?')) {
      const formData = new FormData();

      {
        let values = $('[id^=achk]:checked').map(function () {
          return $(this).val();
        }).get().join(",");
        formData.append("chk[]", values);
      }
      {
        let values = $('[id^=achk]:checked').map(function () {
          return $(this).siblings('[id^=chk2]').val();
        }).get().join(",");
        formData.append("chk2[]", values);
      }
      formData.append("clear_type", 'locker');
      formData.append("auth_code", $('#auth_code').val());

      axios2.postFormEx('/locker/clear/locker', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('비움처리 되었습니다.');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const clear_g_locker = () => {
    let auth_code = $("#auth_code").val();

    if (!auth_code) {
      $("#search_auth_code").focus();
      alert("인증코드를 입력해주세요");
      return;
    }

    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 골프 라커를 비움처리 하시겠습니까?')) {
      const formData = new FormData();

      {
        let values = $('[id^=achk]:checked').map(function () {
          return $(this).val();
        }).get().join(",");
        formData.append("chk[]", values);
      }
      {
        let values = $('[id^=achk]:checked').map(function () {
          return $(this).siblings('[id^=chk2]').val();
        }).get().join(",");
        formData.append("chk2[]", values);
      }
      formData.append("clear_type", 'g_locker');
      formData.append("auth_code", $('#auth_code').val());

      axios2.postFormEx('/locker/clear/locker', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('비움처리 되었습니다.');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  //  5-1.
  const popUp = (file, names, width, height, sc) => {
    const w = width;
    const h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h},scrollbars=${sc},top=${wint},left=${winl}`);
  };

  const form1a = null;

  const take_end = () => {
    let con = 0;
    let editMsgid = '';

    for (let i = 0; i < this.form1a.elements.length; i++) {
      if (this.form1a.elements[i].type === 'checkbox') {
        if (this.form1a.elements[i].checked === true) {
          const nn = this.form1a.elements[i].name;
          const m_chk = nn.substr(0, 3);
          if (m_chk === 'chk') {
            con++;
            editMsgid += this.form1a.elements[i].value + '|';
          }
        }
      }
    }

    if (con === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }
    popUp(`../message/sms.html?b_type=locker&s_type=sel&editMsgid=${editMsgid}`, 'sms.html', '800', '600', 'no');
  }

  //  6-1.
  function searchSend() {
    if (window.confirm('검색된 전체회원에게 SMS를 발송하시겠습니까?')) {
      popUp(`../message/sms.html?b_type=locker&s_type=all&s_mb_name=&s_lo_posi=&s_date1=&s_date2=&ddate=&s_empty=&date_type=A`, 'sms.html', '800', '600', 'no');
    }
  }

  function Excel_locker_list()
  {
    var auth_code = $("#auth_code").val();
    if(!auth_code)
    {
      alert("인증코드를 입력해주세요");
      return;
    }

    var down_reason = $("#down_reason").val();
    if(!down_reason)
    {
      alert("사유를 입력해주세요");
      return;
    }

    const url = `/xls/locker/list?s_lo_posi=${$('#s_lo_posi').val()}&s_mb_name=${$('#s_mb_name').val()}&date_type=${$('#date_type option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_empty=${$('#s_empty:checked').val() || ''}&auth_code=${auth_code}&down_reason=${down_reason}`;
    Fn.download(Constants.API_URL + url);
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 justify-content-between flex-wrap">
        <div className="my-1 d-flex flex-nowrap fn_search_box">
          <div className="pr-1">
            <input type="text" className="form-control width_shorts" placeholder="라커번호" id="s_lo_posi"/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control width_shorts" placeholder="회원명" id="s_mb_name"/>
          </div>
          <div className="pr-1">
            <select className="form-select" id="date_type">
              <option value="A">라커종료일</option>
              <option value="B">골프라커종료일</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="ddate" onChange={(e) => Fn.period(e.target.value)}>
              <option value="">종료일</option>
              <option value="1">오늘</option>
              <option value="2">이번달</option>
              <option value="3">전월</option>
              <option value="4">1분기</option>
              <option value="5">2분기</option>
              <option value="6">1,2분기</option>
              <option value="7">3분기</option>
              <option value="8">4분기</option>
              <option value="9">3,4분기</option>
              <option value="10">올해전체</option>
              <option value="11">전1년</option>
            </select>
          </div>
          <div className="input-group pr-1" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
          <div className="m-1 pr-1">
            <span className="p-1">빈라커 :</span>
            <input type="checkbox" id="s_empty" value="Y"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="my-1">
          <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => {
            if (eval("pageInfo?.totalArticles") > 0) {
              if (!window.confirm(`검색된 ${pageInfo?.totalArticles}명 회원에게 SMS를 발송하시겠습니까?`)) return;
            } else {
              if (!window.confirm(`검색된 회원에게 SMS를 발송하시겠습니까?`)) return;
            }

            $('#SMS_editMsgid').val('');
            $('#SMS_b_type').val("locker");
            $('#SMS_s_type').val("all");

            setSmsModalOpen(true);
          }}>검색 SMS 발송
          </button>
          <button type="button" className="btn btn-sm btn-dark"
                  onClick={() => {
                    if ($('[id^=achk]:checked').length === 0) {
                      window.alert('체크된 리스트가 없습니다.');
                      return;
                    }

                    let con = $('[id^=achk]:checked').map(function () {
                      return $(this).val();
                    }).get().join("|");

                    $('#SMS_editMsgid').val(con);
                    $('#SMS_b_type').val("locker");
                    $('#SMS_s_type').val("sel");

                    setSmsModalOpen(true);
                  }}
          >선택 SMS 발송
          </button>
        </div>
      </div>

      <div className="m-3 text-right">
        <div className="locor_clr_wrap text-right">
          <span className="lock_clr00">사용안함</span> 사용안함
          <span className="lock_clr01">사용중</span> 사용중
          <span className="lock_clr02">이용종료후 14일</span> 이용종료후 14일 미만
          <span className="lock_clr03">이용종료후 28일</span> 이용종료후 28일 미만
          <span className="lock_clr04">이용종료후 30일</span> 이용종료후 30일 미만
          <span className="lock_clr05">이용종료후 30일이상</span> 이용종료후 30일 이상
        </div>

        {/*<p className="bold700">*/}
        {/*  <span className="h4 bold700 vertical-middle" style={{color: '#EFEFF7'}}>◼︎</span> 사용안함*/}
        {/*</p>*/}
        {/*<p className="px-3 bold700">*/}
        {/*  <span className="h4 bold700 vertical-middle" style={{color: '#f9f9f9'}}>◼︎</span> 사용중*/}
        {/*</p>*/}
        {/*<p className="bold700">*/}
        {/*  <span className="h4 bold700 vertical-middle" style={{color: '#BEE4EB'}}>◼︎</span> 이용종료후 14일 미만*/}
        {/*</p>*/}
        {/*<p className="px-3 bold700">*/}
        {/*  <span className="h4 bold700 vertical-middle" style={{color: '#F7D5AE'}}>◼︎</span> 이용종료후 28일 미만*/}
        {/*</p>*/}
        {/*<p className="px-3 bold700">*/}
        {/*  <span className="h4 bold700 vertical-middle" style={{color: '#EFB1D5'}}>◼︎</span> 이용종료후 30일 미만*/}
        {/*</p>*/}
        {/*<p className="px-3 bold700">*/}
        {/*  <span className="h4 bold700 vertical-middle" style={{color: '#CBD5F5'}}>◼︎</span> 이용종료후 30일 이상*/}
        {/*</p>*/}
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded locker_list auto">
          <colgroup>
            <col width="60px"/>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            <th>구분</th>
            <th>그룹명</th>
            <th>번호</th>
            <th>회원명</th>
            <th>카드번호</th>
            <th>핸드폰</th>
            <th>시작일</th>
            <th>종료일</th>
            <th>Key</th>
            <th>이전회원</th>
            <th>이전카드</th>
          </tr>
          {rets && rets.map((ret, i) => {

            let gubun, startDate, endDate, pass;

            if (ret?.locker === 'Y') {
              gubun = '라커1';
              startDate = ret?.locker_start_date;
              endDate = ret?.locker_end_date;
              pass = ret?.locker_pass;
            } else if (ret?.g_locker === 'Y') {
              gubun = '골프라커';
              startDate = ret?.g_locker_start_date;
              endDate = ret?.g_locker_end_date;
              pass = ret?.locker_pass;
            } else {
              gubun = '라커';
              startDate = ret?.locker_start_date;
              endDate = ret?.locker_end_date;
              pass = ret?.locker_pass;
            }

            //TODO 락커 날짜 체크 값 api 추가 필요 className (bgclr = "") 확인 필요
            const fmtNowDate = Fn.getCurrentDate();

            let bgclr;

            if (!ret?.mb_no) {
              bgclr = "#e8e8e8";
            } else {
              if (endDate >= fmtNowDate) {
                bgclr = "#FFFFFF";
              } else {
                const dateDiff = Fn.getDateDiff(fmtNowDate, endDate);
                if (dateDiff < 14) {
                  bgclr = "#bdfdd9";
                } else if (dateDiff < 28) {
                  bgclr = "#fed8f8";
                } else if (dateDiff < 30) {
                  bgclr = "#ff8e8e";
                } else {
                  bgclr = "#bcb5fb";
                }
              }
            }
            return (
              <tr style={{backgroundColor: bgclr}}>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  <input
                    type="checkbox"
                    name="chk"
                    value={btoa(ret?.mb_no)}
                    className="checkbox"
                    id={`achk${i + 1}`}
                  />
                  <input type="hidden" id={`chk2${i + 1}`} value={ret?.lo_posi}/>
                </td>
                <td>{gubun}</td>
                <td className="text-left">{ret?.lo_group}</td>
                <td>{ret?.lo_num}</td>
                <td>{ret?.mb_name}</td>
                <td>{ret?.mb_id}</td>
                <td>
                  <div id={`default_show${i + 1}`}>***-****-****</div>
                </td>
                <td>{startDate}</td>
                <td>{endDate}</td>
                <td>{pass}</td>
                <td>{ret?.before_mb_name}</td>
                <td>{ret?.before_mb_id}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={13}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
          <div className="d-flex mx-1">
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="다운사유"
              id="down_reason"
              style={{width: "180px"}}
            />
            <button type="button" className="btn btn-sm btn-outline-point px-2" onClick={() => Excel_locker_list()}>
              엑셀저장
            </button>
          </div>
          <div>
            <button type="button" className="btn btn-sm btn-outline-success px-2 mx-1" onClick={() => clear_locker()}>
              라커비움처리
            </button>
            <button type="button" className="btn btn-sm btn-outline-success px-2" onClick={() => clear_g_locker()}>
              골프라커비움처리
            </button>
            {pageInfo?.totalArticles > 0 && (
              Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)
            ) && (
              <button type="button" className="btn btn-sm btn-outline-dark px-2 mx-1" onClick={() => Fn.showPhone('locker_list')}>연락처 확인</button>
            )}
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

      <SmsNew
        isOpen={smsModalOpen}
        onModalClose={() => setSmsModalOpen(false)}
        searchType={"sms_list"}
        callback={() => {
          setSmsModalOpen(false);
        }}
      />
      <input type="hidden" id="SMS_b_type"/>
      <input type="hidden" id="SMS_s_type"/>
      <input type="hidden" id="SMS_editMsgid"/>

    </CmnCard>
  );
};

export default LockerList;
