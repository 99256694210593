import React, {useEffect} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";

const SecureMenuAuthDetail = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.baseSeq || '';
  const type = location?.state?.type || '';

  useEffect(() => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 9) {
      alert('메뉴 접근 권한이 없습니다.');
      history.goBack();
      return;
    }

    load();
  }, []);

  const load = () => {
    axios2
      .post(`/secure/menu/auth/detail/ready?base_seq=${baseSeq}&key=&type=${type}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $('#auth_name').val(result?.row?.auth_name);
          $('#auth_describe').val(result?.row?.auth_describe);

          for (let i = 1; i <= 21; i++) {
            if (result?.row?.["A" + i] === "Y")
              $('#A' + i + "_y").prop("checked", true);
            else
              $('#A' + i + "_n").prop("checked", true);
          }
          for (let i = 1; i <= 10; i++) {
            if (result?.row?.["B" + i] === "Y")
              $('#B' + i + "_y").prop("checked", true);
            else
              $('#B' + i + "_n").prop("checked", true);
          }
          for (let i = 1; i <= 30; i++) {
            if (result?.row?.["C" + i] === "Y")
              $('#C' + i + "_y").prop("checked", true);
            else
              $('#C' + i + "_n").prop("checked", true);
          }
          for (let i = 1; i <= 23; i++) {
            if (result?.row?.["D" + i] === "Y")
              $('#D' + i + "_y").prop("checked", true);
            else
              $('#D' + i + "_n").prop("checked", true);
          }
          for (let i = 1; i <= 17; i++) {
            if (result?.row?.["E" + i] === "Y")
              $('#E' + i + "_y").prop("checked", true);
            else
              $('#E' + i + "_n").prop("checked", true);
          }
          for (let i = 1; i <= 14; i++) {
            if (result?.row?.["F" + i] === "Y")
              $('#F' + i + "_y").prop("checked", true);
            else
              $('#F' + i + "_n").prop("checked", true);
          }
          for (let i = 1; i <= 15; i++) {
            if (result?.row?.["G" + i] === "Y")
              $('#G' + i + "_y").prop("checked", true);
            else
              $('#G' + i + "_n").prop("checked", true);
          }
          for (let i = 1; i <= 5; i++) {
            if (result?.row?.["H" + i] === "Y")
              $('#H' + i + "_y").prop("checked", true);
            else
              $('#H' + i + "_n").prop("checked", true);
          }
          for (let i = 1; i <= 14; i++) {
            if (result?.row?.["I" + i] === "Y")
              $('#I' + i + "_y").prop("checked", true);
            else
              $('#I' + i + "_n").prop("checked", true);
          }
          for (let i = 1; i <= 21; i++) {
            if (result?.row?.["J" + i] === "Y")
              $('#J' + i + "_y").prop("checked", true);
            else
              $('#J' + i + "_n").prop("checked", true);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>

      <input type="hidden" id="base_seq"/>

      <div className="m-3">
        <table className="view-rounded sub_view">
          <tr>
            <th className="w-15">권한그룹명</th>
            <td><input type="text" className="form-control" placeholder="본사_영업지원부" id="auth_name"/>
            </td>

            <th className="w-15">권한그룹 설명</th>
            <td><input type="text" className="form-control" placeholder="그룹의 설명" id="auth_describe"/>
            </td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_view_sm">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="6" className="text-left py-2">
              <span className="h6 bold700">회원관리</span>
            </td>
          </tr>
          <tr>
            <th>회원관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A1" id="A1_y" value="Y"/>
                <label className="form-check-label" htmlFor="A1_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A1" id="A1_n" value="N"/>
                <label className="form-check-label" htmlFor="A1_n">
                  불허
                </label>
              </div>
            </td>
            <th>회원검색</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A2" id="A2_y" value="Y"/>
                <label className="form-check-label" htmlFor="A2_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A2" id="A2_n" value="N"/>
                <label className="form-check-label" htmlFor="A2_n">
                  불허
                </label>
              </div>
            </td>
            <th>히스토리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A3" id="A3_y" value="Y"/>
                <label className="form-check-label" htmlFor="A3_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A3" id="A3_n" value="N"/>
                <label className="form-check-label" htmlFor="A3_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>무상서비스내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A4" id="A4_y" value="Y"/>
                <label className="form-check-label" htmlFor="A4_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A4" id="A4_n" value="N"/>
                <label className="form-check-label" htmlFor="A4_n">
                  불허
                </label>
              </div>
            </td>
            <th>환불내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A5" id="A5_y" value="Y"/>
                <label className="form-check-label" htmlFor="A5_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A5" id="A5_n" value="N"/>
                <label className="form-check-label" htmlFor="A5_n">
                  불허
                </label>
              </div>
            </td>
            <th>입장내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A6" id="A6_y" value="Y"/>
                <label className="form-check-label" htmlFor="A6_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A6" id="A6_n" value="N"/>
                <label className="form-check-label" htmlFor="A6_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>온라인회원</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A13" id="A13_y" value="Y"/>
                <label className="form-check-label" htmlFor="A13_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A13" id="A13_n" value="N"/>
                <label className="form-check-label" htmlFor="A13_n">
                  불허
                </label>
              </div>
            </td>
            <th>앱/웹 연기신청</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A14" id="A14_y" value="Y"/>
                <label className="form-check-label" htmlFor="A14_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A14" id="A14_n" value="N"/>
                <label className="form-check-label" htmlFor="A14_n">
                  불허
                </label>
              </div>
            </td>
            <th>연기신청내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A16" id="A16_y" value="Y"/>
                <label className="form-check-label" htmlFor="A16_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A16" id="A16_n" value="N"/>
                <label className="form-check-label" htmlFor="A16_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>양도양수승인</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A7" id="A7_y" value="Y"/>
                <label className="form-check-label" htmlFor="A7_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A7" id="A7_n" value="N"/>
                <label className="form-check-label" htmlFor="A7_n">
                  불허
                </label>
              </div>
            </td>
            <th>주센터변경승인</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A8" id="A8_y" value="Y"/>
                <label className="form-check-label" htmlFor="A8_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A8" id="A8_n" value="N"/>
                <label className="form-check-label" htmlFor="A8_n">
                  불허
                </label>
              </div>
            </td>
            <th>주센터변경</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A12" id="A12_y" value="Y"/>
                <label className="form-check-label" htmlFor="A12_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A12" id="A12_n" value="N"/>
                <label className="form-check-label" htmlFor="A12_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>시작일변경승인</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A9" id="A9_y" value="Y"/>
                <label className="form-check-label" htmlFor="A9_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A9" id="A9_n" value="N"/>
                <label className="form-check-label" htmlFor="A9_n">
                  불허
                </label>
              </div>
            </td>
            <th>이용권등록</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A10" id="A10_y" value="Y"/>
                <label className="form-check-label" htmlFor="A10_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A10" id="A10_n" value="N"/>
                <label className="form-check-label" htmlFor="A10_n">
                  불허
                </label>
              </div>
            </td>
            <th>관심회원</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A11" id="A11_y" value="Y"/>
                <label className="form-check-label" htmlFor="A11_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A11" id="A11_n" value="N"/>
                <label className="form-check-label" htmlFor="A11_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>컴플레인관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C22" id="C22_y" value="Y"/>
                <label className="form-check-label" htmlFor="C22_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C22" id="C22_n" value="N"/>
                <label className="form-check-label" htmlFor="C22_n">
                  불허
                </label>
              </div>
            </td>
            <th>인수센터회원등록</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A15" id="A15_y" value="Y"/>
                <label className="form-check-label" htmlFor="A15_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A15" id="A15_n" value="N"/>
                <label className="form-check-label" htmlFor="A15_n">
                  불허
                </label>
              </div>
            </td>
            <th>오케어쿠폰 발행내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A17" id="A17_y" value="Y"/>
                <label className="form-check-label" htmlFor="A17_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A17" id="A17_n" value="N"/>
                <label className="form-check-label" htmlFor="A17_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>오케어쿠폰 사용내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A18" id="A18_y" value="Y"/>
                <label className="form-check-label" htmlFor="A18_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A18" id="A18_n" value="N"/>
                <label className="form-check-label" htmlFor="A18_n">
                  불허
                </label>
              </div>
            </td>
            <th>오케어사용내역(현장)</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A21" id="A21_y" value="Y"/>
                <label className="form-check-label" htmlFor="A21_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A21" id="A21_n" value="N"/>
                <label className="form-check-label" htmlFor="A21_n">
                  불허
                </label>
              </div>
            </td>
            <th>오케어쿠폰 등록</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A19" id="A19_y" value="Y"/>
                <label className="form-check-label" htmlFor="A19_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A19" id="A19_n" value="N"/>
                <label className="form-check-label" htmlFor="A19_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>오케어 회원</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A20" id="A20_y" value="Y"/>
                <label className="form-check-label" htmlFor="A20_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="A20" id="A20_n" value="N"/>
                <label className="form-check-label" htmlFor="A20_n">
                  불허
                </label>
              </div>
            </td>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_view_sm">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="6" className="text-left py-2">
              <span className="h6 bold700">라커관리</span>
            </td>
          </tr>
          <tr>
            <th>라커현황</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B1" id="B1_y" value="Y"/>
                <label className="form-check-label" htmlFor="B1_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B1" id="B1_n" value="N"/>
                <label className="form-check-label" htmlFor="B1_n">
                  불허
                </label>
              </div>
            </td>
            <th>라커목록</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B2" id="B2_y" value="Y"/>
                <label className="form-check-label" htmlFor="B2_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B2" id="B2_n" value="N"/>
                <label className="form-check-label" htmlFor="B2_n">
                  불허
                </label>
              </div>
            </td>
            <th>비움라커목록</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B3" id="B3_y" value="Y"/>
                <label className="form-check-label" htmlFor="B3_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B3" id="B3_n" value="N"/>
                <label className="form-check-label" htmlFor="B3_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>라커예약내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B4" id="B4_y" value="Y"/>
                <label className="form-check-label" htmlFor="B4_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B4" id="B4_n" value="N"/>
                <label className="form-check-label" htmlFor="B4_n">
                  불허
                </label>
              </div>
            </td>
            <th>라커히스토리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B5" id="B5_y" value="Y"/>
                <label className="form-check-label" htmlFor="B5_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B5" id="B5_n" value="N"/>
                <label className="form-check-label" htmlFor="B5_n">
                  불허
                </label>
              </div>
            </td>
            <th>라커번호</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B6" id="B6_y" value="Y"/>
                <label className="form-check-label" htmlFor="B6_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B6" id="B6_n" value="N"/>
                <label className="form-check-label" htmlFor="B6_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>라커설정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B7" id="B7_y" value="Y"/>
                <label className="form-check-label" htmlFor="B7_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="B7" id="B7_n" value="N"/>
                <label className="form-check-label" htmlFor="B7_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_view_sm">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="6" className="text-left py-2">
              <span className="h6 bold700">PT관리</span>
            </td>
          </tr>
          <tr>
            <th>PT/OT스케줄</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C7" id="C7_y" value="Y"/>
                <label className="form-check-label" htmlFor="C7_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C7" id="C7_n" value="N"/>
                <label className="form-check-label" htmlFor="C7_n">
                  불허
                </label>
              </div>
            </td>
            <th>PT/OT스케줄-트레이너</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C8" id="C8_y" value="Y"/>
                <label className="form-check-label" htmlFor="C8_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C8" id="C8_n" value="N"/>
                <label className="form-check-label" htmlFor="C8_n">
                  불허
                </label>
              </div>
            </td>
            <th>PT/OT스케줄 오픈</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C9" id="C9_y" value="Y"/>
                <label className="form-check-label" htmlFor="C9_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C9" id="C9_n" value="N"/>
                <label className="form-check-label" htmlFor="C9_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>월벌진행일정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C25" id="C25_y" value="Y"/>
                <label className="form-check-label" htmlFor="C25_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C25" id="C25_n" value="N"/>
                <label className="form-check-label" htmlFor="C25_n">
                  불허
                </label>
              </div>
            </td>
            <th>PT대상목록</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C10" id="C10_y" value="Y"/>
                <label className="form-check-label" htmlFor="C10_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C10" id="C10_n" value="N"/>
                <label className="form-check-label" htmlFor="C10_n">
                  불허
                </label>
              </div>
            </td>
            <th>PT진행내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C11" id="C11_y" value="Y"/>
                <label className="form-check-label" htmlFor="C11_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C11" id="C11_n" value="N"/>
                <label className="form-check-label" htmlFor="C11_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>OT대상목록</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C13" id="C13_y" value="Y"/>
                <label className="form-check-label" htmlFor="C13_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C13" id="C13_n" value="N"/>
                <label className="form-check-label" htmlFor="C13_n">
                  불허
                </label>
              </div>
            </td>
            <th>OT진행내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C14" id="C14_y" value="Y"/>
                <label className="form-check-label" htmlFor="C14_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C14" id="C14_n" value="N"/>
                <label className="form-check-label" htmlFor="C14_n">
                  불허
                </label>
              </div>
            </td>
            <th>무료OT신청</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F11" id="F11_y" value="Y"/>
                <label className="form-check-label" htmlFor="F11_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F11" id="F11_n" value="N"/>
                <label className="form-check-label" htmlFor="F11_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>트레이너 기간별 PT진행</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C26" id="C26_y" value="Y"/>
                <label className="form-check-label" htmlFor="C26_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C26" id="C26_n" value="N"/>
                <label className="form-check-label" htmlFor="C26_n">
                  불허
                </label>
              </div>
            </td>
            <th>트레이너 기간별 OT진행</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C27" id="C27_y" value="Y"/>
                <label className="form-check-label" htmlFor="C27_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C27" id="C27_n" value="N"/>
                <label className="form-check-label" htmlFor="C27_n">
                  불허
                </label>
              </div>
            </td>
            <th>PT가입경로 설정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C12" id="C12_y" value="Y"/>
                <label className="form-check-label" htmlFor="C12_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C12" id="C12_n" value="N"/>
                <label className="form-check-label" htmlFor="C12_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_view_sm">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="6" className="text-left py-2">
              <span className="h6 bold700">지점운영</span>
            </td>
          </tr>
          <tr>
            <th>공지사항</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C16" id="C16_y" value="Y"/>
                <label className="form-check-label" htmlFor="C16_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C16" id="C16_n" value="N"/>
                <label className="form-check-label" htmlFor="C16_n">
                  불허
                </label>
              </div>
            </td>
            <th>매출목표 설정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C4" id="C4_y" value="Y"/>
                <label className="form-check-label" htmlFor="C4_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C4" id="C4_n" value="N"/>
                <label className="form-check-label" htmlFor="C4_n">
                  불허
                </label>
              </div>
            </td>
            <th>일일업무현황</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E7" id="E7_y" value="Y"/>
                <label className="form-check-label" htmlFor="E7_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E7" id="E7_n" value="N"/>
                <label className="form-check-label" htmlFor="E7_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>승인처리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C5" id="C5_y" value="Y"/>
                <label className="form-check-label" htmlFor="C5_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C5" id="C5_n" value="N"/>
                <label className="form-check-label" htmlFor="C5_n">
                  불허
                </label>
              </div>
            </td>
            <th>GX 기본정보</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C3" id="C3_y" value="Y"/>
                <label className="form-check-label" htmlFor="C3_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C3" id="C3_n" value="N"/>
                <label className="form-check-label" htmlFor="C3_n">
                  불허
                </label>
              </div>
            </td>
            <th>GX 예약</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C1" id="C1_y" value="Y"/>
                <label className="form-check-label" htmlFor="C1_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C1" id="C1_n" value="N"/>
                <label className="form-check-label" htmlFor="C1_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>GX 출석</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C2" id="C2_y" value="Y"/>
                <label className="form-check-label" htmlFor="C2_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C2" id="C2_n" value="N"/>
                <label className="form-check-label" htmlFor="C2_n">
                  불허
                </label>
              </div>
            </td>
            <th>타지점정산내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C6" id="C6_y" value="Y"/>
                <label className="form-check-label" htmlFor="C6_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C6" id="C6_n" value="N"/>
                <label className="form-check-label" htmlFor="C6_n">
                  불허
                </label>
              </div>
            </td>
            <th>문의게시판</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C15" id="C15_y" value="Y"/>
                <label className="form-check-label" htmlFor="C15_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C15" id="C15_n" value="N"/>
                <label className="form-check-label" htmlFor="C15_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>이상이용자 신고</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C29" id="C29_y" value="Y"/>
                <label className="form-check-label" htmlFor="C29_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C29" id="C29_n" value="N"/>
                <label className="form-check-label" htmlFor="C29_n">
                  불허
                </label>
              </div>
            </td>
            <th>앱설치내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D18" id="D18_y" value="Y"/>
                <label className="form-check-label" htmlFor="D18_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D18" id="D18_n" value="N"/>
                <label className="form-check-label" htmlFor="D18_n">
                  불허
                </label>
              </div>
            </td>
            <th>앱설치내역(스텝)</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D21" id="D21_y" value="Y"/>
                <label className="form-check-label" htmlFor="D21_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D21" id="D21_n" value="N"/>
                <label className="form-check-label" htmlFor="D21_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>스텝입장내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D20" id="D20_y" value="Y"/>
                <label className="form-check-label" htmlFor="D20_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D20" id="D20_n" value="N"/>
                <label className="form-check-label" htmlFor="D20_n">
                  불허
                </label>
              </div>
            </td>
            <th>비정상운영내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D23" id="D23_y" value="Y"/>
                <label className="form-check-label" htmlFor="D23_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D23" id="D23_n" value="N"/>
                <label className="form-check-label" htmlFor="D23_n">
                  불허
                </label>
              </div>
            </td>
            <th></th>
            <td>
            </td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_view_sm">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="6" className="text-left py-2">
              <span className="h6 bold700">회계관리</span>
            </td>
          </tr>
          <tr>
            <th>간편장부</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D2" id="D2_y" value="Y"/>
                <label className="form-check-label" htmlFor="D2_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D2" id="D2_n" value="N"/>
                <label className="form-check-label" htmlFor="D2_n">
                  불허
                </label>
              </div>
            </td>
            <th>자금일보</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D3" id="D3_y" value="Y"/>
                <label className="form-check-label" htmlFor="D3_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D3" id="D3_n" value="N"/>
                <label className="form-check-label" htmlFor="D3_n">
                  불허
                </label>
              </div>
            </td>
            <th>월계표</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D4" id="D4_y" value="Y"/>
                <label className="form-check-label" htmlFor="D4_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D4" id="D4_n" value="N"/>
                <label className="form-check-label" htmlFor="D4_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>전도금 관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D10" id="D10_y" value="Y"/>
                <label className="form-check-label" htmlFor="D10_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D10" id="D10_n" value="N"/>
                <label className="form-check-label" htmlFor="D10_n">
                  불허
                </label>
              </div>
            </td>
            <th>통장 입금</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D13" id="D13_y" value="Y"/>
                <label className="form-check-label" htmlFor="D13_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D13" id="D13_n" value="N"/>
                <label className="form-check-label" htmlFor="D13_n">
                  불허
                </label>
              </div>
            </td>
            <th>비용 지출</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D14" id="D14_y" value="Y"/>
                <label className="form-check-label" htmlFor="D14_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D14" id="D14_n" value="N"/>
                <label className="form-check-label" htmlFor="D14_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>카드매출</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D5" id="D5_y" value="Y"/>
                <label className="form-check-label" htmlFor="D5_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D5" id="D5_n" value="N"/>
                <label className="form-check-label" htmlFor="D5_n">
                  불허
                </label>
              </div>
            </td>
            <th>현금매출</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D6" id="D6_y" value="Y"/>
                <label className="form-check-label" htmlFor="D6_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D6" id="D6_n" value="N"/>
                <label className="form-check-label" htmlFor="D6_n">
                  불허
                </label>
              </div>
            </td>
            <th>미납금 관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D11" id="D11_y" value="Y"/>
                <label className="form-check-label" htmlFor="D11_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D11" id="D11_n" value="N"/>
                <label className="form-check-label" htmlFor="D11_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>계좌 관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D12" id="D12_y" value="Y"/>
                <label className="form-check-label" htmlFor="D12_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D12" id="D12_n" value="N"/>
                <label className="form-check-label" htmlFor="D12_n">
                  불허
                </label>
              </div>
            </td>
            <th>지출내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D9" id="D9_y" value="Y"/>
                <label className="form-check-label" htmlFor="D9_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D9" id="D9_n" value="N"/>
                <label className="form-check-label" htmlFor="D9_n">
                  불허
                </label>
              </div>
            </td>
            <th>수수료관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D1" id="D1_y" value="Y"/>
                <label className="form-check-label" htmlFor="D1_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D1" id="D1_n" value="N"/>
                <label className="form-check-label" htmlFor="D1_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>가맹점 수수료 내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D22" id="D22_y" value="Y"/>
                <label className="form-check-label" htmlFor="D22_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D22" id="D22_n" value="N"/>
                <label className="form-check-label" htmlFor="D22_n">
                  불허
                </label>
              </div>
            </td>
            <th>수수료관리 &gt; 영업컨설팅비</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D17" id="D17_y" value="Y"/>
                <label className="form-check-label" htmlFor="D17_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D17" id="D17_n" value="N"/>
                <label className="form-check-label" htmlFor="D17_n">
                  불허
                </label>
              </div>
            </td>
            <th>앱/웹결제내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D19" id="D19_y" value="Y"/>
                <label className="form-check-label" htmlFor="D19_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D19" id="D19_n" value="N"/>
                <label className="form-check-label" htmlFor="D19_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_view_sm">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="6" className="text-left py-2">
              <span className="h6 bold700">경영분석</span>
            </td>
          </tr>
          <tr>
            <th>통계분석</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E10" id="E10_y" value="Y"/>
                <label className="form-check-label" htmlFor="E10_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E10" id="E10_n" value="N"/>
                <label className="form-check-label" htmlFor="E10_n">
                  불허
                </label>
              </div>
            </td>
            <th>주간업무보고</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E8" id="E8_y" value="Y"/>
                <label className="form-check-label" htmlFor="E8_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E8" id="E8_n" value="N"/>
                <label className="form-check-label" htmlFor="E8_n">
                  불허
                </label>
              </div>
            </td>
            <th>월간업무보고</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E9" id="E9_y" value="Y"/>
                <label className="form-check-label" htmlFor="E9_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E9" id="E9_n" value="N"/>
                <label className="form-check-label" htmlFor="E9_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>스탭별 순위</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E3" id="E3_y" value="Y"/>
                <label className="form-check-label" htmlFor="E3_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E3" id="E3_n" value="N"/>
                <label className="form-check-label" htmlFor="E3_n">
                  불허
                </label>
              </div>
            </td>
            <th>지점랭킹-매출</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E4" id="E4_y" value="Y"/>
                <label className="form-check-label" htmlFor="E4_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E4" id="E4_n" value="N"/>
                <label className="form-check-label" htmlFor="E4_n">
                  불허
                </label>
              </div>
            </td>
            <th>지점랭킹-순익</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E5" id="E5_y" value="Y"/>
                <label className="form-check-label" htmlFor="E5_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E5" id="E5_n" value="N"/>
                <label className="form-check-label" htmlFor="E5_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>지점랭킹-PT</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E6" id="E6_y" value="Y"/>
                <label className="form-check-label" htmlFor="E6_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E6" id="E6_n" value="N"/>
                <label className="form-check-label" htmlFor="E6_n">
                  불허
                </label>
              </div>
            </td>
            <th>이용현황</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E2" id="E2_y" value="Y"/>
                <label className="form-check-label" htmlFor="E2_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E2" id="E2_n" value="N"/>
                <label className="form-check-label" htmlFor="E2_n">
                  불허
                </label>
              </div>
            </td>
            <th>KPI지표 순위</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E1" id="E1_y" value="Y"/>
                <label className="form-check-label" htmlFor="E1_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E1" id="E1_n" value="N"/>
                <label className="form-check-label" htmlFor="E1_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>매출현황-스탭별</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D7" id="D7_y" value="Y"/>
                <label className="form-check-label" htmlFor="D7_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D7" id="D7_n" value="N"/>
                <label className="form-check-label" htmlFor="D7_n">
                  불허
                </label>
              </div>
            </td>
            <th>PT매출현황-트레이너별</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D8" id="D8_y" value="Y"/>
                <label className="form-check-label" htmlFor="D8_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D8" id="D8_n" value="N"/>
                <label className="form-check-label" htmlFor="D8_n">
                  불허
                </label>
              </div>
            </td>
            <th>지점별순익</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D16" id="D16_y" value="Y"/>
                <label className="form-check-label" htmlFor="D16_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D16" id="D16_n" value="N"/>
                <label className="form-check-label" htmlFor="D16_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>월계표비교분석</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F10" id="F10_y" value="Y"/>
                <label className="form-check-label" htmlFor="F10_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F10" id="F10_n" value="N"/>
                <label className="form-check-label" htmlFor="F10_n">
                  불허
                </label>
              </div>
            </td>
            <th>회계관리-회원권매출</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E11" id="E11_y" value="Y"/>
                <label className="form-check-label" htmlFor="E11_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E11" id="E11_n" value="N"/>
                <label className="form-check-label" htmlFor="E11_n">
                  불허
                </label>
              </div>
            </td>
            <th>회계관리-PT매출</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E12" id="E12_y" value="Y"/>
                <label className="form-check-label" htmlFor="E12_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E12" id="E12_n" value="N"/>
                <label className="form-check-label" htmlFor="E12_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>만족도 조사</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E13" id="E13_y" value="Y"/>
                <label className="form-check-label" htmlFor="E13_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E13" id="E13_n" value="N"/>
                <label className="form-check-label" htmlFor="E13_n">
                  불허
                </label>
              </div>
            </td>
            <th>마일리지 히스토리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E14" id="E14_y" value="Y"/>
                <label className="form-check-label" htmlFor="E14_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="E14" id="E14_n" value="N"/>
                <label className="form-check-label" htmlFor="E14_n">
                  불허
                </label>
              </div>
            </td>
            <th></th>
            <td></td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_view_sm">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="6" className="text-left py-2">
              <span className="h6 bold700">시스템관리</span>
            </td>
          </tr>
          <tr>
            <th>IP관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F1" id="F1_y" value="Y"/>
                <label className="form-check-label" htmlFor="F1_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F1" id="F1_n" value="N"/>
                <label className="form-check-label" htmlFor="F1_n">
                  불허
                </label>
              </div>
            </td>

            <th>출석소리설정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F2" id="F2_y" value="Y"/>
                <label className="form-check-label" htmlFor="F2_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F2" id="F2_n" value="N"/>
                <label className="form-check-label" htmlFor="F2_n">
                  불허
                </label>
              </div>
            </td>

            <th>메뉴권한 설정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F3" id="F3_y" value="Y"/>
                <label className="form-check-label" htmlFor="F3_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F3" id="F3_n" value="N"/>
                <label className="form-check-label" htmlFor="F3_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>방문경로 관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F4" id="F4_y" value="Y"/>
                <label className="form-check-label" htmlFor="F4_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F4" id="F4_n" value="N"/>
                <label className="form-check-label" htmlFor="F4_n">
                  불허
                </label>
              </div>
            </td>

            <th>옵션관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F5" id="F5_y" value="Y"/>
                <label className="form-check-label" htmlFor="F5_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F5" id="F5_n" value="N"/>
                <label className="form-check-label" htmlFor="F5_n">
                  불허
                </label>
              </div>
            </td>

            <th>년단위 주 설정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F6" id="F6_y" value="Y"/>
                <label className="form-check-label" htmlFor="F6_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F6" id="F6_n" value="N"/>
                <label className="form-check-label" htmlFor="F6_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>계정관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F7" id="F7_y" value="Y"/>
                <label className="form-check-label" htmlFor="F7_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F7" id="F7_n" value="N"/>
                <label className="form-check-label" htmlFor="F7_n">
                  불허
                </label>
              </div>
            </td>

            <th>설문조사</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F8" id="F8_y" value="Y"/>
                <label className="form-check-label" htmlFor="F8_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F8" id="F8_n" value="N"/>
                <label className="form-check-label" htmlFor="F8_n">
                  불허
                </label>
              </div>
            </td>

            <th>지출세부항목 설정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D15" id="D15_y" value="Y"/>
                <label className="form-check-label" htmlFor="D15_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="D15" id="D15_n" value="N"/>
                <label className="form-check-label" htmlFor="D15_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>지점정보</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C17" id="C17_y" value="Y"/>
                <label className="form-check-label" htmlFor="C17_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C17" id="C17_n" value="N"/>
                <label className="form-check-label" htmlFor="C17_n">
                  불허
                </label>
              </div>
            </td>

            <th>지점상세정보</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C24" id="C24_y" value="Y"/>
                <label className="form-check-label" htmlFor="C24_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C24" id="C24_n" value="N"/>
                <label className="form-check-label" htmlFor="C24_n">
                  불허
                </label>
              </div>
            </td>

            <th>지점상품</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C18" id="C18_y" value="Y"/>
                <label className="form-check-label" htmlFor="C18_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C18" id="C18_n" value="N"/>
                <label className="form-check-label" htmlFor="C18_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>브랜드</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C19" id="C19_y" value="Y"/>
                <label className="form-check-label" htmlFor="C19_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C19" id="C19_n" value="N"/>
                <label className="form-check-label" htmlFor="C19_n">
                  불허
                </label>
              </div>
            </td>

            <th>이용권 생성</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C20" id="C20_y" value="Y"/>
                <label className="form-check-label" htmlFor="C20_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C20" id="C20_n" value="N"/>
                <label className="form-check-label" htmlFor="C20_n">
                  불허
                </label>
              </div>
            </td>

            <th>쿠폰 생성</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C21" id="C21_y" value="Y"/>
                <label className="form-check-label" htmlFor="C21_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C21" id="C21_n" value="N"/>
                <label className="form-check-label" htmlFor="C21_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>응모권 생성</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F12" id="F12_y" value="Y"/>
                <label className="form-check-label" htmlFor="F12_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="F12" id="F12_n" value="N"/>
                <label className="form-check-label" htmlFor="F12_n">
                  불허
                </label>
              </div>
            </td>

            <th>스마일 평가기준 설정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C23" id="C23_y" value="Y"/>
                <label className="form-check-label" htmlFor="C23_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C23" id="C23_n" value="N"/>
                <label className="form-check-label" htmlFor="C23_n">
                  불허
                </label>
              </div>
            </td>

            <th>회원관리 배너설정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C28" id="C28_y" value="Y"/>
                <label className="form-check-label" htmlFor="C28_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="C28" id="C28_n" value="N"/>
                <label className="form-check-label" htmlFor="C28_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_view_sm">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="6" className="text-left py-2">
              <span className="h6 bold700">메세지관리</span>
            </td>
          </tr>
          <tr>
            <th>SMS/LMS 발송내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G1" id="G1_y" value="Y"/>
                <label className="form-check-label" htmlFor="G1_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G1" id="G1_n" value="N"/>
                <label className="form-check-label" htmlFor="G1_n">
                  불허
                </label>
              </div>
            </td>
            <th>SMS/LMS 발송현황</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G5" id="G5_y" value="Y"/>
                <label className="form-check-label" htmlFor="G5_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G5" id="G5_n" value="N"/>
                <label className="form-check-label" htmlFor="G5_n">
                  불허
                </label>
              </div>
            </td>
            <th>SMS/LMS 예약내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G7" id="G7_y" value="Y"/>
                <label className="form-check-label" htmlFor="G7_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G7" id="G7_n" value="N"/>
                <label className="form-check-label" htmlFor="G7_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>TM 등록</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G2" id="G2_y" value="Y"/>
                <label className="form-check-label" htmlFor="G2_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G2" id="G2_n" value="N"/>
                <label className="form-check-label" htmlFor="G2_n">
                  불허
                </label>
              </div>
            </td>
            <th>SMS 발송</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G3" id="G3_y" value="Y"/>
                <label className="form-check-label" htmlFor="G3_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G3" id="G3_n" value="N"/>
                <label className="form-check-label" htmlFor="G3_n">
                  불허
                </label>
              </div>
            </td>
            <th>PUSH 발송</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G4" id="G4_y" value="Y"/>
                <label className="form-check-label" htmlFor="G4_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G4" id="G4_n" value="N"/>
                <label className="form-check-label" htmlFor="G4_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>PUSH 발송내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G6" id="G6_y" value="Y"/>
                <label className="form-check-label" htmlFor="G6_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="G6" id="G6_n" value="N"/>
                <label className="form-check-label" htmlFor="G6_n">
                  불허
                </label>
              </div>
            </td>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_view_sm">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="6" className="text-left py-2">
              <span className="h6 bold700">인사관리</span>
            </td>
          </tr>
          <tr>
            <th>스텝 관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I1" id="I1_y" value="Y"/>
                <label className="form-check-label" htmlFor="I1_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I1" id="I1_n" value="N"/>
                <label className="form-check-label" htmlFor="I1_n">
                  불허
                </label>
              </div>
            </td>
            <th>이동내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I2" id="I2_y" value="Y"/>
                <label className="form-check-label" htmlFor="I2_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I2" id="I2_n" value="N"/>
                <label className="form-check-label" htmlFor="I2_n">
                  불허
                </label>
              </div>
            </td>
            <th>진급내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I3" id="I3_y" value="Y"/>
                <label className="form-check-label" htmlFor="I3_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I3" id="I3_n" value="N"/>
                <label className="form-check-label" htmlFor="I3_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>명칭/형태/부서 설정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I4" id="I4_y" value="Y"/>
                <label className="form-check-label" htmlFor="I4_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I4" id="I4_n" value="N"/>
                <label className="form-check-label" htmlFor="I4_n">
                  불허
                </label>
              </div>
            </td>
            <th>타겟수수료</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I5" id="I5_y" value="Y"/>
                <label className="form-check-label" htmlFor="I5_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I5" id="I5_n" value="N"/>
                <label className="form-check-label" htmlFor="I5_n">
                  불허
                </label>
              </div>
            </td>
            <th>그룹웨어 ID</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I6" id="I6_y" value="Y"/>
                <label className="form-check-label" htmlFor="I6_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I6" id="I6_n" value="N"/>
                <label className="form-check-label" htmlFor="I6_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>관심스포애너</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I7" id="I7_y" value="Y"/>
                <label className="form-check-label" htmlFor="I7_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I7" id="I7_n" value="N"/>
                <label className="form-check-label" htmlFor="I7_n">
                  불허
                </label>
              </div>
            </td>
            <th>ACE 활동</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I8" id="I8_y" value="Y"/>
                <label className="form-check-label" htmlFor="I8_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I8" id="I8_n" value="N"/>
                <label className="form-check-label" htmlFor="I8_n">
                  불허
                </label>
              </div>
            </td>
            <th>독서경영</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I9" id="I9_y" value="Y"/>
                <label className="form-check-label" htmlFor="I9_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I9" id="I9_n" value="N"/>
                <label className="form-check-label" htmlFor="I9_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>스텝 Web표시</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I10" id="I10_y" value="Y"/>
                <label className="form-check-label" htmlFor="I10_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="I10" id="I10_n" value="N"/>
                <label className="form-check-label" htmlFor="I10_n">
                  불허
                </label>
              </div>
            </td>
            <th>투자자리스트</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="H1" id="H1_y" value="Y"/>
                <label className="form-check-label" htmlFor="H1_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="H1" id="H1_n" value="N"/>
                <label className="form-check-label" htmlFor="H1_n">
                  불허
                </label>
              </div>
            </td>
            <th>투자자그룹</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="H2" id="H2_y" value="Y"/>
                <label className="form-check-label" htmlFor="H2_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="H2" id="H2_n" value="N"/>
                <label className="form-check-label" htmlFor="H2_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>메일발송내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="H3" id="H3_y" value="Y"/>
                <label className="form-check-label" htmlFor="H3_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="H3" id="H3_n" value="N"/>
                <label className="form-check-label" htmlFor="H3_n">
                  불허
                </label>
              </div>
            </td>
            <th></th>
            <td></td>
            <th></th>
            <td></td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_view_sm">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="6" className="text-left py-2">
              <span className="h6 bold700">물품판매</span>
            </td>
          </tr>
          <tr>
            <th>품목구분관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J1" id="J1_y" value="Y"/>
                <label className="form-check-label" htmlFor="J1_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J1" id="J1_n" value="N"/>
                <label className="form-check-label" htmlFor="J1_n">
                  불허
                </label>
              </div>
            </td>
            <th>품목분류관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J7" id="J7_y" value="Y"/>
                <label className="form-check-label" htmlFor="J7_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J7" id="J7_n" value="N"/>
                <label className="form-check-label" htmlFor="J7_n">
                  불허
                </label>
              </div>
            </td>
            <th>판매상품관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J2" id="J2_y" value="Y"/>
                <label className="form-check-label" htmlFor="J2_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J2" id="J2_n" value="N"/>
                <label className="form-check-label" htmlFor="J2_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>할인상품관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J3" id="J3_y" value="Y"/>
                <label className="form-check-label" htmlFor="J3_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J3" id="J3_n" value="N"/>
                <label className="form-check-label" htmlFor="J3_n">
                  불허
                </label>
              </div>
            </td>
            <th>입고관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J4" id="J4_y" value="Y"/>
                <label className="form-check-label" htmlFor="J4_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J4" id="J4_n" value="N"/>
                <label className="form-check-label" htmlFor="J4_n">
                  불허
                </label>
              </div>
            </td>
            <th>재고조정</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J5" id="J5_y" value="Y"/>
                <label className="form-check-label" htmlFor="J5_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J5" id="J5_n" value="N"/>
                <label className="form-check-label" htmlFor="J5_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>조정구분관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J6" id="J6_y" value="Y"/>
                <label className="form-check-label" htmlFor="J6_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J6" id="J6_n" value="N"/>
                <label className="form-check-label" htmlFor="J6_n">
                  불허
                </label>
              </div>
            </td>
            <th>판매등록</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J8" id="J8_y" value="Y"/>
                <label className="form-check-label" htmlFor="J8_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J8" id="J8_n" value="N"/>
                <label className="form-check-label" htmlFor="J8_n">
                  불허
                </label>
              </div>
            </td>
            <th>판매내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J9" id="J9_y" value="Y"/>
                <label className="form-check-label" htmlFor="J9_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J9" id="J9_n" value="N"/>
                <label className="form-check-label" htmlFor="J9_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>재고현황</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J10" id="J10_y" value="Y"/>
                <label className="form-check-label" htmlFor="J10_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J10" id="J10_n" value="N"/>
                <label className="form-check-label" htmlFor="J10_n">
                  불허
                </label>
              </div>
            </td>
            <th>재고현황(상세)</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J11" id="J11_y" value="Y"/>
                <label className="form-check-label" htmlFor="J11_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J11" id="J11_n" value="N"/>
                <label className="form-check-label" htmlFor="J11_n">
                  불허
                </label>
              </div>
            </td>
            <th>기간별판매현황(일별)</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J12" id="J12_y" value="Y"/>
                <label className="form-check-label" htmlFor="J12_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J12" id="J12_n" value="N"/>
                <label className="form-check-label" htmlFor="J12_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>기간별판매현황(주별)</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J13" id="J13_y" value="Y"/>
                <label className="form-check-label" htmlFor="J13_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J13" id="J13_n" value="N"/>
                <label className="form-check-label" htmlFor="J13_n">
                  불허
                </label>
              </div>
            </td>
            <th>기간별판매현황(월별)</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J14" id="J14_y" value="Y"/>
                <label className="form-check-label" htmlFor="J14_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J14" id="J14_n" value="N"/>
                <label className="form-check-label" htmlFor="J14_n">
                  불허
                </label>
              </div>
            </td>
            <th>상품별판매현황</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J15" id="J15_y" value="Y"/>
                <label className="form-check-label" htmlFor="J15_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J15" id="J15_n" value="N"/>
                <label className="form-check-label" htmlFor="J15_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>지점랭킹-매출</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J16" id="J16_y" value="Y"/>
                <label className="form-check-label" htmlFor="J16_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J16" id="J16_n" value="N"/>
                <label className="form-check-label" htmlFor="J16_n">
                  불허
                </label>
              </div>
            </td>
            <th>손실등록</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J17" id="J17_y" value="Y"/>
                <label className="form-check-label" htmlFor="J17_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J17" id="J17_n" value="N"/>
                <label className="form-check-label" htmlFor="J17_n">
                  불허
                </label>
              </div>
            </td>
            <th>기간별지점매출-종합</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J18" id="J18_y" value="Y"/>
                <label className="form-check-label" htmlFor="J18_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J18" id="J18_n" value="N"/>
                <label className="form-check-label" htmlFor="J18_n">
                  불허
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <th>공급업체관리</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J19" id="J19_y" value="Y"/>
                <label className="form-check-label" htmlFor="J19_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J19" id="J19_n" value="N"/>
                <label className="form-check-label" htmlFor="J19_n">
                  불허
                </label>
              </div>
            </td>
            <th>발주내역</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J20" id="J20_y" value="Y"/>
                <label className="form-check-label" htmlFor="J20_y">
                  허용
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="J20" id="J20_n" value="N"/>
                <label className="form-check-label" htmlFor="J20_n">
                  불허
                </label>
              </div>
            </td>
            <th></th>
            <td></td>
          </tr>
        </table>
      </div>

      <div className="mt-2 mb-4">
        <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.goBack()}>목록</button>
      </div>

    </CmnCard>
  );
};

export default SecureMenuAuthDetail;
