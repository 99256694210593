import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import search from "../../images/search.svg";
import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";

const MemberOnlineList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState(null);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [searchTxt, setSearchTxt] = useState('');

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    load(currPage);
  }, [currPage, renew]);

  useEffect(() => {
    if (srchRenew <= 0) return;
    load(1);
  }, [srchRenew]);

  const load = (page = 1) => {
    setSearchTxt($('#search_txt').val());

    axios2
      .post(`/member/new/online/list/ready?page=${page}&search_YN=${$('#search_txt').val().length > 0 ? "Y" : "N"}&search_type=&reg_step=&search_txt=${$('#search_txt').val()}&search_auth_code=&auth_code=`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setPageInfo(pageInfo);
          setRets(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  3-1.
  const make_monitor = () => {
    const as = window.open('/inc/black.html', 'enter_monitor', 'width=0,height=0,scrollbars=yes,top=10000,left=10000');
    as.window.close();
    const as2 = window.open('/enter/enter_monitor.html', 'enter_monitor', 'width=500,height=500,scrollbars=yes,top=600,left=1000');

    return () => {
      as2.close();
    };
  };

  //  4-1.
  const s_form = document.getElementById('form1');
  const center_change = (branch_code, mb_no) => {
    const auth_code = s_form.auth_code.value;
    if (auth_code === "") {
      alert('인증코드를 입력해주세요');
      s_form.search_auth_code.focus();
      return;
    }

    const url = `change_center.html?before_center=${branch_code}&mb_no=${mb_no}&auth_code=${auth_code}`;
    const windowFeatures = "width=300,height=200,menubar=no";

    window.open(url, "change_center", windowFeatures);
  };

  //  4-2.
  // center_change("123","123");

  //  5-1.
  const new_search = () => {
    if (s_form.search_txt.value === "") {
      alert("검색조건을 입력해주세요");
      return;
    }

    s_form.target = "_parent";
    s_form.action = "center_change.html";
    s_form.submit();
  };

  //  5-2.
  // new_search();

  //  6-1.
  const [type, setType] = useState("");
  const [searchAuthCode, setSearchAuthCode] = useState("");
  const [split, setSplit] = useState("");
  const [re_data, setRe_data] = useState("");
  const [re_cont1, setRe_cont1] = useState("");
  const [re_cont2, setRe_cont2] = useState("");
  const [authCodeName, setAuthCodeName] = useState("");
  const form1 = document.getElementById('form1');

  //  7-1.
  const select_intro = (mb_no) => {
    const auth_code = $('#auth_code').val();
    if (auth_code === "") {
      alert("인증코드를 입력해주세요");
      return;
    }

    if (window.confirm('선택한 온라인회원을 지점의 회원으로 등록하시겠습니까?')) {
      axios2.postEx('/member/online/reg', {
        mb_no: mb_no
      })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'dup_member') {
            alert('등록에러! 다시 시도해주세요.');
          } else if (data === 'exit') {
            alert('이미 지점회원으로 등록된 회원입니다.');
          } else {
            alert('처리되었습니다.');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>

      <div className="d-flex m-3 justify-content-between fn_search_box" style={{maxWidth: "735px"}}>
        <div className="d-flex">
          <div>
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="카드/이름/연락처 검색"
              id="search_txt"
            />
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
        </div>
      </div>

      <div className="m-3 table_list" style={{maxWidth: "735px"}}>
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="80px"/>
            <col width="90px"/>
            <col width="*"/>
            <col width="140px"/>
            <col width="90px"/>
            <col width="90px"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>성명</th>
            <th>이메일</th>
            <th>핸드폰</th>
            <th>등록일</th>
            <th>선택</th>
          </tr>
          {rets && rets.map((ret, i) =>
            <tr>
              <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
              <td>{ret?.mb_name}</td>
              <td>{ret?.member_id}</td>
              <td>{ret?.mb_hp}</td>
              <td>{(ret?.mb_open_date || '').replace(/-/g, '.').substr(2, 8)}</td>
              <td><input
                type="button"
                className="btn btn-ssm btn-outline-dark px-2"
                value="회원등록"
                onClick={() => select_intro(ret?.mb_no)}
              /></td>
            </tr>
          )}
          {(!rets || rets?.length === 0) &&
            <tr>
              {searchTxt ?
                <td colSpan={6}>※ 등록된 data가 없습니다.</td>
                :
                <td colSpan={6}>※ 검색 버튼을 클릭해 주세요.</td>
              }
            </tr>
          }
        </table>
      </div>

      <nav className="m-3 p-3 my-4 d-flex justify-content-center border-top" style={{maxWidth: "735px"}}>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

    </CmnCard>
  );
};

export default MemberOnlineList;
