import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";

import search from "../../images/search.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import {useHistory} from "react-router-dom";
import Modal from "react-modal";
import JDatePicker from "../common/JDatePicker";

const ConfigResearchList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});
  const [modalPageInfo, setModalPageInfo] = useState({});
  const [modalRenew, setModalRenew] = useState(0);
  const [modalSrchRenew, setModalSrchRenew] = useState(0);
  const [modalCurrPage, setModalCurrPage] = useState(1);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/config/research/list/ready?s_subject=${$('#s_subject').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }


  useEffect(() => {
    loadModal(1);
  }, [modalSrchRenew]);

  useEffect(() => {
    loadModal();
  }, [modalCurrPage, modalRenew]);

  function search_pub() {
    if (window.confirm('검색된 회원에게 설문조사를 등록 하시겠습니까?')) {
      if (!$("#overlay").is(":visible"))
        Fn.showSpinner();

      const url = `/config/research/member/search/new?base_seq=${$('#base_seq').val() || ''}&` +
        `search_YN=Y&o_date1=${$('#o_date1').val() || ''}&o_date2=${$('#o_date2').val() || ''}&date_type=${$('#date_type option:selected').val() || ''}&s_date1=${$('#s_date1').val() || ''}&s_date2=${$('#s_date2').val() || ''}&e_date1=${$('#e_date1').val() || ''}` +
        `&e_date2=${$('#e_date2').val() || ''}&l_date1=${$('#l_date1').val() || ''}&l_date2=${$('#l_date2').val() || ''}&s_mb_name=${$('#s_mb_name').val() || ''}&s_mb_hp=${$('#s_mb_hp').val() || ''}&s_member_id=${$('#s_member_id').val() || ''}&s_locker=${$('#s_locker option:selected').val() || ''}` +
        `&s_locker_name=${$('#s_locker_name').val() || ''}&s_suit=${$('#s_suit option:selected').val() || ''}&s_now_use=${$('#s_now_use option:selected').val() || ''}&s_ot_cnt=${$('#s_ot_cnt option:selected').val() || ''}&s_visit_course=${$('#s_visit_course option:selected').val() || ''}&s_sex=${$('#s_sex option:selected').val() || ''}` +
        `&s_mb_birth=${$('#s_mb_birth').val() || ''}&s_item_month=${$('#s_item_month option:selected').val() || ''}&s_item_grade=${$('#s_item_grade option:selected').val() || ''}&atd_date1=${$('#atd_date1').val() || ''}&atd_date2=${$('#atd_date2').val() || ''}` +
        `&s_start_hour=${$('#s_start_hour option:selected').val() || ''}&s_start_minute=${$('#s_start_minute option:selected').val() || ''}&s_end_hour=${$('#s_end_hour option:selected').val() || ''}&s_end_minute=${$('#s_end_minute option:selected').val() || ''}` +
        `&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_marketing_YN=${$('#s_marketing_YN option:selected').val() || ''}`;

      axios2.postEx(url, {})
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data == "err") {
            alert('오류가 발생하였습니다');
          } else if (data == "ok") {
            alert('등록되었습니다.');
            setRenew(Fn.getRenewTime());
            setModalOpen(false);
          }
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        })
        .finally(() => {
        });
    }
  }

  const loadModal = (_page) => {
    if (modalOpen) {
      const url = `/config/research/member/new/ready?base_seq=${$('#base_seq').val() || ''}&` +
        `search_YN=Y&o_date1=${$('#o_date1').val() || ''}&o_date2=${$('#o_date2').val() || ''}&date_type=${$('#date_type option:selected').val() || ''}&s_date1=${$('#s_date1').val() || ''}&s_date2=${$('#s_date2').val() || ''}&e_date1=${$('#e_date1').val() || ''}` +
        `&e_date2=${$('#e_date2').val() || ''}&l_date1=${$('#l_date1').val() || ''}&l_date2=${$('#l_date2').val() || ''}&s_mb_name=${$('#s_mb_name').val() || ''}&s_mb_hp=${$('#s_mb_hp').val() || ''}&s_member_id=${$('#s_member_id').val() || ''}&s_locker=${$('#s_locker option:selected').val() || ''}` +
        `&s_locker_name=${$('#s_locker_name').val() || ''}&s_suit=${$('#s_suit option:selected').val() || ''}&s_now_use=${$('#s_now_use option:selected').val() || ''}&s_ot_cnt=${$('#s_ot_cnt option:selected').val() || ''}&s_visit_course=${$('#s_visit_course option:selected').val() || ''}&s_sex=${$('#s_sex option:selected').val() || ''}` +
        `&s_mb_birth=${$('#s_mb_birth').val() || ''}&s_item_month=${$('#s_item_month option:selected').val() || ''}&s_item_grade=${$('#s_item_grade option:selected').val() || ''}&atd_date1=${$('#atd_date1').val() || ''}&atd_date2=${$('#atd_date2').val() || ''}` +
        `&s_start_hour=${$('#s_start_hour option:selected').val() || ''}&s_start_minute=${$('#s_start_minute option:selected').val() || ''}&s_end_hour=${$('#s_end_hour option:selected').val() || ''}&s_end_minute=${$('#s_end_minute option:selected').val() || ''}` +
        `&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_marketing_YN=${$('#s_marketing_YN option:selected').val() || ''}&page=${_page || modalCurrPage}`;

      axios2
        .post(url)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            console.log(result);

            if ($('#s_visit_course').html() === '') {
              let optionsHTML = '<option value="">가입경로</option>';
              result?.list?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.sub_code_name}">${list_row.sub_code_name}</option>`;
              });
              $('#s_visit_course').html(optionsHTML);
            }
            if ($('#s_item_grade').html() === '') {
              let optionsHTML = '<option value="">상품등급</option>';
              result?.bl?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
              });
              $('#s_item_grade').html(optionsHTML);
            }
            if ($('#s_branch_code').html() === '') {
              let optionsHTML = '<option value="">::지점::</option>';
              result?.topList?.forEach((list_row) => {
                optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
              });
              $('#s_branch_code').html(optionsHTML);
            }

            setModalResult(result);
            setModalPageInfo(pageInfo);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const modalReset = () => {
    $('#base_seq').val('');
    $('#o_date1').val('');
    $('#o_date2').val('');
    $('#date_type').val('');
    $('#s_date1').val('');
    $('#s_date2').val('');
    $('#e_date1').val('');
    $('#e_date2').val('');
    $('#l_date1').val('');
    $('#l_date2').val('');
    $('#s_mb_name').val('');
    $('#s_mb_hp').val('');
    $('#s_member_id').val('');
    $('#s_locker').val('');
    $('#s_locker_name').val('');
    $('#s_suit').val('');
    $('#s_now_use').val('');
    $('#s_ot_cnt').val('');
    $('#s_visit_course').val('');
    $('#s_sex').val('');
    $('#s_mb_birth').val('');
    $('#s_item_month').val('');
    $('#s_item_grade').val('');
    $('#atd_date1').val('');
    $('#atd_date2').val('');
    $('#s_start_hour').val('00')
    $('#s_start_minute').val('00')
    $('#s_end_hour').val('00');
    $('#s_end_minute').val('00');
    $('#s_branch_code').val('');
    $('#s_marketing_YN').val('');
  }

  const del_data = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 설문조사를 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/config/research/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  function send_push(seq) {
    $("#push_btn" + seq).hide();

    $.ajax({
      type: "post",
      url: "/config/poll/push/send",
      data: "poll_seq=" + seq,
      success: function (data) {
        alert("발송되었습니다");
        setRenew(Fn.getRenewTime());
      }
    });
  }

  return (
    <CmnCard onLogout={props.onLogout}>

      <div className="d-flex justify-content-between m-3">
        <div className="d-flex fn_search_box">
          <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="검색어 입력" id="s_subject"/>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>

        <div>
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => history.push("/config/research/write")}>등록</button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="60px"/>
            <col width="28%"/>
            <col width="8%"/>
            <col width="13%"/>
            <col width="*"/>
            <col width="8%"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th className="w_70px"><input type="checkbox" onClick={(e) => {
              if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
              else $('[id^=achk]').prop("checked", false);
            }}/></th>
            <th>No</th>
            <th>제목</th>
            <th>지급포인트</th>
            <th>기간</th>
            <th>상태</th>
            <th>대상등록</th>
            <th>대상인원</th>
            <th>전시</th>
            <th>결과보기</th>
            <th>push</th>
          </tr>

          {rets.map((item, i) => {
            const seq = item.seq;
            const title = item.title;
            const start_date = item.start_date;
            const end_date = item.end_date;
            const display_flag = item.display_flag;
            const add_point = item.add_point;
            const target_num = item.target_num;
            const push_send = item.push_send;
            const count = i + 1;

            const now_date = Fn.getDate(new Date());

            const base_seq = btoa(seq);

            return (
              <tr key={i}>
                <td>
                  <input
                    type="checkbox"
                    name={`chk[${count}]`}
                    value={base_seq}
                    className="checkbox"
                    id={`achk${count}`}
                  />
                  <label htmlFor={`achk${count}`}>
                    <span></span>
                  </label>
                </td>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td className="text-left"
                >
                  <a style={{cursor: "pointer"}}
                     onClick={() => {
                       history.push({
                         pathname: '/config/research/write', state: {
                           baseSeq: base_seq,
                         }
                       });
                     }}
                  >
                    <strong>
                      {title}
                    </strong>
                  </a>
                </td>
                <td>
                  <div align="right" style={{paddingRight: '5px'}}>
                    {Fn.numberWithCommas(add_point)}
                  </div>
                </td>
                <td>{start_date} ~ {end_date}</td>
                <td>
                  {now_date >= start_date && now_date <= end_date ? (
                    <strong className="text-primary">진행중</strong>
                  ) : now_date < start_date ? (
                    <strong>예정</strong>
                  ) : (
                    <strong className="text-danger">종료</strong>
                  )}
                </td>
                <td>
                  <input
                    type="button"
                    className="btn btn-ssm btn-outline-dark space_nowrap"
                    value="대상등록"
                    onClick={() => {
                      $('#base_seq').val(base_seq);
                      setModalOpen(true)
                    }}
                  />
                </td>
                <td>
                  <strong>{Fn.numberWithCommas(target_num)}</strong>
                </td>
                <td>
                  {display_flag === 'Y' ? (
                    <span className="label label-primary">전시</span>
                  ) : (
                    <span className="label label-danger">미전시</span>
                  )}
                </td>
                <td>
                  {now_date >= start_date ? (
                    <input
                      className="btn btn-ssm btn-outline-point"
                      type="button"
                      value="확인"
                      onClick={() => {
                        history.push({
                          pathname: '/config/research/view', state: {
                            baseSeq: base_seq,
                          }
                        });
                      }}
                    />
                  ) : null}
                </td>
                <td>
                  {push_send === 'N' ? (
                    <span id={`push_btn${seq}`}>
                    <input
                      className="btn btn-sm btn-danger"
                      type="button"
                      value="발송"
                      onClick={() => send_push(seq)}
                    />
                  </span>
                  ) : null}
                </td>
              </tr>
            );
          })}
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => del_data()}>삭제</button>
        </div>
      </nav>

      {/*팝업*/}

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01_wide p_absolute_mid_pop" style={{minWidth: '1080px'}}>
            <div className="POP_title">
              <h5><strong>설문대상 등록</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100" style={{minHeight: '580px'}}>
              <div className="search_box">
                {/* 회원검색 */}
                <div className="p-3">
                  <table className="view-rounded sub_view_sm">
                    <colgroup>
                      <col width="80px"/>
                      <col width="*"/>
                      <col width="110px"/>
                      <col width="*"/>
                      <col width="80px"/>
                      <col width="*"/>
                    </colgroup>
                    <tr>
                      <th>가입일</th>
                      <td>
                        <div className="input-group" style={{width: 'auto'}}>
                          <JDatePicker className="form-control table-inline" id="o_date1"/>
                          <p className="mx-1">~</p>
                          <JDatePicker className="form-control table-inline" id="o_date2"/>
                        </div>
                      </td>
                      <th>이름/연락처/ID</th>
                      <td colSpan="3">
                        <div className="input-group">
                          <input type="text" className="form-control table-inline mr-1" placeholder="이름" id="s_mb_name"/>
                          <input type="text" className="form-control table-inline mr-1" placeholder="연락처" id="s_mb_hp"/>
                          <input type="text" className="form-control table-inline" placeholder="아이디" id="s_member_id"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>운동시작일</th>
                      <td>
                        <div className="input-group" style={{width: 'auto'}}>
                          <JDatePicker className="form-control table-inline" id="s_date1"/>
                          <p className="mx-1">~</p>
                          <JDatePicker className="form-control table-inline" id="s_date2"/>
                        </div>
                      </td>
                      <th>운동복</th>
                      <td>
                        <div className="form-group">
                          <select className="form-select-sm table-inline mr-1" id="s_suit">
                            <option value="">운동복</option>
                            <option value="1">사용</option>
                            <option value="x">미사용</option>
                          </select>
                          <select className="form-select-sm table-inline" id="s_now_use">
                            <option value="">이용구분</option>
                            <option value="Y">이용회원</option>
                            <option value="N">중단회원</option>
                          </select>
                        </div>
                      </td>
                      <th>성별/생일</th>
                      <td className="text-left">
                        <div className="d-flex">
                          <select className="form-select-sm table-inline mr-1" style={{maxWidth: '100px'}} id="s_sex">
                            <option value="">성별</option>
                            <option value="M">남성</option>
                            <option value="F">여성</option>
                          </select>
                          <JDatePicker type="text" className="form-control table-inline" placeholder="생년월일" id="s_mb_birth"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>운동종료일</th>
                      <td>
                        <div className="input-group" style={{width: 'auto'}}>
                          <JDatePicker className="form-control table-inline" id="e_date1"/>
                          <p className="mx-1">~</p>
                          <JDatePicker className="form-control table-inline" id="e_date2"/>
                        </div>
                      </td>
                      <th>OT횟수</th>
                      <td className="text-left">
                        <div className="form-group">
                          <select className="form-select-sm table-inline mr-1" id="s_ot_cnt">
                            <option value="">OT횟수</option>
                            <option value="x">미실시</option>
                            <option value="1">1회</option>
                            <option value="2">2회</option>
                          </select>
                          <select className="form-select-sm table-inline" id="s_visit_course">
                          </select>
                        </div>
                      </td>
                      <th>상품종류</th>
                      <td>
                        <div className="form-group">
                          <select className="form-select-sm table-inline mr-1" id="s_item_grade">
                          </select>
                          <select className="form-select-sm table-inline" id="s_item_month">
                            <option value="">상품개월수</option>
                            <option value="1">1개월</option>
                            <option value="3">3개월</option>
                            <option value="6">6개월</option>
                            <option value="12">12개월</option>
                            <option value="18">18개월</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>라커종료일</th>
                      <td>
                        <div className="input-group" style={{width: 'auto'}}>
                          <JDatePicker className="form-control table-inline" id="l_date1"/>
                          <p className="mx-1">~</p>
                          <JDatePicker className="form-control table-inline" id="l_date2"/>
                        </div>
                      </td>
                      <th>라커사용여부</th>
                      <td>
                        <div className="d-flex">
                          <select className="form-select-sm table-inline mr-1" style={{maxWidth: '100px'}}>
                            <option value="">라커</option>
                            <option value="1">사용</option>
                            <option value="x">미사용</option>
                            <option value="2">예약</option>
                            <option value="3">미지급</option>
                          </select>
                          <input type="text" className="form-control table-inline" placeholder="라커번호"/>
                        </div>
                      </td>
                      <th>출석일</th>
                      <td className="text-left">
                        <div className="input-group" style={{width: 'auto'}}>
                          <JDatePicker className="form-control" aria-describedby="button-addon2" id="atd_date1"/>
                          <p className="mx-1">~</p>
                          <JDatePicker className="form-control" aria-describedby="button-addon2" id="atd_date2"/>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>입장시간</th>
                      <td colSpan="3">
                        <div className="input-group" style={{width: 'auto'}}>
                          <select className="form-select-sm table-inline" id="s_start_hour" style={{maxWidth: "40px"}}>
                            {Array.from({length: 24}).map((_, i) => {
                              const j = i < 10 ? `0${i}` : i;
                              return (
                                <option value={j}>{j}시</option>
                              );
                            })}
                          </select>
                          <p className="p-1">:</p>
                          <select className="form-select-sm table-inline" id="s_start_minute" style={{maxWidth: "40px"}}>
                            {Array.from({length: 60}).map((_, i) => {
                              const j = i < 10 ? `0${i}` : i;
                              return (
                                <option value={j}>{j}분</option>
                              );
                            })}
                          </select>
                          <p className="mx-1">~</p>
                          <select className="form-select-sm table-inline" id="s_end_hour" style={{maxWidth: "40px"}}>
                            {Array.from({length: 24}).map((_, i) => {
                              const j = i < 10 ? `0${i}` : i;
                              return (
                                <option value={j}>{j}시</option>
                              );
                            })}
                          </select>
                          <p className="p-1">:</p>
                          <select className="form-select-sm table-inline" id="s_end_minute" style={{maxWidth: "40px"}}>
                            {Array.from({length: 60}).map((_, i) => {
                              const j = i < 10 ? `0${i}` : i;
                              return (
                                <option value={j}>{j}분</option>
                              );
                            })}
                          </select>
                        </div>
                      </td>
                      <td colSpan="2" className="text-left">
                        <div className="form-group">
                          <select className="form-select-sm table-inline mx-1" id="s_branch_code">
                          </select>
                          <select className="form-select-sm table-inline" id="s_marketing_YN">
                            <option value="">전체</option>
                            <option value="Y">동의</option>
                            <option value="N">미동의</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <div className="text-right mt-2">
                    <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => {
                      if (!$('#s_branch_code option:selected').val()) {
                        alert('지점을 선택해 주세요.');
                        return;
                      }
                      setModalSrchRenew(Fn.getRenewTime());
                    }}>검 색
                    </button>
                    <button type="button" className="btn btn-sm btn-dark px-2" onClick={() => modalReset()}>검색 초기화</button>
                  </div>
                </div>
              </div>

              <div className="text-center mt-3">
                <table className="view-rounded sub_table_border">
                  <colgroup>
                    <col width="60px"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="60px"/>
                    <col width="80px"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                    <col width="*"/>
                  </colgroup>

                  <tr>
                    <th>No</th>
                    <th>지점명</th>
                    <th>카드번호</th>
                    <th>성명</th>
                    <th>연락처</th>
                    <th>성별</th>
                    <th>연령</th>
                    <th>가입일</th>
                    <th>시작일</th>
                    <th>종료일</th>
                    <th>미수금</th>
                  </tr>

                  {modalResult?.result && modalResult?.result?.map((ret, i) => {
                    let mb_no = ret?.mb_no;
                    let mb_id = ret?.mb_id;
                    let mb_name = ret?.mb_name;
                    let mb_hp = ret?.mb_hp;
                    let mb_sex = ret?.mb_sex;
                    let mb_birth = ret?.mb_birth;
                    let mb_open_date = ret?.mb_open_date;
                    let start_date = ret?.start_date;
                    let end_date = ret?.end_date;
                    let locker = ret?.locker;
                    let locker_name = ret?.locker_name;
                    let suit = ret?.suit;
                    let remain_price = ret?.remain_price;
                    let suit_start_date = ret?.suit_start_date;
                    let suit_end_date = ret?.suit_end_date;
                    let locker_start_date = ret?.locker_start_date;
                    let locker_end_date = ret?.locker_end_date;
                    let opt1 = ret?.opt1;
                    let opt2 = ret?.opt2;
                    let opt3 = ret?.opt3;
                    let opt4 = ret?.opt4;
                    let opt5 = ret?.opt5;
                    let opt6 = ret?.opt6;
                    let opt7 = ret?.opt7;
                    let opt8 = ret?.opt8;
                    let opt9 = ret?.opt9;
                    let opt10 = ret?.opt10;
                    let g_locker = ret?.g_locker;
                    let ot_cnt = ret?.ot_cnt;
                    let buy_month = ret?.buy_month;
                    let member_grade_name = ret?.member_grade_name;
                    let member_id = ret?.member_id;

                    let etc1_start_date = ret?.etc1_start_date;
                    let etc2_start_date = ret?.etc2_start_date;
                    let etc3_start_date = ret?.etc3_start_date;
                    let etc4_start_date = ret?.etc4_start_date;
                    let etc5_start_date = ret?.etc5_start_date;

                    let etc1_end_date = ret?.etc1_end_date;
                    let etc2_end_date = ret?.etc2_end_date;
                    let etc3_end_date = ret?.etc3_end_date;
                    let etc4_end_date = ret?.etc4_end_date;
                    let etc5_end_date = ret?.etc5_end_date;

                    let branch_code = ret?.branch_code;

                    let date_type = "A";
                    if (date_type === "A") {
                      start_date = start_date;
                      end_date = end_date;
                    } else if (date_type === "B") {
                      start_date = etc3_start_date;
                      end_date = etc3_end_date;
                    } else if (date_type === "C") {
                      start_date = etc1_start_date;
                      end_date = etc1_end_date;
                    } else if (date_type === "D") {
                      start_date = etc2_start_date;
                      end_date = etc2_end_date;
                    } else if (date_type === "E") {
                      start_date = etc4_start_date;
                      end_date = etc4_end_date;
                    } else if (date_type === "F") {
                      start_date = etc5_start_date;
                      end_date = etc5_end_date;
                    } else {
                      start_date = start_date;
                      end_date = end_date;
                    }

                    const BranchOpt1 = "골프";        //고정
                    const BranchOpt2 = "골프라커";    //고정
                    const BranchOpt3 = "스쿼시";
                    const BranchOpt4 = "스피닝";
                    const BranchOpt5 = "태닝";
                    const BranchOpt6 = "토탈";
                    const BranchOpt7 = "필라테스";
                    const BranchOpt8 = "Opt1";
                    const BranchOpt9 = "Opt2";
                    const BranchOpt10 = "Opt3";

                    let opt_use = "";
                    if (opt1 === 1) opt_use += BranchOpt1 + ",";
                    if (g_locker === 1) opt_use += BranchOpt2 + ",";
                    if (opt3 === 1) opt_use += BranchOpt3 + ",";
                    if (opt4 === 1) opt_use += BranchOpt4 + ",";
                    if (opt5 === 1) opt_use += BranchOpt5 + ",";
                    if (opt6 === 1) opt_use += BranchOpt6 + ",";
                    if (opt7 === 1) opt_use += BranchOpt7 + ",";
                    if (opt8 === 1) opt_use += BranchOpt8 + ",";
                    if (opt9 === 1) opt_use += BranchOpt9 + ",";
                    if (opt10 === 1) opt_use += BranchOpt10 + ",";

                    let mb_sex_show = "";
                    if (mb_sex === "M") {
                      mb_sex_show = "남";
                    } else if (mb_sex === "F") {
                      mb_sex_show = "여";
                    }

                    let m_birth_str = mb_birth?.substring(0, 2);
                    let birth_show = 0;

                    if (m_birth_str === "19") {
                      birth_show = 100;
                    } else {
                      let m_real_b = "";
                      if (m_birth_str < "30") {
                        m_real_b = "20" + m_birth_str;
                      } else {
                        m_real_b = "19" + m_birth_str;
                      }
                      birth_show = new Date().getFullYear() - parseInt(m_real_b) + 1;
                    }

                    return (
                      <tr>
                        <td>{modalPageInfo.totalArticles - modalPageInfo.articlesPerPage * (modalPageInfo.currentPage - 1) - i}</td>
                        <td>{branch_code}</td>
                        <td>{mb_id}</td>
                        <td>{mb_name}</td>
                        <td>{mb_hp}</td>
                        <td>{mb_sex_show}</td>
                        <td>{birth_show > 0 && birth_show}</td>
                        <td>{mb_open_date?.substring(0, 10)}</td>
                        <td>{start_date}</td>
                        <td>{end_date}</td>
                        <td>{ret?.remain_price}</td>
                      </tr>
                    );
                  })}
                  {(!modalResult?.result || modalResult?.result?.length === 0) &&
                    <tr>
                      <td colSpan={11}>※ 검색조건 선택후 검색해주세요.</td>
                    </tr>
                  }
                </table>
              </div>
              <nav className="p-0 m-0 mt-3 d-flex justify-content-center border-top flex-wrap">
                <Paging pageInfo={modalPageInfo} setCurrPage={page => setModalCurrPage(page)}/>
              </nav>
            </div>
            <div className="text-center my-2">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => search_pub()}>검색 등록</button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>취소</button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="base_seq"/>

    </CmnCard>
  );
};

export default ConfigResearchList;
