import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import _ from "lodash";
import JDatePicker from "../common/JDatePicker";

const BranchopDailyWorkList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [sDate, setSDate] = useState(Fn.getCurrentDate());
  const [ret, setRet] = useState({});
  const [rets, setRets] = useState([]);
  const [tabType, setTabType] = useState("1");
  const [srchRenew, setSrchRenew] = useState(0);

  const onTab = (type) => {
    setTabType(type);
    load2(type);

    setTimeout(() => {
      if (type === '1') {
        $('#aa').prop('checked', true);
        $('#bb').prop('checked', false);
        $('#cc').prop('checked', false);
        $('#dd').prop('checked', false);
        $('#ee').prop('checked', false);
      } else if (type === '2') {
        $('#aa').prop('checked', false);
        $('#bb').prop('checked', true);
        $('#cc').prop('checked', false);
        $('#dd').prop('checked', false);
        $('#ee').prop('checked', false);
      } else if (type === '3') {
        $('#aa').prop('checked', false);
        $('#bb').prop('checked', false);
        $('#cc').prop('checked', true);
        $('#dd').prop('checked', false);
        $('#ee').prop('checked', false);
      } else if (type === '4') {
        $('#aa').prop('checked', false);
        $('#bb').prop('checked', false);
        $('#cc').prop('checked', false);
        $('#dd').prop('checked', true);
        $('#ee').prop('checked', false);
      } else if (type === '5') {
        $('#aa').prop('checked', false);
        $('#bb').prop('checked', false);
        $('#cc').prop('checked', false);
        $('#dd').prop('checked', false);
        $('#ee').prop('checked', true);
      }
    }, 150);
  }

  useEffect(() => {
    $('#s_date').val(Fn.getCurrentDate());
    // $('#s_date').val('2021-11-04');
  }, []);

  useEffect(() => {
    load();
    load2(tabType);
  }, [srchRenew]);

  const load = () => {
    setSDate($('#s_date').val());
    axios2
      .post(`/analysis/daily/work/list/ready?s_date=${$('#s_date').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRet(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const load2 = (type) => {
    let url = `/analysis/daily/work/service?s_date=${$('#s_date').val()}`;
    if (type == '2') url = `/analysis/daily/work/history?s_date=${$('#s_date').val()}`;
    else if (type == '3') url = `/analysis/daily/work/ipkum?s_date=${$('#s_date').val()}`;
    else if (type == '4') url = `/analysis/daily/work/delay?s_date=${$('#s_date').val()}`;
    else if (type == '5') url = `/analysis/daily/work/inout?s_date=${$('#s_date').val()}`;

    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex justify-content-between m-3">
        <p className="h5 bold700">&nbsp;</p>
        <div className="d-flex">
          <div className="pr-1">
            <JDatePicker className="form-control" id="s_date"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>
      </div>

      <div className="m-3 p_rative" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item" defaultChecked/>
          <label className="tab_item" htmlFor="aa" onClick={() => onTab('1')}>서비스신청({ret?.d1Num || 0})</label>
          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => onTab('2')}>히스토리({ret?.d2Num || 0})</label>
          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => onTab('3')}>입금내역({ret?.d3Num || 0})</label>
          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => onTab('4')}>연기내역({ret?.d4Num || 0})</label>
          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => onTab('5')}>장부내역({ret?.d5Num || 0})</label>
        </div>
      </div>

      <div className="m-3 pb-2">
        <table className="view-rounded sub_table_border auto">
          {tabType === "1" &&
            <tr>
              <th className="w_70px">No</th>
              <th>시분</th>
              <th>카드번호</th>
              <th>성명</th>
              <th>연락처</th>
              <th>상품명</th>
              <th>금액</th>
              <th>비고</th>
              <th>등록자</th>
              <th>접수자</th>
            </tr>
          }
          {tabType === "2" &&
            <tr>
              <th className="w_70px">No</th>
              <th>시분</th>
              <th>카드번호</th>
              <th>성명</th>
              <th>연락처</th>
              <th>구분</th>
              <th>비고</th>
              <th>등록자</th>
            </tr>
          }
          {tabType === "3" &&
            <tr>
              <th className="w_70px">No</th>
              <th>시분</th>
              <th>카드번호</th>
              <th>성명</th>
              <th>연락처</th>
              <th>시작일</th>
              <th>연기기간</th>
              <th className="w-30">비고</th>
              <th>등록자</th>
            </tr>
          }
          {tabType === "4" &&
            <tr>
              <th className="w_70px">No</th>
              <th>시분</th>
              <th>카드번호</th>
              <th>성명</th>
              <th>연락처</th>
              <th>형태</th>
              <th>금액</th>
              <th className="w-30">비고</th>
              <th>등록자</th>
            </tr>
          }
          {tabType === "5" &&
            <tr>
              <th className="w_70px">No</th>
              <th>시분</th>
              <th>계정</th>
              <th>계좌</th>
              <th>금액</th>
              <th className="w-40">비고</th>
              <th>등록자</th>
            </tr>
          }
          {tabType == "1" && rets?.length > 0 && rets.map((row, i) => {
            const mb_info_split = row?.mb_info?.split("||");
            const [mb_id, mb_name, mb_hp] = mb_info_split || ['', '', ''];

            const item_name = row?.ap_pdtName || row?.it_name;

            const ap_amt = Fn.numberWithCommas(row?.ap_amt);

            const mb_hp_replaced = mb_hp?.replace(/-/g, "");

            let mb_hp_show;
            if (mb_hp_replaced.length === 11) {
              mb_hp_show = `${mb_hp_replaced.substr(0, 3)}-${mb_hp_replaced.substr(3, 4)}-${mb_hp_replaced.substr(7, 4)}`;
            } else if (mb_hp_replaced.length === 10) {
              mb_hp_show = `${mb_hp_replaced.substr(0, 3)}-${mb_hp_replaced.substr(3, 3)}-${mb_hp_replaced.substr(6, 4)}`;
            } else {
              mb_hp_show = mb_hp;
            }

            return (
              <tr>
                <td>{i + 1}</td>
                <td>{row?.ap_time}</td>
                <td>{mb_id}</td>
                <td>{mb_name}</td>
                <td>{mb_hp_show}</td>
                <td className="text-left">{item_name}</td>
                <td className="text-right">{ap_amt}</td>
                <td className="text-left">{row?.ap_etc}</td>
                <td>{row?.ap_step}</td>
                <td>{row?.reg_step}</td>
              </tr>
            )
          })}
          {tabType == "2" && rets?.length > 0 && rets.map((row, index) => {
            const mbInfoSplit = row?.mb_info?.split("||");
            let mbId = mbInfoSplit?.[0];
            let mbName = mbInfoSplit?.[1] || row.hi_mb_name;
            let mbHp = mbInfoSplit?.[2] || row.hi_mb_hp;

            mbHp = mbHp?.replaceAll("-", "");

            let mbHpShow;
            if (mbHp?.length === 11) {
              mbHpShow = `${mbHp?.substring(0, 3)}-${mbHp?.substring(3, 7)}-${mbHp?.substring(7, 11)}`;
            } else if (mbHp?.length === 10) {
              mbHpShow = `${mbHp?.substring(0, 3)}-${mbHp?.substring(3, 6)}-${mbHp?.substring(6, 10)}`;
            } else {
              mbHpShow = mbHp;
            }

            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row?.hi_time}</td>
                <td>{mbId}</td>
                <td>{mbName}</td>
                <td>{mbHpShow}</td>
                <td style={{textAlign: "left", paddingLeft: "5px"}}>{row?.hi_type}</td>
                <td style={{textAlign: "left", paddingLeft: "5px"}}>{row?.hi_etc}</td>
                <td>{row?.hi_step}</td>
              </tr>
            )
          })}
          {tabType == "3" && rets?.length > 0 && rets.map((row, index) => {
            const mbInfoSplit = row?.mb_info?.split("||");
            let mbId = mbInfoSplit?.[0];
            let mbName = mbInfoSplit?.[1];
            let mbHp = mbInfoSplit?.[2].replaceAll("-", "");

            let mbHpShow;
            if (mbHp?.length === 11) {
              mbHpShow = `${mbHp?.substring(0, 3)}-${mbHp?.substring(3, 7)}-${mbHp?.substring(7, 11)}`;
            } else if (mbHp?.length === 10) {
              mbHpShow = `${mbHp?.substring(0, 3)}-${mbHp?.substring(3, 6)}-${mbHp?.substring(6, 10)}`;
            } else {
              mbHpShow = mbHp;
            }

            let inputTxt;
            switch (row.ip_method) {
              case "보통예금":
                row.ip_method = "이체";
                break;
              case "카드매출금":
                row.ip_method = "카드";
                break;
              case "잡손실":
                row.ip_method = "대체손실";
                break;
              case "현금":
                row.ip_method = "현금";
                break;
              default:
                row.ip_method = "";
            }

            if (row.ip_cardName) {
              inputTxt = `${row.ip_method}(${row.ip_cardName})`;
            } else {
              inputTxt = row.ip_method;
            }

            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row?.ip_time}</td>
                <td>{mbId}</td>
                <td>{mbName}</td>
                <td>{mbHpShow}</td>
                <td style={{textAlign: "left", paddingLeft: "5px"}}>{inputTxt}</td>
                <td style={{textAlign: "right", paddingRight: "5px"}}>{Number(row.ip_amt).toLocaleString()}</td>
                <td style={{textAlign: "left", paddingLeft: "5px"}}>{row.ip_etc}</td>
                <td>{row?.ip_step}</td>
              </tr>
            )
          })}
          {tabType == "4" && rets?.length > 0 && rets.map((row, index) => {
            const mbInfoSplit = row?.mb_info?.split("||");
            const mbId = mbInfoSplit?.[0];
            const mbName = mbInfoSplit?.[1];
            let mbHp = mbInfoSplit?.[2].replaceAll("-", "");

            let mbHpShow;
            if (mbHp?.length === 11) {
              mbHpShow = `${mbHp?.substring(0, 3)}-${mbHp?.substring(3, 7)}-${mbHp?.substring(7, 11)}`;
            } else if (mbHp?.length === 10) {
              mbHpShow = `${mbHp?.substring(0, 3)}-${mbHp?.substring(3, 6)}-${mbHp?.substring(6, 10)}`;
            } else {
              mbHpShow = mbHp;
            }

            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row?.de_regTime}</td>
                <td>{mbId}</td>
                <td>{mbName}</td>
                <td>{mbHpShow}</td>
                <td>{row?.de_sDate}</td>
                <td style={{textAlign: "right", paddingRight: "5px"}}>{row.de_period}</td>
                <td style={{textAlign: "left", paddingLeft: "5px"}}>{row.de_etc}</td>
                <td>{row?.de_step}</td>
              </tr>
            );
          })}
          {tabType == "5" && rets?.length > 0 && rets.map((row, index) => {
            return (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{row?.ckk_time}</td>
                <td style={{textAlign: "left", paddingLeft: "5px"}}>{row?.ckk_acname}</td>
                <td style={{textAlign: "left", paddingLeft: "5px"}}>{row?.ckk_bankname}</td>
                <td style={{textAlign: "right", paddingRight: "5px"}}>{new Intl.NumberFormat().format(row?.ckk_amt)}</td>
                <td style={{textAlign: "left", paddingLeft: "5px"}}>{row?.ckk_etc}</td>
                <td>{row?.ckk_step}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={10}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>
    </CmnCard>
  );
};

export default BranchopDailyWorkList;
