import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Modal from "react-modal";
import ChkAuthNum from "../common/ChkAuthNum";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";

const LockerState = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [loc, setLoc] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal1Result, setModal1Result] = useState({});
  const [modal2Result, setModal2Result] = useState({});
  const [modal1CurrPage, setModal1CurrPage] = useState(1);
  const [modal2CurrPage, setModal2CurrPage] = useState(1);
  const [modal1PageInfo, setModal1PageInfo] = useState(1);
  const [modal2PageInfo, setModal2PageInfo] = useState(1);
  const [modal1SrchRenew, setModal1SrchRenew] = useState(0);

  useEffect(() => {
    if (modal1SrchRenew <= 0) return;
    loadModal1(1);
  }, [modal1SrchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  // useEffect(() => {
  //   $('.div-locker').remove();
  // }, [loc]);

  const load = () => {
    //$('.div-locker').unwrap('.div-locker-wrap');
    // $('.div-locker-wrap:last').find("div").first().unwrap();
    // // $('.div-locker-wrap:last').find("div").first().unwrap();
    axios2
      .post(`/locker/locker/state/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          let oddMax = 0, evenMax = 0;
          const loc = result?.loc;
          Object.entries(loc).map(([k, v], index) => {
            const odd = index % 2 === 1;
            if (odd) {
              oddMax = Math.max(oddMax, Array.from({length: v.x})?.length);
            } else {
              evenMax = Math.max(evenMax, Array.from({length: v.x})?.length);
            }
          });

          setLoc(result?.loc);

          setTimeout(() => {
            $('.div-even').css({width: (evenMax * 50) + 50, minWidth: (evenMax * 50) + 50});
            $('.div-odd').css({width: (oddMax * 50) + 50, minWidth: (oddMax * 50) + 50});
          }, 300);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function DateGab(date1, date2) {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const startDate = new Date(date1);
    const endDate = new Date(date2);

    return Math.round(Math.abs((startDate - endDate) / oneDay));
  }

  function select_member(a, Lnum) {
  }

  //  2-1.
  const popUp = (file, names, width, height, sc) => {
    const w = width;
    const h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h},scrollbars=${sc},top=${wint},left=${winl}`);
  };

  function popLoc(val, name, mb_no, gubun, locker_number) {
    $('#mv_val').val(val);
    $('#mv_name').val(name);
    $('#mv_mb_no').val(mb_no);
    $('#mv_gubun').val(gubun);
    $('#mv_locker_number').val(locker_number);

    setModal2Open(true);
    // popUp(`locker_info_edit.html?val=${val}&name=${name}&mb_no=${mb_no}&gubun=${gubun}&locker_number=${locker_number}`, 'locker_name_reg', '700', '600', 'yes');
  }

  function selectMember(val, locker_number) {
    $('#mv_val').val(val);
    $('#mv_locker_number').val(locker_number);

    setModal1Open(true);
    // popUp(`search_member_new.html?val=${val}&locker_number=${locker_number}`, 'search_member', '580', '450', 'yes');
  }

  useEffect(() => {
    if (!modal1Open) return;
    loadModal1();
  }, [modal1CurrPage]);

  const loadModal1 = (_page) => {
    axios2
      .post(`/locker/search/member/new/ready?val=${$('#mv_val').val()}&locker_number=${$('#mv_locker_number').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_hp=${$('#s_mb_hp').val()}&page=${_page || modal1CurrPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModal1Result(result);
          setModal1PageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  useEffect(() => {
    if (!modal2Open) return;
    loadModal2();
  }, [modal2CurrPage]);

  const loadModal2 = () => {
    $('#mp_name').html($('#mv_name').val());
    $('#mp_val').html($('#mv_val').val());

    axios2
      .post(`/locker/locker/state/ready?page=${modal2CurrPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModal2Result(result);
          setModal2PageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const changeLocker = (click, po, gubun, Lnum) => {
    if (click == 'Y') {
      alert('미사용중인 라커를 선택해주세요');
      return;
    }

    if (!window.confirm('라커를 교환하시겠습니까?')) return;

    if ($('#locker_auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      $('#locker_auth_code').focus();
      return;
    }

    axios2
      .postEx('/locker/exchange/locker', {
        auth_code: $('#locker_auth_code').val(),
        val: $('#mv_val').val(),
        lo_posi: $('#mv_val').val(),
        clear_type: "locker",
        name: $('#mv_name').val(),
        mb_no: $('#mv_mb_no').val(),
        locker_number: $('#mv_locker_number').val(),
        Lnum: Lnum,
        gubun: gubun,
        new_lo_posi: po,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (data == "ok") {
          setModal2Open(false);
          alert('라커가 교환되었습니다.');
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function copyData(mb_no, mb_name) {
    if ($('#locker_auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      $('#locker_auth_code').focus();
      return;
    }

    axios2
      .postEx('/locker/locker/bae', {
        auth_code: $('#locker_auth_code').val(),
        val: $('#mv_val').val(),
        mb_no: mb_no,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (data == "ok") {
          setModal1Open(false);
          alert('라커가 배정되었습니다.');
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function clear_locker() {
    if ($('#locker_auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      $('#locker_search_auth_code').focus();
      return;
    }

    if (window.confirm('해당 라커를 비움처리 하시겠습니까?')) {
      axios2
        .postEx('/locker/clear/locker/pop', {
          lo_posi: $('#mv_val').val(),
          mb_no: $('#mv_mb_no').val(),
          auth_code: $('#locker_auth_code').val(),
          clear_type: $('#mv_gubun').val(),
          locker_number: $('#mv_locker_number').val(),
        })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          if (data === "err") {
            alert('오류가 발생하였습니다');
          } else {
            setModal2Open(false);
            alert("비움처리 되었습니다");
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 text-left d-flex div-title">
        <p className="px-3 bold700">
          <span className="lock_clr00">사용안함</span> 사용안함
        </p>
        <p className="px-3 bold700">
          <span className="lock_clr01">사용중</span> 사용중
        </p>
        <p className="bold700">
          <span className="lock_clr02">이용종료후 14일</span> 이용종료후 14일 미만
        </p>
        <p className="px-3 bold700">
          <span className="lock_clr03">이용종료후 28일</span> 이용종료후 28일 미만
        </p>
        <p className="px-3 bold700">
          <span className="lock_clr04">이용종료후 30일</span> 이용종료후 30일 미만
        </p>
        <p className="px-3 bold700">
          <span className="lock_clr05">이용종료후 30일이상</span> 이용종료후 30일 이상
        </p>
      </div>
      <div className="cls-locker-area">
        {
          Object.entries(loc).map(([k, v], index) => {
            k = k.replace("_", "");

            const evenRow = index % 2 === 0;
            const lastRow = Object.entries(loc)?.length === index + 1;
            // if(lastRow && evenRow) $('.wrap-'+index).append(`<div></div>`);

            if (!evenRow) {
              setTimeout(() => {
                if ($('#div_' + index).parents('.wrap-' + index).length === 0)
                  $('#div_' + (index - 1) + ',#div_' + index).wrapAll('<div class="div-locker div-locker-wrap wrap-' + index + ' d-flex m-4 flex_gap2"></div>');
                // $('#div_' + (index - 1) + ',#div_' + index).wrapAll('<div class="div-locker div-locker-wrap wrap-' + index + ' d-flex m-4 flex_gap2 row-cols-2"></div>');
              });
            } else {
              if (lastRow && Object.entries(loc)?.length % 2 === 1) {
                if (!$('#div_' + index).parent().hasClass('div-locker')) {
                  setTimeout(() => {
                    $('#div_' + index).wrapAll('<div class="div-locker div-locker-wrap wrap-' + index + ' d-flex m-4 flex_gap2" style="min-width: 100% !important; padding-right: 40px !important;"></div>')
                    // $('#div_' + index).wrapAll('<div class="div-locker div-locker-wrap wrap-' + index + ' d-flex m-4 flex_gap2 row-cols-2" style="min-width: 100% !important; padding-right: 40px !important;"></div>')
                    //$('.wrap-' + index).append(`<div class="div-locker"></div>`);
                  });
                }
              }
            }
            return (
              <div id={`div_${index}`} className={`div-locker p-0 text-left table_box ${evenRow ? `div-even` : `div-odd`}`}>
                <p className="border-bottom p-2">
                  <span className="h7 bold700 px-2"><strong>{k}</strong> : {v.y} &times; {v.x} ({v.x * v.y}) {v.s}~{v.e}</span>
                </p>
                <div className="m-3">
                  <table className="view-rounded locker_view" style={{width: "auto"}}>
                    {Array.from({length: v.y}).map((_, a) => (
                      <tr key={a}>
                        {Array.from({length: v.x}).map((_, b) => {
                          const po = `${k}-${a + v.s}-${b + 1}`;
                          let Lnum = v[`${k}-${a + v.s}-${b + 1}`].Lnum;
                          let end_date, gubun, click, bgclr, po2;

                          const e_chk_row = v?.[po];

                          if (e_chk_row?.lo_posi) {
                            Lnum = e_chk_row.lo_num;
                          } else {
                            Lnum = Lnum;
                          }

                          if (e_chk_row?.locker === "Y") {
                            end_date = e_chk_row.locker_end_date;
                            gubun = "locker";
                          } else if (e_chk_row?.g_locker === "Y") {
                            end_date = e_chk_row.g_locker_end_date;
                            gubun = "g_locker";
                          } else {
                            end_date = e_chk_row?.locker_end_date;
                            gubun = "locker";
                          }

                          const now_date = Fn.getDate(new Date());

                          if (!e_chk_row?.mb_no) {
                            click = "N";
                            bgclr = "#e8e8e8";
                          } else {
                            if (end_date >= now_date) {
                              click = "Y";
                              bgclr = "#FFFFFF";
                            } else {
                              const gab = DateGab(end_date, now_date);
                              if (gab < 14) {
                                click = "Y";
                                bgclr = "#bdfdd9";
                              } else if (gab < 28) {
                                click = "Y";
                                bgclr = "#fed8f8";
                              } else if (gab < 30) {
                                click = "Y";
                                bgclr = "#ff8e8e";
                              } else {
                                click = "Y";
                                bgclr = "#bcb5fb";
                              }
                            }
                          }

                          po2 = `${end_date} ${e_chk_row?.mb_name}`;

                          return (
                            <th
                              key={b}
                              width="50"
                              align="center"
                              style={{cursor: "pointer", backgroundColor: bgclr, maxWidth: 60}}
                              title={click === "Y" ? po2 : po}
                              onClick={click === "Y" ? () => popLoc(`${k}-${a + v.s}-${b + 1}`, e_chk_row.mb_name, e_chk_row.mb_no, gubun, Lnum) : () => selectMember(`${k}-${a + v.s}-${b + 1}`, Lnum)}
                            >
                              <span id={`L${Lnum}`}>{Lnum}</span>
                            </th>
                          );
                        })}
                      </tr>
                    ))}
                  </table>
                  <div style={{paddingBottom: "10px"}}></div>
                </div>
              </div>
            );
          })
        }
      </div>

      {/*팝업 1*/}

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();

          $('.fn_search_box_pop').each(function () {
            $(this).find("input[type=text]").each(function () {
              $(this).onEnter(() => setModal1SrchRenew(Fn.getRenewTime()));
            });
          });

          loadModal1();
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '850px'}}>
            <div className="POP_title">
              <h5>라커관리 > <strong>라커배정</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="d-flex mb-3 fn_search_box_pop">
                <div className="pr-1">
                  <input type="text" className="form-control" placeholder="성명 검색" name="s_mb_name" id="s_mb_name"/>
                </div>
                <div className="pr-1">
                  <input type="text" className="form-control" placeholder="연락처 검색" name="s_mb_hp" id="s_mb_hp"/>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setModal1SrchRenew(Fn.getRenewTime())}>검색</button>
                </div>
              </div>

              <table className="view-rounded sub_table_border text-center">
                <colgroup>
                  <col width="60px"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <thead>
                <tr>
                  <th>No</th>
                  <th>카드번호</th>
                  <th>성명</th>
                  <th>연락처</th>
                  <th>성별</th>
                  <th>연령</th>
                </tr>
                </thead>
                <tbody>
                {modal1Result?.list?.map((ret, i) => {
                  const mbBirth = ret?.mb_birth;
                  const mBirthStr = mbBirth.substring(0, 2);
                  let mRealB;

                  if (parseInt(mBirthStr) < 30) {
                    mRealB = "20" + mBirthStr;
                  } else {
                    mRealB = "19" + mBirthStr;
                  }

                  const birthShow = new Date().getFullYear() - parseInt(mRealB) + 1;

                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td style={{cursor: "pointer"}} onClick={() => copyData(ret?.mb_no, ret?.mb_name)}>{ret?.mb_id}</td>
                      <td style={{cursor: "pointer"}} onClick={() => copyData(ret?.mb_no, ret?.mb_name)}>{ret?.mb_name}</td>
                      <td>{ret?.mb_hp}</td>
                      <td>{ret?.mb_sex === 'M' ? '남' : '여'}</td>
                      <td>{birthShow || ''}</td>
                    </tr>
                  )
                })}
                </tbody>
              </table>
            </div>
            <nav className="p-3 d-flex justify-content-between border-top flex-wrap">
              <Paging pageInfo={modal1PageInfo} setCurrPage={page => setModal1CurrPage(page)}/>
              <div className="d-flex mx-1">
                <ChkAuthNum type={"locker_info_change"} prefix="locker_"/>
              </div>
            </nav>
          </div>
        </div>
      </Modal>

      {/*팝업 2*/}

      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal2();
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '850px'}}>
            <div className="POP_title">
              <h5>라커관리 > <strong>라커배정</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100" style={{overflowX : "auto"}}>
              <table className="view-rounded sub_table_border text-center mb-3">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="45%"/>
                </colgroup>
                <thead>
                <tr>
                  <th>성명</th>
                  <th>라커정보</th>
                  <th>인증코드</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td id="mp_name"></td>
                  <td id="mp_val"></td>
                  <td>
                    <div className="d-flex mx-1">
                      <ChkAuthNum type={"locker_info_change"} prefix="locker_"/>
                      <button type="button" className="btn btn-sm btn-outline-dark mx-1" onClick={() => clear_locker()}>라커비움처리</button>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
              {
                modal2Result?.loc && Object.entries(modal2Result?.loc).map(([k, v], index) => {
                  k = k.replace("_", "");

                  const evenRow = index % 2 === 0;
                  const lastRow = Object.entries(modal2Result?.loc)?.length === index + 1;
                  // if(lastRow && evenRow) $('.wrap-'+index).append(`<div></div>`);

                  if (!evenRow) {
                    setTimeout(() => {
                      if ($('#div_' + index).parents('.wrap-' + index).length === 0)
                        $('#div_' + (index - 1) + ',#div_' + index).wrapAll('<div class="div-locker wrap-' + index + ' d-flex m-4 flex_gap2 row-cols-1" style="flex-direction: column;"></div>');
                    });
                  } else {
                    if (lastRow && Object.entries(modal2Result?.loc)?.length % 2 === 1) {
                      setTimeout(() => {
                        $('#div_' + index).wrapAll('<div class="div-locker wrap-' + index + ' d-flex m-4 flex_gap2 row-cols-1" style="flex-direction: column;"></div>')
                        $('.wrap-' + index).append(`<div></div>`);
                      });
                    }
                  }
                  return (
                    <div id={`div_${index}`} className={`div-locker p-0 text-left table_box ${evenRow ? `div-even mb-3` : `mb-3`}`}>
                      <p className="border-bottom p-2">
                        <span className="h7 bold700 px-2"><strong>{k}</strong> : {v.y} &times; {v.x} ({v.x * v.y}) {v.s}~{v.e}</span>
                      </p>
                      <div className="m-3">
                        <table className="view-rounded locker_view" style={{width: "auto"}}>
                          {Array.from({length: v.y}).map((_, a) => (
                            <tr key={a}>
                              {Array.from({length: v.x}).map((_, b) => {
                                const po = `${k}-${a + v.s}-${b + 1}`;
                                let Lnum = v[`${k}-${a + v.s}-${b + 1}`].Lnum;
                                let end_date, gubun, click, bgclr, po2;

                                const e_chk_row = v?.[po];

                                if (e_chk_row?.lo_posi) {
                                  Lnum = e_chk_row.lo_num;
                                } else {
                                  Lnum = Lnum;
                                }

                                if (e_chk_row?.locker === "Y") {
                                  end_date = e_chk_row.locker_end_date;
                                  gubun = "locker";
                                } else if (e_chk_row?.g_locker === "Y") {
                                  end_date = e_chk_row.g_locker_end_date;
                                  gubun = "g_locker";
                                } else {
                                  end_date = e_chk_row?.locker_end_date;
                                  gubun = "locker";
                                }

                                const now_date = Fn.getDate(new Date());

                                if (!e_chk_row?.mb_no) {
                                  click = "N";
                                  bgclr = "#e8e8e8";
                                } else {
                                  if (end_date >= now_date) {
                                    click = "Y";
                                    bgclr = "#FFFFFF";
                                  } else {
                                    const gab = DateGab(end_date, now_date);
                                    if (gab < 14) {
                                      click = "Y";
                                      bgclr = "#bdfdd9";
                                    } else if (gab < 28) {
                                      click = "Y";
                                      bgclr = "#fed8f8";
                                    } else if (gab < 30) {
                                      click = "Y";
                                      bgclr = "#ff8e8e";
                                    } else {
                                      click = "Y";
                                      bgclr = "#bcb5fb";
                                    }
                                  }
                                }

                                po2 = `${end_date} ${e_chk_row?.mb_name}`;

                                return (
                                  <th
                                    key={b}
                                    width="50"
                                    align="center"
                                    style={{cursor: "pointer", backgroundColor: bgclr, maxWidth: 60}}
                                    title={click === "Y" ? po2 : po}
                                    onClick={() => changeLocker(click, po, gubun, Lnum)}
                                  >
                                    <span id={`L${Lnum}`}>{Lnum}</span>
                                  </th>
                                );
                              })}
                            </tr>
                          ))}
                        </table>
                        <div style={{paddingBottom: "10px"}}></div>
                      </div>
                    </div>
                  );
                })
              }
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="mv_val"/>
      <input type="hidden" id="mv_name"/>
      <input type="hidden" id="mv_mb_no"/>
      <input type="hidden" id="mv_gubun"/>
      <input type="hidden" id="mv_locker_number"/>

    </CmnCard>
  );
};

export default LockerState;
