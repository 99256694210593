import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";

const EmployeeGroupWare = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/employee/emp/groupware/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_permit_YN=${$('#s_permit_YN option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.hp");

          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-1.
  const form1 = document.getElementById('form1');

  function take_permit(takeYN) {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 스텝을 승인처리 하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);
      formData.append("take_YN", takeYN);

      axios2.postFormEx('/employee/emp/groupware', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('처리되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="pr-1">
            <select className="form-select sel_lg" aria-label="Default select example" id="s_branch_code">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" id="s_permit_YN">
              <option value="">::상태::</option>
              <option value="N">신청</option>
              <option value="Y">승인</option>
            </select>
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="이름/핸드폰 검색"
              id="key"
            />
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>

        {/* 테이블 */}
        <div className="m-3 pb-5 table_list">
          <table className="view-rounded sub_table_border table_wide_sh2">
            <colgroup>
              <col width="60px"/>
              <col width="80px"/>
              <col width="*"/>
              <col width="10%"/>
              <col width="*"/>
              <col width="10%"/>
              <col width="*"/>
              <col width="10%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th><input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/></th>
              <th>성명</th>
              <th>지점</th>
              <th>계약명칭</th>
              <th>부서</th>
              <th>계약형태</th>
              <th>연락처</th>
              <th>계약체결일</th>
              <th>근무상태</th>
              <th>그룹웨어ID</th>
              <th>승인여부</th>
              <th>승인일</th>
              <th>승인자</th>
            </tr>

            {rets.map((ret, i) => {
              const count = i + 1;
              const seq = ret?.seq;
              const user_name = ret?.user_name;
              const pay_part = ret?.pay_part;
              const pt_YN = ret?.pt_YN;
              const pu_basic_price = ret?.pu_basic_price;
              const pu_hour_price = ret?.pu_hour_price;
              const pt_basic_price = ret?.pt_basic_price;
              const pt_hour_price = ret?.pt_hour_price;
              const hp = ret?.hp;
              const in_date = ret?.in_date;
              const branch_name = ret?.branch_name;
              const position_name = ret?.position_name;
              const inout_state = ret?.inout_state;
              const groupware_id = ret?.groupware_id;
              const permit_YN = ret?.permit_YN;
              const permit_date = ret?.permit_date;
              const permit_user = ret?.permit_user;
              const part_name = ret?.part_name;
              const new_position_name = ret?.new_position_name;

              let pay_part_txt;
              if (pay_part === "A") {
                pay_part_txt = "본사";
              } else if (pay_part === "B") {
                pay_part_txt = "퍼블릭";
              } else if (pay_part === "C") {
                pay_part_txt = "PT";
              } else if (pay_part === "D") {
                pay_part_txt = "퍼블릭+PT";
              } else if (pay_part === "E") {
                pay_part_txt = "GX";
              } else if (pay_part === "F") {
                pay_part_txt = "용역";
              } else {
                pay_part_txt = "";
              }

              let permit_YN_show;
              if (permit_YN === "N") {
                permit_YN_show = <font color="red"><strong>신청</strong></font>;
              } else {
                permit_YN_show = <font color="blue"><strong>승인</strong></font>;
              }

              let inout_state_txt;
              if (inout_state === "Y") {
                inout_state_txt = "근무";
              } else {
                inout_state_txt = <font color="red">퇴사</font>;
              }

              let groupware_id_show;
              if (permit_YN === "Y") {
                groupware_id_show = groupware_id;
              } else {
                groupware_id_show = <font color="red">{groupware_id}</font>;
              }

              const base_seq = btoa(seq);

              const mb_hp = hp;
              const mb_hp_show = Fn.hpShow(mb_hp, pageInfo.totalArticles);
              const base_hp = btoa(mb_hp_show);
              // tot_base_hp += `${count},${base_hp}|`;

              return (
                <tr key={i}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input
                      type="checkbox"
                      name="chk"
                      value={btoa(ret?.seq)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  </td>
                  <td>{user_name}</td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px'}}>{branch_name}</div>
                  </td>
                  <td>{position_name}</td>
                  <td>{part_name}</td>
                  <td>{new_position_name}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{in_date?.substring(2, 10)?.replaceAll("-", ".")}</td>
                  <td>{inout_state_txt}</td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px'}}>{groupware_id}</div>
                  </td>
                  <td>{permit_YN_show}</td>
                  <td>{permit_date?.substring(2, 10)?.replaceAll("-", ".")}</td>
                  <td>{permit_user}</td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => take_permit("N")}>
              미승인처리
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-point px-2 mx-1"
              onClick={() => take_permit("Y")}
            >
              승인처리
            </button>
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>
            {pageInfo?.totalArticles > 0 && (
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('carereg')}>연락처 확인</button>
              )
            )}
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default EmployeeGroupWare;
