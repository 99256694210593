import React, {forwardRef, useContext, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../sass/main.scss";
import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import _ from "lodash";
import Fn from "../../libraries/Fn";
import $ from "jquery";
import DatePicker from "react-datepicker";
import {ko} from "date-fns/esm/locale";
import "../../libraries/react-datepicker/datepicker.css";
import {parse, parseISO} from "date-fns";
import moment from "moment";
import calendar from "../../images/calendar.png";

const JDatePicker = (props) => {

  const [dt, setDt] = useState(props.value || '');
  const [selectedDate, setSelectedDate] = useState(props.value ? moment(props.value).toDate() : null);

  useEffect(() => {
    setTimeout(() => {
      if (props.value) {
        $('#' + props.id).val(props.value);
        setSelectedDate(moment(props.value).toDate());
      }
    }, 300);
  }, [props.value]);

  useEffect(() => {
    let prevVal = props.value || $('#' + props.id).val();
    const intval = setInterval(function () {
      if (prevVal !== $('#' + props.id).val()) {
        try {
          if ($('#' + props.id).val()?.trim()) {
            setDt($('#' + props.id).val()?.trim() || '');
            setSelectedDate(moment($('#' + props.id).val()?.trim()).toDate());
          } else {
            // TODO : 2023.09.13 추가 : 면밀하게 확인할 것
            if ($('#' + props.id).val() === '') {
              setDt('');
              setSelectedDate(null);
            }
          }
        } catch (err) {
        }
        prevVal = $('#' + props.id).val();
      }
    }, 100);

    return () => {
      clearInterval(intval);
    };
  }, []);

  return (
    <div className="App" style={props?.style || {}}>
      <input type="hidden" id={props.id} name={props?.name}/>
      <DatePicker
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        locale={ko}
        showMonthYearPicker={props.dateFormat === 'yyyy-MM'}
        dateFormat={props.dateFormat || "yyyy-MM-dd"}
        selected={!Number.isNaN(new Date(selectedDate).getTime()) ? selectedDate : null}
        // selected={selectedDate}
        onChange={(date) => {
          if (props.dateFormat === 'yyyy-MM') {
            try {
              const d = moment(date).format("YYYY-MM");
              if (d == "Invalid date")
                $('#' + props.id).val("");
              else if (d == "null" || !d)
                $('#' + props.id).val("");
              else
                $('#' + props.id).val(d);
              setSelectedDate(date);
              props?.onChange($('#' + props.id).val());
            } catch (err) {
            }
            setDt(date);
          } else {
            try {
              const d = moment(date).format("YYYY-MM-DD");
              if (d == "Invalid date")
                $('#' + props.id).val("");
              else if (d == "null" || !d)
                $('#' + props.id).val("");
              else
                $('#' + props.id).val(d);
              setSelectedDate(date);
              props?.onChange($('#' + props.id).val());
            } catch (err) {
            }
            setDt(date);
          }
        }}
        placeholderText={props?.placeholder || "년-월-일"}
        readOnly={props?.readOnly || false}
        onFocus={e => props?.selectOnly ? e.target.blur() : null}
      />
    </div>
  );
};

export default JDatePicker;
