import React, {useContext, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../sass/main.scss";
import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import _ from "lodash";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "./Paging";

const SelectProduct = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [modalOpen, setModalOpen] = useState(props.isOpen);

  useEffect(() => {
    setModalOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    if (!modalOpen) {
      props?.onModalClose();
    }
  }, [modalOpen]);

  const [result, setResult] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/sales/pop/product/reg/ready?search_type=${props.searchType || 'input_reg'}&cnt=${Fn.parseInt($('#cnt').val()) || 1}&max_cnt=${Fn.parseInt($('#max_cnt').val()) || 30}&page=${_page || currPage}&before_company_seq=${props.beforeCompanySeq || ''}&s_category=${$('#s_category option:selected').val() || ''}&s_pum_code=${$('#s_pum_code option:selected').val() || ''}&key=${$('#key').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_category').html() === '') {
            let optionsHTML = '<option value="">브랜드</option>';
            result?.list1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_category').html(optionsHTML);
          }
          if ($('#s_pum_code').html() === '') {
            let optionsHTML = '<option value="">품목구분</option>';
            result?.list2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_pum_code').html(optionsHTML);
          }

          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  function copyData(p_code, p_name, sale_price, sale_price_show, buy_price, buy_price_real, purchase_cnt) {
    if (props.searchType === "input_reg") {

      //기존에 선택되어 있는지 체크
      for (let i = 1; i <= Fn.parseInt($('#max_cnt').val()); i++) {
        if (i == Fn.parseInt($('#cnt').val())) {
        } else {
          if (p_code == $(`#p_code_${i}`).val()) {
            alert('동일한 상품이 선택되어 있습니다.');
            return;
          }
        }
      }

      var cnt = Fn.parseInt($('#cnt').val());
      $(`#p_code_show_${cnt}`).val(p_code);
      $(`#p_code_${cnt}`).val(p_code);
      $(`#unit_price_${cnt}`).val(sale_price);

      $(`#purchase_cnt_${cnt}`).val(purchase_cnt);
      if (purchase_cnt > 1) $(`#qty_${cnt}`).val(purchase_cnt);
      else $(`#qty_${cnt}`).val(1);

      $(`#p_name_${cnt}`).html(p_name);
      $(`#unit_price_show_${cnt}`).html(sale_price_show);
      $(`#price_${cnt}`).html(sale_price_show);

      $(`#qty_${cnt}`).focus();

      // opener.show_tot();
      // window.close();
      props?.callback();

    } else if (props.searchType === "order_reg") {

      //기존에 선택되어 있는지 체크
      for (let i = 1; i <= Fn.parseInt($('#max_cnt').val()); i++) {
        if (i == Fn.parseInt($('#cnt').val())) {
        } else {
          if (p_code == $(`#p_code_${i}`).val()) {
            alert('동일한 상품이 선택되어 있습니다.');
            return;
          }
        }
      }

      var cnt = Fn.parseInt($('#cnt').val());
      $(`#p_code_show_${cnt}`).val(p_code);
      $(`#p_code_${cnt}`).val(p_code);
      $(`#qty_${cnt}`).val("1");
      $(`#unit_price_${cnt}`).val(buy_price_real);

      $(`#p_name${cnt}`).html(p_name);
      $(`#unit_price_show${cnt}`).html(buy_price);
      $(`#price${cnt}`).html(buy_price);

      $(`#qty_${cnt}`).focus();

      // opener.show_tot();
      // window.close();
      props?.callback();

    } else {
      $('#p_code_show').val(p_code);
      $('#p_code').val(p_code);
      $('#p_name').html(p_name);
      $('#won_sale_price').html(sale_price_show);
      $('#buy_price').html(buy_price);

      // window.close();
      props?.callback();
    }
  }

  return (
    <Modal
      isOpen={modalOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setModalOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: '1220px'}}>
          <div className="POP_title">
            <h5><strong>판매상품 선택</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <div className="mb-3 d-flex flex-nowrap">
              <div className="pr-1">
                <select className="form-select" id="s_category">
                </select>
              </div>
              <div className="pr-1">
                <select className="form-select" id="s_pum_code">
                </select>
              </div>
              <div className="pr-1">
                <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="상품명/바코드 검색" id="key"/>
              </div>

              <div className="pr-1">
                <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
              </div>
            </div>

            <div className="pb-2">
              <table className="view-rounded sub_table_border">
                <thead>
                <tr>
                  <th style={{width: '60px'}}>No</th>
                  <th style={{width: '60px'}}></th>
                  <th style={{width: '80px'}}>상품코드</th>
                  <th>상품명</th>
                  <th style={{width: '110px'}}>브랜드</th>
                  <th style={{width: '110px'}}>품목구분</th>
                  <th style={{width: '110px'}}>매입가격</th>
                  <th style={{width: '110px'}}>판매가격</th>
                  <th style={{width: '140px'}}>바코드</th>
                </tr>
                </thead>
                <tbody>
                {result?.result?.map((ret, i) => {

                  const use_YN_show = ret?.use_YN === 'Y' ? '사용' : <span style={{color: 'red'}}>미사용</span>;
                  // TODO
                  // const canSelect = !(props.searchType === 'order_reg' && beforeCompanySeq && beforeCompanySeq !== ret?.company_seq);
                  const canSelect = true;

                  return (
                    <tr key={i}>
                      <td className="text-center">{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                      <td>
                        {canSelect ?
                          <button className="btn btn-ssm btn-dark" onClick={() => copyData(ret?.p_code, ret?.p_name, ret?.sale_price, ret?.sale_price, ret?.buy_price, parseInt(ret?.buy_price), parseInt(ret?.purchase_cnt))}>
                            선택
                          </button>
                          :
                          <button className="btn btn-ssm btn-dark" onClick={() => alert('수정시에는 동일한 공급업체 상품만 선택가능합니다')}>선택</button>
                        }
                      </td>
                      <td>
                        <div align="center">{ret?.p_code}</div>
                      </td>
                      <td>
                        <div align="left" style={{paddingLeft: '5px'}}>{ret?.p_name}</div>
                      </td>
                      <td>
                        <div align="center">{ret?.category_name}</div>
                      </td>
                      <td>
                        <div align="center">{ret?.pum_name}</div>
                      </td>
                      <td>
                        <div align="right" style={{paddingRight: '5px'}}>{Number(ret?.buy_price).toLocaleString()}</div>
                      </td>
                      <td>
                        <div align="right" style={{paddingRight: '5px'}}>{Number(ret?.sale_price).toLocaleString()}</div>
                      </td>
                      <td>
                        <div align="center">{ret?.barcode}</div>
                      </td>
                    </tr>
                  );
                })}
                {!result?.result?.length &&
                  <tr>
                    <td colSpan="9" className="text-center">※ 등록된 data가 없습니다.</td>
                  </tr>
                }
                </tbody>
              </table>
            </div>

          </div>

          <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>

            <div>
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </nav>
        </div>
      </div>
    </Modal>
  );
};

export default SelectProduct;
