import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import Constants from "../../constants/constants";
import {useHistory} from "react-router-dom";

const FinanceService = (props) => {
  const history = useHistory();

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [result, setResult] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    alert('점검 중입니다.');
    history.back();

    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const Excel_download = () => {
    const url = `/xls/finance/service?s_date1=${$('#s_date1').val() || ''}&s_date2=${$('#s_date2').val() || ''}`;
    Fn.download(Constants.API_URL + url);
  };

  const load = (_page) => {
    axios2
      .post(`/finance/service/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //2-1
  const [totRecord, setTotRecord] = useState(3000);
  const [authCode, setAuthCode] = useState('');
  const [downReason, setDownReason] = useState('');

  const maxNum = 30000;

  const chk_total_record = () => {
    if (!authCode) {
      alert('인증코드를 입력해주세요');
      return;
    }

    if (!downReason) {
      alert('사유를 입력해주세요');
      return;
    }

    const url = `Excel_paylist.php?<?=$link_param?>&f_limit=0&l_limit=${totRecord}&max_num=${maxNum}&excel_num=1&auth_code=${authCode}&down_reason=${downReason}`;
    window.location.href = url;
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex justify-content-between flex-wrap m-3 ">
        <div className="d-flex my-1 fn_search_box">
          <div className="d-flex pr-1">
            <JDatePicker className="form-control" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="s_date2"/>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>
      </div>

      <div className="m-3 pb-2">
        <table className="view-rounded sub_table_border auto">
          <tr>
            <th style={{width: '70px'}}>No</th>
            <th>구분</th>
            <th>회원명</th>
            <th>회원번호</th>
            <th>일자</th>
            <th>내역</th>
            <th>금액</th>
            <th>입금형태</th>
            <th>매입사</th>
          </tr>
          {
            result?.list?.map((row, i) => {
              const count = i + 1;

              return (
                <tr key={count}>
                  <td>{pageInfo?.totalArticles - pageInfo?.articlesPerPage * (pageInfo?.currentPage - 1) - i}</td>
                  <td>{row?.dv}</td>
                  <td>{row?.mb_name}</td>
                  <td>{row?.mb_id}</td>
                  <td>{row?.reg_date}</td>
                  <td className="text-left">{row?.etc1} {row?.etc2 ? "(" + row?.etc2 + ")" : ""}</td>
                  <td>{Fn.numberWithCommas(row?.amt)}</td>
                  <td>{row?.ip_method === 'CASH' ? '현금' : row?.ip_method === 'CARD' ? '카드' : row?.ip_method}</td>
                  <td>{row?.ip_cardName}</td>
                </tr>
              );
            })
          }
          {(!result?.list || result?.list?.length === 0) &&
            <tr>
              <td colSpan={9}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Excel_download()}>엑셀저장</button>
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default FinanceService;
