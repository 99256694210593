import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Switch, Route, HashRouter, useHistory} from "react-router-dom";
import AuthContext from "./components/auth-context";
import {GlobalContextProvider} from "./components/global-context";
import {Refreshable} from 'react-router-refreshable'
import ScrollToTop from "./hooks/ScrollToTop";
import $ from 'jquery';

import Login from "./components/login/Login";
import MemberView from "./components/member/MemberView";
import MemberViewDetail from "./components/member/MemberViewDetail";
import MemberList from "./components/member/MemberList";
import MemberHistory from "./components/member/MemberHistory";
import MemberService from "./components/member/MemberService";
import MemberRefundList from "./components/member/MemberRefundList";
import MemberInputList from "./components/member/MemberInputList";
import MemberOnlineList from "./components/member/MemberOnlineList";
import MemberDelayList from "./components/member/MemberDelayList";
import MemberDelayListAll from "./components/member/MemberDelayListAll";
import MemberToreceive from "./components/member/MemberToreceive";
import MemberToreceiveReg from "./components/member/MemberToreceiveReg";
import MemberBranchChange from "./components/member/MemberBranchChange";
import MemberCenterChange from "./components/member/MemberCenterChange";
import MemberStartDateChange from "./components/member/MemberStartDateChange";
import MemberOcareMember from "./components/member/MemberOcareMember";
import MemberOcareReg from "./components/member/MemberOcareReg";
import MemberOcareUseListBranch from "./components/member/MemberOcareUseListBranch";
import MemberOcareUseList from "./components/member/MemberOcareUseList";
import MemberTakeOverMember from "./components/member/MemberTakeOverMember";
import MemberComplainList from "./components/member/MemberComplainList";
import MemberBlackList from "./components/member/MemberBlackList";
import MemberGiftRegi from "./components/member/MemberGiftRegi";
import MemberOcareCoupon from "./components/member/MemberOcareCoupon";
import SalesReg from "./components/sales/SalesReg";
import SalesList from "./components/sales/SalesList";
import SalesOrderList from "./components/sales/SalesOrderList";
import SalesDailyState from "./components/sales/SalesDailyState";
import SalesWeeklyState from "./components/sales/SalesWeeklyState";
import SalesMonthlyState from "./components/sales/SalesMonthlyState";
import SalesBranchState from "./components/sales/SalesBranchState";
import SalesProdState from "./components/sales/SalesProdState";
import SalesBranchPriceState from "./components/sales/SalesBranchPriceState";
import SalesStockState from "./components/sales/SalesStockState";
import SalesStockStateDetail from "./components/sales/SalesStockStateDetail";
import SalesProductPart from "./components/sales/SalesProductPart";
import SalesProductPosition from "./components/sales/SalesProductPosition";
import SalesProductList from "./components/sales/SalesProductList";
import SalesProductDiscount from "./components/sales/SalesProductDiscount";
import SalesInout from "./components/sales/SalesInout";
import SalesControl from "./components/sales/SalesControl";
import SalesControlPart from "./components/sales/SalesControlPart";
import SalesSupplyList from "./components/sales/SalesSupplyList";
import EmployeeList from "./components/employee/EmployeeList";
import EmployeeBranch from "./components/employee/EmployeeBranch";
import EmployeeNewPosition from "./components/employee/EmployeeNewPosition";
import EmployeeConfig from "./components/employee/EmployeeConfig";
import EmployeePositionFee from "./components/employee/EmployeePositionFee";
import EmployeeGroupWare from "./components/employee/EmployeeGroupWare";
import EmployeeBlackList from "./components/employee/EmployeeBlackList";
import EmployeeAceWork from "./components/employee/EmployeeAceWork";
import EmployeeBookList from "./components/employee/EmployeeBookList";
import EmployeePTtrainerList from "./components/employee/EmployeePTtrainerList";
import EmployeeInvList from "./components/employee/EmployeeInvList";
import EmployeeInvGroupNew from "./components/employee/EmployeeInvGroupNew";
import EmployeeInvMailSendListNew from "./components/employee/EmployeeInvMailSendListNew";
import EmployeeConfig2 from "./components/employee/EmployeeConfig2";
import EmployeeConfig3 from "./components/employee/EmployeeConfig3";
import ConfigIp from "./components/config/ConfigIp";
import ConfigSetSound from "./components/config/ConfigSetSound";
import ConfigAuthList from "./components/config/ConfigAuthList";
import ConfigJoinRoute from "./components/config/ConfigJoinRoute";
import ConfigOption from "./components/config/ConfigOption";
import ConfigWeeklySet from "./components/config/ConfigWeeklySet";
import ConfigUserList from "./components/config/ConfigUserList";
import ConfigResearchList from "./components/config/ConfigResearchList";
import ConfigSubPriceSetting from "./components/config/ConfigSubPriceSetting";
import ConfigBranchList from "./components/config/ConfigBranchList";
import ConfigItemList from "./components/config/ConfigItemList";
import ConfigBrandList from "./components/config/ConfigBrandList";
import ConfigGiftListB2B from "./components/config/ConfigGiftListB2B";
import ConfigGiftList from "./components/config/ConfigGiftList";
import ConfigCouponList from "./components/config/ConfigCouponList";
import ConfigApplicant from "./components/config/ConfigApplicant";
import ConfigSmileSetting from "./components/config/ConfigSmileSetting";
import ConfigBanner from "./components/config/ConfigBanner";
import LockerState from "./components/locker/LockerState";
import LockerList from "./components/locker/LockerList";
import LockerEmptyReserve from "./components/locker/LockerEmptyReserve";
import LockerReserve from "./components/locker/LockerReserve";
import LockerHistory from "./components/locker/LockerHistory";
import LockerNumber from "./components/locker/LockerNumber";
import LockerConfig from "./components/locker/LockerConfig";
import BranchPtSchedule from "./components/branchpt/BranchPtSchedule";
import BranchPtTrainer from "./components/branchpt/BranchPtOtTrainer";
import BranchPtPTReserveMonth from "./components/branchpt/BranchPtPTReserveMonth";
import BranchPtPTList from "./components/branchpt/BranchPtPTList";
import BranchPtPTTakeList from "./components/branchpt/BranchPtPTTakeList";
import BranchPtOTList from "./components/branchpt/BranchPtOTList";
import BranchPtFreeOTList from "./components/branchpt/BranchPtFreeOTList";
import BranchPtOTTakeList from "./components/branchpt/BranchPtOTTakeList";
import BranchPtPTTakeMonth from "./components/branchpt/BranchPtPTTakeMonth";
import BranchPtOTTakeMonth from "./components/branchpt/BranchPtOTTakeMonth";
import BranchPtPTRoute from "./components/branchpt/BranchPtPTRoute";
import BranchPtOpen from "./components/branchpt/BranchPtOpen";
import BranchopNoticeList from "./components/branchop/BranchopNoticeList";
import BranchopSalesTarget from "./components/branchop/BranchopSalesTarget";
import BranchopDailyWorkList from "./components/branchop/BranchopDailyWorkList";
import BranchopPermitList from "./components/branchop/BranchopPermitList";
import BranchopGxBasic from "./components/branchop/BranchopGxBasic";
import BranchopGxReserve from "./components/branchop/BranchopGxReserve";
import BranchopGxMember from "./components/branchop/BranchopGxMember";
import BranchopInoutCal from "./components/branchop/BranchopInoutCal";
import BranchopInoutCalNew from "./components/branchop/BranchopInoutCalNew";
import BranchopBoardList from "./components/branchop/BranchopBoardList";
import BranchopStrangeList from "./components/branchop/BranchopStrangeList";
import BranchopAppInstallList from "./components/branchop/BranchopAppInstallList";
import BranchopStepAppInstallList from "./components/branchop/BranchopStepAppInstallList";
import BranchopStepAttend from "./components/branchop/BranchopStepAttend";
import BranchopNotOperList from "./components/branchop/BranchopNotOperList";
import FinanceAccount from "./components/finance/FinanceAccount";
import FinanceReport from "./components/finance/FinanceReport";
import FinanceMonthly from "./components/finance/FinanceMonthly";
import FinancePrepaid from "./components/finance/FinancePrepaid";
import FinanceBankBook from "./components/finance/FinanceBankBook";
import FinanceCash from "./components/finance/FinanceCash";
import FinanceCard from "./components/finance/FinanceCard";
import FinanceCashNew from "./components/finance/FinanceCashNew";
import FinanceMisu from "./components/finance/FinanceMisu";
import FinanceAccountManage from "./components/finance/FinanceAccountManage";
import FinanceSpending from "./components/finance/FinanceSpending";
import FinanceFeeList from "./components/finance/FinanceFeeList";
import FinanceFranInfo from "./components/finance/FinanceFranInfo";
import FinanceAppWebPay from "./components/finance/FinanceAppWebPay";
import FinanceAppWebPayNice from "./components/finance/FinanceAppWebPayNice";
import FinanceFeePublic from "./components/finance/FinanceFeePublic";
import FinanceFeePt from "./components/finance/FinanceFeePt";
import FinanceFeeGx from "./components/finance/FinanceFeeGx";
import FinanceFeeConsult from "./components/finance/FinanceFeeConsult";
import FinanceFeeStep from "./components/finance/FinanceFeeStep";
import FinanceFeeOverTimeList from "./components/finance/FinanceFeeOverTimeList";
import FinanceFeeEtc from "./components/finance/FinanceFeeEtc";
import FinanceFeeIncenPt from "./components/finance/FinanceFeeIncenPt";
import AnalysisStateNewOld from "./components/analysis/AnalysisStateNewOld";
import AnalysisWeeklyReport from "./components/analysis/AnalysisWeeklyReport";
import AnalysisMonthlyMember from "./components/analysis/AnalysisMonthlyMember";
import AnalysisLongPeriod from "./components/analysis/AnalysisLongPeriod";
import AnalysisBranchRanking from "./components/analysis/AnalysisBranchRanking";
import AnalysisBranchRankingReal from "./components/analysis/AnalysisBranchRankingReal";
import AnalysisBranchRankingPt from "./components/analysis/AnalysisBranchRankingPt";
import AnalysisUseState from "./components/analysis/AnalysisUseState";
import AnalysisKpi from "./components/analysis/AnalysisKpi";
import AnalysisManagerReport from "./components/analysis/AnalysisManagerReport";
import AnalysisTrainerReport from "./components/analysis/AnalysisTrainerReport";
import AnalysisBranchRealPrice from "./components/analysis/AnalysisBranchRealPrice";
import AnalysisFinanceMonthlyReport from "./components/analysis/AnalysisFinanceMonthlyReport";
import AnalysisBranchPrice from "./components/analysis/AnalysisBranchPrice";
import AnalysisBranchPricePt from "./components/analysis/AnalysisBranchPricePt";
import AnalysisSatisSummary from "./components/analysis/AnalysisSatisSummary";
import AnalysisPointHistory from "./components/analysis/AnalysisPointHistory";
import AnalysisStatePeriod from "./components/analysis/AnalysisStatePeriod";
import AnalysisStateVisit from "./components/analysis/AnalysisStateVisit";
import AnalysisStateWeek from "./components/analysis/AnalysisStateWeek";
import AnalysisStateMonth from "./components/analysis/AnalysisStateMonth";
import AnalysisMonthlyFinanceNew from "./components/analysis/AnalysisMonthlyFinanceNew";
import AnalysisMonthlyCoupon from "./components/analysis/AnalysisMonthlyCoupon";
import AnalysisMonthlyTm from "./components/analysis/AnalysisMonthlyTm";
import AnalysisMonthlyRegion from "./components/analysis/AnalysisMonthlyRegion";
import AnalysisMonthlyPublic from "./components/analysis/AnalysisMonthlyPublic";
import AnalysisMonthlyPt from "./components/analysis/AnalysisMonthlyPt";
import AnalysisMonthlyAnalysisPt from "./components/analysis/AnalysisMonthlyAnalysisPt";
import AnalysisSatisList from "./components/analysis/AnalysisSatisList";
import AnalysisPointHistoryBranch from "./components/analysis/AnalysisPointHistoryBranch";
import MessageSmsSendList from "./components/message/MessageSmsSendList";
import MessageSmsSendState from "./components/message/MessageSmsSendState";
import MessageSmsReserveList from "./components/message/MessageSmsReserveList";
import MessageTmReg from "./components/message/MessageTmReg";
import MessageSmsList from "./components/message/MessageSmsList";
import MessagePushList from "./components/message/MessagePushList";
import MessagePushSendList from "./components/message/MessagePushSendList";
import SecureUserListHistory from "./components/secure/SecureUserListHistory";
import SecureMenuAuthHistory from "./components/secure/SecureMenuAuthHistory";
import SecureExcelDownHistory from "./components/secure/SecureExcelDownHistory";
import SecureMemberShowHistory from "./components/secure/SecureMemberShowHistory";
import EmployeeBookEndList from "./components/employee/EmployeeBookEndList";
import EmployeeBookMeetList from "./components/employee/EmployeeBookMeetList";
import EmployeeAceReport from "./components/employee/EmployeeAceReport";
import FinanceFeeAdd from "./components/finance/FinanceFeeAdd";
import FinanceFeeIncen from "./components/finance/FinanceFeeIncen";
import FinanceFeeBranchOverPrice from "./components/finance/FinanceFeeBranchOverPrice";
import BranchopNoticeView from "./components/branchop/BranchopNoticeView";
import BranchopNoticeWrite from "./components/branchop/BranchopNoticeWrite";
import BranchopBoardWrite from "./components/branchop/BranchopBoardWrite";
import BranchopBoardView from "./components/branchop/BranchopBoardView";
import ConfigBrandReg from "./components/config/ConfigBrandReg";
import ConfigUserReg from "./components/config/ConfigUserReg";
import ConfigAuthReg from "./components/config/ConfigAuthReg";
import SalesControlReg from "./components/sales/SalesControlReg";
import SalesInoutReg from "./components/sales/SalesInoutReg";
import SalesProductReg from "./components/sales/SalesProductReg";
import BranchopGxBasicReg from "./components/branchop/BranchopGxBasicReg";
import BranchopStrangeWrite from "./components/branchop/BranchopStrangeWrite";
import SalesOrderReg from "./components/sales/SalesOrderReg";
import ConfigResearchWrite from "./components/config/ConfigResearchWrite";
import ConfigResearchView from "./components/config/ConfigResearchView";
import ConfigBranchReg from "./components/config/ConfigBranchReg";
import ConfigItemReg from "./components/config/ConfigItemReg";
import SecureMenuAuthDetail from "./components/secure/SecureMenuAuthDetail";
import SecureUserListDetail from "./components/secure/SecureUserListDetail";
import EmployeeReg from "./components/employee/EmployeeReg";
import EmployeeRegPay from "./components/employee/EmployeeRegPay";
import EmployeeInvReg from "./components/employee/EmployeeInvReg";
import EmployeeBookReg from "./components/employee/EmployeeBookReg";
import SalesSupplyReg from "./components/sales/SalesSupplyReg";
import ConfigResearchCopy from "./components/config/ConfigResearchCopy";
import MemberInputStat from "./components/member/MemberInputStat";
import EmployeePTtrainerReg from "./components/employee/EmployeePTtrainerReg";
import Intro from "./components/enter/Intro";
import Enter from "./components/enter/Enter";
import Main from "./components/enter/Main";
import Fn from "./libraries/Fn";
import Summary from "./components/main/Summary";
import {EntranceWin} from "./components/EntranceWin";
import NotFound from "./components/NotFound";
import FinanceService from "./components/finance/FinanceService";
import CleanManual from "./components/manual/CleanManual";
import StepManual from "./components/manual/StepManual";
import SmmsManual from "./components/manual/SmmsManual";
import InfoPlan from "./components/manual/InfoPlan";
import InfoManual from "./components/manual/InfoManual";
import MemberCuUseList from "./components/member/MemberCuUseList";
import MemberCuReg from "./components/member/MemberCuReg";

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (window.location.pathname != '/login' && Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 0) {
      logoutHandler();
    }
  }, [window.location.pathname]);

  // 로그인 핸들러
  const loginHandler = (result) => {
    localStorage.setItem("USER.systemID", result?.systemID);
    localStorage.setItem("USER.systemName", result?.systemName);
    localStorage.setItem("USER.systemBranchName", result?.systemBranchName);
    localStorage.setItem("USER.systemManageYN", result?.systemManageYN);
    localStorage.setItem("USER.systemUserAuth", result?.systemUserAuth);
    localStorage.setItem("USER.systemAccountYN", result?.systemAccountYN);
    localStorage.setItem("USER.systemFranYN", result?.systemFranYN);
    localStorage.setItem("USER.systemBranchCode", result?.systemBranchCode);
    localStorage.setItem("USER.systemBrand", result?.systemBrand);
    localStorage.setItem("USER.token", result?.token);
    setIsLoggedIn(true);
  };

  // 로그아웃 핸들러
  const logoutHandler = () => {
    localStorage.removeItem("USER.systemID");
    localStorage.removeItem("USER.systemName");
    localStorage.removeItem("USER.systemBranchName");
    localStorage.removeItem("USER.systemManageYN");
    localStorage.removeItem("USER.systemUserAuth");
    localStorage.removeItem("USER.systemAccountYN");
    localStorage.removeItem("USER.systemFranYN");
    localStorage.removeItem("USER.systemBranchCode");
    localStorage.removeItem("USER.systemBrand");
    localStorage.removeItem("USER.token");
    localStorage.removeItem("login_ck_date")
    setIsLoggedIn(false);
  };

  const [isEntWinOpen, setEntWinOpen] = useState(false);

  const openEntWinHandler = () => {
    setEntWinOpen(true);
  }

  const closeEntWinHandler = () => {
    setEntWinOpen(false);
  }

  return (
    <Router>
      <ScrollToTop/>
      <GlobalContextProvider>
        <AuthContext.Provider
          value={{
            isLoggedIn,
            isEntWinOpen,
            openEntWinHandler: openEntWinHandler,
            closeEntWinHandler: closeEntWinHandler
          }}
        >
          <Refreshable>
            <Switch>
              {/* 로그인 페이지 */}
              <Route
                exact
                path={["/login", "/"]}
                render={() => <Login onLogin={loginHandler}/>}
              />
              {/* 메인 페이지 */}
              <Route
                exact
                path={["/main/summary"]}
                render={() => <Summary onLogout={logoutHandler}/>}
              />
              {/* 회원관리 페이지 */}
              <Route
                exact
                path="/member/view"
                render={() => <MemberView onLogout={logoutHandler}/>}
              />
              {/* 회원관리 > 회원정보 상세 페이지 */}
              <Route
                exact
                path="/member/view/detail"
                render={() => <MemberViewDetail onLogout={logoutHandler}/>}
              />
              {/* 회원검색 페이지 */}
              <Route
                exact
                path="/member/list"
                render={() => <MemberList onLogout={logoutHandler}/>}
              />
              {/* 히스토리 페이지 */}
              <Route
                exact
                path="/member/history"
                render={() => <MemberHistory onLogout={logoutHandler}/>}
              />
              {/* 무상서비스내역 페이지 */}
              <Route
                exact
                path="/member/service"
                render={() => <MemberService onLogout={logoutHandler}/>}
              />
              {/* 환불내역 페이지 */}
              <Route
                exact
                path="/member/refund/list"
                render={() => <MemberRefundList onLogout={logoutHandler}/>}
              />
              {/* 입장내역 페이지 */}
              <Route
                exact
                path="/member/input/list"
                render={() => <MemberInputList onLogout={logoutHandler}/>}
              />
              {/* 입장내역 통계 페이지 */}
              <Route
                exact
                path="/member/input/stat"
                render={() => <MemberInputStat onLogout={logoutHandler}/>}
              />
              {/* 온라인 회원 페이지 */}
              <Route
                exact
                path="/member/online/list"
                render={() => <MemberOnlineList onLogout={logoutHandler}/>}
              />
              {/* 앱/웹 연기 신청 페이지 */}
              <Route
                exact
                path="/member/delay/list"
                render={() => <MemberDelayList onLogout={logoutHandler}/>}
              />
              {/* 연기 신청 내역 페이지 */}
              <Route
                exact
                path="/member/delay/list/all"
                render={() => <MemberDelayListAll onLogout={logoutHandler}/>}
              />
              {/* 양도 양수 승인 페이지 */}
              <Route
                exact
                path="/member/toreceive"
                render={() => <MemberToreceive onLogout={logoutHandler}/>}
              />
              {/* 양도 양수 승인 > 양도 양수 등록 페이지 */}
              <Route
                exact
                path="/member/toreceive/reg"
                render={() => <MemberToreceiveReg onLogout={logoutHandler}/>}
              />
              {/* 주센터변경 승인 페이지 */}
              <Route
                exact
                path="/member/branch/change"
                render={() => <MemberBranchChange onLogout={logoutHandler}/>}
              />
              {/* 주센터변경 페이지 */}
              <Route
                exact
                path="/member/center/change"
                render={() => <MemberCenterChange onLogout={logoutHandler}/>}
              />
              {/* 시작일 변경 승인 페이지 */}
              <Route
                exact
                path="/member/start/date/change"
                render={() => <MemberStartDateChange onLogout={logoutHandler}/>}
              />
              {/* 이용권 등록 */}
              <Route
                exact
                path="/member/gift/regi"
                render={() => <MemberGiftRegi onLogout={logoutHandler}/>}
              />
              {/* 관심 회원 */}
              <Route
                exact
                path="/member/blacklist"
                render={() => <MemberBlackList onLogout={logoutHandler}/>}
              />
              {/* 컴플레인 관리 */}
              <Route
                exact
                path="/member/complain/list"
                render={() => <MemberComplainList onLogout={logoutHandler}/>}
              />
              {/* 인수센터 회원등록 */}
              <Route
                exact
                path="/member/take/over/member"
                render={() => <MemberTakeOverMember onLogout={logoutHandler}/>}
              />
              {/* 오케어 쿠폰 발행내역 */}
              <Route
                exact
                path="/member/ocare/coupon"
                render={() => <MemberOcareCoupon onLogout={logoutHandler}/>}
              />
              {/* 오케어 쿠폰 사용내역 */}
              <Route
                exact
                path="/member/ocare/use/list"
                render={() => <MemberOcareUseList onLogout={logoutHandler}/>}
              />
              {/* 오케어 쿠폰 사용내역(현장) */}
              <Route
                exact
                path="/member/ocare/use/list/branch"
                render={() => <MemberOcareUseListBranch onLogout={logoutHandler}/>}
              />
              {/* 오케어 쿠폰 등록 */}
              <Route
                exact
                path="/member/ocare/reg"
                render={() => <MemberOcareReg onLogout={logoutHandler}/>}
              />
              {/* 오케어 회원 페이지 */}
              <Route
                exact
                path="/member/ocare/member"
                render={() => <MemberOcareMember onLogout={logoutHandler}/>}
              />
              {/* CU 쿠폰 사용내역 */}
              <Route
                exact
                path="/member/cu/use/list"
                render={() => <MemberCuUseList onLogout={logoutHandler}/>}
              />
              {/* CU 쿠폰 등록 */}
              <Route
                exact
                path="/member/cu/reg"
                render={() => <MemberCuReg onLogout={logoutHandler}/>}
              />

              {/* ---------- SALES ---------- */}

              {/* 판매등록 - 바코드 */}
              <Route
                exact
                path="/sales/reg"
                render={() => <SalesReg onLogout={logoutHandler}/>}
              />
              {/* 판매등록 - 전품목 */}
              {/*<Route*/}
              {/*  exact*/}
              {/*  path="/sales/reg2"*/}
              {/*  render={() => <SalesReg2 onLogout={logoutHandler}/>}*/}
              {/*/>*/}
              {/* 판매내역 */}
              <Route
                exact
                path="/sales/list"
                render={() => <SalesList onLogout={logoutHandler}/>}
              />
              {/* 발주내역 */}
              <Route
                exact
                path="/sales/order/list"
                render={() => <SalesOrderList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/sales/order/reg"
                render={() => <SalesOrderReg onLogout={logoutHandler}/>}
              />
              {/* 기간별판매현황(일별) */}
              <Route
                exact
                path="/sales/daily/state"
                render={() => <SalesDailyState onLogout={logoutHandler}/>}
              />
              {/* 기간별판매현황(주별) */}
              <Route
                exact
                path="/sales/weekly/state"
                render={() => <SalesWeeklyState onLogout={logoutHandler}/>}
              />
              {/* 기간별판매현황(월별) */}
              <Route
                exact
                path="/sales/monthly/state"
                render={() => <SalesMonthlyState onLogout={logoutHandler}/>}
              />
              {/* 기간별지점매출-종합 */}
              <Route
                exact
                path="/sales/branch/state"
                render={() => <SalesBranchState onLogout={logoutHandler}/>}
              />
              {/* 상품별판매현황 */}
              <Route
                exact
                path="/sales/prod/state"
                render={() => <SalesProdState onLogout={logoutHandler}/>}
              />
              {/* 지점랭킹-매출 */}
              <Route
                exact
                path="/sales/branch/price/state"
                render={() => <SalesBranchPriceState onLogout={logoutHandler}/>}
              />
              {/* 재고현황 */}
              <Route
                exact
                path="/sales/stock/state"
                render={() => <SalesStockState onLogout={logoutHandler}/>}
              />
              {/* 재고현황(상세) */}
              <Route
                exact
                path="/sales/stock/state/detail"
                render={() => <SalesStockStateDetail onLogout={logoutHandler}/>}
              />
              {/* 품목구분관리 */}
              <Route
                exact
                path="/sales/product/part"
                render={() => <SalesProductPart onLogout={logoutHandler}/>}
              />
              {/* 품목분류관리 */}
              <Route
                exact
                path="/sales/product/position"
                render={() => <SalesProductPosition onLogout={logoutHandler}/>}
              />
              {/* 판매상품관리 */}
              <Route
                exact
                path="/sales/product/list"
                render={() => <SalesProductList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/sales/product/reg"
                render={() => <SalesProductReg onLogout={logoutHandler}/>}
              />
              {/* 할인상품관리 */}
              <Route
                exact
                path="/sales/product/discount"
                render={() => <SalesProductDiscount onLogout={logoutHandler}/>}
              />
              {/* 입고관리 */}
              <Route
                exact
                path="/sales/inout"
                render={() => <SalesInout onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/sales/inout/reg"
                render={() => <SalesInoutReg onLogout={logoutHandler}/>}
              />
              {/* 재고조정 */}
              <Route
                exact
                path="/sales/control"
                render={() => <SalesControl onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/sales/control/reg"
                render={() => <SalesControlReg onLogout={logoutHandler}/>}
              />
              {/* 조정구분관리 */}
              <Route
                exact
                path="/sales/control/part"
                render={() => <SalesControlPart onLogout={logoutHandler}/>}
              />
              {/* 공급업체관리 */}
              <Route
                exact
                path="/sales/supply/list"
                render={() => <SalesSupplyList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/sales/supply/reg"
                render={() => <SalesSupplyReg onLogout={logoutHandler}/>}
              />

              {/* ---------- LOCKER ---------- */}

              {/* 라커현황 */}
              <Route
                exact
                path="/locker/state"
                render={() => <LockerState onLogout={logoutHandler}/>}
              />
              {/* 라커목록 */}
              <Route
                exact
                path="/locker/list"
                render={() => <LockerList onLogout={logoutHandler}/>}
              />
              {/* 비움라커목록 */}
              <Route
                exact
                path="/locker/empty/reserve"
                render={() => <LockerEmptyReserve onLogout={logoutHandler}/>}
              />
              {/* 라커예약내역 */}
              <Route
                exact
                path="/locker/reserve"
                render={() => <LockerReserve onLogout={logoutHandler}/>}
              />
              {/* 라커히스토리 */}
              <Route
                exact
                path="/locker/history"
                render={() => <LockerHistory onLogout={logoutHandler}/>}
              />
              {/* 라커번호 */}
              <Route
                exact
                path="/locker/number"
                render={() => <LockerNumber onLogout={logoutHandler}/>}
              />
              {/* 라커설정 */}
              <Route
                exact
                path="/locker/config"
                render={() => <LockerConfig onLogout={logoutHandler}/>}
              />

              {/* ---------- PT관리 ---------- */}

              {/* PT/OT스케줄 */}
              <Route
                exact
                path="/branchpt/schedule"
                render={() => <BranchPtSchedule onLogout={logoutHandler}/>}
              />
              {/* PT/OT스케줄-트레이너 */}
              <Route
                exact
                path="/branchpt/trainer"
                render={() => <BranchPtTrainer onLogout={logoutHandler}/>}
              />
              {/* PT/OT스케줄 오픈 */}
              <Route
                exact
                path="/branchpt/open"
                render={() => <BranchPtOpen onLogout={logoutHandler}/>}
              />
              {/* 월별진행일정 */}
              <Route
                exact
                path="/branchpt/pt/reserve/month"
                render={() => <BranchPtPTReserveMonth onLogout={logoutHandler}/>}
              />
              {/* PT대상목록 */}
              <Route
                exact
                path="/branchpt/pt/list"
                render={() => <BranchPtPTList onLogout={logoutHandler}/>}
              />
              {/* PT진행내역 */}
              <Route
                exact
                path="/branchpt/pt/take/list"
                render={() => <BranchPtPTTakeList onLogout={logoutHandler}/>}
              />
              {/* OT대상목록 */}
              <Route
                exact
                path="/branchpt/ot/list"
                render={() => <BranchPtOTList onLogout={logoutHandler}/>}
              />
              {/* 무료OT신청 */}
              <Route
                exact
                path="/branchpt/free/ot/list"
                render={() => <BranchPtFreeOTList onLogout={logoutHandler}/>}
              />
              {/* OT진행내역 */}
              <Route
                exact
                path="/branchpt/ot/take/list"
                render={() => <BranchPtOTTakeList onLogout={logoutHandler}/>}
              />
              {/* 트레이너 기간별 PT진행 */}
              <Route
                exact
                path="/branchpt/pt/take/month"
                render={() => <BranchPtPTTakeMonth onLogout={logoutHandler}/>}
              />
              {/* 트레이너 기간별 OT진행 */}
              <Route
                exact
                path="/branchpt/ot/take/month"
                render={() => <BranchPtOTTakeMonth onLogout={logoutHandler}/>}
              />
              {/* PT가입경로 설정 */}
              <Route
                exact
                path="/branchpt/pt/route"
                render={() => <BranchPtPTRoute onLogout={logoutHandler}/>}
              />

              {/* ---------- BRANCHOP ---------- */}

              {/* 공지사항 */}
              <Route
                exact
                path="/branchop/notice/list"
                render={() => <BranchopNoticeList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/branchop/notice/view"
                render={() => <BranchopNoticeView onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/branchop/notice/write"
                render={() => <BranchopNoticeWrite onLogout={logoutHandler}/>}
              />
              {/* 매출목표 설정 */}
              <Route
                exact
                path="/branchop/sales/target"
                render={() => <BranchopSalesTarget onLogout={logoutHandler}/>}
              />
              {/* 일일업무현황 */}
              <Route
                exact
                path="/branchop/daily/work/list"
                render={() => <BranchopDailyWorkList onLogout={logoutHandler}/>}
              />
              {/* 승인처리 */}
              <Route
                exact
                path="/branchop/permit/list"
                render={() => <BranchopPermitList onLogout={logoutHandler}/>}
              />
              {/* GX 기본정보 */}
              <Route
                exact
                path="/branchop/gx/basic"
                render={() => <BranchopGxBasic onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/branchop/gx/basic/reg"
                render={() => <BranchopGxBasicReg onLogout={logoutHandler}/>}
              />
              {/* GX 예약 */}
              <Route
                exact
                path="/branchop/gx/reserve"
                render={() => <BranchopGxReserve onLogout={logoutHandler}/>}
              />
              {/* GX 출석 */}
              <Route
                exact
                path="/branchop/gx/member"
                render={() => <BranchopGxMember onLogout={logoutHandler}/>}
              />
              {/* 타지점정산내역 */}
              <Route
                exact
                path="/branchop/inout/cal"
                render={() => <BranchopInoutCal onLogout={logoutHandler}/>}
              />
              {/* 타지점정산내역(new) */}
              <Route
                exact
                path="/branchop/inout/cal/new"
                render={() => <BranchopInoutCalNew onLogout={logoutHandler}/>}
              />
              {/* 문의게시판 */}
              <Route
                exact
                path="/branchop/board/list"
                render={() => <BranchopBoardList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/branchop/board/write"
                render={() => <BranchopBoardWrite onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/branchop/board/view"
                render={() => <BranchopBoardView onLogout={logoutHandler}/>}
              />
              {/* 이상이용자 신고 */}
              <Route
                exact
                path="/branchop/strange/list"
                render={() => <BranchopStrangeList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/branchop/strange/write"
                render={() => <BranchopStrangeWrite onLogout={logoutHandler}/>}
              />
              {/* 앱설치내역 */}
              <Route
                exact
                path="/branchop/app/install/list"
                render={() => <BranchopAppInstallList onLogout={logoutHandler}/>}
              />
              {/* 앱설치내역(스텝) */}
              <Route
                exact
                path="/branchop/step/app/install/list"
                render={() => <BranchopStepAppInstallList onLogout={logoutHandler}/>}
              />
              {/* 스텝입장내역 */}
              <Route
                exact
                path="/branchop/step/attend"
                render={() => <BranchopStepAttend onLogout={logoutHandler}/>}
              />
              {/* 비정상운영내역 */}
              <Route
                exact
                path="/branchop/not/oper/list"
                render={() => <BranchopNotOperList onLogout={logoutHandler}/>}
              />

              {/* ---------- FINANCE ---------- */}

              {/* 계좌 관리 */}
              <Route
                exact
                path="/finance/account/manage"
                render={() => <FinanceAccountManage onLogout={logoutHandler}/>}
              />
              {/* 간편장부 */}
              <Route
                exact
                path="/finance/account"
                render={() => <FinanceAccount onLogout={logoutHandler}/>}
              />
              {/* 자금일보 */}
              <Route
                exact
                path="/finance/report"
                component={FinanceReport}
              />
              {/* 자금일보 */}
              <Route
                exact
                path="/finance/report/:rn/:ts"
                component={FinanceReport}
              />
              {/* 월계표 */}
              <Route
                exact
                path="/finance/monthly"
                component={FinanceMonthly}
              />
              {/* 월계표 */}
              <Route
                exact
                path="/finance/monthly/:rn/:ts"
                component={FinanceMonthly}
              />
              {/* 서비스/입금 내역 */}
              <Route
                exact
                path="/finance/service"
                render={() => <FinanceService onLogout={logoutHandler}/>}
              />
              {/* 전도금 관리 */}
              <Route
                exact
                path="/finance/prepaid"
                render={() => <FinancePrepaid onLogout={logoutHandler}/>}
              />
              {/* 통장 입금 */}
              <Route
                exact
                path="/finance/bankbook"
                render={() => <FinanceBankBook onLogout={logoutHandler}/>}
              />
              {/* 비용 지출 */}
              <Route
                exact
                path="/finance/cash"
                render={() => <FinanceCash onLogout={logoutHandler}/>}
              />
              {/* 카드매출 */}
              <Route
                exact
                path="/finance/card"
                render={() => <FinanceCard onLogout={logoutHandler}/>}
              />
              {/* 현금매출 */}
              <Route
                exact
                path="/finance/cash/new"
                render={() => <FinanceCashNew onLogout={logoutHandler}/>}
              />
              {/* 미납금관리 */}
              <Route
                exact
                path="/finance/misu"
                render={() => <FinanceMisu onLogout={logoutHandler}/>}
              />
              {/* 지출내역 */}
              <Route
                exact
                path="/finance/spending"
                render={() => <FinanceSpending onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > 수수료 내역보고 */}
              <Route
                exact
                path="/finance/fee/list/:renew"
                render={() => <FinanceFeeList onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > 퍼블릭 부서 */}
              <Route
                exact
                path="/finance/fee/public"
                render={() => <FinanceFeePublic onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > PT 부서 */}
              <Route
                exact
                path="/finance/fee/pt"
                render={() => <FinanceFeePt onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > 추가/기타 */}
              <Route
                exact
                path="/finance/fee/add"
                render={() => <FinanceFeeAdd onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > 인센티브 */}
              <Route
                exact
                path="/finance/fee/incen"
                render={() => <FinanceFeeIncen onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > PT 수당 */}
              <Route
                exact
                path="/finance/fee/incen/pt"
                render={() => <FinanceFeeIncenPt onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > GX 수당 */}
              <Route
                exact
                path="/finance/fee/gx"
                render={() => <FinanceFeeGx onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > 영업컨설팅비 */}
              <Route
                exact
                path="/finance/fee/consult"
                render={() => <FinanceFeeConsult onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > 직원별 수수료 */}
              <Route
                exact
                path="/finance/fee/step"
                render={() => <FinanceFeeStep onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > 추가근무관리 */}
              <Route
                exact
                path="/finance/fee/overtime/list"
                render={() => <FinanceFeeOverTimeList onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > 기타수당 */}
              <Route
                exact
                path="/finance/fee/etc"
                render={() => <FinanceFeeEtc onLogout={logoutHandler}/>}
              />
              {/* 수수료관리 > 초과수당설정 */}
              <Route
                exact
                path="/finance/fee/branch/overprice"
                render={() => <FinanceFeeBranchOverPrice onLogout={logoutHandler}/>}
              />
              {/* 가맹점 수수료 내역 */}
              <Route
                exact
                path="/finance/fran/info"
                render={() => <FinanceFranInfo onLogout={logoutHandler}/>}
              />
              {/* 앱/웹결제내역 */}
              <Route
                exact
                path="/finance/app/web/pay"
                render={() => <FinanceAppWebPay onLogout={logoutHandler}/>}
              />
              {/* 앱/웹결제내역(nice) */}
              <Route
                exact
                path="/finance/app/web/pay/nice"
                render={() => <FinanceAppWebPayNice onLogout={logoutHandler}/>}
              />

              {/* ---------- ANALYSIS ---------- */}

              {/* 통계분석 > 구분별 */}
              <Route
                exact
                path="/analysis/state/new/old"
                render={() => <AnalysisStateNewOld onLogout={logoutHandler}/>}
              />
              {/* 통계분석 > 기간별 */}
              <Route
                exact
                path="/analysis/state/period"
                render={() => <AnalysisStatePeriod onLogout={logoutHandler}/>}
              />
              {/* 통계분석 > 경로별 */}
              <Route
                exact
                path="/analysis/state/visit"
                render={() => <AnalysisStateVisit onLogout={logoutHandler}/>}
              />
              {/* 통계분석 > 주별회원현황 */}
              <Route
                exact
                path="/analysis/state/week"
                render={() => <AnalysisStateWeek onLogout={logoutHandler}/>}
              />
              {/* 통계분석 > 월별회원현황 */}
              <Route
                exact
                path="/analysis/state/month"
                render={() => <AnalysisStateMonth onLogout={logoutHandler}/>}
              />
              {/* 주간업무보고 */}
              <Route
                exact
                path="/analysis/weekly/report"
                render={() => <AnalysisWeeklyReport onLogout={logoutHandler}/>}
              />
              {/* 월간업무보고 > 월간등록현황 */}
              <Route
                exact
                path="/analysis/monthly/member"
                render={() => <AnalysisMonthlyMember onLogout={logoutHandler}/>}
              />
              {/* 월간업무보고 > 월게표 비교분석 */}
              <Route
                exact
                path="/analysis/monthly/finance/new"
                render={() => <AnalysisMonthlyFinanceNew onLogout={logoutHandler}/>}
              />
              {/* 월간업무보고 > 쿠폰발송내역 */}
              <Route
                exact
                path="/analysis/monthly/coupon"
                render={() => <AnalysisMonthlyCoupon onLogout={logoutHandler}/>}
              />
              {/* 월간업무보고 > TM 현황보고 */}
              <Route
                exact
                path="/analysis/monthly/tm"
                render={() => <AnalysisMonthlyTm onLogout={logoutHandler}/>}
              />
              {/* 월간업무보고 > 지역분류 */}
              <Route
                exact
                path="/analysis/monthly/region"
                render={() => <AnalysisMonthlyRegion onLogout={logoutHandler}/>}
              />
              {/* 월간업무보고 > 경쟁업체(퍼블릭) */}
              <Route
                exact
                path="/analysis/monthly/public"
                render={() => <AnalysisMonthlyPublic onLogout={logoutHandler}/>}
              />
              {/* 월간업무보고 > 경쟁업체(PT) */}
              <Route
                exact
                path="/analysis/monthly/pt"
                render={() => <AnalysisMonthlyPt onLogout={logoutHandler}/>}
              />
              {/* 월간업무보고 > 월간등록현황 */}
              <Route
                exact
                path="/analysis/monthly/analysis/pt"
                render={() => <AnalysisMonthlyAnalysisPt onLogout={logoutHandler}/>}
              />
              {/* 스텝별 순위 */}
              <Route
                exact
                path="/analysis/long/period"
                render={() => <AnalysisLongPeriod onLogout={logoutHandler}/>}
              />
              {/* 지점랭킹-매출 */}
              <Route
                exact
                path="/analysis/branch/ranking"
                render={() => <AnalysisBranchRanking onLogout={logoutHandler}/>}
              />
              {/* 지점랭킹-순익 */}
              <Route
                exact
                path="/analysis/branch/ranking/real"
                render={() => <AnalysisBranchRankingReal onLogout={logoutHandler}/>}
              />
              {/* 지점랭킹-PT */}
              <Route
                exact
                path="/analysis/branch/ranking/pt"
                render={() => <AnalysisBranchRankingPt onLogout={logoutHandler}/>}
              />
              {/* 이용현황 */}
              <Route
                exact
                path="/analysis/use/state"
                render={() => <AnalysisUseState onLogout={logoutHandler}/>}
              />
              {/* KPI지표 순위 */}
              <Route
                exact
                path="/analysis/kpi"
                render={() => <AnalysisKpi onLogout={logoutHandler}/>}
              />
              {/* 매출현황-스텝별 */}
              <Route
                exact
                path="/analysis/manager/report"
                render={() => <AnalysisManagerReport onLogout={logoutHandler}/>}
              />
              {/* PT매출현황-트레이너별 */}
              <Route
                exact
                path="/analysis/trainer/report"
                render={() => <AnalysisTrainerReport onLogout={logoutHandler}/>}
              />
              {/* 지점별순익 */}
              <Route
                exact
                path="/analysis/branch/real/price"
                render={() => <AnalysisBranchRealPrice onLogout={logoutHandler}/>}
              />
              {/* 월계표비교분석 */}
              <Route
                exact
                path="/analysis/finance/monthly/report"
                render={() => <AnalysisFinanceMonthlyReport onLogout={logoutHandler}/>}
              />
              {/* 회계관리-회원권매출 */}
              <Route
                exact
                path="/analysis/branch/price"
                render={() => <AnalysisBranchPrice onLogout={logoutHandler}/>}
              />
              {/* 회계관리-PT매출 */}
              <Route
                exact
                path="/analysis/branch/price/pt"
                render={() => <AnalysisBranchPricePt onLogout={logoutHandler}/>}
              />
              {/* 만족도 조사 > 지점별 평균별점 */}
              <Route
                exact
                path="/analysis/satis/summary"
                render={() => <AnalysisSatisSummary onLogout={logoutHandler}/>}
              />
              {/* 만족도 조사 > 회원별 평균별점 */}
              <Route
                exact
                path="/analysis/satis/list"
                render={() => <AnalysisSatisList onLogout={logoutHandler}/>}
              />
              {/* 포인트 히스토리 - 지점별 리스트 */}
              <Route
                exact
                path="/analysis/point/history"
                render={() => <AnalysisPointHistory onLogout={logoutHandler}/>}
              />
              {/* 포인트 히스토리 - 지점별 합계 */}
              <Route
                exact
                path="/analysis/point/history/branch"
                render={() => <AnalysisPointHistoryBranch onLogout={logoutHandler}/>}
              />


              {/* ---------- CONFIG ---------- */}

              {/* IP관리 */}
              <Route
                exact
                path="/config/ip"
                render={() => <ConfigIp onLogout={logoutHandler}/>}
              />
              {/* 출석소리설정 */}
              <Route
                exact
                path="/config/set/sound"
                render={() => <ConfigSetSound onLogout={logoutHandler}/>}
              />
              {/* 메뉴권한 설정 */}
              <Route
                exact
                path="/config/auth/list"
                render={() => <ConfigAuthList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/config/auth/reg"
                render={() => <ConfigAuthReg onLogout={logoutHandler}/>}
              />
              {/* 방문경로 관리 */}
              <Route
                exact
                path="/config/join/route"
                render={() => <ConfigJoinRoute onLogout={logoutHandler}/>}
              />
              {/* 옵션관리 */}
              <Route
                exact
                path="/config/option"
                render={() => <ConfigOption onLogout={logoutHandler}/>}
              />
              {/* 년단위 주 설정 */}
              <Route
                exact
                path="/config/weekly/set"
                render={() => <ConfigWeeklySet onLogout={logoutHandler}/>}
              />
              {/* 계정관리 */}
              <Route
                exact
                path="/config/user/list"
                render={() => <ConfigUserList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/config/user/reg"
                render={() => <ConfigUserReg onLogout={logoutHandler}/>}
              />
              {/* 설문조사 */}
              <Route
                exact
                path="/config/research/list"
                render={() => <ConfigResearchList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/config/research/write"
                render={() => <ConfigResearchWrite onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/config/research/copy"
                render={() => <ConfigResearchCopy onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/config/research/view"
                render={() => <ConfigResearchView onLogout={logoutHandler}/>}
              />
              {/* 지출세부항목 설정 */}
              <Route
                exact
                path="/config/sub/price/setting"
                render={() => <ConfigSubPriceSetting onLogout={logoutHandler}/>}
              />
              {/* 지점정보 */}
              <Route
                exact
                path="/config/branch/list"
                render={() => <ConfigBranchList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/config/branch/reg"
                render={() => <ConfigBranchReg onLogout={logoutHandler}/>}
              />
              {/* 지점상품 */}
              <Route
                exact
                path="/config/item/list"
                render={() => <ConfigItemList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/config/item/reg"
                render={() => <ConfigItemReg onLogout={logoutHandler}/>}
              />
              {/* 브랜드 */}
              <Route
                exact
                path="/config/brand/list"
                render={() => <ConfigBrandList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/config/brand/reg"
                render={() => <ConfigBrandReg onLogout={logoutHandler}/>}
              />
              {/* 이용권 생성 */}
              <Route
                exact
                path="/config/gift/list"
                render={() => <ConfigGiftList onLogout={logoutHandler}/>}
              />
              {/* 이용권 생성(B2B) */}
              <Route
                exact
                path="/config/gift/list/b2b"
                render={() => <ConfigGiftListB2B onLogout={logoutHandler}/>}
              />
              {/* 쿠폰 생성 */}
              <Route
                exact
                path="/config/coupon/list"
                render={() => <ConfigCouponList onLogout={logoutHandler}/>}
              />
              {/* 응모권 생성 */}
              <Route
                exact
                path="/config/applicant"
                render={() => <ConfigApplicant onLogout={logoutHandler}/>}
              />
              {/* 스마일 평가기준 설정 */}
              <Route
                exact
                path="/config/smile/setting"
                render={() => <ConfigSmileSetting onLogout={logoutHandler}/>}
              />
              {/* 회원관리 배너설정 */}
              <Route
                exact
                path="/config/banner"
                render={() => <ConfigBanner onLogout={logoutHandler}/>}
              />

              {/* ---------- MESSAGE ---------- */}

              {/* SMS/LMS 발송내역 */}
              <Route
                exact
                path="/message/sms/send/list"
                render={() => <MessageSmsSendList onLogout={logoutHandler}/>}
              />
              {/* SMS/LMS 발송현황 */}
              <Route
                exact
                path="/message/sms/state"
                render={() => <MessageSmsSendState onLogout={logoutHandler}/>}
              />
              {/* SMS/LMS 예약내역 */}
              <Route
                exact
                path="/message/sms/reserve/list"
                render={() => <MessageSmsReserveList onLogout={logoutHandler}/>}
              />
              {/* TM 등록 */}
              <Route
                exact
                path="/message/tm/reg"
                render={() => <MessageTmReg onLogout={logoutHandler}/>}
              />
              {/* SMS 발송 */}
              <Route
                exact
                path="/message/sms/list"
                render={() => <MessageSmsList onLogout={logoutHandler}/>}
              />
              {/* PUSH 발송 */}
              <Route
                exact
                path="/message/push/list"
                render={() => <MessagePushList onLogout={logoutHandler}/>}
              />
              {/* PUSH 발송내역 */}
              <Route
                exact
                path="/message/push/send/list"
                render={() => <MessagePushSendList onLogout={logoutHandler}/>}
              />

              {/* ---------- EMPLOYEE ---------- */}

              {/* 스텝 관리 */}
              <Route
                exact
                path="/employee/list"
                render={() => <EmployeeList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/employee/reg"
                render={() => <EmployeeReg onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/employee/reg/pay"
                render={() => <EmployeeRegPay onLogout={logoutHandler}/>}
              />
              {/* 이동내역 */}
              <Route
                exact
                path="/employee/branch"
                render={() => <EmployeeBranch onLogout={logoutHandler}/>}
              />
              {/* 진급내역 */}
              <Route
                exact
                path="/employee/new/position"
                render={() => <EmployeeNewPosition onLogout={logoutHandler}/>}
              />
              {/* 명칭/형태/부서 설정 - 계약명칭관리 */}
              <Route
                exact
                path="/employee/config"
                render={() => <EmployeeConfig onLogout={logoutHandler}/>}
              />
              {/* 명칭/형태/부서 설정 - 계약형태관리 */}
              <Route
                exact
                path="/employee/config2"
                render={() => <EmployeeConfig2 onLogout={logoutHandler}/>}
              />
              {/* 명칭/형태/부서 설정 - 부서관리 */}
              <Route
                exact
                path="/employee/config3"
                render={() => <EmployeeConfig3 onLogout={logoutHandler}/>}
              />
              {/* 타겟수수료 */}
              <Route
                exact
                path="/employee/position/fee"
                render={() => <EmployeePositionFee onLogout={logoutHandler}/>}
              />
              {/* 그룹웨어 ID */}
              <Route
                exact
                path="/employee/groupware"
                render={() => <EmployeeGroupWare onLogout={logoutHandler}/>}
              />
              {/* 관심스포애너 */}
              <Route
                exact
                path="/employee/blacklist"
                render={() => <EmployeeBlackList onLogout={logoutHandler}/>}
              />
              {/* ACE 활동 > 고객유치 */}
              <Route
                exact
                path="/employee/ace/work"
                render={() => <EmployeeAceWork onLogout={logoutHandler}/>}
              />
              {/* ACE 활동 > 고객유지 */}
              <Route
                exact
                path="/employee/ace/report"
                render={() => <EmployeeAceReport onLogout={logoutHandler}/>}
              />
              {/* 독서경영 > 도서관리 */}
              <Route
                exact
                path="/employee/book/list"
                render={() => <EmployeeBookList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/employee/book/reg"
                render={() => <EmployeeBookReg onLogout={logoutHandler}/>}
              />
              {/* 독서경영 > 필독완료 */}
              <Route
                exact
                path="/employee/book/end/list"
                render={() => <EmployeeBookEndList onLogout={logoutHandler}/>}
              />
              {/* 독서경영 > 모임완료 */}
              <Route
                exact
                path="/employee/book/meet/list"
                render={() => <EmployeeBookMeetList onLogout={logoutHandler}/>}
              />
              {/* 스텝 Web표시 */}
              <Route
                exact
                path="/employee/pt/trainer/list"
                render={() => <EmployeePTtrainerList onLogout={logoutHandler}/>}
              />
              {/* 스텝 Web수정 */}
              <Route
                exact
                path="/employee/pt/trainer/reg"
                render={() => <EmployeePTtrainerReg onLogout={logoutHandler}/>}
              />
              {/* 투자자리스트 */}
              <Route
                exact
                path="/employee/inv/list"
                render={() => <EmployeeInvList onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/employee/inv/reg"
                render={() => <EmployeeInvReg onLogout={logoutHandler}/>}
              />
              {/* 투자자그룹(신규) */}
              <Route
                exact
                path="/employee/inv/group/new"
                render={() => <EmployeeInvGroupNew onLogout={logoutHandler}/>}
              />
              {/* 메일발송내역(신규) */}
              <Route
                exact
                path="/employee/inv/mail/send/list/new"
                render={() => <EmployeeInvMailSendListNew onLogout={logoutHandler}/>}
              />

              {/* ---------- SECURE ---------- */}

              {/* 권한수정이력 */}
              <Route
                exact
                path="/secure/menu/auth/history"
                render={() => <SecureMenuAuthHistory onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/secure/menu/auth/detail"
                render={() => <SecureMenuAuthDetail onLogout={logoutHandler}/>}
              />
              {/* 계정변경이력 */}
              <Route
                exact
                path="/secure/list/history"
                render={() => <SecureUserListHistory onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/secure/list/detail"
                render={() => <SecureUserListDetail onLogout={logoutHandler}/>}
              />
              {/* 엑셀다운이력 */}
              <Route
                exact
                path="/secure/excel/down/history"
                render={() => <SecureExcelDownHistory onLogout={logoutHandler}/>}
              />
              {/* 회원정보열람이력 */}
              <Route
                exact
                path="/secure/member/show/history"
                render={() => <SecureMemberShowHistory onLogout={logoutHandler}/>}
              />

              {/* ---------- 매뉴얼북 ---------- */}
              <Route
                exact
                path="/guide/step/manual"
                render={() => <StepManual onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/guide/smms/manual"
                render={() => <SmmsManual onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/guide/clean/manual"
                render={() => <CleanManual onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/guide/info/plan"
                render={() => <InfoPlan onLogout={logoutHandler}/>}
              />
              <Route
                exact
                path="/guide/info/manual"
                render={() => <InfoManual onLogout={logoutHandler}/>}
              />

              {/* ---------- ENTER ---------- */}

              {/* index */}
              <Route
                exact
                path="/enter/intro"
                render={() => <Intro onLogout={logoutHandler}/>}
              />

              {/* enter */}
              <Route
                exact
                path="/enter/enter"
                render={() => <Enter onLogout={logoutHandler}/>}
              />

              {/* main */}
              <Route
                exact
                path="/enter/main"
                render={() => <Main onLogout={logoutHandler}/>}
              />
              <Route path={"*"} render={() => <NotFound/>}/>
            </Switch>

          </Refreshable>
          <EntranceWin isOpen={isEntWinOpen} onClose={closeEntWinHandler}/>
        </AuthContext.Provider>
      </GlobalContextProvider>
    </Router>
  );
}

export default App;
