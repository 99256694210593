import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Constants from "../../constants/constants";


const ConfigBanner = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [renew, setRenew] = useState(0);
  const [result, setResult] = useState({});

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/branchop/banner/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult(result);

          if (result?.row?.banner_img?.length > 0)
            $('#banner_img').attr('src', Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=member_pic&filename=" + encodeURIComponent(result?.row?.banner_img)));

          if (result?.row) {
            $('#link_url').val(result?.row?.link_url);
            if (result?.row?.new_win === "Y")
              $('#new_win').prop("checked", true);
            else
              $('#new_win').prop("checked", false);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const img_del = () => {
    if (window.confirm("등록된 배너를 삭제하시겠습니까?")) {
      axios2
        .post(`/branchop/banner?type=del`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  const go_submit = () => {
    const formData = new FormData();

    formData.append('link_url', $('#link_url').val());
    formData.append('new_win', $('#new_win').is(":checked") ? "Y" : "N");
    formData.append('userfile', $('#userfile')[0].files[0]);

    axios2.postFormEx('/branchop/banner', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (code === 200) {
          alert('저장하였습니다.');
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{maxWidth: "935px"}}>
        <table className="view-rounded sub_table_rowspan text-left">
          <colgroup>
            <col width="130px"/>
            <col width="*"/>
            <col width="30%"/>
          </colgroup>
          <tr>
            <th>배너업로드</th>
            <td>
              <div className="input-group">
                <label htmlFor="formFile" className="form-label"></label>
                <input className="form-control" type="file" id="userfile"/>
                {/*<button type="button" className="btn btn-ssm btn-dark px-3">배너업로드</button>*/}
              </div>
            </td>
            <td rowSpan="3" className="text-center border-bottom-0">
              {result?.row?.banner_img?.length > 0 ?
                <>
                  <img id="banner_img" style={{width: "160px", objectFit: "contain"}}/>
                  <p>권장사이즈 넓이 : 160px</p>
                </>
                :
                <>
                  <div className="banner_thum"></div>
                  <p>권장사이즈 넓이 : 160px</p>
                </>
              }
            </td>
          </tr>
          <tr>
            <th>링크</th>
            <td><input type="text" className="form-control table-inline" placeholder="http://" id="link_url"/></td>
          </tr>
          <tr>
            <th>새창</th>
            <td>
              <div className="form-check">
                <input className="form-check-input2" type="checkbox" value="Y" id="new_win"/>
                <label className="form-check-label" htmlFor="new_win">
                  새창
                </label>
              </div>
            </td>
          </tr>
        </table>
        <div className="text-center mt-3">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
          <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => img_del()}>삭제</button>
        </div>
      </div>
    </CmnCard>
  );
};

export default ConfigBanner;
