import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";

const SalesProductPosition = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('#base_seq').val('');
    $('#sub_code_name').val('');
    $('#show_YN').val('Y');

    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/sales/sales/product/position/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.list);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const go_submit = () => {
    // const regBtn = $('.reg-btn');
    // regBtn.html("<a class='btn btn-danger btn-sm'><span class='glyphicon glyphicon-saved'></span> 저장</a>");

    if ($('#sub_code_name').val() === '') {
      alert('품목분류명을 입력해주세요');
      $('#sub_code_name').focus();
      // regBtn.html("<a class='btn btn-danger btn-sm' onclick='go_submit()'><span class='glyphicon glyphicon-saved'></span> 저장</a>");
      return;
    }

    axios2
      .postEx('/sales/sales/product/position', {
        base_seq: $('#base_seq').val(),
        sub_code_name: $('#sub_code_name').val(),
        show_YN: $('#show_YN option:selected').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (data == "in_ok") {
          alert("등록되었습니다");
          setRenew(Fn.getRenewTime());
        } else if (data == "up_ok") {
          alert("수정되었습니다");
          setRenew(Fn.getRenewTime());
        } else if (data == "del_ok") {
          alert("삭제되었습니다");
          setRenew(Fn.getRenewTime());
        } else if (data == "in_err") {
          alert("등록오류! 관리자에게 문의하세요");
          // setRenew(Fn.getRenewTime());
        } else if (data == "up_err") {
          alert("수정오류! 관리자에게 문의하세요");
          // setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  function copy_data(base_seq, sub_code_name, show_YN) {
    $('#base_seq').val(base_seq);
    $('#sub_code_name').val(sub_code_name);
    $('#show_YN').val(show_YN);

    $('#sub_code_name').focus();
  }

  const del_data = (base_seq) => {
    if (!window.confirm('해당 데이터를 삭제하시겠습니까?')) return;

    axios2
      .postEx('/sales/sales/product/position', {
        type: 'del',
        base_seq: base_seq,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        alert("삭제되었습니다");
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="wid_MAX" style={{maxWidth: "800px"}}>
        <div className="m-3 search_box">
          {/* 회원검색 */}
          <div className="p-3">
            <input type="hidden" id="base_seq" value=""/>
            <table className="view-rounded sub_view">
              <colgroup>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>품목분류명</th>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    aria-describedby="button-addon2"
                    id="sub_code_name"
                  />
                </td>

                <th>전시여부</th>
                <td className="text-left">
                  <select className="form-select" aria-label="Default select example" id="show_YN" style={{maxWidth: "40%"}}>
                    <option value="Y">전시</option>
                    <option value="N">미전시</option>
                  </select>
                </td>
              </tr>
            </table>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>
                저장
              </button>
            </div>
          </div>
        </div>

        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="60px"/>
              <col width="45%"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>품목분류명</th>
              <th>전시여부</th>
              <th>비고</th>
            </tr>

            {rets && rets.map((ret, i) =>
              <tr>
                <td>{i + 1}</td>
                <td className="text-left">{ret?.sub_code_name}</td>
                <td>{ret?.show_YN === 'Y' ? <span style={{color: 'blue'}}>전시</span> : <span style={{color: 'red'}}>미전시</span>}</td>
                <td>
                  <button type="button" className="btn btn-ssm btn-outline-point mx-1"
                          onClick={() =>
                            copy_data(
                              btoa(ret?.seq),
                              ret?.sub_code_name,
                              ret?.show_YN
                            )
                          }
                  >
                    수정
                  </button>
                  <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => del_data(btoa(ret?.seq))}>
                    삭제
                  </button>
                </td>
              </tr>)}
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default SalesProductPosition;
