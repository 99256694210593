import React, {useEffect, useRef} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios from "axios";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";

const BranchopGxBasicReg = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.baseSeq || '';

  useEffect(() => {
    $('#base_seq').val(baseSeq);
    load();
  }, []);

  const load = () => {
    axios2
      .post(`/branchop/gx/basic/reg/ready?base_seq=${$('#base_seq').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#gx_gubun').html() === '') {
            let optionsHTML = `<option value="">::GX 구분::</option><option value="opt4">스피닝</option><option value="opt7">필라테스</option>`;
            result?.gx_gubun_list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.gx_value}">${list_row.gx_name}</option>`;
            });
            $('#gx_gubun').html(optionsHTML);
          }
          if ($('#gx_step').html() === '') {
            let optionsHTML = `<option value="">::GX 스텝::</option>`;
            result?.gx_step_list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#gx_step').html(optionsHTML);
          }

          if (result?.base_seq) {
            $('#gx_type').val(result?.gx_type || '');
            $('#gx_gubun').val(result?.gx_gubun || '');
            $('#start_hour').val(result?.start_hour || '');
            $('#start_minute').val(result?.start_minute || '');
            $('#gx_name').val(result?.gx_name || '');
            $('#man_num').val(result?.man_num || '');
            $('#gx_intro').val(result?.gx_intro || '');
            $('#gx_step').val(result?.gx_step || '');
            $('#use_YN').val(result?.use_YN || '');
            if (result?.day1 === 'Y') $('#day1').prop("checked", true);
            if (result?.day2 === 'Y') $('#day2').prop("checked", true);
            if (result?.day3 === 'Y') $('#day3').prop("checked", true);
            if (result?.day4 === 'Y') $('#day4').prop("checked", true);
            if (result?.day5 === 'Y') $('#day5').prop("checked", true);
            if (result?.day6 === 'Y') $('#day6').prop("checked", true);
            if (result?.day7 === 'Y') $('#day7').prop("checked", true);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {
    if ($('#gx_type option:selected').val() === '') {
      window.alert('GX타입을 선택해주세요');
      $('#gx_type').focus();
      return;
    }
    if ($('#gx_gubun option:selected').val() === '') {
      window.alert('GX구분을 선택해주세요');
      $('#gx_gubun').focus();
      return;
    }
    if ($('#gx_name').val() === '') {
      window.alert('GX이름을 입력해주세요');
      $('#gx_name').focus();
      return;
    }
    if ($('#man_num').val() === '') {
      window.alert('정원을 입력해주세요');
      $('#man_num').focus();
      return;
    }
    if (
      !$('#day1').prop("checked") &&
      !$('#day2').prop("checked") &&
      !$('#day3').prop("checked") &&
      !$('#day4').prop("checked") &&
      !$('#day5').prop("checked") &&
      !$('#day6').prop("checked") &&
      !$('#day7').prop("checked")
    ) {
      window.alert('요일을 입력해주세요');
      return;
    }

    axios2
      .postEx('/branchop/gx/basic/reg', {
        base_seq: $('#base_seq').val(),
        gx_type: $('#gx_type option:selected').val(),
        gx_gubun: $('#gx_gubun option:selected').val(),
        start_hour: $('#start_hour option:selected').val(),
        start_minute: $('#start_minute option:selected').val(),
        gx_name: $('#gx_name').val(),
        man_num: $('#man_num').val(),
        day1: $('#day1:checked').val() || '',
        day2: $('#day2:checked').val() || '',
        day3: $('#day3:checked').val() || '',
        day4: $('#day4:checked').val() || '',
        day5: $('#day5:checked').val() || '',
        day6: $('#day6:checked').val() || '',
        day7: $('#day7:checked').val() || '',
        gx_intro: $('#gx_intro').val(),
        gx_step: $('#gx_step option:selected').val(),
        use_YN: $('#use_YN option:selected').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === 'err') {
          window.alert('오류가 발생하였습니다');
        } else if (data === 'insert_ok') {
          window.alert('등록되었습니다.');
          history.push("/branchop/gx/basic");
        } else if (data === 'edit_ok') {
          window.alert('수정되었습니다');
          history.push("/branchop/gx/basic");
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const chkGx = () => {
    const gxType = $('#gx_type option:selected').val();
    const gxGubun = $('#gx_gubun option:selected').val();

    if (gxType === 'B') {
      if (gxGubun === 'opt4' || gxGubun === 'opt7' || gxGubun === '') {
      } else {
        window.alert('유료수업은 스피닝과 필라테스만 가능합니다');
        $('#gx_type').val('');
        $('#gx_gubun').val('');
      }
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 pb-2">
        <table className="view-rounded sub_view text-left">
          <colgroup>
            <col width="120px"/>
            <col width="*"/>
            <col width="120px"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>GX 타입</th>
            <td>
              <select className="form-select" style={{maxWidth: "220px"}} id="gx_type" onChange={() => chkGx()}>
                <option value=''>GX 타입</option>
                <option value="A">무료회원</option>
                <option value="B">유료회원</option>
              </select>
            </td>
            <th>GX 구분</th>
            <td>
              <select className="form-select" style={{maxWidth: "220px"}} id="gx_gubun" onChange={() => chkGx()}>
              </select>
            </td>
          </tr>
          <tr>
            <th>GX 이름</th>
            <td><input type="text" className="form-control" id="gx_name"/></td>
            <th>정원</th>
            <td><input type="text" className="form-control" id="man_num"/></td>
          </tr>
          <tr>
            <th>요일</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" id="day1" value="Y"/>
                <label className="form-check-label" htmlFor="day1">월</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" id="day2" value="Y"/>
                <label className="form-check-label" htmlFor="day2">화</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" id="day3" value="Y"/>
                <label className="form-check-label" htmlFor="day3">수</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" id="day4" value="Y"/>
                <label className="form-check-label" htmlFor="day4">목</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" id="day5" value="Y"/>
                <label className="form-check-label" htmlFor="day5">금</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" id="day6" value="Y"/>
                <label className="form-check-label" htmlFor="day6">토</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="checkbox" id="day7" value="Y"/>
                <label className="form-check-label" htmlFor="day7">일</label>
              </div>
            </td>
            <th>시작시간</th>
            <td>
              <div className="d-flex">
                <select className="form-select" style={{maxWidth: "150px"}} id="start_hour">
                  {Array.from({length: 24}).map((_, i) => {
                    const j = i < 10 ? `0${i}` : i;
                    return (
                      <option value={j}>{j}시</option>
                    );
                  })}
                </select>
                <p className="m-1">:</p>
                <select className="form-select" style={{maxWidth: "150px"}} id="start_minute">
                  {Array.from({length: 12}).map((_, i) => {
                    const j = i * 5 < 10 ? `0${i * 5}` : i * 5;
                    return (
                      <option value={j}>{j}분</option>
                    );
                  })}
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <th>소개</th>
            <td colSpan="3" className="p-2">
              <textarea className="form-control w-100" rows="15" cols="100" id="gx_intro"></textarea>
            </td>
          </tr>
          <tr>
            <th>강사명</th>
            <td>
              <select className="form-select" style={{maxWidth: "150px"}} id="gx_step">
              </select>
            </td>
            <th>운영여부</th>
            <td>
              <select className="form-select" style={{maxWidth: "150px"}} id="use_YN">
                <option value="Y">운영</option>
                <option value="N">중지</option>
              </select>
            </td>
          </tr>
        </table>
      </div>
      <div className="text-center my-4">
        <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit()}>저장</button>
        <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.goBack()}>목록</button>
      </div>

      <input type="hidden" id="base_seq"/>

    </CmnCard>
  );
};

export default BranchopGxBasicReg;
