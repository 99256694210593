import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";

const SecureUserListDetail = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  // TODO : 스크립트 원본과 비교하여 정확하게 교정해야 함

  const history = useHistory();
  const location = useLocation();
  const baseSeq = location?.state?.baseSeq || '';
  const type = location?.state?.type || '';

  const [result, setResult] = useState({});

  useEffect(() => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 9) {
      alert('메뉴 접근 권한이 없습니다.');
      history.goBack();
      return;
    }

    load();
  }, []);

  const load = () => {
    axios2
      .post(`/secure/user/history/detail/ready?base_seq=${baseSeq || ''}&type=${type}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult(result);

          if ($('#menu_auth').html() === '') {
            let optionsHTML = '<option value="">::메뉴권한선택</option>';
            result?.list1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.auth_name}</option>`;
            });
            $('#menu_auth').html(optionsHTML);
          }
          if ($('#branch_code').html() === '') {
            let optionsHTML = '<option value="">::소속(지점)선택</option>';
            result?.list3?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#branch_code').html(optionsHTML);
          }

          $('#menu_auth').val(result?.result?.[0]?.menu_auth);
          $('#branch_code').val(result?.result?.[0]?.branch_code);
          $('#user_name').val(result?.result?.[0]?.user_name);
          // $('#etc_auth').val(result?.result?.[0]?.user_name);
          if(result?.result?.[0]?.use_YN==='Y') $('#use_YN').prop("checked", true);
          $('#manage_branch').val(result?.result?.[0]?.manage_branch);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <input type="hidden" id="base_seq"/>

      <div className="m-3 mb-4" style={{border: "1px solid #eee", borderRadius: "12px"}}>
        <div className="border-bottom text-left">
          <p className="px-3 py-2">
            <span className="h6 bold700">계정 수정이력</span>
          </p>
        </div>
        <div className="p-3">
          <table className="view-rounded sub_view text-left">
            <colgroup>
              <col width="120px"/>
              <col width="*"/>
              <col width="120px"/>
              <col width="*"/>
              <col width="120px"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>ID</th>
              <td>
                <span className="bold700">{result?.result?.[0]?.user_id}</span>
              </td>
              <th>비밀번호</th>
              <td>
                <input type="password" className="form-control" id="user_pwd"/>
              </td>
              <th>성명</th>
              <td>
                <input type="text" className="form-control" id="user_name"/>
              </td>
            </tr>
            <tr>
              <th>사용메뉴</th>
              <td>
                <select className="form-select" id="user_auth">
                  <option value="9">본사관리</option>
                  <option value="8">본사스텝</option>
                  <option value="7">지점관리</option>
                  <option value="6">지점스텝</option>
                </select>
              </td>
              <th>메뉴권한</th>
              <td>
                <select className="form-select" id="menu_auth">
                </select>
              </td>
              <th>소속(지점)</th>
              <td>
                <select className="form-select" id="branch_code">
                </select>
              </td>
            </tr>
            <tr>
              <th>지부장/경리팀</th>
              <td>
                <div className="d-flex w-100">
                  <select className="form-select w-75" id="etc_auth">
                    <option value="">해당없음</option>
                    <option value="A">지부장</option>
                    <option value="B">경리팀</option>
                  </select>
                  <span id="manage_show" style={{display: "none"}}/>
                  <button className="btn btn-ssm btn-outline-point" type="button">관리지점설정</button>
                  <input type="hidden" name="manage_branch"/>
                </div>
              </td>
              <th>로그인허용</th>
              <td>
                <input type="checkbox" id="use_YN"/>
              </td>
              <th></th>
              <td></td>
            </tr>
          </table>
        </div>
      </div>
      <div className="m-4 text-center">
        <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.goBack()}>목록</button>
      </div>

    </CmnCard>
  );
};

export default SecureUserListDetail;
