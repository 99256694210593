import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import axios from "axios";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";


const FinanceBankBook = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [result, setResult] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('#s_date1').val(Fn.getCurrentDate());
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/finance/finance/bankbook/ready?s_date1=${$('#s_date1').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //2-1
  const form1 = document.getElementById('form1');
  const reg_btn = document.getElementById('reg_btn');

  const go_submit = () => {
    // reg_btn.innerHTML = "<a class=' btn btn-danger btn-sm' ><span class='glyphicon glyphicon-pencil'></span>  입금 저장</a>";

    if (!$('#ip_bank').val()) {
      alert('은행을 입력해주세요');
      // reg_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='go_submit()'><span class='glyphicon glyphicon-pencil'></span>  입금 저장</a>";
      $('#ip_bank').focus();
      return;
    }

    if (!$('#amt').val()) {
      alert('금액을 입력해주세요');
      // reg_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='go_submit()'><span class='glyphicon glyphicon-pencil'></span>  입금 저장</a>";
      $('#amt').focus();
      return;
    }

    if ($('#auth_code').val() === '') {
      alert("인증코드를 입력해주세요");
      // reg_btn.innerHTML = "<a class=' btn btn-danger btn-sm' onclick='go_submit()'><span class='glyphicon glyphicon-pencil'></span>  입금 저장</a>";
      $('#search_auth_code').focus();
      return;
    }

    // const form = document.getElementsByTagName('form')[0];
    // const formData = new FormData(form);

    axios2
      .postEx('/finance/finance/bankbook', {
        ip_bank: $('#ip_bank').val(),
        amt: $('#amt').val(),
        etc: $('#etc').val(),
        auth_code: $('#auth_code').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else {
          alert('등록되었습니다')
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const del_bankbook = (ckk_no) => {
    if ($('[name=auth_code]').val() === '') {
      alert("인증코드를 입력해주세요");
      $('[name=search_auth_code]').focus();
      return;
    }

    if (window.confirm('해당 통장입금건을 삭제하시겠습니까?')) {
      axios2.postEx('/finance/finance/bankbook', {
        type: 'del',
        ckk_no: ckk_no,
        auth_code: $('#auth_code').val()
      })
        .then(response => {
          alert("삭제되었습니다");
          setRenew(Fn.getRenewTime());
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 mb-4" style={{border: "1px solid #eee", borderRadius: "12px", maxWidth: "1235px"}}>
        <div className="d-flex justify-content-between border-bottom px-3 p-2">
          <div className="mt-1"><span className="h6 bold700">통장입금 입력</span></div>

          <div>
            <button type="button" className="btn btn-sm btn-point mx-1" onClick={() => go_submit()}>입금저장</button>
          </div>
        </div>
        <div className="p-3">
          <table className="view-rounded sub_table_border text-center auto">
            <tr>
              <th>이체통장</th>
              <td style={{width: "200px"}}>
                <select className="form-select" id="ip_bank">
                  <option value="">선택</option>
                  {result?.qry?.map((item) => (
                    <option key={item?.j_no} value={`${item?.j_no},${item?.j_jukname}`}>
                      {item?.j_jukname}
                    </option>
                  ))}
                </select>
              </td>
              <th>이체금액</th>
              <td style={{width: "130px"}}><input type="text" className="form-control" id="amt"/></td>
              <th>메모</th>
              <td><input type="text" className="form-control" id="etc"/></td>
              <th>인증</th>
              <td>
                <div className="d-flex mx-1">
                  <ChkAuthNum type={"locker_info_change"}/>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div className="d-flex m-3" style={{maxWidth: "1235px"}}>
        <div className="my-1 d-flex flex-nowrap">
          <div className="pr-1">
            <JDatePicker className="form-control" id="s_date1"/>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>
      </div>

      <div className="m-3 pb-2" style={{maxWidth: "1235px"}}>
        <table className="view-rounded sub_table_border auto">
          <tr>
            <th className="w_70px">No</th>
            <th>통장</th>
            <th>이체금액</th>
            <th className="w-40">메모</th>
            <th>등록자</th>
            <th>삭제</th>
          </tr>

          {result?.result?.map((row, count) => {
            const ckk_summary = row.ckk_summary;
            const ckk_amt = row.ckk_amt;
            const ckk_etc = row.ckk_etc;
            const ckk_step = row.ckk_step;
            const ckk_no = row.ckk_no;

            return (
              <tr key={count}>
                <td>{count + 1}</td>
                <td>{ckk_summary}</td>
                <td className="text-right">{new Intl.NumberFormat().format(ckk_amt)}</td>
                <td className="text-left">{ckk_etc}</td>
                <td>{ckk_step}</td>
                <td>
                  {result?.delAuth ? (
                    <button type="button" className="btn btn-ssm btn-outline-dark"
                            onClick={() => del_bankbook(ckk_no)}
                    >
                      삭제
                    </button>
                  ) : (
                    <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => alert('당일건에 한해 지점관리자 이상만 삭제가능합니다')}>
                      삭제
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
          <tr className="bold700 bgcolor">
            <td colSpan={2}><strong>합계</strong></td>
            <td colSpan={1} className="text-right"><strong>{Fn.numberWithCommas(result.sum)}</strong></td>
            <td colSpan={3}></td>
          </tr>
        </table>
      </div>

    </CmnCard>
  );
};

export default FinanceBankBook;
