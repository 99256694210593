import {useHistory} from "react-router-dom";
import "../../sass/main.scss";
import React, {useEffect, useRef, useState} from "react";
import Fn from "../../libraries/Fn";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";

import introLogo from '../../images/intro_logo2.png';
import Modal from "react-modal";
import Constants from "../../constants/constants";

const Intro = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();
  const [branches, setBranches] = useState([]);

  useEffect(() => {
    axios2
      .post(`/common/update/check?build=${Constants.BUILD}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if (message === 'update') {
            window.location.reload();
          }
        }
      })
      .catch((error) => {
      });
  }, [window.location.pathname]);

  useEffect(() => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 0) {
      history.push("/login");
      return;
    }

    $.ajaxSetup({
      beforeSend: function (xhr, options) {
        if (options.url.startsWith(":3000"))
          options.url = options.url.replace(":3000", ":8994");
        else if (options.url.indexOf("http") === -1)
          options.url = Constants.API_URL + options.url;
      },
      headers: {
        'Authorization': ('Bearer ' + localStorage.getItem("USER.token"))
      },
      cache: false,
      error: function (jqXHR, textStatus, errorThrown) {
        Fn.parseAjaxError(jqXHR, textStatus, errorThrown, history);
      }
    });

    $('.intro_wrap').height($(document).height());
    load();
  }, []);

  const load = () => {
    loadBranches();
  };

  const loadBranches = () => {
    axios2
      .post(`/enter/index/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $("#top_S_branch_list").empty();
          if ((Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 8 && localStorage.getItem("USER.systemManageYN") != "Y") || localStorage.getItem("USER.systemManageYN") == "Y") {
            let html = "";
            result.Top_list.forEach((obj) => {
              html += `<option value="${obj.branch_code}" ${localStorage.getItem("USER.systemBranchCode") == obj.branch_code ? " selected" : ""}>${obj.branch_name}</option>`;
            });
            $("#top_S_branch_list").html(html);
          } else {
            $("#top_S_branch_list").html(`<option value="">${localStorage.getItem("USER.systemBranchName")}</option>`);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  useEffect(() => {
    window.currentToken = localStorage.getItem("USER.token")
    window.sessionCheckTimeout = setInterval(() => {
      if (localStorage.getItem("USER.token") == null || localStorage.getItem("USER.token") == undefined || localStorage.getItem("USER.token") == '') {
        clearInterval(window.sessionCheckTimeout);
        history.replace("/login");
        return;
      }
      if (window.currentToken != '' && window.currentToken !== localStorage.getItem("USER.token")) {
        window.currentToken = localStorage.getItem("USER.token");
        clearInterval(window.sessionCheckTimeout);
        window.location.reload();
      }
    }, 3500);
    return () => {
      clearInterval(window.sessionCheckTimeout);
    }
  }, []);

  function changeBranch() {
    const newBranchCode = $("#top_S_branch_list option:selected").val();

    axios2.post(`/inc/session/change?branch_code=${newBranchCode}`)
      .then(function (response) {
        const {code, message, result, pageInfo} = response?.data;
        localStorage.setItem("USER.systemBranchCode", result?.systemBranchCode);
        localStorage.setItem("USER.systemBranchName", result?.systemBranchName);
        localStorage.setItem("USER.token", result?.token);
        localStorage.setItem("USER.systemBrand", result?.systemBrand);

        if (result?.token == localStorage.getItem("USER.token")) {
          window.location.reload();
          return;
        }
        window.currentToken = result?.token;
        localStorage.setItem("USER.token", result?.token);

        if (true) {
          try {
            window._load();
          } catch (err) {
          }
        } else {
          // window.parent.location.reload();
          // history.go(0);
        }
      })
      .catch(function (error) {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <div id="INTRO_BG" className="cls-enter">
      <div className="intro_wrap">
        <div className="p_absolute_mid2">
          <div className="d-flex justify-content-between my-2">
            <p className="h5 bold700 text-white">{localStorage.getItem("USER.systemName")}님 환영합니다.</p>
            <p>
              <select className="form-select" style={{height: '38px'}} id="top_S_branch_list" onChange={() => changeBranch()}>
                {branches && branches.map((ret, i) => <option value={ret?.branch_code} selected={localStorage.getItem("USER.systemBranchCode") == ret.branch_code}>{(ret.ho || '') + "." + ret.branch_name}</option>)}
              </select>
            </p>
          </div>
          <div className="intro_box" style={{width: '940px', height: '480px'}}>
            <div className="m-4 p-4 border-bottom">
              <p><img src={introLogo} style={{width: '205px'}} alt="Intro Logo"/></p>
              <p className="h6 py-3">Spoany Member Management System</p>
            </div>

            <div className="d-flex p-4">
              <div style={{width: '48%'}}>
                <p className="h5 bold700 pb-2">스포애니를 이용하시는 <span className="h5 bold700 text-pink">회원</span>을 위한<br/>
                  <span className="h5 bold700 text-pink">출입 관리시스템</span>
                </p>
                <p className="mt-4">
                  <button type="button" className="btn btn-point" style={{width: '275px', height: '75px'}} onClick={() => history.replace("/enter/enter")}><span className="h5">입장관리</span></button>
                </p>
              </div>
              <div className="border-right" style={{width: '5px'}}></div>
              <div style={{width: '48%'}}>
                <p className="h5 bold700 pb-2">스포애니와 회원을 관리하는<br/>
                  <span className="h5 bold700 text-pink">회원관리 시스템</span>
                </p>
                <p className="mt-4">
                  <button type="button" className="btn btn-ligray" style={{width: '275px', height: '75px'}} onClick={() => {
                    localStorage.setItem("logout_min", "90");
                    localStorage.setItem("logout_sec", "00");
                    localStorage.setItem("login_ck_date", Fn.getRenewTime().toString());

                    history.push("/main/summary");
                  }}><span className="h5">회원관리</span></button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
