import {useHistory} from "react-router-dom";
import "../sass/main.scss";
import React, {useEffect, useRef, useState} from "react";
import Fn from "../libraries/Fn";
import $ from 'jquery';
import axios2 from "../helpers/APIHelper";

import kd_logo from "../images/kd_logo.svg";
import Modal from "react-modal";
import Constants from "../constants/constants";

const NotFound = (props) => {
  const history = useHistory();

  return (
    <div id="LOGIN_WRAP">
      <div id="overlay">
        <div className="cv-spinner">
          <span className="spinner"></span>
        </div>
      </div>
      <div className="container-fluid">
        <div className="Login_box d-flex justify-conntent-center">
          <div className="Left_img_area">
            <img src={kd_logo} alt="KD Logo"/>
          </div>
          <div className="Right_input_area p-5 d-flex justify-content-center align-content-center align-items-center" style={{flexDirection: "column"}}>
            <h4 className="pt-2 text-left bold700">404 NOT FOUND</h4>
            <div style={{margin: '30px auto', fontSize: "20px", fontWeight: "bold"}}>
              존재하지 않는 페이지입니다.
            </div>

            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-2 mx-1" onClick={() => history.goBack()}>돌아가기</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
