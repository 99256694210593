import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";


import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const MemberRefundList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/member/refund/list/ready?page=${currPage}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_manager_name=${encodeURIComponent($('#s_manager_name').val())}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  1-1.

  //  2-2.
  // TODO : 변환 필요
  // <select id="S_top_brand" value={newBrand} onChange={Top_select_brand}>
  //     {/* Render your brand options here */}
  // </select>
  //
  // <ul id="top_S_branch_list">
  //     {branchList.map((branch, index) => (
  //         <li key={index}>{branch}</li>
  //     ))}
  // </ul>

  //  3-1.
  const make_monitor = () => {
    const as = window.open('/inc/black.html', 'enter_monitor', 'width=0,height=0,scrollbars=yes,top=10000,left=10000');
    as.window.close();
    const as2 = window.open('/enter/enter_monitor.html', 'enter_monitor', 'width=500,height=500,scrollbars=yes,top=600,left=1000');

    return () => {
      as2.close();
    };
  };

  //  4-1.
  const input_overtime = () => {
    const pop_up = window.open('overtime_reg.html', 'overtime_reg', 'width=750,height=270,scrollbars=no');
  };

  //  4-2.
  // TODO : 변환 필요
  // <button onClick={input_overtime}></button>

  //  5-1.
  const refund_del = (baseSeq) => {
    axios2
      .post(`/member/refund/del?base_seq=${baseSeq}`)
      .then((response) => {
        alert('삭제되었습니다');
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        console.error(error);
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>

      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1 d-flex">
          <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>
            환불일 :
          </label>
          <div className="input-group" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
        </div>

        <div className="pr-1">
          <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="스텝명 검색" id="s_manager_name"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>
            검색
          </button>
        </div>
      </div>


      <div className="m-3 table_list">
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="30%"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>환불일</th>
            <th>회원명</th>
            <th>회원번호</th>
            <th>차감스텝</th>
            <th>차감금액</th>
            <th>등록자</th>
            <th>비고</th>
            <th>삭제</th>
          </tr>
          {rets && rets.map((ret, i) =>
            <tr>
              <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
              <td>{ret?.refund_date}</td>
              <td>{ret?.incentive_mb_name}</td>
              <td>{ret?.incentive_mb_id}</td>
              <td>{ret?.user_name}</td>
              <td className="text-right">{ret?.incentive_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
              <td>{ret?.reg_step_name}</td>
              <td className="text-left">{ret?.bigo}</td>
              <td>
                <button type="button" className="btn btn-ssm btn-outline-dark px-2" onClick={() => {
                  if (window.confirm('해당 환불내역을 삭제하시겠습니까?')) {
                    refund_del(btoa(ret?.incentive_seq));
                  }
                }}>
                  삭제
                </button>
              </td>
            </tr>
          )}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={9}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 my-4 d-flex justify-content-center border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

    </CmnCard>
  );
};

export default MemberRefundList;
