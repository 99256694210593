import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Modal from "react-modal";
import _ from "lodash";
import {useHistory} from "react-router-dom";


const FinanceMonthly = (props) => {
  const history = useHistory();

  window._load = (_page = 1) => {
    try {
      load(_page);

      if (localStorage.getItem("fm_type") !== undefined && localStorage.getItem("fm_type") !== "")
        loadDtl(localStorage.getItem("fm_type"), localStorage.getItem("fm_k"), localStorage.getItem("fm_v"));
      else {
        localStorage.setItem("fm_type", '_NONE_');
        localStorage.setItem("fm_k", '_NONE_');
        localStorage.setItem("fm_v", '');
        loadDtl(localStorage.getItem("fm_type"), localStorage.getItem("fm_k"), localStorage.getItem("fm_v"));
      }
    } catch (e) {
    }
  }

  const [renew, setRenew] = useState(0);
  const [result, setResult] = useState({});
  const [resultDtl, setResultDtl] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [type, setType] = useState('in');
  const [subTitle, setSubTitle] = useState('계정상세내역');

  const [modalOpen, setModalOpen] = useState(false);
  const [mvType, setMvType] = useState('A');
  const [modalResult, setModalResult] = useState();
  const [modalRenew, setModalRenew] = useState(0);

  let C_id = 0;
  let tMC = 0;

  if (props?.match?.params?.rn != "rsrv") {
    $('#table_type').val('new');
    $('#ddate').val('0');

    localStorage.setItem("fm_s_date1", Fn.getCurrentDate());
    localStorage.setItem("fm_s_date2", Fn.getCurrentDate());

    localStorage.setItem("fm_type", '_NONE_');
    localStorage.setItem("fm_k", '_NONE_');
    localStorage.setItem("fm_v", '');

    localStorage.setItem("fm_ddate", "0");
    localStorage.setItem("fm_table_type", "new");

    history.push("/finance/monthly/rsrv/" + Fn.getRenewTime());
  }

  useEffect(() => {
    $(document).on('scroll', function () {
      let scrollTop = $(window).scrollTop();
      if (scrollTop > 190)
        $("#detail_area").offset({top: scrollTop + 80});
      else
        $("#detail_area").offset({top: 190});
    });
  }, []);

  useEffect(() => {
    $('#ddate').val(localStorage.getItem("fm_ddate"));
    $('#table_type').val(localStorage.getItem("fm_table_type"));
    $('#s_date1').val(localStorage.getItem("fm_s_date1"));
    $('#s_date2').val(localStorage.getItem("fm_s_date2"));
    load();
    loadDtl(localStorage.getItem("fm_type"), localStorage.getItem("fm_k"), localStorage.getItem("fm_v"));
  }, [props?.match?.params?.rn]);

  useEffect(() => {
    $('#s_date1').val(localStorage.getItem("fm_s_date1"));
    $('#s_date2').val(localStorage.getItem("fm_s_date2"));
    load();
  }, [renew]);

  useEffect(() => {
    if (srchRenew > 0) {
      localStorage.setItem("fm_s_date1", $('#s_date1').val());
      localStorage.setItem("fm_s_date2", $('#s_date2').val());
      localStorage.setItem("fm_ddate", $('#ddate option:selected').val() || '');
      localStorage.setItem("fm_table_type", $('#table_type option:selected').val() || '');
      load();
    }
  }, [srchRenew]);

  const load = () => {
    C_id = 0;
    tMC = 0;

    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 8 && localStorage.getItem("USER.systemBranchCode") === "ALL" && $('#s_date1').val() !== $('#s_date2').val()) {
      if (!window.confirm("전체지점 기준으로 조회를 선택하셨습니다.\n진행하시겠습니까?")) return;
    }
    axios2
      // .post(`/finance/finance/monthly/ready?table_type=new&ddate=0&s_date1=2022-08-30&s_date2=2022-11-30`)
      .post(`/finance/finance/monthly/ready?table_type=${$('#table_type option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadDtl = (type, k, v) => {
    localStorage.setItem("fm_type", type);
    localStorage.setItem("fm_k", k);
    localStorage.setItem("fm_v", v);

    setType(type);
    setSubTitle((v || '계정') + ' 상세내역');
    let url = `/finance/finance/monthly?type=${type}&k=${k}&v=${encodeURIComponent(v)}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&table_type=${$('#table_type option:selected').val() || ''}`;
    if (type == "_NONE_") url = `/finance/finance/monthly?type=${type}&k=${k}&v=${encodeURIComponent(v)}&s_date1=2999-01-01&s_date2=2999-01-01&table_type=${$('#table_type option:selected').val() || ''}`;
    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResultDtl(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const loadModal = (mvType = 'A') => {
    setMvType(mvType);

    axios2
      .post(`/finance/finance/monthly/setting/ready?type=${mvType}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);

          setTimeout(() => {
            result?.result?.forEach((item) => {
              $('#mv_show_out_' + item?.count).val(item.show_out);
            })
          });

          if (mvType === 'A') {
            setTimeout(() => $('#aa').prop("checked", true), 100);
          } else {
            setTimeout(() => $('#bb').prop("checked", true), 100);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const modalSubmit = () => {
    if (mvType === 'A') {
      let showOuts = $('[id^=mv_show_out]').map(function () {
        return $(this).val();
      }).get().join(",");

      let jNos = $('[id^=mv_show_out]').map(function () {
        return $(this).attr('value2');
      }).get().join(",");
      console.log(jNos, showOuts);

      const formData = new FormData();

      formData.append("jNos[]", jNos);
      formData.append("showOuts[]", showOuts);

      axios2.postFormEx('/finance/finance/monthly/a', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === "err") {
            alert('오류가 발생하였습니다');
          } else {
            alert("설정되었습니다");
            setRenew(Fn.getRenewTime());
            setModalOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    } else {
      let showOuts = $('[id^=mv_show_out]').map(function () {
        return $(this).val();
      }).get().join(",");

      let accNos = $('[id^=mv_show_out]').map(function () {
        return $(this).attr('value2');
      }).get().join(",");

      const formData = new FormData();

      formData.append("accNos[]", accNos);
      formData.append("showOuts[]", showOuts);

      axios2.postFormEx('/finance/finance/monthly/b', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === "err") {
            alert('오류가 발생하였습니다');
          } else {
            alert("설정되었습니다");
            setRenew(Fn.getRenewTime());
            setModalOpen(false);
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  //1-1
  const n_form1 = document.getElementById('n_form1');

  const color_change = () => {
    const t = n_form1.C_id_count.value;
    for (let i = 1; i <= t; i++) {
      const detailElement = eval(`detail_${i}`);
      detailElement.style.background = "#FFFFFF";
    }

    const selectedDetailElement = eval(`detail_${C_id}`);
    selectedDetailElement.style.background = "#CCEEDD";
  };

//2-1
  const n_form2 = document.getElementById('n_form2');
  const J_id = document.getElementById('J_id');
  const j_color_change = () => {
    const t = n_form2.J_id_count.value;

    for (let i = 1; i <= t; i++) {
      const jDetailElement = eval(`J_detail_${i}`);
      jDetailElement.style.background = "#FFFFFF";
    }

    const selectedJDetailElement = eval(`J_detail_${J_id}`);
    selectedJDetailElement.style.background = "#CCEEDD";
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex">
        <div className="wid_fix">
          <div className="d-flex m-3">
            <div className="pr-1">
              <select className="form-select" id="table_type">
                <option value="new">최근</option>
                <option value="old">이전</option>
              </select>
            </div>
            <div className="pr-1">
              <select className="form-select" id="ddate" onChange={(e) => Fn.period(e.target.value)}>
                <option value="0">검색기간</option>
                <option value="1">오늘</option>
                <option value="2">이번달</option>
                <option value="3">전월</option>
                <option value="4">1분기</option>
                <option value="5">2분기</option>
                <option value="6">1,2분기</option>
                <option value="7">3분기</option>
                <option value="8">4분기</option>
                <option value="9">3,4분기</option>
                <option value="10">올해전체</option>
                <option value="11">전1년</option>
              </select>
            </div>
            <div className="d-flex pr-1">
              <JDatePicker className="form-control" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" id="s_date2"/>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
              <button type="button" className="btn btn-sm btn-dark" onClick={() => setModalOpen(true)}>순서설정</button>
            </div>
          </div>
          <div className="m-3 d-flex">
            <table className="rounded-corner-l sub_table_border text-left" style={{height: "fit-content"}}>
              <tr style={{cursor: 'pointer'}} onClick={() => loadDtl('in', 'all', '수입전체')}>
                <th colSpan="2">수입</th>
              </tr>
              <tr>
                <th>계정</th>
                <th>금액</th>
              </tr>
              {result?.aryMC && Object.keys(result?.aryMC).map((k, index) => {
                k = k.replace("_", "");
                const v = result?.aryMC?.["_" + k];
                C_id++;
                tMC += result?.mc['4113']?.[k] || 0;

                // TODO : PTODO => 환불이 맨 아래 내려와야 하는데, 중간에 위치하는 이슈
                return (
                  <tr
                    id={`detail_${C_id}`}
                    style={{cursor: "pointer"}}
                    onClick={(e) => {
                      //color_change(e.target);
                      loadDtl('in', k, v);
                    }}
                  >
                    <td className="txt_left">{v}</td>
                    <td className="text-right">
                      {result?.mc['4113-' + k] ? Fn.numberWithCommas(result?.mc['4113-' + k]) : ''}
                    </td>
                  </tr>
                );
              })}
              <tr style={{cursor: 'pointer'}} onClick={() => loadDtl('out', '7218', '타지점정산')}>
                <td>타지점정산</td>
                <td className="text-right">{result?.jgain8 ? Fn.numberWithCommas(result?.jgain8) : ''}</td>
              </tr>
              <tr style={{display: "none", cursor: 'pointer'}} onClick={() => loadDtl('out', '7216', '물품판매')}>
                <td>물품판매</td>
                <td className="text-right">{result?.jgain7 ? Fn.numberWithCommas(result?.jgain7) : ''}</td>
              </tr>
              <tr style={{cursor: 'pointer'}} onClick={() => loadDtl('out', '7214', '잡이익')}>
                <td>잡이익</td>
                <td className="text-right">{result?.jgain2 ? Fn.numberWithCommas(result?.jgain2) : ''}</td>
              </tr>
              <tr style={{cursor: 'pointer'}} onClick={() => loadDtl('in', '', '미등록')}>
                <td>미등록</td>
                <td className="text-right">{result?.etc ? Fn.numberWithCommas(result?.etc) : ''}</td>
              </tr>
              <tr className="bg_yellow1">
                <td>현장매출 합계</td>
                <td
                  className="text-right">{(result?.tMC - result?.jgain - result?.jgain3 - result?.jgain4 - result?.jgain5 - result?.jgain6 - result?.jgain7) > 0 ? Fn.numberWithCommas(result?.tMC - result?.jgain - result?.jgain3 - result?.jgain4 - result?.jgain5 - result?.jgain6 - result?.jgain7) : ""}</td>
              </tr>
              <tr style={{cursor: 'pointer'}} onClick={() => loadDtl('in', '245', '서비스상계')}>
                <td>무상서비스 상계</td>
                <td className="text-right">{result?.sang_price != 0 ? Fn.numberWithCommas(result?.sang_price) : ''}</td>
              </tr>
              {/*TODO : PTODO -> onclick 시 color_change 구현할 것*/}
              <tr className="bg_lightred">
                <td>무상서비스 상계 합계</td>
                <td
                  className="text-right">{
                  (result?.tMC) - (result?.jgain) - (result?.jgain3) - (result?.jgain4) - (result?.jgain5) - (result?.jgain6) - (result?.jgain7) + (Fn.parseInt(result?.sang_price)) != 0
                    ?
                    Fn.numberWithCommas((result?.tMC) - (result?.jgain) - (result?.jgain3) - (result?.jgain4) - (result?.jgain5) - (result?.jgain6) - (result?.jgain7) + (Fn.parseInt(result?.sang_price)))
                    :
                    ""}</td>
              </tr>
              <tr style={{cursor: 'pointer'}} onClick={() => loadDtl('out', '2511', '단기대여금')}>
                <td>단기대여금</td>
                <td className="text-right">{result?.jgain3 ? Fn.numberWithCommas(result?.jgain3) : ''}</td>
              </tr>
              <tr style={{cursor: 'pointer'}} onClick={() => loadDtl('out', '2221', '단기차입금')}>
                <td>단기차입금</td>
                <td className="text-right">{result?.jgain4 ? Fn.numberWithCommas(result?.jgain4) : ''}</td>
              </tr>
              <tr style={{cursor: 'pointer'}} onClick={() => loadDtl('out', '7211', '이자수익')}>
                <td>이자수익</td>
                <td className="text-right">{result?.jgain ? Fn.numberWithCommas(result?.jgain) : ''}</td>
              </tr>
              <tr style={{cursor: 'pointer'}} onClick={() => loadDtl('out', '3114', '출자금')}>
                <td>출자금</td>
                <td className="text-right">{result?.jgain5 ? Fn.numberWithCommas(result?.jgain5) : ''}</td>
              </tr>
              <tr style={{cursor: 'pointer'}} onClick={() => loadDtl('out', '7215', '임시수입')}>
                <td>임시보유자금</td>
                <td className="text-right">{result?.jgain6 ? Fn.numberWithCommas(result?.jgain6) : ''}</td>
              </tr>
              <tr className="bg_yellow1 bold700">
                <td className="text-center">미납(-)<br/>전월완납(+)</td>
                <td className="text-right"><span className="text-danger">{Fn.numberWithCommas(0 - result?.dd?.jan)}</span></td>
              </tr>
            </table>

            <table className="rounded-corner-r sub_table_border text-left" style={{height: "fit-content"}}>
              <tr style={{cursor: 'pointer'}} onClick={() => loadDtl('out', 'all', '지출전체')}>
                <th colSpan="2">지출</th>
              </tr>
              <tr>
                <th>계정</th>
                <th>금액</th>
              </tr>
              {result?.biyong && Object.keys(result?.biyong).map((k, index) => {
                const v = result?.biyong?.[k];
                k = k.replace("_", "");
                return (
                  <tr
                    id={`detail_${C_id}`}
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      //color_change(C_id);
                      loadDtl('out', k, v);
                    }}
                  >
                    <td className="txt_left">{v}</td>
                    <td className="text-right">
                      {result?.jichul?.[k + "-amt"] ? Fn.numberWithCommas(result?.jichul?.[k + "-amt"]) : ''}
                    </td>
                  </tr>
                );
              })}
            </table>

          </div>
          <div className="m-3">
            <table className="view-rounded sub_table_border">
              <tr className="bold700 bg_green1">
                <td>수입합계</td>
                <td className="text-right">{Fn.numberWithCommas(result?.tMC + Fn.parseInt(result?.sang_price))}</td>
                <td>지출합계</td>
                <td className="text-right">{Fn.numberWithCommas(result?.tBY)}</td>
              </tr>
              <tr className="bold700" style={{background: "#fff4fd"}}>
                <td>영업이익</td>
                <td colSpan="3" className="text-right">{Fn.numberWithCommas(result?.tMC - result?.tBY + Fn.parseInt(result?.sang_price))}</td>
              </tr>
            </table>
          </div>
        </div>

        <div style={{width: '825px'}}>
          <div className="tip_box tip_box2 w-100 my-3"><span className="p-3">Tip! 좌측 계정명을 click하시면 해당 상세 내역을 보실 수 있습니다</span></div>
          <div id="detail_area" style={{top: "190px", minWidth: "825px", position: "absolute"}}>
            {/*<div id="detail_area" style={{top: "190px", minWidth: "825px", position: "sticky"}}>*/}
            <p className="border-bottom p-2 px-3 bold700 text-left h7">{subTitle}</p>
            <div className="p-3" style={{overflowY: "auto", maxHeight: "750px"}}>
              <table className="view-rounded sub_table_border auto">
                <colgroup>
                  <col width="65px"/>
                  <col width="90px"/>
                  <col width="130px"/>
                  <col width="*"/>
                  <col width="110px"/>
                  {type === 'in' && <col width="200px"/>}
                </colgroup>

                <tr>
                  <th>No</th>
                  <th>일자</th>
                  <th>거래처</th>
                  <th>적요</th>
                  <th>금액</th>
                  {type === 'in' && <th>비고</th>}
                </tr>
                {type === 'in' ? resultDtl?.result?.map((row, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{row?.ck_date}</td>
                        <td>{row?.ck_sangho}</td>
                        <td className="text-left">{row?.ckk_etc ? row?.ckk_etc : resultDtl?.aryMC?.["_" + row?.ck_summary_no]}</td>
                        <td className="text-right">{Fn.numberWithCommas(row?.ck_amtdae)}</td>
                        <td className="text-left">{row?.ip_etc_txt}</td>
                      </tr>
                    )
                  }) :
                  resultDtl?.result?.map((row, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{row?.ck_date}</td>
                        <td>{row?.ck_sangho}</td>
                        <td className="text-left">{row?.ckk_etc ? row?.ckk_etc : row?.ck_acname}</td>
                        <td className="text-right">{row?.ck_amtcha ? Fn.numberWithCommas(row?.ck_amtcha) : Fn.numberWithCommas(row?.ck_amtdae)}</td>
                      </tr>
                    )
                  })
                }
                <tr className="bg_green1 bold700">
                  <td colSpan={4} className="text-center">합계</td>
                  <td className="text-right">{resultDtl.T_price}</td>
                  {type === 'in' && <td></td>}
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal('A');
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '500px'}}>
            <div className="POP_title">
              <h5>
                <strong>월계표 순서설정</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="m-0 mb-3" style={{clear: 'both'}}>
                <div className="tabs">
                  <input id="aa" type="radio" name="tab_item" onClick={() => loadModal('A')}/>
                  <label className="tab_item" htmlFor="aa">매출</label>

                  <input id="bb" type="radio" name="tab_item" onClick={() => loadModal('B')}/>
                  <label className="tab_item" htmlFor="bb">지출</label>
                </div>
              </div>

              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="*"/>
                  <col width="140px"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>분류명</th>
                  <th>우선순위</th>
                </tr>
                {modalResult && modalResult?.result?.map((row, i) =>
                  <tr>
                    <td className="text-center">{i + 1}</td>
                    <td className="text-center">{mvType === 'A' ? row?.j_jukname : row?.acc_name}</td>
                    <td className="text-center">
                      <input type="text" className="input_shorts" id={`mv_show_out_${i + 1}`} value2={mvType === 'A' ? row?.j_no : row?.acc_no}/>
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => modalSubmit()}>
                설정
              </button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
}

export default FinanceMonthly;
