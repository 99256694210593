import React, {useRef, useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import ChkAuthNum from "../common/ChkAuthNum";
import Modal from "react-modal";
import _ from "lodash";
import FinanceAccountEdit from "./FinanceAccountEdit";
import Constants from "../../constants/constants";

// TODO : API에 이슈가 있어, 원본 PHP와 값이 다르게 나오니 확인할 것
// TODO : 렌더링 시, 표시 이슈
const FinanceAccount = (props) => {

  window._load_finance = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [renew, setRenew] = useState(0);
  const [ipRenew, setIpRenew] = useState(0);
  const [rets, setRets] = useState([]);
  const [result2, setResult2] = useState({});
  const [result3, setResult3] = useState();
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [regAreaShow, setRegAreaShow] = useState(false);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [modal4Open, setModal4Open] = useState(false);
  const [isFAEOpen, setFAEOopen] = useState(false);
  const [numbering, setNumbering] = useState(5);

  const [form1, setForm1] = useState();
  const [hide, setHide] = useState();

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    $('#btn_acc_save').css('visibility', 'visible');

    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('.cls-main #s_date2').val(Fn.getCurrentDate());
    $('.cls-main #s_date1').val(Fn.getDate(monthsAgo));

    if ($('.cls-main #numbering').html() === '') {
      let optionsHTML = '';
      for (let i = 5; i <= 20; i++) {
        optionsHTML += `<option value="${i}">${i}건 입력</option>`;
      }
      $('.cls-main #numbering').html(optionsHTML);
    }
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('.cls-main [id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('.cls-main [id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    setForm1($(".cls-main form[name='form1']")[0]);
    setHide($(".cls-main form[name='hide']")[0]);

    const form1 = $(".cls-main form[name='form1']")[0];
    const hide = $(".cls-main form[name='hide']")[0];

    axios2
      .post(`/finance/finance/account/ready?ddate=${$('.cls-main #ddate option:selected').val() || ''}&s_date1=${$('.cls-main #s_date1').val()}&s_date2=${$('.cls-main #s_date2').val()}&cc=${$('.cls-main #cc option:selected').val() || ''}&sec=${$('.cls-main #sec option:selected').val() || ''}&num_per_page=${$('.cls-main #num_per_page option:selected').val() || ''}&search_text=${$('.cls-main #search_text').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.list);
          setPageInfo(pageInfo);

          let optionsHTML = '<option value="">::지점선택::</option>';
          result?.Top_list?.forEach((list_row) => {
            optionsHTML += `<option value="${list_row.branch_code}">${list_row.ho}/${list_row.branch_name}</option>`;
          });

          if ($('.cls-main #basic_branch_code').html() === '') {
            $('.cls-main #basic_branch_code').html(optionsHTML);
          }

          $('.cls-main .cls-branch-code').each(function () {
            $(this).empty();
            $(this).html(optionsHTML);
          });
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const change_numbering = () => {
    const numbering = Fn.parseInt($('.cls-main #numbering option:selected').val());
    setNumbering(numbering);

    for (let i = 1; i <= numbering; i++) {
      $(`.cls-main #detail${i}`).show();
    }

    const numbering2 = numbering + 1;
    for (let i = numbering2; i <= 20; i++) {
      $(`.cls-main #detail${i}`).hide();
    }
  };

  const same_date = () => {
    for (let i = 1; i <= 20; i++) {
      $(`.cls-main #ckk_date${i}`).val($('.cls-main #basic_date').val());
    }
  };

  const same_branch = () => {
    for (let i = 1; i <= 20; i++) {
      $(`.cls-main #branch_code${i}`).val($('.cls-main #basic_branch_code option:selected').val());
    }
  }

  const chk_branch = (i) => {
    const branchCode = $(`.cls-main #branch_code${i}`).val();

    if (!branchCode) {
      alert('지점을 먼저 선택해주세요');
      $(`.cls-main #ckk_kind${i}`).value = '';
    }
  };

  window.cls_tree = (gubun, cod, i) => {
    let form1 = $(".cls-main #form1")[0];
    let hide = $(".cls-main #hide")[0];

    const gaKind = $(`.cls-main #ckk_kind${i}`).val();
    const branchCode = $(`.cls-main #branch_code${i} option:selected`).val();

    if (!branchCode) return;

    if (gaKind === "") {
      var msg = "<input type='text' name='ckk_accjukyoo" + i + "' class='wid100' style='width:130px' placeholder='' onkeydown=\"window.cusor_move('ckk_accjukyoo','" + i + "',event.keyCode)\"/>";
      $(".cls-main #saccB_" + i).parent().html(msg);
    }

    if (gubun == "2") {
      cod = eval("form1.ckk_bank" + i).value;
    }

    hide.gubun.value = gubun;
    hide.cc.value = cod;
    hide.ii.value = i;

    if (gubun === "2") {
      hide.ga_kind.value = gaKind;
    } else {
      hide.ga_kind.value = gaKind;
    }

    var gaecode = eval("form1.ckk_acname" + i).value;
    hide.gaecode.value = gaecode;
    hide.branch_code.value = branchCode;

    var formData = new FormData(hide);
    $.ajax({
      url: '/finance/account/select/data',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        if (gubun == 1) {
          if (data?.result?.result2?.length > 0) {
            if (data?.result?.select2) {
              // $(`.cls-main #ckk_acname${i}`).parent().html(data?.result?.select2);
              $(`.cls-main #sacc5_${i}`).html(data?.result?.select2);
            }
          } else {
            $(`.cls-main #sacc_${i}`).html(msg);
            $(`.cls-main #ckk_acname${i}`)[0].length = 1;
          }

          if (data?.result?.result3?.length > 0) {
            if (data?.result?.select3) {
              // $(`.cls-main #ckk_bank${i}`).parent().html(data?.result?.select3);
              $(`.cls-main #sacc6_${i}`).html(data?.result?.select3);
            }
          } else {
            $(`.cls-main #ckk_bank${i}`)[0].length = 1;
          }
        }

        if (gubun == 5) {
          if (data?.result?.select1) {
            $(`.cls-main #saccB_${i}`).parent().html(data?.result?.select1);
            $(`.cls-main #sub_price_${i}`).html('');
          } else {
            $(`.cls-main #sub_price_${i}`).html(data?.result?.sub_price);
          }
        }

        if (gubun == 2) {
          if (data?.result?.select1) {
            $(`.cls-main #sacc7_${i}`).html(data?.result?.select1);
            if (data?.result?.display === 'inline')
              $(`.cls-main #card_fee_${i}`).show();
            else
              $(`.cls-main #card_fee_${i}`).hide();
          } else {
            $(`.cls-main #sacc7_${i}`).html(data?.result?.input);
          }
        }

        // TODO : PTODO : 반드시 ! 반드시 ! 수정할 것!
        if (gubun == 5 && cod == 1) {
          let msg = `
            <input name="ckk_accode${i}" type="text" size="1" class="form-control wid100 ac" maxLength="4" onclick="this.select();" style="background:#FFCCCC;width:35px;" ondblclick="ondouble_PopGJ(${i});" onchange="search_code_gj(${i});">
            <input name="ckk_acname${i}" type="text" size="5" maxLength="20" style="width:90px" readonly class="form-control wid100">
          `;
          $(`.cls-main #sacc5_${i}`).html(msg);
        }
      }
    });
  };

  //9-1
  const ondouble_PopGJ = () => {
    let i;
    const url = `etc_account.html?i=${i}`;
    window.pop_up(url, 'etc_account', '400', '600', 'yes');
  };

  //10-1
  const search_code_gj = () => {
    let i;
    const par = form1[`ckk_accode${i}`]?.value;

    if (par) {
      window.location.href = `chk_etc_account.php?i=${i}&par=${par}`;
    }
  };

  let is_processing = false;

  //10-1
  const go_submit = () => {
    if (is_processing) return;
    is_processing = false;

    if (!$('.cls-main #auth_code').val()) {
      alert('인증코드를 입력해주세요');
      $('.cls-main #search_auth_code').focus();
      return;
    }

    var j = 1;
    const bobj0_1 = form1[`branch_code${j}`];
    if (!bobj0_1.value) {
      alert('지점을 선택해주세요');
      bobj0_1.focus();
      return;
    }

    const obj0_1 = form1[`ckk_kind${j}`];
    if (!obj0_1.value) {
      alert('분류를 선택해주세요');
      obj0_1.focus();
      return;
    }

    var numbering = form1.numbering.value;
    for (let i = 1; i <= numbering; i++) {
      const obj0 = form1[`ckk_kind${i}`];
      const obj3 = form1[`ckk_acname${i}`];
      const obj1 = form1[`ckk_bank${i}`];
      const obj2 = form1[`ckk_amt${i}`];
      const obj4 = form1[`ckk_date${i}`];
      const obj5 = form1[`ckk_summary${i}`];

      var val_crdr = obj0.options[obj0.selectedIndex].value;
      var val_amt = obj2.value;
      if (val_amt === '0') {
        val_amt = '';
      }
      if ((val_crdr === '0' || val_crdr === '') && (obj1.value === '') && (val_amt === '')) {

      } else {
        if (val_crdr !== '0') {
          if (obj4.value === '') {
            alert('일자를 입력해주세요');
            obj4.focus();
            return false;
          }

          if (obj3.value === '') {
            alert('계정코드를 선택해주세요');
            obj3.focus();
            return false;
          }

          if (obj1.value === '') {
            alert('계좌코드를 선택해주세요');
            obj1.focus();
            return false;
          }

          if (obj1.value !== '1111/현금') {
            if (obj5.value === '') {
              alert('계좌적요를 선택해주세요');
              obj5.focus();
              return false;
            }
          }

          if (val_amt === '') {
            alert('금액을 입력해주세요');
            obj2.focus();
            return false;
          }
        }
      }
    }

    if (window.confirm('입력한 간편장부를 저장하시겠습니까?')) {
      const form = $('.cls-main #form1')[0];
      const formData = new FormData(form);

      $('#btn_acc_save').css('visibility', 'hidden');
      is_processing = true;
      $.ajax({
        url: '/finance/finance/account',
        processData: false,
        contentType: false,
        data: formData,
        type: 'POST',
        success: function (data) {
          $('.cls_ip_card_fee').hide();
          setIpRenew(Fn.getRenewTime());

          alert("저장되었습니다.");

          setRenew(Fn.getRenewTime());

          setTimeout(() => {
            _.range(1, 20 + 1).map(i => {
              $('#ckk_date' + i).val(Fn.getCurrentDate());
              $('#branch_code' + i).val('');
            });
          }, 300);
        },
        complete: function () {
          is_processing = false;
          $('#btn_acc_save').css('visibility', 'visible');
        }
      });
    }
  };

  const del_account = () => {
    if (!$('.cls-main #auth_code').val()) {
      alert('인증코드를 입력해주세요');
      setRegAreaShow(true);
      $('.cls-main #auth_code').focus();
      return;
    }

    let con = $('.cls-main [id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 간편장부를 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('.cls-main [id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);
      formData.append("auth_code", $('.cls-main #auth_code').val());

      axios2.postFormEx('/finance/account/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  const next_focus = (cnt) => {
    let i = eval(cnt) + eval('1');
    $(`.cls-main #detail${i}`).style.display = 'table-row';

    let before_cnt = $('.cls-main #numbering').val();

    if (i > before_cnt) {
      $('.cls-main #numbering').val(i);
    }

    $('.cls-main #ckk_date' + i).focus();
  };

  window.cusor_move = (field_type, hang, key_value) => {
    let down_hang = Fn.parseInt(hang) + 1;
    let up_hang = Fn.parseInt(hang) - 1;
    if (up_hang < 1) up_hang = 1;

    const focusElement = (fieldName) => {
      let obj = document.getElementsByName(fieldName);
      obj[0].focus();
    };

    if (field_type === 'ckk_etc') {
      if (key_value == '40') {
        focusElement('ckk_etc' + down_hang);
      }
      if (key_value == '38') {
        focusElement('ckk_etc' + up_hang);
      }
      if (key_value == '37') {
        focusElement('ckk_amt' + hang);
      }
    }
    if (field_type === 'ckk_amt') {
      if (key_value == '40') {
        focusElement('ckk_amt' + down_hang);
      }
      if (key_value == '38') {
        focusElement('ckk_amt' + up_hang);
      }
      if (key_value == '39') {
        focusElement('ckk_etc' + hang);
      }
      if (key_value == '37') {
        focusElement('ckk_summary' + hang);
      }
    }
    if (field_type === 'ckk_summary') {
      if (key_value == '40') {
        focusElement('ckk_summary' + down_hang);
      }
      if (key_value == '38') {
        focusElement('ckk_summary' + up_hang);
      }
      if (key_value == '39') {
        focusElement('ckk_amt' + hang);
      }
      if (key_value == '37') {
        focusElement('ckk_bank' + hang);
      }
    }
    if (field_type === 'ckk_bank') {
      if (key_value == '39') {
        focusElement('ckk_summary' + hang);
      }
      if (key_value == '37') {
        focusElement('ckk_accjukyoo' + hang);
      }
    }
    if (field_type === 'ckk_accjukyoo') {
      if (key_value == '39') {
        focusElement('ckk_bank' + hang);
      }
      if (key_value == '37') {
        focusElement('ckk_acname' + hang);
      }
    }
    if (field_type === 'ckk_acname') {
      if (key_value == '39') {
        focusElement('ckk_accjukyoo' + hang);
      }
      if (key_value == '37') {
        focusElement('ckk_kind' + hang);
      }
    }
    if (field_type === 'ckk_kind') {
      if (key_value == '39') {
        focusElement('ckk_acname' + hang);
      }
      if (key_value == '37') {
        focusElement('branch_code' + hang);
      }
    }
    if (field_type === 'ckk_date') {
      if (key_value == '40') {
        focusElement('ckk_date' + down_hang);
      }
      if (key_value == '38') {
        focusElement('ckk_date' + up_hang);
      }
      if (key_value == '39') {
        focusElement('branch_code' + hang);
      }
    }
    if (field_type === 'branch_code') {
      if (key_value == '39') {
        focusElement('ckk_kind' + hang);
      }
      if (key_value == '37') {
        focusElement('ckk_date' + hang);
      }
    }
    if (field_type === 'card_fee_price') {
      if (key_value == '40') {
        focusElement('card_fee_price' + down_hang);
      }
      if (key_value == '38') {
        focusElement('card_fee_price' + up_hang);
      }
      if (key_value == '39') {
        focusElement('ckk_etc' + hang);
      }
      if (key_value == '37') {
        focusElement('ckk_summary' + hang);
      }
    }
  };

  const reset_data = (i) => {
    // 분류 초기화
    $(`.cls-main #ckk_kind${i}`).val('');
    // 계정 초기화
    $(`.cls-main #ckk_acname${i}`).val("");
    $(`.cls-main #sub_price_${i}`).html("");
  };

  const change_date = () => {
    if (!$('.cls-main #auth_code').val()) {
      alert('인증코드를 입력해주세요');
      setRegAreaShow(true);
      $('.cls-main #search_auth_code').focus();
      return;
    }

    let con = $('.cls-main [id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    $('.cls-main #mv_all_ckk_no').val(con);

    setModal4Open(true);
  };

  const modal5_submit = () => {
    axios2
      .post(`/finance/finance/date/change?ckk_date=${$('#mv5_ckk_date').val() || ''}&all_ckk_no=${$('#mv_all_ckk_no').val() || ''}`)
      .then((response) => {
        const {code, message: data, result, pageInfo} = response?.data;
        if (code === 200) {
          if (data == "err") {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          } else if (data == "err2") {
            alert('오류데이터가 존재합니다. 시스템 관리자에게 문의하세요');
          } else {
            alert('변경되었습니다');
            setRenew(Fn.getRenewTime());
            setModal4Open(false);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const all_chk_branch = () => {
    // 첫번째 분류 체크
    const i = 1;
    const vv = document.getElementById(`ckk_kind${i}`).value;

    if (vv === "") {
      alert("첫번째 분류를 선택해주세요");
      return;
    }

    const numbering = form1.numbering.value;

    for (let k = 2; k <= 20; k++) {
      setTimeout(() => {
        eval(`form1.ckk_kind${k}`).value = vv;
        window.cls_tree('1', vv, k);
      }, (k - 1) * 1000);
    }
  };

  //17-1

  const all_chk_2 = () => {
    // 첫번째 계정 체크
    const i = 1;
    const vv = document.getElementById(`ckk_acname${i}`).value;

    if (vv === "") {
      alert("첫번째 계정을 선택해주세요");
      return;
    }

    for (let k = 2; k <= 20; k++) {
      setTimeout(() => {
        document.getElementById(`ckk_acname${k}`).value = vv;
        //eval(`form1.ckk_acname${k}`).value = vv;
        window.cls_tree('5', vv, k);
      }, (k - 1) * 1000);
    }
  };

  //18-1
  const all_chk_3 = () => {
    // 첫번째 계좌 체크
    const i = 1;
    const vv = document.getElementById(`ckk_bank${i}`).value;

    if (vv === "") {
      alert("첫번째 계좌를 선택해주세요");
      return;
    }

    for (let k = 2; k <= 20; k++) {
      setTimeout(() => {
        //eval(`form1.ckk_bank${k}`).value = vv;
        document.getElementById(`ckk_bank${k}`).value = vv;
        window.cls_tree('2', vv, k);
      }, (k - 1) * 1000);
    }
  };

  //--------------------------------------------------------
  // JTNET 정산등록 S
  //--------------------------------------------------------

  const [m2BranchCode, setM2BranchCode] = useState();
  const [uKey, setUKey] = useState();
  const [MID, setMID] = useState();
  const [renew2, setRenew2] = useState(0);
  const [jtnform, setJtnform] = useState();

  useEffect(() => {
    if (modal2Open)
      loadModal2();
  }, [renew2]);

  const loadModal2 = (_uKey, _MID) => {
    setJtnform($(".cls-main form[name='jtnform']")[0]);
    const jtnform = $(".cls-main form[name='jtnform']")[0];

    axios2
      .post(`/finance/jtnet/in/ready?branch_code=${$('#jtnet_branch_code option:selected').val() || ''}&MID=${_MID || (MID || '')}&u_key=${_uKey || (uKey || '')}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult2(result);

          if ($('#jtnet_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점선택::</option>';
            result?.Top_list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.ho}/${list_row.branch_name}</option>`;
            });
            $('#jtnet_branch_code').html(optionsHTML);
          }

          $('#jtn_u_key').val(_uKey || (uKey || ''));
          $('#jtn_branch_code').val($('#jtnet_branch_code option:selected').val() || '');
          $('#jtn_MID').val(_MID || (MID || ''));
          $('#jtn_basic_date').val($('#jtnet_basic_date').val());
          $('#jtn_new_basic_date').val(result?.list?.[0]?.basic_date);
        }
      })
      .catch((error) => {
        console.error(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function get_data() {
    if ($('#jtnet_basic_date').val() == "") {
      alert("정산일을 입력해주세요");
      $('#jtnform [name=branch_code]').val("");
      $('#jtnet_basic_date').focus();
      return;
    }

    var basic_date = $('.cls-main #jtnet_basic_date').val();
    var branch_code = $('#jtnet_branch_code option:selected').val();

    //오늘자 자료는 오후 2시 이후
    /*
    if (basic_date == Fn.getCurrentDate()) {
      if ("<?=date('H')?>" < "14") {
        //alert('오늘자 정산자료는 오후2시 이후 가능합니다');
        //return;
      }
    }
    */

    //내일은 안됨
    var formData = new FormData(jtnform);
    $.ajax({
      url: '/finance/jtnet/get/data/t?jtnet_basic_date=' + $('#jtnet_basic_date').val() + '&jtnet_branch_code=' + $('#jtnet_branch_code option:selected').val() + '&jtnet_MID=' + $('#jtnet_MID').val(),
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data?.message?.trim();
        let res_data = data.split("||");
        let code = res_data[0];
        basic_date = res_data[1];
        branch_code = res_data[2];
        let u_key = res_data[3];
        let MID = res_data[4];

        if ($('#jtnet_MID').val() === '') {
          loadModal2('_NONE_', "_NONE_");
        } else {
          if ($('#jtnet_branch_code option:selected').val() && code == "no_key") {
            alert('해당 지점의 MID 혹은 JTnet 서명키가 없습니다');
          } else if (code == "ok") {
            setUKey(u_key);
            setMID(MID);
            loadModal2(u_key, MID);
          }
        }
      }
    });
  }

  function go_submit2() {
    const form1 = $('#jtnform')[0];

    if (form1.bank_code.value == "") {
      alert("입금계좌를 선택해주세요");
      return;
    }
    if ($('#jtnet_auth_code').val() == "") {
      alert("인증번호를 입력해주세요");
      return;
    }
    if (form1.new_basic_date.value == "") {
      alert("데이터를 다시 불러와주세요");
      return;
    }

    form1.auth_code.value = $('#jtnet_auth_code').val();
    if (form1.branch_code.value.includes("|"))
      form1.branch_code.value = form1.branch_code?.value?.split("|")?.[0];

    if (window.confirm('입금 및 수수료를 등록하시겠습니까?')) {
      var formData = new FormData(form1);
      $.ajax({
        url: '/finance/jtnet/in/t',
        processData: false,
        contentType: false,
        data: formData,
        type: 'POST',
        success: function (data) {
          data = data?.message?.trim();
          if (data == "ok") {
            alert('등록되었습니다');
            setRenew(Fn.getRenewTime());
            //setModal2Open(false);
          } else {
            alert('등록오류! 관리자에게 문의하세요');
          }
        }
      });
    }
  }

  function get_data_pre() {
    if ($('#jtnet_basic_date').val() == "") {
      alert("정산일을 입력해주세요");
      $('#jtnform [name=branch_code]').val("");
      $('#jtnet_basic_date').focus();
      return;
    }

    loadModal2();
  }

  //--------------------------------------------------------
  // JTNET 정산등록 E
  //--------------------------------------------------------


  //--------------------------------------------------------
  // NICEPAY 정산등록 S
  //--------------------------------------------------------

  const [m3BranchCode, setM3BranchCode] = useState();
  const [nuKey, setNUKey] = useState();
  const [nMID, setNMID] = useState();
  const [renew3, setRenew3] = useState(0);
  const [npayform, setNpayform] = useState();

  useEffect(() => {
    if (modal3Open)
      loadModal3();
  }, [renew3]);

  const loadModal3 = (MID, uKey) => {
    setNpayform($(".cls-main form[name='npayform']")[0]);
    const npayform = $(".cls-main form[name='npayform']")[0];

    axios2
      .post(`/finance/nicepay/in/ready?branch_code=${$('#npay_branch_code option:selected').val()?.split("|")?.[0] || ''}&MID=${MID || result3?.bbRow?.MID}&u_key=${uKey || result3?.topList?.filter(item => item.branch_code === $('#npay_branch_code option:selected').val())?.[0]?.sign_key || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult3(result);

          console.log(result);

          if ($('#npay_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점선택::</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}|${list_row.MID}|${list_row.sign_key}">${list_row.ho}/${list_row.branch_name}</option>`;
            });
            $('#npay_branch_code').html(optionsHTML);
          }

          $('#np_u_key').val(uKey || '');
          $('#np_branch_code').val($('#npay_branch_code option:selected').val() || '');
          $('#np_MID').val(MID || '');
          $('#np_basic_date').val($('#npay_basic_date').val());
          $('#np_new_basic_date').val(result?.list?.[0]?.basic_date);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function npay_get_data(MID, uKey, _MID) {
    if ($('#npay_basic_date').val() == "") {
      alert("정산일을 입력해주세요");
      $('#npayform [name=branch_code]').val("");
      $('#npay_basic_date').focus();
      return;
    }

    var basic_date = $('.cls-main #npay_basic_date').val();
    var branch_code = $('#npay_branch_code option:selected').val().split("|")?.[0];

    //오늘자 자료는 오후 2시 이후
    /*
    if (basic_date == Fn.getCurrentDate()) {
      if ("<?=date('H')?>" < "14") {
        //alert('오늘자 정산자료는 오후2시 이후 가능합니다');
        //return;
      }
    }
    */

    //내일은 안됨
    var formData = new FormData(npayform);
    $.ajax({
      url: '/finance/nicepay/get/date?npay_basic_date=' + $('#npay_basic_date').val() + '&npay_branch_code=' + branch_code + '&npay_MID=' + (_MID || '') + "&npay_permit_YN=Y&npay_sign_key=" + (uKey || ''),
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data?.message?.trim();
        let res_data = data.split("||");
        let code = res_data[0];
        basic_date = res_data[1];
        branch_code = res_data[2];
        let u_key = res_data[3];
        let MID = res_data[4];

        if ((_MID || (MID || '')) === '') {
          loadModal3('_NONE_', "_NONE_");
        } else {
          if (code == "no_key") {
            alert('해당 지점의 MID 혹은 JTnet 서명키가 없습니다');
          } else if (code == "ok") {
            setUKey(u_key);
            setMID(MID);
            setM3BranchCode('');
            loadModal3(MID, u_key);
          }
        }
      }
    });
  }

  function go_submit3() {
    const form1 = $('#npayform')[0];

    if (form1.bank_code.value == "") {
      alert("입금계좌를 선택해주세요");
      return;
    }
    if ($('#npay_auth_code').val() == "") {
      alert("인증번호를 입력해주세요");
      return;
    }
    if (form1.new_basic_date.value == "") {
      alert("데이터를 다시 불러와주세요");
      return;
    }

    form1.auth_code.value = $('#npay_auth_code').val();
    if (form1.branch_code.value.includes("|"))
      form1.branch_code.value = form1.branch_code?.value?.split("|")?.[0];

    if (window.confirm('입금 및 수수료를 등록하시겠습니까?')) {
      var formData = new FormData(form1);
      $.ajax({
        url: '/finance/nicepay/in',
        processData: false,
        contentType: false,
        data: formData,
        type: 'POST',
        success: function (data) {
          data = data?.message?.trim();
          if (data == "ok") {
            alert('등록되었습니다');
            setRenew(Fn.getRenewTime());
            //setModal3Open(false);
          } else {
            alert('등록오류! 관리자에게 문의하세요');
          }
        }
      });
    }
  }

  //--------------------------------------------------------
  // NICEPAY 정산등록 E
  //--------------------------------------------------------

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="cls-main">
        <form name="form1" id="form1">
          <div className="m-3 mb-5" style={{border: "2px solid #ccc", borderRadius: "12px", display: regAreaShow ? "block" : "none"}}>
            <div className="d-flex justify-content-between border-bottom px-3 p-2">
              <div className="mt-1"><span className="h6 bold700">간편장부 입력</span></div>

              <div className="d-flex">
                <div className="d-flex mx-1">
                  <ChkAuthNum type={"locker_info_change"}/>
                </div>
                <select className="form-select" id="numbering" name="numbering" onChange={() => change_numbering()}>
                </select>
                <button type="button" id="btn_acc_save" className="btn btn-sm btn-point ml-1 mr-5" onClick={() => go_submit()}>간편장부저장</button>
                <button type="button" className="btn btn-sm btn-dark" onClick={() => setRegAreaShow(false)}>닫기</button>
              </div>
            </div>

            <div className="p-3">
              <div className="d-flex mb-2">
                <div className="d-flex">
                  <JDatePicker className="form-control table-inline" id="basic_date"/>
                  <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => same_date()}>거래날짜일괄변경</button>
                </div>
                <div className="d-flex mx-2">
                  <select className="form-select table-inline cls-branch-code" id='basic_branch_code'>
                  </select>
                  <button type="button" className="btn btn-ssm btn-dark mx-1" onClick={() => same_branch()}>지점일괄변경</button>
                </div>
              </div>

              <table className="view-rounded sub_table_border text-center auto" key={ipRenew}>
                <colgroup>
                  <col width="50px"/>
                  <col width="130px"/>
                  <col width="190px"/>
                  <col width="110px"/>
                  <col width="190px"/>
                  <col width="150px"/>
                  <col width="125px"/>
                  <col width="160px"/>
                  <col width="135px"/>
                  <col width="*"/>
                </colgroup>

                <tr>
                  <th className="w_70px">No</th>
                  <th>일자</th>
                  <th>지점</th>
                  <th>분류 <a style={{cursor: "pointer"}} onClick={() => all_chk_branch()}>▼</a></th>
                  <th>계정 <a style={{cursor: "pointer"}} onClick={() => all_chk_2()}>▼</a></th>
                  <th>계정적요</th>
                  <th>계좌 <a style={{cursor: "pointer"}} onClick={() => all_chk_3()}>▼</a></th>
                  <th>계좌적요</th>
                  <th>금액</th>
                  <th>메모</th>
                </tr>
                {_.range(1, 20 + 1).map(i =>
                  <tr key={i} style={{display: i > numbering ? "none" : ""}}>
                    <td>{i}</td>
                    <td>
                      <JDatePicker className="form-control table-inline"
                                   name={`ckk_date${i}`}
                                   id={`ckk_date${i}`}
                                   onKeyDown={(e) => window.cusor_move('ckk_date', i, e.keyCode)}
                      />
                    </td>
                    <td>
                      <select className="form-select cls-branch-code"
                              name={`branch_code${i}`}
                              id={`branch_code${i}`}
                              onChange={(e) => {
                                reset_data(i);
                              }}
                              onKeyDown={(e) => window.cusor_move('ckk_kind', i, e.keyCode)}
                      >
                      </select>
                    </td>
                    <td>
                      <span className="form-inline" id={`sacc3_${i}`}>
                        <select className="form-select"
                                name={`ckk_kind${i}`}
                                id={`ckk_kind${i}`}
                                onChange={(e) => {
                                  chk_branch(i);
                                  window.cls_tree('1', e.target.value, i);
                                }}
                                onKeyDown={(e) => window.cusor_move('ckk_kind', i, e.keyCode)}
                        >
                          <option value="">선택</option>
                          <option className="text-success" value="G">대체</option>
                          <option className="text-primary" value="A">비용</option>
                          <option className="text-primary" value="B">급여</option>
                          <option className="text-danger" value="C">입금</option>
                          <option className="text-danger" value="E">매출</option>
                        </select>
                      </span>
                    </td>
                    <td>
                      <span className="form-inline" id={`sacc5_${i}`}>
                        <select className="form-select"
                                name={`ckk_acname${i}`}
                                id={`ckk_acname${i}`}
                                onChange={(e) => {
                                  window.cls_tree('5', e.target.value, i);
                                }}
                                onKeyDown={(e) => window.cusor_move('ckk_acname', i, e.keyCode)}
                        >
                        </select>
                      </span>
                    </td>
                    <td>
                      <span className="form-inline" id={`saccB_${i}`}>
                        <input type="text" className="form-control"
                               name={`ckk_accjukyoo${i}`}
                               id={`ckk_accjukyoo${i}`}
                               onKeyDown={(e) => window.cusor_move('ckk_accjukyoo', i, e.keyCode)}
                               maxLength={10}
                        />
                      </span>
                    </td>
                    <td>
                      <span className="form-inline" id={`sacc6_${i}`} onChange={(e) => window.cls_tree('2', e.target.value, i)}>
                        <select className="form-select"
                                name={`ckk_bank${i}`}
                                id={`ckk_bank${i}`}
                          // onChange={(e) => {
                          //   cls_tree('5', e.target.value, i);
                          // }}
                                onKeyDown={(e) => window.cusor_move('ckk_bank', i, e.keyCode)}
                        >
                        </select>
                      </span>
                    </td>


                    <td>
                      <span id={`sacc7_${i}`}>
                        <input
                          type="text"
                          name={`ckk_summary${i}`}
                          className="wid100 form-control"
                          placeholder=""
                          style={{width: '130px'}}
                          onKeyDown={(e) => window.cusor_move('ckk_summary', i, e.keyCode)}
                          maxLength="10"
                        />
                      </span>
                    </td>
                    <td>
                      <input
                        type="text"
                        name={`ckk_amt${i}`}
                        className="wid100 price_input form-control"
                        placeholder=""
                        style={{width: '85px', imeMode: 'disabled'}}
                        onKeyPress={(e) => {
                          return Fn.numkeyCheck(e);
                        }}
                        onKeyUp={(e) => Fn.vComma(e.target)}
                        onKeyDown={(e) => window.cusor_move('ckk_amt', i, e.keyCode)}
                      />
                      <span id={`card_fee_${i}`} className="cls_ip_card_fee" style={{display: 'none'}}>
                        <br/>
                        <input
                          type="text"
                          name={`card_fee_price${i}`}
                          className="wid100 price_input form-control"
                          placeholder="카드수수료"
                          style={{width: '85px', imeMode: 'disabled'}}
                          onKeyPress={(e) => {
                            return Fn.numkeyCheck(e);
                          }}
                          onKeyUp={(e) => Fn.vComma(e.target)}
                          onKeyDown={(e) => window.cusor_move('card_fee_price', i, e.keyCode)}
                        />
                      </span>
                    </td>

                    {/*<td>*/}
                    {/*  <input type="text" className="form-control"*/}
                    {/*         name={`ckk_amt${i}`}*/}
                    {/*         id={`ckk_amt${i}`}*/}
                    {/*         onKeyPress={(e) => {*/}
                    {/*           return Fn.numkeyCheck(e);*/}
                    {/*         }}*/}
                    {/*         onKeyUp={(e) => Fn.vComma(e.target)}*/}
                    {/*         onKeyDown={(e) => window.cusor_move('ckk_amt', i, e.keyCode)}*/}
                    {/*  />*/}
                    {/*</td>*/}
                    {/*<td>*/}
                    {/*  <input type="text" className="form-control"*/}
                    {/*         name={`card_fee_price${i}`}*/}
                    {/*         id={`card_fee_price${i}`}*/}
                    {/*         onKeyPress={(e) => {*/}
                    {/*           return Fn.numkeyCheck(e);*/}
                    {/*         }}*/}
                    {/*         onKeyUp={(e) => Fn.vComma(e.target)}*/}
                    {/*         onKeyDown={(e) => window.cusor_move('card_fee_price', i, e.keyCode)}*/}
                    {/*         placeholder={"카드수수료"}*/}
                    {/*  />*/}
                    {/*</td>*/}
                    <td>
                      <input type="text" className="form-control"
                             name={`ckk_etc${i}`}
                             id={`ckk_etc${i}`}
                             onKeyDown={(e) => window.cusor_move('ckk_etc', i, e.keyCode)}

                      />
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </form>

        <div className="d-flex m-3 justify-content-between flex-wrap">
          <div className="my-1 d-flex flex-nowrap fn_search_box">
            <div className="pr-1">
              <select className="form-select" id="ddate" onChange={(e) => Fn.period(e.target.value)}>
                <option value="0">검색기간</option>
                <option value="1">오늘</option>
                <option value="2">이번달</option>
                <option value="3">전월</option>
                <option value="4">1분기</option>
                <option value="5">2분기</option>
                <option value="6">1,2분기</option>
                <option value="7">3분기</option>
                <option value="8">4분기</option>
                <option value="9">3,4분기</option>
                <option value="10">올해전체</option>
                <option value="11">전1년</option>
              </select>
            </div>

            <div className="input-group pr-1" style={{width: "auto"}}>
              <JDatePicker className="form-control" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" id="s_date2"/>
            </div>

            <div className="pr-1">
              <span className="p-1">분류 :</span>
              <select className="form-select" id="cc">
                <option value="" selected>전체</option>
                <option value="G" className="text-success">대체</option>
                <option value="A" className="text-primary">비용</option>
                <option value="B" className="text-primary">급여</option>
                <option value="C" className="text-danger">입금</option>
                <option value="E">매출</option>
              </select>
            </div>

            <div className="pr-1">
              <span className="p-1">검색항목 :</span>
              <select className="form-select" id="sec">
                <option value="ckk_sangho">상호</option>
                <option value="ckk_cust*">*상호(코드)</option>
                <option value="ckk_acname">계정</option>
                <option value="ckk_accode*">계정C</option>
                <option value="ckk_accjukyoo">적요</option>
                <option value="ckk_bankname">계좌</option>
                <option value="ckk_bankcode*">계좌C</option>
                <option value="ckk_summary">계좌적요</option>
                <option value="ckk_etc">비고</option>
                <option value="ckk_step">작성자</option>
                <option value="ckk_amt*">*금액</option>
                <option value="ckk_no">코드</option>
              </select>
            </div>

            <div className="pr-1">
              <span className="p-1">리스트수 :</span>
              <select className="form-select" id="num_per_page">
                <option value="20">20개</option>
                <option value="50">50개</option>
                <option value="100">100개</option>
                <option value="200">200개</option>
                <option value="300">300개</option>
              </select>
            </div>

            <div className="pr-1">
              <input type="text" className="form-control" id="search_text"/>
            </div>

            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
          </div>

          <div className="my-1">
            <button type="button" className="btn btn-sm btn-dark" onClick={() => {
              _.range(1, 20 + 1).map(i => {
                if (!$('#ckk_date' + i).val())
                  $('#ckk_date' + i).val(Fn.getCurrentDate());
              });
              setRegAreaShow(true)
            }}>간편장부 등록
            </button>
            <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => setModal2Open(true)}>JTnet 정산등록</button>
            <button type="button" className="btn btn-sm btn-dark" onClick={() => setModal3Open(true)}>NicePay 정산등록</button>
          </div>
        </div>

        <div className="m-3 pb-2">
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="50px"/>
              <col width="110px"/>
              <col width="150px"/>
              <col width="140px"/>
              <col width="80px"/>
              <col width="150px"/>
              <col width="240px"/>
              <col width="200px"/>
              <col width="*"/>
              <col width="130px"/>
              <col width="80px"/>
            </colgroup>
            <tr>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('.cls-main [id^=achk]').prop("checked", true);
                  else $('.cls-main [id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th style={{width: "110px"}}>No</th>
              <th>일자</th>
              <th>지점명</th>
              <th>분류</th>
              <th>거래처(회원)</th>
              <th>계정</th>
              <th>계좌</th>
              <th>메모</th>
              <th>금액</th>
              <th>등록자</th>
            </tr>

            {
              rets?.map((row, i) => {
                const count = i + 1;

                const ckk_no = row.ckk_no;
                const branch_name = row.branch_name;
                const ckk_date = row.ckk_date;
                const ckk_kind = row.ckk_kind;
                const ckk_cust = row.ckk_cust;
                const ckk_sangho = row.ckk_sangho;
                const ckk_accode = row.ckk_accode;
                const ckk_acname = row.ckk_acname;
                const ckk_accjukyoo = row.ckk_accjukyoo;
                const ckk_bankcode = row.ckk_bankcode;
                const ckk_bankname = row.ckk_bankname;
                const ckk_summary = row.ckk_summary;
                const ckk_etc = row.ckk_etc;
                const ckk_amt = row.ckk_amt;
                const ckk_step = row.ckk_step;
                const ckk_ip_no = row.ckk_ip_no;
                const ckk_ap_no = row.ckk_ap_no;

                const base_ckk_no = btoa(ckk_no);

                return (
                  <tr key={i}>
                    {(ckk_kind === "E" || ckk_ip_no || ckk_ap_no) ? (
                      <td height="30">&nbsp;</td>
                    ) : (
                      <td>
                        <input
                          type="checkbox"
                          name={`chk[${count}]`}
                          value={ckk_no}
                          id={`achk${count}`}
                          className="checkbox"
                        />
                        <label htmlFor={`achk${count}`}>
                          <span></span>
                        </label>
                      </td>
                    )}

                    {ckk_kind === "E" || ckk_ip_no || ckk_ap_no ? (
                      <td>
                        {localStorage.getItem("USER.systemID") === 'futureinfo' && <div style={{fontSize: "11px"}}>{row?.ckk_no}<br/></div>}
                        <strong>
                          <a onClick={() => alert("고객 매출/입금건에 대해서는 수정할 수 없습니다.")}>
                            {/*<a onClick={() => setFAEOopen(true)}>*/}
                            {pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}
                          </a>
                        </strong>
                      </td>
                    ) : (
                      <td>
                        {localStorage.getItem("USER.systemID") === 'futureinfo' && <div style={{fontSize: "11px"}}>{row?.ckk_no}<br/></div>}
                        <strong>
                          <a onClick={() => {
                            $('#edit_base_ckk_no').val(base_ckk_no);
                            setFAEOopen(true);
                          }} style={{cursor: "pointer"}}>
                            {pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}
                          </a>
                        </strong>
                      </td>
                    )}

                    <td>{ckk_date} {row?.ckk_time}</td>
                    <td>{branch_name}</td>

                    {ckk_kind === "G" ? (
                      <td>
                        <span className="text-success">대체</span>
                      </td>
                    ) : null}
                    {ckk_kind === "A" ? (
                      <td>
                        <span className="text-primary">비용</span>
                      </td>
                    ) : null}
                    {ckk_kind === "B" ? (
                      <td>
                        <span className="text-primary">급여</span>
                      </td>
                    ) : null}
                    {ckk_kind === "C" ? (
                      <td>
                        <span className="text-danger">입금</span>
                      </td>
                    ) : null}
                    {ckk_kind === "E" ? (
                      <td>
                        <span className="text-danger">매출</span>
                      </td>
                    ) : null}

                    <td className="text-left">
                      {ckk_cust ? <span className="label label-primary">{ckk_cust}</span> : null} {ckk_sangho}
                    </td>

                    <td className="text-left">
                      {ckk_accode ? <span className="label label-primary">{ckk_accode}</span> : null} {ckk_acname}{" "}
                      {ckk_accjukyoo ? <span className="text-danger">({ckk_accjukyoo})</span> : null}
                    </td>

                    <td className="text-left">
                      {ckk_bankcode ? <span className="label label-primary">{ckk_bankcode}</span> : null} {ckk_bankname}{" "}
                      {ckk_summary ? <span className="text-warning">({ckk_summary})</span> : null}
                    </td>

                    <td className="text-left">{ckk_etc}</td>
                    <td className="text-right">{Fn.numberWithCommas(ckk_amt)}</td>
                    <td>{ckk_step}</td>
                  </tr>
                );
              })
            }
          </table>
        </div>

        <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 8 &&
            <div>
              <button type="button" className="btn btn-sm btn-outline-point px-3 mx-1" onClick={() => {
                if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 8) {
                  alert('권한이 없습니다.');
                  return;
                }
                change_date();
              }}>날짜일괄변경
              </button>
              <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => {
                if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 8) {
                  alert('권한이 없습니다.');
                  return;
                }
                del_account()
              }}>선택삭제
              </button>
            </div>
          }
        </nav>

        {/*팝업 2*/}

        <Modal
          isOpen={modal2Open}
          style={{
            modal: {
              backgroundColor: 'transparent',
              margin: 0,
              padding: 0,
              alignItems: undefined,
              justifyContent: undefined,
            },
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent'
            }
          }}
          onAfterOpen={() => {
            Fn.centerModal();
            loadModal2();
          }}
          onRequestClose={() => setModal2Open(false)}
          ariaHideApp={false}
        >
          <div className="pop_background" style={{zIndex: 1000000000}}>
            <div className="pop_box01_wide p_absolute_mid_pop">
              <div className="POP_title">
                <h5><strong>JTnet 정산입금 등록</strong></h5>
                <p className="closed">
                  <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </p>
              </div>

              <form id="jtnform" name="jtnform">
                <div className="POP_cnt_box w-100">
                  <div className="my-1 mb-3 d-flex">
                    <div className="d-flex pr-1" style={{width: 'auto'}}>
                      <p className="p-1">정산일 :</p>
                      <JDatePicker className="form-control" name="jtnet_basic_date" id="jtnet_basic_date"/>
                    </div>
                    <div className="pr-1">
                      <select className="form-select" name="jtnet_branch_code" id="jtnet_branch_code" onChange={(e) => {
                        $('#jtnet_MID').val('');
                        setM2BranchCode(e.target.value);
                        get_data_pre();
                      }}>
                      </select>
                    </div>
                    {m2BranchCode &&
                      <div className="pr-1">
                        <select className="form-select" name="jtnet_MID" id="jtnet_MID" onChange={() => get_data()}>
                          <option value="">선택</option>
                          {result2?.b_MID && <option value={result2?.b_MID}>기본단말기</option>}
                          {result2?.sub_list?.length > 0 && result2?.sub_list?.map(item =>
                            <option value={item.MID}>{item.as_name}</option>
                          )}
                        </select>
                      </div>
                    }
                  </div>

                  <table className="view-rounded sub_table_border text-center">
                    <tr>
                      <th style={{width: '60px'}}>No</th>
                      <th>정산일</th>
                      <th>결제일</th>
                      <th>매입사</th>
                      <th>결제금액</th>
                      <th>수수료</th>
                      <th>VAT</th>
                      <th>입금액</th>
                    </tr>
                    {uKey && result2?.list?.map((item, index) => {
                      let maip_company = '';

                      switch (item?.jt_maip_company) {
                        case '01':
                          maip_company = '비씨';
                          break;
                        case '02':
                          maip_company = 'KB국민';
                          break;
                        case '03':
                          maip_company = '하나(외환)';
                          break;
                        case '04':
                          maip_company = '삼성';
                          break;
                        case '06':
                          maip_company = '신한';
                          break;
                        case '07':
                          maip_company = '현대';
                          break;
                        case '08':
                          maip_company = '롯데';
                          break;
                        case '12':
                          maip_company = '농협';
                          break;
                        default:
                          maip_company = '';
                      }

                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.c_date}</td>
                          <td>{item?.s_date}</td>
                          <td>{maip_company}</td>
                          <td className="text-right">{Fn.numberWithCommas(item?.price)}</td>
                          <td className="text-right">{Fn.numberWithCommas(item?.fee)}</td>
                          <td className="text-right">{Fn.numberWithCommas(item?.vat)}</td>
                          <td className="text-right">{Fn.numberWithCommas(item?.in_price)}</td>
                        </tr>
                      )
                    })}
                    {uKey && result2?.list?.length > 0 &&
                      <tr>
                        <th colSpan={4}>합 계</th>
                        <td className="text-right">{Fn.numberWithCommas(result2?.tot_price)}</td>
                        <td className="text-right">{Fn.numberWithCommas(result2?.tot_fee)}</td>
                        <td className="text-right">{Fn.numberWithCommas(result2?.tot_vat)}</td>
                        <td className="text-right">{Fn.numberWithCommas(result2?.tot_in_price)}</td>
                      </tr>
                    }
                    {uKey && !result2?.list?.length &&
                      <tr>
                        <td colSpan={8}>※ 불러온 data가 없습니다.</td>
                      </tr>
                    }
                    {!uKey &&
                      <tr>
                        <td colSpan={8}>※ 대상일 및 지점을 선택해주세요.</td>
                      </tr>
                    }
                  </table>
                  {uKey && result2?.list?.length > 0 &&
                    <div>
                      <div className="input-group mt-4" style={{maxWidth: "700px", fontWeight: "600", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                        입금계좌 :
                        <select
                          name="bank_code"
                          id="bank_code"
                          className="form-control ml-1"
                          style={{maxWidth: "180px"}}
                        >
                          {result2?.j_list?.length > 0 && result2?.j_list?.map(item => <option value={item.j_no}>{item.j_jukname}</option>)}
                        </select>
                        &nbsp;&nbsp;

                        <ChkAuthNum type={"locker_info_change"} prefix="jtnet_" style={{maxWidth: "200px"}}/>

                        <button
                          className="btn btn-danger btn-sm btn_create ml-2"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            go_submit2();
                          }}
                        >
                          <i className="fas fa-check-square"></i> 입금 및 수수료 등록
                        </button>
                        &nbsp;&nbsp;
                        <button className="btn btn-outline-dark btn-sm" onClick={() => setModal2Open(false)}>
                          <i className="fas fa-times"></i> 닫기
                        </button>
                      </div>
                    </div>
                  }
                </div>
                <input type="hidden" name="auth_code"/>
                <input type="hidden" name="u_key" id="jtn_u_key" value={uKey}/>
                <input type="hidden" name="branch_code" id="jtn_branch_code"/>
                <input type="hidden" name="MID" id="jtn_MID"/>
                <input type="hidden" name="basic_date" id="jtn_basic_date"/>
                <input type="hidden" name="new_basic_date" id="jtn_new_basic_date"/>
              </form>
            </div>
          </div>
        </Modal>

        {/*팝업 3*/}

        <Modal
          isOpen={modal3Open}
          style={{
            modal: {
              backgroundColor: 'transparent',
              margin: 0,
              padding: 0,
              alignItems: undefined,
              justifyContent: undefined,
            },
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent'
            }
          }}
          onAfterOpen={() => {
            Fn.centerModal();
            loadModal3();
          }}
          onRequestClose={() => setModal3Open(false)}
          ariaHideApp={false}
        >
          <div className="pop_background" style={{zIndex: 1000000000}}>
            <div className="pop_box01_wide p_absolute_mid_pop">
              <div className="POP_title">
                <h5><strong>NicePay 정산입금 등록</strong></h5>
                <p className="closed">
                  <button type="button" className="btn" onClick={() => setModal3Open(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </p>
              </div>

              <div className="POP_cnt_box w-100">
                <form name="npayform" id="npayform">
                  <div className="my-1 mb-3 d-flex">
                    <div className="d-flex pr-1" style={{width: 'auto'}}>
                      <p className="p-1">정산일 :</p>
                      <JDatePicker className="form-control" name="npay_basic_date" id="npay_basic_date"/>
                    </div>
                    <div className="pr-1">
                      <select className="form-select" name="npay_branch_code" id="npay_branch_code" onChange={(e) => {
                        $('#npay_MID').val('');
                        let arr = e.target.value.split("|");
                        setM3BranchCode(arr[0]);
                        setMID(arr[1]);
                        setUKey(arr[2]);
                        npay_get_data(arr[1], arr[2]);
                      }}>
                      </select>
                    </div>
                    {(result3?.bbRow?.MID || result3?.subList?.length > 0) &&
                      <div className="pr-1">
                        <select className="form-select" name="npay_MID" id="npay_MID" onChange={(e) => npay_get_data(MID, uKey, e.target.value)}>
                          <option value="">선택</option>
                          {result3?.bbRow?.MID && <option value={result3?.bbRow?.MID}>기본단말기</option>}
                          {result3?.subList?.length > 0 && result3?.subList?.map(item =>
                            <option value={item.MID}>{item.as_name}</option>
                          )}
                        </select>
                      </div>
                    }
                  </div>

                  <table className="view-rounded sub_table_border text-center">
                    <tr>
                      <th style={{width: '60px'}}>No</th>
                      <th>정산일</th>
                      <th>결제일</th>
                      <th>매입사</th>
                      <th>결제금액</th>
                      <th>수수료</th>
                      <th>VAT</th>
                      <th>입금액</th>
                    </tr>
                    {uKey && result3?.list?.map((item, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{item?.c_date}</td>
                          <td>{item?.s_date}</td>
                          <td>{item?.maipCompany}</td>
                          <td className="text-right">{Fn.numberWithCommas(item?.price)}</td>
                          <td className="text-right">{Fn.numberWithCommas(item?.fee)}</td>
                          <td className="text-right">{Fn.numberWithCommas(item?.vat)}</td>
                          <td className="text-right">{Fn.numberWithCommas(item?.in_price)}</td>
                        </tr>
                      )
                    })}
                    {uKey && result3?.list?.length > 0 &&
                      <tr>
                        <th colSpan={4}>합 계</th>
                        <td className="text-right">{Fn.numberWithCommas(result3?.list?.reduce((n, {price}) => n + price, 0))}</td>
                        <td className="text-right">{Fn.numberWithCommas(result3?.list?.reduce((n, {fee}) => n + fee, 0))}</td>
                        <td className="text-right">{Fn.numberWithCommas(result3?.list?.reduce((n, {vat}) => n + vat, 0))}</td>
                        <td className="text-right">{Fn.numberWithCommas(result3?.list?.reduce((n, {in_price}) => n + in_price, 0))}</td>
                      </tr>
                    }
                    {uKey && !result3?.list?.length &&
                      <tr>
                        <td colSpan={8}>※ 불러온 data가 없습니다.</td>
                      </tr>
                    }
                    {!uKey &&
                      <tr>
                        <td colSpan={8}>※ 대상일 및 지점을 선택해주세요.</td>
                      </tr>
                    }
                  </table>
                  {uKey && result3?.list?.length > 0 &&
                    <div>
                      <div className="input-group mt-4" style={{maxWidth: "700px", fontWeight: "600", justifyContent: "center", alignContent: "center", alignItems: "center"}}>
                        입금계좌 :
                        <select
                          name="bank_code"
                          id="bank_code"
                          className="form-control ml-1"
                          style={{maxWidth: "180px"}}
                        >
                          {result3?.list2?.length > 0 && result3?.list2?.map(item => <option value={item.j_no}>{item.j_jukname}</option>)}
                        </select>
                        &nbsp;&nbsp;

                        <ChkAuthNum type={"locker_info_change"} prefix="npay_" style={{maxWidth: "200px"}}/>

                        <button
                          className="btn btn-danger btn-sm btn_create ml-2"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            go_submit3();
                          }}
                        >
                          <i className="fas fa-check-square"></i> 입금 및 수수료 등록
                        </button>
                        &nbsp;&nbsp;
                        <button className="btn btn-outline-dark btn-sm" onClick={() => setModal3Open(false)}>
                          <i className="fas fa-times"></i> 닫기
                        </button>
                      </div>
                    </div>
                  }
                  <input type="hidden" name="auth_code"/>
                  <input type="hidden" name="u_key" id="np_u_key" value={uKey}/>
                  <input type="hidden" name="branch_code" id="np_branch_code"/>
                  <input type="hidden" name="MID" id="np_MID"/>
                  <input type="hidden" name="basic_date" id="np_basic_date"/>
                  <input type="hidden" name="new_basic_date" id="np_new_basic_date"/>
                </form>
              </div>
            </div>
          </div>
        </Modal>

        {/*팝업 4*/}

        <Modal
          isOpen={modal4Open}
          style={{
            modal: {
              backgroundColor: 'transparent',
              margin: 0,
              padding: 0,
              alignItems: undefined,
              justifyContent: undefined,
            },
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'transparent'
            }
          }}
          onAfterOpen={() => {
            Fn.centerModal();
          }}
          onRequestClose={() => setModal4Open(false)}
          ariaHideApp={false}
        >
          <div className="pop_background" style={{zIndex: 1000000000}}>
            <div className="pop_box01 p_absolute_mid_pop">
              <div className="POP_title">
                <h5><strong>날짜 일괄변경</strong></h5>
                <p className="closed">
                  <button type="button" className="btn" onClick={() => setModal4Open(false)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </p>
              </div>

              <div className="POP_cnt_box w-100" style={{maxWidth: "300px"}}>
                <table className="view-rounded sub_table_border text-center">
                  <tr>
                    <th style={{width: '100px'}}>변경일자</th>
                    <td>
                      <JDatePicker className="form-control" id="mv5_ckk_date"/>
                    </td>
                  </tr>
                </table>
              </div>
              <div className="text-center my-1">
                <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => modal5_submit()}>변경</button>
                <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModal4Open(false)}>닫기</button>
              </div>
            </div>
          </div>
        </Modal>

        <input type="hidden" id="mv_all_ckk_no"/>

        <form name='hide' id='hide'>
          <input type='hidden' name='gubun'/>
          <input type='hidden' name='cc'/>
          <input type='hidden' name='ii'/>
          <input type='hidden' name='ga_kind'/>
          <input type='hidden' name='gaecode'/>
          <input type='hidden' name='branch_code'/>
        </form>
      </div>

      <input type="hidden" id="edit_base_ckk_no"/>

      <FinanceAccountEdit isOpen={isFAEOpen} onClose={() => setFAEOopen(false)} onCallback={() => setRenew(Fn.getRenewTime())}/>
    </CmnCard>
  );
};

export default FinanceAccount;
