import React, {useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios from "axios";
import $ from 'jquery';

const MemberView = (props) => {

  //  3-1.
  const make_monitor = () => {
    const as = window.open('/inc/black.html', 'enter_monitor', 'width=0,height=0,scrollbars=yes,top=10000,left=10000');
    as.window.close();
    const as2 = window.open('/enter/enter_monitor.html', 'enter_monitor', 'width=500,height=500,scrollbars=yes,top=600,left=1000');

    return () => {
      as2.close();
    };
  };

  //  4-1.
  const nds = () => {
    const d = new Date();
    let t_year = d.getFullYear();

    t_year = String(t_year);

    let year = t_year.substr(2, 2);
    let month = d.getMonth() + 1;
    let day = d.getDate();
    let hour = d.getHours();
    let minute = d.getMinutes();
    let second = d.getSeconds();

    if (month < 10) {
      month = "0" + month;
    }

    if (day < 10) {
      day = "0" + day;
    }

    if (hour < 10) {
      hour = "0" + hour;
    }

    if (minute < 10) {
      minute = "0" + minute;
    }

    if (second < 10) {
      second = "0" + second;
    }

    const new_pay_id = year + month + day + hour + minute + second;

    return new_pay_id;
  };

  //  4-2.
  const payId = nds();

  //  5-1.
  // TODO : 변환 필요
  const item_form = document.getElementById('item_form');
  const s_form = document.getElementById('s_form');
  const [service_ok, setService_ok] = useState("");
  const [edtAmount, setEdtAmount] = useState("");
  const [edtTax, setEdtTax] = useState("");
  const [edtInstallment, setEdtInstallment] = useState("");

  const pre_go_submit = () => {
    service_ok.innerHTML = "<a class='btn btn-danger'>서비스 신청</a>";

    const activeTab = $("ul#tab_menu li.active>a").attr("href");

    let new_activeTab = activeTab.substr(0, 12);

    let url;

    if (new_activeTab === "#menu_basics") {
      url = "member_service_save.php";
    } else if (activeTab === "#menu_option") {
      url = "member_service_etc_save.php";
    } else if (activeTab === "#menu_pt") {
      url = "member_service_pt_save.php";
    }

    if ($('*[id^=it_show]').length < 1 && $('*[id^=it_op_show]').length < 1 && $('*[id^=it_pt_show]').length < 1) {
      alert("등록된 서비스신청내역이 없습니다.");
      service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
      return;
    }

    if (item_form.payCard.value === "") {
      alert('받은돈 지불수단을 선택해주세요');
      service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
      item_form.payCard.focus();
      return;
    }

    if (s_form.reg_step.value === "") {
      alert('접수자를 선택해주세요');
      service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
      item_form.payCard.focus();
      return;
    }

    if (url === "member_service_save.php") {
      if (!item_form.period_kind[0].checked && !item_form.period_kind[1].checked && !item_form.period_kind[2].checked) {
        alert("기간을 설정해주세요");
        service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
        return;
      }
    }

    if (url === "member_service_pt_save.php") {
      if (item_form.pt_visit_course.value === "") {
        alert("PT가입경로를 선택해주세요");
        service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
        return;
      }
    }

    //미납금이 있는경우 경고창 처리
    const m_price = item_form.unpaid_price.value;
    if (parseFloat(cfNumeric(m_price)) > 10) {
      alert('미납금이 존재합니다. 정상적인 서비스신청인지 금액을 확인해주세요');
    }

    const dd = item_form.payCard.value.split(",");
    const dd_size = dd.length;

    if (item_form.payCard.value === "2222") {
      if (item_form.pay_price.value === "" || item_form.pay_price.value === "0") {
        alert("카드결제일경우 받은돈을 입력해주세요");
        service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
        return;
      }

      edtAmount.value = 0;
      edtTax.value = 0;
      edtInstallment.value = "00";

      const pay_price = item_form.pay_price.value;

      cal_price(pay_price);

      edtInstallment.value = item_form.month_card.value;

      $("#authType").val("1010");

      writeTo();
    } else if (item_form.payCard.value === "1111" || dd_size === 3) {
      if ((item_form.pay_price.value === "" || item_form.pay_price.value === "0") && item_form.cash_p.checked === true) {
        alert("현금결제일경우 받은돈을 입력해주세요");
        service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
        return;
      }

      if (item_form.cash_p.checked === true && "") {
        edtAmount.value = 0;
        edtTax.value = 0;
        edtInstallment.value = "00";

        const pay_price = item_form.pay_price.value;

        cal_price(pay_price);

        $("#authType").val("5010");

        writeTo();
      } else {
        go_submit();
      }
    } else {
      go_submit();
    }
  };

  //  5-2.
  // TODO : 변환 필요
  // <button onClick={pre_go_submit}>Pre Go Submit</button>

  //  6-1.
  const member_form = document.getElementById('member_form');
  const service_state_form = document.getElementById('service_state_form');
  const service_request_form = document.getElementById('service_request_form');
  const delay_form = document.getElementById('delay_form');
  const [split, setSplit] = useState("");
  const [la_cl, setLa_cl] = useState("");
  const [la_cl2, setLa_cl2] = useState("");
  const [service_ing, setService_ing] = useState("");
  const [re_data, setRe_data] = useState("");
  const [mb_no, setMb_no] = useState("");
  // const [member_view, setMember_view] = useState("");
  const [it_menu_close, setIt_menu_close] = useState("");
  const [cash_p_show, setCash_p_show] = useState("");

  const go_submit = () => {
    service_ok.innerHTML = "<a class='btn btn-danger'>서비스 신청</a>";

    const activeTab = $("ul#tab_menu li.active>a").attr("href");

    const new_activeTab = activeTab.substr(0, 12);

    const dd = item_form.payCard.value.split(",");
    const pay2 = dd[0];

    let url;

    if (new_activeTab === "#menu_basics") {
      if (pay2 === "244") {
        url = "member_service_free_save.php";
      } else {
        url = "member_service_save.php";
      }
    } else if (activeTab === "#menu_option") {
      url = "member_service_etc_save.php";
    } else if (activeTab === "#menu_pt") {
      url = "member_service_pt_save.php";
    }

    if ($('*[id^=it_show]').length < 1 && $('*[id^=it_op_show]').length < 1 && $('*[id^=it_pt_show]').length < 1) {
      alert("등록된 서비스신청내역이 없습니다.");
      service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
      return;
    }

    if (item_form.payCard.value === "") {
      alert('받은돈 지불수단을 선택해주세요');
      service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
      item_form.payCard.focus();
      return;
    }

    if (url === "member_service_save.php") {
      if (!item_form.period_kind[0].checked && !item_form.period_kind[1].checked && !item_form.period_kind[2].checked) {
        alert("기간을 설정해주세요");
        service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
        return;
      }
    }

    item_form.auth_code.value = s_form.auth_code.value;
    item_form.reg_step.value = s_form.reg_step.value;
    item_form.mb_no.value = member_form.mb_no.value;

    service_ok.style.display = "none";
    la_cl.style.display = "none";
    la_cl2.style.display = "none";
    service_ing.style.display = "inline";

    const test = "N";

    if (test === "Y") {
      item_form.test_YN.value = "Y";
      item_form.action = url;
      item_form.submit();
    } else {
      const form = $('form')[0];
      const formData = new FormData(form);
      $.ajax({
        url: url,
        processData: false,
        contentType: false,
        data: formData,
        type: 'POST',
        success: function (data) {
          data = data.trim();
          split = data.split("||");
          re_data = split[0];
          mb_no = split[1];
          if (re_data === "ok") {
            alert('등록되었습니다');
            item_form.reset();
            member_form.reset();
            service_state_form.reset();
            service_request_form.reset();
            delay_form.reset();
            member_view(mb_no);
            it_menu_close();
            cash_p_show.style.display = "none";

            if (split[2] === "Y") {
              // 영수증 출력
              // pop_up('print_pay.html?base_seq='+split[3],'print_pay','300','150','no')
              // future_send_YN - 아래 주석처리

              // common_i.location.href='print_pay.html?base_seq='+split[3]
            }
          } else {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
            service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
            window.location.href = "member_view.html";
          }
        },
        error: function (e) {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
        }
      });
    }
  };

  //  6-2.
  // TODO : 변환 필요
  // <button onClick={go_submit}>Go Submit</button>

  //  7-1.
  const [insert_member_btn, setInsert_member_btn] = useState("");
  const insert_member = () => {
    member_form.auth_code.value = s_form.auth_code.value;
    member_form.receive_name.value = s_form.reg_step.value;

    insert_member_btn.innerHTML =
      "<a class=' btn btn-danger btn-sm' href='#1'>신규등록</a>";

    if (s_form.auth_code.value === '') {
      alert('인증코드를 입력해주세요');
      s_form.search_auth_code.focus();
      insert_member_btn.innerHTML =
        "<a class=' btn btn-danger btn-sm' href='#1' onclick='insert_member()'>신규등록</a>";
      return;
    }
    if (s_form.reg_step.value === '') {
      alert('접수자를 선택해주세요');
      s_form.reg_step.focus();
      insert_member_btn.innerHTML =
        "<a class=' btn btn-danger btn-sm' href='#1' onclick='insert_member()'>신규등록</a>";
      return;
    }
    if (member_form.auth_code.value === '') {
      alert('인증코드를 입력해주세요');
      insert_member_btn.innerHTML =
        "<a class=' btn btn-danger btn-sm' href='#1' onclick='insert_member()'>신규등록</a>";
      return;
    }
    if (member_form.mb_id.value === '') {
      alert('회원번호를 입력해주세요');
      member_form.mb_id.focus();
      insert_member_btn.innerHTML =
        "<a class=' btn btn-danger btn-sm' href='#1' onclick='insert_member()'>신규등록</a>";
      return;
    }
    if (member_form.mb_name.value === '') {
      alert('이름을 입력해주세요');
      member_form.mb_name.focus();
      insert_member_btn.innerHTML =
        "<a class=' btn btn-danger btn-sm' href='#1' onclick='insert_member()'>신규등록</a>";
      return;
    }
    if (member_form.phone_no.checked === false) {
      if (member_form.mb_hp.value === '') {
        alert('연락처를 입력해주세요');
        member_form.mb_hp.focus();
        insert_member_btn.innerHTML =
          "<a class=' btn btn-danger btn-sm' href='#1' onclick='insert_member()'>신규등록</a>";
        return;
      }
    }

    var form = document.getElementsByTagName('form')[2];
    var formData = new FormData(form);
    fetch('/locker/member/save', {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.text())
      .then((data) => {
        data = data.trim();
        // document.write(data); return;
        split = data.split('||');
        re_data = split[0];
        mb_no = split[1];
        if (re_data === 'wrong_birth') {
          alert('생일이 잘못입력되었습니다');
          insert_member_btn.innerHTML =
            "<a class=' btn btn-danger btn-sm' href='#1' onclick='insert_member()'>신규등록</a>";
          return;
        } else if (re_data === 'dup_member') {
          alert('기존 데이터가 존재합니다');
          insert_member_btn.innerHTML =
            "<a class=' btn btn-danger btn-sm' href='#1' onclick='insert_member()'>신규등록</a>";
          return;
        } else if (re_data === 'dup_hp') {
          alert('동일한 연락처가 존재합니다');
          insert_member_btn.innerHTML =
            "<a class=' btn btn-danger btn-sm' href='#1' onclick='insert_member()'>신규등록</a>";
          return;
        } else if (re_data === 'insert_err') {
          alert('등록오류! 다시 등록해주세요');
          window.location.href = 'member_view.html';
        } else if (re_data === 'insert_ok') {
          alert('등록되었습니다');
          window.location.href = 'member_view.html?temp_mb_no=' + mb_no;
        } else if (re_data === 'err') {
          alert('등록오류!! 다시 등록해주세요');
          window.location.href = 'member_view.html';
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
          window.location.href = 'member_view.html';
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  //  7-2.
  // TODO : 변환 필요
  // <button onClick={insert_member}>Insert Member</button>


  //  8-1.
  const [authCode, setAuthCode] = useState('');
  const [regStep, setRegStep] = useState('');

  const edit_member = () => {
    const memberForm = document.forms.memberForm;
    const sForm = document.forms.sForm;

    memberForm.auth_code.value = sForm.auth_code.value;
    memberForm.receive_name.value = sForm.reg_step.value;

    if (memberForm.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    const editMemberBtn = document.getElementById('editMemberBtn');

    editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1'>회원정보 수정</a>";

    if (sForm.auth_code.value === '') {
      alert('인증코드를 입력해주세요');
      sForm.search_auth_code.focus();
      editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
      return;
    }
    if (sForm.reg_step.value === '') {
      alert('접수자를 선택해주세요');
      sForm.reg_step.focus();
      editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
      return;
    }
    if (memberForm.auth_code.value === '') {
      alert('인증코드를 입력해주세요');
      editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
      return;
    }
    if (memberForm.mb_id.value === '') {
      alert('회원번호를 입력해주세요');
      memberForm.mb_id.focus();
      editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
      return;
    }
    if (memberForm.mb_name.value === '') {
      alert('이름을 입력해주세요');
      memberForm.mb_name.focus();
      editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
      return;
    }

    if (memberForm.marketing_YN[0].checked === false && memberForm.marketing_YN[1].checked === false) {
      alert('마케팅 활용동의를 선택해주세요');
      editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
      return;
    }

    if (memberForm.marketing_shop_YN[0].checked === false && memberForm.marketing_shop_YN[1].checked === false) {
      alert('스포애니몰 동의를 선택해주세요');
      editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
      return;
    }

    if (memberForm.edit_memo.value === '') {
      alert('수정메모를 입력해주세요');
      memberForm.edit_memo.focus();
      editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
      return;
    }

    const form = $('form')[2];
    const formData = new FormData(form);
    $.ajax({
      url: 'member_save.php',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data.trim();
        split = data.split('||');
        re_data = split[0];
        mb_no = split[1];
        if (re_data === 'wrong_birth') {
          alert('생일이 잘못입력되었습니다');
          editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
          return;
        } else if (re_data === 'dup_member') {
          alert('기존 데이터가 존재합니다');
          editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
          return;
        } else if (re_data === 'dup_hp') {
          alert('동일한 연락처가 존재합니다');
          editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
          return;
        } else if (re_data === 'no_pic') {
          alert('카드번호 변경시 사진은 필수로 입력해야 합니다.');
          editMemberBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='edit_member()'>회원정보 수정</a>";
          return;
        } else if (re_data === 'insert_err') {
          alert('수정오류! 다시 등록해주세요');
          window.location.href = 'member_view.html';
        } else if (re_data === 'insert_ok') {
          alert('수정되었습니다');
          window.location.href = 'member_view.html?temp_mb_no3=' + mb_no;
        } else if (re_data === 'err') {
          alert('수정오류!! 다시 등록해주세요');
          window.location.href = 'member_view.html';
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
          window.location.href = 'member_view.html';
        }
      }
    });
  };

  //  8-2.
  // TODO : 변환 필요
  {/* JSX code representing the component's structure and UI */
  }
  // <form name="memberForm">
  //   {/* Add necessary form elements */}
  //   <input type="text" name="auth_code" />
  //   <input type="text" name="receive_name" />
  //   {/* Add other form elements as required */}
  // </form>
  // <form name="sForm">
  //   {/* Add necessary form elements */}
  //   <input type="text" name="auth_code" />
  //   <input type="text" name="reg_step" />
  //   {/* Add other form elements as required */}
  // </form>
  // <button id="editMemberBtn" type="button" onClick={edit_member}>
  //   회원정보 수정
  // </button>

  //  9-1.
  const show_locker = (event) => {
    const lockerNoInfo = document.getElementById('lockerNoInfo');
    const lockerDateInfo = document.getElementById('lockerDateInfo');

    if (event.target.checked) {
      lockerNoInfo.style.display = 'inline';
      lockerDateInfo.style.display = 'inline';
    } else {
      lockerNoInfo.style.display = 'none';
      lockerDateInfo.style.display = 'none';
    }
  };

  //  9-2.
  // TODO : 변환 필요
  {/* JSX code representing the component's structure and UI */
  }
  // <form name="serviceStateForm">
  //   {/* Add necessary form elements */}
  //   <input type="checkbox" name="locker" onChange={show_locker} />
  //   {/* Add other form elements as required */}
  // </form>
  // <div id="lockerNoInfo" style={{ display: 'none' }}>
  //   {/* Content to display when locker is checked */}
  // </div>
  // <div id="lockerDateInfo" style={{ display: 'none' }}>
  //   {/* Content to display when locker is checked */}
  // </div>

  //  10-1.
  const show_g_locker = (event) => {
    const gLockerNoInfo = document.getElementById('gLockerNoInfo');
    const gLockerDateInfo = document.getElementById('gLockerDateInfo');

    if (event.target.checked) {
      gLockerNoInfo.style.display = 'inline';
      gLockerDateInfo.style.display = 'inline';
    } else {
      gLockerNoInfo.style.display = 'none';
      gLockerDateInfo.style.display = 'none';
    }
  };

  //  10-2.
  // TODO : 변환 필요
  {/* JSX code representing the component's structure and UI */
  }
  // <form name="serviceStateForm">
  //   {/* Add necessary form elements */}
  //   <input type="checkbox" name="g_locker" onChange={show_g_locker} />
  //   {/* Add other form elements as required */}
  // </form>
  // <div id="gLockerNoInfo" style={{ display: 'none' }}>
  //   {/* Content to display when g_locker is checked */}
  // </div>
  // <div id="gLockerDateInfo" style={{ display: 'none' }}>
  //   {/* Content to display when g_locker is checked */}
  // </div>

  //  11-1.
  const show_golf = (event) => {
    const golfDateInfo = document.getElementById('golfDateInfo');

    if (event.target.checked) {
      golfDateInfo.style.display = 'inline';
    } else {
      golfDateInfo.style.display = 'none';
    }
  };

  //  11-2.
  // TODO : 변환 필요
  // <input type="checkbox" name="opt1" onChange={show_golf}/>

  //  12-1.
  const show_sque = (event) => {
    const squeDateInfo = document.getElementById('squeDateInfo');

    if (event.target.checked) {
      squeDateInfo.style.display = 'inline';
    } else {
      squeDateInfo.style.display = 'none';
    }
  };

  //  12-2.
  // TODO : 변환 필요
  // <input type="checkbox" name="opt3" onChange={show_sque}/>

  //  13-1.
  const show_spin = (event) => {
    const spinDateInfo = document.getElementById('spinDateInfo');

    if (event.target.checked) {
      spinDateInfo.style.display = 'inline';
    } else {
      spinDateInfo.style.display = 'none';
    }
  };

  //  13-2.
  // TODO : 변환 필요
  //<input type="checkbox" name="opt4" onChange={show_spin}/>

  //  14-1.
  const show_tae = (event) => {
    const taeDateInfo = document.getElementById('taeDateInfo');

    if (event.target.checked) {
      taeDateInfo.style.display = 'inline';
    } else {
      taeDateInfo.style.display = 'none';
    }
  };

  //  14-2.
  // TODO : 변환 필요
  // <input type="checkbox" name="opt5" onChange={show_tae}/>

  //  15-1.
  const show_pila = (event) => {
    const pilaDateInfo = document.getElementById('pilaDateInfo');

    if (event.target.checked) {
      pilaDateInfo.style.display = 'inline';
    } else {
      pilaDateInfo.style.display = 'none';
    }
  };

  //  15-2.
  // TODO : 변환 필요
  // <input type="checkbox" name="opt7" onChange={show_pila}/>

  //  16-1.
  const show_suit = (event) => {
    const suitDateInfo = document.getElementById('suitDateInfo');

    if (event.target.checked) {
      suitDateInfo.style.display = 'inline';
    } else {
      suitDateInfo.style.display = 'none';
    }
  };

  //  16-2..
  // TODO : 변환 필요
  // <input type="checkbox" name="suit" onChange={show_suit}/>

  //  17-1.
  const sForm = document.getElementById('sForm');
  const service_request_insert = (event) => {
    event.preventDefault();

    const memberFormMbNo = document.getElementById('memberFormMbNo');
    const sFormAuthCode = document.getElementById('sFormAuthCode');
    const sFormRegStep = document.getElementById('sFormRegStep');
    const serviceRequestForm = document.getElementById('serviceRequestForm');

    if (memberFormMbNo.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }
    if (sFormAuthCode.value === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      return;
    }

    if (serviceRequestForm.pdtDate.value === '') {
      alert('매출일자를 선택해주세요');
      serviceRequestForm.pdtDate.focus();
      return;
    }
    if (serviceRequestForm.pdtNo.value === '') {
      alert('서비스를 선택해주세요');
      serviceRequestForm.pdtNo.focus();
      return;
    }
    if (serviceRequestForm.pdtQty.value === '') {
      alert('수량을 입력해주세요');
      serviceRequestForm.pdtQty.focus();
      return;
    }

    if (serviceRequestForm.pdtAmtOriginal.value !== serviceRequestForm.pdtAmt.value) {
      if (serviceRequestForm.pdtEtc.value === '') {
        alert('입력금액과 상품금액이 틀린경우 서비스비고를 입력해주세요');
        serviceRequestForm.pdtEtc.focus();
        return;
      }
    }

    serviceRequestForm.authCode.value = sFormAuthCode.value;
    serviceRequestForm.regStep.value = sFormRegStep.value;
    serviceRequestForm.mbNo.value = memberFormMbNo.value;

    // Perform submit action using React state or AJAX request
  };

  //  17-2.
  // TODO : 변환 필요
  // <form id="memberForm">
  //   {/* Add necessary form elements */}
  //   <input type="text" id="memberFormMbNo" />
  //   {/* Add other form elements as required */}
  // </form>
  // <form id="sForm">
  //   {/* Add necessary form elements */}
  //   <input type="text" id="sFormAuthCode" />
  //   <input type="text" id="sFormRegStep" />
  //   {/* Add other form elements as required */}
  // </form>
  // <form id="serviceRequestForm" onSubmit={service_request_insert}>
  //   {/* Add necessary form elements */}
  //   <input type="text" name="pdtDate" />
  //   <input type="text" name="pdtNo" />
  //   <input type="text" name="pdtQty" />
  //   <input type="text" name="pdtAmtOriginal" />
  //   <input type="text" name="pdtAmt" />
  //   <input type="text" name="pdtEtc" />
  //   {/* Add other form elements as required */}
  //   <button type="submit">Submit</button>
  // </form>

  //  18-1.
  const change_service_state = (event) => {
    event.preventDefault();

    const memberFormMbNo = document.getElementById('memberFormMbNo');
    const sFormAuthCode = document.getElementById('sFormAuthCode');
    const sFormRegStep = document.getElementById('sFormRegStep');
    const serviceStateForm = document.getElementById('serviceStateForm');

    if (memberFormMbNo.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    const serviceStateBtn = document.getElementById('serviceStateBtn');
    serviceStateBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1'>서비스상태 변경</a></span>";

    if (sFormAuthCode.value === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      serviceStateBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='change_service_state()'>서비스상태 변경</a></span>";
      return;
    }

    if (serviceStateForm.insertMemo.value === '') {
      alert('수정메모를 입력해주세요');
      serviceStateBtn.innerHTML = "<a class=' btn btn-danger btn-sm' href='#1' onclick='change_service_state()'>서비스상태 변경</a></span>";
      serviceStateForm.insertMemo.focus();
      return;
    }

    serviceStateForm.lockerClearYN.value = 'Y';
    serviceStateForm.mbNo.value = memberFormMbNo.value;
    serviceStateForm.authCode.value = sFormAuthCode.value;
    serviceStateForm.receiveName.value = sFormRegStep.value;

    // Perform AJAX request using React state or other methods
  };

  //  18-2.
  // TODO : 변환 필요
  // <form id="memberForm">
  //   {/* Add necessary form elements */}
  //   <input type="text" id="memberFormMbNo" />
  //   {/* Add other form elements as required */}
  // </form>
  // <form id="sForm">
  //   {/* Add necessary form elements */}
  //   <input type="text" id="sFormAuthCode" />
  //   <input type="text" id="sFormRegStep" />
  //   {/* Add other form elements as required */}
  // </form>
  // <form id="serviceStateForm" onSubmit={change_service_state}>
  //   {/* Add necessary form elements */}
  //   <input type="text" name="insertMemo" />
  //   {/* Add other form elements as required */}
  //   <button type="submit">Submit</button>
  // </form>

  //  19-1.
  const change_period = (memberMbNo, sFormAuthCode, sFormRegStep) => {
    if (memberMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      return;
    }

    const mbNo = memberMbNo;
    const authCode = sFormAuthCode;
    const regStep = sFormRegStep;

    const type = ''; // Specify the type of change period action

    const popupUrl = 'change_period.html?type=${type}&mb_no=${mbNo}&receive_name=${regStep}';
    window.open(popupUrl, 'change_period', 'width=500,height=400,scrollbars=no');

  };

  //  19-2.
  // change_period("","","");

  //  20-1.
  const input_form = document.getElementById('input_form');
  const input_insert = (memberMbNo, sFormAuthCode) => {
    if (memberMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      return;
    }

    const ipAmtValue = ''; // Get the value of input_form.ip_amt
    const ipMethodValue = ''; // Get the value of input_form.ip_method
    const ipCardNameValue = ''; // Get the value of input_form.ip_cardName
    const ipBankValue = ''; // Get the value of input_form.ip_bank

    if (ipAmtValue === '' || ipAmtValue === '0') {
      alert('금액을 입력해주세요');
      input_form.ip_amt.focus();
      return;
    }

    if (
      (ipMethodValue === '1143,카드매출금' || ipMethodValue === '-1143,카드취소') &&
      ipCardNameValue === ''
    ) {
      alert('카드사를 선택해주세요');
      input_form.ip_cardName.focus();
      return;
    }

    if (ipMethodValue === '1112,보통예금' && ipBankValue === '') {
      alert('통장을 선택해주세요');
      input_form.ip_bank.focus();
      return;
    }

    const mbNo = memberMbNo;
    const authCode = sFormAuthCode;
    // const regStep = sFormRegStep; // Uncomment this line if needed

    // Perform necessary actions with the form data

    input_form.mb_no.value = mbNo;
    input_form.auth_code.value = authCode;

    input_form.target = 'common_i_member_data';
    input_form.action = 'member_ipkum_save.php';
    input_form.submit();
  };

  //  20-2.
  // input_insert("","");

  //  21-1.
  const delay_input = (memberMbNo, sFormAuthCode) => {
    if (memberMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      return;
    }

    const delayStatusValue = ''; // Get the value of delay_form.delayStatus
    const dePeriodValue = ''; // Get the value of delay_form.de_period
    const dePeriodMValue = ''; // Get the value of delay_form.de_periodM

    if (delayStatusValue === '1') {
      // Perform necessary actions when delayStatusValue is "1"
    } else {
      if ((dePeriodValue === '' || dePeriodValue === '0') && dePeriodMValue === '0') {
        alert('기간을 입력해주세요');
        delay_form.de_period.focus();
        return;
      }
    }

    const mbNo = memberMbNo;
    const authCode = sFormAuthCode;
    // const regStep = sFormRegStep; // Uncomment this line if needed

    // Perform necessary actions with the form data

    delay_form.mb_no.value = mbNo;
    delay_form.auth_code.value = authCode;

    delay_form.target = 'common_i_member_data';
    delay_form.action = 'member_delay_save.php';
    delay_form.submit();
  };

  //  21-2.
  // delay_input("","");

  //  22-1.
  const service_input_menual = (memberMbNo, sFormAuthCode) => {
    if (memberMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      return;
    }

    // Perform necessary actions for the popup window

    const url = 'app_insert_manualy.html';
    const name = 'app_insert_manualy';
    const width = '700';
    const height = '300';
    const options = 'no';

    window.open(url, name, `width=${width},height=${height},resizable=yes,scrollbars=yes,status=yes,toolbar=no,menubar=no,location=no`);
  };

  //  22-2.
  // service_input_menual("","");

  //  23-1.
  const repu_card = (memberMbNo, sFormAuthCode) => {
    if (memberMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      return;
    }

    // Perform necessary actions for the popup window

    const url = `repu_card.html?mb_no=${memberMbNo}`;
    const name = 'repu_card';
    const width = '950';
    const height = '250';
    const options = 'no';

    window.open(url, name, `width=${width},height=${height},resizable=yes,scrollbars=yes,status=yes,toolbar=no,menubar=no,location=no`);
  };

  //  23-2.
  // repu_card("","");

  //  24-1.
  const input_menual = (memberMbNo, sFormAuthCode) => {
    if (memberMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      return;
    }

    // Perform necessary actions for the popup window

    const url = `input_insert_manualy.html?mb_no=${memberMbNo}&auth_code=${sFormAuthCode}`;
    const name = 'input_insert_manualy';
    const width = '600';
    const height = '300';
    const options = 'no';

    window.open(url, name, `width=${width},height=${height},resizable=yes,scrollbars=yes,status=yes,toolbar=no,menubar=no,location=no`);
  };

  //  24-2.
  // input_menual("","");

  //  25-1.
  const input_delay = (memberMbNo, sFormAuthCode, sFormRegStep) => {
    if (memberMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      return;
    }

    const url = `delay_insert_manualy.html?mb_no=${memberMbNo}&receive_name=${sFormRegStep}`;
    const name = 'delay_insert_manualy';
    const width = '700';
    const height = '300';
    const options = 'no';

    window.open(url, name, `width=${width},height=${height},resizable=yes,scrollbars=yes,status=yes,toolbar=no,menubar=no,location=no`);
  };

  //  25-2.
  // input_delay("","","");

  //  26-1.
  const point_list = (memberMbNo, sFormAuthCode, sFormRegStep) => {
    if (memberMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      return;
    }

    const url = `point_list.html?mb_no=${memberMbNo}&receive_name=${sFormRegStep}`;
    const name = 'point_list';
    const width = '600';
    const height = '600';
    const options = 'resizable=yes,scrollbars=yes,status=yes,toolbar=no,menubar=no,location=no';

    window.open(url, name, `width=${width},height=${height},${options}`);
  };

  //  26-2.
  // point_list("","","");

  //  27-1.
  const use_gift = (memberMbNo, sFormAuthCode) => {
    if (memberMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      return;
    }

    const url = `gift_use.html?mb_no=${memberMbNo}`;
    const name = 'gift_use';
    const width = '810';
    const height = '600';
    const options = 'resizable=yes,scrollbars=yes,status=yes,toolbar=no,menubar=no,location=no';

    window.open(url, name, `width=${width},height=${height},${options}`);
  };

  //  27-2.
  // use_gift("","");

  //  28-1.
  const [error, setError] = useState('');

  const center_change = async (memberMbNo, sFormAuthCode) => {
    if (memberMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      sForm.searchAuthCode.focus();
      return;
    }

    try {
      const response = await axios.post('change_center_chk.php', {
        mb_no: memberMbNo,
      });

      const data = response.data.trim();

      if (data === 'ok') {
        const url = `change_center.html?all_branch=Y&mb_no=${memberMbNo}`;
        const name = 'change_center';
        const width = '300';
        const height = '200';
        const options = 'resizable=no,scrollbars=no,status=no,toolbar=no,menubar=no,location=no';

        window.open(url, name, `width=${width},height=${height},${options}`);
      } else if (data === 'pt') {
        setError('잔여PT가 있는 경우 주센터 변경이 불가능합니다.');
      } else if (data === 'date') {
        setError('주센터가입/변경 후 1달 이내는 주센터 변경이 불가능합니다.');
      } else {
        setError('데이터 오류! 시스템 관리자에게 문의하세요.');
      }
    } catch (error) {
      setError('데이터 오류! 시스템 관리자에게 문의하세요.');
    }
  };

  //  28-2.
  // center_change("","");

  //  29-1.
  const center_change_old = () => {
    const memberFormMbNo = document.member_form.mb_no.value;
    const sFormAuthCode = document.s_form.auth_code.value;
    const serviceStateFormLockerName = document.service_state_form.locker_name.value;

    if (memberFormMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      document.s_form.search_auth_code.focus();
      return;
    }

    if (serviceStateFormLockerName !== '') {
      alert('라커비움처리후 주센터 변경이 가능합니다.');
      return;
    }

    const mbNo = memberFormMbNo;
    const authCode = sFormAuthCode;
    const regStep = document.s_form.reg_step.value;

    const url = `center_change.html?auth_code=${authCode}&new_reg_step=${regStep}`;
    const name = 'center_change';
    const width = '1000';
    const height = '600';
    const options = 'resizable=yes,scrollbars=yes,status=yes,toolbar=yes,menubar=yes,location=yes';

    window.open(url, name, `width=${width},height=${height},${options}`);
  };

  //  29-2.
  // center_change_old();

  //  30-1.
  const center_start_date = () => {
    const memberFormMbNo = document.member_form.mb_no.value;
    const sFormAuthCode = document.s_form.auth_code.value;

    if (memberFormMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormAuthCode === '') {
      alert('인증코드를 입력해주세요');
      document.s_form.search_auth_code.focus();
      return;
    }

    const mbNo = memberFormMbNo;
    const authCode = sFormAuthCode;

    const url = `change_start_date.html?mb_no=${mbNo}&auth_code=${authCode}`;
    const name = 'change_start_date';
    const width = '500';
    const height = '300';
    const options = 'resizable=yes,scrollbars=yes,status=yes,toolbar=yes,menubar=yes,location=yes';

    window.open(url, name, `width=${width},height=${height},${options}`);
  };

  //  30-2.
  // center_start_date();

  //  31-1.
  const show_all_service = () => {
    const memberFormMbNo = document.member_form.mb_no.value;

    if (memberFormMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    const mbNo = memberFormMbNo;

    const url = `show_all_service.html?mb_no=${mbNo}`;
    const name = 'show_all_service';
    const width = '900';
    const height = '500';
    const options = 'resizable=yes,scrollbars=yes,status=yes,toolbar=yes,menubar=yes,location=yes';

    window.open(url, name, `width=${width},height=${height},${options}`);
  };

  //  31-2.
  // show_all_service();

  //  32-1.
  const show_all_ipkum = () => {
    const memberFormMbNo = document.member_form.mb_no.value;

    if (memberFormMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    const mbNo = memberFormMbNo;

    const url = `show_all_ipkum.html?mb_no=${mbNo}`;
    const name = 'show_all_ipkum';
    const width = '700';
    const height = '500';
    const options = 'resizable=yes,scrollbars=yes,status=yes,toolbar=yes,menubar=yes,location=yes';

    window.open(url, name, `width=${width},height=${height},${options}`);
  };

  //  32-2.
  // show_all_ipkum();

  //  33-1.
  const show_all_service_ipkum = () => {
    const memberFormMbNo = document.member_form.mb_no.value;

    if (memberFormMbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    const mbNo = memberFormMbNo;

    const url = `show_all_service_ipkum.html?mb_no=${mbNo}`;
    const name = 'show_all_service_ipkum';
    const width = '490';
    const height = '500';
    const options = 'resizable=yes,scrollbars=yes,status=yes,toolbar=yes,menubar=yes,location=yes';

    window.open(url, name, `width=${width},height=${height},${options}`);
  };

  //  33-2.
  // show_all_service_ipkum();

  //  34-1.
  const cleaer_auth_code = () => {
    // parent.opener.s_form.reset();
    const sFormSearchType = document.s_form.search_type;
    const sFormAuthCode = document.s_form.auth_code;
    const sFormSearchAuthCode = document.s_form.search_auth_code;
    const sFormRegStep = document.s_form.reg_step;
    const authCodeName = document.getElementById('auth_code_name');

    if (sFormSearchType) {
      sFormSearchType.value = '';
    }
    if (sFormAuthCode) {
      sFormAuthCode.value = '';
    }
    if (sFormSearchAuthCode) {
      sFormSearchAuthCode.value = '';
    }
    if (sFormRegStep) {
      sFormRegStep.value = '';
    }
    if (authCodeName) {
      authCodeName.innerHTML = '';
    }
  };

  //  34-2.
  // cleaer_auth_code();

  //  35-1.
  const finger_init = () => {
    const parentFingerInfo = window.document.getElementById('finger_info');
    if (parentFingerInfo) {
      parentFingerInfo.innerHTML = "<a class='btn btn-primary btn-sm' href='#1' onclick=\"alert('지문등록 팝업생성')\">지문등록</a>";
    }
  };

  //  35-2.
  // finger_init();

  //  36-1.
  const finger_edit = () => {
    const parentFingerInfo = window.document.getElementById('finger_info');
    if (parentFingerInfo) {
      parentFingerInfo.innerHTML = "<a class='btn btn-danger btn-sm' href='#1' onclick=\"alert('지문수정 팝업생성')\">지문수정</a>";
    }
  };

  //  36-2.
  // finger_edit();

  //  37-1.

  //  공통 (41-1)
  const [totAmountSum, setTotAmountSum] = useState('');
  const [cutPrice, setCutPrice] = useState('');
  //  공통 (38-1,39-1,41-1)
  const [usePoint, setUsePoint] = useState('');
  //  공통 (40-1,41-1)
  const [couponPrice, setCouponPrice] = useState('');
  //  공통 (39-1,41-1)
  const [unpaidPrice, setUnpaidPrice] = useState('');

  const [payCard, setPayCard] = useState('');
  const [cardMonthOptions, setCardMonthOptions] = useState([]);

  const autoInsert = () => {
    let newTotAmountSum = cfNumeric(totAmountSum);
    let newCutPrice = cfNumeric(cutPrice);

    //원 결제금액
    let wonPrice = newTotAmountSum - newCutPrice;

    let newUsePoint = usePoint ? cfNumeric(usePoint) : 0;
    let newCouponPrice = couponPrice ? cfNumeric(couponPrice) : 0;

    let newPrice = wonPrice - newUsePoint - newCouponPrice;
    newPrice = commaNum(newPrice);

    setUnpaidPrice(newPrice);

    if (payCard) {
      item_form.pay_price.value = item_form.unpaid_price.value;
      item_form.unpaid_price.value = 0;

      const dd = payCard.split(",");
      const ddSize = dd.length;

      // Update card_month using React state or a component variable

      if (payCard === "2222") {
        item_form.pay_price.readOnly = true;
        let price = cfNumeric(item_form.pay_price.value);
        if (eval(price) >= 50000) {
          const monthOptions = [];
          for (let i = 0; i <= 12; i++) {
            monthOptions.push(<option value={i < 10 ? `0${i}` : i}>{i === 0 ? '일시불' : `${i}개월`}</option>);
          }
          for (let i = 18; i <= 24; i += 6) {
            monthOptions.push(<option value={i}>{`${i}개월`}</option>);
          }

          setCardMonthOptions(monthOptions);
        } else {
          setCardMonthOptions([<option value="00">일시불</option>]);
        }

        cash_p_show.style.display = 'none';
      } else if (payCard === '1111' || ddSize === 3) {
        item_form.pay_price.readOnly = false;
        setCardMonthOptions([]);
        cash_p_show.style.display = 'inline';
      } else {
        item_form.pay_price.readOnly = false;
        setCardMonthOptions([]);
        item_form.cash_p.checked = false;
        cash_p_show.style.display = 'none';
      }
    } else {
      setCardMonthOptions([]);
      item_form.cash_p.checked = false;
      cash_p_show.style.display = 'none';
    }
  };

  //  38-1.
  const commaNum = () => {
  }
  const point_use = () => {
    let ablePoint = item_form.able_point.value;
    let usePoint = item_form.use_point.value;

    ablePoint = cfNumeric(ablePoint);
    usePoint = cfNumeric(usePoint);

    const ablePointShow = commaNum(ablePoint);

    if (eval(usePoint) > eval(ablePoint)) {
      alert(`사용가능한 마일리지는 ${ablePointShow}입니다`);
      item_form.use_point.value = 0;
    }

    use_p_chk();
  };

  //  39-1.

  const use_point_chk = () => {
    item_form.payCard.value = "";
    item_form.pay_price.value = "";
    window.card_month.innerHTML = "";

    let newUsePoint = cfNumeric(usePoint);
    newUsePoint = Math.floor(newUsePoint / 1000) * 1000;
    newUsePoint = commaNum(newUsePoint);
    setUsePoint(newUsePoint);

    let rPrice = cfNumeric(unpaidPrice);
    if (eval(rPrice) < 0) {
      alert("마일리지 사용은 결제금액을 초과할 수 없습니다");
      setUsePoint(0);
    }

    //usePChk();
  };

  //  40-1.
  const [couponValue, setCouponValue] = useState('');

  const chk_coupon = () => {
    item_form.payCard.value = "";
    item_form.pay_price.value = "";
    window.card_month.innerHTML = "";

    const wonData = couponValue.trim();
    const data = wonData.split("||");
    const couponSeq = data[0];
    const couponType = data[1];
    const newCouponPrice = data[2];

    if (wonData) {
      if (couponType === "B") {
        setCouponPrice(eval(newCouponPrice));
        //usePChk();
      } else {
        alert("기간은 서비스등록시 자동 합산처리됩니다");
        setCouponPrice(0);
        //usePChk();
      }
    } else {
      setCouponPrice(0);
      //usePChk();
    }
  };

  //  41-1.

  const use_p_chk = () => {
    const newTotAmountSum = cfNumeric(totAmountSum);
    const newCutPrice = cfNumeric(cutPrice);

    const wonPrice = eval(newTotAmountSum) - eval(newCutPrice);

    const newUsePoint = cfNumeric(usePoint);
    const newCouponPrice = cfNumeric(couponPrice);

    const newPrice = eval(wonPrice) - eval(newUsePoint) - eval(newCouponPrice);
    const formattedPrice = commaNum(newPrice);

    setUnpaidPrice(formattedPrice);
  };

  //  42-1.
  const serviceStateForm = document.getElementById('serviceStateForm');
  const [mbNo, setMbNo] = useState('');
  //const [authCode, setAuthCode] = useState('');

  const black_reg = () => {
    if (mbNo === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (authCode === '') {
      alert('인증코드를 입력해주세요');
      return;
    }

    const blackState = serviceStateForm.blackState.value;
    const url = blackState === 'N' ?
      `black_list_reg.html?type=register&mb_no=${mbNo}&auth_code=${authCode}` :
      `black_list_reg.html?type=release&mb_no=${mbNo}&auth_code=${authCode}`;

    // Open the registration/release popup
    window.open(url, 'black_list_reg', 'width=600,height=250,scroll=no');

  };

  //  43-1.
  const popUp = (file, names, width, height, sc) => {
    const w = width;
    const h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h},scrollbars=${sc},top=${wint},left=${winl}`);
  };

  const change_brand = () => {
    if (member_form.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (s_form.auth_code.value === '') {
      alert('인증코드를 입력해주세요');
      s_form.search_auth_code.focus();
      return;
    }

    const mbNo = member_form.mb_no.value;

    popUp(`change_brand.html?mb_no=${mbNo}`, 'change_brand', '300', '200', 'no');
  };

  //  44-1.
  const refund_reg = () => {
    if (member_form.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (s_form.auth_code.value === '') {
      alert('인증코드를 입력해주세요');
      s_form.search_auth_code.focus();
      return;
    }

    const mbNo = member_form.mb_no.value;

    popUp(`refund_cal.html?mb_no=${mbNo}`, 'refund_cal', '800', '460', 'no');
  };

  //  45-1.
  const [SAL_PRICE, setSalPrice] = useState(0);
  const [SAL_VAT, setSalVat] = useState(0);

  const cal_price = (price) => {
    price = cfNumeric(price);

    const SAL_AMT = price; // 판매금액

    const calculatedVat = (SAL_AMT / 1.1) * 0.1; // 부가세(VAT)
    const calculatedPrice = Math.round(SAL_AMT - calculatedVat); // 단가(반올림)
    const roundedVat = Math.round(calculatedVat); // 부가세(반올림)

    setSalPrice(calculatedPrice);
    setSalVat(roundedVat);
  };

  const cfNumeric = (value) => {
    // Implement your cfNumeric logic here
    // ...
  };

  //  46-1.
  const show_attend = () => {
    if (member_form.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    const mbNo = member_form.mb_no.value;

    popUp(`member_attend.html?mb_no=${mbNo}`, 'member_attend', '800', '460', 'no');
  };

  //  47-1.
  const insert_phone = () => {
    popUp(`change_hp.html`, 'change_hp', '300', '200', 'no');
  };

  //  48-1.
  const show_phone = (mbNo) => {
    const authCode = $("#auth_code").val();

    if (!authCode) {
      alert('인증코드를 입력해주세요');
      return;
    }

    $.ajax({
      type: 'post',
      url: 'show_hp.php',
      data: `mb_no=${mbNo}&auth_code=${authCode}`,
      success: function (data) {
        data = data.trim();

        $('#mb_hp').val(data);
        $('#phone_show').text('');
        member_form.mb_hp.readOnly = false;
      }
    });
  };

  //  49-1.
  const getTime_YYMMDDHHMMSS = () => {
    const today = new Date();

    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);

    const hours = ('0' + today.getHours()).slice(-2);
    const minutes = ('0' + today.getMinutes()).slice(-2);
    const seconds = ('0' + today.getSeconds()).slice(-2);

    const time = year + month + day + hours + minutes + seconds;

    return time.slice(-12);
  };

  //  50-1.
  const jsonInsert = (json, field, value) => {
    if (json.length === 0) {
      json = `{"${field}":"${value}"}`;
    } else {
      json = `${json.substr(0, json.length - 1)},"${field}":"${value}"}`;
    }
    return json;
  };

  //  50-2.
  let json = ''; // Initialize the JSON object

  // Insert values into the JSON object
  json = jsonInsert(json, 'field1', 'value1');
  json = jsonInsert(json, 'field2', 'value2');
  json = jsonInsert(json, 'field3', 'value3');

  //  51-1.
  let authType;
  let payType;

  function ParseHttpResponse(rtn) {
    // if (String(rtn).substr(0, 1) === '{') {
    //   const rtn_ele_cr = rtn.replace(String.fromCharCode(0x0d), '');
    //   console.log(rtn_ele_cr);
    //   const obj = JSON.parse(rtn_ele_cr);
    //
    //   if (
    //       obj.ERROR_CHECK_RESULT === 'S' &&
    //       obj.PKTYPE === 'S' &&
    //       obj.REPLY === '0000'
    //   ) {
    //     if (obj.MSGTYPE === '1010' || obj.MSGTYPE === '1050') {
    //       if (obj.ADDINFO2.slice(0, 3) === 'KKO') {
    //         edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
    //         edtAuthNo.value = String(obj.MESSAGE).substr(0, 10);
    //         edtAuthUniqueNo.value = '';
    //       } else if (obj.ADDINFO2.slice(0, 3) === 'SSG') {
    //         edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
    //         edtAuthNo.value = String(obj.MESSAGE).substr(0, 12) + fillSpace(3);
    //         edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
    //       } else {
    //         edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
    //         edtAuthNo.value = String(obj.MESSAGE).substr(0, 12);
    //         edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
    //       }
    //     } else if (obj.MSGTYPE === '5010' || obj.MSGTYPE === '5050') {
    //       edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
    //       edtAuthNo.value = String(obj.MESSAGE).substr(0, 12);
    //       edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
    //     }
    //
    //     let a17 = obj.ACQUIERCD;
    //     let a4 = obj.TID;
    //     let a14 = obj.MERCHANTNO;
    //     let a21 = obj.TRACK2DATA;
    //
    //     let d1 = String(obj.MESSAGE).substr(0, 12);
    //     let d2 = String(obj.MESSAGE).substr(12, 12);
    //
    //     let a19 = obj.CARDKBN;
    //
    //     let d3 = String(obj.MESSAGE).substr(24, 12);
    //
    //     let a16 = obj.ISSUER;
    //     let a18 = obj.ACQUIER;
    //
    //     let won_rtn = rtn;
    //
    //     let purchase_code = a17;
    //     let machine_no = a4;
    //     let company_no = a14;
    //     let card_no = a21;
    //     let permit_no = d1;
    //     let permit_time = d2;
    //     let card_gubun = a19;
    //     let unique_no = d3;
    //
    //     item_form.purchase_code.value = purchase_code;
    //     item_form.machine_no.value = machine_no;
    //     item_form.company_no.value = company_no;
    //     item_form.card_no.value = card_no;
    //     item_form.permit_no.value = permit_no;
    //     item_form.permit_time.value = permit_time;
    //     item_form.card_gubun.value = card_gubun;
    //     item_form.unique_no.value = unique_no;
    //
    //     if (item_form.payCard.value === '2222') {
    //       let month_card = item_form.month_card.value;
    //     } else {
    //       let month_card = '00';
    //     }
    //
    //     let price = item_form.pay_price.value;
    //
    //     let c1 = '본사';
    //     let c2 = '';
    //     let c3 = '이규정';
    //     let c4 = '';
    //     let c5 = '';
    //
    //     let c6 = '';
    //     c6 = a21.substr(0, 4) + '-' + a21.substr(4, 4) + '-' + a21.substr(8, 4) + '-' + a21.substr(12, 4);
    //
    //     if (item_form.payCard.value === '2222') {
    //       let c7 = a16.trim();
    //       let c8 = a18.trim();
    //     }
    //
    //     let c9 = '';
    //     c9 = d2.substr(0, 2) + '/' + d2.substr(2, 2) + '/' + d2.substr(4, 2) + ' ' + d2.substr(6, 2) + ':' + d2.substr(8, 2) + ':' + d2.substr(10, 2);
    //
    //     let c10 = '';
    //     if (a19 === 'CK') {
    //       c10 = '신용승인(';
    //       if (month_card === '00') {
    //         c10 += '일시불)';
    //       } else {
    //         c10 += '할부' + month_card + '개월)';
    //       }
    //     } else {
    //       c10 = '체크승인)';
    //       if (month_card === '00') {
    //         c10 += '일시불)';
    //       } else {
    //         c10 += '할부' + month_card + '개월)';
    //       }
    //     }
    //
    //     if (item_form.payCard.value === '2222') {
    //       let c11 = d1.trim();
    //       let c12 = a4.trim();
    //       let c13 = a14.trim();
    //     }
    //
    //     price = cfNumeric(price);
    //
    //     let c17 = '';
    //     if (price >= 50000) {
    //       c17 = 'Y';
    //     } else {
    //       c17 = 'N';
    //     }
    //
    //     SAL_AMT = price;
    //     SAL_VAT = (SAL_AMT / 1.1) * 0.1;
    //     SAL_PRICE = Math.round(SAL_AMT - SAL_VAT);
    //     SAL_VAT = Math.round(SAL_VAT);
    //
    //     let c14 = commaNum(SAL_PRICE);
    //     let c15 = commaNum(SAL_VAT);
    //     let c16 = commaNum(price);
    //
    //     let receipt_info = c1 + '|' + c2 + '|' + c3 + '|' + c4 + '|' + c5 + '|' + c6 + '|' + c7 + '|' + c8 + '|' + c9 + '|' + c10 + '|' + c11 + '|' + c12 + '|' + c13 + '|' + c14 + '|' + c15 + '|' + c16 + '|' + c17;
    //
    //     item_form.rtn.value = won_rtn;
    //     item_form.receipt_info.value = receipt_info;
    //
    //     if (item_form.payCard.value === '2222') {
    //       item_form.print_YN.value = 'Y';
    //     } else {
    //       let new_hp = obj.TRACK2DATA;
    //       item_form.cash_hp.value = new_hp;
    //       item_form.print_YN.value = 'N';
    //     }
    //     go_submit();
    //   } else {
    //     let dda = obj.ERROR_CHECK_MESSAGE;
    //     alert(dda + '\r정상적으로 승인되지 않았습니다. 잠시후 다시 시도해주세요');
    //     service_ok.innerHTML = "<a class='btn btn-danger' onclick='pre_go_submit();'>서비스 신청</a>";
    //   }
    // }
    //
    // taResponse.value += '\n';
    // taResponse.value += '===================================================\n';
    // taResponse.value += '응답 전문: ' + String(rtn);
    // taResponse.value += '\n';
    // taResponse.value += '===================================================\n';
  }

  //  52-1.
  function ShowLoadingImage() {
  }

  function HideLoadingImage() {
  }

  function httpRequest(api, data) {
    const portNo = '';

    ShowLoadingImage(); // Show a loading image or spinner

    // Create a new XMLHttpRequest object
    var xmlHttp = new XMLHttpRequest();

    // Define a callback function that will be called when the readyState changes
    xmlHttp.onreadystatechange = function () {
      if (this.readyState == XMLHttpRequest.DONE) {
        HideLoadingImage(); // Hide the loading image or spinner

        // Check if the request was successful (HTTP status code 200)
        if (this.status == 200) {
          var rtn = xmlHttp.responseText; // Get the response text
          console.log('recieve');

          // Call the ParseHttpResponse function to parse and handle the response
          ParseHttpResponse(rtn);
        }
      } else {
        console.log('waiting...');
      }
    };

    // Set a timeout function to handle when the request exceeds the specified time
    xmlHttp.ontimeout = function (e) {
      alert("시간초과! 다시 시도해주세요");
      window.location.href = "member_view.html";
    };

    // Set the URL for the request
    var url = 'http';
    if (document.querySelector('input[name="HttpType"]:checked').value == 0)
      url += 's';
    url += '://127.0.0.1:';
    url += portNo.value;
    url += api;

    // Open the request with the POST method
    xmlHttp.open("POST", url, true);

    // Set the request header for the content type
    xmlHttp.setRequestHeader("Content-Type", "text/plain;charset=utf-8");

    // Send the request with the provided data
    xmlHttp.send(data);

    //console.log('send'); // Log the request being sent
  }

  //  53-1.
  const writeTo = () => {
    const taskType = document.querySelector('input[name="radioTaskType"]:checked').value;

    switch (taskType) {
      case '0':
        SendPayment();
        break;

      case '1':
        SendInfo();
        break;
    }
  };

  const SendInfo = () => {
    // Code for sending information goes here
  };

  //  54-1.
  // State variables
  // const [cardNum100, setCardNum100] = useState('');
  // const [amount12, setAmount12] = useState('');
  // const [tax12, setTax12] = useState('');
  // const [tip12, setTip12] = useState('');
  // const [taxFree12, setTaxFree12] = useState('');
  // const [amount9, setAmount9] = useState('');
  // const [tax9, setTax9] = useState('');
  // const [tip9, setTip9] = useState('');
  // const [taxFree9, setTaxFree9] = useState('');
  // const [recycleUse, setRecycleUse] = useState('');
  // const [recycleRcdAmt9, setRecycleRcdAmt9] = useState('');
  //
  // // Other state variables
  // const [inputData, setInputData] = useState('');
  // const [taResponse, setTaResponse] = useState('');
  //
  // // Function to handle payment submission
  const SendPayment = () => {
    //   // 가맹점 단말기 tid
    //   if (txtTID.value.length !== 10) {
    //     alert("단말기 TID 자리수 확인(10자리)");
    //     txtTID.focus();
    //     return;
    //   }
    //
    //   if (
    //       edtCardNum.value.length > 0 &&
    //       (edtCardNum.value.length < 10 || edtCardNum.value.length > 98)
    //   ) {
    //     alert("카드번호 자리수 확인(10자리 이상)");
    //     edtCardNum.focus();
    //     return;
    //   }
    //
    //   var cardPaddingLen;
    //   cardPaddingLen = 100 - edtCardNum.value.length - 2;
    //   if (WCC.value === 'K') {
    //     setCardNum100(
    //         edtCardNum.value.length + edtCardNum.value + fillSpace(cardPaddingLen)
    //     );
    //   } else if (WCC.value === 'A') {
    //     setCardNum100(
    //         edtCardNum.value.length + edtCardNum.value + fillSpace(cardPaddingLen)
    //     );
    //   } else {
    //     setCardNum100(fillSpace(100));
    //   }
    //
    //   // 거래금액 입력.
    //   if (edtAmount.value.length > 9) {
    //     alert("거래금액 자리수 확인(9자리 이하)");
    //     edtAmount.focus();
    //     return;
    //   }
    //   var amountLen = 9 - edtAmount.value.length;
    //   setAmount9(fillZero(amountLen) + edtAmount.value);
    //   setAmount12('000' + amount9);
    //
    //   // 세금 입력.
    //   if (edtTax.value.length > 9) {
    //     alert("세금 자리수 확인(9자리 이하)");
    //     edtTax.focus();
    //     return;
    //   }
    //   var taxLen = 9 - edtTax.value.length;
    //   setTax9(fillZero(taxLen) + edtTax.value);
    //   setTax12('000' + tax9);
    //
    //   // 봉사료 입력.
    //   if (edtTip.value.length > 9) {
    //     alert("봉사료 자리수 확인(9자리 이하)");
    //     edtTip.focus();
    //     return;
    //   }
    //   var tipLen = 9 - edtTip.value.length;
    //   setTip9(fillZero(tipLen) + edtTip.value);
    //   setTip12('000' + tip9);
    //
    //   // 비과세 입력.
    //   if (edtTaxFree.value.length > 9) {
    //     alert("비과세 자리수 확인(9자리 이하)");
    //     edtTaxFree.focus();
    //     return;
    //   }
    //   var taxFreeLen = 9 - edtTaxFree.value.length;
    //   setTaxFree9(fillZero(taxFreeLen) + edtTaxFree.value);
    //   setTaxFree12('000' + taxFree9);
    //
    //   setRecycleUse(
    //       document.querySelector('input[name="recycleType"]:checked').value
    //   );
    //
    //   if (recycleUse === 1) {
    //     if (recycleAmt.value.length > 9) {
    //       alert("비과세 자리수 확인(9자리 이하)");
    //       recycleAmt.focus();
    //       return;
    //     }
    //     var recycleAmtLen = 9 - recycleAmt.value.length;
    //     setRecycleRcdAmt9('RCD' + fillZero(recycleAmtLen) + recycleAmt.value);
    //   }
    //
    //   var installment = "00";
    //   if (edtInstallment.value.length !== 2) {
    //     // 카드 결제 관련 입력값 검사.
    //     alert("할부 개월수 확인(00:일시불, 01~12:개월수");
    //     edtInstallment.focus();
    //     return;
    //   }
    //   installment = edtInstallment.value;
    //
    //   // 거래요청 전문 생성
    //   authType = document.querySelector('#authType option:checked').value;
    //   payType = document.querySelector('#payType').value;
    //
    //   // 취소인 경우 원 거래일시 / 원 거래번호 / 원거래고유번호 필요.
    //   if (
    //       authType === '1050' ||
    //       authType === '1052' ||
    //       authType === '1060' ||
    //       authType === '5050'
    //   ) {
    //     // 원 거래일시 입력.
    //     if (edtAuthDate.value.length !== 6) {
    //       alert("원 거래일시 확인(6자리)");
    //       edtAuthDate.focus();
    //       return;
    //     }
    //     //authDate = edtAuthDate.value; // 6자리 'YYMMDD'
    //
    //     // 원 거래번호 입력.
    //     if (edtAuthNo.value.length !== 12) {
    //       alert("원 거래번호 확인(12자리 이하)");
    //       edtAuthNo.focus();
    //       return;
    //     }
    //     //var authNoLen = 12 - edtAuthNo.value.length; // 12자리
    //     //authNo = edtAuthNo.value + fillSpace(authNoLen);
    //
    //     // 원거래 고유번호 입력.
    //     if (edtAuthUniqueNo.value.length !== 12) {
    //       alert("원거래 고유번호 확인(12자리 이하)");
    //       edtAuthUniqueNo.focus();
    //       return;
    //     }
    //     //var authUniqueNoLen = 12 - edtAuthUniqueNo.value.length;
    //     //authUniqueNo = edtAuthUniqueNo.value + fillSpace(authUniqueNoLen); // 12자리
    //   }
    //
    //   if (authType === '5010' || authType === '5050') {
    //     // 현금 영수증 승인,취소
    //     inputData = '';
    //     inputData += makeCash(authType);
    //   }
    //   if (
    //       authType === '1010' ||
    //       authType === '1050' ||
    //       authType === '1012' ||
    //       authType === '1052'
    //   ) {
    //     inputData = '';
    //     inputData += makeCredit(authType);
    //   }
    //
    //   /*
    // ##########  추후 적용 ################
    // // 카카오페이 승인조회/취소조회
    // else if (authType == '8040' || authType == '8041') {
    //   inputData = '';
    //   inputData += makeKakaoPay(authType);
    // }
    // // 통합간편결제 승인조회/취소조회
    // else if (authType == '80990' || authType == '80991') {
    //   inputData = '';
    //   inputData += makeEasyPay(authType);
    // }
    // // 통합간편결제 승인조회/취소조회
    // else if (authType == '8020' || authType == '8021') {
    //   inputData = '';
    //   inputData += makeLPay(authType);
    // }
    // // 현금IC 승인/취소
    // else if (authType == '8080' || authType == '8081')
    // {
    //   inputData = '';
    //   inputData += makeCashIC(authType);
    // }
    // // 현금IC 잔액조회
    // else if (authType == '8084')
    // {
    //   inputData = '';
    //   inputData += makeCashICQuery(authType);
    // }
    // // 제로페이 승인/취소
    // else if (authType == '8050' || authType == '8051')
    // {
    //   inputData = '';
    //   inputData += makeZeroPay(authType);
    // }
    // // 유니온/BC QR - 네이버페이 및 하나앱카드
    // else if (authType == '1014A' || authType == '1014C') {
    //   inputData = '';
    //   inputData += makeQRCode(authType);
    // }
    // // SSG페이 승인조회/취소조회
    // else if (authType == '8030' || authType == '8031') {
    //   inputData = '';
    //   inputData += makeSSGPay(authType);
    // }
    // // SSG페이 확정
    // else if (authType == '8036')
    // {
    //   inputData = '';
    //   inputData += makeSSGPayConfirm(authType);
    // }
    // // 알리페이
    // else if (authType == '8046' || authType == '8047')
    // {
    //   inputData = '';
    //   inputData += makeAliPay(authType);
    // }
    // // 수표조회
    // else if (authType == '6080')
    // {
    //   inputData = '';
    //   inputData += makeCheck(authType);
    // }
    // // 포인트
    // else if (authType.slice(0,2) == '30')
    // {
    //   inputData = '';
    //   inputData += makePoint(authType);
    // }
    // // 멤버쉽
    // else if (authType.slice(0,2) == '40')
    // {
    //   inputData = '';
    //   inputData += makeMembership(authType);
    // }
    // #############################################
    // */
    //
    //   // 승인 요청 전문 출력
    //   taResponse.value += '요청 전문: ' + String(inputData);
    //
    //   // http 전송
    //   var api = '/tPayDaemon/Auth';
    //   httpRequest(api, inputData);
  }

  //  55-1.
  // const makeCredit = (authType) => {
  //   let new_time = nds();
  //   new_time = new_time.trim();
  //
  //   let packet = '';
  //   packet = jsonInsert(packet, 'TIMEOUT', '02');
  //   packet = jsonInsert(packet, 'MSGTYPE', String(authType)); // 전문종류
  //   packet = jsonInsert(packet, 'TID', txtTID.value); // 단말기번호
  //   packet = jsonInsert(packet, 'MSGNO', new_time); // 전문번호
  //   packet = jsonInsert(packet, 'TRANSTIME', getTime_YYMMDDHHMMSS()); // 전문생성일자
  //
  //   if (WCC.value !== ' ') {
  //     packet = jsonInsert(packet, 'WCC', WCC.value);
  //     packet = jsonInsert(packet, 'TRACK2DATA', cardNum100);
  //   }
  //
  //   packet = jsonInsert(packet, 'INSTALLMENT', edtInstallment.value);
  //   packet = jsonInsert(packet, 'AMOUNT', amount9);
  //   packet = jsonInsert(packet, 'TAX', tax9);
  //   packet = jsonInsert(packet, 'SERVICE', tip9);
  //   packet = jsonInsert(packet, 'CURRENCY', 'KRW');
  //
  //   if (authType === '1010' || authType === '1012') {
  //     // packet = jsonInsert(packet, 'TRANSDATE', fillSpace(6));
  //     // packet = jsonInsert(packet, 'AUTHORITY', fillSpace(12));
  //     // packet = jsonInsert(packet, 'ORGAUTHNO', fillSpace(12));
  //   } else {
  //     packet = jsonInsert(packet, 'TRANSDATE', edtAuthDate.value);
  //     packet = jsonInsert(packet, 'AUTHORITY', edtAuthNo.value);
  //     // packet = jsonInsert(packet, 'ORGAUTHNO', edtAuthUniqueNo.value); // 직전취소 설정
  //   }
  //
  //   packet = jsonInsert(packet, 'NOTAX', taxFree9); // 비과세금액
  //   /*
  //   packet = jsonInsert(packet, 'PINNO', fillSpace(18)); // 비밀번호
  //   packet = jsonInsert(packet, 'OILINFO', fillSpace(24)); // 주유정보
  //   packet = jsonInsert(packet, 'SITEBIZNO', fillSpace(10)); // 하위 사업자 번호
  //   */
  //   packet = jsonInsert(packet, 'POSSERIALNO', 'JTPOSDM16011E278'); // pos 시리얼 번호
  //
  //   // packet = jsonInsert(packet, 'ADDINFO1', fillSpace(32)); // 부가정보
  //   // packet = jsonInsert(packet, 'ADDINFO2', fillSpace(128)); // 부가정보2
  //   if (recycleUse === 1) {
  //     packet = jsonInsert(packet, 'ADDINFO2', fillSpace(64) + recycleRcdAmt9 + fillSpace(52)); // 부가정보2
  //   }
  //   // packet = jsonInsert(packet, 'RESERVED', fillSpace(64)); // reserved
  //
  //   packet = jsonInsert(packet, 'SIGNKBN', ' '); // 서명 여부
  //   packet = jsonInsert(packet, 'CR', String.fromCharCode(0x0d)); // CR
  //
  //   return packet;
  // };

  //  56-1.
  // const makeCash = (authType) => {
  //   let packet;
  //
  //   let new_time = nds();
  //   new_time = new_time.trim();
  //
  //   packet = '';
  //   packet = jsonInsert(packet, 'TIMEOUT', '02');
  //   packet = jsonInsert(packet, 'MSGTYPE', String(authType));
  //   packet = jsonInsert(packet, 'TID', txtTID.value); // TID
  //   packet = jsonInsert(packet, 'MSGNO', new_time); // 전문관리번호 (POS가 거래를 확인 하기 위한 번호)
  //   packet = jsonInsert(packet, 'TRANSTIME', getTime_YYMMDDHHMMSS()); // 전문 생성 일자
  //
  //   if (WCC.value !== ' ') {
  //     packet = jsonInsert(packet, 'WCC', WCC.value);
  //     packet = jsonInsert(packet, 'TRACK2DATA', cardNum100);
  //   }
  //   packet = jsonInsert(packet, 'AMOUNT', amount9);
  //   packet = jsonInsert(packet, 'TAX', tax9);
  //   packet = jsonInsert(packet, 'SERVICE', tip9);
  //
  //   if (item_form.cash_p_gubun.value === "0") {
  //     // inputData += "0";    //거래구분자 -개인
  //     packet = jsonInsert(packet, 'AUTH_TYPE', '0'); // 개인
  //   } else {
  //     // inputData += "1";    //거래구분자 -사업자
  //     packet = jsonInsert(packet, 'AUTH_TYPE', '1'); // 사업자
  //   }
  //
  //   if (authType === '5010') {
  //
  //   } else // 취소 인 경우
  //   {
  //     packet = jsonInsert(packet, 'TRANSDATE', edtAuthDate.value); // 원거래일자
  //     packet = jsonInsert(packet, 'AUTHORITY', edtAuthNo.value); // 원승인번호
  //     packet = jsonInsert(packet, 'CANCELCODE', '1');
  //   }
  //   packet = jsonInsert(packet, 'NOTAX', taxFree9);
  //
  //   if (recycleUse === 1) {
  //     packet = jsonInsert(packet, 'ADDINFO2', recycleRcdAmt9 + fillSpace(20));
  //   }
  //
  //   packet = jsonInsert(packet, 'CR', String.fromCharCode(0x0d));
  //
  //   return packet;
  // };

  //  57-1.
  const writeTo_old = () => {
    const taskType = document.querySelector('input[name="radioTaskType"]:checked').value;

    switch (taskType) {
      // 거래요청
      case '0': {
        SendPayment();
      }
        break;

      // 기능요청
      case '1': {
        SendInfo();
      }
        break;

      // 출력요청
      case '2': {
        PrintTo();
      }
        break;
    }
  };

  //  58-1.
  //  SendPayment_old 변환 실패 나중에 다시 확인

  //  59-1.
  const print_fee = (base_seq) => {
    const url = `print_pay.html?base_seq=${base_seq}`;
    const windowFeatures = 'width=300,height=150,scrollbars=no';
    window.open(url, 'locker_select', windowFeatures);
  };

  //  60-1.
  function PrintTo() {
    var taskType = document.querySelector('input[name="radioTaskType"]:checked').value;
    var subCmd = document.querySelector('input[name="radioPrintType"]:checked').value;
    var inputData = "";

    if (subCmd == "0") {
      inputData = "\n";
      inputData += "현금(자진발급) 매출표\n";
      inputData += "고객용(첫장)\n";
      inputData += "==========================================\n";
      inputData += "CATID:2393300001 처리번호:0001\n";
      inputData += "거래유형:현금승인\n";
      inputData += "010-***-1234\n";
      inputData += "거래일시:09/06/23 09:43:54\n";
      inputData += "==========================================\n";
      inputData += "판매금액 21,111,111,111,111,111,111,111\n";
      inputData += "부 가 세 21000000000000000000\n";
      inputData += "봉 사 료 210099999999999000\n";
      inputData += "합    계 2110000000000000\n";
      inputData += " 승인번호: 2123123123123\n";
      inputData += "==========================================\n";
      inputData += "매입사명:소비자\n";
      inputData += "가맹점번호:\n";
      inputData += "SKT001/K/CDMA\n";
      inputData += "가맹점명: JTNet\n";
      inputData += "사업자번호:1078155843\n";
      inputData += "주소:서울 마포구 아현동 686크레딧센터 빌딩\n";
      inputData += "------------------------------------------\n";
      inputData += "소득공제 신청은\n";
      inputData += "HTTP://현금영수증.kr\n";
      inputData += "TEL:02-2187-2787\n";
      inputData += "\n\n\n\n\n";

      // 절단
      inputData += String.fromCharCode(27);
      inputData += String.fromCharCode(105);
      inputData += String.fromCharCode(0);
    } else if (subCmd == "1") {
      inputData = "20171116113733" + fillSpace(32);
    }

    // var rtn = objEncPCPOS.TPayPosAuthSerial(parseInt(taskType), parseInt(subCmd), String(inputData));

    // 전문 응답 처리
    // taResponse.value += "[ " + rtn + " ]\n";
  }

  //  61-1.
  const [parsedObj, setParsedObj] = useState(null);

  const ParseToJSONObj = (instr) => {
    const obj = {};

    obj.status = instr.substring(0, 1);

    if (obj.status === 'E') {
      obj.error_msg = instr.substring(5, 69);
      obj.data = instr;
    } else {
      obj.app_type = instr.substring(5, 9);
      obj.pos_unique_no = instr.substring(9, 21);
      obj.tid = instr.substring(21, 31);
      obj.res_code = instr.substring(31, 35);
      obj.rss_msg = instr.substring(35, 99);
      obj.auth_no = instr.substring(99, 111);
      obj.trade_date = instr.substring(111, 123);
      obj.trade_unique_no = instr.substring(123, 135);
      obj.amt = instr.substring(135, 144);

      if (
        obj.app_type === '1010' ||
        obj.app_type === '1050' ||
        obj.app_type === '1012' ||
        obj.app_type === '1052'
      ) {
        obj.issuer_code = instr.substring(144, 148);
        obj.issuer_name = instr.substring(148, 168);
        obj.purchase_code = instr.substring(168, 172);
        obj.purchase_name = instr.substring(172, 192);
        obj.shop_no = instr.substring(192, 207);
        obj.purchase_type = instr.substring(207, 208);
        obj.card_type = instr.substring(208, 210);
        obj.reserve_amt = instr.substring(210, 219);
        obj.event_point = instr.substring(219, 228);
        obj.use_point = instr.substring(228, 237);
        obj.save_point = instr.substring(237, 246);
        obj.gift_bal = instr.substring(246, 255);
        obj.point_info1 = instr.substring(255, 315);
        obj.point_info2 = instr.substring(315, 375);
        obj.prt_track2data = instr.substring(375, 395);
        obj.cardco_prt = instr.substring(395, 396);
        obj.add_info1 = instr.substring(396, 411);
        obj.reserved = instr.substring(411, 443);
        obj.prt_msg1 = instr.substring(443, 471);
        obj.prt_msg2 = instr.substring(471, 499);
        obj.prt_msg3 = instr.substring(499, 527);
        obj.prt_msg4 = instr.substring(527, 555);
        obj.prt_msg5 = instr.substring(555, 583);
        obj.prt_msg6 = instr.substring(583, 611);
      } else if (obj.app_type === '5010' || obj.app_type === '5050') {
        obj.prt_title = instr.substring(144, 164);
        obj.event_point = instr.substring(164, 173);
        obj.use_point = instr.substring(173, 182);
        obj.save_point = instr.substring(182, 191);
        obj.point_info1 = instr.substring(191, 251);
        obj.prt_track2data = instr.substring(251, 271);
        obj.add_info1 = instr.substring(271, 287);
        obj.reserved = instr.substring(287, 319);
        obj.prt_msg1 = instr.substring(319, 347);
        obj.prt_msg2 = instr.substring(347, 375);
        obj.prt_msg3 = instr.substring(375, 403);
        obj.prt_msg4 = instr.substring(403, 431);
        obj.prt_msg5 = instr.substring(431, 459);
        obj.prt_msg6 = instr.substring(459, 487);
      } else {
        obj.error_msg = '알 수 없는 명령';
      }

      obj.data = instr;
    }

    return obj;
  };

  //  61-2.
  // useState(() => {
  //   const parsed = ParseToJSONObj();
  //   setParsedObj(parsed);
  // }, [instr]);
  //
  // if (!parsedObj) {
  //   return null; // Render loading state or fallback
  // }

  //  62-1.
  const fillZero = (n) => {
    let zeros = "";

    for (let i = 0; i < n; i++) {
      zeros += '0';
    }

    return zeros;
  };


  //  63-1.
  const fillSpace = (n) => {
    let spaces = "";

    for (let i = 0; i < n; i++) {
      spaces += ' ';
    }

    return spaces;
  };

  //  64-1.
  const leadingZeros = (n, digits) => {
    let zero = '';
    let num = n.toString();

    if (num.length < digits) {
      for (let i = 0; i < digits - num.length; i++) {
        zero += '0';
      }
    }

    return zero + num;
  };

  //  65-1.
  const memberFormRef = useRef(null); // TODO : FORM 참조
  const sFormRef = useRef(null); // TODO : FORM 참조
  const serviceStateFormRef = useRef(null); // TODO : FORM 참조
  const intro_reg = () => {
    // Access form values using React state or refs

    if (memberFormRef.current.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormRef.current.auth_code.value === '') {
      alert('인증코드를 입력해주세요');
      sFormRef.current.search_auth_code.focus();
      return;
    }

    const mb_no = memberFormRef.current.mb_no.value;
    const auth_code = sFormRef.current.auth_code.value;

    // Check the end_date value
    const end_date = serviceStateFormRef.current.end_date.value;
    if (end_date < '2023-06-14') {
      alert('이용기간이 종료되거나 설정되지 않은 회원은 소개회원을 등록하실수 없습니다');
      return;
    }

    popUp(`intro_list.html?mb_no=${mbNo}&auth_code=${auth_code}`, 'intro_list', '1160', '450', 'yes');
  };

  //  66-1.
  const change_point = () => {
    // Access form values using React state or refs

    if (memberFormRef.current.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormRef.current.auth_code.value === '') {
      alert('인증코드를 입력해주세요');
      sFormRef.current.search_auth_code.focus();
      return;
    }

    const mb_no = memberFormRef.current.mb_no.value;
    const auth_code = sFormRef.current.auth_code.value;

    popUp(`change_point.html?mb_no=${mbNo}&auth_code=${auth_code}`, 'change_point', '700', '350', 'no');
  };

  //  67-1.
  const online_reg = () => {
    // Access form values using React state or refs

    if (memberFormRef.current.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormRef.current.auth_code.value === '') {
      alert('인증코드를 입력해주세요');
      sFormRef.current.search_auth_code.focus();
      return;
    }

    const mb_no = memberFormRef.current.mb_no.value;
    const auth_code = sFormRef.current.auth_code.value;

    popUp(`online_list.html`, 'intro_list', '780', '450', 'yes');
  };

  //  68-1.
  const del_id = () => {
    // Access form values using React state or refs

    if (memberFormRef.current.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (sFormRef.current.auth_code.value === '') {
      alert('인증코드를 입력해주세요');
      sFormRef.current.search_auth_code.focus();
      return;
    }

    if (window.confirm('해당 회원의 아이디/비밀번호를 삭제하시겠습니까?')) {
      const mb_no = memberFormRef.current.mb_no.value;
      const auth_code = sFormRef.current.auth_code.value;

      $.ajax({
        type: "post",
        url: "id_del.php",
        data: {mb_no: mb_no, auth_code: auth_code},
        success: function (data) {
          data = data.trim();
          member_view2(data);
        }
      });
    }
  };

  //  69-1.
  function clear_calendar() {
    // Remove the calendar class and enable the input field
    // $("#it_start_date").removeAttr("class", "input_date");

    // Disable the datepicker and enable the input field
    $("#it_start_date").datepicker("option", "disabled", true);
    $("#it_start_date").removeAttr("disabled");
  }

  //  70-1.
  function clear_calendar_re() {
    // Add the calendar class to the input field
    // $("#it_start_date").attr("class", "input_date");

    // Enable the datepicker
    $("#it_start_date").datepicker('option', 'disabled', false);
  }

  //  71-1.
  //  function openDaumPostcode 확인 필요.

  //  72-1.
  const [remain_time_show, setRemain_time_show] = useState(5400000);

  const add_time = () => {
    $('#remain_time').val('5400000');
    remain_time_show.innerHTML = "30:00";
  };

  $('#remain_time').val('5400000');

  //  73-1.
  const [show_time, setShow_time] = useState('');

  const show_remain_time_new = (value) => {
    const minute = Math.floor(value / 60000);
    const second = Math.floor((value - (minute * 60000)) / 1000);
    const minuteShow = minute < 10 ? `0${minute}` : minute;
    const secondShow = second < 10 ? `0${second}` : second;
    setShow_time(`${minuteShow}:${secondShow}`);

    remain_time_show.innerHTML = show_time;
  };

  //  74-1.
  const timer_new2 = () => {
    const start_time = $("#remain_time").val();
    let end_time = start_time - 1000;

    if (end_time <= 0) {
      window.location.href = "/login/logout_process.php";
    }

    show_remain_time_new(end_time);

    $("#remain_time").val(end_time);
  }

  // setInterval("timer_new2()", 1000);

  //  75-1.
  // s_form.search_txt.focus();
  const member_view = (mb_no) => {
    const {common_i_member_view} = this.props;
    member_form.edit_YN.value = 'Y';
    member_form.mb_no.value = mb_no;
    common_i_member_view.location.href = 'member_view.php?mb_no=' + mb_no;
    s_form.search_auth_code.focus();
  }

  //  76-1.
  const member_view2 = (mb_no) => {
    const {common_i_member_view} = this.props;
    member_form.edit_YN.value = 'Y';
    member_form.mb_no.value = mb_no;
    common_i_member_view.location.href = 'member_view.php?mb_no=' + mb_no;
    s_form.search_txt.focus();
  };

  //  77-1.
  const member_view3 = (mb_no) => {
    const {common_i_member_view} = this.props;
    member_form.edit_memo.value = '';
    member_form.edit_YN.value = 'Y';
    member_form.mb_no.value = mb_no;
    common_i_member_view.location.href = 'member_view.php?mb_no=' + mb_no;
    s_form.search_txt.focus();
  }

  //  78-1.
  const service_period_setting = (ap_date, mbNo) => {
    if (s_form.auth_code.value === '') {
      alert("인증코드를 입력해주세요");
      window.s_form.search_auth_code.focus();
      return;
    } else {
      popUp(`app_period_setting.html?ap_date=${ap_date}&mb_no=${mbNo}`, "period_set", "700", "400", "yes");
    }
  };

  //  79-1.
  const not_use_del = (deNo, mbNo) => {
    if (s_form.auth_code.value === '') {
      alert("인증코드를 입력해주세요");
      window.s_form.search_auth_code.focus();
      return;
    } else {
      if (window.confirm('휴회기간을 삭제하시겠습니까?')) {
        axios
          .post('not_use_del.php', {
            de_no: deNo,
            mb_no: mbNo,
            auth_code: s_form.auth_code.value,
            receive_name: s_form.reg_step.value
          })
          .then(response => {
            window.member_view(mbNo);
          })
          .catch(error => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          });
      } else {
        // User canceled deletion
      }
    }
  };

  //  80-1.
  const sevice_del = (ap_no, mb_no) => {
    if (member_form.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (s_form.auth_code.value) {
      if (window.confirm('등록된 서비스를 삭제하시겠습니까?')) {
        axios
          .post('service_del.php', {
            ap_no: ap_no,
            mb_no: mb_no,
            auth_code: s_form.auth_code.value,
            receive_name: s_form.reg_step.value
          })
          .then(response => {
            alert('삭제되었습니다');
            window.member_view(mb_no);
          })
          .catch(error => {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          });
      } else {
        // User canceled deletion
      }
    } else {
      alert('인증코드를 입력해주세요');
      s_form.search_auth_code.focus();
      return;
    }
  };

  //  81-1.
  const sevice_date_change = (ap_no, mb_no) => {
    if (member_form.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (s_form.auth_code.value) {
      popUp(`service_date_change.html?ap_no=${ap_no}&mb_no=${mb_no}`, 'sevice_date_change', '500', '300', 'no');
    } else {
      alert('인증코드를 입력해주세요');
      s_form.search_auth_code.focus();
      return;
    }
  };

  //  82-1.
  const ipkum_date_change = (ip_no, mb_no) => {
    if (member_form.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (s_form.auth_code.value) {
      popUp(`ipkum_date_change.html?ip_no=${ip_no}&mb_no=${mb_no}`, 'ipkum_date_change', '500', '300', 'no');
    } else {
      alert('인증코드를 입력해주세요');
      s_form.search_auth_code.focus();
      return;
    }
  };

  //  83-1.
  const edit_ipkum = (ip_no) => {
    if (member_form.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }
    if (s_form.auth_code.value === '') {
      alert('인증코드를 입력해주세요');
      window.s_form.search_auth_code.focus();
      return;
    }

    popUp(`edit_ipkum.html?ip_no=${ip_no}&receive_name=${s_form.reg_step.value}`, 'edit_ipkum', '700', '400', 'yes');
  };

  //  84-1.
  const ipkum_del = (base_ip_no, mb_no) => {
    if (member_form.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (s_form.auth_code.value) {
      if (window.confirm('등록된 입금내역을 삭제하시겠습니까?')) {
        $.ajax({
          type: 'post',
          url: 'ipkum_del.php',
          data:
            'base_ip_no=' +
            base_ip_no +
            '&mb_no=' +
            mb_no +
            '&auth_code=' +
            s_form.auth_code.value +
            '&receive_name=' +
            s_form.reg_step.value,
          success: function (data) {
            alert('삭제되었습니다');
            window.member_view(mb_no);
          },
          error: function (e) {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          },
        });
      } else {
      }
    } else {
      alert('인증코드를 입력해주세요');
      s_form.search_auth_code.focus();
      return;
    }
  };

  //  85-1.
  const delay_del = (de_no, mb_no) => {
    if (member_form.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (s_form.auth_code.value) {
      if (window.confirm('등록된 연기내역을 삭제하시겠습니까?')) {
        $.ajax({
          type: 'post',
          url: 'member_delay_save.php',
          data:
            'type=del&de_no=' +
            de_no +
            '&mb_no=' +
            mb_no +
            '&auth_code=' +
            s_form.auth_code.value +
            '&receive_name=' +
            s_form.reg_step.value,
          success: function (data) {
            data = data.trim();
            alert('연기내역이 삭제되었습니다');
            window.member_view(mbNo);
          },
          error: function (e) {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
          },
        });
      } else {
      }
    } else {
      alert('인증코드를 입력해주세요');
      s_form.search_auth_code.focus();
      return;
    }
  };

  //  86-1.
  const reg_step_edit = (ap_no, mb_no, reg_step_seq) => {
    if (member_form.mb_no.value === '') {
      alert('회원이 정상적으로 선택되지 않았습니다. 다시 검색해주세요');
      return;
    }

    if (!reg_step_seq) {
      alert('SMMS개발전 데이터는 접수자 변경이 불가능합니다');
      return;
    }

    if (s_form.auth_code.value) {
      popUp(`edit_reg_step.html?ap_no=${ap_no}&mb_no=${mb_no}`, 'edit_reg_step', '300', '250', 'no');
    } else {
      alert('인증코드를 입력해주세요');
      s_form.search_auth_code.focus();
      return;
    }
  };


  return (<CmnCard onLogout={props.onLogout}>
    <div className="m-3" style={{border: "1px solid #eee", borderRadius: "12px", background: "#F8F8FA"}}>
      <div className="border-bottom text-left">
        <p className="px-3 py-2"><span className="h6 bold700">회원등록</span></p>
      </div>
      {/* 컨텐츠 내용 시작 */}
      <div className="p-3">
        <div className="d-flex justify-content-between">
          <div>
            <div className="USER_thum_regi">
              <p className="absmiddle">
                <button type="button" className="btn btn-ssm btn-dark space_nowrap">+ 사진등록</button>
              </p>
            </div>
            <p className="mt-1">500*500 이상, 1Mbyte 이내</p>
          </div>
          <div style={{width: "calc(100% - 210px)"}}>
            <table className="view-rounded sub_view">
              <colgroup>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>회원번호</th>
                <td><input type="text" className="form-control" aria-describedby="button-addon2"/></td>
                <th>이름</th>
                <td><input type="text" className="form-control" aria-describedby="button-addon2"/></td>
                <th>연락처</th>
                <td><input type="text" className="form-control" aria-describedby="button-addon2"/></td>
                <th>생일</th>
                <td><input type="text" className="form-control" aria-describedby="button-addon2"/></td>
              </tr>
              <tr>
                <th>성별</th>
                <td>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                    <label className="form-check-label" htmlFor="inlineRadio1">남</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                    <label className="form-check-label" htmlFor="inlineRadio2">여</label>
                  </div>
                </td>
                <th>가입경로</th>
                <td>
                  <select className="form-select" aria-label="Default select example">
                    <option selected>지인추천</option>
                    <option value="1">강남구</option>
                  </select>
                </td>
                <th>주소</th>
                <td colSpan="3">
                  <div className="input-group">
                    <button className="btn btn-ssm btn-dark" type="button" id="button-addon1">검색</button>
                    <input type="text" className="form-control" placeholder="" style={{height: "32px"}}/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>알림</th>
                <td colSpan="5"><input type="text" className="form-control" aria-describedby="button-addon2"/></td>
                <th>아이디</th>
                <td>abcdefg1234</td>
              </tr>
              <tr>
                <th>메모</th>
                <td colSpan="3">-</td>
                <th>마케팅 동의</th>
                <td colSpan="3">
                  <div className="d-flex justify-content-center flex-wrap">
                    <div>
                      <span className="px-2 bold700 vertical-middle">마케팅동의 :</span>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                        <label className="form-check-label" htmlFor="inlineRadio1">동의</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                        <label className="form-check-label" htmlFor="inlineRadio2">미동의</label>
                      </div>
                    </div>
                    <p className="px-2 bold700 vertical-middle">/</p>
                    <div>
                      <span className="px-2 bold700 vertical-middle">스포애니몰 동의 :</span>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio11" value="option11"/>
                        <label className="form-check-label" htmlFor="inlineRadio11">동의</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio12" value="option12"/>
                        <label className="form-check-label" htmlFor="inlineRadio12">미동의</label>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div className="text-right mt-2">
              <button type="button" className="btn btn-sm btn-point">회원정보 수정</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="m-3" style={{border: "1px solid #eee", borderRadius: "12px"}}>
      <div className="border-bottom text-left">
        <p className="px-3 py-2"><span className="h6 bold700">회원정보</span></p>
      </div>
      {/* 컨텐츠 내용 시작 */}
      <div className="p-3">
        <div className="d-flex justify-content-between">
          <div className="pr-2 USER_thum">{/*<img src="images/Rectangle%2017865.png" alt="" />*/}</div>
          <div style={{width: "calc(100% - 210px)"}}>
            <table className="view-rounded sub_view">
              <colgroup>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>회원번호</th>
                <td>1234567890</td>
                <th>이름</th>
                <td>John Doe</td>
                <th>연락처</th>
                <td>010-1234-5678</td>
                <th>생일</th>
                <td>1990-01-01</td>
              </tr>
              <tr>
                <th>성별</th>
                <td>남</td>
                <th>가입경로</th>
                <td>지인추천</td>
                <th>주소</th>
                <td colSpan="3">서울특별시 강남구 테헤란로 123</td>
              </tr>
              <tr>
                <th>알림</th>
                <td colSpan="5">이메일, 문자메시지</td>
                <th>아이디</th>
                <td>abcdefg1234</td>
              </tr>
              <tr>
                <th>메모</th>
                <td colSpan="3">-</td>
                <th>마케팅 동의</th>
                <td colSpan="3">
                  <div className="d-flex justify-content-center flex-wrap">
                    <div>
                      <span className="px-2 bold700 vertical-middle">마케팅동의 :</span>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio3" value="option3"/>
                        <label className="form-check-label" htmlFor="inlineRadio3">동의</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio4" value="option4"/>
                        <label className="form-check-label" htmlFor="inlineRadio4">미동의</label>
                      </div>
                    </div>
                    <p className="px-2 bold700 vertical-middle">/</p>
                    <div>
                      <span className="px-2 bold700 vertical-middle">스포애니몰 동의 :</span>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio5" value="option5"/>
                        <label className="form-check-label" htmlFor="inlineRadio5">동의</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio6" value="option6"/>
                        <label className="form-check-label" htmlFor="inlineRadio6">미동의</label>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div className="text-right mt-2">
              <button type="button" className="btn btn-sm btn-dark">회원정보 수정</button>
              <button type="button" className="btn btn-sm btn-danger ml-1">회원탈퇴</button>
            </div>
          </div>
        </div>
      </div>

      <div className="p-3">
        <div className="d-flex justify-content-between">
          <div className="pr-2 USER_thum">{/*<img src="images/Rectangle%2017865.png" alt="" />*/}</div>
          <div style={{width: "calc(100% - 210px)"}}>
            <table className="view-rounded sub_view">
              <colgroup>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
                <col width="108px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>회원번호</th>
                <td>1234567890</td>
                <th>이름</th>
                <td>John Doe</td>
                <th>연락처</th>
                <td>010-1234-5678</td>
                <th>생일</th>
                <td>1990-01-01</td>
              </tr>
              <tr>
                <th>성별</th>
                <td>남</td>
                <th>가입경로</th>
                <td>지인추천</td>
                <th>주소</th>
                <td colSpan="3">서울특별시 강남구 테헤란로 123</td>
              </tr>
              <tr>
                <th>알림</th>
                <td colSpan="5">이메일, 문자메시지</td>
                <th>아이디</th>
                <td>abcdefg1234</td>
              </tr>
              <tr>
                <th>메모</th>
                <td colSpan="3">-</td>
                <th>마케팅 동의</th>
                <td colSpan="3">
                  <div className="d-flex justify-content-center flex-wrap">
                    <div>
                      <span className="px-2 bold700 vertical-middle">마케팅동의 :</span>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio3" value="option3"/>
                        <label className="form-check-label" htmlFor="inlineRadio3">동의</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio4" value="option4"/>
                        <label className="form-check-label" htmlFor="inlineRadio4">미동의</label>
                      </div>
                    </div>
                    <p className="px-2 bold700 vertical-middle">/</p>
                    <div>
                      <span className="px-2 bold700 vertical-middle">스포애니몰 동의 :</span>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio5" value="option5"/>
                        <label className="form-check-label" htmlFor="inlineRadio5">동의</label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio6" value="option6"/>
                        <label className="form-check-label" htmlFor="inlineRadio6">미동의</label>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div className="text-right mt-2">
              <button type="button" className="btn btn-sm btn-dark">회원정보 수정</button>
              <button type="button" className="btn btn-sm btn-danger ml-1">회원탈퇴</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </CmnCard>);
};

export default MemberView;
