import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";

const AnalysisStateMonth = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [result, setResult] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split('-')[0]);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/analysis/state/month/ready?s_year=${$('#s_year option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/analysis/make/data/monthly/ready?s_year=${$('#modal_s_year option:selected').val()}&s_month=${$('#modal_s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function make_data(branchCode, branchName) {
    const sYear = $('#modal_s_year option:selected').val();
    const sMonth = $('#modal_s_month option:selected').val();

    const txt = `${branchName}지점의 ${sYear}년 ${sMonth}월 데이터를 생성하시겠습니까?`;

    if (window.confirm(txt)) {
      if (!$("#overlay").is(":visible"))
        Fn.showSpinner();

      axios2.postEx("/analysis/make/data/monthly", {
        s_year: sYear,
        s_month: sMonth,
        branch_code: branchCode
      })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          console.log(data, result);
          alert('생성되었습니다');
          setRenew(Fn.getRenewTime());
          loadModal();
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/new/old")}/>
          <label className="tab_item" htmlFor="aa">구분별</label>

          <input id="bb" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/period")}/>
          <label className="tab_item" htmlFor="bb">기간별</label>

          <input id="cc" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/visit")}/>
          <label className="tab_item" htmlFor="cc">경로별</label>

          <input id="dd" type="radio" name="tab_item" onClick={() => history.push("/analysis/state/week")}/>
          <label className="tab_item" htmlFor="dd">주별회원현황</label>

          <input id="ee" type="radio" name="tab_item" checked onClick={() => history.push("/analysis/state/month")}/>
          <label className="tab_item" htmlFor="ee">월별회원현황</label>
        </div>
      </div>

      <div className="overflowX ">
        <div className="d-flex m-3 table_wide_sh2">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_year">
              {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                <option key={year} value={year}>{year}년</option>
              ))}
            </select>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => setModalOpen(true)}>데이타생성</button>
          </div>
        </div>

        <div className="m-3 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_rowspan">
            <colgroup>
              <col width="7%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th rowSpan="2">월</th>
              <th rowSpan="2">유효회원</th>
              <th colSpan="4">회원권</th>
              <th colSpan="2">PT</th>
              <th colSpan="3">매출</th>
              <th colSpan="3">출석률</th>
            </tr>
            <tr>
              <th>신규등록</th>
              <th>재등록</th>
              <th>종료</th>
              <th>재등록률</th>
              <th>신규등록</th>
              <th>재등록</th>
              <th>퍼블릭</th>
              <th>PT</th>
              <th>합계</th>
              <th>미출석</th>
              <th>미출석률</th>
              <th>평균출석률</th>
            </tr>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
              <tr>
                <td>{month}월</td>
                <td>{Fn.parseInt(result?.healthVNum?.[month])}</td>
                <td>{Fn.parseInt(result?.healthNData?.[month]?.new_man)}</td>
                <td>{Fn.parseInt(result?.healthNData?.[month]?.old_man)}</td>
                <td>{Fn.parseInt(result?.healthENum?.[month])}</td>
                <td>{(Fn.parseInt(result?.healthNData?.[month]?.old_man) <= 0 || Fn.parseInt(result?.healthENum?.[month]) <= 0) ? "-" : ((Fn.parseInt(result?.healthNData?.[month]?.old_man) / Fn.parseInt(result?.healthENum?.[month])) * 100).toFixed(2) + "%"}</td>
                <td>{Fn.parseInt(result?.ptNData?.[month]?.new_man)}</td>
                <td>{Fn.parseInt(result?.ptNData?.[month]?.old_man)}</td>
                <td className="text-right">{Fn.numberWithCommas(Fn.parseInt(result?.healthNData?.[month]?.suip_price) - Fn.parseInt(result?.healthNData?.[month]?.pt_price))}</td>
                <td className="text-right">{Fn.numberWithCommas(Fn.parseInt(result?.healthNData?.[month]?.pt_price))}</td>
                <td className="text-right">{Fn.numberWithCommas(Fn.parseInt(result?.healthNData?.[month]?.suip_price))}</td>
                {/*TODO : 원본 코드 상에 att.. 관련 로직이 없음*/}
                {/*<td><?= $att_n_num ?></td>*/}
                {/*<td><?= round($att_n_num / $health_v_num * 100, 2) ?>%</td>*/}
                {/*<td><?= round($att_d_row[att_day] / $att_d_row[abl_day] * 100, 2) ?>%</td>*/}
                <td></td>
                <td>0%</td>
                <td>0%</td>
              </tr>
            ))}
          </table>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          $('#modal_s_year').val(Fn.getCurrentDate().split('-')[0]);
          $('#modal_s_month').val(Fn.getCurrentDate().split('-')[1]);
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '800px'}}>
            <div className="POP_title">
              <h5><strong>데이터 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box">
              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select className="form-select" id="modal_s_year">
                    {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <div className="pr-1">
                  <select className="form-select" id="modal_s_month">
                    {[...Array(12)].map((_, index) => {
                      const monthNumber = index + 1;
                      const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                      return (
                        <option key={monthString} value={monthString}>{monthString}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal()}>검색</button>
                </div>
              </div>
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <thead>
                  <tr>
                    <th className="w_70px">No</th>
                    <th>지점명</th>
                    <th>최종 주</th>
                    <th>생성일시</th>
                    <th>생성</th>
                  </tr>
                  </thead>
                  <tbody>
                  {modalResult?.result && modalResult?.result?.map((ret, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ret?.branch_name}</td>
                        <td>{ret?.last_week || ''}</td>
                        <td>{ret?.last_date?.replace(/-/g, '.')?.substring(2, 16) || ''}</td>
                        <td>
                          <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => make_data(ret?.branch_code, ret?.branch_name)}>생성</button>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              <div className="text-center my-3">
                <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModalOpen(false)}>닫기</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default AnalysisStateMonth;
