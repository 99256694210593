import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";
import {useHistory} from "react-router-dom";

const AnalysisBranchRankingReal = (props) => {
  const history = useHistory();

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  useEffect(() => {
    const currentDate = Fn.getCurrentDate();

    $('#s_year').val(currentDate.split("-")[0]);
    $('#s_month').val("01");
    $('#e_year').val(currentDate.split("-")[0]);
    $('#e_month').val(currentDate.split("-")[1]);
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) == 7 && localStorage.getItem("USER.systemFranYN") === "Y") {
      alert("접근 권한이 없습니다.");
      history.back();
      return;
    }

    axios2
      .post(`/analysis/branch/ranking/real/ready?search_YN=Y&s_brand=${$('#s_brand option:selected').val() || ''}&s_jibu=${$('#s_jibu option:selected').val() || ''}&s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&e_year=${$('#e_year option:selected').val() || ''}&e_month=${$('#e_month option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_brand').html() === '') {
            let optionsHTML = '<option value="">전체 브랜드</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_brand').html(optionsHTML);
          }
          if ($('#s_jibu').html() === '') {
            let optionsHTML = '<option value="">지부선택</option>';
            result?.jibuRs?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.jibu}">${list_row.jibu}</option>`;
            });
            $('#s_jibu').html(optionsHTML);
          }

          setRets(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/analysis/make/data/monthly/ready?s_year=${$('#modal_s_year option:selected').val()}&s_month=${$('#modal_s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function make_data(branchCode, branchName) {
    const sYear = $('#modal_s_year option:selected').val();
    const sMonth = $('#modal_s_month option:selected').val();

    const txt = `${branchName}지점의 ${sYear}년 ${sMonth}월 데이터를 생성하시겠습니까?`;

    if (window.confirm(txt)) {
      if (!$("#overlay").is(":visible"))
        Fn.showSpinner();

      axios2.postEx("/analysis/make/data/monthly", {
        s_year: sYear,
        s_month: sMonth,
        branch_code: branchCode
      })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          console.log(data, result);
          alert('생성되었습니다');
          setRenew(Fn.getRenewTime());
          loadModal();
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        })
        .finally(() => {
          // $(".loading_bg").fadeOut("fast");
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_brand">
          </select>
        </div>

        <div className="pr-1">
          <select className="form-select" id="s_jibu">
          </select>
        </div>

        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({ length: 4 }, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: '80px'}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>

        <p className="p-1">~</p>

        <div className="pr-1">
          <select className="form-select" id="e_year">
            {Array.from({ length: 4 }, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: '80px'}} id="e_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>

        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => setModalOpen(true)}>데이타생성</button>
        </div>
      </div>

      <div className="m-3 table_list" style={{maxWidth: "935px"}}>
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="90px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>랭킹</th>
            <th>지점명</th>
            <th>순익금액</th>
            <th>매출금액</th>
            <th>지출매출</th>
          </tr>
          {rets && rets.map((ret, i) => {
            return (
              <tr>
                <td className="text-center">{i + 1}</td>
                <td className="text-left">{ret?.branch_name}</td>
                <td className="text-right">{Fn.numberWithCommas(ret?.sun_price)}</td>
                <td className="text-right">{Fn.numberWithCommas(ret?.suip_price)}</td>
                <td className="text-right">{Fn.numberWithCommas(ret?.out_price)}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={5}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          $('#modal_s_year').val(Fn.getCurrentDate().split('-')[0]);
          $('#modal_s_month').val(Fn.getCurrentDate().split('-')[1]);
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '800px'}}>
            <div className="POP_title">
              <h5><strong>데이터 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box">
              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select className="form-select" id="modal_s_year">
                    {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <div className="pr-1">
                  <select className="form-select" id="modal_s_month">
                    {[...Array(12)].map((_, index) => {
                      const monthNumber = index + 1;
                      const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                      return (
                        <option key={monthString} value={monthString}>{monthString}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal()}>검색</button>
                </div>
              </div>
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <thead>
                  <tr>
                    <th className="w_70px">No</th>
                    <th>지점명</th>
                    <th>최종 주</th>
                    <th>생성일시</th>
                    <th>생성</th>
                  </tr>
                  </thead>
                  <tbody>
                  {modalResult?.result && modalResult?.result?.map((ret, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ret?.branch_name}</td>
                        <td>{ret?.last_week || ''}</td>
                        <td>{ret?.last_date?.replace(/-/g, '.')?.substring(2, 16) || ''}</td>
                        <td>
                          <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => make_data(ret?.branch_code, ret?.branch_name)}>생성</button>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              <div className="text-center my-3">
                <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModalOpen(false)}>닫기</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </CmnCard>
  );
};

export default AnalysisBranchRankingReal;
