import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import $ from "jquery";
import CmnCard from "../CmnCard";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";


const FinanceCard = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [result, setResult] = useState({});
  const [result2, setResult2] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('#s_date1').val(Fn.getCurrentDate());
    $('#s_date2').val(Fn.getCurrentDate());
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/finance/finance/card/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const load2 = (objParams) => {
    let strParams = "";
    if (objParams) {
      for (let [k, v] of Object.entries(objParams)) {
        strParams += `&${k}=${v}`;
      }
    }

    axios2
      .post(`/finance/finance/card?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}` + strParams)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult2(result);
          console.log(result);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="row m-3">
        <div className="col-5">
          <div className="d-flex my-3">
            <div className="d-flex">
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
            </div>
            <div className="mx-1">
              <button type="button" className="btn btn-sm btn-point px-3 space_nowrap" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
          </div>

          <div className="my-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border auto">
              <tr>
                <td colSpan="5" className="text-left px-3">
                  <span className="h7 bold700">단말기 결제</span>
                </td>
              </tr>
              <tr>
                <th>카드사</th>
                <th>정상건수</th>
                <th>금액</th>
                <th>취소건수</th>
                <th>취소금액</th>
              </tr>
              {result?.card_comp_qry?.map((row, i) =>
                <tr>
                  <td onClick={() => load2({"pay_route": "N", "card_name": row?.ck_summary})} style={{cursor: "pointer"}}>{row?.ck_summary}</td>
                  <td>{row?.ok_cnt}</td>
                  <td className="text-right"><span className="text-primary">{Fn.numberWithCommas(row?.ok_price)}원</span></td>
                  <td>{row?.cancel_cnt}</td>
                  <td className="text-right"><span className="text-danger">{Fn.numberWithCommas(row?.cancel_price)}원</span></td>
                </tr>
              )}
              {result?.card_comp_qry?.length > 0 &&
                <>
                  <tr className="bgcolor2 bold700">
                    <td>소계</td>
                    <td>{result?.T_ok_cnt}건</td>
                    <td className="text-right"><span className="text-primary">{Fn.numberWithCommas(result?.T_ok_price)}원</span></td>
                    <td>{result?.T_cancel_cnt}건</td>
                    <td className="text-right"><span className="text-danger">{Fn.numberWithCommas(result?.T_cancel_price)}원</span></td>
                  </tr>
                  <tr className="bgcolor bold700">
                    <td colSpan="4">합계</td>
                    <td className="text-right">{Fn.numberWithCommas(result?.T_total_price)}원</td>
                  </tr>
                </>
              }
              {result?.card_comp_qry?.length === 0 &&
                <tr>
                  <td colSpan={5}>※ 등록된 카드매출내역이 없습니다.</td>
                </tr>
              }
            </table>
          </div>

          <div className="my-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border auto">
              <tr>
                <td colSpan="5" className="text-left px-3">
                  <span className="h7 bold700">온라인 결제</span>
                </td>
              </tr>
              <tr>
                <th>카드사</th>
                <th>정상건수</th>
                <th>금액</th>
                <th>취소건수</th>
                <th>취소금액</th>
              </tr>
              {result?.L_card_comp_qry?.map((row, i) =>
                <tr>
                  <td onClick={() => load2({"pay_route": "L", "card_name": row?.ck_summary})} style={{cursor: "pointer"}}>{row?.ck_summary}</td>
                  <td>{row?.L_ok_cnt}</td>
                  <td className="text-right"><span className="text-primary">{Fn.numberWithCommas(row?.L_ok_price)}원</span></td>
                  <td>{row?.L_cancel_cnt}</td>
                  <td className="text-right"><span className="text-danger">{Fn.numberWithCommas(row?.L_cancel_price)}원</span></td>
                </tr>
              )}
              {result?.L_card_comp_qry?.length > 0 &&
                <>
                  <tr className="bgcolor2 bold700">
                    <td>소계</td>
                    <td>{result?.L_T_ok_cnt}건</td>
                    <td className="text-right"><span className="text-primary">{Fn.numberWithCommas(result?.L_T_ok_price)}원</span></td>
                    <td>{result?.L_T_cancel_cnt}건</td>
                    <td className="text-right"><span className="text-danger">{Fn.numberWithCommas(result?.L_T_cancel_price)}원</span></td>
                  </tr>
                  <tr className="bgcolor bold700">
                    <td colSpan="4">합계</td>
                    <td className="text-right">{Fn.numberWithCommas(result?.L_T_total_price)}원</td>
                  </tr>
                </>
              }
              {result?.L_card_comp_qry?.length === 0 &&
                <tr>
                  <td colSpan={5}>※ 등록된 카드매출내역이 없습니다.</td>
                </tr>
              }
            </table>
          </div>

          <div className="my-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border auto">
              <tr>
                <td colSpan="5" className="text-left px-3">
                  <span className="h7 bold700">키오스크 결제</span>
                </td>
              </tr>
              <tr>
                <th>카드사</th>
                <th>정상건수</th>
                <th>금액</th>
                <th>취소건수</th>
                <th>취소금액</th>
              </tr>
              {result?.K_card_comp_qry?.map((row, i) =>
                <tr>
                  <td onClick={() => load2({"pay_route": "K", "card_name": row?.ck_summary})} style={{cursor: "pointer"}}>{row?.ck_summary}</td>
                  <td>{row?.K_ok_cnt}</td>
                  <td className="text-right"><span className="text-primary">{Fn.numberWithCommas(row?.K_ok_price)}원</span></td>
                  <td>{row?.K_cancel_cnt}</td>
                  <td className="text-right"><span className="text-danger">{Fn.numberWithCommas(row?.K_cancel_price)}원</span></td>
                </tr>
              )}
              {result?.K_card_comp_qry?.length > 0 &&
                <>
                  <tr className="bgcolor2 bold700">
                    <td>소계</td>
                    <td>{result?.K_T_ok_cnt}건</td>
                    <td className="text-right"><span className="text-primary">{Fn.numberWithCommas(result?.K_T_ok_price)}원</span></td>
                    <td>{result?.K_T_cancel_cnt}건</td>
                    <td className="text-right"><span className="text-danger">{Fn.numberWithCommas(result?.K_T_cancel_price)}원</span></td>
                  </tr>
                  <tr className="bgcolor bold700">
                    <td colSpan="4">합계</td>
                    <td className="text-right">{Fn.numberWithCommas(result?.K_T_total_price)}원</td>
                  </tr>
                </>
              }
              {result?.K_card_comp_qry?.length === 0 &&
                <tr>
                  <td colSpan={5}>※ 등록된 카드매출내역이 없습니다.</td>
                </tr>
              }
            </table>
          </div>
        </div>

        <div className="col-7">
          <div className="tip_box">
            Tip! 좌측의 "현금금액","통장금액","카드매출금액","합계금액" 을 click하시면 해당 상세 내역을 보실 수 있습니다.
          </div>
          <div className="my-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border auto">
              <tr>
                <th className="w_70px">No</th>
                <th>일시</th>
                <th>회원명</th>
                <th>카드사</th>
                <th>금액</th>
                <th>등록자</th>
              </tr>
              {result2?.result?.map((row, index) =>
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{`${row?.ck_date} ${row?.ckk_time} ${row?.ck_no}`}</td>
                  <td>{row?.ck_sangho}</td>
                  <td>{row?.ck_summary}</td>
                  <td className="txt_right">{Fn.numberWithCommas(row?.ck_amtcha)}</td>
                  <td>{row?.ck_step}</td>
                </tr>
              )}
            </table>
          </div>
        </div>
      </div>
    </CmnCard>
  );
};

export default FinanceCard;
