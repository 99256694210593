import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";

const ConfigIp = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/config/ip/config/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.list);

          if (result?.list?.act_YN === 'Y') $('#act_YN1').prop("checked", true);
          else $('#act_YN2').prop("checked", true);
          $('#cf_possible_ip').val(result?.list?.cf_possible_ip);
          $('#ip_comment').val(result?.list?.ip_comment);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const go_submit = () => {
    axios2
      .postEx('/config/ip/config', {
        base_seq: $('#base_seq').val(),
        act_YN: $("input[name='act_YN']:checked").val(),
        cf_possible_ip: $('#cf_possible_ip').val(),
        ip_comment: $('#ip_comment').val()
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === "err") {
          alert('오류가 발생하였습니다');
        } else {
          alert("수정되었습니다");
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3">
        <input type="hidden" id="base_seq"/>
        <table className="view-rounded sub_table_border" style={{maxWidth: '650px'}}>
          <tr>
            <th>적용여부</th>
            <td>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="act_YN" id="act_YN1" value="Y"/>
                <label className="form-check-label" htmlFor="act_YN1">예</label>
              </div>
              <div className="form-check form-check-inline">
                <input className="form-check-input" type="radio" name="act_YN" id="act_YN2" value="N"/>
                <label className="form-check-label" htmlFor="act_YN2">아니오</label>
              </div>
            </td>
          </tr>
          <tr>
            <th>IP리스트</th>
            <th>IP설명</th>
          </tr>

          <tr>
            <td><textarea className="form-control w-100" style={{height: Math.max(rets?.cf_possible_ip?.split("\n")?.length, rets?.ip_comment?.split("\n")?.length) * 17}} id="cf_possible_ip"/></td>
            <td><textarea className="form-control w-100" style={{height: Math.max(rets?.cf_possible_ip?.split("\n")?.length, rets?.ip_comment?.split("\n")?.length) * 17}} id="ip_comment"/></td>
          </tr>
        </table>
      </div>

      <div className="text-center" style={{maxWidth: '650px'}}>
        <button type="button" className="btn btn-sm btn-point px-3 mb-3" onClick={() => go_submit()}>저장</button>
      </div>
    </CmnCard>
  );
};

export default ConfigIp;
