import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import {useHistory} from "react-router-dom";

const MemberTorecive = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  // TODO : 백엔드 API 구현 필요
  const load = () => {
    axios2
      .post(`/member/toreceive/ready?page=${currPage}&date_type=${$('#date_type option:selected').val() || ''}&s_date=${$('#s_date').val()}&e_date=${$('#e_date').val()}&s_permit_YN=${$('#s_permit_YN option:selected').val() || ''}&key=${$('#key').val()}&key2=${$('#key2').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);

          {
            let rets = result?.result;
            if (!rets) return;

            $('[id^=default_show1]').text('***-****-****');

            let tot_base_hp = "";
            let count = 0;

            let account_YN;
            if ((localStorage.getItem("USER.systemID") === "futureinfo" || localStorage.getItem("USER.systemID") === "spoany" || localStorage.getItem("USER.systemID") === "tpwls8905") && localStorage.getItem("USER.systemBranchCode") == "ALL") {
              account_YN = "Y";
            } else {
              account_YN = "N";
            }

            rets.forEach(ret => {
              count++;

              let beforeInfo = ret['before_info'];
              let afterInfo = ret['after_info'];

              let beforeInfoSplit = beforeInfo?.split("||");
              let beforeHp = beforeInfoSplit?.[2] || '';

              let afterInfoSplit = afterInfo?.split("||");
              let afterHp = afterInfoSplit?.[2] || '';


              let mb_hp = beforeHp;
              let mb_hp_show;

              if (account_YN === "Y") {
                mb_hp = mb_hp?.replace("-", "");

                if (mb_hp?.length === 11) {
                  mb_hp_show = `${mb_hp?.substr(0, 3)}-${mb_hp?.substr(3, 4)}-${mb_hp?.substr(7, 4)}`;
                } else if (mb_hp?.length === 10) {
                  mb_hp_show = `${mb_hp?.substr(0, 3)}-${mb_hp?.substr(3, 3)}-${mb_hp?.substr(6, 4)}`;
                } else {
                  mb_hp_show = mb_hp;
                }

                mb_hp_show = `${mb_hp_show.substr(0, 3)}-${mb_hp_show.substr(4, 4)}-****`;
              } else {
                mb_hp_show = Fn.hpShow(mb_hp, pageInfo?.totalArticles || 0);
              }

              let base_mb_hp_show = '';
              try {
                base_mb_hp_show = btoa(mb_hp_show);
              } catch (e) {
              }

              tot_base_hp += count + "," + base_mb_hp_show + "|";
            });
            $('#tot_record1').val(rets?.length || 0);
            $('#tot_base_hp1').val(tot_base_hp);
          }

          {
            let rets = result?.result;
            if (!rets) return;

            $('[id^=default_show2]').text('***-****-****');

            let tot_base_hp = "";
            let count = 0;

            let account_YN;
            if ((localStorage.getItem("USER.systemID") === "futureinfo" || localStorage.getItem("USER.systemID") === "spoany" || localStorage.getItem("USER.systemID") === "tpwls8905") && localStorage.getItem("USER.systemBranchCode") == "ALL") {
              account_YN = "Y";
            } else {
              account_YN = "N";
            }

            rets.forEach(ret => {
              count++;

              let beforeInfo = ret['before_info'] || '';
              let afterInfo = ret['after_info'] || '';

              let beforeInfoSplit = beforeInfo?.split("||");
              let beforeHp = beforeInfoSplit?.[2];

              let afterInfoSplit = afterInfo?.split("||");
              let afterHp = afterInfoSplit?.[2];


              let mb_hp = afterHp;
              let mb_hp_show;

              if (account_YN === "Y") {
                mb_hp = mb_hp?.replace("-", "");

                if (mb_hp?.length === 11) {
                  mb_hp_show = `${mb_hp?.substr(0, 3)}-${mb_hp?.substr(3, 4)}-${mb_hp?.substr(7, 4)}`;
                } else if (mb_hp?.length === 10) {
                  mb_hp_show = `${mb_hp?.substr(0, 3)}-${mb_hp?.substr(3, 3)}-${mb_hp?.substr(6, 4)}`;
                } else {
                  mb_hp_show = mb_hp;
                }

                mb_hp_show = `${mb_hp_show.substr(0, 3)}-${mb_hp_show.substr(4, 4)}-****`;
              } else {
                mb_hp_show = Fn.hpShow(mb_hp, pageInfo?.totalArticles || 0);
              }

              let base_mb_hp_show = '';
              try {
                base_mb_hp_show = btoa(mb_hp_show);
              } catch (e) {
              }

              tot_base_hp += count + "," + base_mb_hp_show + "|";
            });
            $('#tot_record2').val(rets?.length || 0);
            $('#tot_base_hp2').val(tot_base_hp);
          }


          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const take_permit = () => {
    if ($('#auth_code').val() === "") {
      alert('인증코드를 입력해주세요');
      $('#auth_code').focus();
      return;
    }

    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 양도양수건을 승인처리 하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);
      formData.append("auth_code", $('#auth_code').val());

      axios2.postFormEx('/member/toreceive/permit', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('처리되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  const showPhone = (type, prefix = '') => {
    if (!$("#" + prefix + "auth_code").val()) {
      alert('인증코드를 입력해주세요');
      return;
    }

    showPhone1('toreceive');
    showPhone2('toreceive');
  }

  const showPhone1 = (type, prefix = '') => {
    const tot_record = $("#tot_record1").val();
    const tot_base_hp = $("#tot_base_hp1").val();
    const tot_sql = $("#tot_sql1").val();


    let totBaseHpSpl = tot_base_hp.split("|");

    for (let i = 0; i < totBaseHpSpl.length; i++) {
      let sp = totBaseHpSpl[i].split(",");
      let count = sp[0];
      let hp = "";
      try {
        hp = atob(sp[1]);
        $(`#default_show1${count}`).text(hp === '!!!-!!!!-!!!!' ? "" : hp);
      } catch (ignored) {
      }
    }

    // // TODO : tot_sql 처리 필요
    // axios2
    //   .postEx('/member/member/list/hp/show', {
    //     member_type: type,
    //     auth_code: $("#" + prefix + "auth_code").val(),
    //     tot_record: tot_record,
    //     tot_base_hp: tot_base_hp,
    //     tot_sql: tot_sql,
    //   })
    //   .then((response) => response.json())
    //   .then(({code, message: data, result, pageInfo}) => {
    //     const new_data = data.split('|');
    //     const dd = new_data.length;
    //
    //     for (let i = 0; i < dd; i++) {
    //       const won_data = new_data[i];
    //       if (won_data) {
    //         const won_data_split = won_data.split(',');
    //
    //         const cnt = won_data_split[0];
    //         const hp = won_data_split[1];
    //
    //         $(`#default_show1${cnt}`).text(hp === '!!!-!!!!-!!!!' ? "" : hp);
    //       }
    //     }
    //   })
    //   .catch((error) => {
    //     if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
    //   });
  }

  const showPhone2 = (type, prefix = '') => {
    const tot_record = $("#tot_record2").val();
    const tot_base_hp = $("#tot_base_hp2").val();
    const tot_sql = $("#tot_sql2").val();

    let totBaseHpSpl = tot_base_hp.split("|");

    for (let i = 0; i < totBaseHpSpl.length; i++) {
      let sp = totBaseHpSpl[i].split(",");
      let count = sp[0];
      let hp = "";
      try {
        hp = atob(sp[1]);
        $(`#default_show2${count}`).text(hp === '!!!-!!!!-!!!!' ? "" : hp);
      } catch (ignored) {
      }
    }

    // TODO : tot_sql 처리 필요
    axios2
      .postEx('/member/member/list/hp/show', {
        member_type: type,
        auth_code: $("#" + prefix + "auth_code").val(),
        tot_record: tot_record,
        tot_base_hp: tot_base_hp,
        tot_sql: tot_sql,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>

      <div className="d-flex justify-content-between m-3 fn_search_box">
        <div className="d-flex">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="date_type">
              <option value="yd_date">신청일</option>
              <option value="take_date">처리일</option>
            </select>
          </div>
          <div className="pr-1 d-flex">
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="e_date"/>
            </div>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_permit_YN">
              <option value="">전체</option>
              <option value="N">신청</option>
              <option value="Y">승인</option>
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>검색</button>
          </div>

          {/*TODO : 임시로 숨겨놓은 영역 / 반드시 처리할 것*/}
          <div className="pr-1">
            <input type="text" className="form-control width_shorts" aria-describedby="button-addon2" placeholder="양도자명" id="key" style={{visibility: "hidden"}}/>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control width_shorts" aria-describedby="button-addon2" placeholder="양수자명" id="key2" style={{visibility: "hidden"}}/>
          </div>
        </div>

        <div>
          <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.push("/member/toreceive/reg")}>양도양수 등록</button>
        </div>
      </div>

      <div>
        <div className="m-3 pb-5 table_list table_wide">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_rowspan">
            <colgroup>
              <col width="60px"/>
              <col width="60px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="6%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>

              <col width="*"/>
              <col width="6%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>

              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th rowSpan="2">No</th>
              <th rowSpan="2" className="w_70px"><input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/></th>
              <th rowSpan="2">신청일</th>
              <th rowSpan="2">처리일</th>
              <th colSpan="6">양도자</th>
              <th colSpan="6">양수자</th>
              <th colSpan="4">양도내용</th>
              <th rowSpan="2">처리여부</th>
              <th rowSpan="2">처리자</th>
            </tr>
            <tr>
              <th>성명</th>
              <th>연락처</th>
              <th>헬스종료</th>
              <th>운동복종료</th>
              <th>라커종료</th>
              <th>잔여PT</th>

              <th>성명</th>
              <th>연락처</th>
              <th>헬스종료</th>
              <th>운동복종료</th>
              <th>라커종료</th>
              <th>잔여PT</th>

              <th>이용기간</th>
              <th>운동복</th>
              <th>라커</th>
              <th>PT</th>
            </tr>
            {rets && rets.map((ret, i) => {
              const beforeInfoSplit = (ret?.before_info || '').split("||");
              const beforeBranch = beforeInfoSplit[0];
              const beforeName = beforeInfoSplit[1];
              const beforeHp = beforeInfoSplit[2];

              const afterInfoSplit = (ret?.after_info || '').split("||");
              const afterBranch = afterInfoSplit[0];
              const afterName = afterInfoSplit[1];
              const afterHp = afterInfoSplit[2];

              let permitYnShow;
              if (ret?.permit_YN === "N") {
                permitYnShow = <span style={{color: 'red', fontWeight: 'bold'}}>신청</span>;
              } else {
                permitYnShow = <span style={{color: 'blue', fontWeight: 'bold'}}>승인</span>;
              }

              return (
                <tr>
                  <td>{pageInfo?.totalArticles - pageInfo?.articlesPerPage * (pageInfo?.currentPage - 1) - i}</td>
                  <td>
                    {((ret?.permit_YN === "N" && !ret?.locker_name)) && (
                      <input
                        type="checkbox"
                        // name={`chk[${i + 1}]`}
                        name="chk"
                        value={ret?.yd_no}
                        className="checkbox"
                        id={`achk${i + 1}`}
                      />
                    )}
                  </td>
                  <td>{(ret?.yd_date || '').replace(/-/g, '.').substr(2, 8)}</td>
                  <td>{(ret?.take_date || '').replace(/-/g, '.').substr(2, 8)}</td>
                  <td>{beforeName}</td>
                  <td>
                    <div id={`default_show1${i + 1}`}>***-****-****</div>
                  </td>
                  <td>
                    {ret?.date_chk === "Y" ? (
                      <span style={{color: 'blue'}}>
                            {(ret?.yd_bDate_do || '').replace(/-/g, '.').substr(2, 8)}
                          </span>
                    ) : (
                      (ret?.yd_bDate_do || '').replace(/-/g, '.').substr(2, 8)
                    )}
                  </td>
                  <td>
                    {ret?.suit_date_chk === "Y" ? (
                      <span style={{color: 'blue'}}>
                            {(ret?.yd_bDate_do_suit || '').replace(/-/g, '.').substr(2, 8)}
                          </span>
                    ) : (
                      (ret?.yd_bDate_do_suit || '').replace(/-/g, '.').substr(2, 8)
                    )}
                  </td>
                  <td>
                    {ret?.locker_date_chk === "Y" ? (
                      <span style={{color: 'blue'}}>
                            {(ret?.yd_bDate_do_locker || '').replace(/-/g, '.').substr(2, 8)}
                          </span>
                    ) : (
                      (ret?.yd_bDate_do_locker || '').replace(/-/g, '.').substr(2, 8)
                    )}
                  </td>
                  <td>
                    {ret?.pt_chk === "Y" ? (
                      <span style={{color: 'blue'}}>{ret?.pt_cnt_do}</span>
                    ) : (
                      ret?.pt_cnt_do
                    )}
                  </td>
                  <td>{afterName}</td>
                  <td>
                    <div id={`default_show2${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{(ret?.yd_bDate_su || '').replace(/-/g, '.').substr(2, 8)}</td>
                  <td>{(ret?.yd_bDate_su_suit || '').replace(/-/g, '.').substr(2, 8)}</td>
                  <td>{(ret?.yd_bDate_su_locker || '').replace(/-/g, '.').substr(2, 8)}</td>
                  <td>{ret?.pt_cnt_su}</td>
                  <td>{ret?.yd_period}</td>
                  <td>{ret?.yd_period_suit}</td>
                  <td>{ret?.yd_period_locker}</td>
                  <td>{ret?.yd_pt}</td>
                  <td>{permitYnShow}</td>
                  <td>{ret?.yd_step}</td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={22}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>
      <nav className="p-3 mb-4 d-flex justify-content-between border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>

            <button type="button" className="btn btn-sm btn-dark px-2 mx-1" onClick={() => take_permit()}>
              승인처리
            </button>
            {pageInfo?.totalArticles > 0 && (
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => showPhone('toreceive')}>연락처 확인</button>
              )
            )}
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp1" style={{display: "none"}}/>
      <input type="hidden" id="tot_record1" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql1" style={{display: "none"}}/>

      <input type="hidden" id="tot_base_hp2" style={{display: "none"}}/>
      <input type="hidden" id="tot_record2" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql2" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default MemberTorecive;
