import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";

const MvAllIpkum = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();
  const [bCount, setBCount] = useState(0);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const load = () => {
    axios2
      .post(`/member/member/history/pop/ready?mb_no=${props.mbNo}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          setBCount(result?.b_count >= 90 ? 90 : result?.b_count)
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: '1380px'}}>
          <div className="POP_title">
            <h5><strong>히스토리</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <p className="p-1 h6 bold800">{result?.mb_name} {result?.mb_hp_show}</p>
            <table className="view-rounded sub_table_border">
              <colgroup>
                <col width="25%"/>
                <col width="25%"/>
                <col width="25%"/>
                <col width="25%"/>
              </colgroup>
              <thead>
              <tr>
                <th>신청내역 - 입력자(접수자)</th>
                <th>입금내역</th>
                <th>연기내역</th>
                <th>출석내역</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style={{verticalAlign: "top"}}>
                  <div style={{overflowX: "hidden", overflowY: "auto", maxHeight: "260px"}}>
                  {result?.app_list?.map((item, index) =>
                    <dl className="border mb-1">
                      <dt className="p-1 border-bottom">{item?.ap_pdtName ? item?.ap_pdtName : item?.it_name}</dt>
                      <div className="d-flex">
                        <dd className="border-right w-40 p-1 mb-0 text-center" style={{width: "80px"}}>{item?.ap_date?.replace(/-/g, '.')?.substring(2, 10)}</dd>
                        <dd className="border-right p-1 mb-0 bold700 text-blue text-right" style={{width: "calc(100% - 190px)"}}>{Fn.numberWithCommas(item?.ap_amt)}</dd>
                        <dd className="p-1 mb-0 text-right" style={{width: "110px"}}>{item?.ap_step === "undefined" ? "" : item?.ap_step}({item?.reg_step === "undefined" ? "" : item?.ap_step})</dd>
                      </div>
                    </dl>
                  )}
                  </div>
                </td>
                <td style={{verticalAlign: "top"}}>
                  <div style={{overflowX: "hidden", overflowY: "auto", maxHeight: "260px"}}>
                    {result?.i_list?.map((item, index) => {
                      let inputTxt;
                      if (item?.ip_cardName) {
                        inputTxt = item?.ip_method + "(" + item?.ip_cardName + ")";
                      } else {
                        inputTxt = item?.ip_method;
                      }

                      return (
                        <dl className="border mb-1">
                          <div className="d-flex border-bottom">
                            <dd className="border-right p-1 mb-0 text-center" style={{width: "80px"}}>{item?.ip_date?.replace(/-/g, '.')?.substring(2, 10)}</dd>
                            <dd className="border-right p-1 mb-0 bold700 text-blue text-right" style={{width: "calc(100% - 190px)"}}>{Fn.numberWithCommas(item?.ip_amt)}</dd>
                            <dd className="p-1 mb-0 text-right" style={{width: "110px"}}>{item?.ip_step}</dd>
                          </div>
                          <dt className="p-1">{inputTxt}</dt>
                        </dl>
                      );
                    })}
                  </div>
                </td>
                <td style={{verticalAlign: "top"}}>
                  <div style={{overflowX: "hidden", overflowY: "auto", maxHeight: "260px"}}>
                    {result?.d_list?.map((item, index) => {
                      return (
                        <dl className="border mb-1">
                          <div className="d-flex">
                            <dd className="border-right p-1 mb-0 bold700 text-blue" style={{width: "90px"}}>{item?.de_sDate?.replace(/-/g, '.')?.substring(2, 10)}<br/>~ {item?.de_eDate?.replace(/-/g, '.')?.substring(2, 10)}</dd>
                            <dd className="p-1 mb-0" style={{width: "calc(100% - 90px)"}}>{item?.de_etc}</dd>
                          </div>
                        </dl>
                      );
                    })}
                  </div>
                </td>
                <td style={{verticalAlign: "top"}}>
                  <div style={{overflowX: "hidden", overflowY: "auto", maxHeight: "260px"}}>
                    {Array.from({length: bCount}, (_, ii) => (
                      <dl className="border mb-1 w-100" style={{minWidth: "310px"}}>
                        <div className="d-flex w-100" style={{minWidth: "310px"}}>
                          <dd className="border-right p-1 mb-0 text-center" style={{width: "50px"}}><strong>{ii + 1}</strong></dd>
                          <dd className="border-right p-1 mb-0" style={{width: "80px"}}>{result?.inoutBaDate?.[ii]}</dd>
                          <dd className="border-right p-1 mb-0" style={{width: "55px"}}>{result?.inoutBaTime?.[ii]}</dd>
                          <dd className="p-1 mb-0 text-right" style={{width: "125px"}}>{result?.inoutBranchName?.[ii]}&nbsp;</dd>
                        </div>
                      </dl>
                    ))}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <br/>

            <table className="view-rounded sub_table_border">
              <colgroup>
                <col width="70px"/>
                <col width="155px"/>
                <col width="140px"/>
                <col width="*"/>
                <col width="120px"/>
                <col width="80px"/>
                <col width="130px"/>
              </colgroup>
              <thead>
              <tr>
                <th>No</th>
                <th>일시</th>
                <th>구분</th>
                <th>내용</th>
                <th>아이디</th>
                <th>담당자</th>
                <th>접속지</th>
              </tr>
              </thead>
              <tbody>
              {result?.h_list?.map((item, index) =>
                <tr>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{item?.hi_date} {item?.hi_time}</td>
                  <td className="text-center">{item?.hi_type}</td>
                  <td>
                    <div dangerouslySetInnerHTML={{__html: item?.hi_etc?.replace(/null/g, "")?.replace(/undefined/g, "")}}></div>
                  </td>
                  <td className="text-center">{item?.hi_writer}</td>
                  <td className="text-center">{item?.hi_step}</td>
                  <td className="text-center">{item?.reg_ip}</td>
                </tr>
              )}
              </tbody>
            </table>

            <div className="text-center mt-3 mb-3">
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setIsOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MvAllIpkum;
