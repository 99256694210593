import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";

const MvChangeSuitPeriod = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{ zIndex: 1000000000 }}>
        <div className="pop_box01 p_absolute_mid_pop" style={{ width: '750px' }}>
          <div className="POP_title">
            <h5><strong>운동복 이용기간 변경</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <table className="view-rounded sub_view" style={{borderRadius: 0}}>
              <colgroup>
                <col width="180px" />
                <col width="*" />
              </colgroup>
              <tr>
                <th>변경전 운동복 이용기간 </th>
                <td>2022-02-10 ~ 2022-05-09</td>
              </tr>
              <tr>
                <th>변경후 운동복 이용기간</th>
                <td>
                  <div className="d-flex">
                    <input type="date" className="form-control" aria-describedby="button-addon2" />
                    <p className="mx-2">~</p>
                    <input type="date" className="form-control" aria-describedby="button-addon2" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>변경사유</th>
                <td><input type="text" className="form-control" aria-describedby="button-addon2" placeholder="" /></td>
              </tr>
            </table>
          </div>
          <div className="text-center mb-3">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1">기간변경</button>
            <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>닫기</button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MvChangeSuitPeriod;
