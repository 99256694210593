import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";


const LockerHistory = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/locker/locker/history/ready?s_date=${$('#s_date').val()}&e_date=${$('#e_date').val()}&s_locker_number=${$('#s_locker_number').val()}&s_mb_name=${$('#s_mb_name').val()}&s_gubun=${$('#s_gubun option:selected').val() || ''}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.list);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>

      <div className="m-3 d-flex fn_search_box">
        <div className="input-group pr-1" style={{width: 'auto'}}>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date"/>
          <p className="mx-2">~</p>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="e_date"/>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control width_shorts" placeholder="라커번호" id="s_locker_number"/>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control width_shorts" placeholder="회원명" id="s_mb_name"/>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_gubun">
            <option value="">구분</option>
            <option value="C">비움</option>
            <option value="M">이동</option>
            <option value="B">배정</option>
          </select>
        </div>

        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>
            검색
          </button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border auto">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="20%"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>대상일</th>
            <th>회원명</th>
            <th>구분</th>
            <th>라커명</th>
            <th>라커위치</th>
            <th>라커번호</th>
            <th>비고</th>
            <th>처리자</th>
          </tr>
          {rets && rets.map((ret, i) => {

            let gubunTxt;
            if (ret?.gubun === 'C') {
              gubunTxt = '비움';
            } else if (ret?.gubun === 'M') {
              gubunTxt = '이동';
            } else if (ret?.gubun === 'B') {
              gubunTxt = '배정';
            } else {
              gubunTxt = '';
            }

            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>{(ret?.basic_date || '').replace(/-/g, '.').substr(2, 8)}</td>
                <td>{ret?.mb_name}</td>
                <td>{gubunTxt}</td>
                <td className="text-left">{ret?.locker_name}</td>
                <td className="text-left">{ret?.locker_place}</td>
                <td>{ret?.locker_number}</td>
                <td className="text-left">{ret?.bigo}</td>
                <td>{ret?.manager_name}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={9}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-center border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

    </CmnCard>
  );
};

export default LockerHistory;
