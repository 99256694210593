import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const EmployeeNewPosition = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/employee/emp/new/position/ready?s_start_date=${$('#s_start_date').val()}&s_end_date=${$('#s_end_date').val()}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box" style={{maxWidth: "1235px"}}>
        <div className="pr-1 d-flex">
          <div className="input-group justify-content-center align-content-center align-items-center" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_start_date"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_end_date"/>
          </div>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="이름/핸드폰 검색" id="key"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list" style={{maxWidth: "1235px"}}>
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="75px"/>
            <col width="90px"/>
            <col width="150px"/>
            <col width="90px"/>
            <col width="140px"/>
            <col width="140px"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>일자</th>
            <th>지점명</th>
            <th>성명</th>
            <th>이전직급</th>
            <th>변경직급</th>
            <th>메모</th>
          </tr>

          {rets.map((ret, i) => {
            const count = i + 1;
            const seq = ret?.seq;
            const change_date = ret?.change_date;
            const before_name = ret?.before_name;
            const after_name = ret?.after_name;
            const bigo = ret?.bigo;
            const manager_name = ret?.manager_name;
            const branch_name = ret?.branch_name;

            return (
              <tr key={i}>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>{change_date}</td>
                <td>{branch_name}</td>
                <td>{manager_name}</td>
                <td>{before_name}</td>
                <td>{after_name}</td>
                <td>
                  <div align="left" style={{paddingLeft: '5px'}}>{bigo}</div>
                </td>
              </tr>
            );
          })}
        </table>
      </div>

      <nav className="m-3 p-3 mb-4 d-flex justify-content-center border-top flex-wrap" style={{maxWidth: "1235px"}}>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>
    </CmnCard>
  );
};

export default EmployeeNewPosition;
