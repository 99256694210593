import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import {useHistory} from "react-router-dom";

const SecureMemberShowHistory = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const history = useHistory();

  useEffect(() => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 9) {
      alert('메뉴 접근 권한이 없습니다.');
      history.goBack();
      return;
    }

    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#s_date1').val(`${year}-${month}-01`);
    $('#s_date2').val(today);
  }, []);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/secure/member/show/history/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_basic_page=${$('#s_basic_page option:selected').val() || ''}&s_step_name=${$('#s_step_name').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }
          if ($('#s_basic_page option').length <= 1) {
            let optionsHTML = '<option value="">메뉴명</option>';
            result?.dd?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.basic_page}">${list_row.basic_page}</option>`;
            });
            $('#s_basic_page').html(optionsHTML);
          }

          setRets(result?.result1);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box" style={{maxWidth: "935px"}}>
        <div className="pr-1">
          <select className="form-select" id="s_branch_code">
          </select>
        </div>
        <div className="input-group pr-1" style={{width: "auto"}}>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
          <p className="mx-2">~</p>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_basic_page">
          </select>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" placeholder="스텝명 검색" id="s_step_name"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3 pb-2" style={{maxWidth: "935px"}}>
        <table className="view-rounded sub_table_border auto">
          <colgroup>
            <col width="65px"/>
            <col width="140px"/>
            <col width="170px"/>
            <col width="170px"/>
            <col width="70px"/>
            <col width="80px"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>지점명</th>
            <th>열람일시</th>
            <th>열람메뉴</th>
            <th>건수</th>
            <th>스텝명</th>
            <th>회원정보</th>
          </tr>
          {
            rets.map((row, i) => {
              const branch_name = row.branch_name;
              const basic_date = row.basic_date;
              const basic_time = row.basic_time;
              const basic_page = row.basic_page;
              const basic_cnts = row.basic_cnts;
              const step_name = row.step_name;
              const basic_qry = row.basic_qry.replace(/'/g, "\\'");
              const member_info = row.member_info;

              return (
                <tr key={i}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px'}}>{branch_name}</div>
                  </td>
                  <td>{basic_date} {basic_time}</td>
                  <td>{basic_page}</td>
                  <td>{basic_cnts}</td>
                  <td>{step_name}</td>
                  <td>
                    {member_info ? (
                      <div align="left" style={{paddingLeft: '5px'}}>{member_info}</div>
                    ) : (
                      <div align="left" style={{paddingLeft: '5px'}}>
                        {/*<!--{StrLimit(basic_qry, 50)}-->*/}
                        [쿼리저장]
                      </div>
                    )}
                  </td>
                </tr>
              );
            })
          }
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={7}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="m-3 p-3 mb-4 d-flex justify-content-center border-top" style={{maxWidth: "935px"}}>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>

    </CmnCard>
  );
};

export default SecureMemberShowHistory;
