import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import Fn from "../../libraries/Fn";
import axios from "axios";
import JDatePicker from "../common/JDatePicker";
import SelectProduct from "../common/SelectProduct";
import {useHistory} from "react-router-dom";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import _ from "lodash";


const SalesInoutReg = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);
  const [maxCnt, setMaxCnt] = useState(10);

  useEffect(() => {
    load();
    if (!$('#inout_date').val()) $('#inout_date').val(Fn.getCurrentDate());
    change_list(10);
  }, [])

  const load = (_page) => {
    // axios2
    //   .post(`/sales/sales/inout/reg/ready`)
    //   .then((response) => {
    //     const {code, message, result, pageInfo} = response?.data;
    //     if (code === 200) {
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
    //   });
  }

  function data_reset(cnt) {
    $("#p_code_show_" + cnt).val("");
    $("#p_code_" + cnt).val("");
    $("#qty_" + cnt).val("");
    $("#unit_price_" + cnt).val("");
    $("#detail_bigo_" + cnt).val("");
    $("#control_code_" + cnt).val("");

    $("#p_name_" + cnt).html("");
    $("#unit_price_show_" + cnt).html("");
    $("#price_" + cnt).html("");

    show_tot();
  }

  function cfNumeric(sOrg) {
    let nm;
    sOrg = sOrg.replace(/,/g, "");
    nm = parseFloat(sOrg).toString();
    return (isNaN(nm) ? 0 : nm);
  }

  function show_tot() {
    let tot_cnt = 0;
    let tot_qty = 0;
    let tot_price = 0;

    for (let cnt = 1; cnt <= Fn.parseInt($('#max_cnt').val()); cnt++) {
      let p_code = $("#p_code_" + cnt).val();

      if (p_code) {
        tot_cnt++;

        let unit_price = $("#unit_price_" + cnt).val(); //단가
        unit_price = cfNumeric(unit_price);

        let qty = $("#qty_" + cnt).val(); //수량
        qty = cfNumeric(qty);

        let o_price = eval(unit_price) * eval(qty);

        tot_qty = eval(tot_qty) + eval(qty);
        tot_price = eval(tot_price) + o_price;
      }
    }

    $('#tot_qty_show').html("<strong>" + tot_qty + "</strong>");
    $('#tot_price_show').html("<strong>" + Fn.numberWithCommas(tot_price) + "</strong>");
  }

  function change_list(val) {
    $('#max_cnt').val(val);

    let cnts = Fn.parseInt($('#max_cnt').val());

    for (let i = 1; i <= cnts; i++) {
      $('#detail_' + i).show();
    }
    for (let i = 30; i > cnts; i--) {
      $('#detail_' + i).hide();
      //데이터 초기화
      data_reset(i)
    }

    show_tot();
  }

  function go_submit() {
    /*
        String baseSeq = (String) requestParams.get("base_seq");
        String[] pCodes = (String[]) requestParams.get("p_codes[]"); // TODO : 체크 필요
        String[] qtys = (String[]) requestParams.get("qtys[]"); // TODO : 체크 필요
        String[] detailBigos = (String[]) requestParams.get("detail_bigos[]"); // TODO : 체크 필요
        String inoutDate = (String) requestParams.get("inout_date");
        int detailCnt = Fn.parseInt(requestParams.get("detail_cnt"));
     */

    const formData = new FormData();

    let p_codes = $('[id^=p_code_]').map(function () {
      return $(this).val();
    }).get().join(",");
    formData.append("p_codes[]", p_codes);

    let qtys = $('[id^=qty_]').map(function () {
      return $(this).val();
    }).get().join(",");
    formData.append("qtys[]", qtys);

    // let control_codes = $('[id^=control_code_]').map(function () {
    //   return $(this).find('option:selected').val();
    // }).get().join(",");
    // formData.append("control_codes[]", control_codes);

    let detail_bigos = $('[id^=detail_bigo_]').map(function () {
      return $(this).val();
    }).get().join(",");
    formData.append("detail_bigos[]", detail_bigos);

    formData.append("base_seq", $('#base_seq').val());
    formData.append("inout_date", $('#inout_date').val());
    formData.append("detail_cnt", $('#detail_cnt option:selected').val() || '10');

    axios2.postFormEx('/sales/sales/inout/reg', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();
        if (data === 'insert_ok') {
          alert('저장되었습니다.');
          history.goBack();
        } else if (data === 'update_ok') {
          alert('수정되었습니다.');
          history.goBack();
        } else {
          alert("저장오류! 잠시후 다시 시도해주세요");
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  // TODO : select_product 구현 -> 동일한 상품 등록 방지

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="m-3 search_box table_wide_sh2">
          {/* 회원검색 */}
          <div className="p-3">
            <table className="view-rounded sub_view">
              <colgroup>
                <col width="120px"/>
                <col width="*"/>
                <col width="120px"/>
                <col width="*"/>
              </colgroup>
              <tr>
                <th>입고일</th>
                <td className="text-left"><JDatePicker className="form-control" id="inout_date"/></td>
                <th>품목수</th>
                <td className="text-left">
                  <select className="form-select" style={{maxWidth: '250px'}} id="detail_cnt" onChange={(e) => change_list(e.target.value)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </select>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div className="m-3 pb-2 table_list table_wide_sh2">

          <input type="hidden" id="base_seq"/>
          <input type="hidden" id="cnt"/>
          <input type="hidden" id="max_cnt" value={maxCnt}/>

          {/* 회원목록 */}
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="60px"/>
              <col width="15%"/>
              <col width="*"/>
              <col width="150px"/>
              <col width="120px"/>
              <col width="95px"/>
              <col width="120px"/>
              <col width="25%"/>
              <col width="60px"/>
            </colgroup>
            <tr>
              <th>No</th>
              <th>상품코드</th>
              <th>상품명</th>
              <th>단가</th>
              <th>수량</th>
              <th>금액</th>
              <th>비고</th>
              <th>삭제</th>
            </tr>
            {_.range(1, 30 + 1).map(i =>
              <tr id={`detail_${i}`}>
                <td>{i}</td>
                <td>
                  <div className="input-group" style={{width: 'auto'}}>
                    <input type="text" className="form-control" aria-describedby="button-addon2" id={`p_code_show_${i}`}/>
                    <input type="hidden" id={`p_code_${i}`}/>
                    <button type="button" className="btn btn-ssm btn-outline-point"
                            onClick={() => {
                              $('#cnt').val(i);
                              $('#max_cnt').val(maxCnt);
                              setModalOpen(true);
                            }}
                    >검색
                    </button>
                  </div>
                </td>
                <td className="text-left"><span id={`p_name_${i}`}></span></td>
                <td>
                  <span id={`unit_price_show_${i}`}></span>
                  <input type="hidden" id={`unit_price_${i}`}/>
                </td>
                <td><input type="text" id={`qty_${i}`} className="form-control" aria-describedby="button-addon2" onChange={() => show_tot()}/></td>
                <td className="text-right"><span id={`price_${i}`}></span></td>
                <td><input type="text" className="form-control" aria-describedby="button-addon2" id={`detail_bigo_${i}`}/></td>
                <td>
                  <button type="button" className="btn btn-ssm btn-outline-dark"
                          onClick={() => {
                            data_reset(i);
                          }}
                  >삭제
                  </button>
                </td>
              </tr>
            )}
            <tr>
              <th colSpan="4">합계</th>
              <th><span id="tot_qty_show"></span></th>
              <th><span id="tot_price_show"></span></th>
              <th colSpan="2"></th>
            </tr>
          </table>

          <div className="my-3 text-center">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
            <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.goBack()}>목록</button>
          </div>
        </div>
      </div>

      <SelectProduct
        isOpen={modalOpen}
        onModalClose={() => setModalOpen(false)}
        searchType={"input_reg"}
        callback={() => {
          setModalOpen(false);
          show_tot();
        }}
      />
    </CmnCard>
  );
};

export default SalesInoutReg;
