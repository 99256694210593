import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";

const MessageSmsReserveList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/message/sms/reserve/list/ready?s_branch_code=${$('#s_branch_code option:selected').val() || localStorage.getItem("USER.systemBranchCode")}&key=${$('#key').val()}&s_opt_cmp=${$('#s_opt_cmp option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.dstaddr");

          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 8) {
            $('#s_branch_code').val(localStorage.getItem("USER.systemBranchCode"));
            $('#s_branch_code').prop("disabled", true);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function all_del() {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 예약발송을 삭제하시겠습니까?')) {
      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join("|");

      axios2.postFormEx(`/message/sms/del?del_key=${encodeURIComponent(values)}`, {})
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          alert('삭제되었습니다');
          setRenew(Fn.getRenewTime());
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  //  4-1.
  function search_del(nums) {
    if (!window.confirm("검색된 " + nums + "건의 예약발송을 삭제하시겠습니까?")) return;

    axios2
      .postEx(`/message/sms/search/del?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&key=${$('#key').val()}&s_opt_cmp=${$('#s_opt_cmp option:selected').val() || ''}&s_date1=${$('#s_date1').val()}`, {})
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else {
          alert("삭제하였습니다.");
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="pr-1">
            <select className="form-select" id="s_branch_code">
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="성명/회원번호/수신번호 검색" id="key" style={{width: "200px"}}/>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_opt_cmp">
              <option value="">::문자타입</option>
              <option value="SMS">SMS</option>
              <option value="LMS">LMS</option>
              <option value="kakao">kakao</option>
            </select>
          </div>
          <div className="pr-1">
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list table_wide_sh2">
          <table className="view-rounded sub_table_border auto">
            <tr>
              <th className="w_70px">
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th className="w_70px" style={{width: "80px"}}>No</th>
              <th>지점</th>
              <th>회원번호</th>
              <th>회원명</th>
              <th>예약일시</th>
              <th>수신번호</th>
              <th>문자타입</th>
              <th className="w-40">내용</th>
            </tr>
            {rets && rets.map((ret, i) => {
              const statTxt = ret?.result == 100 ? "발송성공" : "실패";
              ret.text = ret?.text?.replace(/"/g, '');
              const branchName = ret?.branch_name;

              return (
                <tr>
                  <td align="center">
                    <input
                      type="checkbox"
                      name="chk"
                      value={btoa(ret?.mseq)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  </td>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td style={{textAlign: 'left'}}>{branchName}</td>
                  <td>{ret?.ext_col2}</td>
                  <td>{ret?.ext_col1}</td>
                  <td>{ret?.requestTime?.replaceAll("-", ".")?.replaceAll("T", " ")?.substr(2, 14)}</td>
                  <td>
                    <div id={`default_show${i + 1}`}>***-****-****</div>
                  </td>
                  <td>{ret?.opt_cmp}</td>
                  <td style={{textAlign: 'left'}}><a title={ret?.text || ''}>{ret?.text?.substr(0, 120)}</a></td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={9}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            <div>
              <button type="button" className="btn btn-sm btn-outline-dark px-2 mx-1" onClick={() => all_del()}>선택삭제</button>
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => search_del(pageInfo.totalArticles)}>검색삭제</button>
            </div>
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>
            {pageInfo?.totalArticles > 0 && (
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('sms_send_list')}>연락처 확인</button>
              )
            )}
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};

export default MessageSmsReserveList;
