import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import remove from "../../images/x.png";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";


const SalesReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [tabType, setTabType] = useState('barcode');

  const [item_form, setItemForm] = useState();
  const [pay_form, setPayForm] = useState();

  useEffect(() => {
    setTabType('barcode');
    $('#barcode').prop('checked', true);

    setItemForm($("form[name='item_form']")[0]);
    setPayForm($("form[name='pay_form']")[0]);
  }, []);

  const onTab = (type) => {
    setTabType(type);

    setTimeout(() => {
      $('#a1').prop("checked", true);
      if (type === 'barcode') {
        $('#barcode').prop('checked', true);
        $('#all').prop('checked', false);
      } else {
        $('#barcode').prop('checked', false);
        $('#all').prop('checked', true);
      }
    }, 150);
  }

  const onPumTab = (n) => {
    if (n < 0) return;
    setTimeout(() => {
      $('#a' + n).prop('checked', true).siblings('input').prop('checked', false);
    }, 150);
  }

  const [pumList, setPumList] = useState([]);
  const [pumProducts, setPumProducts] = useState([]);
  const [result, setResult] = useState({});
  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);

  useEffect(() => {
    load(tabType);
  }, [currPage, tabType]);

  const load = (type) => {
    let url = `/sales/sales/reg/ready`;

    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        setPageInfo(pageInfo);
        if (code === 200) {
          setResult(result);
          console.log(result);
          setPumList(result?.pumList);
          if (result?.pumList?.length) {
            onProducts(result?.pumList?.[0]?.pum_code);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const onProducts = (type, n, key = '') => {
    axios2
      .post(`/sales/show/product?type=${type}&key=${key || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        setPumProducts(result?.result || []);
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };


  //바코드로 상품추가 혹은 전품목에서 상품추가시
  function add_product(type, barcode) {
    //객체의 아이디는 barcode 로 한다
    //이미 해당 객체가 생성되어 있는지 체크
    if ($("#" + barcode).length > 0) {
      if (type == "barcode") {
        $('.loading_bg_barcode').hide();
        $("#barcode").val('')
      } else {
        $('.loading_bg').hide();
      }

      //체크되어 있는경우 수량을 + 1
      var before_qty = $("#" + barcode + "_qty").val();
      let new_qty = eval(before_qty) + 1;
      $("#" + barcode + "_qty").val(new_qty);

      //해당제품의 총금액/총할인/총결제금액을 수량에 딸 변경
      line_sum(barcode);

      //재계산 함수
      cal_sum();
      return;
    }

    //객체가 없는경우 아래 ajax를 통해 객체 생성
    $.ajax({
      type: "post",
      url: "/sales/add/sales/product",
      data: "barcode=" + barcode,
      success: function (data) {
        console.log(data)
        //쿼리된 지점 표시
        data = data?.message?.trim();

        if (type == "barcode") {
          $('.loading_bg_barcode').hide();
          $("#barcode").val('')
        } else {
          $('.loading_bg').hide();
        }

        //객체생성
        if (data == "no_stock") {
          alert("재고가 없습니다");
          return;
        } else if (data == "no_product") {
          alert("상품이 존재하지 않습니다.");
          return;
        } else {
          //alert(data);
          $("#item_list_show").append(data);
          cal_sum();
        }

      }
    });
  }

  window.del_product = (barcode) => {
    $("#" + barcode).remove();
    cal_sum();
  }

  window.min_qty = (barcode) => {
    //체크되어 있는경우 수량을 + 1
    var before_qty = $("#" + barcode + "_qty").val();

    if (eval(before_qty) == 1) {
      alert("최소수량은 1 입니다");
      return;
    }

    let new_qty = eval(before_qty) - 1;
    $("#" + barcode + "_qty").val(new_qty);

    //해당제품의 총금액/총할인/총결제금액을 수량에 딸 변경
    line_sum(barcode);


    cal_sum();
  }

  window.plus_qty = (barcode) => {
    //체크되어 있는경우 수량을 + 1
    var before_qty = $("#" + barcode + "_qty").val();

    let new_qty = eval(before_qty) + 1;
    $("#" + barcode + "_qty").val(new_qty);

    //해당제품의 총금액/총할인/총결제금액을 수량에 딸 변경
    line_sum(barcode);

    cal_sum();
  }

  function cal_sum() {
    //등록되어 있는 상품의 수

    //결제방식 초기화
    item_form.payCard.value = "";
    //받은돈 0 처리
    $("#pay_price").val('0');


    var sum_sale_price = 0, sum_discount_price = 0, sum_pay_price = 0;
    $('.sum_sale_price').each(function () {
      sum_sale_price += parseInt($(this).val());
    });
    $('.sum_discount_price').each(function () {
      sum_discount_price += parseFloat($(this).val());
    });
    $('.sum_pay_price').each(function () {
      sum_pay_price += parseFloat($(this).val());
    });

    var won_sale_price_show = Fn.commaNum(sum_sale_price);
    var discount_sale_price_show = Fn.commaNum(sum_discount_price);
    var pay_price_show = Fn.commaNum(sum_pay_price);

    $('#show_won_sale_price')[0].innerHTML = won_sale_price_show;
    $('#show_sum_discount_price')[0].innerHTML = discount_sale_price_show;
    $('#show_sum_pay_price')[0].innerHTML = pay_price_show;


    //$("#pay_price").val(pay_price_show);
    $("#tot_amount_sum").val(pay_price_show);
    $("#unpaid_price").val(pay_price_show);

    //loss_YN
    $('#loss_YN').attr('checked', false);
    $('#loss_gubun_etc_txt').val('');
    $('#loss_gubun').val('');

    $('#loss_show')[0].style.display = "none";
    $('#loss_gubun_etc')[0].style.display = "none";
  }

  function line_sum(barcode) {
    //해당 라인의 기본 판매가
    var basic_sale_price = $("#" + barcode + "_basic_sale_price").val();

    //해당 라인의 수량
    var qty = $("#" + barcode + "_qty").val();

    //해당 라인의 할인가
    var basic_discount_price = $("#" + barcode + "_basic_discount_price").val();

    //해당 라인의 할인가
    var basic_pay_price = $("#" + barcode + "_basic_pay_price").val();


    //해당 라인의 판매가 = 기본판매가 * 수량
    var sale_price = eval(eval(basic_sale_price) * eval(qty));

    //해당 라인의 할인가 = 할인가 * 수량
    var discount_price = eval(eval(basic_discount_price) * eval(qty));

    //해당 라인의 결제가 = 결제가 * 수량
    var pay_price = eval(eval(basic_pay_price) * eval(qty));


    //1. 선택라인의 판매가 표시 변경
    $("#" + barcode + "_pay_price").text(Fn.commaNum(sale_price));

    //2. 선택라인의 판매가 값 등록
    $("#" + barcode + "_sum_sale_price").val(sale_price);

    //3. 선택라인의 할인가 값 등록
    $("#" + barcode + "_sum_discount_price").val(discount_price);

    //3. 선택라인의 결제가 값 등록
    $("#" + barcode + "_sum_pay_price").val(pay_price);


    cal_sum();
  }

  function chk_loss(cc) {
    $('#loss_gubun_etc')[0].style.display = "none";
    if (cc == true) {
      alert("손실처리시 판매금액은 '0'원으로 등록되며 월계표에 반영되지 않습니다");
      $('#loss_show')[0].style.display = "inline-block";
    } else {
      $('#loss_show')[0].style.display = "none";
    }
  }

  function select_loss_etc(value) {
    if (value == "기타") {
      $('#loss_gubun_etc')[0].style.display = "inline-block";
    } else {
      $('#loss_gubun_etc')[0].style.display = "none";
    }
  }

  function auto_insert() {
    //전체초기화
    $('#card_month')[0].innerHTML = "";
    item_form.cash_p.checked = false;
    $('#cash_p_show')[0].style.display = "none";


    let tot_amount_sum = item_form.tot_amount_sum.value;
    let cut_price = 0;

    tot_amount_sum = Fn.cfNumeric(tot_amount_sum);
    cut_price = 0;

    //원 결제금액
    let won_price = eval(tot_amount_sum) - eval(cut_price);

    let use_point = item_form.use_point.value;
    let coupon_price = 0;

    if (use_point) {
      use_point = Fn.cfNumeric(use_point);
    } else {
      use_point = 0;
    }

    let new_price = eval(won_price) - eval(use_point) - eval(coupon_price);
    new_price = Fn.commaNum(new_price);

    item_form.unpaid_price.value = new_price;

    if (item_form.payCard.value) {
      item_form.pay_price.value = item_form.unpaid_price.value
      item_form.unpaid_price.value = 0;

      let dd = item_form.payCard.value.split(",");
      let dd_size = dd.length;

      $('#card_month')[0].innerHTML = "";
      if (item_form.payCard.value == "2222")	//카드결제
      {
        var price = Fn.cfNumeric(item_form.pay_price.value);
        if (eval(price) >= 50000) {
          var hh = "<select name='month_card' id='month_card' class='form-select wid70' style='width:145px; display:inline-block; margin-top: 5px;'>";
          hh += "<option value='00'>일시불</option>";
          hh += "<option value='02'>2개월</option>";
          hh += "<option value='03'>3개월</option>";
          hh += "<option value='04'>4개월</option>";
          hh += "<option value='05'>5개월</option>";
          hh += "<option value='06'>6개월</option>";
          hh += "<option value='07'>7개월</option>";
          hh += "<option value='08'>8개월</option>";
          hh += "<option value='09'>9개월</option>";
          hh += "<option value='10'>10개월</option>";
          hh += "<option value='11'>11개월</option>";
          hh += "<option value='12'>12개월</option>";
          hh += "<option value='18'>18개월</option>";
          hh += "<option value='24'>24개월</option>";
          hh += "</select>";

          $("#card_month").append(hh)
        } else {
          var hh = "<select name='month_card' id='month_card' class='form-select wid70' style='width:145px; display:inline-block; margin-top: 5px;'>";
          hh += "<option value='00'>일시불</option>";
          hh += "</select>";

          $("#card_month").append(hh)
        }

        $('#cash_p_show')[0].style.display = "none";
      } else if (item_form.payCard.value == "1111" || dd_size == 3)	//현금 혹은 계좌이체
      {
        var spl = item_form.payCard.value.split(",");

        if (spl[0] == "180" || spl[0] == "242") {
          $('#card_month')[0].innerHTML = "";
          item_form.cash_p.checked = false;
          $('#cash_p_show')[0].style.display = "none";
        } else {
          $('#card_month')[0].innerHTML = "";
          $('#cash_p_show')[0].style.display = "inline";
        }
      } else															//카드 선택한경우
      {
        $('#card_month')[0].innerHTML = "";
        item_form.cash_p.checked = false;
        $('#cash_p_show')[0].style.display = "none";
      }
    } else {
      $('#card_month')[0].innerHTML = "";
      item_form.cash_p.checked = false;
      $('#cash_p_show')[0].style.display = "none";
    }
  }

  function pre_go_submit() {
    //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block'><i class='fas fa-check'></i> 결제</a>";

    var url = "sales_reg.php";

    if ($(".sel_price").length < 1) {
      alert("등록된 상품이 없습니다");
      //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
      return;
    }


    if (item_form.payCard.value == "") {
      alert('받은돈 지불수단을 선택해주세요');
      //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
      item_form.payCard.focus();
      return;
    }

    //미납금이 있는경우 경고창 처리
    var m_price = item_form.unpaid_price.value;
    if (eval(Fn.cfNumeric(m_price)) > 10) {
      alert('미납금이 존재합니다. 정상적인 서비스신청인지 금액을 확인해주세요');
      //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
      return;
    }


    //손실체크 확인
    if ($('input:checkbox[id="loss_YN"]').is(":checked") == true) {

      if (item_form.payCard.value == "2222") {
        alert('손실체크시 현금만 선택가능합니다');
        //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
        return;
      }

      if ($("#loss_gubun").val() == "") {
        alert('손실구분을 선택해주세요');
        //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
        return;
      }

      if ($("#loss_gubun").val() == "기타" && $("#loss_gubun_etc_txt").val() == "") {
        alert('손실구분 기타내용을 입력해주세요');
        //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
        return;
      }
    }

    let dd = item_form.payCard.value.split(",");
    let dd_size = dd.length;	//dd_size

    if (item_form.payCard.value == "2222") {
      //카드결제일경우 금액 필수입력
      if (item_form.pay_price.value == "" || item_form.pay_price.value == "0") {
        alert("카드결제일경우 받은돈을 입력해주세요");
        //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
        return;
      }

      //카드결제 항목 초기화
      //edtPayID.value = "";		//전문관리번호
      pay_form.edtAmount.value = 0;		//전문관리번호
      pay_form.edtTax.value = 0;			//부가세
      pay_form.edtInstallment.value = "00";//할부개월

      //1.전문관리번호 입력하기
      //new_time = nds();
      //new_time  = new_time.trim();
      //edtPayID.value = new_time;

      //2.결제금액 입력
      var pay_price = item_form.pay_price.value;

      //3.단가/부가세 입력
      cal_price(pay_price);

      //4. 할부개월 입력하기
      pay_form.edtInstallment.value = item_form.month_card.value;

      $("#authType").val("1010");		//신용카드

      writeTo();

    }//추가사항1
    else if (item_form.payCard.value == "1111" || dd_size == 3) {
      if (localStorage.getItem("USER.systemID") == "testjbj") {
        if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 8 || localStorage.getItem("USER.systemAccountYN") == "Y") {
        } else {
          if (item_form.cash_p.checked == false) {
            alert("현금영수증은 필수사항입니다.");
            return;
          }
        }
      }

      //카드결제일경우 금액 필수입력
      if ((item_form.pay_price.value == "" || item_form.pay_price.value == "0") && item_form.cash_p.checked == true) {
        alert("현금결제일경우 받은돈을 입력해주세요");
        //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
        return;
      }

      if (item_form.cash_p.checked == true && "<?=$TID?>") {

        //카드결제 항목 초기화
        //edtPayID.value = "";		//전문관리번호
        pay_form.edtAmount.value = 0;		//전문관리번호
        pay_form.edtTax.value = 0;			//부가세
        pay_form.edtInstallment.value = "00";//할부개월

        //1.전문관리번호 입력하기
        //new_time = nds();
        //new_time  = new_time.trim();
        //edtPayID.value = new_time;

        //2.결제금액 입력
        var pay_price = item_form.pay_price.value;

        //3.단가/부가세 입력
        cal_price(pay_price);

        $("#authType").val("5010");		//현금영수증
        writeTo();
      } else {
        go_submit();
      }
    } else {
      go_submit();
    }
  }

  function go_submit() {
    //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block'><i class='fas fa-check'></i> 결제</a>";

    var url = "/sales/sales/reg";

    if ($(".sel_price").length < 1) {
      alert("등록된 상품이 없습니다");
      //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
      return;
    }

    if (item_form.payCard.value == "") {
      alert('받은돈 지불수단을 선택해주세요');
      //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
      item_form.payCard.focus();
      return;
    }

    var test = "N";

    if (test == "Y") {
      item_form.action = url
      item_form.submit();
    } else {
      var formData = new FormData(item_form);

      {
        let values = $('[id^=p_code]').map(function () {
          return $(this).val();
        }).get().join(",");
        formData.append("pCode[]", values);
      }
      {
        let values = $('[id^=qty]:checked').map(function () {
          return $(this).val();
        }).get().join(",");
        formData.append("qty[]", values);
      }

      $.ajax({
        url: url,
        processData: false,
        contentType: false,
        data: formData,
        type: 'POST',
        success: function (data) {
          data = data?.message?.trim();

          //document.write(data); return;
          let split = data.split("||");
          let re_data = split[0];

          if (re_data == "ok") {
            alert('등록되었습니다');

            if (split[2] == "Y") {
              //영수증출력
              //var dad = split[1];
              //alert(dad);
              //future_send_YN - 아래 주석처리

              // TODO : 주석 처리
              // common_i.location.href = 'print_pay_sales.html?sales_code=' + split[1];
              window.parent.location.href = "/sales/reg";
            } else {
              window.parent.location.href = "/sales/reg";
            }
          } else {
            if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
            //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
            //window.parent.location.href = "/sales/reg";
          }
        }
      });
    }

  }

  function cal_price(price) {
    var SAL_PRICE = 0;
    var SAL_VAT = 0;

    price = Fn.cfNumeric(price);

    let SAL_AMT = price; // 판매금액

    SAL_VAT = ((SAL_AMT / 1.1) * 0.1);    // 부가세(VAT)
    SAL_PRICE = Math.round(SAL_AMT - SAL_VAT); // 단가(반올림)
    SAL_VAT = Math.round(SAL_VAT);    // 부가세(반올림)

    pay_form.edtAmount.value = SAL_PRICE;
    pay_form.edtTax.value = SAL_VAT;

    //임시설정
    //edtAmount.value = "1004";
    //edtTax.value = "0";;

  }

  function getTime_YYMMDDHHMMSS() {
    var today = new Date();

    var year = today.getFullYear()
    var month = ('0' + (today.getMonth() + 1)).slice(-2);
    var day = ('0' + today.getDate()).slice(-2);

    var hours = ('0' + today.getHours()).slice(-2);
    var minutes = ('0' + (today.getMinutes() + 1)).slice(-2);
    var seconds = ('0' + today.getSeconds()).slice(-2);

    var time = year + month + day + hours + minutes + seconds;

    return time.slice(-12);
  }

  function jsonInsert(json, field, value) {
    if (json.length == 0) {
      json = '{"';
      json += field;
      json += '":"';
      json += value;
      json += '"}';
    } else {
      json = json.substr(0, json.length - 1) + ',"';
      json += field;
      json += '":"';
      json += value;
      json += '"}';
    }
    return json;
  }

  function ShowLoadingImage() {
    //결제시 이미지 표시 - 미사용
    //document.getElementById('btn_send').disabled = true;
    //document.all.LoadingPageLayer.style.visibility = "visible";
    //document.all.LoadingImageLayer.style.visibility = "visible";
  }

  function HideLoadingImage() {
    //document.getElementById('btn_send').disabled = false;
    //document.all.LoadingPageLayer.style.visibility = "hidden";
    //document.all.LoadingImageLayer.style.visibility = "hidden";
  }


  var authType;
  var payType;

  function ParseHttpResponse(rtn)	//결제결과 파싱
  {
    // 스트링 utf-8 -> multibyte 변환 필요
    //console.log('응답전문 길이: ' + (String(rtn).indexOf(String.fromCharCode(13)) + 1));

    // json 전문
    if (String(rtn).substr(0, 1) == '{') {
      let rtn_ele_cr = rtn.replace(String.fromCharCode(0x0d), '');
      console.log(rtn_ele_cr);
      const obj = JSON.parse(rtn_ele_cr);


      if (obj.ERROR_CHECK_RESULT == 'S' && obj.PKTYPE == 'S' && obj.REPLY == '0000') {
        // 신용
        if (obj.MSGTYPE == '1010' || obj.MSGTYPE == '1050') {
          // 카카오페이 신용
          if (obj.ADDINFO2.slice(0, 3) == 'KKO') {
            pay_form.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            pay_form.edtAuthNo.value = String(obj.MESSAGE).substr(0, 10);
            pay_form.edtAuthUniqueNo.value = '';
          }
          // SSG페이 신용
          else if (obj.ADDINFO2.slice(0, 3) == 'SSG') {
            pay_form.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            pay_form.edtAuthNo.value = String(obj.MESSAGE).substr(0, 12) + fillSpace(3);
            pay_form.edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
          }
          // 일반 신용
          else {
            pay_form.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            pay_form.edtAuthNo.value = String(obj.MESSAGE).substr(0, 12);
            pay_form.edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
          }
        }

        // 현금영수증
        else if (obj.MSGTYPE == '5010' || obj.MSGTYPE == '5050') {
          pay_form.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
          pay_form.edtAuthNo.value = String(obj.MESSAGE).substr(0, 12);
          pay_form.edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
        }


        var a17 = obj.ACQUIERCD;
        var a4 = obj.TID;
        var a14 = obj.MERCHANTNO;
        var a21 = obj.TRACK2DATA;

        var d1 = String(obj.MESSAGE).substr(0, 12);
        var d2 = String(obj.MESSAGE).substr(12, 12);

        var a19 = obj.CARDKBN;

        var d3 = String(obj.MESSAGE).substr(24, 12);

        var a16 = obj.ISSUER;
        var a18 = obj.ACQUIER;


        var won_rtn = rtn;


        var purchase_code = a17;		//매입사코드
        var machine_no = a4;			//단말기번호
        var company_no = a14;			//가맹점번호
        var card_no = a21;				//카드번호
        var permit_no = d1;				//승인번호
        var permit_time = d2;			//승인일시
        var card_gubun = a19;	//카드구분
        var unique_no = d3;		//거래고유번호
        //발급사코드

        item_form.purchase_code.value = purchase_code;				//발급사코드
        item_form.machine_no.value = machine_no;					//단말기번호
        item_form.company_no.value = company_no;					//가맹점번호
        item_form.card_no.value = card_no;							//카드번호
        item_form.permit_no.value = permit_no;						//승인번호
        item_form.permit_time.value = permit_time;					//승인일시
        item_form.card_gubun.value = card_gubun;					//카드구분
        item_form.unique_no.value = unique_no;						//거래고유번호


        //영수증 출력
        if (item_form.payCard.value == "2222")	//카드결제
        {
          var month_card = item_form.month_card.value;
        } else {
          var month_card = "00";
        }

        var price = item_form.pay_price.value;

        // 2023.10.22 수정
        var c1 = result?.bOptRow?.branch_name;
        var c2 = result?.bInfoRow?.company_no;
        var c3 = "이규정";
        var c4 = result?.bInfoRow?.address;
        var c5 = result?.bInfoRow?.tel;

        var c6 = "";
        c6 = a21.substr(0, 4) + "-" + a21.substr(4, 4) + "-" + a21.substr(8, 4) + "-" + a21.substr(12, 4);

        if (item_form.payCard.value == "2222")	//카드결제
        {
          var c7 = a16.trim();
          var c8 = a18.trim();
        }

        var c9 = "";
        c9 = d2.substr(0, 2) + "/" + d2.substr(2, 2) + "/" + d2.substr(4, 2) + " " + d2.substr(6, 2) + ":" + d2.substr(8, 2) + ":" + d2.substr(10, 2);

        var c10 = "";
        if (a19 == "CK") {
          c10 = "신용승인("
          if (month_card == "00") {
            c10 += "일시불)";
          } else {
            c10 += "할부" + month_card + "개월)";
          }
        } else {
          c10 = "체크승인)"
          if (month_card == "00") {
            c10 += "일시불)";
          } else {
            c10 += "할부" + month_card + "개월)";
          }
        }

        if (item_form.payCard.value == "2222")	//카드결제
        {
          var c11 = d1.trim();
          var c12 = a4.trim();
          var c13 = a14.trim();
        }


        price = Fn.cfNumeric(price);

        if (price >= 50000) {
          var c17 = "Y";
        } else {
          var c17 = "N";
        }

        let SAL_AMT = price; // 판매금액

        let SAL_VAT = ((SAL_AMT / 1.1) * 0.1);    // 부가세(VAT)
        let SAL_PRICE = Math.round(SAL_AMT - SAL_VAT); // 단가(반올림)
        SAL_VAT = Math.round(SAL_VAT);    // 부가세(반올림)

        var c14 = Fn.commaNum(SAL_PRICE);
        var c15 = Fn.commaNum(SAL_VAT);
        var c16 = Fn.commaNum(price);

        var receipt_info = c1 + "|" + c2 + "|" + c3 + "|" + c4 + "|" + c5 + "|" + c6 + "|" + c7 + "|" + c8 + "|" + c9 + "|" + c10 + "|" + c11 + "|" + c12 + "|" + c13 + "|" + c14 + "|" + c15 + "|" + c16 + "|" + c17;
        //영수증 출력 끝

        item_form.rtn.value = won_rtn;								//전문데이터
        item_form.receipt_info.value = receipt_info;				//영수증데이터

        /*
        if(confirm("영수증을 출력하시겠습니까?"))
        {
            item_form.print_YN.value = "Y";
            go_submit();
            //pop_up('print_pay.html?data='+receipt_info,'locker_select','300','150','no')
        }
        else
        {
            go_submit();
        }
        */


        //카드승인일때만 영수증 출력
        if (item_form.payCard.value == "2222") {
          item_form.print_YN.value = "Y";
        } else {
          var new_hp = obj.TRACK2DATA;

          item_form.cash_hp.value = new_hp;				//핸드폰번호
          item_form.print_YN.value = "N";
        }
        go_submit();
      } else {
        //거래실패시 에러메시지 출력
        var dda = obj.ERROR_CHECK_MESSAGE;
        //alert(dda);
        alert(dda + "\r정상적으로 승인되지 않았습니다. 잠시후 다시 시도해주세요");
        //$('#service_ok')[0].innerHTML = "<a class='btn btn-danger btn-block' onclick='pre_go_submit()'><i class='fas fa-check'></i> 결제</a>";
      }
    }

    pay_form.taResponse.value += "\n";
    pay_form.taResponse.value += "===================================================\n";
    pay_form.taResponse.value += "응답 전문: " + String(rtn);
    pay_form.taResponse.value += "\n";
    pay_form.taResponse.value += "===================================================\n";
  }

  function httpRequest(api, data) {

    ShowLoadingImage();

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 0) {
        alert("단말기 연결이 실패하였습니다.");
      }
      if (this.readyState == XMLHttpRequest.DONE) {
        HideLoadingImage();
        if (this.status == 200) {
          var rtn = xmlHttp.responseText;
          console.log('recieve');

          ParseHttpResponse(rtn);
        }
      } else {
        console.log('waiting...');
      }
    };
    xmlHttp.ontimeout = function (e) {
      //console.log('Timeout!');
      alert("시간초과! 다시 시도해주세요");
      // location.href = "member_view.html"
    };
    //xmlHttp.timeout = 35 * 1000;

    let url = 'http';
    if (document.querySelector('input[name="HttpType"]:checked').value == 0)
      url += 's';
    url += '://127.0.0.1:';
    url += pay_form.portNo.value;
    url += api;
    xmlHttp.open("POST", url, true);
    //xmlHttp.open("POST", url.value, true);
    //xmlHttp.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
    xmlHttp.setRequestHeader("Content-Type", "text/plain;charset=utf-8");
    //xmlHttp.setRequestHeader("Connection", "Close");	// 브라우저에 의존
    //xmlHttp.setRequestHeader("Content-Type", "text/plain");
    //xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    //xmlHttp.setRequestHeader("Content-Type", "application/json");

    xmlHttp.send(data);

    //console.log('send');		//요청전문
  }


  function writeTo() {
    var taskType = document.querySelector('input[name="radioTaskType"]:checked').value;

    switch (taskType) {
      // 거래요청
      case '0':
        SendPayment();
        break;

      // 기능요청
      case '1':
        //SendInfo();
        break;
    }
  }

  // 단말기 정보 요청
  function SendInfo() {
    var taskType = document.querySelector('input[name="radioTaskType"]:checked').value;
    var subCmd = document.querySelector('input[name="radioFunctinType"]:checked').value;
    var inputData = "";

    // 전문 요청
    pay_form.taResponse.value += "전문 요청" + "[업무구분:" + taskType + " 세부명령:" + subCmd + "]" + " [명령인자:" + String(inputData) + "]\n";
    pay_form.taResponse.value += "전문 전송 응답\n";
    //var rtn = objEncPCPOS.TPayPosAuthSerial(parseInt(taskType), parseInt(subCmd), String(inputData));
    var rtn = "TODO_ACTIVEX"

    // 전문 응답 처리
    pay_form.taResponse.value += "[ " + rtn + " ]\n";
    pay_form.taResponse.value += "단말기 시리얼번호 : " + rtn.substring(5, 15) + '\n';
  }

  var cardNum100;

  var amount12;
  var tax12;
  var tip12;
  var taxFree12;

  var amount9;
  var tax9;
  var tip9;
  var taxFree9;

  var recycleUse;
  var recycleRcdAmt9;

  function SendPayment() {
    // 가맹점 단말기 tid
    if (pay_form.txtTID.value.length != 10) {
      alert("단말기 TID 자리수 확인(10자리)");
      pay_form.txtTID.focus();
      return;
    }

    if (pay_form.edtCardNum.value.length > 0 && pay_form.edtCardNum.value.length < 10 || pay_form.edtCardNum.value.length > 98) {
      alert("카드번호 자리수 확인(10자리 이상)");
      pay_form.edtCardNum.focus();
      return;
    }

    var cardPaddingLen;
    cardPaddingLen = 100 - pay_form.edtCardNum.value.length - 2;
    if (pay_form.WCC.value == 'K') {
      cardNum100 = pay_form.edtCardNum.value.length + pay_form.edtCardNum.value + fillSpace(cardPaddingLen);
    } else if (pay_form.WCC.value == 'A') {
      cardNum100 = pay_form.edtCardNum.value.length + pay_form.edtCardNum.value + fillSpace(cardPaddingLen);
    } else {
      cardNum100 = fillSpace(100);
    }

    // 거래금액 입력.
    if (pay_form.edtAmount.value.length > 9) {
      alert("거래금액 자리수 확인(9자리 이하)");
      pay_form.edtAmount.focus();
      return;
    }
    var amountLen = 9 - pay_form.edtAmount.value.length;
    amount9 = fillZero(amountLen) + pay_form.edtAmount.value;
    amount12 = '000' + amount9;

    // 세금 입력.
    if (pay_form.edtTax.value.length > 9) {
      alert("세금 자리수 확인(9자리 이하)");
      pay_form.edtTax.focus();
      return;
    }
    var taxLen = 9 - pay_form.edtTax.value.length;
    tax9 = fillZero(taxLen) + pay_form.edtTax.value;
    tax12 = '000' + tax9;

    // 봉사료 입력.
    if (pay_form.edtTip.value.length > 9) {
      alert("봉사료 자리수 확인(9자리 이하)");
      pay_form.edtTip.focus();
      return;
    }
    var tipLen = 9 - pay_form.edtTip.value.length;
    tip9 = fillZero(tipLen) + pay_form.edtTip.value;
    tip12 = '000' + tip9;

    // 비과세 입력.
    if (pay_form.edtTaxFree.value.length > 9) {
      alert("비과세 자리수 확인(9자리 이하)");
      pay_form.edtTaxFree.focus();
      return;
    }
    var taxFreeLen = 9 - pay_form.edtTaxFree.value.length;
    taxFree9 = fillZero(taxFreeLen) + pay_form.edtTaxFree.value;
    taxFree12 = '000' + taxFree9;

    recycleUse = document.querySelector('input[name="recycleType"]:checked').value;
    if (recycleUse == 1) {
      if (pay_form.recycleAmt.value.length > 9) {
        alert("비과세 자리수 확인(9자리 이하)");
        pay_form.recycleAmt.focus();
        return;
      }
      var recycleAmtLen = 9 - pay_form.recycleAmt.value.length;
      recycleRcdAmt9 = 'RCD' + fillZero(recycleAmtLen) + pay_form.recycleAmt.value;
    }

    var installment = "00"
    if (pay_form.edtInstallment.value.length != 2) {
      // 카드 결제 관련 입력값 검사.
      alert("할부 개월수 확인(00:일시불, 01~12:개월수");
      pay_form.edtInstallment.focus();
      return;
    }
    installment = pay_form.edtInstallment.value;

    // 거래요청 전문 생성
    authType = document.querySelector('#authType option:checked').value;
    payType = document.querySelector('#payType').value;


    // 취소인 경우 원 거래일시 / 원 거래번호 / 원거래고유번호 필요.
    if (authType == '1050' || authType == '1052' || authType == '1060' || authType == '5050') {
      // 원 거래일시 입력.
      if (pay_form.edtAuthDate.value.length != 6) {
        alert("원 거래일시 확인(6자리)");
        pay_form.edtAuthDate.focus();
        return;
      }
      //authDate = edtAuthDate.value; // 6자리 'YYMMDD'

      // 원 거래번호 입력.
      if (pay_form.edtAuthNo.value.length != 12) {
        alert("원 거래번호 확인(12자리 이하)");
        pay_form.edtAuthNo.focus();
        return;
      }
      //var authNoLen = 12 - edtAuthNo.value.length; // 12자리
      //authNo = edtAuthNo.value + fillSpace(authNoLen);

      // 원거래 고유번호 입력.
      if (pay_form.edtAuthUniqueNo.value.length != 12) {
        alert("원거래 고유번호 확인(12자리 이하)");
        pay_form.edtAuthUniqueNo.focus();
        return;
      }
      //var authUniqueNoLen = 12 - edtAuthUniqueNo.value.length;
      //authUniqueNo = edtAuthUniqueNo.value + fillSpace(authUniqueNoLen); // 12자리
    }

    let inputData = '';
    if (authType == '5010' || authType == '5050') // 현금 영수증 승인,취소
    {
      inputData = '';
      inputData += makeCash(authType);
    }
    if (authType == '1010' || authType == '1050' || authType == '1012' || authType == '1052') {
      inputData = '';
      inputData += makeCredit(authType);
    }

    // 승인 요청 전문 출력
    pay_form.taResponse.value += '요청 전문: ' + String(inputData);

    // http 전송
    var api = '/tPayDaemon/Auth'
    httpRequest(api, inputData);

  }

  function nds() {
    var d = new Date();
    var t_year = d.getFullYear();

    t_year = new String(t_year)

    var year = t_year.substr(2, 2);
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var second = d.getSeconds();

    if (eval(month) < 10) {
      month = "0" + month;
    } else {
      month = month;
    }

    if (eval(day) < 10) {
      day = "0" + day;
    } else {
      day = day;
    }

    if (eval(hour) < 10) {
      hour = "0" + hour;
    } else {
      hour = hour;
    }

    if (eval(minute) < 10) {
      minute = "0" + minute;
    } else {
      minute = minute;
    }

    if (eval(second) < 10) {
      second = "0" + second;
    } else {
      second = second;
    }

    let new_pay_id = year + month + day + hour + minute + second;

    return new_pay_id;
  }

  function makeCredit(authType) {
    let new_time = nds();
    new_time = new_time.trim();

    var packet;
    packet = '';
    packet = jsonInsert(packet, 'TIMEOUT', '02');
    packet = jsonInsert(packet, 'MSGTYPE', String(authType));			//전문종류
    packet = jsonInsert(packet, 'TID', pay_form.txtTID.value);					//단말기번호
    packet = jsonInsert(packet, 'MSGNO', new_time);						//전문번호
    packet = jsonInsert(packet, 'TRANSTIME', getTime_YYMMDDHHMMSS());	//전문생성일자

    if (pay_form.WCC.value != ' ') {
      packet = jsonInsert(packet, 'WCC', pay_form.WCC.value);
      packet = jsonInsert(packet, 'TRACK2DATA', cardNum100);
    }


    packet = jsonInsert(packet, 'INSTALLMENT', pay_form.edtInstallment.value);
    packet = jsonInsert(packet, 'AMOUNT', amount9);
    packet = jsonInsert(packet, 'TAX', tax9);
    packet = jsonInsert(packet, 'SERVICE', tip9);
    packet = jsonInsert(packet, 'CURRENCY', 'KRW');

    if (authType == '1010' || authType == '1012') {
      //packet = jsonInsert(packet, 'TRANSDATE', fillSpace(6));
      //packet = jsonInsert(packet, 'AUTHORITY', fillSpace(12));
      //packet = jsonInsert(packet, 'ORGAUTHNO', fillSpace(12));
    } else {
      packet = jsonInsert(packet, 'TRANSDATE', pay_form.edtAuthDate.value);
      packet = jsonInsert(packet, 'AUTHORITY', pay_form.edtAuthNo.value);
      //packet = jsonInsert(packet, 'ORGAUTHNO', pay_form.edtAuthUniqueNo.value);	// 직전취소 설정
    }

    packet = jsonInsert(packet, 'NOTAX', taxFree9);			//비과세금액
    /*
    packet = jsonInsert(packet, 'PINNO', fillSpace(18));		//비밀번호
    packet = jsonInsert(packet, 'OILINFO', fillSpace(24));	//주유정보
    packet = jsonInsert(packet, 'SITEBIZNO', fillSpace(10));	//하위 사업자 번호
    */
    packet = jsonInsert(packet, 'POSSERIALNO', 'JTPOSDM16011E278');	//pos 시리얼 번호

    //packet = jsonInsert(packet, 'ADDINFO1', fillSpace(32));			//부가정보
    //packet = jsonInsert(packet, 'ADDINFO2', fillSpace(128));			//부가정보2
    if (recycleUse == 1) {
      packet = jsonInsert(packet, 'ADDINFO2', fillSpace(64) + recycleRcdAmt9 + fillSpace(52));			//부가정보2
    }
    //packet = jsonInsert(packet, 'RESERVED', fillSpace(64));			//reserved

    packet = jsonInsert(packet, 'SIGNKBN', ' ');				//서명 여부
    packet = jsonInsert(packet, 'CR', String.fromCharCode(0x0d));		// CR

    return packet;
  }

  function makeCash(authType) {
    var packet;

    let new_time = nds();
    new_time = new_time.trim();

    packet = '';
    packet = jsonInsert(packet, 'TIMEOUT', '02');
    packet = jsonInsert(packet, 'MSGTYPE', String(authType));
    packet = jsonInsert(packet, 'TID', pay_form.txtTID.value); //TID
    packet = jsonInsert(packet, 'MSGNO', new_time);					//전문관리번호 (POS가 거래를 확인 하기 위한 번호)
    packet = jsonInsert(packet, 'TRANSTIME', getTime_YYMMDDHHMMSS());		//전문 생성 일자

    if (pay_form.WCC.value != ' ') {
      packet = jsonInsert(packet, 'WCC', pay_form.WCC.value);
      packet = jsonInsert(packet, 'TRACK2DATA', cardNum100);
    }
    packet = jsonInsert(packet, 'AMOUNT', amount9);
    packet = jsonInsert(packet, 'TAX', tax9);
    packet = jsonInsert(packet, 'SERVICE', tip9);

    if (item_form.cash_p_gubun.value == "0") {
      //inputData += "0";    //거래구분자 -개인
      packet = jsonInsert(packet, 'AUTH_TYPE', '0'); //개인
    } else {
      //inputData += "1";    //거래구분자 -사업자
      packet = jsonInsert(packet, 'AUTH_TYPE', '1'); //사업자
    }


    if (authType == '5010') {

    } else //취소 인 경우
    {
      packet = jsonInsert(packet, 'TRANSDATE', pay_form.edtAuthDate.value); //원거래일자
      packet = jsonInsert(packet, 'AUTHORITY', pay_form.edtAuthNo.value); //원승인번호
      packet = jsonInsert(packet, 'CANCELCODE', '1');
    }
    packet = jsonInsert(packet, 'NOTAX', taxFree9);

    if (recycleUse == 1) {
      packet = jsonInsert(packet, 'ADDINFO2', recycleRcdAmt9 + fillSpace(20));
    }

    packet = jsonInsert(packet, 'CR', String.fromCharCode(0x0d));

    return packet;
  }


  function fillZero(n) {
    var zeros = "";

    for (let i = 0; i < n; i++) {
      zeros += '0';
    }

    return zeros;
  }

  function fillSpace(n) {
    var zeros = "";

    for (let i = 0; i < n; i++) {
      zeros += ' ';
    }

    return zeros;
  }

  function leadingZeros(n, digits) {
    var zero = '';
    n = n.toString();

    if (n.length < digits) {
      for (var i = 0; i < digits - n.length; i++)
        zero += '0';
    }

    return zero + n;
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="sales_wrap m-4">
        <div style={{width: 'calc(98% - 320px)'}}>
          <div className="tabs">
            <input id="barcode" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="barcode" onClick={() => onTab('barcode')}>바코드스캔</label>
            <input id="all" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="all" onClick={() => onTab('all')}>전품목</label>
          </div>

          {tabType === 'barcode' ?
            <>
              <div className="my-4 search_box">
                <p className="h5 bold700 mt-3">Barcode Scan</p>
                <div className="my-3 mb-4">
                  <input type="text" className="barcode" onKeyDown={(e) => {
                    if (e.keyCode == 13) {
                      add_product('barcode', e.target.value);
                    }
                  }}/>
                </div>
              </div>
            </>
            :
            <>
              <div className="d-flex my-3 justify-content-end">
                <div className="d-flex">
                  <div className="pr-1">
                    <input type="text" className="form-control" placeholder="상품 검색" id="srch_prd_name" onKeyDown={(e) => {
                      if (e.keyCode == 13) {
                        onProducts('all', 0, e.target.value);
                      }
                    }}/>
                  </div>
                  <div className="pr-1">
                    <button type="button" className="btn btn-sm btn-point px-3" onClick={() => {
                      onProducts('all', 0, $('#srch_prd_name').val());
                    }}>검색
                    </button>
                  </div>
                </div>
              </div>
              <div className="tabs_inline">
                {pumList && pumList.map((row, i) =>
                  <>
                    <input id={`a${i + 1}`} type="radio" name="tab_in_item"/>
                    <label className="tab_in_item" htmlFor={`a${i + 1}`} onClick={() => onProducts(row.pum_code, i + 1)}>{row?.pum_name}</label>
                  </>
                )}
              </div>
              <div className="mb-4 py-3 search_box" style={{borderRadius: "0 0 8px 8px"}}>
                <div className="product">
                  {pumProducts?.map((row, i) => {
                    console.log(row);
                    return (
                      <dd style={{cursor: "pointer"}} onClick={() => add_product('manual', row?.barcode)}>{row?.p_name} <br/>
                        <span className="h6 bold700">{Fn.numberWithCommas(row?.sale_price)}원</span>
                      </dd>
                    );
                  })}
                </div>
              </div>
            </>
          }

          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width=""/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>판매일</th>
              <th>상품코드</th>
              <th>상품명</th>
              <th>상품단가</th>
              <th>할인금액</th>
              <th>판매단가</th>
              <th>수량</th>
              <th>금액</th>
              <th>결제방식</th>
            </tr>
            {result?.tResult?.map((row, i) =>
              <tr>
                <td>
                  <div align="center">{row?.sales_date}</div>
                </td>
                <td>
                  <div align="center">{row?.p_code}</div>
                </td>
                <td>
                  <div align="left">{row?.p_name}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(Fn.parseInt(row?.won_sale_price))}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(Fn.parseInt(row?.discount_price) * Fn.parseInt(row?.qty))}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(Fn.parseInt(row?.unit_price))}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(Fn.parseInt(row?.qty))}</div>
                </td>
                <td>
                  <div align="right">{Fn.numberWithCommas(Fn.parseInt(row?.price))}</div>
                </td>
                <td>
                  <div align="center">{row?.pay_method === '1111' ? '현금' : '카드'}</div>
                </td>
              </tr>
            )}
          </table>

        </div>


        <div>

          <form name="item_form" id="item_form">
            <input type="text" name="mb_no" style={{display: 'none'}}/>
            <input type="text" name="auth_code" style={{display: 'none'}}/>
            <input type="text" name="reg_step" style={{display: 'none'}}/>

            {/* 카드결제 관련정보 */}
            <input type="text" name="purchase_code" style={{display: 'none'}}/>
            <input type="text" name="machine_no" style={{display: 'none'}}/>
            <input type="text" name="company_no" style={{display: 'none'}}/>
            <input type="text" name="card_no" style={{display: 'none'}}/>
            <input type="text" name="permit_no" style={{display: 'none'}}/>
            <input type="text" name="permit_time" style={{display: 'none'}}/>
            <input type="text" name="card_gubun" style={{display: 'none'}}/>
            <input type="text" name="unique_no" style={{display: 'none'}}/>
            <input type="text" name="rtn" style={{display: 'none'}}/>
            <input type="text" name="receipt_info" style={{display: 'none'}}/>
            <input type="text" name="print_YN" style={{display: 'none'}}/>
            <input type="text" name="cash_hp" style={{display: 'none'}}/>
            <input type="hidden" name="coupon_price" value="0"/>
            <input type="hidden" name="f_coupon_show"/>
            <input type="hidden" name="f_mileage_show"/>

            <p className="price_tit">물품계산</p>
            <div className="price_cart">

              <div className="my-3 search_box" id="item_list_show">
                {/*
              <dl className="product_cart">
                <dt className="d-flex justify-content-between mt-2">
                  <p>(칼로바이)프로틴바 요거트맛</p>
                  <button type="button" className="btn btn-ssm px-0"><img src={remove} className="w-75" alt="Remove"/></button>
                </dt>
                <dd>
                  <div className="d-flex mb-2">
                    <button className="btn btn-ssm btn-input" type="button">-</button>
                    <input type="text" className="bg-white input_shorts2 text-center" placeholder="1" style={{height: '32px', zIndex: 123, margin: '0 -3px'}}/>
                    <button className="btn btn-ssm btn-input" type="button">+</button>
                  </div>
                  <p><span className="h6 bold700 px-1">1,300</span>원</p>
                </dd>
              </dl>
              <dl className="product_cart">
                <dt className="d-flex justify-content-between mt-2">
                  <p>(켈로그)레드베리 에너지바</p>
                  <button type="button" className="btn btn-ssm px-0"><img src={remove} className="w-75" alt="Remove"/></button>
                </dt>
                <dd>
                  <div className="d-flex mb-2">
                    <button className="btn btn-ssm btn-input" type="button">-</button>
                    <input type="text" className="bg-white input_shorts2 text-center" placeholder="1" style={{height: '32px', zIndex: 123, margin: '0 -3px'}}/>
                    <button className="btn btn-ssm btn-input" type="button">+</button>
                  </div>
                  <p><span className="h6 bold700 px-1">1,200</span>원</p>
                </dd>
             </dl>
             */}
              </div>

              <dl>
                <dt>합계</dt>
                <dd><span className="h5 bold700 px-1" id="show_won_sale_price">0</span>원</dd>
              </dl>
              <dl>
                <dt>할인</dt>
                <dd><span className="h5 bold700 px-1" id="show_sum_discount_price">0</span>원</dd>
              </dl>
              <dl>
                <dt>결제금액</dt>
                <dd><span className="h5 bold700 px-1" id="show_sum_pay_price">0</span>원</dd>
              </dl>
              <dl id="mileage_show" style={{display: "none"}}>
                <dt>마일리지사용</dt>
                <dd>
                  <div style={{float: "right", paddingRight: "4px"}}>
                    <span id="mileage_show_data">
                      <input type="hidden" name="able_point" value="0"/>
                      <input type="hidden" name="use_point" value="0"/>
                    </span>
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>받을돈</dt>
                <dd>
                  <div className="d-flex">
                    <select className="form-select table-inline mr-1" style={{maxWidth: '90px'}} name="payCard" onChange={() => auto_insert()}>
                      <option value=''>지불수단</option>
                      <option value='1111'>현금</option>
                      <option value='2222'>카드</option>
                      {result?.pc?.map((item, index) =>
                        <option key={index} value={`${item.j_no},${item.j_jukname},${item.j_gyecode}`}>계좌 {item.j_jukname}</option>
                      )}
                      {result?.pc2?.map((item, index) =>
                        <option key={index} value={`${item.j_no},${item.j_jukname},${item.j_gyecode}`}>{item.j_jukname}</option>
                      )}
                    </select>

                    <input type="text" name="pay_price" id="pay_price" value="0" className="form-control wid60 price_input" style={{width: "80px", maxHeight: "32px", display: "inline-block;"}} readOnly={true}/>
                    <input type="hidden" name="tot_amount_sum" id="tot_amount_sum" className="form-control wid100 price_input" value="0" readOnly={true}/>
                    <input type="hidden" name="unpaid_price" id="unpaid_price" className="form-control wid100 price_input txt_red" value="0" readOnly={true}/>
                  </div>

                  <div id="cash_p_show" style={{display: "none"}}>
                    <label>현금영수증: <input type="checkbox" name="cash_p" value="Y"/></label>
                    <select name="cash_p_gubun" className="form-select wid80" style={{width: "74px", marginLeft: "5px", marginTop: "5px"}}>
                      <option value='0'>개인</option>
                      <option value='1'>사업자</option>
                    </select>
                  </div>

                  <div id="card_month" className="text-right"></div>
                </dd>
              </dl>
              {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) >= 7 &&
                <dl>
                  <dt>손실처리</dt>
                  <dd>
                    <input type="checkbox" name="loss_YN" value="Y" id="loss_YN"
                           onClick={(e) => chk_loss(e.target.checked)} // TODO : PTODO
                    />
                    <span className="pl-1">(체크시 '손실'로 등록)</span>
                    <span id="loss_show" style={{display: "none"}}>
                    <select name="loss_gubun" id="loss_gubun" className="form-select wid80" style={{width: "80px"}}
                      // onChange={() => select_loss_etc(this.value)} // TODO : PTODO
                    >
                      <option value=''>손실구분</option>
                      <option value='분실/도난'>분실/도난</option>
                      <option value='상품손실'>상품손실</option>
                      <option value='실사오류'>실사오류</option>
                      <option value='기타'>기타</option>
                    </select>
                    <span id="loss_gubun_etc" style={{display: "none"}}>
                      <input type="text" name="loss_gubun_etc_txt" id="loss_gubun_etc_txt" className="form-control wid80" style={{display: "inline-block"}} placeholder="기타 상세"/>
                    </span>
                  </span>
                  </dd>
                </dl>
              }
              <button type="button" className="btn btn-point w-100 my-3" onClick={() => pre_go_submit()}>결제</button>
            </div>
          </form>
        </div>
      </div>

      <span style={{display: "none"}}>
        <form name="pay_form" id="pay_form">

            {/*연결방식-ok : */}
          <input type="radio" name="HttpType" value="0" checked="checked"/>HTTPS(보안) 연결 / <input type="radio" name="HttpType" value="1"/>HTTP 연결

          {/*Port 번호-ok : */}
          <input id="portNo" name="portNo" style={{width: '60px;'}} type="text" value="6443"/>

          {/*단말기번호-ok : */}
          <input id="txtTID" name="txtTID" style={{width: '200px;'}} type="text" value={result?.bOptRow?.TID}/>

          {/*사업자번호-ok : */}
          <input id="txtBiz" name="txtBiz" style={{width: '200px;'}} type="text" value=""/>

            <input type="radio" name="radioTaskType" value="0" checked="checked"/>거래 요청-ok
            <select id="authType" style={{width: '200px;'}}>
              <option value="1010">01.신용 승인</option>
              <option value="1050">02.신용 취소</option>
              <option value="1012">03.은련 승인</option>
              <option value="1052">04.은련 취소</option>
              <option value="5010">05.현금영수증 승인</option>
              <option value="5050">06.현금영수증 취소</option>
              <option value="8040">07.카카오페이 승인조회</option>
              <option value="8041">08.카카오페이 취소조회</option>
              <option value="80990">09.통합간편결제 승인</option>
              <option value="80991">10.통합간편결제 취소</option>
              <option value="8020">11.L.PAY 승인</option>
              <option value="8021">12.L.PAY 취소</option>
              <option value="8080">13.현금IC 승인</option>
              <option value="8081">14.현금IC 취소</option>
              <option value="8084">15.현금IC 조회</option>
              <option value="8050">16.제로페이 승인</option>
              <option value="8051">17.제로페이 취소</option>
              <option value="1014A">18.ICQR 승인</option>
              <option value="1014C">19.ICQR 취소</option>
              <option value="8030">20.SSG페이 승인</option>
              <option value="8031">21.SSG페이 취소</option>
              <option value="8036">22.SSG페이 확정</option>
              <option value="8046">23.알리페이 승인</option>
              <option value="8047">24.알리페이 취소</option>
              <option value="6080">25.수표조회</option>
              <option value="3010">26.포인트적립 승인</option>
              <option value="4010">27.멤버쉽 승인</option>
            </select>

            <input type="radio" name="radioTaskType" value="1"/>기능 요청-ok
            <select id="abilityType" style={{width: '200px;'}}>
              <option value="PC">01.식별번호 요청</option>
              <option value="KC">02.키 교환 요청</option>
              <option value="DC">03.데몬 상태 체크</option>
              <option value="RC">04.키오스크 리더기 체크</option>
              <option value="UP">05.데몬 업데이트 요청</option>
              <option value="LG">06.로그 수집 요청</option>
            </select>

          {/*현금영수증 승인 구분-ok :*/}
          <select id="authCashType" name="authCashType" style={{width: '200px;'}}>
              <option value="0">01.소비자 소득공제</option>
              <option value="1">02.사업자 지출증빙</option>
            </select>

          {/*WCC-ok :*/}
          <select id="WCC" name="WCC" style={{width: '200px;'}}>
              <option value=" ">01. 리더기 처리</option>
              <option value="K">02. Key-In</option>
              <option value="Q">03. 앱카드</option>
              <option value="A">04. 앱카드KeyIn</option>
              <option value="B">05. 바코드</option>
            </select>

          {/*지불수단-ok*/}
          <select id="payType" name="payType" style={{width: '200px;'}}>
              <option value="C">01. 신용</option>
              <option value="M">02. 머니</option>
              <option value="B">03. 계좌</option>
            </select>

          {/*카드번호-ok*/}
          <input type="text" id="edtCardNum" name="edtCardNum" value="" style={{width: '200px;'}}/>

          {/*거래금액-ok*/}
          <input type="text" id="edtAmount" name="edtAmount" value="0" style={{width: '200px;'}}/>

          {/*세금-ok*/}
          <input type="text" id="edtTax" name="edtTax" value="0" style={{width: '200px;'}}/>

          {/*봉사료-ok*/}
          <input type="text" id="edtTip" name="edtTip" value="0" style={{width: '200px;'}}/>

          {/*할부개월-ok*/}
          <input type="text" id="edtInstallment" name="edtInstallment" value="00" placeholder="00" style={{width: '200px;'}}/>

          {/*비과세 금액-ok*/}
          <input type="text" id="edtTaxFree" name="edtTaxFree" value="0" style={{width: '200px;'}}/>

          {/*자연순환보증금-ok*/}
          <input type="radio" name="recycleType" value="0" checked="checked"/>없음 <input type="radio" name="recycleType" value="1"/>있음 <input id="recycleAmt" style={{width: "80px"}} type="text" value="0"/>

          {/*원 거래일자-ok*/}
          <input type="text" id="edtAuthDate" name="edtAuthDate" placeholder="YYMMDD" style={{width: '200px;'}}/>

          {/*원 승인번호-ok*/}
          <input type="text" id="edtAuthNo" name="edtAuthNo" style={{width: '200px;'}}/>

          {/*원거래 고유번호-ok*/}
          <input type="text" id="edtAuthUniqueNo" name="edtAuthUniqueNo" style={{width: '200px;'}}/>

            <textarea id="taResponse" name="taResponse" style={{height: "260px", width: "100%;"}} rows="1" cols="129"></textarea>

        </form>
      </span>
    </CmnCard>
  );
};

export default SalesReg;
