import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";

const MemberBranchChange = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load(currPage);
  }, [currPage, renew]);

  useEffect(() => {
    if (srchRenew <= 0) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  const load = (page = 1) => {
    axios2
      .post(`/member/branch/change/ready?page=${page}&date_type=${$('#date_type option:selected').val() || ''}&s_date=${$('#s_date').val()}&e_date=${$('#e_date').val()}&s_permit_YN=${$('#s_permit_YN option:selected').val() || ''}&key=${$('#key').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  3-1.
  const make_monitor = () => {
    const as = window.open('/inc/black.html', 'enter_monitor', 'width=0,height=0,scrollbars=yes,top=10000,left=10000');
    as.window.close();
    const as2 = window.open('/enter/enter_monitor.html', 'enter_monitor', 'width=500,height=500,scrollbars=yes,top=600,left=1000');

    return () => {
      as2.close();
    };
  };

  const form1a = document.getElementById('form1a');
  const take_permit = () => {
    if (!$('#auth_code').val()) {
      alert('인증코드를 입력해주세요');
      $('#auth_code').val().focus();
      return;
    }

    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 주센터변경건을 승인처리 하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);
      formData.append("auth_code", $('#auth_code').val());

      axios2.postFormEx('/member/branch/change', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('처리되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  const del_data = (base_seq) => {
    if (!$('#auth_code').val()) {
      alert('인증코드를 입력해주세요');
      $('#auth_code').val().focus();
      return;
    }

    if (window.confirm('해당 신청건을 삭제하시겠습니까?')) {
      axios2.post(`/member/branch/change/del?base_seq=${base_seq}`)
        .then(response => {
          alert('삭제되었습니다');
          setRenew(Fn.getRenewTime());
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>

      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="date_type">
            <option value="request_date">신청일</option>
            <option value="take_date">처리일</option>
          </select>
        </div>
        <div className="pr-1 d-flex">
          <div className="input-group" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="e_date"/>
          </div>
        </div>

        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_permit_YN">
            <option value="">전체</option>
            <option value="N">신청</option>
            <option value="Y">승인</option>
          </select>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="이름/핸드폰 검색" id="key"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>


      <div className="m-3 table_list">
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            <th>성명</th>
            <th>회원번호</th>
            <th>신청일</th>
            <th>처리일</th>
            <th>이전지점</th>
            <th>변경지정</th>
            <th>처리여부</th>
            <th>처리자</th>
            <th>삭제</th>
          </tr>
          {rets && rets.map((ret, i) => {

            let permitYnShow;
            if (ret?.permit_YN === "N") {
              permitYnShow = <span style={{color: 'red', fontWeight: 'bold'}}>신청</span>;
            } else {
              permitYnShow = <span style={{color: 'blue', fontWeight: 'bold'}}>승인</span>;
            }

            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  {ret?.permit_YN === "N" && !ret?.locker_name && (
                    <input
                      type="checkbox"
                      // name={`chk[${i + 1}]`}
                      name="chk"
                      value={btoa(ret?.seq)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  )}
                </td>
                <td>
                  {ret?.locker_name ? (
                    <span style={{color: 'blue'}}>{ret?.mb_name}</span>
                  ) : (
                    ret?.mb_name
                  )}
                </td>
                <td>{ret?.mb_id}</td>
                <td>{(ret?.request_date || '').replace(/-/g, '.').substr(2, 8)}</td>
                <td>{(ret?.take_date || '').replace(/-/g, '.').substr(2, 8)}</td>
                <td className="text-left">{ret?.before_branch_name}</td>
                <td className="text-left">{ret?.after_branch_name}</td>
                <td>{permitYnShow}</td>
                <td>{ret?.manager_name}</td>
                <td>
                  {ret?.permit_YN === "N" && (
                    <a className="btn btn-sm btn-danger" onClick={() => del_data(btoa(ret?.seq))}>삭제</a>
                  )}
                </td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={11}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 my-4 d-flex justify-content-between border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>

        <div>
          <div className="d-flex">
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>

            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => take_permit()}>승인처리</button>
          </div>
        </div>
      </nav>


    </CmnCard>
  );
};

export default MemberBranchChange;
