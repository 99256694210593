import {useHistory} from "react-router-dom";
import "../../sass/main.scss";
import React, {useEffect, useRef, useState} from "react";
import Fn from "../../libraries/Fn";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";

import kd_logo from "../../images/kd_logo.svg";
import Modal from "react-modal";
import Constants from "../../constants/constants";

const Login = (props) => {

  const history = useHistory();

  const auChk = document.cookie.includes('Tcookieid');
  const [loginInProgress, setLoginInProgress] = useState(false);

  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [userid, setUserid] = useState();
  const [noPermit, setNoPermit] = useState(false);

  useEffect(() => {
    $.ajaxSetup({
      beforeSend: function (xhr, options) {
        if (options.url.startsWith(":3000"))
          options.url = options.url.replace(":3000", ":8994");
        else if (options.url.indexOf("http") === -1)
          options.url = Constants.API_URL + options.url;
      },
      headers: {
        'Authorization': ('Bearer ' + localStorage.getItem("USER.token"))
      },
      cache: false,
      error: function (jqXHR, textStatus, errorThrown) {
        Fn.parseAjaxError(jqXHR, textStatus, errorThrown, history);
      }
    });

    if (window.location.hostname !== "kdsportsraykim21.spoany.co.kr") {
      $('#secure').val('0');
      axios2
        .post('login/check/ip')
        .then((response) => {
          console.log(response);
          const {code, ip} = response?.data?.result;

          if (code === 210) {
            // alert("접속하신 IP [" + ip + "]에서는 사용이 불가능합니다.");
            setNoPermit(true);
            // history.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
        })
    } else {
      $('#secure').val('1');
    }
  }, []);

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      $("input[type=text]").each(function () {
        $(this).prop("autocomplete", "one-time-code");
      });
      $("input[type=password]").each(function () {
        $(this).prop("autocomplete", "one-time-code");
      });
    }
  }, []);

  useEffect(() => {
    /*
    localStorage.removeItem("USER.systemID");
    localStorage.removeItem("USER.systemName");
    localStorage.removeItem("USER.systemBranchName");
    localStorage.removeItem("USER.systemManageYN");
    localStorage.removeItem("USER.systemUserAuth");
    localStorage.removeItem("USER.systemAccountYN");
    localStorage.removeItem("USER.systemBranchCode");
    localStorage.removeItem("USER.systemBrand");
    localStorage.removeItem("USER.token");
    localStorage.removeItem("isUserLoggedIn");

    sessionStorage.removeItem("USER.systemID");
    sessionStorage.removeItem("USER.systemName");
    sessionStorage.removeItem("USER.systemBranchName");
    sessionStorage.removeItem("USER.systemManageYN");
    sessionStorage.removeItem("USER.systemUserAuth");
    sessionStorage.removeItem("USER.systemAccountYN");
    sessionStorage.removeItem("USER.systemBranchCode");
    sessionStorage.removeItem("USER.systemBrand");
    sessionStorage.removeItem("USER.token");
    sessionStorage.removeItem("isUserLoggedIn");
    */
  }, []);

  useEffect(() => {
    if (window.location.href.includes('localhost')) {
      // $('#user_pwd').val('zmfkdldsjcm@3');
    }
    if (localStorage.getItem("USER.savedID")) {
      $('#user_id').val(localStorage.getItem("USER.savedID") || '');
      $('#save_id').prop('checked', true);
      $('#user_pwd').focus();
    } else {
      $('#user_id').focus();
      $('#save_id').prop('checked', false);
    }
  }, []);

  function detectRatio() {
    const orientation = (window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation;
    if ("userAgentData" in window.navigator && window.navigator.userAgentData.mobile && orientation === "landscape-primary" || orientation === "landscape-secondary") {
      return window.devicePixelRatio;
    } else if ("userAgentData" in window.navigator && !window.navigator.userAgentData.mobile && orientation === "landscape-primary" || orientation === "landscape-secondary") {
      return window.outerWidth / window.innerWidth;
    } else if (orientation === "portrait-secondary" || orientation === "portrait-primary") {
      return window.devicePixelRatio;
    } else {
      return Math.max(window.devicePixelRatio, window.outerWidth / window.innerWidth);
    }
  }

  useEffect(() => {
    Fn.resizeScreen(1.5);
  }, []);

  useEffect(() => {
    $(window).bind('resize', function () {
      Fn.resizeScreen(1.5);
    })
    return () => {
      $(window).unbind('resize');
    };
  }, []);

  const popUp = (file, names, width, height, sc) => {
    const w = width;
    const h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h}, scrollbars=${sc}, top=${wint}, left=${winl}`);
  };

  const goSubmit = () => {
    const form1 = $('*[name=form1]')[0];
    const new_form = $('*[name=new_form]')[0];

    if (form1.user_id.value === '') {
      alert('ID를 입력해주세요');
      form1.user_id.focus();
      return false;
    }
    if (form1.user_pwd.value === '') {
      alert('PassWord를 입력해주세요');
      form1.user_pwd.focus();
      return false;
    }

    const form = document.forms[0];
    const formData = new FormData(form);

    $('#overlay').show();

    axios2
      .post('/login/login/process', formData)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        const data = result?.result;

        const spl = data?.split('||');
        if (data === 'err') {
          alert('오류가 발생하였습니다');
          setLoginInProgress(false);
          return;
        } else if (data === 'no_user') {
          alert('존재하지 않는 사용자입니다.');
          setLoginInProgress(false);
          return;
        } else if (data === 'not_permit_ip') {
          alert('허용되지 않은 IP입니다.');
          setLoginInProgress(false);
          console.log(result);
          return;
        } else if (spl[0] === 'no_use') {
          if (spl[1] === 'Y') {
            alert('로그인 10회이상 불일치로 사용이 중지되었습니다. 관리자에게 문의해주세요');
          } else {
            alert('사용이 중지된 계정입니다. 사용을 원할경우 본사관리자에게 연락해주세요');
          }
          setLoginInProgress(false);
          return;
        } else if (spl[0] === 'wrong_pwd') {
          alert(`일치하는 정보가 없습니다.\n로그인 실패횟수: ${spl[1]}/10회`);
          setLoginInProgress(false);
          return;
        } else if (data === 'no_branch') {
          alert('일치하는 정보가 없습니다.');
          setLoginInProgress(false);
          return;
        } else if (data === 'no_step') {
          alert('스텝은 사용하실 수 없습니다.');
          setLoginInProgress(false);
          return;
        } else if (spl[0] === 'pw_re') {
          alert('비밀번호가 초기화 되었으니 비밀번호를 변경해주시기 바랍니다');
          setUserid($('#user_id').val());
          setModal1Open(true);
        } else if (data === 'pwd_end') {
          alert('비밀번호 만료일이 경과되었으니 비밀번호를 변경해주시기 바랍니다');
          setUserid($('#user_id').val());
          setModal2Open(true);
        } else if (data === 'finace_go') {
          alert('지점투자는 지점투자자 로그인을 이용해주세요');
        } else if (data === 'login_ok' || data === 'login_ok_new1' || data === 'login_ok_new2') {
          if ($('#save_id').is(":checked"))
            localStorage.setItem("USER.savedID", form1.user_id.value);
          else
            localStorage.setItem("USER.savedID", "");

          props.onLogin(result);
          history.push("/enter/intro");
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        setLoginInProgress(false);
      })
      .finally(() => {
        $('#overlay').hide();
        $("#login_ing").val("N");
      });
  };

  /*
  useEffect(() => {
    $('#LOGIN_WRAP').height($('body').innerHeight());

    var ratio1 = detectRatio();
    var ratio2 = window.devicePixelRatio / ratio1;

    $('.Login_box').css('zoom', (1 / (ratio1 * ratio2)) * ($(window).width() * window.devicePixelRatio / 1521) / 1);
    $('body').css('overflow-y', 'hidden');
  }, []);
  */

  function goSubmit1() {
    var form1 = $('#form1')[0];

    if (form1.before_pwd.value == "") {
      alert("기존 비밀번호를 입력해주세요");
      form1.before_pwd.focus();
      return;
    }
    if (form1.new_pwd.value == "") {
      alert("신규 비밀번호를 입력해주세요");
      form1.new_pwd.focus();
      return;
    }

    var password = form1.new_pwd.value
    if (!/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{10,15}$/.test(password)) {
      alert("비밀번호를 영문,숫자,특수문자를 포함한 10~15자까지 입력해주세요")
      return;
    }

    if (form1.pwd_confirm.value == "") {
      alert("비밀번호 확인을 입력해주세요");
      form1.pwd_confirm.focus();
      return;
    }

    if (form1.new_pwd.value != form1.pwd_confirm.value) {
      alert('비밀번호 확인이 일치하지 않습니다');
      form1.pwd_confirm.value = '';
      return;
    }

    // if ($("#login_ing").val() == "Y") {
    //   alert('로그인중입니다. 잠시만 기다려주세요');
    //   return false;
    // }
    // $("#login_ing").val("Y");

    var formData = new FormData(form1);
    $.ajax({
      url: '/login/login/process/re',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (_data) {
        const {code, message, result, pageInfo} = _data;
        console.log(_data);
        const data = result?.message;

        if (data == "err") {
          alert('오류가 발생하였습니다');
          $("#login_ing").val("N");
          return;
        } else if (data == "not_permit_ip") {
          alert('허용되지 않은 IP입니다.');
          $("#login_ing").val("N");
          return;
        } else if (data == "wrong_pwd") {
          alert("이전 비밀번호가 일치하지 않습니다.");
          $("#login_ing").val("N");
          return;
        } else if (data == "login_ok") {
          props.onLogin(result);
          history.push("/enter/intro");
          // parent.location.href = "<?="http" . (empty($_SERVER["HTTPS"]) ? "" : "s") . "://" . SMMS_SPOANY_CO_KR?>/enter/"
        } else if (data == "login_ok_new1")	//점장이동
        {
          props.onLogin(result);
          history.push("/enter/intro");
          // parent.location.href = "<?="http" . (empty($_SERVER["HTTPS"]) ? "" : "s") . "://" . SMMS_SPOANY_CO_KR?>/enter/"
        } else if (data == "login_ok_new2")	//본사스텝이상
        {
          props.onLogin(result);
          history.push("/enter/intro");
          // parent.location.href = "<?="http" . (empty($_SERVER["HTTPS"]) ? "" : "s") . "://" . SMMS_SPOANY_CO_KR?>/enter/"
        }
      },
      complete: function () {
        $("#login_ing").val("N");
      }
    });
  }

  function goSubmit2() {
    var input_form = $('#form2')[0];

    if (input_form.old_pwd.value == "") {
      alert("기존 비밀번호를 입력해주세요");
      input_form.old_pwd.focus();
      return;
    }

    var password = input_form.user_pwd.value
    if (!/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/.test(password)) {
      alert("비밀번호를 영문,숫자,특수문자를 포함한 8~15자까지 입력해주세요")
      return;
    }

    if (input_form.user_pwd.value != "" && input_form.re_pwd.value == "") {
      alert("비밀번호 확인을 입력해주세요");
      input_form.re_pwd.focus();
      return;
    }

    if ((input_form.user_pwd.value != "" && input_form.re_pwd.value != "") && (input_form.user_pwd.value != input_form.re_pwd.value)) {
      alert("비밀번호 확인이 일치하지 않습니다.");
      input_form.re_pwd.focus();
      return;
    }

    var formData = new FormData(input_form);
    $.ajax({
      url: '/inc/info/change',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (_data) {
        console.log(_data);
        const data = _data?.message;

        if (data == "err") {
          alert('오류가 발생하였습니다');
          return;
        } else if (data == "same_pwd") {
          alert('현재와 동일한 비밀번호입니다.');
          return;
        } else if (data == "same_old_pwd") {
          alert('직전 비밀번호와 동일한 비밀번호는 사용하실 수 없습니다.');
          return;
        } else if (data == "wrong_pwd") {
          alert("이전 비밀번호가 일치하지 않습니다.");
          return;
        } else if (data == "ok") {
          alert('비밀번호가 변경되었습니다.\n다시 로그인해 주세요.');
          setModal2Open(false);
        }
      },
      complete: function () {
        $("#login_ing").val("N");
      }
    });
  }

  return (
    <div id="LOGIN_WRAP">
      <div id="overlay">
        <div className="cv-spinner">
          <span className="spinner"></span>
        </div>
      </div>
      <div className="container-fluid">
        <div className="Login_box d-flex justify-content-center align-content-center align-items-center" style={{display: "flex", flexDirection: "row"}}>
          <div className="Left_img_area">
            <img src={kd_logo} alt="KD Logo"/>
          </div>
          <div className="Right_input_area p-5">
            {noPermit ?
              <h4 className="pt-2 text-left bold700">접속하신 IP 에서는 사용이 불가능합니다.</h4>
              :
              <>
                <h4 className="pt-2 text-left bold700">로그인</h4>
                <form name="form1" method="post">
                  <div style={{margin: '30px auto'}}>
                    <input type="hidden" name="login_ing" id="login_ing"/>
                    <input type="hidden" name="secure" id="secure"/>
                    <input
                      type="text"
                      name="user_id"
                      id="user_id"
                      className="form-login"
                      aria-describedby="button-addon2"
                      placeholder="아이디 입력"
                      // onChange={idHandler}
                      // ref={idRef}
                      // onKeyDown={handleIdKeyDown}
                      tabIndex={1}
                    />
                    <input
                      type="password"
                      name="user_pwd"
                      id="user_pwd"
                      className="form-login"
                      aria-describedby="button-addon2"
                      placeholder="비밀번호 입력"
                      // onChange={passwordHandler}
                      onKeyDown={(event) => {
                        if (event.keyCode === 13) {
                          goSubmit();
                        }
                      }}
                      tabIndex={2}
                    />

                    <li className="text-left mt-3">
                      <div className="form-check">
                        <input className="checkstyle" name="save_id" id="save_id" type="checkbox" value="Y" tabIndex={3}/>
                        <label className="form-check-label" htmlFor="save_id">
                          아이디 저장
                        </label>
                      </div>
                    </li>

                    <li>
                      <button type="button" className="btn btn-lg mt-3 btn-point w-100" onClick={goSubmit}>
                        로그인
                      </button>
                    </li>

                    {/*<li className="my-4">*/}
                    {/*  <a href="#">아이디 찾기</a> | <a href="#">비밀번호 찾기</a> | <a href="#">회원가입</a>*/}
                    {/*</li>*/}
                  </div>
                </form>
              </>}
          </div>
        </div>
      </div>
      <form name="new_form" method="post" action="login_retry.html">
        <input type="hidden" name="base_user_id"/>
      </form>

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
        onAfterOpen={() => {
          Fn.centerModal();
        }}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '550px'}}>
            <form name="form1" id="form1">
              <div className="POP_title">
                <h5>
                  <strong>비밀번호 변경</strong>
                </h5>
                <p className="closed">
                  <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </p>
              </div>

              <div className="POP_cnt_box w-100">
                <table className="view-rounded sub_view">
                  <colgroup>
                    <col width="120px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>아이디</th>
                    <td>
                      <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="사용자 아이디" id="user_id" name="user_id" value={userid} readOnly={true}/>
                    </td>
                  </tr>
                  <tr>
                    <th>기존 패스워드</th>
                    <td>
                      <input type="password" className="form-control" aria-describedby="button-addon2" placeholder="기존 패스워드" id="before_pwd" name="before_pwd"/>
                    </td>
                  </tr>
                  <tr>
                    <th>신규 패스워드</th>
                    <td>
                      <input type="password" className="form-control" aria-describedby="button-addon2" placeholder="신규 패스워드" id="new_pwd" name="new_pwd"/>
                    </td>
                  </tr>
                  <tr>
                    <th>패스워드 확인</th>
                    <td>
                      <input type="password" className="form-control" aria-describedby="button-addon2" placeholder="패스워드 확인" id="pwd_confirm" name="pwd_confirm"/>
                    </td>
                  </tr>
                </table>
              </div>
            </form>

            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => goSubmit1()}>
                로그인
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
        onAfterOpen={() => {
          Fn.centerModal();
        }}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '550px'}}>
            <form name="form2" id="form2">
              <div className="POP_title">
                <h5>
                  <strong>비밀번호 변경</strong>
                </h5>
                <p className="closed">
                  <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                  </button>
                </p>
              </div>

              <div className="POP_cnt_box w-100">
                <table className="view-rounded sub_view">
                  <colgroup>
                    <col width="120px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>아이디</th>
                    <td>
                      <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="사용자 아이디" id="user_id" name="user_id" value={userid} readOnly={true}/>
                    </td>
                  </tr>
                  <tr>
                    <th>기존 패스워드</th>
                    <td>
                      <input type="password" className="form-control" aria-describedby="button-addon2" placeholder="기존 패스워드" id="old_pwd" name="old_pwd"/>
                    </td>
                  </tr>
                  <tr>
                    <th>신규 패스워드</th>
                    <td>
                      <input type="password" className="form-control" aria-describedby="button-addon2" placeholder="신규 패스워드" id="user_pwd" name="user_pwd"/>
                    </td>
                  </tr>
                  <tr>
                    <th>패스워드 확인</th>
                    <td>
                      <input type="password" className="form-control" aria-describedby="button-addon2" placeholder="패스워드 확인" id="re_pwd" name="re_pwd"/>
                    </td>
                  </tr>
                </table>
              </div>
            </form>

            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-2 mx-1" onClick={() => goSubmit2()}>수정</button>
              <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModal2Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

    </div>
  );
};

export default Login;
