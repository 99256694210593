import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import Fn from "../../libraries/Fn";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Modal from "react-modal";
import moment from "moment";

// 스크립트 없음
const AnalysisWeeklyReport = (props) => {

  window._load = (_page = 1) => {
    try {
      load(startDate, endDate);
    } catch (e) {
    }
  }

  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [result, setResult] = useState({});
  const [gabDate, setGabDate] = useState(0);
  const [ttj, setTtj] = useState(0);
  const [ttt, setTtt] = useState(0);
  const [tta, setTta] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});

  useEffect(() => {
    const currentDate = Fn.getCurrentDate();
    $('#s_year').val(currentDate.split("-")[0]);
    $('#s_month').val(currentDate.split("-")[1]);
    $('#basic_date').val(currentDate);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  function fixed(n) {
    return Number(Number(n).toFixed(1));
  }

  function dateGab(start_date, end_date) {
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    const diffInTime = Math.abs(endDate.getTime() - startDate.getTime());
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
    return diffInDays;
  }

  function calculateDate(baseDate, daysToAdd) {
    const date = new Date(baseDate);
    date.setDate(date.getDate() + daysToAdd);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
  }

  function data_show(data) {
    if (data) return data;
    else return <span style={{visibility: "hidden"}}>0</span>;
  }

  const load = (basic_date = '', end_date = '') => {
    const currentDate = Fn.getCurrentDate();
    if (($('#s_year option:selected').val() != currentDate.split("-")[0] || $('#s_month option:selected').val() != currentDate.split("-")[1]) && !basic_date) {
      basic_date = $('#s_year option:selected').val() + "-" + $('#s_month option:selected').val() + "-01";
    } else {
      if (!basic_date) {
        basic_date = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD');
        if ($('#s_year option:selected').val() != basic_date.split("-")[0] || $('#s_month option:selected').val() != basic_date.split("-")[1])
          basic_date = $('#s_year option:selected').val() + "-" + $('#s_month option:selected').val() + "-01";
      }
    }
    if (!end_date) {
      end_date = moment(basic_date).add(1, 'weeks').startOf('isoWeek').subtract(1, "days").format('YYYY-MM-DD');
      if ($('#s_year option:selected').val() != end_date.split("-")[0] || $('#s_month option:selected').val() != end_date.split("-")[1]) {
        end_date = moment($('#s_year option:selected').val() + "-" + $('#s_month option:selected').val() + "-01").endOf('month').format('YYYY-MM-DD');
      }
    }

    setGabDate(Math.abs(dateGab(basic_date, end_date)));
    setEndDate(end_date);

    console.log(`/analysis/weekly/report/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&basic_date=${basic_date}`);
    axios2
      .post(`/analysis/weekly/report/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&basic_date=${basic_date}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log('result', result);
          let reloaded = false;

          if (result?.w_list?.length) {
            let idx = 0;
            result?.w_list?.forEach((lrow) => {
              if (basic_date >= lrow.start_date && basic_date <= lrow.end_date) {
                setStartDate(result?.w_list?.[idx]?.start_date);
              }
              idx++;
            });

            $('#w_lists').show();
            if (!startDate && !basic_date) {
              result?.w_list?.forEach((lrow) => {
                if (Fn.getCurrentDate() >= lrow.start_date && Fn.getCurrentDate() <= lrow.end_date) {
                  setTimeout(() => {
                    load(lrow.start_date, lrow.end_date);
                  }, 100);
                  reloaded = true;
                }
              });
            }
          } else $('#w_lists').hide();

          if (reloaded) return;

          setTtj((result?.wt_join1 || 0) + (result?.wt_join2 || 0) + (result?.wt_join3 || 0) + (result?.wt_join4 || 0) + (result?.wt_join5 || 0) + (result?.wt_join6 || 0) + (result?.wt_join7 || 0) + (result?.wt_join8 || 0) + (result?.wt_join9 || 0) + (result?.wt_join10 || 0) + (result?.wt_join11 || 0) + (result?.wt_join12 || 0) + (result?.wt_join12 || 0));
          setTtt((result?.wt_time1 || 0) + (result?.wt_time2 || 0) + (result?.wt_time3 || 0) + (result?.wt_time4 || 0) + (result?.wt_time5 || 0));
          setTta((result?.wt_age1 || 0) + (result?.wt_age2 || 0) + (result?.wt_age3 || 0) + (result?.wt_age4 || 0) + (result?.wt_age5 || 0) + (result?.wt_age6 || 0));

          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal = () => {
    axios2
      .post(`/analysis/make/data/monthly/ready?s_year=${$('#modal_s_year option:selected').val()}&s_month=${$('#modal_s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setModalResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function make_data(branchCode, branchName) {
    const sYear = $('#modal_s_year option:selected').val();
    const sMonth = $('#modal_s_month option:selected').val();

    const txt = `${branchName}지점의 ${sYear}년 ${sMonth}월 데이터를 생성하시겠습니까?`;

    if (window.confirm(txt)) {
      if (!$("#overlay").is(":visible"))
        Fn.showSpinner();

      axios2.postEx("/analysis/make/data/monthly", {
        s_year: sYear,
        s_month: sMonth,
        branch_code: branchCode
      })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          console.log(data, result);
          alert('생성되었습니다');
          setRenew(Fn.getRenewTime());
          loadModal();
        })
        .catch(error => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        })
        .finally(() => {
          // $(".loading_bg").fadeOut("fast");
        });
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
          년
        </div>
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
          월
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          <button type="button" className="btn btn-sm btn-dark mx-1" onClick={() => setModalOpen(true)}>데이터 생성</button>
        </div>
      </div>

      <div className="m-3 my-4 d-flex flex-wrap justify-content-center" id="w_lists">
        {result?.w_list?.map((row, i) =>
          <button type="button"
                  style={
                    {
                      backgroundColor: row?.start_date === startDate ? "#E1282F" : "#ffffff",
                      borderColor: row?.start_date === startDate ? "#E1282F" : "#dddddd",
                      color: row?.start_date === startDate ? "#ffffff" : "#888888"
                    }
                  }
                  className="btn term"
                  onClick={() => load(row?.start_date, row?.end_date)}>{row?.start_date.replace(/-/g, ".").substring(2)} ~ {row?.end_date.replace(/-/g, ".").substring(2)}</button>
        )}
      </div>

      <div className="m-3 table_list">
        <div className="d-flex p_rative">
          <div className="table_overlap1" style={{minWidth: '1120px', zIndex: 1000}}>
            <table className="rounded-corner-l sub_table_rowspan space_nowrap">
              <colgroup>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="112px"/>
                <col width="112px"/>
                <col width="112px"/>
                <col width="*"/>
                <col width="112px"/>
                <col width="112px"/>
              </colgroup>
              <tr>
                <th rowSpan="2">요일</th>
                <th rowSpan="2">날짜</th>
                <th rowSpan="2">토탈</th>
                <th rowSpan="2">신규</th>
                <th rowSpan="2">재등록</th>
                <th rowSpan="2">P.T</th>
                <th rowSpan="2">PT매출금</th>
                <th colSpan="2">매출</th>
                <th colSpan="2">물품판매</th>
                <th rowSpan="2">매출합계</th>
              </tr>
              <tr>
                <th>현금+이체</th>
                <th>카드</th>
                <th>건수</th>
                <th>금액</th>
              </tr>

              {result?.gab_date && Array.from({length: gabDate + 1}, (_, i) => {
                const row = result?.gab_date?.[i];
                return (
                  <>
                    <tr style={{height: '43px'}}>
                      <td rowSpan="2">{row?.yo}</td>
                      <td rowSpan="2">{row?.cell_date}</td>
                      <td rowSpan="2">{Fn.parseInt(row?.new_man?.[row?.dd]) + Fn.parseInt(row?.old_man?.[row?.dd])}</td>
                      <td rowSpan="2">{row?.new_man?.[row?.dd]}</td>
                      <td rowSpan="2">{row?.old_man?.[row?.dd]}</td>
                      <td rowSpan="2">{row?.pt_cnt?.[row?.dd]}</td>
                      <td rowSpan="2">{Fn.numberWithCommas(row?.pt_price?.[row?.dd])}</td>
                      <td>{Fn.numberWithCommas(row?.cash_price?.[row?.dd])}</td>
                      <td>{Fn.numberWithCommas(row?.card_price?.[row?.dd])}</td>
                      <td rowSpan="2">{Fn.numberWithCommas(row?.sales_cnt)}</td>
                      <td rowSpan="2">{Fn.numberWithCommas(row?.sales_price?.[row?.dd])}</td>
                      <td rowSpan="2">
                        {Fn.numberWithCommas((row?.cash_price?.[row?.dd] || 0) + (row?.card_price?.[row?.dd] || 0) + (row?.sales_price?.[row?.dd] || 0))}
                      </td>
                    </tr>
                    <tr style={{height: '43px'}}>
                      <td className="bg_blue price">
                        {row?.cash_price[row?.dd] + row?.card_price[row?.dd] ?
                          `${((row?.cash_price[row?.dd] / (row?.cash_price[row?.dd] + row?.card_price[row?.dd])) * 100).toFixed(2)}%` :
                          ''
                        }
                      </td>
                      <td className="bg_blue price">
                        {row?.cash_price[row?.dd] + row?.card_price[row?.dd] ?
                          `${((row?.card_price[row?.dd] / (row?.cash_price[row?.dd] + row?.card_price[row?.dd])) * 100).toFixed(2)}%` :
                          ''
                        }
                      </td>
                    </tr>
                  </>
                );
              })}
              {result?.gab_date?.length > 0 &&
                <>
                  <tr className="bgcolor bold700">
                    <td colSpan={2} rowSpan={2} className="warning"><strong>합계</strong></td>
                    <td rowSpan={2} className="warning"><strong>{data_show(result?.s_new_man + result?.s_old_man)}</strong></td>
                    <td className="warning"><strong>{data_show(result?.s_new_man) || <span style={{visibility: "hidden"}}>0</span>}</strong></td>
                    <td className="warning"><strong>{data_show(result?.s_old_man) || <span style={{visibility: "hidden"}}>0</span>}</strong></td>
                    <td rowSpan={2} className="warning"><strong>{data_show(result?.s_pt_cnt)}</strong></td>
                    <td rowSpan={2} className="price"><strong>{data_show(Fn.numberWithCommas(result?.s_pt_price))}</strong></td>
                    <td className="price price"><strong>{data_show(Fn.numberWithCommas(result?.s_cash_price))}</strong></td>
                    <td className="price price"><strong>{data_show(Fn.numberWithCommas(result?.s_card_price))}</strong></td>
                    <td rowSpan={2} className="price price"><strong>{data_show(Fn.numberWithCommas(result?.s_sales_cnt))}</strong></td>
                    <td rowSpan={2} className="price price"><strong>{data_show(Fn.numberWithCommas(result?.s_sales_price))}</strong></td>
                    <td rowSpan={2} className="price line_r"><strong>{data_show(Fn.numberWithCommas((result?.s_cash_price || 0) + (result?.s_card_price || 0) + (result?.s_sales_price || 0)))}</strong></td>
                  </tr>
                  <tr className="bgcolor bold700">
                    <td><strong>
                      {result?.s_new_man + result?.s_old_man ?
                        `${((result?.s_new_man / (result?.s_new_man + result?.s_old_man)) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                    </strong></td>
                    <td><strong>
                      {result?.s_new_man + result?.s_old_man ?
                        `${((result?.s_old_man / (result?.s_new_man + result?.s_old_man)) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                    </strong></td>
                    <td className="price"><strong>
                      {result?.s_cash_price + result?.s_card_price ?
                        `${((result?.s_cash_price / (result?.s_cash_price + result?.s_card_price)) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                    </strong></td>
                    <td className="price"><strong>
                      {result?.s_cash_price + result?.s_card_price ?
                        `${((result?.s_card_price / (result?.s_cash_price + result?.s_card_price)) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                    </strong></td>
                  </tr>
                </>
              }
              {result &&
                Array.from({length: result?.wcnt}, (_, i) => (
                  <React.Fragment key={i}>
                    <tr className="active">
                      <td colSpan={2} rowSpan={2} className="active">{result?.wweek[i + 1]}주차</td>
                      <td rowSpan={2} className="active">{data_show(result?.wnew_man[i + 1] + result?.wold_man[i + 1])}</td>
                      <td className="active">{data_show(result?.wnew_man[i + 1])}&nbsp;</td>
                      <td className="active">{data_show(result?.wold_man[i + 1])}</td>
                      <td rowSpan={2} className="active">{data_show(result?.wpt_cnt[i + 1])}</td>
                      <td rowSpan={2} className="price">{data_show(Fn.numberWithCommas(result?.wpt_price[i + 1]))}</td>
                      <td className="price">{data_show(Fn.numberWithCommas(result?.wcash_price[i + 1]))}</td>
                      <td className="price">{data_show(Fn.numberWithCommas(result?.wcard_price[i + 1]))}</td>
                      <td rowSpan={2} className="price">{data_show(Fn.numberWithCommas(result?.wsale_cnt[i + 1]))}</td>
                      <td rowSpan={2} className="price">{data_show(Fn.numberWithCommas(result?.wsale_price[i + 1]))}</td>
                      <td rowSpan={2} className="price line_r">{data_show(Fn.numberWithCommas(result?.wcash_price[i + 1] + result?.wcard_price[i + 1] + result?.wsale_price[i + 1]))}</td>
                    </tr>
                    <tr className="active">
                      <td>
                        {result?.wnew_man[i + 1] + result?.wold_man[i + 1] ?
                          `${((result?.wnew_man[i + 1] / (result?.wnew_man[i + 1] + result?.wold_man[i + 1])) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </td>
                      <td>
                        {result?.wnew_man[i + 1] + result?.wold_man[i + 1] ?
                          `${((result?.wold_man[i + 1] / (result?.wnew_man[i + 1] + result?.wold_man[i + 1])) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </td>
                      <td className="price">
                        {result?.wcash_price[i + 1] + result?.wcard_price[i + 1] ?
                          `${((result?.wcash_price[i + 1] / (result?.wcash_price[i + 1] + result?.wcard_price[i + 1])) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </td>
                      <td className="price">
                        {result?.wcash_price[i + 1] + result?.wcard_price[i + 1] ?
                          `${((result?.wcard_price[i + 1] / (result?.wcash_price[i + 1] + result?.wcard_price[i + 1])) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </td>
                    </tr>
                  </React.Fragment>
                ))
              }


              <tr className="bgcolor2 bold700">
                <td colSpan={2} rowSpan={2} className="success"><strong>누계</strong></td>
                <td rowSpan={2} className="success">{data_show(result?.wt_new_man + result?.wt_old_man)}</td>
                <td className="success">{data_show(result?.wt_new_man)}</td>
                <td className="success">{data_show(result?.wt_old_man)}</td>
                <td rowSpan={2} className="success">{data_show(Fn.numberWithCommas(result?.wt_pt_cnt))}</td>
                <td rowSpan={2} className="price">{data_show(Fn.numberWithCommas(result?.wt_pt_price))}</td>
                <td className="price">{data_show(Fn.numberWithCommas(result?.wt_cash_price))}</td>
                <td className="price">{data_show(Fn.numberWithCommas(result?.wt_card_price))}</td>
                <td rowSpan={2} className="price">{data_show(Fn.numberWithCommas(result?.wt_sale_cnt))}</td>
                <td rowSpan={2} className="price">{data_show(Fn.numberWithCommas(result?.wt_sale_price))}</td>
                <td rowSpan={2} className="price line_r">{data_show(Fn.numberWithCommas(result?.wt_cash_price + result?.wt_card_price + result?.wt_sale_price))}</td>
              </tr>
              <tr className="bgcolor2 bold700">
                <td>
                  {result?.wt_new_man + result?.wt_old_man ?
                    `${(result?.wt_new_man / (result?.wt_new_man + result?.wt_old_man) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {result?.wt_new_man + result?.wt_old_man ?
                    `${(result?.wt_old_man / (result?.wt_new_man + result?.wt_old_man) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td className="price">
                  {result?.wt_cash_price + result?.wt_card_price ?
                    `${(result?.wt_cash_price / (result?.wt_cash_price + result?.wt_card_price) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td className="price">
                  {result?.wt_cash_price + result?.wt_card_price ?
                    `${(result?.wt_card_price / (result?.wt_cash_price + result?.wt_card_price) * 100).toFixed(1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </td>
              </tr>

              <tr>
                <th colSpan={2} rowSpan={2}>금월목표</th>
                <th style={{fontSize: "13px"}}>인원</th>
                <th style={{fontSize: "13px"}}>신규</th>
                <th style={{fontSize: "13px"}}>재등록</th>
                <th style={{fontSize: "13px"}}>PT매출목표</th>
                <th style={{fontSize: "13px"}}>PT실매출금</th>
                <th style={{fontSize: "13px"}}>목표매출금</th>
                <th style={{fontSize: "13px"}}>실매출금</th>
                <th style={{fontSize: "13px"}}>달성률</th>
              </tr>
              <tr>
                <td className="active txt_red"><strong>{data_show(result?.wtarget_new_man + result?.wtarget_old_man)}</strong></td>
                <td className="active txt_red"><strong>{data_show(result?.wtarget_new_man)}</strong></td>
                <td className="active txt_red"><strong>{data_show(result?.wtarget_old_man)}</strong></td>
                <td className="active txt_red">
                  <div align="right"><strong>{data_show(Fn.numberWithCommas(result?.wtarget_pt_price))}</strong></div>
                </td>
                <td className="active txt_red">
                  <div align="right"><strong>{data_show(Fn.numberWithCommas(result?.wt_pt_price))}</strong></div>
                </td>
                <td className="active txt_red">
                  <div align="right"><strong>{data_show(Fn.numberWithCommas(result?.wtarget_public_price + result?.wtarget_pt_price))}</strong></div>
                </td>
                <td className="active txt_red">
                  <div align="right"><strong>{data_show(Fn.numberWithCommas(result?.wsuip_price))}</strong></div>
                </td>
                <td className="active price txt_red"><strong>{result?.wpublic_rate}</strong></td>
              </tr>
              <tr>
                <td colSpan={3} className="bgcolor2"><strong>금주 미납현황</strong></td>
                <td colSpan={2} className="bgcolor2"><strong>미납자수</strong></td>
                <td colSpan={2} className="active"><strong>{data_show(result?.m_result?.['weekly_misu_cnt'])}</strong></td>
                <td className="bgcolor2"><strong>미납금액</strong></td>
                <td colSpan={2} className="active"><strong>{data_show(Fn.numberWithCommas(result?.m_result?.['weekly_misu_price']))}</strong></td>
              </tr>
              <tr>
                <td colSpan={3} className="bgcolor2"><strong>1인당 매출단가</strong></td>
                <td colSpan={5} className="active price  txt_red">
                  {/*<strong>{data_show(Fn.numberWithCommas(result?.wman_danga))}</strong>*/}
                  <strong>{data_show(Fn.numberWithCommas(Math.round((result?.wt_cash_price + result?.wt_card_price) / (result?.wt_new_man + result?.wt_old_man)).toFixed(1)))}</strong>
                </td>
              </tr>
            </table>
          </div>

          <div style={{marginLeft: 0}}>
            <table className="view-rounded sub_table_rowspan" style={{width: '3000px', borderRadius: 0}}>
              <colgroup>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>

                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>

                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>

                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>

                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
                <col width="*"/>
              </colgroup>

              <tr>
                <th colSpan="7" className="line_r">기간</th>
                <th colSpan="2" className="line_r">성별</th>
                <th colSpan="13" className="line_r">가입경로</th>
                <th colSpan="5" className="line_r">타임</th>
                <th colSpan="6">연령대</th>
              </tr>
              <tr>
                <th width="60">1개월</th>
                <th width="60">2개월</th>
                <th width="60">3개월</th>
                <th width="60">5개월</th>
                <th width="60">6개월</th>
                <th width="60">1년</th>
                <th width="60" className="line_r">프레</th>

                <th>남자</th>
                <th className="line_r">여자</th>

                <th width="60">TV</th>
                <th width="60">간판</th>
                <th width="60">현수막</th>
                <th width="60">전봇대</th>
                <th width="60">광고지</th>
                <th width="60">소개</th>
                <th width="60">인터넷</th>
                <th width="60">신문</th>
                <th width="60">문구</th>
                <th width="60">판촉물</th>
                <th width="60">인수C</th>
                <th width="60">네이버</th>
                <th width="60" className="line_r">기타</th>

                <th width="60">오전</th>
                <th width="60">오후</th>
                <th width="60">심야</th>
                <th width="60">주말</th>
                <th width="60" className="line_r">공휴일</th>

                <th width="60">10대</th>
                <th width="60">20대</th>
                <th width="60">30대</th>
                <th width="60">40대</th>
                <th width="60">50대</th>
                <th>60대 이상</th>
              </tr>

              {result?.gab_date && Array.from({length: gabDate + 1}, (_, i) => {
                const dd = calculateDate(startDate, i);

                return (
                  <tr style={{height: '86px'}} key={i}>
                    <td height="60">{data_show(result?.month1[dd])}</td>
                    <td>{data_show(result?.month2[dd])}</td>
                    <td>{data_show(result?.month3[dd])}</td>
                    <td>{data_show(result?.month5[dd])}</td>
                    <td>{data_show(result?.month6[dd])}</td>
                    <td>{data_show(result?.month12[dd])}</td>
                    <td className="line_r">{data_show(result?.month18[dd])}</td>

                    <td>{data_show(result?.m_num[dd])}</td>
                    <td className="line_r">{data_show(result?.f_num[dd])}</td>

                    <td>{data_show(result?.join1[dd])}</td>
                    <td>{data_show(result?.join2[dd])}</td>
                    <td>{data_show(result?.join3[dd])}</td>
                    <td>{data_show(result?.join4[dd])}</td>
                    <td>{data_show(result?.join5[dd])}</td>
                    <td>{data_show(result?.join6[dd])}</td>
                    <td>{data_show(result?.join7[dd])}</td>
                    <td>{data_show(result?.join8[dd])}</td>
                    <td>{data_show(result?.join9[dd])}</td>
                    <td>{data_show(result?.join10[dd])}</td>
                    <td>{data_show(result?.join12[dd])}</td>
                    <td>{data_show(result?.join13[dd])}</td>
                    <td className="line_r">{data_show(result?.join11[dd])}</td>

                    <td>{data_show(result?.time1[dd])}</td>
                    <td>{data_show(result?.time2[dd])}</td>
                    <td>{data_show(result?.time3[dd])}</td>
                    <td>{data_show(result?.time4[dd])}</td>
                    <td className="line_r">{data_show(result?.time5[dd])}</td>

                    <td>{data_show(result?.age1[dd])}</td>
                    <td>{data_show(result?.age2[dd])}</td>
                    <td>{data_show(result?.age3[dd])}</td>
                    <td>{data_show(result?.age4[dd])}</td>
                    <td>{data_show(result?.age5[dd])}</td>
                    <td>{data_show(result?.age6[dd])}</td>
                  </tr>
                );
              })}

              <tr className="bgcolor bold700">
                <td className="warning"><strong>{data_show(result?.s_month1)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_month2)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_month3)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_month5)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_month6)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_month12)}</strong></td>
                <td className="line_r"><strong>{data_show(result?.s_month18)}</strong></td>

                <td className="warning"><strong>{data_show(result?.s_m_num)}</strong></td>
                <td className="line_r"><strong>{data_show(result?.s_f_num)}</strong></td>

                <td className="warning"><strong>{data_show(result?.s_join1)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_join2)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_join3)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_join4)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_join5)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_join6)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_join7)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_join8)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_join9)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_join10)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_join12)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_join13)}</strong></td>
                <td className="line_r"><strong>{data_show(result?.s_join11)}</strong></td>

                <td className="warning"><strong>{data_show(result?.s_time1)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_time2)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_time3)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_time4)}</strong></td>
                <td className="line_r"><strong>{data_show(result?.s_time5)}</strong></td>

                <td className="warning"><strong>{data_show(result?.s_age1)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_age2)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_age3)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_age4)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_age5)}</strong></td>
                <td className="warning"><strong>{data_show(result?.s_age6)}</strong></td>
              </tr>


              <tr className="bgcolor bold700">
                <td><strong>
                  {result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18 ?
                    `${fixed((result?.s_month1 / (result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18)) * 100)}%` :
                    <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18 ?
                    `${fixed((result?.s_month2 / (result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18)) * 100, 1)}%` :
                    <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18 ?
                    `${fixed((result?.s_month3 / (result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18)) * 100, 1)}%` :
                    <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18 ?
                    `${fixed((result?.s_month5 / (result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18)) * 100, 1)}%` :
                    <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18 ?
                    `${fixed((result?.s_month6 / (result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18)) * 100, 1)}%` :
                    <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18 ?
                    `${fixed((result?.s_month12 / (result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18)) * 100, 1)}%` :
                    <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td className="line_r"><strong>
                  {result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18 ?
                    `${fixed((result?.s_month18 / (result?.s_month1 + result?.s_month2 + result?.s_month3 + result?.s_month5 + result?.s_month6 + result?.s_month12 + result?.s_month18)) * 100, 1)}%` :
                    <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_m_num + result?.s_f_num ?
                    `${fixed((result?.s_m_num / (result?.s_m_num + result?.s_f_num)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td className="line_r"><strong>
                  {result?.s_m_num + result?.s_f_num ?
                    `${fixed((result?.s_f_num / (result?.s_m_num + result?.s_f_num)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>

                {(() => {
                  const temp_j = result?.s_join1 + result?.s_join2 + result?.s_join3 + result?.s_join4 + result?.s_join5 + result?.s_join6 + result?.s_join7 + result?.s_join8 + result?.s_join9 + result?.s_join10 + result?.s_join11 + result?.s_join12 + result?.s_join13;
                  return (
                    <>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join1 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join2 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join3 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join4 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join5 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join6 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join7 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join8 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join9 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join10 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join12 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td><strong>
                        {temp_j ?
                          `${fixed((result?.s_join13 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                      <td className="line_r"><strong>
                        {temp_j ?
                          `${fixed((result?.s_join11 / temp_j) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                      </strong></td>
                    </>
                  );
                })()}

                <td><strong>
                  {result?.s_time1 + result?.s_time2 + result?.s_time3 + result?.s_time4 + result?.s_time5 ?
                    `${fixed((result?.s_time1 / (result?.s_time1 + result?.s_time2 + result?.s_time3 + result?.s_time4 + result?.s_time5)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_time1 + result?.s_time2 + result?.s_time3 + result?.s_time4 + result?.s_time5 ?
                    `${fixed((result?.s_time2 / (result?.s_time1 + result?.s_time2 + result?.s_time3 + result?.s_time4 + result?.s_time5)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_time1 + result?.s_time2 + result?.s_time3 + result?.s_time4 + result?.s_time5 ?
                    `${fixed((result?.s_time3 / (result?.s_time1 + result?.s_time2 + result?.s_time3 + result?.s_time4 + result?.s_time5)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_time1 + result?.s_time2 + result?.s_time3 + result?.s_time4 + result?.s_time5 ?
                    `${fixed((result?.s_time4 / (result?.s_time1 + result?.s_time2 + result?.s_time3 + result?.s_time4 + result?.s_time5)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td className="line_r"><strong>
                  {result?.s_time1 + result?.s_time2 + result?.s_time3 + result?.s_time4 + result?.s_time5 ?
                    `${fixed((result?.s_time5 / (result?.s_time1 + result?.s_time2 + result?.s_time3 + result?.s_time4 + result?.s_time5)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>

                <td><strong>
                  {result?.s_age1 + result?.s_age2 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6 ?
                    `${fixed((result?.s_age1 / (result?.s_age1 + result?.s_age2 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_age1 + result?.s_age2 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6 ?
                    `${fixed((result?.s_age2 / (result?.s_age1 + result?.s_age2 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_age1 + result?.s_age2 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6 ?
                    `${fixed((result?.s_age3 / (result?.s_age1 + result?.s_age2 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_age1 + result?.s_age4 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6 ?
                    `${fixed((result?.s_age4 / (result?.s_age1 + result?.s_age2 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_age1 + result?.s_age2 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6 ?
                    `${fixed((result?.s_age5 / (result?.s_age1 + result?.s_age2 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
                <td><strong>
                  {result?.s_age1 + result?.s_age2 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6 ?
                    `${fixed((result?.s_age6 / (result?.s_age1 + result?.s_age2 + result?.s_age3 + result?.s_age4 + result?.s_age5 + result?.s_age6)) * 100, 1)}%` : <span style={{visibility: "hidden"}}>0</span>}
                </strong></td>
              </tr>


              {Array.from({length: result?.wcnt}, (_, i) => i + 1).map(i => {
                  const t_j =
                    (result?.wjoin1[i] || 0) +
                    (result?.wjoin2[i] || 0) +
                    (result?.wjoin3[i] || 0) +
                    (result?.wjoin4[i] || 0) +
                    (result?.wjoin5[i] || 0) +
                    (result?.wjoin6[i] || 0) +
                    (result?.wjoin7[i] || 0) +
                    (result?.wjoin8[i] || 0) +
                    (result?.wjoin9[i] || 0) +
                    (result?.wjoin10[i] || 0) +
                    (result?.wjoin11[i] || 0) +
                    (result?.wjoin12[i] || 0) +
                    (result?.wjoin13[i] || 0)
                  ;

                  return (
                    <>
                      <tr className="active">
                        <td className="active" height={22}>{data_show(result?.wmonth1[i])}</td>
                        <td className="active">{data_show(result?.wmonth2[i])}</td>
                        <td className="active">{data_show(result?.wmonth3[i])}</td>
                        <td className="active">{data_show(result?.wmonth5[i])}</td>
                        <td className="active">{data_show(result?.wmonth6[i])}</td>
                        <td className="active">{data_show(result?.wmonth12[i])}</td>
                        <td className="line_r">{data_show(result?.wmonth18[i])}</td>

                        <td className="active">{data_show(result?.wm_num[i])}</td>
                        <td className="line_r">{data_show(result?.wf_num[i])}</td>

                        <td className="active">{data_show(result?.wjoin1[i])}</td>
                        <td className="active">{data_show(result?.wjoin2[i])}</td>
                        <td className="active">{data_show(result?.wjoin3[i])}</td>
                        <td className="active">{data_show(result?.wjoin4[i])}</td>
                        <td className="active">{data_show(result?.wjoin5[i])}</td>
                        <td className="active">{data_show(result?.wjoin6[i])}</td>
                        <td className="active">{data_show(result?.wjoin7[i])}</td>
                        <td className="active">{data_show(result?.wjoin8[i])}</td>
                        <td className="active">{data_show(result?.wjoin9[i])}</td>
                        <td className="active">{data_show(result?.wjoin10[i])}</td>
                        <td className="active">{data_show(result?.wjoin12[i])}</td>
                        <td className="active">{data_show(result?.wjoin13[i])}</td>
                        <td className="line_r">{data_show(result?.wjoin11[i])}</td>

                        <td className="active">{data_show(result?.wtime1[i])}</td>
                        <td className="active">{data_show(result?.wtime2[i])}</td>
                        <td className="active">{data_show(result?.wtime3[i])}</td>
                        <td className="active">{data_show(result?.wtime4[i])}</td>
                        <td className="line_r">{data_show(result?.wtime5[i])}</td>

                        <td className="active">{data_show(result?.wage1[i])}</td>
                        <td className="active">{data_show(result?.wage2[i])}</td>
                        <td className="active">{data_show(result?.wage3[i])}</td>
                        <td className="active">{data_show(result?.wage4[i])}</td>
                        <td className="active">{data_show(result?.wage5[i])}</td>
                        <td className="active">{data_show(result?.wage6[i])}</td>
                      </tr>


                      <tr className="active">
                        <td>
                          {result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i] ? (
                            `${fixed((result?.wmonth1[i] / (result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i])) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i] ? (
                            `${fixed((result?.wmonth2[i] / (result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i])) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i] ? (
                            `${fixed((result?.wmonth3[i] / (result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i])) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>

                        <td>
                          {result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i] ? (
                            `${fixed(((result?.wmonth5[i] / (result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i])) * 100))}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i] ? (
                            `${fixed(((result?.wmonth6[i] / (result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i])) * 100))}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i] ? (
                            `${fixed((result?.wmonth12[i] / (result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i])) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>


                        <td className="line_r">
                          {result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i] ? (
                            `${fixed((result?.wmonth18[i] / (result?.wmonth1[i] + result?.wmonth2[i] + result?.wmonth3[i] + result?.wmonth5[i] + result?.wmonth6[i] + result?.wmonth12[i] + result?.wmonth18[i])) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>

                        <td>
                          {result?.wm_num[i] + result?.wf_num[i] ? (
                            `${fixed((result?.wm_num[i] / (result?.wm_num[i] + result?.wf_num[i])) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td className="line_r">
                          {result?.wm_num[i] + result?.wf_num[i] ? (
                            `${fixed((result?.wf_num[i] / (result?.wm_num[i] + result?.wf_num[i])) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>


                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin1[i] / (t_j)) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin2[i] / (t_j)) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin3[i] / (t_j)) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin4[i] / (t_j)) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin5[i] / (t_j)) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin6[i] / (t_j)) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin7[i] / (t_j)) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin8[i] / (t_j)) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>


                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin9[i] / t_j) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin10[i] / t_j) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin12[i] / t_j) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {t_j ? (
                            `${fixed((result?.wjoin13[i] / t_j) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td className="line_r">
                          {t_j ? (
                            `${fixed((result?.wjoin11[i] / t_j) * 100)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>


                        <td>
                          {result?.wtime1[i] + result?.wtime2[i] + result?.wtime3[i] + result?.wtime4[i] + result?.wtime5[i] ? (
                            `${fixed((result?.wtime1[i] / (result?.wtime1[i] + result?.wtime2[i] + result?.wtime3[i] + result?.wtime4[i] + result?.wtime5[i])) * 100, 1)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {result?.wtime1[i] + result?.wtime2[i] + result?.wtime3[i] + result?.wtime4[i] + result?.wtime5[i] ? (
                            `${fixed((result?.wtime2[i] / (result?.wtime1[i] + result?.wtime2[i] + result?.wtime3[i] + result?.wtime4[i] + result?.wtime5[i])) * 100, 1)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {result?.wtime1[i] + result?.wtime2[i] + result?.wtime3[i] + result?.wtime4[i] + result?.wtime5[i] ? (
                            `${fixed((result?.wtime3[i] / (result?.wtime1[i] + result?.wtime2[i] + result?.wtime3[i] + result?.wtime4[i] + result?.wtime5[i])) * 100, 1)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {result?.wtime1[i] + result?.wtime2[i] + result?.wtime3[i] + result?.wtime4[i] + result?.wtime5[i] ? (
                            `${fixed((result?.wtime4[i] / (result?.wtime1[i] + result?.wtime2[i] + result?.wtime3[i] + result?.wtime4[i] + result?.wtime5[i])) * 100, 1)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>


                        <td className="line_r">
                          {result?.wtime1[i] + result?.wtime2[i] + result?.wtime3[i] + result?.wtime4[i] + result?.wtime5[i] ? (
                            `${fixed((result?.wtime5[i] / (result?.wtime1[i] + result?.wtime2[i] + result?.wtime3[i] + result?.wtime4[i] + result?.wtime5[i])) * 100, 1)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>

                        <td>
                          {result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i] ? (
                            `${fixed((result?.wage1[i] / (result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i])) * 100, 1)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i] ? (
                            `${fixed((result?.wage2[i] / (result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i])) * 100, 1)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i] ? (
                            `${fixed((result?.wage3[i] / (result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i])) * 100, 1)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>


                        <td>
                          {result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i] ? (
                            `${fixed((result?.wage4[i] / (result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i])) * 100, 1)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i] ? (
                            `${fixed((result?.wage5[i] / (result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i])) * 100, 1)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>
                        <td>
                          {result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i] ? (
                            `${fixed((result?.wage6[i] / (result?.wage1[i] + result?.wage2[i] + result?.wage3[i] + result?.wage4[i] + result?.wage5[i] + result?.wage6[i])) * 100, 1)}%`
                          ) : <span style={{visibility: "hidden"}}>0</span>}
                        </td>

                      </tr>
                    </>
                  )
                }
              )}


              <tr className="bgcolor2 bold700">
                <td className="success">{data_show(result?.wt_month1)}</td>
                <td className="success">{data_show(result?.wt_month2)}</td>
                <td className="success">{data_show(result?.wt_month3)}</td>
                <td className="success">{data_show(result?.wt_month5)}</td>
                <td className="success">{data_show(result?.wt_month6)}</td>
                <td className="success">{data_show(result?.wt_month12)}</td>
                <td className="line_r">{data_show(result?.wt_month18)}</td>

                <td className="success">{data_show(result?.wt_m_num)}</td>
                <td className="line_r">{data_show(result?.wt_f_num)}</td>

                <td className="success">{data_show(result?.wt_join1)}</td>
                <td className="success">{data_show(result?.wt_join2)}</td>
                <td className="success">{data_show(result?.wt_join3)}</td>
                <td className="success">{data_show(result?.wt_join4)}</td>
                <td className="success">{data_show(result?.wt_join5)}</td>
                <td className="success">{data_show(result?.wt_join6)}</td>
                <td className="success">{data_show(result?.wt_join7)}</td>
                <td className="success">{data_show(result?.wt_join8)}</td>
                <td className="success">{data_show(result?.wt_join9)}</td>
                <td className="success">{data_show(result?.wt_join10)}</td>
                <td className="success">{data_show(result?.wt_join12)}</td>
                <td className="success">{data_show(result?.wt_join13)}</td>
                <td className="line_r">{data_show(result?.wt_join11)}</td>

                <td className="success">{data_show(result?.wt_time1)}</td>
                <td className="success">{data_show(result?.wt_time2)}</td>
                <td className="success">{data_show(result?.wt_time3)}</td>
                <td className="success">{data_show(result?.wt_time4)}</td>
                <td className="line_r">{data_show(result?.wt_time5)}</td>

                <td className="success">{data_show(result?.wt_age1)}</td>
                <td className="success">{data_show(result?.wt_age2)}</td>
                <td className="success">{data_show(result?.wt_age3)}</td>
                <td className="success">{data_show(result?.wt_age4)}</td>
                <td className="success">{data_show(result?.wt_age5)}</td>
                <td className="success">{data_show(result?.wt_age6)}</td>
              </tr>

              <tr className="bgcolor2 bold700">
                <td>
                  {(result?.wt_month1 + result?.wt_month2 + result?.wt_month3 + result?.wt_month5 + result?.wt_month6 + result?.wt_month12 + result?.wt_month18) ?
                    fixed(result?.wt_month1 / ((result?.wt_month1 || 0) + (result?.wt_month2 || 0) + (result?.wt_month3 || 0) + (result?.wt_month5 || 0) + (result?.wt_month6 || 0) + (result?.wt_month12 || 0) + (result?.wt_month18 || 0)) * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {(result?.wt_month1 + result?.wt_month2 + result?.wt_month3 + result?.wt_month5 + result?.wt_month6 + result?.wt_month12 + result?.wt_month18) ?
                    fixed(result?.wt_month2 / ((result?.wt_month1 || 0) + (result?.wt_month2 || 0) + (result?.wt_month3 || 0) + (result?.wt_month5 || 0) + (result?.wt_month6 || 0) + (result?.wt_month12 || 0) + (result?.wt_month18 || 0)) * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {(result?.wt_month1 + result?.wt_month2 + result?.wt_month3 + result?.wt_month5 + result?.wt_month6 + result?.wt_month12 + result?.wt_month18) ?
                    fixed(result?.wt_month3 / ((result?.wt_month1 || 0) + (result?.wt_month2 || 0) + (result?.wt_month3 || 0) + (result?.wt_month5 || 0) + (result?.wt_month6 || 0) + (result?.wt_month12 || 0) + (result?.wt_month18 || 0)) * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {(result?.wt_month1 + result?.wt_month2 + result?.wt_month3 + result?.wt_month5 + result?.wt_month6 + result?.wt_month12 + result?.wt_month18) ?
                    fixed(result?.wt_month5 / ((result?.wt_month1 || 0) + (result?.wt_month2 || 0) + (result?.wt_month3 || 0) + (result?.wt_month5 || 0) + (result?.wt_month6 || 0) + (result?.wt_month12 || 0) + (result?.wt_month18 || 0)) * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {(result?.wt_month1 + result?.wt_month2 + result?.wt_month3 + result?.wt_month5 + result?.wt_month6 + result?.wt_month12 + result?.wt_month18) ?
                    fixed(result?.wt_month6 / ((result?.wt_month1 || 0) + (result?.wt_month2 || 0) + (result?.wt_month3 || 0) + (result?.wt_month5 || 0) + (result?.wt_month6 || 0) + (result?.wt_month12 || 0) + (result?.wt_month18 || 0)) * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {(result?.wt_month1 + result?.wt_month2 + result?.wt_month3 + result?.wt_month5 + result?.wt_month6 + result?.wt_month12 + result?.wt_month18) ?
                    fixed(result?.wt_month12 / ((result?.wt_month1 || 0) + (result?.wt_month2 || 0) + (result?.wt_month3 || 0) + (result?.wt_month5 || 0) + (result?.wt_month6 || 0) + (result?.wt_month12 || 0) + (result?.wt_month18 || 0)) * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {(result?.wt_month1 + result?.wt_month2 + result?.wt_month3 + result?.wt_month5 + result?.wt_month6 + result?.wt_month12 + result?.wt_month18) ?
                    fixed(result?.wt_month18 / ((result?.wt_month1 || 0) + (result?.wt_month2 || 0) + (result?.wt_month3 || 0) + (result?.wt_month5 || 0) + (result?.wt_month6 || 0) + (result?.wt_month12 || 0) + (result?.wt_month18 || 0)) * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>

                <td>
                  {(result?.wt_m_num + result?.wt_f_num) ?
                    fixed(result?.wt_m_num / ((result?.wt_m_num || 0) + (result?.wt_f_num || 0)) * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td className="line_r">
                  {(result?.wt_m_num + result?.wt_f_num) ?
                    fixed(result?.wt_f_num / ((result?.wt_m_num || 0) + (result?.wt_f_num || 0)) * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>


                <td>
                  {ttj ?
                    fixed(result?.wt_join1 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttj ?
                    fixed(result?.wt_join2 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttj ?
                    fixed(result?.wt_join3 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttj ?
                    fixed(result?.wt_join4 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttj ?
                    fixed(result?.wt_join5 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttj ?
                    fixed(result?.wt_join6 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttj ?
                    fixed(result?.wt_join7 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttj ?
                    fixed(result?.wt_join8 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttj ?
                    fixed(result?.wt_join9 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttj ?
                    fixed(result?.wt_join10 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttj ?
                    fixed(result?.wt_join12 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttj ?
                    fixed(result?.wt_join13 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td className="line_r">
                  {ttj ?
                    fixed(result?.wt_join11 / ttj * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>

                <td>
                  {ttt ?
                    fixed(result?.wt_time1 / ttt * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttt ?
                    fixed(result?.wt_time2 / ttt * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttt ?
                    fixed(result?.wt_time3 / ttt * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {ttt ?
                    fixed(result?.wt_time4 / ttt * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td className="line_r">
                  {ttt ?
                    fixed(result?.wt_time5 / ttt * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>

                <td>
                  {tta ?
                    fixed(result?.wt_age1 / tta * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {tta ?
                    fixed(result?.wt_age2 / tta * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {tta ?
                    fixed(result?.wt_age3 / tta * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {tta ?
                    fixed(result?.wt_age4 / tta * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {tta ?
                    fixed(result?.wt_age5 / tta * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
                <td>
                  {tta ?
                    fixed(result?.wt_age6 / tta * 100) + "%"
                    : <span style={{visibility: "hidden"}}>0</span>}
                </td>
              </tr>


            </table>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          $('#modal_s_year').val(Fn.getCurrentDate().split('-')[0]);
          $('#modal_s_month').val(Fn.getCurrentDate().split('-')[1]);
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '800px'}}>
            <div className="POP_title">
              <h5><strong>데이터 생성</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>
            <div className="POP_cnt_box">
              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select className="form-select" id="modal_s_year">
                    {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>
                <div className="pr-1">
                  <select className="form-select" id="modal_s_month">
                    {[...Array(12)].map((_, index) => {
                      const monthNumber = index + 1;
                      const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                      return (
                        <option key={monthString} value={monthString}>{monthString}</option>
                      );
                    })}
                  </select>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal()}>검색</button>
                </div>
              </div>
              <div className="pb-2">
                <table className="view-rounded sub_table_border text-center auto">
                  <thead>
                  <tr>
                    <th className="w_70px">No</th>
                    <th>지점명</th>
                    <th>최종 주</th>
                    <th>생성일시</th>
                    <th>생성</th>
                  </tr>
                  </thead>
                  <tbody>
                  {modalResult?.result && modalResult?.result?.map((ret, i) => {
                    return (
                      <tr>
                        <td>{i + 1}</td>
                        <td>{ret?.branch_name}</td>
                        <td>{ret?.last_week || ''}</td>
                        <td>{ret?.last_date?.replace(/-/g, '.')?.substring(2, 16) || ''}</td>
                        <td>
                          <button type="button" className="btn btn-ssm btn-outline-dark" onClick={() => make_data(ret?.branch_code, ret?.branch_name)}>생성</button>
                        </td>
                      </tr>
                    );
                  })}
                  </tbody>
                </table>
              </div>
              <div className="text-center my-3">
                <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setModalOpen(false)}>닫기</button>
              </div>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default AnalysisWeeklyReport;
