import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios from "axios";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import Modal from "react-modal";
import {useHistory, useLocation} from "react-router-dom";
import JDatePicker from "../common/JDatePicker";

const ConfigItemList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();
  const location = useLocation();
  const [usePrevUrl, setUsePrevUrl] = useState(location?.state?.usePrevUrl);

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [srchRenew5, setSrchRenew5] = useState(0);
  const [renew, setRenew] = useState(0);
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [modal3Open, setModal3Open] = useState(false);
  const [modal4Open, setModal4Open] = useState(false);
  const [modal5Open, setModal5Open] = useState(false);
  const [result1, setResult1] = useState({});
  const [result2, setResult2] = useState({});
  const [result5, setResult5] = useState();

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    if (!srchRenew5) return;
    loadModal5();
  }, [srchRenew5]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    let url = `/branchop/item/list/ready?s_brand=${$('#s_brand option:selected').val() || ''}&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_ca_id=${$('#s_ca_id option:selected').val() || ''}&s_it_acc=${$('#s_it_acc option:selected').val() || ''}&s_it_use=${$('#s_it_use option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`;
    if (usePrevUrl) {
      url = localStorage.getItem("url") || url;
      const params = url.split("?")[1];
      try {
        params?.split("&")?.forEach(value => {
          try {
            if (value?.includes("=")) {
              if (value?.split("=")?.[0] === "page") setCurrPage(Fn.parseInt(value?.split("=")?.[1]));
              else $('#' + value?.split("=")?.[0]).val(value?.split("=")?.[1]);
            }
          } catch (err) {
          }
        });
      } catch (err) {
      }
      setUsePrevUrl(false);
    }

    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_brand').html() === '') {
            let optionsHTML = '<option value="">::브랜드::</option>';
            result?.bl?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_brand').html(optionsHTML);
          }

          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          if ($('#s_it_acc').html() === '') {
            let optionsHTML = '<option value="">::계정_적요선택::</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.j_no}">${list_row.j_jukname}</option>`;
            });
            $('#s_it_acc').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);

          localStorage.setItem("url", `/branchop/item/list/ready?s_brand=${$('#s_brand option:selected').val() || ''}&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_ca_id=${$('#s_ca_id option:selected').val() || ''}&s_it_acc=${$('#s_it_acc option:selected').val() || ''}&s_it_use=${$('#s_it_use option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`);

          setTimeout(() => {
            result?.result?.forEach((list_row, i) => {
              $('#it_order' + (i + 1)).val(list_row.it_order);
            })
          }, 100);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal1 = () => {
    console.log(`/branchop/branch/select2/ready?base_seq=${$('#base_seq').val()}`);
    axios2
      .post(`/branchop/branch/select2/ready?base_seq=${$('#base_seq').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult1(result);

          setTimeout(() => {
            result?.list?.forEach((list_row) => {
              if (result?.branchList?.filter(r => r.branch_code === list_row?.branch_code)?.length) {
                $('#m1chk_' + list_row?.branch_code).prop("checked", true);
              }
            });
          }, 200);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal2 = () => {
    axios2
      .post(`/branchop/branch/select/show/ready?base_seq=${$('#base_seq').val()}&it_id=${$('#it_id').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult2(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal5 = () => {
    axios2
      .post(`/branchop/item/sale/list/ready?it_id=${$('#m5_it_id').val()}&s_branch_code=${$('#m5_branch_code option:selected').val() || ''}&s_date1=${$('#m5_s_date1').val()}&s_date2=${$('#m5_s_date2').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#m5_branch_code').html() === '') {
            let optionsHTML = '<option value="">지점선택</option>';
            result?.Top_list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#m5_branch_code').html(optionsHTML);

            $('#m5_s_date1').val(result?.s_date1);
            $('#m5_s_date2').val(result?.s_date2);
          }

          console.log('a', result);
          setResult5(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const del_item = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 상품을 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/branchop/item/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  //  3-1.
  const change_state = (base_seq, type, value) => {
    let updatedValue = value;
    if (type === "it_order") {
      updatedValue = value;
    } else if (type === "menu_YN") {
      if (value === true) {
        updatedValue = "Y";
      } else {
        updatedValue = "N";
      }
    } else {
      if (value === true) {
        updatedValue = "1";
      } else {
        updatedValue = "0";
      }
    }

    axios2
      .post(`/branchop/change/item/state?base_seq=${base_seq}&type=${type}&value=${updatedValue}`)
      .then((response) => {
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  //  4-1.
  const change_lo_su = (base_seq, value) => {
    let updatedValue = value ? "Y" : "N";

    axios2
      .post(`/branchop/change/lo/su?base_seq=${base_seq}&value=${updatedValue}`)
      .then((response) => {
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  //  6-1.
  const popUp = (file, names, width, height, sc) => {
    const w = width;
    const h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h},scrollbars=${sc},top=${wint},left=${winl}`);
  };

  const form1 = 'TODO'; // TODO

  const chk_copy = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join(",");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    setModal4Open(true);
  };

  const chk_copy_real = () => {
    if ($('#m4_e_branch_code option:selected').val() === '') {
      alert("복사 지점을 선택해주세요");
      $('#m4_e_branch_code').focus();
      return;
    }

    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join(",");

    const formData = new FormData();

    let values = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join(",");

    formData.append("chk[]", values);
    formData.append("e_branch_code", $('#m4_e_branch_code option:selected').val());

    axios2.postFormEx('/branchop/item/copy/chk', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();
        alert("복사되었습니다");
        setModal4Open(false);
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const go_submit3 = () => {
    if ($('#m3_s_branch_code option:selected').val() === '') {
      alert("원본 지점을 선택해주세요");
      $('#m3_s_branch_code').focus();
      return;
    }
    if ($('#m3_e_branch_code option:selected').val() === '') {
      alert("복사 지점을 선택해주세요");
      $('#m3_e_branch_code').focus();
      return;
    }

    const formData = new FormData();

    formData.append("s_branch_code", $('#m3_s_branch_code option:selected').val());
    formData.append("e_branch_code", $('#m3_e_branch_code option:selected').val());

    axios2.postFormEx('/branchop/item/copy', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        data = data.trim();
        alert("복사되었습니다");
        setModal3Open(false);
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const loadModal3 = () => {
    axios2
      .post(`/branchop/item/copy/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#m3_s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#m3_s_branch_code').html(optionsHTML);
          }
          if ($('#m3_e_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#m3_e_branch_code').html(optionsHTML);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const loadModal4 = () => {
    axios2
      .post(`/branchop/item/copy/chk/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#m4_e_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#m4_e_branch_code').html(optionsHTML);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit1() {
    let con = $('[id^=m1chk]:checked').map(function () {
      return $(this).val();
    }).get().join(",");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    const formData = new FormData();

    let values = $('[id^=m1chk]:checked').map(function () {
      return $(this).val();
    }).get().join(",");

    formData.append("chk[]", values);
    formData.append("it_id", $('#it_id').val());
    formData.append("brand", $('#brand').val());

    axios2.postFormEx('/branchop/item/branch/update', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        setRenew(Fn.getRenewTime());
        setModal1Open(false);
        setModal2Open(true);
      })
      .catch((error) => {
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div>
        {/* 컨텐츠 내용 시작 */}
        <div className="d-flex m-3 table_wide fn_search_box">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_brand">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_branch_code">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_ca_id">
              <option value="">::카테고리선택::</option>
              <option value="10">기본</option>
              <option value="20">부가</option>
              <option value="30">무상</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_it_acc">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_it_use">
              <option value="xx">전체</option>
              <option value="1">사용</option>
              <option value="2">미사용</option>
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="상품명 입력" id="key"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setModal3Open(true)}>전체상품복사</button>
          </div>
          <div>
            <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => chk_copy()}>체크상품복사</button>
          </div>
        </div>
        {/* 테이블 */}
        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_border table">
            <colgroup>
              <col width="50px"/>
              <col width="40px"/>
              <col width="75px"/>
              <col width="100px"/>
              <col width="70px"/>
              <col width="*"/>
              <col width="70px"/>
              <col width="75px"/>
              <col width="70px"/>
              <col width="128px"/>
              <col width="130px"/>
              <col width="60px"/>
              <col width="50px"/>
              <col width="60px"/>
              <col width="55px"/>
              <col width="55px"/>
              <col width="70px"/>
              <col width="50px"/>
              <col width="65px"/>
              <col width="70px"/>
              <col width="130px"/>
            </colgroup>
            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>브랜드</th>
              <th>상품코드</th>
              <th>카테고리</th>
              <th>상품명</th>
              <th>계정적요</th>
              <th>가격</th>
              <th>이용기간</th>
              <th>이용가능일</th>
              <th>메뉴노출기간</th>
              <th>메뉴판</th>
              <th>순서</th>
              <th>운동복</th>
              <th>라커</th>
              <th>1일<br/>2회</th>
              <th>장기등록<br/>인센티브</th>
              <th>사용</th>
              <th>사용(H)</th>
              <th>운동/<br/>라커없음</th>
              <th>사용지점/복사</th>
            </tr>
            {rets.map((ret, i) => {
              const count = i + 1;

              return (
                <tr key={i}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    {ret?.delYN === 'Y' && (
                      <div>
                        <input
                          type="checkbox"
                          name={`chk[${count}]`}
                          value={btoa(ret?.seq)}
                          className="checkbox"
                          id={`achk${count}`}
                        />
                        <label htmlFor={`achk${count}`}>
                          <span></span>
                        </label>
                      </div>
                    )}
                  </td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px'}}>
                      {ret?.brand_name}
                    </div>
                  </td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px'}}>
                      <a
                        style={{cursor: 'pointer'}}
                        onClick={() => {
                          history.push({
                            pathname: '/config/item/reg', state: {
                              baseSeq: btoa(ret?.seq),
                            }
                          });
                        }}
                      >
                        <strong>{ret?.it_id}</strong>
                      </a>
                    </div>
                  </td>
                  <td>
                    <div align="center">{ret?.caIdTxt}</div>
                  </td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px'}}>
                      {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ? (
                        <a
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            $('#m5_it_id').val(ret?.it_id);
                            setModal5Open(true);
                          }}
                        >
                          <b>{ret?.it_name}</b>
                        </a>
                      ) : (
                        ret?.it_name
                      )}
                    </div>
                  </td>

                  <td>
                    <div align="center">{ret?.it_acc_name}</div>
                  </td>
                  <td>
                    <div align="right" style={{paddingRight: '5px'}}>
                      {Fn.numberWithCommas(Fn.parseInt(ret?.it_amount))}
                    </div>
                  </td>
                  {ret?.delYN === 'Y' ? (
                    <>
                      <td>
                        <div align="right" style={{paddingRight: '5px'}}>
                          {ret?.it_period ? `${ret?.it_period} ${ret?.it_period_type?.replace("month", "mon")}` : ''}
                        </div>
                      </td>
                      <td>
                        <div align="center">{ret?.ableDayShow}</div>
                      </td>
                      <td>
                        <div align="center">
                          {`${ret?.menu_start_day?.substring(2, 10)?.replaceAll("-", ".")}~${ret?.menu_end_day?.substring(2, 10)?.replaceAll("-", ".")}`}
                        </div>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name="menu_YN"
                          value="Y"
                          onClick={(e) => change_state(btoa(ret?.seq), 'menu_YN', e.target.checked)}
                          checked={ret?.menu_YN === 'Y'}
                          className="checkbox"
                          id={`menu_YN${count}`}
                        />
                        <label htmlFor={`menu_YN${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="text"
                          id={`it_order${count}`}
                          name="it_order"
                          // value={ret?.it_order}
                          style={{imeMode: 'disabled'}}
                          // onFocus={this.select()}
                          // onKeyPress={numkeyCheck(event)}
                          onBlur={(e) => change_state(btoa(ret?.seq), 'it_order', e.target.value)}
                          maxLength={3}
                        />
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name="it_suit"
                          value="1"
                          onClick={(e) => change_state(btoa(ret?.seq), 'it_suit', e.target.checked)}
                          checked={ret?.it_suit == 1}
                          className="checkbox"
                          id={`it_suit${count}`}
                        />
                        <label htmlFor={`it_suit${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name="it_locker"
                          value="1"
                          onClick={(e) => change_state(btoa(ret?.seq), 'it_locker', e.target.checked)}
                          checked={ret?.it_locker == 1}
                          className="checkbox"
                          id={`it_locker${count}`}
                        />
                        <label htmlFor={`it_locker${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name="it_day"
                          value="1"
                          onClick={(e) => change_state(btoa(ret?.seq), 'it_day', e.target.checked)}
                          checked={ret?.it_day == 1}
                          className="checkbox"
                          id={`it_day${count}`}
                        />
                        <label htmlFor={`it_day${count}`}>
                          <span></span>
                        </label>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </>
                  )}
                  <td>
                    <div align="right" style={{paddingRight: '5px'}}>
                      {ret?.night_YN === 'Y' && <img src="https://cdc-smms.spoany.co.kr/image/icon_sim.gif" style={{width: "16px", height: "16px"}}/>} {Fn.numberWithCommas(ret?.incen_price)}
                    </div>
                  </td>

                  {ret?.delYN === 'Y' ? (
                    <>
                      <td>
                        <input
                          type="checkbox"
                          name="it_use"
                          value="1"
                          onClick={(e) => change_state(btoa(ret?.seq), 'it_use', e.target.checked)}
                          checked={ret?.it_use == 1}
                          className="checkbox"
                          id={`it_use${count}`}
                        />
                        <label htmlFor={`it_use${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          name="it_use_h"
                          value="1"
                          onClick={(e) => change_state(btoa(ret?.seq), 'it_use_h', e.target.checked)}
                          checked={ret?.it_use_h == 1}
                          className="checkbox"
                          id={`it_use_h${count}`}
                        />
                        <label htmlFor={`it_use_h${count}`}>
                          <span></span>
                        </label>
                      </td>
                    </>
                  ) : (
                    <>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                    </>
                  )}

                  <td>
                    <div align="center">
                      <input
                        type="checkbox"
                        name="lo_su_no"
                        value="1"
                        onClick={(e) => change_lo_su(btoa(ret?.seq), e.target.checked)}
                        checked={ret?.lo_su_no === 'Y'}
                        className="checkbox"
                        id={`lo_su_no${count}`}
                      />
                      <label htmlFor={`lo_su_no${count}`}>
                        <span></span>
                      </label>
                    </div>
                  </td>

                  <td>
                    <div align="center">
                      {ret?.branch_num ? (
                        <input
                          className="btn btn-sssm btn-outline-point"
                          type="button"
                          value={`확인(${parseInt(ret?.branch_num)})`}
                          onClick={() => {
                            $('#base_seq').val(btoa(ret?.seq));
                            $('#it_id').val(ret?.it_id);
                            $('#brand').val(ret?.brand);
                            setModal2Open(true)
                          }}
                          // onClick={() =>
                          //   pop_up(
                          //     `branch_select_show.html?it_id=${ret?.it_id}&base_seq=${btoa(ret?.seq)}`,
                          //     'branch_select_show',
                          //     '450',
                          //     '600',
                          //     'yes'
                          //   )
                          // }
                        />
                      ) : (
                        <input
                          className="btn btn-sssm btn-outline-point"
                          type="button"
                          value="확인 (0)"
                          onClick={() => alert('등록된 지점이 없습니다')}
                        />
                      )}
                      <input
                        className="btn btn-sssm btn-outline-dark mx-1"
                        type="button"
                        value="복사"
                        onClick={() => {
                          history.push({
                            pathname: '/config/item/reg', state: {
                              copySeq: btoa(ret?.seq),
                            }
                          });
                        }}
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
        <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          <div>
            <button type="button" className="btn btn-sm btn-outline-point px-3" onClick={() => history.push("/config/item/reg")}>추가</button>
            <button type="button" className="btn btn-sm btn-outline-dark px-3 mx-1" onClick={() => del_item()}>삭제</button>
          </div>
        </nav>
      </div>


      {/*팝업 1*/}

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal1();
        }}
        onRequestClose={() => setModal1Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 520}}>
            <div className="POP_title">
              <h5><strong>지점설정</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              {/* 회원목록 */}
              <table className="view-rounded sub_table_border text-center">
                <colgroup>
                  <col width="60px"/>
                  <col width="70px"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>
                    <input type="checkbox" onClick={(e) => {
                      if ($(e.target).prop("checked")) $('[id^=m1chk]').prop("checked", true);
                      else $('[id^=m1chk]').prop("checked", false);
                    }}/>
                  </th>
                  <th>브랜드</th>
                  <th>지점명</th>
                </tr>
                {result1?.list?.length > 0 && result1?.list?.map((ret, index) =>
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <input
                        type="checkbox"
                        name={`m1chk[${index + 1}]`}
                        value={ret?.branch_code}
                        className="checkbox"
                        id={`m1chk_${ret?.branch_code}`}
                      />
                    </td>
                    <td>{ret?.brand_name}</td>
                    <td>{ret?.branch_name}</td>
                  </tr>
                )}
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit1()}>등록</button>
              <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => setModal1Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      {/*팝업 2*/}

      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal2();
        }}
        onRequestClose={() => setModal2Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 520}}>
            <div className="POP_title">
              <div className="d-flex flex_gap">
                <h5 className="pt-1"><strong>지점설정</strong></h5>
                <button type="button" className="btn btn-ssm btn-point" onClick={() => {
                  setModal2Open(false);
                  setModal1Open(true);
                }}>지점설정
                </button>
              </div>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              {/* 회원목록 */}
              <table className="view-rounded sub_table_border text-center">
                <colgroup>
                  <col width="60px"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>브랜드</th>
                  <th>지점명</th>
                </tr>
                {result2?.list && result2?.list.map((item, index) =>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.ssRow?.brand_name}</td>
                    <td>{item?.branch_name}</td>
                  </tr>
                )}
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-dark" onClick={() => setModal2Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      {/*팝업 3*/}

      <Modal
        isOpen={modal3Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onRequestClose={() => setModal3Open(false)}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal3();
        }}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 520}}>
            <div className="POP_title">
              <div className="d-flex flex_gap">
                <h5 className="pt-1"><strong>전체상품복사</strong></h5>
              </div>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal3Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              {/* 회원목록 */}
              <table className="view-rounded sub_table_border text-center">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>원본지점</th>
                  <th>복사지점</th>
                </tr>
                <tr>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="m3_s_branch_code">
                    </select>
                  </td>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="m3_e_branch_code">
                    </select>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-point mx-1" onClick={() => go_submit3()}>상품복사</button>
              <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setModal3Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      {/*팝업 4*/}

      <Modal
        isOpen={modal4Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onRequestClose={() => setModal4Open(false)}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal4();
        }}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 280}}>
            <div className="POP_title">
              <div className="d-flex flex_gap">
                <h5 className="pt-1"><strong>체크상품복사</strong></h5>
              </div>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal4Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              {/* 회원목록 */}
              <table className="view-rounded sub_table_border text-center">
                <colgroup>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>복사지점</th>
                </tr>
                <tr>
                  <td>
                    <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="m4_e_branch_code">
                    </select>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-point mx-1" onClick={() => chk_copy_real()}>상품복사</button>
              <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setModal4Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>


      {/*팝업 5*/}

      <Modal
        isOpen={modal5Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal5();
        }}
        onRequestClose={() => setModal5Open(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 720}}>
            <div className="POP_title">
              <h5><strong>판매내역</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal5Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100" style={{minHeight: 400}}>
              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select className="form-select" aria-label="Default select example" id="m5_branch_code">
                  </select>
                </div>
                <div className="input-group pr-1" style={{width: "auto"}}>
                  <JDatePicker className="form-control" aria-describedby="button-addon2" id="m5_s_date1"/>
                  <p className="mx-2">~</p>
                  <JDatePicker className="form-control" aria-describedby="button-addon2" id="m5_s_date2"/>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal5()}>검색</button>
                </div>
              </div>
              <table className="view-rounded sub_table_border text-center">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>지점명</th>
                  <th>판매수</th>
                  <th>판매금액</th>
                </tr>
                {result5?.result?.length > 0 && result5?.result?.map((ret, index) =>
                  <tr>
                    <td>{ret?.branch_name}</td>
                    <td className="text-right">{Fn.numberWithCommas(ret?.sum_qty)}</td>
                    <td className="text-right">{Fn.numberWithCommas(ret?.sum_price)}</td>
                  </tr>
                )}
                {result5?.result?.length > 0 &&
                  <tr className="bgcolor bold700">
                    <td>합계</td>
                    <td className="text-right">{Fn.numberWithCommas(result5?.result?.reduce((n, {sum_qty}) => n + sum_qty, 0))}</td>
                    <td className="text-right">{Fn.numberWithCommas(result5?.result?.reduce((n, {sum_price}) => n + sum_price, 0))}</td>
                  </tr>
                }
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => setModal5Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="base_seq"/>
      <input type="hidden" id="it_id"/>
      <input type="hidden" id="brand"/>

      <input type="hidden" id="m5_it_id"/>

    </CmnCard>
  );
};

export default ConfigItemList;
