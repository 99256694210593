import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import SmsNew from "../common/SmsNew";
import Constants from "../../constants/constants";


const LockerEmptyReserve = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [smsModalOpen, setSmsModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/locker/empty/reserve/ready?s_lo_posi=${$('#s_lo_posi').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_id=${$('#s_mb_id').val()}&date_type=${$('#date_type option:selected').val() || ''}&off_type=${$('#off_type option:selected').val() || ''}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.list, pageInfo, "ret?.mb_hp");

          setRets(result?.list);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function clear_locker() {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 라커를 비움처리 하시겠습니까?')) {
      const formData = new FormData();

      {
        let values = $('[id^=achk]:checked').map(function () {
          return $(this).val();
        }).get().join(",");
        formData.append("chk[]", values);
      }
      {
        let values = $('[id^=achk]:checked').map(function () {
          return $(this).siblings('[id^=chk2]').val();
        }).get().join(",");
        formData.append("chk2[]", values);
      }
      formData.append("clear_type", 'locker');
      formData.append("auth_code", $('#auth_code').val());

      axios2.postFormEx('/locker/clear/locker', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('비움처리 되었습니다.');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const clear_g_locker = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 골프 라커를 비움처리 하시겠습니까?')) {
      const formData = new FormData();

      {
        let values = $('[id^=achk]:checked').map(function () {
          return $(this).val();
        }).get().join(",");
        formData.append("chk[]", values);
      }
      {
        let values = $('[id^=achk]:checked').map(function () {
          return $(this).siblings('[id^=chk2]').val();
        }).get().join(",");
        formData.append("chk2[]", values);
      }
      formData.append("clear_type", 'g_locker');
      formData.append("auth_code", $('#auth_code').val());

      axios2.postFormEx('/locker/clear/locker', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('비움처리 되었습니다.');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function Excel_empty_reserve() {
    var auth_code = $("#auth_code").val();
    if (!auth_code) {
      alert("인증코드를 입력해주세요");
      return;
    }

    var down_reason = $("#down_reason").val();
    if (!down_reason) {
      alert("사유를 입력해주세요");
      return;
    }

    const url = `/xls/locker/empty/reserve?s_lo_posi=${$('#s_lo_posi').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_id=${$('#s_mb_id').val()}&date_type=${$('#date_type option:selected').val() || ''}&off_type=${$('#off_type option:selected').val() || ''}&auth_code=${auth_code}&down_reason=${down_reason}`;
    Fn.download(Constants.API_URL + url);
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 justify-content-between flex-wrap">
        <div className="my-1 d-flex flex-nowrap fn_search_box">
          <div className="pr-1">
            <input
              type="text"
              className="form-control width_shorts"
              placeholder="라커번호"
              id="s_lo_posi"
            />
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control width_shorts"
              placeholder="회원명"
              id="s_mb_name"
            />
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control"
              placeholder="카드번호"
              id="s_mb_id"
            />
          </div>
          <div className="pr-1">
            <select className="form-select" id="date_type">
              <option value="A" selected>라커종료일</option>
              <option value="B">골프라커종료일</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="off_type">
              <option value="F">1일경과</option>
              <option value="A" selected>7일경과</option>
              <option value="B">15일경과</option>
              <option value="C">1달경과</option>
              <option value="D">2달경과</option>
              <option value="E">3달경과</option>
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(() => Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>

        <div className="my-1">
          <button type="button" className="btn btn-sm btn-dark" onClick={() => {
            if (eval("pageInfo?.totalArticles") > 0) {
              if (!window.confirm(`검색된 ${pageInfo?.totalArticles}명 회원에게 SMS를 발송하시겠습니까?`)) return;
            } else {
              if (!window.confirm(`검색된 회원에게 SMS를 발송하시겠습니까?`)) return;
            }

            $('#SMS_editMsgid').val('');
            $('#SMS_b_type').val("e_locker");
            $('#SMS_s_type').val("all");

            setSmsModalOpen(true);
          }}>검색 SMS 발송
          </button>
          <button type="button" className="btn btn-sm btn-dark mx-1"
                  onClick={() => {
                    if ($('[id^=achk]:checked').length === 0) {
                      window.alert('체크된 리스트가 없습니다.');
                      return;
                    }

                    let con = $('[id^=achk]:checked').map(function () {
                      return $(this).val();
                    }).get().join("|");

                    $('#SMS_editMsgid').val(con);
                    $('#SMS_b_type').val("e_locker");
                    $('#SMS_s_type').val("sel");

                    setSmsModalOpen(true);
                  }}
          >선택 SMS 발송
          </button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border auto">
          <colgroup>
            <col width="60px"/>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            <th>구분</th>
            <th>그룹명</th>
            <th>번호</th>
            <th>회원명</th>
            <th>카드번호</th>
            <th>핸드폰</th>
            <th>시작일</th>
            <th>종료일</th>
            <th>Key</th>
          </tr>
          {rets && rets.map((ret, i) => {

            let gubun, startDate, endDate, pass;

            if (ret?.locker === 'Y') {
              gubun = '라커1';
              startDate = ret?.locker_start_date;
              endDate = <span style={{color: 'red'}}>{ret?.locker_end_date}</span>;
              pass = ret?.locker_pass;
            } else if (ret?.g_locker === 'Y') {
              gubun = '골프라커';
              startDate = ret?.g_locker_start_date;
              endDate = <span style={{color: 'red'}}>{ret?.g_locker_end_date}</span>;
              pass = ret?.locker_pass;
            } else {
              gubun = '라커';
              startDate = ret?.locker_start_date;
              endDate = <span style={{color: 'red'}}>{ret?.locker_end_date}</span>;
              pass = ret?.locker_pass;
            }

            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  <input
                    type="checkbox"
                    name="chk"
                    value={btoa(ret?.mb_no)}
                    className="checkbox"
                    id={`achk${i + 1}`}
                  />
                  <input type="hidden" id={`chk2${i + 1}`} value={ret?.lo_posi}/>
                </td>
                <td>{gubun}</td>
                <td className="text-left">{ret?.lo_group}</td>
                <td>{ret?.lo_num}</td>
                <td>{ret?.mb_name}</td>
                <td>{ret?.mb_id}</td>
                <td>
                  <div id={`default_show${i + 1}`}>***-****-****</div>
                </td>
                <td>{startDate}</td>
                <td>{endDate}</td>
                <td>{pass}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={11}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
          <div className="d-flex mx-1">
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="다운사유"
              id="down_reason"
              style={{width: "180px"}}
            />
            <button type="button" className="btn btn-sm btn-outline-point px-2" onClick={() => Excel_empty_reserve()}>
              엑셀저장
            </button>
          </div>
          <div>
            <button type="button" className="btn btn-sm btn-outline-success px-2" onClick={() => clear_locker()}>
              라커비움처리
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-success px-2 mx-1"
              onClick={() => clear_g_locker()}>
              골프라커비움처리
            </button>
            {pageInfo?.totalArticles > 0 && (
              Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)
            ) && (
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('empty_reserve')}>연락처 확인</button>
            )}
          </div>
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

      <SmsNew
        isOpen={smsModalOpen}
        onModalClose={() => setSmsModalOpen(false)}
        searchType={"sms_list"}
        callback={() => {
          setSmsModalOpen(false);
        }}
      />
      <input type="hidden" id="SMS_b_type"/>
      <input type="hidden" id="SMS_s_type"/>
      <input type="hidden" id="SMS_editMsgid"/>

    </CmnCard>
  );
};

export default LockerEmptyReserve;
