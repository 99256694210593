import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios from "axios";
import Modal from "react-modal";
import JDatePicker from "../common/JDatePicker";
import Fn from "../../libraries/Fn";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import {value} from "lodash/seq";

const SalesProductReg = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.baseSeq || '';

  const [modalResult, setModalResult] = useState();

  useEffect(() => {
    $('#base_seq').val(baseSeq);
    load();
  }, []);

  const load = () => {
    axios2
      .post(`/sales/sales/product/reg/ready?base_seq=${$('#base_seq').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#company_seq').html() === '') {
            let optionsHTML = '<option value="">공급업체</option>';
            result?.list1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.shop_name}</option>`;
            });
            $('#company_seq').html(optionsHTML);
          }
          if ($('#category').html() === '') {
            let optionsHTML = '<option value="">브랜드</option>';
            result?.list2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#category').html(optionsHTML);
          }
          if ($('#pum_position').html() === '') {
            let optionsHTML = '<option value="">품목분류</option>';
            result?.list3?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#pum_position').html(optionsHTML);
          }
          if ($('#pum_code').html() === '') {
            let optionsHTML = '<option value="">품목구분</option>';
            result?.list4?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#pum_code').html(optionsHTML);
          }

          $('#branch_list').val(result?.branchList);
          $('#company_seq').val(result?.result?.company_seq);
          $('#p_code').val(result?.result?.p_code);
          $('#p_name').val(result?.result?.p_name);
          $('#category').val(result?.result?.category);
          $('#barcode').val(result?.result?.barcode);
          $('#pum_position').val(result?.result?.pum_position);
          $('#pum_code').val(result?.result?.pum_code);
          $('#buy_price').val(result?.result?.buy_price);
          $('#sale_price').val(result?.result?.sale_price);
          $('#use_YN').val(result?.result?.use_YN);
          $('#purchase_cnt').val(result?.result?.purchase_cnt);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const [modalOpen, setModalOpen] = useState(false);

  const loadModal = () => {
    let url = `/branchop/branch/select/ready?s_brand=${$('#s_brand option:selected').val() || ''}`;
    if (($('#s_brand option:selected').val() || '') === '') {
      url = `/branchop/branch/select/ready`;
    }
    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_brand').html() === '') {
            let optionsHTML = '<option value="">::브랜드::</option>';
            result?.bl?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_brand').html(optionsHTML);
          }

          setModalResult(result);

          result?.list?.map((row, i) => {
            if ($('#branch_list').val()?.split('|')?.filter(r => r == row.branch_code)?.length)
              $('#mchk_' + (i + 1)).prop("checked", true);
            else
              $('#mchk_' + (i + 1)).prop("checked", false);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const onModalSelect = () => {
    let values = $('[id^=mchk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    $('#branch_list').val(values);

    setModalOpen(false);
  }

  const goSubmit = () => {
    if ($('#company_seq option:selected').val() == "") {
      alert('공급업체를 선택해주세요');
      $('#company_seq').focus();
      return;
    }

    if (!$('#base_seq').val()) {
      if (!$('#p_code').val()) {
        alert('상품코드를 입력해주세요');
        $('#p_code').focus();
        return;
      }
    }

    if (!$('#p_name').val()) {
      alert('상품명을 입력해주세요');
      $('#p_name').focus();
      return;
    }

    if (!$('#category option:selected').val()) {
      alert('브랜드를 선택해주세요');
      $('#category').focus();
      return;
    }
    if (!$('#pum_position option:selected').val()) {
      alert('품목분류를 선택해주세요');
      $('#pum_position').focus();
      return;
    }
    if (!$('#pum_code option:selected').val()) {
      alert('품목구분을 선택해주세요');
      $('#pum_code').focus();
      return;
    }
    if ($('#buy_price').val() == "" || $('#buy_price').val() == "0") {
      alert('매입가를 입력해주세요');
      $('#buy_price').focus();
      return;
    }
    if ($('#sale_price').val() == "" || $('#sale_price').val() == "0") {
      alert('판매가를 입력해주세요');
      $('#sale_price').focus();
      return;
    }

    //매입가는 판매가보다 작아야 함
    if (Fn.parseInt($('#buy_price').val()) > Fn.parseInt($('#sale_price').val())) {
      alert("매입가가 판매가보다 높습니다.");
      return;
    }

    if ($('#barcode').val() == "") {
      alert('바코드를 입력해주세요');
      $('#barcode').focus();
      return;
    }

    axios2
      .postEx('/sales/sales/product/reg', {
        company_seq: $('#company_seq option:selected').val(),
        p_code: $('#p_code').val(),
        p_name: $('#p_name').val(),
        category: $('#category option:selected').val(),
        barcode: $('#barcode').val(),
        pum_position: $('#pum_position option:selected').val(),
        pum_code: $('#pum_code option:selected').val(),
        buy_price: $('#buy_price').val(),
        sale_price: $('#sale_price').val(),
        use_YN: $('#use_YN option:selected').val(),
        purchase_cnt: $('#purchase_cnt').val() || '',
        base_seq: $('#base_seq').val() || '',
        branch_list: $('#branch_list').val() || '',
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === 'err') {
          window.alert('오류가 발생하였습니다');
        } else if (data === 'dup_p_code') {
          window.alert('상품 코드가 중복되었습니다.');
        } else if (data === 'dup_barcode') {
          window.alert('바코드가 중복되었습니다.');
        } else if (data === 'insert_ok') {
          window.alert('등록되었습니다.');
          history.push("/sales/product/list");
        } else if (data === 'update_ok') {
          window.alert('수정되었습니다');
          history.push("/sales/product/list");
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="wid_MAX">
        <div className="m-3">
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="130px"/>
              <col width="*"/>
              <col width="130px"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>공급업체</th>
              <td>
                <select className="form-select" id="company_seq">
                </select>
              </td>
              <th>사용지점</th>
              <td className="text-left">
                <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => setModalOpen(true)}>지점설정</button>
              </td>
            </tr>
            <tr>
              <th>상품코드</th>
              <td><input type="text" className="form-control table-inline" id="p_code"/></td>
              <th>상품명</th>
              <td><input type="text" className="form-control table-inline" id="p_name"/></td>
            </tr>
            <tr>
              <th>브랜드</th>
              <td>
                <select className="form-select" id="category">
                </select>
              </td>
              <th>바코드</th>
              <td><input type="text" className="form-control table-inline" id="barcode"/></td>
            </tr>
            <tr>
              <th>품목분류</th>
              <td>
                <select className="form-select" id="pum_position">
                </select>
              </td>
              <th>품목구분</th>
              <td>
                <select className="form-select" id="pum_code">
                </select>
              </td>
            </tr>
            <tr>
              <th>매입가격</th>
              <td><input type="text" className="form-control table-inline" id="buy_price"/></td>
              <th>판매가격</th>
              <td><input type="text" className="form-control table-inline" id="sale_price"/></td>
            </tr>
            <tr>
              <th>사용여부</th>
              <td>
                <select className="form-select" id="use_YN">
                  <option value="Y">사용</option>
                  <option value="N">미사용</option>
                </select>
              </td>
              <th>발주단위</th>
              <td><input type="text" className="form-control table-inline" id="purchase_cnt"/></td>
            </tr>
          </table>
        </div>
        <div className="my-3 text-center">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => goSubmit()}>저장</button>
          <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.goBack()}>목록</button>
        </div>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>

          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: '650px'}}>
            <div className="POP_title">
              <h5><strong>지점설정</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="d-flex m-0 table_wide_sh2">
              <div className="pr-1">
                <select className="form-select" id="s_brand" style={{minWidth: "180px"}}>
                </select>
              </div>
              <div className="pr-1">
                <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal()}>검색</button>
              </div>
            </div>

            <div className="POP_cnt_box w-100" style={{maxHeight: "620px"}}>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="80px"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>
                    <input type="checkbox" onClick={(e) => {
                      if ($(e.target).prop("checked")) $('[id^=mchk]').prop("checked", true);
                      else $('[id^=mchk]').prop("checked", false);
                    }}/>
                  </th>
                  <th>브랜드</th>
                  <th>지점명</th>
                </tr>
                {modalResult?.list?.map((row, i) =>
                  <tr>
                    <td className="text-center">
                      {i + 1}
                    </td>
                    <td className="text-center">
                      <input type="checkbox" id={`mchk_${i + 1}`} value={row.branch_code}/>
                    </td>
                    <td className="text-center">{row.branch_code}</td>
                    <td className="text-center">{row.branch_name}</td>
                  </tr>
                )}
              </table>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => onModalSelect()}>선택</button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="base_seq"/>
      <input type="hidden" id="branch_list"/>

    </CmnCard>
  );
};

export default SalesProductReg;
