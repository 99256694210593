import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import SmsNew from "../common/SmsNew";

const FinanceMisu = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [result, setResult] = useState({});
  const [resultDtl, setResultDtl] = useState();
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [smsModalOpen, setSmsModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  const load = (_page) => {
    axios2
      .post(`/finance/finance/misu/ready?s_mb_name=${$('#s_mb_name').val()}&o_date1=${$('#o_date1').val()}&o_date2=${$('#o_date2').val()}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&sf_type=${$('#sf_type option:selected').val() || ''}&currPage=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const onDetail = (mb_no) => {
    axios2
      .post(`/finance/finance/misu?mb_no=${mb_no}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResultDtl(result);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //1-1
  const handleSend = () => {
    let con = 0;
    let editMsgid = "";

    const formElements = document.form1a.elements;

    for (let i = 0; i < formElements.length; i++) {
      if (formElements[i].type === 'checkbox') {
        if (formElements[i].checked === true) {
          const nn = formElements[i].name;
          const m_chk = nn.substr(0, 3);
          if (m_chk === "chk") {
            con++;
            editMsgid += formElements[i].value + "|";
          }
        }
      }
    }

    if (con === 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    window.pop_up(
      `../message/sms.html?b_type=finance&s_type=sel&editMsgid=${editMsgid}`,
      'sms.html',
      '800',
      '450',
      'no'
    );
  };

  //2-1
  const linkParam = document.getElementById('linkParam');
  const search_send = () => {
    if (window.confirm("검색된 전체회원에게 SMS를 발송하시겠습니까?")) {
      window.pop_up(`../message/sms.html?b_type=finance&s_type=all&${linkParam}`, 'sms.html', '800', '450', 'no');
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex">
        <div className="wid_fix" style={{minWidth: "1027px"}}>
          <div className="d-flex m-3 fn_search_box">
            <div className="pr-1">
              <input type="text" className="form-control" placeholder="회원명 입력" id="s_mb_name"/>
            </div>
            <div className="d-flex pr-1 justify-content-center align-content-center align-items-center">
              <p className="p-1">가입일 :</p>
              <JDatePicker className="form-control" id="o_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" id="o_date2"/>
            </div>
            <div className="d-flex pr-1 justify-content-center align-content-center align-items-center">
              <p className="p-1">종료일 :</p>
              <JDatePicker className="form-control" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" id="s_date2"/>
            </div>
            <div className="pr-1">
              <select className="form-select" id="sf_type">
                <option value="X">전체</option>
                <option value="A" selected>이용중</option>
                <option value="B">이용기간만료</option>
              </select>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
          </div>

          <div className="m-3 pb-2 table_list">
            <table className="view-rounded sub_table_border auto">
              <tr>
                <th className="w_70px"><input type="checkbox"/></th>
                <th className="w_70px">No</th>
                <th>카드번호</th>
                <th>회원명</th>
                <th>미납금액</th>
                <th>연락처</th>
                <th>출석수</th>
                <th>가입일</th>
                <th>시작일</th>
                <th>종료일</th>
              </tr>

              {
                result?.result?.map((row, index) => {
                  const count = index + 1;

                  const mb_no = row?.mb_no;
                  const mb_hp = row?.mb_hp;
                  const misu_price = row?.misu_price;

                  const base_mb_no = btoa(mb_no);
                  // const mb_hp_show = CommonLib.hpShow(mb_hp, record);
                  const mb_hp_show = Fn.hpShow(mb_hp);
                  const base_hp = btoa(mb_hp_show);
                  // tot_base_hp += `${count},${base_hp}|`;

                  return (
                    <tr key={index} className={new Date() > new Date(row?.end_date) ? "cls-danger" : ""}>
                      <td>
                        <input
                          type="checkbox"
                          name={`chk[${count}]`}
                          id={`achk${count}`}
                          value={base_mb_no}
                          className="checkbox"
                        />
                        <label htmlFor={`achk${count}`}><span/></label>
                      </td>
                      <td>{count}</td>
                      <td>{row?.mb_id}</td>
                      <td>
                        <strong>
                          <a onClick={() => onDetail(row?.mb_no)} style={{cursor: "pointer"}}>{row?.mb_name}</a>
                        </strong>
                      </td>
                      <td className="text-right">{Fn.numberWithCommas(misu_price)}</td>
                      <td>
                        <div id={`default_show${count}`}>***-****-****</div>
                      </td>
                      <td>{row?.attent_num ? row?.attent_num : null}</td>
                      <td>{row?.mb_open_date?.replace(/-/g, '.')?.substring(2, 10)}</td>
                      <td>{row?.start_date?.replace(/-/g, '.')?.substring(2, 10)}</td>
                      <td>{row?.end_date?.replace(/-/g, '.')?.substring(2, 10)}</td>
                    </tr>
                  );
                })
              }
              <tr className="warning">
                <td colSpan="2"><strong>소계</strong></td>
                <td colSpan="3" className="text-right">
                  <strong>{Fn.numberWithCommas(result?.total_price)}</strong>
                </td>
                <td colSpan="5"><span className="text-muted">(운동기간 끝난 적색표시는 집계에 포함되지 않음)</span></td>
              </tr>
            </table>
          </div>

          <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
            <div>
              <div className="d-flex">
                <button type="button" className="btn btn-sm btn-outline-success px-2" onClick={() => {
                  if (eval("pageInfo?.totalArticles") > 0) {
                    if (!window.confirm(`검색된 ${pageInfo?.totalArticles}명 회원에게 SMS를 발송하시겠습니까?`)) return;
                  } else {
                    if (!window.confirm(`검색된 회원에게 SMS를 발송하시겠습니까?`)) return;
                  }

                  $('#SMS_editMsgid').val('');
                  $('#SMS_b_type').val("finance");
                  $('#SMS_s_type').val("all");

                  setSmsModalOpen(true);
                }}>검색 SMS 발송
                </button>
                <button type="button" className="btn btn-sm btn-outline-success px-2 mx-1"
                        onClick={() => {
                          if ($('[id^=achk]:checked').length === 0) {
                            window.alert('체크된 리스트가 없습니다.');
                            return;
                          }

                          let con = $('[id^=achk]:checked').map(function () {
                            return $(this).val();
                          }).get().join("|");

                          $('#SMS_editMsgid').val(con);
                          $('#SMS_b_type').val("finance");
                          $('#SMS_s_type').val("sel");

                          setSmsModalOpen(true);
                        }}
                >선택 SMS 발송
                </button>
                <div className="d-flex mx-1">
                  <ChkAuthNum type={"locker_info_change"}/>
                </div>
                {pageInfo?.totalArticles > 0 && (
                  (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
                    (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
                    <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('finance_misu')}>연락처 확인</button>
                  )
                )}
              </div>
            </div>
          </nav>
        </div>

        <div style={{width: "calc(100% - 850px)"}}>
          <div className="tip_box tip_box2 w-100 my-3"><span className="p-3">Tip! 좌측 계정명을 click하시면 해당 상세 내역을 보실 수 있습니다</span></div>
          <div className="fixed_box" style={{top: "190px", position: "absolute", minHeight: "unset", maxHeight: "615px"}}>
            <p className="border-bottom p-2 px-3 bold700 text-left h7" id="left_area">상세내역 {!resultDtl ? "" : " (" + resultDtl?.mRow?.mb_name + " / " + resultDtl?.mRow?.mb_id + ")"}</p>
            <div className="p-3">
              <table className="view-rounded sub_table_border" style={{minHeight: "unset"}}>
                <colgroup>
                  <col width="75px"/>
                  <col width="180px"/>
                  <col width="120px"/>
                  <col width="120px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>날짜</th>
                  <th>상품명</th>
                  <th>매출액</th>
                  <th>입금액</th>
                  <th>비고</th>
                </tr>
                {resultDtl && resultDtl?.result?.length && resultDtl?.result.map((row, index) =>
                  <tr>
                    <td>{row?.ck_date_data}</td>
                    <td className="text-left">{row?.ap_pdtName}</td>
                    <td className="text-right">{row?.ck_amtcha_data}</td>
                    <td className="text-right">{row?.ck_amtdae_data}</td>
                    <td className="text-left">{row?.ap_etc}</td>
                  </tr>
                )}
                <tr className="bgcolor bold700">
                  <td colSpan="2" className="text-right">{resultDtl ? "미납금 : " + resultDtl?.misu_price : ""}</td>
                  <td className="text-right">{resultDtl ? resultDtl?.sumCha : ""}</td>
                  <td className="text-right">{resultDtl ? resultDtl?.sumDae : ""}</td>
                  <td></td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
        <input type="hidden" id="tot_record" style={{display: "none"}}/>
        <input type="hidden" id="tot_sql" style={{display: "none"}}/>
      </div>

      <SmsNew
        isOpen={smsModalOpen}
        onModalClose={() => setSmsModalOpen(false)}
        searchType={"sms_list"}
        callback={() => {
          setSmsModalOpen(false);
        }}
      />
      <input type="hidden" id="SMS_b_type"/>
      <input type="hidden" id="SMS_s_type"/>
      <input type="hidden" id="SMS_editMsgid"/>

    </CmnCard>
  );
};

export default FinanceMisu;
