import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";
import Constants from "../../constants/constants";

const BranchopNoticeWrite = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.base_seq || '';

  const [result, setResult] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [content, setContent] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalResult, setModalResult] = useState({});
  const [modalSrchRenew, setModalSrchRenew] = useState(0);

  useEffect(() => {
    $('#bb_1').prop("checked", true);
    //$('#branch_code').val(localStorage.getItem("USER.systemBranchCode"));
    load();
  }, [srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/branchop/notice/write/ready?base_seq=${baseSeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult(result);
          setContent(result?.row?.content || "");
          $('#title').val(result?.row?.title);
          $('#branch_code').val(result?.row?.branch_code);

          if (result?.row?.branch_code) {
            $('#bb_2').prop("checked", true);
          } else {
            $('#bb_1').prop("checked", true);
          }

          if (result?.row?.gong_YN === 'Y') $('#gong_YN').prop("checked", true);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    loadModal();
  }, [modalSrchRenew]);

  const loadModal = () => {
    axios2
      .post(`/branchop/branch/select/notice/ready?s_brand=${$('#modal_s_brand option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#modal_s_brand').html() === '') {
            let optionsHTML = '<option value="">전체 브랜드</option>';
            result?.bl?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#modal_s_brand').html(optionsHTML);
          }

          setModalResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {
    if (!$('#title').val()) {
      alert('제목을 입력해주세요');
      $('#title').focus();
      return;
    }
    if (!content) {
      alert('내용을 입력해주세요');
      return;
    }

    let branchCode = $('#branch_code').val();
    if (branchCode !== '' && !branchCode.endsWith("|")) {
      branchCode += "|";
    }

    axios2
      .postEx('/branchop/notice/write', {
        base_seq: $('#base_seq').val(),
        branch_code: $("input[name='bb']:checked").val() === 'all' ? '' : branchCode,
        bb: $("input[name='bb']:checked").val(),
        gong_YN: $('#gong_YN:checked').val() || '',
        title: $('#title').val(),
        content: content,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === "err") {
          alert('오류가 발생하였습니다');
        } else if (data === "upd") {
          alert("수정되었습니다");
          history.goBack();
        } else if (data === "reg") {
          alert("등록되었습니다");
          history.goBack();
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const onModalSave = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split("|").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    $('#branch_code').val(con.replace("o|", ""));
    setModalOpen(false);
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);

            const token = (localStorage.getItem("USER.token") || null);
            let headers = token ? {Authorization: "Bearer " + token, CurrentUri: window.location.pathname} : {CurrentUri: window.location.pathname};

            fetch(Constants.API_URL.replace("/api/", "") + `/common/editor/upload`, {
              method: "post",
              body: body,
              headers
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: Constants.API_URL.replace("/api/", "") + `${res.url}`
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <input type="hidden" id="base_seq" value={baseSeq}/>
      <input type="hidden" id="branch_code"/>
      <div className="m-3 pb-2">
        <table className="view-rounded sub_view text-left">
          <colgroup>
            <col width="120px"/>
            <col width="*"/>
            <col width="120px"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>제목</th>
            <td colSpan="3">
              <input type="text" id="title"/>
            </td>
          </tr>
          <tr>
            <th>대상</th>
            <td>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="bb"
                  id="bb_1"
                  value="all"
                />
                <label className="form-check-label" htmlFor="bb_1">
                  전체지점
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="bb"
                  id="bb_2"
                  value="selected"
                  onClick={() => setModalOpen(true)}
                />
                <label className="form-check-label" htmlFor="bb_2" onChange={(e) => e.target.checked ? setModalOpen(true) : null}>
                  선택지점
                </label>
              </div>
            </td>
            <th>공지</th>
            <td>
              <input type="checkbox" id="gong_YN" value="Y"/>
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="p-2">
              <CKEditor editor={DecoupledEditor} data={content}
                        config={{
                          extraPlugins: [uploadPlugin]
                        }}
                        onReady={(editor) => {
                          editor.ui.getEditableElement().parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement()
                          );
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "350px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(data);
                        }}
              />
            </td>
          </tr>
        </table>
      </div>
      <div className="text-center my-4">
        <button type="button" className="btn btn-sm btn-point px-3" onClick={() => goSubmit()}>
          저장
        </button>
        <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.goBack()}>
          목록
        </button>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();

          const arr = $('#branch_code').val().split("|");
          for (let i = 0; i < arr.length; i++) {
            const _b = arr[i];
            $('#achk_' + _b).prop("checked", true);
          }
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>

          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: '650px'}}>
            <div className="POP_title">
              <h5><strong>지점 설정</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <div className="mb-3 d-flex flex-nowrap">
                <div className="pr-1">
                  <select className="form-select" id="modal_s_brand">
                  </select>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setModalSrchRenew(Fn.getRenewTime())}>검색</button>
                </div>
              </div>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="80px"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th><input type="checkbox" onClick={(e) => {
                    if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                    else $('[id^=achk]').prop("checked", false);
                  }}/></th>
                  <th>브랜드</th>
                  <th>지점명</th>
                </tr>
                {modalResult?.list?.map((row, i) =>
                  <tr>
                    <td className="text-center">
                      {i + 1}
                    </td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        name={`chk[${i + 1}]`}
                        value={row?.branch_code}
                        className="checkbox"
                        id={`achk_${row?.branch_code}`}
                      />
                    </td>
                    <td className="text-center">{row?.brand_name}</td>
                    <td className="text-center">{row?.branch_name}</td>
                  </tr>
                )}
              </table>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => onModalSave()}>저장</button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default BranchopNoticeWrite;
