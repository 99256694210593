import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const MvPointList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };
  const [result, setResult] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [searchYN, setSearchYN] = useState('N');

  useEffect(() => {
    if (isOpen) {
      load(1);
    }
  }, [srchRenew]);

  useEffect(() => {
    if (isOpen) {
      load();
    }
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/member/intro/list/ready?search_YN=${searchYN}&auth_code=${$('#auth_code').val()}&mb_no=${props.mbNo}&to_mb_no=&key=${$('#key').val() || ''}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function del_point(seq) {
    console.log("/member/point/list/del?mb_no=" + props.mbNo + "&tbl_gubun=" + $('#pl_tbl_gubun option:selected').val() + "&seq=" + seq + "&auth_code=" + $('#auth_code').val());
    if (window.confirm('해당 포인트를 삭제하시겠습니까?')) {
      axios2
        .post("/member/point/list/del?mb_no=" + props.mbNo + "&tbl_gubun=" + $('#pl_tbl_gubun option:selected').val() + "&seq=" + seq + "&auth_code=" + $('#auth_code').val())
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            alert('삭제되었습니다');
            props?.onCallback();
            onToggleModal();
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function selectIntro(mb_no, to_mb_no) {
    if (window.confirm('선택한 회원을 추천인으로 등록하시겠습니까?')) {
      axios2
        .post("/member/intro/reg?auth_code=" + $('#auth_code').val() + "&mb_no=" + mb_no + "&to_mb_no=" + to_mb_no)
        .then((response) => {
          const {code, message: data, result, pageInfo} = response?.data;
          if (code === 200) {
            if (data == "err") {
              alert('오류가 발생하였습니다');
            } else if (data == "dup_member") {
              alert("이미 추천인소개가 처리되었습니다.");
            } else if (data == "no_date") {
              alert("추가 부여일수가 없습니다.");
            } else if (data == "wrong_date") {
              alert("서비스등록 1주일 이내만 가능합니다.");
            } else if (data == "to_no_date") {
              alert("추천인의 이용기간이 종료되었습니다.");
            } else {
              alert("처리되었습니다.");
              props?.onCallback();
              onToggleModal();
            }
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: '1350px'}}>
          <div className="POP_title">
            <h5><strong>추천인 검색</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <div className="mb-3 d-flex flex-nowrap">
              <div className="pr-1">
                <input type="text" className="form-control" aria-describedby="button-addon2" id="key" placeholder="성명/연락처 검색"/>
              </div>
              <div className="pr-1">
                <button type="button" className="btn btn-sm btn-point px-3" onClick={() => {
                  setSearchYN('Y');
                  setSrchRenew(Fn.getRenewTime());
                }}>검색
                </button>
              </div>
            </div>
            <table className="view-rounded sub_table_border" style={{borderRadius: 0}}>
              <colgroup>
                <col width="70px"/>
                <col width="140px"/>
                <col width="120px"/>
                <col width="75px"/>
                <col width="115px"/>
                <col width="80px"/>
                <col width="80px"/>
                <col width="*"/>
                <col width="*"/>
                <col width="70px"/>
                <col width="125px"/>
                <col width="120px"/>
              </colgroup>
              <tr>
                <th className="w_70px">No</th>
                <th>지점명</th>
                <th>카드번호</th>
                <th>성명</th>
                <th>연락처</th>
                <th>시작일</th>
                <th>종료일</th>
                <th>라커</th>
                <th>운동복</th>
                <th>잔여PT</th>
                <th>미납금</th>
                <th>선택</th>
              </tr>
              {searchYN === 'Y' && result?.result && result?.result.map((ret, i) => {
                let locker_period = "";
                let locker_period2 = "";
                let suit_period = "";
                let suit_period2 = "";
                let health_period = "";

                if (ret?.locker == 1 || ret?.locker == 3) {
                  locker_period = `${ret?.locker_start_date.substring(2, 10).replace(/-/g, ".")} ~ ${ret?.locker_end_date.substring(2, 10).replace(/-/g, ".")}`;
                  locker_period2 = `${ret?.locker_start_date} ~ ${ret?.locker_end_date}`;
                }

                if (ret?.suit == 1) {
                  suit_period = `${ret?.suit_start_date.substring(2, 10).replace(/-/g, ".")} ~ ${ret?.suit_end_date.substring(2, 10).replace(/-/g, ".")}`;
                  suit_period2 = `${ret?.suit_start_date} ~ ${ret?.suit_end_date}`;
                }

                if (ret?.end_date) {
                  health_period = `${ret?.start_date} ~ ${ret?.end_date}`;
                }

                return (
                  <tr>
                    <td className="text-center">{pageInfo?.totalArticles - pageInfo?.articlesPerPage * (pageInfo?.currentPage - 1) - i}</td>
                    <td className="text-center">{ret?.branch_name}</td>
                    <td className="text-center">{ret?.mb_id}</td>
                    <td className="text-center">{ret?.mb_name}</td>
                    <td className="text-center">{Fn.hpShow(ret?.mb_hp, pageInfo?.totalArticles)}</td>
                    <td className="text-center">{ret?.start_date?.substring(2, 10)?.replace(/-/g, ".")}</td>
                    <td className="text-center">{ret?.end_date?.substring(2, 10)?.replace(/-/g, ".")}</td>
                    <td className="text-center">{locker_period}</td>
                    <td className="text-center">{suit_period}</td>
                    <td className="text-right">{Fn.numberWithCommas(ret?.remainPT)}</td>
                    <td className="text-right">{Fn.numberWithCommas(ret?.misuPrice)}</td>
                    <td className="text-center">
                      <button type="button" className="btn btn-ssm btn-point px-2" onClick={() => selectIntro(props.mbNo, ret?.toMbNo)}>추천인선택</button>
                    </td>
                  </tr>
                );
              })}
              {searchYN === 'Y' && (!result?.result || result?.result?.length === 0) &&
                <tr>
                  <td colSpan={12} className="text-center">※ 등록된 data가 없습니다.</td>
                </tr>
              }
              {searchYN !== 'Y' &&
                <tr>
                  <td colSpan={12} className="text-center">※ 검색버튼을 클릭해주세요.</td>
                </tr>
              }
            </table>
          </div>
          <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </nav>
        </div>
      </div>
    </Modal>
  );
};

export default MvPointList;
