import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";


const ConfigSubPriceSetting = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [renew, setRenew] = useState(0);
  const [accName, setAccName] = useState('');
  const [subCode, setSubCode] = useState();
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    load();
  }, [renew]);

  const load = (accNo) => {
    $('#modal_base_acc_no').val(btoa(accNo));

    axios2
      .post(`/finance/sub/price/setting/ready?base_acc_no=${btoa(accNo)}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setAccName(result?.accName);
          setSubCode(result?.subCode);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const handleSubData = (mainData) => {
    load(mainData.acc_no);
  }

  const onAdd = () => {
    if (!accName) {
      alert('메인 분류를 먼저 선택해 주세요.');
      return;
    }
    $('#modal_base_seq').val('');
    setModalOpen(true);
  }

  const loadModal = () => {
    axios2
      .post(`/finance/sub/price/edit/ready?base_seq=${$('#modal_base_seq').val()}&base_acc_no=${$('#modal_base_acc_no').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $('#modal_sub_name').val(result?.row?.sub_name);
          if (result?.row?.show_YN === 'Y')
            $("#modal_show_YN1").prop("checked", true);
          else
            $("#modal_show_YN2").prop("checked", true);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {
    axios2
      .postEx('/finance/sub/price/edit', {
        base_seq: $('#modal_base_seq').val(),
        base_acc_no: $('#modal_base_acc_no').val(),
        sub_name: $('#modal_sub_name').val(),
        show_YN: $("input[name='modal_show_YN']:checked").val()
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === "err") {
          alert('오류가 발생하였습니다');
        } else {
          if ($('#base_seq').val())
            alert("수정하였습니다");
          else
            alert("추가하였습니다");
          load(atob($('#modal_base_acc_no').val()))
          setModalOpen(false);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 d-flex flex_gap">
        <div className="subprice_item">
          <div className="border-bottom text-left">
            <p className="px-3 py-2">
              <span className="h6 bold700">지출항목</span>
            </p>
          </div>

          <div className="m-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border">
              <colgroup>
                <col width="*"/>
                <col width="45%"/>
                <col width="*"/>
              </colgroup>

              <tr>
                <th>메인코드</th>
                <th>메인분류명</th>
                <th>상세분류수</th>
              </tr>
              {rets.map((ret, i) => {
                return (
                  <tr key={i} style={{cursor: "pointer"}}>
                    <td onClick={() => handleSubData(ret)}>{ret?.acc_no}</td>
                    <td onClick={() => handleSubData(ret)}>{ret?.acc_name}</td>
                    <td onClick={() => handleSubData(ret)}>{ret?.r_num}</td>
                  </tr>
                );
              })}
            </table>
          </div>
        </div>
        <div className="subprice_item">
          <div className="border-bottom text-left d-flex justify-content-between">
            <p className="px-3 py-2">
              <span className="h6 bold700">세부항목{accName ? "(" + accName + ")" : ""}</span>
            </p>
            <p className="px-3 py-2">
              <button type="button" className="btn btn-ssm btn-point px-3" onClick={() => onAdd()}>
                추가
              </button>
            </p>
          </div>

          <div className="m-3">
            {/* 회원목록 */}
            <table className="view-rounded sub_table_border">
              <colgroup>
                <col width="*"/>
                <col width="45%"/>
                <col width="*"/>
                <col width="*"/>
              </colgroup>

              <tr>
                <th>상세분류코드</th>
                <th>상세분류명</th>
                <th>전시여부</th>
                <th>수정</th>
              </tr>
              {subCode?.map((row, i) =>
                <tr>
                  <td>{row?.acc_no}</td>
                  <td>{row?.sub_name}</td>
                  <td>{row?.show_YN === 'Y' ? 'O' : ''}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-ssm btn-outline-point"
                      onClick={() => {
                        $('#modal_base_seq').val(btoa(row?.seq));
                        setModalOpen(true);
                      }}
                    >
                      수정
                    </button>
                  </td>
                </tr>
              )}
            </table>
          </div>
        </div>
      </div>

      {/*팝업*/}

      <input type="hidden" id="modal_base_seq"/>
      <input type="hidden" id="modal_base_acc_no"/>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          if ($('#modal_base_seq').val()) {
            loadModal();
          } else {
            $('#modal_sub_name').val('');
            $('#modal_show_YN1').prop("checked", true);
          }
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '730px'}}>
            <div className="POP_title">
              <h5>
                지출 세부항목 설정 &gt; <strong>세부항목</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="158px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>메인분류명</th>
                  <td>{accName}</td>
                </tr>
                <tr>
                  <th>상세분류명</th>
                  <td>
                    <input type="text" className="form-control" id="modal_sub_name"/>
                  </td>
                </tr>
                <tr>
                  <th>전시여부</th>
                  <td>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="modal_show_YN"
                        id="modal_show_YN1"
                        value="Y"
                      />
                      <label className="form-check-label" htmlFor="modal_show_YN1">
                        전시
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="modal_show_YN"
                        id="modal_show_YN2"
                        value="N"
                      />
                      <label className="form-check-label" htmlFor="modal_show_YN2">
                        미전시
                      </label>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => goSubmit()}>
                저장
              </button>
              <button type="button" className="btn btn-sm btn-ligray px-3 mx-1" onClick={() => setModalOpen(false)}>
                닫기
              </button>
            </div>
          </div>
        </div>
      </Modal>

    </CmnCard>
  );
};

export default ConfigSubPriceSetting;
