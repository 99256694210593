import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import JDatePicker from "../common/JDatePicker";

const MvCashCanel = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();

  const [input_form, setInputForm] = useState();
  const [pay_form, setPayForm] = useState();

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const load = () => {
    setInputForm($("#input_form")[0]);
    setPayForm($("#pay_form")[0]);

    let input_form = $("#input_form")[0];
    input_form.won_ip_no.value = $('#cr_ip_no').val();
    input_form.auth_code.value = $('#auth_code').val();

    input_form.ip_date.value = Fn.getCurrentDate();

    axios2
      .post(`/member/cash/cancel/ready?base_seq=${$('#cr_ip_no').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  String.prototype.cut = function (start, len) {
    var str = this;
    var l = 0;
    for (var i = 0; i < str.length; i++) {

      l += (str.charCodeAt(i) > 128) ? 2 : 1;

      if (l > len) return str.substring(start, i);

    }
    return str;
  };

  function input_insert() {
    let input_form = $("#input_form")[0];

    if (input_form.ip_amt.value == "" || input_form.ip_amt.value == "0") {
      alert("금액을 입력해주세요");
      input_form.ip_amt.focus();
      return;
    }

    input_form.mb_no.value = props.mbNo;
    input_form.auth_code.value = $('#auth_code').val();

    var formData = new FormData(input_form);
    $.ajax({
      url: '/member/member/ipkum/cancel',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        data = data?.message?.trim();
        if (data == "err") {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        } else {
          const split = data.split("||");
          const mb_no = split[0];

          if (split[1] == "Y") {
            // TODO : PTODO -> 주석 처리
            // common_i_pop2.location.href = 'print_pay_cancel.html?base_seq=' + split[2]
          }

          alert('취소되었습니다');
          props?.onCallback();
          setIsOpen(false);
        }
      }
    });
  }


  function pre_input_insert() {
    let input_form = $("#input_form")[0];
    //reg_btn.innerHTML = "<input type='button' class='btn btn-danger btn-sm' value='반품처리' >";

    var aa = props.mbNo;
    var bb = $('#auth_code').val();

    if (aa == "" || aa == "0") {
      alert("오류가 발생하였습니다. 회원을 다시 검색해주세요");
      return;
    }
    if (bb == "" || bb == "0") {
      alert("인증코드를 먼저 입력해주세요");
      return;
    }

    if (1) {
      //카드결제일경우 금액 필수입력
      if (input_form.ip_amt.value == "" || input_form.ip_amt.value == "0") {
        alert(" 받은돈을 입력해주세요");
        //reg_btn.innerHTML = "<input type='button' class='btn btn-danger btn-sm' value='반품처리' onclick='pre_input_insert()'>";
        return;
      }

      //결제금액이 5만원 이하이면서 할부불가처리
      let input_price = input_form.ip_amt.value;
      input_price = Fn.cfNumeric(input_price);

      //카드결제 항목 초기화
      pay_form.edtAmount.value = 0;		//전문관리번호
      pay_form.edtTax.value = 0;			//부가세
      pay_form.edtInstallment.value = "00";//할부개월

      //1.전문관리번호 입력하기

      //2.결제금액 입력
      var pay_price = input_form.ip_amt.value;
      //3.단가/부가세 입력
      cal_price(pay_price);

      //4. 할부개월 입력하기

      $("#authType").val("5050");

      writeTo();
    } else {
      //input_insert();
    }

  }

  function auto_insert() {
    if (input_form.payCard.value) {
      $('#card_month').html("");
      if (input_form.payCard.value == "2222")	//카드결제
      {
        var hh = "<select name='month_card' id='month_card' class='form-control wid60' style='width:80px'>";
        hh += "<option value='00'>일시불</option>";
        hh += "<option value='02'>2개월</option>";
        hh += "<option value='03'>3개월</option>";
        hh += "<option value='04'>4개월</option>";
        hh += "<option value='05'>5개월</option>";
        hh += "<option value='06'>6개월</option>";
        hh += "<option value='07'>7개월</option>";
        hh += "<option value='08'>8개월</option>";
        hh += "<option value='09'>9개월</option>";
        hh += "<option value='10'>10개월</option>";
        hh += "<option value='11'>11개월</option>";
        hh += "<option value='12'>12개월</option>";
        hh += "<option value='18'>18개월</option>";
        hh += "<option value='24'>24개월</option>";
        hh += "</select>";

        $("#card_month").append(hh)
      } else {
        $("#card_month").html('');
      }
      //카드결제일때 금액에 따라 일시불. 할부 표시
    }
  }

  function cal_price(price) {
    var SAL_PRICE = 0;
    var SAL_VAT = 0;

    price = Fn.cfNumeric(price);

    let SAL_AMT = price; // 판매금액

    SAL_VAT = ((SAL_AMT / 1.1) * 0.1);    // 부가세(VAT)
    SAL_PRICE = Math.round(SAL_AMT - SAL_VAT); // 단가(반올림)
    SAL_VAT = Math.round(SAL_VAT);    // 부가세(반올림)

    pay_form.edtAmount.value = SAL_PRICE;
    pay_form.edtTax.value = SAL_VAT;
  }


  //<!-- POS 관련 -->

  function getTime_YYMMDDHHMMSS() {
    var today = new Date();

    var year = today.getFullYear()
    var month = ('0' + (today.getMonth() + 1)).slice(-2);
    var day = ('0' + today.getDate()).slice(-2);

    var hours = ('0' + today.getHours()).slice(-2);
    var minutes = ('0' + (today.getMinutes() + 1)).slice(-2);
    var seconds = ('0' + today.getSeconds()).slice(-2);

    var time = year + month + day + hours + minutes + seconds;

    return time.slice(-12);
  }

  function jsonInsert(json, field, value) {
    if (json.length == 0) {
      json = '{"';
      json += field;
      json += '":"';
      json += value;
      json += '"}';
    } else {
      json = json.substr(0, json.length - 1) + ',"';
      json += field;
      json += '":"';
      json += value;
      json += '"}';
    }
    return json;
  }

  function ShowLoadingImage() {
    //결제시 이미지 표시 - 미사용
    //document.getElementById('btn_send').disabled = true;
    //document.all.LoadingPageLayer.style.visibility = "visible";
    //document.all.LoadingImageLayer.style.visibility = "visible";
  }

  function HideLoadingImage() {
    //document.getElementById('btn_send').disabled = false;
    //document.all.LoadingPageLayer.style.visibility = "hidden";
    //document.all.LoadingImageLayer.style.visibility = "hidden";
  }


  var authType;
  var payType;

  function ParseHttpResponse(rtn)	//결제결과 파싱
  {
    // 스트링 utf-8 -> multibyte 변환 필요
    //console.log('응답전문 길이: ' + (String(rtn).indexOf(String.fromCharCode(13)) + 1));

    // json 전문
    if (String(rtn).substr(0, 1) == '{') {
      let rtn_ele_cr = rtn.replace(String.fromCharCode(0x0d), '');
      console.log(rtn_ele_cr);
      const obj = JSON.parse(rtn_ele_cr);


      if (obj.ERROR_CHECK_RESULT == 'S' && obj.PKTYPE == 'S' && obj.REPLY == '0000') {
        // 신용
        if (obj.MSGTYPE == '1010' || obj.MSGTYPE == '1050') {
          // 카카오페이 신용
          if (obj.ADDINFO2.slice(0, 3) == 'KKO') {
            pay_form.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            pay_form.edtAuthNo.value = String(obj.MESSAGE).substr(0, 10);
            pay_form.edtAuthUniqueNo.value = '';
          }
          // SSG페이 신용
          else if (obj.ADDINFO2.slice(0, 3) == 'SSG') {
            pay_form.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            pay_form.edtAuthNo.value = String(obj.MESSAGE).substr(0, 12) + fillSpace(3);
            pay_form.edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
          }
          // 일반 신용
          else {
            pay_form.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
            pay_form.edtAuthNo.value = String(obj.MESSAGE).substr(0, 12);
            pay_form.edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
          }
        }

        // 현금영수증
        else if (obj.MSGTYPE == '5010' || obj.MSGTYPE == '5050') {
          pay_form.edtAuthDate.value = String(obj.MESSAGE).substr(12, 6);
          pay_form.edtAuthNo.value = String(obj.MESSAGE).substr(0, 12);
          pay_form.edtAuthUniqueNo.value = String(obj.MESSAGE).substr(24, 12);
        }

        var a17 = obj.ACQUIERCD;
        var a4 = obj.TID;
        var a14 = obj.MERCHANTNO;
        var a21 = obj.TRACK2DATA;

        var d1 = String(obj.MESSAGE).substr(0, 12);
        var d2 = String(obj.MESSAGE).substr(12, 12);

        var a19 = obj.CARDKBN;

        var d3 = String(obj.MESSAGE).substr(24, 12);

        var a16 = obj.ISSUER;
        var a18 = obj.ACQUIER;


        var won_rtn = rtn;


        var purchase_code = a17;		//매입사코드
        var machine_no = a4;			//단말기번호
        var company_no = a14;			//가맹점번호
        var card_no = a21;				//카드번호
        var permit_no = d1;				//승인번호
        var permit_time = d2;			//승인일시
        var card_gubun = a19;	//카드구분
        var unique_no = d3;		//거래고유번호
        //발급사코드

        input_form.purchase_code.value = purchase_code;				//발급사코드
        input_form.machine_no.value = machine_no;					//단말기번호
        input_form.company_no.value = company_no;					//가맹점번호
        input_form.card_no.value = card_no;							//카드번호
        input_form.permit_no.value = permit_no;						//승인번호
        input_form.permit_time.value = permit_time;					//승인일시
        input_form.card_gubun.value = card_gubun;					//카드구분
        input_form.unique_no.value = unique_no;						//거래고유번호


        //영수증 출력
        if (input_form.payCard.value == "2222")	//카드결제
        {
          var month_card = input_form.month_card.value;
        } else {
          var month_card = "00";
        }

        var price = input_form.ip_amt.value;

        var c1 = result?.bOptRow?.branch_name;
        var c2 = result?.bInfoRow?.company_no;
        var c3 = "이규정";
        var c4 = result?.bInfoRow?.address;
        var c5 = result?.bInfoRow?.tel;

        var c6 = "";
        c6 = a21.substr(0, 4) + "-" + a21.substr(4, 4) + "-" + a21.substr(8, 4) + "-" + a21.substr(12, 4);

        if (input_form.payCard.value == "2222")	//카드결제
        {
          var c7 = a16.trim();
          var c8 = a18.trim();
        } else {
          var c7 = a16;
          var c8 = a18;
        }

        var c9 = "";
        c9 = d2.substr(0, 2) + "/" + d2.substr(2, 2) + "/" + d2.substr(4, 2) + " " + d2.substr(6, 2) + ":" + d2.substr(8, 2) + ":" + d2.substr(10, 2);

        var c10 = "";
        if (a19 == "CK") {
          c10 = "현금취소("
          if (month_card == "00") {
            c10 += "일시불)";
          } else {
            c10 += "할부" + month_card + "개월)";
          }
        } else {
          c10 = "현금취소)"
          if (month_card == "00") {
            c10 += "일시불)";
          } else {
            c10 += "할부" + month_card + "개월)";
          }
        }

        if (input_form.payCard.value == "2222")	//카드결제
        {
          var c11 = d1.trim();
          var c12 = a4.trim();
          var c13 = a14.trim();
        } else {
          var c11 = d1;
          var c12 = a4;
          var c13 = a14;
        }


        price = Fn.cfNumeric(price);

        if (price >= 50000) {
          var c17 = "Y";
        } else {
          var c17 = "N";
        }

        var SAL_AMT = price; // 판매금액

        var SAL_VAT = ((SAL_AMT / 1.1) * 0.1);    // 부가세(VAT)
        var SAL_PRICE = Math.round(SAL_AMT - SAL_VAT); // 단가(반올림)
        SAL_VAT = Math.round(SAL_VAT);    // 부가세(반올림)

        var c14 = Fn.commaNum(SAL_PRICE);
        var c15 = Fn.commaNum(SAL_VAT);
        var c16 = Fn.commaNum(price);

        var receipt_info = c1 + "|" + c2 + "|" + c3 + "|" + c4 + "|" + c5 + "|" + c6 + "|" + c7 + "|" + c8 + "|" + c9 + "|" + c10 + "|" + c11 + "|" + c12 + "|" + c13 + "|" + c14 + "|" + c15 + "|" + c16 + "|" + c17;
        //영수증 출력 끝

        input_form.rtn.value = won_rtn;								//전문데이터
        input_form.receipt_info.value = receipt_info;				//영수증데이터


        //input_form.print_YN.value = "Y";

        if (input_form.payCard.value == "2222") {
          input_form.print_YN.value = "Y";
        } else {
          var new_hp = obj.TRACK2DATA;

          input_form.cash_hp.value = new_hp;				//핸드폰번호
          input_form.print_YN.value = "N";


        }


        input_insert();


      } else {
        //거래실패시 에러메시지 출력
        var dda = obj.ERROR_CHECK_MESSAGE;
        //alert(dda);
        alert(dda + "\r정상적으로 승인되지 않았습니다. 잠시후 다시 시도해주세요");
      }
    }

    pay_form.taResponse.value += "\n";
    pay_form.taResponse.value += "===================================================\n";
    pay_form.taResponse.value += "응답 전문: " + String(rtn);
    pay_form.taResponse.value += "\n";
    pay_form.taResponse.value += "===================================================\n";
  }

  function httpRequest(api, data) {

    ShowLoadingImage();

    var xmlHttp = new XMLHttpRequest();
    xmlHttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 0) {
        alert("단말기 연결이 실패하였습니다.");
      }
      if (this.readyState == XMLHttpRequest.DONE) {
        HideLoadingImage();
        if (this.status == 200) {
          var rtn = xmlHttp.responseText;
          console.log('recieve');

          ParseHttpResponse(rtn);
        }
      } else {
        console.log('waiting...');
      }
    };
    xmlHttp.ontimeout = function (e) {
      //console.log('Timeout!');
      alert("시간초과! 다시 시도해주세요");
      // location.href = "member_view.html"
      setIsOpen(false);
    };
    //xmlHttp.timeout = 35 * 1000;

    var url = 'http';
    if (document.querySelector('input[name="HttpType"]:checked').value == 0)
      url += 's';
    url += '://127.0.0.1:';
    url += pay_form.portNo.value;
    url += api;
    xmlHttp.open("POST", url, true);
    //xmlHttp.open("POST", url.value, true);
    //xmlHttp.setRequestHeader("Content-Type", "text/plain;charset=UTF-8");
    xmlHttp.setRequestHeader("Content-Type", "text/plain;charset=utf-8");
    //xmlHttp.setRequestHeader("Connection", "Close");	// 브라우저에 의존
    //xmlHttp.setRequestHeader("Content-Type", "text/plain");
    //xmlHttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    //xmlHttp.setRequestHeader("Content-Type", "application/json");

    xmlHttp.send(data);

    //console.log('send');		//요청전문
  }


  // 단말기 정보 요청
  function SendInfo() {
    var taskType = document.querySelector('input[name="radioTaskType"]:checked').value;
    var subCmd = document.querySelector('input[name="radioFunctinType"]:checked').value;
    var inputData = "";

    // 전문 요청
    pay_form.taResponse.value += "전문 요청" + "[업무구분:" + taskType + " 세부명령:" + subCmd + "]" + " [명령인자:" + String(inputData) + "]\n";
    pay_form.taResponse.value += "전문 전송 응답\n";
    // TODO : 결제 모듈
    {
      //var rtn = objEncPCPOS.TPayPosAuthSerial(parseInt(taskType), parseInt(subCmd), String(inputData));

      // 전문 응답 처리
      //pay_form.taResponse.value += "[ " + rtn + " ]\n";
    }
    pay_form.taResponse.value += "단말기 시리얼번호 : " + pay_form.rtn.substring(5, 15) + '\n';
  }

  function writeTo() {
    var taskType = document.querySelector('input[name="radioTaskType"]:checked').value;

    switch (taskType) {
      // 거래요청
      case '0':
        SendPayment();
        break;

      // 기능요청
      case '1':
        SendInfo();
        break;
    }
  }

  var cardNum100;

  var amount12;
  var tax12;
  var tip12;
  var taxFree12;

  var amount9;
  var tax9;
  var tip9;
  var taxFree9;

  var recycleUse;
  var recycleRcdAmt9;

  function SendPayment() {
    // 가맹점 단말기 tid
    if (pay_form.txtTID.value.length != 10) {
      alert("단말기 TID 자리수 확인(10자리)");
      pay_form.txtTID.focus();
      return;
    }

    if (pay_form.edtCardNum.value.length > 0 && pay_form.edtCardNum.value.length < 10 || pay_form.edtCardNum.value.length > 98) {
      alert("카드번호 자리수 확인(10자리 이상)");
      pay_form.edtCardNum.focus();
      return;
    }

    var cardPaddingLen;
    cardPaddingLen = 100 - pay_form.edtCardNum.value.length - 2;
    if (pay_form.WCC.value == 'K') {
      cardNum100 = pay_form.edtCardNum.value.length + pay_form.edtCardNum.value + fillSpace(cardPaddingLen);
    } else if (pay_form.WCC.value == 'A') {
      cardNum100 = pay_form.edtCardNum.value.length + pay_form.edtCardNum.value + fillSpace(cardPaddingLen);
    } else {
      cardNum100 = fillSpace(100);
    }

    // 거래금액 입력.
    if (pay_form.edtAmount.value.length > 9) {
      alert("거래금액 자리수 확인(9자리 이하)");
      pay_form.edtAmount.focus();
      return;
    }
    var amountLen = 9 - pay_form.edtAmount.value.length;
    amount9 = fillZero(amountLen) + pay_form.edtAmount.value;
    amount12 = '000' + amount9;

    // 세금 입력.
    if (pay_form.edtTax.value.length > 9) {
      alert("세금 자리수 확인(9자리 이하)");
      pay_form.edtTax.focus();
      return;
    }
    var taxLen = 9 - pay_form.edtTax.value.length;
    tax9 = fillZero(taxLen) + pay_form.edtTax.value;
    tax12 = '000' + tax9;

    // 봉사료 입력.
    if (pay_form.edtTip.value.length > 9) {
      alert("봉사료 자리수 확인(9자리 이하)");
      pay_form.edtTip.focus();
      return;
    }
    var tipLen = 9 - pay_form.edtTip.value.length;
    tip9 = fillZero(tipLen) + pay_form.edtTip.value;
    tip12 = '000' + tip9;

    // 비과세 입력.
    if (pay_form.edtTaxFree.value.length > 9) {
      alert("비과세 자리수 확인(9자리 이하)");
      pay_form.edtTaxFree.focus();
      return;
    }
    var taxFreeLen = 9 - pay_form.edtTaxFree.value.length;
    taxFree9 = fillZero(taxFreeLen) + pay_form.edtTaxFree.value;
    taxFree12 = '000' + taxFree9;

    recycleUse = document.querySelector('input[name="recycleType"]:checked').value;
    if (recycleUse == 1) {
      if (pay_form.recycleAmt.value.length > 9) {
        alert("비과세 자리수 확인(9자리 이하)");
        pay_form.recycleAmt.focus();
        return;
      }
      var recycleAmtLen = 9 - pay_form.recycleAmt.value.length;
      recycleRcdAmt9 = 'RCD' + fillZero(recycleAmtLen) + pay_form.recycleAmt.value;
    }

    var installment = "00"
    if (pay_form.edtInstallment.value.length != 2) {
      // 카드 결제 관련 입력값 검사.
      alert("할부 개월수 확인(00:일시불, 01~12:개월수");
      pay_form.edtInstallment.focus();
      return;
    }
    installment = pay_form.edtInstallment.value;

    // 거래요청 전문 생성
    authType = document.querySelector('#authType option:checked').value;
    payType = document.querySelector('#payType').value;


    // 취소인 경우 원 거래일시 / 원 거래번호 / 원거래고유번호 필요.
    if (authType == '1050' || authType == '1052' || authType == '1060' || authType == '5050') {
      // 원 거래일시 입력.
      if (pay_form.edtAuthDate.value.length != 6) {
        alert("원 거래일시 확인(6자리)");
        pay_form.edtAuthDate.focus();
        return;
      }
      //authDate = edtAuthDate.value; // 6자리 'YYMMDD'

      // 원 거래번호 입력.
      if (pay_form.edtAuthNo.value.length != 12) {
        alert("원 거래번호 확인(12자리 이하)");
        pay_form.edtAuthNo.focus();
        return;
      }
      //var authNoLen = 12 - edtAuthNo.value.length; // 12자리
      //authNo = edtAuthNo.value + fillSpace(authNoLen);

      // 원거래 고유번호 입력.
      if (pay_form.edtAuthUniqueNo.value.length != 12) {
        alert("원거래 고유번호 확인(12자리 이하)");
        pay_form.edtAuthUniqueNo.focus();
        return;
      }
      //var authUniqueNoLen = 12 - edtAuthUniqueNo.value.length;
      //authUniqueNo = edtAuthUniqueNo.value + fillSpace(authUniqueNoLen); // 12자리
    }

    let inputData;

    if (authType == '5010' || authType == '5050') // 현금 영수증 승인,취소
    {
      inputData = '';
      inputData += makeCash(authType);
    }
    if (authType == '1010' || authType == '1050' || authType == '1012' || authType == '1052') {
      inputData = '';
      inputData += makeCredit(authType);
    }

    /*
    ##########  추후 적용 ################
    // 카카오페이 승인조회/취소조회
    else if (authType == '8040' || authType == '8041') {
        inputData = '';
        inputData += makeKakaoPay(authType);
    }
    // 통합간편결제 승인조회/취소조회
    else if (authType == '80990' || authType == '80991') {
        inputData = '';
        inputData += makeEasyPay(authType);
    }
    // 통합간편결제 승인조회/취소조회
    else if (authType == '8020' || authType == '8021') {
        inputData = '';
        inputData += makeLPay(authType);
    }
    // 현금IC 승인/취소
    else if (authType == '8080' || authType == '8081')
    {
        inputData = '';
        inputData += makeCashIC(authType);
    }
    // 현금IC 잔액조회
    else if (authType == '8084')
    {
        inputData = '';
        inputData += makeCashICQuery(authType);
    }
    // 제로페이 승인/취소
    else if (authType == '8050' || authType == '8051')
    {
        inputData = '';
        inputData += makeZeroPay(authType);
    }
    // 유니온/BC QR - 네이버페이 및 하나앱카드
    else if (authType == '1014A' || authType == '1014C') {
        inputData = '';
        inputData += makeQRCode(authType);
    }
    // SSG페이 승인조회/취소조회
    else if (authType == '8030' || authType == '8031') {
        inputData = '';
        inputData += makeSSGPay(authType);
    }
    // SSG페이 확정
    else if (authType == '8036')
    {
        inputData = '';
        inputData += makeSSGPayConfirm(authType);
    }
    // 알리페이
    else if (authType == '8046' || authType == '8047')
    {
        inputData = '';
        inputData += makeAliPay(authType);
    }
    // 수표조회
    else if (authType == '6080')
    {
        inputData = '';
        inputData += makeCheck(authType);
    }
    // 포인트
    else if (authType.slice(0,2) == '30')
    {
        inputData = '';
        inputData += makePoint(authType);
    }
    // 멤버쉽
    else if (authType.slice(0,2) == '40')
    {
        inputData = '';
        inputData += makeMembership(authType);
    }
    #############################################
    */

    // 승인 요청 전문 출력
    pay_form.taResponse.value += '요청 전문: ' + String(inputData);

    // http 전송
    var api = '/tPayDaemon/Auth'
    httpRequest(api, inputData);
  }

  function nds() {
    var d = new Date();
    var t_year = d.getFullYear();

    t_year = new String(t_year)

    var year = t_year.substr(2, 2);
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hour = d.getHours();
    var minute = d.getMinutes();
    var second = d.getSeconds();

    if (eval(month) < 10) {
      month = "0" + month;
    } else {
      month = month;
    }

    if (eval(day) < 10) {
      day = "0" + day;
    } else {
      day = day;
    }

    if (eval(hour) < 10) {
      hour = "0" + hour;
    } else {
      hour = hour;
    }

    if (eval(minute) < 10) {
      minute = "0" + minute;
    } else {
      minute = minute;
    }

    if (eval(second) < 10) {
      second = "0" + second;
    } else {
      second = second;
    }

    let new_pay_id = year + month + day + hour + minute + second;

    return new_pay_id;
  }

  function makeCredit(authType) {

    let new_time = nds();
    new_time = new_time.trim();

    var packet;
    packet = '';
    packet = jsonInsert(packet, 'TIMEOUT', '02');
    packet = jsonInsert(packet, 'MSGTYPE', String(authType));			//전문종류
    packet = jsonInsert(packet, 'TID', pay_form.txtTID.value);					//단말기번호
    packet = jsonInsert(packet, 'MSGNO', new_time);						//전문번호
    packet = jsonInsert(packet, 'TRANSTIME', getTime_YYMMDDHHMMSS());	//전문생성일자

    if (pay_form.WCC.value != ' ') {
      packet = jsonInsert(packet, 'WCC', pay_form.WCC.value);
      packet = jsonInsert(packet, 'TRACK2DATA', cardNum100);
    }

    packet = jsonInsert(packet, 'INSTALLMENT', pay_form.edtInstallment.value);
    packet = jsonInsert(packet, 'AMOUNT', amount9);
    packet = jsonInsert(packet, 'TAX', tax9);
    packet = jsonInsert(packet, 'SERVICE', tip9);
    packet = jsonInsert(packet, 'CURRENCY', 'KRW');

    if (authType == '1010' || authType == '1012') {
      //packet = jsonInsert(packet, 'TRANSDATE', fillSpace(6));
      //packet = jsonInsert(packet, 'AUTHORITY', fillSpace(12));
      //packet = jsonInsert(packet, 'ORGAUTHNO', fillSpace(12));
    } else {
      packet = jsonInsert(packet, 'TRANSDATE', pay_form.edtAuthDate.value);
      packet = jsonInsert(packet, 'AUTHORITY', pay_form.edtAuthNo.value);
      //packet = jsonInsert(packet, 'ORGAUTHNO', edtAuthUniqueNo.value);	// 직전취소 설정
    }

    packet = jsonInsert(packet, 'NOTAX', taxFree9);			//비과세금액
    /*
    packet = jsonInsert(packet, 'PINNO', fillSpace(18));		//비밀번호
    packet = jsonInsert(packet, 'OILINFO', fillSpace(24));	//주유정보
    packet = jsonInsert(packet, 'SITEBIZNO', fillSpace(10));	//하위 사업자 번호
    */
    packet = jsonInsert(packet, 'POSSERIALNO', 'JTPOSDM16011E278');	//pos 시리얼 번호

    //packet = jsonInsert(packet, 'ADDINFO1', fillSpace(32));			//부가정보
    //packet = jsonInsert(packet, 'ADDINFO2', fillSpace(128));			//부가정보2
    if (recycleUse == 1) {
      packet = jsonInsert(packet, 'ADDINFO2', fillSpace(64) + recycleRcdAmt9 + fillSpace(52));			//부가정보2
    }
    //packet = jsonInsert(packet, 'RESERVED', fillSpace(64));			//reserved

    packet = jsonInsert(packet, 'SIGNKBN', ' ');				//서명 여부
    packet = jsonInsert(packet, 'CR', String.fromCharCode(0x0d));		// CR

    return packet;
  }

  function makeCash(authType) {
    var packet;

    let new_time = nds();
    new_time = new_time.trim();

    packet = '';
    packet = jsonInsert(packet, 'TIMEOUT', '02');
    packet = jsonInsert(packet, 'MSGTYPE', String(authType));
    packet = jsonInsert(packet, 'TID', pay_form.txtTID.value); //TID
    packet = jsonInsert(packet, 'MSGNO', new_time);					//전문관리번호 (POS가 거래를 확인 하기 위한 번호)
    packet = jsonInsert(packet, 'TRANSTIME', getTime_YYMMDDHHMMSS());		//전문 생성 일자

    if (pay_form.WCC.value != ' ') {
      packet = jsonInsert(packet, 'WCC', pay_form.WCC.value);
      packet = jsonInsert(packet, 'TRACK2DATA', cardNum100);
    }
    packet = jsonInsert(packet, 'AMOUNT', amount9);
    packet = jsonInsert(packet, 'TAX', tax9);
    packet = jsonInsert(packet, 'SERVICE', tip9);

    if (input_form?.cash_p_gubun?.value == "0") {
      //inputData += "0";    //거래구분자 -개인
      packet = jsonInsert(packet, 'AUTH_TYPE', '0'); //개인
    } else {
      //inputData += "1";    //거래구분자 -사업자
      packet = jsonInsert(packet, 'AUTH_TYPE', '1'); //사업자
    }


    if (authType == '5010') {

    } else //취소 인 경우
    {
      packet = jsonInsert(packet, 'TRANSDATE', pay_form.edtAuthDate.value); //원거래일자
      packet = jsonInsert(packet, 'AUTHORITY', pay_form.edtAuthNo.value); //원승인번호
      packet = jsonInsert(packet, 'CANCELCODE', '1');
    }
    packet = jsonInsert(packet, 'NOTAX', taxFree9);

    if (recycleUse == 1) {
      packet = jsonInsert(packet, 'ADDINFO2', recycleRcdAmt9 + fillSpace(20));
    }

    packet = jsonInsert(packet, 'CR', String.fromCharCode(0x0d));

    return packet;
  }

  function fillZero(n) {
    var zeros = "";

    for (let i = 0; i < n; i++) {
      zeros += '0';
    }

    return zeros;
  }

  function fillSpace(n) {
    var zeros = "";

    for (let i = 0; i < n; i++) {
      zeros += ' ';
    }

    return zeros;
  }

  function leadingZeros(n, digits) {
    var zero = '';
    n = n.toString();

    if (n.length < digits) {
      for (var i = 0; i < digits - n.length; i++)
        zero += '0';
    }

    return zero + n;
  }


  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <form name="input_form" id="input_form">
          <input type="hidden" name="mb_no" value={props.mbNo}/>
          <input type="hidden" name="auth_code"/>
          <input type="hidden" name="ip_no"/>
          <input type="hidden" name="won_ip_no"/>

          {/*카드결제 관련정보*/}
          <input type="text" name="purchase_code" style={{display: "none"}}/>
          <input type="text" name="machine_no" style={{display: "none"}}/>
          <input type="text" name="company_no" style={{display: "none"}}/>
          <input type="text" name="card_no" style={{display: "none"}}/>
          <input type="text" name="permit_no" style={{display: "none"}}/>
          <input type="text" name="permit_time" style={{display: "none"}}/>
          <input type="text" name="card_gubun" style={{display: "none"}}/>
          <input type="text" name="unique_no" style={{display: "none"}}/>
          <input type="text" name="rtn" style={{display: "none"}}/>
          <input type="text" name="receipt_info" style={{display: "none"}}/>
          <input type="text" name="print_YN" style={{display: "none"}}/>
          <input type="text" name="cash_hp" style={{display: "none"}}/>
          <input type="text" name="cash_p_gubun" style={{display: "none"}} value={result?.payInfo?.gubun}/>


          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: '400px'}}>
            <div className="POP_title">
              <h5><strong>취소처리</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border text-center">
                <colgroup>
                  <col width="30%"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>원거래일</th>
                  <td className="text-left">
                    {result?.iData?.ip_date}
                  </td>
                </tr>
                <tr>
                  <th>결제금액</th>
                  <td className="text-left">
                    {Fn.numberWithCommas(result?.iData?.ip_amt)}
                    <input type="hidden" name="ip_amt" value={result?.iData?.ip_amt}/>
                    <input type="hidden" name="month_card" value={result?.iData?.month_card}/>
                    {
                      result?.iData?.ip_method == '현금' ?
                        <input type="text" name="payCard" value="1111" style={{display: "none"}}/>
                        :
                        result?.iData?.ip_method == '계좌이체' ?
                          <input type="text" name="payCard" value={result?.ff_data || ''} style={{display: "none"}}/>
                          : null
                    }
                  </td>
                </tr>
                <tr>
                  <th>취소일자</th>
                  <td className="text-left">
                    {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) > 7 ?
                      <JDatePicker name="ip_date" id="ip_date" type="text" className="form-control wid80" placeholder=""/>
                      :
                      <JDatePicker name="ip_date" id="ip_datess" type="text" className="form-control wid80" placeholder=""/>
                    }
                  </td>
                </tr>
                <tr>
                  <th>비고</th>
                  <td>
                    <input name="ip_etc" type="text" className="form-control" placeholder="" maxLength="30"/>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-point mx-1 px-3" onClick={() => pre_input_insert()}>취소처리</button>
              <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setIsOpen(false)}>닫기</button>
            </div>
          </div>
        </form>


        <span style={{display: "none"}}>
          <form name="pay_form" id="pay_form">

            {/*연결방식-ok : */}
            <input type="radio" name="HttpType" value="0" checked="checked"/>HTTPS(보안) 연결 / <input type="radio" name="HttpType" value="1"/>HTTP 연결

            {/*Port 번호-ok : */}
            <input id="portNo" name="portNo" style={{width: '60px;'}} type="text" value="6443"/>

            {/*단말기번호-ok : */}
            <input id="txtTID" name="txtTID" style={{width: '200px;'}} type="text" value={result?.bOptRow?.TID}/>

            {/*사업자번호-ok : */}
            <input id="txtBiz" name="txtBiz" style={{width: '200px;'}} type="text" value=""/>

            <input type="radio" name="radioTaskType" value="0" checked="checked"/>거래 요청-ok
            <select id="authType" style={{width: '200px;'}}>
              <option value="1010">01.신용 승인</option>
              <option value="1050">02.신용 취소</option>
              <option value="1012">03.은련 승인</option>
              <option value="1052">04.은련 취소</option>
              <option value="5010">05.현금영수증 승인</option>
              <option value="5050" selected={true}>06.현금영수증 취소</option>
              <option value="8040">07.카카오페이 승인조회</option>
              <option value="8041">08.카카오페이 취소조회</option>
              <option value="80990">09.통합간편결제 승인</option>
              <option value="80991">10.통합간편결제 취소</option>
              <option value="8020">11.L.PAY 승인</option>
              <option value="8021">12.L.PAY 취소</option>
              <option value="8080">13.현금IC 승인</option>
              <option value="8081">14.현금IC 취소</option>
              <option value="8084">15.현금IC 조회</option>
              <option value="8050">16.제로페이 승인</option>
              <option value="8051">17.제로페이 취소</option>
              <option value="1014A">18.ICQR 승인</option>
              <option value="1014C">19.ICQR 취소</option>
              <option value="8030">20.SSG페이 승인</option>
              <option value="8031">21.SSG페이 취소</option>
              <option value="8036">22.SSG페이 확정</option>
              <option value="8046">23.알리페이 승인</option>
              <option value="8047">24.알리페이 취소</option>
              <option value="6080">25.수표조회</option>
              <option value="3010">26.포인트적립 승인</option>
              <option value="4010">27.멤버쉽 승인</option>
            </select>

            <input type="radio" name="radioTaskType" value="1"/>기능 요청-ok
            <select id="abilityType" style={{width: '200px;'}}>
              <option value="PC">01.식별번호 요청</option>
              <option value="KC">02.키 교환 요청</option>
              <option value="DC">03.데몬 상태 체크</option>
              <option value="RC">04.키오스크 리더기 체크</option>
              <option value="UP">05.데몬 업데이트 요청</option>
              <option value="LG">06.로그 수집 요청</option>
            </select>

            {/*현금영수증 승인 구분-ok :*/}
            <select id="authCashType" name="authCashType" style={{width: '200px;'}}>
              <option value="0">01.소비자 소득공제</option>
              <option value="1">02.사업자 지출증빙</option>
            </select>

            {/*WCC-ok :*/}
            <select id="WCC" name="WCC" style={{width: '200px;'}}>
              <option value=" ">01. 리더기 처리</option>
              <option value="K">02. Key-In</option>
              <option value="Q">03. 앱카드</option>
              <option value="A">04. 앱카드KeyIn</option>
              <option value="B">05. 바코드</option>
            </select>

            {/*지불수단-ok*/}
            <select id="payType" name="payType" style={{width: '200px;'}}>
              <option value="C">01. 신용</option>
              <option value="M">02. 머니</option>
              <option value="B">03. 계좌</option>
            </select>

            {/*카드번호-ok*/}
            <input type="text" id="edtCardNum" name="edtCardNum" value="" style={{width: '200px;'}}/>

            {/*거래금액-ok*/}
            <input type="text" id="edtAmount" name="edtAmount" value="0" style={{width: '200px;'}}/>

            {/*세금-ok*/}
            <input type="text" id="edtTax" name="edtTax" value="0" style={{width: '200px;'}}/>

            {/*봉사료-ok*/}
            <input type="text" id="edtTip" name="edtTip" value="0" style={{width: '200px;'}}/>

            {/*할부개월-ok*/}
            <input type="text" id="edtInstallment" name="edtInstallment" value="00" placeholder="00" style={{width: '200px;'}}/>

            {/*비과세 금액-ok*/}
            <input type="text" id="edtTaxFree" name="edtTaxFree" value="0" style={{width: '200px;'}}/>

            {/*자연순환보증금-ok*/}
            <input type="radio" name="recycleType" value="0" checked="checked"/>없음 <input type="radio" name="recycleType" value="1"/>있음 <input id="recycleAmt" style={{width: "80px"}} type="text" value="0"/>

            {/*원 거래일자-ok*/}
            <input type="text" id="edtAuthDate" name="edtAuthDate" placeholder="YYMMDD" style={{width: '200px;'}} value={result?.iData?.reg_date?.substr(2, 8)?.replace(/-/g, "")}/>

            {/*원 승인번호-ok*/}
            <input type="text" id="edtAuthNo" name="edtAuthNo" style={{width: '200px;'}} value={result?.iData?.trade_auth_no}/>

            {/*원거래 고유번호-ok*/}
            <input type="text" id="edtAuthUniqueNo" name="edtAuthUniqueNo" style={{width: '200px;'}} value={result?.iData?.trade_unique_no}/>

            <textarea id="taResponse" name="taResponse" style={{height: "260px", width: "100%;"}} rows="1" cols="129"></textarea>

          </form>
        </span>
      </div>

    </Modal>
  );
};

export default MvCashCanel;
