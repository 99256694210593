import React from "react";
import ReactDOM from "react-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./sass/main.scss";

import App from "./App";

import {Provider} from "react-redux";
import {createStore} from "redux";
import rootreducer from "./reducers/rootReducer";

const store = createStore(rootreducer);

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>,
  document.getElementById("root")
);
