import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";


const ConfigSmileSetting = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [renew, setRenew] = useState(0);
  const [result, setResult] = useState({});

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    axios2
      .post(`/branchop/smile/setting/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          for (let i = 1; i <= 50; i++) {
            if ($(`#t${i}_1_s`).length > 0) {
              $(`#t${i}_1_s`).val(eval(`result?.value${i}_s`)?.["1"]);
              $(`#t${i}_1_e`).val(eval(`result?.value${i}_e`)?.["1"]);
              $(`#t${i}_2_s`).val(eval(`result?.value${i}_s`)?.["2"]);
              $(`#t${i}_2_e`).val(eval(`result?.value${i}_e`)?.["2"]);
              $(`#t${i}_3_s`).val(eval(`result?.value${i}_s`)?.["3"]);
              $(`#t${i}_3_e`).val(eval(`result?.value${i}_e`)?.["3"]);
            }
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-1.
  const go_submit = () => {
    const params = {};
    for (let i = 1; i <= 50; i++) {
      if ($(`#t${i}_1_s`).length > 0) {
        params[`t${i}_1_s`] = $(`#t${i}_1_s`).val();
        params[`t${i}_1_e`] = $(`#t${i}_1_e`).val();
        params[`t${i}_2_s`] = $(`#t${i}_2_s`).val();
        params[`t${i}_2_e`] = $(`#t${i}_2_e`).val();
        params[`t${i}_3_s`] = $(`#t${i}_3_s`).val();
        params[`t${i}_3_e`] = $(`#t${i}_3_e`).val();
      }
    }

    axios2
      .postEx('/branchop/smile/setting', params)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        alert("저장되었습니다");
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="15%"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="8" className="text-left">
              <span className="h7 bold700">각 %항목</span> - 각 항목별 점수로 산정 기준을 설정
            </td>
          </tr>
          <tr>
            <th>항목</th>
            <th>1점</th>
            <th>2점</th>
            <th>3점</th>
            <th>항목</th>
            <th>1점</th>
            <th>2점</th>
            <th>3점</th>
          </tr>
          <tr>
            <td className="text-left">신규등록율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t12_1_s" value2={result?.value12_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t12_1_e" value2={result?.value12_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t12_2_s" value2={result?.value12_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t12_2_e" value2={result?.value12_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t12_3_s" value2={result?.value12_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t12_3_e" value2={result?.value12_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td className="text-left">문자쿠폰 회수율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t7_1_s" value2={result?.value7_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t7_1_e" value2={result?.value7_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t7_2_s" value2={result?.value7_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t7_2_e" value2={result?.value7_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t7_3_s" value2={result?.value7_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t7_3_e" value2={result?.value7_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">장기등록율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t13_1_s" value2={result?.value13_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t13_1_e" value2={result?.value13_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t13_2_s" value2={result?.value13_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t13_2_e" value2={result?.value13_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t13_3_s" value2={result?.value13_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t13_3_e" value2={result?.value13_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td className="text-left">소개등록율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t8_1_s" value2={result?.value8_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t8_1_e" value2={result?.value8_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t8_2_s" value2={result?.value8_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t8_2_e" value2={result?.value8_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t8_3_s" value2={result?.value8_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t8_3_e" value2={result?.value8_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">PT가입율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t14_1_s" value2={result?.value14_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t14_1_e" value2={result?.value14_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t14_2_s" value2={result?.value14_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t14_2_e" value2={result?.value14_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t14_3_s" value2={result?.value14_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t14_3_e" value2={result?.value14_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td className="text-left">환불율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t9_1_s" value2={result?.value9_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t9_1_e" value2={result?.value9_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t9_2_s" value2={result?.value9_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t9_2_e" value2={result?.value9_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t9_3_s" value2={result?.value9_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t9_3_e" value2={result?.value9_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">OT진행율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t15_1_s" value2={result?.value15_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t15_1_e" value2={result?.value15_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t15_2_s" value2={result?.value15_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t15_2_e" value2={result?.value15_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t15_3_s" value2={result?.value15_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t15_3_e" value2={result?.value15_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td className="text-left">퇴직율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t24_1_s" value2={result?.value24_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t24_1_e" value2={result?.value24_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t24_2_s" value2={result?.value24_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t24_2_e" value2={result?.value24_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t24_3_s" value2={result?.value24_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t24_3_e" value2={result?.value24_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">OT진행자 중 PT가입율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t16_1_s" value2={result?.value16_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t16_1_e" value2={result?.value16_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t16_2_s" value2={result?.value16_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t16_2_e" value2={result?.value16_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t16_3_s" value2={result?.value16_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t16_3_e" value2={result?.value16_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td className="text-left">서비스개월 발급율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t25_1_s" value2={result?.value25_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t25_1_e" value2={result?.value25_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t25_2_s" value2={result?.value25_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t25_2_e" value2={result?.value25_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t25_3_s" value2={result?.value25_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t25_3_e" value2={result?.value25_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">재등록율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t1_1_s" value2={result?.value1_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t1_1_e" value2={result?.value1_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t1_2_s" value2={result?.value1_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t1_2_e" value2={result?.value1_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t1_3_s" value2={result?.value1_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t1_3_e" value2={result?.value1_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td className="text-left">PT순익율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t21_1_s" value2={result?.value21_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t21_1_e" value2={result?.value21_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t21_2_s" value2={result?.value21_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t21_2_e" value2={result?.value21_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t21_3_s" value2={result?.value21_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t21_3_e" value2={result?.value21_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">복귀율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t2_1_s" value2={result?.value2_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t2_1_e" value2={result?.value2_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t2_2_s" value2={result?.value2_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t2_2_e" value2={result?.value2_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t2_3_s" value2={result?.value2_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t2_3_e" value2={result?.value2_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td className="text-left">순익타겟 달성율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t22_1_s" value2={result?.value22_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t22_1_e" value2={result?.value22_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t22_2_s" value2={result?.value22_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t22_2_e" value2={result?.value22_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t22_3_s" value2={result?.value22_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t22_3_e" value2={result?.value22_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">미출석율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t3_1_s" value2={result?.value3_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t3_1_e" value2={result?.value3_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t3_2_s" value2={result?.value3_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t3_2_e" value2={result?.value3_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t3_3_s" value2={result?.value3_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t3_3_e" value2={result?.value3_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td className="text-left">목표매출 달성율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t23_1_s" value2={result?.value23_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t23_1_e" value2={result?.value23_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t23_2_s" value2={result?.value23_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t23_2_e" value2={result?.value23_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t23_3_s" value2={result?.value23_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t23_3_e" value2={result?.value23_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">출석율</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t4_1_s" value2={result?.value4_s?.["1"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t4_1_e" value2={result?.value4_e?.["1"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t4_2_s" value2={result?.value4_s?.["2"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t4_2_e" value2={result?.value4_e?.["2"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t4_3_s" value2={result?.value4_s?.["3"]}/>
                <p className="my-1 bold700">%</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="t4_3_e" value2={result?.value4_e?.["3"]}/>
                <p className="my-1 bold700">%</p>
              </div>
            </td>
            <td className="text-left"></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
      </div>

      <div className="m-3">
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="15%"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <td colSpan="4" className="text-left">
              <span className="h7 bold700">그외의 항목</span>
            </td>
          </tr>
          <tr>
            <th>항목</th>
            <th>1점</th>
            <th>2점</th>
            <th>3점</th>
          </tr>
          <tr>
            <td className="text-left">추천지수</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t6_1_s" value2={result?.value6_s?.["1"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t6_1_e" value2={result?.value6_e?.["1"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t6_2_s" value2={result?.value6_s?.["2"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t6_2_e" value2={result?.value6_e?.["2"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t6_3_s" value2={result?.value6_s?.["3"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t6_3_e" value2={result?.value6_e?.["3"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">컴플레인관리</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t26_1_s" value2={result?.value26_s?.["1"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t26_1_e" value2={result?.value26_e?.["1"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t26_2_s" value2={result?.value26_s?.["2"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t26_2_e" value2={result?.value26_e?.["2"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t26_3_s" value2={result?.value26_s?.["3"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t26_3_e" value2={result?.value26_e?.["3"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">총매출</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t17_1_s" value2={result?.value17_s?.["1"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t17_1_e" value2={result?.value17_e?.["1"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t17_2_s" value2={result?.value17_s?.["2"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t17_2_e" value2={result?.value17_e?.["2"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t17_3_s" value2={result?.value17_s?.["3"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t17_3_e" value2={result?.value17_e?.["3"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">퍼블릭 매출</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t18_1_s" value2={result?.value18_s?.["1"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t18_1_e" value2={result?.value18_e?.["1"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t18_2_s" value2={result?.value18_s?.["2"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t18_2_e" value2={result?.value18_e?.["2"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t18_3_s" value2={result?.value18_s?.["3"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t18_3_e" value2={result?.value18_e?.["3"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">PT매출</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t19_1_s" value2={result?.value19_s?.["1"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t19_1_e" value2={result?.value19_e?.["1"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t19_2_s" value2={result?.value19_s?.["2"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t19_2_e" value2={result?.value19_e?.["2"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t19_3_s" value2={result?.value19_s?.["3"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t19_3_e" value2={result?.value19_e?.["3"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-left">총매출</td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t20_1_s" value2={result?.value20_s?.["1"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t20_1_e" value2={result?.value20_e?.["1"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t20_2_s" value2={result?.value20_s?.["2"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t20_2_e" value2={result?.value20_e?.["2"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t20_3_s" value2={result?.value20_s?.["3"]}/>
                <p className="m-1 bold700">점</p>
                <p className="m-1 bold700">-</p>
                <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="t20_3_e" value2={result?.value20_e?.["3"]}/>
                <p className="m-1 bold700">점</p>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div className="m-3 text-center">
        <button type="button" className="btn btn-point px-4" onClick={() => go_submit()}>저장</button>
      </div>

    </CmnCard>
  );
};

export default ConfigSmileSetting;
