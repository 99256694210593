import {useHistory, useLocation} from "react-router-dom";
import "../../sass/main.scss";
import React, {useEffect, useRef, useState} from "react";
import Fn from "../../libraries/Fn";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";

import introLogo from '../../images/intro_logo.png';
import introThum from '../../images/icon_user.png'; // Ensure path is correct
// import Modal from "react-modal";
import Constants from "../../constants/constants";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import logoPro from "../../images/logo_pro.png";
import logoFitness from "../../images/logo_fitness.png";
import logoGym from "../../images/logo_gym.png";
import logoFa from "../../images/logo_fa.png";
import logoX2 from "../../images/logo_x2.png";
import logoHealthymeal from "../../images/logo_healthymeal.png";
import member_thumb from "../../images/member_thumb.png";
import img_0011_w from "../../images/0011_w.png";
import img_0018_w from "../../images/0018_w.png";
import img_0020_w from "../../images/0020_w.png";
import img_0022_w from "../../images/0022_w.png";

import _introThum from '../../images/enter/intro_thum.png';
import introIcon01 from '../../images/enter/intro_icon01.png';
import introIcon02 from '../../images/enter/intro_icon02.png';
import introIcon03 from '../../images/enter/intro_icon03.png';
import introIcon01off from '../../images/enter/intro_icon01off.png';
import introIcon02off from '../../images/enter/intro_icon02off.png';
import introIcon03off from '../../images/enter/intro_icon03off.png';
import introLogoW from '../../images/enter/intro_logo_w.png';

// TODO : PTODO => alim (알림 삭제) 처리 필요
const Main = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();
  const location = useLocation();

  const [cardNum, setCardNum] = useState(location?.state?.cardNum || '');
  const [result, setResult] = useState();
  const [audioFile, setAudioFile] = useState();

  useEffect(() => {
    // $('body').css('overflow', 'hidden');
    // Fn.resizeScreenReal(1.1);
    // $(window).bind('resize', function () {
    //   localStorage.setItem("intro_resized", "1");
    //   Fn.resizeScreen(1.1);
    // })
    return () => {
      try {
        clearTimeout(window.backTimeout);
      } catch (err) {
      }
      // $(window).unbind('resize');
    };
  }, []);

  var maskName = function (strName) {
    if (!strName) return "";
    if (strName.length > 2) {
      var originName = strName.split('');
      originName.forEach(function (name, i) {
        if (i === 0 || i === originName.length - 1) return;
        originName[i] = '*';
      });
      var joinName = originName.join();
      return joinName.replace(/,/g, '');
    } else {
      var pattern = /.$/; // 정규식
      return strName.replace(pattern, '*');
    }
  };

  /*
  useEffect(() => {
    axios2
      .post(`/common/update/check?build=${Constants.BUILD}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if (message === 'update') {
            window.location.reload();
          }
        }
      })
      .catch((error) => {
      });
  }, [window.location.pathname]);
  */

  useEffect(() => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 0) {
      history.push("/login");
      return;
    }

    $.ajaxSetup({
      beforeSend: function (xhr, options) {
        if (options.url.startsWith(":3000"))
          options.url = options.url.replace(":3000", ":8994");
        else if (options.url.indexOf("http") === -1)
          options.url = Constants.API_URL + options.url;
      },
      headers: {
        'Authorization': ('Bearer ' + localStorage.getItem("USER.token"))
      },
      cache: false,
      error: function (jqXHR, textStatus, errorThrown) {
        Fn.parseAjaxError(jqXHR, textStatus, errorThrown, history);
      }
    });

    $('.main_wrapper').height($(document).height());
  }, []);

  useEffect(() => {
    $('body').css('zoom', 'unset');
    $('#_INTRO_BG2').height($(document).height());
    $("*").each(function () {
      $(this).css({"outline": "none", "lineHeight": "180%"});
    });
  }, []);

  useEffect(() => {
    clearTimeout(window.backTimeout);
    $('#cardNum').focus().select();
    load();
  }, [cardNum]);

  const load = () => {
    $('#overlay').show();

    axios2
      .post(`/enter/enter?cardNum=${cardNum}`)
      .then((response) => {
        const {code, message, result} = response?.data;
        console.log(result);
        setResult(result);
        if (code === 200) {
          if (Fn.parseInt(result.err_type) === 1) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file2)));
            $('#err_title').html('미인증 회원');
            $('#err_cont').html('등록되지 않은 회원번호입니다<br/>자세한 사항은 관리자에게 문의바랍니다.');
            $('.pop_background').show();
          } else if (Fn.parseInt(result.err_type) === 80) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file2)));
            $('#err_title').html('카드 읽기 오류');
            $('#err_cont').html('리더기에 카드를 다시 읽어주세요<br/>자세한 사항은 관리자에게 문의바랍니다.');
            $('.pop_background').show();
          } else if (Fn.parseInt(result.err_type) === 2) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file2)));
            $('#err_title').html('기간 만료');
            if (result.end_date)
              $('#err_cont').html(`이용기간이 종료된 회원입니다.<br/>종료일 : ${result.end_date}<br/>자세한 사항은 관리자에게 문의바랍니다.`);
            else
              $('#err_cont').html(`이용기간이 종료된 회원입니다.<br/>자세한 사항은 관리자에게 문의바랍니다.`);
            $('.pop_background').show();
          } else if (Fn.parseInt(result.err_type) === 3) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file4)));
            $('#err_title').html('휴회기간 입장');
            // TODO
            // $('#err_cont').html(`회원님은 현재 휴회기간중입니다.<br/>휴회기간 : ${result.de_sDate} ~ ${result.de_eDate}<br/>자세한 사항은 관리자에게 문의바랍니다.`);
            $('#err_cont').html(`회원님은 현재 휴회기간중입니다.<br/>자세한 사항은 관리자에게 문의바랍니다.`);
            $('.pop_background').show();
          } else if (Fn.parseInt(result.err_type) === 4) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file5)));
            $('#err_title').html('미납회원');
            $('#err_cont').html(`미납요금 ${Fn.numberWithCommas(result?.misu_price)}이 있습니다.<br/>자세한 사항은 관리자에게 문의바랍니다.`);
            $('.pop_background').show();
          } else if (Fn.parseInt(result.err_type) === 5) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file3)));
            $('#err_title').html('기출석 회원');
            $('#err_cont').html(`금일 출석이력이 존재합니다.<br/>자세한 사항은 관리자에게 문의바랍니다.`);
            $('.pop_background').show();
          } else if (Fn.parseInt(result.err_type) === 8) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file2)));
            $('#err_title').html('유효시간 초과');
            $('#err_cont').html(`생성된 바코드의 유효시간이 초과되었습니다.<br/>자세한 사항은 관리자에게 문의바랍니다.`);
            $('.pop_background').show();
          } else if (Fn.parseInt(result.err_type) === 9) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file6)));
            $('#err_title').html('이용불가 지점');
            $('#err_cont').html(`회원님의 입장이 불가능한 지점입니다.<br/>자세한 사항은 관리자에게 문의바랍니다.`);
            $('.pop_background').show();
          } else if (Fn.parseInt(result.err_type) === 10) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file2)));
            $('#err_title').html('이용요일 불가');
            $('#err_cont').html(`금일은 회원님의 입장이 불가능한 요일입니다.<br/>자세한 사항은 관리자에게 문의바랍니다.`);
            $('.pop_background').show();
          } else if (Fn.parseInt(result.err_type) === 11) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file2)));
            $('#err_title').html('이용시간 불가');
            $('#err_cont').html(`금일은 회원님의 입장이 불가능한 시간입니다.<br/>자세한 사항은 관리자에게 문의바랍니다.`);
            $('.pop_background').show();
          } else if (Fn.parseInt(result.err_type) === 12) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file2)));
            $('#err_title').html('타지점 이용불가');
            $('#err_cont').html(`타지점 이용이 불가합니다.<br/>자세한 사항은 관리자에게 문의바랍니다.`);
            $('.pop_background').show();
          } else if (Fn.parseInt(result.err_type) === 90) {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file1)));
            $('#err_title').html(`${result.branch_name} 지점`);
            $('#err_cont').html(`${result.step_name} 스텝 바코드 입장입니다.`);
            $('.pop_background').show();
          } else {
            setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent(result?.s_file1)));
          }
        }

        try {
          const ocare_send = result?.ocare_send || "";
          if (ocare_send) {
            $.ajax({
              type: "post",
              url: `https://smms.spoany.co.kr/kiosk/enter_ocare.php?member_id=${ocare_send?.split(",")?.[0]}&branch_code=${ocare_send?.split(",")?.[1]}&branch_name=${encodeURIComponent(ocare_send?.split(",")?.[2])}`,
              success: function (data) {
                data = data.trim();
                console.log('OCARE', data);
              },
              error: function (e) {
                //alert('데이터오류! 시스템 관리자에게 문의하세요');
              }
            });
          }
        } catch (err) {
        }

        clearTimeout(window.backTimeout);
        window.backTimeout = setTimeout(() => {
          try {
            clearTimeout(window.backTimeout);
          } catch (err) {
          }
          // 수정
          history.push("/enter/enter");
        }, 6000)
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      })
      .finally(() => {
        $('#overlay').fadeOut(300);
      });
  }

  useEffect(() => {
    return () => {
      clearTimeout(window.backTimeout);
    }
  }, []);

  if (false) {

    let logoSrc;
    switch (localStorage.getItem("USER.systemBrand")) {
      case 'P':
        logoSrc = logoPro;
        break;
      case 'F':
        logoSrc = logoFitness;
        break;
      case 'G':
        logoSrc = logoGym;
        break;
      case 'S':
        logoSrc = logoFa;
        break;
      case 'X':
        logoSrc = logoX2;
        break;
      case 'M':
        logoSrc = logoHealthymeal;
        break;
      default:
        logoSrc = logoFitness;
    }
    return (
      <div className="entry_body2">

        <div className="enter_container">
          <input type="hidden" name="stop_YN"/>
          <div className="entry_m_head">
            <h1>
              <img src={logoSrc} alt="SPOANY" style={{width: "296px", marginRight: "10px"}}/>
              {localStorage.getItem("USER.systemBranchName")}
              <div className="enter_form2">
                <input type="text" className="input2" style={{outline: "none"}} placeholder="회원 카드번호 입력" name="cardNum" id="cardNum"
                       onKeyDown={(e) => {
                         if (e.keyCode == 13) {
                           if (!$('#cardNum')?.val()?.trim()) return;
                           if ($('#cardNum').val().length < 6) return;

                           setCardNum($('#cardNum').val());
                           $('#cardNum').val('').focus();
                         }
                       }}
                />
                <button name="" type="button" className="enter_btn2" onClick={() => {
                  if (!$('#cardNum')?.val()?.trim()) return;
                  if ($('#cardNum').val().length < 6) return;

                  setCardNum($('#cardNum').val());
                  $('#cardNum').val('').focus();
                }} style={{outline: "none"}}>검색
                </button>
              </div>
            </h1>
          </div>

          <div className="enter_inner">
            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
              <tr>
                <td width="180" align="center" valign="top">
                  <div className="enter_thumb">
                    <img src={member_thumb} width="120" height="auto"/>
                  </div>
                </td>
                <td width="400" align="left" valign="top">
                  <div className="enter_txt1">Welcome!</div>
                  <div className="enter_txt2"><strong>{maskName(result?.mb_name)}</strong> 님</div>
                  <div className="enter_txt2"
                       style={{fontSize: "34px", lineHeight: "110%"}}>{result?.member_grade === 'F' ? '피트니스' : result?.member_grade === 'P' ? '프로' : result?.member_grade === 'G' ? '짐' : result?.member_grade === 'S' ? '패스트' : ''}</div>
                </td>
                <td height="150" align="right" valign="top">
                  <table>
                    <tr>
                      <td>
                        <div className="enter_dash"><span><strong>{result?.per2 || 0}</strong>%</span> 출석률</div>
                      </td>
                      <td>
                        <div className="enter_dash"><span><strong>{Fn.numberWithCommas(result?.mb_point || 0)}</strong>P</span> 출석포인트</div>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="enter_tb1" style={{marginTop: "10px"}}>
              <tr>
                <td width="50%" align="left">이번달 <strong>{isNaN(result?.ct + result?.ctTar) ? "0" : (result?.ct + result?.ctTar)}</strong>번째 출석</td>
                {result?.d_day < 0 ?
                  <td width="50%" align="right"><strong>D+{Math.abs(result?.d_day || 0)}</strong></td>
                  :
                  <td width="50%" align="right"><strong>D-{result?.d_day || 0}</strong></td>
                }
              </tr>
            </table>
            <div className="progress">
              <div className="progress-bar progress-bar-danger" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{width: result?.per}}><span className="sr-only">{result?.per}% Complete</span></div>
            </div>
            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="enter_tb1" style={{marginTop: "10px"}}>
              <tr>
                <td width="50%" align="left"> 출석 : {result?.branch_name} {result?.ct}회 / 타지점 {result?.ctTar}회</td>
                <td width="50%" align="right"> 회원기간 만료 : {result?.end_date?.split("-")?.[0]}년 {result?.end_date?.split("-")?.[1]}월 {result?.end_date?.split("-")?.[2]}일</td>
              </tr>
            </table>
            <table width="100%" border="0" cellSpacing="0" cellPadding="0" style={{marginTop: "60px"}}>
              <tr>
                <td width="11%" align="center" valign="top">
                  <div className={`enter_my_icon ${result?.suit_end_date >= Fn.getCurrentDate() ? "active" : ""} d-flex justify-content-center align-content-center align-items-center`}><img src={img_0018_w} alt="운동복"/></div>
                  <div className="enter_my_ser">운동복</div>
                  <div className="enter_my_ser2">{result?.suit_end_date}</div>
                </td>
                <td width="11%" align="center" valign="top">
                  <div className={`enter_my_icon ${result?.locker_end_date >= Fn.getCurrentDate() ? "active" : ""} d-flex justify-content-center align-content-center align-items-center`}><img src={img_0020_w} alt="락커"/></div>
                  <div className="enter_my_ser">락커</div>
                  <div className="enter_my_ser2">{result?.locker_end_date}</div>
                </td>
                <td width="11%" align="center" valign="top">
                  <div className={`enter_my_icon ${result?.pt_remain > 0 ? "active" : ""} d-flex justify-content-center align-content-center align-items-center`}><img src={img_0011_w} alt="PT"/></div>
                  <div className="enter_my_ser">PT</div>
                  <div className="enter_my_ser2">{result?.pt_remain}회</div>
                </td>
                {/*<? if ($row[opt4] == "Y") { ?>*/}
                {/*<?*/}
                {/*if ($etc2_end_date >= $now_date) $active_spin = "active";*/}
                {/*?>*/}
                {/*<td width="11%" align="center" valign="top">*/}
                {/*  <div className="enter_my_icon <?= $active_spin ?>"><img src="../image/0008_w.png" alt="스피닝"/></div>*/}
                {/*  <div className="enter_my_ser">스피닝</div>*/}
                {/*  <div className="enter_my_ser2"><?= $etc2_end_date ?></div>*/}
                {/*</td>*/}
                {/*<? } ?>*/}

                {/*<? if ($row[opt3] == "Y") { ?>*/}
                {/*<?*/}
                {/*if ($data_row[etc1_end_date] >= $now_date) $active_sque = "active";*/}
                {/*?>*/}
                {/*<td width="11%" align="center" valign="top">*/}
                {/*  <div className="enter_my_icon <?= $active_sque ?>"><img src="../image/0009_w.png" alt="스쿼시"></div>*/}
                {/*  <div className="enter_my_ser">스쿼시</div>*/}
                {/*  <div className="enter_my_ser2"></div>*/}
                {/*</td>*/}
                {/*<? } ?>*/}

                {/*<? if ($row[opt1] == "Y") { ?>*/}
                {/*<?*/}
                {/*if ($data_row[etc3_end_date] >= $now_date) $active_golf = "active";*/}
                {/*?>*/}
                {/*<td width="11%" align="center" valign="top">*/}
                {/*  <div className="enter_my_icon <?= $active_golf ?>"><img src="../image/0012_w.png" alt="골프"></div>*/}
                {/*  <div className="enter_my_ser">골프</div>*/}
                {/*  <div className="enter_my_ser2"></div>*/}
                {/*</td>*/}
                {/*<? } ?>*/}

                {/*<? if ($row[g_locker] == "1") { ?>*/}
                {/*<?*/}
                {/*if ($data_row[g_locker_end_date] >= $now_date) $active_golf_locker = "active";*/}
                {/*?>*/}
                {/*<td width="11%" align="center" valign="top">*/}
                {/*  <div className="enter_my_icon <?= $active_golf_locker ?>"><img src="../image/0022_w.png" alt="골프라커"></div>*/}
                {/*  <div className="enter_my_ser">골프라커</div>*/}
                {/*  <div className="enter_my_ser2"></div>*/}
                {/*</td>*/}
                {/*<? } ?>*/}

                {/*<? if ($row[opt5] == "Y") { ?>*/}
                {/*<?*/}
                {/*if ($data_row[etc4_end_date] >= $now_date) $active_tae = "active";*/}
                {/*?>*/}
                {/*<td width="11%" align="center" valign="top">*/}
                {/*  <div className="enter_my_icon <?= $active_tae ?>"><img src="../image/0013_w.png" alt="태닝"></div>*/}
                {/*  <div className="enter_my_ser">태닝</div>*/}
                {/*  <div className="enter_my_ser2"></div>*/}
                {/*</td>*/}
                {/*<? } ?>*/}

                {/*<? if ($row[opt7] == "Y") { ?>*/}
                {/*<?*/}
                {/*if ($data_row[etc5_end_date] >= $now_date) $active_pila = "active";*/}
                {/*?>*/}
                {/*<td width="11%" align="center" valign="top">*/}
                {/*  <div className="enter_my_icon <?= $active_pila ?>"><img src="../image/0010_w.png" alt="필라테스"></div>*/}
                {/*  <div className="enter_my_ser">필라테스</div>*/}
                {/*  <div className="enter_my_ser2"></div>*/}
                {/*</td>*/}
                {/*<? } ?>*/}
              </tr>
            </table>
          </div>
          <table width="100%" border="0" cellSpacing="0" cellPadding="0">
            <tr>
              <td width="33%" align="left">
                <div className="enter_panel_head">이용정보</div>
                <div className="enter_panel_body">
                  <table width="80%" border="0" align="center" cellPadding="0" cellSpacing="0" className="font_16">
                    <tr>
                      <td height="15" align="center"></td>
                      <td align="center"></td>
                    </tr>
                    <tr>
                      <td width="110" height="40" align="center" style={{fontSize: "18px", fontWeight: "bold"}}>가입일 :</td>
                      <td align="center" style={{fontSize: "18px", fontWeight: "bold"}}>{result?.mb_open_date?.substring(0, 10)?.replace(/-/g, ".")}</td>
                    </tr>
                    <tr>
                      <td height="40" align="center" style={{fontSize: "18px", fontWeight: "bold"}}>시작일 :</td>
                      <td align="center" style={{fontSize: "18px", fontWeight: "bold"}}>{result?.start_date?.substring(0, 10)?.replace(/-/g, ".")}</td>
                    </tr>
                    <tr>
                      <td height="40" align="center" style={{fontSize: "18px", fontWeight: "bold"}}>종료일 :</td>
                      <td align="center" style={{fontSize: "18px", fontWeight: "bold"}}>{result?.end_date?.substring(0, 10)?.replace(/-/g, ".")}</td>
                    </tr>
                  </table>
                </div>
              </td>
              <td width="34%" align="center">
                <div style={{marginTop: "0px", marginRight: "10px", marginBottom: "10px", marginLeft: "10px"}}>
                  <div className="enter_panel_head">서비스 내역</div>
                  <div className="enter_panel_body">
                    <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="enter_list01">
                      <tr>
                        <td height="15" align="center"></td>
                        <td align="center"></td>
                      </tr>
                      {result?.app_list_qry?.map((item, index) =>
                        <tr>
                          <th width="100">{item?.ap_date?.substring(0, 10)?.replace(/-/g, ".")}</th>
                          <td align="left">{item?.ap_pdtName || (item?.it_name || '')}</td>
                        </tr>
                      )}
                    </table>
                  </div>
                </div>
              </td>
              <td width="33%" align="right">
                <div className="enter_panel_head">출석 내역</div>
                <div className="enter_panel_body">
                  <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="enter_list01">
                    <tr>
                      <td height="15" align="center"></td>
                      <td align="center"></td>
                    </tr>
                    {result?.l?.map((item, index) =>
                      <tr>
                        <th width="160">{item?.ba_date?.replace(/-/g, ".") + " " + item?.ba_time}</th>
                        <td align="left">{item?.ba_gr_name}</td>
                      </tr>
                    )}
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div className="modal_wrap pop_background" style={{display: "none"}}>
          <div className="modal_inner modal_inner2">
            <h2 id="err_title" style={{fontWeight: "700"}}></h2>
            <h7 id="err_cont" style={{fontWeight: "500", marginTop: "15px"}}></h7>
          </div>
        </div>

        <div style={{visibility: "hidden", position: "absolute", top: "-9999px"}}>
          {audioFile &&
            <AudioPlayer
              autoPlay
              src={audioFile}
              onPlay={e => console.log("onPlay")}
            />
          }
        </div>

        <div className="enter_foot1">ⓒ 2018 kdsports. all right perserved</div>
      </div>
    );
  } else {
    let logoSrc;
    switch (localStorage.getItem("USER.systemBrand")) {
      case 'P':
        logoSrc = logoPro;
        break;
      case 'F':
        logoSrc = logoFitness;
        break;
      case 'G':
        logoSrc = logoGym;
        break;
      case 'S':
        logoSrc = logoFa;
        break;
      case 'X':
        logoSrc = logoX2;
        break;
      case 'M':
        logoSrc = logoHealthymeal;
        break;
      default:
        logoSrc = logoFitness;
    }

    return (
      /*
      <div className="main_wrapper cls-enter" style={{background: '#EFEFF4'}}>
        <div id="overlay">
          <div className="cv-spinner">
            <span className="spinner"></span>
          </div>
        </div>
        <input type="hidden" name="stop_YN"/>
        <div className="intro_wrap">
          <div className="p_absolute_mid3">
            <div className="d-flex justify-content-between my-2">
              <p className="h5 bold700 text-white">
                <img src={introLogo} alt="Logo" style={{width: '180px'}}/>
              </p>
              <p className="mt-2">
                <input type="text" style={{width: '280px', height: '50px', verticalAlign: '-2px'}} id="cardNum" name="cardNum"
                       onKeyDown={(e) => {
                         if (e.keyCode == 13) {
                           setCardNum($('#cardNum').val());
                         }
                       }}/>
                <button type="button" className="btn btn-point" style={{width: '80px', height: '50px'}}
                        onClick={() => setCardNum($('#cardNum').val())}>
                  <span className="h6 bold700">검색</span>
                </button>
              </p>
            </div>
            <div className="intro_box" style={{width: '1140px', minHeight: '410px'}}>
              <div className="d-flex pt-5 px-5" style={{gap: '10px'}}>
                <div className="d-flex" style={{flex: 2, textAlign: 'left'}}>
                  <p className="intro_usr_thum">
                    <img src={introThum} alt="User Thumbnail"/>
                  </p>
                  <p className="px-3 py-2 mt-1">
                    <span className="h5 bold700">{maskName(result?.mb_name)}님</span><br/>
                    <span className="h6 bold700">{result?.member_grade === 'F' ? '피트니스' : result?.member_grade === 'P' ? '프로' : result?.member_grade === 'G' ? '짐' : result?.member_grade === 'S' ? '패스트' : ''}</span>
                  </p>
                </div>
                <p className="border px-4 h6 bold800 my-3 intro_box2">출석률
                  <span className="h6 bold800 text-pink">{` `}{result?.per2}%</span>
                </p>
                <p className="border px-4 h6 bold800 my-3 intro_box2">포인트
                  <span className="h6 bold800 text-pink">{` `}{Fn.numberWithCommas(result?.mb_point)}P</span>
                </p>
              </div>
              <div className="d-flex justify-content-between px-5 pt-4">
                <p className="h6 bold100 mb-0 px-1">이번달
                  <span className="h5 bold800 text-pink">{` `}{isNaN(result?.ct + result?.ctTar) ? "" : (result?.ct + result?.ctTar)}</span>번째 출석</p>
                {result?.d_day < 0 ?
                  <p className="h5 bold800 mb-0 px-1">D+
                    <span className="h5 bold800 text-pink">{Math.abs(result?.d_day)}</span>
                  </p>
                  :
                  <p className="h5 bold800 mb-0 px-1">D-
                    <span className="h5 bold900 text-pink">{result?.d_day}</span>
                  </p>
                }
              </div>
              <div className="mx-5 my-2 intro_progress_bg">
                <p className="intro_progress_point" style={{left: result?.per2 + "%"}}></p>
                <div className="intro_progress_bar" style={{width: (((result?.total_day - result?.d_day) / result?.total_day * 100) > 100 ? 100 : ((result?.total_day - result?.d_day) / result?.total_day * 100)) + "%"}}></div>
              </div>
              <div className="d-flex justify-content-between px-5">
                <p className="h7 bold100 px-1">출석: {result?.branch_name} {result?.ct}회 / 타지점 {result?.ctTar}회</p>
                <p className="h7 bold100 px-1">{result?.start_date?.replace(/-/g, '.')} ~ {result?.end_date?.replace(/-/g, '.')}</p>
              </div>
              <div className="d-flex mt-4 px-5" style={{gap: '20px'}}>
                <div className="d-flex justify-content-between intro_box3">
                  <p className="p-3 px-4 h7 bold900">운동복</p>
                  <p className="p-3 px-4 h6 bold900 text-pink">{result?.suit_end_date ? result?.suit_end_date.replace(/-/g, '.') : '사용안함'}</p>
                </div>
                <div className="d-flex justify-content-between intro_box3">
                  <p className="p-3 px-4 h7 bold900">락커</p>
                  <p className="p-3 px-4 h6 bold900">{result?.locker_end_date ? result?.locker_end_date.replace(/-/g, '.') : '사용안함'}</p>
                </div>
                <div className="d-flex justify-content-between intro_box3">
                  <p className="p-3 px-4 h7 bold900">PT</p>
                  <p className="p-3 px-4 h6 bold900">{result?.pt_remain}</p>
                </div>
              </div>
            </div>
            <div className="d-flex mt-2" style={{gap: '10px'}}>
              <div className="intro_box4">
                <p className="border-bottom pb-1 text-left mb-2">이용정보</p>
                <div className="d-flex justify-content-between">
                  <p className="p-2 h7 bold700">가입일</p>
                  <p className="p-2 h6 bold800 text-pink">{result?.mb_open_date?.substring(0, 10)?.replace(/-/g, ".")}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="p-2 h7 bold700">시작일</p>
                  <p className="p-2 h6 bold800 text-pink">{result?.start_date?.substring(0, 10)?.replace(/-/g, ".")}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="p-2 h7 bold700">종료일</p>
                  <p className="p-2 h6 bold800 text-pink">{result?.end_date?.substring(0, 10)?.replace(/-/g, ".")}</p>
                </div>
              </div>
              <div className="intro_box4">
                <p className="border-bottom pb-1 text-left mb-2">서비스내역</p>
                {result?.app_list_qry?.map((item, index) =>
                  <div className="d-flex justify-content-between">
                    <p className="p-1 h7 bold700">{item?.ap_date?.substring(0, 10)?.replace(/-/g, ".")}</p>
                    <p className="p-1 h7 bold700">{item?.ap_pdtName}</p>
                  </div>
                )}
              </div>

              <div className="intro_box4">
                <p className="border-bottom pb-1 text-left mb-2">출석내역</p>

                {result?.l?.map((item, index) =>
                  <div className="d-flex justify-content-between">
                    <p className="p-1 h7 bold700">{item?.ba_date?.replace(/-/g, ".") + " " + item?.ba_time}</p>
                    <p className="p-1 h7 bold700">{item?.ba_gr_name}</p>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div style={{visibility: "hidden", position: "absolute", top: "-9999px"}}>
            {audioFile &&
              <AudioPlayer
                autoPlay
                src={audioFile}
                onPlay={e => console.log("onPlay")}
              />
            }
          </div>

          <div className="pop_background" style={{zIndex: 1000000000, display: "none", zoom: "120%"}}>
            <div className="pop_box01 p_absolute_mid_pop text-center" style={{width: "760px"}}>
              <h2 className="bold800" id="err_title"></h2>
              <h6 className="bold500" id="err_cont" style={{marginTop: "30px"}}></h6>
            </div>
          </div>

        </div>
      </div>
       */

      <div id="_INTRO_BG2">
        <div className="_intro_wrap">
          <div className="p_absolute_mid2">
            <div className="d-flex justify-content-between my-2">
              <div className="d-flex justify-content-center" style={{alignItems: "center"}}>
                <p><img src={logoSrc} style={{height: "62px"}}/></p>
                <p className="h5 bold800 mb-0 px-1 text-gray" style={{marginLeft: "10px", marginTop: "10px"}}>{localStorage.getItem("USER.systemBranchName")}</p>
              </div>

              <p className="mt-2">
                <input type="text" style={{width: "280px", height: "50px", verticalAlign: "-2px"}} placeholder="회원 카드번호 입력" name="cardNum" id="cardNum" className="enter-cardnum"
                       onKeyDown={(e) => {
                         if (e.keyCode == 13) {
                           if (!$('#cardNum')?.val()?.trim()) return;
                           if ($('#cardNum').val().length < 6) return;

                           setCardNum($('#cardNum').val());
                           $('#cardNum').val('').focus();
                         }
                       }}
                />
                <button type="button" className="btn btn-point" style={{width: "80px", height: "50px"}} onClick={() => {
                  if (!$('#cardNum')?.val()?.trim()) return;
                  if ($('#cardNum').val().length < 6) return;

                  setCardNum($('#cardNum').val());
                  $('#cardNum').val('').focus();
                }}>
                  <span className="h6 bold700">검색</span>
                </button>
              </p>
            </div>

            <div className="_intro_box" style={{width: "1140px", minHeight: "500px"}}>
              <div className="d-flex pt-5 px-5 _intro_box_in">
                <div className="d-flex" style={{flex: 2, textAlign: "left"}}>
                  <p className="_intro_usr_thum"><img src={introThum}/></p>
                  <p className="px-3 py-3 text-white" style={{marginTop: "-22px"}}>
                    <span className="h1 bold700">{maskName(result?.mb_name)}님</span><br/>
                    <span className="h4 bold700">{result?.member_grade === 'F' ? '피트니스' : result?.member_grade === 'P' ? '프로' : result?.member_grade === 'G' ? '짐' : result?.member_grade === 'S' ? '패스트' : ''}</span>
                  </p>
                </div>
                <p className="mx-3 px-4 h6 bold800 my-4 _intro_box2">출석률&nbsp;&nbsp;&nbsp;<span className="h6 bold800 text-pink">{result?.per2 > 100 ? 100 : 0} %</span></p>
                <p className="px-4 h6 bold800 my-4 _intro_box2">포인트&nbsp;&nbsp;&nbsp;<span className="h6 bold800 text-pink">{Fn.numberWithCommas(result?.mb_point || 0)}P</span></p>
              </div>

              <div className="d-flex justify-content-between px-5 pt-4 mt-2">
                <p className="h5 bold100 mb-0 px-1 text-white">이번달 <span className="h5 bold800 text-pink">{isNaN(result?.ct + result?.ctTar) ? "" : (result?.ct + result?.ctTar)}</span>번째 출석</p>
                <p className="h2 bold800 mb-0 px-1 text-gray2" style={{marginTop: "-15px"}}>
                  {result?.d_day < 0 ? "D+" : "D-"}
                  {result?.d_day < 0 ?
                    <span className="h2 bold800 text-pink">{Math.abs(result?.d_day || 0)}</span>
                    :
                    <span className="h2 bold800 text-pink">{result?.d_day || 0}</span>
                  }
                </p>
              </div>

              <div className="mx-5 my-3 _intro_progress_bg">
                {/*<p className="_intro_progress_point"></p>*/}
                <div className="_intro_progress_bar" style={{width: (result?.per > 100) ? 100 : result?.per}}></div>
              </div>

              <div className="d-flex justify-content-between px-5 ">
                <p className="px-1 text-gray" style={{fontSize: "18px", fontWeight: "bold", letterSpacing: "-1px"}}>출석 : {result?.branch_name} {result?.ct}회 / 타지점 {result?.ctTar}회</p>
                <p className="px-1 text-gray" style={{fontSize: "18px", fontWeight: "bold", letterSpacing: "-1px"}}>회원기간 만료 : {result?.end_date?.split("-")?.[0]}년 {result?.end_date?.split("-")?.[1]}월 {result?.end_date?.split("-")?.[2]}일</p>
              </div>

              <div className="d-flex mt-4 px-5" style={{gap: "20px"}}>
                <div className={`d-flex justify-content-between ${result?.suit_end_date >= Fn.getCurrentDate() ? '_intro_box3_on' : '_intro_box3_off'}`}>
                  <div className="m-4 px-2"><img src={result?.suit_end_date >= Fn.getCurrentDate() ? introIcon01 : introIcon01off} style={{width: "48px"}}/></div>
                  <div className="my-3 mx-2" style={{textAlign: "right"}}>
                    <p className="px-4 h6 bold800 text-white">운동복</p>
                    <p className={`m-0 px-4 h4 bold800 ${result?.suit_end_date >= Fn.getCurrentDate() ? "text-pink" : "text-gray2"}`}>{result?.suit_end_date || "사용안함"}</p>
                  </div>
                </div>
                <div className={`d-flex justify-content-between ${result?.locker_end_date >= Fn.getCurrentDate() ? '_intro_box3_on' : '_intro_box3_off'}`}>
                  <div className="m-4 px-2"><img src={result?.locker_end_date >= Fn.getCurrentDate() ? introIcon02 : introIcon02off} style={{width: "48px"}}/></div>
                  <div className="my-3 mx-2" style={{textAlign: "right"}}>
                    <p className="px-4 h6 bold800 text-white">라커</p>
                    <p className={`m-0 px-4 h4 bold800 ${result?.locker_end_date >= Fn.getCurrentDate() ? "text-pink" : "text-gray2"}`}>{result?.locker_end_date || "사용안함"}</p>
                  </div>
                </div>
                <div className={`d-flex justify-content-between ${result?.pt_remain > 0 ? '_intro_box3_on' : '_intro_box3_off'}`}>
                  <div className="m-4 px-2"><img src={result?.pt_remain > 0 ? introIcon03 : introIcon03off} style={{width: "35px"}}/></div>
                  <div className="my-3 mx-2" style={{textAlign: "right"}}>
                    <p className="pr-4 h6 bold800 text-white">PT</p>
                    <p className="m-0 pr-4 h4 bold800 text-gray2">{result?.pt_remain || 0}회</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex mt-2" style={{gap: "10px"}}>
              <div className="_intro_box4">
                <p className="text-gray2 text-left intro_in_head_title">이용정보</p>
                <div className="d-flex justify-content-between">
                  <p className="py-2 h7 bold700 text-white">가입일</p>
                  <p className="p-2 h6 bold800 text-pink">{result?.mb_open_date?.substring(0, 10)?.replace(/-/g, ".")}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="py-2 h7 bold700 text-white">시작일</p>
                  <p className="p-2 h6 bold800 text-pink">{result?.start_date?.substring(0, 10)?.replace(/-/g, ".")}</p>
                </div>
                <div className="d-flex justify-content-between">
                  <p className="py-2 h7 bold700 text-white">종료일</p>
                  <p className="p-2 h6 bold800 text-pink">{result?.end_date?.substring(0, 10)?.replace(/-/g, ".")}</p>
                </div>
              </div>

              <div className="_intro_box4">
                <p className="text-gray2 text-left intro_in_head_title">서비스내역</p>

                {result?.app_list_qry?.map((item, index) =>
                  <div className="d-flex justify-content-between">
                    <p className="py-1 h7 bold700 text-white">{item?.ap_date?.substring(0, 10)?.replace(/-/g, ".")}</p>
                    <p className="p-1 h7 bold700 text-white">{item?.ap_pdtName || (item?.it_name || '')}</p>
                  </div>
                )}
              </div>

              <div className="_intro_box4">
                <p className="text-gray2 text-left intro_in_head_title">출석내역</p>

                {result?.l?.map((item, index) =>
                  <div className="d-flex justify-content-between">
                    <p className="py-1 h7 bold700 text-white">{item?.ba_date?.replace(/-/g, ".") + " " + item?.ba_time}</p>
                    <p className="p-1 h7 bold700 text-white">{item?.ba_gr_name}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pop_background" style={{zIndex: 1000000000, display: "none"}}>
            <div className="pop_box01 p_absolute_mid_pop_err text-center" style={{width: "500px", minHeight: "170px"}}>
              <h2 className="bold800" id="err_title"></h2>
              <h6 className="enter-modal-font bold500" id="err_cont" style={{marginTop: "20px"}}></h6>
            </div>
          </div>

        </div>

        {/*<div className="modal_wrap pop_background" style={{display: "none"}}>*/}
        {/*  <div className="modal_inner modal_inner2">*/}
        {/*    <h2 id="err_title" style={{fontWeight: "700"}}></h2>*/}
        {/*    <h7 id="err_cont" style={{fontWeight: "500", marginTop: "15px"}}></h7>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div style={{visibility: "hidden", position: "absolute", top: "-9999px"}}>
          {audioFile &&
            <AudioPlayer
              autoPlay
              src={audioFile}
              onPlay={e => console.log("onPlay")}
            />
          }
        </div>
      </div>
    );
  }
};

export default Main;
