import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import Fn from "../../libraries/Fn";
import $ from "jquery";
import JDatePicker from "../common/JDatePicker";

const MemberDelayListAll = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#e_date').val(Fn.getCurrentDate());
    $('#s_date').val(monthsAgo);
  }, []);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  //TODO 날짜 데이터 임시로 추가
  const load = () => {
    axios2
      .post(`/member/delay/list/all/ready?page=${currPage}&date_type=${$('#date_type option:selected').val() || ''}&s_date=${$('#s_date').val()}&e_date=${$('#e_date').val()}&s_delay_type=${$('#s_delay_type option:selected').val() || ''}&key=${$('#key').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        alert('데이터오류! 시스템 관리자에게 문의하세요.');
      });
  }

  //  3-1.
  const make_monitor = () => {
    const as = window.open('/inc/black.html', 'enter_monitor', 'width=0,height=0,scrollbars=yes,top=10000,left=10000');
    as.window.close();
    const as2 = window.open('/enter/enter_monitor.html', 'enter_monitor', 'width=500,height=500,scrollbars=yes,top=600,left=1000');

    return () => {
      as2.close();
    };
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="date_type">
            <option value="de_regDate" selected>신청일</option>
            <option value="de_sdate">시작일</option>
            <option value="de_eDate">종료일</option>
          </select>
        </div>
        <div className="pr-1 d-flex">
          <div className="input-group" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="e_date"/>
          </div>
        </div>
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_delay_type">
            <option value="">연기구분</option>
            <option value="A">자동(휴회기간)</option>
            <option value="S">회원신청</option>
          </select>
        </div>
        <div className="pr-1">
          <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="이름/핸드폰 검색" id="key"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>
            검색
          </button>
        </div>
      </div>

      <div className="m-3 table_list">
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="25%"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>회원명</th>
            <th>회원번호</th>
            <th>연기구분</th>
            <th>신청일</th>
            <th>시작일</th>
            <th>종료일</th>
            <th>연기기간</th>
            <th>이전종료일</th>
            <th>비고</th>
          </tr>
          {rets && rets.map((ret, i) => {

            let delay_type_show = "";
            if (ret?.delay_type == "A") {
              delay_type_show = "자동(휴회기간)";
            } else if (ret?.delay_type == "S") {
              delay_type_show = "회원신청";
            } else {
              delay_type_show = "";
            }

            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>{ret?.mb_name || ''}</td>
                <td>{ret?.mb_id || ''}</td>
                <td>{delay_type_show || ''}</td>
                <td>{(ret?.de_regDate || '').replace(/-/g, '.').substr(2, 8)}</td>
                <td>{(ret?.de_sDate || '').replace(/-/g, '.').substr(2, 8)}</td>
                <td>{(ret?.de_eDate || '').replace(/-/g, '.').substr(2, 8)}</td>
                <td>{ret?.de_period || ''}</td>
                <td>{(ret?.de_eDateOld || '').replace(/-/g, '.').substr(2, 8)}</td>
                <td>{ret?.de_etc || ''}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={10}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 my-4 d-flex justify-content-center border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
      </nav>


    </CmnCard>
  );
};

export default MemberDelayListAll;
