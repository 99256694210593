import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios from "axios";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import Constants from "../../constants/constants";

const BranchopInoutCal = (props) => {

  window._load_cal = (_page = 1) => {
    setSystemBranchCode(localStorage.getItem("USER.systemBranchCode"));
    if (localStorage.getItem("USER.systemBranchCode") !== "central") {
      setTimeout(() => {
        $('#aa').trigger("click");
        onTab('1');
        load();
      }, 500);
    } else {
      $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
      $('#s_month').val(Fn.getCurrentDate().split("-")[1]);
      load();
    }
  }

  const [tabType, setTabType] = useState('1');
  const [srchRenew, setSrchRenew] = useState(0);
  const [systemBranchCode, setSystemBranchCode] = useState(localStorage.getItem("USER.systemBranchCode"));

  const onTab = (type) => {
    setTabType(type);
    load(type);

    setTimeout(() => {
      if (type === '1') {
        $('#aa').prop('checked', true);
        $('#bb').prop('checked', false);
      } else {
        $('#aa').prop('checked', false);
        $('#bb').prop('checked', true);
      }
    }, 150);
  }

  useEffect(() => {
    if (localStorage.getItem("USER.systemBranchCode") === "central") {
      $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
      $('#s_month').val(Fn.getCurrentDate().split("-")[1]);
    }
  }, []);

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#s_date1').val(`${year}-${month}-01`);
    $('#s_date2').val(today);
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(tabType);
  }, [srchRenew]);

  useEffect(() => {
    load('1');
  }, []);

  const downloadExcel = () => {
    let url;
    if (localStorage.getItem("USER.systemBranchCode") === "central") {
      url = `/xls/branchop/inout/cal/central?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`;
    } else {
      url = `/xls/branchop/inout/cal?s_gubun=${$('#s_gubun option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`;
      if (tabType != "1") url = `/xls/branchop/not/inout/cal?s_gubun=${$('#s_gubun option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`;
    }
    if (url) {
      Fn.download(Constants.API_URL + url);
    }
  }

  const load = (type) => {
    let url = '';

    if (localStorage.getItem("USER.systemBranchCode") === "central") {
      url = `/branchop/inout/cal/central?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`;
    } else {
      url = `/branchop/inout/cal?s_gubun=${$('#s_gubun option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`;
      if (type != "1") url = `/branchop/not/inout/cal?s_gubun=${$('#s_gubun option:selected').val() || ''}&ddate=${$('#ddate option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`;
    }

    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if (localStorage.getItem("USER.systemBranchCode") === "central") {
            let tot_rr_price = 0;
            let tot_ss_price = 0;
            let tot_jj_price = 0;
            let cont = '';

            $('.cls-data').remove();

            result?.data.forEach((data_row, index) => {
              const count = index + 1;
              const branch_name = data_row.branch_name;

              tot_rr_price += Fn.parseInt(data_row?.receive_m);
              tot_ss_price += Fn.parseInt(data_row?.send_m);
              tot_jj_price += Fn.parseInt(data_row?.cha_m);

              cont += `
                <tr class="cls-data">
                  <td>${count}</td>
                  <td>${branch_name}</td>
                  <td class='text-right'>${data_row?.rr_price}</td>
                  <td class='text-right'>${data_row?.ss_price}</td>
                  <td class='text-right'>${data_row?.jj_price}</td>
                </tr>
              `;
            });

            cont += `
              <tr class="cls-data">
                <td colspan='2' style='background:#fcfff2'><strong>합 계</strong></td>
                <td style='background:#fcfff2' class='text-right'><strong>${Fn.numberWithCommas(tot_rr_price)}</strong></td>
                <td style='background:#fcfff2' class='text-right'><strong>${Fn.numberWithCommas(tot_ss_price)}</strong></td>
                <td style='background:#fcfff2' class='text-right'><strong>${Fn.numberWithCommas(tot_jj_price)}</strong></td>
              </tr>
            `;

            $('#header_tr').after(cont);
          } else {
            let tot_cnts = 0;
            let tot_price = 0;
            let cont = '';

            $('.cls-data').remove();

            if (url.includes("/not/")) {
              result?.data.forEach((data_row, index) => {
                const count = index + 1;
                const branch_name = data_row.branch_name;

                const cnts = Number(data_row.cnts).toLocaleString();
                const price = data_row?.usePrice * data_row.cnts;
                const new_price = price.toLocaleString();

                tot_cnts += Number(cnts);
                tot_price += price;

                cont += `
                  <tr class="cls-data">
                    <td>${count}</td>
                    <td>${branch_name}</td>
                    <td class='text-right'>${cnts}</td>
                    <td class='text-right'>${new_price}</td>
                  </tr>
                `;
              });
            } else {
              result?.data.forEach((data_row, index) => {
                const count = index + 1;
                const branch_name = data_row.branch_name;

                const cnts = Number(data_row.cnts).toLocaleString();
                const price = result?.usePrice * data_row.cnts;
                const new_price = price.toLocaleString();

                tot_cnts += Number(cnts);
                tot_price += price;

                cont += `
                  <tr class="cls-data">
                    <td>${count}</td>
                    <td>${branch_name}</td>
                    <td class='text-right'>${cnts}</td>
                    <td class='text-right'>${new_price}</td>
                  </tr>
                `;
              });
            }

            cont += `
              <tr class="cls-data">
                <td colspan='2' style='background:#fcfff2'><strong>합 계</strong></td>
                <td style='background:#fcfff2' class='text-right'><strong>${tot_cnts.toLocaleString()}</strong></td>
                <td style='background:#fcfff2' class='text-right'><strong>${tot_price.toLocaleString()}</strong></td>
              </tr>
            `;

            $('#header_tr').after(cont);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  2-1.
  function showData() {
    $(".loading_bg").fadeIn("fast");

    const form = $('form')[0];
    const formData = new FormData(form);
    axios({
      url: '/branchop/inout/cal/central',
      method: 'post',
      data: formData,
      processData: false,
      contentType: false
    })
      .then(response => {
        let data = response.data.trim();

        //cal_info.innerHTML = data;
        //alert(data);

        $("#cal_info").html(data);
        $(".loading_bg").fadeOut();
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  3-1.
  function notShowData() {
    const pub = document.getElementById('pub');
    pub.style.display = 'inline';
    $(".loading_bg").fadeIn("fast");

    const form = $('form')[0];
    const formData = new FormData(form);
    axios({
      url: '/branchop/not/inout/cal/central',
      method: 'POST',
      data: formData,
      processData: false,
      contentType: false
    })
      .then(response => {
        let data = response.data.trim();
        //cal_info.innerHTML = data;
        $("#not_cal_info").html(data);
        $(".loading_bg").fadeOut();
        //alert(data);
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  4-1.
  const sForm = document.getElementById('s_form');

  const goSearch = () => {
    if (sForm.target.elements.s_date1.value === "") {
      alert("입장 시작일을 선택해주세요");
      return;
    }
    if (sForm.target.elements.s_date2.value === "") {
      alert("입장 종료일을 선택해주세요");
      return;
    }
    sForm.target.submit();
  };

  //  7-1.
  const showDataMake = () => {
    if (window.confirm("해당월 데이터를 생성하시겠습니까?")) {
      axios2
        .post(`/branchop/inout/make/central?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            load();
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  //  8-1.
  const pubData = () => {
    const pubDate = $("#pub_date").val();

    if (!pubDate) {
      window.alert("발행일을 입력해주세요");
      return;
    }

    window.location.href = `/branchop/excel/inout/publish?s_date1=2023-06-01&s_date2=2023-06-29&s_gubun=x&s_year=2023&s_month=06&pub_date=${pubDate}`;
  };

  const inoutPub = () => {
    if (!$('#pub_date').val()) {
      alert('발행일을 입력해 주세요.');
      return;
    }
    const url = Constants.API_URL + `/branchop/excel/inout/publish?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_gubun=${$('#s_gubun option:selected').val()}&pub_date=${$('#pub_date').val()}`;
    Fn.download(url);
  }

  if (systemBranchCode !== "central") {
    return (
      <CmnCard onLogout={props.onLogout}>
        <div className="wid_MAX" style={{maxWidth: "700px"}}>
          <div className="d-flex m-3">
            <div className="pr-1">
              <select className="form-select" id="s_gubun">
                <option value="x">정상입장</option>
                <option value="1">비회원</option>
                <option value="2">이용기간만료</option>
                <option value="3">휴회기간</option>
                <option value="4">미납회원</option>
                <option value="5">중복입장</option>
                <option value="6">1일2회입장</option>
                <option value="xx">정상입장제외</option>
              </select>
            </div>
            <div className="pr-1">
              <select className="form-select" id="ddate" onChange={(e) => Fn.period(e.target.value)}>
                <option value="0">검색기간</option>
                <option value="1">오늘</option>
                <option value="2">이번달</option>
                <option value="3">전월</option>
              </select>
            </div>
            <div className="d-flex pr-1">
              <JDatePicker className="form-control" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" id="s_date2"/>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
            <p className="p-1">(최대90일)</p>
          </div>

          <div className="m-3 p_rative" style={{clear: 'both'}}>
            {tabType === '2' &&
              <div className="d-flex" style={{position: 'absolute', right: 0}}>
                <div className="pr-1">
                  <JDatePicker className="form-control" id="pub_date" name="pub_date"/>
                </div>
                <div className="pr-1">
                  <button type="button" className="btn btn-sm btn-dark" onClick={() => inoutPub()}>계산서 발행</button>
                </div>
              </div>
            }
            <div className="tabs" style={{minHeight: "40px"}}>
              <input id="aa" type="radio" name="tab_item" defaultChecked/>
              <label className="tab_item" htmlFor="aa" onClick={() => onTab('1')}>정산내역(받을돈)</label>
              <input id="bb" type="radio" name="tab_item"/>
              <label className="tab_item" htmlFor="bb" onClick={() => onTab('2')}>타지점 정산내역(보낼돈)</label>
            </div>
          </div>

          <div className="m-3 pb-2">
            <table className="view-rounded sub_table_border auto">
              <colgroup>
                <col width="65px"/>
                <col width="*"/>
                <col width="100px"/>
                <col width="140px"/>
              </colgroup>
              <tr id="header_tr">
                <th className="w_70px">No</th>
                <th>지점명</th>
                <th>횟수</th>
                <th>금액</th>
              </tr>
            </table>
          </div>

          <div className="mt-2 mb-4">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => downloadExcel()}>정산내역 엑셀저장</button>
          </div>

        </div>
      </CmnCard>
    );
  } else {
    return (
      <CmnCard onLogout={props.onLogout}>
        <div className="wid_MAX" style={{maxWidth: "700px"}}>
          <div className="d-flex m-3">
            <div className="pr-1">
              <select className="form-select" id="s_year">
                {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
            <div className="pr-1">
              <select className="form-select" style={{minWidth: '80px'}} id="s_month">
                {[...Array(12)].map((_, index) => {
                  const monthNumber = index + 1;
                  const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                  return (
                    <option key={monthString} value={monthString}>{monthString}</option>
                  );
                })}
              </select>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-dark" onClick={() => load()}>검색</button>
            </div>
            <div className="pr-1">
              <button type="button" className="btn btn-sm btn-point" onClick={() => showDataMake()}>데이터 생성</button>
            </div>
          </div>

          <div className="m-3 pb-2">
            <table className="view-rounded sub_table_border auto">
              <colgroup>
                <col width="65px"/>
                <col width="*"/>
                <col width="100px"/>
                <col width="100px"/>
                <col width="100px"/>
              </colgroup>
              <tr id="header_tr">
                <th className="w_70px">No</th>
                <th>지점명</th>
                <th>받을돈</th>
                <th>보낼돈</th>
                <th>차액</th>
              </tr>
            </table>
          </div>

          <div className="mt-2 mb-4">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => downloadExcel()}>정산내역 엑셀저장</button>
          </div>

        </div>
      </CmnCard>
    );
  }
};

export default BranchopInoutCal;
