import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";


const FinanceFeeBranchOverPrice = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [result, setResult] = useState({});

  useEffect(() => {
    $('#base_seq').val('');
    $('#s_price').val('');
    $('#e_price').val('');
    $('#jum_price').val('');
    $('#jibu_price').val('');
  }, [renew]);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/finance/branch/overprice/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit(type) {
    if ($('#s_price').val() == "") {
      alert("시작금액을 입력해주세요");
      $('#s_price').focus();
      return;
    }
    if ($('#e_price').val() == "") {
      alert("종료금액을 입력해주세요");
      $('#e_price').focus();
      return;
    }
    if ($('#jum_price').val() == "") {
      alert("지점장 초과수당을 입력해주세요");
      $('#jum_price').focus();
      return;
    }
    if ($('#jibu_price').val() == "") {
      alert("지부장 초과수당을 입력해주세요");
      $('#e_price').focus();
      return;
    }

    axios2
      .postEx('/finance/branch/overprice', {
        base_seq: type !== 'register' ? ($('#base_seq').val() || '') : '',
        s_price: Fn.cfNumeric($('#s_price').val()),
        e_price: Fn.cfNumeric($('#e_price').val()),
        jum_price: Fn.cfNumeric($('#jum_price').val()),
        jibu_price: Fn.cfNumeric($('#jibu_price').val())
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "insert_ok") {
          alert("등록되었습니다");
          $('#base_seq').val('');
          $('.cls-submit').text('추가');
          setRenew(Fn.getRenewTime());
        } else if (data == "update_ok") {
          alert("수정되었습니다");
          $('#base_seq').val('');
          $('.cls-submit').text('추가');
          setRenew(Fn.getRenewTime());
        } else if (data == "del_ok") {
          alert("삭제되었습니다");
          $('#base_seq').val('');
          $('.cls-submit').text('추가');
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const copy_data = (baseSeq, sPrice, ePrice, jumPrice, jibuPrice) => {
    $('#base_seq').val(baseSeq);
    $('#s_price').val(Fn.numberWithCommas(sPrice));
    $('#e_price').val(Fn.numberWithCommas(ePrice));
    $('#jum_price').val(Fn.numberWithCommas(jumPrice));
    $('#jibu_price').val(Fn.numberWithCommas(jibuPrice));
  };

  const del_data = (base_seq) => {
    axios2
      .postEx('/finance/branch/overprice', {
        type: 'del',
        base_seq: base_seq,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        alert("삭제되었습니다");
        $('#base_seq').val('');
        $('.cls-submit').text('추가');
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <input type="hidden" id="base_seq" value=""/>
      <div className="overflowX m-3">
        <div className="table_wide_sh3 mb-3">
          <div className="tabs">
            <input id="aa" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="aa" onClick={() => history.push("/finance/fee/list/false")}>수수료 내역보고</label>

            <input id="bb" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="bb" onClick={() => history.push("/finance/fee/public")}>퍼블릭 부서</label>

            <input id="cc" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="cc" onClick={() => history.push("/finance/fee/pt")}>PT 부서</label>

            <input id="dd" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="dd" onClick={() => history.push("/finance/fee/add")}>추가/기타</label>

            <input id="ee" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="ee" onClick={() => history.push("/finance/fee/incen")}>인센티브</label>

            <input id="ff" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="ff" onClick={() => history.push("/finance/fee/incen/pt")}>PT 수당</label>

            <input id="gg" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="gg" onClick={() => history.push("/finance/fee/gx")}>GX 수당</label>

            <input id="hh" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="hh" onClick={() => history.push("/finance/fee/consult")}>영업컨설팅비</label>

            <input id="ii" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="ii" onClick={() => history.push("/finance/fee/step")}>직원별 수수료</label>

            <input id="jj" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="jj" onClick={() => history.push("/finance/fee/overtime/list")}>추가근무관리</label>

            <input id="kk" type="radio" name="tab_item"/>
            <label className="tab_item" htmlFor="kk" onClick={() => history.push("/finance/fee/etc")}>기타수당</label>

            <input id="ll" type="radio" name="tab_item" checked/>
            <label className="tab_item" htmlFor="ll" onClick={() => history.push("/finance/fee/branch/overprice")}>초과수당설정</label>
          </div>
        </div>

        <div className="table_wide_sh3 no-min-width mb-4" style={{minWidth: "835px", maxWidth: "835px"}}>
          <div className="p-3 my-3" style={{border: '1px solid #eee', borderRadius: '12px'}}>
            <table className="view-rounded sub_table_border text-center auto">
              <tr>
                <th>금액</th>
                <td>
                  <div className="input-group">
                    <input type="text" className="form-control table-inline cls-type-fmtnum-ex" style={{maxWidth: "100px"}} id="s_price"/>
                    <p className="m-1 bold700">이상</p>
                    &nbsp;&nbsp;
                    <input type="text" className="form-control table-inline cls-type-fmtnum-ex" style={{maxWidth: "100px"}} id="e_price"/>
                    <p className="m-1 bold700">미만</p>
                  </div>
                </td>
                <th>점장수당</th>
                <td><input type="text" className="form-control cls-type-fmtnum-ex" id="jum_price" style={{maxWidth: "100px"}}/></td>
                <th>지부장수당</th>
                <td><input type="text" className="form-control cls-type-fmtnum-ex" id="jibu_price" style={{maxWidth: "100px"}}/></td>
              </tr>
            </table>
            <div className="my-3">
              <button type="button" className="btn btn-sm btn-point px-3 cls-submit" onClick={() => {
                go_submit();
              }}>추가
              </button>
            </div>
          </div>

          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="65px"/>
              <col width="*"/>
              <col width="145px"/>
              <col width="145px"/>
              <col width="155px"/>
            </colgroup>
            <tr>
              <th className="w_70px">No</th>
              <th>금액</th>
              <th>점장 초과수당</th>
              <th>지부장 초과수당</th>
              <th className="w-50">비고</th>
            </tr>

            {result?.list?.map((list_row, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <div align="left" style={{paddingLeft: "10px"}}>
                    <strong>
                      {new Intl.NumberFormat().format(list_row.s_price)} 이상{" "}
                      {new Intl.NumberFormat().format(list_row.e_price)} 미만
                    </strong>
                  </div>
                </td>
                <td>
                  <div align="right" style={{paddingRight: "10px"}}>
                    <strong>{new Intl.NumberFormat().format(list_row.jum_price)}</strong>
                  </div>
                </td>
                <td>
                  <div align="right" style={{paddingRight: "10px"}}>
                    <strong>{new Intl.NumberFormat().format(list_row.jibu_price)}</strong>
                  </div>
                </td>

                <td>
                  <button type="button" className="btn btn-ssm btn-dark"
                          onClick={() => {
                            $('.cls-submit').text('수정');
                            copy_data(
                              btoa(list_row?.seq),
                              list_row?.s_price,
                              list_row?.e_price,
                              list_row?.jum_price,
                              list_row?.jibu_price
                            );
                          }}
                  >수정
                  </button>
                  <button type="button" className="btn btn-ssm btn-gray mx-1" onClick={() => del_data(btoa(list_row?.seq))}>삭제</button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default FinanceFeeBranchOverPrice;
