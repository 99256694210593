import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Constants from "../../constants/constants";
import Fn from "../../libraries/Fn";


const EmployeePTtraninerReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();
  const location = useLocation();

  const [baseSeq, setBaseSeq] = useState(location?.state?.baseSeq || '');
  const [result, setResult] = useState();
  const [renew, setRenew] = useState(0);

  const [infoform, setInfoform] = useState();

  useEffect(() => {
    setInfoform($("form[name='infoform']")[0]);
  }, []);

  useEffect(() => {
    load()
  }, [renew]);

  const load = () => {
    axios2
      .post(`/employee/pt/trainer/reg/ready?base_seq=${baseSeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          setResult(result);

          $('#hp1').val(result?.dataRow?.hp?.split("-")?.[0]);
          $('#hp2').val(result?.dataRow?.hp?.split("-")?.[1]);
          $('#hp3').val(result?.dataRow?.hp?.split("-")?.[2]);
          $('#work_type').val(result?.dataRow?.work_type);
          $('#trainer_level').val(result?.dataRow?.trainer_level);

          $('#declarem').val(result?.emRow?.declarem);
          $('#career').val(result?.emRow?.career);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function ajaxFileUpload() {
    // 업로드 버튼이 클릭되면 파일 찾기 창을 띄운다.
    $("#userfile").click();
  }

  function ajaxFileChange() {
    // 파일이 선택되면 업로드를 진행한다.
    ajaxFileTransmit();
  }

  function ajaxFileTransmit() {
    var form = $('#infoform')[0];
    var formData = new FormData(form);
    $.ajax({
      url: '/employee/step/img/tempsave',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        console.log('data', data);
        // TODO : PTODO
        // data = data.trim();
        // $('#b_pic').append(data);
        $('#b_pic').append(`
            <div style="display: flex; flex-direction: column; justify-content: center; align-content: center; align-items: center;" id="${data?.result?.div_id}">
              <img class="IMG_thum m-1" src=${Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=step_pr_img_tempsave&filename=" + encodeURIComponent(data?.result?.brand_img))} alt="spoAny" style="object-fit: cover"/>
              <button type="button" class="temp-img-del-btn btn btn-ssm btn-outline-dark" style="max-width: 70px" onclick="window.temp_img_del(atob(baseSeq), '${data?.result?.last_row_seq}', '${data?.result?.div_id}');">삭제</button>
            </div>
        `);
      }
    });
  }

  //  5-1.
  window.temp_img_del = (seq, temp_seq, d_id) => {
    $('#' + d_id).remove();
    //템프DB 삭제
    $.ajax({
      type: "post",
      url: "/employee/step/img/tempsave/del",
      data: "seq=" + seq + "&temp_seq=" + temp_seq,
      success: function (data) {
        //alert(data); return;
      }
    });
  }

  //  6-1.
  function go_submit() {
    var form = $('form')[0];
    var formData = new FormData(form);
    $.ajax({
      url: '/employee/pt/trainer/reg',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        console.log(data);
        const {code, message, result, pageInfo} = data;
        if (message == "not_img_auth") {
          alert('사진파일은 gif,jpg,png 만 가능합니다');
        } else {
          alert("저장되었습니다");
          // TODO : PTODO -> 개선필요
          // setRenew(Fn.getRenewTime());
          window.location.reload();
        }
      }
    });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <form name="infoform" id="infoform" enctype='multipart/form-data'>
        <input type="hidden" name="base_seq" value={baseSeq || ''}/>
        <input type="hidden" name="seq" value={baseSeq ? atob(baseSeq) : ""}/>
        <input type="file" id="userfile" name="userfile" onChange={() => ajaxFileChange()} style={{display: "none"}} accept=".jpg,.jpeg"/>
        <div className="m-3 search_box" style={{maxWidth: "950px"}}>
          <div className="border-bottom text-left">
            <p className="px-3 py-2">
              <span className="h6 bold700">트레이너 등록/수정</span>
            </p>
          </div>
          {/* 컨텐츠 내용 시작 */}
          <div className="p-3">
            <div className="d-flex justify-content-between">
              <div>
                <div className="USER_thum_white">
                  {result?.dataRow?.intro_pic &&
                    <img src={Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=step_pr_img&filename=" + encodeURIComponent(result?.dataRow?.intro_pic))} style={{objectFit: "cover", borderRadius: 8, width: "192px", height: "192px"}}
                         className="w-100"/>}
                </div>
                <p className="mt-1">500*500 이상, 1Mbyte 이내</p>
              </div>
              <div style={{width: 'calc(100% - 210px)'}}>
                <table className="view-rounded sub_view">
                  <colgroup>
                    <col width="108px"/>
                    <col width="*"/>
                    <col width="108px"/>
                    <col width="*"/>
                  </colgroup>
                  <tr>
                    <th>이름</th>
                    <td className="text-left">{result?.dataRow?.user_name || ''}</td>
                    <th>연락처</th>
                    <td>
                      <div className="input-group">
                        <input type="text" className="form-control" aria-describedby="button-addon2" style={{maxWidth: "50px"}} name="hp1" id="hp1"/>
                        <span className="mx-1"></span>
                        <input type="text" className="form-control" aria-describedby="button-addon2" style={{maxWidth: "50px"}} name="hp2" id="hp2"/>
                        <span className="mx-1"></span>
                        <input type="text" className="form-control" aria-describedby="button-addon2" style={{maxWidth: "50px"}} name="hp3" id="hp3"/>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>직무구분</th>
                    <td className="text-left">{result?.dataRow?.position_name || ''}</td>
                    <th>근무형태</th>
                    <td>
                      <input type="text" className="form-control" aria-describedby="button-addon2" name="work_type" id="work_type"/>
                    </td>
                  </tr>
                  <tr>
                    <th>부서</th>
                    <td className="text-left">{result?.dataRow?.part_name || ''}</td>
                    <th>직급</th>
                    <td className="text-left">{result?.dataRow?.new_position_name || ''}</td>
                  </tr>
                  <tr>
                    <th>트레이너레벨</th>
                    <td>
                      <select className="form-select" aria-label="Default select example" style={{width: '32%'}} name="trainer_level" id="trainer_level">
                        <option value="">선택</option>
                        <option value="1">1레벨</option>
                        <option value="2">2레벨</option>
                        <option value="3">3레벨</option>
                      </select>
                    </td>
                    <th>사진등록</th>
                    <td>
                      <div className="input-group inline">
                        <input type="file" className="form-control" aria-describedby="intro_pic" aria-label="Upload" id="intro_pic" name="intro_pic"/>
                        <button className="btn btn-ssm btn-outline-point" type="button" onClick={() => $('#intro_pic').trigger("click")}>파일등록</button>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className="m-3" style={{border: '1px solid #eee', borderRadius: '12px', maxWidth: "950px"}}>
          <div className="border-bottom text-left">
            <p className="px-3 py-2">
              <span className="h6 bold700">사명선언문</span>
            </p>
          </div>
          {/* 컨텐츠 내용 시작 */}
          <div className="p-3">
            <textarea className="txtarea" style={{minHeight: '150px'}} name="declarem" id="declarem"></textarea>
          </div>
        </div>

        <div className="m-3" style={{border: '1px solid #eee', borderRadius: '12px', maxWidth: "950px"}}>
          <div className="border-bottom text-left">
            <p className="px-3 py-2">
              <span className="h6 bold700">이력/학력</span>
            </p>
          </div>
          {/* 컨텐츠 내용 시작 */}
          <div className="p-3">
            <textarea className="txtarea" style={{minHeight: '150px'}} name="career" id="career"></textarea>
          </div>
        </div>

        {result?.dataRow?.pt_YN == 'Y' &&
          <div className="m-3" style={{border: '1px solid #eee', borderRadius: '12px', maxWidth: "950px"}}>
            <div className="border-bottom text-left">
              <p className="px-3 py-2"><span className="h6 bold700">트레이너 소개사진</span></p>
            </div>

            <div className="p-3">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <td className="text-left">
                    <p className="m-1">
                      <button type="button" className="btn btn-ssm btn-dark px-3" onClick={() => $('#userfile').click()}>사진업로드</button>
                    </p>
                    <div className="d-flex flex-wrap" id="b_pic">
                      {result?.imgList?.length > 0 && result?.imgList?.map((item, index) =>
                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center"}} id={`div_${item?.seq}`}>
                          <img className="IMG_thum m-1" src={Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=step_pr_img&filename=" + encodeURIComponent(item?.img_name))} alt="spoAny" style={{objectFit: "cover"}}></img>
                          <button type="button" className="btn btn-ssm btn-outline-dark" style={{maxWidth: "70px"}} onClick={() => window.temp_img_del(atob(baseSeq), item?.seq, `div_${item?.seq}`)}>삭제</button>
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        }

        <div className="m-4 text-center" style={{maxWidth: "950px"}}>
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
          <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.goBack()}>목록</button>
        </div>
      </form>
    </CmnCard>
  );
};

export default EmployeePTtraninerReg;
