import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import {useHistory} from "react-router-dom";
import JDatePicker from "../common/JDatePicker";

// datepicker bind 외에 스크립트 없음
const AnalysisSatisSummary = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#s_date1').val(`${year}-${month}-01`);
    $('#s_date2').val(today);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/analysis/satis/summary/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&my_sort=${$('#my_sort option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="cc" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/analysis/satis/summary")}>지점별 평균별점</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/analysis/satis/list")}>회원별 평균별점</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="input-group pr-1" style={{width: 'auto'}}>
          <JDatePicker className="form-control" id="s_date1"/>
          <p className="mx-2">~</p>
          <JDatePicker className="form-control" id="s_date2"/>
        </div>
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="my_sort">
            <option value="A">지점순</option>
            <option value="B">별점높은순</option>
            <option value="C">별점낮은순</option>
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3 table_list">
        {/* 회원목록 */}
        <table className="view-rounded sub_table_border">
          <colgroup>
            <col width="80px"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>지점명</th>
            <th>평균별점</th>
          </tr>
          {rets && rets.map((ret, i) => {
            return (
              <tr>
                <td>{i + 1}</td>
                <td>{ret?.branch_name}</td>
                <td>{ret?.satis_value?.toFixed(2)}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={3}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

    </CmnCard>
  );
};

export default AnalysisSatisSummary;
