import React, {useEffect, useRef} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Constants from "../../constants/constants";


const ConfigBrandReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.baseSeq || '';

  useEffect(() => {
    $('#base_seq').val(baseSeq);
    load();
  }, []);

  const load = () => {
    if (baseSeq) {
      axios2
        .post(`/branchop/brand/reg/ready?base_seq=${baseSeq}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            console.log(result);
            $('#brand_name').val(result?.row?.brand_name);
            //$('#userfile').val(result?.row?.brand_img);
            $('#brand').val(result?.row?.brand);
            $('#in_twice').val(result?.row?.in_twice);
            $('#cal_price').val(result?.row?.cal_price);
            $('#not_oper_price').val(result?.row?.not_oper_price);
            $('#free_sale_price').val(result?.row?.free_sale_price);
            $('#apply_cnt').val(result?.row?.apply_cnt);
            $('#show_out').val(result?.row?.show_out);
            $('#show_YN').val(result?.row?.show_YN);

            if (result?.row?.brand_img)
              $('#logo_thumb').html(`<img src=${Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=member_pic&renew=" + Fn.getRenewTime() + "&filename=" + encodeURIComponent(result?.row?.brand_img))} width="200" height="70" alt="로고" style="object-fit: contain; width: 200px !important; margin-bottom: 10px;"/>`);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  const go_submit = () => {
    const brandName = $('#brand_name').val();
    const userfile = $('#userfile').val();
    const brand = $('#brand').val();
    const inTwice = $('#in_twice').val();
    const calPrice = $('#cal_price').val();
    const notOperPrice = $('#not_oper_price').val();
    const freeSalePrice = $('#free_sale_price').val();
    const applyCnt = $('#apply_cnt').val();
    const showOut = $('#show_out').val();

    if (brandName === '') {
      alert('브랜드명을 설정해주세요');
      $('#brand_name').focus();
      return;
    }

    if (userfile === '') {
      alert('브랜드이미지를 입력해주세요');
      $('#userfile').focus();
      return;
    }

    if (brand === '') {
      alert('브랜드 약어를 입력해주세요');
      $('#brand').focus();
      return;
    }

    if (inTwice === '' || inTwice === '0') {
      alert('1일2회입장 추가퍼센트를 입력해주세요');
      $('#in_twice').focus();
      return;
    }

    if (calPrice === '' || calPrice === '0') {
      alert('타지점 정산금액을 입력해주세요');
      $('#cal_price').focus();
      return;
    }

    if (notOperPrice === '' || notOperPrice === '0') {
      alert('비정상 운영 금액을 입력해주세요');
      $('#not_oper_price').focus();
      return;
    }

    if (freeSalePrice === '' || freeSalePrice === '0') {
      alert('공사/프리세일 금액을 입력해주세요');
      $('#free_sale_price').focus();
      return;
    }

    if (applyCnt === '' || applyCnt === '0') {
      alert('이용우선순위를 입력해주세요');
      $('#apply_cnt').focus();
      return;
    }

    if (showOut === '') {
      alert('전시순서를 입력해주세요');
      $('#show_out').focus();
      return;
    }

    const formData = new FormData();

    formData.append('base_seq', $('#base_seq').val() || '');
    formData.append('brand_name', $('#brand_name').val() || '');
    formData.append('userfile', $('#userfile')[0].files[0]);
    formData.append('brand', $('#brand').val() || '');
    formData.append('in_twice', $('#in_twice').val() || '');
    formData.append('cal_price', $('#cal_price').val() || '');
    formData.append('not_oper_price', $('#not_oper_price').val() || '');
    formData.append('free_sale_price', $('#free_sale_price').val() || '');
    formData.append('apply_cnt', $('#apply_cnt').val() || '');
    formData.append('show_YN', $('#show_YN option:selected').val());
    formData.append('show_out', $('#show_out').val() || '');

    axios2.postFormEx('/branchop/brand/reg', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == 'err') {
          alert('오류가 발생하였습니다');
        } else if (data == 'not_img_auth') {
          alert('확장자가 gif,jpg,png 파일만 가능합니다');
        } else if (data == 'dup_short') {
          alert('동일한 브랜드약어가 존재합니다.');
        } else if (data == 'insert_ok') {
          alert('등록되었습니다');
          history.push("/config/brand/list");
        } else if (data == 'edit_ok') {
          alert('수정되었습니다');
          history.push("/config/brand/list");
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });

    /*
    axios2
      .postEx('/branchop/brand/reg', {
        base_seq: $('#base_seq').val() || '',
        brand_name: $('#brand_name').val() || '',
        userfile: $('#userfile').val() || '',
        brand: $('#brand').val() || '',
        in_twice: $('#in_twice').val() || '',
        cal_price: $('#cal_price').val() || '',
        not_oper_price: $('#not_oper_price').val() || '',
        free_sale_price: $('#free_sale_price').val() || '',
        apply_cnt: $('#apply_cnt').val() || '',
        show_YN: $('#show_YN option:selected').val() || '',
        show_out: $('#show_out').val() || ''
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == 'err') {
          alert('오류가 발생하였습니다');
        } else if (data == 'not_img_auth') {
          alert('확장자가 gif,jpg,png 파일만 가능합니다');
        } else if (data == 'dup_short') {
          alert('동일한 브랜드약어가 존재합니다.');
        } else if (data == 'insert_ok') {
          alert('등록되었습니다');
          history.push("/config/brand/list");
        } else if (data == 'edit_ok') {
          alert('수정되었습니다');
          history.push("/config/brand/list");
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
    */
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <input type="hidden" id="base_seq"/>

      <div className="m-3 mb-4 search_box" style={{maxWidth: "935px"}}>
        <div className="border-bottom text-left">
          <p className="px-3 py-2"><span className="h6 bold700">브랜드 추가</span></p>
        </div>
        {/* 컨텐츠 내용 시작 */}
        <div className="p-3">
          <table className="view-rounded sub_view_sm">
            <colgroup>
              <col width="220px"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>브랜드명</th>
              <td><input type="text" className="form-control" style={{maxWidth: "150px"}} id="brand_name"/></td>
            </tr>
            <tr>
              <th>브랜드 이미지</th>
              <td>
                <div id="logo_thumb" style={{textAlign: "left"}}></div>
                <div className="input-group">
                  <input type="file" className="form-control" style={{maxWidth: "450px"}} id="userfile"/>
                  <p className="m-1 px-2">(385 * 131px)</p>
                </div>
              </td>
            </tr>
            <tr>
              <th>브랜드 약어</th>
              <td>
                <div className="input-group">
                  <input type="text" className="form-control table-inline" style={{maxWidth: "150px"}} id="brand" maxLength={1}/>
                  <p className="m-1 px-2">(Ex. 피트니스 : F , 짐 : G )</p>
                </div>
              </td>
            </tr>
            <tr>
              <th>1일2회입장 추가금 %</th>
              <td>
                <div className="input-group">
                  <input type="text" className="form-control table-inline" style={{maxWidth: "150px"}} id="in_twice"/>
                  <p className="m-1 bold700">%</p>
                </div>
              </td>
            </tr>
            <tr>
              <th>타지점정산금액</th>
              <td><input type="text" className="form-control" style={{maxWidth: "150px"}} id="cal_price"/></td>
            </tr>
            <tr>
              <th>비정상 운영 금액</th>
              <td><input type="text" className="form-control" style={{maxWidth: "150px"}} id="not_oper_price"/></td>
            </tr>
            <tr>
              <th>공사/프리세일 금액</th>
              <td><input type="text" className="form-control" style={{maxWidth: "150px"}} id="free_sale_price"/></td>
            </tr>
            <tr>
              <th>이용우선순위</th>
              <td>
                <div className="input-group">
                  <input type="text" className="form-control table-inline" style={{maxWidth: "150px"}} id="apply_cnt"/>
                  <p className="m-1 px-2">( 높은 숫자의 브랜드에서 낮은 숫자의 브랜드 이용가능 )</p>
                </div>
              </td>
            </tr>
            <tr>
              <th>전시여부</th>
              <td className="text-left">
                <select className="form-select-sm table-inline" style={{maxWidth: "150px"}} id="show_YN">
                  <option value="Y">전시</option>
                  <option value="N">미전시</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>전시순서</th>
              <td><input type="text" className="form-control" style={{maxWidth: "150px"}} id="show_out"/></td>
            </tr>
          </table>
          <div className="mt-2">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
            <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.goBack()}>목록</button>
          </div>
        </div>
      </div>
    </CmnCard>
  );
};

export default ConfigBrandReg;
