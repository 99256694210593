import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import $ from "jquery";


const FinanceFeeConsult = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [result, setResult] = useState({});
  const [pageInfo, setPageInfo] = useState();
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('#s_year').val(localStorage.getItem('fee_year') || Fn.getCurrentDate().split('-')[0])
    $('#s_month').val(localStorage.getItem('fee_month') || Fn.getCurrentDate().split('-')[1])
  }, []);

  useEffect(() => {
    load();
  }, [currPage, renew, srchRenew]);

  const load = () => {
    axios2
      .post(`/finance/fee/consult/ready?s_year=${$('#s_year option:selected').val()}&s_month=${$('#s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          console.log(result);
          console.log(result?.top_row?.sun_price)

          localStorage.setItem('fee_year', $('#s_year option:selected').val());
          localStorage.setItem('fee_month', $('#s_month option:selected').val());

          $('#sun_price').val(Fn.numberWithCommas(result?.top_row?.sun_price));
          $('#jum_price').val(Fn.numberWithCommas(result?.top_row?.jum_price));
          $('#add_price1').val(Fn.numberWithCommas(result?.top_row?.add_price1));
          $('#add_price2').val(Fn.numberWithCommas(result?.top_row?.add_price2));
          $('#add_price3').val(Fn.numberWithCommas(result?.top_row?.add_price3));
          $('#add_price4').val(Fn.numberWithCommas(result?.top_row?.add_price4));
          $('#add_price5').val(Fn.numberWithCommas(result?.top_row?.add_price5));
          $('#difer_price').val(Fn.numberWithCommas(result?.top_row?.difer_price));
          $('#cal_price').val(Fn.numberWithCommas(result?.top_row?.cal_price));

          if ($('#manager_seq').html() === '') {
            let optionsHTML = '<option value="">::선택</option>';
            result?.ll?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#manager_seq').html(optionsHTML);
          }

          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }


  function go_submit() {
    if (!$('#manager_seq option:selected').val()) {
      alert("스텝을 선택해주세요");
      $('#manager_seq').focus();
      return;
    }

    if (!$('#jibun_rate').val()) {
      alert("지분율을 입력해주세요");
      $('#jibun_rate').focus();
      return;
    }

    if (!$('#chagam_rate').val()) {
      alert("차감율을 입력해주세요");
      $('#chagam_rate').focus();
      return;
    }

    axios2
      .postEx(`/finance/fee/consult`, {
        s_year: $('#s_year option:selected').val(),
        s_month: $('#s_month option:selected').val(),
        base_seq: $('#base_seq').val() || '',
        manager_seq: $('#manager_seq option:selected').val(),
        jibun_rate: $('#jibun_rate').val(),
        chagam_rate: $('#chagam_rate').val(),
        bigo: $('#bigo option:selected').val(),
        type: ""
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "insert_ok") {
          alert("등록되었습니다");
          setRenew(Fn.getRenewTime());
        } else if (data == "update_ok") {
          alert("수정되었습니다");
          setRenew(Fn.getRenewTime());
        } else if (data == "delete_ok") {
          alert("삭제되었습니다");
          setRenew(Fn.getRenewTime());
        }
        $('#base_seq').val('');
        $('#manager_seq').val('');
        $('#jibun_rate').val('');
        $('#chagam_rate').val('');
        $('#bigo').val('');
        $('#add_btn2').html('추가');
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function del_consult(base_seq) {
    if (window.confirm("해당 영업컨설팅비를 삭제하시겠습니까?")) {
      axios2
        .postEx(`/finance/fee/consult`, {
          base_seq: base_seq,
          type: "del"
        })
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          alert("삭제되었습니다");
          setRenew(Fn.getRenewTime());
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  }

  function edit_consult(base_seq, sun_price, manager_seq, jibun_rate, chagam_rate, bigo) {
    $('#add_btn2').html('수정');

    $('#base_seq').val(base_seq);
    $('#manager_seq').val(manager_seq);
    $('#jibun_rate').val(jibun_rate);
    $('#chagam_rate').val(chagam_rate);
    $('#bigo').val(bigo);
  }

  function cfNumeric(sOrg) {
    let nm;
    sOrg = sOrg.replace(/,/g, "");
    nm = parseFloat(sOrg).toString();
    return (isNaN(nm) ? 0 : nm);
  }

  function commaNum(num) {
    if (num < 0) {
      num *= -1;
      var minus = true
    } else var minus = false

    var dotPos = (num + "").split(".")
    var dotU = dotPos[0]
    var dotD = dotPos[1]
    var commaFlag = dotU.length % 3

    if (commaFlag) {
      var out = dotU.substring(0, commaFlag)
      if (dotU.length > 3) out += ","
    } else var out = ""

    for (var i = commaFlag; i < dotU.length; i += 3) {
      out += dotU.substring(i, i + 3)
      if (i < dotU.length - 3) out += ","
    }

    if (minus) out = "-" + out
    if (dotD) return out + "." + dotD
    else return out
  }

  function top_cal_price() {
    let sun_price = $('#sun_price').val();
    let jum_price = $('#jum_price').val();
    let add_price1 = $('#add_price1').val();
    let add_price2 = $('#add_price2').val();
    let add_price3 = $('#add_price3').val();
    let add_price4 = $('#add_price4').val();
    let add_price5 = $('#add_price5').val();

    sun_price = cfNumeric(sun_price);
    jum_price = cfNumeric(jum_price);
    add_price1 = cfNumeric(add_price1);
    add_price2 = cfNumeric(add_price2);
    add_price3 = cfNumeric(add_price3);
    add_price4 = cfNumeric(add_price4);
    add_price5 = cfNumeric(add_price5);

    let price = sun_price - jum_price - add_price1 - add_price2 - add_price3 - add_price4 - add_price5;
    //유보금액 = price * 유보율
    let difer_price = (price * cfNumeric($('#difer_price').val())) / 100;
    difer_price = Math.round(difer_price);

    let real_price = price - difer_price;

    $('#difer_price').val(commaNum(difer_price));
    $('#cal_price').val(commaNum(real_price));
  }

  function go_top() {
    if (!$('#sun_price').val() || $('#sun_price').val() == "0") {
      alert("순익을 입력해주세요");
      $('#sun_price').focus();
      return;
    }

    axios2
      .postEx(`/finance/fee/consult/top`, {
        s_year: $('#s_year option:selected').val(),
        s_month: $('#s_month option:selected').val(),
        sun_price: $('#sun_price').val(),
        jum_price: $('#jum_price').val(),
        add_price1: $('#add_price1').val(),
        add_price2: $('#add_price2').val(),
        add_price3: $('#add_price3').val(),
        add_price4: $('#add_price4').val(),
        add_price5: $('#add_price5').val(),
        difer_price: $('#difer_price').val(),
        cal_price: $('#cal_price').val()
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        alert("저장되었습니다");
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function call_step(seq) {
    axios2
      .postEx(`/finance/call/consult`, {
        seq: seq
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        let value = data.split("||");

        if (value[0]) {
          $('#jibun_rate').val(eval(value[0]));
        } else {
          $('#jibun_rate').val(0);
        }

        if (value[1]) {
          $('#chagam_rate').val(eval(value[1]));
        } else {
          $('#chagam_rate').val(1);
        }

        $('#bigo').val(value[2]);
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/finance/fee/list/false")}>수수료 내역보고</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/finance/fee/public")}>퍼블릭 부서</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/finance/fee/pt")}>PT 부서</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/finance/fee/add")}>추가/기타</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/finance/fee/incen")}>인센티브</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/finance/fee/incen/pt")}>PT 수당</label>

          <input id="gg" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/finance/fee/gx")}>GX 수당</label>

          <input id="hh" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/finance/fee/consult")}>영업컨설팅비</label>

          <input id="ii" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ii" onClick={() => history.push("/finance/fee/step")}>직원별 수수료</label>

          <input id="jj" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="jj" onClick={() => history.push("/finance/fee/overtime/list")}>추가근무관리</label>

          <input id="kk" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="kk" onClick={() => history.push("/finance/fee/etc")}>기타수당</label>

          <input id="ll" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ll" onClick={() => history.push("/finance/fee/branch/overprice")}>초과수당설정</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 3}, (_, index) => new Date().getFullYear() - 2 + index).map((year) => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}월</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="overflowX m-3 ">
        <div className="table_wide_sh3 mb-4">
          <div className="p-3" style={{border: "1px solid #eee", borderRadius: "12px"}}>
            <table className="view-rounded sub_table_border text-center auto">
              <tr>
                <th>순익</th>
                <td>
                  <input
                    type="text"
                    id="sun_price"
                    className="form-control wid150 wid100 price_input"
                    placeholder=""
                    style={{width: '120px', imeMode: 'disabled'}}
                    onKeyUp={(e) => {
                      Fn.vComma(e.target);
                      top_cal_price()
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                </td>
                <th>점장수당공제액</th>
                <td>
                  <input
                    type="text"
                    id="jum_price"
                    className="form-control wid150 wid100 price_input"
                    placeholder=""
                    style={{width: '120px', imeMode: 'disabled'}}
                    //onKeyPress={numkeyCheck}
                    onKeyUp={(e) => {
                      Fn.vComma(e.target);
                      top_cal_price()
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                </td>
                <th>추가공제액1</th>
                <td>
                  <input
                    type="text"
                    id="add_price1"
                    className="form-control wid150 wid100 price_input"
                    placeholder=""
                    style={{width: '120px', imeMode: 'disabled'}}
                    //onKeyPress={numkeyCheck}
                    onKeyUp={(e) => {
                      Fn.vComma(e.target);
                      top_cal_price()
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                </td>
                <th>추가공제액2</th>
                <td>
                  <input
                    type="text"
                    id="add_price2"
                    className="form-control wid150 wid100 price_input"
                    placeholder=""
                    style={{width: '120px', imeMode: 'disabled'}}
                    //onKeyPress={numkeyCheck}
                    onKeyUp={(e) => {
                      Fn.vComma(e.target);
                      top_cal_price()
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                </td>
              </tr>
              <tr>
                <th>추가공제액3</th>
                <td>
                  <input
                    type="text"
                    id="add_price3"
                    className="form-control wid150 wid100 price_input"
                    placeholder=""
                    style={{width: '120px', imeMode: 'disabled'}}
                    //onKeyPress={numkeyCheck}
                    onKeyUp={(e) => {
                      Fn.vComma(e.target);
                      top_cal_price()
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                </td>
                <th>추가공제액4</th>
                <td>
                  <input
                    type="text"
                    id="add_price4"
                    className="form-control wid150 wid100 price_input"
                    placeholder=""
                    style={{width: '120px', imeMode: 'disabled'}}
                    //onKeyPress={numkeyCheck}
                    onKeyUp={(e) => {
                      Fn.vComma(e.target);
                      top_cal_price()
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                </td>
                <th>추가공제액5</th>
                <td>
                  <input
                    type="text"
                    id="add_price5"
                    className="form-control wid150 wid100 price_input"
                    placeholder=""
                    style={{width: '120px', imeMode: 'disabled'}}
                    //onKeyPress={numkeyCheck}
                    onKeyUp={(e) => {
                      Fn.vComma(e.target);
                      top_cal_price()
                    }}
                    onFocus={(e) => e.target.select()}
                  />
                </td>
                <th>유보금</th>
                <td>
                  <input
                    type="text"
                    id="difer_price"
                    className="form-control wid150 wid100 price_input"
                    placeholder=""
                    style={{width: '120px', imeMode: 'disabled'}}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>실제이체액</th>
                <td>
                  <input
                    type="text"
                    id="cal_price"
                    className="form-control wid150 wid100 price_input"
                    placeholder=""
                    style={{width: '120px', imeMode: 'disabled'}}
                    readOnly
                  />
                </td>
              </tr>
            </table>
            <div className="my-3">
              <input type="button" id="add_btn" className="btn btn-sm btn-point px-3" onClick={() => go_top()} value="저장"/>
            </div>
          </div>

          <div className="my-3 p-3" style={{border: "1px solid #eee", borderRadius: "12px"}}>
            <input type="hidden" id="base_seq"/>

            <table className="view-rounded sub_table_border text-left auto">
              <tr>
                <th>스텝명</th>
                <td>
                  <select className="form-select w-50" id="manager_seq" onChange={(e) => call_step(e.target.value)} style={{maxWidth: "150px"}}>
                  </select>
                </td>
                <th>지분율</th>
                <td>
                  <div className="d-flex">
                    <input type="text" className="form-control table-inline" id="jibun_rate" style={{maxWidth: "120px"}}/>
                    <p className="p-1">%</p>
                  </div>
                </td>
              </tr>

              <tr>
                <th>차감율</th>
                <td>
                  <div className="d-flex">
                    <input type="text" className="form-control table-inline" id="chagam_rate" style={{maxWidth: "120px"}}/>
                    <p className="p-1">%</p>
                  </div>
                </td>
                <th>비고</th>
                <td>
                  <select className="form-select w-50" id="bigo" style={{maxWidth: "150px"}}>
                    <option value="">선택</option>
                    <option value="법인세비축">법인세비축</option>
                    <option value="사업소득">사업소득</option>
                    <option value="익명조합원">익명조합원</option>
                    <option value="위탁사업자">위탁사업자</option>
                    <option value="영업자">영업자</option>
                  </select>
                </td>
              </tr>
            </table>
            <div className="my-3">
              {result?.top_row?.sun_price !== undefined ?
                <button type="button" id="add_btn2" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>추가</button>
                :
                <button type="button" id="add_btn2" className="btn btn-sm btn-point px-3" onClick={() => alert('순익/실제이체액이 등록되지 않았습니다.')}>추가</button>
              }
            </div>
          </div>

          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <tr>
              <th>스텝명</th>
              <th>지분율</th>
              <th>지분 이체액</th>
              <th>차감율</th>
              <th>차감액</th>
              <th>실제 이체액</th>
              <th>비고</th>
              <th>수정/삭제</th>
            </tr>
            {result?.list?.map((row, i) => {
              const base_seq = btoa(row?.seq);

              return (
                <tr key={i}>
                  <td>{row?.user_name}</td>
                  <td className="text-right">{row?.jibun_rate}%</td>
                  <td className="text-right">{Fn.numberWithCommas(row?.jibun_price)}</td>
                  <td className="text-right">{row?.chagam_rate}%</td>
                  <td className="text-right">{Fn.numberWithCommas(row?.chagam_price)}</td>
                  <td className="text-right">{Fn.numberWithCommas(row?.real_price)}</td>
                  <td>
                    <div align="left" style={{paddingLeft: '5px'}}>{row?.bigo}</div>
                    <input type="hidden" name={`input_bigo[${i + 1}]`} value={row?.bigo}/>
                  </td>
                  <td>
                    <input
                      type="button"
                      className="btn btn-ssm btn-outline-point"
                      value="수정"
                      onClick={() =>
                        edit_consult(
                          base_seq,
                          Fn.numberWithCommas(row?.sun_price),
                          row?.manager_seq,
                          row?.jibun_rate,
                          row?.chagam_rate,
                          row?.bigo
                        )
                      }
                    />
                    <input
                      type="button"
                      className="btn btn-ssm btn-outline-dark mx-1"
                      value="삭제"
                      onClick={() => del_consult(base_seq)}
                    />
                  </td>
                </tr>
              );
            })}
            <tr>
              <th colSpan={2}>합계</th>
              <th className="text-right">
                <strong>{Fn.numberWithCommas(result?.tot_jibun_price)}</strong>
              </th>
              <th></th>
              <th className="text-right">
                <strong>{Fn.numberWithCommas(result?.tot_chagam_price)}</strong>
              </th>
              <th className="text-right">
                <strong>{Fn.numberWithCommas(result?.tot_real_price)}</strong>
              </th>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th colSpan={2}>법인세</th>
              <th className="text-right">
                <strong>{Fn.numberWithCommas(result?.bub)}</strong>
              </th>
            </tr>
          </table>
        </div>
      </div>
    </CmnCard>
  )
    ;
};

export default FinanceFeeConsult;
