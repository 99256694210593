import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import _ from "lodash";
import {defaults} from 'chart.js/auto';

import Chart, {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js/auto';
import {Bar} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const SalesDailyState = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [ret, setRet] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    defaults.font.family = 'Tahoma';
    $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
    $('#s_month').val(Fn.getCurrentDate().split("-")[1]);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/sales/daily/sales/state/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&s_pum_position=${$('#s_pum_position option:selected').val() || ''}&s_pum_code=${$('#s_pum_code option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_pum_position').html() === '') {
            let optionsHTML = '<option value="">::품목분류::</option>';
            result?.list1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_pum_position').html(optionsHTML);
          }
          if ($('#s_pum_code').html() === '') {
            let optionsHTML = '<option value="">::품목구분::</option>';
            result?.list2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_pum_code').html(optionsHTML);
          }

          const row = {};

          let s1_price = [];
          result?.s1.forEach(row => {
            let days = row['days1'];
            s1_price[days] = row['price'];
          });
          row.s1_price = s1_price;

          let s2_price = [];
          result?.s2.forEach(row => {
            let days = row['days2'];
            s2_price[days] = row['price'];
          });
          row.s2_price = s2_price;

          let s3_price = [];
          result?.s3.forEach(row => {
            let days = row['days3'];
            s3_price[days] = row['price'];
          });
          row.s3_price = s3_price;

          let s4_price = [];
          result?.s4.forEach(row => {
            let days = row['days4'];
            s4_price[days] = row['price'];
          });
          row.s4_price = s4_price;

          let s5_price = [];
          result?.s5.forEach(row => {
            let days = row['days5'];
            s5_price[days] = row['price'];
          });
          row.s5_price = s5_price;

          let s6_price = [];
          result?.s6.forEach(row => {
            let days = row['days6'];
            s6_price[days] = row['price'];
          });
          row.s6_price = s6_price;

          let s7_price = [];
          result?.s7.forEach(row => {
            let days = row['days7'];
            s7_price[days] = row['price'];
          });
          row.s7_price = s7_price;

          let s8_price = [];
          result?.s8.forEach(row => {
            let days = row['days8'];
            s8_price[days] = row['price'];
          });
          row.s8_price = s8_price;

          let s_year = $('#s_year option:selected').val() || '';
          let s_month = $('#s_month option:selected').val() || '';

          let temp_date = `${s_year}-${s_month}-01`;
          let day_count = new Date(Fn.parseInt(s_year), Fn.parseInt(s_month), 0).getDate();

          if (Fn.parseInt(s_year) === new Date().getFullYear() && Fn.parseInt(s_month) === new Date().getMonth() + 1) {
            day_count = new Date().getDate();
          }

          row.day_count = day_count;

          let tot_s1_price = 0;
          let tot_s2_price = 0;
          let tot_s3_price = 0;
          let tot_s4_price = 0;
          let tot_s5_price = 0;
          let tot_s6_price = 0;
          let tot_s7_price = 0;
          let tot_s8_price = 0;

          let tot_real_price = 0;
          let tot_supply_price = 0;
          let tot_add_price = 0;
          let tot_new_real_price = 0;

          const g_total_price = [];
          const g_sales_price = [];
          const g_real_price = [];

          for (let i = 1; i <= day_count; i++) {
            let j = i < 10 ? "0" + i : i;
            let basic_date = `${s_year}-${s_month}-${j}`;
            let yy = new Date(basic_date).getDay();

            let real_price = (row?.s5_price[basic_date] || 0) - (row?.s6_price[basic_date] || 0);
            let supply_price = Math.round(real_price / 1.1);
            let add_price = real_price - supply_price;
            let new_real_price = real_price - (row?.s8_price[basic_date] || 0);

            tot_s1_price += row?.s1_price?.[basic_date] || 0;
            tot_s2_price += row?.s2_price?.[basic_date] || 0;
            tot_s3_price += row?.s3_price?.[basic_date] || 0;
            tot_s4_price += row?.s4_price?.[basic_date] || 0;
            tot_s5_price += row?.s5_price?.[basic_date] || 0;
            tot_s6_price += row?.s6_price?.[basic_date] || 0;
            tot_s7_price += row?.s7_price?.[basic_date] || 0;
            tot_s8_price += row?.s8_price?.[basic_date] || 0;

            g_total_price[basic_date] = row?.s1_price?.[basic_date] || 0;    //판매총액
            g_sales_price[basic_date] = row?.s5_price?.[basic_date] || 0;    //실판매금액
            g_real_price[basic_date] = real_price || 0;                      //순익

            tot_real_price += real_price;
            tot_supply_price += supply_price;
            tot_add_price += add_price;
            tot_new_real_price += new_real_price;
          }

          row.g_total_price = g_total_price;
          row.g_sales_price = g_sales_price;
          row.g_real_price = g_real_price;

          row.tot_s1_price = tot_s1_price;
          row.tot_s2_price = tot_s2_price;
          row.tot_s3_price = tot_s3_price;
          row.tot_s4_price = tot_s4_price;
          row.tot_s5_price = tot_s5_price;
          row.tot_s6_price = tot_s6_price;
          row.tot_s7_price = tot_s7_price;
          row.tot_s8_price = tot_s8_price;

          row.tot_real_price = tot_real_price;
          row.tot_supply_price = tot_supply_price;
          row.tot_add_price = tot_add_price;
          row.tot_new_real_price = tot_new_real_price;

          setRet(row);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function yoil_show(value) {
    let yy = "";

    if (value === 0) {
      yy = <span style={{color: "red"}}>일</span>;
    } else if (value === 1) {
      yy = "월";
    } else if (value === 2) {
      yy = "화";
    } else if (value === 3) {
      yy = "수";
    } else if (value === 4) {
      yy = "목";
    } else if (value === 5) {
      yy = "금";
    } else if (value === 6) {
      yy = <span style={{color: "blue"}}>토</span>;
    }

    return yy;
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_year">
              {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_month">
              {[...Array(12)].map((_, index) => {
                const monthNumber = index + 1;
                const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
                return (
                  <option key={monthString} value={monthString}>{monthString}</option>
                );
              })}
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_pum_position">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_pum_code">
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            {/*TODO : PTODO -> 2023.10.31 : No API*/}
            <button type="button" className="btn btn-sm btn-dark px-3 mx-1" style={{display: "none"}}>엑셀저장</button>
          </div>
        </div>

        <div className="m-3 table_wide_sh2">
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>판매일</th>
              <th>요일</th>
              <th>판매총액</th>
              <th>취소금액</th>
              <th>반품금액</th>
              <th>할인금액</th>
              <th>손실금액</th>
              <th>실판매금액</th>
              <th>구매원가</th>
              <th>순익</th>
              <th>공급가액</th>
              <th>VAT</th>
              <th className="bgcolor2">입고(발주)금액</th>
              <th className="bgcolor2">손실금액</th>
            </tr>
            {ret && _.range(1, ret?.day_count + 1).map(i => {
              let j = i < 10 ? "0" + i : i;

              let s_year = $('#s_year option:selected').val() || '';
              let s_month = $('#s_month option:selected').val() || '';

              let basic_date = `${s_year}-${s_month}-${j}`;
              let yy = new Date(basic_date).getDay();

              let real_price = (ret?.s5_price?.[basic_date] || 0) - (ret?.s6_price?.[basic_date] || 0);
              let supply_price = Math.round(real_price / 1.1);
              let add_price = real_price - supply_price;
              let new_real_price = real_price - (ret?.s8_price?.[basic_date] || 0);

              return (
                <tr>
                  <td>{`${s_year}-${s_month}-${j}`}</td>
                  <td>{yoil_show(yy)}</td>
                  <td className="text-right">{ret?.s1_price?.[basic_date] ? Fn.numberWithCommas(ret?.s1_price?.[basic_date]) : ""}</td>
                  <td className="text-right">{ret?.s2_price?.[basic_date] ? Fn.numberWithCommas(ret?.s2_price?.[basic_date]) : ""}</td>
                  <td className="text-right">{ret?.s3_price?.[basic_date] ? Fn.numberWithCommas(ret?.s3_price?.[basic_date]) : ""}</td>
                  <td className="text-right">{ret?.s4_price?.[basic_date] ? Fn.numberWithCommas(ret?.s4_price?.[basic_date]) : ""}</td>
                  <td className="text-right">{ret?.s7_price?.[basic_date] ? Fn.numberWithCommas(ret?.s7_price?.[basic_date]) : ""}</td>
                  <td className="text-right">{ret?.s5_price?.[basic_date] ? Fn.numberWithCommas(ret?.s5_price?.[basic_date]) : ""}</td>
                  <td className="text-right">{ret?.s6_price?.[basic_date] ? Fn.numberWithCommas(ret?.s6_price?.[basic_date]) : ""}</td>
                  <td className="text-right">{real_price ? Fn.numberWithCommas(real_price) : ""}</td>
                  <td className="text-right">{supply_price ? Fn.numberWithCommas(supply_price) : ""}</td>
                  <td className="text-right">{add_price ? Fn.numberWithCommas(add_price) : ""}</td>
                  <td className="bgcolor3">{ret?.s8_price?.[basic_date] ? Fn.numberWithCommas(ret?.s8_price?.[basic_date]) : ""}</td>
                  <td className="bgcolor3">{new_real_price ? Fn.numberWithCommas(new_real_price) : ""}</td>
                </tr>
              );
            })}
            <tr className="bold700 bgcolor">
              <td colSpan="2">합계</td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s1_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s2_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s3_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s4_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s7_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s5_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s6_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_real_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_supply_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_add_price)}</strong></td>
              <td className="text-right bgcolor3"><strong>{Fn.numberWithCommas(ret?.tot_s8_price)}</strong></td>
              <td className="text-right bgcolor3">{Fn.numberWithCommas(ret?.tot_new_real_price)}</td>
            </tr>
          </table>
        </div>

        {ret &&
          <div className="m-3">
            <div className="chart_box">
              <p className="h7 bold700 text-left p-2">판매총액</p>
              <div style={{width: '100%', height: '280px', backgroundColor: "#ffffff", padding: '15px'}}>
                <Bar options={{
                  maintainAspectRatio: false,
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    title: {
                      display: false
                    },
                  },
                }}
                     data={{
                       labels: _.range(1, ret?.day_count + 1).map(i => i + "일"),
                       datasets: [
                         {
                           label: '판매총액',
                           data: _.range(1, (ret?.day_count || 0) + 1).map(i => {
                             let basic_date = `${$('#s_year').val()}-${$('#s_month').val()}-${i < 10 ? '0' + i : i}`;
                             return ret?.g_total_price?.[basic_date] || 0;
                           }),
                           backgroundColor: 'rgba(53, 162, 235, 0.5)',
                           barPercentage: 0.4,
                         },
                       ],
                     }}/>
              </div>
            </div>
          </div>}

        <div className="m-3">
          <div className="chart_box">
            <p className="h7 bold700 text-left p-2">실판매금액</p>
            <div style={{width: '100%', height: '280px', backgroundColor: "#ffffff", padding: '15px'}}>
              <Bar options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false
                  },
                },
              }}
                   data={{
                     labels: _.range(1, ret?.day_count + 1).map(i => i + "일"),
                     datasets: [
                       {
                         label: '실판매금액',
                         data: _.range(1, (ret?.day_count || 0) + 1).map(i => {
                           let basic_date = `${$('#s_year').val()}-${$('#s_month').val()}-${i < 10 ? '0' + i : i}`;
                           return ret?.g_sales_price?.[basic_date] || 0;
                         }),
                         backgroundColor: 'rgba(53, 162, 235, 0.5)',
                         barPercentage: 0.4,
                       },
                     ],
                   }}/>
            </div>
          </div>
        </div>

        <div className="m-3">
          <div className="chart_box">
            <p className="h7 bold700 text-left p-2">순익</p>
            <div style={{width: '100%', height: '280px', backgroundColor: "#ffffff", padding: '15px'}}>
              <Bar options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false
                  },
                },
              }}
                   data={{
                     labels: _.range(1, ret?.day_count + 1).map(i => i + "일"),
                     datasets: [
                       {
                         label: '순익',
                         data: _.range(1, (ret?.day_count || 0) + 1).map(i => {
                           let basic_date = `${$('#s_year').val()}-${$('#s_month').val()}-${i < 10 ? '0' + i : i}`;
                           return ret?.g_real_price?.[basic_date] || 0;
                         }),
                         backgroundColor: 'rgba(53, 162, 235, 0.5)',
                         barPercentage: 0.4,
                       },
                     ],
                   }}/>
            </div>
          </div>
        </div>
      </div>
    </CmnCard>
  );
};

export default SalesDailyState;
