import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios from "axios";
import ChkAuthNum from "../common/ChkAuthNum";
import {useHistory, useLocation} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import Constants from "../../constants/constants";

const BranchopBoardWrite = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const location = useLocation();
  const baseSeq = location?.state?.base_seq || '';

  const [result, setResult] = useState({});
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [content, setContent] = useState("");

  const goSubmit = () => {
    if (!$('#title').val()) {
      alert('제목을 입력해주세요');
      $('#title').focus();
      return;
    }
    if (!$('#auth_code').val()) {
      alert('인증코드를 입력해 주세요.');
      $('#auth_code').focus();
      return;
    }
    if (!content) {
      alert('내용을 입력해주세요');
      return;
    }

    axios2
      .postEx('/branchop/board/write', {
        base_seq: $('#base_seq').val(),
        title: $('#title').val(),
        auth_code: $('#auth_code').val(),
        part: "G",
        content: content,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === "err") {
          alert('오류가 발생하였습니다');
        } else if (data === "upd") {
          alert("수정되었습니다");
          history.goBack();
        } else if (data === "reg") {
          alert("등록되었습니다");
          history.goBack();
        } else {
          alert('데이터오류! 시스템 관리자에게 문의하세요');
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  const load = () => {
    $('#base_seq').val(baseSeq);

    axios2
      .post(`/branchop/board/write/ready?base_seq=${baseSeq}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        console.log(result);
        if (code === 200) {
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);

            const token = (localStorage.getItem("USER.token") || null);
            let headers = token ? {Authorization: "Bearer " + token, CurrentUri: window.location.pathname} : {CurrentUri: window.location.pathname};

            fetch(Constants.API_URL.replace("/api/", "") + `/common/editor/upload`, {
              method: "post",
              body: body,
              headers
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: Constants.API_URL.replace("/api/", "") + `${res.url}`
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <input type="hidden" id="base_seq"/>
      <div className="m-3 pb-2">
        <table className="view-rounded sub_view text-left">
          <colgroup>
            <col width="120px"/>
            <col width="58%"/>
            <col width="120px"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th>제목</th>
            <td>
              <input type="text" id="title"/>
            </td>
            <th>인증코드</th>
            <td>
              <div className="d-flex">
                <ChkAuthNum type={"locker_info_change"}/>
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan="4" className="p-2">
              <CKEditor editor={DecoupledEditor} data={content}
                        config={{
                          extraPlugins: [uploadPlugin]
                        }}
                        onReady={(editor) => {
                          editor.ui.getEditableElement().parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement()
                          );
                          editor.editing.view.change((writer) => {
                            writer.setStyle(
                              "height",
                              "350px",
                              editor.editing.view.document.getRoot()
                            );
                          });
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(data);
                        }}
              />
            </td>
          </tr>
        </table>
      </div>
      <div className="text-center my-4">
        <button type="button" className="btn btn-sm btn-point px-3" onClick={() => goSubmit()}>저장</button>
        <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.goBack()}>목록</button>
      </div>

    </CmnCard>
  );
};

export default BranchopBoardWrite;
