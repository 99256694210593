import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import Fn from "../../libraries/Fn";
import {useHistory} from "react-router-dom";

const BranchopGxBasic = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/branchop/gx/basic/ready?s_gx_type=${$('#s_gx_type option:selected').val() || ''}&s_gx_gubun=${$('#s_gx_gubun option:selected').val() || ''}&s_gx_step=${$('#s_gx_step option:selected').val() || ''}&s_gx_name=${$('#s_gx_name').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_gx_gubun').html() === '') {
            let optionsHTML = '<option value="">::GX 구분::</option><option value="opt4">스피닝</option><option value="opt7">필라테스</option>';
            result?.gg1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.gx_value}">${list_row.gx_name}</option>`;
            });
            $('#s_gx_gubun').html(optionsHTML);
          }
          if ($('#s_gx_step').html() === '') {
            let optionsHTML = '<option value="">::GX 스텝::</option>';
            result?.gg2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#s_gx_step').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const del_gx = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 GX정보를 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/branchop/gx/basic/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 fn_search_box">
          <div className="pr-1">
            <select className="form-select" id="s_gx_type">
              <option value="">GX 타입</option>
              <option value="A">무료회원</option>
              <option value="B">유료회원</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_gx_gubun">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_gx_step">
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="GX명 검색" id="s_gx_name"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_border auto">
            <tr>
              <th className="w_70px">No</th>
              <th className="w_70px">
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>GX타입</th>
              <th>GX구분</th>
              <th>GX이름</th>
              <th>시작시간</th>
              <th>정원</th>
              <th className="w-20">요일</th>
              <th>강사명</th>
              <th>운영여부</th>
            </tr>
            {rets && rets.map((ret, i) => {

              const seq = ret.seq;
              const gx_type = ret.gx_type;
              const gx_gubun = ret.gx_gubun;
              const gx_name = ret.gx_name;
              const start_time = ret.start_time;
              const man_num = ret.man_num;
              const step_name = ret.step_name;
              const day1 = ret.day1;
              const day2 = ret.day2;
              const day3 = ret.day3;
              const day4 = ret.day4;
              const day5 = ret.day5;
              const day6 = ret.day6;
              const day7 = ret.day7;
              const use_YN = ret.use_YN;

              let gx_type_show = "";
              if (gx_type === "A") {
                gx_type_show = "무료회원";
              } else if (gx_type === "B") {
                gx_type_show = "유료회원";
              }

              let use_YN_show = "";
              if (use_YN === "Y") {
                use_YN_show = "운영";
              } else if (use_YN === "N") {
                use_YN_show = "중지";
              }

              let day_show = "";
              if (day1 === "Y") day_show += "월,";
              if (day2 === "Y") day_show += "화,";
              if (day3 === "Y") day_show += "수,";
              if (day4 === "Y") day_show += "목,";
              if (day5 === "Y") day_show += "금,";
              if (day6 === "Y") day_show += "토,";
              if (day7 === "Y") day_show += "일,";
              day_show += "*";
              day_show = day_show.replace(",*", "");

              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input
                      type="checkbox"
                      // name={`chk[${i + 1}]`}
                      name="chk"
                      value={btoa(ret?.seq)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  </td>
                  <td>{gx_type_show}</td>
                  <td>{ret?.gxGubunShow}</td>
                  <td className="text-left" style={{cursor: "pointer"}}
                      onClick={() => {
                        history.push({
                          pathname: '/branchop/gx/basic/reg', state: {
                            baseSeq: btoa(ret?.seq)
                          }
                        });
                      }}
                  >{gx_name}</td>
                  <td>{start_time}</td>
                  <td>{man_num}명</td>
                  <td>{day_show}</td>
                  <td>{step_name}</td>
                  <td>{use_YN_show}</td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={10}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <button type="button" className="btn btn-sm btn-outline-point px-3 mx-1" onClick={() => history.push("/branchop/gx/basic/reg")}>추가</button>
          <button type="button" className="btn btn-sm btn-outline-dark px-3" onClick={() => del_gx()}>삭제</button>
        </div>
      </nav>

    </CmnCard>
  );
};

export default BranchopGxBasic;
