import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";

const MvEditIpkum = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  const load = () => {
    axios2
      .post(`/member/edit/ipkum/ready?ip_no=${btoa($('#edit_ipkum_ip_no').val())}&receive_name=${$('#_reg_step option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const goSubmit = () => {
    if ($('#auth_code').val() == "") {
      alert('인증코드를 입력해주세요');
      $('#search_auth_code').focus();
      return;
    }
    if ($('#ei_payCard option:selected').val() == "") {
      alert("입금방법을 선택해주세요");
      $('#ei_payCard').focus();
      return;
    }
    if ($('#ip_amt').val() == "" || $('#ip_amt').val() == "0") {
      alert("금액을 입력해주세요");
      $('#ip_amt').focus();
      return;
    }

    axios2
      .postEx(`/member/member/ipkum/save`, {
        ip_no: $('#edit_ipkum_ip_no').val(),
        auth_code: $('#auth_code').val(),
        mb_no: props.mbNo,
        receive_name: $('#_reg_step option:selected').val() || '',
        ip_date: $('#ei_ip_date').val(),
        payCard: $('#ei_payCard option:selected').val() || '',
        ip_amt: $('#ei_ip_amt').val(),
        ip_etc: $('#ei_ip_etc').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        } else {
          alert('수정되었습니다');
          props?.onCallback();
          onToggleModal();
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '900px'}}>
          <div className="POP_title">
            <h5><strong>{result?.mRow?.mb_name} 입금내역 변경</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <form name="ii_input_form" method="post">
              <table className="view-rounded sub_table_border" style={{borderRadius: 0}}>
                <colgroup>
                  <col width="85px"/>
                  <col width="120px"/>
                  <col width="100px"/>
                  <col width="*"/>
                  <col width="80px"/>
                </colgroup>
                <tr>
                  <th>입금날짜</th>
                  <th>형태</th>
                  <th>금액</th>
                  <th>금액비고</th>
                  <th>등록자</th>
                </tr>
                <tr>
                  <td className="text-center">{result?.row?.ip_date}</td>
                  <td className="text-center">
                    {
                      result?.row?.ip_method == "보통예금" ? "이체" :
                        result?.row?.ip_method == "카드매출금" ? "카드" :
                          result?.row?.ip_method == "현금" ? "현금" :
                            result?.row?.ip_method == "잡손실" ? "대체손실" : ""
                    }
                  </td>
                  <td className="text-right">
                    {result?.row?.ip_amt < 0 ? <span className="text-danger">{Fn.numberWithCommas(result?.row?.ip_amt)}</span> : <span>{Fn.numberWithCommas(result?.row?.ip_amt)}</span>}
                  </td>
                  <td className="text-left"><span className="ml-3">{result?.row?.ip_etc}</span></td>
                  <td className="text-center">{result?.row?.ip_step}</td>
                </tr>
              </table>
              <table className="view-rounded sub_table_border mt-4" style={{borderRadius: 0, borderTopWidth: 2, borderTopColor: "#aaa"}}>
                <colgroup>
                  <col width="108px"/>
                  <col width="*"/>
                  <col width="108px"/>
                  <col width="50%"/>
                </colgroup>
                <tr>
                  <th>날짜</th>
                  <td>
                    <JDatePicker name="ei_ip_date" id="ei_ip_date" type="text" className="form-control wid80" placeholder=""/>
                  </td>
                  <th>형태</th>
                  <td>
                    <div className="d-flex mb-2">
                      <select className="form-select" style={{maxWidth: '150px'}} name="ei_payCard" id="ei_payCard">
                        <option value="">선택</option>
                        <option value="1111">현금</option>
                        {result?.banks?.length > 0 && result?.banks?.map((pcRow) =>
                          pcRow.j_gyecode == "1112" ?
                            <option value={`${pcRow.j_no},${pcRow.j_jukname},${pcRow.j_gyecode}`}>
                              (계좌) {pcRow.j_jukname}
                            </option>
                            :
                            <option value={`${pcRow.j_no},${pcRow.j_jukname},${pcRow.j_gyecode}`}>
                              {pcRow.j_jukname}Card
                            </option>
                        )}
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>금액</th>
                  <td>
                    <input name="ei_ip_amt" id="ei_ip_amt" type="text" className="form-control wid80 cls-type-fmtnum" placeholder="" onKeyUp={(e) => Fn.vComma(e.target)} maxLength={12}/>
                  </td>
                  <th>금액비고</th>
                  <td>
                    <input name="ei_ip_etc" id="ei_ip_etc" type="text" className="form-control" aria-describedby="button-addon2" placeholder="" maxLength={30}/>
                  </td>
                </tr>
              </table>
            </form>
          </div>
          <div className="text-center mb-3">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => goSubmit()}>입금내역 변경</button>
            <button type="button" className="btn btn-sm btn-ligray px-2" onClick={() => setIsOpen(false)}>닫기</button>
          </div>

        </div>
      </div>
    </Modal>
  );
};

export default MvEditIpkum;
