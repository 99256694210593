import React, {useEffect, useState} from "react";
import axios from 'axios';
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";

import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";

const MemberOcareMember = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [dateType, setDateType] = useState('A');
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/member/ocare/member/ready?search_YN=Y&o_date1=${$('#o_date1').val()}&o_date2=${$('#o_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_hp=${$('#s_mb_hp').val()}&s_sex=${$('#s_sex option:selected').val() || ''}&s_member_id=${$('#s_member_id').val()}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  3-1.
  const make_monitor = () => {
    const as = window.open('/inc/black.html', 'enter_monitor', 'width=0,height=0,scrollbars=yes,top=10000,left=10000');
    as.window.close();
    const as2 = window.open('/enter/enter_monitor.html', 'enter_monitor', 'width=500,height=500,scrollbars=yes,top=600,left=1000');

    return () => {
      as2.close();
    };
  };

  //  4-1.
  const [searchAuthCode, setSearchAuthCode] = useState("");
  const [authCodeName, setAuthCodeName] = useState("");
  const [authCode, setAuthCode] = useState("");
  const [regStep, setRegStep] = useState("");
  const [type, setType] = useState("");

  const handleSearchAuthCodeChange = (event) => {
    setSearchAuthCode(event.target.value);
  };

  //  6-1.
  //const [authCode, setAuthCode] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [confirmation, setConfirmation] = useState(false);

  const handleAuthCodeChange = (event) => {
    setAuthCode(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    const {name, checked} = event.target;
    setCheckedItems(prevCheckedItems => {
      if (checked) {
        return [...prevCheckedItems, name];
      } else {
        return prevCheckedItems.filter(item => item !== name);
      }
    });
  };

  const ocare_del = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 오케어 회원을 스포애니회원으로 변경하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/member/spoany/member/change', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('변경되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="pr-1 d-flex">
            <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>
              가입일 :
            </label>
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="o_date2"/>
            </div>
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control width_shorts"
              aria-describedby="button-addon2"
              placeholder="이름 검색"
              id="s_mb_name"
            />
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="연락처 검색"
              id="s_mb_hp"
            />
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" id="s_sex">
              <option value="">성별</option>
              <option value="M">남성</option>
              <option value="F">여성</option>
            </select>
          </div>
          <div className="pr-1">
            <input
              type="text"
              className="form-control"
              aria-describedby="button-addon2"
              placeholder="스포애니 아이디 검색"
              id="s_member_id"
            />
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>


        <div className="m-3 pb-5 table_list">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border table_wide_sh2">
            <colgroup>
              <col width="60px"/>
              <col width="60px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>카드번호</th>
              <th>이름</th>
              <th>회원아이디</th>
              <th>연락처</th>
              <th>성별</th>
              <th>연령</th>
              <th>가입일</th>
              <th>시작일</th>
              <th>종료일</th>
            </tr>
            {rets && rets.map((ret, i) => {
              let mb_no = ret?.mb_no;
              let mb_id = ret?.mb_id;
              let mb_name = ret?.mb_name;
              let mb_hp = ret?.mb_hp;
              let mb_sex = ret?.mb_sex;
              let mb_birth = ret?.mb_birth;
              let mb_open_date = ret?.mb_open_date;
              let start_date = ret?.start_date;
              let end_date = ret?.end_date;
              let locker = ret?.locker;
              let locker_name = ret?.locker_name;
              let suit = ret?.suit;
              let remain_price = ret?.remain_price;
              let suit_start_date = ret?.suit_start_date;
              let suit_end_date = ret?.suit_end_date;
              let locker_start_date = ret?.locker_start_date;
              let locker_end_date = ret?.locker_end_date;
              let opt1 = ret?.opt1;
              let opt2 = ret?.opt2;
              let opt3 = ret?.opt3;
              let opt4 = ret?.opt4;
              let opt5 = ret?.opt5;
              let opt6 = ret?.opt6;
              let opt7 = ret?.opt7;
              let opt8 = ret?.opt8;
              let opt9 = ret?.opt9;
              let opt10 = ret?.opt10;
              let g_locker = ret?.g_locker;
              let ot_cnt = ret?.ot_cnt;
              let buy_month = ret?.buy_month;
              let member_grade_name = ret?.member_grade_name;
              let member_id = ret?.member_id;

              let etc1_start_date = ret?.etc1_start_date;
              let etc2_start_date = ret?.etc2_start_date;
              let etc3_start_date = ret?.etc3_start_date;
              let etc4_start_date = ret?.etc4_start_date;
              let etc5_start_date = ret?.etc5_start_date;

              let etc1_end_date = ret?.etc1_end_date;
              let etc2_end_date = ret?.etc2_end_date;
              let etc3_end_date = ret?.etc3_end_date;
              let etc4_end_date = ret?.etc4_end_date;
              let etc5_end_date = ret?.etc5_end_date;

              let branch_code = ret?.branch_code;

              let date_type = dateType;
              if (date_type === "A") {
                start_date = start_date;
                end_date = end_date;
              } else if (date_type === "B") {
                start_date = etc3_start_date;
                end_date = etc3_end_date;
              } else if (date_type === "C") {
                start_date = etc1_start_date;
                end_date = etc1_end_date;
              } else if (date_type === "D") {
                start_date = etc2_start_date;
                end_date = etc2_end_date;
              } else if (date_type === "E") {
                start_date = etc4_start_date;
                end_date = etc4_end_date;
              } else if (date_type === "F") {
                start_date = etc5_start_date;
                end_date = etc5_end_date;
              } else {
                start_date = start_date;
                end_date = end_date;
              }

              const BranchOpt1 = "골프";        //고정
              const BranchOpt2 = "골프라커";    //고정
              const BranchOpt3 = "스쿼시";
              const BranchOpt4 = "스피닝";
              const BranchOpt5 = "태닝";
              const BranchOpt6 = "토탈";
              const BranchOpt7 = "필라테스";
              const BranchOpt8 = "Opt1";
              const BranchOpt9 = "Opt2";
              const BranchOpt10 = "Opt3";

              let opt_use = "";
              if (opt1 === 1) opt_use += BranchOpt1 + ",";
              if (g_locker === 1) opt_use += BranchOpt2 + ",";
              if (opt3 === 1) opt_use += BranchOpt3 + ",";
              if (opt4 === 1) opt_use += BranchOpt4 + ",";
              if (opt5 === 1) opt_use += BranchOpt5 + ",";
              if (opt6 === 1) opt_use += BranchOpt6 + ",";
              if (opt7 === 1) opt_use += BranchOpt7 + ",";
              if (opt8 === 1) opt_use += BranchOpt8 + ",";
              if (opt9 === 1) opt_use += BranchOpt9 + ",";
              if (opt10 === 1) opt_use += BranchOpt10 + ",";

              let mb_sex_show = "";
              if (mb_sex === "M") {
                mb_sex_show = "남";
              } else if (mb_sex === "F") {
                mb_sex_show = "여";
              }

              let m_birth_str = mb_birth.substring(0, 2);
              let birth_show = 0;

              if (m_birth_str === "19") {
                birth_show = 100;
              } else {
                let m_real_b = "";
                if (m_birth_str < "30") {
                  m_real_b = "20" + m_birth_str;
                } else {
                  m_real_b = "19" + m_birth_str;
                }
                birth_show = new Date().getFullYear() - parseInt(m_real_b) + 1;
              }

              return (
                <tr>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input
                      type="checkbox"
                      name={`chk[${i + 1}]`}
                      value={btoa(ret?.mb_no)}
                      className="checkbox"
                      id={`achk${i + 1}`}
                    />
                  </td>
                  <td>{mb_id}</td>
                  <td>{mb_name}</td>
                  <td>{member_id}</td>
                  <td>***-****-****</td>
                  <td>{mb_sex_show}</td>
                  <td>{birth_show > 0 && birth_show}</td>
                  <td>{mb_open_date.substring(0, 10)}</td>
                  <td>{start_date}</td>
                  <td>{end_date}</td>
                </tr>
              );
            })}
            {(!rets || rets?.length === 0) &&
              <tr>
                <td colSpan={11}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <div className="d-flex">
            <div className="d-flex mx-1">
              <ChkAuthNum type={"locker_info_change"}/>
            </div>

            <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => ocare_del()}>
              스포애니 회원변경
            </button>
          </div>
        </div>
      </nav>

    </CmnCard>
  );
};
export default MemberOcareMember;
