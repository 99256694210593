import {useHistory} from "react-router-dom";
import "../../sass/main.scss";
import React, {useEffect, useRef, useState} from "react";
import Fn from "../../libraries/Fn";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";

import introLogo from '../../images/intro_logo.png';
import Modal from "react-modal";
import Constants from "../../constants/constants";

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import logoPro from "../../images/logo_pro.png";
import logoFitness from "../../images/logo_fitness.png";
import logoGym from "../../images/logo_gym.png";
import logoFa from "../../images/logo_fa.png";
import logoX2 from "../../images/logo_x2.png";
import logoHealthymeal from "../../images/logo_healthymeal.png";

const Enter = (props) => {

  const history = useHistory();
  const [audioFile, setAudioFile] = useState();

  useEffect(() => {
    $('body').css('zoom', 'unset');
    $('#_INTRO_BG3').height($(document).height());
    $("*").each(function () {
      $(this).css("outline", "none");
    });
  }, []);

  useEffect(() => {
    axios2
      .post(`/common/update/check?build=${Constants.BUILD}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if (message === 'update') {
            window.location.reload();
          }
        }
      })
      .catch((error) => {
      });
  }, [window.location.pathname]);

  useEffect(() => {
    if (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) <= 0) {
      history.push("/login");
      return;
    }

    $.ajaxSetup({
      beforeSend: function (xhr, options) {
        if (options.url.startsWith(":3000"))
          options.url = options.url.replace(":3000", ":8994");
        else if (options.url.indexOf("http") === -1)
          options.url = Constants.API_URL + options.url;
      },
      headers: {
        'Authorization': ('Bearer ' + localStorage.getItem("USER.token"))
      },
      cache: false,
      error: function (jqXHR, textStatus, errorThrown) {
        Fn.parseAjaxError(jqXHR, textStatus, errorThrown, history);
      }
    });

    $('.main_wrapper').height($(document).height());
    $('#cardNum').val('회원 카드번호 입력');
    $('#cardNum')[0].focus();
    $('#cardNum').select();

    setAudioFile(Fn.makeUrl(Constants.API_URL + "/common/download?cd=attachment&type=sound_files&filename=" + encodeURIComponent("silence.wav")));
  }, []);

  let is_processing = false;

  const enter = () => {
    if (is_processing) return;

    if ($('#cardNum').val().trim() == "회원 카드번호 입력" || $('#cardNum').val().trim() == "") {
      $('#cardNum')[0].focus();
      $('#cardNum')[0].select();
      return;
    }

    if ($('#cardNum').val().length < 6)
      return;

    is_processing = true;

    setTimeout(() => {
      is_processing = false;
    }, 1000);

    history.push({
      pathname: '/enter/main', state: {
        cardNum: $('#cardNum').val(),
      }
    });
  }

  let logoSrc;
  switch (localStorage.getItem("USER.systemBrand")) {
    case 'P':
      logoSrc = logoPro;
      break;
    case 'F':
      logoSrc = logoFitness;
      break;
    case 'G':
      logoSrc = logoGym;
      break;
    case 'S':
      logoSrc = logoFa;
      break;
    case 'X':
      logoSrc = logoX2;
      break;
    case 'M':
      logoSrc = logoHealthymeal;
      break;
    default:
      logoSrc = logoFitness;
  }

  if (false)
    return (
      <div className="entry_body2">
        <div className="entry_head">
          <h1>
            <img src={logoSrc} alt="SPOANY" style={{width: "296px", marginRight: "10px"}}/>
            {localStorage.getItem("USER.systemBranchName")}
          </h1>
        </div>
        <hr className="enter_hr"/>
        <p className="enter_p1">회원카드를 태그하신 후<br/>입장해 주세요</p>
        <div className="enter_form1">
          <input
            type="text"
            className="input1"
            placeholder="회원 카드번호 입력"
            name="cardNum"
            id="cardNum"
            onKeyDown={(e) => {
              if (e.keyCode == 13)
                enter()
            }}
            style={{outline: "none"}}
          />
          <button className="enter_btn1" onClick={() => enter()} style={{outline: "none"}}>검색</button>
        </div>
        <p className="enter_foot">카드 분실 및 회원조회가 안되는 경우 담당자에게 문의 바랍니다.</p>

        <div style={{visibility: "hidden", position: "absolute", top: "-9999px"}}>
          {audioFile &&
            <AudioPlayer
              autoPlay
              src={audioFile}
              onPlay={e => console.log("onPlay - Silence")}
            />
          }
        </div>
      </div>
    );
  else
    return (
      <div id="_INTRO_BG3">
        <div className="_intro_wrap">
          <div className="p_absolute_mid2">
            <div className="_intro_usr1_box">
              <div className="m-4 p-4 p_rative">
                <p className="p_absolute px-4 py-2 abs_top abs_right h6 bold700" style={{borderRadius: '30px', border: '2px solid #606060'}}>{localStorage.getItem("USER.systemBranchName")}</p>
                <div className="d-flex justify-content-center" style={{alignItems: 'center'}}>
                  <p>
                    <p><img src={logoSrc} style={{height: "70px"}}/></p>
                  </p>
                </div>
              </div>

              <div className="py-3">
                <p className="h2 bold700 pb-4 font-notosans">회원카드를 태그하신 후 입장해 주세요.</p>
                <p className="mt-4">
                  <input type="text" id="cardNum" name="cardNum"
                         onKeyDown={(e) => {
                           if (e.keyCode == 13)
                             enter()
                         }}/>
                  <button type="button" className="btn btn-point" onClick={() => enter()}>
                    <span className="h5 bold700">검색</span>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{visibility: "hidden", position: "absolute", top: "-9999px"}}>
          {audioFile &&
            <AudioPlayer
              autoPlay
              src={audioFile}
              onPlay={e => console.log("onPlay - Silence")}
            />
          }
        </div>
      </div>
    );
};

export default Enter;
