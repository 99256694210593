import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";


const SalesProdState = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#s_date1').val(`${year}-${month}-01`);
    $('#s_date2').val(today);
  }, []);

  useEffect(() => {
    axios2
      .post(`/sales/prod/sales/state/ready?init=Y&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_pum_position=${$('#s_pum_position option:selected').val() || ''}&s_pum_code=${$('#s_pum_code option:selected').val() || ''}&s_p_name=${$('#s_p_name').val()}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }
          if ($('#s_pum_position').html() === '') {
            let optionsHTML = '<option value="">품목분류</option>';
            result?.list1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_pum_position').html(optionsHTML);
          }
          if ($('#s_pum_code').html() === '') {
            let optionsHTML = '<option value="">품목구분</option>';
            result?.list2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_pum_code').html(optionsHTML);
          }
        }
      })
      .catch((error) => {
      });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, [currPage]);

  // TODO : setState 로 처리할 것
  let tot_sales_qty = 0;
  let tot_sales_price = 0;
  let tot_cancel_qty = 0;
  let tot_cancel_price = 0;
  let tot_return_qty = 0;
  let tot_return_price = 0;
  let tot_discount_price = 0;
  let tot_loss_price = 0;
  let tot_real_price = 0;
  let tot_buy_price = 0;
  let tot_sun_price = 0;
  let tot_supply_price = 0;
  let tot_add_price = 0;

  const load = (_page) => {
    axios2
      .post(`/sales/prod/sales/state/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_pum_position=${$('#s_pum_position option:selected').val() || ''}&s_pum_code=${$('#s_pum_code option:selected').val() || ''}&s_p_name=${$('#s_p_name').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          tot_sales_qty = 0;
          tot_sales_price = 0;
          tot_cancel_qty = 0;
          tot_cancel_price = 0;
          tot_return_qty = 0;
          tot_return_price = 0;
          tot_discount_price = 0;
          tot_loss_price = 0;
          tot_real_price = 0;
          tot_buy_price = 0;
          tot_sun_price = 0;
          tot_supply_price = 0;
          tot_add_price = 0;

          setRets(result?.result);

          //setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide fn_search_box">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_branch_code">
            </select>
          </div>
          <div className="pr-1">
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
            </div>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_pum_position">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_pum_code">
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="검색어 입력" id="s_p_name"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 table_wide table_list">
          {/* ---- 회원목록 ---- */}
          <table className="view-rounded sub_table_rowspan">
            <colgroup>
              <col width="60px"/>
              <col width="13%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>

              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>

              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>

              <col width="*"/>
            </colgroup>
            <tr>
              <th rowSpan="2">No</th>
              <th rowSpan="2">상품명</th>
              <th rowSpan="2">상품단가</th>
              <th colSpan="2">판매</th>
              <th colSpan="2">취소</th>
              <th colSpan="2">반품</th>
              <th rowSpan="2">할인금액</th>
              <th rowSpan="2">손실금액</th>
              <th rowSpan="2">실판매금액</th>
              <th rowSpan="2">구매원가</th>
              <th rowSpan="2">순익</th>
              <th rowSpan="2">공급가</th>
              <th rowSpan="2">VAT</th>
            </tr>
            <tr>
              <th>수량</th>
              <th>금액</th>
              <th>수량</th>
              <th>금액</th>
              <th>수량</th>
              <th>금액</th>
            </tr>
            {rets && rets.map((ret, i) => {
              //순익 : 실판매금액 - 구매원가
              const sun_price = ret?.real_price - ret?.buy_price;

              //공급가,부가세
              const supply_price = Math.round(sun_price / 1.1);
              const add_price = sun_price - supply_price;

              tot_sales_qty = tot_sales_qty + ret?.sales_qty;
              tot_sales_price = tot_sales_price + ret?.sales_price;

              tot_cancel_qty = tot_cancel_qty + ret?.cancel_qty;
              tot_cancel_price = tot_cancel_price + ret?.cancel_price;

              tot_return_qty = tot_return_qty + Math.abs(ret?.return_qty);
              tot_return_price = tot_return_price + Math.abs(ret?.return_price);

              tot_discount_price = tot_discount_price + ret?.discount_price;

              tot_loss_price = tot_loss_price + ret?.loss_price;

              tot_real_price = tot_real_price + ret?.real_price;

              tot_buy_price = tot_buy_price + ret?.buy_price;

              tot_sun_price = tot_sun_price + sun_price;

              tot_supply_price = tot_supply_price + supply_price;

              tot_add_price = tot_add_price + add_price;

              return (
                <tr>
                  <td>{i + 1}</td>
                  <td className="text-left">{ret?.p_name}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.p_sales_price || 0)}</td>
                  <td>{Fn.numberWithCommas(ret?.sales_qty || 0)}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.sales_price || 0)}</td>
                  <td>{Fn.numberWithCommas(ret?.cancel_qty || 0)}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.cancel_price || 0)}</td>
                  <td>{Fn.numberWithCommas(ret?.return_qty || 0)}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.return_price || 0)}</td>

                  <td className="text-right">{Fn.numberWithCommas(ret?.discount_price || 0)}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.loss_price || 0)}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.real_price || 0)}</td>
                  <td className="text-right">{Fn.numberWithCommas(ret?.buy_price || 0)}</td>
                  <td className="text-right">{Fn.numberWithCommas(sun_price || 0)}</td>
                  <td className="text-right">{Fn.numberWithCommas(supply_price || 0)}</td>
                  <td className="text-right">{Fn.numberWithCommas(add_price || 0)}</td>
                </tr>
              );
            })}
            <tr className="bold700 bgcolor">
              <td colSpan="2">합계</td>
              <td className="text-right">{Fn.numberWithCommas(tot_sales_qty || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_sales_qty || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_sales_price || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_cancel_qty || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_cancel_price || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_return_qty || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_return_price || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_discount_price || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_loss_price || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_real_price || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_buy_price || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_sun_price || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_supply_price || 0)}</td>
              <td className="text-right">{Fn.numberWithCommas(tot_add_price || 0)}</td>
            </tr>
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default SalesProdState;
