import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import search0001 from "../../images/0001.png";
import search0002 from "../../images/0002.png";
import search0003 from "../../images/0003.png";
import search0004 from "../../images/0004.png";
import search0005 from "../../images/0005.png";
import search0006 from "../../images/0006.png";
import search0007 from "../../images/0007.png";
import search0008 from "../../images/0008.png";
import search0009 from "../../images/0009.png";
import search0010 from "../../images/0010.png";
import search0011 from "../../images/0011.png";
import search0012 from "../../images/0012.png";
import search0014 from "../../images/0014.png";
import search0017 from "../../images/0017.png";
import search0018 from "../../images/0018.png";
import search0019 from "../../images/0019.png";
import search0020 from "../../images/0020.png";
import search0021 from "../../images/0021.png";
import search0023 from "../../images/0023.png";
import $ from "jquery";
import JDatePicker from "../common/JDatePicker";
import {useHistory, useLocation} from "react-router-dom";
import PostCode from "../common/PostCode";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Constants from "../../constants/constants";
import Modal from "react-modal";

const ConfigBrandReg = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isPostCodeOpen, setPostCodeOpen] = useState(false);

  const history = useHistory();

  const location = useLocation();
  const [baseSeq, setBaseSeq] = useState(location?.state?.baseSeq || '');

  const [renew, setRenew] = useState(0);
  const [result, setResult] = useState({});

  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);

  const [infoform, setInfoform] = useState();

  useEffect(() => {
    setInfoform($("form[name='infoform']")[0]);

    // $('.temp-img-del-btn').on('click', (e) => {
    //   temp_img_del($(this).attr('val1'), $(this).attr('val2'), $(this).attr('val3'));
    // })

    $('#base_seq').val(baseSeq);
  }, []);

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    const infoform = $("form[name='infoform']")[0];

    axios2
      .post(`/branchop/branch/reg/ready?base_seq=${baseSeq || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        console.log(result);
        if (code === 200) {
          $('#b_pic').empty();

          if ($('#brand').html() === '') {
            let optionsHTML = '<option value="">선택</option>';
            result?.b_list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#brand').html(optionsHTML);
          }
          if ($('#country_code').html() === '') {
            let optionsHTML = '<option value="">선택</option>';
            result?.list?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.sub_code}">${list_row.sub_code_name}</option>`;
            });
            $('#country_code').html(optionsHTML);
          }
          if ($('#jibu').html() === '') {
            let optionsHTML = '<option value="">선택</option>';
            result?.jibu_rs?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.jibu}">${list_row.jibu}</option>`;
            });
            optionsHTML += '<option value="w">직접입력</option>';
            $('#jibu').html(optionsHTML);
          }

          if (baseSeq) {
            setResult(result);

            infoform.base_seq.value = baseSeq;
            infoform.branch_code.value = result?.branch_code || '';

            $('#brand').val(result?.brand || '');
            $('#country_code').val(result?.country_code || '');
            $('#branch_code').val(result?.branch_code || '');
            $('#jibu').val(result?.jibu || '');
            $('#show_out').val(result?.show_out || '');
            $('#show_YN').val(result?.show_YN || '');
            $('#opt1').prop("checked", result?.opt1 === 'Y');
            $('#opt2').prop("checked", result?.opt2 === 'Y');
            $('#opt3').prop("checked", result?.opt3 === 'Y');
            $('#opt4').prop("checked", result?.opt4 === 'Y');
            $('#opt5').prop("checked", result?.opt5 === 'Y');
            $('#opt6').prop("checked", result?.opt6 === 'Y');
            $('#opt7').prop("checked", result?.opt7 === 'Y');
            $('#opt8').prop("checked", result?.opt8 === 'Y');
            $('#opt9').prop("checked", result?.opt9 === 'Y');
            $('#opt10').prop("checked", result?.opt10 === 'Y');
            $('#target_sun_price').val(result?.target_sun_price || '');
            $('#difer_rate').val(result?.difer_rate || '');
            $('#branch_tel').val(result?.branch_tel || '');
            $('#pt_tel').val(result?.pt_tel || '');
            $('#TID').val(result?.TID || '');
            $('#K_TID').val(result?.K_TID || '');
            $('#MID').val(result?.MID || '');
            $('#sign_key').val(result?.sign_key || '');
            $('#sign_key_nicepay').val(result?.sign_key_nicepay || '');
            $('#mall_id').val(result?.mall_id || '');
            $('#fran_YN').prop("checked", result?.fran_YN === 'Y');
            $('#fran_rate').val(result?.fran_rate || '');

            $('#account_bank').val(result?.account_bank || '');
            $('#account_number').val(result?.account_number || '');
            $('#tid1').val(result?.tid1 || '');
            $('#tid2').val(result?.tid2 || '');
            $('#tid3').val(result?.tid3 || '');
            $('#o_mid').val(result?.o_mid || '');
            $('#o_merchantKey').val(result?.o_merchantKey || '');
            $('#nice_mid').val(result?.nice_mid || '');
            $('#nice_merchantKey').val(result?.nice_merchantKey || '');

            $('#h_range1').val(result?.h_range1 || '');
            $('#h_range2').val(result?.h_range2 || '');

            $('#jum_tel').val(result?.jum_tel || '');
            $('#ceo').val(result?.ceo || '');
            $('#tel').val(result?.tel || '');
            $('#branch_state').val(result?.branch_state || '');
            $('#region').val(result?.region || '');
            $('#open_date').val(result?.open_date || '');
            $('#scale').val(result?.scale || '');
            $('#close_date').val(result?.close_date || '');
            $('#company_no').val(result?.company_no || '');
            $('#address').val(result?.address || '');
            $('#address2').val(result?.address2 || '');
            $('#coord_x').val(result?.coord_x || '');
            $('#coord_y').val(result?.coord_y || '');
            $('#intro').val(result?.intro || '');
            $('#blog').val(result?.blog || '');
            $('#hompage').val(result?.hompage || '');
            $('#nomal_open_hour').val(result?.nomal_open_hour || '');
            $('#nomal_open_minute').val(result?.nomal_open_minute || '');
            $('#nomal_close_hour').val(result?.nomal_close_hour || '');
            $('#nomal_close_minute').val(result?.nomal_close_minute || '');
            $('#to_open_hour').val(result?.to_open_hour || '');
            $('#to_open_minute').val(result?.to_open_minute || '');
            $('#to_close_hour').val(result?.to_close_hour || '');
            $('#to_close_minute').val(result?.to_close_minute || '');
            $('#holi_open_hour').val(result?.holi_open_hour || '');
            $('#holi_open_minute').val(result?.holi_open_minute || '');
            $('#holi_close_hour').val(result?.holi_close_hour || '');
            $('#holi_close_minute').val(result?.holi_close_minute || '');
            $('#regular_rest').val(result?.regular_rest || '');

            $('#m1_health').val(result?.m1_health || '');
            $('#m3_health').val(result?.m3_health || '');
            $('#m6_health').val(result?.m6_health || '');
            $('#m12_health').val(result?.m12_health || '');

            $('#m_etc_name').val(result?.m_etc_name || '');
            $('#m1_etc').val(result?.m1_etc || '');
            $('#m3_etc').val(result?.m3_etc || '');
            $('#m6_etc').val(result?.m6_etc || '');
            $('#m12_etc').val(result?.m12_etc || '');

            $('#m1_shirt').val(result?.m1_shirt || '');
            $('#m3_shirt').val(result?.m3_shirt || '');
            $('#m6_shirt').val(result?.m6_shirt || '');
            $('#m12_shirt').val(result?.m12_shirt || '');

            $('#m1_locker').val(result?.m1_locker || '');
            $('#m3_locker').val(result?.m3_locker || '');
            $('#m6_locker').val(result?.m6_locker || '');
            $('#m12_locker').val(result?.m12_locker || '');

            $('#price_info').val(result?.price_info || '');
            $('#pt1_price').val(result?.pt1_price || '');
            $('#pt2_price').val(result?.pt2_price || '');
            $('#pt3_price').val(result?.pt3_price || '');
            $('#pt_info').val(result?.pt_info || '');

            $('#attention').val(result?.attention || '');
            $('#traffic').val(result?.traffic || '');
            $('#parking').val(result?.parking || '');
            $('#bran').val(result?.parking || '');
            $('#parking').val(result?.parking || '');

            $('#branch_name').val(result?.branch_name || '');
            $('#ho').val(result?.ho || '');

            {
              $('.p_list_cont').remove();

              let cont = '';

              cont += '<tr class="p_list_cont">';

              let selectedConvList = '|' + result?.conv_list;
              let pList = result?.p_list || [];

              let pCnt = 0;

              pList.forEach((pListRow) => {
                pCnt++;
                let bConvList = '|' + pListRow.sub_code + '|';
                let selected = selectedConvList.includes(bConvList) ? 'checked' : '';

                cont += '<td>';
                cont += '<div class="form-check">';
                cont += `<input type="checkbox" name="conv[${pCnt}]" value="${pListRow.sub_code}" class="checkbox" id="conv${pCnt}" ${selected}>`;
                cont += `<label for="conv${pCnt}"><i style={{verticalAlign: "bottom"}}><img src=${Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=conv_img&filename=" + encodeURIComponent(pListRow?.img_name))} style='max-width: 25px'/></i> ${pListRow.sub_code_name}</label>`;
                cont += '</div>';
                cont += '</td>';

                if (pCnt % 5 === 0) {
                  cont += '</tr><tr class="p_list_cont">';
                }
              });

              cont += '</tr>';

              $('#p_list_area').after(cont);
            }
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const go_submit = () => {
    if ($('#brand').val() === "") {
      alert('브랜드를 선택해주세요');
      $('#brand').focus();
      return;
    }
    if ($('#branch_code').val() === "") {
      alert('지점코드를 입력해주세요');
      $('#branch_code').focus();
      return;
    }
    if ($('#country_code').val() === "") {
      alert('국가를 선택해주세요');
      $('#country_code').focus();
      return;
    }
    if ($('#branch_name').val() === "") {
      alert('지점명을 입력해주세요');
      $('#branch_name').focus();
      return;
    }

    const formData = new FormData();

    formData.append("base_seq", $('#base_seq').val() || '');
    formData.append("brand", $('#brand option:selected').val() || '');
    formData.append("country_code", $('#country_code option:selected').val() || '');
    formData.append("branch_code", $.trim($('#branch_code').val()) || '');
    formData.append("jibu", $('#jibu option:selected').val() || '');
    formData.append("jibu_txt", $('#jibu_txt').val() || '');
    formData.append("show_out", $('#show_out').val() || '');
    formData.append("show_YN", $('#show_YN').val() || '');
    formData.append("opt1", $('#opt1:checked').val() || '');
    formData.append("opt2", $('#opt2:checked').val() || '');
    formData.append("opt3", $('#opt3:checked').val() || '');
    formData.append("opt4", $('#opt4:checked').val() || '');
    formData.append("opt5", $('#opt5:checked').val() || '');
    formData.append("opt6", $('#opt6:checked').val() || '');
    formData.append("opt7", $('#opt7:checked').val() || '');
    formData.append("opt8", $('#opt8:checked').val() || '');
    formData.append("opt9", $('#opt9:checked').val() || '');
    formData.append("opt10", $('#opt10:checked').val() || '');
    formData.append("target_sun_price", $('#target_sun_price').val() || '');
    formData.append("difer_rate", $('#difer_rate').val() || '');

    if (baseSeq)
      formData.append("branch_tel", $('#tel').val() || '');
    else
      formData.append("branch_tel", $('#branch_tel').val() || '');

    formData.append("pt_tel", $('#pt_tel').val() || '');
    formData.append("TID", $('#TID').val() || '');
    formData.append("K_TID", $('#K_TID').val() || '');
    formData.append("MID", $('#MID').val() || '');
    formData.append("sign_key", $('#sign_key').val() || '');
    formData.append("sign_key_nicepay", $('#sign_key_nicepay').val() || '');
    formData.append("mall_id", $('#mall_id').val() || '');
    formData.append("fran_YN", $('#fran_YN:checked').val() || '');
    formData.append("fran_rate", $('#fran_rate').val() || '');
    formData.append("account_bank", $('#account_bank option:selected').val() || '');
    formData.append("account_number", $('#account_number').val() || '');
    formData.append("tid1", $('#tid1').val() || '');
    formData.append("tid2", $('#tid2').val() || '');
    formData.append("tid3", $('#tid3').val() || '');
    formData.append("o_mid", $('#o_mid').val() || '');
    formData.append("o_merchantKey", $('#o_merchantKey').val() || '');
    formData.append("nice_mid", $('#nice_mid').val() || '');
    formData.append("nice_merchantKey", $('#nice_merchantKey').val() || '');
    formData.append("h_range1", $('#h_range1').val() || '');
    formData.append("h_range2", $('#h_range2').val() || '');
    formData.append("jum_tel", $('#jum_tel').val() || '');
    formData.append("ceo", $('#ceo').val() || '');
    formData.append("tel", $('#tel').val() || '');
    formData.append("branch_state", $('#branch_state option:selected').val() || '');
    formData.append("region", $('#region option:selected').val() || '');
    formData.append("open_date", $('#open_date').val() || '');
    formData.append("scale", $('#scale').val() || '');
    formData.append("close_date", $('#close_date').val() || '');
    formData.append("company_no", $('#company_no').val() || '');
    formData.append("address", $('#address').val() || '');
    formData.append("address2", $('#address2').val() || '');
    formData.append("coord_x", $('#coord_x').val() || '');
    formData.append("coord_y", $('#coord_y').val() || '');
    formData.append("intro", $('#intro').val() || '');
    formData.append("blog", $('#blog').val() || '');
    formData.append("hompage", $('#hompage').val() || '');
    formData.append("nomal_open_hour", $('#nomal_open_hour option:selected').val() || '');
    formData.append("nomal_open_minute", $('#nomal_open_minute option:selected').val() || '');
    formData.append("nomal_close_hour", $('#nomal_close_hour option:selected').val() || '');
    formData.append("nomal_close_minute", $('#nomal_close_minute option:selected').val() || '');
    formData.append("to_open_hour", $('#to_open_hour option:selected').val() || '');
    formData.append("to_open_minute", $('#to_open_minute option:selected').val() || '');
    formData.append("to_close_hour", $('#to_close_hour option:selected').val() || '');
    formData.append("to_close_minute", $('#to_close_minute option:selected').val() || '');
    formData.append("holi_open_hour", $('#holi_open_hour option:selected').val() || '');
    formData.append("holi_open_minute", $('#holi_open_minute option:selected').val() || '');
    formData.append("holi_close_hour", $('#holi_close_hour option:selected').val() || '');
    formData.append("holi_close_minute", $('#holi_close_minute option:selected').val() || '');
    formData.append("regular_rest", $('#regular_rest').val() || '');
    formData.append("m1_health", $('#m1_health').val() || '');
    formData.append("m3_health", $('#m3_health').val() || '');
    formData.append("m6_health", $('#m6_health').val() || '');
    formData.append("m12_health", $('#m12_health').val() || '');
    formData.append("m_etc_name", $('#m_etc_name').val() || '');
    formData.append("m1_etc", $('#m1_etc').val() || '');
    formData.append("m3_etc", $('#m3_etc').val() || '');
    formData.append("m6_etc", $('#m6_etc').val() || '');
    formData.append("m12_etc", $('#m12_etc').val() || '');
    formData.append("m1_shirt", $('#m1_shirt').val() || '');
    formData.append("m3_shirt", $('#m3_shirt').val() || '');
    formData.append("m6_shirt", $('#m6_shirt').val() || '');
    formData.append("m12_shirt", $('#m12_shirt').val() || '');
    formData.append("m1_locker", $('#m1_locker').val() || '');
    formData.append("m3_locker", $('#m3_locker').val() || '');
    formData.append("m6_locker", $('#m6_locker').val() || '');
    formData.append("m12_locker", $('#m12_locker').val() || '');
    formData.append("price_info", $('#price_info').val() || '');
    formData.append("pt1_price", $('#pt1_price').val() || '');
    formData.append("pt2_price", $('#pt2_price').val() || '');
    formData.append("pt3_price", $('#pt3_price').val() || '');
    formData.append("pt_info", $('#pt_info').val() || '');
    formData.append("attention", $('#attention').val() || '');
    formData.append("traffic", $('#traffic').val() || '');
    formData.append("parking", $('#parking').val() || '');
    formData.append("branch_name", $('#branch_name').val() || '');
    formData.append("ho", $('#ho').val() || '');

    axios2.postFormEx('/branchop/branch/reg', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        const res_data = data.split("||");
        const return_value = res_data[0];
        const base_seq = res_data[1];
        if (data === "err") {
          alert('오류가 발생하였습니다');
        } else if (data === "dup_branch_code") {
          alert("동일한 거래처코드가 사용중입니다");
        } else if (return_value === "insert_ok") {
          alert("등록되었습니다.");
          $('#base_seq').val(base_seq);
          setBaseSeq(base_seq);
          // TODO : PTODO -> 개선필요
          // setRenew(Fn.getRenewTime());
          window.location.reload();
        } else if (return_value === "edit_ok") {
          alert("수정되었습니다");
          $('#base_seq').val(base_seq);
          setBaseSeq(base_seq);
          // TODO : PTODO -> 개선필요
          // setRenew(Fn.getRenewTime());
          window.location.reload();
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  window.temp_img_del = (branch_code, temp_seq, d_id) => {
    $('#' + d_id).remove();
    // Delete from temp DB
    $.ajax({
      type: "post",
      url: "/branchop/img/tempsave/del",
      data: "branch_code=" + branch_code + "&temp_seq=" + temp_seq,
      success: function (data) {
        //alert(data); return;
      }
    });
  };

//  4-1.
  const jibu_chk = (v) => {
    if (v === "w") {
      $("#jibu_span").show();
      $("#jibu_txt").focus();
    } else {
      $("#jibu_span").hide();
    }
  };

  const ajaxFileChange = () => {
    ajaxFileTransmit();
  };

  const ajaxFileTransmit = () => {
    const formData = new FormData(infoform);
    $.ajax({
      url: '/branchop/img/tempsave',
      processData: false,
      contentType: false,
      data: formData,
      type: 'POST',
      success: function (data) {
        console.log(data);
        // data = data.trim();
        //alert(data);
        $('#b_pic').append(`
            <div style="display: flex; flex-direction: column; justify-content: center; align-content: center; align-items: center;" id="${data?.result?.div_id}">
              <img class="IMG_thum m-1" src=${Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=branch_img_tempsave&filename=" + encodeURIComponent(data?.result?.brand_img))} alt="spoAny" style="object-fit: cover"/>
              <button type="button" class="temp-img-del-btn btn btn-ssm btn-outline-dark" style="max-width: 70px" onclick="window.temp_img_del('${infoform.branch_code.value}', '${data?.result?.last_row?.seq}', '${data?.result?.div_id}');">삭제</button>
            </div>
        `);
      }
    });
  };

//  7-1.
  const test = () => {
    const data = (
      <div className="col-sm-3 col-md-3" id="dd">
        <div className="thumbnail">
          <img src="" alt="spoAny" width="211" height="140"/>
          <div className="caption">
            <p className="text-center">
              <a className="btn btn-danger btn-xs">
                <i className="fas fa-trash-alt"></i> 삭제
              </a>
            </p>
          </div>
        </div>
      </div>
    );
    $('#b_pic').append(data);
  };

//  9-1.
  const go_info = () => {

    const formData = new FormData();

    formData.append("base_seq", $('#base_seq').val() || '');
    formData.append("brand", $('#brand option:selected').val() || '');
    formData.append("country_code", $('#country_code option:selected').val() || '');
    formData.append("branch_code", $('#branch_code').val() || '');
    formData.append("jibu", $('#jibu option:selected').val() || '');
    formData.append("show_out", $('#show_out').val() || '');
    formData.append("address", $('#address').val() || '');
    formData.append("address2", $('#address2').val() || '');
    formData.append("show_out", $('#show_out').val() || '');
    formData.append("show_YN", $('#show_YN').val() || '');
    formData.append("opt1", $('#opt1:checked').val() || '');
    formData.append("opt2", $('#opt2:checked').val() || '');
    formData.append("opt3", $('#opt3:checked').val() || '');
    formData.append("opt4", $('#opt4:checked').val() || '');
    formData.append("opt5", $('#opt5:checked').val() || '');
    formData.append("opt6", $('#opt6:checked').val() || '');
    formData.append("opt7", $('#opt7:checked').val() || '');
    formData.append("opt8", $('#opt8:checked').val() || '');
    formData.append("opt9", $('#opt9:checked').val() || '');
    formData.append("opt10", $('#opt10:checked').val() || '');
    formData.append("target_sun_price", $('#target_sun_price').val() || '');
    formData.append("difer_rate", $('#difer_rate').val() || '');

    if (baseSeq)
      formData.append("branch_tel", $('#tel').val() || '');
    else
      formData.append("branch_tel", $('#branch_tel').val() || '');

    formData.append("pt_tel", $('#pt_tel').val() || '');
    formData.append("TID", $('#TID').val() || '');
    formData.append("K_TID", $('#K_TID').val() || '');
    formData.append("MID", $('#MID').val() || '');
    formData.append("sign_key", $('#sign_key').val() || '');
    formData.append("sign_key_nicepay", $('#sign_key_nicepay').val() || '');
    formData.append("mall_id", $('#mall_id').val() || '');
    formData.append("fran_YN", $('#fran_YN:checked').val() || '');
    formData.append("fran_rate", $('#fran_rate').val() || '');
    formData.append("account_bank", $('#account_bank option:selected').val() || '');
    formData.append("account_number", $('#account_number').val() || '');
    formData.append("tid1", $('#tid1').val() || '');
    formData.append("tid2", $('#tid2').val() || '');
    formData.append("tid3", $('#tid3').val() || '');
    formData.append("o_mid", $('#o_mid').val() || '');
    formData.append("o_merchantKey", $('#o_merchantKey').val() || '');
    formData.append("nice_mid", $('#nice_mid').val() || '');
    formData.append("nice_merchantKey", $('#nice_merchantKey').val() || '');
    formData.append("h_range1", $('#h_range1').val() || '');
    formData.append("h_range2", $('#h_range2').val() || '');
    formData.append("jum_tel", $('#jum_tel').val() || '');
    formData.append("ceo", $('#ceo').val() || '');
    formData.append("tel", $('#tel').val() || '');
    formData.append("branch_state", $('#branch_state option:selected').val() || '');
    formData.append("region", $('#region option:selected').val() || '');
    formData.append("open_date", $('#open_date').val() || '');
    formData.append("scale", $('#scale').val() || '');
    formData.append("close_date", $('#close_date').val() || '');
    formData.append("company_no", $('#company_no').val() || '');
    formData.append("address", $('#address').val() || '');
    formData.append("address2", $('#address2').val() || '');
    formData.append("coord_x", $('#coord_x').val() || '');
    formData.append("coord_y", $('#coord_y').val() || '');
    formData.append("intro", $('#intro').val() || '');
    formData.append("blog", $('#blog').val() || '');
    formData.append("hompage", $('#hompage').val() || '');
    formData.append("nomal_open_hour", $('#nomal_open_hour option:selected').val() || '');
    formData.append("nomal_open_minute", $('#nomal_open_minute option:selected').val() || '');
    formData.append("nomal_close_hour", $('#nomal_close_hour option:selected').val() || '');
    formData.append("nomal_close_minute", $('#nomal_close_minute option:selected').val() || '');
    formData.append("to_open_hour", $('#to_open_hour option:selected').val() || '');
    formData.append("to_open_minute", $('#to_open_minute option:selected').val() || '');
    formData.append("to_close_hour", $('#to_close_hour option:selected').val() || '');
    formData.append("to_close_minute", $('#to_close_minute option:selected').val() || '');
    formData.append("holi_open_hour", $('#holi_open_hour option:selected').val() || '');
    formData.append("holi_open_minute", $('#holi_open_minute option:selected').val() || '');
    formData.append("holi_close_hour", $('#holi_close_hour option:selected').val() || '');
    formData.append("holi_close_minute", $('#holi_close_minute option:selected').val() || '');
    formData.append("regular_rest", $('#regular_rest').val() || '');
    formData.append("m1_health", $('#m1_health').val() || '');
    formData.append("m3_health", $('#m3_health').val() || '');
    formData.append("m6_health", $('#m6_health').val() || '');
    formData.append("m12_health", $('#m12_health').val() || '');
    formData.append("m_etc_name", $('#m_etc_name').val() || '');
    formData.append("m1_etc", $('#m1_etc').val() || '');
    formData.append("m3_etc", $('#m3_etc').val() || '');
    formData.append("m6_etc", $('#m6_etc').val() || '');
    formData.append("m12_etc", $('#m12_etc').val() || '');
    formData.append("m1_shirt", $('#m1_shirt').val() || '');
    formData.append("m3_shirt", $('#m3_shirt').val() || '');
    formData.append("m6_shirt", $('#m6_shirt').val() || '');
    formData.append("m12_shirt", $('#m12_shirt').val() || '');
    formData.append("m1_locker", $('#m1_locker').val() || '');
    formData.append("m3_locker", $('#m3_locker').val() || '');
    formData.append("m6_locker", $('#m6_locker').val() || '');
    formData.append("m12_locker", $('#m12_locker').val() || '');
    formData.append("price_info", $('#price_info').val() || '');
    formData.append("pt1_price", $('#pt1_price').val() || '');
    formData.append("pt2_price", $('#pt2_price').val() || '');
    formData.append("pt3_price", $('#pt3_price').val() || '');
    formData.append("pt_info", $('#pt_info').val() || '');
    formData.append("attention", $('#attention').val() || '');
    formData.append("traffic", $('#traffic').val() || '');
    formData.append("parking", $('#parking').val() || '');
    formData.append("branch_name", $('#branch_name').val() || '');
    formData.append("ho", $('#ho').val() || '');

    formData.append("p_cnt", result?.p_list?.length || 0);
    for (let i = 1; i <= result?.p_list?.length; i++) {
      formData.append("conv_" + i, $('#conv' + i + ":checked").val());
    }

    axios2.postFormEx('/branchop/branch/info', formData)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        const res_data = data.split("||");
        const return_value = res_data[0];
        const base_seq = res_data[1];

        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (return_value == "insert_ok") {
          alert("등록되었습니다.");
          setBaseSeq(base_seq);
          setRenew(Fn.getRenewTime());
        } else if (return_value == "edit_ok") {
          alert("수정되었습니다");
          // TODO -> 반드시 renew 로 수정
          window.location.reload();
          // setBaseSeq(base_seq);
          // setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const go_lec = () => {
    if ($('#start_hour').val() === "") {
      alert("강좌시작시간을 선택해주세요");
      $('#start_hour').focus();
      return;
    }
    if ($('#start_minute').val() === "") {
      alert("강좌시작시간을 선택해주세요");
      $('#start_minute').focus();
      return;
    }
    if (
      !$('#day1').is(":checked") &&
      !$('#day2').is(":checked") &&
      !$('#day3').is(":checked") &&
      !$('#day4').is(":checked") &&
      !$('#day5').is(":checked") &&
      !$('#day6').is(":checked") &&
      !$('#day7').is(":checked")
    ) {
      alert("요일을 선택해주세요");
      return;
    }
    if ($('#lec_name').val() === "") {
      alert("강좌명을 입력해주세요");
      $('#lec_name').focus();
      return;
    }

    axios2.postEx("/branchop/branch/group/lec", {
      start_hour: $('#start_hour').val() || '',
      start_minute: $('#start_minute').val() || '',
      day1: $('#day1').is(":checked") ? "Y" : "",
      day2: $('#day2').is(":checked") ? "Y" : "",
      day3: $('#day3').is(":checked") ? "Y" : "",
      day4: $('#day4').is(":checked") ? "Y" : "",
      day5: $('#day5').is(":checked") ? "Y" : "",
      day6: $('#day6').is(":checked") ? "Y" : "",
      day7: $('#day7').is(":checked") ? "Y" : "",
      lec_name: $('#lec_name').val() || '',
      lec_seq: $('#lec_seq').val() || '',
      base_seq: $('#base_seq').val() || '',
      branch_code: $('#branch_code').val() || ''
    })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        console.log(data, result);
        alert('저장되었습니다');
        setRenew(Fn.getRenewTime());
        $('#lec_seq').val('');
        $('#start_hour').val('');
        $('#start_minute').val('');
        $('#day1').prop("checked", false);
        $('#day2').prop("checked", false);
        $('#day3').prop("checked", false);
        $('#day4').prop("checked", false);
        $('#day5').prop("checked", false);
        $('#day6').prop("checked", false);
        $('#day7').prop("checked", false);
        $('#lec_name').val('');
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      })
      .finally(() => {
      });
  }

//  11-1.
  const del_lec = (seq) => {
    if (window.confirm('그룹강좌를 삭제하시겠습니까?')) {
      axios2
        .post(`/branchop/lec/del?seq=${seq}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
            $('#lec_seq').val('');
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  const edit_lec = (seq, start_hour, start_minute, day1, day2, day3, day4, day5, day6, day7, lec_name) => {
    $('#lec_seq').val(seq);
    $('#start_hour').val(start_hour);
    $('#start_minute').val(start_minute);
    $('#lec_name').val(lec_name);
    $('#day1').prop("checked", day1 === "Y");
    $('#day2').prop("checked", day2 === "Y");
    $('#day3').prop("checked", day3 === "Y");
    $('#day4').prop("checked", day4 === "Y");
    $('#day5').prop("checked", day5 === "Y");
    $('#day6').prop("checked", day6 === "Y");
    $('#day7').prop("checked", day7 === "Y");
  };

//  13-1.
// const documentReady = (event) => {
//     const {value, name} = event.target;
//     // TODO : 입력값 처리 로직 추가
//     // 숫자만 체크
//     $(".numberOnly").keyup(function () {
//       /* 48~57:일반 숫자키 코드, 96~105:숫자키패드 숫자키 코드 , 8 : backspace, 9 : tab ,46 : delete, 37 : 왼쪽방향키 , 39 : 오른쪽방향키 */
//       var keyID = event.which;
//
//       if ((keyID >= 48 && keyID <= 57) || (keyID >= 96 && keyID <= 105) || keyID == 8 || keyID == 9 || keyID == 46 || keyID == 37 || keyID == 39) {
//       } else {
//         this.value = this.value.replace(/[^0-9\.]/g, ''); //숫자를 제외한 문자를 지워준다.
//       }
//     });
//     $(".nodda").keyup(function () {
//       /* 48~57:일반 숫자키 코드, 96~105:숫자키패드 숫자키 코드 , 8 : backspace, 9 : tab ,46 : delete, 37 : 왼쪽방향키 , 39 : 오른쪽방향키 */
//       var keyID = event.which;
//       if (keyID == 222) {
//         this.value = this.value.replace(/['"\{\}\[\]\|\*~`!^\+\#$%&\\\=\\'\"]/g, ''); //숫자를 제외한 문자를 지워준다.
//       }
//     });
//     $(".notHangul").keyup(function () {
//         //영문,숫자,하이픈,언더바만 허용
//         var keyID = event.which;
//         if (!(keyID >= 37 && keyID <= 40)) {
//             this.value = this.value.replace(/[^a-z0-9:\-_.@]/gi, ''); //숫자를 제외한 문자를 지워준다.
//         }
//     });
// };

  const hon_change = () => {
    const h_range1 = $("#h_range1").val();
    const h_range2 = $("#h_range2").val();

    axios2
      .post('/branchop/hon/change?base_seq=' + baseSeq + '&h_range1=' + h_range1 + '&h_range2=' + h_range2)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          alert('적용되었습니다');
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  function loadModal1() {
    if ($('#m1_base_seq').val()) $('.btn-m1-del').show();
    else $('.btn-m1-del').hide();

    axios2
      .post(`/branchop/add/mid/ready?base_seq=${$('#m1_base_seq').val() || ''}&branch_code=${$('#m1_branch_code').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);

          $('#m1_as_name').val(result?.row?.as_name || '');
          $('#m1_MID').val(result?.row?.MID || '');
          $('#m1_sign_key').val(result?.row?.sign_key || '');
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit1() {
    $('#m1_del_YN').val('');

    if ($('#m1_as_name').val() == "") {
      alert("지점별칭을 입력해주세요");
      $('#m1_as_name').focus();
      return;
    }
    if ($('#m1_MID').val() == "") {
      alert("MID를 입력해주세요");
      $('#m1_MID').focus();
      return;
    }
    if ($('#m1_sign_key').val() == "") {
      alert("서명키를 입력해주세요");
      $('#m1_sign_key').focus();
      return;
    }

    axios2.postEx("/branchop/add/mid", {
      base_seq: $('#m1_base_seq').val() || '',
      branch_code: $('#m1_branch_code').val() || '',
      as_name: $('#m1_as_name').val() || '',
      MID: $('#m1_MID').val() || '',
      sign_key: $('#m1_sign_key').val() || '',
      del_YN: $('#m1_del_YN').val() || '',
    })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        console.log(data, result);
        alert('저장되었습니다');
        setRenew(Fn.getRenewTime());
        setModal1Open(false);
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      })
      .finally(() => {
      });
  }

  function go_del1() {
    $('#m1_del_YN').val("Y");

    axios2.postEx("/branchop/add/mid", {
      base_seq: $('#m1_base_seq').val() || '',
      branch_code: $('#m1_branch_code').val() || '',
      del_YN: $('#m1_del_YN').val() || '',
    })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        console.log(data, result);
        alert('삭제되었습니다');
        setRenew(Fn.getRenewTime());
        setModal1Open(false);
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      })
      .finally(() => {
      });
  }

  function loadModal2() {
    if ($('#m2_base_seq').val()) $('.btn-m2-del').show();
    else $('.btn-m2-del').hide();

    axios2
      .post(`/branchop/add/mid/nicepay/ready?base_seq=${$('#m2_base_seq').val() || ''}&branch_code=${$('#m2_branch_code').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);

          $('#m2_as_name').val(result?.row?.as_name || '');
          $('#m2_MID').val(result?.row?.MID || '');
          $('#m2_sign_key').val(result?.row?.sign_key || '');
          $('#m2_mall_id').val(result?.row?.mall_id || '');
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit2() {
    $('#m2_del_YN').val('');

    if ($('#m2_as_name').val() == "") {
      alert("지점별칭을 입력해주세요");
      $('#m2_as_name').focus();
      return;
    }
    if ($('#m2_MID').val() == "") {
      alert("MID를 입력해주세요");
      $('#m2_MID').focus();
      return;
    }
    if ($('#m2_sign_key').val() == "") {
      alert("서명키를 입력해주세요");
      $('#m2_sign_key').focus();
      return;
    }
    if ($('#m2_mall_id').val() == "") {
      alert("상점아이디를 입력해주세요");
      $('#m2_mall_id').focus();
      return;
    }

    axios2.postEx("/branchop/add/mid/nicepay", {
      base_seq: $('#m2_base_seq').val() || '',
      branch_code: $('#m2_branch_code').val() || '',
      as_name: $('#m2_as_name').val() || '',
      MID: $('#m2_MID').val() || '',
      sign_key: $('#m2_sign_key').val() || '',
      mall_id: $('#m2_mall_id').val() || '',
      del_YN: $('#m2_del_YN').val() || '',
    })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        console.log(data, result);
        alert('저장되었습니다');
        setRenew(Fn.getRenewTime());
        setModal2Open(false);
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      })
      .finally(() => {
      });
  }

  function go_del2() {
    $('#m2_del_YN').val("Y");

    axios2.postEx("/branchop/add/mid/nicepay", {
      base_seq: $('#m2_base_seq').val() || '',
      branch_code: $('#m2_branch_code').val() || '',
      del_YN: $('#m2_del_YN').val() || '',
    })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        console.log(data, result);
        alert('삭제되었습니다');
        setRenew(Fn.getRenewTime());
        setModal2Open(false);
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      })
      .finally(() => {
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3 mb-4 search_box">
        <div className="border-bottom text-left">
          <p className="px-3 py-2">
            <span className="h6 bold700">지점 기본정보</span>
          </p>
        </div>
        <div className="p-3">
          <table className="view-rounded sub_view_sm">
            <colgroup>
              <col width="135px"/>
              <col width="*"/>
              <col width="135px"/>
              <col width="*"/>
              <col width="135px"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>브랜드</th>
              <td className="text-left">
                <select className="form-select" aria-label="Default select example" style={{maxWidth: "32%"}} id="brand">
                </select>
              </td>
              <th>지점코드</th>
              <td className="text-left">
                {baseSeq ?
                  <span>{result?.branch_code}</span>
                  :
                  <input type="text" className="form-control table-inline" style={{maxWidth: "170px"}} id="branch_code"/>
                }
              </td>
              <th>국가</th>
              <td className="text-left">
                <select className="form-select" aria-label="Default select example" style={{maxWidth: "32%"}} id="country_code">
                </select>
              </td>
            </tr>
            <tr>
              <th>지점명/호</th>
              <td>
                <div className="input-group">
                  <input type="text" className="form-control table-inline" style={{maxWidth: "180px"}} id="branch_name"/>
                  <p className="m-1 mx-2">/</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "50px"}} aria-describedby="button-addon2" id="ho"/>
                  <p className="m-1">호점</p>
                </div>
              </td>
              <th>지부명</th>
              <td className="text-left">
                <div className="input-group">
                  <select className="form-select mr-1" aria-label="Default select example" style={{maxWidth: "32%"}}
                          id="jibu" onChange={(e) => jibu_chk(e.target.value)}
                  >
                  </select>
                  &nbsp; <span id="jibu_span" style={{display: "none", maxWidth: '32%'}}>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "100%"}} id="jibu_txt"/>
                  </span>
                </div>
              </td>
              <th>전시순서</th>
              <td>
                <input type="text" className="form-control" aria-describedby="button-addon2" style={{maxWidth: "32%"}} id="show_out"/>
              </td>
            </tr>
            <tr>
              <th>골프</th>
              <td>
                <input type="checkbox" className="form-control" value="Y" id="opt1"/>
              </td>
              <th>골프라커</th>
              <td>
                <input type="checkbox" className="form-control" value="Y" id="opt2"/>
              </td>
              <th>스쿼시</th>
              <td>
                <input type="checkbox" className="form-control" value="Y" id="opt3"/>
              </td>
            </tr>
            <tr>
              <th>스피닝</th>
              <td>
                <input type="checkbox" className="form-control" value="Y" id="opt4"/>
              </td>
              <th>태닝</th>
              <td>
                <input type="checkbox" className="form-control" value="Y" id="opt5"/>
              </td>
              <th>토탈</th>
              <td>
                <input type="checkbox" className="form-control" value="Y" id="opt6"/>
              </td>
            </tr>
            <tr>
              <th>필라테스</th>
              <td>
                <input type="checkbox" className="form-control" value="Y" id="opt7"/>
              </td>
              <th>OPT1</th>
              <td>
                <input type="checkbox" className="form-control" value="Y" id="opt8"/>
              </td>
              <th>OPT2</th>
              <td>
                <input type="checkbox" className="form-control" value="Y" id="opt9"/>
              </td>
            </tr>
            <tr>
              <th>OPT3</th>
              <td>
                <input type="checkbox" className="form-control" value="Y" id="opt10"/>
              </td>
              <th>수수료 관련사항</th>
              <td colSpan="3">
                <div className="input-group">
                  <p className="m-1 bold700">지점순익Target :</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "150px"}} id="target_sun_price"
                         onKeyPress={(e) => {
                           return Fn.numkeyCheck(e);
                         }}
                         onKeyUp={(e) => Fn.vComma(e.target)}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <p className="m-1 bold700">영업컨설팅 유보금% :</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="difer_rate"
                         onKeyPress={(e) => {
                           return Fn.numkeyCheck(e);
                         }}
                         onKeyUp={(e) => Fn.vComma(e.target)}
                  />
                  <p className="m-1 bold700">%</p>
                </div>
              </td>
            </tr>
            <tr>
              <th>SMS발신번호</th>
              <td>
                {baseSeq ?
                  <input type="text" className="form-control" aria-describedby="button-addon2" id="branch_tel" style={{maxWidth: "200px"}} readOnly={true}/>
                  :
                  <input type="text" className="form-control" aria-describedby="button-addon2" id="branch_tel" style={{maxWidth: "200px"}}/>
                }
              </td>
              <th>POS 단말기번호</th>
              <td colSpan="3">
                <div className="input-group">
                  <p className="m-1 bold700">인포데스크 :</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "150px"}} id="TID" maxLength="20"/>
                  &nbsp;&nbsp;&nbsp;
                  <p className="m-1 bold700">키오스크 :</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "150px"}} id="K_TID" maxLength="20"/>
                </div>
              </td>
            </tr>
            <tr>
              <th>PT발신번호</th>
              <td>
                <input type="text" className="form-control" aria-describedby="button-addon2" id="pt_tel" style={{maxWidth: "200px"}}/>
              </td>
              <th>POS MID</th>
              <td colSpan="3">
                <div className="input-group">
                  <input type="text" className="form-control table-inline" style={{maxWidth: "150px"}} id="MID" maxLength="20"/>
                  <p className="m-1">(Jtnet / NicePay 공용)</p>
                </div>
              </td>
            </tr>
            <tr>
              <th>JTNET 서명키 (merchantKey)</th>
              <td>
                <input type="text" className="form-control" aria-describedby="button-addon2" id="sign_key"/>
              </td>
              <th>NicePay 서명키 (merchantKey)</th>
              <td>
                <input type="text" className="form-control" aria-describedby="button-addon2" id="sign_key_nicepay"/>
              </td>
              <th>POS 상점아이디 (Nicepay)</th>
              <td>
                <input type="text" className="form-control" aria-describedby="button-addon2" id="mall_id" maxLength="20"/>
              </td>
            </tr>
            <tr>
              <th>가맹점</th>
              <td colSpan="3">
                <div className="input-group" style={{maxWidth: "250px"}}>
                  <p className="m-1">가맹점 여부</p>
                  <input type="checkbox" className="form-control" value="Y" id="fran_YN"/>
                  <p className="m-1">수수료율</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="fran_rate"/>
                  <p className="m-1">%</p>
                </div>
              </td>
              <th>MID 추가등록 (Jtnet)</th>
              <td className="text-left">
                <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => {
                  $('#m1_base_seq').val('');
                  $('#m1_branch_code').val($('#branch_code').val());
                  setModal1Open(true)
                }}>MID 추가등록
                </button>
                {result?.m_list?.map((item, index) =>
                  <>
                    <br/>
                    <a style={{cursor: "pointer"}} onClick={() => {
                      $('#m1_base_seq').val(btoa(item.seq));
                      $('#m1_branch_code').val($('#branch_code').val());
                      setModal1Open(true);
                    }}>
                      <strong>{`${item.as_name} : ${item.MID}`}</strong>
                    </a>
                  </>
                )}
              </td>
            </tr>
            <tr>
              <th>계좌정보</th>
              <td colSpan="3">
                <div className="input-group">
                  <select className="form-select" aria-label="Default select example" style={{maxWidth: "150px"}} id="account_bank">
                    <option value="">선택</option>
                    <option value="004">국민</option>
                    <option value="039">경남</option>
                    <option value="034">광주</option>
                    <option value="003">기업</option>
                    <option value="011">농협</option>
                    <option value="031">대구</option>
                    <option value="055">도이치</option>
                    <option value="032">부산</option>
                    <option value="002">산업</option>
                    <option value="050">상호저축</option>
                    <option value="045">새마을금고</option>
                    <option value="007">수협</option>
                    <option value="088">신한</option>
                    <option value="048">신협</option>
                    <option value="027">씨티</option>
                    <option value="081">KEB하나</option>
                    <option value="020">우리</option>
                    <option value="071">우체국</option>
                    <option value="037">전북</option>
                    <option value="023">SC</option>
                    <option value="035">제주</option>
                    <option value="054">HSBC</option>
                  </select>
                  <input type="text" className="form-control table-inline mx-1" id="account_number" style={{maxWidth: "250px"}}/>
                </div>
              </td>
              <th>MID 추가등록 (Nicepay)</th>
              <td className="text-left">
                <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => {
                  $('#m2_base_seq').val('');
                  $('#m2_branch_code').val($('#branch_code').val());
                  setModal2Open(true)
                }}>MID 추가등록
                </button>
                {result?.m_nicepay_list?.map((item, index) =>
                  <>
                    <br/>
                    <a style={{cursor: "pointer"}} onClick={() => {
                      $('#m2_base_seq').val(btoa(item.seq));
                      $('#m2_branch_code').val($('#branch_code').val());
                      setModal2Open(true);
                    }}>
                      <strong>{`${item.as_name} : ${item.MID} : ${item.mall_id}`}</strong>
                    </a>
                  </>
                )}
              </td>
            </tr>
            <tr>
              <th>자동문 TID</th>
              <td colSpan="5">
                <div className="input-group">
                  <input type="text" className="form-control table-inline mr-1" style={{maxWidth: "100px"}} id="tid1"/>
                  <input type="text" className="form-control table-inline mr-1" style={{maxWidth: "100px"}} id="tid2"/>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="tid3"/>
                </div>
              </td>
            </tr>
            <tr>
              <th>온라인Key(jtent)</th>
              <td colSpan="5">
                <div className="input-group">
                  <p className="m-1 bold700">MID :</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="o_mid" maxLength="20"/>
                  &nbsp;&nbsp;&nbsp;
                  <p className="m-1 bold700">상점키 :</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "300px"}} id="o_merchantKey"/>
                </div>
              </td>
            </tr>
            <tr>
              <th>온라인Key(nice)</th>
              <td colSpan="5">
                <div className="input-group">
                  <p className="m-1 bold700">MID :</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="nice_mid" maxLength="20"/>
                  &nbsp;&nbsp;&nbsp;
                  <p className="m-1 bold700">상점키 :</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "300px"}} id="nice_merchantKey"/>
                </div>
              </td>
            </tr>
            <tr>
              <th>키오스크 자동로그인</th>
              <td colSpan="5" className="text-left">
                {!result?.branch_code ? null : <strong>https://smms.spoany.co.kr/kiosk/auto_login.php?auto_key={btoa(result?.branch_code)}</strong>}
              </td>
            </tr>
            <tr>
              <th>혼잡도 기준</th>
              <td colSpan="5">
                <div className="input-group">
                  <p className="m-1 bold700">표준범위 :</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="h_range1"
                         onKeyPress={(e) => {
                           return Fn.numkeyCheck(e)
                         }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <p className="m-1 bold700">-</p>
                  <input type="text" className="form-control table-inline" style={{maxWidth: "100px"}} id="h_range2"
                         onKeyPress={(e) => {
                           return Fn.numkeyCheck(e)
                         }}
                  />
                  <button type="button" className="btn btn-ssm btn-outline-point mx-1" onClick={() => hon_change()}>적용</button>
                </div>
              </td>
            </tr>
          </table>
          <div className="mt-2">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
            <button type="button" className="btn btn-sm btn-dark px-3 mx-1" onClick={() => history.goBack()}>목록</button>
          </div>
        </div>
      </div>

      {baseSeq &&
        <>
          <div className="m-3" style={{border: '1px solid #eee', borderRadius: '12px'}}>
            <div className="border-bottom text-left">
              <p className="px-3 py-2"><span className="h6 bold700">지점 상세정보</span></p>
            </div>

            <div className="p-3">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="130px"/>
                  <col width="*"/>
                  <col width="130px"/>
                  <col width="*"/>
                  <col width="130px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>지점사진</th>
                  <td colSpan="5" className="text-left">
                    <form name="infoform" method="post" id="infoform" encType='multipart/form-data'>
                      <input type="hidden" name="base_seq"/>
                      <input type="hidden" name="branch_code"/>
                      <input type="file" id="userfile" name="userfile" onChange={() => ajaxFileChange()} style={{display: "none"}} accept=".jpg,.jpeg"/>
                      <p className="m-1">
                        <button type="button" className="btn btn-ssm btn-dark px-3" onClick={() => $('#userfile').click()}>사진업로드</button>
                      </p>
                      <div className="d-flex flex-wrap" id="b_pic">
                        {result?.img_list?.length > 0 && result?.img_list?.map((item, index) =>
                          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center"}} id={`div_${item?.seq}`}>
                            <img className="IMG_thum m-1" src={Fn.makeUrl(Constants.API_URL + "/common/download?cd=inline&type=branch_img&filename=" + encodeURIComponent(item?.img_name))} alt="spoAny" style={{objectFit: "cover"}}></img>
                            <button type="button" className="btn btn-ssm btn-outline-dark" style={{maxWidth: "70px"}} onClick={() => window.temp_img_del(item?.branch_code, item?.seq, `div_${item?.seq}`)}>삭제</button>
                          </div>
                        )}
                      </div>
                    </form>
                  </td>
                </tr>
                <tr>
                  <th>지점휴대폰</th>
                  <td><input type="text" className="form-control table-inline" id="jum_tel" maxLength={20}/></td>
                  <th>대표자</th>
                  <td><input type="text" className="form-control table-inline" id="ceo" maxLength={20}/></td>
                  <th></th>
                  <td></td>
                </tr>
                <tr>
                  <th>연락처</th>
                  <td><input type="text" className="form-control table-inline" id="tel" maxLength={20}/></td>
                  <th>운영상태</th>
                  <td>
                    <select className="form-select-sm table-inline" id="branch_state">
                      <option value="">선택</option>
                      <option value="운영">운영</option>
                      <option value="휴점">휴점</option>
                      <option value="폐점">폐점</option>
                    </select>
                  </td>
                  <th>지역</th>
                  <td>
                    <select className="form-select-sm table-inline" id="region">
                      <option value="">선택</option>
                      <option value="서울특별시">서울특별시</option>
                      <option value="부산광역시">부산광역시</option>
                      <option value="대구광역시">대구광역시</option>
                      <option value="인천광역시">인천광역시</option>
                      <option value="광주광역시">광주광역시</option>
                      <option value="대전광역시">대전광역시</option>
                      <option value="울산광역시">울산광역시</option>
                      <option value="세종특별자치시">세종특별자치시</option>
                      <option value="경기도">경기도</option>
                      <option value="강원도">강원도</option>
                      <option value="충청북도">충청북도</option>
                      <option value="충청남도">충청남도</option>
                      <option value="전라북도">전라북도</option>
                      <option value="전라남도">전라남도</option>
                      <option value="경상북도">경상북도</option>
                      <option value="경상남도">경상남도</option>
                      <option value="제주특별자치도">제주특별자치도</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <th>개관일</th>
                  <td className="text-left"><JDatePicker className="form-control table-inline" id="open_date"/></td>
                  <th>규모</th>
                  <td>
                    <div className="input-group">
                      <input type="text" className="form-control table-inline" style={{maxWidth: '100px'}} id="scale" maxLength={6}/>
                      <p className="m-1 bold700">평</p>
                    </div>
                  </td>
                  <th>폐점일</th>
                  <td className="text-left"><JDatePicker className="form-control table-inline" id="close_date"/></td>
                </tr>
                <tr>
                  <th>사업자번호</th>
                  <td><input type="text" className="form-control table-inline" id="company_no" maxLength={20}/></td>
                  <th>주소</th>
                  <td colSpan="3">
                    <div className="input-group">
                      <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => setPostCodeOpen(true)}>주소검색</button>
                      <input type="text" className="form-control table-inline mx-1" id="address" placeholder="기본주소"/>
                      <input type="text" className="form-control table-inline" id="address2" style={{minWidth: '50%'}} placeholder="상세주소"/>
                      <input type='hidden' name='coord_x' id='coord_x'/>
                      <input type='hidden' name='coord_y' id='coord_y'/>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>지점소개글</th>
                  <td colSpan="5" className="pb-0"><textarea className="txtarea" style={{minHeight: '200px'}} id="intro"></textarea></td>
                </tr>
                <tr>
                  <th>블로그주소</th>
                  <td colSpan="5"><input type="text" className="form-control table-inline" id="blog"/></td>
                </tr>
                <tr>
                  <th>홈페이지주소</th>
                  <td colSpan="5"><input type="text" className="form-control table-inline" id="hompage"/></td>
                </tr>
              </table>
            </div>

            <div className="p-3">
              <table className="view-rounded sub_table_rowspan">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <td colSpan="6" className="text-left h7 bold700">운영시간</td>
                </tr>
                <tr>
                  <th colSpan="2">평일</th>
                  <th colSpan="2">토요일</th>
                  <th colSpan="2">일요일,공휴일</th>
                </tr>
                <tr>
                  <th>OPEN</th>
                  <th>CLOSE</th>
                  <th>OPEN</th>
                  <th>CLOSE</th>
                  <th>OPEN</th>
                  <th>CLOSE</th>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex justify-content-center">
                      <select className="form-select-sm table-inline" id="nomal_open_hour">
                        {Array.from({length: 24}).map((_, i) => {
                          const j = i < 10 ? `0${i}` : i;
                          return (
                            <option value={j}>{j}시</option>
                          );
                        })}
                      </select>
                      <p className="p-1">:</p>
                      <select className="form-select-sm table-inline" id="nomal_open_minute">
                        {Array.from({length: 12}).map((_, i) => {
                          const j = i * 5 < 10 ? `0${i * 5}` : i * 5;
                          return (
                            <option value={j}>{j}분</option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <select className="form-select-sm table-inline" id="nomal_close_hour">
                        {Array.from({length: 24}).map((_, i) => {
                          const j = i < 10 ? `0${i}` : i;
                          return (
                            <option value={j}>{j}시</option>
                          );
                        })}
                      </select>
                      <p className="p-1">:</p>
                      <select className="form-select-sm table-inline" id="nomal_close_minute">
                        {Array.from({length: 12}).map((_, i) => {
                          const j = i * 5 < 10 ? `0${i * 5}` : i * 5;
                          return (
                            <option value={j}>{j}분</option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <select className="form-select-sm table-inline" id="to_open_hour">
                        {Array.from({length: 24}).map((_, i) => {
                          const j = i < 10 ? `0${i}` : i;
                          return (
                            <option value={j}>{j}시</option>
                          );
                        })}
                      </select>
                      <p className="p-1">:</p>
                      <select className="form-select-sm table-inline" id="to_open_minute">
                        {Array.from({length: 12}).map((_, i) => {
                          const j = i * 5 < 10 ? `0${i * 5}` : i * 5;
                          return (
                            <option value={j}>{j}분</option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <select className="form-select-sm table-inline" id="to_close_hour">
                        {Array.from({length: 24}).map((_, i) => {
                          const j = i < 10 ? `0${i}` : i;
                          return (
                            <option value={j}>{j}시</option>
                          );
                        })}
                      </select>
                      <p className="p-1">:</p>
                      <select className="form-select-sm table-inline" id="to_close_minute">
                        {Array.from({length: 12}).map((_, i) => {
                          const j = i * 5 < 10 ? `0${i * 5}` : i * 5;
                          return (
                            <option value={j}>{j}분</option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <select className="form-select-sm table-inline" id="holi_open_hour">
                        {Array.from({length: 24}).map((_, i) => {
                          const j = i < 10 ? `0${i}` : i;
                          return (
                            <option value={j}>{j}시</option>
                          );
                        })}
                      </select>
                      <p className="p-1">:</p>
                      <select className="form-select-sm table-inline" id="holi_open_minute">
                        {Array.from({length: 12}).map((_, i) => {
                          const j = i * 5 < 10 ? `0${i * 5}` : i * 5;
                          return (
                            <option value={j}>{j}분</option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-center">
                      <select className="form-select-sm table-inline" id="holi_close_hour">
                        {Array.from({length: 24}).map((_, i) => {
                          const j = i < 10 ? `0${i}` : i;
                          return (
                            <option value={j}>{j}시</option>
                          );
                        })}
                      </select>
                      <p className="p-1">:</p>
                      <select className="form-select-sm table-inline" id="holi_close_minute">
                        {Array.from({length: 12}).map((_, i) => {
                          const j = i * 5 < 10 ? `0${i * 5}` : i * 5;
                          return (
                            <option value={j}>{j}분</option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th colSpan="6">정기휴관일</th>
                </tr>
                <tr>
                  <td colSpan="6"><input type="text" className="form-control table-inline" id="regular_rest"/></td>
                </tr>
              </table>
            </div>

            <div className="p-3">
              <table className="view-rounded sub_table_border text-left">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr id="p_list_area">
                  <td colSpan="5" className="text-left h7 bold700">편의시설</td>
                </tr>
              </table>
            </div>

            <div className="p-3">
              <table className="view-rounded sub_table_border text-left">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <td colSpan="5" className="text-left h7 bold700">이용요금</td>
                </tr>
                <tr>
                  <th></th>
                  <th>1개월</th>
                  <th>3개월</th>
                  <th>6개월</th>
                  <th>1년</th>
                </tr>
                <tr>
                  <th>헬스 이용 요금 (GX 포함)</th>
                  <td><input type="text" className="form-control table-inline" id="m1_health" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m3_health" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m6_health" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m12_health" maxLength="10"/></td>
                </tr>
                <tr>
                  <th><input type="text" className="form-control table-inline white w-100" id="m_etc_name"/></th>
                  <td><input type="text" className="form-control table-inline" id="m1_etc" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m3_etc" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m6_etc" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m12_etc" maxLength="10"/></td>
                </tr>
                <tr>
                  <th>운동복 대여</th>
                  <td><input type="text" className="form-control table-inline" id="m1_shirt" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m3_shirt" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m6_shirt" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m12_shirt" maxLength="10"/></td>
                </tr>
                <tr>
                  <th>개인 사물함</th>
                  <td><input type="text" className="form-control table-inline" id="m1_locker" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m3_locker" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m6_locker" maxLength="10"/></td>
                  <td><input type="text" className="form-control table-inline" id="m12_locker" maxLength="10"/></td>
                </tr>
                <tr>
                  <th>가격 정보</th>
                  <td colSpan="4" className="pb-0"><textarea className="txtarea" style={{minHeight: '70px'}} id="price_info"></textarea></td>
                </tr>
              </table>
            </div>

            <div className="p-3">
              <table className="view-rounded sub_table_border text-left">
                <colgroup>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <td colSpan="6" className="text-left h7 bold700">PT요금-Session당 가격</td>
                </tr>
                <tr>
                  <th>트레이너 레벨1</th>
                  <td><input type="text" className="form-control table-inline" id="pt1_price"/></td>
                  <th>트레이너 레벨2</th>
                  <td><input type="text" className="form-control table-inline" id="pt2_price"/></td>
                  <th>트레이너 레벨3</th>
                  <td><input type="text" className="form-control table-inline" id="pt3_price"/></td>
                </tr>
                <tr>
                  <th>PT 정보</th>
                  <td colSpan="5" className="pb-0"><textarea className="txtarea" style={{minHeight: '70px'}} id="pt_info"></textarea></td>
                </tr>
              </table>
            </div>

            <div className="p-3">
              <table className="view-rounded sub_table_border text-left">
                <colgroup>
                  <col width="130px"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <td colSpan="2" className="text-left h7 bold700">기타정보</td>
                </tr>
                <tr>
                  <th>유의사항</th>
                  <td className="pb-0"><textarea className="txtarea" style={{minHeight: '70px'}} id="attention"></textarea></td>
                </tr>
                <tr>
                  <th>교통정보</th>
                  <td className="pb-0"><textarea className="txtarea" style={{minHeight: '70px'}} id="traffic"></textarea></td>
                </tr>
                <tr>
                  <th>기타정보</th>
                  <td className="pb-0"><textarea className="txtarea" style={{minHeight: '70px'}} id="parking"></textarea></td>
                </tr>
              </table>
            </div>

            <div className="p-3">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="18%"/>
                  <col width="41%"/>
                  <col width=""/>
                  <col width="120px"/>
                </colgroup>
                <tr>
                  <td colSpan="4" className="text-left h7 bold700">그룹강좌</td>
                </tr>
                <tr>
                  <th>시작기간</th>
                  <th>요일</th>
                  <th>강좌명</th>
                  <th></th>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex justify-content-center">
                      <select className="form-select-sm table-inline" id="start_hour">
                        {Array.from({length: 24}).map((_, i) => {
                          const j = i < 10 ? `0${i}` : i;
                          return (
                            <option value={j}>{j}시</option>
                          );
                        })}
                      </select>
                      <p className="p-1">:</p>
                      <select className="form-select-sm table-inline" id="start_minute">
                        {Array.from({length: 12}).map((_, i) => {
                          const j = i * 5 < 10 ? `0${i * 5}` : i * 5;
                          return (
                            <option value={j}>{j}분</option>
                          );
                        })}
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="form-check form-check-inline mx-1">
                      <input className="form-check-input2" type="checkbox" value="" id="day1"/>
                      <label className="form-check-label mx-1" htmlFor="day1">월</label>
                    </div>
                    <div className="form-check form-check-inline mx-0">
                      <input className="form-check-input2" type="checkbox" value="" id="day2"/>
                      <label className="form-check-label mx-1" htmlFor="day2">화</label>
                    </div>
                    <div className="form-check form-check-inline mx-0">
                      <input className="form-check-input2" type="checkbox" value="" id="day3"/>
                      <label className="form-check-label mx-1" htmlFor="day3">수</label>
                    </div>
                    <div className="form-check form-check-inline mx-0">
                      <input className="form-check-input2" type="checkbox" value="" id="day4"/>
                      <label className="form-check-label mx-1" htmlFor="day4">목</label>
                    </div>
                    <div className="form-check form-check-inline mx-0">
                      <input className="form-check-input2" type="checkbox" value="" id="day5"/>
                      <label className="form-check-label mx-1" htmlFor="day5">금</label>
                    </div>
                    <div className="form-check form-check-inline mx-0">
                      <input className="form-check-input2" type="checkbox" value="" id="day6"/>
                      <label className="form-check-label mx-1" htmlFor="day6">토</label>
                    </div>
                    <div className="form-check form-check-inline mx-0">
                      <input className="form-check-input2" type="checkbox" value="" id="day7"/>
                      <label className="form-check-label mx-1" htmlFor="day7">일</label>
                    </div>
                  </td>
                  <td><input type="text" className="form-control table-inline" id="lec_name"/></td>
                  <td>
                    <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => go_lec()}>저장</button>
                  </td>
                </tr>
                {result?.lec_result?.map((item, i) =>
                  <tr>
                    <td>{item?.start_time}</td>
                    <td>{item?.day_show}</td>
                    <td>{item?.lec_name}</td>
                    <td>
                      <button type="button" className="btn btn-ssm btn-outline-point mx-1"
                              onClick={() => edit_lec(item.seq, item.start_hour, item.start_minute, item.day1_chk, item.day2_chk, item.day3_chk, item.day4_chk, item.day5_chk, item.day6_chk, item.day7_chk, item.lec_name)}
                      >수정
                      </button>
                      <button type="button" className="btn btn-ssm btn-outline-dark"
                              onClick={() => del_lec(item.seq)}
                      >삭제
                      </button>
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>

          <div className="m-3 text-center">
            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => go_info()}>저장</button>
            <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => history.goBack()}>목록</button>
          </div>
        </>
      }

      <PostCode isOpen={isPostCodeOpen} onCallback2={(addr, addr2) => {
        $('#address').val(addr);
        $('#address2').val(addr2);
      }} onClose={() => setPostCodeOpen(false)}/>

      <input type="hidden" id="base_seq"/>
      <input type="hidden" id="lec_seq"/>
      <input type="hidden" id="branch_code"/>
      <input type="hidden" id="show_YN" name="show_YN"/>

      <Modal
        isOpen={modal1Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onRequestClose={() => setModal1Open(false)}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal1();
        }}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 520}}>
            <div className="POP_title">
              <div className="d-flex flex_gap">
                <h5 className="pt-1"><strong>MID 추가등록</strong></h5>
              </div>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal1Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border text-center">
                <colgroup>
                  <col width="30%"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>별칭</th>
                  <td><input type="text" className="form-control table-inline" id="m1_as_name" maxLength={10}/></td>
                </tr>
                <tr>
                  <th>MID</th>
                  <td><input type="text" className="form-control table-inline" id="m1_MID"/></td>
                </tr>
                <tr>
                  <th>서명키</th>
                  <td><input type="text" className="form-control table-inline" id="m1_sign_key"/></td>
                </tr>
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-outline-dark mx-1 btn-m1-del px-3" onClick={() => go_del1()}>삭제</button>
              <button type="button" className="btn btn-sm btn-point mx-1 px-3" onClick={() => go_submit1()}>저장</button>
              <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setModal1Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modal2Open}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onRequestClose={() => setModal2Open(false)}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal2();
        }}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: 520}}>
            <div className="POP_title">
              <div className="d-flex flex_gap">
                <h5 className="pt-1"><strong>MID 추가등록</strong></h5>
              </div>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModal2Open(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border text-center">
                <colgroup>
                  <col width="30%"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>별칭</th>
                  <td><input type="text" className="form-control table-inline" id="m2_as_name" maxLength={10}/></td>
                </tr>
                <tr>
                  <th>MID</th>
                  <td><input type="text" className="form-control table-inline" id="m2_MID"/></td>
                </tr>
                <tr>
                  <th>서명키</th>
                  <td><input type="text" className="form-control table-inline" id="m2_sign_key"/></td>
                </tr>
                <tr>
                  <th>상점아이디</th>
                  <td><input type="text" className="form-control table-inline" id="m2_mall_id"/></td>
                </tr>
              </table>
            </div>
            <div className="text-center mt-2">
              <button type="button" className="btn btn-sm btn-outline-dark mx-1 btn-m1-del px-3" onClick={() => go_del2()}>삭제</button>
              <button type="button" className="btn btn-sm btn-point mx-1 px-3" onClick={() => go_submit2()}>저장</button>
              <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => setModal2Open(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="m1_del_YN"/>
      <input type="hidden" id="m1_base_seq"/>
      <input type="hidden" id="m1_branch_code"/>

      <input type="hidden" id="m2_del_YN"/>
      <input type="hidden" id="m2_base_seq"/>
      <input type="hidden" id="m2_branch_code"/>

    </CmnCard>
  );
};

export default ConfigBrandReg;
