import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import {Link, useHistory} from "react-router-dom";

import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";


const EmployeePositionFee = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [row, setRow] = useState({});
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('#jibu_type').val('A');
    $('#jibu_price1').val('');
    $('#jibu_price2').val('');
    $('#jibu_price3').val('');
    $('#jibu_price4').val('');
    $('#jibu_price5').val('');
    $('#jibu_price6').val('');
    $('#jibu_b_rate1').val('');
    $('#jibu_b_rate2').val('');
    $('#jum_type').val('A');
    $('#jum_price1').val('');
    $('#jum_price2').val('');
    $('#jum_price3').val('');
    $('#jum_price4').val('');
    $('#jum_price5').val('');
    $('#jum_b_rate1').val('');
    $('#jum_b_rate2').val('');
    $('#pt_price1').val('');
    $('#pt_price2').val('');
    $('#pt_price3').val('');

    $('#jiub_B').hide();
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/employee/position/fee/ready`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRow(result?.row);

          $('#jibu_type').val(result?.row?.jibu_type);
          $('#jibu_price1').val(result?.row?.jibu_price1);
          $('#jibu_price2').val(result?.row?.jibu_price2);
          $('#jibu_price3').val(result?.row?.jibu_price3);
          $('#jibu_price4').val(result?.row?.jibu_price4);
          $('#jibu_price5').val(result?.row?.jibu_price5);
          $('#jibu_price6').val(result?.row?.jibu_price6);
          $('#jibu_b_rate1').val(result?.row?.jibu_b_rate1);
          $('#jibu_b_rate2').val(result?.row?.jibu_b_rate2);
          $('#jum_type').val(result?.row?.jum_type);
          $('#jum_price1').val(result?.row?.jum_price1);
          $('#jum_price2').val(result?.row?.jum_price2);
          $('#jum_price3').val(result?.row?.jum_price3);
          $('#jum_price4').val(result?.row?.jum_price4);
          $('#jum_price5').val(result?.row?.jum_price5);
          $('#jum_b_rate1').val(result?.row?.jum_b_rate1);
          $('#jum_b_rate2').val(result?.row?.jum_b_rate2);
          $('#pt_price1').val(result?.row?.pt_price1);
          $('#pt_price2').val(result?.row?.pt_price2);
          $('#pt_price3').val(result?.row?.pt_price3);

          change_jibu_type();
          change_jum_type();
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit() {
    axios2
      .postEx('/employee/position/fee', {
        jibu_type: $('#jibu_type option:selected').val(),
        jibu_price1: $('#jibu_price1').val(),
        jibu_price2: $('#jibu_price2').val(),
        jibu_price3: $('#jibu_price3').val(),
        jibu_price4: $('#jibu_price4').val(),
        jibu_price5: $('#jibu_price5').val(),
        jibu_price6: $('#jibu_price6').val(),
        jibu_b_rate1: $('#jibu_b_rate1').val(),
        jibu_b_rate2: $('#jibu_b_rate2').val(),
        jum_type: $('#jum_type option:selected').val(),
        jum_price1: $('#jum_price1').val(),
        jum_price2: $('#jum_price2').val(),
        jum_price3: $('#jum_price3').val(),
        jum_price4: $('#jum_price4').val(),
        jum_price5: $('#jum_price5').val(),
        jum_b_rate1: $('#jum_b_rate1').val(),
        jum_b_rate2: $('#jum_b_rate2').val(),
        pt_price1: $('#pt_price1').val(),
        pt_price2: $('#pt_price2').val(),
        pt_price3: $('#pt_price3').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          alert('오류가 발생하였습니다');
        } else if (data == "insert_ok") {
          alert("등록되었습니다");
          setRenew(Fn.getRenewTime());
        } else if (data == "insert_err") {
          alert("등록에러! 관리자에게 문의하세요");
          // setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function change_jibu_type() {
    if ($('#jibu_type option:selected').val() === "A") {
      $('.jiub_A').removeClass('d-none').addClass('d-flex');
      $('.jiub_B').removeClass('d-flex').addClass('d-none');
    } else {
      $('.jiub_A').removeClass('d-flex').addClass('d-none');
      $('.jiub_B').removeClass('d-none').addClass('d-flex');
    }
  }

  function change_jum_type() {
    if ($('#jum_type option:selected').val() === "A") {
      $('.jum_A').removeClass('d-none').addClass('d-flex');
      $('.jum_B').removeClass('d-flex').addClass('d-none');
    } else {
      $('.jum_A').removeClass('d-flex').addClass('d-none');
      $('.jum_B').removeClass('d-none').addClass('d-flex');
    }
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3">
        <table className="view-rounded sub_view">
          <colgroup>
            <col width="130px"/>
            <col width="220px"/>
            <col width="*"/>
          </colgroup>
          <tr>
            <th width="100" className="border-right">지부장</th>
            <td width="80" className="border-right">
              <div align="center">
                <select
                  id="jibu_type"
                  className="form-select"
                  onChange={change_jibu_type}
                >
                  <option value="A">
                    A타입
                  </option>
                  <option value="B">
                    B타입
                  </option>
                </select>
              </div>
            </td>
            <td height="60">
              <div id="jiub_A" className="jiub_A d-flex flex-wrap inline h7" style={{flexDirection: "row", alignItems: "center"}}>
                설정된 지점순익타겟 달성시 (타겟의
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jibu_price1"
                  style={{width: "55px", marginLeft: "10px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
                % + 타겟을 초과한 금액의
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jibu_price2"
                  style={{width: "55px", marginLeft: "10px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
                %)×
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jibu_price3"
                  style={{width: "55px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
                ÷
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jibu_price4"
                  style={{width: "55px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
                +
                <input
                  type="text"
                  className="form-control cls-type-number wid100 price_input"
                  placeholder=""
                  id="jibu_price5"
                  style={{imeMode: "disabled", maxWidth: "85px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="12"
                />
              </div>
              <div className="jiub_A d-flex flex-wrap inline h7 mt-1" style={{flexDirection: "row", alignItems: "center"}}>
                미달성시{" "}
                <input
                  type="text"
                  className="form-control cls-type-number wid100 price_input"
                  placeholder=""
                  id="jibu_price6"
                  style={{imeMode: "disabled", maxWidth: "85px", marginLeft: "10px"}}
                  // onFocus={e => e.target.select()}
                  // onKeyPress={numkeyCheck}
                  maxLength="12"
                />
              </div>

              <div id="jiub_B" className="jiub_B d-none flex-wrap inline h7" style={{flexDirection: "row", alignItems: "center"}}>
                월계표상 (수입합계-잡이익-단기대여금-단기차입금-이자수익-출자금-임시보유자금) ×{" "}
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jibu_b_rate1"
                  style={{width: "55px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
                % ×{" "}
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jibu_b_rate2"
                  style={{width: "55px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
                %
              </div>
            </td>
          </tr>

          <tr>
            <th width="100" className="border-right">지점장</th>
            <td width="80" className="border-right">
              <div align="center">
                <select
                  id="jum_type"
                  className="form-select"
                  onChange={change_jum_type}
                >
                  <option value="A">
                    A타입
                  </option>
                  <option value="B">
                    B타입
                  </option>
                </select>
              </div>
            </td>
            <td height="60">
              <div id="jum_A" className="jum_A d-flex flex-wrap inline h7" style={{flexDirection: "row", alignItems: "center"}}>
                설정된 지점순익타겟 달성시 (타겟의
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jum_price1"
                  style={{width: "55px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
                % + 타겟을 초과한 금액의
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jum_price2"
                  style={{width: "55px", marginLeft: "10px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
                %)×
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jum_price3"
                  style={{width: "55px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
                ÷
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jum_price4"
                  style={{width: "55px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
              </div>
              <div className="jum_A d-flex flex-wrap inline h7 mt-1" style={{flexDirection: "row", alignItems: "center"}}>
                미달성시{" "}
                <input
                  type="text"
                  className="form-control cls-type-number wid100 price_input"
                  placeholder=""
                  id="jum_price5"
                  style={{width: "55px", marginLeft: "10px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                /> %
              </div>

              <div id="jum_B" className="jum_B d-none flex-wrap inline h7" style={{flexDirection: "row", alignItems: "center"}}>
                월계표상 (수입합계-잡이익-단기대여금-단기차입금-이자수익-출자금-임시보유자금) ×{" "}
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jum_b_rate1"
                  style={{width: "55px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
                % ×{" "}
                <input
                  type="text"
                  className="form-control cls-type-number wid180"
                  placeholder=""
                  id="jum_b_rate2"
                  style={{width: "55px"}}
                  //onFocus={e => e.target.select()}
                  //onKeyPress={numkeyCheck}
                  maxLength="3"
                />
                %
              </div>
            </td>
          </tr>
          <tr>
            <th>PT팀장</th>
            <td className="border-right"></td>
            <td>
              <div className="d-flex flex-wrap inline h7" style={{flexDirection: "row", alignItems: "center"}}>
                PT팀원 전체 타겟
                <input
                  type="text"
                  className="form-control cls-type-number wid180 ml-2"
                  placeholder=""
                  id="pt_price1"
                  style={{width: "55px"}}
                  //onFocus={(e) => e.target.select()}
                  // onKeyPress={handleKeyPress}
                  maxLength="3"
                />%
              </div>
              <div className="d-flex flex-wrap inline h7 mt-1" style={{flexDirection: "row", alignItems: "center"}}>
                PT팀 매출타겟 달성시 PT순익의
                <input
                  type="text"
                  className="form-control cls-type-number wid180 ml-2"
                  placeholder=""
                  id="pt_price2"
                  style={{width: "55px"}}
                  //onFocus={(e) => e.target.select()}
                  // onKeyPress={handleKeyPress}
                  maxLength="3"
                />%
              </div>
              <div className="d-flex flex-wrap inline h7 mt-1" style={{flexDirection: "row", alignItems: "center"}}>
                미달성시 순익의
                <input
                  type="text"
                  className="form-control cls-type-number wid180 ml-2"
                  placeholder=""
                  id="pt_price3"
                  style={{width: "55px"}}
                  //onFocus={(e) => e.target.select()}
                  // onKeyPress={handleKeyPress}
                  maxLength="3"
                />%
              </div>
            </td>
          </tr>
        </table>
      </div>
      <div className="text-center my-4">
        <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
      </div>
    </CmnCard>
  );
};

export default EmployeePositionFee;
