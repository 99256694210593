import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import {useHistory} from "react-router-dom";
import Modal from "react-modal";

const SalesProductList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const [modalResult, setModalResult] = useState();

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [renew, currPage]);

  const load = (_page) => {
    axios2
      .post(`/sales/sales/product/list/ready?s_supply=${$('#s_supply option:selected').val() || ''}&s_category=${$('#s_category option:selected').val() || ''}&s_pum_position=${$('#s_pum_position option:selected').val() || ''}&s_pum_code=${$('#s_pum_code option:selected').val() || ''}&s_use_use=${$('#s_use_use option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_supply').html() === '') {
            let optionsHTML = '<option value="">::공급업체::</option>';
            result?.list1?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.shop_name}</option>`;
            });
            $('#s_supply').html(optionsHTML);
          }
          if ($('#s_category').html() === '') {
            let optionsHTML = '<option value="">::브랜드::</option>';
            result?.list2?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_category').html(optionsHTML);
          }
          if ($('#s_pum_position').html() === '') {
            let optionsHTML = '<option value="">::품목분류::</option>';
            result?.list3?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_pum_position').html(optionsHTML);
          }
          if ($('#s_pum_code').html() === '') {
            let optionsHTML = '<option value="">::품목구분::</option>';
            result?.list4?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.sub_code_name}</option>`;
            });
            $('#s_pum_code').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }


  const form1 = useRef(null); // TODO : FORM 참조

  const popUp = (file, names, width, height, sc) => {
    const w = width;
    let h = height;
    const winl = (window.screen.width - w) / 2;
    const wint = (window.screen.height - h) / 2;
    window.open(file, names, `width=${w}, height=${h},scrollbars=${sc},top=${wint},left=${winl}`);
  };

  const delItem = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val().split("|")[0];
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 판매상품을 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val().split("|")[0];
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/sales/sales/product/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  const brandChkSelect = () => {
    let con = 0;
    let editMsgid = '';
    for (let i = 0; i < form1.elements.length; i++) {
      if (form1.elements[i].type === 'checkbox') {
        if (form1.elements[i].checked === true) {
          const nn = form1.elements[i].name;
          const m_chk = nn.substr(0, 3);
          if (m_chk === 'chk') {
            con++;
            editMsgid += form1.elements[i].getAttribute('data') + '|';
          }
        }
      }
    }
    if (con === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    popUp(`branch_select_new_all.html?p_codes=${editMsgid}`, 'branch_select_show', '450', '600', 'yes');
  };

  const loadModal = () => {
    let url = `/branchop/branch/select/ready?s_brand=${$('#s_brand option:selected').val() || ''}`;
    if (($('#s_brand option:selected').val() || '') === '') {
      url = `/branchop/branch/select/ready`;
    }
    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_brand').html() === '') {
            let optionsHTML = '<option value="">::브랜드::</option>';
            result?.bl?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_brand').html(optionsHTML);
          }

          setModalResult(result);

          result?.list?.map((row, i) => {
            if ($('#branch_list').val()?.split('|')?.filter(r => r == row.branch_code)?.length)
              $('#mchk_' + (i + 1)).prop("checked", true);
            else
              $('#mchk_' + (i + 1)).prop("checked", false);
          });
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const onModalSubmit = () => {
    let values = $('[id^=mchk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    $('#branch_list').val(values);

    axios2
      .postEx('/sales/branch/select/new/all', {
        p_codes: $('#p_codes').val(),
        branch_list: $('#branch_list').val(),
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        setRenew(Fn.getRenewTime());
        setModalOpen(false);
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX">
        <div className="d-flex m-3 table_wide_sh2 fn_search_box">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: "32%"}} id="s_supply">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: "32%"}} id="s_category">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_pum_position">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_pum_code">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{width: "32%"}} id="s_use_use">
              <option value="">전체</option>
              <option value="Y">사용</option>
              <option value="N">미사용</option>
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="상품명/바코드 검색" id="key"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 pb-2 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border auto">
            <colgroup>
              <col width="60px"/>
              <col width="70px"/>
              <col width="*"/>
              <col width="23%"/>
              <col width="*"/>

              <col width=""/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>

              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th>No</th>
              <th>
                <input type="checkbox" onClick={(e) => {
                  if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                  else $('[id^=achk]').prop("checked", false);
                }}/>
              </th>
              <th>공급업체</th>
              <th>상품명</th>
              <th>브랜드</th>

              <th>품목분류</th>
              <th>품목구분</th>
              <th>매입가격</th>
              <th>판매가격</th>
              <th>바코드</th>

              <th>사용여부</th>
              <th>판매지점</th>
            </tr>
            {rets && rets.map((ret, i) =>
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  <input
                    type="checkbox"
                    // name={`chk[${i + 1}]`}
                    name="chk"
                    value={`${btoa(ret?.seq)}|${ret?.p_code}`}
                    className="checkbox"
                    id={`achk${i + 1}`}
                  />
                </td>
                <td>{ret?.company_name}</td>
                <td
                  style={{cursor: "pointer"}}
                  onClick={() => {
                    history.push({
                      pathname: '/sales/product/reg', state: {
                        baseSeq: btoa(ret?.seq),
                      }
                    });
                  }}
                >{ret?.p_name}</td>
                <td>{ret?.category_name}</td>
                <td className="text-left">{ret?.position_name}</td>
                <td>{ret?.pum_name}</td>
                <td className="text-right">{Fn.numberWithCommas(ret?.buy_price)}</td>
                <td className="text-right">{Fn.numberWithCommas(ret?.sale_price)}</td>
                <td>{ret.barcode}</td>
                <td>{ret?.use_YN === 'Y' ? <span>사용</span> : <span style={{color: 'red'}}>미전시</span>}</td>
                <td>
                  <button type="button" className="btn btn-ssm btn-outline-point" onClick={() => {
                    $('#p_codes').val(ret?.p_code);
                    $('#branch_list').val(ret?.branch_list);

                    setModalOpen(true)
                  }}>설정({ret?.branch_num || 0})
                  </button>
                </td>
              </tr>)}
          </table>
        </div>

        <nav className="p-3 mb-4 d-flex justify-content-between border-top">
          <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          <div>
            <div>
              <button type="button" className="btn btn-sm btn-outline-point" onClick={() => {
                let con = $('[id^=achk]:checked').map(function () {
                  return $(this).val().split("|")[0];
                }).get().join("|");

                if (con === '' || con.split(",").length === 0) {
                  window.alert('체크된 리스트가 없습니다.');
                  return;
                }

                let pCodes = $('[id^=achk]:checked').map(function () {
                  return $(this).val().split("|")[1];
                }).get().join("|");

                $('#p_codes').val(pCodes);
                $('#branch_list').val('');
                setModalOpen(true)
              }}>설정
              </button>
              <button type="button" className="btn btn-sm btn-outline-point mx-1" onClick={() => history.push("/sales/product/reg")}>추가
              </button>
              <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => delItem()}>삭제</button>
            </div>
          </div>
        </nav>
      </div>

      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>

          <div className="pop_box01 p_absolute_mid_pop" style={{maxWidth: '650px'}}>
            <div className="POP_title">
              <h5><strong>지점설정</strong></h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="d-flex m-0 table_wide_sh2">
              <div className="pr-1">
                <select className="form-select" id="s_brand" style={{minWidth: "180px"}}>
                </select>
              </div>
              <div className="pr-1">
                <button type="button" className="btn btn-sm btn-point px-3" onClick={() => loadModal()}>검색</button>
              </div>
            </div>

            <div className="POP_cnt_box w-100" style={{maxHeight: "620px"}}>
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="60px"/>
                  <col width="80px"/>
                  <col width="*"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>No</th>
                  <th>
                    <input type="checkbox" onClick={(e) => {
                      if ($(e.target).prop("checked")) $('[id^=mchk]').prop("checked", true);
                      else $('[id^=mchk]').prop("checked", false);
                    }}/>
                  </th>
                  <th>브랜드</th>
                  <th>지점명</th>
                </tr>
                {modalResult?.list?.map((row, i) =>
                  <tr>
                    <td className="text-center">
                      {i + 1}
                    </td>
                    <td className="text-center">
                      <input type="checkbox" id={`mchk_${i + 1}`} value={row.branch_code}/>
                    </td>
                    <td className="text-center">{row.branch_code}</td>
                    <td className="text-center">{row.branch_name}</td>
                  </tr>
                )}
              </table>
            </div>
            <div className="text-center my-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => onModalSubmit()}>선택</button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </div>
        </div>
      </Modal>

      <input type="hidden" id="base_seq"/>
      <input type="hidden" id="p_codes"/>
      <input type="hidden" id="branch_list"/>

    </CmnCard>
  );
};

export default SalesProductList;
