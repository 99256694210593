import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import _ from "lodash";
import $ from "jquery";

// datepicker bind 외에 스크립트 없음

const AnalysisManagerReport = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [sYear, setSYear] = useState(2022);
  const [top, setTop] = useState([]);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split("-")[0]);
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/finance/manager/report/ready?s_year=${$('#s_year option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setTop(result?.top);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX ">
        <div className="d-flex m-3 table_wide_sh2">
          <div className="pr-1">
            <select className="form-select" id="s_year">
              {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                <option key={year} value={year}>{year}</option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="60px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>No</th>
              <th>스텝명</th>
              <th>01월</th>
              <th>02월</th>
              <th>03월</th>
              <th>04월</th>
              <th>05월</th>
              <th>06월</th>
              <th>07월</th>
              <th>08월</th>
              <th>09월</th>
              <th>10월</th>
              <th>11월</th>
              <th>12월</th>
              <th>합계</th>
            </tr>
            {top && top?.map((top_row, top_count) => {
              const new_data = {};

              _.range(1, 12 + 1).map(i => {
                const my_month = sYear + "-" + (i < 10 ? "0" + i : i);
                new_data[my_month] = Fn.parseInt(top_row?.data?.filter(r => r.month === my_month)?.[0]?.salesAmount);
              });

              let t_data = 0;

              return (
                <tr key={top_count} className="active">
                  <td height="30">{top_count + 1}</td>
                  <td style={{background: '#FFFFFF'}}>{top_row?.ap_step}</td>
                  {Array.from({length: 12}, (_, i) => {
                    const month = i + 1 < 10 ? `0${i + 1}` : `${i + 1}`;
                    const target_month = `${sYear}-${month}`;
                    const amount = new_data[target_month] || 0;
                    t_data += amount;
                    new_data[target_month] = 0;

                    return (
                      <td key={i} style={{background: '#FFFFFF'}}>
                        <div align="right" style={{paddingRight: '2px'}}>
                          {new Intl.NumberFormat().format(amount)}
                        </div>
                      </td>
                    );
                  })}
                  <td style={{background: '#FFFFFF'}}>
                    <div align="right" style={{paddingRight: '2px'}}>
                      <strong>{new Intl.NumberFormat().format(t_data)}</strong>
                    </div>
                  </td>
                </tr>
              );
            })}
            {(!top || top?.length === 0) &&
              <tr>
                <td colSpan={14}>※ 등록된 data가 없습니다.</td>
              </tr>
            }
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default AnalysisManagerReport;
