import React, {useContext, useEffect, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import "../../sass/main.scss";
import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import _ from "lodash";
import Fn from "../../libraries/Fn";
import Modal from "react-modal";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Paging from "./Paging";

const SelectProduct = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const [modalOpen, setModalOpen] = useState(props.isOpen);

  useEffect(() => {
    setModalOpen(props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    if (!modalOpen) {
      props?.onClose();
    }
  }, [modalOpen]);

  const [result, setResult] = useState({});
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    if (modalOpen)
      load(1);
  }, [srchRenew]);

  useEffect(() => {
    if (modalOpen)
      load();
  }, [currPage, renew]);

  const load = (_page) => {
    $('#mp_name').html($('#mv_name').val());
    $('#mp_val').html($('#mv_val').val());

    // TODO : PTODO -> with_no_info=1 제거하고 원 로직대로 구현할 것
    axios2
      .post(`/member/locker/select/ready?with_no_info=1`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          axios2
            .post(`/locker/locker/state/ready`)
            .then((response) => {
              const {code, message, result, pageInfo} = response?.data;
              if (code === 200) {
                setResult(result);
              }
            })
            .catch((error) => {
              if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
            });
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const changeLocker = (click, po, gubun, Lnum, name) => {
    if (click == 'Y') {
      alert('미사용중인 라커를 선택해주세요');
      return;
    }

    if (!window.confirm('라커를 지정하시겠습니까?')) return;

    if (props.formType == "item_form") {
      $('#mnpLocName').val(name);
      $('#mnpLocNum').val(po);
      // props?.onCallback();
      setModalOpen(false);
      return;
    }

    if (props.formType == "service_state_form") {
      $('#locker_name').val(name);
      $('#locker_no').val(po);
      // props?.onCallback();
      setModalOpen(false);
      return;
    }

    if (props.formType == "g_service_state_form") {
      $('#g_locker_name').val(name);
      $('#g_locker_no').val(po);
      // props?.onCallback();
      setModalOpen(false);
      return;
    }

    if (props.formType == "form1") {
      $('#locker_name').val(name);
      $('#locker_no').val(po);
      // props?.onCallback();
      setModalOpen(false);
      return;
    }

    /*
    axios2
      .postEx('/locker/locker/bae', {
        auth_code: $('#auth_code').val(),
        val: po,
        mb_no: props.mbNo,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "err") {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        } else if (data == "ok") {
          if (props.formType == "item_form") {
            $('#mnpLocName').val(name);
            $('#mnpLocNum').val(po);
            // props?.onCallback();
            setModalOpen(false);
            return;
          }

          if (props.formType == "service_state_form") {
            $('#locker_name').val(name);
            $('#locker_no').val(po);
            // props?.onCallback();
            setModalOpen(false);
            return;
          }

          if (props.formType == "g_service_state_form") {
            $('#g_locker_name').val(name);
            $('#g_locker_no').val(po);
            // props?.onCallback();
            setModalOpen(false);
            return;
          }

          if (props.formType == "form1") {
            $('#locker_name').val(name);
            $('#locker_no').val(po);
            // props?.onCallback();
            setModalOpen(false);
            return;
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
     */
  }

  function DateGab(date1, date2) {
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const startDate = new Date(date1);
    const endDate = new Date(date2);

    return Math.round(Math.abs((startDate - endDate) / oneDay));
  }

  return (
    <Modal
      isOpen={modalOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        Fn.centerModal();
        load();
      }}
      onRequestClose={() => setModalOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{minWidth: '1000px', overflowX: "auto"}}>
          <div className="POP_title">
            <h5><strong>라커배정</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100" style={{overflowX: "auto"}}>
            <div className="mb-3 d-flex flex-nowrap">
              <div style={{marginLeft: "25px"}}>
                <select className="form-select" onChange={(e) => {
                  window.location.href = '#' + e.target.value;
                  // $(window).scrollTop(0); // TODO : PTODO
                }}>
                  <option value="">라커 바로가기</option>
                  {result?.loc && Object.entries(result?.loc).map(([k, v], index) =>
                    <option key={k.replace("_", "")} value={k.replace("_", "")}>{k.replace("_", "")}</option>
                  )}
                </select>
              </div>
            </div>

            <div className="pb-2">
              {
                result?.loc && Object.entries(result?.loc).map(([k, v], index) => {
                  k = k.replace("_", "");
                  const evenRow = index % 2 === 0;
                  const lastRow = Object.entries(result?.loc)?.length === index + 1;
                  // if(lastRow && evenRow) $('.wrap-'+index).append(`<div></div>`);

                  /*
                  if (!evenRow) {
                    setTimeout(() => {
                      if ($('#div_' + index).parents('.wrap-' + index).length === 0)
                        $('#div_' + (index - 1) + ',#div_' + index).wrapAll('<div class="div-locker wrap-' + index + ' d-flex m-4 flex_gap2 row-cols-1" style="flex-direction: column;"></div>');
                    });
                  } else {
                    if (lastRow && Object.entries(result?.loc)?.length % 2 === 1) {
                      if (!$('#div_' + index).parent().hasClass('div-locker')) {
                        setTimeout(() => {
                          $('#div_' + index).wrapAll('<div class="div-locker wrap-' + index + ' d-flex m-4 flex_gap2 row-cols-1" style="flex-direction: column;"></div>')
                          // $('.wrap-' + index).append(`<div></div>`);
                        });
                      }
                    }
                  }
                */
                  return (
                    <div id={`div_${index}`} className={`div-locker p-0 text-left table_box ${evenRow ? `div-even mb-3 mr-3` : `mb-3 mr-3`}`}>
                      <p className="border-bottom p-2">
                        <a className="h7 bold700 px-2" name={k} style={{color: '#333333'}}><strong>{k}</strong> : {v.y} &times; {v.x} ({v.x * v.y}) {v.s}~{v.e}</a>
                      </p>
                      <div className="m-3">
                        <table className="view-rounded locker_view" style={{width: "auto"}}>
                          {Array.from({length: v.y}).map((_, a) => (
                            <tr key={a}>
                              {Array.from({length: v.x}).map((_, b) => {
                                const po = `${k}-${a + v.s}-${b + 1}`;
                                let Lnum = v[`${k}-${a + v.s}-${b + 1}`].Lnum;
                                let end_date, gubun, click, bgclr, po2;

                                const e_chk_row = v?.[po];

                                if (e_chk_row?.lo_posi) {
                                  Lnum = e_chk_row.lo_num;
                                } else {
                                  Lnum = Lnum;
                                }

                                if (e_chk_row?.locker === "Y") {
                                  end_date = e_chk_row.locker_end_date;
                                  gubun = "locker";
                                } else if (e_chk_row?.g_locker === "Y") {
                                  end_date = e_chk_row.g_locker_end_date;
                                  gubun = "g_locker";
                                } else {
                                  end_date = e_chk_row?.locker_end_date;
                                  gubun = "locker";
                                }

                                const now_date = Fn.getDate(new Date());

                                if (!e_chk_row?.mb_no) {
                                  click = "N";
                                  bgclr = "#e8e8e8";
                                } else {
                                  if (end_date >= now_date) {
                                    click = "Y";
                                    bgclr = "#FFFFFF";
                                  } else {
                                    const gab = DateGab(end_date, now_date);
                                    if (gab < 14) {
                                      click = "Y";
                                      bgclr = "#bdfdd9";
                                    } else if (gab < 28) {
                                      click = "Y";
                                      bgclr = "#fed8f8";
                                    } else if (gab < 30) {
                                      click = "Y";
                                      bgclr = "#ff8e8e";
                                    } else {
                                      click = "Y";
                                      bgclr = "#bcb5fb";
                                    }
                                  }
                                }

                                po2 = `${end_date} ${e_chk_row?.mb_name}`;

                                const split = po.split("-");
                                const name = Lnum + "(" + split[0] + ")";

                                return (
                                  <th
                                    key={b}
                                    width="50"
                                    align="center"
                                    style={{cursor: "pointer", backgroundColor: bgclr, maxWidth: 60}}
                                    title={click === "Y" ? po2 : po}
                                    onClick={() => changeLocker(click, po, gubun, Lnum, name)}
                                  >
                                    <span id={`L${Lnum}`}>{Lnum}</span>
                                  </th>
                                );
                              })}
                            </tr>
                          ))}
                        </table>
                        <div style={{paddingBottom: "10px"}}></div>
                      </div>
                    </div>
                  );
                })
              }
            </div>

          </div>

          <input type="hidden" id="mv_val"/>
          <input type="hidden" id="mv_name"/>
          <input type="hidden" id="mv_mb_no"/>
          <input type="hidden" id="mv_gubun"/>
          <input type="hidden" id="mv_locker_number"/>

          <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>

            <div>
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => setModalOpen(false)}>닫기</button>
            </div>
          </nav>
        </div>
      </div>
    </Modal>
  );
};

export default SelectProduct;
