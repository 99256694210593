import React, {useEffect, useRef, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import $ from "jquery";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import {useHistory} from "react-router-dom";

const ConfigBranchList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/branchop/branch/list/ready?s_brand=${$('#s_brand option:selected').val() || ''}&show_out=${$('#show_out option:selected').val() || ''}&keyfield=${$('#keyfield option:selected').val() || ''}&key=${$('#key').val()}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_brand').html() === '') {
            let optionsHTML = '<option value="">::브랜드::</option>';
            result?.bl?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.brand}">${list_row.brand_name}</option>`;
            });
            $('#s_brand').html(optionsHTML);
          }

          result?.result?.forEach((list_row, i) => {
            $('#show_out' + (i + 1)).val(list_row.show_out);
          })

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const del_branch = (seq) => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 지점을 삭제하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/config/branch/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'del_ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  //  3-1.
  const change_value = (base_seq, type, checked) => {
    let value;
    if (checked === true) {
      value = "Y";
    } else if (checked === false) {
      value = "N";
    } else {
      value = checked;
    }

    axios2
      .post(`/branchop/change/branch/state?base_seq=${base_seq}&type=${type}&value=${value}`)
      .then((response) => {
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div>
        <div className="d-flex m-3 table_wide_sh fn_search_box">
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_brand">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="show_out">
              <option value="show_out asc">전시순</option>
              <option value="branch_name asc">지점명순</option>
              <option value="seq desc">등록역순</option>
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="keyfield">
              <option value="branch_name">지점명</option>
              <option value="branch_code">지점코드</option>
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" placeholder="검색어 입력" id="key"/>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>
        {/* 테이블 */}
        <div className="m-3 pb-2 table_list">
          <table className="view-rounded sub_table_border table_wide_sh">
            <colgroup>
              <col width="60px"/>
              <col width="80px"/>
              <col width="150px"/>
              <col width="150px"/>
              <col width="150px"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="85px"/>
              <col width="95px"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>
            <tr>
              <th>No</th>
              <th className="w_70px"><input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/></th>
              <th>브랜드</th>
              <th>지점코드</th>
              <th>지점명</th>
              <th>골프</th>
              <th>골프라커</th>
              <th>스쿼시</th>
              <th>스피닝</th>
              <th>태닝</th>
              <th>토탈</th>
              <th>필라테스</th>
              <th>Opt1</th>
              <th>Opt2</th>
              <th>Opt3</th>
              <th>전시여부</th>
              <th>H.전시여부</th>
              <th>투자전시여부</th>
              <th>전시순서</th>
              <th>세부정보</th>
            </tr>
            {rets.map((row, i) => {
              const count = i + 1;

              const seq = row.seq;
              const branch_code = row.branch_code;
              const branch_name = row.branch_name;
              const show_out = row.show_out;
              const H_show_YN = row.H_show_YN;
              const inv_YN = row.inv_YN;
              const show_YN = row.show_YN;
              const opt1 = row.opt1;
              const opt2 = row.opt2;
              const opt3 = row.opt3;
              const opt4 = row.opt4;
              const opt5 = row.opt5;
              const opt6 = row.opt6;
              const opt7 = row.opt7;
              const opt8 = row.opt8;
              const opt9 = row.opt9;
              const opt10 = row.opt10;
              const info_num = row.info_num;
              const brand_name = row.brand_name;
              const base_seq = btoa(seq);

              const handleChangeValue = (base_seq, option, checked) => {
              };

              return (
                <tr key={i}>
                  <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                  <td>
                    <input
                      type="checkbox"
                      className="checkbox"
                      id={`achk${count}`}
                      name={`chk[${count}]`}
                      value={base_seq}
                    />
                    <label htmlFor={`achk${count}`}>
                      <span></span>
                    </label>
                  </td>
                  <td>
                    <div align="left" style={{paddingLeft: "5px"}}>{brand_name}</div>
                  </td>
                  <td
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      history.push({
                        pathname: '/config/branch/reg', state: {
                          baseSeq: base_seq,
                        }
                      });
                    }}
                  >
                    <div align="left" style={{paddingLeft: "5px"}}>{branch_code}</div>
                  </td>
                  <td
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      history.push({
                        pathname: '/config/branch/reg', state: {
                          baseSeq: base_seq,
                        }
                      });
                    }}
                  >
                    <div align="left" style={{paddingLeft: "5px"}}>
                      <strong>{branch_name}</strong>
                    </div>
                  </td>
                  {Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) < 8 ? (
                    <React.Fragment>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk1${count}`}
                          name="opt1"
                          value="Y"
                          checked={opt1 === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "opt1", e.target.checked)}
                        />
                        <label htmlFor={`chk1${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk2${count}`}
                          name="opt2"
                          value="Y"
                          checked={opt2 === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "opt2", e.target.checked)}
                        />
                        <label htmlFor={`chk2${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk3${count}`}
                          name="opt3"
                          value="Y"
                          checked={opt3 === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "opt3", e.target.checked)}
                        />
                        <label htmlFor={`chk3${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk4${count}`}
                          name="opt4"
                          value="Y"
                          checked={opt4 === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "opt4", e.target.checked)}
                        />
                        <label htmlFor={`chk4${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk5${count}`}
                          name="opt5"
                          value="Y"
                          checked={opt5 === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "opt5", e.target.checked)}
                        />
                        <label htmlFor={`chk5${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk6${count}`}
                          name="opt6"
                          value="Y"
                          checked={opt6 === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "opt6", e.target.checked)}
                        />
                        <label htmlFor={`chk6${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk7${count}`}
                          name="opt7"
                          value="Y"
                          checked={opt7 === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "opt7", e.target.checked)}
                        />
                        <label htmlFor={`chk7${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk8${count}`}
                          name="opt8"
                          value="Y"
                          checked={opt8 === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "opt8", e.target.checked)}
                        />
                        <label htmlFor={`chk8${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk9${count}`}
                          name="opt9"
                          value="Y"
                          checked={opt9 === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "opt9", e.target.checked)}
                        />
                        <label htmlFor={`chk9${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk10${count}`}
                          name="opt10"
                          value="Y"
                          checked={opt10 === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "opt10", e.target.checked)}
                        />
                        <label htmlFor={`chk10${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`show_YN${count}`}
                          name="show_YN"
                          value="Y"
                          checked={show_YN === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "show_YN", e.target.checked)}
                        />
                        <label htmlFor={`show_YN${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`H_show_YN${count}`}
                          name="H_show_YN"
                          value="Y"
                          checked={H_show_YN === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "H_show_YN", e.target.checked)}
                        />
                        <label htmlFor={`H_show_YN${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`inv_YN${count}`}
                          name="inv_YN"
                          value="Y"
                          checked={inv_YN === "Y"}
                          onChange={(e) => handleChangeValue(base_seq, "inv_YN", e.target.checked)}
                        />
                        <label htmlFor={`inv_YN${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="show_out"
                          value={show_out}
                          style={{imeMode: "disabled"}}
                          onFocus={(e) => e.target.select()}
                          // onKeyPress={numkeyCheck}
                          onBlur={(e) => handleChangeValue(base_seq, "show_out", e.target.value)}
                          maxLength={4}
                        />
                      </td>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk1${count}`}
                          name="opt1"
                          value="Y"
                          checked={opt1 === "Y"}
                          onChange={(e) => change_value(base_seq, 'opt1', e.target.checked)}
                        />
                        <label htmlFor={`chk1${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk2${count}`}
                          name="opt2"
                          value="Y"
                          checked={opt2 === "Y"}
                          onChange={(e) => change_value(base_seq, 'opt2', e.target.checked)}
                        />
                        <label htmlFor={`chk2${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk3${count}`}
                          name="opt3"
                          value="Y"
                          checked={opt3 === "Y"}
                          onChange={(e) => change_value(base_seq, 'opt3', e.target.checked)}
                        />
                        <label htmlFor={`chk3${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk4${count}`}
                          name="opt4"
                          value="Y"
                          checked={opt4 === "Y"}
                          onChange={(e) => change_value(base_seq, 'opt4', e.target.checked)}
                        />
                        <label htmlFor={`chk4${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk5${count}`}
                          name="opt5"
                          value="Y"
                          checked={opt5 === "Y"}
                          onChange={(e) => change_value(base_seq, 'opt5', e.target.checked)}
                        />
                        <label htmlFor={`chk5${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk6${count}`}
                          name="opt6"
                          value="Y"
                          checked={opt6 === "Y"}
                          onChange={(e) => change_value(base_seq, 'opt6', e.target.checked)}
                        />
                        <label htmlFor={`chk6${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk7${count}`}
                          name="opt7"
                          value="Y"
                          checked={opt7 === "Y"}
                          onChange={(e) => change_value(base_seq, 'opt7', e.target.checked)}
                        />
                        <label htmlFor={`chk7${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk8${count}`}
                          name="opt8"
                          value="Y"
                          checked={opt8 === "Y"}
                          onChange={(e) => change_value(base_seq, 'opt8', e.target.checked)}
                        />
                        <label htmlFor={`chk8${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk9${count}`}
                          name="opt9"
                          value="Y"
                          checked={opt9 === "Y"}
                          onChange={(e) => change_value(base_seq, 'opt9', e.target.checked)}
                        />
                        <label htmlFor={`chk9${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`chk10${count}`}
                          name="opt10"
                          value="Y"
                          checked={opt10 === "Y"}
                          onChange={(e) => change_value(base_seq, 'opt10', e.target.checked)}
                        />
                        <label htmlFor={`chk10${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`show_YN${count}`}
                          name="show_YN"
                          value="Y"
                          checked={show_YN === "Y"}
                          onChange={(e) => change_value(base_seq, 'show_YN', e.target.checked)}
                        />
                        <label htmlFor={`show_YN${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`H_show_YN${count}`}
                          name="H_show_YN"
                          value="Y"
                          checked={H_show_YN === "Y"}
                          onChange={(e) => change_value(base_seq, 'H_show_YN', e.target.checked)}
                        />
                        <label htmlFor={`H_show_YN${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="checkbox"
                          className="checkbox"
                          id={`inv_YN${count}`}
                          name="inv_YN"
                          value="Y"
                          checked={inv_YN === "Y"}
                          onChange={(e) => change_value(base_seq, 'inv_YN', e.target.checked)}
                        />
                        <label htmlFor={`inv_YN${count}`}>
                          <span></span>
                        </label>
                      </td>
                      <td>
                        <input
                          type="text"
                          name="show_out"
                          id={`show_out${count}`}
                          style={{imeMode: 'disabled'}}
                          onFocus={(e) => e.target.select()}
                          // onKeyPress={numkeyCheck}
                          onBlur={(e) => change_value(base_seq, 'show_out', e.target.value)}
                          maxLength={4}
                        />
                      </td>
                    </React.Fragment>
                  )}
                  <td>
                    {Fn.parseInt(info_num) ? (
                      <strong>
                        <font color="blue">Y</font>
                      </strong>
                    ) : (
                      <strong>
                        <font color="red">N</font>
                      </strong>
                    )}
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div>
          <button type="button" className="btn btn-sm btn-outline-point px-3" onClick={() => history.push("/config/branch/reg")}>추가</button>
        </div>
      </nav>
    </CmnCard>
  );
};

export default ConfigBranchList;
