import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import _ from "lodash";
import JDatePicker from "../common/JDatePicker";

// datepicker bind 외에 스크립트 없음

const SalesBranchState = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [ret, setRet] = useState({});
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#start_date').val(`${year}-${month}-01`);
    $('#end_date').val(today);
  }, []);

  useEffect(() => {
    load();
  }, [renew]);

  const load = () => {
    axios2
      .post(`/sales/branch/sales/state/ready?start_date=${$('#start_date').val()}&end_date=${$('#end_date').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          const row = {};

          row.tot_price = result?.tot_price;

          let s1_price = [];
          result?.s1.forEach(row => {
            let branchCode = row['branchCode'];
            s1_price[branchCode] = row['price'];
          });
          row.s1_price = s1_price;

          let s2_price = [];
          result?.s2.forEach(row => {
            let branchCode = row['branchCode'];
            s2_price[branchCode] = row['price'];
          });
          row.s2_price = s2_price;

          let s3_price = [];
          result?.s3.forEach(row => {
            let branchCode = row['branchCode'];
            s3_price[branchCode] = row['price'];
          });
          row.s3_price = s3_price;

          let s4_price = [];
          result?.s4.forEach(row => {
            let branchCode = row['branchCode'];
            s4_price[branchCode] = row['price'];
          });
          row.s4_price = s4_price;

          let s5_price = [];
          result?.s5.forEach(row => {
            let branchCode = row['branchCode'];
            s5_price[branchCode] = row['price'];
          });
          row.s5_price = s5_price;

          let s6_price = [];
          result?.s6.forEach(row => {
            let branchCode = row['branchCode'];
            s6_price[branchCode] = row['price'];
          });
          row.s6_price = s6_price;

          let s7_price = [];
          result?.s7.forEach(row => {
            let branchCode = row['branchCode'];
            s7_price[branchCode] = row['price'];
          });
          row.s7_price = s7_price;

          let s8_price = [];
          result?.s8.forEach(row => {
            let branchCode = row['branchCode'];
            s8_price[branchCode] = row['price'];
          });
          row.s8_price = s8_price;

          let tot_s1_price = 0;
          let tot_s2_price = 0;
          let tot_s3_price = 0;
          let tot_s4_price = 0;
          let tot_s5_price = 0;
          let tot_s6_price = 0;
          let tot_s7_price = 0;
          let tot_s8_price = 0;

          let tot_real_price = 0;
          let tot_supply_price = 0;
          let tot_add_price = 0;
          let tot_new_real_price = 0;

          result?.result.forEach(_row => {
            const branch_code = _row?.branch_code;
            const branch_name = _row?.branch_name;

            let real_price = (row?.s5_price[branch_code] || 0) - (row?.s6_price[branch_code] || 0);
            let supply_price = Math.round(real_price / 1.1);
            let add_price = real_price - supply_price;
            let new_real_price = real_price - (row?.s8_price[branch_code] || 0);

            tot_s1_price += row?.s1_price?.[branch_code] || 0;
            tot_s2_price += row?.s2_price?.[branch_code] || 0;
            tot_s3_price += row?.s3_price?.[branch_code] || 0;
            tot_s4_price += row?.s4_price?.[branch_code] || 0;
            tot_s5_price += row?.s5_price?.[branch_code] || 0;
            tot_s6_price += row?.s6_price?.[branch_code] || 0;
            tot_s7_price += row?.s7_price?.[branch_code] || 0;
            tot_s8_price += row?.s8_price?.[branch_code] || 0;

            tot_real_price += real_price;
            tot_supply_price += supply_price;
            tot_add_price += add_price;
            tot_new_real_price += new_real_price;
          });

          row.tot_s1_price = tot_s1_price;
          row.tot_s2_price = tot_s2_price;
          row.tot_s3_price = tot_s3_price;
          row.tot_s4_price = tot_s4_price;
          row.tot_s5_price = tot_s5_price;
          row.tot_s6_price = tot_s6_price;
          row.tot_s7_price = tot_s7_price;
          row.tot_s8_price = tot_s8_price;
          row.result = result?.result;

          row.tot_real_price = tot_real_price;
          row.tot_supply_price = tot_supply_price;
          row.tot_add_price = tot_add_price;
          row.tot_new_real_price = tot_new_real_price;

          setRet(row);
        }
      })
      .catch((error) => {
        console.error(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function yoil_show(value) {
    let yy = "";

    if (value === 0) {
      yy = <span style={{color: "red"}}>일</span>;
    } else if (value === 1) {
      yy = "월";
    } else if (value === 2) {
      yy = "화";
    } else if (value === 3) {
      yy = "수";
    } else if (value === 4) {
      yy = "목";
    } else if (value === 5) {
      yy = "금";
    } else if (value === 6) {
      yy = <span style={{color: "blue"}}>토</span>;
    }

    return yy;
  }

  const make_monitor = () => {
    const enterMonitorBlack = window.open('/inc/black.html', 'enter_monitor', 'width=0,height=0,scrollbars=yes,top=10000,left=10000');
    enterMonitorBlack.close();

    const enterMonitor = window.open('/enter/enter_monitor.html', 'enter_monitor', 'width=500,height=500,scrollbars=yes,top=600,left=1000');
  };
  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="overflowX" style={{minHeight: "500px"}}>
        <div className="d-flex m-3 table_wide_sh2">
          <div className="pr-1">
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="start_date"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="end_date"/>
            </div>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>검색</button>
            {/*TODO : PTODO -> 2023.10.31 : No API*/}
            <button type="button" className="btn btn-sm btn-dark px-3 mx-1" style={{display: "none"}}>엑셀저장</button>
          </div>
        </div>

        <div className="m-3 table_wide_sh2">
          {/* ---- 회원목록 ---- */}
          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="250"/>
            </colgroup>
            <tr>
              <th>지점명</th>
              <th>판매총액</th>
              <th>취소금액</th>
              <th>반품금액</th>
              <th>할인금액</th>
              <th>손실금액</th>
              <th>실판매금액</th>
              <th>구매원가</th>
              <th>순익</th>
              <th className="bgcolor2">입고(발주)금액</th>
              <th className="bgcolor2">손실금액</th>
              <th>매출비중</th>
            </tr>
            {ret?.result?.length && ret?.result.map(row => {
              const branch_code = row.branch_code;
              const branch_name = row.branch_name;

              let real_price = (ret?.s5_price?.[branch_code] || 0) - (ret?.s6_price?.[branch_code] || 0);
              let supply_price = Math.round(real_price / 1.1);
              let new_real_price = real_price - (ret?.s8_price?.[branch_code] || 0);

              let rate = 0;
              if (ret?.tot_price) {
                rate = Math.round((ret?.s5_price?.[branch_code] / ret?.tot_price) * 100);
              } else {
                rate = 0;
              }

              return (
                <tr>
                  <td className="text-left">{branch_name}</td>
                  <td className="text-right">{ret?.s1_price?.[branch_code] ? Fn.numberWithCommas(ret?.s1_price?.[branch_code]) : ""}</td>
                  <td className="text-right">{ret?.s2_price?.[branch_code] ? Fn.numberWithCommas(ret?.s2_price?.[branch_code]) : ""}</td>
                  <td className="text-right">{ret?.s3_price?.[branch_code] ? Fn.numberWithCommas(ret?.s3_price?.[branch_code]) : ""}</td>
                  <td className="text-right">{ret?.s4_price?.[branch_code] ? Fn.numberWithCommas(ret?.s4_price?.[branch_code]) : ""}</td>
                  <td className="text-right">{ret?.s7_price?.[branch_code] ? Fn.numberWithCommas(ret?.s7_price?.[branch_code]) : ""}</td>
                  <td className="text-right">{ret?.s5_price?.[branch_code] ? Fn.numberWithCommas(ret?.s5_price?.[branch_code]) : ""}</td>
                  <td className="text-right">{ret?.s6_price?.[branch_code] ? Fn.numberWithCommas(ret?.s6_price?.[branch_code]) : ""}</td>
                  <td className="text-right">{real_price ? Fn.numberWithCommas(real_price) : ""}</td>
                  <td className="text-right bgcolor3">{ret?.s8_price?.[branch_code] ? Fn.numberWithCommas(ret?.s8_price?.[branch_code]) : ""}</td>
                  <td className="text-right bgcolor3">{new_real_price ? Fn.numberWithCommas(new_real_price) : ""}</td>
                  <td>
                    <div className="progress">
                      {ret?.s5_price?.[branch_code] &&
                        <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width: `${rate}%`}}>
                          {rate}%
                        </div>
                      }
                    </div>
                  </td>
                </tr>
              );
            })}
            <tr className="bold700 bgcolor">
              <td>합계</td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s1_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s2_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s3_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s4_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s7_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s5_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_s6_price)}</strong></td>
              <td className="text-right"><strong>{Fn.numberWithCommas(ret?.tot_real_price)}</strong></td>
              <td className="text-right bgcolor3"><strong>{Fn.numberWithCommas(ret?.tot_s8_price)}</strong></td>
              <td className="text-right bgcolor3">{Fn.numberWithCommas(ret?.tot_new_real_price)}</td>
              <td>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default SalesBranchState;
