import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";

import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import Fn from "../../libraries/Fn";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";


const MemberBlackList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    load();
  }, [currPage, renew]);

  const load = () => {
    axios2
      .post(`/member/member/blacklist/ready?page=${currPage}&date_type=${$('#date_type option:selected').val() || ''}&s_start_date=${$('#s_start_date').val()}&s_end_date=${$('#s_end_date').val()}&s_status=${$('#s_status option:selected').val() || ''}&key=${$('#key').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          Fn.processPhone(result?.result, pageInfo, "ret?.mb_hp");

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const [authCode, setAuthCode] = useState("");

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="date_type">
            <option value="A.register_date">등록일</option>
            <option value="A.release_date">해제일</option>
          </select>
        </div>
        <div className="pr-1 d-flex">
          <div className="input-group" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_start_date"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_end_date"/>
          </div>
        </div>
        <div className="pr-1">
          <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="s_status">
            <option value="">::상태::</option>
            <option value="Y">등록</option>
            <option value="N">해제</option>
          </select>
        </div>
        <div className="pr-1">
          <input
            type="text"
            className="form-control"
            aria-describedby="button-addon2"
            placeholder="이름/핸드폰 검색"
            id="key"
          />
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setRenew(Fn.getRenewTime())}>
            검색
          </button>
        </div>
      </div>

      <div className="m-3 table_list">
        {/* 회원목록 */}
        <table className="view-rounded sub_table_rowspan">
          <colgroup>
            <col width="60px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>회원번호</th>
            <th>성명</th>
            <th>시작일</th>
            <th>종료일</th>
            <th>연락처</th>
            <th>등록일</th>
            <th>등록사유</th>
            <th>해제일</th>
            <th>해제사유</th>
            <th>상태</th>
          </tr>
          {rets && rets.map((ret, i) => {

            const statusTxt = ret?.status === 'Y' ? <span style={{color: 'red'}}>등록</span> : <span style={{color: 'blue'}}>해제</span>;

            let mb_hp_show = Fn.hpShow(ret?.mb_hp, pageInfo?.totalArticles);
            mb_hp_show = '***-****-****'


            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>{ret?.mb_id}</td>
                <td>{ret?.mb_name}</td>
                <td>{ret?.start_date}</td>
                <td>{ret?.end_date}</td>
                <td>
                  <div id={`default_show${i + 1}`}>{mb_hp_show}</div>
                </td>
                <td>{(ret?.register_date || '').replace(/-/g, '.').substr(2, 8)}</td>
                <td className="text-left">{ret?.register_reason}</td>
                <td>{(ret?.release_date || '').replace(/-/g, '.').substr(2, 8)}</td>
                <td className="text-left">{ret?.release_reason}</td>
                <td>{statusTxt}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={11}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="p-3 my-4 d-flex justify-content-between border-top">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div className="d-flex mx-1">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
          {pageInfo?.totalArticles > 0 && (
            (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 9 ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 8 && localStorage.getItem("USER.systemManageYN") !== "Y" && pageInfo?.totalArticles <= 2000) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 7 && pageInfo?.totalArticles <= 300) ||
              (Fn.parseInt(localStorage.getItem("USER.systemUserAuth")) === 6 && pageInfo?.totalArticles <= 10)) && (
              <button type="button" className="btn btn-sm btn-outline-dark px-2" onClick={() => Fn.showPhone('blacklist')}>연락처 확인</button>
            )
          )}
        </div>
      </nav>

      <input type="hidden" id="tot_base_hp" style={{display: "none"}}/>
      <input type="hidden" id="tot_record" style={{display: "none"}}/>
      <input type="hidden" id="tot_sql" style={{display: "none"}}/>

    </CmnCard>
  );
};
export default MemberBlackList;
