import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from "jquery";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import {useHistory} from "react-router-dom";

const FinanceReport = (props) => {
  const history = useHistory();

  window._load = (_page = 1) => {
    try {
      load(_page);

      if (!(localStorage.getItem("FMR.s_year") !== "" && localStorage.getItem("FMR.s_year") !== undefined && localStorage.getItem("FMR.s_year") !== null)) {
        if (localStorage.getItem("fmr_url") !== undefined && localStorage.getItem("fmr_topName") !== "")
          load2(localStorage.getItem("fmr_url"), localStorage.getItem("fmr_topName"));
        else {
          localStorage.setItem("fmr_url", '');
          localStorage.setItem("fmr_topName", '');
          load2(localStorage.getItem("fmr_url"), localStorage.getItem("fmr_topName"));
        }
      }
    } catch (e) {
    }
  }
  const [result, setResult] = useState({});
  const [result2, setResult2] = useState();
  const [srchRenew, setSrchRenew] = useState(0);
  const [dtlRenew, setDtlRenew] = useState(0);
  const [topName, setTopName] = useState('');
  const [chkAble, setChkAble] = useState('');
  const [colorType, setColorType] = useState(1);

  if (props?.match?.params?.rn != "rsrv") {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    $('#ddate').val('0');

    localStorage.setItem("fmr_s_date1", `${year}-${month}-01`);
    localStorage.setItem("fmr_s_date2", today);
    localStorage.setItem("fmr_ddate", "0");
    localStorage.setItem("fmr_url", '');
    localStorage.setItem("fmr_topName", '');

    history.push("/finance/report/rsrv/" + Fn.getRenewTime());
  }

  useEffect(() => {
    const today = Fn.getCurrentDate();
    const [year, month] = today.split("-");

    if (localStorage.getItem("FMR.s_year") !== "" && localStorage.getItem("FMR.s_year") !== undefined && localStorage.getItem("FMR.s_year") !== null) {
      $('#s_date1').val(localStorage.getItem("FMR.e_year") + "-" + localStorage.getItem("FMR.e_month") + "-01");
      $('#s_date2').val(localStorage.getItem("FMR.e_year") + "-" + localStorage.getItem("FMR.e_month") + "-" + new Date(Fn.parseInt(localStorage.getItem("FMR.e_year")), Fn.parseInt(localStorage.getItem("FMR.e_month")), 0).getDate());

      setTimeout(() => {
        localStorage.removeItem("FMR.s_year");
        localStorage.removeItem("FMR.s_month");
        localStorage.removeItem("FMR.e_year");
        localStorage.removeItem("FMR.e_month");
      }, 3000);
    } else {
      $('#s_date1').val(`${year}-${month}-01`);
      $('#s_date2').val(today);
    }
  }, []);

  useEffect(() => {
    if (!(localStorage.getItem("FMR.s_year") !== "" && localStorage.getItem("FMR.s_year") !== undefined && localStorage.getItem("FMR.s_year") !== null)) {
      $('#ddate').val(localStorage.getItem("fmr_ddate"));
      $('#s_date1').val(localStorage.getItem("fmr_s_date1"));
      $('#s_date2').val(localStorage.getItem("fmr_s_date2"));
    }
    load();
    if (!(localStorage.getItem("FMR.s_year") !== "" && localStorage.getItem("FMR.s_year") !== undefined && localStorage.getItem("FMR.s_year") !== null)) {
      load2(localStorage.getItem("fmr_url"), localStorage.getItem("fmr_topName"));
    }
  }, [props?.match?.params?.rn]);

  useEffect(() => {
    if (srchRenew > 0) {
      if (!(localStorage.getItem("FMR.s_year") !== "" && localStorage.getItem("FMR.s_year") !== undefined && localStorage.getItem("FMR.s_year") !== null)) {
        localStorage.setItem("fmr_s_date1", $('#s_date1').val());
        localStorage.setItem("fmr_s_date2", $('#s_date2').val());
        localStorage.setItem("fmr_ddate", $('#ddate option:selected').val() || '');
      }
      // localStorage.setItem("fm_table_type", $('#table_type option:selected').val() || '');
      load();
    }
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/finance/finance/report/ready?ddate=${$('#ddate option:selected').val()}&s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          let chk_able = "N";
          if (result?.idChkRow?.account_YN == "Y" || localStorage.getItem("USER.systemID") == "mamark" || localStorage.getItem("USER.systemID") == "futureinfo") {
            chk_able = "Y";
          }
          setChkAble(chk_able);

          setResult(result);
          $('#color_type0').prop("checked", true);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const load2 = (url, topName) => {
    if (!url) {
      setTopName('');
      setResult2(undefined);
      return;
    }

    localStorage.setItem("fmr_url", url);
    localStorage.setItem("fmr_topName", topName);

    setTopName(topName);

    axios2
      .post(url)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setDtlRenew(Fn.getRenewTime());
          setTimeout(() => {
            setResult2(result);
            console.log('result', result);
          }, 100);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const data_copy = (val) => {
    let cd;
    const ck_ckk_no = val?.split("_")?.[1];

    if ($('#color_type0').prop("checked")) {
      cd = 0;
      $(`#${val}`).css('background-color', '#ffffff');
    } else if ($('#color_type1').prop("checked")) {
      cd = 1;
      $(`#${val}`).css('background-color', '#f5f5f5');
    } else if ($('#color_type2').prop("checked")) {
      cd = 2;
      $(`#${val}`).css('background-color', '#d0ecfb');
    } else if ($('#color_type3').prop("checked")) {
      cd = 3;
      $(`#${val}`).css('background-color', '#fcf8e3');
    }

    axios2
      .post(`/finance/change/state?mode=cardChange&no=${ck_ckk_no}&cd=${cd}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  const getValueByKey = (data, key) => {
    try {
      let t = Object.keys(data);
      for (let i = 0; i < t?.length; i++) {
        if (t[i] == key)
          return data?.[key];
      }
    } catch (err) {
    }
    return null;
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="row m-3">
        <div className="col-4">
          <div className="d-flex my-3">
            <div className="pr-1">
              <select className="form-select" id="ddate" onChange={(e) => Fn.period(e.target.value)}>
                <option value="0">검색기간</option>
                <option value="1">오늘</option>
                <option value="2">이번달</option>
                <option value="3">전월</option>
                <option value="4">1분기</option>
                <option value="5">2분기</option>
                <option value="6">1,2분기</option>
                <option value="7">3분기</option>
                <option value="8">4분기</option>
                <option value="9">3,4분기</option>
                <option value="10">올해전체</option>
                <option value="11">전1년</option>
              </select>
            </div>
            <div className="input-group">
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
            </div>
            <div className="mx-1">
              <button type="button" className="btn btn-sm btn-point px-3 space_nowrap" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
            </div>
          </div>
          <div className="my-3">
            <table className="view-rounded sub_table_border auto">
              <tr style={{cursor: "pointer"}} onClick={() => load2(`/finance/finance/report?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&gyecode=1111&jukcode=현금&top_name=현금`, '현금')}>
                <th width="30%">현금</th>
                <td className="text-right">
                  {Fn.numberWithCommas(result?.cash?.["jan"])}
                </td>
              </tr>
            </table>
          </div>

          <div className="my-3">
            <table className="view-rounded sub_table_border auto">
              <tbody>
              <tr>
                <th colSpan={2}>통장</th>
              </tr>
              </tbody>
              {result?.bank && Object.entries(result?.bank).map(([k, v]) =>
                result?.bk?.[k] &&
                <tr style={{cursor: "pointer"}} onClick={() => load2(`/finance/finance/report?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&gyecode=1112&jukcode=${k}&top_name=${result?.bk[k]}`, result?.bk[k])}>
                  <td width="30%" className="danger">
                    {result?.bk?.[k]}
                  </td>
                  <td className="text-right">
                    {Fn.numberWithCommas(result?.bank?.[k])}
                  </td>
                </tr>
              )}
            </table>
          </div>

          <div className="my-3">
            <table className="view-rounded sub_table_border auto">
              <tr style={{cursor: "pointer"}} onClick={() => load2(`/finance/finance/report?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&gyecode=1143&jukcode=&top_name=전체카드`, '전체카드')}>
                <th colSpan="2">카드매출금</th>
              </tr>
              {result?.card && Object.entries(result?.cardA).map(([k, v]) =>
                <tr style={{cursor: "pointer"}} onClick={() => load2(`/finance/finance/report?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&gyecode=1143&jukcode=${k}&top_name=${result?.bk[k]}`, result?.bk[k])}>
                  <td width="30%" className="danger">
                    {result?.bk?.[k]}
                  </td>
                  <td className="text-right">
                    {Fn.numberWithCommas(result?.card?.[k])}
                  </td>
                </tr>
              )}
              <tr>
                <td width="30%">합계</td>
                <td className="text-right">{Fn.numberWithCommas(result?.sumCard)}</td>
              </tr>
            </table>
          </div>

          <div className="my-3">
            <table className="view-rounded sub_table_border auto">
              <tr style={{cursor: "pointer"}} onClick={() => load2(`/finance/finance/report?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&code=all&top_name=전체`, '전체')}>
                <th width="30%">합계</th>
                <td className="text-right bold700 text-danger">{Fn.numberWithCommas(result?.sumCard + result?.sumBank + (result?.cash?.["jan"] || 0))}</td>
              </tr>
              <tr>
                <th>전잔액</th>
                <td className="text-right bold700 text-primary">{Fn.numberWithCommas(result?.b_row?.["jan"])}</td>
              </tr>
              <tr>
                <th>증감</th>
                <td className="text-right bold700 text-success">{Fn.numberWithCommas((result?.sumCard + result?.sumBank + (result?.cash?.["jan"] || 0)) - (result?.b_row?.["jan"] || 0))}</td>
              </tr>
            </table>
          </div>
        </div>

        <div className="col-8">
          <div className="tip_box my-3">
            Tip! 좌측의 "현금금액", "통장금액", "카드매출금액", "합계금액"을 클릭하시면 해당 상세 내역을 보실 수 있습니다.<br/>
            전잔액 상단의 합계금액을 클릭하시면 현금+통장+카드매출금 전체내역 확인이 가능합니다.
          </div>

          {result2 &&
            <div className="my-3" key={dtlRenew}>
              <table className="view-rounded sub_table_border auto" style={{width: "fit-content"}}>
                <colgroup>
                  <col width="60px"/>
                  <col width="90px"/>
                  <col width="287px"/>
                  <col width="110px"/>
                  <col width="110px"/>
                  <col width="110px"/>
                  <col width="110px"/>
                  {result2?.code !== "all" && <col width="110px"/>}
                  <col width="100px"/>
                </colgroup>

                <tr>
                  <td colSpan={result2?.code === "all" ? 8 : 9}>
                    <div className="d-flex justify-content-between">
                      {topName ?
                        <p className="h7 bold700 pl-1">{topName} - 상세내역</p>
                        :
                        <p className="h7 bold700 pl-1">상세내역</p>
                      }
                      {topName !== '전체' &&
                        <div>
                          <span className="px-2 bold700 vertical-middle">색상선택 :</span>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name="color_type" id="color_type0" value="0" type="radio" onClick={() => setColorType(0)} checked={colorType === 0}/>
                            <label className="form-check-label" htmlFor="color_type0">흰색</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name="color_type" id="color_type1" value="1" type="radio" onClick={() => setColorType(1)} checked={colorType === 1}/>
                            <label className="form-check-label" htmlFor="color_type1" style={{backgroundColor: "#f5f5f5"}}>회색</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name="color_type" id="color_type2" value="2" type="radio" onClick={() => setColorType(2)} checked={colorType === 2}/>
                            <label className="form-check-label" htmlFor="color_type2" style={{backgroundColor: "#d0ecfb"}}>하늘</label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input className="form-check-input" name="color_type" id="color_type3" value="3" type="radio" onClick={() => setColorType(3)} checked={colorType === 3}/>
                            <label className="form-check-label" htmlFor="color_type3" style={{backgroundColor: "#fcf8e3"}}>노랑</label>
                          </div>
                        </div>
                      }
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>No</th>
                  <th>날짜</th>
                  <th>내용</th>
                  <th>출금액</th>
                  <th>입금액</th>
                  <th>잔액</th>
                  <th>적요</th>
                  {result2?.code !== "all" && <th>수수료계</th>}
                  <th>구분</th>
                </tr>

                {(result2?.result?.length > 0 || true) &&
                  <tr className="bgcolor bold700">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-right"></td>
                    <td className="text-right">{result2?.code !== "all" ? "전잔액" : "전잔액1"}</td>
                    <td className="text-right">{Fn.numberWithCommas(result2?.b_row?.jan || 0)}</td>
                    <td></td>
                    {result2?.code !== "all" && <td></td>}
                    <td></td>
                  </tr>
                }

                {result2 && result2?.result?.length > 0 && result2?.result?.map((row, index) => {
                  let count = index + 1;
                  let backgroundColor = '#ffffff';
                  if (row?.ckk_card == 1) backgroundColor = '#f5f5f5';
                  else if (row?.ckk_card == 2) backgroundColor = '#d0ecfb';
                  else if (row?.ckk_card == 3) backgroundColor = '#fcf8e3';

                  const t = (getValueByKey(result2?.T_date, count) === getValueByKey(result2?.T_date, count - 1)) && (getValueByKey(result2?.t_cha, count) !== null && getValueByKey(result2?.t_cha, count - 1) !== null);
                  if (t) {
                    if (result2?.gyecode == 1143) {
                      return (
                        <tr className="cls-dtl" id={`lst_${row?.ck_ckk_no}_${row?.jan}`} style={{backgroundColor: backgroundColor, cursor: chkAble === "Y" ? "pointer" : undefined}} onClick={() => {
                          if (chkAble === "Y")
                            data_copy(`lst_${row?.ck_ckk_no}_${row?.jan}`);
                        }}>
                          <td>{index + 1}</td>
                          <td>{row?.ck_date}</td>
                          <td className="text-left">{row?.c_show}</td>
                          <td className="text-right">{row?.ss_show}</td>
                          <td className="text-right">{row?.gg_show}</td>
                          <td className="text-right">{Fn.numberWithCommas(row?.jan)}</td>
                          <td>
                            {row?.ck_summary}
                            <input type="hidden" size="1" value={row?.ckk_card} id={`cd${row?.ck_ckk_no}`}/>
                          </td>
                          {result2?.code !== "all" && <td className="text-right"><font color='red'>{result2?.m_value?.[row?.ck_date?.replace(/-/g, "")] != 0 ? Fn.numberWithCommas(result2?.m_value?.[row?.ck_date?.replace(/-/g, "")]) : ""}</font></td>}
                          <td>
                            <div dangerouslySetInnerHTML={{__html: row?.pay_route_show}}/>
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr className="cls-dtl" id={`lst_${row?.ck_ckk_no}_${row?.jan}`} style={{backgroundColor: backgroundColor, cursor: chkAble === "Y" ? "pointer" : undefined}} onClick={() => {
                          if (chkAble === "Y")
                            data_copy(`lst_${row?.ck_ckk_no}_${row?.jan}`);
                        }}>
                          <td>{index + 1}</td>
                          <td>{row?.ck_date}</td>
                          <td className="text-left">{row?.c_show}</td>
                          <td className="text-right">{row?.ss_show}</td>
                          <td className="text-right">{row?.gg_show}</td>
                          <td className="text-right">{Fn.numberWithCommas(row?.jan)}</td>
                          <td>
                            {row?.ck_summary}
                            <input type="hidden" size="1" value={row?.ckk_card} id={`cd${row?.ck_ckk_no}`}/>
                          </td>
                          {result2?.code !== "all" && <td className="text-right"><font color='red'>&nbsp;</font></td>}
                          <td>
                            <div dangerouslySetInnerHTML={{__html: row?.pay_route_show}}/>
                          </td>
                        </tr>
                      );
                    }
                  } else {
                    return (
                      <tr className="cls-dtl" id={`lst_${row?.ck_ckk_no}_${row?.jan}`} style={{backgroundColor: backgroundColor, cursor: chkAble === "Y" ? "pointer" : undefined}} onClick={() => {
                        if (chkAble === "Y")
                          data_copy(`lst_${row?.ck_ckk_no}_${row?.jan}`);
                      }}>
                        <td>{index + 1}</td>
                        <td>{row?.ck_date}</td>
                        <td className="text-left">{row?.c_show}</td>
                        <td className="text-right">{row?.ss_show}</td>
                        <td className="text-right">{row?.gg_show}</td>
                        <td className="text-right">{Fn.numberWithCommas(row?.jan)}</td>
                        <td>
                          {row?.ck_summary}
                          <input type="hidden" size="1" value={row?.ckk_card} id={`cd${row?.ck_ckk_no}`}/>
                        </td>
                        {result2?.code !== "all" && <td className="text-right"><font color='red'>&nbsp;</font></td>}
                        <td>
                          <div dangerouslySetInnerHTML={{__html: row?.pay_route_show}}/>
                        </td>
                      </tr>
                    );
                  }
                })}
                {(result2?.result?.length > 0 || true) &&
                  <tr className="cls-dtl bgcolor2 bold700">
                    <th colSpan={3} className="text-right">소계</th>
                    <th className="text-right">{Fn.numberWithCommas(result2?.cdDae || 0)}</th>
                    <th className="text-right">{Fn.numberWithCommas(result2?.cdCha || 0)}</th>
                    {/*<th className="text-right"><span className={"text-danger"}>{Fn.numberWithCommas(result2?.result[result2?.result?.length - 1]?.jan || result2?.l_jan || 0)}</span></th>*/}
                    <th className="text-right"><span className={"text-danger"}>{Fn.numberWithCommas(result2?.l_jan || 0)}</span></th>
                    <th></th>
                    {result2?.code !== "all" && <th></th>}
                    <th></th>
                  </tr>
                }
              </table>
            </div>
          }
        </div>
      </div>
    </CmnCard>
  );
};

export default FinanceReport;
