import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import JDatePicker from "../common/JDatePicker";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import _ from "lodash";
import {Bar} from "react-chartjs-2";

const AnalysisUseState = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [result1, setResult1] = useState([]);
  const [result2, setResult2] = useState([]);
  const [result3, setResult3] = useState([]);

  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('#s_date1').val('2023-02-11');
    $('#s_date2').val('2023-09-17');
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    axios2
      .post(`/analysis/use/state/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          console.log(result);
          $('#s_date1').val(result?.sDate1);
          $('#s_date2').val(result?.sDate2);
          setResult1(result?.result1);
          setResult2(result?.result2);
          setResult3(result?.result3);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3">
        <div className="input-group pr-1" style={{width: 'auto'}}>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
          <p className="mx-2">~</p>
          <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="m-3">
        <div className="chart_box">
          <p className="h7 bold700 text-left p-2">시간대별 이용현황</p>
          <div style={{width: '100%', height: '280px', backgroundColor: "#ffffff", padding: '15px'}}>
            {result1?.length > 0 &&
              <Bar options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false
                  },
                },
                options: {
                  // tooltips: {
                  //   mode: 'label',
                  //   position: "nearest",
                  //   label: 'mylabel',
                  //   callbacks: {
                  //     label: function (tooltipItem, data) {
                  //       return data.datasets[tooltipItem.datasetIndex].label + " ^^" + (tooltipItem.yLabel.toString(), 2, ',', '.');
                  //     },
                  //   },
                  // }
                }
              }}
                   data={{
                     labels: result1.map((row, i) => {
                       return (row?.stats_time || 0) + "시";
                     }),
                     datasets: [
                       {
                         label: '시간대별 이용현황',
                         data: result1.map((row, i) => {
                           return row?.cnt || 0;
                         }),
                         backgroundColor: 'rgba(53, 162, 235, 0.5)',
                         barPercentage: 0.4,
                       },
                     ],
                   }}/>
            }
          </div>
        </div>
      </div>

      <div className="m-3">
        <div className="chart_box">
          <p className="h7 bold700 text-left p-2">연령대별 이용현황</p>
          <div style={{width: '100%', height: '280px', backgroundColor: "#ffffff", padding: '15px'}}>
            {result2?.length > 0 &&
              <Bar options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false
                  },
                },
              }}
                   data={{
                     labels: result2.map((row, i) => {
                       return (row?.stats_age || 0) + "대";
                     }),
                     datasets: [
                       {
                         label: '연령대별 이용현황',
                         data: result2.map((row, i) => {
                           return row?.cnt || 0;
                         }),
                         backgroundColor: 'rgba(53, 162, 235, 0.5)',
                         barPercentage: 0.4,
                       },
                     ],
                   }}/>
            }
          </div>
        </div>
      </div>

      <div className="m-3">
        <div className="chart_box">
          <p className="h7 bold700 text-left p-2">성별 이용현황</p>
          <div style={{width: '100%', height: '280px', backgroundColor: "#ffffff", padding: '15px'}}>
            {result3?.length > 0 &&
              <Bar options={{
                maintainAspectRatio: false,
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false
                  },
                },
              }}
                   data={{
                     labels: result3.map((row, i) => {
                       return (row?.stats_sex === "M" ? "남성" : "여성");
                     }),
                     datasets: [
                       {
                         label: '성별 이용현황',
                         data: result3.map((row, i) => {
                           return row?.cnt || 0;
                         }),
                         backgroundColor: 'rgba(53, 162, 235, 0.5)',
                         barPercentage: 0.4,
                       },
                     ],
                   }}/>
            }
          </div>
        </div>
      </div>
    </CmnCard>
  );
};

export default AnalysisUseState;
