import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import JDatePicker from "../common/JDatePicker";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";
import Fn from "../../libraries/Fn";

const {kakao, daum} = window;

const AnalysisMonthlyRegion = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const history = useHistory();

  const [result, setResult] = React.useState();
  const [srchRenew, setSrchRenew] = React.useState(0);
  const [mapRenew, setMapRenew] = React.useState(0);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
    // $('#s_date1').val('2023-07-01'); // TODO : 임시처리
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    load();
  }, [srchRenew]);

  useEffect(() => {
    load();
  }, []);


  //주소로 좌표가져오기
  function bb(addr) {
    var geocoder = new daum.maps.services.Geocoder();

    // 주소로 좌표를 검색합니다
    geocoder.addressSearch(addr, function (result, status) {

      // 정상적으로 검색이 완료됐으면
      if (status === daum.maps.services.Status.OK) {

        var coords = new daum.maps.LatLng(result[0].y, result[0].x);

        var imageSrc = "https://cdc-smms.spoany.co.kr/image/icon_pointer_star.png", // 마커이미지의 주소입니다
          imageSize = new daum.maps.Size(20, 20), // 마커이미지의 크기입니다
          imageOption = {
            offset: new daum.maps.Point(17, 17)
          };

        var markerImage = new daum.maps.MarkerImage(imageSrc, imageSize, imageOption),
          //마커가 표시될 위치입니다
          markerPosition = new daum.maps.LatLng(33.450701, 126.570667);


        // 결과값으로 받은 위치를 마커로 표시합니다
        var marker = new daum.maps.Marker({
          map: window.map,
          position: coords,
          image: markerImage
        });

        /*
        // 인포윈도우로 장소에 대한 설명을 표시합니다
        var infowindow = new daum.maps.InfoWindow({
            content: '<div style="width:150px;text-align:center;padding:6px 0;">우리회사</div>'
        });
        infowindow.open(window.map, marker);
        */
        // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
        window.map.setCenter(coords);
      }
    });
  }

  function aa(add, cnts, type) {
    if (Fn.parseInt(cnts) == 1) {
      if (type == "new") {
        var imageSrc = "https://cdc-smms.spoany.co.kr/image/icon_pointer_r.png", // 마커이미지의 주소입니다
          imageSize = new daum.maps.Size(20, 20), // 마커이미지의 크기입니다
          imageOption = {
            offset: new daum.maps.Point(17, 17)
          };
      } else {
        var imageSrc = "https://cdc-smms.spoany.co.kr/image/icon_pointer_b.png", // 마커이미지의 주소입니다
          imageSize = new daum.maps.Size(20, 20), // 마커이미지의 크기입니다
          imageOption = {
            offset: new daum.maps.Point(17, 17)
          };
      }
    } else {
      if (type == "new") {
        var img_name = "https://cdc-smms.spoany.co.kr/image/icon_pointer_r_" + cnts + ".png";

        //var imageSrc = "https://cdc-smms.spoany.co.kr/image/icon_pointer_r.png", // 마커이미지의 주소입니다
        var imageSrc = img_name, // 마커이미지의 주소입니다

          imageSize = new daum.maps.Size(30, 30), // 마커이미지의 크기입니다
          imageOption = {
            offset: new daum.maps.Point(17, 17)
          };
      } else if (type == "old") {
        var img_name = "https://cdc-smms.spoany.co.kr/image/icon_pointer_b_" + cnts + ".png";
        var imageSrc = img_name, // 마커이미지의 주소입니다

          //var imageSrc = "https://cdc-smms.spoany.co.kr/image/icon_pointer_r.png", // 마커이미지의 주소입니다
          imageSize = new daum.maps.Size(30, 30), // 마커이미지의 크기입니다
          imageOption = {
            offset: new daum.maps.Point(17, 17)
          };
      } else {
        var img_name = "https://cdc-smms.spoany.co.kr/image/icon_pointer_p_" + cnts + ".png";
        var imageSrc = img_name, // 마커이미지의 주소입니다
          //var imageSrc = "https://cdc-smms.spoany.co.kr/image/icon_pointer_b.png", // 마커이미지의 주소입니다
          imageSize = new daum.maps.Size(30, 30), // 마커이미지의 크기입니다
          imageOption = {
            offset: new daum.maps.Point(17, 17)
          };
      }
    }

    // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
    var markerImage = new daum.maps.MarkerImage(imageSrc, imageSize, imageOption),
      //마커가 표시될 위치입니다
      markerPosition = new daum.maps.LatLng(33.450701, 126.570667);

    /*
    var over = '<div class="wrap">' +
    '    <div class="info">' +
    '        <div class="title">' + name +
    '        </div>' +
    '        <div class="body">' +
    '            <div >' +
    '                <div class="desc">'+add+'</div>' +
    '            </div>' +
    '        </div>' +
    '    </div>' +
    '</div>';
    */


    // 주소로 좌표를 검색합니다
    geocoder.addressSearch(add, function (result, status) {

      // 정상적으로 검색이 완료됐으면
      if (status === daum.maps.services.Status.OK) {

        var coords = new daum.maps.LatLng(result[0].y, result[0].x);

        // 결과값으로 받은 위치를 마커로 표시합니다
        var marker = new daum.maps.Marker({
          map: window.map,
          position: coords,
          image: markerImage
        });
        window.marker.push(marker);

        /*
        // 인포윈도우로 장소에 대한 설명을 표시합니다
        var infowindow = new daum.maps.InfoWindow({
        content: over
        });
        infowindow.open(map, marker);
        */

        // 지도의 중심을 결과값으로 받은 위치로 이동시킵니다
        //window.map.setCenter(coords);
      }
    });
  }


  var drawingFlag = false; // 선이 그려지고 있는 상태를 가지고 있을 변수입니다
  var moveLine; // 선이 그려지고 있을때 마우스 움직임에 따라 그려질 선 객체 입니다
  var clickLine // 마우스로 클릭한 좌표로 그려질 선 객체입니다
  var distanceOverlay; // 선의 거리정보를 표시할 커스텀오버레이 입니다
  var dots = {}; // 선이 그려지고 있을때 클릭할 때마다 클릭 지점과 거리를 표시하는 커스텀 오버레이 배열입니다.

  // 클릭으로 그려진 선을 지도에서 제거하는 함수입니다
  function deleteClickLine() {
    if (clickLine) {
      clickLine.setMap(null);
      clickLine = null;
    }
  }

  // 마우스 드래그로 그려지고 있는 선의 총거리 정보를 표시하거
  // 마우스 오른쪽 클릭으로 선 그리가 종료됐을 때 선의 정보를 표시하는 커스텀 오버레이를 생성하고 지도에 표시하는 함수입니다
  function showDistance(content, position) {

    if (distanceOverlay) { // 커스텀오버레이가 생성된 상태이면

      // 커스텀 오버레이의 위치와 표시할 내용을 설정합니다
      distanceOverlay.setPosition(position);
      distanceOverlay.setContent(content);

    } else { // 커스텀 오버레이가 생성되지 않은 상태이면

      // 커스텀 오버레이를 생성하고 지도에 표시합니다
      distanceOverlay = new daum.maps.CustomOverlay({
        map: window.map, // 커스텀오버레이를 표시할 지도입니다
        content: content,  // 커스텀오버레이에 표시할 내용입니다
        position: position, // 커스텀오버레이를 표시할 위치입니다.
        xAnchor: 0,
        yAnchor: 0,
        zIndex: 3
      });
    }
  }

  // 그려지고 있는 선의 총거리 정보와
  // 선 그리가 종료됐을 때 선의 정보를 표시하는 커스텀 오버레이를 삭제하는 함수입니다
  function deleteDistnce() {
    if (distanceOverlay) {
      distanceOverlay.setMap(null);
      distanceOverlay = null;
    }
  }

  // 선이 그려지고 있는 상태일 때 지도를 클릭하면 호출하여
  // 클릭 지점에 대한 정보 (동그라미와 클릭 지점까지의 총거리)를 표출하는 함수입니다
  function displayCircleDot(position, distance) {

    // 클릭 지점을 표시할 빨간 동그라미 커스텀오버레이를 생성합니다
    var circleOverlay = new daum.maps.CustomOverlay({
      content: '<span class="dot"></span>',
      position: position,
      zIndex: 1
    });

    // 지도에 표시합니다
    circleOverlay.setMap(window.map);

    if (distance > 0) {
      // 클릭한 지점까지의 그려진 선의 총 거리를 표시할 커스텀 오버레이를 생성합니다
      var distanceOverlay = new daum.maps.CustomOverlay({
        content: '<div class="dotOverlay">거리 <span class="number">' + distance + '</span>m</div>',
        position: position,
        yAnchor: 1,
        zIndex: 2
      });

      // 지도에 표시합니다
      distanceOverlay.setMap(window.map);
    }

    // 배열에 추가합니다
    dots.push({circle: circleOverlay, distance: distanceOverlay});
  }

  // 클릭 지점에 대한 정보 (동그라미와 클릭 지점까지의 총거리)를 지도에서 모두 제거하는 함수입니다
  function deleteCircleDot() {
    var i;

    for (i = 0; i < dots.length; i++) {
      if (dots[i].circle) {
        dots[i].circle.setMap(null);
      }

      if (dots[i].distance) {
        dots[i].distance.setMap(null);
      }
    }

    dots = [];
  }

  // 마우스 우클릭 하여 선 그리기가 종료됐을 때 호출하여
  // 그려진 선의 총거리 정보와 거리에 대한 도보, 자전거 시간을 계산하여
  // HTML Content를 만들어 리턴하는 함수입니다
  function getTimeHTML(distance) {

    // 도보의 시속은 평균 4km/h 이고 도보의 분속은 67m/min입니다
    var walkkTime = distance / 67 | 0;
    var walkHour = '', walkMin = '';

    // 계산한 도보 시간이 60분 보다 크면 시간으로 표시합니다
    if (walkkTime > 60) {
      walkHour = '<span class="number">' + Math.floor(walkkTime / 60) + '</span>시간 '
    }
    walkMin = '<span class="number">' + walkkTime % 60 + '</span>분'

    // 자전거의 평균 시속은 16km/h 이고 이것을 기준으로 자전거의 분속은 267m/min입니다
    var bycicleTime = distance / 227 | 0;
    var bycicleHour = '', bycicleMin = '';

    // 계산한 자전거 시간이 60분 보다 크면 시간으로 표출합니다
    if (bycicleTime > 60) {
      bycicleHour = '<span class="number">' + Math.floor(bycicleTime / 60) + '</span>시간 '
    }
    bycicleMin = '<span class="number">' + bycicleTime % 60 + '</span>분'

    // 거리와 도보 시간, 자전거 시간을 가지고 HTML Content를 만들어 리턴합니다
    var content = '<ul class="dotOverlay distanceInfo">';
    content += '    <li>';
    content += '        <span class="">총거리 </span><span class="number">' + distance + '</span>m';
    content += '    </li>';
    content += '    <li>';
    content += '        <span class="">도보 </span>' + walkHour + walkMin;
    content += '    </li>';
    content += '    <li>';
    content += '        <span class="">자전거 </span>' + bycicleHour + bycicleMin;
    content += '    </li>';
    content += '</ul>'

    return content;
  }

  var mapTypeControl;
  var zoomControl;

  var mapTypeControl = new daum.maps.MapTypeControl();

  //주소 좌표 변환객체
  var geocoder = new daum.maps.services.Geocoder();

  useEffect(() => {
    $("#map").css("height", window.outerHeight - 300);

    var container = document.getElementById('map');
    var options = {
      center: new daum.maps.LatLng(33.450701, 126.570667),
      level: 3
    };

    window.map = new daum.maps.Map(container, options);

    mapTypeControl = new daum.maps.MapTypeControl();

    // 지도에 컨트롤을 추가해야 지도위에 표시됩니다
    // daum.maps.ControlPosition은 컨트롤이 표시될 위치를 정의하는데 TOPRIGHT는 오른쪽 위를 의미합니다
    window.map.addControl(mapTypeControl, daum.maps.ControlPosition.TOPRIGHT);

    // 지도 확대 축소를 제어할 수 있는  줌 컨트롤을 생성합니다
    zoomControl = new daum.maps.ZoomControl();
    window.map.addControl(zoomControl, daum.maps.ControlPosition.RIGHT);


    // 지도에 클릭 이벤트를 등록합니다
    // 지도를 클릭하면 선 그리기가 시작됩니다 그려진 선이 있으면 지우고 다시 그립니다
    daum.maps.event.addListener(window.map, 'click', function (mouseEvent) {

      // 마우스로 클릭한 위치입니다
      var clickPosition = mouseEvent.latLng;

      // 지도 클릭이벤트가 발생했는데 선을 그리고있는 상태가 아니면
      if (!drawingFlag) {

        // 상태를 true로, 선이 그리고있는 상태로 변경합니다
        drawingFlag = true;

        // 지도 위에 선이 표시되고 있다면 지도에서 제거합니다
        deleteClickLine();

        // 지도 위에 커스텀오버레이가 표시되고 있다면 지도에서 제거합니다
        deleteDistnce();

        // 지도 위에 선을 그리기 위해 클릭한 지점과 해당 지점의 거리정보가 표시되고 있다면 지도에서 제거합니다
        deleteCircleDot();

        // 클릭한 위치를 기준으로 선을 생성하고 지도위에 표시합니다
        clickLine = new daum.maps.Polyline({
          map: window.map, // 선을 표시할 지도입니다
          path: [clickPosition], // 선을 구성하는 좌표 배열입니다 클릭한 위치를 넣어줍니다
          strokeWeight: 3, // 선의 두께입니다
          strokeColor: '#db4040', // 선의 색깔입니다
          strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid' // 선의 스타일입니다
        });

        // 선이 그려지고 있을 때 마우스 움직임에 따라 선이 그려질 위치를 표시할 선을 생성합니다
        moveLine = new daum.maps.Polyline({
          strokeWeight: 3, // 선의 두께입니다
          strokeColor: '#db4040', // 선의 색깔입니다
          strokeOpacity: 0.5, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
          strokeStyle: 'solid' // 선의 스타일입니다
        });

        // 클릭한 지점에 대한 정보를 지도에 표시합니다
        displayCircleDot(clickPosition, 0);


      } else { // 선이 그려지고 있는 상태이면

        // 그려지고 있는 선의 좌표 배열을 얻어옵니다
        var path = clickLine.getPath();

        // 좌표 배열에 클릭한 위치를 추가합니다
        path.push(clickPosition);

        // 다시 선에 좌표 배열을 설정하여 클릭 위치까지 선을 그리도록 설정합니다
        clickLine.setPath(path);

        var distance = Math.round(clickLine.getLength());
        displayCircleDot(clickPosition, distance);
      }
    });

    // 지도에 마우스무브 이벤트를 등록합니다
    // 선을 그리고있는 상태에서 마우스무브 이벤트가 발생하면 그려질 선의 위치를 동적으로 보여주도록 합니다
    daum.maps.event.addListener(window.map, 'mousemove', function (mouseEvent) {

      // 지도 마우스무브 이벤트가 발생했는데 선을 그리고있는 상태이면
      if (drawingFlag) {

        // 마우스 커서의 현재 위치를 얻어옵니다
        var mousePosition = mouseEvent.latLng;

        // 마우스 클릭으로 그려진 선의 좌표 배열을 얻어옵니다
        var path = clickLine.getPath();

        // 마우스 클릭으로 그려진 마지막 좌표와 마우스 커서 위치의 좌표로 선을 표시합니다
        var movepath = [path[path.length - 1], mousePosition];
        moveLine.setPath(movepath);
        moveLine.setMap(window.map);

        var distance = Math.round(clickLine.getLength() + moveLine.getLength()), // 선의 총 거리를 계산합니다
          content = '<div class="dotOverlay distanceInfo">총거리 <span class="number">' + distance + '</span>m</div>'; // 커스텀오버레이에 추가될 내용입니다

        // 거리정보를 지도에 표시합니다
        showDistance(content, mousePosition);
      }
    });

    // 지도에 마우스 오른쪽 클릭 이벤트를 등록합니다
    // 선을 그리고있는 상태에서 마우스 오른쪽 클릭 이벤트가 발생하면 선 그리기를 종료합니다
    daum.maps.event.addListener(window.map, 'rightclick', function (mouseEvent) {

      // 지도 오른쪽 클릭 이벤트가 발생했는데 선을 그리고있는 상태이면
      if (drawingFlag) {

        // 마우스무브로 그려진 선은 지도에서 제거합니다
        moveLine.setMap(null);
        moveLine = null;

        // 마우스 클릭으로 그린 선의 좌표 배열을 얻어옵니다
        var path = clickLine.getPath();

        // 선을 구성하는 좌표의 개수가 2개 이상이면
        if (path.length > 1) {

          // 마지막 클릭 지점에 대한 거리 정보 커스텀 오버레이를 지웁니다
          if (dots[dots.length - 1].distance) {
            dots[dots.length - 1].distance.setMap(null);
            dots[dots.length - 1].distance = null;
          }

          var distance = Math.round(clickLine.getLength()), // 선의 총 거리를 계산합니다
            content = getTimeHTML(distance); // 커스텀오버레이에 추가될 내용입니다

          // 그려진 선의 거리정보를 지도에 표시합니다
          showDistance(content, path[path.length - 1]);

        } else {

          // 선을 구성하는 좌표의 개수가 1개 이하이면
          // 지도에 표시되고 있는 선과 정보들을 지도에서 제거합니다.


          deleteClickLine();
          deleteCircleDot();
          deleteDistnce();

        }

        // 상태를 false로, 그리지 않고 있는 상태로 변경합니다
        drawingFlag = false;
      }
    });

  }, [])

  const load = () => {
    setMapRenew(Fn.getRenewTime());
    if (window.marker?.length > 0) {
      window.marker.forEach((item) => {
        item.setMap(null);
        item = null;
      });
    }
    window.marker = [];

    setTimeout(() => {
      axios2
        .post(`/analysis/monthly/region/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}`)
        .then((response) => {
          const {code, message, result, pageInfo} = response?.data;
          if (code === 200) {
            bb(result?.row2?.address);

            let i = 0;
            result?.rets?.forEach((item) => {
              let addr_code = item?.addr;
              let cnts = Fn.parseInt(item?.cnts);
              let new_old = item?.new_old;

              let new_cnt;
              if (Fn.parseInt(cnts) < 10)
                new_cnt = "0" + cnts;
              else
                new_cnt = cnts;

              i++;
              setTimeout(() => {
                aa(addr_code, new_cnt, new_old);
              }, 10 * i);
            });

            setResult(result);
          }
        })
        .catch((error) => {
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }, 200)
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: "both"}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/analysis/monthly/member")}>월간등록현황</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/analysis/monthly/finance/new")}>월계표 비교분석</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/analysis/monthly/coupon")}>쿠폰발송내역</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/analysis/monthly/tm")}>TM 현황보고</label>

          <input id="ee" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/analysis/monthly/region")}>지역분류</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/analysis/monthly/public")}>경쟁업체(퍼블릭)</label>

          <input id="gg" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/analysis/monthly/pt")}>경쟁업체(PT)</label>

          <input id="hh" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/analysis/monthly/analysis/pt")}>PT월간등록현황</label>
        </div>
      </div>

      <div className="">
        <div className="d-flex justify-content-between m-3">
          <div className="d-flex pr-1" style={{width: "auto"}}>
            <JDatePicker className="form-control" id="s_date1" aria-describedby="button-addon2"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" id="s_date2" aria-describedby="button-addon2"/>

            <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>

          <div className="text-left d-flex justify-content-center align-content-center align-items-center">
            <div className="px-3 bold700">
              <span className="h4 bold700 vertical-middle" style={{color: "#EC0071"}}>◼︎</span> 신규
            </div>
            <p className="px-3 bold700">
              <span className="h4 bold700 vertical-middle" style={{color: "#69C"}}>◼︎</span> 재등록
            </p>
          </div>
        </div>
        <p className="tip_box m-3 text-center">마우스 좌클릭시 거리재기 시작 , 우클릭시 거리재기 종료됩니다.</p>
        <div id="map" className="m-3 mt-0" style={{height: "215px"}}></div>
      </div>

    </CmnCard>
  );
};

export default AnalysisMonthlyRegion;
