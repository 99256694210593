import React, {useContext, useEffect, useState} from "react";
import "../../sass/main.scss";
import Modal from "react-modal";
import APIHelper from "../../helpers/APIHelper";
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import JDatePicker from "../common/JDatePicker";
import $ from "jquery";
import Fn from "../../libraries/Fn";

const MvAttend = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    document.body.style.overflow = 'auto';
    setIsOpen(props.isOpen);
    if (!props.isOpen) props?.onClose();
  }, [props.isOpen]);

  useEffect(() => {
    if (!isOpen) props?.onClose();
  }, [isOpen]);

  const onToggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isOpen)
      load(1);
  }, [srchRenew]);

  useEffect(() => {
    if (isOpen)
      load();
  }, [currPage, renew]);

  const load = (_page) => {
    // mb_no=1027015&tbl_gubun=now&s_date1=2023-09-01&s_date2=2023-09-01
    console.log(`/member/member/attend/ready?mb_no=${props.mbNo}&page=${_page || currPage}&tbl_gubun=${$('#atnd_tbl_gubun option:selected').val() || 'now'}&s_date1=${$('#atnd_s_date1').val()}&s_date2=${$('#atnd_s_date2').val()}`)
    axios2
      .post(`/member/member/attend/ready?mb_no=${props.mbNo}&page=${_page || currPage}&tbl_gubun=${$('#atnd_tbl_gubun option:selected').val() || 'now'}&s_date1=${$('#atnd_s_date1').val() || ''}&s_date2=${$('#atnd_s_date2').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setResult(result);
          setPageInfo(pageInfo);
          console.log(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <Modal
      isOpen={isOpen}
      style={{
        modal: {
          backgroundColor: 'transparent',
          margin: 0,
          padding: 0,
          alignItems: undefined,
          justifyContent: undefined,
        },
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'transparent'
        }
      }}
      onAfterOpen={() => {
        setCurrPage(1);
        $('#atnd_s_date1').val(Fn.getCurrentDate());
        $('#atnd_s_date2').val(Fn.getCurrentDate());
        Fn.centerModal();
      }}
      onRequestClose={() => setIsOpen(false)}
      ariaHideApp={false}
    >
      <div className="pop_background" style={{zIndex: 1000000000}}>
        <div className="pop_box01 p_absolute_mid_pop" style={{width: '900px'}}>
          <div className="POP_title">
            <h5><strong>입장 내역</strong></h5>
            <p className="closed">
              <button type="button" className="btn" onClick={() => setIsOpen(false)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </button>
            </p>
          </div>

          <div className="POP_cnt_box w-100">
            <div className="mb-3 d-flex flex-nowrap">
              <div className="pr-1">
                <select className="form-select" id="atnd_tbl_gubun">
                  <option value="now">2022</option>
                  <option value="2021">2021</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                </select>
              </div>
              <div className="d-flex pr-1">
                <p className="p-1">입장일 : </p>
                <JDatePicker className="form-control" id="atnd_s_date1"/>
                <p className="mx-2">~</p>
                <JDatePicker className="form-control" id="atnd_s_date2"/>
              </div>

              <div className="pr-1">
                <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
              </div>
            </div>
            <table className="view-rounded sub_table_border" style={{borderRadius: 0}}>
              <colgroup>
                <col width="50px"/>
                <col width="170px"/>
                <col width="15%"/>
                <col width="120px"/>
                <col width="*"/>
                <col width="140px"/>
              </colgroup>
              <tr>
                <th>No</th>
                <th>일시</th>
                <th>입장체크번호</th>
                <th>만료일</th>
                <th>입장지점</th>
                <th>입장구분</th>
              </tr>
              {result?.result?.map((ret, i) => {
                return (
                  <tr key={i}>
                    <td className="text-center">{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                    <td className="text-center">{ret?.ba_date} {ret?.ba_time}</td>
                    <td className="text-center">{ret?.cardnum}</td>
                    <td className="text-center">{ret?.ba_end_date}</td>
                    <td className="text-center">{ret?.branch_name}</td>
                    <td className="text-center">{ret?.err_type_show}</td>
                  </tr>
                );
              })}
              {(!result?.result || result?.result?.length === 0) &&
                <tr>
                  <td colSpan={6} className="text-center">※ 검색된 data가 없습니다.</td>
                </tr>
              }
            </table>
          </div>
          <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
            <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
          </nav>
        </div>
      </div>
    </Modal>
  );
};

export default MvAttend;
