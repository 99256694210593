import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Paging from "../common/Paging";
import caretLeftFill from "../../images/caret-left-fill.svg";
import caretRightFill from "../../images/caret-right-fill.svg";
import axios from "axios";
import Fn from "../../libraries/Fn";
import JDatePicker from "../common/JDatePicker";
import Modal from 'react-modal';

const MemberComplainList = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew, srchRenew]);

  const load = () => {
    axios2
      .post(`/branchop/complain/list/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_content=${$('#s_content').val()}&page=${currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  //  3-1.
  const make_monitor = () => {
    const as = window.open('/inc/black.html', 'enter_monitor', 'width=0,height=0,scrollbars=yes,top=10000,left=10000');
    as.window.close();
    const as2 = window.open('/enter/enter_monitor.html', 'enter_monitor', 'width=500,height=500,scrollbars=yes,top=600,left=1000');

    return () => {
      as2.close();
    };
  };

  const del_data = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('체크한 컴플레인 내역을 삭제 하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/branchop/complain/list/del', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          data = data.trim();
          if (data === 'err') {
            alert('오류가 발생하였습니다');
          } else if (data === 'ok') {
            alert('삭제되었습니다');
            setRenew(Fn.getRenewTime());
          }
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  const loadModal = () => {
    axios2
      .post(`/branchop/complain/reg/ready?base_seq=${$('#modal_base_seq').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#modal_branch_code').html() === '') {
            let optionsHTML = '<option value="">::지점::</option>';
            result?.topList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#modal_branch_code').html(optionsHTML);
          }
          if ($('#modal_base_seq').val()) {
            $('#modal_branch_code').val(result?.row?.branch_code);
            $('#modal_c_man').val(result?.row?.c_man);
            $('#modal_c_content').val(result?.row?.c_content);
            $('#modal_c_date').val(result?.row?.c_date);
          }
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const modal_go_submit = () => {
    if (!$('#modal_c_date').val()) {
      alert('등록일을 입력해주세요');
      $('#c_date').focus();
      return;
    }
    if (!$('#modal_branch_code').val()) {
      alert('지점을 선택해주세요');
      $('#modal_branch_code').focus();
      return;
    }
    if (!$('#modal_c_content').val()) {
      alert('내용을 선택해주세요');
      $('#modal_c_content').focus();
      return;
    }
    if (!$('#modal_c_man').val()) {
      alert('등록자를 입력해주세요');
      $('#modal_c_man').focus();
      return;
    }

    if (!window.confirm('컴플레인을 저장하시겠습니까?')) return;

    axios2.postEx('/branchop/complain/reg', {
      base_seq: $('#modal_base_seq').val(),
      c_date: $('#modal_c_date').val(),
      c_content: $('#modal_c_content').val(),
      branch_code: $('#modal_branch_code').val(),
      c_man: $('#modal_c_man').val(),
    })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === 'err') {
          alert('오류가 발생하였습니다');
        } else if (data === 'ok') {
          alert('생성되었습니다.');
          setRenew(Fn.getRenewTime());
          setModalOpen(false);
        } else if (data === 'edit') {
          alert('수정되었습니다.');
          setRenew(Fn.getRenewTime());
          setModalOpen(false);
        }
      })
      .catch(error => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 justify-content-between" style={{maxWidth: "935px"}}>
        <div className="d-flex fn_search_box">
          <div className="pr-1 d-flex">
            <label htmlFor="대상일" className="p-1 px-2" style={{wordBreak: 'keep-all'}}>
              등록일 :
            </label>
            <div className="input-group" style={{width: 'auto'}}>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
              <p className="mx-2">~</p>
              <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
            </div>
          </div>

          <div className="pr-1">
            <select className="form-select" aria-label="Default select example" style={{minWidth: '32%'}} id="s_branch_code">
            </select>
          </div>
          <div className="pr-1">
            <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="내용 검색" id="s_content"/>
          </div>

          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>
              검색
            </button>
          </div>
        </div>

        <div className="mx-1">
          <button type="button" className="btn btn-sm btn-dark px-3" onClick={() => {
            $('#modal_base_seq').val("");
            $('#modal_c_date').val(Fn.getCurrentDate());
            setModalOpen(true);
          }}>
            등록
          </button>
        </div>
      </div>

      <div className="m-3 table_list" style={{maxWidth: "935px"}}>
        {/* 회원목록 */}
        <table className="view-rounded sub_table_rowspan">
          <colgroup>
            <col width="65px"/>
            <col width="60px"/>
            <col width="90px"/>
            <col width="150px"/>
            <col width="*"/>
            <col width="100px"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            <th>등록일</th>
            <th>지점명</th>
            <th>내용</th>
            <th>등록자</th>
          </tr>
          {rets && rets.map((ret, i) => {

            const statusTxt = ret?.status_txt === 'Y' ? <span style={{color: 'red'}}>등록</span> : <span style={{color: 'blue'}}>해제</span>;

            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  <input
                    type="checkbox"
                    name="chk"
                    value={btoa(ret?.seq)}
                    className="checkbox"
                    id={`achk${i + 1}`}
                  />
                </td>
                <td onClick={() => {
                  $('#modal_base_seq').val(btoa(ret?.seq));
                  setModalOpen(true);
                }} style={{cursor: "pointer"}}>{ret?.c_date}</td>
                <td className="text-left">{ret?.branch_name}</td>
                <td className="text-left">{ret?.c_content}</td>
                <td>{ret?.c_man}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={6}>※ 등록된 data가 없습니다.</td>
            </tr>
          }
        </table>
      </div>

      <nav className="m-3 p-3 my-4 d-flex justify-content-between border-top" style={{maxWidth: "935px"}}>
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>

        <div>
          <div className="d-flex">
            <button type="button" className="btn btn-sm btn-outline-point px-2" onClick={() => del_data()}>
              삭제
            </button>
          </div>
        </div>
      </nav>

      <input type="hidden" id="modal_base_seq"/>
      <Modal
        isOpen={modalOpen}
        style={{
          modal: {
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            alignItems: undefined,
            justifyContent: undefined,
          },
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'transparent'
          }
        }}
        onAfterOpen={() => {
          Fn.centerModal();
          loadModal();
        }}
        onRequestClose={() => setModalOpen(false)}
        ariaHideApp={false}
      >
        <div className="pop_background" style={{zIndex: 1000000000}}>
          <div className="pop_box01 p_absolute_mid_pop" style={{width: '850px'}}>
            <div className="POP_title">
              <h5>
                <strong>컴플레인 등록</strong>
              </h5>
              <p className="closed">
                <button type="button" className="btn" onClick={() => setModalOpen(false)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    fill="currentColor"
                    className="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </button>
              </p>
            </div>

            <div className="POP_cnt_box w-100">
              <table className="view-rounded sub_table_border">
                <colgroup>
                  <col width="158px"/>
                  <col width="*"/>
                  <col width="40%"/>
                  <col width="*"/>
                </colgroup>
                <tr>
                  <th>등록일</th>
                  <th>지점</th>
                  <th>내용</th>
                  <th>등록자</th>
                </tr>
                <tr>
                  <td className="text-center">
                    <JDatePicker className="form-control" aria-describedby="button-addon2" id="modal_c_date"/>
                  </td>
                  <td className="text-center">
                    <select className="form-select" aria-label="Default select example" style={{width: '32%'}} id="modal_branch_code">
                    </select>
                  </td>
                  <td className="text-center">
                    <textarea className="form-control w-100" id="modal_c_content"/>
                  </td>
                  <td className="text-center">
                    <input type="text" className="form-control" aria-describedby="button-addon2" placeholder="등록자" id="modal_c_man"/>
                  </td>
                </tr>
              </table>
            </div>
            <div className="text-center mb-3">
              <button type="button" className="btn btn-sm btn-point px-3 mx-1" onClick={() => modal_go_submit()}>
                등록
              </button>
              <button type="button" className="btn btn-sm btn-ligray px-3" onClick={() => setModalOpen(false)}>
                취소
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </CmnCard>
  );
};

export default MemberComplainList;
