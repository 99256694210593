import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import $ from 'jquery';
import CmnCard from "../CmnCard";
import axios from "axios";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import Paging from "../common/Paging";
import ChkAuthNum from "../common/ChkAuthNum";
import JDatePicker from "../common/JDatePicker";
import SmsNew from "../common/SmsNew";

const BranchPtFreeOTList = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }

  const [rets, setRets] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [currPage, setCurrPage] = useState(1);
  const [srchRenew, setSrchRenew] = useState(0);
  const [renew, setRenew] = useState(0);
  const [smsModalOpen, setSmsModalOpen] = useState(false);

  useEffect(() => {
    $('.fn_search_box').each(function () {
      $(this).find("input[type=text]").each(function () {
        $(this).onEnter(() => setSrchRenew(Fn.getRenewTime));
      });
    });
  }, []);

  useEffect(() => {
    const currentDate = new Date();
    const monthsAgo = new Date(currentDate);
    monthsAgo.setMonth(monthsAgo.getMonth() - 1);

    $('#s_date2').val(Fn.getDate(currentDate));
    $('#s_date1').val(Fn.getDate(monthsAgo));
  }, []);

  useEffect(() => {
    if (!srchRenew) return;
    $('[id^=achk]').prop("checked", false);
    load(1);
  }, [srchRenew]);

  useEffect(() => {
    $('[id^=achk]').prop("checked", false);
    load();
  }, [currPage, renew]);

  const load = (_page) => {
    axios2
      .post(`/branchop/free/ot/list/ready?s_date1=${$('#s_date1').val()}&s_date2=${$('#s_date2').val()}&s_mb_name=${$('#s_mb_name').val()}&s_mb_hp=${$('#s_mb_hp').val()}&s_take_YN=${$('#s_take_YN option:selected').val() || ''}&page=${_page || currPage}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          setRets(result?.result);
          setPageInfo(pageInfo);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const chkSend = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let con = 0;
    let editMsgid = "";
    checkboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        const nn = checkbox.name;
        const m_chk = nn.substr(0, 3);
        if (m_chk === "chk") {
          con++;
          editMsgid += checkbox.value + "|";
        }
      }
    });
    if (con === 0) {
      window.alert("체크된 리스트가 없습니다.");
      return;
    }

    const url = `../message/sms.html?b_type=free_ot&s_type=sel&editMsgid=${editMsgid}`;
    const newWindow = window.open(url, "sms.html", "800", "600", "no");
    if (newWindow) newWindow.focus();
  };

  const take_ot = () => {
    let con = $('[id^=achk]:checked').map(function () {
      return $(this).val();
    }).get().join("|");

    if (con === '' || con.split(",").length === 0) {
      window.alert('체크된 리스트가 없습니다.');
      return;
    }

    if (window.confirm('진행 처리하시겠습니까?')) {
      const formData = new FormData();

      let values = $('[id^=achk]:checked').map(function () {
        return $(this).val();
      }).get().join(",");

      formData.append("chk[]", values);

      axios2.postFormEx('/branchop/free/ot/take', formData)
        .then((response) => response.json())
        .then(({code, message: data, result, pageInfo}) => {
          alert('처리되었습니다');
          setRenew(Fn.getRenewTime());
        })
        .catch((error) => {
          console.log(error);
          if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
        });
    }
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="d-flex m-3 fn_search_box">
        <div className="pr-1 d-flex">
          <div className="input-group" style={{width: 'auto'}}>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date1"/>
            <p className="mx-2">~</p>
            <JDatePicker className="form-control" aria-describedby="button-addon2" id="s_date2"/>
          </div>
        </div>
        <div className="pr-1">
          <input
            type="text"
            className="form-control"
            aria-describedby="button-addon2"
            placeholder="성명 검색"
            id="s_mb_name"
          />
        </div>
        <div className="pr-1">
          <input
            type="text"
            className="form-control"
            aria-describedby="button-addon2"
            placeholder="연락처 검색"
            id="s_mb_hp"
          />
        </div>
        <div className="pr-1">
          <select className="form-select sel_lg" aria-label="Default select example" id="s_take_YN">
            <option value="">::전체</option>
            <option value="N">미진행</option>
            <option value="Y">진행완료</option>
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>
            검색
          </button>
        </div>
      </div>

      <div className="m-3 pb-2 table_list">
        <table className="view-rounded sub_table_border auto">
          <colgroup>
            <col width="60px"/>
            <col width="80px"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
            <col width="*"/>
          </colgroup>

          <tr>
            <th>No</th>
            <th>
              <input type="checkbox" onClick={(e) => {
                if ($(e.target).prop("checked")) $('[id^=achk]').prop("checked", true);
                else $('[id^=achk]').prop("checked", false);
              }}/>
            </th>
            <th>성명</th>
            <th>카드번호</th>
            <th>연락처</th>
            <th>신청일</th>
            <th>처리일</th>
          </tr>

          {rets && rets.map((ret, i) => {
            return (
              <tr>
                <td>{pageInfo.totalArticles - pageInfo.articlesPerPage * (pageInfo.currentPage - 1) - i}</td>
                <td>
                  <input
                    type="checkbox"
                    name="chk"
                    value={btoa(ret?.seq)}
                    className="checkbox"
                    id={`achk${i + 1}`}
                  />
                </td>
                <td>{ret?.mb_name || ''}</td>
                <td>{ret?.mb_id || ''}</td>
                <td>***-****-****</td>
                <td>{ret?.request_date || ''}</td>
                <td>{ret?.take_date || ''}</td>
              </tr>
            );
          })}
          {(!rets || rets?.length === 0) &&
            <tr>
              <td colSpan={7}>※ 등록된 data가 없습니다.</td>
            </tr>
          }

        </table>
      </div>

      <nav className="p-3 mb-4 d-flex justify-content-between border-top flex-wrap">
        <Paging pageInfo={pageInfo} setCurrPage={page => setCurrPage(page)}/>
        <div className="d-flex">
          <div>
            <button type="button" className="btn btn-sm btn-outline-dark" onClick={() => take_ot()}>
              진행처리
            </button>
            <button type="button" className="btn btn-sm btn-outline-success mx-1"
                    onClick={() => {
                      if ($('[id^=achk]:checked').length === 0) {
                        window.alert('체크된 리스트가 없습니다.');
                        return;
                      }
                      setSmsModalOpen(true);
                    }}
            >
              선택 SMS 발송
            </button>
          </div>
          <div className="d-flex">
            <ChkAuthNum type={"locker_info_change"}/>
          </div>
        </div>
      </nav>

      <SmsNew
        isOpen={smsModalOpen}
        onModalClose={() => setSmsModalOpen(false)}
        searchType={"sms_list"}
        callback={() => {
          setSmsModalOpen(false);
        }}
      />

    </CmnCard>
  );
};

export default BranchPtFreeOTList;
