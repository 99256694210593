import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";
import $ from "jquery";


const FinanceFeeEtc = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);
  const [result, setResult] = useState({});

  useEffect(() => {
    $('#base_seq').val('');
    $('#manager_seq').val('');
    $('#etc_price').val('');
    $('#bigo').val('');
  }, [renew]);

  useEffect(() => {
    $('#s_year').val(localStorage.getItem('fee_year') || Fn.getCurrentDate().split('-')[0])
    $('#s_month').val(localStorage.getItem('fee_month') || Fn.getCurrentDate().split('-')[1])
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew, renew]);

  const load = () => {
    axios2
      .post(`/finance/fee/etc/ready?s_year=${$('#s_year option:selected').val()}&s_month=${$('#s_month option:selected').val()}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#manager_seq').html() === '') {
            let optionsHTML = '<option value="">::선택</option>';
            result?.ll?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#manager_seq').html(optionsHTML);
          }

          setResult(result);
          console.log(result);

          localStorage.setItem('fee_year', $('#s_year option:selected').val());
          localStorage.setItem('fee_month', $('#s_month option:selected').val());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  function go_submit(type) {
    if ($('#manager_seq').val() == "") {
      alert("스텝을 선택해주세요");
      $('#manager_seq').focus();
      return;
    }

    if ($('#etc_price').val() == "") {
      alert("기타수당을 입력해주세요");
      $('#etc_price').focus();
      return;
    }

    axios2
      .postEx('/finance/fee/etc', {
        s_year: $('#s_year option:selected').val(),
        s_month: $('#s_month option:selected').val(),
        base_seq: $('#base_seq').val() || '',
        manager_seq: $('#manager_seq option:selected').val(),
        etc_price: Fn.cfNumeric($('#etc_price').val()),
        bigo: $('#bigo').val()
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data == "insert_ok") {
          alert("등록되었습니다");
          $('#base_seq').val('');
          $('.cls-submit').text('추가');
          setRenew(Fn.getRenewTime());
        } else if (data == "update_ok") {
          alert("수정되었습니다");
          $('#base_seq').val('');
          $('.cls-submit').text('추가');
          setRenew(Fn.getRenewTime());
        } else if (data == "delete_ok") {
          alert("삭제되었습니다");
          $('#base_seq').val('');
          $('.cls-submit').text('추가');
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  const copy_data = (baseSeq, managerSeq, etcPrice, bigo) => {
    $('#base_seq').val(baseSeq);
    $('#manager_seq').val(managerSeq);
    $('#etc_price').val(Fn.numberWithCommas(etcPrice));
    $('#bigo').val(bigo);
  };

  const del_data = (base_seq) => {
    if (!window.confirm('해당 기타수당을 삭제하시겠습니까?')) return;

    axios2
      .postEx('/finance/fee/etc', {
        type: 'del',
        base_seq: base_seq,
      })
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        alert("삭제되었습니다");
        $('#base_seq').val('');
        $('.cls-submit').text('추가');
        setRenew(Fn.getRenewTime());
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  };

  return (
    <CmnCard onLogout={props.onLogout}>
      <input type="hidden" id="base_seq" value=""/>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/finance/fee/list/false")}>수수료 내역보고</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/finance/fee/public")}>퍼블릭 부서</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/finance/fee/pt")}>PT 부서</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/finance/fee/add")}>추가/기타</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/finance/fee/incen")}>인센티브</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/finance/fee/incen/pt")}>PT 수당</label>

          <input id="gg" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/finance/fee/gx")}>GX 수당</label>

          <input id="hh" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/finance/fee/consult")}>영업컨설팅비</label>

          <input id="ii" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ii" onClick={() => history.push("/finance/fee/step")}>직원별 수수료</label>

          <input id="jj" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="jj" onClick={() => history.push("/finance/fee/overtime/list")}>추가근무관리</label>

          <input id="kk" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="kk" onClick={() => history.push("/finance/fee/etc")}>기타수당</label>

          <input id="ll" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ll" onClick={() => history.push("/finance/fee/branch/overprice")}>초과수당설정</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
              <option key={year} value={year}>{year}년</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" style={{minWidth: "80px"}} id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="overflowX m-3">
        <div className="table_wide_sh3 no-min-width mb-4" style={{minWidth: "835px !important", maxWidth: "835px"}}>
          <div className="p-3 my-3" style={{border: "1px solid #eee", borderRadius: "12px"}}>
            <table className="view-rounded sub_table_border text-center auto">
              <colgroup>
                <col style={{width: "10%"}}/>
                <col style={{width: "23%"}}/>
                <col style={{width: "10%"}}/>
                <col style={{width: "23%"}}/>
                <col style={{width: "10%"}}/>
                <col style={{width: "23%"}}/>
              </colgroup>
              <tr>
                <th>스텝명</th>
                <td className="text-left">
                  <select className="form-select" id="manager_seq">
                  </select>
                </td>
                <th>기타수당</th>
                <td>
                  <input type="text" className="form-control" id="etc_price"
                         onKeyUp={(e) => Fn.vComma(e.target)}
                  />
                </td>
                <th>비고</th>
                <td><input type="text" className="form-control" id="bigo"/></td>
              </tr>
            </table>
            <div className="my-3">
              <button type="button" className="btn btn-sm btn-point px-3 cls-submit" onClick={() => {
                go_submit();
              }}>추가
              </button>
            </div>
          </div>

          <table className="view-rounded sub_table_border">
            <colgroup>
              <col width="140px"/>
              <col width="145px"/>
              <col width="*"/>
              <col width="145px"/>
            </colgroup>

            <tr>
              <th>스텝명</th>
              <th>기타수당</th>
              <th className="w-50">비고</th>
              <th>수정/삭제</th>
            </tr>

            {result?.list?.map((row, index) => {
              const base_seq = btoa(row.seq);

              return (
                <tr key={index}>
                  <td>{row?.user_name}</td>
                  <td className="text-right">{new Intl.NumberFormat().format(row?.etc_price)}</td>
                  <td>
                    <div align="left" style={{paddingLeft: "5px"}}>{row?.bigo}</div>
                    <input type="hidden" name={`input_bigo[${index + 1}]`} value={row?.bigo}/>
                  </td>
                  <td>
                    <button type="button" className="btn btn-ssm btn-dark"
                            onClick={() => {
                              $('.cls-submit').text('수정');
                              copy_data(
                                btoa(row?.seq),
                                row?.manager_seq,
                                row?.etc_price,
                                row?.bigo
                              );
                            }}
                    >수정
                    </button>
                    <button type="button" className="btn btn-ssm btn-gray mx-1" onClick={() => del_data(btoa(row?.seq))}>삭제</button>
                  </td>
                </tr>
              );
            })}
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default FinanceFeeEtc;
