import React, {useEffect, useState} from "react";
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import $ from 'jquery';
import axios2 from "../../helpers/APIHelper";
import Fn from "../../libraries/Fn";


const FinanceFeeGx = (props) => {
	
  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const total_days = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  const [year, setYear] = useState();
  const [month, setMonth] = useState();
  const [day, setDay] = useState(25);
  const [result, setResult] = useState();
  const [renew, setRenew] = useState(0);
  const [srchRenew, setSrchRenew] = useState(0);

  useEffect(() => {
    $('#s_year').val(localStorage.getItem('fee_year') || Fn.getCurrentDate().split('-')[0])
    $('#s_month').val(localStorage.getItem('fee_month') || Fn.getCurrentDate().split('-')[1])
  }, []);

  useEffect(() => {
    load();
  }, [renew, srchRenew]);

  const load = () => {
    const _year = Fn.parseInt($('#s_year option:selected').val());
    const _month = Fn.parseInt($('#s_month option:selected').val());
    setYear(_year);
    setMonth(_month);

    axios2
      .post(`/finance/fee/gx/ready?s_year=${$('#s_year option:selected').val() || ''}&s_month=${$('#s_month option:selected').val() || ''}&gx_teacher=${$('#gx_teacher option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          $('.class-cal').remove();

          localStorage.setItem('fee_year', $('#s_year option:selected').val());
          localStorage.setItem('fee_month', $('#s_month option:selected').val());

          setResult(result);
          console.log(result);

          if ($('#gx_teacher').html() === '') {
            let optionsHTML = '<option value="">강사 선택</option>';
            result?.ll?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.seq}">${list_row.user_name}</option>`;
            });
            $('#gx_teacher').html(optionsHTML);
          }

          // 윤년
          if (new Date(_year, 1, 29).getDate() === 29) {
            total_days[1] = 29;
          }

          const first_yoil = new Date(_year, _month - 1, 1).toLocaleString('en-US', {weekday: 'long'});
          const last_yoil = new Date(_year, _month - 1, total_days[_month - 1]).toLocaleString('en-US', {weekday: 'long'});

          let td_su, td_su2;

          switch (first_yoil) {
            case 'Sunday':
              td_su = 0;
              break;
            case 'Monday':
              td_su = 1;
              break;
            case 'Tuesday':
              td_su = 2;
              break;
            case 'Wednesday':
              td_su = 3;
              break;
            case 'Thursday':
              td_su = 4;
              break;
            case 'Friday':
              td_su = 5;
              break;
            case 'Saturday':
              td_su = 6;
              break;
          }

          switch (last_yoil) {
            case 'Sunday':
              td_su2 = 6;
              break;
            case 'Monday':
              td_su2 = 5;
              break;
            case 'Tuesday':
              td_su2 = 4;
              break;
            case 'Wednesday':
              td_su2 = 3;
              break;
            case 'Thursday':
              td_su2 = 2;
              break;
            case 'Friday':
              td_su2 = 1;
              break;
            case 'Saturday':
              td_su2 = 0;
              break;
          }

          const start_num = 1 - td_su;
          const end_num = total_days[_month - 1] + td_su2;

          const _calendar_array = [];

          for (let i = start_num; i <= end_num; i++) {
            if (i < 1 || i > total_days[_month - 1]) {
              _calendar_array[i] = [' ', ' '];
            } else {
              _calendar_array[i] = [i, new Date(_year, _month - 1, i).toLocaleString('en-US', {weekday: 'long'})];
            }
          }


          let output = '';

          let cnt = 1;
          for (let i = start_num; i <= end_num; i++) {
            const value = result?.calendar_array?.filter(r => r.value0 == i)?.[0];
            let holy_class = '';

            if (value?.value1 === 'Sunday' || cnt === 1) {
              if (_year === new Date().getFullYear() && _month === new Date().getMonth() + 1 && day === new Date().getDate() && Fn.parseInt(value?.value0) === day) {
                holy_class = "class='sunday today'";
              } else {
                holy_class = "class='sunday'";
              }
            } else {
              if (_year === new Date().getFullYear() && _month === new Date().getMonth() + 1 && day === new Date().getDate() && Fn.parseInt(value?.value0) === day) {
                holy_class = "class='today'";
              } else {
                holy_class = "";
              }
            }

            if (cnt % 7 === 1) {
              output += '<tr class="class-cal">';
            }

            let db_month = _month < 10 ? '0' + _month : _month.toString();
            let db_day = Fn.parseInt(value?.value0) < 10 ? '0' + Fn.parseInt(value?.value0) : Fn.parseInt(value?.value0).toString();
            let today_chk = `${_year}-${db_month}-${db_day}`;

            const dayNum = Fn.parseInt(value?.value0);

            holy_class = "scope";

            output += `
              <td key={i}>
                <a href="#" class="cal_date">` + (dayNum > 0 && dayNum <= total_days[_month - 1] ? dayNum : ' ') + `</a>
                <p class="m-2 mt-4 pt-2">`;

            if (dayNum > 0 && dayNum <= total_days[_month - 1]) {
              output += `
                <input
                  type="hidden"
                  id="show_days_${Fn.parseInt(value?.value0)}"
                  value="${Fn.parseInt(value?.value0)}"
                />
                <input
                  type="text"
                  id="days_${Fn.parseInt(value?.value0)}"
                  class="form-control w-100"
                  value="${value?.gx_cnt || ''}"
                />`;
            }

            output += `
                </p>
              </td>`;

            if (cnt % 7 === 0) {
              output += '</tr>';
            }

            cnt++;
          }

          $('#this_area').after(output);
        }
      })
      .catch((error) => {
        console.log(error);
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  useEffect(() => {
    load();
  }, []);

  function go_submit() {
    if (!$('#gx_teacher option:selected').val()) {
      alert("강사명을 선택해주세요");
      return;
    }

    const obj = {
      s_year: $('#s_year option:selected').val(),
      s_month: $('#s_month option:selected').val(),
      gx_teacher: $('#gx_teacher option:selected').val()
    }

    for (let i = 1; i <= 31; i++) {
      obj[`days_${i}`] = $('#days_' + i).val();
      obj[`show_days_${i}`] = $('#show_days_' + i).val();
    }

    axios2
      .postEx('/finance/fee/gx', obj)
      .then((response) => response.json())
      .then(({code, message: data, result, pageInfo}) => {
        if (data === "err") {
          alert('오류가 발생하였습니다');
        } else {
          alert("등록되었습니다");
          setRenew(Fn.getRenewTime());
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/finance/fee/list/false")}>수수료 내역보고</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/finance/fee/public")}>퍼블릭 부서</label>

          <input id="cc" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/finance/fee/pt")}>PT 부서</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/finance/fee/add")}>추가/기타</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/finance/fee/incen")}>인센티브</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/finance/fee/incen/pt")}>PT 수당</label>

          <input id="gg" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/finance/fee/gx")}>GX 수당</label>

          <input id="hh" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/finance/fee/consult")}>영업컨설팅비</label>

          <input id="ii" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ii" onClick={() => history.push("/finance/fee/step")}>직원별 수수료</label>

          <input id="jj" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="jj" onClick={() => history.push("/finance/fee/overtime/list")}>추가근무관리</label>

          <input id="kk" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="kk" onClick={() => history.push("/finance/fee/etc")}>기타수당</label>

          <input id="ll" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ll" onClick={() => history.push("/finance/fee/branch/overprice")}>초과수당설정</label>
        </div>
      </div>

      <div className="d-flex m-3">
        <div className="pr-1">
          <select className="form-select" id="s_year">
            {Array.from({length: 3}, (_, index) => new Date().getFullYear() - 2 + index).map((year) => (
              <option key={year} value={year}>{year}년</option>
            ))}
          </select>
        </div>
        <div className="pr-1">
          <select className="form-select" id="s_month">
            {[...Array(12)].map((_, index) => {
              const monthNumber = index + 1;
              const monthString = monthNumber < 10 ? `0${monthNumber}` : `${monthNumber}`;
              return (
                <option key={monthString} value={monthString}>{monthString}월</option>
              );
            })}
          </select>
        </div>
        <div className="pr-1">
          <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
        </div>
      </div>

      <div className="overflowX p-3 ">
        <div className="row table_wide_sh3">
          <div className="col-6">
            <table className="view-rounded calender_sm">
              <tr id="before_calendar">
                <td colSpan="7">
                  <div className="d-flex justify-content-between m-3">
                    <div>
                      <span className="h6 bold700 vertical-middle">{year}년 {month}월</span>
                    </div>
                    <div>
                      <select className="form-select" aria-label="Default select example" id="gx_teacher" onChange={() => setSrchRenew(Fn.getRenewTime())}>
                      </select>
                    </div>
                  </div>
                </td>
              </tr>
              <tr id="this_area">
                <th>일</th>
                <th>월</th>
                <th>화</th>
                <th>수</th>
                <th>목</th>
                <th>금</th>
                <th>토</th>
              </tr>
            </table>
            <p className="my-4">
              <button type="button" className="btn btn-sm btn-point px-3" onClick={() => go_submit()}>저장</button>
            </p>
          </div>
          <div className="col-6">
            {/* 강사목록 */}
            <table className="view-rounded sub_table_border">
              <tr>
                <th className="w_70px">No</th>
                <th>이름</th>
                <th>기본급</th>
                <th>수업횟수</th>
                <th>시급</th>
                <th>총수수료</th>
              </tr>
              {result?.gx_ll?.map((row, i) =>
                <tr>
                  <td>{row?.cc}</td>
                  <td>{row?.user_name}</td>
                  <td className="text-right">{Fn.numberWithCommas(row?.pu_basic_price)}</td>
                  <td className="text-right">{Fn.numberWithCommas(row?.gx_cnt)}</td>
                  <td className="text-right">{Fn.numberWithCommas(row?.pu_hour_price)}</td>
                  <td className="text-right">{Fn.numberWithCommas(row?.gx_price)}</td>
                </tr>
              )}
              <tr className="bgcolor bold700">
                <td colSpan="2">합계</td>
                <td className="text-right">{Fn.numberWithCommas(result?.gx_ll?.reduce((n, {pu_basic_price}) => n + pu_basic_price, 0))}</td>
                <td className="text-right">{Fn.numberWithCommas(result?.gx_ll?.reduce((n, {gx_cnt}) => n + gx_cnt, 0))}</td>
                <td className="text-right">{Fn.numberWithCommas(result?.gx_ll?.reduce((n, {pu_hour_price}) => n + pu_hour_price, 0))}</td>
                <td className="text-right">{Fn.numberWithCommas(result?.gx_ll?.reduce((n, {gx_price}) => n + gx_price, 0))}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </CmnCard>
  );
};

export default FinanceFeeGx;
