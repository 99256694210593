import React, {useEffect, useState} from 'react';
import "../../sass/main.scss";
import CmnCard from "../CmnCard";
import {useHistory} from "react-router-dom";
import Fn from "../../libraries/Fn";
import axios2 from "../../helpers/APIHelper";
import $ from "jquery";

const AnalysisMonthlyCoupon = (props) => {

  window._load = (_page = 1) => {
    try {
      load(_page);
    } catch (e) {
    }
  }
  const history = useHistory();

  const [srchRenew, setSrchRenew] = useState(0);
  const [result, setResult] = useState({});

  useEffect(() => {
    $('#s_year').val(Fn.getCurrentDate().split('-')[0])
  }, []);

  useEffect(() => {
    load();
  }, [srchRenew]);

  const load = () => {
    console.log(`/analysis/monthly/coupon/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_year=${$('#s_year option:selected').val() || ''}`)
    axios2
      .post(`/analysis/monthly/coupon/ready?s_branch_code=${$('#s_branch_code option:selected').val() || ''}&s_year=${$('#s_year option:selected').val() || ''}`)
      .then((response) => {
        const {code, message, result, pageInfo} = response?.data;
        if (code === 200) {
          if ($('#s_branch_code').html() === '') {
            let optionsHTML = '<option value="">전체지점</option>';
            result?.bList?.forEach((list_row) => {
              optionsHTML += `<option value="${list_row.branch_code}">${list_row.branch_name}</option>`;
            });
            $('#s_branch_code').html(optionsHTML);
          }

          setResult(result);
        }
      })
      .catch((error) => {
        if (!window.disableErrorMsg) alert('데이터오류! 시스템 관리자에게 문의하세요');
      });
  }

  return (
    <CmnCard onLogout={props.onLogout}>
      <div className="m-3" style={{clear: 'both'}}>
        <div className="tabs">
          <input id="aa" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="aa" onClick={() => history.push("/analysis/monthly/member")}>월간등록현황</label>

          <input id="bb" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="bb" onClick={() => history.push("/analysis/monthly/finance/new")}>월계표 비교분석</label>

          <input id="cc" type="radio" name="tab_item" checked/>
          <label className="tab_item" htmlFor="cc" onClick={() => history.push("/analysis/monthly/coupon")}>쿠폰발송내역</label>

          <input id="dd" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="dd" onClick={() => history.push("/analysis/monthly/tm")}>TM 현황보고</label>

          <input id="ee" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ee" onClick={() => history.push("/analysis/monthly/region")}>지역분류</label>

          <input id="ff" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="ff" onClick={() => history.push("/analysis/monthly/public")}>경쟁업체(퍼블릭)</label>

          <input id="gg" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="gg" onClick={() => history.push("/analysis/monthly/pt")}>경쟁업체(PT)</label>

          <input id="hh" type="radio" name="tab_item"/>
          <label className="tab_item" htmlFor="hh" onClick={() => history.push("/analysis/monthly/analysis/pt")}>PT월간등록현황</label>
        </div>
      </div>

      <div className="overflowX ">
        <div className="d-flex m-3">
          <div className="pr-1">
            <select className="form-select" id="s_branch_code">
            </select>
          </div>
          <div className="pr-1">
            <select className="form-select" id="s_year">
              {Array.from({length: 4}, (_, index) => new Date().getFullYear() - 3 + index).map((year) => (
                <option key={year} value={year}>{year}년</option>
              ))}
            </select>
          </div>
          <div className="pr-1">
            <button type="button" className="btn btn-sm btn-point px-3" onClick={() => setSrchRenew(Fn.getRenewTime())}>검색</button>
          </div>
        </div>

        <div className="m-3 table_list table_wide_sh2">
          {/* 회원목록 */}
          <table className="view-rounded sub_table_rowspan">
            <colgroup>
              <col width="60px"/>
              <col width="15%"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>

              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>

              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
              <col width="*"/>
            </colgroup>

            <tr>
              <th rowSpan="2">No</th>
              <th rowSpan="2">쿠폰명</th>
              <th colSpan="3">적용구분</th>
              <th rowSpan="2">적용기간</th>
              <th rowSpan="2">쿠폰금액</th>

              <th rowSpan="2">01월</th>
              <th rowSpan="2">02월</th>
              <th rowSpan="2">03월</th>
              <th rowSpan="2">04월</th>
              <th rowSpan="2">05월</th>

              <th rowSpan="2">06월</th>
              <th rowSpan="2">07월</th>
              <th rowSpan="2">08월</th>
              <th rowSpan="2">09월</th>
              <th rowSpan="2">10월</th>

              <th rowSpan="2">11월</th>
              <th rowSpan="2">12월</th>
              <th rowSpan="2">합계</th>
            </tr>
            <tr>
              <th>헬스</th>
              <th>운동복</th>
              <th>라커</th>
            </tr>

            {result?.top?.map((top_row, top_count) => {
              top_count++;
              const coupon_seq = top_row.coupon_seq;
              const coupon_name = top_row.coupon_name;
              const c_info = top_row.c_info.split("||");
              const health_YN = c_info[0];
              const suit_YN = c_info[1];
              const locker_YN = c_info[2];
              const period_type = c_info[3];
              const period = c_info[4];
              const coupon_price = c_info[5];

              const new_data = [];

              for (let i = 1; i <= 12; i++) {
                const target_month = i < 10 ? "0" + i : i.toString();
                new_data[target_month] = Fn.parseInt(top_row?.data?.filter(r => r.pu_month === target_month)?.[0]?.count);
              }

              let t_data = 0;
              const t_data_values = [];
              for (let i = 1; i <= 12; i++) {
                const target_month = i < 10 ? "0" + i : i;
                t_data_values.push(new_data[target_month] || 0);
                t_data += new_data[target_month] || 0;
                new_data[target_month] = 0;
              }

              return (
                <tr className="active">
                  <td height="30">{top_count}</td>
                  <td style={{background: "#FFFFFF"}} className="text-left">
                    <div style={{paddingLeft: "5px"}}>{coupon_name}</div>
                  </td>
                  <td style={{background: "#FFFFFF"}}>{health_YN}</td>
                  <td style={{background: "#FFFFFF"}}>{suit_YN}</td>
                  <td style={{background: "#FFFFFF"}}>{locker_YN}</td>
                  <td style={{background: "#FFFFFF"}}>{period} {period_type}</td>
                  <td style={{background: "#FFFFFF"}}>
                    <div style={{paddingRight: "5px", textAlign: "right"}}>
                      {Fn.numberWithCommas(coupon_price)}
                    </div>
                  </td>
                  {t_data_values.map((value, index) => (
                    <td key={index} style={{background: "#FFFFFF"}}>
                      <div style={{paddingRight: "5px", textAlign: "right"}}>
                        {Fn.numberWithCommas(value)}
                      </div>
                    </td>
                  ))}
                  <td style={{background: "#FFFFFF"}}>
                    <div style={{paddingRight: "5px", textAlign: "right"}}>
                      <strong>{Fn.numberWithCommas(t_data)}</strong>
                    </div>
                  </td>
                </tr>
              )
            })}
          </table>
        </div>
      </div>
    </CmnCard>
  );
};

export default AnalysisMonthlyCoupon;
